import { Delete } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { useState } from 'react';
import { compose } from 'redux';
import InputBox from 'src/utils/components/input-field/input-box';
import { styles } from 'src/utils/components/input-field/input-generic/styles';

interface ReportModalProps {
  classes: Record<string, string>;
  placeholder: string;
  value: string;
  disableNotes: boolean;
  onBlur: (value: string) => void;
  onDelete: () => void;
}

const NoteInput = (props: ReportModalProps): JSX.Element => {
  const { classes, placeholder, disableNotes, onBlur, onDelete, value } = props;
  const [note, setNote] = useState(value);
  const handleNoteChange = (e: any) => {
    setNote(e[0]);
  };
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <InputBox
        placeholder={placeholder}
        onChange={handleNoteChange}
        options={[]}
        id='noteBox'
        multiline={true}
        disabled={disableNotes}
        onBlur={() => {
          onBlur(note);
        }}
        values={note ? [note] : ['']}
        autoFocus
      />
      {note && !disableNotes ? (
        <Delete
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDelete();
          }}
          className={classes.deleteIcon}
        />
      ) : null}
    </div>
  );
};

export default compose(withStyles(styles))(NoteInput);
