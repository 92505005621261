import { getErrorObject, withGraphQLErrors } from 'src/utils/funcs';
import * as queries from 'src/modules/agenda/agenda.queries';
import { APOLLO_CLIENT } from 'config/apollo.config';
import { Dispatch } from 'redux';
import axios from 'axios';
import { API_URL } from 'src/utils/env';
import { ApolloQueryResult, FetchResult } from '@apollo/client';
import { buildAdvancedFilters } from './utils/redux/queries/agenda.filter.buildAdvancedFilters';
import { buildIssueInstanceToScheduleFilter } from './utils/redux/filter/agenda.filter.buildIssueInstanceToScheduleFilter';
import fixOrderKey from './utils/agenda.utils.fixOrderKey';
import {
  AccountFilterFilter,
  Action,
  ActionWhereInput,
  Element,
  GetIssuesInstancesAndCountDocument,
  GetIssuesInstancesToTableDocument,
  IssueCatalogWhereInput,
  IssueInstance,
  IssueInstanceWhereInput,
  Job,
  JobWhereInput,
  Site,
  WorkPackageData,
} from 'src/gql/graphql';

export default (): void => null;

export const getIssueInstancesSimple =
  (args: {
    page: number;
    limit: number;
    isHistory?: boolean;
    workPackageId?: string;
    nameContains?: string;
    glarID_contains?: string;
  }) =>
  async (dispatch: Dispatch) => {
    const { page, limit, isHistory, workPackageId, nameContains, glarID_contains } = args;
    let response: { data: { issues: IssueInstance[] } };
    const filter: IssueInstanceWhereInput = isHistory
      ? {
          workPackageId_eq: typeof workPackageId === 'string' ? workPackageId : undefined,
          closedAt_exists: true,
          name_contains: nameContains ? nameContains : undefined,
          glarID_contains: glarID_contains ? glarID_contains : undefined,
        }
      : {
          closedAt_exists: false,
          needsApproval_eq: false,
          workPackageId_eq: typeof workPackageId === 'string' ? workPackageId : undefined,
          stateMachineInstance: { canceled_eq: false },
          name_contains: nameContains ? nameContains : undefined,
          glarID_contains: glarID_contains ? glarID_contains : undefined,
        };

    try {
      response = await APOLLO_CLIENT.query({
        variables: {
          skip: page /* * limit >= 0 ? page * limit : 0 */,
          limit,
          filter,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_ISSUES_INSTANCES,
      });

      const count: { data: { issuesCount: number } } = await APOLLO_CLIENT.query({
        variables: {
          filter,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_COUNT_ISSUES_INSTANCES,
      });

      return {
        rows: response.data.issues,
        total: count.data.issuesCount,
      };
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const issueInstancesCount =
  (
    accessToSite: Site,
    accessToElement: Element,
    isHistory: boolean,
    catalogId?: string,
    workPackageId?: string | boolean,
    advancedFilters?: AccountFilterFilter[],
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const filter = buildAdvancedFilters({
        accessToSites: accessToSite ? [accessToSite] : undefined,
        accessToElements: accessToElement ? [accessToElement] : undefined,
        advancedFilters,
        isHistory,
        catalogId,
        workPackageId,
      });

      const response = await APOLLO_CLIENT.query({
        variables: {
          filter,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_COUNT_ISSUES_INSTANCES,
      });

      return response.data.issuesCount;
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getIssueInstances =
  (
    limit: number,
    _sorted: string,
    skip: number,
    _history: string,
    orderFilter: string | string[] | undefined,
    accessToSite: Site,
    accessToElement: Element,
    isHistory: boolean,
    catalogId?: string,
    workPackageId?: string | boolean,
    advancedFilters?: AccountFilterFilter[],
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const order: string[] = [];

      if (orderFilter) {
        if (Array.isArray(orderFilter)) {
          for (const orderVal of orderFilter) {
            order.push(fixOrderKey(orderVal));
          }
        } else {
          order.push(fixOrderKey(orderFilter));
        }
      }

      const filter = buildAdvancedFilters({
        accessToSites: accessToSite ? [accessToSite] : undefined,
        accessToElements: accessToElement ? [accessToElement] : undefined,
        advancedFilters,
        isHistory,
        catalogId,
        workPackageId,
      });

      const instances = await APOLLO_CLIENT.query({
        variables: {
          skip,
          limit,
          filter,
          orderBy: order,
        },
        fetchPolicy: 'no-cache',
        query: GetIssuesInstancesAndCountDocument,
      });

      return {
        rows: instances.data.issues,
        total: instances.data.issuesCount,
      };
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getIssueInstance =
  (issueId: string) =>
  async (dispatch: Dispatch): Promise<IssueInstance> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          filter: issueId,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_ISSUE_INSTANCE,
      });

      return response.data.issue;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getIssuesAndActions = (
  issueFilter: IssueInstanceWhereInput | null,
  actionFilter: ActionWhereInput | null,
  skip: number,
  limit: number,
) =>
  withGraphQLErrors<Array<IssueInstance | Action>>(async () => {
    const response = await APOLLO_CLIENT.query({
      variables: {
        issueFilter,
        actionFilter,
        skip,
        limit,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_ISSUES_AND_ACTIONS,
    });

    return response.data.issuesAndActions;
  });

const handleErrorRest = (error: { response: { data: Blob } }, dispatch: Dispatch): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (): void => {
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: JSON.parse(reader.result as string).errors[0].message,
          severity: 'error',
        },
      });
      resolve(Promise.reject(error));
    };

    reader.onerror = (): void => {
      reject(error);
    };

    reader.readAsText(error.response.data);
  });
};

export const downloadIssueReport = (issue: { _id: string; name: string }) => async (dispatch: Dispatch) => {
  try {
    await axios
      .get(API_URL + '/issue/report/' + issue._id, {
        responseType: 'blob',
        headers: { authorization: localStorage.getItem('access_token') },
      })
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', issue.name);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        handleErrorRest(error, dispatch);
      });
  } catch (error) {
    handleErrorRest(error, dispatch);
  }
};

export const getIssueInstancesToSchedule =
  (filters: AccountFilterFilter[], initDate: Date, finishDate: Date, accessToSites: Site[]) =>
  async (dispatch: Dispatch): Promise<Job[]> => {
    try {
      const filterInstances = buildIssueInstanceToScheduleFilter({ filters, initDate, finishDate, accessToSites });

      const response = await APOLLO_CLIENT.query({
        variables: {
          filterInstances,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_ISSUES_INSTANCES_TO_SCHEDULE,
      });
      return response.data.myCalendarJobs;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getCalendarJobsCount =
  (filters: JobWhereInput) =>
  async (dispatch: Dispatch): Promise<number> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          filters,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_MY_CALENDAR_JOBS_COUNT,
      });

      return response.data.myCalendarJobsCount;
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getJobsToTable =
  (filters, limit, skip, order) =>
  async (dispatch: Dispatch): Promise<{ rows: Job[]; total: number }> => {
    try {
      const response: { data: { myCalendarJobs: Job[]; myCalendarJobsCount: number } } = await APOLLO_CLIENT.query({
        variables: {
          filters,
          limit,
          skip,
          order,
        },
        fetchPolicy: 'no-cache',
        query: GetIssuesInstancesToTableDocument,
      });

      return { rows: response.data.myCalendarJobs, total: response.data.myCalendarJobsCount };
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const jobsCount =
  (filter: JobWhereInput) =>
  async (dispatch: Dispatch): Promise<number> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          filter,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_JOBS_COUNT,
      });

      return response.data.jobsCount;
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getJob =
  (id: string) =>
  async (dispatch: Dispatch): Promise<Job> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_JOB,
      });

      return response.data.agendaJob;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getJobIssueInfo = (jobId: string) => async (dispatch: Dispatch) => {
  try {
    const response: FetchResult<{
      agendaJob: any;
    }> = await APOLLO_CLIENT.query({
      variables: {
        id: jobId,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_JOB_ISSUE_INFO,
    });

    return response.data.agendaJob;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const runJob = (jobId: string) => async (dispatch: Dispatch) => {
  try {
    const response: FetchResult<{
      runJob: { _id: string; data: { issue: { name: string; instance: { _id: string } } } };
    }> = await APOLLO_CLIENT.mutate({
      variables: {
        jobId,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_RUN_JOB,
    });

    return response.data.runJob;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const updateWorkPackage =
  (jobId: string, issueCatalogs?: WorkPackageData[], name?: string, executionDate?: string) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          jobId,
          issueCatalogs,
          name,
          executionDate,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_WORK_PACKAGE,
      });

      return response.data.updateWorkPackageJob;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const createJobs =
  (executionDate: Date, IssuesCatalogs: string[], site?: string, element?: string) => async (dispatch: Dispatch) => {
    try {
      await APOLLO_CLIENT.mutate({
        variables: {
          executionDate,
          IssuesCatalogs,
          site,
          element,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_CREATE_JOBS,
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const addIssuesToWorkPackage = (jobId: string, issueCatalogs: WorkPackageData[]) => async () => {
  const response = await APOLLO_CLIENT.mutate({
    variables: {
      jobId,
      issueCatalogs,
    },
    fetchPolicy: 'no-cache',
    mutation: queries.M_ADD_ISSUES_TO_WORK_PACKAGE,
  });

  return response.data.instantiateIssuesOnWorkPackage;
};

export const getJobs = (filter: JobWhereInput, limit?: number, skip?: number) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        filter,
        limit,
        skip,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_JOBS,
    });

    return response.data.jobs;
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const getJobsCount =
  (filter: JobWhereInput) =>
  async (dispatch: Dispatch): Promise<number> => {
    try {
      const response: ApolloQueryResult<{ jobsCount: number }> = await APOLLO_CLIENT.query({
        variables: {
          filter,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_JOBS_COUNT,
      });

      return response.data.jobsCount;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const cancelWorkPackage =
  (jobId: string) =>
  async (dispatch: Dispatch): Promise<Job> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          jobId,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_CANCEL_WORK_PACKAGE,
      });

      return response.data.cancelWorkPackage;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getIssueInstancesWorkPackages =
  (workPackageId: string, limit?: number, skip?: number) =>
  async (dispatch: Dispatch): Promise<IssueInstance[]> => {
    try {
      const filter = { workPackageId_eq: workPackageId };
      const response = await APOLLO_CLIENT.query({
        variables: {
          filter,
          limit,
          skip,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_ISSUE_INSTANCES_WORK_PACKAGES,
      });

      return response.data.issues;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getIssueCatalogsToWorkPackage =
  (filter: IssueCatalogWhereInput) =>
  async (
    dispatch: Dispatch,
  ): Promise<
    Array<
      WorkPackageData & {
        templateId: string;
      }
    >
  > => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          filter,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_ISSUES_CATALOGS_TO_WORK_PACKAGE,
      });

      return response.data.issueCatalogs;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const createWorkPackage =
  (executionDate: string, name: string, issueCatalogs: WorkPackageData[]) =>
  async (dispatch: Dispatch): Promise<Job> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          executionDate,
          name,
          issueCatalogs,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_CREATE_WORK_PACKAGE,
      });

      return response.data.createWorkPackageJob;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getIssueInstanceToNotification =
  (where: IssueInstanceWhereInput) =>
  async (dispatch: Dispatch): Promise<Partial<IssueInstance>> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          where,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_ISSUES_INSTANCES_TO_NOTIFICATION,
      });

      return response.data.issues.length ? response.data.issues[0] : null;
    } catch (error: any) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const subscribeJobUpdate = (_id: string) => {
  try {
    return APOLLO_CLIENT.subscribe({
      query: queries.S_ON_JOB_UPDATE,
      variables: { _id },
    }).map((result) => {
      return result?.data?.jobUpdated;
    });
  } catch (error) {
    const obj = getErrorObject(error, '');
    console.error(obj);
    return error;
  }
};
