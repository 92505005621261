import * as React from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';

const MeetingRefresh = () => React.lazy(() => import('src/modules/remote-assistance/pages/meeting/agora-page'));

const Meeting = MeetingRefresh();

export const render = {
  default: ({ ...props }) => {
    return (
      <React.Suspense fallback={<LazyLoadingContent />}>
        <Meeting {...props} />
      </React.Suspense>
    );
  },
};

export default render;
