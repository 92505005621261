import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';

const ProfileCreateRefresh = () => lazy(() => import('src/modules/profile/profile.create.default'));
const ProfileCreateTemplateRefresh = () => lazy(() => import('src/modules/profile/profile.create.template'));

let ProfileCreate = ProfileCreateRefresh();
let ProfileCreateTemplate = ProfileCreateTemplateRefresh();

export const render = {
  create: {
    default: ({ ...props }) => {
      if (ProfileCreate?._result?.type === 'error') {
        ProfileCreate = ProfileCreateRefresh();
      }
      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <ProfileCreate {...props} />
        </Suspense>
      );
    },
    template: ({ ...props }) => {
      if (ProfileCreateTemplate?._result?.type === 'error') {
        ProfileCreateTemplate = ProfileCreateTemplateRefresh();
      }
      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <ProfileCreateTemplate {...props} />
        </Suspense>
      );
    },
  },
};

export default render;
