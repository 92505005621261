import { Category, LocationOn } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useMemo } from 'react';
import i18n from 'src/utils/translations/i18n';

const useStyles = makeStyles((theme) => ({
  placeholderContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    color: theme.palette.text.disabled,
    bottom: '2px',
  },
}));

export const PlaceholderInputSitesElements = ({ context }: { context: 'site' | 'element' | 'siteElement' }) => {
  const classes = useStyles();

  const placeholderContent = useMemo(() => {
    switch (context) {
      case 'element':
        return {
          icon: <Category />,
          text: i18n.t('selectElement'),
        };
      case 'site':
        return {
          icon: <LocationOn />,
          text: i18n.t('selectSite'),
        };
      default:
        return {
          icon: <></>,
          text: i18n.t('select'),
        };
    }
  }, [context]);

  return (
    <div className={classes.placeholderContainer}>
      {placeholderContent.icon}
      <Typography>{placeholderContent.text}</Typography>
    </div>
  );
};
