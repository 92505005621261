import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    '@global': {
      '*::-webkit-scrollbar-thumb': {
        'background-color': theme.palette.mode === 'light' ? '' : `${theme.palette.text.primary} !important`,
      },
      '.swal2-modal': {
        backgroundColor: theme.palette.background.default,
      },
      '.swal2-title': {
        color: theme.palette.text.primary,
        fontSize: '22px !important',
      },
      '.swal2-content': {
        color: theme.palette.text.primary,
      },
      '.swal2-icon': {
        color: `${theme.palette.error.main} !important`,
        borderColor: `${theme.palette.error.main} !important`,
      },
      '.swal2-confirm': {
        backgroundColor: `${theme.palette.primary.main} !important`,
        padding: '8px 22px',
        fontFamily: '"Poppins", sans-serif;',
        fontWeight: '500 !important',
        fontSize: '14px !important',
        lineHeight: '16px !important',
        letterSpacing: '1.25px !important',
        '&:hover': {
          backgroundColor: `${theme.palette.primary.dark} !important`,
        },
      },
      '.swal2-styled': {
        boxShadow: 'none !important',
        '&:focus': {
          boxShadow: 'none !important',
        },
      },
      '.swal2-cancel': {
        background: 'transparent !important',
        border: `1px solid ${theme.palette.primary.light} !important`,
        color: `${theme.palette.primary.main} !important`,
        padding: '8px 22px',
        fontFamily: '"Poppins", sans-serif;',
        fontWeight: '500 !important',
        fontSize: '14px !important',
        lineHeight: '16px !important',
        letterSpacing: '1.25px !important',
        '&:hover': {
          border: `1px solid ${theme.palette.primary.light} !important`,
          background: `${theme.palette.primary.light} !important`,
        },
      },
      'div.MuiChip-outlined': {
        border: `1px solid ${theme.palette.grey.medium} !important`,
      },
      'span.MuiChip-deleteIcon': {
        color: `${theme.palette.grey.medium} !important`,
      },
      'svg.MuiChip-deleteIcon': {
        color: `${theme.palette.grey.medium} !important`,
      },
      'div.MuiChip-avatar': {
        color: `${theme.palette.common.white} !important`,
        backgroundColor: `${theme.palette.grey.medium}`,
      },
      'span.MuiChip-label': {
        color: theme.palette.text.primary,
      },
      'a:-webkit-any-link': {
        color: theme.palette.primary.main,
      },
      '.MuiPaper-root': {
        background: theme.palette.background.default,
      },
    },
    container: {
      position: 'relative',
      fontSize: theme.typography.fontSize,
      //overflowY: 'hidden', // Uncomment this when fixing Full Pages MR
    },
    main: {
      flex: '1 1',
      display: 'flex',
      height: '100vh',
      flexDirection: 'column',
      transition: 'all .5s cubic-bezier(.685,.0473,.346,1)',
    },
    div: {
      height: '-webkit-fill-available',
      width: '-webkit-fill-available',
    },
    routerWrapper: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    leaveModalWidth: {
      maxWidth: '400px',
    },
    taskInputModal: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    taskInputTitle: {
      fontWeight: 500,
      fontSize: '16px',
    },
    taskInputContent: {
      fontWeight: 400,
      fontSize: '16px',
      color: theme.palette.grey.light,
    },
    'div.MuiChip-outlined': {
      border: `1px solid ${theme.palette.grey.medium} !important`,
    },
    'span.MuiChip-deleteIcon': {
      color: `${theme.palette.grey.medium} !important`,
    },
    'svg.MuiChip-deleteIcon': {
      color: `${theme.palette.grey.medium} !important`,
    },
    'div.MuiChip-avatar': {
      color: `${theme.palette.common.white} !important`,
      backgroundColor: `${theme.palette.grey.medium}`,
    },
    'span.MuiChip-label': {
      color: theme.palette.text.primary,
    },
    success: {},
    warning: {},
    error: {},
  });
