import LabelInput from '../input-field/input-label';
import { styles } from './styles';
import { WithStyles, withStyles } from '@mui/styles';
import { ExpandMore, Search } from '@mui/icons-material';
import { useMemo, useRef, useState } from 'react';
import { InputBase, ListItem, ListItemText, Popover, Tooltip } from '@mui/material';
import { t } from 'i18next';
import classNames from 'classnames';

type Option = {
  id: string;
  label: string;
  disabled?: boolean;
};

interface StaticFieldPopoverProps extends WithStyles<typeof styles> {
  title?: string;
  disabled?: boolean;
  required?: boolean;
  searchable?: boolean;
  options: Option[];
  placeholder?: string;
  innerRef?: React.MutableRefObject<Element>;
  selected: string;
  onSelect: (item: Option) => any;
  'data-testid': string;
  error?: boolean;
}

const StaticFieldPopover = (props: StaticFieldPopoverProps) => {
  const {
    title,
    disabled = false,
    required = false,
    searchable = false,
    options = [],
    placeholder = '',
    error = false,
    classes,
    onSelect,
    selected,
    'data-testid': dataTestId,
  } = props;
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [searchWord, setSearchWord] = useState<string>(null);

  const selectedItem = useMemo(() => options.find((o) => o.id == selected), [selected, options]);

  const filteredOptions = useMemo(
    () => options.filter((o) => (searchWord ? o.label.toLowerCase().includes(searchWord.toLowerCase()) : true)),
    [searchWord, options],
  );

  function handleSelectItem(option: Option) {
    setSearchWord(null);
    onSelect(option);
    setPopoverOpen(false);
  }

  function handleKeyDownDiv(event: React.KeyboardEvent, isDisabled: boolean) {
    if (isDisabled) return;
    if (event.key === 'Enter') {
      setPopoverOpen(true);
    }
  }

  return (
    <>
      <div className={classes.divContainer} role='button'>
        {title ? <LabelInput required={required}>{title}</LabelInput> : null}
        <div className={classes.relative}>
          <ExpandMore
            className={`${classes.expandIcon} ${!disabled ? classes.expandIconActive : classes.expandIconDisabled}`}
            onClick={(e) => {
              if (disabled) return;
              e.stopPropagation();
              e.preventDefault();
              setPopoverOpen(true);
            }}
          />
        </div>
        <div
          ref={anchorRef}
          className={classNames({
            [classes.input]: !disabled && !error,
            [classes.inputDisabled]: disabled,
            [classes.inputError]: error,
          })}
          onClick={() => {
            if (disabled) return;
            setPopoverOpen(true);
          }}
          data-testid={props['data-testid']}
          role='button'
          onKeyDown={(e) => {
            handleKeyDownDiv(e, disabled);
          }}
          tabIndex={0}
        >
          <span className={classNames({ [classes.selectedItem]: !!selected, [classes.placeholder]: true })}>
            {selectedItem ? selectedItem.label : t(placeholder)}
          </span>
        </div>
        {disabled || !popoverOpen ? null : (
          <Popover
            open={true}
            anchorEl={anchorRef.current}
            onClose={() => {
              setPopoverOpen(false);
              setSearchWord('');
            }}
            slotProps={{ paper: { style: { width: anchorRef.current?.getBoundingClientRect().width } } }}
            classes={{ paper: classes.popoverContainer }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            {searchable ? (
              <div>
                <InputBase
                  autoFocus
                  key={'static-dropdown-input'}
                  id={'searchInput'}
                  data-testid={'searchInput'}
                  fullWidth
                  classes={{ root: classes.searchInput }}
                  placeholder={t('search') + '...'}
                  endAdornment={<Search className={classes.searchIcon} />}
                  onChange={(e) => setSearchWord(e.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' && options.length > 0) {
                      handleSelectItem(options[0]);
                    }
                  }}
                />
                <hr className={classes.divider} />
              </div>
            ) : null}
            {filteredOptions.length > 0 ? (
              <ul
                css={{
                  overflowY: 'auto',
                  maxHeight: '280px',
                  padding: 0,
                  margin: 0,
                }}
              >
                {filteredOptions.map((option) => {
                  return (
                    <ListItem
                      key={option.id}
                      classes={{
                        root: classNames(classes.listItem, { [classes.itemDisabled]: option.id === selectedItem?.id }),
                      }}
                      onClick={() => {
                        handleSelectItem(option);
                      }}
                    >
                      <ListItemText classes={{ root: classes.itemText }}>
                        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                          <Tooltip
                            title={option.label}
                            placement='top-start'
                            enterNextDelay={500}
                            data-testid={`static-field-popover-${dataTestId}-${option.label}`}
                          >
                            <span>{option.label}</span>
                          </Tooltip>
                        </div>
                      </ListItemText>
                    </ListItem>
                  );
                })}
              </ul>
            ) : (
              <span className={classes.noResults}>{t('noResults')}</span>
            )}
          </Popover>
        )}
      </div>
    </>
  );
};

export default withStyles(styles)(StaticFieldPopover);
