import gql from 'graphql-tag';
import { LABEL_VALUE } from './label';

export const UNIT_BODY = gql`
  fragment unit on Unit {
    __typename
    _id
    name
    protected
    symbol
    labelValues {
      ...labelValue
    }
    folder {
      _id
      name
      protected
      parentsTree {
        name
        _id
      }
    }
  }
  ${LABEL_VALUE}
`;

export const UNIT_TO_TREE = gql`
  fragment unitToTree on Unit {
    __typename
    _id
    name
    protected
    symbol
    labelValues {
      ...labelValue
    }
  }
  ${LABEL_VALUE}
`;

export const UNIT_FOLDER_BODY = gql`
  fragment unitFolder on UnitFolder {
    __typename
    _id
    name
    protected
    unitsCount(where: { archived_ne: true })
    unitFoldersCount
    totalFolders: unitFoldersCount
    totalItems: unitsCount(where: { archived_ne: true })
    parentsTree {
      _id
      name
    }
    parent {
      _id
      name
    }
  }
`;
