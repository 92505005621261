import {
  AttachFile,
  CalendarToday,
  Category,
  CheckBox,
  ChevronRightOutlined,
  Construction,
  FilterCenterFocus,
  Gesture,
  Info,
  KeyboardArrowDown,
  LocationOn,
  LooksOne,
  RadioButtonCheckedOutlined,
  TableChart,
  TextFields,
} from '@mui/icons-material';
import { MenuItem, Popover } from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Dispatch, bindActionCreators, compose } from 'redux';
import { useMainContainerContext } from 'src/base/main-container/context/main.container.context';
import { editPreset } from 'src/modules/issue-templates/issue.templates.redux';
import { withIssueTemplateConnector } from 'src/modules/issue-templates/redux/issue.template.draft.redux';
import DatetimePopover from 'src/utils/components/generic-input-card/components/datetime-popover';
import { BlockCloseDropdownContext } from '..';
import SelectionPresetsPopover from '../../selection-presets-popover';

const SelectType = (props: any) => {
  const {
    classes,
    input,
    setInput,
    instruction,
    refs,
    presets,
    templateId,
    setPopoverAnchorEl,
    editPreset,
    issueTemplate,
    updateIssueTemplateDraftState,
  } = props;
  const { t } = useTranslation();
  const [openDatetimePopover, setOpenDatetimePopover] = useState<{ current: EventTarget }>(null);
  const fieldRef = useRef();
  const location = useLocation();
  const isIssueTemplate = location.pathname.includes('/templates/issues');
  const { doingOnBoarding } = useMainContainerContext();

  const [open, setOpen] = useState<boolean>(false);

  const setBlockClose = useContext(BlockCloseDropdownContext);

  useEffect(() => {
    if (!open) {
      props.setPopoverAnchorEl(null);
      setOpenDatetimePopover({ current: null });
    }
    setBlockClose(!!open);
  }, [open]);

  const onChangeType = async (value, close = true) => {
    const id = `id-${moment().toISOString() + '' + (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)}`;
    if (value !== input.type) {
      if (value !== 'selection' && input.type === 'selection' && input[input.type].preset?.presetId) {
        const response = await editPreset(
          issueTemplate._id,
          { inputs: [input._id] },
          input[input.type].preset.presetId,
          null,
        );

        updateIssueTemplateDraftState({
          frame: { ...issueTemplate?.frame, presets: response.frame.presets },
        });
      }
      setInput({
        _id: input._id,
        subType: value === 'datetime' ? 'datetime' : undefined,
        type: value,
        // @ts-ignore
        [value]: {
          values: value === 'table' ? [{ name: 'Column 1', type: 'string', rows: { string: [''] } }] : [],
          options:
            value === 'multiple' || value === 'selection'
              ? [{ _id: id, id: id, label: `${t('option')} 1` }]
              : undefined,
          onResponse: [],
          multiple: value === 'multiple' || value === 'file',
        },
        [input.type]: null,
      });
    }
    if (close) setOpen(false);
  };

  const string = (menuItem: boolean) => (
    <MenuItem
      onClick={() => (menuItem ? onChangeType('string') : null)}
      id={`menuItemText`}
      data-testid={`menuItemText`}
      ref={refs.refText}
      itemID={'text'}
      classes={{ root: classes.menuItem }}
    >
      <TextFields classes={{ root: classes.textFieldIcon }} />
      {t('text')}
    </MenuItem>
  );

  const instructionItem = (menuItem: boolean) => (
    <MenuItem
      onClick={() => (menuItem ? onChangeType('instruction') : null)}
      id={`menuItemInstructions`}
      data-testid={`menuItemInstructions`}
      ref={refs.refInstruction}
      itemID={'instructions'}
      classes={{ root: classes.menuItem }}
    >
      <Info classes={{ root: classes.elementIcon }} />
      {t('instructions')}
    </MenuItem>
  );

  const number = (menuItem: boolean) => (
    <MenuItem
      onClick={() => (menuItem ? onChangeType('number') : null)}
      id={`menuItemNumber`}
      data-testid={`menuItemNumber`}
      ref={refs.refNumber}
      itemID={'number'}
      classes={{ root: classes.menuItem }}
    >
      <LooksOne classes={{ root: classes.oneIcon }} />
      {t('number')}
    </MenuItem>
  );

  const multiple = (menuItem: boolean) => (
    <MenuItem
      onClick={() => (menuItem ? onChangeType('multiple') : null)}
      id={`menuItemMultiple`}
      data-testid={`menuItemMultiple`}
      ref={refs.refMultiple}
      itemID={'multiple'}
      classes={{ root: classes.menuItem }}
    >
      <CheckBox classes={{ root: classes.checkboxOutIcon }} />
      {t('multiple')}
    </MenuItem>
  );

  const selection = (menuItem: boolean) => (
    <MenuItem
      id={`menuItemSelection`}
      data-testid={`menuItemSelection`}
      ref={refs.refSelect}
      itemID={'selection'}
      classes={{ root: classes.menuItem }}
      onClick={() => {
        if (open && isIssueTemplate && instruction) props.setPopoverAnchorEl(refs.refSelect.current);
        if (props.popoverAnchorEl && isIssueTemplate) props.setPopoverAnchorEl(null);
        if (!isIssueTemplate || (!instruction && issueTemplate)) onChangeType('selection');
        if (openDatetimePopover?.current) setOpenDatetimePopover({ current: null });
      }}
    >
      <RadioButtonCheckedOutlined classes={{ root: classes.radioOutIcon }} />
      {t('selection')}
      {menuItem && isIssueTemplate && instruction && (
        <div itemID='ChevronRightOutlinedIcon' style={{ display: 'flex', marginLeft: 'auto', marginRight: 0 }}>
          <ChevronRightOutlined />
        </div>
      )}
    </MenuItem>
  );

  const datetime = (menuItem: boolean) => (
    <MenuItem
      onClick={() => {
        if (open) setOpenDatetimePopover({ current: refs.refDateTime.current });
        if (openDatetimePopover?.current) setOpenDatetimePopover({ current: null });
        if (props.popoverAnchorEl) props.setPopoverAnchorEl(null);
      }}
      id={`menuItemDatetime`}
      data-testid={`menuItemDatetime`}
      ref={refs.refDateTime}
      itemID={'dateTime'}
      classes={{ root: classes.menuItem }}
    >
      <CalendarToday classes={{ root: classes.calendarIcon }} />
      {t('dateTime')}
      {menuItem && (
        <div itemID='ChevronRightOutlinedIcon' style={{ display: 'flex' }}>
          <ChevronRightOutlined style={{ marginLeft: 'auto', marginRight: 0 }} />
        </div>
      )}
    </MenuItem>
  );

  const file = (menuItem: boolean) => (
    <MenuItem
      onClick={() => (menuItem ? onChangeType('file') : null)}
      id={`menuItemFiles`}
      data-testid={`menuItemFiles`}
      ref={refs.refFiles}
      itemID={'files'}
      classes={{ root: classes.menuItem }}
    >
      <AttachFile classes={{ root: classes.fileIcon }} />
      {t('files')}
    </MenuItem>
  );

  const element = (menuItem: boolean) => (
    <MenuItem
      onClick={() => (menuItem ? onChangeType('element') : null)}
      id={`menuItemElement`}
      data-testid={`menuItemElement`}
      ref={refs.refElement}
      itemID={'element'}
      classes={{ root: classes.menuItem }}
    >
      <Category classes={{ root: classes.elementIcon }} />
      {t('element')}
    </MenuItem>
  );

  const site = (menuItem: boolean) => (
    <MenuItem
      onClick={() => (menuItem ? onChangeType('site') : null)}
      id={`menuItemSite`}
      data-testid={`menuItemSite`}
      ref={refs.refSite}
      itemID={'site'}
      classes={{ root: classes.menuItem }}
    >
      <LocationOn classes={{ root: classes.elementIcon }} />
      {t('site')}
    </MenuItem>
  );

  const table = (menuItem: boolean) => (
    <MenuItem
      onClick={() => (menuItem ? onChangeType('table') : null)}
      id={`menuItemTable`}
      data-testid={`menuItemTable`}
      ref={refs.refTable}
      itemID={'table'}
      classes={{ root: classes.menuItem }}
    >
      <TableChart classes={{ root: classes.tableIcon }} />
      {t('table')}
    </MenuItem>
  );

  const signature = (menuItem: boolean) => (
    <MenuItem
      onClick={() => (menuItem ? onChangeType('signature') : null)}
      id={`menuItemSignature`}
      data-testid={`menuItemSignature`}
      ref={refs.refSignature}
      itemID={'signature'}
      classes={{ root: classes.menuItem }}
    >
      <Gesture classes={{ root: classes.signatureIcon }} />
      {t('signature')}
    </MenuItem>
  );

  const qrCode = (menuItem: boolean) => (
    <MenuItem
      onClick={() => (menuItem ? onChangeType('qrCode') : null)}
      id={`menuItemQrCode`}
      data-testid={`menuItemQrCode`}
      ref={refs.refQrCode}
      itemID={'qrCode'}
      classes={{ root: classes.menuItem }}
    >
      <FilterCenterFocus classes={{ root: classes.qrCodeIcon }} />
      {t('scan')}
    </MenuItem>
  );

  const tool = (menuItem: boolean) => (
    <MenuItem
      onClick={() => (menuItem ? onChangeType('tool') : null)}
      id={`menuItemTool`}
      data-testid={`menuItemTool`}
      ref={refs.refTool}
      itemID={'tool'}
      classes={{ root: classes.menuItem }}
    >
      <Construction className={`material-icons ${classes.toolIcon}`} />
      {t('tool')}
    </MenuItem>
  );

  const showSelectedItem = (inp) => {
    switch (inp.type) {
      case 'string':
        return string(false);
      case 'instruction':
        return instructionItem(false);
      case 'number':
        return number(false);
      case 'multiple':
        return multiple(false);
      case 'selection':
        return selection(false);
      case 'datetime':
        return datetime(false);
      case 'file':
        return file(false);
      case 'element':
        return element(false);
      case 'site':
        return site(false);
      case 'table':
        return table(false);
      case 'signature':
        return signature(false);
      case 'qrCode':
        return qrCode(false);
      case 'tool':
        return tool(false);
      default:
        return '';
    }
  };

  return (
    <>
      <div
        ref={fieldRef}
        onClick={() => setOpen(true)}
        className={classes.selectInputRoot}
        id={`selectInputType`}
        data-testid={`selectInputType`}
      >
        {showSelectedItem(input)}
        <KeyboardArrowDown
          id={`selectInputTypeArrow`}
          data-testid={`selectInputTypeArrow`}
          style={{ marginLeft: 'auto', marginRight: 0 }}
        />
      </div>
      {open && (
        <Popover
          open={true}
          anchorEl={fieldRef.current}
          onClose={() => {
            setOpen(false);
            setPopoverAnchorEl(null);
          }}
          ref={props.innerRef}
          classes={{ root: classes.popoverAdvanced }}
        >
          {string(true)}
          {instruction ? instructionItem(true) : undefined}
          {number(true)}
          {multiple(true)}
          {selection(true)}
          {datetime(true)}
          {file(true)}
          {element(true)}
          {site(true)}
          {table(true)}
          {instruction ? signature(true) : undefined}
          {instruction ? qrCode(true) : undefined}
          {instruction && !doingOnBoarding && tool(true)}
          {isIssueTemplate && instruction && (
            <SelectionPresetsPopover
              popoverAnchorEl={props.popoverAnchorEl}
              scoreInputs={props.scoreInputs}
              toHover={true}
              presets={presets}
              setInput={setInput}
              input={input}
              templateId={templateId}
              setOpen={setOpen}
            />
          )}
        </Popover>
      )}
      {!!openDatetimePopover?.current ? (
        <DatetimePopover
          open={!!openDatetimePopover?.current}
          setInput={setInput}
          input={input}
          anchor={openDatetimePopover}
          setOpenDatetimePopover={setOpenDatetimePopover}
          innerRef={props.innerRef}
          onClose={() => {
            setOpenDatetimePopover(null);
            setOpen(false);
          }}
        />
      ) : null}

      {/* </Select > */}
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      editPreset,
    },
    dispatch,
  );

export default compose<any>(connect(mapStateToProps, mapDispatchToProps), withIssueTemplateConnector)(SelectType);
