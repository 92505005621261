import { QueryIssueOrderedWorkPackageId } from './types/agenda.queries.types';
import getStateMachineInstanceFilter from './util/agenda.filter.queries.utils.getStateMachineInstanceFilter';
import { AccountFilterFilter, Element, IssueInstanceWhereInput, Site } from 'src/gql/graphql';
import { findFilterBaseData, findFilterValue } from '../filter/agenda.filter.findFilterValue';
import { buildAndConditions } from '../filter/buildLogicalConditions';
import { map } from 'lodash';
import { getDateToFilterQuery } from 'src/utils/components/glar-filter-popover/helper';

export type CountIssuesWithAdvancedFiltersArgs = {
  accessToSites?: Site[];
  accessToElements?: Element[];
  advancedFilters: AccountFilterFilter[];
  workPackageId?: QueryIssueOrderedWorkPackageId;
  isHistory: boolean;
  catalogId?: string;
};

export const buildAdvancedFilters = (args: CountIssuesWithAdvancedFiltersArgs): IssueInstanceWhereInput => {
  const { advancedFilters, isHistory, catalogId, workPackageId, accessToSites, accessToElements } = args;

  const { sites, elements, rootSites, rootElements, parentSites, parentElements, search } =
    findFilterBaseData(advancedFilters);

  const and = buildAndConditions({
    accessToSites,
    accessToElements,
    elements,
    sites,
    rootElements,
    rootSites,
    parentElements,
    parentSites,
    search,
    isInstance: true,
  });

  const assignedAccountsFilter = map(findFilterValue(advancedFilters, 'assignedAccounts'), '_id');
  const assignedLabelValuesFilter = map(findFilterValue(advancedFilters, 'assignedLabelValues'), '_id');
  const labelValuesFilter = map(findFilterValue(advancedFilters, 'labelValues'), '_id');
  const executedByFilter = map(findFilterValue(advancedFilters, 'executedBy'), '_id');
  const approvedAccountsFilter = map(findFilterValue(advancedFilters, 'approvedAccounts'), '_id');

  const createdAtFilter = advancedFilters?.find((f) => f.name === 'created_at')?.created_at;
  const expectedTimeFilter = advancedFilters?.find((elem: { name: string }) => elem.name === 'expectedTime');
  const frequencyFilter = advancedFilters?.find((elem: { name: string }) => elem.name === 'frequency');

  return {
    needsApproval_eq: isHistory ? undefined : false,
    catalog_eq: catalogId ?? undefined,
    workPackageId_eq: typeof workPackageId === 'string' ? workPackageId : undefined,
    workPackageId_exists: workPackageId === true ? undefined : typeof workPackageId === 'string',
    closedAt_exists: isHistory,
    AND: and.length ? and : undefined,
    createdAt_gte: getDateToFilterQuery(createdAtFilter, 'from'),
    createdAt_lte: getDateToFilterQuery(createdAtFilter, 'to'),
    stateMachineInstance: getStateMachineInstanceFilter(advancedFilters),
    executedBy_in: executedByFilter?.length ? executedByFilter : undefined,
    approval: approvedAccountsFilter?.length ? { approvedBy_in: approvedAccountsFilter } : undefined,
    estimatedDurationUnit_eq: expectedTimeFilter?.expectedTime?.length
      ? (expectedTimeFilter.expectedTime[0] as any)
      : undefined,
    frequency: frequencyFilter?.frequency?.length ? { timeUnit_eq: frequencyFilter.frequency[0] as any } : undefined,
    assignedAccounts_in: assignedAccountsFilter?.length ? assignedAccountsFilter : undefined,
    assignedLabelValues_in: assignedLabelValuesFilter?.length ? assignedLabelValuesFilter : undefined,
    labelValues_in: labelValuesFilter?.length ? labelValuesFilter : undefined,
  };
};
