import { MainContainerState } from 'src/base/main-container/context/main.container.context';

/**
 * This will handle the logic of passing to the next sub step, there are cases where this will be used in other
 * components, whenever a new component needs to be generated, so that the next sub step can appear
 * @param state
 * @param updateMainContainerState
 * @param currentSubStep
 * @param props
 * @param loggedUser
 */
export const handleNextSubStep = async (
  state: MainContainerState,
  updateMainContainerState: (newData: Partial<MainContainerState>) => void,
  currentSubStep: number,
  props: any,
  loggedUser: any,
) => {
  const totalMajorSteps = 3;
  const stepsInMajorStep = loggedUser.onBoarding.maxSubStep + 1;

  let progressTotal: number;

  const stepsToGenerateComponents = ['createTasks', 'execute'];

  if (stepsToGenerateComponents.includes(state.currentStep)) {
    switch (currentSubStep) {
      case 0:
        updateMainContainerState({ generateSubComponent: state.currentStep === 'createTasks' ? 'task' : 'state' });
        break;
      default:
        updateMainContainerState({ generateSubComponent: '' });
    }
  }

  switch (state.currentStep) {
    case 'build':
      progressTotal = ((1 - 1) * stepsInMajorStep + currentSubStep + 1) * (100 / (totalMajorSteps * stepsInMajorStep));
      if (currentSubStep === 1) {
        setTimeout(async () => {
          await props.updateLoggedUser(
            {
              id: loggedUser._id,
              onBoarding: {
                ...loggedUser?.onBoarding,
                templateDraft: loggedUser.onBoarding.templateDraft._id,
                template: null,
                openIssueDraft: null,
                openIssue: null,
                issueInstance: null,
                doingOnboarding: true,
                currentSubStep: currentSubStep + 1,
                progressTotal: Math.floor(progressTotal),
              },
            },
            false,
          );
        }, 500);
      } else {
        await props.updateLoggedUser(
          {
            id: loggedUser._id,
            onBoarding: {
              ...loggedUser?.onBoarding,
              templateDraft: loggedUser.onBoarding.templateDraft._id,
              template: null,
              openIssueDraft: null,
              openIssue: null,
              issueInstance: null,
              doingOnboarding: true,
              currentSubStep: currentSubStep + 1,
              progressTotal: Math.floor(progressTotal),
            },
          },
          false,
        );
      }

      break;
    case 'createIssue':
      progressTotal =
        ((2 - 1) * stepsInMajorStep * 2 + currentSubStep + 1) * (100 / (totalMajorSteps * stepsInMajorStep * 2));
      await props.updateLoggedUser(
        {
          id: loggedUser._id,
          onBoarding: {
            ...loggedUser?.onBoarding,
            templateDraft: null,
            template: loggedUser.onBoarding.template._id,
            openIssueDraft: loggedUser.onBoarding.openIssueDraft._id,
            openIssue: null,
            issueInstance: null,
            currentSubStep: currentSubStep + 1,
            progressTotal: Math.floor(progressTotal),
          },
        },
        false,
      );
      break;
    case 'createTasks':
      progressTotal =
        ((2 - 1) * stepsInMajorStep * 2 + currentSubStep + 1) * (50 / (totalMajorSteps * stepsInMajorStep * 2)) + 33;
      await props.updateLoggedUser(
        {
          id: loggedUser._id,
          onBoarding: {
            ...loggedUser?.onBoarding,
            templateDraft: null,
            template: loggedUser.onBoarding.template._id,
            openIssueDraft: loggedUser.onBoarding.openIssueDraft._id,
            openIssue: null,
            issueInstance: null,
            currentSubStep: currentSubStep + 1,
            progressTotal: Math.floor(progressTotal),
          },
        },
        false,
      );
      break;
    case 'execute':
      progressTotal = ((3 - 1) * stepsInMajorStep + currentSubStep + 1) * (100 / (totalMajorSteps * stepsInMajorStep));
      await props.updateLoggedUser(
        {
          id: loggedUser._id,
          onBoarding: {
            ...loggedUser?.onBoarding,
            templateDraft: null,
            template: loggedUser.onBoarding.template._id,
            openIssueDraft: null,
            openIssue: loggedUser.onBoarding.openIssue._id,
            issueInstance: loggedUser.onBoarding.issueInstance._id,
            currentSubStep: currentSubStep + 1,
            progressTotal: Math.floor(progressTotal),
          },
        },
        false,
      );
      break;
  }

  if (currentSubStep + 1 === loggedUser.onBoarding.maxSubStep) {
    updateMainContainerState({
      startSubSteps: false,
    });
  }
};
