import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';

const DeviceConfigurationJourneyRefresh = () => lazy(() => import('src/modules/device/device.configuration.journey'));

let DeviceConfigurationJourney = DeviceConfigurationJourneyRefresh();

export const render = {
  create: {
    default: ({ ...props }) => {
      if (DeviceConfigurationJourney?._result?.type === 'error') {
        DeviceConfigurationJourney = DeviceConfigurationJourneyRefresh();
      }

      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <DeviceConfigurationJourney {...props} />
        </Suspense>
      );
    },
  },
};

export default render;
