import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    values: {
      // width: 'calc(100% - 75px)',
      display: 'flex',
    },
    val: {
      width: '100%',
      wordWrap: 'break-word',
    },
    valWithHover: {
      wordWrap: 'break-word',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
        textDecorationLine: 'underline',
      },
    },
    valActive: {
      wordWrap: 'break-word',
      color: theme.palette.primary.main,
    },
    font: {
      fontSize: '14px',
      fontStyle: 'unset',
      padding: '5px 5px 5px 10px',
      position: 'relative',
    },
    ph: {
      fontSize: '14px',
      fontStyle: 'italic',
      padding: '5px 5px 5px 10px',
      position: 'relative',
    },
    withBadge: {
      background: 'transparent !important',
      boxShadow: 'none !important',
      color: `${theme.palette.text.primary} !important`,
      transition: 'all 0.3s, height 1ms',
      minHeight: '35px !important',
      border: `1px solid ${theme.palette.text.primary} !important`,
      borderRadius: '5px !important',
      '&:hover': {
        border: `1px solid ${theme.palette.text.primary}`,
        color: theme.palette.text.primary,
        background: theme.palette.common.white,
      },
      '&:focus': {
        color: theme.palette.text.primary,
        background: theme.palette.text.primary,
      },
    },
    disabledWithBadge: {
      boxShadow: 'none !important',
      transition: '0.3s',
      minHeight: '35px !important',
      border: `1px solid ${theme.palette.text.primary} !important`,
      borderRadius: '5px !important',
      color: `${theme.palette.text.secondary} !important`,
    },
    separator: {
      transition: 'all 0.3s',
      height: '100%',
      width: '37px',
      position: 'absolute',
      right: '0',
      top: '0',
      '& > div:first-child': {
        position: 'absolute',
        left: '-29px',
        top: '4px',
      },
      '& > div:nth-child(2)': {
        marginTop: '5px',
        borderLeft: `1px solid ${theme.palette.text.primary}`,
        width: '1px',
        height: '70%',
      },
      '& > div:last-child': {
        width: '12px',
        height: '12px',
        top: '10px',
        left: '13px',
        position: 'absolute',
        border: `2px solid ${theme.palette.text.primary}`,
        '& > div': {
          width: '1px',
          height: '1px',
          border: `1px solid ${theme.palette.text.primary}`,
          marginTop: '3px',
          marginLeft: '3px',
        },
        borderRadius: '20px',
      },
    },
    centerShade: {
      transition: 'all 0.3s',
      borderLeft: `1px solid ${theme.palette.text.primary} !important`,
    },
    leftShade: {
      transition: 'all 0.3s',
      borderLeft: `1px solid ${theme.palette.text.primary} !important`,
      opacity: '0.4',
    },
    rightShade: {
      transition: 'all 0.3s',
      border: `2px solid ${theme.palette.text.primary} !important`,
      opacity: '0.4',
    },
    inputCursorDisabled: {
      cursor: 'not-allowed',
    },
    inputCursorEnabled: {
      cursor: 'pointer',
    },
    withBadgeError: {
      background: 'transparent !important',
      boxShadow: 'none !important',
      color: `${theme.palette.text.primary} !important`,
      transition: 'all 0.3s, height 1ms',
      minHeight: '35px !important',
      border: `1px solid ${theme.palette.error.main} !important`,
      borderRadius: '5px !important',
      '&:hover': {
        border: `1px solid ${theme.palette.error.main}`,
        color: theme.palette.text.primary,
        background: theme.palette.common.white,
      },
      '&:focus': {
        color: theme.palette.text.primary,
        background: theme.palette.text.primary,
      },
    },
    spanValues: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  });
