import { AccountFilterFilter } from 'src/gql/graphql';
import { findFilterValue } from '../../filter/agenda.filter.findFilterValue';

const getStateMachineInstanceFilter = (advancedFilters: AccountFilterFilter[]) => {
  const stateMachineFilters = findFilterValue(advancedFilters, 'state');

  if (!stateMachineFilters) {
    return undefined;
  }

  const isCanceledBySystem = stateMachineFilters?.some((s) => s.toLowerCase().includes('system'));

  const isCanceled = stateMachineFilters?.some((s) => s.toLowerCase() === 'canceled');

  const stateFilters = stateMachineFilters?.filter(
    (s) => s.toLowerCase() !== 'canceled' && !s.toLowerCase().includes('system'),
  );

  const stateMachineInstance = stateFilters?.length
    ? { currentState_in: stateFilters, canceled_ne: !isCanceled }
    : isCanceled
      ? { canceled_eq: isCanceled }
      : { canceledBySystem_eq: isCanceledBySystem };

  return stateMachineInstance;
};

export default getStateMachineInstanceFilter;
