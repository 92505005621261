import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    '@global': {
      '.react-resizable-handle': {
        position: 'static !important',
        cursor: 'nw-resize !important',
      },
    },
    widget: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      flexWrap: 'nowrap',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      borderRadius: '16px 16px 0 16px',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
      margin: 0,
      padding: 4,
      boxSizing: 'border-box',
      backgroundColor: theme.palette.background.default,
      overflow: 'hidden',
    },
    widgetHeader: {
      userSelect: 'none',
      height: 'fit-content',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '2px',
      boxSizing: 'border-box',
      marginRight: '8px',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.default,
      gap: 5,
    },
    cursorMove: {
      cursor: 'move !important',
      '& *': {
        cursor: 'move',
      },
    },
    widgetName: {
      marginLeft: '5px',
      textOverflow: 'ellipsis !important',
      whiteSpace: 'nowrap',
      fontWeight: 'bolder',
      overflow: 'hidden',
      color: theme.palette.text.primary,
      flex: 1,
      fontSize: 16,
    },
    justifyEnd: {
      justifySelf: 'flex-end',
    },
    spinner: {
      marginLeft: '5px',
    },
    widgetConfigure: {
      padding: 0,
      '&,& *': {
        cursor: 'pointer !important',
      },
    },
    widgetDelete: {
      margin: '2px 0 0 5px',
    },
    widgetBody: {
      userSelect: 'none',
      position: 'relative',
      height: 'calc(100% - 30px)',
      margin: 0,
      boxSizing: 'border-box',
      flex: 1,
      padding: '5px',
    },
    icon: {
      color: theme.palette.text.primary,
      fontSize: 20,
      height: '100%',
    },
    itemGap: {
      gap: 8,
    },
    overrideHeight: {
      justifyContent: 'center',
      height: 'unset',
    },
    overrideGridItem: {
      gridTemplateColumns: '100%/3',
    },
    fullScreenPaper: {
      borderRadius: 10,
      padding: '0 24px 20px 24px',
    },
    fullScreenHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    fullScreenContent: {
      width: '800px',
      height: '750px',
      overflow: 'hidden',
    },
    allRoundedCorners: {
      borderRadius: '16px',
    },
  });
