import gql from 'graphql-tag';
import { INPUT } from 'src/utils/fragments/input';
import {
  ACCOUNT_TEMPLATE,
  ACCOUNT,
  ACCOUNT_TEMPLATE_FOLDER,
  ACCOUNT_FOLDER,
  ACCOUNT_TO_TREE,
  ACCOUNT_TEMPLATE_TO_TREE,
  ACCOUNT_LOGIN,
  ACCOUNT_FILTERS,
  ON_BOARDING,
} from 'src/utils/fragments/account';
import { ISSUE_INSTANCE } from 'src/utils/fragments/issue';
import { STATE_MACHINE_INSTANCE } from 'src/utils/fragments/stateMachine';
import { LABEL_VALUE } from 'src/utils/fragments/label';

export const Q_GET_ACCOUNT_FOLDERS = gql`
  query GetAccountFolders($where: AccountFolderWhereInput) {
    accountFolders(where: $where, orderBy: name_ASC) {
      ...accountFolder
    }
  }
  ${ACCOUNT_FOLDER}
`;

export const M_CREATE_ACCOUNT_FOLDER = gql`
  mutation CreateAccountFolder($name: String!, $parent: ID) {
    createAccountFolder(data: { name: $name, parent: $parent }) {
      ...accountFolder
    }
  }
  ${ACCOUNT_FOLDER}
`;

export const Q_GET_ACCOUNT_WITH_DELETED = gql`
  query GetAccountWithDeleted($filter: AccountWhereUniqueInput!) {
    accountWithDeleted(where: $filter) {
      ...account
    }
  }
  ${INPUT}
  ${ACCOUNT_TEMPLATE}
  ${ACCOUNT}
`;

export const M_UPDATE_ACCOUNT_FOLDER = gql`
  mutation UpdateAccountFolder($name: String!, $parent: ID, $_id: ID!) {
    updateAccountFolder(data: { name: $name, parent: $parent }, where: { _id: $_id }) {
      ...accountFolder
    }
  }
  ${ACCOUNT_FOLDER}
`;

export const M_DELETE_ACCOUNT_FOLDER = gql`
  mutation DeleteAccountFolder($where: AccountFolderWhereUniqueInput!) {
    deleteOneAccountFolder(where: $where) {
      ...accountFolder
    }
  }
  ${ACCOUNT_FOLDER}
`;

export const Q_SEARCH_ACCOUNT = gql`
  query SearchAccount($searchString: String) {
    accountFolders(where: { name_contains: $searchString }) {
      ...accountFolder
    }
    accounts(where: { name_contains: $searchString }, orderBy: createdAt_ASC) {
      ...accountToTree
    }
  }
  ${ACCOUNT_TO_TREE}
  ${ACCOUNT_FOLDER}
`;

export const M_CREATE_ACCOUNT_TEMPLATE = gql`
  mutation CreateAccountTemplate($title: String!, $folder: ID!, $inputs: [InputCreateInput!]) {
    createAccountTemplate(data: { title: $title, folder: $folder, frame: { inputs: $inputs } }) {
      ...accountTemplate
    }
  }
  ${INPUT}
  ${ACCOUNT_TEMPLATE}
`;

export const Q_GET_ACCOUNT_TEMPLATE = gql`
  query GetAccountTemplate($filter: AccountTemplateWhereInput) {
    accountTemplates(where: $filter) {
      ...accountTemplate
    }
  }
  ${INPUT}
  ${ACCOUNT_TEMPLATE}
`;

export const Q_GET_ACCOUNT_TEMPLATE_TO_TREE = gql`
  query GetAccountTemplateToTree($filter: AccountTemplateWhereInput) {
    accountTemplates(where: $filter, orderBy: title_ASC) {
      ...accountTemplateToTree
    }
  }
  ${ACCOUNT_TEMPLATE_TO_TREE}
`;

export const Q_GET_ACCOUNTS_AND_FOLDERS = gql`
  query GetAccountsAndFolders(
    $skip: Int
    $limit: Int
    $templateWhere: AccountWhereInput
    $templateFolderWhere: AccountFolderWhereInput
    $orderByTemplate: [AccountOrderByInput!]!
    $orderByTemplateFolder: [AccountFolderOrderByInput!]!
    $tenant: ID
  ) {
    accountsAndFolders(
      skip: $skip
      limit: $limit
      templateWhere: $templateWhere
      templateFolderWhere: $templateFolderWhere
      templateOrderBy: $orderByTemplate
      templateFolderOrderBy: $orderByTemplateFolder
      tenant: $tenant
    ) {
      ... on Account {
        ...accountToTree
      }
      # ... on AccountFolder {
      #   ...accountFolder
      # }
    }
  }
  ${ACCOUNT_TO_TREE}
  # ${ACCOUNT_FOLDER}
`;

export const Q_GET_ACCOUNT_TEMPLATES_AND_FOLDERS = gql`
  query GetAccountTemplatesAndFolders(
    $skip: Int
    $limit: Int
    $templateWhere: AccountTemplateWhereInput
    $templateFolderWhere: AccountTemplateFolderWhereInput
    $orderByTemplate: [AccountTemplateOrderByInput!]!
    $orderByTemplateFolder: [AccountTemplateFolderOrderByInput!]!
    $tenant: ID
  ) {
    accountTemplatesAndFolders(
      skip: $skip
      limit: $limit
      templateWhere: $templateWhere
      templateFolderWhere: $templateFolderWhere
      templateOrderBy: $orderByTemplate
      templateFolderOrderBy: $orderByTemplateFolder
      tenant: $tenant
    ) {
      ... on AccountTemplate {
        ...accountTemplateToTree
      }
      ... on AccountTemplateFolder {
        ...accountTemplateFolder
      }
    }
  }
  ${ACCOUNT_TEMPLATE_TO_TREE}
  ${ACCOUNT_TEMPLATE_FOLDER}
`;

export const Q_GET_ACCOUNTS_BY_LABELS = gql`
  query GetAccountsByLabels($labels: [ID]!) {
    accounts(where: { labelValues_in: $labels }) {
      _id
      name
      username
      photo
      labelValuesIds
      labelValues {
        ...labelValue
      }
    }
  }
  ${LABEL_VALUE}
`;

export const Q_GET_ACCOUNTS_LIGHT = gql`
  query GetAccountsLight($filter: AccountWhereInput, $tenant: ID) {
    accounts(where: $filter, tenant: $tenant) {
      _id
      name
      photo
      username
      email
      labelValuesIds
      labelValues {
        ...labelValue
      }
    }
  }
  ${LABEL_VALUE}
`;

gql`
  query GetAccountsByTenant(
    $filter: AccountWhereInput!
    $limit: Int
    $skip: Int
    $orderBy: [AccountOrderByInput]
    $tenant: ID
  ) {
    accounts(where: $filter, limit: $limit, skip: $skip, orderBy: $orderBy, tenant: $tenant) {
      _id
      name
      photo
      username
      email
      createdAt
      isActive
      isPlatformAdmin
    }
    accountsCount(where: $filter, tenant: $tenant)
  }
`;

export const M_UPDATE_ACCOUNT_TEMPLATE = gql`
  mutation UpdateAccountTemplate($data: AccountTemplateUpdateInput!, $where: AccountTemplateWhereUniqueInput!) {
    updateAccountTemplate(data: $data, where: $where) {
      ...accountTemplate
    }
  }
  ${INPUT}
  ${ACCOUNT_TEMPLATE}
`;

export const M_DELETE_ACCOUNT_TEMPLATE = gql`
  mutation DeleteAccountTemplate($id: ID!) {
    deleteOneAccountTemplate(where: { _id: $id }) {
      ...accountTemplate
    }
  }
  ${INPUT}
  ${ACCOUNT_TEMPLATE}
`;

export const Q_GET_ACCOUNT_TEMPLATE_FOLDERS = gql`
  query GetAccountTemplateFolders($where: AccountTemplateFolderWhereInput) {
    accountTemplateFolders(where: $where, orderBy: name_ASC) {
      ...accountTemplateFolder
    }
  }
  ${ACCOUNT_TEMPLATE_FOLDER}
`;

export const M_CREATE_ACCOUNT_TEMPLATE_FOLDER = gql`
  mutation CreateAccountTemplateFolder($name: String!, $parent: ID) {
    createAccountTemplateFolder(data: { name: $name, parent: $parent }) {
      ...accountTemplateFolder
    }
  }
  ${ACCOUNT_TEMPLATE_FOLDER}
`;

export const M_UPDATE_ACCOUNT_TEMPLATE_FOLDER = gql`
  mutation UpdateAccountTemplateFolder($name: String!, $parent: ID, $_id: ID!) {
    updateAccountTemplateFolder(data: { name: $name, parent: $parent }, where: { _id: $_id }) {
      ...accountTemplateFolder
    }
  }
  ${ACCOUNT_TEMPLATE_FOLDER}
`;

export const M_DELETE_ACCOUNT_TEMPLATE_FOLDER = gql`
  mutation DeleteAccountTemplateFolder($where: AccountTemplateFolderWhereUniqueInput!) {
    deleteOneAccountTemplateFolder(where: $where) {
      ...accountTemplateFolder
    }
  }
  ${ACCOUNT_TEMPLATE_FOLDER}
`;

export const Q_SEARCH = gql`
  query Search($searchString: String) {
    accountTemplateFolders(where: { name_contains: $searchString }) {
      ...accountTemplateFolder
    }
    accountTemplates(where: { folder: { name_contains: $searchString } }, orderBy: createdAt_ASC) {
      ...accountTemplateToTree
    }
  }
  ${ACCOUNT_TEMPLATE_TO_TREE}
  ${ACCOUNT_TEMPLATE_FOLDER}
`;

export const Q_GET_ACCOUNTS_TO_TREE = gql`
  query GetAccountsToTree($filter: AccountWhereInput!) {
    accounts(where: $filter, orderBy: name_ASC) {
      ...accountToTree
    }
  }
  ${ACCOUNT_TO_TREE}
`;

export const Q_GET_ACCOUNTS = gql`
  query GetAccounts(
    $skip: Int
    $limit: Int
    $filter: AccountWhereInput!
    $orderBy: [AccountOrderByInput]
    $tenant: ID
  ) {
    accounts(skip: $skip, limit: $limit, where: $filter, orderBy: $orderBy, tenant: $tenant) {
      ...account
    }
  }
  ${INPUT}
  ${ACCOUNT_TEMPLATE}
  ${ACCOUNT}
`;

export const Q_GET_ACCOUNTS_TO_ASSIGN = gql`
  query GetAccountsToAssign($skip: Int, $limit: Int, $filter: AccountWhereInput!, $tenant: ID) {
    accounts(skip: $skip, limit: $limit, where: $filter, orderBy: name_ASC, tenant: $tenant) {
      _id
      __typename
      name
      username
      photo
      email
      labelValuesIds
      labelValues {
        ...labelValue
      }
    }
  }
  ${LABEL_VALUE}
`;

export const Q_GET_ACCOUNTS_COUNT = gql`
  query GetAccountsCount($filter: AccountWhereInput!) {
    accountsCount(where: $filter)
  }
`;

gql`
  query GetMe {
    me {
      ...account
      impersonatedById
    }
  }
`;

export const Q_GET_ACCOUNT_UNREAD_MESSAGES = gql`
  query GetAccountUnreadMessages {
    allUnreadMessages
  }
`;

export const M_CREATE_ACCOUNT = gql`
  mutation CreateAccount(
    $email: String!
    $username: String
    $name: String
    $password: String!
    $inputs: [InputCreateInput!]
    $authorizedSites: [ID!]
    $template: ID
    $folder: ID
    $labelValues: [ID]
    $roles: [ID]
  ) {
    createAccount(
      data: {
        email: $email
        username: $username
        name: $name
        password: $password
        inputs: $inputs
        folder: $folder
        authorizedSites: $authorizedSites
        template: $template
        labelValues: $labelValues
        roles: $roles
      }
    ) {
      ...account
    }
  }
  ${INPUT}
  ${ACCOUNT_TEMPLATE}
  ${ACCOUNT}
`;

export const M_UPDATE_LOGGED_ACCOUNT = gql`
  mutation UpdateLoggedAccount(
    $email: String
    $username: String
    $name: String
    $password: String
    $newPassword: String
    $id: ID!
    $inputs: [InputUpdateInput!]
    $authorizedSites: [ID!]
    $template: ID
    $folder: ID
    $labelValues: [ID]
    $roles: [ID]
    $preferences: AccountPreferencesUpdateInput
    $onBoarding: AccountOnBoardingUpdateInput
    $firstLogin: Boolean
  ) {
    updateAccount(
      data: {
        email: $email
        username: $username
        name: $name
        inputs: $inputs
        folder: $folder
        authorizedSites: $authorizedSites
        template: $template
        password: $password
        newPassword: $newPassword
        preferences: $preferences
        labelValues: $labelValues
        roles: $roles
        onBoarding: $onBoarding
        firstLogin: $firstLogin
      }
      where: { _id: $id }
    ) {
      ...accountLogin
    }
  }
  ${INPUT}
  ${ACCOUNT_TEMPLATE}
  ${ACCOUNT_LOGIN}
  ${ON_BOARDING}
  ${ISSUE_INSTANCE}
  ${STATE_MACHINE_INSTANCE}
`;

gql`
  mutation UpdateAccount(
    $email: String
    $username: String
    $name: String
    $password: String
    $newPassword: String
    $id: ID!
    $inputs: [InputUpdateInput!]
    $authorizedSites: [ID!]
    $template: ID
    $folder: ID
    $preferences: AccountPreferencesUpdateInput
    $labelValues: [ID]
    $roles: [ID]
    $onBoarding: AccountOnBoardingUpdateInput
    $tenant: ID
  ) {
    updateAccount(
      data: {
        email: $email
        username: $username
        name: $name
        password: $password
        newPassword: $newPassword
        inputs: $inputs
        folder: $folder
        authorizedSites: $authorizedSites
        template: $template
        preferences: $preferences
        labelValues: $labelValues
        roles: $roles
        onBoarding: $onBoarding
      }
      where: { _id: $id }
      tenant: $tenant
    ) {
      ...account
    }
  }
`;

export const M_DELETE_ACCOUNT = gql`
  mutation DeleteAccount($id: ID!) {
    deleteOneAccount(where: { _id: $id }) {
      ...account
    }
  }
  ${INPUT}
  ${ACCOUNT_TEMPLATE}
  ${ACCOUNT}
`;

export const M_DELETE_MANY_ACCOUNTS = gql`
  mutation DeleteManyAccounts($_ids: [ID!]!, $tenant: ID) {
    deleteManyAccounts(_ids: $_ids, tenant: $tenant) {
      _id
    }
  }
`;

export const M_ACTIVATE_ACCOUNT = gql`
  mutation ActivateAccount($_id: ID!) {
    activateAccount(_id: $_id) {
      _id
    }
  }
`;

export const M_ACTIVATE_MANY_ACCOUNTS = gql`
  mutation ActivateManyAccounts($_ids: [ID!]!, $tenant: ID) {
    activateManyAccounts(_ids: $_ids, tenant: $tenant) {
      _id
    }
  }
`;

export const M_DEACTIVATE_ACCOUNT = gql`
  mutation DeactivateAccount($_id: ID!) {
    deactivateAccount(_id: $_id) {
      _id
    }
  }
`;

export const M_DEACTIVATE_MANY_ACCOUNTS = gql`
  mutation DeactivateManyAccounts($_ids: [ID!]!, $tenant: ID) {
    deactivateManyAccounts(_ids: $_ids, tenant: $tenant) {
      _id
    }
  }
`;

export const M_SET_PREFERENCES = gql`
  mutation SetPreferences($data: AccountPreferencesCreateInput!) {
    setPreferences(data: $data) {
      ...accountLogin
    }
  }
  ${INPUT}
  ${ACCOUNT_TEMPLATE}
  ${ACCOUNT_LOGIN}
  ${ON_BOARDING}
  ${ISSUE_INSTANCE}
  ${STATE_MACHINE_INSTANCE}
`;

export const M_CHANGE_PASSWORD = gql`
  mutation ChangePassword($password: String!, $newPassword: String!) {
    changePassword(password: $password, newPassword: $newPassword)
  }
`;

export const M_CREATE_ACCOUNT_FILTER = gql`
  mutation CreateAccountFilter($name: String!, $columns: [String!]!, $labelValues: [ID!]!, $context: String) {
    accountCreateFilter(name: $name, columns: $columns, labelValues: $labelValues, context: $context) {
      filters {
        ...accountFilterFragment
      }
    }
  }
  ${ACCOUNT_FILTERS}
`;

export const M_UPDATE_ACCOUNT_FILTER = gql`
  mutation UpdateAccountFilter(
    $_id: ID!
    $name: String
    $columns: [String]
    $labelValues: [ID]
    $filters: [AccountFilterFilterUpdateInput]
    $order: String
  ) {
    accountUpdateFilter(
      data: { name: $name, columns: $columns, labelValues: $labelValues, filters: $filters, order: $order }
      _id: $_id
    ) {
      filters {
        ...accountFilterFragment
      }
    }
  }
  ${ACCOUNT_FILTERS}
`;

export const M_DELETE_ACCOUNT_FILTER = gql`
  mutation DeleteAccountFilter($filterId: ID!) {
    accountDeleteFilter(_id: $filterId) {
      filters {
        ...accountFilterFragment
      }
    }
  }
  ${ACCOUNT_FILTERS}
`;

export const M_SET_GPS_LOCATION = gql`
  mutation SetGpsLocation($coordinates: [Float!]!) {
    setGpsLocation(coordinates: $coordinates) {
      ...accountLogin
    }
  }

  ${INPUT}
  ${ACCOUNT_TEMPLATE}
  ${ACCOUNT_LOGIN}
  ${ON_BOARDING}
  ${ISSUE_INSTANCE}
  ${STATE_MACHINE_INSTANCE}
`;

export const M_DUPLICATE_ACCOUNT_TEMPLATE = gql`
  mutation DuplicateAccountTemplate($_id: ID!, $copyChildren: Boolean!) {
    duplicateAccountTemplate(_id: $_id, copyChildren: $copyChildren) {
      ...accountTemplate
    }
  }
  ${INPUT}
  ${ACCOUNT_TEMPLATE}
`;

export const M_MARK_SEEN_ACTION_ISSUE = gql`
  mutation MarkSeenActionIssue($docId: ID!) {
    markSeen(docId: $docId) {
      ...accountLogin
    }
  }
  ${INPUT}
  ${ACCOUNT_TEMPLATE}
  ${ACCOUNT_LOGIN}
  ${ON_BOARDING}
  ${ISSUE_INSTANCE}
  ${STATE_MACHINE_INSTANCE}
`;

export const M_MARK_ALL_SEEN_ACTION_ISSUE = gql`
  mutation MarkAllSeenActionIssue($docIds: [ID!]!, $markType: MarkAllReadTypeEnum!) {
    markAllSeen(docIds: $docIds, markType: $markType) {
      ...accountLogin
    }
  }
  ${INPUT}
  ${ACCOUNT_TEMPLATE}
  ${ACCOUNT_LOGIN}
  ${ON_BOARDING}
  ${ISSUE_INSTANCE}
  ${STATE_MACHINE_INSTANCE}
`;

export const M_UPDATE_MANY_ACCOUNT_TEMPLATES = gql`
  mutation UpdateManyAccountTemplates($where: AccountTemplateWhereInput!, $update: AccountTemplateUpdateInput!) {
    updateManyAccountTemplates(where: $where, update: $update) {
      _id
      title
    }
  }
`;

export const M_UPDATE_TUTORIAL = gql`
  mutation UpdateTutorial($accountId: ID!, $tutorial: Boolean!) {
    updateTutorial(accountId: $accountId, tutorial: $tutorial) {
      _id
    }
  }
`;

gql`
  query Tabs($where: TabWhereInput!) {
    tabs(where: $where) {
      ...tab
    }
  }

  mutation CreateTab($data: TabCreateInput!) {
    createTab(data: $data) {
      ...tab
    }
  }

  mutation UpdateTab($where: TabWhereUniqueInput!, $data: TabUpdateInput!) {
    updateTab(where: $where, data: $data) {
      ...tab
    }
  }

  subscription SubscribeLastActiveDates($accountIds: [ID!]!) {
    subscribeAccountActivity(accountIds: $accountIds, ignoreSender: false) {
      _id
      lastActiveDate
    }
  }

  query AccountsLastActivity($accountIds: [ID!]!) {
    accountsLastActivity(accountIds: $accountIds) {
      _id
      lastActiveDate
    }
  }
`;
