import { Delete } from '@mui/icons-material';
import { Chip, Popover, Typography } from '@mui/material';
import { makeStyles, withStyles, WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import DefaultButton from 'src/utils/components/default-button/default-button';
import { FilterDataType } from '../..';
import SelectFilterCondition from '../select-filter-condition';
import SelectFilterType from '../select-filter-type';
import SelectFilterValue from '../select-filter-value';
import { styles } from './styles';

type PieFilterBuilderProps = {
  open: boolean;
  popoverAnchorEl: HTMLParagraphElement;
  context: string;
  filterData: FilterDataType[];
  missingFilterData?: boolean;
  widgetWrapper: boolean;
  closePopover: (val: boolean) => void;
  setFilterData: (data: FilterDataType[]) => void;
  onSave?: (updatedFilters?: FilterDataType[]) => void;
} & WithStyles<typeof styles> &
  WithTranslation;

const useStyles = makeStyles(styles);
const PieFilterBuilder: React.FC<PieFilterBuilderProps> = (props): JSX.Element => {
  const {
    t,
    open,
    popoverAnchorEl,
    filterData,
    context,
    missingFilterData,
    widgetWrapper,
    closePopover,
    setFilterData,
    onSave,
  } = props;
  const classes = useStyles();

  const handleAddNewFilter = () => {
    const oldData = [...filterData];
    setFilterData([...oldData, { context, type: null, condition: null, values: null, dateValue: null }]);
  };

  const handleClearAll = () => {
    setFilterData([{ context: null, type: null, condition: null, values: null, dateValue: null }]);
    if (widgetWrapper) {
      onSave([{ context: null, type: null, condition: null, values: null, dateValue: null }]);
      closePopover(false);
    }
  };

  const handleApply = () => {
    if (widgetWrapper) {
      onSave();
    }
    closePopover(false);
  };

  const handleClose = () => {
    closePopover(false);
  };

  return (
    <Popover
      open={open}
      anchorEl={popoverAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      classes={{
        paper: classes.popoverContent,
      }}
      onClose={handleClose}
    >
      <div className={classes.filterBuilderHeader}>
        <Typography className={classes.filterBuilderTitle}>{t('cardFilters')}</Typography>
        <div className={classes.filterBuilderActionsContainer}>
          <DefaultButton
            data-testid={'clear-all-filters'}
            variant={'outlined'}
            label={t('clearAll')}
            onClick={() => handleClearAll()}
          />
          <DefaultButton
            disabled={missingFilterData}
            data-testid={'add-filters'}
            label={t('apply')}
            onClick={() => handleApply()}
          />
        </div>
      </div>
      <div className={classes.filterBuilderContent}>
        {filterData.map((_data, index) => (
          <div key={index} className={classes.selectContentContainer}>
            <SelectFilterType context={context} index={index} setFilterData={setFilterData} filterData={filterData} />
            <SelectFilterCondition index={index} setFilterData={setFilterData} filterData={filterData} />
            <SelectFilterValue context={context} index={index} setFilterData={setFilterData} filterData={filterData} />
            <Delete
              className={filterData.length === 1 ? classes.deleteIconDisabled : classes.deleteIcon}
              onClick={() => {
                const filterToDelete = filterData.filter((_data, i) => index !== i);
                if (filterData.length > 1) setFilterData(filterToDelete);
              }}
            />
          </div>
        ))}
        <Chip
          className={classes.andChip}
          variant={'filled'}
          classes={{ label: classes.chipLabel }}
          label={t('And')}
          onClick={() => handleAddNewFilter()}
        />
      </div>
    </Popover>
  );
};

export default withTranslation('translation')(withStyles(styles)(PieFilterBuilder));
