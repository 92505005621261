import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    '@global': {
      '.MuiInput-underline.Mui-disabled:before': {
        borderBottomStyle: 'unset',
      },

      '.Dropdown__root___3ALmx select': {
        color: 'black',
        background: theme.palette.mode === 'light' ? '' : 'white',
        '-webkit-appearance': theme.palette.mode === 'light' ? '' : 'progress-bar',
        'border-right-width': theme.palette.mode === 'light' ? '' : 10,
      },
    },
    iconAlertChip: {
      color: `${theme.palette.warning.main} !important`,
      fontSize: '20px',
      /* transform: 'rotate(90deg)', */
    },
    iconAlertChipRotated: {
      color: theme.palette.primary.main,
      fontSize: '20px',
      transform: 'rotate(90deg)',
    },
    iconFinishIssueChip: {
      color: theme.palette.primary.main,
      fontSize: '20px',
      transform: 'rotate(90deg)',
    },
    divInput: {
      display: 'inline-flex',
      width: '100%',
      marginTop: '6px',
      borderRadius: '7px',
      marginBottom: '6px',
      '&:hover': {
        '& >svg': {
          display: 'block !important',
        },
      },
    },
    divInsideDivInput: {
      border: `1px solid ${theme.palette.text.disabled}`,
      boxShadow: `0px 0px 0px 0.8px ${theme.palette.grey.light}`,
      backgroundColor: theme.palette.background.default,
      borderRadius: '7px',
      width: 'calc(100% - 39px)',
      marginRight: 0,
      marginLeft: 'auto',
      padding: '20px',
    },
    divInputName: {
      alignSelf: 'center',
      width: '100%',
      display: 'flex',
    },
    textField: {
      fontSize: '14px',
      width: '100%',
    },
    selectInputRoot: {
      display: 'flex',
      border: `1px solid ${theme.palette.grey.light}`,
      alignItems: 'center',
      width: '156px',
      gap: '4px',
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeigth: '400',
      lineHeight: '22px',
      borderRadius: '4px',
      cursor: 'pointer',
      height: '40px',
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
      '&>li': {
        height: '33px',
        borderRadius: '4px',
        '&:hover': {
          backgroundColor: `${theme.palette.background.default} !important`,
          color: `${theme.palette.text.primary} !important`,
          fontWeight: 400,
        },
      },
    },
    selectInputInput: {
      borderRadius: '4px !important',
      position: 'relative',
      border: `1px solid ${theme.palette.text.secondary}`,
      color: theme.palette.text.secondary,
      fontSize: '14px',
      height: '20px',
      width: '165px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Poppins',
      fontWeight: 400,
    },
    inputContainer: {
      gap: '8px',
      marginTop: '10px',
      display: 'flex',
    },
    textFieldIcon: {
      color: theme.palette.primary.main,
      marginRight: '4px',
    },
    oneIcon: {
      color: 'rgba(247, 104, 0, 0.54)',
      marginRight: '4px',
    },
    calendarIcon: {
      color: '#00AF5B',
      marginRight: '4px',
    },
    fileIcon: {
      color: theme.palette.error.main,
      marginRight: '4px',
    },
    elementIcon: {
      color: theme.palette.text.primary,
      marginRight: '4px',
    },
    tableIcon: {
      color: '#7C18FB',
      marginRight: '4px',
    },
    signatureIcon: {
      color: theme.palette.primary.main,
      marginRight: '4px',
    },
    qrCodeIcon: {
      color: '#FFD600',
      marginRight: '4px',
    },
    toolIcon: {
      color: theme.palette.error.main,
      marginRight: '4px',
    },
    menuItem: {
      height: '35px',
      fontSize: '14px',
      padding: '8px 4px 8px 8px',
      fontFamily: 'Poppins',
      fontWeight: 400,
      '&:hover': {
        backgroundColor: `${theme.palette.background.background1} !important`,
        color: `${theme.palette.primary.main} !important`,
        fontWeight: 600,
      },
    },
    divRequired: {
      display: 'flex',
      marginLeft: 'auto',
    },
    iconButtonSettings: {
      border: `1px solid ${theme.palette.text.disabled}`,
      marginLeft: '15px',
      padding: '3px',
      '&:hover': {
        backgroundColor: `${theme.palette.background.background1} !important`,
      },
      '&:hover > span > svg': {
        color: `${theme.palette.primary.main} !important`,
      },
    },
    iconButtonSettingsSelection: {
      border: `1px solid ${theme.palette.text.disabled}`,
      marginLeft: '15px',
      padding: '3px',
      '&:hover': {
        backgroundColor: `${theme.palette.background.background1} !important`,
      },
      '&:hover > span > svg': {
        color: `${theme.palette.primary.main} !important`,
      },
    },
    iconButtonMore: {
      padding: '3px',
      '&:hover': {
        backgroundColor: `${theme.palette.background.background1} !important`,
      },
    },
    iconSettingsAndMore: {
      color: theme.palette.text.primary,
      fontSize: '21px',
    },
    menuItemDelete: {
      color: theme.palette.error.main,
      width: '177px',
      height: '35px',
      '&:hover': {
        backgroundColor: `${theme.palette.background.background1} !important`,
      },
    },
    advancedChip: {
      backgroundColor: `${theme.palette.background.background1} !important`,
      color: `${theme.palette.primary.main} !important`,
      fontFamily: 'Roboto',
      fontWeight: 400,
      minHeight: '34px',
      height: 'auto',
      paddingTop: '5px',
      paddingBottom: '5px',
    },
    advancedChipCustom: {
      backgroundColor: `${theme.palette.background.background1} !important`,
      color: `${theme.palette.primary.main} !important`,
      fontFamily: 'Roboto',
      fontWeight: 400,
      minHeight: '34px',
      height: 'auto',
      paddingTop: '5px',
      paddingBottom: '5px',
      alignItems: 'flex-start',
    },
    repeatTaskChip: {
      backgroundColor: 'rgba(0, 120, 254, 0.12) !important',
      color: `${theme.palette.primary.main} !important`,
      fontFamily: 'Roboto',
      fontWeight: 400,
    },
    advancedAlertChip: {
      marginTop: '15px',
      backgroundColor: `${theme.palette.background.background1} !important`,
      color: `#FE8947 !important`,
      fontFamily: 'Roboto',
      fontWeight: 400,
      minHeight: '34px',
      height: 'auto',
      paddingTop: '5px',
      paddingBottom: '5px',
    },
    alertChipExecution: {
      backgroundColor: `${theme.palette.background.background1} !important`,
      color: `${theme.palette.primary.main} !important`,
      fontFamily: 'Roboto',
      fontWeight: 400,
      minHeight: '34px',
      height: 'auto',
      paddingTop: '5px',
      paddingBottom: '5px',
      margin: '5px',
    },

    advancedChipError: {
      backgroundColor: `${theme.palette.background.background1} !important`,
      color: `${theme.palette.primary.main} !important`,
      fontFamily: 'Roboto',
      fontWeight: 400,
      border: `1px solid ${theme.palette.error.main}`,
    },
    iconChip: {
      color: `${theme.palette.primary.main} !important`,
      fontSize: '21px',
    },
    iconChipAddCircle: {
      color: theme.palette.success.main,
      fontSize: '21px',
    },
    /* iconAlertChip: {
      color: '#FE8947',
      fontSize: '20px',
    }, */
    /* popoverAdvanced: {
      marginTop: '33px',
    }, */
    menuItemAdvanced: {
      minWidth: '177px',
      height: '35px',
      '&:hover': {
        backgroundColor: `${theme.palette.primary.light} !important`,
      },
    },
    chevronRight: {
      marginRight: 0,
      marginLeft: 'auto',
    },
    checkboxOutIcon: {
      color: theme.palette.primary.main,
      marginRight: '4px',
    },
    radioOutIcon: {
      color: theme.palette.success.main,
      marginRight: '4px',
    },
    inputNameDisable: {
      color: theme.palette.text.primary,
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '36px',
      marginLeft: '5px',
    },
    inputNameDisableEmpty: {
      color: theme.palette.text.secondary,
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: '14px',
      // fontStyle: 'italic',
      marginLeft: '5px',
    },
    requiredDisable: {
      color: theme.palette.error.main,
    },
    warningIcon: {
      fill: theme.palette.error.main,
      marginLeft: '10px',
      fontSize: '20px',
    },
    checkIcon: {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '16px',
    },
    menuItemWithCheck: {
      color: theme.palette.primary.main,
      fontSize: '14px',
      fontFamily: 'Open Sans',
      marginLeft: '10px',
      cursor: 'pointer',
    },
    menuItemWithoutCheck: {
      marginLeft: '10px',
      fontSize: '14px',
      fontFamily: 'Open Sans',
      cursor: 'pointer',
    },
    inputValidations: {
      fontSize: '14px',
      fontFamily: 'Open Sans',
      minHeight: '25px',
      fontWeight: 400,
      padding: 'unset',
      WebkitTextFillColor: `${theme.palette.text.primary} !important`,
    },
    textFieldAmountAlert: {
      fontSize: '14px',
      fontFamily: 'Open Sans',
      fotWeight: 400,
      maxWidth: '45px',
      padding: '2px 0 0px',
      color: `${theme.palette.text.primary} !important`,
    },
    selectConditional: {
      fontSize: '14px',
      fontFamily: 'Open Sans',
      fotWeight: 400,
      padding: 'unset',
      display: 'flex',
      color: `${theme.palette.text.primary} !important`,
    },
    closeIcon: {
      color: theme.palette.common.black,
      fontSize: '16px',
      cursor: 'pointer',
    },
    divLabel: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: '5px',
      color: theme.palette.primary.main,
    },
    divSubLabel: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: '5px',
    },
    divAnwersOf: {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      color: theme.palette.text.secondary,
    },
    timelineItem: {
      '&:before': {
        display: 'none',
      },
    },
    iconAdd: {
      color: theme.palette.background.default,
    },
    buttonAddInput: {
      marginLeft: 'auto',
      marginRight: 'auto',
      alignSelf: 'center',
      backgroundColor: `${theme.palette.success.main} !important`,
      boxShadow:
        '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
    },
    divDivider: {
      height: '4px',
      backgroundColor: theme.palette.text.disabled,
      display: 'flex',
      opacity: 0,
      '&:hover': {
        opacity: 1,
      },
    },
    spanValueAction: {
      borderRadius: '5px',
      marginLeft: '5px',
      marginRight: '5px',
      paddingLeft: '5px',
      paddingRight: '5px',
    },
    spanCreateAction: {
      backgroundColor: 'rgba(254, 137, 71, 0.13)',
      color: '#FE8947',
      marginLeft: '5px',
      marginRight: '5px',
      paddingLeft: '5px',
      paddingRight: '5px',
      borderRadius: '5px',
      display: 'flex',
      alignItems: 'center',
    },
    feedbackIcon: {
      color: '#FE8947',
      marginRight: '5px',
      fontSize: '16px',
    },
    finishIssueIcon: {
      color: theme.palette.error.main,
      marginRight: '5px',
      fontSize: '16px',
    },
    failResponseIcon: {
      color: theme.palette.error.main,
      marginRight: '5px',
      fontSize: '16px',
    },
    createTasksIcon: {
      marginRight: '5px',
      fontSize: '16px',
    },
    alertIcon: {
      color: theme.palette.error.main,
      marginRight: '5px',
      fontSize: '16px',
    },
    liveHelpIcon: {
      color: '#1868FB',
      marginRight: '5px',
      fontSize: '16px',
    },
    numberChipCondContainer: {
      display: 'inline-block',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '10px',
      marginLeft: '10px',
      width: 'fit-content',
    },
    alertChipExecContainer: {
      display: 'inline-block',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '10px',
      width: 'fit-content',
    },
    cancelIssueContainer: {
      display: 'inline-block',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '10px',
      width: 'fit-content',
    },
    finishIssueChipContainer: {
      display: 'inline-block',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '10px',
      width: 'fit-content',
    },
    dialogContent: {
      width: '465px',
    },
    divTextTitle: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      textAlign: 'center',
      marginLeft: '40px',
      marginRight: '40px',
      marginTop: '15px',
      fontSize: '17px',
      color: theme.palette.text.primary,
    },
    divText: {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      textAlign: 'center',
      marginLeft: '40px',
      marginRight: '40px',
      marginTop: '15px',
      color: theme.palette.text.secondary,
    },
    divBtnsModal: {
      display: 'flex',
      marginBottom: '10px',
      justifyContent: 'center',
      marginTop: '20px',
    },
    firstSpanAlert: {
      backgroundColor: 'rgba(39, 40, 72, 0.13)',
      /* height: "22px", */
      color: theme.palette.text.primary,
      marginLeft: '5px',
      marginRight: '5px',
      borderRadius: '5px',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '5px',
      paddingRight: '5px',
    },
    secondSpanAlert: {
      color: theme.palette.error.main,
      paddingLeft: '5px',
      paddingRight: '5px',
      marginLeft: '5px',
      marginRight: '5px',
      borderRadius: '5px',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'rgba(244, 67, 54, 0.13)',
    },
    selectText: {
      color: theme.palette.primary.main,
      fontFamily: 'Roboto',
      marginBottom: 0,
    },
    selectOptions: {
      fontFamily: 'Roboto',
      fontSize: '12px',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.main,
      '& .MuiSelect-icon': {
        color: theme.palette.primary.main,
      },
    },
    inputIcon: {
      position: 'relative',
      top: '3px',

      '&.MuiSvgIcon-root': {
        fontSize: '17px',
        marginRight: '6px',
      },
    },
    addButton: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 500,
      fontStyle: 'normal',
      display: 'flex',
      alignItems: 'center',
      margin: '10px 0px 10px 0px',
      color: theme.palette.primary.main,
      lineHeight: '20px',
      cursor: 'pointer',
    },
    removeOutlinedIcon: {
      color: '#F44336',

      '&.MuiSvgIcon-root': {
        fontSize: '17px',
      },
    },
    finishIssueOptionSelected: {
      height: '20px !important',
      marginRight: 3,
      marginLeft: 3,
      backgroundColor: 'rgba(244, 67, 54, 0.13)',
      color: '#F44336',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '24px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      letterSpacing: '0.4px',
    },
    finishIssueChip: {
      backgroundColor: `${theme.palette.background.background1} !important`,
      color: '#F44336',
      fontFamily: 'Roboto',
      fontWeight: 400,
      minHeight: '34px',
      height: 'auto',
      paddingTop: '5px',
      paddingBottom: '5px',
      margin: '5px',
    },
    failResponseOptionSelected: {
      height: '20px !important',
      marginRight: 3,
      marginLeft: 3,
      backgroundColor: 'rgba(244, 67, 54, 0.13)',
      color: '#F44336',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '24px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      letterSpacing: '0.4px',
    },
    failResponseChip: {
      backgroundColor: `${theme.palette.background.background1} !important`,
      color: '#F44336',
      fontFamily: 'Roboto',
      fontWeight: 400,
      minHeight: '34px',
      height: 'auto',
      paddingTop: '5px',
      paddingBottom: '5px',
      margin: '5px',
    },
    descriptionNote: {
      marginTop: 30,
      marginBottom: 30,
      borderBottom: `1px solid ${theme.palette.text.disabled}`,
      color: theme.palette.text.disabled,
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      maxWidth: '100%',
    },
    descriptionNoteLimit: {
      marginTop: 30,
      marginBottom: 30,
      borderBottom: `1px solid ${theme.palette.text.disabled}`,
      color: theme.palette.text.disabled,
      fontFamily: 'Poppins',
      /*  fontStyle: 'normal', */
      /* fontWeight: 400,
      fontSize: '14px', */
      lineHeight: '20px',
      alignItems: 'center',
      display: '-webkit-box',
      maxWidth: '100%',
      WebkitLineClamp: 8,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    },
    cancelIssueSpanText: {
      height: '20px !important',
      marginRight: 3,
      marginLeft: 3,
      backgroundColor: 'rgba(136, 137, 154, 0.13)',
      color: theme.palette.text.primary,
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '24px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      letterSpacing: '0.4px',
      borderRadius: '5px',
      padding: 5,
    },
    createTaskChip: {
      display: 'inline-block',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '10px',
      width: 'fit-content',
    },
    secondSpanCreateTask: {
      color: theme.palette.primary.main,
      paddingLeft: '5px',
      paddingRight: '5px',
      marginLeft: '5px',
      marginRight: '5px',
      borderRadius: '5px',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 120, 254, 0.13)',
    },
    createTaskDropdownIcon: {
      color: theme.palette.common.black,
      fontSize: '20px',
      cursor: 'pointer',
      marginRight: '5px',
    },
    createTaskMenuItem: {
      fontFamily: 'Open Sans',
    },
    createTaskCheckEmpty: {
      color: theme.palette.text.secondary,
      fontSize: '20px',
      cursor: 'pointer',
      marginRight: '10px',
    },
    createTaskCheck: {
      color: theme.palette.primary.main,
      fontSize: '20px',
      cursor: 'pointer',
      marginRight: '10px',
    },
    containerLinkInput: {},
    addOrValidation: {
      color: '#B468FF',
      marginLeft: '5px',
      fontSize: '17px',
      visibility: 'hidden',
      cursor: 'pointer',
      verticalAlign: 'middle',
    },
    radioBtnChecked: {
      color: theme.palette.primary.main,
      fontSize: '20px',
      marginRight: '8px',
    },
    radioBtnUnchecked: {
      color: theme.palette.text.secondary,
      fontSize: '20px',
      marginRight: '8px',
    },
    orChip: {
      backgroundColor: `#B468FF !important`,
      color: `${theme.palette.common.white} !important`,
      fontFamily: 'Poppins',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    settingsIcon: {
      color: theme.palette.text.secondary,
      fontSize: '16px',
      cursor: 'pointer',
      /* marginRight: '5px' */
    },
    closeIconActionDefaultSettings: {
      color: theme.palette.text.primary,
      fontSize: '14px',
      marginLeft: '5px',
      cursor: 'pointer',
    },
    inputChip: {
      backgroundColor: `${theme.palette.primary.light} !important`,
      color: `${theme.palette.primary.main} !important`,
      fontFamily: 'Poppins',
      marginRight: '10px',
      maxWidth: '200px',
    },
    divLinkInputs: {
      minWidth: '80px',
      display: 'flex',
      minHeight: '25px',
      alignItems: 'center',
      cursor: 'pointer',
      flexWrap: 'wrap',
      color: theme.palette.text.primary,
      gap: '5px',
      '&:hover': {
        '& > svg': {
          visibility: 'visible',
        },
      },
    },
    chipNumberCreateAction: {
      backgroundColor: 'rgba(254, 137, 71, 0.13)',
      fontFamily: 'Open Sans',
    },
    chipNumberFinishIssue: {
      backgroundColor: 'rgba(244, 67, 54, 0.13)',
      fontFamily: 'Open Sans',
      color: theme.palette.error.main,
    },
    chipNumberFailResponse: {
      backgroundColor: 'rgba(244, 67, 54, 0.13)',
      fontFamily: 'Open Sans',
      color: theme.palette.error.main,
    },
    chipNumberCreateTasks: {
      backgroundColor: 'rgba(0, 120, 254, 0.13)',
      fontFamily: 'Open Sans',
      color: theme.palette.primary.main,
    },
    chipNumberCreateAlert: {
      backgroundColor: 'rgba(244, 67, 54, 0.13)',
      fontFamily: 'Open Sans',
      color: theme.palette.error.main,
    },
    chipNumberNewAnswer: {
      backgroundColor: 'rgba(0, 120, 254, 0.13)',
      fontFamily: 'Open Sans',
      color: theme.palette.primary.main,
    },
    checkBoxInput: {
      position: 'absolute',
      alignSelf: 'center',
      color: theme.palette.primary.main,
    },
    conditionalDiv: {
      width: '100%',
    },
    menuItemAdvancedSelected: {
      background: theme.palette.primary.light,
      color: theme.palette.primary.main,
    },
    divSpecificTools: {
      minWidth: '80px',
      display: 'flex',
      minHeight: '25px',
      alignItems: 'center',
      cursor: 'pointer',
      flexWrap: 'wrap',
      color: theme.palette.text.primary,
      gap: '5px',
      '&:hover': {
        '& > svg': {
          visibility: 'visible',
        },
      },
      maxWidth: '200px',
    },
    placeholderTool: {
      color: theme.palette.text.secondary,
    },
    inputMultiline: {
      whiteSpace: 'nowrap',
      width: 'fit-content',
      '& .MuiInputBase-multiline': {
        padding: '3px 0 3px',
      },
      '& .MuiInput-underline:before': {
        borderBottom: `1px solid`,
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
    },
    inputMultilineError: {
      '& .MuiInput-underline:before': {
        borderBottom: `1px solid ${theme.palette.error.main}`,
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: `1px solid ${theme.palette.error.main}`,
      },
    },
    inputWithEllipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    popover: {
      pointerEvents: 'none',
    },
    popoverContent: {
      pointerEvents: 'auto',
    },
    moreVertIcon: {
      cursor: 'pointer',
    },
    adjustUnitField: {
      height: '35px !important',
      alignItems: 'center !important',
    },
    noBorder: {
      border: 'none !important',
    },
    spanAlert: {
      color: `${theme.palette.warning.main} !important`,
    },
    textFieldUnderline: {
      '&:not(.Mui-disabled):before': {
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
      },
      '&:not(.Mui-disabled):hover::before': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
      '&:not(.Mui-disabled):after': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
    },
    validationErrorText: {
      fontFamily: 'Open Sans',
      fontSize: '12px',
      fontWeight: 400,
      color: theme.palette.error.main,
    },
  });
