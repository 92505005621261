import { AccountFilterFilter, AccountFilterFilterUpdateInput } from 'src/gql/graphql';

type FilterProp = keyof {
  [K in keyof AccountFilterFilterUpdateInput as Required<AccountFilterFilterUpdateInput>[K] extends Array<any>
    ? K
    : never]: any;
};

export const findFilterValue = <T extends FilterProp>(
  advancedFilters: AccountFilterFilter[],
  filterName: T,
): AccountFilterFilter[T] | undefined => {
  const filter = advancedFilters?.find((elem) => elem.name === filterName);
  //@ts-ignore
  return filter?.[filterName] ?? undefined;
};

export const findFilterBaseData = (
  advancedFilters: AccountFilterFilter[],
): {
  [K in keyof AccountFilterFilterUpdateInput]: AccountFilterFilter[K];
} => {
  const sites = findFilterValue(advancedFilters, 'sites');
  const elements = findFilterValue(advancedFilters, 'elements');
  const rootSites = findFilterValue(advancedFilters, 'rootSites');
  const rootElements = findFilterValue(advancedFilters, 'rootElements');
  const parentSites = findFilterValue(advancedFilters, 'parentSites');
  const parentElements = findFilterValue(advancedFilters, 'parentElements');
  const assignedAccounts = findFilterValue(advancedFilters, 'assignedAccounts');
  const assignedLabelValues = findFilterValue(advancedFilters, 'assignedLabelValues');
  const labelValues = findFilterValue(advancedFilters, 'labelValues');
  const search = advancedFilters?.find((elem) => elem.name === 'search')?.search;
  return {
    sites,
    elements,
    rootSites,
    rootElements,
    parentSites,
    parentElements,
    assignedAccounts,
    assignedLabelValues,
    labelValues,
    search,
  };
};
