import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    popoverContainer: {
      width: '390px',
    },
    popoverTitle: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      marginLeft: '19px',
      marginTop: '13px',
    },
    checkboxChecked: {
      color: `${theme.palette.primary.main} !important`,
    },
    listItemText: {
      fontFamily: 'Poppins',
      fontWeight: 400,
    },
    divBtns: {
      display: 'flex',
      justifyContent: 'space-around',
      marginBottom: '10px',
    },
    outlinedButtons: {
      borderColor: theme.palette.primary.light,
      color: theme.palette.primary.light,
      width: '90px',
      '&:hover': {
        borderColor: theme.palette.text.primary,
        color: theme.palette.text.primary,
        backgroundColor: 'transparent',
      },
    },
    containedButton: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.common.white} !important`,
      width: '90px',
      '&:hover': {
        backgroundColor: `${theme.palette.primary.light} !important`,
      },
    },
    modalTitle: {
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontSize: '19px',
      paddingTop: '19px',
      width: '100%',
      textAlign: 'center',
    },
    divHeader: {
      display: 'flex',
    },
    dialogContent: {
      width: '310px',
      /* height: '174px', */
    },
    closeIcon: {
      margin: '10px',
      marginLeft: 'auto',
      marginRight: '10px',
      cursor: 'pointer',
    },
    divText: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      textAlign: 'center',
      marginLeft: '40px',
      marginRight: '40px',
      marginTop: '15px',
    },
    divBtnsModal: {
      display: 'flex',
      justifyContent: 'space-around',
      marginTop: '20px',
    },
    outlinedButtonCancel: {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      width: '90px',
    },

    columnsSortDiv: {
      paddingTop: '6px',
      paddingBottom: '6px',
      paddingLeft: '16px',
      fontWeight: 700,
      fontFamily: 'Open Sans',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    arrowBackIcon: {
      width: '16px',
      height: '16px',
      marginRight: '8px',
      transform: 'rotate(180deg)',
    },
    containerShowHide: {
      paddingTop: '8px',
      display: 'flex',
      alignItems: 'center',
      marginTop: '6px',
      marginBottom: '6px',
      marginLeft: '16px',
      marginRight: '16px',
    },
    showHidden: {
      fontFamily: 'Open Sans',
      color: theme.palette.text.secondary,
    },
    showHideAll: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      fontFamily: 'Open Sans',
      marginLeft: 'auto',
      marginRight: 0,
    },
  });
