import { getErrorObject } from 'src/utils/funcs';
import * as queries from 'src/modules/issue-history/issue.history.queries';
import { APOLLO_CLIENT } from 'config/apollo.config';
import { Dispatch } from 'redux';
import axios from 'axios';
import { API_URL } from 'src/utils/env';
import { IssueInstance } from 'src/gql/graphql';

export default (): void => null;

export const getIssueRecord = (issueId: string) => async (): Promise<IssueInstance | { graphQLErrors: string }> => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        filter: issueId,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ISSUE_INSTANCE_CLOSED,
    });

    return response.data.issue;
  } catch (error) {
    return error;
  }
};

const handleErrorRest = (error, dispatch: Dispatch): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (): void => {
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          //@ts-ignore
          message: JSON.parse(reader.result).errors[0].message,
          severity: 'error',
        },
      });
      resolve(Promise.reject(error));
    };

    reader.onerror = (): void => {
      reject(error);
    };

    reader.readAsText(error.response.data);
  });
};

export const downloadIssueReport = (issue: { _id: string; name: string }) => async (dispatch: Dispatch) => {
  try {
    await axios
      .get(API_URL + '/issue/report/' + issue._id, {
        responseType: 'blob',
        headers: { authorization: localStorage.getItem('access_token') },
      })
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', issue.name);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        handleErrorRest(error, dispatch);
      });
  } catch (error) {
    handleErrorRest(error, dispatch);
  }
};

export const deleteIssue =
  (id: string) =>
  async (dispatch: Dispatch): Promise<IssueInstance> => {
    try {
      const resp = await APOLLO_CLIENT.mutate({
        variables: {
          _id: id,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_DELETE_ISSUE,
      });
      return resp.data.deleteOneIssue;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const reOpenIssue =
  (id: string) =>
  async (dispatch: Dispatch): Promise<IssueInstance> => {
    try {
      const resp: { data: { reOpenIssue: IssueInstance } } = await APOLLO_CLIENT.query({
        variables: {
          where: { _id: id },
        },
        fetchPolicy: 'no-cache',
        query: queries.M_REOPEN_ISSUE,
      });

      return resp.data.reOpenIssue;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };
