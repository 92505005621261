import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    '@global': {
      '.MuiAlert-message': {
        whiteSpace: 'pre-wrap',
      },
    },
    headerBrand: {
      fontWeight: 400,
      fontFamily: '"Poppins", sans-serif;',
      width: '200px',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      cursor: 'pointer',
      fontSize: theme.typography.fontSize,
      '&:link': {
        textDecoration: 'none',
      },
      '&:visited': {
        textDecoration: 'none',
      },
      '&:hover': {
        textDecoration: 'none',
      },
      '&:active': {
        textDecoration: 'none',
      },
    },
    spanFirstPart: {
      verticalAlign: 'middle',
      justifyContent: 'center',
      display: 'flex',
      fontWeight: 500,
      alignItems: 'center',
      color: theme.palette.text.primary,
    },
    spanSecondPart: {
      color: theme.palette.primary.main,
      verticalAlign: 'middle',
      justifyContent: 'center',
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
    },
    spanImg: {
      paddingRight: '10px',
      verticalAlign: 'middle',
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
    },
    mlAuto: {
      marginLeft: 'auto',
      gap: '6px',
    },
    toolbar: {
      width: '100%',
      flex: 1,
    },
    appBar: {
      backgroundColor: theme.palette.background.default,
      borderBottom: `1px solid ${theme.palette.text.disabled}`,
      flexDirection: 'column',
      display: 'flex',
      height: 'unset',
      minHeight: 55,
    },
    tour: {
      backgroundColor: theme.palette.background.default,
    },
    helpDropdownIcon: {
      fill: theme.palette.text.primary,
      color: theme.palette.text.primary,
    },
    helpDropdown: {
      marginTop: '34px',
    },
    helpDropdownList: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    helpDropdownItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: '8px',
      color: theme.palette.text.primary,
      '&:hover': {
        background: theme.palette.primary.light,
      },
    },
    helpDropdownItemIcon: {
      fill: theme.palette.text.primary,
      color: theme.palette.text.primary,
    },
    helpDropdownItemContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    helpDropdownItemContent: {
      fontSize: '12px',
      color: theme.palette.grey.light,
      fontFamily: 'Open Sans',
      lineHeight: '20px',
    },
    leaveTutorialBtnLabel: {
      display: 'flex',
      alignItems: 'center',
      gap: '6px',
    },
    leaveTutorialBtn: {
      border: `1px solid ${theme.palette.primary.light}`,
      '&:hover': {
        background: theme.palette.primary.light,
      },
    },
    leaveTutorialBtnText: {
      fontWeight: 500,
      letterSpacing: '1.25px',
    },
    onBoardingTitle: {
      fontSize: '18px',
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
  });
