import gql from 'graphql-tag';
import { LABEL_VALUE } from './label';

export const PRESET = gql`
  fragment preset on IssueTemplateDraftFrame {
    presets {
      order
      options {
        order
        response
        conditionals
      }
      _id
      inputs
    }
  }
`;

export const ISSUE_TEMPLATE = gql`
  fragment issueTemplate on IssueTemplate {
    __typename
    title
    variant {
      variantId
      name
    }
    folder {
      parentsTree {
        _id
        name
      }
      parent {
        _id
        name
      }
      protected
      name
      _id
    }
    favorites {
      name
      _id
      isForAttribution
      resultByCondition {
        value
        _id
        backgroundColor
        label {
          _id
          name
        }
      }
      result {
        value
        _id
        backgroundColor
        label {
          _id
          name
        }
      }
      filter {
        includeAllLabels
        conditions {
          operator
          labelValue {
            value
            _id
            backgroundColor
            label {
              name
              _id
              labelValues {
                ...labelValue
              }
            }
            description
          }
        }
      }
    }
    frame {
      presets {
        order
        options {
          order
          response
          conditionals
        }
        _id
        inputs
      }
      sequential
      estimatedDuration
      estimatedDurationUnit
      estimatedDurationFromTasks
      customArConfiguration
      idOnTasks
      divideTime
      estimatedDurationDivided {
        valueAdded
        nonValueAdded
        waste
      }
      scoreInputs
      inputs {
        ...input
      }
      labelValues {
        ...labelValue
      }
      scanMapping {
        label {
          _id
          name
        }
        numberCharacters
        thisForward
        isMultiEntry
        selectedOption
        firstDelimiter
        lastDelimiter
        delimiter
      }
    }
    timeTableFilters {
      _id
      name
      filter {
        includeAllLabels
        conditions {
          operator
          labelValue {
            value
            _id
            backgroundColor
            label {
              name
              _id
              labelValues {
                ...labelValue
              }
            }
            description
          }
        }
      }
    }
    _id
  }
  ${LABEL_VALUE}
`;

export const ISSUE_TEMPLATE_DRAFT = gql`
  fragment issueTemplateDraft on IssueTemplateDraft {
    __typename
    title
    updateTemplateId
    variant {
      variantId
      name
      description
      position
    }
    updatedBy {
      name
    }
    updatedAt
    folder {
      parentsTree {
        _id
        name
      }
      parent {
        _id
        name
      }
      protected
      name
      _id
      totalItems: issueTemplatesCount
      totalFolders: issueTemplateFoldersCount
      issueTemplatesCount
      issueTemplateFoldersCount
    }
    frame {
      ...preset
      scoreInputs
      sequential
      estimatedDuration
      estimatedDurationFromTasks
      estimatedDurationUnit
      divideTime
      estimatedDurationDivided {
        valueAdded
        nonValueAdded
        waste
      }
      customArConfiguration
      idOnTasks
      inputs {
        ...input
      }
      scanMapping {
        label {
          _id
          name
        }
        numberCharacters
        thisForward
        isMultiEntry
        selectedOption
        firstDelimiter
        lastDelimiter
        delimiter
      }
    }
    favorites {
      name
      _id
      isForAttribution
      resultByCondition {
        value
        _id
        backgroundColor
        label {
          name
          _id
        }
      }
      result {
        value
        _id
        backgroundColor
        label {
          name
          _id
        }
      }
      filter {
        includeAllLabels
        conditions {
          operator
          labelValue {
            value
            _id
            backgroundColor
            label {
              name
              _id
              labelValues {
                ...labelValue
              }
            }
            description
          }
        }
      }
    }
    timeTableFilters {
      _id
      name
      filter {
        includeAllLabels
        conditions {
          operator
          labelValue {
            value
            _id
            backgroundColor
            label {
              name
              _id
              labelValues {
                ...labelValue
              }
            }
            description
          }
        }
      }
    }
    _id
  }
  ${LABEL_VALUE}
`;

export const ISSUE_TEMPLATE_TO_TREE = gql`
  fragment issueTemplateToTree on IssueTemplate {
    __typename
    title
    protected
    folder {
      _id
      name
      parentsTree {
        _id
        name
      }
    }
    _id
  }
`;

export const ISSUE_TEMPLATE_FOLDER = gql`
  fragment issueTemplateFolder on IssueTemplateFolder {
    __typename
    _id
    name
    protected
    totalItems: issueTemplatesCount(where: { archived_ne: true, variant: { position_eq: 1 } })
    totalFolders: issueTemplateFoldersCount
    issueTemplatesCount(where: { archived_ne: true, variant: { position_eq: 1 } })
    issueTemplateFoldersCount
    parentsTree {
      _id
      name
    }
    parent {
      _id
      name
    }
  }
`;

export const ISSUE_CATALOG_DRAFT = gql`
  fragment issueCatalogDraft on IssueCatalogDraft {
    __typename
    name
    description
    requestDescription
    glarPrefix
    sequential
    acknowledgeRequired
    conflictHandler
    filterTasksByLabels
    _id
    isActive
    allowTracking
    emailsToSendReport
    assignedAccounts {
      __typename
      name
      email
      photo
      _id
      deleted
    }
    tasksFallbackStateMachine {
      name
      _id
    }
    site {
      name
      totalItems: elementsCount
      totalFolders: sitesCount
      parent {
        name
        _id
        __typename
      }
      parentsTreeIds
      _id
      __typename
    }
    element {
      name
      totalItems: elementsCount
      parent {
        name
        _id
        __typename
      }
      parentsTreeIds
      site {
        name
        _id
        __typename
      }
      __typename
      _id
    }
    scheduleTasks
    scheduler {
      amount
      timeUnit
      taskIds
      name
      dueDate {
        allowPast
        distance
        timeUnit
      }
      scheduleSettings {
        initialDate
        site {
          totalFolders: sitesCount
          totalItems: elementsCount
          name
          _id
          __typename
        }
        element {
          totalItems: elementsCount
          name
          __typename
          _id
        }
      }
      shifts {
        monday {
          hour
          minutes
        }
        tuesday {
          hour
          minutes
        }
        wednesday {
          hour
          minutes
        }
        thursday {
          hour
          minutes
        }
        friday {
          hour
          minutes
        }
        saturday {
          hour
          minutes
        }
        sunday {
          hour
          minutes
        }
      }
    }
    needsApproval
    approval {
      followHierarchy
      levels {
        accounts {
          __typename
          name
          email
          photo
          _id
          deleted
        }
        labelValues {
          labelValue {
            ...labelValue
          }
          requiredApprovals
        }
      }
    }
    assignedLabelValues {
      ...labelValue
    }
    labelValues {
      ...labelValue
    }
    folder {
      parentsTree {
        _id
        name
      }
      parent {
        _id
      }
      protected
      name
      _id
    }
    stateMachine {
      name
      _id
    }
    inputs {
      ...input
    }
  }
  ${LABEL_VALUE}
`;

export const ISSUE_CATALOG_TO_PREVIEW = gql`
  fragment issueCatalogToPreview on IssueCatalog {
    __typename
    name
    _id
    updatedAt
    createdAt
    description
    folder {
      _id
    }
    requestDescription
    acknowledgeRequired
    assignedAccountsIds
    assignedLabelValuesIds
    assignedAccounts {
      name
      email
      photo
      _id
      deleted
    }
    filterTasksByLabels
    needsApproval
    assignedLabelValues {
      ...labelValue
    }
    site {
      name
      _id
    }
    template {
      __typename
      frame {
        inputs {
          ...input
        }
        labelValues {
          ...labelValue
        }
      }
    }
    stateMachine {
      name
      _id
    }
    element {
      name
      _id
    }
    inputs {
      ...input
    }
  }
  ${LABEL_VALUE}
`;

export const ISSUE_CATALOG_TO_TREE = gql`
  fragment issueCatalogToTree on IssueCatalog {
    __typename
    name
    isActive
    _id
    updatedAt
    createdAt
    emailsToSendReport
    assignedLabelValues {
      ...labelValue
    }
    assignedAccounts {
      __typename
      _id
      name
      email
      photo
      _id
      deleted
    }
    updateLog {
      by {
        name
      }
      at
    }
    site {
      name
      _id
      __typename
      parentsTreeIds
    }
    element {
      name
      _id
      __typename
      parentsTreeIds
      site {
        _id
        name
        parentsTreeIds
      }
    }
    filterTasksByLabels
    needsApproval
    createdBy {
      _id
      name
    }
    totalExecutionInstances
    template {
      _id
      frame {
        estimatedDuration
        estimatedDurationUnit
      }
    }
    folder {
      name
      _id
    }
    scheduleTasks
    updatedAt
    createdAt
    labelValues {
      ...labelValue
    }
    scheduler {
      amount
      timeUnit
      taskIds
      name
      dueDate {
        allowPast
        distance
        timeUnit
      }
      scheduleSettings {
        initialDate

        site {
          name
          _id
          parentsTreeIds
          __typename
        }
        element {
          name
          __typename
          _id
          parentsTreeIds
          site {
            name
            parentsTreeIds
            _id
          }
        }
      }
      shifts {
        monday {
          hour
          minutes
        }
        tuesday {
          hour
          minutes
        }
        wednesday {
          hour
          minutes
        }
        thursday {
          hour
          minutes
        }
        friday {
          hour
          minutes
        }
        saturday {
          hour
          minutes
        }
        sunday {
          hour
          minutes
        }
      }
    }
  }
  ${LABEL_VALUE}
`;

export const ISSUE_CATALOG_FOLDER = gql`
  fragment issueCatalogFolder on IssueCatalogFolder {
    __typename
    _id
    context
    name
    protected
    totalFolders: issueCatalogFoldersCount
    totalItems: issueCatalogsCount
    issueCatalogsCount
    parentsTreeIds
    parentsTree {
      _id
      name
    }
    parent {
      _id
      name
      __typename
    }
  }
`;

export const ISSUE_INSTANCE_TO_TABLE = gql`
  fragment issueInstanceToTable on IssueInstance {
    __typename
    glarID
    frequency {
      amount
      timeUnit
    }
    dueDateStamp
    workPackageId
    workPackage {
      workPackageName
    }
    estimatedDuration
    estimatedDurationUnit
    labelValues {
      ...labelValue
    }
    assignedAccounts {
      __typename
      name
      email
      photo
      _id
      deleted
    }
    executedBy {
      name
      email
      photo
      _id
      deleted
    }
    assignedLabelValues {
      ...labelValue
    }
    createdAt
    updatedAt
    name
    _id
    possibleActions
    stateMachineInstance {
      ...stateMachineInstance
    }
    site {
      name
      coordinates
      _id
      parent {
        _id
        name
        parentsTreeIds
        __typename
      }
      parentsTreeIds
      parentsTree {
        name
        _id
      }
    }
    element {
      name
      site {
        parentsTreeIds
        parentsTree {
          name
          _id
        }
        name
        coordinates
        _id
      }
      profile {
        name
        _id
      }
      parentsTreeIds
      parentsTree {
        _id
        name
        __typename
      }
      _id
    }
    completedTasksCount
    failResponses
    createdBySystem
    description
    createdBy {
      name
      email
      photo
      _id
    }
    approval {
      approvedBy {
        __typename
        name
        email
        photo
        _id
      }
      log {
        type
        createdAt
      }
    }
    seenBy {
      _id
      name
    }
    rootSite {
      _id
      name
    }
    rootElement {
      _id
      name
    }
  }
  ${LABEL_VALUE}
`;

export const ISSUE_INSTANCE = gql`
  fragment issueInstance on IssueInstance {
    scoreInputs
    __typename
    completedTasksCount
    note
    emailsToSendReport
    glarID
    dueDateStamp
    labelValues {
      ...labelValue
    }
    filterTasksLabelValues {
      ...labelValue
    }
    possibleActions
    isLocked
    lockedBy {
      name
    }
    needsApproval
    approval {
      log {
        by {
          name
        }
        type
        observation
        executionDate
        createdAt
        _id
      }
      followHierarchy
      executionDate
      approvalsNeeded
      approvalsCount
      approved
      approvedBy {
        __typename
        name
        _id
        email
        photo
      }
      levels {
        approvedOnce
        approved
        accounts {
          name
          email
          photo
          _id
          deleted
        }
        labelValues {
          labelValue {
            ...labelValue
          }
        }
      }
    }
    createdAt
    closedAt
    updatedAt
    sequential
    needsAcknowledge
    executedBy {
      __typename
      name
      email
      photo
      _id
      deleted
    }
    assignedAccountsIds
    assignedAccounts {
      __typename
      name
      email
      photo
      _id
      deleted
      username
    }
    assignedLabelValuesIds
    assignedLabelValues {
      ...labelValue
    }
    name
    description
    acknowledgedAccounts {
      _id
    }
    allowTracking
    requestDescription
    estimatedDuration
    estimatedDurationUnit
    frequency {
      amount
      timeUnit
    }
    acknowledgeRequired
    element {
      _id
      name
      labelValues {
        ...labelValue
      }
    }
    inputs {
      ...input
    }
    _id
    stateMachineInstance {
      ...stateMachineInstance
    }
    site {
      name
      _id
    }
    failResponses
    createdBySystem
    createdBy {
      __typename
      _id
      username
      name
      email
      protected
      tenantId
      folder {
        _id
        name
        parentsTree {
          _id
          name
        }
      }
      labelValues {
        ...labelValue
      }
      preferences {
        defaultSite {
          name
          coordinates
          _id
        }
        locale
        theme
      }
      template {
        __typename
        title
        folder {
          parentsTree {
            _id
            name
          }
          parent {
            _id
          }
          protected
          name
          _id
        }
        _id
        frame {
          inputs {
            ...input
          }
        }
      }
      authorizedSites {
        name
        _id
        coordinates
      }
      inputs {
        ...input
      }
      roles {
        name
        _id
      }
    }
    toolItems {
      _id
      available
      status
      description
      serialNumber
      code
      createdBy {
        name
        email
        photo
        _id
      }
      createdAt
      tool {
        _id
        designation
        partNumber
      }
      location {
        _id
        address {
          street
          site {
            name
          }
          element {
            name
          }
        }
        storage {
          _id
          name
          address {
            street
            site {
              name
            }
            element {
              name
            }
          }
        }
      }
      usedBy {
        name
        email
        photo
        _id
      }
      usedIn {
        _id
        glarID
        name
      }
    }
    seenBy {
      _id
      name
    }
    toolItemsIds
    toolItems {
      _id
      available
      status
      description
      serialNumber
      code
      createdBy {
        name
        email
        photo
        _id
      }
      createdAt
      tool {
        _id
        designation
        partNumber
      }
      location {
        _id
        address {
          street
          site {
            name
          }
          element {
            name
          }
        }
        storage {
          _id
          name
          address {
            street
            site {
              name
            }
            element {
              name
            }
          }
        }
      }
      usedBy {
        name
        email
        photo
        _id
      }
      usedIn {
        _id
        glarID
        name
      }
    }
  }
  ${LABEL_VALUE}
`;
