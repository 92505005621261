import { Avatar, Badge, Tooltip } from '@mui/material';
import moment from 'moment';
import { memo, useEffect, useState } from 'react';
import { useAccountActivity } from 'src/base/last-activity';
import { Account } from 'src/gql/graphql';
import { css } from '@emotion/css';
import { shallowEqual } from 'react-redux';

type AccountAvatarProps = {
  'data-testid'?: string;
  size?: number;
  account: Partial<Account>;
};

export const AccountAvatar = memo((props: AccountAvatarProps) => {
  const { account, size } = props;

  const name = account.name || account.username;

  const activities = useAccountActivity([props.account._id]);

  const lastActiveDate = activities[0]?.lastActiveDate;

  const [isOnline, setIsOnline] = useState(false);

  useEffect(() => {
    const intervalHandler = () => {
      setIsOnline(moment().diff(moment.unix(lastActiveDate), 'seconds') < 30);
    };

    if (!lastActiveDate) {
      setIsOnline(false);

      return () => {};
    } else {
      intervalHandler();
      const interval = setInterval(intervalHandler, 0.5 * 60 * 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [lastActiveDate]);

  return (
    <Badge
      invisible={!isOnline}
      overlap='circular'
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant={'dot'}
      classes={{
        badge: css({
          width: 10,
          height: 10,
          borderRadius: '50%',
          bottom: '12%',
          backgroundColor: 'rgb(0, 196, 140)',
        }),
      }}
    >
      <Tooltip title={name} placement={'right'} arrow>
        <Avatar
          data-testid={props['data-testid']}
          src={account.photo ? `data:image/webp;base64,${account.photo}` : undefined}
          alt={name}
          css={
            size
              ? {
                  width: size,
                  height: size,
                }
              : undefined
          }
        >
          {account.photo ? undefined : name?.trim().charAt(0).toUpperCase()}
        </Avatar>
      </Tooltip>
    </Badge>
  );
}, shallowEqual);
