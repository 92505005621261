import { Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { WithStyles, withStyles } from '@mui/styles';
import * as React from 'react';
import { CSSProperties } from 'react';
import { styles } from 'src/utils/components/default-button/styles';
import { upper } from 'src/utils/funcs';
import useAtomicCallback from 'src/utils/hooks/useAtomicCallback';

type ButtonProps = {
  /* necessary prop for on-click action */
  onClick: (e?: React.MouseEvent<HTMLDivElement>) => any;

  /* button type */
  button?: boolean;
  icon?: boolean;

  style?: CSSProperties;

  /* templates */
  success?: boolean;
  remove?: boolean;
  cancel?: boolean;
  discard?: boolean;
  pause?: boolean;
  edit?: boolean;
  load?: boolean;
  info?: boolean;
  search?: boolean;
  previous?: boolean;
  next?: boolean;
  transparent?: boolean;

  /* misc */
  loader?: boolean /* show if function takes time and the user isn't supposed to click multiple times */;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  label?: any /* TO DELETE */;
  edge?: boolean /* true if icon is an edge - important for border radius */;
  disabled?: boolean /* true if the input is disabled */;
  size?: string /* button size (width for button, width/height for icon) */;
  className?: string /* context-specific classes */;
  tooltip?: string /* tooltip for button/icon hover */;
  children?: any /* button content (e.g., label) */;
  id?: string;
  rounded?: boolean /* true if icon is a rounded - important for border radius */;
  'data-testid'?: string;
} & WithStyles<typeof styles>;

/**
 * @deprecated Use DefaultButton from:
 *
 * `src/utils/components/default-button/default-button.tsx`
 */
function DefaultButton(props: ButtonProps) {
  const {
    success,
    remove,
    cancel,
    discard,
    pause,
    load,
    info,
    search,
    transparent,
    edit,
    previous,
    next,
    label,
    size,
    classes,
    className,
    disabled,
    onClick,
    tooltip,
    children,
    loader,
    id,
    style: stylesProp = {},
  } = props;

  const clickFunction = useAtomicCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!disabled && !loader) {
        return onClick(e);
      }
    },
    [disabled, loader, onClick],
  );

  const formatLabel = (type: string): string =>
    type
      .split('-')
      .map((part) => upper(part))
      .join(' ');

  const template = (() => {
    if (success) return 'success';
    if (remove) return 'delete';
    if (cancel) return 'cancel';
    if (discard) return 'discard';
    if (pause) return 'pause';
    if (load) return 'load-more';
    if (info) return 'info';
    if (search) return 'search';
    if (transparent) return 'transparent';
    if (edit) return 'edit';
    if (previous) return 'previous';
    if (next) return 'next';
    return 'default';
  })();

  const type = (() => {
    const { icon } = props;
    if (icon) return classes.icon;
    return classes.button; // fallback to normal button
  })();

  const style: CSSProperties = (() => {
    const { icon, edge, rounded } = props;
    let style: CSSProperties = stylesProp;
    if (icon) {
      style = {
        width: size || '35px',
        borderTopRightRadius: edge ? '0.25rem' : '0',
        borderBottomRightRadius: edge ? '0.25rem' : '0',
      };
      if (edge) {
        style = { ...style, borderTopRightRadius: '0.25rem', borderBottomRightRadius: '0.25rem' };
      }
      if (rounded) {
        style = { ...style, borderRadius: '50%', height: size || '35px' };
      }
      if (previous || next) style.height = size || '35px';
    } else {
      style = {
        minWidth: size || '120px',
      };
    }

    return style;
  })();

  const cls = (() => {
    if (loader || disabled) return classes.disabled;
    else {
      if (!transparent) return classes.enabledHover;
      else {
        if (previous || next) return classes.enabledCircleHover;
        else return classes.enabled;
      }
    }
  })();

  const loadSpinner = <CircularProgress size={24} classes={{ root: classes.loading }} />;

  return tooltip ? (
    <Tooltip title={tooltip} arrow>
      <div
        id={id}
        data-testid={id}
        className={`${classes.common} ${type} ${loader || disabled ? '' : classes[`${template}`]} ${
          className ? className : ''
        }`}
        onClick={clickFunction}
        style={style}
      >
        <div className={cls}>
          {Array.isArray(children)
            ? [...children]
            : children || (loader && loadSpinner) || label || formatLabel(template)}
        </div>
      </div>
    </Tooltip>
  ) : (
    <div
      id={id}
      data-testid={props['data-testid'] ?? id}
      className={`${classes.common} ${type} ${loader || disabled ? '' : classes[`${template}`]} ${
        className ? className : ''
      }`}
      onClick={clickFunction}
      style={style}
    >
      <div className={cls}>
        {Array.isArray(children)
          ? [...children]
          : children || (loader && loadSpinner) || label || formatLabel(template)}
      </div>
    </div>
  );
}

/**
 * @deprecated Use DefaultButton from:
 *
 * `src/utils/components/default-button/default-button.tsx`
 */
export default withStyles(styles)(DefaultButton);
