import { Action, State } from 'src/interfaces/reducers';
import { APOLLO_CLIENT } from 'config/apollo.config';
import { Dispatch } from 'redux';
import { ApolloQueryResult } from '@apollo/client';
import {
  getErrorObject,
  isLeaf,
  setChildren,
  addSpace,
  addNewChildren,
  updateChildren,
  deleteChildren,
  addToTemplate,
  updateParent,
  deleteFromParent,
} from 'src/utils/funcs';
import { cloneDeep, orderBy } from 'lodash';
import i18n from 'src/utils/translations/i18n';
import {
  CreateIssueReportConfigDocument,
  CreateIssueReportDocument,
  CreateIssueReportFolderDocument,
  CreateSiteElementReportConfigDocument,
  CreateSiteElementReportDocument,
  CreateSiteElementReportFolderDocument,
  DeleteIssueReportDocument,
  DeleteIssueReportFolderDocument,
  DeleteSiteElementReportDocument,
  DeleteSiteElementReportFolderDocument,
  ElementSiteReport,
  ElementSiteReportDistinctValues,
  ElementSiteReportFilters,
  ElementSiteReportInfo,
  GetDistinctElementSiteReportValuesDocument,
  GetIssueReportDocument,
  GetIssueReportFoldersDocument,
  GetIssueReportInfoBulkDocument,
  GetIssueReportInfoDocument,
  GetIssueReportsTreeDocument,
  GetSiteElementReportDocument,
  GetSiteElementReportFoldersDocument,
  GetSiteElementReportInfoBulkDocument,
  GetSiteElementReportInfoCountDocument,
  GetSiteElementReportInfoDocument,
  GetSiteElementReportTreeDocument,
  IssueReport,
  IssueReportFilters,
  ReportInfo,
  SearchIssueReportFoldersDocument,
  SiteElementSearchDocument,
  UpdateIssueReportConfigDocument,
  UpdateIssueReportDocument,
  UpdateIssueReportFolderDocument,
  UpdateSiteElementReportConfigDocument,
  UpdateSiteElementReportDocument,
  UpdateSiteElementReportFolderDocument,
} from 'src/gql/graphql';

export const GET_ISSUE_REPORT_FOLDERS = 'GET_ISSUE_REPORT_FOLDERS';
export const CREATE_ISSUE_REPORT_FOLDER = 'CREATE_ISSUE_REPORT_FOLDER';
export const DELETE_ISSUE_REPORT_FOLDER = 'DELETE_ISSUE_REPORT_FOLDER';
export const UPDATE_ISSUE_REPORT_FOLDER = 'UPDATE_ISSUE_REPORT_FOLDER';
export const CREATE_ISSUE_REPORT = 'CREATE_ISSUE_REPORT';
export const UPDATE_ISSUE_REPORT = 'UPDATE_ISSUE_REPORT';
export const DELETE_ISSUE_REPORT = 'DELETE_ISSUE_REPORT';
export const SEARCH_ISSUE_REPORT_FOLDERS = 'SEARCH_ISSUE_REPORT_FOLDERS';
export const GET_SITE_ELEMENT_REPORT_FOLDERS = 'GET_SITE_ELEMENT_REPORT_FOLDERS';
export const CREATE_SITE_ELEMENT_REPORT_FOLDER = 'CREATE_SITE_ELEMENT_REPORT_FOLDER';
export const DELETE_SITE_ELEMENT_REPORT_FOLDER = 'DELETE_SITE_ELEMENT_REPORT_FOLDER';
export const UPDATE_SITE_ELEMENT_REPORT_FOLDER = 'UPDATE_SITE_ELEMENT_REPORT_FOLDER';
export const CREATE_SITE_ELEMENT_REPORT = 'CREATE_SITE_ELEMENT_REPORT';
export const UPDATE_SITE_ELEMENT_REPORT = 'UPDATE_SITE_ELEMENT_REPORT';
export const DELETE_SITE_ELEMENT_REPORT = 'DELETE_SITE_ELEMENT_REPORT';
export const SEARCH_SITE_ELEMENT_REPORT_FOLDERS = 'SEARCH_SITE_ELEMENT_REPORT_FOLDERS';

const initialState: State = {
  issueReportFolders: [],
  issueReportSearchResults: [],
  siteElementReportFolders: [],
  siteElementReportSearchResults: [],
};

export default (state: State = initialState, action: Action): State => {
  let folders: any = [];

  switch (action.type) {
    case GET_ISSUE_REPORT_FOLDERS:
      folders = [];
      if (action.payload.folders.length > 0 && action.payload.folders[0].parent === null) {
        action.payload.folders.forEach((p) => {
          folders.push({
            ...p,
            leaf: isLeaf(p, 'issueReport'),
            children: [],
          });
        });
      } else if (action.payload.folders.length || action.payload.issueReports.length) {
        folders = setChildren(
          cloneDeep(state).issueReportFolders,
          action.payload.folders.length && action.payload.folders[0].parent
            ? action.payload.folders[0].parent._id
            : action.payload.issueReports[0].folder._id,
          action.payload,
          'issueReport',
        );
      }

      return {
        ...state,
        issueReportFolders: folders.length === 0 ? state.folders || [] : orderBy(folders, 'name', 'asc'),
      };
    case CREATE_ISSUE_REPORT_FOLDER:
      folders = [];
      if (action.payload.parent && action.payload.parent._id) {
        folders = addNewChildren(
          cloneDeep(state).issueReportFolders,
          action.payload.parent._id,
          action.payload,
          'issueReport',
        );
      } else {
        folders = JSON.parse(JSON.stringify(state.issueReportFolders));
        folders.push(Object.assign({ children: [], leaf: true }, action.payload));
      }
      return { ...state, issueReportFolders: orderBy(folders, 'name', 'asc') };
    case UPDATE_ISSUE_REPORT_FOLDER:
      folders = [];
      if (action.payload.parent !== null)
        folders = updateChildren(cloneDeep(state).issueReportFolders, action.payload.parent._id, action.payload);
      else
        state.issueReportFolders.map((folder) => {
          if (folder._id === action.payload._id) folders.push({ ...folder, ...action.payload });
          else folders.push(folder);
        });
      return {
        ...state,
        issueReportFolders: orderBy(folders, 'name', 'asc'),
        issueReportSearchResults: state.issueReportSearchResults.map((s) =>
          s._id === action.payload._id ? action.payload : s,
        ),
      };
    case DELETE_ISSUE_REPORT_FOLDER:
      folders = [];
      if (action.payload.parent !== null) {
        folders = deleteChildren(
          cloneDeep(state).issueReportFolders,
          action.payload.parent._id,
          action.payload,
          'issueReport',
        );
      } else {
        folders = state.issueReportFolders.filter((st) => st._id !== action.payload._id);
      }
      return {
        ...state,
        issueReportFolders: folders,
        issueReportSearchResults: state.issueReportSearchResults.filter((s) => s._id !== action.payload._id),
      };
    case CREATE_ISSUE_REPORT:
      folders = addToTemplate(
        cloneDeep(state).issueReportFolders,
        action.payload.folder._id,
        action.payload,
        'issueReport',
      );
      return {
        ...state,
        issueReportFolders: folders,
      };
    case UPDATE_ISSUE_REPORT:
      folders = updateParent(
        cloneDeep(state).issueReportFolders,
        action.payload.folder._id,
        action.payload,
        'issueReport',
        undefined,
      );
      return {
        ...state,
        issueReportFolders: folders,
        issueReportSearchResults: state.issueReportSearchResults.map((s) =>
          s._id === action.payload._id ? action.payload : s,
        ),
      };
    case DELETE_ISSUE_REPORT:
      folders = deleteFromParent(
        cloneDeep(state).issueReportFolders,
        action.payload.folder._id,
        action.payload,
        'issueReport',
      );
      return {
        ...state,
        issueReportFolders: folders,
        issueReportSearchResults: state.issueReportSearchResults.filter((s) => s._id !== action.payload._id),
      };
    case SEARCH_ISSUE_REPORT_FOLDERS:
      return {
        ...state,
        issueReportSearchResults: [...action.payload.issueReports, ...action.payload.issueReportFolders],
      };
    case GET_SITE_ELEMENT_REPORT_FOLDERS:
      folders = [];
      if (action.payload.folders.length > 0 && action.payload.folders[0].parent === null) {
        action.payload.folders.forEach((p) => {
          folders.push({
            ...p,
            leaf: isLeaf(p, 'elementSiteReport'),
            children: [],
          });
        });
      } else if (action.payload.folders.length || action.payload.siteElementReports.length) {
        folders = setChildren(
          cloneDeep(state).siteElementReportFolders,
          action.payload.folders.length && action.payload.folders[0].parent
            ? action.payload.folders[0].parent._id
            : action.payload.siteElementReports[0].folder._id,
          action.payload,
          'siteElementReport',
        );
      }
      return {
        ...state,
        siteElementReportFolders: folders.length === 0 ? state.folders || [] : orderBy(folders, 'name', 'asc'),
      };
    case CREATE_SITE_ELEMENT_REPORT_FOLDER:
      folders = [];
      if (action.payload.parent && action.payload.parent._id) {
        folders = addNewChildren(
          cloneDeep(state).siteElementReportFolders,
          action.payload.parent._id,
          action.payload,
          'elementSiteReport',
        );
      } else {
        folders = JSON.parse(JSON.stringify(state.siteElementReportFolders));
        folders.push(Object.assign({ children: [], leaf: true }, action.payload));
      }
      return { ...state, siteElementReportFolders: orderBy(folders, 'name', 'asc') };
    case UPDATE_SITE_ELEMENT_REPORT_FOLDER:
      folders = [];
      if (action.payload.parent !== null)
        folders = updateChildren(cloneDeep(state).siteElementReportFolders, action.payload.parent._id, action.payload);
      else
        state.siteElementReportFolders.map((folder) => {
          if (folder._id === action.payload._id) folders.push({ ...folder, ...action.payload });
          else folders.push(folder);
        });
      return {
        ...state,
        siteElementReportFolders: orderBy(folders, 'name', 'asc'),
        siteElementReportSearchResults: state.siteElementReportSearchResults.map((s) =>
          s._id === action.payload._id ? action.payload : s,
        ),
      };
    case DELETE_SITE_ELEMENT_REPORT_FOLDER:
      folders = [];
      if (action.payload.parent !== null) {
        folders = deleteChildren(
          cloneDeep(state).siteElementReportFolders,
          action.payload.parent._id,
          action.payload,
          'elementSiteReport',
        );
      } else {
        folders = state.siteElementReportFolders.filter((st) => st._id !== action.payload._id);
      }
      return {
        ...state,
        siteElementReportFolders: folders,
        siteElementReportSearchResults: state.siteElementReportSearchResults.filter(
          (s) => s._id !== action.payload._id,
        ),
      };
    case CREATE_SITE_ELEMENT_REPORT:
      folders = addToTemplate(
        cloneDeep(state).siteElementReportFolders,
        action.payload.folder._id,
        action.payload,
        'elementSiteReport',
      );
      return {
        ...state,
        siteElementReportFolders: folders,
      };
    case UPDATE_SITE_ELEMENT_REPORT:
      folders = updateParent(
        cloneDeep(state).siteElementReportFolders,
        action.payload.folder._id,
        action.payload,
        'elementSiteReport',
        undefined,
      );
      return {
        ...state,
        siteElementReportFolders: folders,
        siteElementReportSearchResults: state.siteElementReportSearchResults.map((s) =>
          s._id === action.payload._id ? action.payload : s,
        ),
      };
    case DELETE_SITE_ELEMENT_REPORT:
      folders = deleteFromParent(
        cloneDeep(state).siteElementReportFolders,
        action.payload.folder._id,
        action.payload,
        'elementSiteReport',
      );
      return {
        ...state,
        siteElementReportFolders: folders,
        siteElementReportSearchResults: state.siteElementReportSearchResults.filter(
          (s) => s._id !== action.payload._id,
        ),
      };
    case SEARCH_SITE_ELEMENT_REPORT_FOLDERS:
      return {
        ...state,
        siteElementReportSearchResults: [
          ...action.payload.elementSiteReports,
          ...action.payload.elementSiteReportFolders,
        ],
      };
    default:
      return state;
  }
};

export const getFolders =
  (options, item, modal = false) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      let response: ApolloQueryResult<any> | null = null;
      if (item?.node.totalFolders || options.parent_exists !== undefined) {
        response = await APOLLO_CLIENT.query({
          variables: { where: options },
          fetchPolicy: 'no-cache',
          query: GetIssueReportFoldersDocument,
        });
      }

      let reports: ApolloQueryResult<any> | null = null;
      if (options.parent_eq && !modal && item.node.totalItems) {
        reports = await APOLLO_CLIENT.query({
          variables: { filter: { folder_eq: options.parent_eq } },
          fetchPolicy: 'no-cache',
          query: GetIssueReportsTreeDocument,
        });
      }

      dispatch({
        type: GET_ISSUE_REPORT_FOLDERS,
        payload: {
          folders: response ? response.data.issueReportFolders : [],
          issueReports: reports ? reports.data.issueReports : [],
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const createFolder =
  ({ name, parent = null }: { name: string; parent: { _id: string } | null }) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { name, parent: parent?._id },
        fetchPolicy: 'no-cache',
        mutation: CreateIssueReportFolderDocument,
      });
      dispatch({
        type: CREATE_ISSUE_REPORT_FOLDER,
        payload: response.data.createIssueReportFolder,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(name) + i18n.t('toastCreateSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const updateFolder =
  ({ name, parent = null }: { name: string; parent: { _id: string } | null }, { _id }) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { name, parent: parent?._id, _id },
        fetchPolicy: 'no-cache',
        mutation: UpdateIssueReportFolderDocument,
      });
      dispatch({
        type: UPDATE_ISSUE_REPORT_FOLDER,
        payload: response.data.updateIssueReportFolder,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(name) + i18n.t('toastUpdateSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const deleteFolder =
  (folderData) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { where: { _id: folderData.id } },
        fetchPolicy: 'no-cache',
        mutation: DeleteIssueReportFolderDocument,
      });
      dispatch({
        type: DELETE_ISSUE_REPORT_FOLDER,
        payload: response.data.deleteOneIssueReportFolder,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(folderData.name) + i18n.t('toastDeleteSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const createIssueReport = (data) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { data },
      fetchPolicy: 'no-cache',
      mutation: CreateIssueReportDocument,
    });
    dispatch({
      type: CREATE_ISSUE_REPORT,
      payload: response.data.createIssueReport,
    });
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: i18n.t('report') + addSpace(data.name) + i18n.t('toastCreateSuccess'),
        severity: 'success',
      },
    });
    return response.data.createIssueReport;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const updateIssueReport = (where, data) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { data, where },
      fetchPolicy: 'no-cache',
      mutation: UpdateIssueReportDocument,
    });

    dispatch({
      type: UPDATE_ISSUE_REPORT,
      payload: response.data.updateIssueReport,
    });
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: i18n.t('report') + addSpace(data.name) + i18n.t('toastUpdateSuccess'),
        severity: 'success',
      },
    });
    return response.data.updateIssueReport;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const deleteIssueReport = (where) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { where },
      fetchPolicy: 'no-cache',
      mutation: DeleteIssueReportDocument,
    });

    dispatch({
      type: DELETE_ISSUE_REPORT,
      payload: response.data.deleteOneIssueReport,
    });
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: i18n.t('report') + addSpace(response.data.deleteOneIssueReport.name) + i18n.t('toastDeleteSuccess'),
        severity: 'success',
      },
    });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getIssueReport =
  (where) =>
  async (dispatch: Dispatch): Promise<IssueReport> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: { where },
        fetchPolicy: 'no-cache',
        query: GetIssueReportDocument,
      });

      return response.data.issueReport as IssueReport;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getIssueReportInfo =
  (where: IssueReportFilters, limit: number, skip: number, orderBy, inputOrderBy?) =>
  async (dispatch: Dispatch): Promise<ReportInfo[]> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: { where, limit, skip, orderBy: inputOrderBy?.length ? [] : orderBy, inputOrderBy },
        fetchPolicy: 'no-cache',
        query: GetIssueReportInfoDocument,
      });

      return response.data.getIssueReportInfo as ReportInfo[];
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getIssueReportInfoBulk =
  ({ _ids }) =>
  async (dispatch: Dispatch): Promise<ReportInfo[]> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: { _ids },
        fetchPolicy: 'no-cache',
        query: GetIssueReportInfoBulkDocument,
      });

      return response.data.getIssueReportInfoBulk as ReportInfo[];
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const search =
  (searchString, doDispatch = true) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { searchString },
        fetchPolicy: 'no-cache',
        mutation: SearchIssueReportFoldersDocument,
      });
      if (doDispatch) {
        dispatch({
          type: SEARCH_ISSUE_REPORT_FOLDERS,
          payload: response.data,
        });
      }
      return response.data;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const createIssueReportConfig = (data) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { data },
      fetchPolicy: 'no-cache',
      mutation: CreateIssueReportConfigDocument,
    });
    return response.data.createIssueReportConfig;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const updateIssueReportConfig = (where, data) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { where, data },
      fetchPolicy: 'no-cache',
      mutation: UpdateIssueReportConfigDocument,
    });
    return response.data.updateIssueReportConfig;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getSiteElementFolders =
  (options, item, modal = false) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      let response: ApolloQueryResult<any> | null = null;
      if (item?.node.totalFolders || options.parent_exists !== undefined) {
        response = await APOLLO_CLIENT.query({
          variables: { where: options },
          fetchPolicy: 'no-cache',
          query: GetSiteElementReportFoldersDocument,
        });
      }

      let reports: ApolloQueryResult<any> | null = null;
      if (options.parent_eq && !modal && item.node.totalItems) {
        reports = await APOLLO_CLIENT.query({
          variables: { filter: { folder_eq: options.parent_eq } },
          fetchPolicy: 'no-cache',
          query: GetSiteElementReportTreeDocument,
        });
      }

      dispatch({
        type: GET_SITE_ELEMENT_REPORT_FOLDERS,
        payload: {
          folders: response ? response.data.elementSiteReportFolders : [],
          siteElementReports: reports ? reports.data.elementSiteReports : [],
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const createSiteElementFolder =
  ({ name, parent = null }: { name: string; parent: { _id: string } | null }) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { name, parent: parent?._id },
        fetchPolicy: 'no-cache',
        mutation: CreateSiteElementReportFolderDocument,
      });
      dispatch({
        type: CREATE_SITE_ELEMENT_REPORT_FOLDER,
        payload: response.data.createElementSiteReportFolder,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(name) + i18n.t('toastCreateSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const updateSiteElementFolder =
  ({ name, parent = null }: { name: string; parent: { _id: string } | null }, { _id }) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { name, parent: parent?._id, _id },
        fetchPolicy: 'no-cache',
        mutation: UpdateSiteElementReportFolderDocument,
      });
      dispatch({
        type: UPDATE_SITE_ELEMENT_REPORT_FOLDER,
        payload: response.data.updateElementSiteReportFolder,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(name) + i18n.t('toastUpdateSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const deleteSiteElementFolder =
  (folderData) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { where: { _id: folderData.id } },
        fetchPolicy: 'no-cache',
        mutation: DeleteSiteElementReportFolderDocument,
      });
      dispatch({
        type: DELETE_SITE_ELEMENT_REPORT_FOLDER,
        payload: response.data.deleteOneElementSiteReportFolder,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(folderData.name) + i18n.t('toastDeleteSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const createSiteElementReport = (data) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { data },
      fetchPolicy: 'no-cache',
      mutation: CreateSiteElementReportDocument,
    });
    dispatch({
      type: CREATE_SITE_ELEMENT_REPORT,
      payload: response.data.createElementSiteReport,
    });
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: i18n.t('report') + addSpace(data.name) + i18n.t('toastCreateSuccess'),
        severity: 'success',
      },
    });
    return response.data.createElementSiteReport;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const updateSiteElementReport = (where, data) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { data, where },
      fetchPolicy: 'no-cache',
      mutation: UpdateSiteElementReportDocument,
    });

    dispatch({
      type: UPDATE_SITE_ELEMENT_REPORT,
      payload: response.data.updateElementSiteReport,
    });
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: i18n.t('report') + addSpace(data.name) + i18n.t('toastUpdateSuccess'),
        severity: 'success',
      },
    });
    return response.data.updateElementSiteReport;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const deleteSiteElementReport = (where) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { where },
      fetchPolicy: 'no-cache',
      mutation: DeleteSiteElementReportDocument,
    });

    dispatch({
      type: DELETE_SITE_ELEMENT_REPORT,
      payload: response.data.deleteOneElementSiteReport,
    });
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message:
          i18n.t('report') + addSpace(response.data.deleteOneElementSiteReport.name) + i18n.t('toastDeleteSuccess'),
        severity: 'success',
      },
    });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getSiteElementReport =
  (where) =>
  async (dispatch: Dispatch): Promise<ElementSiteReport> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: { where },
        fetchPolicy: 'no-cache',
        query: GetSiteElementReportDocument,
      });

      return response.data.elementSiteReport as ElementSiteReport;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getSiteElementReportInfo =
  (where: ElementSiteReportFilters, limit, skip, orderBy, inputOrderBy?) =>
  async (dispatch: Dispatch): Promise<ElementSiteReportInfo[]> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: { where, limit, skip, orderBy: inputOrderBy ? [] : orderBy, inputOrderBy },
        fetchPolicy: 'no-cache',
        query: GetSiteElementReportInfoDocument,
      });

      return response.data.getElementSiteReportInfo as ElementSiteReportInfo[];
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getSiteElementReportInfoBulk =
  ({ _ids }) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: { _ids },
        fetchPolicy: 'no-cache',
        query: GetSiteElementReportInfoBulkDocument,
      });

      return response.data.getElementSiteReportInfoBulk;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getSiteElementReportInfoCount = (where: ElementSiteReportFilters) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: { where },
      fetchPolicy: 'no-cache',
      query: GetSiteElementReportInfoCountDocument,
    });

    return response.data.getElementSiteReportInfoCount;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const siteElementSearch = (searchString) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { searchString },
      fetchPolicy: 'no-cache',
      mutation: SiteElementSearchDocument,
    });
    dispatch({
      type: SEARCH_SITE_ELEMENT_REPORT_FOLDERS,
      payload: response.data,
    });
    return response.data;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const createSiteElementReportConfig = (data) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { data },
      fetchPolicy: 'no-cache',
      mutation: CreateSiteElementReportConfigDocument,
    });

    return response.data.createElementSiteReportConfig;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const updateSiteElementReportConfig = (where, data) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { where, data },
      fetchPolicy: 'no-cache',
      mutation: UpdateSiteElementReportConfigDocument,
    });

    return response.data.updateElementSiteReportConfig;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const distinctElementSiteReportValues =
  (id, inputsIds) =>
  async (dispatch: Dispatch): Promise<ElementSiteReportDistinctValues> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: { id, inputsIds },
        fetchPolicy: 'no-cache',
        query: GetDistinctElementSiteReportValuesDocument,
      });

      return response.data.distinctElementSiteReportValues as ElementSiteReportDistinctValues;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };
