import gql from 'graphql-tag';
import {
  INPUT,
  INPUT_WITH_LOG,
  INPUT_WITH_RECURSIVE,
  ON_RESPONSE_COMMON_NUMBER,
  ON_RESPONSE_COMMON_STRING,
  INPUT_NUMBER,
  INPUT_STRING,
} from 'src/utils/fragments/input';
import { ISSUE_INSTANCE } from 'src/utils/fragments/issue';
import { LABEL_VALUE } from 'src/utils/fragments/label';
import { STATE_MACHINE_INSTANCE } from 'src/utils/fragments/stateMachine';
import { TASK_INSTANCE_TO_TABLE } from 'src/utils/fragments/task';

export const Q_GET_ISSUE_INSTANCE_CLOSED = gql`
  query GetIssueInstanceClosed($filter: ID!) {
    issue(where: { _id: $filter }) {
      ...issueInstance
      workPackageId
      workPackage {
        workPackageName
      }
      taskGroups {
        name
        order
        _id
        tasks {
          ...taskInstanceToTable
          inputs {
            ...input
          }
          onResponseId
          score {
            currentScore
            totalScore
          }
          parentsTree
          isActiveByOnResponse
          assignedAccounts {
            name
            email
            photo
            _id
            deleted
          }
          assignedTools {
            tool {
              designation
              partNumber
              _id
            }
            quantity
            reserveAtStart
            associatedWith {
              designation
              partNumber
              _id
            }
          }
          assignedMaterials {
            material {
              code
              name
              _id
            }
            quantity
          }
          assignedLabelValues {
            ...labelValue
          }
          labelValues {
            ...labelValue
          }
          executedBy {
            name
            email
            photo
            _id
            deleted
          }
          site {
            name
            _id
          }
          element {
            name
            _id
            labelValues {
              ...labelValue
            }
            site {
              _id
              name
            }
          }
          inputs {
            ...input
          }
        }
      }
    }
  }
  ${INPUT}
  ${INPUT_WITH_LOG}
  ${INPUT_WITH_RECURSIVE}
  ${STATE_MACHINE_INSTANCE}
  ${ISSUE_INSTANCE}
  ${TASK_INSTANCE_TO_TABLE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
  ${LABEL_VALUE}
`;

export const M_DELETE_ISSUE = gql`
  mutation DeleteIssue($_id: ID!) {
    deleteOneIssue(where: { _id: $_id }) {
      _id
    }
  }
`;

export const M_REOPEN_ISSUE = gql`
  mutation ReopenIssue($where: IssueInstanceWhereUniqueInput!) {
    reOpenIssue(where: $where) {
      ...issueInstance
    }
  }
  ${INPUT}
  ${ISSUE_INSTANCE}
  ${STATE_MACHINE_INSTANCE}
`;
