import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    responseSetting: {
      width: '100%',
      display: 'flex',
      background: theme.palette.background.default,
      padding: '21px 4px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: '6px',
      maxHeight: '60px',
      height: '60px',
    },
    responseSettingDragged: {
      background: theme.palette.grey.light,
    },
    dragIcon: {
      fill: theme.palette.text.disabled,
      cursor: 'pointer',
    },
    deleteIcon: {
      marginRight: '8px',
      cursor: 'pointer',
    },
    dividerHovered: {
      height: '3px',
      background: theme.palette.primary.main,
    },
    editResponse: {
      padding: '8px 4px',
    },
    response: {
      fontSize: '16px',
    },
    hasConditionalsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '3px',
      flexGrow: 1,
    },
    responseInConditional: {
      fontSize: '12px',
      color: theme.palette.grey.light,
    },
  });
