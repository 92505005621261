import { makeStyles, WithStyles, withStyles } from '@mui/styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { styles } from './styles';
import { Badge, Typography } from '@mui/material';
import DefaultButton from '../default-button/default-button';
import { Add, FilterList } from '@mui/icons-material';
import { useEffect, useMemo, useRef, useState } from 'react';
import PieFilterBuilder from './components/pie-filter-builder';
import { DateRange } from '../custom-date-range-picker';

export interface FilterDataType {
  context: string;
  type: string;
  condition: string;
  values?: any[];
  dateValue?: DateRange;
}

type PieChartFilterProps = {
  context: string;
  filterData: FilterDataType[];
  widgetWrapper?: boolean;
  setFilterData: (data: FilterDataType[]) => void;
  onSave?: (updatedFilters?: FilterDataType[]) => void;
} & WithStyles<typeof styles> &
  WithTranslation;

const useStyles = makeStyles(styles);
const PieChartFilter: React.FC<PieChartFilterProps> = (props): JSX.Element => {
  const { t, filterData, context, widgetWrapper, setFilterData, onSave } = props;
  const titleRef = useRef<HTMLParagraphElement>();
  const buttonRef = useRef<HTMLDivElement>();
  const [openFilterPopover, setOpenFilterPopover] = useState<boolean>(false);
  const classes = useStyles();
  const filterDataNoData = useMemo(() => {
    return filterData.some((data) => {
      if (!data) return true; // Ensure filterData[0] exists

      const { type, condition, values, dateValue } = data;

      // Check type and condition
      if (!type || !condition) {
        return true;
      }

      // Check values if they are present
      if (values === null && dateValue === null) {
        return true;
      }

      return false;
    });
  }, [filterData]);

  useEffect(() => {
    if (!widgetWrapper) {
      const mappedCorrectlyFilters = filterData.map((customFilter) =>
        (customFilter.type?.toLowerCase().includes('date') || customFilter.type?.toLowerCase().includes('time')) &&
        (!customFilter.dateValue || customFilter.dateValue === null)
          ? { ...customFilter, dateValue: undefined }
          : customFilter,
      );
      setFilterData(mappedCorrectlyFilters);
    }
  }, [widgetWrapper]);

  return (
    <div className={classes.customFilterContainer}>
      {!widgetWrapper && (
        <Typography ref={titleRef} className={classes.customFilterTitle}>
          {t('cardFilters')}
        </Typography>
      )}
      {filterDataNoData && !widgetWrapper ? (
        <DefaultButton
          data-testid={'add-filter-btn'}
          variant={'outlined'}
          className={classes.addFilterBtn}
          onClick={() => setOpenFilterPopover(true)}
          label={
            <div className={classes.addFilterBtnText}>
              <Add />
              <Typography>{t('addFilter')}</Typography>
            </div>
          }
        />
      ) : (
        <div ref={buttonRef}>
          <DefaultButton
            data-testid={'filter-btn'}
            className={!widgetWrapper ? classes.filterBtn : classes.filterBtnNoText}
            onClick={() => setOpenFilterPopover(true)}
            label={
              <div className={classes.filterBtnText}>
                <FilterList fontSize={!widgetWrapper ? 'medium' : 'small'} />
                {!widgetWrapper && <Typography>{t('filter')}</Typography>}
                <Badge
                  classes={{ badge: !widgetWrapper ? classes.badge : classes.badgeWidgetWrapper }}
                  style={!widgetWrapper ? { marginLeft: '8px' } : { marginLeft: '8px', marginRight: '10px' }}
                  badgeContent={filterData.length}
                  showZero={false}
                />
              </div>
            }
          />
        </div>
      )}
      <PieFilterBuilder
        open={openFilterPopover}
        popoverAnchorEl={!widgetWrapper ? titleRef.current : buttonRef.current}
        closePopover={setOpenFilterPopover}
        filterData={filterData}
        setFilterData={setFilterData}
        context={context}
        missingFilterData={filterDataNoData}
        onSave={onSave}
        widgetWrapper={widgetWrapper}
      />
    </div>
  );
};

export default withTranslation('translation')(withStyles(styles)(PieChartFilter));
