import { Dispatch } from 'redux';
import 'webrtc-adapter';
import { setSelectedMicrophone, setSelectedSpeaker } from 'src/modules/remote-assistance/rtc/rtc.redux';
import { DEVICES_ERROR, PERMISSION_ERROR } from './genericErrors';
import { showSnackbar } from 'src/base/root/root.redux';
import { changeSpeaker } from './agorartc/agoraState';

export interface DevicesProps {
  microphones: MediaDeviceInfo[];
  speakers: MediaDeviceInfo[];
  selectedMicrophone: string;
  selectedSpeaker: string;
}

interface Constraints {
  audio: boolean;
}

export const constraints: Constraints = {
  audio: true,
};

const AUDIO_INPUT_DEVICES = 'audioinput';
const AUDIO_OUTPUT_DEVICES = 'audiooutput';

const openMediaDevices = async (constraints: Constraints): Promise<string | MediaStream | void> => {
  if (navigator.mediaDevices) {
    return navigator.mediaDevices
      ?.getUserMedia(constraints)
      .then((res) => res)
      .catch((e: Error) => {
        if (!e?.message?.includes('Permission denied')) {
          return DEVICES_ERROR;
        }

        return PERMISSION_ERROR;
      });
  }
};

const getConnectedDevices = async (): Promise<MediaDeviceInfo[]> => {
  return navigator.mediaDevices?.enumerateDevices();
};

const checkMicrophones = async (microphones) => {
  let microphone = null;

  for (const device of microphones) {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: { deviceId: device.deviceId } });
      microphone = device.deviceId;
      break; // Exit the loop once a valid microphone is found
    } catch (error) {
      // Continue to the next device if an error occurs
      continue;
    }
  }

  localStorage.setItem('microphone', microphone);
  return microphone;
};

const checkAndRequestPermissions = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    stream.getTracks().forEach((track) => track.stop());
    return true;
  } catch (error) {
    console.error('Error accessing media devices.', error);
    return false;
  }
};

export const loadDevices = async (): Promise<DevicesProps | null> => {
  const hasPermissions = await checkAndRequestPermissions();
  if (!hasPermissions) {
    showSnackbar('error', 'Permissions are required to access media devices');
    return null;
  }

  const devices = await getConnectedDevices();

  // Devices are not available
  if (!devices) {
    showSnackbar('error', 'Devices are unavailable');
    return null;
  }

  // const cameras = devices.filter((device) => device.kind === VIDEO_INPUT_DEVICES);
  const microphones = devices.filter((device) => device.kind === AUDIO_INPUT_DEVICES);
  const speakers = devices.filter((device) => device.kind === AUDIO_OUTPUT_DEVICES);

  // Check for stored values
  let microphone = localStorage.getItem('microphone');
  let speaker = localStorage.getItem('speaker');

  if (microphones.length > 0 && !microphones.find((device) => device.deviceId === microphone)) {
    microphone = await checkMicrophones(microphones);
  }

  if (speakers.length > 0 && !speakers.find((device) => device?.deviceId === speaker)) {
    speaker = speakers[0]?.deviceId ?? null;
    localStorage.setItem('speaker', speaker);
  }

  const res = {
    microphones: microphones,
    speakers: speakers,
    selectedMicrophone: microphone,
    selectedSpeaker: speaker,
  };

  return res;
};

const dispatchDevices = (props: DevicesProps, reduxDispatch: Dispatch, setMicrophones: any, setSpeakers: any): void => {
  const { microphones, speakers, selectedMicrophone, selectedSpeaker } = props;
  setMicrophones(microphones);
  setSpeakers(speakers);

  reduxDispatch(setSelectedMicrophone(selectedMicrophone));

  changeSpeaker(selectedSpeaker);
};

const startWebRTC = async (
  reduxDispatch: Dispatch,
  setStream: any,
  setMicrophones: any,
  setSpeakers: any,
): Promise<string | MediaStream | void> => {
  try {
    // Give ourselves a stream just so that the user gets asked early
    let stream = null;
    stream = await openMediaDevices(constraints);
    if (stream == null) return;
    setStream(stream);
    const devices = await loadDevices();
    if (devices) dispatchDevices(devices, reduxDispatch, setMicrophones, setSpeakers);
    if (!navigator.mediaDevices) {
      console.error('No media devices available');
      return;
    }
    return stream;
  } catch (error) {
    console.error('Error accessing media devices.', error);
  }
};

// Function not being used
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
/* eslint-disable */
export const filterIceServers = (iceServers) => {
  let hasTurn = false;
  iceServers = JSON.parse(JSON.stringify(iceServers));
  return iceServers.filter(function (server) {
    if (server && (server.urls || server.url)) {
      let urls = server.urls || server.url;
      const isString = typeof urls === 'string';
      if (isString) {
        urls = [urls];
      }
      urls = urls.filter(function (url) {
        // filter STUN unconditionally.
        if (url.indexOf('stun:') === 0) {
          return false;
        }

        const validTurn = url.startsWith('turn') && !url.startsWith('turn:[') && url.includes('transport=udp');
        if (validTurn && !hasTurn) {
          hasTurn = true;
          return true;
        }
        return validTurn && !hasTurn;
      });

      delete server.url;
      server.urls = isString ? urls[0] : urls;
      return !!urls.length;
    }

    return false;
  });
};
/* eslint-enable */

export default startWebRTC;
