import { useEffect } from 'react';
import { useMainContainerContext } from '../context/main.container.context';

export function withBlankPage<T>(Component: React.FC<T>) {
  return (props: T) => {
    const { updateMainContainerState, blankPage } = useMainContainerContext();

    useEffect(() => {
      updateMainContainerState({ blankPage: true });

      return () => {
        updateMainContainerState({ blankPage: false });
      };
    }, []);

    return blankPage && <Component {...props} />;
  };
}
