import { ReactNode, createContext, useCallback, useContext, useState } from 'react';
import { AccountProfileState, ProfileState } from '../types/account.profile.types';

export const accountProfileContextInitialState: ProfileState = {
  id: '',
  name: '',
  email: '',
  roles: [],
  authorizedSites: [],
  labelValues: [],
  preferences: {
    theme: '',
    locale: '',
    timeZone: '',
    defaultSite: null,
  },
  photo: '',
  currentPassword: '',
  newPassword: '',
  repeatNewPassword: '',
  protected: false,
};

export const accountProfileContextInitialValue: AccountProfileState = {
  userProfile: accountProfileContextInitialState,
  canEditGeneralData: false,
  canEditPhoto: false,
  canEditUserInformation: false,
  canEditSecurity: false,
  canEditPreferences: false,
  unsaved: false,
};

const AccountProfileContext = createContext<AccountProfileState>(accountProfileContextInitialValue);

interface AccountProfileContextProviderProps {
  children: ReactNode;
  initialValue: AccountProfileState;
}

export const AccountProfileContextProvider = ({
  children,
  initialValue = accountProfileContextInitialValue,
}: AccountProfileContextProviderProps) => {
  const [accountProfileState, setAccountProfileState] = useState<AccountProfileState>(initialValue);

  const updateAccountProfileState = useCallback(
    (newData: Partial<AccountProfileState>) => {
      setAccountProfileState((prevState) => ({ ...prevState, ...newData }));
    },
    [setAccountProfileState],
  );

  const updateAccountProfileDataState = useCallback(
    (newData: Partial<ProfileState>) => {
      setAccountProfileState((prevState) => ({
        ...prevState,
        userProfile: { ...prevState.userProfile, ...newData },
      }));
    },
    [setAccountProfileState],
  );

  return (
    <AccountProfileContext.Provider
      value={{
        ...accountProfileState,
        updateAccountProfileState,
        updateAccountProfileDataState,
        setAccountProfileState,
      }}
    >
      {children}
    </AccountProfileContext.Provider>
  );
};

export const useAccountProfileContext = () => {
  const context = useContext(AccountProfileContext);
  if (!context) {
    throw new Error('useAccountProfileContext must be used within a AccountProfileContext');
  }
  return context;
};
