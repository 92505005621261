import { Divider, Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styles } from 'src/modules/agenda/components/columns-popover/styles';
import { useEffect, useRef, useState } from 'react';
import { ArrowForwardIos } from '@mui/icons-material';
import { useDrop } from 'react-dnd';
import ContainerActive from './container-active';
import ContainerHidden from './container-hidden';
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { cloneDeep } from 'lodash';

interface ColumnPopoverOption {
  key: string;
  label: string;
}

interface ColumnsPopoverProps {
  allColumns: ColumnPopoverOption[];
  provColumns: string[];
  open: boolean;
  refAnchor: HTMLDivElement;
  onClose: () => void;
  save: (columns) => void;
}

const useStyles = makeStyles(styles);

const ColumnsPopover = (props: ColumnsPopoverProps): JSX.Element => {
  const classes: ClassNameMap<string> = useStyles();
  const { t } = useTranslation();
  const [provColumns, setProvColumns] = useState<string[]>([]);
  const containerRef = useRef(null);

  useEffect(() => {
    setProvColumns(cloneDeep(props.provColumns));
  }, []);

  const [, connectDrop] = useDrop({
    accept: 'column',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    hover: (draggedColumn: any) => {
      if (!provColumns.find((col) => col === draggedColumn.key)) {
        setProvColumns([...provColumns, draggedColumn.key]);
      }
    },
  });

  connectDrop(containerRef);

  const onExit = () => {
    if (JSON.stringify(provColumns) !== JSON.stringify(props.provColumns)) {
      props.save(provColumns);
    } else {
      props.onClose();
    }
  };

  return (
    <Popover
      open={props.open}
      anchorEl={props.refAnchor}
      onClose={onExit}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{ paper: classes.popoverContainer }}
    >
      <div style={{ maxHeight: '740px', zIndex: 1000 }}>
        <div className={classes.columnsSortDiv} data-testid={'go-back-button'} onClick={onExit}>
          <ArrowForwardIos classes={{ root: classes.arrowBackIcon }} />
          {t('columnsSort')}
        </div>
        <div>
          <div className={classes.containerShowHide}>
            <span className={classes.showHidden}>{t('show')}</span>
            <span
              data-testid={'hide-all-button'}
              className={classes.showHideAll}
              onClick={() => {
                setProvColumns([]);
              }}
            >
              {t('hideAll')}
            </span>
          </div>
          <Divider />
          <ContainerActive provColumns={provColumns} setProvColumns={setProvColumns} allColumns={props.allColumns} />
          <div className={classes.containerShowHide}>
            <span className={classes.showHidden}>{t('hidden')}</span>
            <span
              className={classes.showHideAll}
              data-testid={'show-all-button'}
              onClick={() => {
                setProvColumns(props.allColumns.map((c) => c.key));
              }}
            >
              {t('showAll')}
            </span>
          </div>
          <Divider />
          <ContainerHidden provColumns={provColumns} setProvColumns={setProvColumns} allColumns={props.allColumns} />
        </div>
      </div>
    </Popover>
  );
};

export default ColumnsPopover;
