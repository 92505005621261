/* 

  This function normalizes the skill and subject levels so when they are compared using isEqual, they have the same attributes.
  Also the attributes might come as null or undefined, so this makes them all undefined before comparing

*/

export const normalizeLevels = (obj) => {
  if (!obj) return obj;
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([key]) => key !== '__typename')
      .map(([key, value]) => [key, value === null ? undefined : value]),
  );
};
