import gql from 'graphql-tag';
import { ELEMENT, ELEMENT_TEMPLATE } from 'src/utils/fragments/element';
import { INPUT } from 'src/utils/fragments/input';

export const Q_GET_ALARM_TASKS = gql`
  query GetAlarmTasks {
    getAlarmTasks
  }
`;

export const Q_GET_ALARM_HISTORY = gql`
  query GetAlarmHistory($offset: Int, $limit: Int) {
    getAlarmHistory(offset: $offset, limit: $limit)
    getAlarmHistoryCount
  }
`;

export const Q_CREATE_ALARM_TASK = gql`
  mutation CreateAlarmTask(
    $taskId: String
    $taskStatus: AlarmTaskStatus!
    $measurement: String!
    $tagKey: String!
    $tagValue: String!
    $name: String!
    $triggerType: AlarmTaskTriggerType!
    $triggerWhen: AlarmTaskTriggerWhen
    $triggerShift: String
    $triggerCondition: AlarmTaskTriggerCondition
    $triggerValue: Float
    $triggerRangeValues: AlarmTaskTriggerRangeValuesType
    $triggerPeriod: String
    $triggerFunctionName: AlarmTaskTriggerFunctionName
    $triggerFunctionPeriod: String
  ) {
    createOrUpdateAlarmTask(
      taskId: $taskId
      taskStatus: $taskStatus
      measurement: $measurement
      tagKey: $tagKey
      tagValue: $tagValue
      name: $name
      triggerType: $triggerType
      triggerWhen: $triggerWhen
      triggerShift: $triggerShift
      triggerCondition: $triggerCondition
      triggerValue: $triggerValue
      triggerRangeValues: $triggerRangeValues
      triggerPeriod: $triggerPeriod
      triggerFunctionName: $triggerFunctionName
      triggerFunctionPeriod: $triggerFunctionPeriod
    )
  }
`;

export const Q_DELETE_ALARM_TASK = gql`
  mutation DeleteAlarmTask($taskId: String!) {
    deleteAlarmTask(taskId: $taskId)
  }
`;

export const Q_ELEMENTS_WITH_TAGS = gql`
  query ElementsWithTags {
    elements(where: { tags_some: { name_eq: "" } }) {
      __typename
      name
      _id
      tags {
        __typename
        key
        value
        name
        _id
      }
      site {
        __typename
        name
        coordinates
        _id
      }
    }
  }
`;

export const Q_ELEMENT_WITH_TAG = gql`
  query ElementWithTag($tagValue: String) {
    elements(where: { tags_some: { value_eq: $tagValue } }) {
      _id
    }
  }
`;

export const M_ELEMENT_SEARCH = gql`
  query ElementSearchAlarm($searchString: String!) {
    elements(where: { name_contains: $searchString }) {
      ...element
    }
  }
  ${INPUT}
  ${ELEMENT_TEMPLATE}
  ${ELEMENT}
`;

export const Q_SITE_ELEMENTS = gql`
  query SiteElements($id: ID!) {
    site(where: { _id: $id }) {
      elements {
        _id
        tags {
          __typename
          name
          value
          key
          _id
          unit {
            name
            symbol
            _id
          }
        }
      }
    }
  }
`;

export const Q_TIME_SERIES = gql`
  query TimeSeries($startTimeRange: String, $stopTimeRange: String, $filters: [TimeSeriesFilter!]) {
    timeSeriesQueryBuilder(startTimeRange: $startTimeRange, stopTimeRange: $stopTimeRange, filters: $filters)
  }
`;
