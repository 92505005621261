import * as THREE from 'three';

const moveObjectToCenter = (mesh, YBottomBool = false) => {
  //DESCRIPTION : it will take model and if object position is not center then it will make object in center

  // Create object parent
  const parent = new THREE.Object3D();
  parent.add(mesh);

  // Getting bounding box from scene object
  const box = new THREE.Box3();
  box.setFromObject(mesh);

  // Getting bounding box center
  const center = new THREE.Vector3();
  box.getCenter(center);

  // Reverse center to get translation vector for object to move center
  center.negate();

  mesh.position.copy(center);

  if (YBottomBool) {
    // Getting bounding box from parent object
    const box2 = new THREE.Box3();
    box2.setFromObject(parent);

    const minY = box2.min.y;
    const translateY = -minY;
    mesh.translateY(translateY);
  }
  return parent;
};

export default moveObjectToCenter;
