import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    toolsInUseMainContainer: {
      padding: '14px',
    },
    toolsInUseTitle: {
      color: theme.palette.text.primary,
      textAlign: 'center',
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
      marginTop: '10px',
      marginBottom: '24px',
    },
    toolsInUseSubTitle: {
      color: theme.palette.text.secondary,
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.15px',
      marginBottom: '45px',
    },
    borderBtn: {
      background: `transparent !important`,
      border: `1px solid ${theme.palette.primary.light}`,
      color: theme.palette.primary.main,
      marginLeft: '18px',
      '&:hover': {
        background: `${theme.palette.primary.light} !important`,
      },
    },
  });
