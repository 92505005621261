import styled from '@emotion/styled';
import { CheckCircleOutline, Info, PriorityHigh, WarningAmber } from '@mui/icons-material';
import { VariantType } from 'notistack';
import { ReactNode, useMemo } from 'react';

const StyledSpan = styled.span({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
});

export const ToastMessage = ({ severity, message }: { severity: VariantType; message: ReactNode }) => {
  const color = 'white';

  const icon = useMemo(() => {
    switch (severity) {
      case 'warning':
        return <WarningAmber htmlColor={color} />;
      case 'error':
        return <PriorityHigh htmlColor={color} />;
      case 'success':
        return <CheckCircleOutline htmlColor={color} />;
      case 'info':
        return <Info />;
      case 'default':
      default:
        return <></>;
    }
  }, [severity]);

  return (
    <StyledSpan>
      {icon}
      <span css={{ color }}>{message}</span>
    </StyledSpan>
  );
};
