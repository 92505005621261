import { MainContainerState } from 'src/base/main-container/context/main.container.context';
import { FinishStepModalProps } from '../components/finish.step.modal';
import { changeState } from 'src/modules/issue-execution/func/issue.execution.changeState';
import i18n from 'src/utils/translations/i18n';
import { IssueCatalogDraftConflictHandler } from 'src/gql/graphql';

/**
 * This will handle all the logic so that the user may advance to the next major step
 * @param props
 * @param navigate
 * @param updateMainContainerState
 */
const createOpenIssueDraft = async (
  props: FinishStepModalProps,
  navigate,
  updateMainContainerState: (newData: Partial<MainContainerState>) => void,
) => {
  const issueTemplate = await props.upsertIssueTemplateFromDraft(
    props.loggedUser?.onBoarding?.templateDraft._id,
    true,
    true,
  );

  props.getCatalogFolders(1, 0, { name_contains: 'Drafts' }).then((folders) => {
    props.getStateMachines({ folder: { _id_ne: null } }).then((stMachines) => {
      if (stMachines && stMachines.graphQLErrors) {
        return;
      }
      props
        .createIssueCatalogDraft({
          name: 'New issue',
          folder: folders[0]._id,
          conflictHandler: IssueCatalogDraftConflictHandler.keepBoth,
          stateMachine: stMachines.find((st) => st.name === 'Start and finish manually')._id,
          taskMappings: [],
          scheduler: [],
          assignedAccounts: [props.loggedUser?._id],
          site: props.loggedUser?.authorizedSites[0]._id,
          tasksFallbackStateMachine: stMachines.find((st) => i18n.t(st.name) === 'Start and finish manually')?._id,
        })
        .then((r) => {
          props
            .updateLoggedUser(
              {
                id: props.loggedUser._id,
                onBoarding: {
                  ...props.loggedUser?.onBoarding,
                  step: 'createIssue',
                  availableSteps: ['createTasks', 'execute', 'finish'],
                  templateDraft: null,
                  template: issueTemplate._id,
                  openIssueDraft: r._id,
                  openIssue: null,
                  issueInstance: null,
                  progressTotal: Math.floor(100 / 3),
                  currentSubStep: 0,
                  maxSubStep: 3,
                },
              },
              false,
            )
            .then(() => {
              navigate(`/open?type=draft&idIssue=${r._id}`);
              updateMainContainerState({ currentStep: 'createIssue', open: true });
            });
        });
    });
  });
};

const executeIssue = async (
  props: FinishStepModalProps,
  navigate,
  updateMainContainerState: (newData: Partial<MainContainerState>) => void,
) => {
  updateMainContainerState({ currentStep: 'execute' });
  const issueResp = await props.draftToIssue(props.loggedUser?.onBoarding?.openIssueDraft._id);

  const issueInstance = await props.runIssues([issueResp._id], null, null, null, null, true);

  navigate(`/execution?name=${issueInstance[0].name}&id=${issueInstance[0]._id}`);

  props.updateLoggedUser(
    {
      id: props.loggedUser._id,
      onBoarding: {
        ...props.loggedUser?.onBoarding,
        step: 'execute',
        availableSteps: ['finish'],
        templateDraft: null,
        template: props.loggedUser?.onBoarding?.template._id,
        openIssueDraft: null,
        openIssue: issueResp._id,
        issueInstance: issueInstance[0]._id,
        progressTotal: Math.floor(100 / 3 + 100 / 3),
        currentSubStep: 0,
        maxSubStep: 3,
      },
    },
    false,
  );
  updateMainContainerState({ open: true });
};

export const handleNextStep = async (
  state: MainContainerState,
  props: FinishStepModalProps,
  updateMainContainerState: (newData: Partial<MainContainerState>) => void,
  navigate,
) => {
  switch (state.currentStep) {
    case 'build':
      createOpenIssueDraft(props, navigate, updateMainContainerState);
      break;
    case 'createTasks':
      executeIssue(props, navigate, updateMainContainerState);
      break;
    case 'execute':
      await changeState(
        i18n.t,
        props.changeStateIssueInstance,
        props.loggedUser?.onBoarding?.issueInstance,
        'finish',
        null,
        null,
        null,
        false,
        navigate,
      );
      await props.updateLoggedUser(
        {
          id: props.loggedUser._id,
          onBoarding: {
            ...props.loggedUser?.onBoarding,
            step: 'finish',
            availableSteps: [],
            templateDraft: null,
            template: props.loggedUser?.onBoarding?.template._id,
            openIssueDraft: null,
            openIssue: props.loggedUser?.onBoarding?.openIssue._id,
            issueInstance: props.loggedUser?.onBoarding?.issueInstance._id,
            progressTotal: 100,
          },
        },
        false,
      );
      updateMainContainerState({ openEndStepModal: false, openEndOnBoardingModal: true });
      break;
  }
  updateMainContainerState({ openEndStepModal: false, startSubSteps: false });
};
