import { Fragment, useMemo } from 'react';
import { ConnectedProps } from 'react-redux';
import { closePostComments, CommentType, PostType } from '../../feed.redux';
import { groupBy } from 'lodash';
import { CommentProvider, ShowMoreComments } from './comment';
import { useComponentWillUnmount } from 'src/utils/hooks/useComponentWillUnmount';
import { dateComparator } from '../../helper';
import { EditingProvider } from './comment-draft';
import { useTranslation } from 'react-i18next';
import { styles } from './styles';
import { stringifyUrl } from 'query-string';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import Comment from './comment';
import { typedConnect, useTypedSelector } from 'src/utils/funcs';

type CommentSectionProps = {
  post: PostType;
  singledCommentId?: string;
} & ConnectedProps<typeof connector>;

const useStyles = makeStyles(styles);

const connector = typedConnect(null, { closePostComments });

function CommentSection(props: CommentSectionProps) {
  const { post, singledCommentId } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles();

  const postComments = useTypedSelector((state) => state.feedReducer.comments.filter((c) => c.postId === post._id));

  useComponentWillUnmount(() => {
    props.closePostComments(post._id);
  });

  const groupedComments = useMemo(
    () => groupBy<CommentType>(postComments, (c) => c.parentsTreeIds[0] || null),
    [postComments],
  );

  const commentsToTree = (parent?: string) =>
    groupedComments[parent || 'null']?.sort(dateComparator('createdAt', 'desc')).map((comment) => (
      <Fragment key={comment._id}>
        {parent ? (
          <Comment comment={comment} />
        ) : (
          <CommentProvider postId={post._id} parentId={comment._id}>
            <Comment comment={comment}>{commentsToTree(comment._id)}</Comment>
          </CommentProvider>
        )}
      </Fragment>
    ));

  const commentsTree = useMemo(() => {
    return commentsToTree();
  }, [postComments, singledCommentId]);

  return (
    <>
      {singledCommentId ? (
        <span
          data-testid={'show-all-comments'}
          className={`${classes.blueColor} ${classes.clickable}`}
          onClick={() =>
            navigate(
              stringifyUrl({
                url: `/feed?id=${post._id}`,
              }),
            )
          }
        >
          {t('showAllComments')}
        </span>
      ) : null}
      <EditingProvider>
        <CommentProvider postId={post._id} filterId={singledCommentId} fetchInitial>
          <Comment />
          <ShowMoreComments newest />
          {commentsTree}
          <ShowMoreComments oldest />
        </CommentProvider>
      </EditingProvider>
    </>
  );
}

export default connector(CommentSection);
