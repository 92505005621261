import { CameraAlt, Close, Delete, NoteAdd } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { File as IFile } from 'src/gql/graphql';
import { addFile, getFiles } from 'src/modules/file-storage/file-storage.redux';
import store from 'src/reducer-manager';
import DefaultButton from 'src/utils/components/default-button';
import ButtonAddFile from '../generic-input-card/components/button-add-file';
import ShowFile from '../show-file';
import CameraDialog from './camera-dialog';
import { styles } from './styles';

interface IFileFieldProps extends ConnectedProps<typeof connector> {
  handleChange: (files: string[]) => void;
  deleteAll?: () => void;
  disabled?: boolean;
  editable: boolean;
  keyValue?: string;
  id?: string;
  value: string[];
}

const FileField = (props: IFileFieldProps) => {
  const [progressMap, setProgressMap] = useState<Map<string, number>>(new Map());
  const [uploadingFiles, setUploadingFiles] = useState<IFile[]>([]);
  const [currentFiles, setCurrentFiles] = useState<any[]>([]);
  const [, setUpdate] = useState();
  //@ts-ignore
  const forceUpdate = useCallback(() => setUpdate({}), []);
  const [deleteData, setDeleteData] = useState<boolean>(false);
  const [openCamera, setOpenCamera] = useState<boolean>(false);
  const [allValues, setAllValues] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [expectedValueLength, setExpectedValueLength] = useState<number>(props.value.length);
  const classes = styles();
  const { t } = useTranslation();

  const theme = useTheme();

  const dataURItoBlob = (dataURI: string) => {
    const bytes =
      dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (let i = 0; i < max; i++) ia[parseInt(`${i}`)] = bytes.charCodeAt(i);
    return new Blob([ia], { type: mime });
  };

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      // mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: 'png' });
  };

  const addImage = async (imgSrc: string) => {
    if (!imgSrc) return;
    const thumbnail = dataURItoBlob(imgSrc);
    const file = dataURLtoFile(imgSrc, `image${currentFiles.length + 1}.png`);
    const resp = await props.addFile(file, 1, 1, thumbnail);

    if (resp === undefined) {
      forceUpdate();
      return;
    }
    //@ts-ignore
    if (resp.response && resp.response.data.errors) {
      store.dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          //@ts-ignore
          message: resp.response.data.errors[0].error,
          severity: 'error',
        },
      });
      forceUpdate();
      return;
      //@ts-ignore
    } else if (resp.response && resp.response.status >= 400) {
      store.dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: t('fileUploadFailed'),
          severity: 'error',
        },
      });
      forceUpdate();
      return;
    }

    if (resp.data?.data?.files?.length > 0) {
      const f = resp.data.data.files[0];
      //@ts-ignore
      f.extension = f.name.split('.')[f.name.split('.').length - 1];
      //@ts-ignore
      f.data = window.URL.createObjectURL(new Blob([file]));
      //@ts-ignore
      f.image = <img id={`image${0}`} className={classes.img} src={window.URL.createObjectURL(new Blob([file]))} />;

      props.handleChange([...props.value, f._id]);
      forceUpdate();
    }
  };

  useEffect(() => {
    if (uploadingFiles.length === 0 && allValues.length > 0) {
      props.handleChange([...props.value, ...allValues]);
      setExpectedValueLength(props.value.length + allValues.length);
      setAllValues([]);
    }
  }, [uploadingFiles]);

  useEffect(() => {
    const newLength = props.value.length;
    const diff = newLength - expectedValueLength;
    if (diff !== 0) {
      setLoading(true);
    } else {
      setLoading(false);
      setExpectedValueLength(props.value.length);
    }
  }, [props.value, expectedValueLength]);

  return (
    <div>
      {props.editable && (
        <div className={classes.divButtons}>
          <div
            className={classes.uploadDiv}
            style={{
              cursor: props.disabled ? 'default' : 'pointer',
              border: `1px solid ${props.disabled ? theme.palette.text.disabled : theme.palette.primary.light}`,
            }}
            onClick={() => !props.disabled && setOpenCamera(true)}
          >
            <div className={`${classes.row}`} style={{ cursor: props.disabled ? 'default' : 'pointer' }}>
              <div className={classes.icon} style={{ cursor: props.disabled ? 'default' : 'pointer' }}>
                <CameraAlt
                  classes={{ root: classes.addIcon }}
                  style={{
                    cursor: props.disabled ? 'default' : 'pointer',
                    color: props.disabled ? theme.palette.text.disabled : theme.palette.primary.main,
                  }}
                />
              </div>
              <div
                className={classes.text}
                style={{
                  cursor: props.disabled ? 'default' : 'pointer',
                  color: props.disabled ? theme.palette.text.disabled : theme.palette.primary.main,
                }}
              >
                {t('camera')}
              </div>
            </div>
          </div>
          <ButtonAddFile
            setProgressMap={setProgressMap}
            setUploadingFiles={setUploadingFiles}
            onAdd={async (files) => {
              setAllValues((prev) => [...prev, ...files]);
            }}
            text={'file'}
            Icon={NoteAdd}
          ></ButtonAddFile>
        </div>
      )}

      {props.editable && props.deleteAll && currentFiles.length ? (
        <Delete
          onClick={() => {
            setDeleteData(true);
          }}
          className={classes.deleteAllIcon}
        />
      ) : null}
      {(props.editable || props.disabled) && (
        <ShowFile
          loading={loading}
          setExpectedValueLength={setExpectedValueLength}
          setLoading={setLoading}
          handleChange={(files) => {
            props.handleChange(files.map((f) => f._id));
          }}
          withImage
          noName
          value={props.value}
          progressMap={progressMap}
          uploadingFiles={uploadingFiles}
          noDownload
        ></ShowFile>
      )}
      {deleteData ? (
        <Dialog open={deleteData} onClose={() => setDeleteData(false)} classes={{ paper: classes.dialog }}>
          <DialogTitle>
            <Typography className={classes.dialogHeader}>{t('deleteFiles')}</Typography>
            <IconButton
              aria-label='close'
              className={classes.closeButton}
              onClick={() => setDeleteData(false)}
              size='large'
            >
              <Close fontSize={'small'} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography>{t('actionUndone')}</Typography>
          </DialogContent>
          <DialogActions>
            <DefaultButton id='buttonCancel' data-testid='buttonCancel' onClick={() => setDeleteData(false)} discard>
              {t('cancel')}
            </DefaultButton>
            <DefaultButton
              id='buttonDelete'
              data-testid='buttonDelete'
              remove
              onClick={() => {
                setCurrentFiles([]);
                setDeleteData(false);
                props.deleteAll();
              }}
            >
              {t('delete')}
            </DefaultButton>
          </DialogActions>
        </Dialog>
      ) : null}
      {openCamera ? <CameraDialog onClose={() => setOpenCamera(false)} addImage={addImage} /> : null}
    </div>
  );
};

const connector = connect(null, {
  addFile,
  getFiles,
});

export default connector(FileField);
