import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    tableGrid: {
      width: '100%',
      height: '100%',
      overflowX: 'hidden',
      overflowY: 'auto',
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gridTemplateRows: 'auto',
      border: '0.4px solid #CACACA',
      borderRadius: '5px',

      '& > div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '8px 16px',
        borderBottom: '0.4px solid #CACACA',
      },
    },
    tableHeader: {
      width: '100%',
      backgroundColor: theme.palette.background.background1,
      fontWeight: 500,
    },
  });
