import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      height: '100%',
    },
    listPadd: {
      paddingLeft: '20px',
    },
    divBack: {
      display: 'flex',
      alignItems: 'center',
    },
    arrowLeftIcon: {
      marginRight: '5px',
      color: theme.palette.text.primary,
      cursor: 'pointer',
    },
    noMargin: {
      padding: 0,
      margin: 0,
      '& > *': {
        margin: '0 !important',
      },
    },
    selected: {
      color: theme.palette.primary.main,
    },
    divider: {
      opacity: '0.3 !important',
      color: theme.palette.grey.light,
      borderWidth: 1,
    },
    kaka: {
      opacity: '0.8 !important',
      color: theme.palette.grey.light,
    },
    itemText: {
      margin: 0,
      '& >span': {
        marginLeft: 8,
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontFamily: 'Open sans',
        wordBreak: 'break-all',

        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        maxWidth: '100%',
      },
    },
    itemTextWithPadding: {
      paddingLeft: '32px',
      '& >span': {
        // whiteSpace: 'nowrap',
        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
        // fontFamily: 'Open sans',
        // width: '160px',

        wordBreak: 'break-all',
        display: '-webkit-box',
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 1,
        fontFamily: 'Open sans',
        maxWidth: '23ch',
      },
    },
    loading: {
      height: '100%',
      width: '100%',
    },
    successBtn: {
      position: 'absolute',
      bottom: '5px',
      right: '5px',
    },
    createDiv: {
      color: theme.palette.primary.main,
      paddingLeft: '16px',
      cursor: 'pointer',
    },
    iconLink: {
      color: theme.palette.primary.main,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.common.white,
      position: 'relative',
      display: 'flex',
      height: '100%',
      minHeight: '400px',
    },
    loadMore: {
      minHeight: '35px',
      padding: '10px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        minHeight: '35px',
        padding: '10px',
        cursor: 'pointer',
        zIndex: '1003 !important',
      },
    },
    listItem: {
      height: '40px',
      paddingTop: '2px',
      paddingBottom: '2px',
      paddingLeft: '12px',
      paddingRight: '12px',
      fontFamily: 'Open Sans',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    hoveredColor: {
      color: theme.palette.primary.main,
    },
    rightIcon: {
      color: theme.palette.text.primary,
      marginLeft: 'auto',
    },
    spanParent: {
      alignSelf: 'center',
      fontFamily: 'Open Sans',
      fontSize: '14px',
    },
    spanParentEllipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '-webkit-line-clamp': '2',
      display: '-webkit-box',
      marginRight: '16px',
      '-webkit-box-orient': 'vertical',
    },
    hyphenate: {
      '-ms-hyphens': 'auto',
      '-moz-hyphens': 'auto',
      '-webkit-hyphens': 'auto',
      hyphens: 'auto',
    },
    svgRadioButton: {
      '& .MuiSvgIcon-root': {
        fontSize: '20px !important',
      },
    },
    inputAreaRadioButton: {
      padding: '0 5px 0 0 !important',
      '&.MuiIconButton-root': {
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
    },
    parentDiv: {
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light,
        '& svg': {
          color: theme.palette.primary.main,
        },
      },
    },
    nextItem: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
    },
    tooltipCustom: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.text.secondary,
      maxWidth: 240,
      padding: '4px 8px 4px 8px',
      textAlign: 'center',
      border: `1px solid ${theme.palette.text.secondary}`,
      top: '9px',
    },
    arrowCustom: {
      color: theme.palette.text.secondary,
    },
    searchInput: {
      marginTop: 8,
      padding: '0 16px',
      fontSize: 14,
      '& input': {
        padding: '0 !important',
      },
    },
    tooltip: {
      maxWidth: '400px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '@global': {
      'input::-webkit-input-placeholder,  input::-moz-placeholder': {
        color: `${theme.palette.grey.light} !important`,
        opacity: '1 !important',
      },
    },
    spanNoResults: {
      fontSize: 14,
      fontWeight: 500,
      width: '100%',
      padding: '8px 0px 12px 0px',
      color: theme.palette.grey.main,
      textAlign: 'center',
    },
  });
