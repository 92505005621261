import { createContext, useCallback, useContext, useRef } from 'react';

type Task = () => Promise<any>;

type SequentialContextObject = {
  busy: boolean;
  queue: Task[];
  locked: boolean;
};

const SequentialContext = createContext<React.RefObject<SequentialContextObject>>(null);

export function SequentialContextProvider({ children }) {
  const sequentialRef = useRef<SequentialContextObject>({
    queue: [],
    busy: false,
    locked: false,
  });

  return <SequentialContext.Provider value={sequentialRef}>{children}</SequentialContext.Provider>;
}

/**
 * very useful when you don't want multiple api calls or state changes running at the same time
 */
export function useSequential() {
  const sequentialRef = useContext(SequentialContext);

  //don't allow any more requests after callback, unless it throws an errror
  const queueTask = useCallback((callback: Task) => {
    if (!sequentialRef) {
      callback();
      return;
    }

    const executeQueue = async () => {
      if (!sequentialRef.current.busy && sequentialRef.current.queue.length > 0) {
        sequentialRef.current.busy = true;
        const task = sequentialRef.current.queue.pop();

        try {
          await task();
        } finally {
          sequentialRef.current.busy = false;
          executeQueue();
        }
      }
    };

    sequentialRef.current.queue.unshift(callback);
    executeQueue();
  }, []);

  return { queueTask };
}
