import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Room, RoomStatus } from 'src/interfaces/remote-assistance/room';

export interface RoomUser {
  agoraUid?: string;
  name?: string;
  session_id?: string;
}

export interface RoomStateProps {
  room?: Room;
  roomUser?: RoomUser;
  roomStatus?: RoomStatus;
}

const defaultState: RoomStateProps = {
  room: null,
  roomUser: {
    agoraUid: '',
    name: null,
  },
  roomStatus: RoomStatus.CLEAR,
};

const roomSlice = createSlice({
  name: 'room',
  initialState: defaultState,
  reducers: {
    setRoom(state, action: PayloadAction<Room>) {
      state.room = action.payload;
    },
    setRoomUser(state, action: PayloadAction<RoomUser>) {
      state.roomUser = action.payload;
    },
    setRoomStatus(state, action: PayloadAction<RoomStatus>) {
      state.roomStatus = action.payload;
    },
    clearRoom() {
      return defaultState;
    },
  },
});

export const { setRoom, setRoomUser, setRoomStatus, clearRoom } = roomSlice.actions;

export default roomSlice.reducer;

export interface RoomDispatchProps {
  setRoom?: typeof setRoom;
  setRoomUser?: typeof setRoomUser;
  setRoomStatus?: typeof setRoomStatus;
  clearRoom?: typeof clearRoom;
}
