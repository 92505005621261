import { fileIsImage, fileIsVideo } from '../../utils/show-file-util-functions';
import { File } from 'src/gql/graphql';
import { Close, Edit, PlayArrow, ViewInAr } from '@mui/icons-material';
import { useContext, useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { styles } from './styles';
import { Size } from 'react-virtualized';
import ImageEditor from './image-editor';
import { BlockCloseDropdownContext } from 'src/utils/components/generic-input-card';
import useForceUpdate from 'src/utils/hooks/useForceUpdate';

interface IFileImageProps {
  file: File;
  size?: Size;
  showPlus?: boolean;
  uploadProgress?: number;
  plusFiles?: number;
  allowEdit?: boolean;
  setOpenCarousel?: (file: File) => void;
  onClickPlus?: () => void;
  onClickClose?: () => void;
}
const FileImage = (props: IFileImageProps) => {
  const { file, setOpenCarousel } = props;
  const fileIsAR = ['gltf', 'glb'].includes(file.extension);
  const [isHovered, setIsHovered] = useState(false);
  const [edit, setEdit] = useState(false);
  const classes = styles();

  const setBlockClose = useContext(BlockCloseDropdownContext);

  useEffect(() => {
    setBlockClose?.(edit);
  }, [edit]);

  const handleClick = (e) => {
    e.stopPropagation();
    if (props.showPlus) {
      props.onClickPlus();
    } else {
      if (file) setOpenCarousel(file);
    }
  };

  const handleClickEdit = (e) => {
    e.stopPropagation();
    setEdit(true);
  };

  const forceUpdate = useForceUpdate();

  if (file.hasThumbnail) {
    return (
      <div
        className={classes.imageContainer}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        id={`image-div-${file.name ?? 'noName'}`}
        data-testid={`image-div-${file.name ?? 'noName'}`}
      >
        {!!props.uploadProgress && (
          <CircularProgress
            variant='determinate'
            size={21}
            className={classes.imageLoading}
            value={props.uploadProgress}
          ></CircularProgress>
        )}
        {!props.showPlus && fileIsAR && isHovered && <ViewInAr className={classes.arIcon} />}
        <div
          className={classes.plusDiv}
          style={
            props.showPlus || (isHovered && props.allowEdit && fileIsImage(file))
              ? { backgroundColor: 'rgba(0,0,0, 0.4)' }
              : {}
          }
          onClick={handleClick}
        >
          {props.showPlus && (
            <Typography variant='h2' className={classes.plusText}>
              {'+' + props.plusFiles}
            </Typography>
          )}
          {!props.showPlus && props.allowEdit && isHovered && fileIsImage(file) && (
            <Edit className={classes.editIcon} onClick={handleClickEdit}></Edit>
          )}
        </div>
        <img
          id={`image_${file.name ?? 'noName'}`}
          data-testid={`image_${file.name ?? 'noName'}`}
          onClick={(): void => setOpenCarousel?.(file)}
          className={classes.image}
          src={file.download?.thumbnail?.url || file.download?.url}
          crossOrigin='anonymous'
          style={props.size ? { width: `${props.size.width}px`, height: `${props.size.height}px` } : {}}
        />
        {props.onClickClose && (
          <button className={classes.closeButton} onClick={props.onClickClose}>
            <Close className={classes.close} />
          </button>
        )}
        {!props.showPlus && !props.uploadProgress && fileIsVideo(file) && (
          <div className={classes.playDiv} onClick={(): void => setOpenCarousel?.(file)}>
            <PlayArrow className={classes.playArrow} />
          </div>
        )}
        {edit && file ? (
          <ImageEditor
            file={file}
            close={() => {
              setIsHovered(false);
              setEdit(false);
            }}
            reload={() => {
              forceUpdate();
            }}
          />
        ) : null}
      </div>
    );
  }
  return null;
};

export default FileImage;
