import { createStyles, makeStyles } from '@mui/styles';
import moment from 'moment';
import { useEffect } from 'react';
import useForceUpdate from 'src/utils/hooks/useForceUpdate';

const useStyles = makeStyles((theme) =>
  createStyles({
    online: {
      color: theme.palette.success.main,
    },
  }),
);

export const OnlineStatus = ({ unixTimestamp }: { unixTimestamp?: number }) => {
  const classes = useStyles();

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    forceUpdate();

    const interval = setInterval(forceUpdate, 0.5 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [unixTimestamp]);

  if (!unixTimestamp) {
    return '-';
  }

  const date = moment.unix(unixTimestamp);

  return moment().diff(date, 'minutes') < 1 ? <span className={classes.online}>{'Online'}</span> : date.fromNow();
};
