import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    skillLabelContainer: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.primary,
      fontFamily: 'Open Sans',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
    },
    skillLabelIcon: {
      width: '20px',
      height: '20px',
    },
    selectionIcon: {
      marginLeft: '6px',
      fontSize: '1rem',
    },
    skillLevelOption: {
      padding: '8px 16px',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '&:hover': {
        background: theme.palette.info.light,
      },
    },
    skillLevelOptionSelected: {
      color: `${theme.palette.primary.main} !important`,
    },
    skillLevelTitle: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      color: theme.palette.text.primary,
      marginLeft: '8px',
      letterSpacing: '1.25px',
    },
    selectedSkillLevelTitle: {
      color: theme.palette.primary.main,
    },
    skillLevelTitleSmall: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '10.5px',
      lineHeight: '12px',
      marginLeft: '8px',
      letterSpacing: '0.94px',
    },
  });
