import { APOLLO_CLIENT } from 'config/apollo.config';
import { Action } from 'src/interfaces/reducers';
import {} from 'src/base/notification-dropdown/notification-dropdown.queries';
import { getErrorObject } from 'src/utils/funcs';
import { cloneDeep } from 'lodash';
import { Dispatch } from 'redux';
import { Notification } from '.';
import {
  AcknowledgeNotificationDocument,
  GetConfigurationsDocument,
  GetNotificationDocument,
  GetNotificationsDocument,
  NotViewedNotificationsDocument,
  PushNotification,
  SubscribeNotificationsDocument,
  ViewAllNotificationsDocument,
} from 'src/gql/graphql';
import { GlarThunk } from 'src/reducer-manager';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const GET_CONFIGURATIONS = 'GET_CONFIGURATIONS';
export const ERROR = 'ERROR';

export interface NotificationDropdownReducerProps {
  notifications: Notification[];
  totalNotifications: number;
  firebaseConfigurations?: {
    serverKey: string;
    vapidKey: string;
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  } | null;
}

const initialState: NotificationDropdownReducerProps = {
  notifications: [],
  totalNotifications: 0,
  firebaseConfigurations: null,
};

export default (
  state: NotificationDropdownReducerProps = initialState,
  action: Action,
): NotificationDropdownReducerProps => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications:
          action.payload.skip === 0
            ? action.payload.notifications
            : [...state.notifications, ...action.payload.notifications],
        totalNotifications: action.payload.totalNotifications,
      };
    case UPDATE_NOTIFICATION:
      let notifications = cloneDeep(state.notifications);
      let totalNotifications = state.totalNotifications;
      if (notifications.filter((n) => n._id === action.payload.notification._id).length) {
        notifications = notifications.map((n) =>
          n._id === action.payload.notification._id ? action.payload.notification : n,
        );
      } else {
        totalNotifications = totalNotifications + 1;
        notifications.unshift(action.payload.notification);
      }
      return { ...state, notifications, totalNotifications };
    case GET_CONFIGURATIONS:
      return {
        ...state,
        firebaseConfigurations: action.payload.configurations.firebase.clientConfiguration
          ? JSON.parse(atob(action.payload.configurations.firebase.clientConfiguration))
          : null,
      };
    case ERROR:
    default:
      return state;
  }
};

export const getNotifications = (skip: number, limit?: number) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: { skip, limit: limit || 10 },
      fetchPolicy: 'no-cache',
      query: GetNotificationsDocument,
    });
    dispatch({
      type: GET_NOTIFICATIONS,
      payload: {
        notifications: response.data.myPushNotifications,
        totalNotifications: response.data.myPushNotificationsCount,
        skip: skip,
      },
    });
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    dispatch({
      type: ERROR,
      payload: error,
    });

    return error;
  }
};

export const getNotification =
  (where): GlarThunk<Promise<PushNotification>> =>
  async (dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: { where },
        fetchPolicy: 'no-cache',
        query: GetNotificationDocument,
      });

      return response.data.myPushNotifications[0];
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      dispatch({
        type: ERROR,
        payload: error,
      });

      return error;
    }
  };

export const subscriptionNotifications =
  () =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      return await APOLLO_CLIENT.subscribe({
        query: SubscribeNotificationsDocument,
      }).subscribe({
        next(data) {
          dispatch({
            type: UPDATE_NOTIFICATION,
            payload: { notification: data.data.pushNotifications },
          });
        },
        error(err: string) {
          console.error('err', err);
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      dispatch({
        type: ERROR,
        payload: error,
      });

      return error;
    }
  };

export const acknowledgeNotification = (id: string) => async (dispatch: Dispatch) => {
  try {
    const data = await APOLLO_CLIENT.mutate({
      variables: { _id: id },
      fetchPolicy: 'no-cache',
      mutation: AcknowledgeNotificationDocument,
    });

    dispatch({
      type: UPDATE_NOTIFICATION,
      payload: { notification: data.data.acknowledgePushNotification },
    });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    dispatch({
      type: ERROR,
      payload: error,
    });

    return error;
  }
};

export const hasNotViewedNotification =
  () =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {},
        fetchPolicy: 'no-cache',
        query: NotViewedNotificationsDocument,
      });

      return !!response.data.myPushNotificationsCount;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      dispatch({
        type: ERROR,
        payload: error,
      });

      return error;
    }
  };

export const viewAllNotifications =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await APOLLO_CLIENT.mutate({
        variables: {},
        fetchPolicy: 'no-cache',
        mutation: ViewAllNotificationsDocument,
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      dispatch({
        type: ERROR,
        payload: error,
      });

      return error;
    }
  };

export const getConfiguration = () => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      query: GetConfigurationsDocument,
    });

    dispatch({
      type: GET_CONFIGURATIONS,
      payload: {
        configurations: response.data.getConfigurations,
      },
    });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
  }
};
