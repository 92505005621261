import { useEffect, useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';

interface CanvasProps {
  width: number;
  height: number;
  save: boolean;
  saveImage: (val: string) => void;
  reset: boolean;
  setReset: (val: boolean) => void;
  file;
  setCurrentFiles;
  deleteFile: (file: { _id: string }) => void;
}

type Coordinate = {
  x: number;
  y: number;
};

let coordinates: Coordinate = { x: 0, y: 0 };
let isDrawing = false;

const DrawSignature = ({
  width,
  height,
  save,
  saveImage,
  reset,
  setReset,
  file,
  setCurrentFiles,
  deleteFile,
}: CanvasProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [image, setImage] = useState<boolean>(false);
  const [, setUpdate] = useState();
  //@ts-ignore
  const forceUpdate = useCallback(() => setUpdate({}), []);

  useEffect(() => {
    if (file) {
      setImage(true);
    } else {
      forceUpdate();
      setImage(false);

      canvasRef.current.addEventListener('mousedown', (e) => {
        coordinates = { x: e.offsetX, y: e.offsetY };
        isDrawing = true;
      });
      canvasRef.current.addEventListener('mousemove', (e) => {
        if (isDrawing) {
          drawLine(coordinates, { x: e.offsetX, y: e.offsetY });
          coordinates = { x: e.offsetX, y: e.offsetY };
        }
      });
    }
  }, [file]);

  useEffect(() => {
    if (save) {
      const context = canvasRef.current.getContext('2d');
      if (context) {
        saveImage(canvasRef.current.toDataURL('image/png'));
      }
    }
  }, [save]);

  useEffect(() => {
    if (reset) {
      if (canvasRef.current) {
        const context = canvasRef.current.getContext('2d');
        if (context) {
          context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
          setReset(false);
        }
      } else {
        setImage(false);
        setReset(false);
        deleteFile(file);
        setCurrentFiles([]);
        forceUpdate();
      }
    }
  }, [reset]);

  useEffect(() => {
    if (canvasRef.current) {
      canvasRef.current.addEventListener('mousedown', (e) => {
        coordinates = { x: e.offsetX, y: e.offsetY };
        isDrawing = true;
      });
    }
  }, [coordinates]);

  useEffect(() => {
    if (canvasRef.current) {
      canvasRef.current.addEventListener('mousemove', (e) => {
        if (isDrawing) {
          drawLine(coordinates, { x: e.offsetX, y: e.offsetY });
          coordinates = { x: e.offsetX, y: e.offsetY };
        }
      });
    }
  }, [isDrawing]);

  useEffect(() => {
    window.addEventListener('mouseup', (e) => {
      if (isDrawing) {
        drawLine(coordinates, { x: e.offsetX, y: e.offsetY });
        coordinates = { x: 0, y: 0 };
        isDrawing = false;
      }
    });
  }, []);

  function drawLine(oldCoordinates: Coordinate, newCoordinates: Coordinate) {
    if (canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      context.beginPath();
      context.strokeStyle = 'black';
      context.lineWidth = 1;
      context.moveTo(oldCoordinates.x, oldCoordinates.y);
      context.lineTo(newCoordinates.x, newCoordinates.y);
      context.stroke();
      context.closePath();
    }
  }

  return (
    <div>
      {image ? (
        <div>
          {
            <img
              id={`image${1}`}
              style={{ height: height, width: width }}
              src={file.download?.thumbnail?.url || file.download?.url || file?.data}
            />
          }
        </div>
      ) : (
        <canvas ref={canvasRef} height={height} width={width} />
      )}
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default compose<any>(connect(mapStateToProps, mapDispatchToProps))(DrawSignature);
