import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    mainContainer: {
      width: '100%',
      height: '40px !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: '5px',
      cursor: 'pointer',
      padding: '8px',
      background: theme.palette.background.default,
      fontFamily: 'Open Sans',
      fontWeight: 400,
    },

    enabled: {
      color: theme.palette.text.primary,
      border: `1px solid ${theme.palette.grey.light}`,
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },

    disabled: {
      color: theme.palette.text.disabled,
      border: `1px solid ${theme.palette.text.disabled}`,
      cursor: 'default',
    },

    popoverContainer: {
      width: '280px',
      maxHeight: '250px',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
  });
