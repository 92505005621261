import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export default (theme: Theme) =>
  createStyles({
    'root-menu-container': {
      boxSizing: 'border-box',
      position: 'relative',
      width: '100%',
      fontSize: 14,
      border: `1px solid ${theme.palette.text.disabled}`,
      color: theme.palette.text.primary,
      padding: '8px 16px',
      borderRadius: 4,
      cursor: 'pointer',
      minHeight: '48px',
      display: 'flex',
    },

    'menu-close': {
      display: 'none',
    },

    'menu-open': {
      display: 'block',
    },

    'menu-selector-container': {
      display: 'flex',
      flexDirection: 'row',
      border: '1px solid grey',
      maxWidth: '100%',
    },
    'menu-selector': {
      width: '100%',
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      userSelect: 'none',
    },

    'menu-level-options-container': {
      backgroundColor: theme.palette.background.default,
      // position: 'absolute',
      // marginTop: 5,
      borderRadius: 4,
      // zIndex: theme.zIndex.drawer + 1,
      // boxShadow: `0px 0px 5px ${theme.palette.black.main}`,
      //width: 104,
      //maxWidth: 200,
    },

    'options-main-menu': {
      position: 'relative',
      margin: 0,
      padding: 0,
    },
    'options-container': {
      width: '100%',
      display: 'flex',
      gap: 8,
      alignItems: 'center',
      justifyContent: 'start',
      padding: '8px 8px 8px 16px',
      position: 'relative',
      color: theme.palette.text.primary,

      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        cursor: 'pointer',

        '& > $options-sub-menu-container': {
          display: 'block',
        },
      },
    },
    'options-label': {
      maxWidth: 180,
      wordBreak: 'break-all',
      paddingRight: 10,
    },

    'options-sub-menu-container': {
      backgroundColor: theme.palette.background.default,
      boxShadow: '0px 0px 3px grey',
      borderRadius: 5,
      position: 'absolute',
      width: 175,
      maxWidth: 200,
      top: 0,
      right: 300,
      zIndex: theme.zIndex.drawer + 1,
      transform: 'translate(100%, 0)',
      display: 'none',
    },
    'arrow-right': {
      marginLeft: 'auto',
    },
    popoverFrequencies: {
      maxWidth: '100%',
      maxHeight: '100%',
      overflow: 'unset',
    },
    popoverShadow: {
      borderRadius: '4px',
      boxShadow:
        '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14)',
    },
  });
