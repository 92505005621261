import { CheckCircle } from '@mui/icons-material';
import { Chip, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QrReader from 'react-qr-reader-es6';
import { styles } from './styles';

interface ScanModalProps {
  open: boolean;
  labels: any[];
  inputValues: any;
  isTool: boolean;
  toolItems: any[];
  onClose: () => void;
  handleError: (err: any) => void;
  handleScan: (data: any) => void;
}

const useStyles = makeStyles(styles);

const ScanModal = ({
  open,
  labels,
  inputValues,
  isTool,
  toolItems,
  onClose,
  handleError,
  handleScan,
}: ScanModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const showCounter = useMemo(() => {
    return labels?.length;
  }, [labels]);

  const scannedValues = useMemo(() => {
    const expectedValues = labels?.map((inp) => inp[inp.type]?.values[0]);
    const scanedValues = isTool
      ? expectedValues?.filter((v) => inputValues.find((x) => (x?._id || x) === (v?._id || v)))
      : expectedValues?.filter((v) => inputValues.includes(v));
    return scanedValues || [];
  }, [inputValues, labels, isTool]);

  return (
    <Dialog open={open} onClose={onClose} data-testid={'qrCodeScanModal'} classes={{ paper: classes.dialog }}>
      <DialogTitle>
        <Typography className={classes.dialogTitle}>{t('scanDialog')}</Typography>
      </DialogTitle>
      <DialogContent data-testid={'qrCodeScanModalScan'} style={{ padding: 0, paddingTop: '40px' }}>
        <QrReader delay={500} onError={handleError} onScan={handleScan} />
        {showCounter ? (
          <div className={classes.dialogFooter}>
            <div className={classes.readedNumber}>
              Read {scannedValues.length}/{labels?.length || 0}
            </div>
            <div className={classes.chipContainer}>
              {labels?.map((inp, idx) => (
                <Chip
                  key={idx}
                  size={'small'}
                  classes={{
                    root:
                      inputValues?.find((v) => inp[inp.type]?.values?.includes(v)) ||
                      inputValues?.find((val) =>
                        inp[inp.type]?.values?.some((ival) => (ival?._id || ival) === (val?._id || val)),
                      )
                        ? classes.chipValueScanned
                        : classes.chipValue,
                  }}
                  label={
                    <div
                      className={classNames({
                        [classes.chipTextContainer]: true,
                        [classes.chipTextScanned]:
                          inputValues?.find((v) => inp[inp.type]?.values?.includes(v)) ||
                          inputValues?.find((val) =>
                            inp[inp.type]?.values?.some((ival) => (ival?._id || ival) === (val?._id || val)),
                          ),
                      })}
                    >
                      {isTool
                        ? `${
                            toolItems?.find(
                              (ti) => ti?._id === inp[inp.type]?.values[0]?._id || ti?._id === inp[inp.type]?.values[0],
                            )?.code
                          } - ${
                            toolItems?.find(
                              (ti) => ti?._id === inp[inp.type]?.values[0]?._id || ti?._id === inp[inp.type]?.values[0],
                            )?.tool?.designation
                          }`
                        : inp[inp.type].values}
                      {inputValues?.find((v) => inp[inp.type]?.values?.includes(v)) ||
                      inputValues?.find((val) =>
                        inp[inp.type]?.values?.some((ival) => (ival?._id || ival) === (val?._id || val)),
                      ) ? (
                        <CheckCircle data-testid={'qrCodeChipToolValid'} classes={{ root: classes.checkIcon }} />
                      ) : null}
                    </div>
                  }
                />
              ))}
            </div>
          </div>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default ScanModal;
