import { APOLLO_CLIENT } from 'config/apollo.config';
import { getErrorObject } from 'src/utils/funcs';
import { Dispatch } from 'redux';
import { Action } from 'src/interfaces/reducers';
import { getIssueInstance } from '../agenda/agenda.redux';
import store from 'src/reducer-manager';
import {
  CreateTimesheetDocument,
  DeleteTimesheetDocument,
  Full_TimesheetFragment,
  GetIssueInstancesDropdownDocument,
  GetTimesheetsByUsersDocument,
  GetTimesheetsCountDocument,
  GetTimesheetsFullDocument,
  GetTimesheetStatisticsDocument,
  IssueInstanceWhereInput,
  Timesheet,
  TimesheetCreateInput,
  TimesheetUpdateInput,
  TimesheetWhereInput,
  TimesheetWhereUniqueInput,
  UpdateTimesheetDocument,
} from 'src/gql/graphql';
import { TimesheetFilters } from 'src/utils/components/glar-filter-popover/preset-filters/timesheet-filter';
import i18n from 'src/utils/translations/i18n';

interface TimesheetState {
  filters: TimesheetFilters;
  amAuthorized: boolean;
  lastItemUpdatedAt?: string;
  type: 'mine' | 'issue' | 'team';
  timeline: boolean;
}

const initialState: TimesheetState = {
  filters: {},
  amAuthorized: false,
  lastItemUpdatedAt: null,
  type: 'mine',
  timeline: false,
};

export enum TimesheetActions {
  REFRESH = 'REFRESH_TIMESHEETS',
  SET_FILTERS = 'SET_TIMESHEET_FILTERS',
  SET_AUTHORIZED = 'SET_TIMESHET_AUTHORIZED',
  SET_TYPE = 'SET_TIMESHEET_TYPE',
  SET_TIMELINE = 'SET_TIMELINE',
}

export default (state: TimesheetState = initialState, action: Action): TimesheetState => {
  switch (action.type) {
    case TimesheetActions.REFRESH:
      return {
        ...state,
        lastItemUpdatedAt: action.payload.lastItemUpdatedAt,
      };
    case TimesheetActions.SET_FILTERS:
      return {
        ...state,
        filters: action.payload.filters,
      };
    case TimesheetActions.SET_AUTHORIZED:
      return {
        ...state,
        amAuthorized: action.payload.amAuthorized,
      };
    case TimesheetActions.SET_TYPE:
      return {
        ...state,
        type: action.payload.type,
      };
    case TimesheetActions.SET_TIMELINE:
      return {
        ...state,
        timeline: action.payload.timeline,
      };
    default:
      break;
  }

  return state;
};

export const getTimesheets =
  (where?: TimesheetWhereInput, limit?: number, skip?: number, order?: string) =>
  async (dispatch: Dispatch): Promise<Timesheet[] & { graphQLErrors?: string }> => {
    try {
      const variables = {
        where,
        limit,
        skip: skip || 0,
        orderBy: order ? order : 'startDate_ASC',
      };
      const response = await APOLLO_CLIENT.query({
        variables,
        fetchPolicy: 'no-cache',
        query: GetTimesheetsFullDocument,
      });

      return response.data.timesheets;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getTimesheetsByUser =
  (where?: TimesheetWhereInput, limit?: number, skip?: number, order?: string, filterType?: 'mine' | 'team') =>
  async (
    dispatch: Dispatch,
  ): Promise<{ timesheets: Full_TimesheetFragment[]; found: number; graphQLErrors?: string }> => {
    try {
      const variables = {
        where,
        limit,
        skip: skip || 0,
        orderBy: order,
        filterType,
      };
      const response = await APOLLO_CLIENT.query({
        variables,
        fetchPolicy: 'no-cache',
        query: GetTimesheetsByUsersDocument,
      });

      return response.data.timesheetByUser;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getTimesheetsCount = (where?: TimesheetWhereInput) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      query: GetTimesheetsCountDocument,
    });
    return response.data.timesheetsCount;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getTimesheetStatistics = (issueId: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where: {
          issue_eq: issueId,
        },
      },
      fetchPolicy: 'cache-first',
      query: GetTimesheetStatisticsDocument,
    });

    return response.data.timesheetStatistics;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const createTimesheet = (data: TimesheetCreateInput) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        data,
      },
      fetchPolicy: 'no-cache',
      query: CreateTimesheetDocument,
    });
    return response.data.createTimesheet;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const updateTimesheet = (id: string, data: TimesheetUpdateInput) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        id,
        data,
      },
      fetchPolicy: 'no-cache',
      query: UpdateTimesheetDocument,
    });
    return response.data.updateTimesheet;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const deleteTimesheet = (where: TimesheetWhereUniqueInput) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      query: DeleteTimesheetDocument,
    });

    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: i18n.t('timesheetDeleted'),
        severity: 'success',
      },
    });

    return response.data.deleteOneTimesheet;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getIssuesDropdown =
  (
    name: string | undefined,
    glarID: string | undefined,
    extraFilters: IssueInstanceWhereInput,
    limit: number,
    skip: number,
  ) =>
  async () => {
    const response = await APOLLO_CLIENT.query({
      variables: {
        limit,
        skip,
        where: {
          glarID_contains: glarID,
          name_contains: name,
          closedAt_exists: false,
          needsApproval_eq: false,
          ...extraFilters,
        },
      },
      fetchPolicy: 'no-cache',
      query: GetIssueInstancesDropdownDocument,
    });
    return response.data.issues;
  };

export const getAmAuthorized = (issueId: string) => async (dispatch: Dispatch) => {
  if (issueId) {
    const instance = await getIssueInstance(issueId)(dispatch);
    const loggedUser = store.getState().loginReducer.loggedUser;

    dispatch({
      type: TimesheetActions.SET_AUTHORIZED,
      payload: {
        amAuthorized:
          (!instance['graphQLErrors'] &&
            !!loggedUser &&
            //@ts-ignore
            instance.assignedLabelValues.map((c) => c._id).some((l) => loggedUser?.labelValuesIds?.includes(l))) ||
          instance.assignedAccounts.map((c) => c._id).includes(loggedUser?._id),
      },
    });
  } else {
    dispatch({
      type: TimesheetActions.SET_AUTHORIZED,
      payload: {
        amAuthorized: true,
      },
    });
  }
};

export const setTimesheetViewType = (type: 'issue' | 'mine' | 'team') => (dispatch: Dispatch) => {
  dispatch({ type: TimesheetActions.SET_TYPE, payload: { type } });
};

export const setTimesheetViewTimeline = (timeline: string) => (dispatch: Dispatch) => {
  dispatch({ type: TimesheetActions.SET_TIMELINE, payload: { timeline: timeline == 'true' } });
};

export const triggerRefresh = (item: Timesheet) => async (dispatch: Dispatch) => {
  await APOLLO_CLIENT.clearStore();
  return await dispatch({ type: TimesheetActions.REFRESH, payload: { lastItemUpdatedAt: item.updatedAt?.toString() } });
};

export const setTimesheetSearch = (search: string) => (dispatch: Dispatch, getState) => {
  const oldFilters = getState().timesheetReducer.filters;

  const newFilters: TimesheetFilters = {
    ...oldFilters,
    description: search,
  };
  dispatch({ type: TimesheetActions.SET_FILTERS, payload: { filters: newFilters } });
};

export const setTimesheetFilters = (filters: TimesheetFilters) => ({
  type: TimesheetActions.SET_FILTERS,
  payload: { filters },
});
