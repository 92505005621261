import * as React from 'react';
import { Chip, Divider, InputBase, List, Popover, Typography } from '@mui/material';
import { Add, Search } from '@mui/icons-material';
import { useTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { styles } from 'src/utils/components/selection-presets-popover/styles';
import { Input } from 'src/interfaces/inputs';
import moment from 'moment';
import PresetItem from './components/preset-item';
import ResponseSettingsPopover from './components/response-settings-popover';
import { WithStyles, withStyles } from '@mui/styles';
import { PresetWhereInput } from 'src/gql/graphql';
import {
  withIssueTemplateConnector,
  WithIssueTemplateConnectorProps,
} from 'src/modules/issue-templates/redux/issue.template.draft.redux';

interface SelectionPresetsPopoverProps extends WithIssueTemplateConnectorProps, WithStyles<typeof styles> {
  presets?: PresetWhereInput[];
  popoverAnchorEl?: any;
  setInput: (inp: any) => void;
  input: Input;
  templateId: string;
  setOpen: (val: boolean) => void;
  scoreInputs: boolean;
}

const SelectionPresetsPopover: React.FC<SelectionPresetsPopoverProps> = (props): JSX.Element => {
  const { classes, popoverAnchorEl, presets, setInput, input, templateId, setOpen, issueTemplate } = props;
  const [listItemHovered, setListItemHovered] = React.useState(false);
  const [openEditResponses, setOpenEditResponses] = React.useState(false);
  const [selectedPreset, setSelectedPreset] = React.useState<PresetWhereInput>({
    options: [
      {
        order: 0,
        response: '',
        conditionals: [],
      },
    ],
  });
  const [searchResults, setSearchResults] = React.useState<PresetWhereInput[]>([]);
  const [addPreset, setAddPreset] = React.useState(false);
  const [changesSubmited, setChangesSubmited] = React.useState(false);
  const [updatedPresets, setUpdatedPresets] = React.useState<PresetWhereInput[]>([]);
  const { t } = useTranslation();
  const listRef = React.useRef(null);

  const translatedPresets = issueTemplate.frame.presets.map((preset) => ({
    ...preset,
    options: preset.options.map((option) => ({
      ...option,
      response: t(option.response).toUpperCase(),
    })),
  }));

  const dataToDisplay = searchResults.length ? searchResults : translatedPresets;
  const reversedData = dataToDisplay.toReversed();

  const getPresetResults = (searchTerm: string) => {
    //@ts-ignore
    const filteredResults: Partial<PresetWhereInput[]> = translatedPresets.filter((preset) =>
      preset.options.some((option) => option.response.toLowerCase().includes(searchTerm)),
    );

    setSearchResults(filteredResults);
    setUpdatedPresets(filteredResults);
  };

  React.useEffect(() => {
    getPresetResults('');
  }, [changesSubmited, translatedPresets.length]);

  return (
    <>
      <Popover
        open={!!popoverAnchorEl}
        anchorEl={popoverAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        className={classes.popover}
        classes={{
          paper: classes.popoverContent,
        }}
      >
        <div>
          <InputBase
            autoFocus
            key={'better-dropdown-input'}
            data-testid={'better-dropdown-input'}
            fullWidth
            onChange={async (e) => {
              getPresetResults(e.target.value);
            }}
            classes={{ root: classes.searchInput }}
            placeholder={t('search') + '...'}
            endAdornment={<Search className={classes.searchIcon} />}
          />
          <Divider className={classes.divider} />
        </div>
        <div
          data-testid={'customResponseButton'}
          className={classes.customResponseChipContainer}
          onClick={() => {
            const id = `id-${
              moment().toISOString() + '' + (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
            }`;
            setInput(
              input.type === 'selection'
                ? {
                    _id: input._id,
                    type: 'selection',
                    [input.type]: {
                      options: [{ _id: id, id: id, label: `${t('option')} 1` }],
                      optionsWithScore: props.scoreInputs ? [{ option: `${t('option')} 1`, score: 0 }] : null,
                      values: [],
                      onResponse: [],
                      multiple: false,
                      preset: null,
                    },
                  }
                : {
                    _id: input._id,
                    type: 'selection',
                    selection: {
                      options: [{ _id: id, id: id, label: `${t('option')} 1` }],
                      optionsWithScore: props.scoreInputs ? [{ option: `${t('option')} 1`, score: 0 }] : null,
                      values: [],
                      onResponse: [],
                      multiple: false,
                      preset: null,
                    },
                    [input.type]: undefined,
                  },
            );
            setOpen(false);
          }}
        >
          <Chip
            className={classes.customResponseChip}
            size={'small'}
            classes={{ label: classes.customResponseChipLabel }}
            label={
              <>
                <Add className={classes.customResponseText} />
                <Typography className={classes.customResponseText}>{t('customResponse')}</Typography>
              </>
            }
          />
        </div>
        <Typography className={classes.responsesTitle}>{t('presetResponses')}</Typography>
        <Divider className={classes.divider} />
        <List ref={listRef} className={classes.responsesContainer}>
          {reversedData?.map((preset, index) => (
            <div key={index}>
              <PresetItem
                key={index}
                preset={preset}
                input={input}
                listItemHovered={listItemHovered}
                setInput={setInput}
                scoreInputs={props.scoreInputs}
                setListItemHovered={setListItemHovered}
                setOpenEditResponses={setOpenEditResponses}
                setSelectedPreset={setSelectedPreset}
                setOpen={setOpen}
              />
            </div>
          ))}
        </List>
        <div
          data-testid={'addPresetButton'}
          className={classes.addResponsesBtn}
          onMouseOver={() => setListItemHovered(true)}
          onMouseOut={() => setListItemHovered(false)}
          onClick={() => {
            setSelectedPreset({
              ...selectedPreset,
              options: [
                {
                  order: 0,
                  response: '',
                  conditionals: [],
                },
              ],
            });
            setAddPreset(true);
            setOpenEditResponses(true);
          }}
        >
          <Add className={classes.responsesAdd} />
          <Typography className={classes.responsesAdd}>{t('presetResponses')}</Typography>
        </div>
      </Popover>
      <ResponseSettingsPopover
        openEditResponses={openEditResponses}
        selectedPreset={selectedPreset}
        addPreset={addPreset}
        presets={presets}
        updatedPresets={updatedPresets}
        input={input}
        templateId={templateId}
        setOpenEditResponses={setOpenEditResponses}
        setSelectedPreset={setSelectedPreset}
        setAddPreset={setAddPreset}
        setChangesSubmited={setChangesSubmited}
        setOpen={setOpen}
        originalResponses={[]}
      />
    </>
  );
};

export default compose<any>(
  withTranslation('translation'),
  withStyles(styles),
  withIssueTemplateConnector,
)(SelectionPresetsPopover);
