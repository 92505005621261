import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    '@global': {
      '.MuiList-padding': {
        padding: '0 !important',
      },
    },
    menuTextSize: {
      fontFamily: '"Poppins", sans-serif;',
      minHeight: '54px',
      width: '375px',
      fontWeight: 700,
      fontSize: '13px',
    },
    menuTextSizeAcknowledge: {
      fontSize: theme.typography.fontSize,
      fontFamily: '"Poppins", sans-serif;',
      minHeight: '54px',
      width: '375px',
      backgroundColor: theme.palette.background.background0,
    },
    iconSize: {
      fontSize: 20,
      color: theme.palette.grey.main,
    },
    timeDiv: {
      fontSize: '9px',
      fontFamily: '"Open Sans", sans-serif !important;',
      fontStyle: 'italic',
      width: '100px',
      alignSelf: 'center',
      textAlign: 'center',
      fontWeight: 500,
    },
    divItemContent: {
      display: 'flex',
      width: '90%',
    },
    divItemMsg: {
      width: 'calc(100% - 100px)',
      wordWrap: 'break-word',
      whiteSpace: 'normal',
    },
    pastSubHeader: {
      fontSize: '12px',
      fontFamily: '"Open Sans", sans-serif !important;',
      fontWeight: 700,
      backgroundColor: theme.palette.background.background0,
    },
    divMainIcon: {
      background: theme.palette.error.main,
      borderRadius: '50%',
      width: '10px',
      height: '10px',
      position: 'absolute',
      marginLeft: '15px',
    },
    closeIcon: {
      marginLeft: 'auto',
      marginRight: '10px',
      marginTop: '10px',
    },
    titleDialog: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '20px',
      color: theme.palette.primary.main,
    },
    fieldTitle: {
      fontFamily: 'Open Sans',
      fontWeight: 600,
      marginTop: '30px',
    },
    notificationIcon: {
      display: 'flex',
      fill: theme.palette.text.primary,
      color: theme.palette.text.primary,
      padding: '6px',
    },
  });
