import { uniqBy } from 'lodash';
import React, { createContext, useState } from 'react';
import { useAccountsLastActivityQuery, useSubscribeLastActiveDatesSubscription } from 'src/gql/graphql';

type ActiveDates = Array<{ _id: string; lastActiveDate: number }>;

type ILastActivityContext = { dates: ActiveDates; subscribeTo: React.Dispatch<React.SetStateAction<string[]>> };

export const LastActivityContext = createContext<ILastActivityContext>(null);

export function useAccountActivity(accountIds: string[] = []) {
  const [lastActiveDates, setLastActiveDates] = useState<ActiveDates>([]);

  useAccountsLastActivityQuery({
    variables: { accountIds },
    fetchPolicy: 'no-cache',
    skip: !accountIds.length,
    onCompleted(data) {
      setLastActiveDates(
        data.accountsLastActivity.map((a) => ({
          _id: a._id,
          lastActiveDate: a.lastActiveDate,
        })),
      );
    },
  });

  useSubscribeLastActiveDatesSubscription({
    variables: {
      accountIds,
    },
    skip: !accountIds.length,
    onData({ data }) {
      setLastActiveDates((lastActiveDates) =>
        uniqBy(
          [
            {
              _id: data.data.subscribeAccountActivity._id,
              lastActiveDate: data.data.subscribeAccountActivity.lastActiveDate,
            },
            ...lastActiveDates,
          ],
          '_id',
        ),
      );
    },
  });

  return lastActiveDates;
}
