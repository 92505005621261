import { Row } from '@tanstack/react-table';
import { useRef } from 'react';
import { HTMLAttributes } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { styles } from '../styles';
import makeStyles from '@mui/styles/makeStyles';
import { Popper } from '@mui/material';

interface TableRowProps<TData> extends HTMLAttributes<HTMLTableRowElement> {
  rowData: Row<TData>;
  isDraggable?: boolean;
  onRowDrop?: (args: { src: TData; tgt: TData }) => void;
}

const useStyles = makeStyles(styles);

const TableAbstractRow = <TData,>(props: TableRowProps<TData>): JSX.Element => {
  const { isDraggable, rowData, onRowDrop, ...rest } = props;

  const children = props.children as React.ReactNode;

  const classes = useStyles();

  const dragRef = useRef(null);

  const [, connectDrag] = useDrag(
    () => ({
      type: 'TABLE-ROW',
      item: rowData.original,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [],
  );

  //connectPreview(dragPreview);
  connectDrag(dragRef);

  const [{ isOver }, connectDrop] = useDrop({
    accept: 'TABLE-ROW',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: (item: TData) => {
      if (rowData) {
        onRowDrop({ src: item, tgt: rowData.original });
      }
    },
  });

  connectDrop(dragRef);

  if (!isDraggable) {
    return <tr {...rest}>{children}</tr>;
  }

  return (
    <tr ref={dragRef} {...rest}>
      {children}
      {isOver && (
        <Popper open={isOver} anchorEl={dragRef.current}>
          <div
            className={classes.dragBarPreview}
            style={{ width: dragRef.current.clientWidth, translate: -dragRef.current.clientWidth / 2 }}
          ></div>
        </Popper>
      )}
    </tr>
  );
};

export default TableAbstractRow;
