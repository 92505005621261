import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

export const styles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      width: '65%',
      height: '80%',
      maxWidth: 'initial',
      borderRadius: '4px',
      gap: '24px',
      position: 'relative',
      overflow: 'unset',
    },
    divHeader: {
      minHeight: '42px',
      display: 'flex',
      gap: '8px',
      padding: '10px 24px 10px 24px',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    iconClose: {
      fontSize: '24px',
      color: theme.palette.text.primary,
      cursor: 'pointer',
    },
    iconArrowRight: {
      position: 'fixed',
      right: 0,
      top: 'calc(50% - 70px)',
      fontSize: '140px',
      color: theme.palette.background.background2,
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.common.white,
      },
    },
    video: {
      borderRadius: '4px',
      maxHeight: '600px',
      maxWidth: '600px',
      objectFit: 'contain',
    },
    divImage: {
      display: 'flex',
      flex: 1,
    },
    divModalPreview: {
      display: 'flex',
      flex: '1 0 0',
    },
    image: {
      maxHeight: '100%',
      objectFit: 'contain',
      borderRadius: '4px',
    },
    divImageWithComment: {
      flex: 1,
      //height: '536px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    divFooter: {
      padding: '16px 24px 16px 24px',
      display: 'flex',
      gap: '12px',
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'center',
      backgroundColor: theme.palette.background.background1,
      borderBottomRightRadius: '4px',
      borderBottomLeftRadius: '4px',
    },
    fileTitle: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '18px',
    },
    fileDescription: {
      color: theme.palette.text.secondary,
      lineHeight: '20px',
      letterSpacing: '0.25px',
      fontSize: '14px',
      fontWeight: 400,
      '& span': {
        fontWeight: 700,
      },
    },
    fileInfo: { gap: '16px', display: 'flex', width: '100%', justifyContent: 'space-between' },

    divImageNotFound: {
      height: '602px',
      width: '490px',
      textAlign: 'center',
      fontFamily: 'Open Sans',
      backgroundColor: theme.palette.background.background0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    attachIcon: {
      fontSize: '53px',
      color: theme.palette.primary.main,
    },
    zoomIcon: {
      cursor: 'pointer',
      color: theme.palette.text.primary,
    },
    divZoom: {
      position: 'fixed',
      top: 0,
      left: 0,
      display: 'flex',
    },
    inputPosition: {
      width: 'inherit !important',
      height: 'inherit !important',
      objectFit: 'contain',
    },
    divPreview: {
      position: 'absolute',
      top: '10px',
      left: '10px',
      zIndex: 2000,
    },
    iconsAddRemove: {
      color: theme.palette.common.white,
      cursor: 'pointer',
    },
    slider: {
      color: `${theme.palette.common.white} !important`,
    },
    divCloseZoom: {
      position: 'absolute',
      right: '0px',
      top: '54px',
      cursor: 'pointer',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      borderRadius: '50%',
      width: '30px',
      height: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    closeZoom: {
      color: `${theme.palette.common.white} !important`,
    },
    deleteCommentIcon: {
      fontSize: '24px',
      color: `${theme.palette.error.main} !important`,
    },
    commentIcon: {
      color: `${theme.palette.primary.light} !important`,
    },
    buttonIcon: {
      border: `1px solid ${theme.palette.text.primary}`,
      padding: '5px',
    },
    dialogComment: {
      width: '800px',
      height: '300px',
    },
    commentDialogTitle: {
      fontHeight: 500,
      fontSize: '20px',
      fontFamily: 'Poppins',
    },
    commentDialogBody: {
      display: 'flex',
      margin: '30px',
    },
    divComment: {
      width: '100%',
      marginLeft: '20px',
      '& > div': {
        height: '100%',
        '& > div': {
          height: '100%',
        },
        '& > p': {
          position: 'absolute',
          bottom: 0,
          right: 0,
        },
      },
    },
    showComment: {
      backgroundColor: theme.palette.text.secondary,
      width: '100%',
      height: '50px',
      fontFamily: 'Open Sans',
      color: theme.palette.background.default,
      paddingLeft: '15px',
      paddingRight: '15px',
      paddingTop: '6px',
    },
    showCommentReadMore: {
      backgroundColor: theme.palette.text.secondary,
      width: '100%',
      fontFamily: 'Open Sans',
      color: theme.palette.background.default,
      paddingLeft: '15px',
      paddingRight: '15px',
      paddingTop: '6px',
      position: 'absolute',
      bottom: '65px',
    },
    comment: {
      wordBreak: 'break-all',
    },
    readMore: {
      fontFamily: 'Open Sans',
      fontSize: '13px',
      fontWeight: 700,
      cursor: 'pointer',
    },
    attachIconFile: {
      width: '107px',
      height: '112px',
      textAlign: 'center',
      fontFamily: 'Open Sans',
      backgroundColor: theme.palette.background.background1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    icons: {
      display: 'flex',
      gap: '16px',
      '& svg': {
        cursor: 'pointer',
      },
    },
    circularButton: {
      minWidth: '50px',
      height: '50px',
      borderRadius: '50%',
      backgroundColor: theme.palette.grey.light,
      opacity: '87%',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      '&:hover': {
        backgroundColor: theme.palette.grey.light,
      },
    },
    arrow: {
      color: theme.palette.text.primary,
      fontSize: '24px',
      opacity: '87%',
    },
    container: { display: 'flex', flexDirection: 'column', height: '100%' },
    arRenderContainer: { width: '100%', height: '100%' },
    amountOfFiles: { fontFamily: 'Poppins', fontWeight: '500', fontSize: '16px', lineHeight: '26px' },
  }),
);
