import { MainContainerState } from '../context/main.container.context';

export const mainContainerContextInitialValue: MainContainerState = {
  fullpage: false,
  blankPage: false,
  hideSteps: false,
  openModalIntroduction: false,
  openModalLeaveTutorial: false,
  anchorEl: null,
  open: false,
  placement: null,
  textDialog: { title: '', content: '', flag: '' },
  doingOnBoarding: false,
  sidenavMinimized: false,
  openEndStepModal: false,
  openNoTaskAndInputModal: false,
  currentStep: null,
  openEndOnBoardingModal: false,
  startSubSteps: false,
  generateSubComponent: '',
  headerLeftComponent: undefined,
  headerRightComponent: undefined,
};
