import { CalendarToday, Visibility, VisibilityOff } from '@mui/icons-material';
import {
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme, withStyles } from '@mui/styles';
import moment from 'moment';
import * as React from 'react';
import { Ref, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Options } from 'react-select';
import { styles } from 'src/utils/components/input-field/input-box/styles';
import SelectBox from 'src/utils/components/select-box';

interface InputBoxProps {
  id?: string;
  type?: string;
  values?: string[];
  onChange: (arg?: any) => void;
  disabled?: boolean;
  onBlur?: (arg?: any) => void;
  min?: number;
  max?: number;
  style?: any;
  className?: any;
  classNameContainer?: any;
  placeholder: string;
  onKeyDown?: any;
  onKeyPress?: any;
  onClick?: any;
  defaultValue?: any;
  template?: any;
  mouseOver?: boolean;
  minDate?: Date;
  autoFocus?: boolean;
  otherId?: string;
  classes?: any;
  error?: boolean;
  onFocus?: (e?: React.FocusEvent) => void;
  multiple?: boolean;
  options: any[];
  editableOptions?: boolean;
  setOptions?: (type: string, e) => Promise<any>;
  setScoreOptions?;
  extended?: boolean;
  unit?: {
    symbol: string;
  };
  multiline?: boolean;
  showPassword?: boolean;
  icon?: any;
  addCondition?: (opt: any) => void;
  validations?: any;
  refCreateAction?: any;
  refCreateAlert?: any;
  refNewAnswer?: any;
  refFailResponse?: any;
  refCancelIssue?: any;
  refFinishIssue?: any;
  setActionRequired?: (item: any) => void;
  setAlert?: (item: any) => void;
  addFailResponse?: (item: any) => void;
  setCancelIssue?: (item: any) => void;
  refExecuteNewTask?: any;
  addNewTask?: (item: any) => void;
  setFinishIssue?: (item: any) => void;
  required?: boolean;
  maxDate?: Date;
  scoreInputs?: boolean;
  scoreOptions?;
  disabledBorderColor?: boolean;
  isOptionDisabled?: (option: string, selectValue: Options<string>) => boolean;
  errorMessage?: string;
  helperText?: JSX.Element;
  setOpts?: (options: string[]) => void;
  innerRef?: Ref<HTMLInputElement>;
  inputMaxLength?: number;
  autocomplete?: string;
  loggedUser;
}

export function toCurrentOffset(date) {
  let result = moment(date);
  result = result.local();
  /* if (moment().isDST()) {
    result = result.add(moment().utcOffset(), 'minutes');

    const janOffset = moment({ month: 0, day: 1 }).utcOffset();
    const junOffset = moment({ month: 5, day: 1 }).utcOffset();
    result = result.subtract(Math.abs(junOffset - janOffset), 'minutes');
  } */

  return new Date(result.format());
}

export function toDateOffset(date, skipValidations: boolean = false, customTimeZone?: string) {
  let result;

  if (skipValidations === true) {
    let resultSkip = moment(date).local(true);
    resultSkip = resultSkip.utc();
    return resultSkip.format();
  }

  if (moment(date, moment.ISO_8601, true).isValid() || moment(date, 'DD/MM/YYYY HH:mm', true).isValid()) {
    if (customTimeZone?.length) {
      result = moment(date, moment.ISO_8601, true).tz(customTimeZone).toISOString();
    } else {
      result = moment(date, moment.ISO_8601, true).local(true);
    }
  } else {
    if (customTimeZone?.length) {
      result = moment.parseZone(date).tz(customTimeZone).toISOString();
    } else {
      result = moment.parseZone(date);
    }
  }
  return result;
}

const InputBox = (props: InputBoxProps) => {
  const {
    type,
    values,
    onChange,
    disabled,
    onBlur,
    min,
    max,
    style,
    className,
    classNameContainer,
    placeholder,
    onKeyDown,
    onKeyPress,
    onClick,
    id,
    mouseOver,
    minDate,
    autoFocus,
    otherId,
    error,
    onFocus,
    multiple,
    options,
    extended,
    editableOptions,
    setOptions,
    unit,
    multiline,
    showPassword,
    icon,
    addCondition,
    validations,
    setAlert,
    addFailResponse,
    setCancelIssue,
    addNewTask,
    setFinishIssue,
    required,
    maxDate,
    scoreInputs,
    setScoreOptions,
    scoreOptions,
    disabledBorderColor,
    isOptionDisabled,
    errorMessage,
    inputMaxLength,
    innerRef,
  } = props;
  const [showPass, setShowPass] = useState<boolean>(false);
  const [dateTime, setDateTime] = useState<any>(values);
  const theme = useTheme();
  const { t } = useTranslation();
  const isIssueTemplate = location.hash.includes('/templates/issues');

  const handleChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const sanitizedInput = inputValue.replace(/[^0-9.,-]/g, '');

    onChange([sanitizedInput]);

    if (
      !isNaN(parseFloat(sanitizedInput.replace(',', '.'))) &&
      !sanitizedInput.endsWith('.') &&
      !sanitizedInput.endsWith(',')
    ) {
      const processedInput = sanitizedInput.replace(/,/g, '.');
      onChange([parseFloat(processedInput)]);
    }
  };

  React.useEffect(() => {
    if (type === 'datetime') {
      setDateTime(values);
    }
  }, [values]);
  if (multiple || options?.length) {
    return (
      <SelectBox
        setOpts={props.setOpts}
        mouseOver={disabled}
        disabled={disabled}
        onClick={onClick}
        handleChange={onChange}
        placeholder={placeholder}
        listValues={options}
        multiple={multiple}
        onFocus={onFocus}
        onBlur={onBlur}
        error={error}
        extended={extended}
        values={values}
        validations={validations}
        editableOptions={editableOptions}
        options={options}
        scoreOptions={scoreOptions}
        setOptions={setOptions}
        setScoreOptions={setScoreOptions}
        addCondition={addCondition}
        refCreateAction={props.refCreateAction}
        refCreateAlert={props.refCreateAlert}
        refNewAnswer={props.refNewAnswer}
        refFailResponse={props.refFailResponse}
        refCancelIssue={props.refCancelIssue}
        refExecuteNewTask={props.refExecuteNewTask}
        refFinishIssue={props.refFinishIssue}
        setActionRequired={props.setActionRequired}
        setAlert={setAlert}
        addFailResponse={addFailResponse}
        setCancelIssue={setCancelIssue}
        addNewTask={addNewTask}
        setFinishIssue={setFinishIssue}
        required={required}
        id={id}
        scoreInputs={scoreInputs}
        isOptionDisabled={isOptionDisabled}
        helperText={props.helperText}
      />
    );
  } else {
    switch (type) {
      case 'number':
        return unit?.symbol ? (
          <div className={`${props.classes.header}`}>
            <FormControl
              classes={{
                root:
                  props.classes.fullWidth +
                  ' ' +
                  (disabled ? props.classes.inputGroupDisable : props.classes.inputGroup),
              }}
              onClick={onClick}
            >
              <OutlinedInput
                id={id}
                style={{ ...style, minHeight: '40px' }}
                disabled={!!disabled}
                type={type}
                value={values?.length ? values[0] : ''}
                classes={{
                  adornedEnd: props.classes.adornEnd,
                  root:
                    (disabled ? props.classes.inputDisabledWithBadge : props.classes.inputWithBadge) +
                    ' ' +
                    props.classes.taskTemplateInputColor +
                    ' ' +
                    (mouseOver
                      ? props.classes.inputCursorEnabled
                      : disabled
                        ? props.classes.inputCursorDisabled
                        : null),
                  notchedOutline: error
                    ? props.classes.inputError
                    : disabledBorderColor
                      ? props.classes.disabledBorderColor
                      : props.classes.noBorderColor,
                }}
                className={className || ''}
                onChange={handleChangeQuantity}
                onBlur={(e): void | null =>
                  onBlur ? onBlur(e.target.value ? [parseFloat(e.target.value)] : []) : null
                }
                onFocus={onFocus}
                inputProps={{
                  min,
                  max,
                }}
                placeholder={placeholder.toString()}
                onKeyDown={(e) => {
                  const invalidChars = ['e', 'E', '+', '*', '/', '-'];
                  if (invalidChars.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                // onClick={onClick}
                autoFocus={autoFocus}
                endAdornment={
                  <InputAdornment
                    component={'div'}
                    disableTypography
                    position='end'
                    classes={{ positionEnd: props.classes.inputAddon }}
                  >
                    <Tooltip arrow title={unit.symbol}>
                      <div className={props.classes.numberUnit}>{unit.symbol}</div>
                    </Tooltip>
                    <div className={props.classes.imgDiv}>{icon}</div>
                  </InputAdornment>
                }
              />
              {error && errorMessage ? (
                <FormHelperText error className={props.classes.textError}>
                  <Typography variant='caption'>{errorMessage}</Typography>
                </FormHelperText>
              ) : null}
            </FormControl>
          </div>
        ) : (
          <div className={`${props.classes.header}`}>
            <FormControl
              classes={{
                root:
                  props.classes.fullWidth +
                  ' ' +
                  (disabled ? props.classes.inputGroupDisable : props.classes.inputGroup),
              }}
              onClick={onClick}
            >
              <OutlinedInput
                style={style}
                disabled={!!disabled}
                type={type}
                classes={{
                  root:
                    (disabled ? props.classes.inputDisabled : props.classes.input) +
                    ' ' +
                    (mouseOver
                      ? props.classes.inputCursorEnabled
                      : disabled
                        ? props.classes.inputCursorDisabled
                        : null),
                  notchedOutline: error
                    ? props.classes.inputError
                    : disabledBorderColor
                      ? props.classes.disabledBorderColor
                      : props.classes.noBorderColor,
                }}
                inputProps={{
                  min,
                  max,
                }}
                id={id}
                value={values.length ? values[0] : ''}
                className={className || ''}
                onChange={handleChangeQuantity}
                onBlur={(e): void | null =>
                  onBlur ? onBlur(e.target.value ? [parseFloat(e.target.value)] : []) : null
                }
                onFocus={onFocus}
                placeholder={placeholder.toString()}
                onKeyDown={(e) => {
                  const invalidChars = ['e', 'E', '+', '*', '/', '-'];
                  if (invalidChars.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                // onClick={onClick}
                autoFocus={autoFocus}
              />
              {error && values.some((v) => parseInt(v) < 0) ? (
                <FormHelperText className={props.classes.textError}>
                  <Typography variant='caption'>{t('negativeValues')}</Typography>
                </FormHelperText>
              ) : error && errorMessage ? (
                <FormHelperText error className={props.classes.textError}>
                  <Typography variant='caption'>{errorMessage}</Typography>
                </FormHelperText>
              ) : null}
            </FormControl>
          </div>
        );
      case 'datetime':
        return (
          <FormControl classes={{ root: props.classes.fullWidth }}>
            <DatePicker
              portalId='root-portal'
              className={`${
                disabled
                  ? props.classes.datePickerDisable
                  : error
                    ? props.classes.datePickerError
                    : props.classes.datePicker
              } ${disabled || isIssueTemplate ? props.classes.inputDisabled : props.classes.input} ${className ? className : ''} ${
                mouseOver ? props.classes.inputCursorEnabled : disabled ? props.classes.inputCursorDisabled : ''
              }`}
              disabled={disabled}
              onCalendarClose={() => {
                onChange(dateTime?.length && dateTime[0] ? dateTime : []);
              }}
              dateFormat='dd/MM/yyyy HH:mm'
              //@ts-ignore
              selected={dateTime?.length && dateTime[0] ? toCurrentOffset(dateTime[0]) : null}
              onChange={(e): void => {
                const timeZone = props?.loggedUser?.preferences.timeZone ?? props?.loggedUser?.defaultTimeZone;

                setDateTime(e ? [toDateOffset(e, false, timeZone)] : []);
                //@ts-ignore
                /* onChange(e ? [toDateOffset(e)] : []); */
              }}
              //@ts-ignore
              style={{ color: `${theme.palette.text.primary} !important` }}
              onBlur={(e): void => {
                if (toDateOffset(e.target.value) !== 'Invalid date') {
                  //@ts-ignore
                  onBlur ? onBlur(!!e.target.value ? [toDateOffset(e.target.value)] : []) : null;
                }
              }}
              placeholderText={placeholder ? placeholder.toString() : undefined}
              id={id}
              autoComplete='off'
              onFocus={onFocus}
              showTimeSelect
              timeFormat='HH:mm'
              timeIntervals={15}
              timeCaption='time'
              otherId={'input-date-picker-' + otherId}
              maxDate={maxDate}
              minDate={minDate}
              data-testid={id}
              customInput={
                <Input
                  disableUnderline={true}
                  endAdornment={
                    <InputAdornment position='end'>
                      <CalendarToday />
                    </InputAdornment>
                  }
                />
              }
            />
          </FormControl>
        );
      case 'time':
        const today = moment();

        return (
          <FormControl classes={{ root: props.classes.fullWidth }}>
            <DatePicker
              portalId='root-portal'
              className={`${
                disabled
                  ? props.classes.datePickerDisable
                  : error
                    ? props.classes.datePickerError
                    : props.classes.datePicker
              } ${disabled || isIssueTemplate ? props.classes.inputDisabled : props.classes.input} ${className ? className : ''} ${
                mouseOver ? props.classes.inputCursorEnabled : disabled ? props.classes.inputCursorDisabled : ''
              }`}
              id={id}
              dateFormat='HH:mm'
              disabled={disabled}
              selected={
                values?.length && values[0].split(':').length > 1
                  ? moment({
                      year: today.get('year'),
                      month: today.get('month'),
                      day: today.get('day'),
                      hours: parseInt(values[0].split(':')[0]),
                      minutes: parseInt(values[0].split(':')[1]),
                    }).toDate()
                    ? moment({
                        year: today.get('year'),
                        month: today.get('month'),
                        day: today.get('day'),
                        hours: parseInt(values[0].split(':')[0]),
                        minutes: parseInt(values[0].split(':')[1]),
                      }).toDate()
                    : null
                  : null
              }
              onChange={(e): void => {
                //@ts-ignore
                onChange(e ? [moment(e).format('HH:mm:ss') + 'Z'] : []);
              }}
              onBlur={(e): void | null =>
                onBlur
                  ? //@ts-ignore
                    onBlur(e ? [moment(e).format('HH:mm:ss') + 'Z'] : [])
                  : null
              }
              onFocus={onFocus}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              minDate={minDate}
              customInput={
                <Input
                  disableUnderline={true}
                  endAdornment={
                    <InputAdornment position='end'>
                      <CalendarToday />
                    </InputAdornment>
                  }
                />
              }
            />
          </FormControl>
        );
      case 'date':
        return (
          <FormControl classes={{ root: props.classes.fullWidth }}>
            <DatePicker
              portalId='root-portal'
              className={`${
                disabled
                  ? props.classes.datePickerDisable
                  : error
                    ? props.classes.datePickerError
                    : props.classes.datePicker
              } ${disabled || isIssueTemplate ? props.classes.inputDisabled : props.classes.input} ${className ? className : ''} ${
                mouseOver ? props.classes.inputCursorEnabled : disabled ? props.classes.inputCursorDisabled : ''
              }`}
              id={id}
              dateFormat='dd/MM/yyyy'
              //@ts-ignore
              selected={values?.length ? Date.parse(values[0]) : null}
              onChange={(e): void => {
                //@ts-ignore
                onChange(e ? [moment(e).format('YYYY-MM-DD')] : []);
              }}
              onBlur={(e): void | null =>
                //@ts-ignore
                onBlur ? onBlur(e ? [moment(e).format('YYYY-MM-DD')] : []) : null
              }
              placeholderText={placeholder ? placeholder.toString() : undefined}
              onFocus={onFocus}
              disabled={disabled}
              minDate={minDate}
              otherId={'input-date-picker-' + otherId}
              customInput={
                <Input
                  disableUnderline={true}
                  endAdornment={
                    <InputAdornment position='end'>
                      <CalendarToday />
                    </InputAdornment>
                  }
                />
              }
            />
          </FormControl>
        );
      default:
        return (
          <div
            style={multiline ? { height: 'unset' } : {}}
            className={`${props.classes.header} ${classNameContainer ? classNameContainer : ''}`}
          >
            <FormControl
              style={
                multiline
                  ? {
                      height: 'unset',
                    }
                  : {}
              }
              classes={{
                root:
                  props.classes.fullWidth +
                  ' ' +
                  (disabled ? props.classes.inputGroupDisable : props.classes.inputGroup),
              }}
              onClick={onClick}
            >
              <OutlinedInput
                id={id}
                style={multiline ? { ...style, height: 'fit-content ', padding: '0' } : style}
                disabled={!!disabled}
                type={showPassword && type === 'password' && showPass ? 'text' : type}
                multiline={multiline}
                // onMouseMove={(e): void => {
                //   e.preventDefault();
                // }}
                label={''}
                notched={false}
                classes={{
                  root:
                    (disabled ? props.classes.inputDisabled : props.classes.input) +
                    ' ' +
                    (type !== 'textarea' ? props.classes.height : null) +
                    ' ' +
                    (mouseOver ? props.classes.inputCursorEnabled : disabled ? props.classes.inputCursorDisabled : ''),
                  notchedOutline: error ? props.classes.inputError : props.classes.noBorderColor,
                }}
                onChange={(e): void => {
                  if (typeof onChange === 'function') {
                    onChange(e.target.value ? [e.target.value] : []);
                  } else {
                    console.error(`This input onChange is of type "${typeof onChange}" instead of "function"`);
                  }
                }}
                value={values?.length ? values[0] : ''}
                onBlur={(e): void | null => (onBlur ? onBlur(e.target.value ? [e.target.value] : []) : null)}
                inputProps={{ 'data-testid': id, className: props.classes.inputOverride, maxLength: inputMaxLength }}
                onFocus={onFocus}
                placeholder={placeholder.toString()}
                onKeyDown={onKeyDown}
                margin={'none'}
                inputRef={innerRef}
                autoComplete={props.autocomplete}
                // onClick={onClick}
                autoFocus={autoFocus}
                endAdornment={
                  showPassword ? (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowPass(!showPass)}
                        edge='end'
                        classes={{ root: props.classes.buttonView }}
                        size='large'
                      >
                        {showPass ? (
                          <Visibility classes={{ root: props.classes.visibilityIcons }} />
                        ) : (
                          <VisibilityOff classes={{ root: props.classes.visibilityIcons }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ) : null
                }
              />
              {error && errorMessage ? (
                <FormHelperText error className={props.classes.textError}>
                  <Typography variant='caption'>{errorMessage}</Typography>
                </FormHelperText>
              ) : null}
            </FormControl>
          </div>
        );
    }
  }
};

const connector = connect(
  (state: any) => ({
    loggedUser: state.loginReducer.loggedUser,
  }),
  {},
);

export default connector(withStyles(styles)(InputBox));
