import { Announcement, Attachment, ChevronRight, Close, Create, FeedbackOutlined, History } from '@mui/icons-material';
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputLabel,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { debounce, pick } from 'lodash';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Anchorme } from 'react-anchorme';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { useMainContainerContext } from 'src/base/main-container/context/main.container.context';
import ModalCreateAction from 'src/modules/actions/components/create-modal';
import ActionField from 'src/utils/components/action-field';
import ActionsPopover from 'src/utils/components/actions-popover';
import CheckboxField from 'src/utils/components/checkbox-field';
import DefaultButton from 'src/utils/components/default-button';
import DescriptionField from 'src/utils/components/description-field';
import ElementShow from 'src/utils/components/element-show';
import FileField from 'src/utils/components/file-field';
import InputBox from 'src/utils/components/input-field/input-box';
import { styles } from 'src/utils/components/input-field/input-generic/styles';
import PhotoField from 'src/utils/components/photo-field';
import QrCode from 'src/utils/components/qrCode';
import ShowFile from 'src/utils/components/show-file';
import SignatureField from 'src/utils/components/signature-field';
import SiteShow from 'src/utils/components/site-show';
import TableField from 'src/utils/components/table-field';
import i18n from 'src/utils/translations/i18n';
import BetterFieldPopover from '../../better-field-popover';
import { PlaceholderInputSitesElements } from '../../better-field-popover/placeholders';
import ToolField from '../../tool-field';
import ToolShow from '../../tool-show';
import InputHistory from '../input-history';
import NoteInput from './components/note-input';
import InputFieldGenericProps from './types/InputFieldGenericProps';
// import queryString from 'query-string';

const InputFieldGeneric: React.FC<InputFieldGenericProps> = (props) => {
  const {
    input,
    mouseOver,
    checkbox,
    inline,
    type,
    editable,
    size,
    placeholder,
    createMode,
    updateMode,
    disabled,
    disableNotes,
    classNameInput,
    multiple,
    id,
    extended,
    onlyInput,
    noBorder,
    onlyText,
    editableOptions,
    options,
    setScoreOptions,
    error,
    values,
    validations,
    cursorEnable,
    multiline,
    showPassword,
    icon,
    maxDate,
    scoreInputs,
    scoreOptions,
    minDate,
    disabledBorderColor,
    history,
    min,
    task,
    issue,
    actions,
    setAlert,
    action,
    reset,
    handleChange,
    onClick,
    onBlur,
    onFocus,
    onKeyPress,
    onKeyDown,
    setOptions,
    addCondition,
    innerRef,
    setActionRequired,
    setCancelIssue,
    addFailResponse,
    addNewTask,
    setFinishIssue,
    setDotRed,
    handleNotes,
    setActionsOfInputs,
    isOptionDisabled,
    errorMessage,
    onLinkAction,
    max,
    inputMaxLength,
    autoComplete,
  } = props;

  const [addNote, setAddNote] = useState<boolean>(input?.note?.text ? true : false);
  const [addPhoto, setAddFiles] = useState<boolean>(input?.note?.files?.length ? true : false);
  const [note, setNote] = useState<string>(input?.note?.text ? input?.note?.text : null);
  const [fileNotes, setFileNotes] = useState<any>(input?.note?.files ? input?.note?.files : null);
  const [deleteData, setDeleteData] = useState<boolean>(false);
  const [deleteNote, setDeleteNote] = useState<boolean>(false);
  const [createAction, setCreateAction] = useState<boolean>(false);
  const [openActionsPopover, setOpenActionsPopover] = useState<boolean>(false);
  const [historyPopover, setHistoryPopover] = useState<boolean>(false);
  const [editNote, setEditNote] = useState<boolean>(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const auxFileNotes = [];
  fileNotes ? fileNotes.map((file) => (file._id ? auxFileNotes.push(file._id) : auxFileNotes.push(file))) : null;
  const mainContainerState = useMainContainerContext();

  useEffect(() => {
    setFileNotes(input?.note?.files);
  }, [input?.note?.files]);

  useEffect(() => {
    setNote(input?.note?.text);
  }, [input?.note?.text]);

  const handleNoteChangeCommited = (value: string) => {
    setNote(value);
    handleNotes(auxFileNotes, value);
    if (!note) {
      setAddNote(false);
    }
    setEditNote(false);
  };
  const handleChangeFileNotes = (e: any) => {
    setFileNotes(e);
    handleNotes(e, note);
  };

  const handleDeleteData = (): void => {
    if (deleteNote) {
      setNote(null);
      setEditNote(false);
      setAddNote(false);
    } else {
      setFileNotes(null);
    }
    setDeleteData(false);
    handleNotes(deleteNote ? auxFileNotes : null, deleteNote ? null : note);
  };

  const show = (
    type: string,
    values: any,
    unit: { name: string; symbol: string },
    subType: string,
    showRed: boolean,
  ): JSX.Element | '-' => {
    try {
      switch (type) {
        case 'element':
          return !values.length ? null : <ElementShow values={values} />;
        case 'site':
          return !values.length ? null : <SiteShow values={values} />;
        case 'tool':
          return !values.length ? null : <ToolShow values={values} />;
        case 'datetime':
          if (subType === 'time') {
            return !values.length ? (
              <span className={props.classes.textInput}>{i18n.t('time')}</span>
            ) : (
              values.map((v: string, i: number) =>
                values.length === i + 1
                  ? `${v.split(':')[0]}:${v.split(':')[1]}`
                  : `${v.split(':')[0]}:${v.split(':')[1]}, `,
              )
            );
          } else if (subType === 'date') {
            return !values.length ? (
              <span className={props.classes.textInput}>{i18n.t('date')}</span>
            ) : (
              values.map((v: string, i: number) =>
                values.length === i + 1 ? moment(v).format('YYYY/MM/DD') : `${moment(v).format('YYYY/MM/DD')}, `,
              )
            );
          }
          return !values.length
            ? null
            : values.map((v: string, i: number) =>
                values.length === i + 1
                  ? moment(v).format('YYYY/MM/DD HH:mm:ss')
                  : `${moment(v).format('YYYY/MM/DD HH:mm:ss')}, `,
              );
        case 'file':
          return !values.length ? null : (
            <ShowFile
              withImage
              noName
              value={values}
              noBold
              noColon
              /* handleChange={handleChange} */
            />
          );
        case 'instruction':
          return !values.length ? null : (
            <ShowFile
              withImage
              noName
              value={values}
              noBold
              noColon
              noDelete
              noDownload
              progressMap={props.progressMap}
              uploadingFiles={props.uploadingFiles}
              allowReorder={editable}
            />
          );
        case 'signature':
          return !values.length ? null : (
            <SignatureField
              disabled={disabled}
              editable={editable}
              callback={handleChange}
              value={values}
              noBorder={noBorder}
            />
          );
        case 'table':
          return (
            <TableField
              disabled={true}
              id={''}
              editable={false}
              callback={handleChange}
              value={values ? values : []}
              editableOptions={false}
            />
          );
        case 'action':
          return (
            <ActionField
              disabled={true}
              values={values}
              context={props.context}
              placeholder={placeholder}
              reset={() => null}
              fireEvent={() => null}
              onlyText
            />
          );
        case 'selection':
        case 'multiple':
          return extended ? (
            <InputBox
              error={error}
              onFocus={onFocus}
              type={type}
              disabled={true}
              values={values}
              onChange={() => null}
              placeholder={''}
              multiple={type === 'multiple'}
              editableOptions={false}
              options={options || []}
              scoreOptions={scoreOptions || []}
              extended={extended}
              scoreInputs={scoreInputs}
              isOptionDisabled={isOptionDisabled}
              className={classNameInput}
              errorMessage={errorMessage}
            />
          ) : (
            <div style={{ display: 'flex' }}>
              {!values?.length || values[0] === null
                ? ''
                : values.map((v: { name: string }, i: number) =>
                    values.length === i + 1 ? (
                      <Anchorme key={i} target='_blank'>{` ${v?.name || v}`}</Anchorme>
                    ) : (
                      <Anchorme key={i} target='_blank'>{` ${v?.name || v}, `}</Anchorme>
                    ),
                  )}
              {unit ? (
                <div className={`${props.classes.numberUnit} ${props.classes.textInput}`}>{unit.name}</div>
              ) : undefined}
            </div>
          );
        case 'number':
          //@ts-ignore
          return unit ? (
            <div
              style={{ color: showRed ? theme.palette.error.main : null }}
              className={`${props.classes.numberUnit} ${props.classes.textInput}`}
            >
              {`${values.length ? values[0] : ''} ${unit.name ? unit.name : ''}`}
            </div>
          ) : values.length ? (
            <div style={{ color: showRed ? theme.palette.error.main : null }}>{values[0]}</div>
          ) : (
            ''
          );
        default:
          return (
            <div style={{ display: 'flex' }}>
              {!values.length
                ? null
                : values.map((v: { name: string }, i: number) =>
                    values.length === i + 1 ? (
                      <Typography key={i} style={{ color: showRed ? theme.palette.error.main : null }}>{` ${
                        v?.name || v
                      }`}</Typography>
                    ) : (
                      <Typography key={i} style={{ color: showRed ? theme.palette.error.main : null }}>{` ${
                        v?.name || v
                      }, `}</Typography>
                    ),
                  )}
            </div>
          );
      }
    } catch (e) {
      return '-';
    }
  };

  const inputAllValidations = (validations) => {
    const stack = [
      ...validations.filter((validation) => {
        return validation.name === 'linkWith';
      }),
    ];
    const inputsToValidate = [];
    const inputsIds = [];
    const findInputInTaskGroups = (linkWithInput) => {
      //@ts-ignore
      for (const group of issue.taskGroups) {
        for (const task of group.tasks) {
          const input = task.executionInputs.find((input) => input._id === linkWithInput);
          if (input) {
            return input;
          }
        }
      }
      return null;
    };
    while (stack.length > 0) {
      const currentValidation = stack.pop();
      const inputs = currentValidation?.inputs;

      if (inputs) {
        inputs.forEach((input) => {
          const inputValidations = input[input.type]?.validations || [];
          if (inputValidations.length > 0) {
            const linkWithValidation = inputValidations.find((v) => v.name === 'linkWith');
            if (linkWithValidation) {
              linkWithValidation.linkWithInputs.forEach((linkWithInput) => {
                const inputFromTask = findInputInTaskGroups(linkWithInput);
                if (inputFromTask && inputFromTask[inputFromTask.type]?.validations?.length > 0) {
                  stack.push(
                    inputFromTask[inputFromTask.type]?.validations.find((validation) => validation.name === 'linkWith'),
                  );
                } else if (inputFromTask && !inputsIds.includes(inputFromTask._id)) {
                  inputsToValidate.push(inputFromTask);
                  inputsIds.push(inputFromTask._id);
                }
              });
            }
          } else {
            if (!inputsIds.includes(input._id)) {
              inputsToValidate.push(input);
              inputsIds.push(input._id);
            }
          }
        });
      }
    }
    return inputsToValidate;
  };

  show.propTypes = {
    values: PropTypes.arrayOf(PropTypes.string),
  };

  const renderInput = (inputToRender: any): JSX.Element | '-' => {
    let data = inputToRender;

    if (!inputToRender) {
      data = {
        type: type,
        //@ts-ignore
        [type]: {
          values: values ? values : [],
          multiple: input ? (input[input?.type].values.length > 0 ? true : false) : multiple,
          options: options,
        },
      };
    }

    const validateNumberIfValues = (ifValues, values, condition) => {
      if (!values.length) return false;
      switch (condition) {
        case 'EQUALS':
          return values[0] === ifValues[0];
        case 'LESS_THAN':
          return values[0] < ifValues[0];
        case 'LESS_EQUAL_THAN':
          return values[0] <= ifValues[0];
        case 'MORE_THAN':
          return values[0] > ifValues[0];
        case 'MORE_EQUAL_THAN':
          return values[0] >= ifValues[0];
        case 'BETWEEN':
          return values[0] > ifValues[0] && values[0] < ifValues[1];
        case 'NOT_BETWEEN':
          return values[0] <= ifValues[0] || values[0] >= ifValues[1];
        default:
          return false;
      }
    };
    if (disabled && onlyText) {
      const showRed =
        data[data.type]?.failOptions?.find((opt) => data[data.type]?.values.find((value) => value === opt)) ||
        (data.type === 'number' &&
          data[data.type].onResponse?.length &&
          data[data.type].onResponse.find((onResp) => onResp.failResponse) &&
          validateNumberIfValues(
            data[data.type].onResponse.find((onResp) => onResp.failResponse).ifValues,
            data[data.type]?.values,
            data[data.type].onResponse.find((onResp) => onResp.failResponse).condition,
          ));

      if (setDotRed) setDotRed(showRed);
      return show(
        data.type,
        data[data.type]?.values.length ? data[data.type].values : [],
        data[data.type]?.unit,
        data.subType,
        showRed,
      );
    }
    switch (data.type) {
      case 'element':
        return (
          <BetterFieldPopover
            context={'element'}
            placeholder={<PlaceholderInputSitesElements context='element' />}
            classes={{ root: props.classes.popoverHeight }}
            disabled={disabled}
            onChange={(e) => {
              const val = e ? [pick(e, ['_id', '__typename', 'name'])] : [];

              (handleChange || data.handleChange)?.(val);
            }}
            value={data[data.type]?.values?.at(0)}
            error={error}
            data-testid={id ?? 'element-field-' + input.name}
          />
        );
      case 'site':
        return (
          <BetterFieldPopover
            context={'site'}
            placeholder={<PlaceholderInputSitesElements context='site' />}
            data-testid={id ?? 'site-field-' + input.name}
            disabled={disabled}
            onChange={(e) => {
              const val = e ? [pick(e, ['_id', '__typename', 'name'])] : [];

              (handleChange || data.handleChange)?.(val);
            }}
            value={data[data.type]?.values?.at(0)}
            error={error}
          />
        );
      case 'qrCode':
        return (
          <QrCode
            validations={data[data.type].validations}
            inputsToValidate={inputAllValidations(data[data.type].validations)}
            disabled={disabled}
            handleChange={handleChange}
            values={data[data.type].values}
            inputId={data._id}
            taskId={task?._id}
            data={data}
            isTool={data.type === 'tool'}
            issue={issue}
          />
        );
      case 'tool':
        if (
          (data[data.type]?.validations.length && data[data.type]?.validations.find((v) => v.name === 'linkWith')) ||
          data[data.type]?.setValueWithQRCode
        ) {
          return (
            <QrCode
              validations={data[data.type].validations}
              disabled={disabled}
              inputsToValidate={inputAllValidations(data[data.type].validations)}
              handleChange={handleChange}
              values={data[data.type].values}
              inputId={data._id}
              taskId={task?._id}
              data={data}
              isTool={data.type === 'tool'}
              issue={issue}
            />
          );
        }
        return (
          <ToolField
            disabled={disabled}
            error={error}
            values={data[data.type] ? data[data.type].values : []}
            id={id}
            validations={data[data.type].validations}
            inputId={data._id}
            taskId={task?._id}
            handleChange={handleChange}
            data={data}
          />
        );
      case 'action':
        return (
          <ActionField
            mouseOver={createMode || updateMode}
            disabled={disabled}
            fireEvent={action}
            placeholder={placeholder || ''}
            values={data[data.type] ? data[data.type].values : []}
            reset={reset}
            error={error}
            cursorEnable={cursorEnable}
            context={props.context}
            id={id}
          />
        );
      case 'photo':
        return (
          <PhotoField
            mouseOver={createMode || updateMode}
            editable={editable}
            size={size}
            callback={handleChange}
            value={data[data.type].values?.length ? data[data.type].values : []}
            id={id}
          />
        );
      case 'file':
        return (
          <FileField
            disabled={disabled}
            editable={editable}
            handleChange={handleChange}
            value={data[data.type].values.map((v) => v?._id || v)}
            id={id || inputToRender?.name}
          />
        );
      case 'instruction':
        return (
          <div>
            <ShowFile
              withImage
              noName
              value={data[data.type].values}
              noBold
              noColon
              noDownload
              handleChange={handleChange}
              progressMap={props.progressMap}
              uploadingFiles={props.uploadingFiles}
              allowReorder
            />
          </div>
        );
      case 'checkbox':
        return (
          <CheckboxField
            mouseOver={createMode || updateMode}
            disabled={disabled}
            handleChange={handleChange}
            value={data[data.type] ? data[data.type].values : []}
            checkbox={checkbox}
            inline={inline}
            id={id || inputToRender?.name}
          />
        );
      case 'table':
        return (
          <TableField
            disabled={disabled}
            id={id || inputToRender?.name}
            editable={editable}
            callback={handleChange}
            value={data[data.type] ? data[data.type].values : []}
            editableOptions={editableOptions}
            innerRef={innerRef}
          />
        );
      case 'signature':
        return (
          <SignatureField
            mouseOver={mouseOver}
            disabled={disabled}
            editable={editable}
            callback={handleChange}
            instruction={true}
            value={data[data.type] ? data[data.type].values : []}
            validations={validations}
            id={id || inputToRender?.name}
            noBorder={noBorder}
            error={error}
          />
        );
      case 'string':
        if (data[data.type] && data[data.type].setValueWithQRCode) {
          return (
            <QrCode
              validations={data[data.type].validations}
              inputsToValidate={inputAllValidations(data[data.type].validations)}
              disabled={disabled}
              handleChange={handleChange}
              values={data[data.type] ? data[data.type].values : []}
              inputId={data._id}
              taskId={task?._id}
              issue={issue}
              data={data}
            />
          );
        } else {
          return (
            <InputBox
              mouseOver={createMode || updateMode}
              onClick={onClick}
              error={error}
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyPress={onKeyPress}
              onKeyDown={onKeyDown}
              type={data.type}
              disabled={disabled}
              values={data[data.type] ? data[data.type].values : []}
              onChange={handleChange}
              className={classNameInput}
              placeholder={placeholder || ''}
              multiple={data[data.type]?.multiple}
              unit={data[data.type]?.unit || props.unit}
              editableOptions={editableOptions}
              options={options || []}
              scoreOptions={scoreOptions || []}
              autocomplete={autoComplete || 'on'}
              setOptions={setOptions}
              setScoreOptions={setScoreOptions}
              extended={extended}
              validations={validations}
              multiline={multiline}
              id={id}
              inputMaxLength={inputMaxLength}
              icon={icon}
              showPassword={showPassword}
              addCondition={addCondition}
              refCreateAction={props.refCreateAction}
              refNewAnswer={props.refNewAnswer}
              refFailResponse={props.refFailResponse}
              refExecuteNewTask={props.refExecuteNewTask}
              refFinishIssue={props.refFinishIssue}
              setActionRequired={setActionRequired}
              addFailResponse={addFailResponse}
              refCancelIssue={props.refCancelIssue}
              setCancelIssue={setCancelIssue}
              addNewTask={addNewTask}
              setFinishIssue={setFinishIssue}
              required={data.required}
              scoreInputs={scoreInputs}
              isOptionDisabled={isOptionDisabled}
              helperText={props.helperText}
              errorMessage={errorMessage}
              innerRef={props.innerRef}
            />
          );
        }
      case 'session': {
        const formatSession = (value: string) => {
          const digits = String(value).replace(/\D/g, '');
          const formatted = digits.match(/.{1,3}/g)?.join(' ') || '';
          return formatted.slice(0, 11);
        };

        return (
          <InputBox
            mouseOver={createMode || updateMode}
            onClick={onClick}
            error={error}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
            type='text'
            disabled={disabled}
            values={data.session.values ? [formatSession(data.session.values)] : []}
            onChange={handleChange}
            className={classNameInput}
            placeholder='123 456 789'
            validations={validations}
            id={id}
            options={[]}
          />
        );
      }

      default:
        return (
          <InputBox
            mouseOver={createMode || updateMode}
            onClick={onClick}
            min={min}
            max={max}
            error={error}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
            type={data.type}
            disabled={disabled}
            values={data[data.type] ? data[data.type].values : []}
            onChange={handleChange}
            className={classNameInput}
            placeholder={placeholder || ''}
            multiple={data[data.type]?.multiple && !data[data.type]?.setValueWithQRCode}
            unit={data[data.type]?.unit || props.unit}
            editableOptions={editableOptions}
            options={options || []}
            scoreOptions={scoreOptions || []}
            setOptions={setOptions}
            setScoreOptions={setScoreOptions}
            extended={extended}
            validations={validations}
            multiline={multiline}
            id={id}
            icon={icon}
            showPassword={showPassword}
            addCondition={addCondition}
            refCreateAction={props.refCreateAction}
            refCreateAlert={props.refCreateAlert}
            refNewAnswer={props.refNewAnswer}
            refFailResponse={props.refFailResponse}
            refExecuteNewTask={props.refExecuteNewTask}
            setOpts={props.setOpts}
            refFinishIssue={props.refFinishIssue}
            setActionRequired={setActionRequired}
            setAlert={setAlert}
            addFailResponse={addFailResponse}
            refCancelIssue={props.refCancelIssue}
            setCancelIssue={setCancelIssue}
            addNewTask={addNewTask}
            setFinishIssue={setFinishIssue}
            required={data.required}
            maxDate={maxDate}
            minDate={minDate}
            scoreInputs={scoreInputs}
            disabledBorderColor={disabledBorderColor}
            isOptionDisabled={isOptionDisabled}
            errorMessage={errorMessage}
            innerRef={props.innerRef}
          />
        );
    }
  };
  const chipActionStatus = (status) => {
    switch (status) {
      case 'CANT_DO':
        return (
          <Chip
            label={i18n.t('cantDo')}
            size={'small'}
            className={`${props.classes.statusCantDo} ${props.classes.chip}`}
          />
        );
      case 'DOING':
        return (
          <Chip
            label={i18n.t('doing')}
            size={'small'}
            className={`${props.classes.statusDoing} ${props.classes.chip}`}
          />
        );
      case 'SOLVED':
        return (
          <Chip
            label={i18n.t('solved')}
            size={'small'}
            className={`${props.classes.statusSolved} ${props.classes.chip}`}
          />
        );
      case 'PENDING':
      default:
        return (
          <Chip
            label={i18n.t('pending')}
            size={'small'}
            className={`${props.classes.statusPending} ${props.classes.chip}`}
          />
        );
    }
  };

  const getActionsLinkedToInput = () => {
    if (props.allActionsFromIssue?.length > 0) {
      const actionsLinked = props.allActionsFromIssue?.filter((act) =>
        act.issues.find((i) => i.task === task?._id && i.input === input?._id),
      );

      return actionsLinked;
    }

    return [];
  };

  const showAction = (actions) => {
    const lastAction = actions[0];
    return (
      <div data-testid={'show-action-container-' + lastAction.name} className={props.classes.actionContainerDiv}>
        <div style={{ marginRight: '10px' }}>
          <Announcement className={props.classes.iconAnnouncement} />
        </div>

        <div>
          <div>{chipActionStatus(lastAction.status)}</div>
          <div
            data-testid={'show-action-input-' + lastAction.name}
            style={{ display: 'flex', alignItems: 'center', marginTop: '4px', cursor: 'pointer' }}
            onClick={() => {
              navigate(`/action?name=${lastAction.name}&id=${lastAction._id || ''}`);
            }}
          >
            <span className={props.classes.spanName}>{lastAction.name}</span>
          </div>
          {actions.length > 1 ? (
            <div
              className={props.classes.moreActionsDiv}
              data-testid={'show-actions-more-' + props.input?.name}
              onClick={() => setOpenActionsPopover(true)}
            >{`+ ${actions.length - 1} ${i18n.t('action(s)')}`}</div>
          ) : undefined}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
          <ChevronRight
            style={{ fontSize: '33px', cursor: 'pointer' }}
            onClick={() => {
              navigate(`/action?name=${lastAction.name}&id=${lastAction._id || ''}`);
            }}
          />
        </div>
      </div>
    );
  };

  const cantCreateActionFromInput = useMemo(
    () =>
      !onlyInput &&
      input &&
      input[input.type]?.onResponse?.length &&
      input[input.type]?.onResponse[0]?.ifValues?.length &&
      input[input.type]?.onResponse[0]?.ifValues.some((val) => input[input.type].values.includes(val)) &&
      input[input.type]?.onResponse[0]?.actionRequired &&
      mainContainerState.userPermissions.actions === 'viewer',
    [onlyInput, input],
  );

  return onlyInput ? (
    <div data-testid={props['data-testid']}>
      {history ? <div>{input.description ? <DescriptionField description={input.description} /> : null}</div> : null}
      {renderInput(input)}
      {history ? (
        <div style={{ marginTop: '15px' }}>
          {note && (addNote || editNote) ? (
            <>
              <Typography className={props.classes.titleHeader}>{i18n.t('notes')}</Typography>
              <Typography className={props.classes.text}>{note}</Typography>
            </>
          ) : null}
          {fileNotes ? (
            <>
              <Typography className={props.classes.titleHeader}>{i18n.t('files')}</Typography>
              <ShowFile
                withImage
                noName
                value={fileNotes ? fileNotes : []}
                noBold
                noColon
                handleChange={handleChange}
              />
            </>
          ) : null}
        </div>
      ) : null}
      {input && getActionsLinkedToInput().length > 0
        ? showAction(
            getActionsLinkedToInput().sort((a, b) =>
              a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0,
            ),
          )
        : null}
      {openActionsPopover ? (
        <ActionsPopover
          input={input}
          onClose={() => {
            setOpenActionsPopover(false);
            setActionsOfInputs && setActionsOfInputs();
          }}
          issue={issue}
          task={task}
          onLink={onLinkAction}
          onUnlink={props.onUnlinkAction}
        />
      ) : undefined}
    </div>
  ) : (
    <div
      data-testid={props['data-testid']}
      className={`${props.inline ? props.classes.inline : props.classes.flex} ${props.classes.inputContainer} ${
        props.classNameContainer ? props.classNameContainer : ''
      }`}
    >
      {(props.title || input?.name) && (
        <div className={`${props.inline ? props.classes.unflex : ''} ${props.classes.label}`}>
          <InputLabel style={{ width: '100%', whiteSpace: 'unset' }}>
            <span
              className={`${props.title || input.name !== undefined ? null : props.classes.italicUndefined} ${
                props.labelWhite ? props.classes.labelNameWhite : props.classes.labelName
              } ${
                props.task?.stateMachineInstance?.isRunning && props.onlyText
                  ? {}
                  : props.disabled
                    ? props.classes.labelNameDisabled
                    : {}
              }`}
            >
              <Anchorme target='_blank'>
                {`${props.title || input.name !== undefined ? props.title || input.name : i18n.t('undefined')}`}
              </Anchorme>
              <span className={props.classes.requiredSignal}>{props.required ? ' *' : ''}</span>
            </span>
          </InputLabel>
          {props.context === 'Task' ? (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
              <Tooltip title={i18n.t('history')} placement='top' arrow>
                <History
                  classes={{ root: props.classes.historyIcon }}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setHistoryPopover(true);
                  }}
                  data-testid={`input-${props.input?.name}-history`}
                />
              </Tooltip>
            </div>
          ) : undefined}
        </div>
      )}
      {input?.description ? <DescriptionField description={input.description} /> : null}
      <div data-testid={`input-${props.input?.name}`}>{renderInput(input)}</div>
      {cantCreateActionFromInput ? (
        <Typography color={theme.palette.error.main}>{i18n.t('requiresActionButNoPermissions')}</Typography>
      ) : null}
      {addPhoto || (input && getActionsLinkedToInput().length > 0) || addNote ? (
        <Divider className={props.classes.divider} />
      ) : null}
      {addPhoto ? (
        <>
          <FileField
            disabled={disableNotes}
            editable={!disableNotes}
            handleChange={handleChangeFileNotes}
            deleteAll={() => {
              handleDeleteData();
            }}
            value={fileNotes ? fileNotes.map((f) => f._id ?? f) : []}
            id={'photoBox'}
          />
        </>
      ) : null}
      {input && getActionsLinkedToInput().length > 0
        ? showAction(
            getActionsLinkedToInput().sort((a, b) =>
              a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0,
            ),
          )
        : null}
      {addNote ? (
        <div>
          {editNote && (
            <NoteInput
              placeholder={`${i18n.t('addYourNote')}...`}
              value={note}
              disableNotes={disableNotes}
              onDelete={() => {
                setDeleteData(true);
                setDeleteNote(true);
              }}
              onBlur={(value) => {
                debounce(handleNoteChangeCommited, 500)(value);
              }}
            />
          )}
        </div>
      ) : null}
      {note && !editNote && (
        <div className={props.classes.noteDiv} onClick={() => setEditNote(true)}>
          {note}
        </div>
      )}

      {props.context === 'Task' ? (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: '8px' }}>
          {!mainContainerState.doingOnBoarding && mainContainerState.userPermissions.actions !== 'viewer' && (
            <Typography
              className={disableNotes ? props.classes.optionTextDisabled : props.classes.optionText}
              style={{ marginRight: '24px' }}
              onClick={() => !disableNotes && !!task.stateMachineInstance && setCreateAction(true)}
              data-testid={`input-${props.input.name}-createAction`}
            >
              <FeedbackOutlined className={props.classes.notesIcons} />
              {i18n.t('createAction')}
            </Typography>
          )}
          <Typography
            className={disableNotes ? props.classes.optionTextDisabled : props.classes.optionText}
            style={{ marginRight: '24px' }}
            onClick={() => {
              if (!disableNotes && !!task.stateMachineInstance) {
                setEditNote(true);
                setAddNote(!addNote);
              }
            }}
            data-testid={`input-${props.input.name}-addNote`}
          >
            <Create classes={{ root: note ? props.classes.notesIconsActive : props.classes.notesIcons }} />
            {addNote || (note && !editNote) ? i18n.t('hideNote') : i18n.t('addNote')}
          </Typography>
          <Typography
            className={disableNotes ? props.classes.optionTextDisabled : props.classes.optionText}
            onClick={() => {
              if (disableNotes && ((fileNotes && fileNotes.length) || !task.stateMachineInstance)) return;
              setAddFiles(!addPhoto);
              setAddNote(false);
            }}
            data-testid={`input-${props.input.name}-addFile`}
          >
            <Attachment
              classes={{
                root: props.classes.iconSize,
              }}
            />
            {addPhoto ? i18n.t('hidePhoto') : i18n.t('addPhoto')}
          </Typography>
        </div>
      ) : undefined}
      {deleteData ? (
        <Dialog open={deleteData} onClose={() => setDeleteData(false)} classes={{ paper: props.classes.dialog }}>
          <DialogTitle>
            <Typography className={props.classes.dialogHeader}>
              {deleteNote ? i18n.t('deleteNote') : i18n.t('deleteFiles')}
            </Typography>
            <IconButton
              aria-label='close'
              className={props.classes.closeButton}
              onClick={() => setDeleteData(false)}
              size='large'
            >
              <Close fontSize={'small'} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography className={props.classes.dialogContent}>{i18n.t('actionUndone')}</Typography>
          </DialogContent>
          <DialogActions>
            <DefaultButton id='buttonCancel' data-testid='buttonCancel' onClick={() => setDeleteData(false)} discard>
              {i18n.t('cancel')}
            </DefaultButton>
            <DefaultButton id='buttonDelete' data-testid='buttonDelete' onClick={handleDeleteData} remove>
              {i18n.t('delete')}
            </DefaultButton>
          </DialogActions>
        </Dialog>
      ) : null}
      {createAction ? (
        <ModalCreateAction
          onClose={() => {
            setCreateAction(false);
            setActionsOfInputs && setActionsOfInputs();
          }}
          input={input}
          execution={true}
          issue={issue}
          task={task}
          onSave={props.onSaveAction}
          onLinkActions={(actions) => {
            props.onLinkActions(actions);
          }}
        />
      ) : null}
      {openActionsPopover ? (
        <ActionsPopover
          input={input}
          onClose={() => {
            setOpenActionsPopover(false);
            setActionsOfInputs && setActionsOfInputs();
          }}
          issue={issue}
          task={task}
          onUnlink={props.onUnlinkAction}
          onLink={onLinkAction}
        />
      ) : undefined}
      {historyPopover ? (
        <InputHistory
          onClose={() => setHistoryPopover(false)}
          logs={input.log}
          task={task}
          actions={
            actions[input._id] > 0 && props.allActionsFromIssue?.filter((act) => act.input === input._id).length
              ? props.allActionsFromIssue
                  .filter((act) => act.input === input._id)
                  .sort((a, b) => (a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0))
              : []
          }
        />
      ) : null}
    </div>
  );
};

export default compose<any>(withTranslation('translation'), withStyles(styles))(InputFieldGeneric);
