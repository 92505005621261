import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';

const MaterialCreateDefaultRefresh = () => lazy(() => import('src/modules/inventory/material.create.default'));
const ToolCreateDefaultRefresh = () => lazy(() => import('src/modules/inventory/tool.create.default'));
const StorageListDefaultRefresh = () => lazy(() => import('src/modules/inventory/storage.list.default'));
const ToolItemViewDefaultRefresh = () => lazy(() => import('src/modules/inventory/tool-item.view.default'));
const ToolAssignDefaultRefresh = () => lazy(() => import('src/modules/inventory/tool.assign.default'));

let MaterialCreateDefault = MaterialCreateDefaultRefresh();
let ToolCreateDefault = ToolCreateDefaultRefresh();
let StorageListDefault = StorageListDefaultRefresh();
let ToolItemViewDefault = ToolItemViewDefaultRefresh();
let ToolAssignDefault = ToolAssignDefaultRefresh();

export const render = {
  create: {
    material: ({ ...props }) => {
      if (MaterialCreateDefault?._result?.['type'] === 'error') {
        MaterialCreateDefault = MaterialCreateDefaultRefresh();
      }

      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <MaterialCreateDefault {...props} />
        </Suspense>
      );
    },
    tool: (props) => {
      //@ts-ignore //TODO: see what was done on issue-catalogs
      if (ToolCreateDefault?._result?.type === 'error') {
        ToolCreateDefault = ToolCreateDefaultRefresh();
      }
      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <ToolCreateDefault {...props} />
        </Suspense>
      );
    },
  },
  view: {
    storages: ({ ...props }) => {
      if (StorageListDefault?._result?.type === 'error') {
        StorageListDefault = StorageListDefaultRefresh();
      }

      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <StorageListDefault {...props} />
        </Suspense>
      );
    },
    toolItem: ({ ...props }) => {
      if (ToolItemViewDefault?._result?.type === 'error') {
        ToolItemViewDefault = ToolItemViewDefaultRefresh();
      }
      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <ToolItemViewDefault {...props} />
        </Suspense>
      );
    },
  },
  assign: {
    tool: ({ ...props }) => {
      if (ToolAssignDefault?._result?.type === 'error') {
        ToolAssignDefault = ToolAssignDefaultRefresh();
      }
      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <ToolAssignDefault {...props} />
        </Suspense>
      );
    },
  },
};

export default render;
