import gql from 'graphql-tag';

export const Q_GET_FILES = gql`
  query GetFiles($where: FileWhereInput) {
    files(where: $where) {
      name
      _id
      extension
      accessToken
      size
      comment
      createdBy {
        name
      }
      updatedAt
      download {
        url
        validUntil
        thumbnail {
          url
          validUntil
        }
      }
      hasThumbnail
    }
  }
`;

export const Q_GET_FILES_ALT = gql`
  query GetFilesAlt($fileIds: [ID!]) {
    files(where: { _id_in: $fileIds }) {
      name
      _id
      extension
      accessToken
      size
      comment
      createdBy {
        name
      }
      updatedAt
      download {
        url
        validUntil
        thumbnail {
          url
          validUntil
        }
      }
      hasThumbnail
    }
  }
`;

export const Q_GET_FILE = gql`
  query GetFile($where: FileWhereInput) {
    files(where: $where) {
      download {
        url
      }
    }
  }
`;

export const Q_GET_UNIQUE_FILE = gql`
  query GetUniqueFile($where: FileWhereUniqueInput!) {
    file(where: $where) {
      download {
        url
      }
    }
  }
`;

export const M_UPDATE_FILE = gql`
  mutation UpdateFile($where: FileWhereUniqueInput!, $data: FileUpdateInput!) {
    updateFile(where: $where, data: $data) {
      _id
    }
  }
`;

export const M_ADD_FILE_TO_MEDIA_LIBRARY = gql`
  mutation AddfileToMediaLibrary($ids: [ID]!) {
    addFilestoMediaLibrary(ids: $ids) {
      updated
    }
  }
`;

export const M_UPLOAD_FILE_REQUEST = gql`
  mutation UploadFileRequest(
    $name: String!
    $size: Int!
    $thumbnail: Upload
    $comment: String
    $uploadedFromLibrary: Boolean
    $totalTriangles: Int
  ) {
    getUploadFileRequest(
      name: $name
      size: $size
      thumbnail: $thumbnail
      comment: $comment
      uploadedFromLibrary: $uploadedFromLibrary
      polygonCount: $totalTriangles
    ) {
      url
      validUntil
      formData
      _id
    }
  }
`;

export const M_NOTIFY_UPLOAD = gql`
  mutation NotifyUpload($where: FileWhereUniqueInput!, $data: FileUpdateInput) {
    notifyUpload(where: $where, data: $data) {
      _id
      download {
        thumbnail {
          url
          validUntil
        }
        url
      }
    }
  }
`;

export const M_REMOVE_FILE = gql`
  mutation RemoveFile($where: FileWhereUniqueInput!) {
    removeFile(where: $where) {
      _id
    }
  }
`;

export const M_UPLOAD_FILE_REQUEST_UNIQUE = gql`
  mutation UploadFileRequestUnique($where: FileWhereUniqueInput!, $size: Int!, $thumbnail: Upload) {
    getUploadFileRequestForFile(where: $where, size: $size, thumbnail: $thumbnail) {
      url
      validUntil
      formData
      _id
    }
  }
`;
