import { styles } from 'src/utils/components/lazy-loading-content/styles';
import { withStyles } from '@mui/styles';
import { Backdrop, CircularProgress } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LazyLoadingContent = (props: any): JSX.Element => {
  return (
    <Backdrop className={props.classes.backdrop} open={true}>
      <CircularProgress color='inherit' data-testid='lazy-loading-content' />
    </Backdrop>
  );
};

export default withStyles(styles)(LazyLoadingContent);
