import { withStyles } from '@mui/styles';
import { styles } from 'src/base/navbar/components/styles';

export type NavbarBadgeProps = {
  value?: number;
  classes;
};

const NavbarBadge = (props: NavbarBadgeProps) => {
  const { value, classes } = props;

  return <div className={classes.badge}>{value >= 100 ? '99+' : value}</div>;
};

export default withStyles(styles)(NavbarBadge);
