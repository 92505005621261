import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { styles } from 'src/base/on-boarding/components/styles';
import { useMainContainerContext } from 'src/base/main-container/context/main.container.context';
import { updateLoggedUser } from 'src/base/login/login.redux';
import { leaveTutorialMode } from '../utils/onboarding.utils.handleLeaveTutorial';
import DefaultButton from 'src/utils/components/default-button/default-button';
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export type LeaveTutorialModalProps = {
  loggedUser: any;
} & ConnectedProps<typeof connecter>;

const useStyles = makeStyles(styles);
const LeaveTutorialModal: React.FC<LeaveTutorialModalProps> = (props): JSX.Element => {
  const state = useMainContainerContext();
  const { updateMainContainerState } = state;
  const classes: ClassNameMap<string> = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Dialog
      open={state.openModalLeaveTutorial}
      classes={{
        root: classes.leaveTutorialModal,
        paperWidthSm: classes.leaveModalWidth,
      }}
      onClose={() => updateMainContainerState({ openModalLeaveTutorial: false })}
    >
      <DialogTitle>
        <Typography className={classes.leaveModalTitle}>{t('wouldLikeToLeaveTutorial')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography className={classes.leaveModalContent}>{t('youCanGoBackProgressSaved')}</Typography>
      </DialogContent>
      <DialogActions classes={{ root: classes.skipStartContainer }}>
        <DefaultButton
          data-testid={'stay-tutorial-button'}
          variant={'outlined'}
          rounded
          className={classes.defaultBtnPadding}
          onClick={() => updateMainContainerState({ openModalLeaveTutorial: false })}
          label={t('stay')}
        />
        <DefaultButton
          data-testid={'leave-tutorial-button'}
          variant={'delete'}
          rounded
          className={classes.defaultBtnPadding}
          onClick={() => leaveTutorialMode(state, props, updateMainContainerState, navigate)}
          label={t('leave')}
        />
      </DialogActions>
    </Dialog>
  );
};

const connecter = connect(
  (state: any) => ({
    loggedUser: state.loginReducer.loggedUser,
  }),
  {
    updateLoggedUser,
  },
);

export default connecter(LeaveTutorialModal);
