import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    helpDropdownIcon: {
      fill: theme.palette.text.primary,
      color: theme.palette.text.primary,
    },
    helpDropdown: {
      marginTop: '34px',
    },
    helpDropdownList: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    helpDropdownItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: '8px',
      color: theme.palette.text.primary,
      '&:hover': {
        background: theme.palette.primary.light,
      },
    },
    helpDropdownItemIcon: {
      fill: theme.palette.text.primary,
      color: theme.palette.text.primary,
    },
    helpDropdownItemContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      color: theme.palette.text.primary,
    },
    helpDropdownItemContent: {
      fontSize: '12px',
      color: theme.palette.grey.main,
      fontFamily: 'Open Sans',
      lineHeight: '20px',
    },
  });
