import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader.js';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';
import moveObjectToCenter from './utils/threeUtils';

export default function modelToImage(modelUrl) {
  return new Promise<{ base64Data: string; totalTriangles: number }>((resolve) => {
    let camera, scene, renderer;
    init();
    render();

    function init() {
      const container = document.createElement('div');
      container.style.backgroundColor = 'transparent';
      document.body.appendChild(container);

      const width = 200; //window.innerWidth
      const height = 200; //window.innerHeight

      renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(width, height);
      renderer.toneMapping = THREE.ACESFilmicToneMapping;
      const lightStrength = 0.4;
      renderer.toneMappingExposure = lightStrength;
      renderer.outputColorSpace = THREE.SRGBColorSpace;
      container.appendChild(renderer.domElement);

      camera = new THREE.PerspectiveCamera(45, width / height, 1, 20000);
      camera.position.set(-200, 100, 400);

      const environment = new RoomEnvironment();
      const pmremGenerator = new THREE.PMREMGenerator(renderer);

      scene = new THREE.Scene();
      scene.background = null; //new THREE.Color(0xe8e8e8);
      renderer.setClearColor(0x000000, 0); //To enable transparency
      scene.environment = pmremGenerator.fromScene(environment).texture;

      const ktx2Loader = new KTX2Loader().setTranscoderPath('js/libs/basis/').detectSupport(renderer);

      const loader = new GLTFLoader();
      loader.setKTX2Loader(ktx2Loader);
      loader.setMeshoptDecoder(MeshoptDecoder);
      loader.load(
        modelUrl,
        function (gltf) {
          const mesh = gltf.scene;
          const boundingBox = new THREE.Box3();
          boundingBox.setFromObject(mesh);

          const treshhold = 1;

          const vector = new THREE.Vector3();
          const height = boundingBox.getSize(vector).y * treshhold;
          const width = (boundingBox.getSize(vector).x / 1.5) * treshhold;
          const length = (boundingBox.getSize(vector).z / 1.5) * treshhold;

          let maxDimension;
          if (height > width && height > length) {
            maxDimension = height;
          } else if (width > length) {
            maxDimension = width;
          } else {
            maxDimension = length;
          }

          const requiredMaxDimension = 200;
          const requiredScale = requiredMaxDimension / maxDimension;

          mesh.scale.setScalar(requiredScale);

          const boundingBox2 = new THREE.Box3();
          boundingBox2.setFromObject(mesh);

          let totalTriangles = 0;
          mesh.traverse(function (object) {
            if ((object as THREE.Mesh).isMesh) {
              const child = object as THREE.Mesh;
              const geometry = child.geometry;
              if (geometry.index !== null) {
                // If the geometry is indexed, the number of triangles is the count of index / 3
                totalTriangles += geometry.index.count / 3;
              } else if (geometry.attributes.position) {
                // If the geometry is not indexed, the number of triangles is the count of vertices / 3
                totalTriangles += geometry.attributes.position.count / 3;
              }
            }
          });

          const centeredObject = moveObjectToCenter(mesh, true);
          centeredObject.position.y = 8;
          scene.add(centeredObject);
          render();

          const base64Data = renderer.domElement.toDataURL('image/png');
          resolve({ base64Data, totalTriangles });
          renderer.dispose();
        },
        undefined,
        function (error) {
          console.log(error);
          console.error('Error loading GLTF model:', error);
          resolve(null); // Resolve with null in case of an error
        },
      );

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.addEventListener('change', render); // use if there is no animation loop
      controls.minDistance = 400;
      controls.maxDistance = 1000;
      controls.target.set(10, 90, -16);
      controls.update();

      container.parentNode.removeChild(container);
    }

    function render() {
      renderer.render(scene, camera);
    }
  });
}
