import { concat, filter, orderBy, cloneDeep, uniqBy } from 'lodash';
import { APOLLO_CLIENT } from 'config/apollo.config';
import { Action, State } from 'src/interfaces/reducers';
import i18n from 'src/utils/translations/i18n';
import {
  addNewChildren,
  addToTemplate,
  deleteChildren,
  deleteFromParent,
  getErrorObject,
  isLeaf,
  isModalFolderLeaf,
  setChildren,
  setModalFolderChildren,
  updateChildren,
  updateParent,
  addNewChildrenDefault,
  deleteChildrenDefault,
} from 'src/utils/funcs';
import * as queries from 'src/modules/element/element.queries';
import { addSpace } from 'src/utils/funcs/index';
import { ApolloQueryResult } from '@apollo/client';
import { Dispatch } from 'redux';
import { NexusGenFieldTypes, NexusGenInputs } from '../../../../server/src/types';
import { GraphQLErrors } from '@apollo/client/errors';
import { ElementTemplateUpdateInput, ElementTemplateWhereUniqueInput } from 'src/gql/graphql';

export const GET_ELEMENT_ROOT = 'GET_ELEMENT_ROOT';
export const GET_ELEMENTS = 'GET_ELEMENTS';
export const GET_ELEMENT_SEARCH = 'GET_ELEMENT_SEARCH';
export const GET_SITES_ROOT_ELEMENT = 'GET_SITES_ROOT_ELEMENT';
export const GET_ALL_ELEMENT_NAMES = 'GET_ALL_ELEMENT_NAMES';
export const GET_ELEMENT_BY_FATHER_ID = 'GET_ELEMENT_BY_FATHER_ID';
export const MODAL_ELEMENT_TEMPLATE_FOLDERS = 'MODAL_ELEMENT_TEMPLATE_FOLDERS';
export const GET_ELEMENT_TEMPLATE_FOLDERS = 'GET_ELEMENT_TEMPLATE_FOLDERS';
export const CREATE_ELEMENT_TEMPLATE_FOLDER = 'CREATE_ELEMENT_TEMPLATE_FOLDER';
export const UPDATE_ELEMENT_TEMPLATE_FOLDER = 'UPDATE_ELEMENT_TEMPLATE_FOLDER';
export const DELETE_ELEMENT_TEMPLATE_FOLDER = 'DELETE_ELEMENT_TEMPLATE_FOLDER';
export const CREATE_ELEMENT_TEMPLATE = 'CREATE_ELEMENT_TEMPLATE';
export const DELETE_ELEMENT_TEMPLATE = 'DELETE_ELEMENT_TEMPLATE';
export const ARCHIVE_ELEMENT_TEMPLATE = 'ARCHIVE_ELEMENT_TEMPLATE';
export const UPDATE_ELEMENT_TEMPLATE = 'UPDATE_ELEMENT_TEMPLATE';
export const SEARCH_ELEMENT_TEMPLATE_FOLDERS = 'SEARCH_ELEMENT_TEMPLATE_FOLDERS';
export const CREATE_ELEMENT = 'CREATE_ELEMENT';
export const DELETE_ELEMENT = 'DELETE_ELEMENT';
export const UPDATE_ELEMENT = 'UPDATE_ELEMENT';
export const ERROR = 'ERROR';
export const CHANGE_SEARCH_INPUT_VALUE = 'CHANGE_SEARCH_INPUT_VALUE';
export const MODAL_GET_ELEMENTS = 'MODAL_GET_ELEMENTS';
export const UPDATE_SITE_PARENT = 'UPDATE_SITE_PARENT';
export const MOVE_ELEMENTS = 'MOVE_ELEMENTS';

const initialState: State = {
  elements: [],
  searchResults: [],
  allElements: [],
  elementNames: [],
  error: false,
  searchInputValue: '',
  fields: [],
  folders: [],
  modalResults: [],
  elementTemplates: [],
  elementsSearch: [],
  modalElements: [],
};

/**
 * @function ElementsReducer
 * @description Manages the `users` state of the app. Can, in some cases, keep an error value.
 * @param {object} state - The current state of the reducer (alternatively, the initial).
 * @param {object} action - Action to be reduced.
 * @returns {object} - The new UserManagement state.
 */

export const addMultipleChildren = (tree, fatherNodeId, newNodes) =>
  tree.map((node) => {
    if (node.id === fatherNodeId) {
      newNodes.map((newNode) => {
        node.children.push(newNode);
      });
      node.children = orderBy(uniqBy(node.children, '_id'), 'name', 'asc');
    } else if (node.children.length > 0) {
      node.children = addMultipleChildren(node.children, fatherNodeId, newNodes);
    }
    return node;
  });

export const setLoadMore = (tree, fatherNodeId, loadMore) =>
  tree.map((node) => {
    if (node?.id === fatherNodeId || node?._id === fatherNodeId) {
      node.loadMore = loadMore;
    } else if (node.children?.length > 0) {
      node.children = setLoadMore(node.children, fatherNodeId, loadMore);
    }
    return node;
  });

export const deleteChildrenElement = (tree, fatherNodeId, nodeToDelete) =>
  tree.map((node) => {
    if ((node.id || node._id) === fatherNodeId) {
      node.children = node.children.filter((ch) => (ch.id || ch._id) !== nodeToDelete._id);
      if (node.children.length === 0) {
        node.leaf = true;
      }
    } else if (node.children.length > 0) {
      node.children = deleteChildrenElement(node.children, fatherNodeId, nodeToDelete);
    }
    return node;
  });

export const setChildrenEl = (tree, fatherNodeId, newNodes, type): object[] =>
  tree?.map((node) => {
    if (node._id === fatherNodeId || node.id === fatherNodeId) {
      const children: any = [];
      newNodes.map((newNode) => {
        children.push(Object.assign({ children: [], leaf: isLeaf(newNode, 'issueTemplate') }, newNode));
      });
      node.children = orderBy(uniqBy([...node.children, ...children], '_id'), 'name', 'asc');
    } else if (node.children?.length > 0) {
      node.children = setChildrenEl(node.children, fatherNodeId, newNodes, type);
    }
    return node;
  }) || [];

export default (state: State = initialState, action: Action): State => {
  let elementsAux: any = [];
  const sites: any = [];
  let folders: any = [];
  let newChildren: any = [];
  switch (action.type) {
    case UPDATE_SITE_PARENT:
      let sitesT = [];
      if (!!action.payload.oldParentId) {
        sitesT = deleteChildrenDefault(
          cloneDeep(state).elements,
          action.payload.oldParentId,
          {
            ...action.payload.oldSite,
            parent: { _id: action.payload.oldParentId },
            _id: action.payload.oldSite.id,
            __typename: 'LeanSite',
          },
          'site',
        );
      } else {
        sitesT = state.sitesTree.filter((st: NexusGenFieldTypes['Site']) => st._id !== action.payload.oldSite.id);
      }
      elementsAux = [];
      if (action.payload.site.parent !== null) {
        elementsAux = addNewChildrenDefault(
          sitesT,
          action.payload.site?.parent?._id || action.payload.site.parentId,
          { ...action.payload.site, __typename: 'LeanSite' },
          'site',
        );
      } else {
        elementsAux = sitesT;
        elementsAux.push(Object.assign({ children: [], leaf: !action.payload.site.hasChildren }, action.payload.site));
      }
      return { ...state, elements: elementsAux, error: false };
    case CHANGE_SEARCH_INPUT_VALUE:
      return { ...state, searchInputValue: action.payload.searchInputValue };
    case GET_SITES_ROOT_ELEMENT:
      action.payload.sites.forEach(async (element) => {
        sites.push({
          ...element,
          id: element._id,
          children: [],
          leaf: !element.hasChildren && !element.hasElements,
          elementsCount: element.elementsCount + element.sitesCount,
          sitesCount: element.sitesCount,
        });
      });
      return action.payload.modal
        ? { ...state, modalElements: sites, error: false }
        : { ...state, elements: sites, error: false };
    case GET_ELEMENT_ROOT:
      return {
        ...state,
        elements: [
          {
            ...action.payload.parent,
            children: action.payload.elements.map((element) => ({
              ...element,
              id: element._id,
              children: [],
              leaf: element.elementsCount === 0,
              elementsCount: element.elementsCount,
            })),
          },
        ],
        error: false,
      };
    case GET_ELEMENT_SEARCH:
      return {
        ...state,
        elementsSearch: action.payload.elements,
        error: false,
      };
    case GET_ALL_ELEMENT_NAMES:
      action.payload.elements.forEach((element) => {
        const { name, _id: id, description, fields } = element;
        elementsAux.push({ name, id, description, fields });
      });
      return { ...state, elementNames: elementsAux, error: false };
    case GET_ELEMENT_BY_FATHER_ID:
      elementsAux = [];
      newChildren = [];
      action.payload.sites.forEach((site) => {
        newChildren.push({
          ...site,
          id: site._id,
          children: [],
          leaf: !site.hasChildren && !site.hasElements,
          elementsCount: site.hasElements ? 1 : 0,
        });
      });
      action.payload.elements.forEach((element) => {
        const { _id, hasChildren } = element;
        newChildren.push({
          ...element,
          id: _id,
          children: [],
          leaf: !hasChildren,
        });
      });
      newChildren = orderBy(newChildren, 'name', 'asc');
      if (action.payload.more === true && action.payload.loadMore === true) {
        elementsAux = addMultipleChildren(cloneDeep(state).elements, action.payload.parentItem.id, newChildren);
      } else {
        elementsAux = setChildrenEl(cloneDeep(state).elements, action.payload.parentItem.id, newChildren, 'children');
      }

      elementsAux = setLoadMore(elementsAux, action.payload.parentItem.id, action.payload.loadMore);
      return { ...state, elements: elementsAux, error: false };
    case MODAL_GET_ELEMENTS:
      elementsAux = [];
      newChildren = [];
      action.payload.sites.forEach((site) => {
        newChildren.push({
          ...site,
          id: site._id,
          children: [],
          leaf: !site.hasChildren && !site.hasElements,
          elementsCount: site.hasElements ? 1 : 0,
        });
      });
      action.payload.elements.forEach((element) => {
        const { _id, elementsCount } = element;
        newChildren.push({
          ...element,
          id: _id,
          children: [],
          leaf: !element.hasChildren,
          elementsCount,
        });
      });
      newChildren = orderBy(newChildren, 'name', 'asc');
      if (action.payload.more === true && action.payload.loadMore === true) {
        elementsAux = addMultipleChildren(cloneDeep(state).modalElements, action.payload.parentItem.id, newChildren);
      } else {
        elementsAux = setChildrenEl(
          cloneDeep(state).modalElements,
          action.payload.parentItem.id,
          newChildren,
          'children',
        );
      }

      elementsAux = setLoadMore(elementsAux, action.payload.parentItem.id, action.payload.loadMore);
      return { ...state, modalElements: elementsAux, error: false };
    case CREATE_ELEMENT:
      const { parent } = action.payload.element;
      const el = {
        ...action.payload.element,
        parentId: parent ? parent._id : null,
        leaf: isLeaf(action.payload.element, 'element'),
        id: action.payload.element._id,
        profile: {},
      };
      elementsAux = addNewChildrenDefault(
        cloneDeep(state).elements,
        action.payload.fatherNodeId ? action.payload.fatherNodeId : el.parentId,
        el,
        'element',
      );
      return { ...state, elements: elementsAux, error: false };
    case DELETE_ELEMENT:
      if (state.elements.length)
        elementsAux = deleteChildrenElement(
          cloneDeep(state).elements,
          action.payload.element.parent ? action.payload.element.parent._id : action.payload.element.site._id,
          { ...action.payload.element, id: action.payload.element._id },
        );
      return {
        ...state,
        elements: elementsAux,
        error: false,
        elementsSearch: state.elementsSearch.filter((s) => s._id !== action.payload.element._id),
      };
    case UPDATE_ELEMENT:
      elementsAux = [];
      if (action.payload.change) {
        let children = null;
        const getChildrenElement = (tree, elementId): object[] =>
          tree.map((node) => {
            if (node._id === elementId || node.id === elementId) {
              children = node.children;
            } else if (node.children?.length > 0) {
              getChildrenElement(node.children, elementId);
            }
          });
        getChildrenElement(cloneDeep(state).elements, action.payload.element._id);
        elementsAux = deleteChildrenElement(JSON.parse(JSON.stringify(state.elements)), action.payload.change, {
          ...action.payload.element,
          id: action.payload.element._id,
        });
        const { parent } = action.payload.element;
        const el = {
          ...action.payload.element,
          parentId: parent ? parent._id : null,
          leaf: action.payload.element.elementsCount === 0,
          id: action.payload.element._id,
          children: cloneDeep(children),
        };
        elementsAux = addNewChildrenDefault(
          elementsAux,
          action.payload.element.parent ? action.payload.element.parent._id : action.payload.element.site._id,
          el,
          'element',
          action.payload.change,
        );
      } else {
        const elem = {
          ...action.payload.element,
          id: action.payload.element._id,
        };
        elementsAux = updateChildren(
          cloneDeep(state).elements,
          elem.parentId ? elem.parentId : elem.parent ? elem.parent._id : elem.site._id,
          elem,
        );
      }
      return {
        ...state,
        elements: elementsAux,
        error: false,
        elementsSearch: state.elementsSearch.map((s) =>
          s._id === action.payload.element._id ? action.payload.element : s,
        ),
      };
    case MODAL_ELEMENT_TEMPLATE_FOLDERS:
      folders = [];
      if (action.payload.folders.length > 0 && action.payload.folders[0].parent === null) {
        action.payload.folders.forEach((p) => {
          folders.push({
            ...p,
            leaf: isModalFolderLeaf(p, 'elementTemplate'),
            children: [],
          });
        });
      } else if (action.payload.folders.length > 0) {
        folders = setModalFolderChildren(
          cloneDeep(state).modalResults,
          action.payload.folders[0].parent ? action.payload.folders[0].parent._id : null,
          action.payload.folders,
          'elementTemplate',
        );
      }
      return {
        ...state,
        modalResults: folders.length === 0 ? state.modalResults : folders,
      };
    case GET_ELEMENT_TEMPLATE_FOLDERS:
      folders = [];
      if (action.payload.folders.length > 0 && action.payload.folders[0].parent === null) {
        action.payload.folders.forEach((p) => {
          folders.push({
            ...p,
            leaf: isLeaf(p, 'elementTemplate'),
            children: [],
          });
        });
      } else {
        folders = setChildren(
          cloneDeep(state).folders,
          action.payload.folders.length && action.payload.folders[0].parent
            ? action.payload.folders[0].parent._id
            : action.payload.elementTemplates.length
              ? action.payload.elementTemplates[0].folder._id
              : null,
          action.payload,
          'elementTemplate',
        );
      }
      return {
        ...state,
        folders: folders.length === 0 ? state.folders : orderBy(folders, 'name', 'asc'),
      };
    case CREATE_ELEMENT_TEMPLATE_FOLDER:
      folders = [];
      if (action.payload.parent && action.payload.parent._id) {
        folders = addNewChildren(
          cloneDeep(state).folders,
          action.payload.parent._id,
          action.payload,
          'elementTemplate',
        );
      } else {
        folders = JSON.parse(JSON.stringify(state.folders));
        folders.push(Object.assign({ children: [], leaf: true }, action.payload));
      }
      return { ...state, folders: orderBy(folders, 'name', 'asc') };
    case UPDATE_ELEMENT_TEMPLATE_FOLDER:
      folders = [];
      if (action.payload.parent !== null)
        folders = updateChildren(cloneDeep(state).folders, action.payload.parent._id, action.payload);
      else
        state.folders.map((folder) => {
          if (folder._id === action.payload._id) folders.push({ ...folder, ...action.payload });
          else folders.push(folder);
        });
      return {
        ...state,
        folders: orderBy(folders, 'name', 'asc'),
        searchResults: state.searchResults.map((s) => (s._id === action.payload._id ? action.payload : s)),
      };
    case DELETE_ELEMENT_TEMPLATE_FOLDER:
      folders = [];
      if (action.payload.parent !== null) {
        folders = deleteChildren(
          cloneDeep(state).folders,
          action.payload.parent._id,
          action.payload,
          'elementTemplate',
        );
      } else {
        folders = state.folders.filter((st) => st._id !== action.payload._id);
      }
      return {
        ...state,
        folders,
        searchResults: state.searchResults.filter((s) => s._id !== action.payload._id),
      };
    case CREATE_ELEMENT_TEMPLATE:
      folders = addToTemplate(
        cloneDeep(state).folders,
        action.payload.elementTemplate.folder._id,
        action.payload.elementTemplate,
        'elementTemplate',
      );
      return {
        ...state,
        elementTemplates: concat(state.elementTemplates, action.payload.elementTemplate),
        folders,
      };
    case DELETE_ELEMENT_TEMPLATE:
      folders = deleteFromParent(
        cloneDeep(state).folders,
        action.payload.folder._id,
        action.payload,
        'elementTemplate',
      );
      return {
        ...state,
        elementTemplate: filter(state.elementTemplates, (u) => u._id !== action.payload._id),
        folders,
        searchResults: state.searchResults.filter((s) => s._id !== action.payload._id),
      };
    case UPDATE_ELEMENT_TEMPLATE:
      folders = updateParent(
        cloneDeep(state).folders,
        action.payload.new.folder._id,
        action.payload.new,
        'elementTemplate',
        action.payload.old,
      );
      return {
        ...state,
        elementTemplates: state.elementTemplates.map((it) =>
          it._id === action.payload.new._id ? action.payload.new : it,
        ),
        folders,
        searchResults: state.searchResults.map((s) => (s._id === action.payload.new._id ? action.payload.new : s)),
      };
    case SEARCH_ELEMENT_TEMPLATE_FOLDERS:
      return {
        ...state,
        searchResults: concat(action.payload.elementTemplates, action.payload.elementTemplateFolders),
      };
    case ERROR:
      return { ...state, error: true };
    default:
      return state;
  }
};

export const getElement = (filter: NexusGenInputs['ElementWhereUniqueInput']) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        filter: filter,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ELEMENTS,
    });
    return response.data.element;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getElementWithDeleted = (filter) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        filter: filter,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ELEMENT_WITH_DELETED,
    });
    return response.data.elementWithDeleted;
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getElementToTree = (filter, item?, limit?: number, back?: boolean) => async (dispatch: Dispatch) => {
  try {
    let elements: ApolloQueryResult<any> | null = null;
    let sites: ApolloQueryResult<any> | null = null;

    if (item && (item.__typename === 'LeanElement' || item.__typename === 'Element')) {
      if (back) {
        // Validar se tem parentId ou siteId
        if (item.parentId) {
          elements = await APOLLO_CLIENT.query({
            variables: {
              filter: { name_contains: filter.name_contains, _id_eq: item.parentId },
            },
            fetchPolicy: 'no-cache',
            query: queries.Q_GET_ELEMENT_TO_TREE,
          });
        } else if (item.siteId) {
          sites = await APOLLO_CLIENT.query({
            variables: {
              filter: { name_contains: filter.name_contains, _id_eq: item.siteId },
            },
            fetchPolicy: 'no-cache',
            query: queries.Q_GET_SITES,
          });
        }
      } else {
        elements = await APOLLO_CLIENT.query({
          variables: {
            filter: { name_contains: filter.name_contains, parent_eq: item._id },
          },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_ELEMENT_TO_TREE,
        });
      }
    } else {
      if (back) {
        sites = await APOLLO_CLIENT.query({
          variables: {
            filter:
              filter.parent_exists !== undefined
                ? { name_contains: filter.name_contains, _id_eq: localStorage.getItem('current_site_id') }
                : filter,
            limit: limit ? limit : 1000,
          },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_SITES,
        });
      } else {
        sites = await APOLLO_CLIENT.query({
          variables: {
            filter:
              filter.parent_exists !== undefined
                ? { name_contains: filter.name_contains, _id_eq: localStorage.getItem('current_site_id') }
                : filter,
            limit: limit ? limit : 1000,
          },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_SITES,
        });
        if (item && !item.profileId && item.__typename !== 'LeanElement')
          elements = await APOLLO_CLIENT.query({
            variables: { name: filter.name_contains, id: item._id, limit: limit ? limit : 1000 },
            fetchPolicy: 'no-cache',
            query: queries.Q_GET_ELEMENT_ROOT_BY_SITE,
          });
      }
    }

    return orderBy(
      [...(sites ? sites.data.sitesV2 : []), ...(elements ? elements.data.elementsV2 : [])],
      'name',
      'asc',
    );
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getElementTemplate = (filter) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        filter,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ELEMENT_TEMPLATES,
    });
    return response.data.elementTemplates[0];
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getTemplateFolders =
  (options, item, modal = false) =>
  async (dispatch: Dispatch) => {
    try {
      let response: ApolloQueryResult<any> | null = null;
      if (item?.node.elementTemplateFoldersCount || options.parent_exists !== undefined) {
        response = await APOLLO_CLIENT.query({
          variables: { where: options, archivedBy_exists: false },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_ELEMENT_TEMPLATE_FOLDERS,
        });
      }

      let elementTemplates: ApolloQueryResult<any> | null = null;
      if (options.parent_eq && !modal && item.node.elementTemplatesCount) {
        elementTemplates = await APOLLO_CLIENT.query({
          variables: { filter: { folder: { _id_eq: options.parent_eq }, archivedBy_exists: false } },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_ELEMENT_TEMPLATES_TO_TREE,
        });
      }

      dispatch({
        type: modal ? MODAL_ELEMENT_TEMPLATE_FOLDERS : GET_ELEMENT_TEMPLATE_FOLDERS,
        payload: {
          folders: response ? response.data.elementTemplateFolders : [],
          elementTemplates: elementTemplates ? elementTemplates.data.elementTemplates : [],
        },
      });
      return orderBy(
        [
          ...(response ? response.data.elementTemplateFolders : []),
          ...(elementTemplates ? elementTemplates.data.elementTemplates : []),
        ],
        'name',
        'asc',
      );
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const createFolder =
  ({ name, parent = null }: { name: string; parent: { _id: string } | null }) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { name, parent: parent?._id },
        fetchPolicy: 'no-cache',
        mutation: queries.M_CREATE_ELEMENT_TEMPLATE_FOLDER,
      });
      dispatch({
        type: CREATE_ELEMENT_TEMPLATE_FOLDER,
        payload: response.data.createElementTemplateFolder,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(name) + i18n.t('toastCreateSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const updateTemplateFolder =
  ({ name, parent = null }, { _id }) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { name, parent_eq: parent, _id },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_ELEMENT_TEMPLATE_FOLDER,
      });
      dispatch({
        type: UPDATE_ELEMENT_TEMPLATE_FOLDER,
        payload: response.data.updateElementTemplateFolder,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(name) + i18n.t('toastUpdateSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const deleteTemplateFolder =
  (templateData) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { where: { _id: templateData._id } },
        fetchPolicy: 'no-cache',
        mutation: queries.M_DELETE_ELEMENT_TEMPLATE_FOLDER,
      });
      dispatch({
        type: DELETE_ELEMENT_TEMPLATE_FOLDER,
        payload: response.data.deleteOneElementTemplateFolder,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(templateData.name) + i18n.t('toastDeleteSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
    }
  };

export const createElementTemplate = (template) => async (dispatch: Dispatch) => {
  try {
    let response;
    if (template.frame.inputs) {
      response = await APOLLO_CLIENT.mutate({
        variables: {
          title: Array.isArray(template.title) ? template.title[0] : template.title,
          folder: template.folder._id,
          inputs: template.frame.inputs,
          icon: template.icon,
        },
        mutation: queries.M_CREATE_ELEMENT_TEMPLATE,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('Template') + addSpace(template.title || template.name) + i18n.t('toastCreateSuccess'),
          severity: 'success',
        },
      });
    }
    // update the local storage with the token using the correct action
    dispatch({
      type: CREATE_ELEMENT_TEMPLATE,
      payload: {
        elementTemplate: response ? response.data.createElementTemplate : template,
      },
    });

    if (response) return response.data.createElementTemplate;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const deleteElementTemplate =
  (elementTemplateData) =>
  async (dispatch: Dispatch): Promise<void> => {
    // access the backend and get the access token
    try {
      let response;
      if (elementTemplateData.__typename) {
        response = await APOLLO_CLIENT.mutate({
          variables: {
            id: elementTemplateData._id,
          },
          mutation: queries.M_DELETE_ELEMENT_TEMPLATE,
        });
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message:
              i18n.t('Template') +
              addSpace(elementTemplateData.title || elementTemplateData.name) +
              i18n.t('toastDeleteSuccess'),
            severity: 'success',
          },
        });
      }
      // update the local storage with the token using the correct action
      dispatch({
        type: DELETE_ELEMENT_TEMPLATE,
        payload: response ? response.data.deleteOneElementTemplate : elementTemplateData,
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const updateElementTemplate =
  (data: ElementTemplateUpdateInput, where: ElementTemplateWhereUniqueInput, previousFolderId) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          data,
          where,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_ELEMENT_TEMPLATE,
      });

      if (data.archived) {
        dispatch({
          type: DELETE_ELEMENT_TEMPLATE,
          payload: response ? response.data.updateElementTemplate : data,
        });
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('Template') + ' ' + i18n.t('toastArchiveSuccess'),
            severity: 'success',
          },
        });
      } else {
        // update the local storage with the token using the correct action
        dispatch({
          type: UPDATE_ELEMENT_TEMPLATE,
          payload: {
            new: response.data.updateElementTemplate,
            old: previousFolderId,
          },
        });
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('Template') + addSpace(data.title) + i18n.t('toastUpdateSuccess'),
            severity: 'success',
          },
        });
      }

      return response.data.updateElementTemplate;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const search = (searchString) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { searchString },
      fetchPolicy: 'no-cache',
      mutation: queries.Q_SEARCH,
    });

    dispatch({
      type: SEARCH_ELEMENT_TEMPLATE_FOLDERS,
      payload: response.data,
    });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
  }
};

export const getSitesRoot =
  (modal = false) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          filter: localStorage.getItem('current_site_id')
            ? { _id_eq: localStorage.getItem('current_site_id') }
            : { parent_exists: false },
          filterElements: localStorage.getItem('current_site_id')
            ? { site_eq: localStorage.getItem('current_site_id') }
            : { parent_exists: false },
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_SITES_ROOT,
      });

      dispatch({
        type: GET_SITES_ROOT_ELEMENT,
        payload: { sites: response.data.sitesV2, modal },
      });
      return response.data.sites;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getElementsRoot = (element) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        filter: { parent: { _id: element.id } },
      },
      query: queries.Q_GET_ELEMENT_ROOT,
      fetchPolicy: 'no-cache',
    });

    // update the local storage with the token using the correct action
    dispatch({
      type: GET_ELEMENT_ROOT,
      payload: { elements: response.data.elements, parent: element },
    });
    return response.data.elements;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getElementsByFatherId =
  (item, skip: number, limit: number, modal = false) =>
  async (dispatch: Dispatch) => {
    try {
      let elements: ApolloQueryResult<any> | null = null;
      let sites: ApolloQueryResult<any> | null = null;
      let loadMore = false;
      if (item.siteId || item.site) {
        elements = await APOLLO_CLIENT.query({
          variables: {
            parentId: item.id,
            skip: item.children.filter((data) => data.profile !== undefined).length,
            limit: limit + 1,
          },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_ELEMENT_BY_FATHER_ID,
        });

        if (elements.data.elementsV2.length === limit + 1) {
          elements.data.elementsV2.pop();
          loadMore = loadMore || true;
        }
      } else {
        sites = await APOLLO_CLIENT.query({
          variables: {
            filter: { parent_eq: item.id },
            skip: item.children?.filter((data) => data.profile === undefined).length,
            limit: limit + 1,
          },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_SITES,
        });
        if (sites.data.sitesV2.length === limit + 1) {
          sites.data.sitesV2.pop();
          loadMore = loadMore || true;
        }
        if (sites.data.sitesV2.length < 50) {
          const lim = 50 - sites.data.sitesV2.length;
          elements = await APOLLO_CLIENT.query({
            variables: {
              id: item.id,
              skip: item.children.filter((data) => (data.profile !== undefined ? true : false)).length,
              limit: lim + 1,
            },
            fetchPolicy: 'no-cache',
            query: queries.Q_GET_ELEMENT_ROOT_BY_SITE,
          });

          if (elements.data.elementsV2.length === lim + 1) {
            elements.data.elementsV2.pop();
            loadMore = loadMore || true;
          }
        }
      }

      // update the local storage with the token using the correct action
      dispatch({
        type: modal ? MODAL_GET_ELEMENTS : GET_ELEMENT_BY_FATHER_ID,
        payload: {
          elements: elements ? elements.data.elementsV2 : [],
          sites: sites ? sites.data.sitesV2 : [],
          parentItem: item,
          more: skip > 0,
          loadMore,
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getElements =
  (page: number, itemsByPage: number, filter: NexusGenInputs['ElementWhereInput']) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          limit: itemsByPage,
          skip: (page - 1) * itemsByPage,
          filter,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_ELEMENTS_V2,
      });
      return response.data.elementsV2;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const elementSearch = (stringSearch) => async (dispatch, _) => {
  try {
    dispatch({
      type: CHANGE_SEARCH_INPUT_VALUE,
      payload: { searchInputValue: stringSearch },
    });
    const currentSiteId = localStorage.getItem('current_site_id');
    const response = await APOLLO_CLIENT.query({
      variables: {
        searchString: stringSearch,
        OR: [{ site: { _id: currentSiteId } }, { site: { parentsTree_some: { _id: currentSiteId } } }],
      },
      fetchPolicy: 'no-cache',
      query: queries.M_ELEMENT_SEARCH,
    });

    dispatch({
      type: GET_ELEMENT_SEARCH,
      payload: { elements: response.data.elements },
    });

    return response.data.elements;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const createElement = (elementData) => async (dispatch, _) => {
  try {
    let response;
    if (elementData.templateId) {
      if (elementData.parentId) {
        response = await APOLLO_CLIENT.mutate({
          variables: {
            name: elementData.name,
            description: elementData.description,
            parentId: elementData.parentId,
            profileId: elementData.profileId,
            inputs: elementData.inputs,
            site: elementData.siteId ? elementData.siteId : elementData.site._id,
            template: elementData.templateId ? elementData.templateId : elementData.template._id,
            tags: elementData.tags,
            labelValues: elementData.labelValues,
            imageId: elementData.image,
            icon: elementData.icon,
            imageDetails: elementData.imageDetails ? elementData.imageDetails : null,
          },
          mutation: queries.M_CREATE_ELEMENT,
        });
      } else {
        response = await APOLLO_CLIENT.mutate({
          variables: {
            name: elementData.name,
            description: elementData.description,
            profileId: elementData.profileId,
            inputs: elementData.inputs,
            site: elementData.siteId ? elementData.siteId : elementData.site._id,
            template: elementData.templateId ? elementData.templateId : elementData.template._id,
            tags: elementData.tags,
            labelValues: elementData.labelValues,
            imageId: elementData.image,
            icon: elementData.icon,
            imageDetails: elementData.imageDetails ? elementData.imageDetails : null,
          },
          mutation: queries.M_CREATE_ELEMENT_ROOT,
        });
      }
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('element') + addSpace(elementData.name) + i18n.t('toastCreateSuccess'),
          severity: 'success',
        },
      });
    }

    // update the local storage with the token using the correct action
    dispatch({
      type: CREATE_ELEMENT,
      payload: {
        element: response ? response.data.createElement : elementData,
        fatherNodeId: elementData.parentId ? elementData.parentId : elementData.siteId,
      },
    });

    return response ? response.data.createElement : elementData;
  } catch (error) {
    const obj = getErrorObject(error, 'Element.createElement');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const deleteElement =
  (elementData) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      let response;
      if (elementData.template) {
        response = await APOLLO_CLIENT.mutate({
          variables: {
            id: elementData._id,
          },
          mutation: queries.M_DELETE_ELEMENT,
        });
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('element') + addSpace(elementData.name) + i18n.t('toastDeleteSuccess'),
            severity: 'success',
          },
        });
      }
      // update the local storage with the token using the correct action
      dispatch({
        type: DELETE_ELEMENT,
        payload: {
          element: response ? response.data.deleteOneElement : elementData,
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, 'Element.deleteElement');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const updateElement =
  (elementData, showToast = true, change?) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          name: elementData.name,
          parentId: elementData.parent?._id
            ? elementData.parent._id
            : elementData.fatherNodeId
              ? elementData.fatherNodeId
              : null,
          profileId: elementData.profileId,
          description: elementData.description,
          id: elementData._id,
          inputs: elementData.inputs,
          tags: elementData.tags,
          templateId: elementData.templateId,
          siteId: elementData.siteId,
          labelValues: elementData.labelValues,
          imageId: elementData.image,
          icon: elementData.icon,
          imageDetails: elementData.imageDetails ? elementData.imageDetails : null,
        },
        mutation: queries.M_UPDATE_ELEMENT,
      });

      dispatch({
        type: UPDATE_ELEMENT,
        payload: { element: response.data.updateElement, change: change },
      });

      if (showToast) {
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('element') + addSpace(elementData.name) + i18n.t('toastUpdateSuccess'),
            severity: 'success',
          },
        });
      }
      return response.data.updateElement;
    } catch (error) {
      const obj = getErrorObject(error, 'Element.updateElement');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getElementById = (id) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        id,
      },
      query: queries.Q_GET_ELEMENT_BY_ID,
    });
    return response.data.element;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getElementsWithTags = () => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        filter: { tags_some: { value_contains: '' } },
      },
      query: queries.Q_ELEMENT_WITH_TAGS,
    });

    return response.data.elements;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const duplicateElement = (_id, copyChildren, name) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { _id, copyChildren },
      fetchPolicy: 'no-cache',
      mutation: queries.M_DUPLICATE_ELEMENT,
    });
    dispatch({
      type: CREATE_ELEMENT,
      payload: {
        element: response.data.duplicateElement,
        fatherNodeId: response.data.duplicateElement.parent
          ? response.data.duplicateElement.parent._id
          : response.data.duplicateElement.site._id,
      },
    });
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: `${name} ${i18n.t('duplicated')}`,
        severity: 'success',
      },
    });
    return response.data.duplicateElement;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const duplicateElementTemplate = (_id, copyChildren, name) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { _id, copyChildren },
      fetchPolicy: 'no-cache',
      mutation: queries.M_DUPLICATE_ELEMENT_TEMPLATE,
    });
    dispatch({
      type: CREATE_ELEMENT_TEMPLATE,
      payload: {
        elementTemplate: response.data.duplicateElementTemplate,
      },
    });
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: `${name} ${i18n.t('duplicated')}`,
        severity: 'success',
      },
    });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const deleteMarkerFromElement = (id: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        filter: { _id: id },
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ELEMENTS,
    });
    if (response.data.element) {
      const element = response.data.element;
      await APOLLO_CLIENT.query({
        variables: {
          anchorId: element.marker.anchorId,
        },
        fetchPolicy: 'no-cache',
        query: queries.M_DELETE_MARKER_BY_ANCHOR_ID,
      });
    }
    dispatch({
      type: UPDATE_ELEMENT,
      payload: { element: { ...response.data.element, markerId: null, marker: null } },
    });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getElementsLoadMore =
  (filter, item?, limit?: number, skip?: { sites: number; elements: number }, back?: boolean) =>
  async (dispatch: Dispatch) => {
    try {
      let elements: ApolloQueryResult<any> | null = null;
      let sites: ApolloQueryResult<any> | null = null;

      if (item?.__typename?.endsWith('Element')) {
        if (back) {
          elements = await APOLLO_CLIENT.query({
            variables: {
              // filter: { parent_eq: item.parentId },
              filter,
            },
            fetchPolicy: 'no-cache',
            query: queries.Q_GET_ELEMENT_TO_TREE,
          });
        } else {
          elements = await APOLLO_CLIENT.query({
            variables: {
              filter: { name_contains: filter.name_contains, parent_eq: item._id },
            },
            fetchPolicy: 'no-cache',
            query: queries.Q_GET_ELEMENT_TO_TREE,
          });
        }
      } else {
        if (back) {
          sites = await APOLLO_CLIENT.query({
            variables: {
              filter:
                filter.parent_exists !== undefined
                  ? { _id_eq: localStorage.getItem('current_site_id'), name_contains: filter.name_contains }
                  : filter,
              limit: limit ? limit : 1000,
              skip: skip?.sites ?? 0,
            },
            fetchPolicy: 'no-cache',
            query: queries.Q_GET_SITES,
          });
          if (item && !item.profileId && !item.__typename?.endsWith('Element'))
            elements = await APOLLO_CLIENT.query({
              variables: {
                id: item._id,
                limit: limit ?? 1000,
                skip: skip?.elements ?? 0,
                name: filter.name_contains,
              },
              fetchPolicy: 'no-cache',
              query: queries.Q_GET_ELEMENT_ROOT_BY_SITE,
            });
        } else {
          sites = await APOLLO_CLIENT.query({
            variables: {
              filter:
                filter.parent_exists !== undefined
                  ? { _id_eq: localStorage.getItem('current_site_id'), name_contains: filter.name_contains }
                  : filter,
              limit: limit ?? 1000,
              skip: skip?.sites ?? 0,
            },
            fetchPolicy: 'no-cache',
            query: queries.Q_GET_SITES,
          });
          if (item && !item.profileId && !item.__typename.endsWith('Element'))
            elements = await APOLLO_CLIENT.query({
              variables: {
                id: item._id,
                limit: limit ?? 1000,
                skip: skip?.elements ?? 0,
                name: filter.name_contains,
              },
              fetchPolicy: 'no-cache',
              query: queries.Q_GET_ELEMENT_ROOT_BY_SITE,
            });
        }
      }
      const sitesResponse = orderBy([...(sites?.data.sitesV2 ?? [])], 'name', 'asc');

      const elementsResponse = orderBy([...(elements?.data.elementsV2 ?? [])], 'name', 'asc');

      return { sites: sitesResponse, elements: elementsResponse };
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const moveManyElements = (elements, parent) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        _ids: elements.map((elem) => elem._id),
        siteId: parent.__typename === 'Site' || parent.__typename === 'LeanSite' ? parent._id : undefined,
        parentId: parent.__typename === 'Element' || parent.__typename === 'LeanElement' ? parent._id : undefined,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_MOVE_MANY_ELEMENT,
    });

    dispatch({
      type: MOVE_ELEMENTS,
      payload: {
        elements: elements,
        destination: parent,
      },
    });

    return response.data.moveManyElements;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getElementParent = (name: string) => async (dispatch: Dispatch) => {
  try {
    const names: string[] = [];
    const element = await APOLLO_CLIENT.query<{
      elementsV2: {
        name: string;
        _id: string;
        siteId: string;
        parentId: string;
        parentsTreeIds: string[];
      }[];
    }>({
      variables: {
        filter: { name_eq: name },
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ELEMENT_PARENT,
    });
    if (element.data.elementsV2[0].parentId) {
      const parent = await APOLLO_CLIENT.query<{
        elementsV2: {
          name: string;
          _id: string;
          siteId: string;
          parentId: string;
          parentsTreeIds: string[];
        }[];
      }>({
        variables: {
          filter: { _id_eq: element.data.elementsV2[0].parentId },
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_ELEMENT_PARENT,
      });
      names.push(parent.data.elementsV2[0].name);
    } else if (element.data.elementsV2[0].siteId) {
    }
    return names;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const updateManyElementTemplates =
  (where: NexusGenInputs['ElementTemplateWhereInput'], update: NexusGenInputs['ElementTemplateUpdateInput']) =>
  async (dispatch: Dispatch): Promise<NexusGenFieldTypes['ElementTemplate'][] & { graphQLErrors: GraphQLErrors }> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          where,
          update,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_MANY_ELEMENT_TEMPLATES,
      });

      return response.data.updateManyElementTemplates;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };
