import { Checkbox, Chip, MenuItem, Popover, useTheme } from '@mui/material';
import { ArrowDropDown, Close, RemoveCircleOutline } from '@mui/icons-material';
import { Ref, useState } from 'react';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { styles } from 'src/utils/components/generic-input-card/styles';

interface FailResponseChipProps {
  classes: Record<string, string>;
  t?: any;
  setInput: (input: any) => void;
  active?: boolean;
  refNumberCondFirstSelect: Ref<HTMLDivElement>;
  error: any;
  input: any;
}

const FailResponseChip = (props: FailResponseChipProps): JSX.Element => {
  const { input, classes, setInput, refNumberCondFirstSelect, active } = props;
  const [popoverOpen, setPopoverOpen] = useState<any>(null);
  const [options, setOptions] = useState<any>([]);
  const theme = useTheme();

  const handleClose = () => {
    const color = theme.palette.error.main;
    setInput({
      _id: input._id,
      [input.type]: {
        ...input[input.type],
        options: input[input.type].options.map((opt: { label: string; color?: string; id: string }) =>
          options.find((o) => o === opt.label) ? { ...opt, color } : opt.color ? { ...opt, color: null } : opt,
        ),
        failOptions: options,
      },
    });
    setPopoverOpen(null);
  };

  return input[input.type].failOptions?.length > 0 ? (
    <div>
      <Chip
        icon={<RemoveCircleOutline className={classes.removeOutlinedIcon} />}
        label={
          <div className={classes.divLabel}>
            <span style={{ display: 'flex', alignItems: 'center', marginTop: '2px' }}>{`${props.t(
              'failResponse',
            )}: `}</span>
            {input[input.type].failOptions.map((option, idx) => (
              <Chip key={idx} label={option} classes={{ root: classes.failResponseOptionSelected }} />
            ))}
            {active ? (
              <ArrowDropDown
                onClick={(e) => {
                  setPopoverOpen(e.target);
                  let aux = [];
                  input[input.type].failOptions.map((opt) => aux.push(opt));
                  setOptions(aux);
                }}
                style={{ color: theme.palette.common.white }}
              />
            ) : null}
            {active ? (
              <Close
                classes={{ root: classes.closeIcon }}
                onClick={() => {
                  setInput({
                    _id: input._id,
                    [input.type]: {
                      ...input[input.type],
                      options: input[input.type].options.map((opt: { label: string; color?: string; id: string }) =>
                        opt.color ? { ...opt, color: null } : opt,
                      ),
                      failOptions: [],
                    },
                  });
                  setOptions([]);
                }}
              />
            ) : null}
            <Popover
              ref={refNumberCondFirstSelect}
              open={!!popoverOpen}
              // @ts-ignore
              anchorEl={popoverOpen}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={handleClose}
            >
              {input[input.type].options.map((opt, idx) => (
                <MenuItem
                  id={'menuItem' + opt.label}
                  data-testid={'menuItem' + opt.label}
                  value={opt.label}
                  key={idx}
                  onClick={() => {
                    options.find((o) => o === opt.label)
                      ? setOptions(
                          options.filter((o) => {
                            return o !== opt.label;
                          }),
                        )
                      : setOptions([...options, opt.label]);
                  }}
                >
                  <Checkbox checked={options.find((o) => o === opt.label) ? true : false} color='primary' />
                  {opt.label}
                </MenuItem>
              ))}
            </Popover>
          </div>
        }
        classes={{
          root: classes.failResponseChip,
        }}
      />
    </div>
  ) : (
    <div />
  );
};

export default compose<any>(withTranslation('translation'), withStyles(styles))(FailResponseChip);
