import { Clear, ExpandMore } from '@mui/icons-material';
import { Avatar, Chip, Popover, Tooltip, Typography } from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import classNames from 'classnames';
import { findIndex, map } from 'lodash';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import DetailsPopover from 'src/utils/components/details-popover';
import useForceUpdate from 'src/utils/hooks/useForceUpdate';
import LabelInput from '../input-field/input-label';
import BetterListByLevel from './better-list-by-level';
import { FieldPopoverContext, FieldPopoverItem } from './better-list-by-level/popover-reducer';
import { styles } from './styles';

type ContextToUnion<C extends FieldPopoverContext | FieldPopoverContext[]> = C extends FieldPopoverContext[]
  ? C[number]
  : C;

type FieldPopoverValue<T extends boolean, C extends FieldPopoverContext[] | FieldPopoverContext> = T extends true
  ? FieldPopoverItem[ContextToUnion<C>][]
  : FieldPopoverItem[ContextToUnion<C>] | null | undefined;

type ModifiedKey<T extends Record<string, Record<string, any>>, KeyToModify extends keyof T> = {
  [K in keyof T]: K extends KeyToModify ? Partial<T[K]> : T[K];
};

export type FieldPopoverOnChange<T extends boolean, C extends FieldPopoverContext[] | FieldPopoverContext> = (
  item: FieldPopoverValue<T, C>,
) => void;

export type FieldPopoverProps<C extends FieldPopoverContext[] | FieldPopoverContext> = {
  title?: string;
  context: C;
  labelField?: string;
  required?: boolean;
  disabled?: boolean;
  disabledItems?: string[];
  resetOption?: boolean | string;
  iconAnchor?: boolean;
  error?: boolean;
  errorMessage?: string;
  innerRef?: React.MutableRefObject<Element>;
  popoverRef?: React.MutableRefObject<HTMLDivElement>;
  filterIds?: string[];
  placeholder?: string | React.ReactNode;
  'data-testid'?: string;
  isAllSelected?: (data: boolean) => void;
} & (
  | {
      multiple: true;
      value: FieldPopoverValue<true, C>;
      onChange: FieldPopoverOnChange<true, C>;
    }
  | {
      multiple?: false;
      value: FieldPopoverValue<false, C>;
      onChange: FieldPopoverOnChange<false, C>;
    }
) &
  ModifiedKey<Partial<WithStyles<typeof styles>>, 'classes'>;

const BetterFieldPopover = <C extends FieldPopoverContext[] | FieldPopoverContext>(props: FieldPopoverProps<C>) => {
  const {
    title,
    required,
    classes,
    disabled,
    value,
    multiple,
    context,
    onChange,
    error,
    errorMessage,
    filterIds,
    iconAnchor,
    isAllSelected,
  } = props;
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const forceUpdate = useForceUpdate();
  const [isOverFlown, setIsOverFlown] = useState<number>(-1);
  const [popoverDetails, setPopoverDetails] = useState<any>(null);

  const selectedItem = useMemo(
    () => (multiple ? (value ?? []) : value ? [value] : []) as FieldPopoverValue<true, C>,
    [value, multiple],
  );

  const [selectedValue, setSelectedValue] = useState<Record<string, any>>(null);

  useEffect(() => {
    const flexItems = Array.from(anchorRef.current.getElementsByClassName('flexItem'));

    const observer = new ResizeObserver(() => {
      if (anchorRef.current?.scrollHeight > anchorRef.current?.clientHeight) {
        const ovr = findIndex(
          map(flexItems, (i) => i.getBoundingClientRect().y),
          (i, _, arr) => i != arr[0],
        );

        if (ovr !== isOverFlown) {
          setIsOverFlown(ovr);
        }
      } else if (isOverFlown != -1) {
        setIsOverFlown(-1);
      }
    });

    observer.observe(anchorRef.current);

    return () => {
      observer.disconnect();
    };
  }, [selectedItem, isOverFlown]);

  useEffect(() => {
    if (props.innerRef) {
      props.innerRef.current = anchorRef.current;
    }
  });

  const typenameToContext = (typename: string): string => {
    switch (true) {
      case typename?.endsWith('Element'):
        return 'element';
      case typename?.endsWith('Site'):
        return 'site';
      case typename?.endsWith('Account'):
        return 'account';
      case typename?.endsWith('Profile'):
        return 'profile';
      default:
        return '';
    }
  };

  const renderChip = (val: Record<string, any>, isVisible?: boolean) => {
    const visibility = isVisible ? 'visible' : 'hidden';
    const disabledItem = !!props.disabledItems?.includes(val._id);

    const onDelete = () => {
      if (disabled) {
        return;
      }

      const newItems = selectedItem.filter((i) => i._id !== val._id);

      if (props.multiple === true) {
        props.onChange(newItems);
      } else {
        props.onChange(newItems.at(0) || null);
      }
    };

    const onClick = (e) => {
      e.stopPropagation();

      if (typenameToContext(val.__typename)) {
        setSelectedValue(val);
        setPopoverDetails(e.target);
      }
    };

    switch (val.__typename) {
      case 'LabelValue':
        return (
          <Tooltip title={val.description ?? ''} arrow placement='top'>
            <Chip
              key={val._id}
              data-testid={`better-field-popover-${props['data-testid']}-chip-${val.value}`}
              avatar={
                <Avatar classes={{ root: classes.avatar }} style={{ backgroundColor: val.backgroundColor }}>
                  <Typography variant='caption'>{val.label.name}</Typography>
                </Avatar>
              }
              label={<Typography variant='caption'>{val.value}</Typography>}
              style={{
                borderColor: val.backgroundColor,
                color: val.backgroundColor,
                visibility,
              }}
              deleteIcon={
                disabled || disabledItem ? undefined : (
                  <Clear style={{ fontSize: '14px', color: val.backgroundColor }} />
                )
              }
              onDelete={disabled || disabledItem ? undefined : onDelete}
              onClick={onClick}
              color={'primary'}
              variant='outlined'
              size='small'
              className={classNames(classes.chipWidth, 'flexItem')}
            />
          </Tooltip>
        );
      case 'Account':
        return (
          <Chip
            key={val._id}
            data-testid={`better-field-popover-${props['data-testid']}-chip-${val.name}`}
            avatar={
              val.photo ? (
                <Avatar src={`data:image/webp;base64,${val.photo}`} />
              ) : (
                <Avatar>{val.name.charAt(0).toUpperCase()}</Avatar>
              )
            }
            style={{
              visibility,
            }}
            label={val.name}
            deleteIcon={disabled || disabledItem ? undefined : <Clear style={{ fontSize: '14px' }} />}
            onDelete={disabled || disabledItem ? undefined : onDelete}
            onClick={onClick}
            variant='outlined'
            size='small'
            className={classNames(classes.chipWidth, 'flexItem')}
          />
        );
      default:
        return (
          <Chip
            key={val._id}
            data-testid={`better-field-popover-${props['data-testid']}-chip-${val.name || val.title}`}
            className={classNames(classes.chipWidth, 'flexItem')}
            size={'small'}
            onClick={onClick}
            onDelete={disabled || disabledItem ? undefined : onDelete}
            variant='outlined'
            label={props.labelField ? val[props.labelField] : val['name'] || val['title'] || val['value']}
            style={{
              visibility,
            }}
          />
        );
    }
  };

  return (
    <>
      <div className={`${classes.divContainer} ${classes.root}`}>
        {title ? <LabelInput required={required}>{title}</LabelInput> : null}
        <div className={classes.relative}>
          <ExpandMore
            className={`${classes.expandIcon} ${!disabled ? classes.expandIconActive : classes.expandIconDisabled}`}
            onClick={(e) => {
              if (disabled) return;

              e.stopPropagation();
              e.preventDefault();

              setPopoverOpen(true);
            }}
          />
        </div>
        <div
          ref={anchorRef}
          className={`${classes.input} ${classes.scheduleSettingsInput} ${popoverOpen ? classes.activeBorder : ''} ${classes.border} ${
            error ? classes.inputError : ''
          } ${disabled ? classes.inputDisable : ''}
            `}
          data-testid={props['data-testid']}
          onClick={() => {
            setPopoverOpen(true);
            setTimeout(() => {
              window.dispatchEvent(new Event('resize'));
            }, 20);
          }}
        >
          <div className={classes.divValueWithHover}>
            {selectedItem.length ? (
              selectedItem.map((item, index) => (
                <Fragment key={index}>
                  {renderChip(item, index < isOverFlown || isOverFlown == -1)}
                  {isOverFlown === index + 1 && (
                    <div className={classes.overflowBadge}>{`+${selectedItem.length - isOverFlown}`}</div>
                  )}
                </Fragment>
              ))
            ) : typeof props.placeholder === 'string' ? (
              <span className={classes.placeholder}>{props.placeholder}</span>
            ) : (
              (props.placeholder ?? null)
            )}
          </div>
        </div>
        {errorMessage?.trim() !== '' && error ? (
          <div className={classes.errorMessage}>
            <Typography variant='caption'>{errorMessage}</Typography>
          </div>
        ) : null}
      </div>
      {disabled || !popoverOpen ? null : (
        <Popover
          open={true}
          anchorEl={anchorRef.current}
          ref={props.popoverRef}
          PaperProps={{
            style: {
              width: anchorRef.current?.getBoundingClientRect().width,
            },
          }}
          onClose={() => setPopoverOpen(false)}
          classes={{ paper: classes.popoverContainer }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <BetterListByLevel
            context={Array.isArray(context) ? context : [context]}
            iconAnchor={iconAnchor}
            resetOption={props.resetOption}
            filterIds={filterIds}
            labelField={props.labelField}
            onNavigate={forceUpdate}
            changeSelected={(item) => (multiple === true ? onChange(item) : onChange(item[0]))}
            multiple={multiple}
            selectedItem={selectedItem}
            disabledItems={props.disabledItems}
            close={() => setPopoverOpen(false)}
            isAllSelected={isAllSelected}
          />
        </Popover>
      )}
      {popoverDetails ? (
        <DetailsPopover
          context={typenameToContext(selectedValue.__typename)}
          anchor={popoverDetails}
          value={selectedValue}
          clear={() => {
            setPopoverDetails(null);
            setSelectedValue(null);
          }}
        />
      ) : null}
    </>
  );
};

export default withStyles(styles)(BetterFieldPopover) as typeof BetterFieldPopover;
