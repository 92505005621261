import { cloneDeep, concat } from 'lodash';
import { APOLLO_CLIENT } from 'config/apollo.config';
import { Action, State } from 'src/interfaces/reducers';
import { getErrorObject, isLeaf, isModalFolderLeaf, setChildren, setModalFolderChildren } from 'src/utils/funcs';
import * as queries from 'src/modules/state-machine/state-machine.queries';
import { orderBy } from 'lodash';
import { ApolloQueryResult } from '@apollo/client';
import { Dispatch } from 'redux';
import { StateMachineWhereInput } from 'src/gql/graphql';

export const GET_DEFAULT_STATE_MACHINE = ' GET_DEFAULT_STATE_MACHINE';
export const GET_STATE_MACHINE_FOLDERS = 'GET_STATE_MACHINE_FOLDERS';
export const MODAL_STATE_MACHINE_FOLDERS = 'MODAL_STATE_MACHINE_FOLDERS';
export const SEARCH_STATE_MACHINE_FOLDERS = 'SEARCH_STATE_MACHINE_FOLDERS';
export const ERROR = 'ERROR';

const initialState: State = {
  defaultStateMachine: null,
  stateMachines: [],
  modalResults: [],
  searchResults: [],
  folders: [],
  count: 0,
  error: false,
};

export default (state: State = initialState, action: Action): State => {
  let folders: any = [];
  switch (action.type) {
    case GET_DEFAULT_STATE_MACHINE:
      return { ...state, defaultStateMachine: action.payload.stateMachines[0] };
    case GET_STATE_MACHINE_FOLDERS:
      folders = [];
      if (action.payload.length > 0 && action.payload[0].parent === null) {
        action.payload.forEach((p: any) => {
          folders.push({
            ...p,
            leaf: isLeaf(p, 'stateMachine'),
            children: [],
          });
        });
      } else if (action.payload.length > 0) {
        folders = setChildren(
          cloneDeep(state).folders,
          action.payload[0].parent ? action.payload[0].parent._id : null,
          action.payload,
          'stateMachine',
        );
      }
      return {
        ...state,
        folders: folders.length === 0 ? state.folders : folders,
      };
    case SEARCH_STATE_MACHINE_FOLDERS:
      return {
        ...state,
        searchResults: concat(action.payload.stateMachines, action.payload.stateMachineFolders),
      };
    case MODAL_STATE_MACHINE_FOLDERS:
      folders = [];
      if (action.payload.length > 0 && action.payload[0].parent === null) {
        action.payload.forEach((p: any) => {
          folders.push({
            ...p,
            leaf: isModalFolderLeaf(p, 'stateMachine'),
            children: [],
          });
        });
      } else if (action.payload.length > 0) {
        folders = setModalFolderChildren(
          cloneDeep(state).modalResults,
          action.payload[0].parent ? action.payload[0].parent._id : null,
          action.payload,
          'stateMachine',
        );
      }
      return {
        ...state,
        modalResults: folders.length === 0 ? state.modalResults : folders,
      };
    case ERROR:
      return { ...state, error: true };
    default:
      return state;
  }
};

export const getDefaultStateMachine = () => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      fetchPolicy: 'no-cache',
      mutation: queries.Q_GET_DEFAULT_STATE_MACHINE,
    });

    dispatch({
      type: GET_DEFAULT_STATE_MACHINE,
      payload: response.data,
    });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
  }
};

export const getStateMachines = (filter: StateMachineWhereInput) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        filter,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_STATE_MACHINES,
    });

    return response.data.stateMachines;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getStateMachinesToTree =
  (options: { parent_exists: boolean; parent_eq: string }, item: { totalFolders: number; totalItems: number }) =>
  async (dispatch: Dispatch) => {
    try {
      let response: ApolloQueryResult<any> | null = null;
      if (item?.totalFolders || options.parent_exists !== undefined) {
        response = await APOLLO_CLIENT.query({
          variables: { where: options },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_STATE_MACHINE_FOLDERS_TO_TREE,
        });
      }

      let stateMachines: ApolloQueryResult<any> | null = null;
      if (options.parent_eq && item.totalItems) {
        stateMachines = await APOLLO_CLIENT.query({
          variables: { filter: { folder: { _id_eq: options.parent_eq } } },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_STATE_MACHINES_TO_TREE,
        });
      }
      return orderBy(
        {
          ...(response ? response.data.stateMachineFolders : []),
          ...(stateMachines ? stateMachines.data.stateMachines : []),
        },
        [
          (o): string => {
            return o.name || o.title;
          },
        ],
        'asc',
      );
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const search = (searchString: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { searchString },
      fetchPolicy: 'no-cache',
      mutation: queries.Q_SEARCH,
    });

    dispatch({
      type: SEARCH_STATE_MACHINE_FOLDERS,
      payload: response.data,
    });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
  }
};

export const getFolders =
  (options: any, modal = false) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          where: options,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_STATE_MACHINE_FOLDERS,
      });

      for (let i = 0; i < response.data.stateMachineFolders.length; i++) {
        const stateMachines = await APOLLO_CLIENT.query({
          variables: {
            filter: {
              folder: { _id_eq: response.data.stateMachineFolders[parseInt(`${i}`)]._id },
            },
          },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_STATE_MACHINES,
        });
        response.data.stateMachineFolders[parseInt(`${i}`)].stateMachinesCount =
          stateMachines.data.stateMachines.length;
        response.data.stateMachineFolders[parseInt(`${i}`)].stateMachines = stateMachines.data.stateMachines;
      }

      dispatch({
        type: modal ? MODAL_STATE_MACHINE_FOLDERS : GET_STATE_MACHINE_FOLDERS,
        payload: response.data.stateMachineFolders,
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
    }
  };
