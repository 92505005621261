import { styles } from 'src/utils/components/default-background/styles';
import { CardContent } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';

interface DefaultBackgroundProps extends WithStyles<typeof styles> {
  children: any;
  backgroundColor?: string;
  element?: string;
  login?: boolean;
}

const DefaultBackground = (props: DefaultBackgroundProps) => {
  const { children } = props;

  return (
    <CardContent
      className={`animated fadeIn ${
        props.login
          ? props.classes.cardContentLogin
          : props.element
            ? props.classes.bodyElementView
            : props.classes.body
      }`}
      style={props.backgroundColor ? { backgroundColor: props.backgroundColor } : {}}
    >
      <div className={`${props.login ? props.classes.bodyLogin : props.classes.bodyPadding}`}>{children}</div>
    </CardContent>
  );
};

export default withStyles(styles)(DefaultBackground);
