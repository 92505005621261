import { MenuItem, Popover } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { WithStyles, withStyles } from '@mui/styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { styles } from 'src/utils/components/generic-input-card/styles';
import { useState, useMemo, Ref } from 'react';
import { Input } from 'src/interfaces/inputs';
import GenericCardInputData from '../interfaces/GenericCardInputData';
import {
  withIssueTemplateConnector,
  WithIssueTemplateConnectorProps,
} from 'src/modules/issue-templates/redux/issue.template.draft.redux';

interface StringPopoverProps extends WithIssueTemplateConnectorProps, WithTranslation, WithStyles<typeof styles> {
  anchor: {
    current: Element;
  };
  onClose: () => void;
  input: GenericCardInputData;
  setInput: (input: Partial<Input>) => void;
  innerRef: Ref<HTMLDivElement>;
  subRef: Ref<HTMLDivElement>;
}

const StringPopover = (props: StringPopoverProps) => {
  const { classes, input, setInput, innerRef, subRef, anchor, issueTemplate, selectedTask } = props;
  const [openLimitCharacters, setOpenLimitCharacters] = useState<EventTarget | null>(null);
  const [openContentValidation, setOpenContentValidation] = useState<EventTarget | null>(null);
  const [openValidation, setOpenValidation] = useState<EventTarget | null>(null);

  const qrCodeInputsToLink = (inputs) => {
    let inpsToReturn = [];
    let inputEqual = false;
    inputs?.forEach((inp) => {
      if (inputEqual) return;
      if (
        (inp.type === 'qrCode' || (inp.type === 'string' && inp.string.setValueWithQRCode)) &&
        inp._id !== input._id
      ) {
        inpsToReturn.push(inp);
      } else if (inp._id === input._id) {
        inputEqual = true;
        return;
      }
      if (inp[inp.type].onResponse?.length) {
        inp[inp.type].onResponse.map((onResp) => {
          if (onResp.inputs?.length) {
            const onRespInps = qrCodeInputsToLink(onResp.inputs);
            inpsToReturn = [...inpsToReturn, ...onRespInps];
          }
        });
      }
    });
    return inpsToReturn;
  };

  const qrInputsExist = useMemo(() => {
    let inpsWithQrCode = {};
    let breakMaps = false;

    issueTemplate?.taskTemplateGroups.map((g) => {
      g.taskTemplates?.map((t) => {
        if (breakMaps) {
          return;
        }
        const inps = qrCodeInputsToLink(t.inputs);
        if (inps.length) {
          inpsWithQrCode = { ...inpsWithQrCode, [g.name]: { ...inpsWithQrCode[g.name], [t._id]: inps } };
        }
        if (t._id === selectedTask._id) {
          breakMaps = true;
        }
      });
    });
    return Object.keys(inpsWithQrCode).length ? true : false;
  }, [issueTemplate?.taskTemplateGroups, input, selectedTask?._id]);

  const handleNewCondition = (condition: string) => {
    const currentValidations = input[input.type].validations || [];
    const regexValidations = ['contains:', 'notContains:', 'startWith:', 'endWith:'];

    let updatedValidations = [...currentValidations];

    const findAndUpdateValidation = (name: string, updates: object) => {
      const regexValidationExists = input[input.type].validations.some((val: { name: string }) =>
        regexValidations.includes(val?.name.split(':')[0] + ':'),
      );

      const index = updatedValidations.findIndex(
        (v) =>
          v.name === name ||
          (regexValidations.includes(v?.name.split(':')[0] + ':') && !['minmax', 'min', 'max'].includes(name)),
      );

      if (index !== -1) {
        updatedValidations[index] = {
          ...updatedValidations[index],
          ...updates,
          name: regexValidationExists ? name : updatedValidations[index]?.name,
        };
      } else {
        updatedValidations.push({ name, ...updates });
      }
    };

    const minmaxExists = input[input.type].validations.some(
      (val: { name: string }) => val?.name === 'minmax' && (condition === 'min' || condition === 'max'),
    );

    if (minmaxExists) {
      return;
    }

    if (condition === 'min') {
      findAndUpdateValidation('min', { min: null, inclusive: true });
      const maxValidation = updatedValidations.find((v) => v.name === 'max');
      if (maxValidation) {
        findAndUpdateValidation('minmax', { min: null, max: maxValidation.max });
        updatedValidations = updatedValidations.filter((v) => v.name !== 'min' && v.name !== 'max');
      }
    } else if (condition === 'max') {
      findAndUpdateValidation('max', { max: null, inclusive: true });
      const minValidation = updatedValidations.find((v) => v.name === 'min');
      if (minValidation) {
        findAndUpdateValidation('minmax', { min: minValidation.min, max: null });
        updatedValidations = updatedValidations.filter((v) => v.name !== 'min' && v.name !== 'max');
      }
    } else if (regexValidations.includes(condition)) {
      findAndUpdateValidation(condition, { regex: '' });
    }

    updatedValidations = updatedValidations.filter(
      (v) => v.name === 'minmax' || Object.keys(v).some((key) => v[key] !== null && v[key] !== undefined),
    );

    if (input.type === 'string') {
      return setInput({
        _id: input._id,
        [input.type]: {
          ...input[input.type],
          validations: updatedValidations,
        },
      });
    }

    setInput({
      _id: input._id,
      [input.type]: {
        ...input[input.type],
        validations: updatedValidations,
      },
      string: {
        ...input['string'],
        validations: updatedValidations,
      },
    });
  };

  return (
    <Popover
      open={true}
      anchorEl={anchor.current}
      onClose={(): void => {
        props.onClose();
      }}
      ref={innerRef}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {input.type !== 'tool' && (
        <MenuItem
          id={`menuItemValidationCharLimits`}
          data-testid={`menuItemValidationCharLimits`}
          classes={{ root: classes.menuItemAdvanced }}
          onClick={(e): void => {
            setOpenLimitCharacters(e.target);
          }}
        >
          <span className={classes.menuItemWithoutCheck}>{props.t('characterLimits')}</span>
          <ChevronRight classes={{ root: classes.chevronRight }} />
        </MenuItem>
      )}
      {openLimitCharacters ? (
        <Popover
          open={!!openLimitCharacters}
          // @ts-ignore
          anchorEl={openLimitCharacters}
          ref={subRef}
          onClose={(): void => {
            setOpenLimitCharacters(null);
          }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem
            id={`menuItemValidationMinChar`}
            data-testid={`menuItemValidationMinChar`}
            classes={{ root: classes.menuItemAdvanced }}
            onClick={(): void => {
              handleNewCondition('min');
              setOpenLimitCharacters(null);
              props.onClose();
            }}
          >
            {props.t('minOfCharacters')}
          </MenuItem>
          <MenuItem
            id={`menuItemValidationMaxChar`}
            data-testid={`menuItemValidationMaxChar`}
            classes={{ root: classes.menuItemAdvanced }}
            onClick={(): void => {
              handleNewCondition('max');
              setOpenLimitCharacters(null);
              props.onClose();
            }}
          >
            {props.t('maxOfCharacters')}
          </MenuItem>
        </Popover>
      ) : null}
      <MenuItem
        id={`menuItemValidationContent`}
        data-testid={`menuItemValidationContent`}
        classes={{ root: classes.menuItemAdvanced }}
        onClick={(e): void => {
          setOpenContentValidation(e.target);
        }}
      >
        <span className={classes.menuItemWithoutCheck}>{props.t('contentValidation')}</span>
        <ChevronRight classes={{ root: classes.chevronRight }} />
      </MenuItem>
      {openContentValidation ? (
        <Popover
          open={!!openContentValidation}
          // @ts-ignore
          anchorEl={openContentValidation}
          ref={subRef}
          onClose={(): void => {
            setOpenContentValidation(null);
          }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem
            id={`menuItemValidationContains`}
            data-testid={`menuItemValidationContains`}
            classes={{ root: classes.menuItemAdvanced }}
            onClick={(): void => {
              handleNewCondition('contains:');
              setOpenContentValidation(null);
              props.onClose();
            }}
          >
            {props.t('contains')}
          </MenuItem>
          <MenuItem
            id={`menuItemValidationNotContains`}
            data-testid={`menuItemValidationNotContains`}
            classes={{ root: classes.menuItemAdvanced }}
            onClick={(): void => {
              handleNewCondition('notContains:');
              setOpenContentValidation(null);
              props.onClose();
            }}
          >
            {props.t('notContains')}
          </MenuItem>
          <MenuItem
            id={`menuItemValidationStartWith`}
            data-testid={`menuItemValidationStartWith`}
            classes={{ root: classes.menuItemAdvanced }}
            onClick={(): void => {
              handleNewCondition('startWith:');
              setOpenContentValidation(null);
              props.onClose();
            }}
          >
            {props.t('startWith')}
          </MenuItem>
          <MenuItem
            id={`menuItemValidationEndWith`}
            data-testid={`menuItemValidationEndWith`}
            classes={{ root: classes.menuItemAdvanced }}
            onClick={(): void => {
              handleNewCondition('endWith:');
              setOpenContentValidation(null);
              props.onClose();
            }}
          >
            {props.t('endWith')}
          </MenuItem>
          {input.type === 'qrCode' ? (
            <MenuItem
              id={`menuItemValidationLinkWithInput`}
              data-testid={`menuItemValidationLinkWithInput`}
              classes={{ root: classes.menuItemAdvanced }}
              disabled={!qrInputsExist}
              onClick={(): void => {
                setInput({
                  _id: input._id,
                  required: true,
                  [input.type]: {
                    ...input[input.type],
                    validations: [{ linkWithInputs: [], name: 'linkWith' }],
                  },
                });
                setOpenContentValidation(null);
                props.onClose();
              }}
            >
              {props.t('linkWithInput')}
            </MenuItem>
          ) : input.type === 'tool' ? (
            <>
              <MenuItem
                id={`menuItemValidationContent`}
                data-testid={`menuItemValidationContent`}
                classes={{
                  root: openValidation
                    ? `${classes.menuItemAdvanced} ${classes.menuItemAdvancedSelected}`
                    : classes.menuItemAdvanced,
                }}
                onClick={(e): void => {
                  setOpenValidation(e.target);
                }}
              >
                {props.t('validation')}
                <ChevronRight classes={{ root: classes.chevronRight }} />
              </MenuItem>
              <Popover
                open={!!openValidation}
                // @ts-ignore
                anchorEl={openValidation}
                ref={subRef}
                onClose={(): void => {
                  setOpenValidation(null);
                  setOpenContentValidation(null);
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                style={{ marginTop: '10px' }}
              >
                {openValidation ? (
                  <>
                    <MenuItem
                      id={`menuItemValidationLinkWithInput`}
                      data-testid={`menuItemValidationLinkWithInput`}
                      classes={{ root: classes.menuItemAdvanced }}
                      onClick={(): void => {
                        setInput({
                          _id: input._id,
                          required: true,
                          [input.type]: {
                            ...input[input.type],
                            validations: [{ linkWithInputs: [], name: 'linkWith' }],
                          },
                        });
                        setOpenValidation(null);
                        setOpenContentValidation(null);
                        props.onClose();
                      }}
                    >
                      {props.t('linkWithInput')}
                    </MenuItem>
                    <MenuItem
                      id={`menuItemValidationSpecificTool`}
                      data-testid={`menuItemValidationSpecificTool`}
                      classes={{ root: classes.menuItemAdvanced }}
                      onClick={(): void => {
                        setInput({
                          _id: input._id,
                          required: true,
                          [input.type]: {
                            ...input[input.type],
                            validations: [{ specificTool: [], name: 'specificTool' }],
                          },
                        });
                        setOpenValidation(null);
                        setOpenContentValidation(null);
                        props.onClose();
                      }}
                    >
                      {props.t('specificTool')}
                    </MenuItem>
                  </>
                ) : undefined}
              </Popover>
            </>
          ) : undefined}
        </Popover>
      ) : null}
    </Popover>
  );
};

export default withIssueTemplateConnector(withTranslation('translation')(withStyles(styles)(StringPopover)));
