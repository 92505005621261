import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    popoverContainer: {
      height: '100%',
      left: 'unset !important',
      right: '0',
      top: '0 !important',
      width: '370px',
      maxHeight: '100% !important',
      display: 'flex',
      flexDirection: 'column',
    },
    headerInfo: {
      marginTop: '15px',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.13)',
    },
    closeIcon: {
      color: theme.palette.text.secondary,
      marginRight: '20px',
      marginLeft: 'auto',
      cursor: 'pointer',
    },
    bodyInfo: {
      paddingLeft: '25px',
      paddingRight: '20px',
      marginTop: '15px',
      overflowY: 'auto',
      height: '100%',
    },
    infoType: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '20px',
      color: theme.palette.other.peach,
      marginLeft: '25px',
      display: 'flex',
      alignItems: 'center',
    },
    infoTitle: {
      fontSize: '21px',
      fontFamily: 'Poppins',
      fontWeight: 400,
      marginTop: '15px',
      marginLeft: '25px',
      paddingBottom: '15px',
    },
    actionCardContainer: {
      /*  marginTop: '20px',
      marginBottom: '20px',
      border: `1px solid ${theme.palette.text.disabled}`,
      borderRadius: '2px', */
    },
    statusShow: {
      borderRadius: '30px',
      /* minWidth: '110px', */
      fontFamily: 'Poppins',
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: '6px',
      paddingRight: '6px',
      fontSize: '12px',
      width: 'fit-content',
      height: '18px',
      color: theme.palette.background.default,
    },
    statusPending: {
      color: '#272848 !important',
      backgroundColor: theme.palette.alert.main,
    },
    statusCantDo: {
      backgroundColor: theme.palette.error.main,
    },
    statusDoing: {
      backgroundColor: theme.palette.primary.main,
    },
    statusSolved: {
      backgroundColor: theme.palette.success.main,
    },
    spanGoTo: {
      fontFamily: 'Open Sans',
      fontSize: '12px',
      color: theme.palette.text.secondary,
      marginLeft: 'auto',
      marginRight: 0,
      alignSelf: 'center',
    },
    spanGoToAction: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '12px',
      color: theme.palette.primary.main,
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    divActionName: {
      margin: '10px 10px 10px 10px',
      fontFamily: 'Poppins',
      fontSize: '16px',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    divFooter: {
      height: '65px',
      /* boxShadow: '0px -6px 8px rgba(0, 0, 0, 0.06)', */
      display: 'flex',
      alignItems: 'center',
    },
    issueSpan: {
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontSize: '16px',
      color: theme.palette.text.secondary,
    },
    issueNameSpan: {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '16px',
    },
    popoverLinkButtonDiv: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginBottom: '10px',
    },
  });
