import {
  AccessibilityNew,
  ArrowBackIos,
  Close,
  HourglassEmpty,
  North,
  Search,
  Settings,
  South,
} from '@mui/icons-material';
import { Backdrop, CardContent, Chip, CircularProgress, Tooltip, useTheme } from '@mui/material';
import { IconButton, Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AccountOrderByInput,
  GetAccountsByTenantQueryResult,
  useGetAccountsByTenantLazyQuery,
  useLoginMutation,
  useSingleTenantQuery,
} from 'src/gql/graphql';
import { withRefresh } from 'src/utils/components/refresh-provider';
import TableAbstract from 'src/utils/components/table/abstract/table-abstract';
import { useStyles } from './styles';
import moment from 'moment';
import { AccountAvatar } from 'src/utils/components/account-avatar';
import { ArConfigurationPopover } from './ar-configuration-popover';
import { InputBase } from '@mui/material';
import useDebouncedCallback from 'src/utils/hooks/useDebouncedCallback';
import classNames from 'classnames';
import DefaultButton from 'src/utils/components/default-button/default-button';
import { css } from '@emotion/css';
import { compose } from 'redux';
import { withDefaultTenant } from './with-tenant';
import { useSnackbar } from 'notistack';
import { ToastMessage } from '../feed/components/toast';
import Swal from 'sweetalert2';
import { useAccountActivity } from 'src/base/last-activity';
import { OnlineStatus } from 'src/base/last-activity/online-status';
import { TenantAccountsHeader } from './tenant-accounts-header';
import GlarFilterButton from 'src/utils/components/glar-filter-popover/glar-filter-button';
import { countFilters } from 'src/utils/components/glar-filter-popover';
import AccountsFilterPopover, {
  AccountsFilters,
} from 'src/utils/components/glar-filter-popover/preset-filters/accounts-filter';
import GlarFilterToastBars from 'src/utils/components/glar-filter-popover/filter-toast-bars';
import useEffectEvent from 'src/utils/hooks/useEffectEvent';

function TenantsAccountsRaw() {
  const { tenant: tenantId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [filters, setFilters] = useState<AccountsFilters>({});
  const [openFilterPopover, setOpenFilterPopover] = useState(false);
  const [openArConfigurationPopover, setOpenArConfigurationPopover] = useState(false);

  const [orderBy, setOrderBy] = useState<AccountOrderByInput>(AccountOrderByInput.name_ASC);

  const [tableOrderKey, tableOrderType] = orderBy?.split('_') || [];

  const [loginMutation, { loading: loadingLogin }] = useLoginMutation();

  const { data: tenantData, loading: loadingTenantData } = useSingleTenantQuery({
    variables: { where: { _id: tenantId } },
    skip: typeof tenantId !== 'string',
  });

  const { enqueueSnackbar } = useSnackbar();

  const [accounts, setAccounts] = useState<GetAccountsByTenantQueryResult['data']['accounts']>([]);

  const [getAccountsByTenant, { data: { accountsCount = 0 } = {}, loading }] = useGetAccountsByTenantLazyQuery();

  const setSearch = useDebouncedCallback(
    (val?: string) => setFilters((filters) => ({ ...filters, search: val })),
    200,
    [],
  );

  const activeDates = useAccountActivity(accounts.map((d) => d._id));

  const impersonateUser = useEffectEvent(async (email: string) =>
    loginMutation({
      variables: { subDomain: tenantData.tenant.domain, username: email },
      async onCompleted(data) {
        const accessToken = localStorage.getItem('access_token');
        localStorage.clear();
        localStorage.setItem('old_access_token', accessToken);
        localStorage.setItem('access_token', data.login._id);

        navigate('/');
        window.location.reload();
      },
      onError(error) {
        enqueueSnackbar(<ToastMessage message={error.message} severity='error' />, { variant: 'error' });
      },
    }),
  );

  const tableHeaderTitle = (name: string, orderKey: string) => {
    return (
      <div className={classes.divHeader}>
        <span
          css={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: 14,
            cursor: 'pointer',
          }}
          className={classNames({ [classes.columnSorting]: tableOrderKey === orderKey })}
        >
          {t(name)}
        </span>
        {tableOrderType === 'ASC' && tableOrderKey === orderKey ? (
          <North
            classes={{
              root: `${classes.sortArrowIcon} ${tableOrderKey === orderKey ? classes.sortArrowIconVisible : classes.sortArrowIconHidden}`,
            }}
            onClick={(e) => {
              e.stopPropagation();
              setOrderBy((orderKey + '_DESC') as any);
            }}
          />
        ) : (
          <South
            classes={{
              root: `${classes.sortArrowIcon} ${tableOrderKey === orderKey ? classes.sortArrowIconVisible : classes.sortArrowIconHidden}`,
            }}
            onClick={(e) => {
              e.stopPropagation();
              setOrderBy((orderKey + '_ASC') as any);
            }}
          />
        )}
      </div>
    );
  };

  const columns: ColumnDef<GetAccountsByTenantQueryResult['data']['accounts'][0]>[] = useMemo(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: () => tableHeaderTitle('name', 'name'),
        cell: (props) => (
          <div css={{ display: 'flex', alignItems: 'center', gap: 8, padding: 8 }}>
            <AccountAvatar account={props.row.original} />
            <span
              css={{ '&:hover': { textDecoration: 'underline', cursor: 'pointer', color: theme.palette.primary.dark } }}
              onClick={() => navigate(`/tenants/${tenantId}/profile?id=${props.row.original._id}`)}
            >
              {props.row.original.name}
            </span>
          </div>
        ),
      },
      {
        id: 'email',
        accessorKey: 'email',
        header: () => tableHeaderTitle('email', 'email'),
        cell: (props) => props.row.original.email,
      },
      {
        id: 'createdAt',
        accessorKey: 'createdAt',
        header: () => tableHeaderTitle('createdAt', 'createdAt'),
        cell: (props) => moment(props.row.original.createdAt).format(`DD/MM/YYYY [${t('at')}] hh:mm`),
      },
      {
        id: 'lastSeen',
        accessorKey: 'lastSeen',
        header: t('lastSeen'),
        cell: (props) => (
          <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <OnlineStatus unixTimestamp={activeDates.find((d) => d._id === props.row.original._id)?.lastActiveDate} />
            {!props.row.original.isActive && (
              <Chip
                classes={{
                  label: css({
                    fontSize: '12px',
                    color: `gray !important`,
                    fontWeight: 400,
                  }),
                }}
                size={'small'}
                data-testid={`deactivated-${props.row.original.name}`}
                label={t('deactivated')}
              />
            )}
          </div>
        ),
      },
    ],
    [orderBy, classes, activeDates],
  );

  return (
    <CardContent
      css={{
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {loadingLogin ? (
        <Backdrop open={true}>
          <CircularProgress color='inherit' />
        </Backdrop>
      ) : (
        <>
          <div
            css={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              margin: 12,
              gap: 8,
            }}
          >
            {tenantData ? (
              <>
                <IconButton onClick={() => navigate('/tenants')}>
                  <ArrowBackIos fontSize={'small'} htmlColor={theme.palette.primary.dark} />
                </IconButton>
                <Typography
                  className={css({
                    marginRight: 'auto',
                  })}
                  variant={'h3'}
                  color={theme.palette.primary.dark}
                >
                  {tenantData?.tenant?.name}
                </Typography>
                <DefaultButton
                  className={css({
                    display: 'flex',
                    alignItems: 'center',
                    margin: '0px',
                  })}
                  data-testid={'go-to-invites'}
                  variant={'discard'}
                  size={'medium'}
                  onClick={() => {
                    navigate(`/tenants/${tenantId}/pending-invites`);
                  }}
                >
                  <HourglassEmpty
                    className={css({
                      width: '20px',
                      height: '20px',
                    })}
                  />
                  {loadingTenantData ? <CircularProgress /> : <span>{tenantData.tenant.invitesCount}</span>}
                </DefaultButton>
                <DefaultButton
                  variant={'default'}
                  data-testid='add-users-button'
                  onClick={() => navigate(`/tenants/${tenantId}/add-users`)}
                >
                  {t('addUsers')}
                </DefaultButton>
              </>
            ) : null}
            <IconButton onClick={() => setOpenArConfigurationPopover(true)}>
              <Settings />
            </IconButton>
          </div>
          <div
            css={{
              display: 'flex',
              justifyContent: 'flex-start',
              gap: 8,
              margin: 12,
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            {!!selectedAccounts.length && (
              <TenantAccountsHeader selected={selectedAccounts} clear={() => setSelectedAccounts([])} />
            )}
            <div css={{ marginLeft: 'auto' }} />
            {openSearch ? (
              <>
                <InputBase
                  autoFocus
                  css={{
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: '4px',
                    padding: '0px 4px',
                  }}
                  key={filters.search}
                  defaultValue={filters.search ?? ''}
                  onChange={(e) => {
                    const val = e.target.value;
                    setSearch(val);
                  }}
                />
                <IconButton
                  onClick={() => {
                    setOpenSearch(false);
                    setFilters((filters) => ({ ...filters, search: undefined }));
                  }}
                >
                  <Close />
                </IconButton>
              </>
            ) : (
              <IconButton onClick={() => setOpenSearch(true)}>
                <Search />
              </IconButton>
            )}
            <GlarFilterButton
              data-testid={'tenant-account-filter-button'}
              filterCount={countFilters(filters)}
              onClick={() => setOpenFilterPopover(true)}
            />
          </div>
          <GlarFilterToastBars filters={filters} setFilters={setFilters} />
          <TableAbstract
            data-testid={'tenant-accounts-table'}
            showLoading={loading}
            columns={columns}
            showCheckboxes={!tenantData?.tenant?.isAdminTenant}
            onCheckboxesChanged={(indices, data) => setSelectedAccounts(indices.map((i) => data[i]._id))}
            dataSize={accountsCount}
            loadData={async (skip, limit) => {
              return (
                await getAccountsByTenant({
                  variables: {
                    tenant: tenantId,
                    filter: {
                      name_contains: filters.search ? filters.search : undefined,
                      roles_in: filters.roles?.length ? filters.roles.map((r) => r._id) : undefined,
                      labelValues_in: filters.labels?.length ? filters.labels.map((l) => l._id) : undefined,
                      authorizedSites_in: filters.authorizedSites?.length
                        ? filters.authorizedSites.map((s) => s._id)
                        : undefined,
                    },
                    limit,
                    skip,
                    orderBy,
                  },
                })
              ).data.accounts;
            }}
            onDataUpdated={setAccounts}
            moreOptionsCell={(props) =>
              !props.row.original.isPlatformAdmin &&
              props.row.original.isActive && (
                <IconButton
                  onClick={() => {
                    Swal.fire({
                      title: t('areYouSureImpregnateUser', { name: props.row.original.username }),
                      showCancelButton: true,
                      reverseButtons: true,
                      cancelButtonText: t('cancel'),
                      confirmButtonText: t('continue'),
                    }).then((val) => {
                      if (val.isConfirmed) {
                        impersonateUser(props.row.original.email);
                      }
                    });
                  }}
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    visibility: 'hidden',
                    cursor: 'pointer',
                  }}
                >
                  <Tooltip arrow placement={'left'} title={t('impersonateUser')}>
                    <AccessibilityNew />
                  </Tooltip>
                </IconButton>
              )
            }
            refetchDependencies={[orderBy, tenantId, filters]}
          />
          {openArConfigurationPopover ? (
            <ArConfigurationPopover tenantId={tenantId} onClose={() => setOpenArConfigurationPopover(false)} />
          ) : null}
          {openFilterPopover && (
            <AccountsFilterPopover
              onClose={() => setOpenFilterPopover(false)}
              onSubmit={setFilters}
              defaultFilters={filters}
            />
          )}
        </>
      )}
    </CardContent>
  );
}

export const TenantsAccounts = compose(withRefresh, withDefaultTenant)(TenantsAccountsRaw);
