import * as React from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';

const RoomsRefresh = () => React.lazy(() => import('src/modules/remote-assistance/rooms/rooms'));

const Rooms = RoomsRefresh();

export const render = {
  default: ({ ...props }) => {
    return (
      <React.Suspense fallback={<LazyLoadingContent />}>
        <Rooms {...props} />
      </React.Suspense>
    );
  },
};

export default render;
