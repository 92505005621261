import { useEffect, useMemo, useRef, useState } from 'react';
import { CommentMention, CommentMentionCreateInput } from 'src/gql/graphql';
import IssueMention from './components/mentions/issue-mention';
import AccountMention from './components/mentions/account-mention';

interface IncompleteMention {
  type: 'account' | 'issue';
  text: string;
}

export interface Mention extends Omit<CommentMention, '__typename'> {}

type OnComplete = (character: '#' | '@') => (mention: CommentMentionCreateInput) => any;

export default function useMentions(
  text: string,
  setText: (val: string) => any,
  defaultMentions: Omit<CommentMention, '__typename'>[],
) {
  const ref = useRef<HTMLInputElement>();
  const [selection, setSelection] = useState(0);
  const [mentions, setMentions] = useState<CommentMentionCreateInput[]>([]);

  useEffect(() => {
    const onSelectionChange = () => setSelection(ref.current.selectionStart);

    ref.current.addEventListener('keyup', onSelectionChange);
    ref.current.addEventListener('click', onSelectionChange);

    return () => {
      ref.current?.removeEventListener('keyup', onSelectionChange);
      ref.current?.removeEventListener('click', onSelectionChange);
    };
  }, []);

  const incompleteMention = useMemo<IncompleteMention | null>(() => {
    if (!ref.current) {
      return null;
    }

    const lastContiguousText = text.substring(0, selection).split(' ').at(-1);

    const lastIssueIndex = lastContiguousText.lastIndexOf('#');
    const lastAccountIndex = lastContiguousText.lastIndexOf('@');

    if (lastIssueIndex === -1 && lastAccountIndex === -1) {
      return null;
    }

    if (lastIssueIndex > lastAccountIndex) {
      return { type: 'issue', text: lastContiguousText.split('#').at(-1) };
    }

    return { type: 'account', text: lastContiguousText.split('@').at(-1) };
  }, [text, selection, mentions.length]);

  useEffect(() => {
    setMentions(
      (defaultMentions || []).map((m) => ({
        type: m.type,
        text: m.text,
        action: m.actionId,
        issue: m.issueId,
        account: m.accountId,
      })),
    );
  }, [defaultMentions]);

  useEffect(() => {
    const usedMentions = mentions.filter((m) => text.includes(m.text));

    if (usedMentions.length < mentions.length) {
      setMentions(usedMentions);
    }
  }, [text]);

  const onComplete: OnComplete = (character: '#' | '@') => (mention: CommentMentionCreateInput) => {
    const lastIndex = text.substring(0, selection).lastIndexOf(character);

    const preString = text.substring(0, lastIndex) + mention.text + ' ';

    setText(preString + text.substring(selection));
    setSelection(preString.length);

    setMentions((mentions) => [...mentions, mention]);

    setTimeout(() => {
      ref.current.selectionStart = ref.current.selectionEnd = preString.length;
      ref.current.focus();
    });
  };

  return { incompleteMention, onComplete, mentions, ref };
}

export function ShowMentions({
  incompleteMention,
  inputRef,
  onComplete,
  postId,
}: {
  incompleteMention: IncompleteMention | null;
  inputRef: React.RefObject<HTMLInputElement>;
  onComplete: OnComplete;
  postId: string;
}) {
  return incompleteMention ? (
    incompleteMention.type === 'issue' ? (
      <IssueMention
        anchorEl={inputRef.current}
        textToAutocomplete={incompleteMention.text}
        onComplete={onComplete('#')}
      />
    ) : (
      <AccountMention
        anchorEl={inputRef.current}
        textToAutocomplete={incompleteMention.text}
        onComplete={onComplete('@')}
        postId={postId}
      />
    )
  ) : null;
}
