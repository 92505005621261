import { AccessorKeyColumnDef, createColumnHelper } from '@tanstack/react-table';
import { DEFAULT_COLUMN_MIN_WIDTH, DEFAULT_COLUMN_WIDTH } from '../constants/table.constants';

/**
 * @deprecated This function is for a specific case to prevent major refactors to a badly typed structure.
 */
const mapDeprecatedUntypedColumns = <T>(columns: any) => {
  const columnHelper = createColumnHelper<T>();
  const mappedColumns: AccessorKeyColumnDef<T>[] = columns.map((c) => {
    //@ts-ignore
    return columnHelper.accessor(c.accessorKey as keyof T, {
      id: c.id,
      cell: c.cell,
      size: c.width || c.size || DEFAULT_COLUMN_WIDTH,
      minSize: c.minSize ?? DEFAULT_COLUMN_MIN_WIDTH,
      enableResizing: c?.enableResizing,
      header: c.header,
    });
  });
  return mappedColumns;
};

/**
 * @deprecated This function is for a specific case to prevent major refactors to a badly typed structure.
 */
export { mapDeprecatedUntypedColumns };
