import * as React from 'react';
import { Popover } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';
import styles from './styles';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { identity } from 'lodash';

export type DropdownOption = {
  id: string;
  label: string;
  hidden?: boolean;
  icon?: React.ReactNode;
  options?: DropdownOption[];
  color?: string;
};

interface DropDownProps extends WithStyles<typeof styles> {
  handleChange: (val: string) => any;
  options: DropdownOption[];
  value: string;
  zIndex?: number;
  mainStyles?: any;
  dropdownStyles?: any;
}

const portalId = 'multi-level-dropdown';

const dropdownEl = document.createElement('div');
dropdownEl.id = portalId;
document.body.appendChild(dropdownEl);

//https://github.com/varunpappu/react-nested-menu-selector
const DropDownMenuSelect: React.FC<DropDownProps> = (props) => {
  const { classes, value = '', mainStyles } = props;

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>();

  const renderPlaceholder = (placeholder) => <div>{placeholder || <>&nbsp;</>}</div>;

  const toggleMenu = () => setIsMenuOpen((isMenuOpen) => !isMenuOpen);

  return (
    <div className={classes['root-menu-container']} ref={ref} style={mainStyles}>
      <div className={classes['menu-selector']} onClick={toggleMenu}>
        {renderPlaceholder(value)}
        <ChevronLeft style={{ marginLeft: 'auto', rotate: '-90deg' }} />
      </div>
      <MultiLevelMenu {...props} anchorRef={ref} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
    </div>
  );
};

type MultiLevelMenu = {
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  anchorRef: React.RefObject<HTMLDivElement>;
} & DropDownProps;

export const MultiLevelMenu = withStyles(styles)(function (props: MultiLevelMenu) {
  const { isMenuOpen, setIsMenuOpen, classes, anchorRef, handleChange = identity, options = [] } = props;

  const renderOptionsMenu = (options: DropdownOption[]) =>
    (options || []).map((item, i) => {
      if (!item.hidden) {
        if (item.options && item.options.length) {
          return (
            <div
              key={`${item.label}-${i}`}
              className={classes['options-container']}
              style={item.color ? { color: item.color } : undefined}
            >
              {item.icon}
              <div
                id={`select-option-${item.label}`}
                data-testid={`select-option-${item.label}`}
                className={classes['options-label']}
              >
                {item.label}
              </div>
              {renderSubMenu(item)}
            </div>
          );
        }

        return <React.Fragment key={`${item.label}-${i}`}>{renderSubMenu(item)}</React.Fragment>;
      }
      return null;
    });

  const renderSubMenu = (item: DropdownOption) => {
    if (!item.hidden) {
      if (item.options && item.options.length) {
        return (
          <>
            <ChevronRight id='select-right-arrow-icon' className={classes['arrow-right']} />
            <div className={classes['options-sub-menu-container']}>{renderOptionsMenu(item.options)}</div>
          </>
        );
      }

      return (
        <div
          className={classes['options-container']}
          style={item.color ? { color: item.color } : undefined}
          onClick={() => {
            handleChange(item.id);
            props.setIsMenuOpen(false);
          }}
        >
          {item.icon}
          <div
            id={`select-option-${item.label}`}
            data-testid={`select-option-${item.label}`}
            className={classes['options-label']}
          >
            {item.label}
          </div>
        </div>
      );
    }
    return null;
  };

  return isMenuOpen ? (
    <Popover
      open={!!isMenuOpen}
      anchorEl={anchorRef.current}
      onClose={() => {
        setIsMenuOpen(false);
      }}
      classes={{ paper: classes.popoverFrequencies }}
      className={classes.popoverShadow}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {renderOptionsMenu(options)}
    </Popover>
  ) : null;
});

export default withStyles(styles)(DropDownMenuSelect);
