import GlarFilterPopover, { GenericFilterPopoverProps } from '..';
import { LabelValueFilterItem, RoleFilterItem, SiteElementFilterItem } from '../preset-filter-items';
import { Label, Role, Site } from 'src/gql/graphql';

export type AccountsFilters = Partial<{
  search: string;
  roles: Role[];
  authorizedSites: Site[];
  labels: Label[];
}>;

export default function AccountsFilterPopover(props: GenericFilterPopoverProps<AccountsFilters>) {
  return (
    <GlarFilterPopover {...props}>
      <RoleFilterItem accessor={'roles'} />
      <SiteElementFilterItem accessor={'authorizedSites'} context={'site'} />
      <LabelValueFilterItem accessor={'labels'} context={'labelAccount'} />
    </GlarFilterPopover>
  );
}
