import { Chip, MenuItem, Popover, Typography } from '@mui/material';
import { Dispatch, bindActionCreators, compose } from 'redux';
import { withStyles } from '@mui/styles';
import { styles } from 'src/utils/components/generic-input-card/styles';
import { useEffect, Ref, useState, useContext, useMemo } from 'react';
import SelectValidationType from './select-validation-type';
import { Close, KeyboardArrowDown } from '@mui/icons-material';
import { connect } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';
import { getTool } from 'src/modules/inventory/inventory.redux';
import { IssueTemplateReportContext } from 'src/modules/report/components/modal-create-issue-report';
import {
  withIssueTemplateConnector,
  WithIssueTemplateConnectorProps,
} from 'src/modules/issue-templates/redux/issue.template.draft.redux';
import { Input, InputToolValidation, Tool } from 'src/gql/graphql';

interface ToolQRValidationChipsProps extends WithIssueTemplateConnectorProps {
  classes: Record<string, string>;
  input: Input;
  active: boolean;
  idx: number;
  regexName: any;
  setInput: (data) => void;
  validation: InputToolValidation;
  optionsWithoutLinkWith: { option: string; value: string }[];
  options: { option: string; value: string }[];
  selectRef: Ref<HTMLDivElement>;
  getTool: (where: any) => Promise<Tool>;
}

const ToolQRValidationChips = (props: ToolQRValidationChipsProps): JSX.Element => {
  const {
    classes,
    input,
    active,
    selectRef,
    validation,
    idx,
    options,
    optionsWithoutLinkWith,
    setInput,
    regexName,
    getTool,
    selectedTask,
  } = props;
  const [selectedPopover, setSelectedPopover] = useState<any>(null);
  const templateGroups = useContext(IssueTemplateReportContext) || props.templateGroups;

  const [taskTools, setTaskTools] = useState<Tool[]>([]);
  //@ts-ignore
  const [selectedTool, setSelectedTool] = useState<Tool>({});
  const { t } = useTranslation();

  useMemo(() => {
    setTaskTools(selectedTask?.assignedTools?.map((at) => at.tool));
  }, [templateGroups]);

  useEffect(() => {
    if (input.tool.validations[0].specificTool[0]) {
      getTool({ _id: input.tool.validations[0].specificTool[0] }).then((tool) => setSelectedTool(tool));
    }
  }, [input.tool.validations]);

  useEffect(() => {
    if (
      !selectedTask?.assignedTools
        ?.map((at) => at.tool)
        ?.some((tt) => tt._id === input.tool.validations[0].specificTool[0])
    ) {
      setInput({
        _id: input._id,
        [input.type]: {
          ...input[input.type],
          validations: [
            {
              ...input.tool.validations[0],
              specificTool: [],
            },
          ],
        },
      });
    }
  }, [templateGroups]);

  const handleToolSelected = (tool: Tool) => {
    setInput({
      _id: input._id,
      [input.type]: {
        ...input[input.type],
        validations: [
          {
            ...input.tool.validations[0],
            specificTool: [tool._id],
          },
        ],
      },
    });
  };

  return (
    <div className={classes.containerLinkInput}>
      <Chip
        id={'toolQrValidationChip'}
        size='small'
        label={
          <div className={classes.divLabel}>
            {active ? (
              <SelectValidationType
                id={`selection-input-not-sure-3`}
                data-testid={`selection-input-not-sure-3`}
                input={input}
                active={active}
                selectRef={selectRef}
                name={validation.name}
                idx={idx}
                linkWith={true}
                options={options}
                optionsWithoutLinkWith={optionsWithoutLinkWith}
                setInput={setInput}
              />
            ) : (
              regexName(validation.name)
            )}
            <div
              id={'toolQrValidationChipSelectSpecificTool'}
              data-testid={'toolQrValidationChipSelectSpecificTool'}
              className={classes.divSpecificTools}
              style={{ borderBottom: active ? '1px solid' : '' }}
              onClick={(e) => {
                if (active) setSelectedPopover(e.target);
              }}
            >
              {input.tool.validations[0].specificTool[0] ? (
                <Typography
                  style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                  className={classes.selectedTool}
                  id={'toolQrValidationChipSpecificTool'}
                >{`${selectedTool?.partNumber} - ${selectedTool?.designation}`}</Typography>
              ) : (
                <Typography
                  style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                  className={classes.placeholderTool}
                >
                  {t('select')}
                </Typography>
              )}
            </div>
            <div
              className={classes.divSpecificTools}
              style={{
                //@ts-ignore
                borderBottom: active && input[input.type].validations?.length < 2 ? '1px solid' : '',
                marginLeft: active ? '-5px' : '',
              }}
            >
              {active && (
                <KeyboardArrowDown
                  style={{ marginLeft: 'auto', marginRight: 0 }}
                  onClick={(e) => {
                    if (active) setSelectedPopover(e.target);
                  }}
                />
              )}
            </div>
            <Popover
              id={'toolQrValidationChipSpecificToolPopover'}
              ref={selectRef}
              open={selectedPopover}
              anchorEl={selectedPopover}
              className={classes.selectOptions}
              onClose={() => setSelectedPopover(null)}
            >
              {taskTools?.map((tool, i) => (
                <MenuItem
                  id={'toolQrValidationChipSpecificToolPopoverTools'}
                  onClick={() => {
                    handleToolSelected(tool);
                    setSelectedPopover(null);
                  }}
                  key={i}
                >
                  {`${t(tool?.partNumber)} - ${t(tool.designation)} `}
                </MenuItem>
              ))}
            </Popover>
            <Close
              id={'toolQrValidationChipClose'}
              classes={{ root: classes.closeIcon }}
              style={{ display: active ? '' : 'none' }}
              onClick={(): void => {
                setInput({
                  _id: input._id,
                  [input.type]: {
                    ...input[input.type],
                    multiple: false,
                    //@ts-ignore
                    validations: input[input.type].validations.filter((_v, i) => i !== idx),
                  },
                });
              }}
            />
          </div>
        }
        classes={{ root: classes.advancedChip }}
      />
    </div>
  );
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getTool,
    },
    dispatch,
  );

export default compose<any>(
  withStyles(styles),
  withTranslation('translation'),
  connect(mapStateToProps, mapDispatchToProps),
  withIssueTemplateConnector,
)(ToolQRValidationChips);
