import axios from 'axios';
import * as ReactDOM from 'react-dom';
import 'react-sweet-progress/lib/style.css';
import { Action, State } from 'src/interfaces/reducers';
import * as queries from 'src/modules/file-storage/file-storage.queries';
import i18n from 'src/utils/translations/i18n';
import { convertBytesToString, getErrorObject } from 'src/utils/funcs';
import { Box, Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { API_URL } from 'src/utils/env';
import { APOLLO_CLIENT } from 'config/apollo.config';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import { Dispatch } from 'redux';
import moment from 'moment';
import { FileUpdateInput, FileWhereInput, FileWhereUniqueInput, NotifyUploadMutation } from 'src/gql/graphql';
import { GraphQLErrors } from '@apollo/client/errors';
import { File as IFile } from 'src/gql/graphql';
import { dataURItoBlob, resizeImage } from 'src/utils/funcs/files/utils';

export const ERROR = 'ERROR';

const initialState: State = {};

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }): JSX.Element {
  return (
    <Box position='relative' display='inline-flex'>
      <CircularProgress variant='determinate' {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position='absolute'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <Typography variant='caption' component='div' color='textSecondary'>{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    default:
      return state;
  }
};

export const getFiles =
  (where: FileWhereInput, noCache = false) =>
  async (dispatch: Dispatch): Promise<IFile[] | { graphQLErrors: GraphQLErrors }> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          where,
        },
        query: queries.Q_GET_FILES,
        fetchPolicy: noCache ? 'network-only' : 'cache-first',
      });

      return response.data.files;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

const handleErrorRest = (error, dispatch: Dispatch): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (): void => {
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          //@ts-ignore
          message: JSON.parse(reader.result).errors[0].message,
          severity: 'error',
        },
      });
      resolve(Promise.reject(error));
    };

    reader.onerror = (): void => {
      reject(error);
    };

    reader.readAsText(error.response.data);
  });
};

export interface FileUploadResponse {
  data: {
    data: {
      files: Partial<IFile>[];
    };
  };
}

export const addFile =
  (data, index, total, thumbnail, totalTriangles?, uploadProgressCallback?: (name: string, progress: number) => void) =>
  async (dispatch: Dispatch): Promise<FileUploadResponse> => {
    function onError(error) {
      const obj = getErrorObject(error, '', dispatch);
      if (Object.keys(error).length && Object.keys(error)[0] !== 'message') {
        try {
          ReactDOM.render(
            <div>
              <div
                style={{
                  display: 'flex',
                  marginTop: '5px',
                  maxWidth: '107px',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    width: 'calc(100% - 0px)',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '9px',
                    fontWeight: 400,
                    fontFamily: '"Poppins", sans-serif',
                  }}
                >
                  {data.name}
                </span>
                {
                  <span
                    style={{
                      paddingLeft: '5px',
                      paddingRight: '5px',
                      cursor: 'pointer',
                    }}
                  >
                    {/*  <Tooltip title={i18n.t('delete').toString()} disableFocusListener={true}> */}
                    <Delete
                      style={{ fontSize: '14px', color: '#FF4E3E' }}
                      onClick={(): void => {
                        const elem: HTMLElement | null = document.getElementById(data.name);
                        if (elem?.childNodes[0]) elem.removeChild(elem.childNodes[0]);
                      }}
                    />
                    {/* </Tooltip> */}
                  </span>
                }
              </div>
              <div
                style={{
                  width: '107px',
                  height: '112px',
                  textAlign: 'center',
                  fontFamily: 'Open Sans',
                  backgroundColor: '#EFF0F8',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span style={{ color: '#FF4E3E' }}>{i18n.t('error')}</span>
              </div>
              <div
                style={{
                  fontSize: '11px',
                  fontWeight: 400,
                  fontFamily: '"Poppins", sans-serif',
                }}
              >
                {convertBytesToString(data.size)}
              </div>
            </div>,
            document.getElementById(data.name),
          );
        } catch (error) {
          // console.log(error);
        }
        if (obj?.message) {
          dispatch({
            type: 'SNACKBAR_NEW_MESSAGE',
            payload: {
              message: obj.message,
              severity: 'error',
            },
          });
        }
        if (error?.response?.data?.errors.length && error.response.data.errors[0].message)
          dispatch({
            type: 'SNACKBAR_NEW_MESSAGE',
            payload: {
              message: error.response.data.errors[0].message,
              severity: 'error',
            },
          });
      } else {
        const elem: HTMLElement | null = document.getElementById(data.name);
        if (elem?.childNodes[0]) elem.removeChild(elem.childNodes[0]);
      }
    }
    const { CancelToken } = axios;
    const source = CancelToken.source();
    const isCancel = false;

    const config = {
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        uploadProgressCallback?.(data.name, percentCompleted);
        const i = index;
        if (document.getElementById(data.name))
          ReactDOM.render(
            (percentCompleted < 100 || i < total) && isCancel === false ? (
              <div key={i}>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '5px',
                    maxWidth: '107px',
                    alignItems: 'center',
                  }}
                >
                  <span
                    style={{
                      width: 'calc(100% - 0px)',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontSize: '9px',
                      fontWeight: 400,
                      fontFamily: '"Poppins", sans-serif',
                    }}
                  >
                    {data.name}
                  </span>
                  <span
                    style={{
                      paddingLeft: '5px',
                      paddingRight: '5px',
                      cursor: 'pointer',
                    }}
                  >
                    <Delete
                      style={{ fontSize: '14px', color: '#FF4E3E' }}
                      onClick={(): void => {
                        source.cancel();
                      }}
                    />
                  </span>
                </div>
                <div
                  style={{
                    width: '107px',
                    height: '112px',
                    textAlign: 'center',
                    fontFamily: 'Open Sans',
                    backgroundColor: '#EFF0F8',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgressWithLabel value={percentCompleted} />
                </div>
                <div
                  style={{
                    fontSize: '11px',
                    fontWeight: 400,
                    fontFamily: '"Poppins", sans-serif',
                  }}
                >
                  {convertBytesToString(data.size)}
                </div>
              </div>
            ) : null,
            document.getElementById(data.name),
          );
      },
      cancelToken: source.token,
    };
    try {
      const uploadFileRequest = await APOLLO_CLIENT.mutate({
        variables: {
          name: data.name.normalize().replace(/[–]/g, '-'),
          size: data.size,
          thumbnail: thumbnail,
          triangles: totalTriangles,
          comment: undefined,
          uploadedFromLibrary: false,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPLOAD_FILE_REQUEST,
      });

      const formData = new FormData();
      for (const key in uploadFileRequest.data.getUploadFileRequest.formData) {
        formData.append(key, uploadFileRequest.data.getUploadFileRequest.formData[`${key}`]);
      }
      formData.append('File', data);
      const ansUpload = await axios.post(uploadFileRequest.data.getUploadFileRequest.url, formData, config);

      if (ansUpload.status === 204) {
        const data: FileUpdateInput = totalTriangles ? { details: { triangles: totalTriangles } } : {};

        await APOLLO_CLIENT.mutate({
          variables: {
            where: { _id: uploadFileRequest.data.getUploadFileRequest._id },
            data: data,
          },
          fetchPolicy: 'no-cache',
          mutation: queries.M_NOTIFY_UPLOAD,
        });
      }

      return {
        data: {
          data: {
            files: [
              {
                name: data.name,
                size: data.size,
                hasThumbnail: !!thumbnail,
                _id: uploadFileRequest.data.getUploadFileRequest._id,
              },
            ],
          },
        },
      };
    } catch (error) {
      onError(error);
      return error;
    }
  };

export const uploadFile =
  (
    file,
    thumbnail,
    totalTriangles?,
    uploadProgressCallback?: (name: string, progress: number) => void,
    onErrorCallback?: (name: string, error) => void,
  ) =>
  async (): Promise<FileUploadResponse | { graphQLErrors: GraphQLErrors }> => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    const config = {
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        uploadProgressCallback?.(file.name, percentCompleted);
      },
      cancelToken: source.token,
    };

    try {
      const uploadFileRequest = await APOLLO_CLIENT.mutate({
        variables: {
          name: file.name.normalize().replace(/[–]/g, '-'),
          size: file.size,
          thumbnail: thumbnail,
          triangles: totalTriangles,
          comment: undefined,
          uploadedFromLibrary: false,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPLOAD_FILE_REQUEST,
      });

      const formData = new FormData();
      for (const key in uploadFileRequest.data.getUploadFileRequest.formData) {
        formData.append(key, uploadFileRequest.data.getUploadFileRequest.formData[`${key}`]);
      }
      formData.append('File', file);
      const ansUpload = await axios.post(uploadFileRequest.data.getUploadFileRequest.url, formData, config);

      let notifyUploadResponse;

      if (ansUpload.status === 204) {
        const data: FileUpdateInput = totalTriangles ? { details: { triangles: totalTriangles } } : {};

        notifyUploadResponse = await APOLLO_CLIENT.mutate<NotifyUploadMutation>({
          variables: {
            where: { _id: uploadFileRequest.data.getUploadFileRequest._id },
            data: data,
          },
          fetchPolicy: 'no-cache',
          mutation: queries.M_NOTIFY_UPLOAD,
        });
      }

      return {
        data: {
          data: {
            files: [
              {
                name: file.name,
                size: file.size,
                hasThumbnail: !!thumbnail,
                _id: uploadFileRequest.data.getUploadFileRequest._id,
                download: notifyUploadResponse.data.notifyUpload.download,
              },
            ],
          },
        },
      };
    } catch (error) {
      onErrorCallback(file.name, error);
      return error;
    }
  };

export const addDescriptionFile = (data, thumbnail) => async () => {
  try {
    const uploadFileRequest = await APOLLO_CLIENT.mutate({
      variables: {
        name: data.name.normalize().replace(/[–]/g, '-'),
        size: data.size,
        thumbnail: thumbnail,
        comment: undefined,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_UPLOAD_FILE_REQUEST,
    });

    const formData = new FormData();
    for (const key in uploadFileRequest.data.getUploadFileRequest.formData) {
      formData.append(key, uploadFileRequest.data.getUploadFileRequest.formData[`${key}`]);
    }
    formData.append('File', data);
    const ansUpload = await axios.post(uploadFileRequest.data.getUploadFileRequest.url, formData);

    if (ansUpload.status === 204) {
      await APOLLO_CLIENT.mutate({
        variables: {
          where: { _id: uploadFileRequest.data.getUploadFileRequest._id },
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_NOTIFY_UPLOAD,
      });
    }

    return {
      data: {
        _id: uploadFileRequest.data.getUploadFileRequest._id,
      },
    };
  } catch (error) {
    return error;
  }
};

export const getFile = (id) => async () => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where: { _id: id },
      },
      query: queries.Q_GET_UNIQUE_FILE,
    });

    return response.data.file.download.url;
  } catch (error) {
    return error;
  }
};

export const getDescriptionFile = (id) => async () => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where: { _id_in: id },
      },
      query: queries.Q_GET_FILES,
    });

    return response.data.files[0];
  } catch (error) {
    return error;
  }
};

export const deleteFile = (file: { _id: string }) => async (dispatch: Dispatch) => {
  function onSuccess(resp) {
    return resp;
  }
  try {
    const resp = await axios.delete(API_URL + '/file/files?id=' + file._id, {
      headers: { Authorization: localStorage.getItem('access_token') },
    });
    return onSuccess(resp);
  } catch (error) {
    handleErrorRest(error, dispatch);
  }
};

export const downloadFile =
  (fileUrl, fileName, fileId = undefined, validUntil = undefined) =>
  async (dispatch: Dispatch) => {
    try {
      let response;
      if (fileId && validUntil && moment.unix(validUntil).isBefore(moment())) {
        response = await APOLLO_CLIENT.query({
          variables: {
            where: { _id_eq: fileId },
          },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_FILE,
        });
      }
      const link = document.createElement('a');
      link.href = response?.data.files[0].download.url || fileUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      handleErrorRest(error, dispatch);
    }
  };

export const getNewDownloadLink = (fileId) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where: { _id_eq: fileId },
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_FILE,
    });

    return response?.data.files[0].download.url;
  } catch (error) {
    handleErrorRest(error, dispatch);
  }
};

export const getContent = (fileUrl) => async (dispatch: Dispatch) => {
  try {
    return await axios
      .get(fileUrl)
      .then(async (resp) => {
        return resp;
      })
      .catch((e) => console.error('getContent: ', e));
  } catch (error) {
    handleErrorRest(error, dispatch);
  }
};

interface FileUpdateData {
  comment?: string;
  order?: number;
}

export const updateFile = (data: FileUpdateData, where: FileWhereInput) => async (dispatch: Dispatch) => {
  try {
    const state = await APOLLO_CLIENT.mutate({
      variables: {
        data,
        where,
      },
      mutation: queries.M_UPDATE_FILE,
    });
    return state;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const saveEditedFile =
  (file: IFile, editedFile) =>
  async (dispatch: Dispatch): Promise<IFile | void | { graphQLErrors: GraphQLErrors }> => {
    const blob = dataURItoBlob(editedFile.imageBase64);
    const thumbnail = await resizeImage({ maxSize: 200, file: blob });
    try {
      //Request url and signature for upload to S3
      const uploadFileRequest = await APOLLO_CLIENT.mutate({
        variables: {
          where: { _id: file._id } as FileWhereUniqueInput,
          size: blob.size,
          thumbnail: thumbnail,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPLOAD_FILE_REQUEST_UNIQUE,
      });

      const formData = new FormData();
      for (const key in uploadFileRequest.data.getUploadFileRequestForFile.formData) {
        formData.append(key, uploadFileRequest.data.getUploadFileRequestForFile.formData[`${key}`]);
      }
      formData.append('File', new File([blob], file.name, { type: file.mime }));

      //Upload to S3
      const ansUpload = await axios.post(uploadFileRequest.data.getUploadFileRequestForFile.url, formData, {
        cancelToken: axios.CancelToken.source().token,
      });

      if (ansUpload.status === 204) {
        //notify backend of successfull upload
        const data: FileUpdateInput = { size: blob.size };

        const resp = await APOLLO_CLIENT.mutate<NotifyUploadMutation>({
          variables: {
            where: { _id: file._id },
            data: data,
          },
          fetchPolicy: 'no-cache',
          mutation: queries.M_NOTIFY_UPLOAD,
        });
        return resp.data.notifyUpload as IFile;
      }
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };
