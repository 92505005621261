import { cloneDeep } from 'lodash';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { addFile, getFiles, downloadFile } from 'src/modules/file-storage/file-storage.redux';
import { Tooltip, Backdrop, CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import { styles } from 'src/utils/components/signature-field/styles';
import store from 'src/reducer-manager';
import { useState, useEffect, useRef } from 'react';
import { Delete, AttachFile, Add, Visibility, GetApp } from '@mui/icons-material';
import { convert } from 'src/utils/funcs';
import { useCallback } from 'react';
import InputFieldGeneric from 'src/utils/components/input-field/input-generic';
import SignatureDialog from './draw-signature/signature-dialog';

const SignatureField = (props: any) => {
  const [currentFiles, setCurrentFiles] = useState<any[]>([]);
  const [name, setName] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [, setUpdate] = useState();
  //@ts-ignore
  const forceUpdate = useCallback(() => setUpdate({}), []);
  const uploadFile = useRef<any>(null);
  const [drawDialogOpen, setDrawDialogOpen] = useState<boolean>(false);
  let downloads: string[] = [];

  const getImagesByURL = React.useCallback(async (files: any[]) => {
    for (let i = 0; i < files.length; i++) {
      /* if (files[parseInt(`${i}`)].hasThumbnail) { */
      files[parseInt(`${i}`)].data = files[parseInt(`${i}`)].download.url;
      files[parseInt(`${i}`)].image = (
        <img
          id={`image${i}`}
          onClick={(): void => setDrawDialogOpen(true)}
          className={props.classes.img}
          src={files[parseInt(`${i}`)].download.thumbnail?.url || files[parseInt(`${i}`)].download.url}
        />
      );
    }
    /* } */
    setCurrentFiles(files);
    setLoading(false);
  }, []);

  useEffect(
    () => {
      if ((props.value?.length || props.value[0]?.image) && !location.pathname.includes('template')) {
        getImagesByURL(cloneDeep(props.value.map((v) => v.image)));
        /*  props
        .getFiles({ _id_in: [props.value[0].image?._id || props.value[0].image] })
        .then((resp: NexusGenFieldTypes['File'][]) => {
          getImagesByURL(cloneDeep(resp));
        }); */
      } else if (!props.value?.length || !props.value[0]?.image) {
        setCurrentFiles([]);
      }
      setName(props.value[0]?.name ? props.value[0].name : '');
      setLoading(false);
    },
    [
      /* props.value */
    ],
  );

  const dataURItoBlob = (dataURI: string) => {
    const bytes =
      dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (let i = 0; i < max; i++) ia[parseInt(`${i}`)] = bytes.charCodeAt(i);
    return new Blob([ia], { type: mime });
  };

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      // mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: 'png' });
  };

  const addFile = async (imgSrc: string) => {
    const thumbnail = dataURItoBlob(imgSrc);
    //Usage example:
    const file = dataURLtoFile(imgSrc, `signature${name}.png`);
    const resp = await props.addFile(file, 1, 1, thumbnail);

    if (resp === undefined) {
      forceUpdate();
      return;
    }
    if (resp.response && resp.response.data.errors) {
      store.dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: resp.response.data.errors[0].error,
          severity: 'error',
        },
      });
      forceUpdate();
      return;
    } else if (resp.response && resp.response.status >= 400) {
      store.dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: props.t('fileUploadFailed'),
          severity: 'error',
        },
      });
      forceUpdate();
      return;
    }

    if (
      resp.data.data.files.length > 0 &&
      currentFiles.filter((c) => c?.name === resp.data.data.files[0].name).length === 0
    ) {
      const f = resp.data.data.files[0];
      f.extension = f.name.split('.')[f.name.split('.').length - 1];
      f.data = window.URL.createObjectURL(new Blob([file]));
      f.image = (
        <img
          id={`image${0}`}
          onClick={(): void => setDrawDialogOpen(true)}
          className={props.classes.img}
          src={window.URL.createObjectURL(new Blob([file]))}
        />
      );
      if (name) props.callback([{ name, image: f._id }]);
      setCurrentFiles((prev) => [...prev, f]);
      forceUpdate();
    }
  };

  const deleteFile = (file: { _id: string }) => {
    props.removeFile({ _id: file._id });
    setCurrentFiles(currentFiles.filter((f) => f._id !== file._id));
    // if (name) props.callback([{ name, image: currentFiles.length ? currentFiles[0]._id : undefined }]);
  };

  const downloadFile = (file: {
    data: any;
    _id: string;
    download: { url: string; validUntil: string };
    name: string;
  }): void => {
    if (downloads.filter((d) => d === file._id).length === 0) {
      downloads.push(file._id);
      props
        .downloadFile(file.download?.url || file.data, file.name, file._id, file.download?.validUntil || undefined)
        .then(() => {
          downloads = downloads.filter((d) => d !== file._id);
        });
    }
  };

  return (
    <div>
      <InputFieldGeneric
        disabled={props.disabled}
        title={''}
        id={`signatureName${props.id}`}
        onlyInput
        type={'string'}
        placeholder={props.t('fullName')}
        values={[name]}
        onBlur={(e: string[]) => {
          setName(e[0]);
          if (currentFiles.length)
            props.callback([
              {
                name: e[0],
                image: currentFiles.length ? currentFiles[0]._id : undefined,
              },
            ]);
        }}
        handleChange={(e: string[]) => {
          setName(e[0]);
        }}
      />
      <Backdrop style={loading ? {} : { display: 'none' }} className={props.classes.backdrop} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      {(currentFiles && Array.isArray(currentFiles) && currentFiles.length) || uploadFile?.current?.files.length ? (
        currentFiles.map((file, i) => (
          <div key={i} className={props.classes.divImages}>
            <div className={props.classes.imageTitle}>
              <span className={props.classes.rowName}>{file.name}</span>
              {!props.disabled ? (
                <span className={props.classes.spanDownload}>
                  <Tooltip title={props.t('delete')}>
                    <Delete classes={{ root: props.classes.deleteIcon }} onClick={(): void => deleteFile(file)} />
                  </Tooltip>
                </span>
              ) : (
                <span className={props.classes.spanDownload}>
                  <Tooltip title={props.t('download')}>
                    <span id={`fileDownload${i}`} onClick={(): void => downloadFile(file)}>
                      <GetApp style={{ fontSize: '20px' }} />
                    </span>
                  </Tooltip>
                </span>
              )}
            </div>

            {
              /* file.hasThumbnail && */
              file.extension.match(
                /(jpg|png|gif|webp|tiff|psd|raw|bmp|heif|indd|jpeg|webm|mpg|mp2|mpeg|mpe|mpv|ogg|mp4|m4p|m4v|avi|wmv|mov|qt|flv|swf|avchd)$/i,
              ) ? (
                <div className={props.classes.divWithIcon}>
                  {file.image}{' '}
                  <Visibility
                    fontSize='small'
                    htmlColor='#FFFFFF'
                    style={{ position: 'relative', bottom: 52, right: 66 }}
                    onClick={(): void => setDrawDialogOpen(true)}
                  />
                </div>
              ) : (
                <div className={props.classes.divImageNotFound} onClick={(): void => setDrawDialogOpen(true)}>
                  <AttachFile classes={{ root: props.classes.attachIcon }} />
                </div>
              )
            }
            <div
              className={props.classes.divSize}
              style={
                /* file.hasThumbnail && */
                file.extension.match(
                  /(jpg|png|gif|webp|tiff|psd|raw|bmp|heif|indd|jpeg|webm|mpg|mp2|mpeg|mpe|mpv|ogg|mp4|m4p|m4v|avi|wmv|mov|qt|flv|swf|avchd)$/i,
                )
                  ? { marginTop: '-5px' }
                  : {}
              }
            >
              {file.size ? convert(file.size) : props.t('sizeUnavailable')}
            </div>
          </div>
        ))
      ) : (
        <div>
          <div className={props.disabled ? props.classes.uploadDivDisable : props.classes.uploadDiv}>
            <div className={`${props.classes.row}`}>
              <div className={props.classes.icon}>
                <Add
                  classes={{
                    root: props.disabled ? props.classes.addIconDisabled : props.classes.addIcon,
                  }}
                />
              </div>
              <div
                className={props.disabled ? props.classes.textDisabled : props.classes.text}
                onClick={() => {
                  !props.disabled && setDrawDialogOpen(true);
                }}
              >
                {props.t('addSignature')}
              </div>
            </div>
          </div>
        </div>
      )}
      {uploadFile?.current?.files.length
        ? Object.keys(uploadFile.current.files).map((f) =>
            f !== 'length' &&
            !currentFiles.filter((file) => file.name === uploadFile.current.files[`${f}`].name).length ? (
              <div
                id={uploadFile.current.files[`${f}`].name}
                key={uploadFile.current.files[`${f}`].name}
                className={props.classes.divImages}
              />
            ) : null,
          )
        : null}
      {drawDialogOpen ? (
        <SignatureDialog
          onClose={() => setDrawDialogOpen(false)}
          addFile={addFile}
          file={currentFiles[0]}
          setCurrentFiles={setCurrentFiles}
          deleteFile={deleteFile}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addFile,
      getFiles,
      downloadFile,
    },
    dispatch,
  );

export default compose<any>(
  withTranslation('translation'),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(SignatureField);
