import { Delete, GetApp } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Size } from 'react-virtualized';
import { File } from 'src/gql/graphql';
import { downloadFile } from 'src/modules/file-storage/file-storage.redux';
import 'swiper/css';
import { styles as showFileStyles } from '../../styles';
import { moveObjectAtIndex } from '../../utils/show-file-util-functions';
import { styles } from '../styles';
import FileImage from './FileImage';
import FileOrderIcon from './FileOrderIcon';
import FileSizeText from './FileSizeText';

interface IDraggableMediaProps extends ConnectedProps<typeof connector> {
  file: File;
  dragFile?: File;
  candidateFileOrder?: File;
  setDragFile?: React.Dispatch<React.SetStateAction<File>>;
  setCandidateFileOrder?: React.Dispatch<React.SetStateAction<File>>;
  currentFiles: File[];
  showInfo?: boolean;
  showClose?: boolean;
  imageSize?: Size;
  files: File[];
  allowReorder?: boolean;
  allowEdit?: boolean;
  allowNavigation?: boolean;
  setOpenCarousel: React.Dispatch<React.SetStateAction<object>>;
  setCurrentFiles: React.Dispatch<React.SetStateAction<object>>;
  onDeleteFile: (file: File) => void;
  setGallery?: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      initialTab: number;
    }>
  >;
  progressMap?: Map<string, number>;
  showDelete?: boolean;
  showDownload?: boolean;
  index: number;
  slidesPerView?: number;
}

const DraggableMedia: React.FC<IDraggableMediaProps> = (props) => {
  const {
    file,
    currentFiles,
    onDeleteFile,
    setCurrentFiles,
    index,
    showClose,
    imageSize,
    files,
    allowReorder,
    setOpenCarousel,
    slidesPerView,
    dragFile,
    setDragFile,
    candidateFileOrder,
    setCandidateFileOrder,
  } = props;
  const { t } = useTranslation();

  const classes = styles();
  const showFileClasses = showFileStyles();

  const downloadsRef = useRef<string[]>([]);
  const downloads = downloadsRef.current;
  const DownloadButton = ({ file, i }: { file: File; i: number }) => {
    return (
      <Tooltip title={t('download')}>
        <span id={`fileDownload${i}`} onClick={(): void => onClickDownloadFile(file)}>
          <GetApp style={{ fontSize: '16px' }} />
        </span>
      </Tooltip>
    );
  };
  const onClickDownloadFile = (file: File): void => {
    if (downloads.filter((d) => d === file._id).length === 0) {
      downloads.push(file._id);
      props.downloadFile(file.download?.url || file.name, file._id, file.download?.validUntil || undefined).then(() => {
        downloadsRef.current = downloads.filter((d) => d !== file._id);
      });
    }
  };

  const clearDragFile = () => {
    setDragFile(null);
    setCandidateFileOrder(null);
  };

  const [{ isDragging }, connectDrag] = useDrag(
    () => ({
      type: 'FILE',
      item: file,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: allowReorder,
    }),
    [file],
  );

  const [, connectDrop] = useDrop(
    () => ({
      accept: 'FILE',
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      hover: (item: File) => {
        if (dragFile !== item) {
          setDragFile(item);
        }
        if (allowReorder && item !== file) {
          setCandidateFileOrder(file);
        }
      },
      drop: () => {
        if (dragFile && dragFile !== candidateFileOrder) {
          const idxSrc = currentFiles.indexOf(dragFile);
          const idxTgt = currentFiles.indexOf(candidateFileOrder);
          if (idxSrc !== idxTgt) {
            setCurrentFiles(moveObjectAtIndex<File>(currentFiles, idxSrc, idxTgt));
          }
        }
        clearDragFile();
      },
    }),
    [currentFiles, dragFile, candidateFileOrder],
  );

  return (
    <div
      ref={(node) => {
        connectDrag(node);
        connectDrop(node);
      }}
      style={{ opacity: isDragging ? 0.25 : 1 }}
    >
      <div className={showFileClasses.imageTitle}>
        {props.showInfo ? (
          <>
            <span className={showFileClasses.rowName}>{file.name}</span>
            <span className={showFileClasses.spanDownload}>
              {props.showDelete &&
              (props.progressMap?.get(file.name) === 100 || props.progressMap?.get(file.name) === undefined) ? (
                <Delete className={classes.iconDelete} onClick={() => onDeleteFile?.(file)} id={`fileDelete${index}`} />
              ) : null}
              {props.showDownload && <DownloadButton file={file} i={index} />}
            </span>
          </>
        ) : null}
      </div>
      <div data-testid={`image-container-${file.name ?? 'noName'}`}>
        {dragFile !== null && candidateFileOrder !== null && candidateFileOrder._id == file._id && (
          <div className={showFileClasses.reorderSeparator}></div>
        )}
        <div style={dragFile === file ? { opacity: 0.25 } : {}}>
          <FileImage
            allowEdit={props.allowEdit}
            size={imageSize}
            onClickClose={
              showClose
                ? () => {
                    onDeleteFile?.(file);
                  }
                : undefined
            }
            file={file}
            setOpenCarousel={setOpenCarousel}
            showPlus={!props.allowNavigation && index === slidesPerView - 1 && index !== files.length - 1}
            plusFiles={files.length - index - 1}
            onClickPlus={() => props.setGallery({ open: true, initialTab: 0 })}
            uploadProgress={!file._id ? props.progressMap?.get(file.name) : undefined}
          />
        </div>
      </div>
      {props.showInfo && (
        <div className={showFileClasses.sizeAndOrder}>
          {props.progressMap?.get(file.name) ? (
            <span className={showFileClasses.sizeText}>{t('loadingContent')}</span>
          ) : null}
          <FileSizeText file={file} className={showFileClasses.sizeText} />
          {allowReorder && <FileOrderIcon index={index} className={showFileClasses.orderIcon} />}
        </div>
      )}
      {file.comment ? (
        <Tooltip title={file.comment}>
          <div className={showFileClasses.showComment}>{file.comment}</div>
        </Tooltip>
      ) : null}
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  downloadFile,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(DraggableMedia);
