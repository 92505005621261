import { WithTranslation, withTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { styles } from './styles';
import DefaultButton from '../default-button/default-button';
import CustomValueRangeList from './components/custom-value-range-list';
import { Add } from '@mui/icons-material';
import { withStyles, WithStyles } from '@mui/styles';
import { useState } from 'react';

export type CustomRange = {
  name: string;
  max: number;
  color: string;
};

type CustomValueRangePickerProps = {
  customRanges: CustomRange[];
  setCustomRanges: (ratings: CustomRange[]) => void;
  setErrorExists?: (error: boolean) => void;
} & WithTranslation &
  WithStyles<typeof styles>;

const CustomValueRangePicker = ({
  t,
  customRanges,
  classes,
  setCustomRanges,
  setErrorExists,
}: CustomValueRangePickerProps) => {
  const [addClicked, setAddClicked] = useState(false);
  const handleAddRange = () => {
    const newCustomRange: CustomRange = {
      name: `Target ${customRanges.length + 1}`,
      max: null,
      color: '#2470B3',
    };
    setAddClicked(true);
    setCustomRanges([...customRanges, newCustomRange]);
  };

  return (
    <div className={classes.customRangeContainer}>
      <Typography className={classes.customRangeTitle}>{t('targetSegments')}</Typography>
      <CustomValueRangeList
        customRanges={customRanges}
        setCustomRanges={setCustomRanges}
        addClicked={addClicked}
        setAddClicked={setAddClicked}
        setErrorExists={setErrorExists}
      />
      <DefaultButton
        data-testid={'add-range-btn'}
        variant={'edit'}
        className={classes.addRangeBtn}
        onClick={() => handleAddRange()}
        label={
          <div className={classes.addRangeBtnText}>
            <Add />
            <Typography>{t('addRange')}</Typography>
          </div>
        }
      />
    </div>
  );
};

export default withTranslation('translation')(withStyles(styles)(CustomValueRangePicker));
