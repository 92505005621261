export enum SocketStatus {
  CONNECTED = 'CONNECTED',
  CONNECTING = 'CONNECTING',
  DISCONNECTED = 'DISCONNECTED',
  DISCONNECTING = 'DISCONNECTING',
  ERROR = 'ERROR',
}

export const isLoadingSocket = (status: SocketStatus): boolean =>
  status == SocketStatus.CONNECTING ||
  status == SocketStatus.DISCONNECTING ||
  status == SocketStatus.DISCONNECTED ||
  status == SocketStatus.ERROR;
