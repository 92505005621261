import _ from 'lodash';
import { AndConditions } from '../types/agenda.filter.types';
import { Element, Site } from 'src/gql/graphql';

export interface BuildAndConditionsArgs {
  accessToSites?: Site[];
  accessToElements?: Element[];
  sites: Site[];
  elements: Element[];
  rootElements: Element[];
  rootSites: Site[];
  parentElements: Element[];
  parentSites: Site[];
  search?: string;
  isInstance?: boolean;
}

export const buildAndConditions = (args: BuildAndConditionsArgs): AndConditions[] => {
  const {
    accessToSites,
    accessToElements,
    sites = [],
    elements = [],
    parentElements = [],
    parentSites = [],
    rootElements = [],
    rootSites = [],
    search,
    isInstance,
  } = args;

  const onlySiteOrElementReceived = !!(accessToElements?.length || accessToSites?.length);

  const andConditions: AndConditions[] = [];

  if (onlySiteOrElementReceived || !!sites.length || !!elements.length) {
    if (elements.length) {
      andConditions.push({
        OR: [{ element: { _id_in: elements.map((e) => e._id) } }],
      });
    }
    if (sites.length) {
      andConditions.push({ OR: [{ site: { _id_in: sites.map((s) => s._id) } }] });
    }

    if (accessToSites?.length) {
      andConditions.push({
        OR: [
          { site: { parentsTree_in: accessToSites.map((s) => s._id) } },
          { site: { _id_in: accessToSites.map((s) => s._id) } },
          ...(accessToSites.length === 1 && !accessToSites[0].parentsTree?.length ? [{ site_exists: false }] : []),
        ],
      });
    }

    if (accessToElements?.length) {
      andConditions.push({
        OR: [
          { element: { parentsTree_in: accessToElements.map((e) => e._id) } },
          { element: { _id_in: accessToElements.map((e) => e._id) } },
        ],
      });
    }
  }

  if (parentSites.length) {
    andConditions.push({ OR: [{ site: { parent_in: parentSites.map((p) => p._id) } }] });
  }

  if (parentElements.length) {
    andConditions.push({ OR: [{ element: { parent_in: parentElements.map((p) => p._id) } }] });
  }

  if (rootSites.length) {
    andConditions.push({ OR: [{ rootSite: { _id_in: rootSites.map((e) => e._id) } }] });
  }
  if (rootElements.length) {
    andConditions.push({ OR: [{ rootElement: { _id_in: rootElements.map((e) => e._id) } }] });
  }

  if (search) {
    andConditions.push({ OR: [{ name_contains: search }, { glarID_contains: isInstance ? search : undefined }] });
  }

  // Filter out undefined and empty objects
  const filteredAndConditions = andConditions?.filter((f) => Object.values(f)?.some(_.identity));

  return filteredAndConditions;
};
