import { LastEventAnnotation, AnnotationType, DispatchedAnnotation, SessionAnnotations } from './agora.interfaces';
import { sendBubbleAnchorKey } from 'src/utils/remote-assistance/sockets';

export const createAnchorKey = () => {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let randomString = '';
  for (let i = 0; i < 16; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    randomString += charset.charAt(randomIndex);
  }
  return randomString;
};

export const getSessionAnnotations = (webMobileAnnotations: any, sessionId: string): SessionAnnotations => {
  return (
    webMobileAnnotations[sessionId] || {
      arrows: [],
      lines: [],
      freezeLines: [],
      texts: [],
    }
  );
};

export const handleMouseDown = (
  lastEvents: LastEventAnnotation,
  sessionId: string,
  annotation: DispatchedAnnotation,
) => {
  lastEvents[sessionId] = { type: AnnotationType.MOUSE_DOWN, annotation };
};

export const handleMouseUp = (
  state: any,
  sessionId: string,
  annotation: DispatchedAnnotation,
  lastEvents: LastEventAnnotation,
  lineIndex: number,
  newLine: boolean,
) => {
  const sessionAnnotations = getSessionAnnotations(state.webMobileAnnotations, sessionId);

  if (lastEvents[sessionId]?.type === AnnotationType.MOUSE_DOWN) {
    if (annotation.isArrow) {
      const newArrowNumber =
        sessionAnnotations.arrows.length >= 9
          ? (sessionAnnotations.arrows[sessionAnnotations.arrows.length - 1].number % 9) + 1
          : sessionAnnotations.arrows.length + 1;

      if (sessionAnnotations.arrows.length === 9) sessionAnnotations.arrows.shift();

      sessionAnnotations.arrows.push({
        x: annotation.x,
        y: annotation.y,
        timestamp: annotation.timestamp,
        colorIndex: annotation.colorIndex,
        number: newArrowNumber,
      });
    } else {
      const key = createAnchorKey();
      sessionAnnotations.texts.push({ key, x: annotation.x, y: annotation.y });
      sendBubbleAnchorKey(sessionId, key).catch((error) => void error);
    }
  } else if (lastEvents[sessionId]?.type === AnnotationType.MOUSE_MOVE) {
    const distanceFromDownMovement = Math.sqrt(
      Math.pow(annotation.x - lastEvents[sessionId].annotation.x, 2) +
        Math.pow(annotation.y - lastEvents[sessionId].annotation.y, 2),
    );
    if (distanceFromDownMovement > 10) return { lineIndex, newLine };

    if (state.imageFreeze) {
      if (!sessionAnnotations.freezeLines[lineIndex]) {
        sessionAnnotations.freezeLines[lineIndex] = { lines: [] };
      }

      sessionAnnotations.freezeLines[lineIndex].lines.push(lastEvents[sessionId].annotation, annotation);
      sessionAnnotations.freezeLines[lineIndex].timestamp = Date.now();
      newLine = true;
    } else {
      sessionAnnotations.lines.push(lastEvents[sessionId].annotation, annotation);
    }
  } else {
    sessionAnnotations.texts.push({
      x: annotation.x,
      y: annotation.y,
      value: annotation.text,
      timestamp: annotation.timestamp,
      colorIndex: annotation.colorIndex,
    });
  }

  delete lastEvents[sessionId];
  state.webMobileAnnotations[sessionId] = sessionAnnotations;

  return { lineIndex, newLine };
};

export const handleMouseMove = (
  state: any,
  sessionId: string,
  annotation: DispatchedAnnotation,
  lastEvents: LastEventAnnotation,
  lineIndex: number,
  newLine: boolean,
) => {
  const sessionAnnotations = getSessionAnnotations(state.webMobileAnnotations, sessionId);

  if (lastEvents[sessionId]) {
    if (!state.imageFreeze) {
      sessionAnnotations.lines.push(lastEvents[sessionId].annotation, annotation);
    } else {
      if (newLine) {
        lineIndex += 1;
        newLine = false;
      }

      if (!sessionAnnotations.freezeLines[lineIndex]) {
        sessionAnnotations.freezeLines[lineIndex] = { lines: [] };
      }

      sessionAnnotations.freezeLines[lineIndex].lines.push(lastEvents[sessionId].annotation, annotation);
      sessionAnnotations.freezeLines[lineIndex].timestamp = Date.now();
    }

    lastEvents[sessionId] = { type: AnnotationType.MOUSE_MOVE, annotation };
  }

  state.webMobileAnnotations[sessionId] = sessionAnnotations;

  return { lineIndex, newLine };
};

export const undoLastAnnotation = (state: any, sessionId: string, lineIndex: number) => {
  const sessionAnnotations = getSessionAnnotations(state.webMobileAnnotations, sessionId);

  let lastLineTimestamp = 0;
  if (sessionAnnotations.freezeLines && Object.keys(sessionAnnotations.freezeLines).length > 0) {
    Object.keys(sessionAnnotations.freezeLines).forEach((key) => {
      const lineTimestamp = sessionAnnotations.freezeLines[key].timestamp;
      if (lineTimestamp > lastLineTimestamp) {
        lastLineTimestamp = lineTimestamp;
      }
    });
  }
  const lastBubbleTimestamp =
    sessionAnnotations.texts.length > 0 ? sessionAnnotations.texts[sessionAnnotations.texts.length - 1].timestamp : 0;
  const lastArrowTimestamp =
    sessionAnnotations.arrows.length > 0
      ? sessionAnnotations.arrows[sessionAnnotations.arrows.length - 1].timestamp
      : 0;

  const maxTimestamp = Math.max(lastLineTimestamp, lastBubbleTimestamp, lastArrowTimestamp);

  if (lastArrowTimestamp === maxTimestamp) {
    sessionAnnotations.arrows.pop();
  } else if (lastLineTimestamp === maxTimestamp) {
    delete sessionAnnotations.freezeLines[lineIndex];
    lineIndex -= 1;
  } else if (lastBubbleTimestamp === maxTimestamp) {
    sessionAnnotations.texts.pop();
  }

  state.webMobileAnnotations[sessionId] = sessionAnnotations;

  return lineIndex;
};
