import { Dialog } from '@mui/material';
import DefaultButton from '../default-button';
import { useTranslation } from 'react-i18next';
import { styles } from 'src/utils/components/tools-in-use-popover/styles';
import { WithStyles, withStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { ToolItem } from 'src/gql/graphql';

interface ToolsInUsePopoverProps extends WithStyles<typeof styles> {
  openToolsInUse: boolean;
  toolsToAdd: ToolItem[];
  setOpenToolsInUse: (val: boolean) => void;
  handleAddToolItems: (toolItems?: ToolItem[]) => Promise<void>;
  setToolsToAdd?: (toolItems?: ToolItem[]) => void;
}

const ToolsInUsePopover = (props: ToolsInUsePopoverProps): JSX.Element => {
  const { classes, openToolsInUse, toolsToAdd, setOpenToolsInUse, handleAddToolItems, setToolsToAdd } = props;
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Dialog
      data-testid={'toolsInUsePopup'}
      open={openToolsInUse}
      fullWidth
      maxWidth={'xs'}
      onClose={() => {
        setOpenToolsInUse(false);
        setToolsToAdd?.([]);
      }}
    >
      <div className={classes.toolsInUseMainContainer}>
        <div data-testid={'toolsInUseTitle'} className={classes.toolsInUseTitle}>
          {toolsToAdd.length === 1
            ? t('thisToolIsBeingUsedInOtherIssue')
            : t('someSelectedToolsAreBeingUsedInOtherIssue')}
        </div>
        <div className={classes.toolsInUseSubTitle}>{t('doYouWishContinue')}</div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <DefaultButton
            data-testid={'toolsInUseCancel'}
            className={classes.borderBtn}
            onClick={() => {
              setOpenToolsInUse(false);
              setToolsToAdd?.([]);
            }}
          >
            {t('cancel')}
          </DefaultButton>
          <DefaultButton
            data-testid={'toolsInUseContinue'}
            success
            onClick={() => {
              handleAddToolItems(location.pathname.includes('/tools/item') ? null : toolsToAdd);
              setOpenToolsInUse(false);
            }}
          >
            {t('continue')}
          </DefaultButton>
        </div>
      </div>
    </Dialog>
  );
};

export default withStyles(styles)(ToolsInUsePopover);
