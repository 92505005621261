import { NexusGenFieldTypes, NexusGenInputs } from '@server/src/types';
import { APOLLO_CLIENT } from 'config/apollo.config';
import { Dispatch } from 'redux';
import {
  InvitesDocument,
  InvitesCountDocument,
  CreateOrUpdateManyInvitesDocument,
  ResendInviteDocument,
  ResendManyInvitesDocument,
  DeleteInviteDocument,
  DeleteManyInvitesDocument,
  IsValidInviteDocument,
  InviteOrderByInput,
} from 'src/gql/graphql';
import { getDefaultTenant } from 'src/modules/tenants/tenant.redux';
import { getErrorObject } from 'src/utils/funcs';
import i18n from 'src/utils/translations/i18n';

export const getInvites =
  (where?: NexusGenInputs['InviteWhereInput'], limit?: number, skip?: number, orderBy?: InviteOrderByInput) =>
  async (dispatch: Dispatch): Promise<{ invites: NexusGenFieldTypes['Invite'][]; count: number }> => {
    try {
      const response: { data: { invites: NexusGenFieldTypes['Invite'][] } } = await APOLLO_CLIENT.query({
        variables: {
          where,
          tenant: getDefaultTenant(),
          limit,
          skip,
          orderBy,
        },
        fetchPolicy: 'no-cache',
        query: InvitesDocument,
      });
      const count = await APOLLO_CLIENT.query({
        variables: {
          where,
          tenant: getDefaultTenant(),
        },
        fetchPolicy: 'no-cache',
        query: InvitesCountDocument,
      });

      return {
        invites: response.data.invites,
        count: count.data.invitesCount,
      };
    } catch (error: any) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getInvitesCount =
  (where?: NexusGenInputs['InviteWhereInput']) =>
  async (dispatch: Dispatch): Promise<number> => {
    try {
      const count = await APOLLO_CLIENT.query({
        variables: {
          where,
          tenant: getDefaultTenant(),
        },
        fetchPolicy: 'no-cache',
        query: InvitesCountDocument,
      });

      return count.data.invitesCount ?? 0;
    } catch (error: any) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const createOrUpdateManyInvites = (invites: any[], type: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        invites,
        type,
        tenant: getDefaultTenant(),
      },
      fetchPolicy: 'no-cache',
      mutation: CreateOrUpdateManyInvitesDocument,
    });

    return response.data.createOrUpdateManyInvites;
  } catch (error: any) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const resendInvite = (_id: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        _id,
      },
      fetchPolicy: 'no-cache',
      mutation: ResendInviteDocument,
    });

    return response.data.resendInvite;
  } catch (error: any) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const resendManyInvites = (_ids: string[]) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        _ids,
      },
      fetchPolicy: 'no-cache',
      mutation: ResendManyInvitesDocument,
    });

    return response.data.resendManyInvites;
  } catch (error: any) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const deleteInvite = (_id: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        _id,
      },
      fetchPolicy: 'no-cache',
      mutation: DeleteInviteDocument,
    });

    return response.data.deleteInvite;
  } catch (error: any) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const deleteManyInvites = (_ids: string[]) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        _ids,
        tenant: getDefaultTenant(),
      },
      fetchPolicy: 'no-cache',
      mutation: DeleteManyInvitesDocument,
    });

    return response.data.deleteManyInvites;
  } catch (error: any) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const isValidInvite = (domain: string, token: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: { domain, token },
      fetchPolicy: 'no-cache',
      query: IsValidInviteDocument,
    });

    return response.data.isValidInvite;
  } catch (error) {
    const obj = getErrorObject(error, '');

    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: i18n.t(obj.message),
        severity: 'error',
      },
    });

    return error;
  }
};
