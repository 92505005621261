import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    fullPopoverContainer: {
      left: 'unset !important',
      right: '0',
      width: '390px',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      top: '0 !important',
      maxHeight: '100% !important',
    },
    headerInfo: {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.13)',
      display: 'flex',
      alignItems: 'center',
      padding: '24px',
    },
    closeIcon: {
      color: theme.palette.text.secondary,
      // marginRight: '20px',
      marginLeft: 'auto',
      display: 'flex',
      cursor: 'pointer',
    },
    title: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '20px',
      /* color: theme.palette.error.main, */
      // marginLeft: '25px',
      display: 'flex',
    },
    divLineNestedLog: {
      // display: 'flex',
      // marginLeft: '35px',
    },
    divCircleNestedLog: {
      borderRadius: '10px',
      width: '15px',
      height: '20px',
      zIndex: 1,
    },
    divLine: {
      // borderLeft: `5px solid ${theme.palette.primary.dark}`,
      height: '100%',
      width: '5px',
    },
    timeTextFormat: {
      fontFamily: 'Open Sans',
      fontStyle: 'regular',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: theme.palette.text.primary,
    },
    responseValueText: {
      fontFamily: 'Open Sans',
      fontStyle: 'regular',
      fontSize: '14px',
      lineHeight: '19px',
      letterSpacing: '0.1px',
      color: theme.palette.text.secondary,
    },
    statusShow: {
      borderRadius: '30px',
      minWidth: '80px',
      fontFamily: 'Poppins',
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: '5px',
      paddingRight: '5px',
      fontSize: '14px',
      color: theme.palette.background.default,
    },
    statusPending: {
      color: '#272848 !important',
      backgroundColor: theme.palette.alert.main,
    },
    statusCantDo: {
      backgroundColor: theme.palette.error.main,
    },
    statusDoing: {
      backgroundColor: theme.palette.primary.main,
    },
    statusSolved: {
      backgroundColor: theme.palette.success.main,
    },
    actionName: {
      fontFamily: 'Poppins',
      fontStyle: 'Medium',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: theme.palette.text.primary,
    },
    arrowIcon: {
      cursor: 'pointer',
      fontSize: '15px',
    },
    popoverContent: {
      padding: '24px 24px 16px 24px',
    },
    nothingToShow: {
      fontFamily: 'Poppins',
      fontStyle: 'Italic',
      fontSize: '18px',
      color: theme.palette.text.disabled,
      height: 'inherit',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
    },
  });
