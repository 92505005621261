import GlartekDark from '@assets/images/glartek_dark.png';
import GlartekLight from '@assets/images/GlarVision.png';
import { Box, Card } from '@mui/material';
import withStyles, { WithStyles } from '@mui/styles/withStyles';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import CredentialForm from 'src/base/login/components/credentials-form';
import ResetForm from 'src/base/login/components/reset-form';
import { login } from 'src/base/login/login.redux';
import { styles } from 'src/base/login/styles/login';
import { recoverPassword } from 'src/base/reset-password/reset-password.backend';
import { showSnackbar } from 'src/base/root/root.redux';
import { getTenantInfo } from 'src/modules/authentication/authentication.redux';
import DefaultBackground from 'src/utils/components/default-background';
import { typedConnect, validateEmail } from 'src/utils/funcs';

interface LoginProps extends ConnectedProps<typeof connector>, WithStyles<typeof styles>, WithTranslation {}

interface LoginState {
  recover?: boolean;
  type?: string;
  saving?: boolean;
  emailError?: boolean;
  ldapState?: boolean;
}

const connector = typedConnect(
  (state) => ({
    themeSelected: state.loginReducer.theme,
    tenantInfo: state.authenticationReducer.tenantInfo,
  }),
  { login, recoverPassword, showSnackbar, getTenantInfo },
);

export const render = {
  view: {
    default: compose(
      withTranslation('translation'),
      withStyles(styles),
      connector,
    )(
      class Login extends React.Component<LoginProps, LoginState> {
        userRef: React.RefObject<HTMLInputElement>;
        pwRef: React.RefObject<HTMLInputElement>;
        propRef: React.RefObject<HTMLInputElement>;
        domainRef: React.RefObject<HTMLInputElement>;

        constructor(props: LoginProps) {
          super(props);

          this.userRef = React.createRef();
          this.pwRef = React.createRef();
          this.propRef = React.createRef();
          this.domainRef = React.createRef();

          this.state = {
            type: 'local',
            recover: false,
            saving: false,
            emailError: false,
          };
        }

        check = (t: React.KeyboardEvent<HTMLDivElement>) =>
          t.charCode === 13 && (!this.state.recover ? this.login() : this.reset());

        login = () => {
          if (!validateEmail(this.userRef.current?.value)) {
            this.setState({ emailError: true });
          } else this.setState({ emailError: false });
          this.setState({ saving: true }, () => {
            if (this.userRef && this.userRef.current && this.pwRef && this.pwRef.current) {
              void this.props
                .login(
                  this.userRef.current.value.trim(),
                  this.pwRef.current.value,
                  !this.domainRef.current?.value || this.domainRef.current?.value === 'undefined'
                    ? undefined
                    : this.domainRef.current?.value,
                )
                .then(() => {
                  this.setState({ saving: false });
                });
            } else {
              this.setState({ saving: false });
            }
          });
        };

        reset = (token?: string) => {
          this.setState({ saving: true }, () => {
            const { value: prop } = this.propRef.current;
            void this.props
              .recoverPassword(
                prop,
                token,
                !this.domainRef.current?.value || this.domainRef.current?.value === 'undefined'
                  ? undefined
                  : this.domainRef.current?.value,
              )
              .then((resp) => {
                if (resp?.graphQLErrors) {
                  this.setState({ saving: false });
                  return;
                }
                void this.props.showSnackbar('success', this.props.t('recoverPassword'));
                this.setState({ saving: false, recover: false });
              });
          });
        };

        render() {
          const { recover } = this.state;

          return (
            <DefaultBackground login>
              <div className={`hidden ${this.props.classes.headerBrand}`}>
                <span>
                  <img
                    src={
                      this.props.themeSelected === 'dark'
                        ? this.props.tenantInfo?.resources?.logo_dark?.url || GlartekDark
                        : this.props.tenantInfo?.resources?.logo_light?.url || GlartekLight
                    }
                    height={56}
                    width={'auto'}
                    alt={'logo'}
                  />
                </span>
              </div>
              <Box boxShadow={6}>
                <Card enzyme-attr='component-login-card' classes={{ root: this.props.classes.loginWrapper }}>
                  <div style={!recover ? {} : { display: 'none' }}>
                    <CredentialForm
                      userRef={this.userRef}
                      pwRef={this.pwRef}
                      domainRef={this.domainRef}
                      callback={this.login}
                      recover={() => this.setState({ recover: !recover })}
                      saving={this.state.saving}
                      emailError={this.state.emailError}
                    />
                  </div>
                  {!recover ? null : (
                    <ResetForm
                      emailRef={this.propRef}
                      t={this.props.t}
                      callback={(token: string): void => this.reset(token)}
                      saving={this.state.saving}
                      recover={() => this.setState({ recover: !recover })}
                    />
                  )}
                </Card>
              </Box>
            </DefaultBackground>
          );
        }
      },
    ),
  },
};

export default render;
