import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { getElement } from 'src/modules/element/element.redux';
import { useEffect, useState } from 'react';
import { styles } from 'src/utils/components/element-show/styles';
import { withStyles } from '@mui/styles';
import DetailsPopover from 'src/utils/components/details-popover';
import { NexusGenInputs } from '@server/src/types';

interface ElShow {
  values: { name: string; _id: string }[];
  getElement: (filter: NexusGenInputs['ElementWhereUniqueInput']) => Promise<{ name: string; _id: string }>;
  classes: {
    divValueActive: string;
    divValue: string;
  };
}

const ElementShow = (props: ElShow): JSX.Element => {
  const [element, setElement] = useState<{ name: string; _id: string } | null>(null);
  const [popoverDetails, setPopoverDetails] = useState<any>(null);

  useEffect(() => {
    if (props.values.length) {
      if (props.values[0].name) {
        setElement(props.values[0]);
      } else {
        props.getElement({ _id: props.values[0]._id }).then((resp) => {
          setElement(resp);
        });
      }
    }
  }, [props.values?.at(0)?._id]);

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setPopoverDetails(e.target);
        }}
        className={popoverDetails ? props.classes.divValueActive : props.classes.divValue}
      >
        {element ? element.name : null}
      </div>
      {popoverDetails ? (
        <DetailsPopover
          context={'element'}
          anchor={popoverDetails}
          value={element}
          clear={() => {
            setPopoverDetails(null);
          }}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (): object => ({});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getElement,
    },
    dispatch,
  );

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default compose<any>(
  withTranslation('translation'),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ElementShow);
