import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    divContainer: {
      width: '100%',
      marginBottom: '16px',
      overflow: 'auto',
    },
    popoverContainer: {
      width: '280px',
      maxHeight: '250px',
      // overflow: 'hidden',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    listItem: {
      padding: '8px 16px',
      fontFamily: 'Open Sans',
      cursor: 'pointer',
      fontSize: '14px',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    listItemSelected: {
      padding: '8px 16px',
      fontFamily: 'Open Sans',
      cursor: 'pointer',
      fontSize: '14px',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    selectedSortItem: {
      color: theme.palette.primary.main,
    },
    relative: {
      marginRight: 8,
      position: 'relative',
    },
    expandIcon: {
      position: 'absolute',
      right: 0,
      top: 8,
      cursor: 'pointer',
    },
    input: {
      background: theme.palette.background.default,
      boxShadow: 'none !important',
      color: `${theme.palette.text.primary} !important`,
      transition: 'all 0.3s, height 1ms',
      height: '40px',
      width: '100%',
      alignItems: 'flex-start',
      display: 'flex',
      cursor: 'pointer',
      maxWidth: '100%',
      padding: '8px',
      border: `1px solid ${theme.palette.grey.light}`,
      borderRadius: '5px',
      overflow: 'hidden',
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.text.primary,
      },
      '&:focus': {
        color: theme.palette.common.white,
        border: '1px solid',
      },
    },
    selectedItem: {
      color: theme.palette.text.primary,
      fontFamily: 'Open Sans',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingRight: '10px',
    },
  });
