import gql from 'graphql-tag';
import {
  INPUT,
  INPUT_WITH_LOG,
  INPUT_WITH_RECURSIVE,
  ON_RESPONSE_COMMON_NUMBER,
  ON_RESPONSE_COMMON_STRING,
  INPUT_NUMBER,
  INPUT_STRING,
} from 'src/utils/fragments/input';
import { LOCATION, STORAGE, TOOL, TOOL_ITEM } from 'src/utils/fragments/inventory';
import { ISSUE_INSTANCE } from 'src/utils/fragments/issue';
import { LABEL_VALUE } from 'src/utils/fragments/label';
import { STATE_MACHINE_INSTANCE } from 'src/utils/fragments/stateMachine';
import { TASK_INSTANCE_TO_TABLE } from 'src/utils/fragments/task';

export const Q_GET_ISSUE_INSTANCE = gql`
  query GetIssueInstanceExecution($filter: ID!) {
    issue(where: { _id: $filter }) {
      ...issueInstance
      chatRoom {
        _id
        type
        assignedAccounts {
          _id
        }
      }
      startDate
      taskGroups {
        name
        _id
        sequential
        order
        tasks {
          ...taskInstanceToTable
          executedBy {
            name
            email
            photo
            _id
            deleted
          }
          inputs {
            ...input
          }
          element {
            _id
            name
          }
          site {
            _id
            name
          }
          onResponseId
          parentsTree
          isActiveByOnResponse
          assignedAccounts {
            name
            email
            photo
            _id
            deleted
          }
          labelValues {
            ...labelValue
          }
          assignedLabelValues {
            ...labelValue
          }
          score {
            totalScore
            currentScore
          }
          taskTemplate
          assignedTools {
            tool {
              _id
              designation
            }
            reserveAtStart
            associatedWith {
              designation
              partNumber
              _id
            }
          }
        }
      }
      workPackageId
      workPackage {
        _id
        workPackageName
        totalIssuesDone
        issueCatalogs {
          _id
          name
        }
      }
      allTaskScore {
        current
        total
      }
      toolItems {
        _id
        available
        status
        description
        serialNumber
        code
        createdBy {
          name
          email
          photo
          _id
        }
        createdAt
        tool {
          _id
          designation
          partNumber
        }
        location {
          _id
          address {
            street
            site {
              name
            }
            element {
              name
            }
          }
          storage {
            _id
            name
            address {
              street
              site {
                name
              }
              element {
                name
              }
            }
          }
        }
        usedBy {
          name
          email
          photo
          _id
        }
        usedIn {
          _id
          glarID
          name
        }
      }
      toolAssociations {
        associatedToolItems {
          ...toolItem
        }
        tool {
          ...tool
        }
        toolItems {
          ...toolItem
        }
        associatedTools {
          ...tool
        }
      }
    }
  }
  ${INPUT}
  ${INPUT_WITH_LOG}
  ${INPUT_WITH_RECURSIVE}
  ${STATE_MACHINE_INSTANCE}
  ${ISSUE_INSTANCE}
  ${TASK_INSTANCE_TO_TABLE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
  ${TOOL}
  ${TOOL_ITEM}
  ${LOCATION}
  ${STORAGE}
  ${LABEL_VALUE}
`;

export const M_STATE_MACHINE_CHANGE_STATE = gql`
  mutation ChangeStateMachineState($token: String, $id: ID!, $action: String!, $note: String) {
    changeIssueState(token: $token, _id: $id, action: $action, note: $note) {
      possibleActions
      stateMachineInstance {
        ...stateMachineInstance
      }
    }
  }
  ${STATE_MACHINE_INSTANCE}
`;

export const M_REASSIGN_ISSUE_INSTANCE = gql`
  mutation ReassignIssueInstance($_id: ID!, $accounts: [ID!], $labelValues: [ID!]) {
    reassignIssueInstance(_id: $_id, accounts: $accounts, labelValues: $labelValues) {
      ...issueInstance
      startDate
      chatRoom {
        _id
        type
        assignedAccounts {
          _id
        }
      }
      taskGroups {
        name
        _id
        sequential
        order
        tasks {
          ...taskInstanceToTable
          element {
            name
          }
          site {
            name
          }
          onResponseId
          parentsTree
          isActiveByOnResponse
          assignedAccounts {
            name
            email
            photo
            _id
            _id
            deleted
          }
          labelValues {
            ...labelValue
          }
          assignedLabelValues {
            ...labelValue
          }
          score {
            totalScore
            currentScore
          }
          assignedTools {
            tool {
              _id
              designation
            }
            reserveAtStart
            associatedWith {
              designation
              partNumber
              _id
            }
          }
        }
      }
      workPackageId
      workPackage {
        _id
        workPackageName
        totalIssuesDone
        issueCatalogs {
          _id
          name
        }
      }
      toolItems {
        _id
        available
        status
        description
        serialNumber
        code
        createdBy {
          name
          email
          photo
          _id
        }
        createdAt
        tool {
          _id
          designation
          partNumber
        }
        location {
          _id
          address {
            street
            site {
              name
            }
            element {
              name
            }
          }
          storage {
            _id
            name
            address {
              street
              site {
                name
              }
              element {
                name
              }
            }
          }
        }
        usedBy {
          name
          email
          photo
          _id
        }
        usedIn {
          _id
          glarID
          name
        }
      }
      toolAssociations {
        associatedToolItems {
          ...toolItem
        }
        tool {
          ...tool
        }
        toolItems {
          ...toolItem
        }
        associatedTools {
          ...tool
        }
      }
    }
  }
  ${INPUT}
  ${INPUT_WITH_LOG}
  ${INPUT_WITH_RECURSIVE}
  ${STATE_MACHINE_INSTANCE}
  ${ISSUE_INSTANCE}
  ${TASK_INSTANCE_TO_TABLE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
  ${TOOL}
  ${TOOL_ITEM}
  ${LOCATION}
  ${STORAGE}
  ${LABEL_VALUE}
`;

export const M_ACCEPT_ISSUE_INSTANCE = gql`
  mutation AcceptIssueInstance($issueInstanceId: ID!) {
    acceptIssue(_id: $issueInstanceId) {
      _id
      acknowledgedAccounts {
        _id
      }
      acknowledgeRequired
      needsAcknowledge
    }
  }
`;

export const M_CANCEL_ISSUE = gql`
  mutation CancelIssue($where: IssueInstanceWhereUniqueInput!) {
    cancelIssue(where: $where) {
      _id
      name
    }
  }
`;

export const M_APPROVE_ISSUE = gql`
  mutation ApproveIssue($where: IssueInstanceWhereUniqueInput!, $data: IssueApprovalInput!) {
    approveIssue(where: $where, data: $data) {
      ...issueInstance
      taskGroups {
        tasks {
          ...taskInstanceToTable
          element {
            name
          }
          inputs {
            ...input
          }
        }
      }
    }
  }
  ${INPUT}
  ${INPUT_WITH_LOG}
  ${INPUT_WITH_RECURSIVE}
  ${STATE_MACHINE_INSTANCE}
  ${ISSUE_INSTANCE}
  ${TASK_INSTANCE_TO_TABLE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
`;

export const M_REJECT_ISSUE = gql`
  mutation RejectIssue($where: IssueInstanceWhereUniqueInput!, $data: IssueRejectionInput!) {
    rejectIssue(where: $where, data: $data) {
      ...issueInstance
      taskGroups {
        tasks {
          ...taskInstanceToTable
          element {
            name
          }
          inputs {
            ...input
          }
        }
      }
    }
  }
  ${INPUT}
  ${INPUT_WITH_LOG}
  ${INPUT_WITH_RECURSIVE}
  ${STATE_MACHINE_INSTANCE}
  ${ISSUE_INSTANCE}
  ${TASK_INSTANCE_TO_TABLE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
`;

export const M_CHANGE_EXECUTION_DATE_ISSUE = gql`
  mutation ChangeExecutionDateIssue($where: IssueInstanceWhereUniqueInput!, $data: IssueChangeDateExecutionInput!) {
    changeIssueExecutionDate(where: $where, data: $data) {
      ...issueInstance
      taskGroups {
        tasks {
          ...taskInstanceToTable
          element {
            name
          }
          inputs {
            ...input
          }
        }
      }
    }
  }
  ${INPUT}
  ${INPUT_WITH_LOG}
  ${INPUT_WITH_RECURSIVE}
  ${STATE_MACHINE_INSTANCE}
  ${ISSUE_INSTANCE}
  ${TASK_INSTANCE_TO_TABLE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
`;

export const M_UPDATE_ISSUE_INSTANCE = gql`
  mutation UpdateIssueInstance($where: IssueInstanceWhereUniqueInput!, $data: IssueUpdateInput!) {
    updateIssue(where: $where, data: $data) {
      ...issueInstance
      startDate
      chatRoom {
        _id
        type
        assignedAccounts {
          _id
        }
      }
      taskGroups {
        name
        _id
        sequential
        order
        tasks {
          ...taskInstanceToTable
          element {
            name
          }
          site {
            name
          }
          onResponseId
          parentsTree
          isActiveByOnResponse
          assignedAccounts {
            name
            email
            photo
            _id
            _id
            deleted
          }
          labelValues {
            ...labelValue
          }
          assignedLabelValues {
            ...labelValue
          }
          score {
            totalScore
            currentScore
          }
          assignedTools {
            tool {
              _id
              designation
            }
            reserveAtStart
            associatedWith {
              designation
              partNumber
              _id
            }
          }
        }
      }
      workPackageId
      workPackage {
        _id
        workPackageName
        totalIssuesDone
        issueCatalogs {
          _id
          name
        }
      }
      toolItems {
        _id
        available
        status
        description
        serialNumber
        code
        createdBy {
          name
          email
          photo
          _id
        }
        createdAt
        tool {
          _id
          designation
          partNumber
        }
        location {
          _id
          address {
            street
            site {
              name
            }
            element {
              name
            }
          }
          storage {
            _id
            name
            address {
              street
              site {
                name
              }
              element {
                name
              }
            }
          }
        }
        usedBy {
          name
          email
          photo
          _id
        }
        usedIn {
          _id
          glarID
          name
        }
      }
      toolAssociations {
        associatedToolItems {
          ...toolItem
        }
        tool {
          ...tool
        }
        toolItems {
          ...toolItem
        }
        associatedTools {
          ...tool
        }
      }
    }
  }
  ${INPUT}
  ${INPUT_WITH_LOG}
  ${INPUT_WITH_RECURSIVE}
  ${STATE_MACHINE_INSTANCE}
  ${ISSUE_INSTANCE}
  ${TASK_INSTANCE_TO_TABLE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
  ${TOOL}
  ${TOOL_ITEM}
  ${LOCATION}
  ${STORAGE}
  ${LABEL_VALUE}
`;

export const M_RESET_ISSUE = gql`
  mutation ResetIssue($where: IssueInstanceWhereUniqueInput!) {
    resetIssue(where: $where) {
      ...issueInstance
      taskGroups {
        name
        _id
        sequential
        order
        tasks {
          ...taskInstanceToTable
          element {
            name
          }
          inputs {
            ...input
          }
          isActiveByOnResponse
          onResponseId
          parentsTree
          score {
            currentScore
            totalScore
          }
          assignedTools {
            tool {
              _id
              designation
            }
            reserveAtStart
            associatedWith {
              designation
              partNumber
              _id
            }
          }
        }
      }
    }
  }
  ${INPUT}
  ${INPUT_WITH_LOG}
  ${INPUT_WITH_RECURSIVE}
  ${STATE_MACHINE_INSTANCE}
  ${ISSUE_INSTANCE}
  ${TASK_INSTANCE_TO_TABLE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
`;

export const M_DUPLICATE_TASK = gql`
  mutation DuplicateTask($_id: ID!, $groupId: ID!, $taskId: ID!) {
    duplicateTaskFromGroup(_id: $_id, groupId: $groupId, taskId: $taskId) {
      tasks
      issue {
        ...issueInstance
        startDate
        taskGroups {
          name
          _id
          sequential
          order
          tasks {
            ...taskInstanceToTable
            inputs {
              ...input
            }
            assignedAccounts {
              name
              email
              photo
              _id
            }
            assignedTools {
              tool {
                designation
                partNumber
                _id
              }
              quantity
              reserveAtStart
              associatedWith {
                designation
                partNumber
                _id
              }
            }
            assignedMaterials {
              material {
                code
                name
                _id
              }
              quantity
            }
            assignedLabelValues {
              ...labelValue
            }
            element {
              name
            }
            site {
              name
            }
            onResponseId
            parentsTree
            isActiveByOnResponse
          }
        }
      }
    }
  }
  ${INPUT}
  ${INPUT_WITH_LOG}
  ${INPUT_WITH_RECURSIVE}
  ${STATE_MACHINE_INSTANCE}
  ${ISSUE_INSTANCE}
  ${TASK_INSTANCE_TO_TABLE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
  ${LABEL_VALUE}
`;

export const Q_LINK_WITH_THIS_INPUT_COUNT = gql`
  query LinkWithThisInputCount($_id: ID!, $taskId: ID!) {
    linkedWithThisInputCount(_id: $_id, taskId: $taskId)
  }
`;

export const Q_FIND_ISSUE_INSTANCE = gql`
  query FindIssueInstance($filter: IssueInstanceWhereInput) {
    issueInstances(where: $filter) {
      _id
      glarID
      name
      description
      site {
        _id
        name
      }
      element {
        _id
        name
      }
      frequency {
        amount
        timeUnit
      }
      labelValues {
        ...labelValue
      }
      executedBy {
        name
        email
        photo
        _id
        deleted
      }
      assignedAccounts {
        name
        email
        photo
        _id
        deleted
      }
      assignedLabelValues {
        ...labelValue
      }
      stateMachineInstance {
        finishedAt
        log {
          action
          createdAt
        }
      }
    }
  }
  ${LABEL_VALUE}
`;

export const Q_GET_ISSUE_LABEL_ASSIGNEES = gql`
  query GetIssueLabelAssignees($filter: IssueInstanceWhereInput!) {
    issues(where: $filter) {
      _id
      name
      catalogId
      closedAt
      needsApproval

      assignedLabelValues {
        ...labelValue
      }
      assignedAccounts {
        username
        labelValuesIds
        name
        email
        _id
        deleted
      }
      taskGroups {
        tasks {
          _id
          assignedLabelValues {
            ...labelValue
          }
          assignedAccounts {
            username
            labelValuesIds
            name
            email
            _id
            deleted
          }
        }
      }
    }
  }
  ${LABEL_VALUE}
`;

export const S_ON_ISSUE_UPDATE = gql`
  subscription OnIssueUpdate($_id: ID!) {
    issueInstanceUpdated(_id: $_id) {
      ...issueInstance
      startDate
      chatRoom {
        _id
        type
        assignedAccounts {
          _id
        }
      }
      taskGroups {
        name
        _id
        sequential
        order
        tasks {
          ...taskInstanceToTable
          element {
            name
          }
          site {
            name
          }
          onResponseId
          parentsTree
          isActiveByOnResponse
          executedBy {
            name
            email
            photo
            _id
            deleted
          }
          assignedAccounts {
            __typename
            name
            email
            photo
            _id
            _id
            deleted
          }
          labelValues {
            ...labelValue
          }
          assignedLabelValues {
            ...labelValue
          }
          score {
            totalScore
            currentScore
          }
          assignedTools {
            tool {
              _id
              designation
            }
            reserveAtStart
            associatedWith {
              designation
              partNumber
              _id
            }
          }
        }
      }
      workPackageId
      workPackage {
        _id
        workPackageName
        totalIssuesDone
        issueCatalogs {
          _id
          name
        }
      }
      toolItems {
        _id
        available
        status
        description
        serialNumber
        code
        createdBy {
          name
          email
          photo
          _id
        }
        createdAt
        tool {
          _id
          designation
          partNumber
        }
        location {
          _id
          address {
            street
            site {
              name
            }
            element {
              name
            }
          }
          storage {
            _id
            name
            address {
              street
              site {
                name
              }
              element {
                name
              }
            }
          }
        }
        usedBy {
          name
          email
          photo
          _id
        }
        usedIn {
          _id
          glarID
          name
        }
      }
      toolAssociations {
        associatedToolItems {
          ...toolItem
        }
        tool {
          ...tool
        }
        toolItems {
          ...toolItem
        }
        associatedTools {
          ...tool
        }
      }
    }
  }
  ${INPUT}
  ${INPUT_WITH_LOG}
  ${INPUT_WITH_RECURSIVE}
  ${STATE_MACHINE_INSTANCE}
  ${ISSUE_INSTANCE}
  ${TASK_INSTANCE_TO_TABLE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
  ${TOOL}
  ${TOOL_ITEM}
  ${LOCATION}
  ${STORAGE}
  ${LABEL_VALUE}
`;

export const S_ON_ISSUES_UPDATE = gql`
  subscription OnIssuesUpdate($where: [ID!]!) {
    issueInstancesUpdated(where: $where) {
      ...issueInstance
      startDate
      taskGroups {
        name
        _id
        sequential
        order
        tasks {
          ...taskInstanceToTable
          element {
            name
          }
          site {
            name
          }
          onResponseId
          parentsTree
          isActiveByOnResponse
          assignedAccounts {
            __typename
            name
            email
            photo
            _id
            _id
            deleted
          }
          labelValues {
            ...labelValue
          }
          assignedLabelValues {
            ...labelValue
          }
          score {
            totalScore
            currentScore
          }
          assignedTools {
            tool {
              _id
              designation
            }
            reserveAtStart
            associatedWith {
              designation
              partNumber
              _id
            }
          }
        }
      }
      workPackageId
      workPackage {
        _id
        workPackageName
        totalIssuesDone
        issueCatalogs {
          _id
          name
        }
      }
      toolItems {
        _id
        available
        status
        description
        serialNumber
        code
        createdBy {
          name
          email
          photo
          _id
        }
        createdAt
        tool {
          _id
          designation
          partNumber
        }
        location {
          _id
          address {
            street
            site {
              name
            }
            element {
              name
            }
          }
          storage {
            _id
            name
            address {
              street
              site {
                name
              }
              element {
                name
              }
            }
          }
        }
        usedBy {
          name
          email
          photo
          _id
        }
        usedIn {
          _id
          glarID
          name
        }
      }
      toolAssociations {
        associatedToolItems {
          ...toolItem
        }
        tool {
          ...tool
        }
        toolItems {
          ...toolItem
        }
        associatedTools {
          ...tool
        }
      }
    }
  }
  ${INPUT}
  ${INPUT_WITH_LOG}
  ${INPUT_WITH_RECURSIVE}
  ${STATE_MACHINE_INSTANCE}
  ${ISSUE_INSTANCE}
  ${TASK_INSTANCE_TO_TABLE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
  ${TOOL}
  ${TOOL_ITEM}
  ${LOCATION}
  ${STORAGE}
  ${LABEL_VALUE}
`;

export const M_REPEAT_TASK_MANY_TIMES = gql`
  mutation RepeatTaskManyTimes($_id: ID!, $groupId: ID!, $taskId: [ID!]!, $timesToRepeat: Int!) {
    repeatTaskManyTimes(_id: $_id, groupId: $groupId, taskId: $taskId, timesToRepeat: $timesToRepeat) {
      ...issueInstance
      startDate
      taskGroups {
        name
        _id
        sequential
        order
        tasks {
          ...taskInstanceToTable
          element {
            name
          }
          site {
            name
          }
          onResponseId
          parentsTree
          isActiveByOnResponse
          assignedAccounts {
            name
            email
            photo
            _id
            _id
            deleted
          }
          labelValues {
            ...labelValue
          }
          assignedLabelValues {
            ...labelValue
          }
          score {
            totalScore
            currentScore
          }
          assignedTools {
            tool {
              _id
              designation
            }
            reserveAtStart
            associatedWith {
              designation
              partNumber
              _id
            }
          }
        }
      }
      workPackageId
      workPackage {
        _id
        workPackageName
        totalIssuesDone
        issueCatalogs {
          _id
          name
        }
      }
      toolItems {
        _id
        available
        status
        description
        serialNumber
        code
        createdBy {
          name
          email
          photo
          _id
        }
        createdAt
        tool {
          _id
          designation
          partNumber
        }
        location {
          _id
          address {
            street
            site {
              name
            }
            element {
              name
            }
          }
          storage {
            _id
            name
            address {
              street
              site {
                name
              }
              element {
                name
              }
            }
          }
        }
        usedBy {
          name
          email
          photo
          _id
        }
        usedIn {
          _id
          glarID
          name
        }
      }
      toolAssociations {
        associatedToolItems {
          ...toolItem
        }
        tool {
          ...tool
        }
        toolItems {
          ...toolItem
        }
        associatedTools {
          ...tool
        }
      }
    }
  }
  ${INPUT}
  ${INPUT_WITH_LOG}
  ${INPUT_WITH_RECURSIVE}
  ${STATE_MACHINE_INSTANCE}
  ${ISSUE_INSTANCE}
  ${TASK_INSTANCE_TO_TABLE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
  ${TOOL}
  ${TOOL_ITEM}
  ${LOCATION}
  ${STORAGE}
  ${LABEL_VALUE}
`;

export const Q_GET_ISSUE_SCORE = gql`
  query GetIssueInstanceScore($filter: ID!) {
    issue(where: { _id: $filter }) {
      allTaskScore {
        current
        total
      }
    }
  }
`;
