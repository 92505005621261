import { Backdrop, CircularProgress, FormGroup } from '@mui/material';
import * as React from 'react';
import { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { File } from 'src/gql/graphql';
import { downloadFile, getFiles } from 'src/modules/file-storage/file-storage.redux';
import DialogFilesCarousel from 'src/utils/components/dialog-files-carousel';
import DeleteDialog from '../delete-dialog';
import { BlockCloseDropdownContext } from '../generic-input-card';
import DocumentGrid from './components/documents/document-grid';
import { GalleryDialog } from './components/gallery-dialog';
import MediaCarousel from './components/media/MediaCarousel';
import { FilesContext } from './context';
import { styles } from './styles';
import { fileIsMedia } from './utils/show-file-util-functions';

type ShowFilePropsType = {
  loading?: boolean;
  setExpectedValueLength?: React.Dispatch<React.SetStateAction<number>>;
  setLoading?: (loading: boolean) => void;
  noReorder?: boolean;
  value: File[] | string[];
  withImage?: boolean;
  noName?: boolean;
  allowReorder?: boolean;
  noBold?: boolean;
  noColon?: boolean;
  titleStyle?: Record<string, string>;
  required?: boolean;
  noImageInfo?: boolean;
  noDelete?: boolean;
  noDownload?: boolean;
  showImageClose?: boolean;
  progressMap?: Map<string, number>;
  uploadingFiles?: File[];
  handleChange?: (values: File[]) => void;
} & ConnectedProps<typeof connector> &
  PropsWithChildren<Partial<File>>;

interface IDeleteFileDialog {
  type: 'media' | 'file';
  file: File;
  open: boolean;
}

interface IGallery {
  open: boolean;
  initialTab: number;
}

const FILE_EXPIRATION_TIME = 59 * 60 * 1000;

const ShowFile: React.FC<ShowFilePropsType> = (props: ShowFilePropsType) => {
  const location = useLocation();
  const atTemplates = React.useMemo(() => {
    return location.pathname.includes('/templates/issues/issue');
  }, [location.pathname]);
  const { allowReorder } = props;
  const [currentFiles, setCurrentFiles] = useState<File[]>([]);
  const [openCarousel, setOpenCarousel] = useState<File | null>(null);
  const [gallery, setGallery] = useState<IGallery>({ open: false, initialTab: 0 }); // 0 - media 1 - files
  const [deleteDialog, setDeleteDialog] = useState<IDeleteFileDialog>({ type: 'media', file: null, open: false });
  const { t } = useTranslation();

  const classes = styles();

  const checkAndUpdateExpiredFiles = () => {
    const now = Math.floor(Date.now() / 1000);
    const hasExpiredFiles = currentFiles.some((file) => file.download?.validUntil <= now);

    if (hasExpiredFiles) {
      fetchFiles(false);
    }
  };
  useEffect(() => {
    const intervalId = setInterval(checkAndUpdateExpiredFiles, FILE_EXPIRATION_TIME);

    return () => clearInterval(intervalId);
  }, [currentFiles]);

  const files = React.useMemo(() => {
    return [...(props.uploadingFiles ?? []), ...currentFiles];
  }, [currentFiles, props.uploadingFiles]);

  const setBlockClose = useContext(BlockCloseDropdownContext);

  useEffect(() => {
    setBlockClose?.(deleteDialog.open || !!openCarousel);
  }, [deleteDialog.open, openCarousel]);

  const fetchFiles = async (cache = true) => {
    const noCache = !cache; //Force refetching images if in edit-mode (IE: Issue template editing);
    const files = await props.getFiles(
      { _id_in: props.value.map((file) => (typeof file === 'string' ? file : file._id)) },
      noCache,
    );
    if ('graphQLErrors' in files) {
      return;
    }
    setCurrentFiles(files);
    props.setLoading?.(false);
  };

  useEffect(() => {
    if (props.value.length && typeof props.value[0] === 'string') {
      const sortedFiles = [...files].sort((a, b) => {
        const values = props.value as string[];
        return values.indexOf(a._id) - values.indexOf(b._id);
      });
      setCurrentFiles(sortedFiles);
      fetchFiles();
    } else {
      setCurrentFiles(props.value as File[]);
    }
  }, [props.value]);

  useEffect(() => {
    if (props.value) {
      if (props.value.length && typeof props.value[0] === 'string') {
        fetchFiles();
      } else {
        setCurrentFiles(props.value as File[]);
      }
    } else {
      setCurrentFiles([]);
      props.setLoading?.(false);
    }
  }, [props.value.length]);
  return (
    <FormGroup className={classes.form}>
      <div className={classes.insideForm}>
        {props.noName ? null : props.noBold ? (
          <span className={`${props.titleStyle ? props.titleStyle : classes.infoLabel}`}>
            {props.name + (props.required ? ' *' : '') + (props.noColon ? '' : ':')}
          </span>
        ) : (
          <b className={`${props.titleStyle ? props.titleStyle : classes.infoLabel}`}>
            {props.name + (props.required ? ' *' : '') + (props.noColon ? '' : ':')}
          </b>
        )}
        <Backdrop style={props.loading ? {} : { display: 'none' }} className={classes.backdrop} open={props.loading}>
          <CircularProgress color='inherit' />
        </Backdrop>
        {!props.loading && files && Array.isArray(files) && files.length > 0 && (
          <>
            <FilesContext.Provider value={{ handleChange: props.handleChange, files: files }}>
              <div className={classes.filePreviewDiv} key={files.length}>
                <MediaCarousel
                  showInfo={!props.noImageInfo}
                  showDownload={!props.noDownload}
                  showDelete={!props.noDelete}
                  allowEdit={allowReorder}
                  currentFiles={files.filter((f) => fileIsMedia(f))}
                  setCurrentFiles={(files: File[]) => {
                    props.handleChange?.([...currentFiles.filter((f) => !fileIsMedia(f)), ...files]);
                  }}
                  onDeleteFile={(file) => {
                    setDeleteDialog({
                      ...deleteDialog,
                      open: true,
                      file: file,
                      type: 'media',
                    });
                  }}
                  setOpenCarousel={setOpenCarousel}
                  allowReorder={props.noReorder ? false : allowReorder}
                  allowNavigation={atTemplates}
                  setGallery={setGallery}
                  progressMap={props.progressMap}
                />
                <DocumentGrid
                  showDownload={!props.noDownload}
                  showDelete={!props.noDelete}
                  multiline={atTemplates || location.pathname.includes('/feed/create-post')}
                  currentFiles={files.filter((f) => !fileIsMedia(f))}
                  edit={props.noReorder ? false : allowReorder}
                  setOpenCarousel={setOpenCarousel}
                  onDeleteFile={(file) => {
                    setDeleteDialog({
                      ...deleteDialog,
                      open: true,
                      file: file,
                      type: 'file',
                    });
                  }}
                  setGallery={setGallery}
                  progressMap={props.progressMap}
                />
              </div>
            </FilesContext.Provider>
          </>
        )}
      </div>
      {openCarousel && currentFiles.find((f) => f._id === openCarousel._id) ? (
        <DialogFilesCarousel
          setFiles={props.handleChange}
          close={(): void => setOpenCarousel(null)}
          files={
            fileIsMedia(openCarousel)
              ? currentFiles.filter((f) => fileIsMedia(f))
              : currentFiles.filter((f) => !fileIsMedia(f))
          }
          clickedFile={openCarousel}
          showDelete={!props.noDelete}
          delete={(file: File) => {
            setDeleteDialog({
              ...deleteDialog,
              open: true,
              file: file,
              type: fileIsMedia(file) ? 'media' : 'file',
            });
          }}
        />
      ) : null}
      {gallery.open ? (
        <GalleryDialog
          close={() => setGallery({ ...gallery, open: false })}
          initialTab={gallery.initialTab}
          files={files.filter((f) => !fileIsMedia(f))}
          media={files.filter((f) => fileIsMedia(f))}
          setOpenCarousel={setOpenCarousel}
        ></GalleryDialog>
      ) : null}
      <DeleteDialog
        open={deleteDialog.open}
        title={t(deleteDialog.type === 'media' ? 'deleteImage' : 'deleteFile')}
        onCancel={function (): void {
          setDeleteDialog({ ...deleteDialog, open: false });
        }}
        onDelete={function (): void {
          if (props.setExpectedValueLength) {
            props.setExpectedValueLength((prev) => prev - 1);
          }
          let deletedIdx = null;
          props.handleChange?.(
            files.filter((f, idx) => {
              if (f._id != deleteDialog.file._id) {
                deletedIdx = idx;
                return true;
              }
              return false;
            }),
          );
          setDeleteDialog({ ...deleteDialog, open: false });
          setOpenCarousel(
            openCarousel
              ? currentFiles.find((f, idx) => {
                  const isMedia = 'media' === deleteDialog.type;

                  if (!!fileIsMedia(f) === isMedia && deletedIdx >= idx) {
                    return f;
                  }
                  return undefined;
                })
              : null,
          );
        }}
        subtitle={t(deleteDialog.type === 'media' ? 'deleteImageSubtitle' : 'deleteFileSubtitle')}
      />
    </FormGroup>
  );
};

const connector = connect(null, { downloadFile, getFiles });

export default connector(ShowFile);
