import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    divValue: {
      width: 'fit-content',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
        textDecorationLine: 'underline',
      },
    },
    divValueActive: {
      width: 'fit-content',
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  });
