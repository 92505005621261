import { useMemo, useState } from 'react';
import { styles } from './styles';
import { Backdrop, Dialog, Divider, TextField, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BetterFieldPopover from 'src/utils/components/better-field-popover';
import RichTextEditor, { EditorValue, ToolbarConfig } from 'react-rte';
import { ConnectedProps, connect } from 'react-redux';
import { addFile, uploadFile } from 'src/modules/file-storage/file-storage.redux';
import queryString from 'query-string';
import { createFeedPost, deleteFeedPost, updateFeedPost } from '../../feed.redux';
import { LabelValue, useGetPostQuery } from 'src/gql/graphql';
import { showSnackbar } from 'src/base/root/root.redux';
import DefaultButton from 'src/utils/components/default-button';
import ReactLoading from 'react-loading';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation, useNavigate } from 'react-router-dom';
import LabelInput from 'src/utils/components/input-field/input-label';
import UploadFiles from 'src/utils/components/upload-files';
import { PostDoesNotExistModal } from '../post/utils';
import { css } from '@emotion/css';

type GroupName =
  | 'INLINE_STYLE_BUTTONS'
  | 'BLOCK_TYPE_BUTTONS'
  | 'LINK_BUTTONS'
  | 'BLOCK_TYPE_DROPDOWN'
  | 'HISTORY_BUTTONS';

const toolbarDisplay: GroupName[] = [
  'INLINE_STYLE_BUTTONS',
  'BLOCK_TYPE_BUTTONS',
  'LINK_BUTTONS',
  'BLOCK_TYPE_DROPDOWN',
  'HISTORY_BUTTONS',
];
const toolbarConfig: ToolbarConfig = {
  INLINE_STYLE_BUTTONS: [{ label: 'Bold', style: 'BOLD', className: 'custom-css-class' }],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-one' },
    { label: 'Heading Medium', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
  ],
  display: toolbarDisplay,
  BLOCK_ALIGNMENT_BUTTONS: [],
};

const useStyles = makeStyles(styles);

type FeedCreatePost = ConnectedProps<typeof connector>;

const FeedCreatePost = function (props: FeedCreatePost) {
  const location = useLocation();
  const postId = queryString.parse(location.search)?.postId as string;
  const classes = useStyles();
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();
  const [labels, setLabels] = useState<LabelValue[]>([]);
  const [subject, setSubject] = useState<string>('');
  const [description, setDescription] = useState<EditorValue>(RichTextEditor.createEmptyValue());
  const [filesIds, setFilesIds] = useState<string[]>([]);
  const [showPublishModal, setShowPublishModal] = useState<boolean>(false);
  const [showDiscardModal, setShowDiscardModal] = useState<boolean>(false);

  const { loading = false } = useGetPostQuery({
    variables: {
      id: postId,
    },
    skip: !postId,
    onCompleted({ post }) {
      setSubject(post.subject);
      setDescription(RichTextEditor.createValueFromString(post.description, 'markdown'));
      //@ts-ignore
      setFilesIds(post.filesIds.filter((f) => !!f));
      setLabels(post.labelValues);
    },
    onError(error) {
      if (error.message.startsWith('Not found')) {
        PostDoesNotExistModal().then(() => navigate('/feed'));
      }
    },
  });

  const theme = useTheme();

  const validateSave = useMemo(() => {
    if (!description.toString('markdown').trim().replaceAll('\u200B', '')) {
      return false;
    }

    return true;
  }, [description]);

  const saveData = async (publish?: boolean) => {
    setSaving(true);

    const fmtDescription = description.toString('markdown').trim().replaceAll('\u200B', '');

    try {
      if (postId) {
        if (publish) {
          await props.deleteFeedPost(postId);
          await props.createFeedPost({
            subject,
            description: fmtDescription,
            files: filesIds,
            labelValues: labels.map((l) => l._id),
          });
          props.showSnackbar('success', t('postPublished'));
        } else {
          await props.updateFeedPost(postId, {
            subject,
            description: fmtDescription,
            files: filesIds,
            labelValues: labels.map((l) => l._id),
          });
          props.showSnackbar('success', t('postEdited'));
        }
      } else {
        await props.createFeedPost({
          subject,
          description: fmtDescription,
          files: filesIds,
          labelValues: labels.map((l) => l._id),
        });
        props.showSnackbar('success', t('postPublished'));
      }

      navigate('/feed');
    } catch {
      setSaving(false);
    }
  };

  return (
    <>
      <div className={classes.backdrop}>
        <Backdrop open={loading} />
        <div className={classes.editCard}>
          <BetterFieldPopover
            title={t('youArePostingTo')}
            placeholder={t('general')}
            data-testid={'post-labels-selection'}
            context={'labelAccount'}
            resetOption={t('general') as string}
            multiple
            //@ts-ignore
            value={labels}
            //@ts-ignore
            onChange={setLabels}
            classes={{ placeholder: classes.generalPlaceholder }}
          />
          <Divider style={{ marginBottom: '8px' }} />
          <LabelInput htmlFor={'subject-input'}>{t('subject')}</LabelInput>
          <TextField
            id='subject-input'
            data-testid='subject-input'
            value={subject}
            placeholder={t('addOptionalSubject')}
            size={'small'}
            InputProps={
              {
                /* disableUnderline: true, */
              }
            }
            /* className={classes.input} */
            onChange={(ev) => setSubject(ev.target.value)}
          />
          <LabelInput style={{ marginTop: '8px' }}>{t('description')}</LabelInput>
          {description ? (
            <div id='post-description' data-testid='post-description'>
              <RichTextEditor
                data-testid={'rich-editor'}
                placeholder={t('addADescription')}
                toolbarClassName={classes.editorToolbar}
                editorClassName={classes.editor}
                className={css({
                  backgroundColor: 'unset',
                })}
                toolbarConfig={toolbarConfig}
                value={description}
                rootStyle={{ overflow: 'auto' }}
                onChange={(val) => setDescription(val)}
              />
            </div>
          ) : null}
          <UploadFiles setFileIds={setFilesIds} camera={true} fileIds={filesIds} />
          {showDiscardModal ? (
            <Dialog classes={{ paper: classes.publishPaper }} open={true} onClose={() => setShowPublishModal(false)}>
              <Typography variant={'h4'} style={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>
                {t('discardPost')}
              </Typography>
              <Typography
                align='center'
                variant={'body1'}
                style={{ fontWeight: '400', color: theme.palette.text.secondary }}
              >
                {t('allChangesWillBeLost')}
              </Typography>
              <div className={classes.discardModalButtons}>
                <DefaultButton
                  data-testid={'btn-cancel-lost-changes-modal'}
                  discard
                  onClick={() => setShowDiscardModal(false)}
                >
                  {t('cancel')}
                </DefaultButton>
                <DefaultButton remove data-testid={'btn-discard-lost-changes-modal'} onClick={() => navigate('/feed')}>
                  {t('discard')}
                </DefaultButton>
              </div>
            </Dialog>
          ) : null}
          {showPublishModal ? (
            <Dialog classes={{ paper: classes.publishPaper }} open={true} onClose={() => setShowPublishModal(false)}>
              <Typography variant={'h3'} style={{ fontWeight: 'bold' }}>
                {/*t('publishChangesAsNewPost')*/}
                {t('publishChanges')}
              </Typography>
              <Typography variant={'body1'} style={{ fontWeight: '400', color: theme.palette.text.secondary }}>
                {t('thisWillResetPostViews')}
              </Typography>
              <div className={classes.discardModalButtons}>
                <DefaultButton
                  data-testid={'btn-cancel-publish-modal'}
                  discard
                  onClick={() => setShowPublishModal(null)}
                >
                  {t('cancel')}
                </DefaultButton>
                <DefaultButton data-testid={'btn-publish-publish-modal'} onClick={() => saveData(false)}>
                  {t('publish')}
                </DefaultButton>
              </div>
            </Dialog>
          ) : null}
          {saving && (
            <Dialog open={true} classes={{ paper: classes.saving }}>
              <Typography variant='h3' className={classes.bold}>
                {t('pleaseWait')}
              </Typography>
              <Typography variant='h3' className={classes.bold}>
                {t('postBeingSaved') + '...'}
              </Typography>
              <ReactLoading type={'spin'} color={theme.palette.primary.main} height={70} width={70} />
            </Dialog>
          )}
        </div>
      </div>
      <div className={classes.fixedButtons}>
        <DefaultButton data-testid={'buttonDiscard'} discard onClick={() => setShowDiscardModal(true)}>
          {t('discard')}
        </DefaultButton>
        <DefaultButton
          disabled={!validateSave}
          data-testid={'buttonSave'}
          success
          onClick={() => {
            if (postId) {
              setShowPublishModal(true);
            } else {
              saveData();
            }
          }}
          loader={saving}
        >
          {t('Save')}
        </DefaultButton>
      </div>
    </>
  );
};

const connector = connect(() => ({}), {
  addFile,
  createFeedPost,
  updateFeedPost,
  deleteFeedPost,
  showSnackbar,
  uploadFile,
});

export default connector(FeedCreatePost);
