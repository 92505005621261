import { Chip } from '@mui/material';
import { CallSplit, CancelOutlined, Close } from '@mui/icons-material';
import { withTranslation } from 'react-i18next';
import { AnyAction, compose } from 'redux';
import { withStyles } from '@mui/styles';
import { styles } from 'src/utils/components/generic-input-card/styles';

const CancelIssueChip = (props: AnyAction): JSX.Element => {
  const { classes, input, setInput, active } = props;

  const showChip = (response): JSX.Element => {
    if (input.type === 'selection' && response.cancelIssue) {
      return (
        <Chip
          key={response._id}
          icon={<CallSplit classes={{ root: classes.iconAlertChip }} />}
          label={
            <div className={classes.divLabel}>
              {props.t('if')}

              <span className={classes.cancelIssueSpanText}>{response.ifValues[0].label}</span>

              {props.t('selected')}
              <span className={classes.cancelIssueSpanText}>
                <CancelOutlined style={{ fontSize: '15px' }} />
                {props.t('cancelIssue')}
              </span>
              {!active ? null : (
                <Close
                  classes={{ root: classes.closeIcon }}
                  onClick={(): void => {
                    setInput({
                      _id: input._id,
                      [input.type]: {
                        ...input[input.type],
                        onResponse: input[input.type].onResponse.map((onRespon) =>
                          onRespon.ifValues[0].label === response.ifValues[0].label
                            ? { ...onRespon, cancelIssue: false }
                            : onRespon,
                        ),
                      },
                    });
                  }}
                />
              )}
            </div>
          }
          classes={{ root: /* props.error ? classes.advancedChipError : */ classes.alertChipExecution }}
        />
      );
    } else return <div key={response._id} />;
  };
  return (
    <div className={classes.cancelIssueContainer}>
      {input[input.type].onResponse
        ?.filter((r) => r.inputs?.length || r.cancelIssue)
        .map((response) => showChip(response))}
    </div>
  );
};

export default compose<any>(withTranslation('translation'), withStyles(styles))(CancelIssueChip);
