import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APOLLO_CLIENT } from 'config/apollo.config';
import * as queries from './config.queries';
import { getErrorObject } from 'src/utils/funcs';
import { setAgoraID } from 'src/utils/remote-assistance/constants';
import reduxStore from 'src/reducer-manager';
import { AssistConfiguration } from 'src/gql/graphql';

interface State {
  configuration?: AssistConfiguration;
  error?: string;
}

const initialState: State = {};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setAssistConfiguration(state, action: PayloadAction<AssistConfiguration>) {
      state.configuration = action.payload;
      state.error = undefined;
    },
    setAssistConfigurationError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.configuration = undefined;
    },
  },
});

export const { setAssistConfiguration, setAssistConfigurationError } = configSlice.actions;

export default configSlice.reducer;

// Async thunk to get assist configuration
export const getAssistConfiguration = async (): Promise<void> => {
  try {
    const response = await APOLLO_CLIENT.query({
      query: queries.Q_GET_ASSIST_CONFIGURATION,
      fetchPolicy: 'no-cache',
    });

    const assistCfg: AssistConfiguration = response.data.getAssistConfiguration;
    reduxStore.dispatch(setAssistConfiguration(assistCfg));

    if (assistCfg && assistCfg.AgoraIOAppID) {
      setAgoraID(assistCfg.AgoraIOAppID);
    }
  } catch (error) {
    const errMsg = getErrorObject(error, '');
    reduxStore.dispatch(setAssistConfigurationError(errMsg.message));
  }
};
