import moment from 'moment';
import { jsPDF } from 'jspdf';
import {
  issueDate,
  IssueInstance,
  issueInstruction,
  issueNumber,
  issueSignature,
  issueSiteElement,
  issueString,
  issueTable,
} from 'src/interfaces/issue';
import humanizeDuration from 'humanize-duration';
import { openSans } from 'src/utils/other/pdf-fonts/openSans';
import { openSansBold } from 'src/utils/other/pdf-fonts/openSansBold';
import { openSansItalic } from 'src/utils/other/pdf-fonts/openSansItalic';
import { getActionColor, getImageForReport } from 'src/utils/funcs/reports';
import { ActionsDictionary } from 'src/modules/issue-history/components/pdf-report';
import { TenantBasicInfo } from 'src/gql/graphql';
import ActionImage from '@assets/images/actionIcon.png';
import GlarvisionImage from '@assets/images/GlarVision.png';
import RoundCheckGreen from '@assets/images/roundCheckGreen.png';
import RoundCheckOrange from '@assets/images/roundCheckOrange.png';

export interface ImagesOnNotes {
  src?: string;
  imageToRender: HTMLImageElement;
  name: string;
  extension: string;
}

interface signatureSourceLinks {
  src: string;
  extension: string;
  id: string;
}

const incrementY = (n: number): number => {
  return (n = n + 10);
};

const addHeaders = (doc: jsPDF, tenantInfo: TenantBasicInfo) => {
  const pageCount = doc.internal.pages.length - 1;
  const width = doc.internal.pageSize.getWidth();
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    const logo = new Image();
    logo.src = tenantInfo?.resources?.logo_light?.url ? tenantInfo?.resources?.logo_light?.url : GlarvisionImage;
    doc.addImage(logo, 'png', width / 2 - 30, 10, 68, 15);
  }
};

const addFooters = (doc: jsPDF, t: (s: string) => string) => {
  const pageCount = doc.internal.pages.length - 1;
  const today = moment().format('LLL');
  doc.setTextColor('#CACACA');
  doc.setFont('OpenSans', 'normal');
  doc.setFontSize(10);
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.text(`${t('reportGenerate')} ${t('on')} ${today}`, 20, doc.internal.pageSize.height - 10);
    const text = String(i) + `/` + String(pageCount);
    doc.text(text, doc.internal.pageSize.width - 20 - doc.getTextDimensions(text).w, doc.internal.pageSize.height - 10);
  }
};

const getAttachemnts = (issue: IssueInstance) => {
  let attachments = [];
  for (const group of issue.taskGroups) {
    for (const task of group.tasks) {
      for (const executionInput of task.executionInputs) {
        try {
          if (executionInput.type === 'instruction' || executionInput.type === 'file') {
            attachments = [
              ...attachments,
              ...executionInput[executionInput.type].values.map((elem) => ({
                url: elem.download.thumbnail?.url || elem.download.url,
                name: elem.name,
                extension: elem.extension,
              })),
            ];
          }
          if (executionInput?.note?.files) {
            attachments = [
              ...attachments,
              ...executionInput.note.files.map((elem) => {
                return { url: elem.download.thumbnail.url, name: elem.name, extension: elem.extension };
              }),
            ];
          }
        } catch (error) {
          (error) => console.error(error);
        }
      }
    }
  }
  return attachments;
};

const addAtatchements = (doc: jsPDF, issue: IssueInstance, t: (s: string) => string) => {
  const attachments = getAttachemnts(issue);
  if (attachments.length) {
    try {
      const width = doc.internal.pageSize.getWidth();
      doc.addPage();
      doc.setFontSize(14);
      doc.setFillColor('#F4F5F9');
      doc.roundedRect(20, 38, width - 40, 20, 5, 5, 'F');
      doc.setTextColor('#0078FE');
      let y = 51;
      doc.text(t('attachments'), 25, y);
      y += 30;
      for (const attachment of attachments) {
        try {
          if (y > doc.internal.pageSize.getHeight() - 100) {
            doc.addPage();
            y = 40;
          }
          doc.addImage(attachment.url, attachment.extension, 25, y + 10, 230, 230);
          doc.setFontSize(14);
          doc.setTextColor('#88899A');
          doc.text(attachment.name, 25, y);
          y += 260;
        } catch (error) {
          (error) => console.error(error);
        }
      }
    } catch (error) {
      (error) => console.error(error);
    }
  }
};

let rows: Record<string, string>[] = [];
export const basicReport = (
  issue: IssueInstance,
  t: (s: string) => string,
  totalFinishedTasks: number,
  totalTasks: number,
  _signatureSrcArray?: signatureSourceLinks[],
  actionsFromInput?: ActionsDictionary[],
  totalActions?: number,
  tenantInfo?: TenantBasicInfo,
): void => {
  try {
    const doc = new jsPDF('p', 'px');

    doc.addFileToVFS('OpenSans-Regular.ttf', openSans);
    doc.addFileToVFS('OpenSans-Bold.ttf', openSansBold);
    doc.addFileToVFS('OpenSans-Italic.ttf', openSansItalic);
    doc.addFont('OpenSans-Regular.ttf', 'OpenSans', 'normal');
    doc.addFont('OpenSans-Bold.ttf', 'OpenSans', 'bold');
    doc.addFont('OpenSans-Italic.ttf', 'OpenSans', 'italic');
    doc.setFont('OpenSans');

    const width = doc.internal.pageSize.getWidth();

    doc.setFillColor('#F4F5F9');
    doc.roundedRect(20, 33, width - 40, 50, 5, 5, 'F');
    doc.setTextColor('#0078FE');
    doc.setFont('OpenSans', 'normal');
    doc.setFontSize(14);
    doc.text(issue.name, 30, 50);

    if (totalActions > 0) {
      const action = new Image();
      action.src = ActionImage;
      doc.addImage(action, 'png', width - 104, 43, 5, 5);
      doc.setTextColor('#FE8947');
      doc.setFont('OpenSans', 'normal');
      doc.setFontSize(9);
      doc.text(totalActions.toString(), width - 98, 48);
    }

    doc.setTextColor(totalFinishedTasks === totalTasks ? '#00C48C' : '#FE8947');
    const check = new Image();
    check.src = totalFinishedTasks === totalTasks ? RoundCheckGreen : RoundCheckOrange;

    doc.addImage(check, 'png', width - 88, 43, 5, 5);

    doc.setTextColor(totalFinishedTasks === totalTasks ? '#00C48C' : '#FE8947');
    doc.setFont('OpenSans', 'bold');
    doc.setFontSize(9);

    doc.text(`${t('completed')} ${totalFinishedTasks}/${totalTasks}`, width - 80, 48);

    doc.setTextColor('#777777');
    doc.setFont('OpenSans', 'normal');
    doc.setFontSize(10);

    doc.text(`${t('StartedAt')}:`, 30, 65);

    doc.text(`${t('finishAt')}:`, 140, 65);

    doc.text(`${t('executionTime')}:`, 250, 65);

    doc.setFontSize(10);
    doc.setTextColor('#000000');

    doc.text(
      `${
        issue.stateMachineInstance?.log.length
          ? moment(issue.stateMachineInstance.log[0].createdAt).format('YYYY/MM/DD HH:mm:ss')
          : ' - '
      }`,
      30,
      75,
    );
    doc.text(`${moment(issue.closedAt).format('YYYY/MM/DD HH:mm:ss')}`, 140, 75);

    doc.text(
      `${
        issue.stateMachineInstance.log.length
          ? (humanizeDuration(
              moment(issue.closedAt).diff(
                moment(
                  issue.stateMachineInstance.log.length ? issue.stateMachineInstance.log[0].createdAt : issue.closedAt,
                ),
              ),
              { round: true },
            ) as string)
          : ' - '
      }`,
      250,
      75,
    );

    if (issue.scoreInputs) {
      const auxX =
        200 +
        doc.getTextDimensions(
          humanizeDuration(
            moment(issue.closedAt).diff(
              moment(
                issue.stateMachineInstance.log.length ? issue.stateMachineInstance.log[0].createdAt : issue.closedAt,
              ),
            ),
            { round: true },
          ) as string,
        ).w;

      let totalScore = 0;
      let currentScore = 0;
      issue.taskGroups.map((taskGroup) =>
        taskGroup.tasks.map(
          (task) => ((totalScore += task.score.totalScore), (currentScore += task.score.currentScore)),
        ),
      );

      doc.setTextColor('#777777');
      doc.setFontSize(9);
      doc.text(`${t('score')}:`, auxX + 110, 65);

      doc.setFontSize(10);
      doc.setTextColor('#000000');
      doc.text(`${currentScore}/${totalScore} (${Math.round((currentScore / totalScore) * 100)}%)`, auxX + 110, 75);
    }

    let y = 100;

    // if (issue.note) {
    //   y = y + 10;
    //   doc.setFontSize(10);
    //   doc.setTextColor('#272848');
    //   doc.text(`${t('ObservationPendingTasks')}`, 20, y);
    //   doc.setFontSize(8);
    //   doc.setTextColor('#88899A');
    //   doc.text(`${issue.note}`, 20, y + 10);

    //   y = y + doc.getTextDimensions(issue.note).h + 20;
    // }

    doc.setTextColor('#0078FE');
    doc.setFontSize(14);
    doc.setFont('OpenSans', 'normal');
    doc.text(`${t('tasks')}`, width / 2, y, { align: 'center' });
    doc.setFont('OpenSans', 'normal');

    y = y + 10;

    const addInput = (input, actionsFromInput?: ActionsDictionary) => {
      if (y > doc.internal.pageSize.getHeight() - 100) {
        doc.addPage();
        y = 40;
      }
      doc.setFontSize(10);
      doc.setFont('OpenSans', 'normal');
      doc.setTextColor('#88899A');
      doc.text(input.name, 30, y, { maxWidth: width / 2 - 40 });
      if (actionsFromInput?.actions?.length) {
        const action = new Image();
        const nameWidth = doc.getTextDimensions(input.name).w;
        action.src = ActionImage;
        doc.addImage(action, 'png', nameWidth + 35, y - 5, 5, 5);
        doc.setTextColor('#FE8947');
        doc.setFont('OpenSans', 'normal');
        doc.setFontSize(9);
        doc.text(actionsFromInput.actions.length.toString(), nameWidth + 40, y);
      }
      let headers: string[] = [];
      const srcArray: ImagesOnNotes[] = [];
      const today = moment();
      const inputNameHeight = doc.getTextDimensions(input.name, { maxWidth: width / 2 - 40 }).h;
      let inputAnswerHeight = 0;

      switch (input.type) {
        case 'number':
          doc.setFontSize(10);
          doc.setTextColor('#272848');
          doc.text((input[input.type] as issueNumber).values.toString(), width / 2, y, {
            maxWidth: width / 2 - 40,
          });
          y = incrementY(y);
          break;
        case 'string':
          doc.setFontSize(10);
          doc.setTextColor('#272848');
          doc.text((input[input.type] as issueString).values.join(', '), width / 2, y, {
            maxWidth: width / 2 - 40,
          });
          inputAnswerHeight = doc.getTextDimensions((input[input.type] as issueString).values.join(', '), {
            maxWidth: width / 2 - 40,
          }).h;
          y = incrementY(y);
          break;
        case 'file':
        case 'instruction':
          doc.setFontSize(6);
          doc.setTextColor('#272848');
          let x = width / 2;
          (input[input.type] as issueInstruction).values.map((val) => {
            if (val.download.thumbnail?.url || val.download.url)
              srcArray.push({
                imageToRender: getImageForReport(val.download.thumbnail?.url || val.download.url),
                name: val.name,
                extension: val.extension,
              });
          });
          let addNewLine = false;
          srcArray.map((src) => {
            if (src.extension !== 'pdf') {
              doc.addImage(src.imageToRender, src.extension, x, y, 70, 70),
                doc.text(src.name, x, y + 80, doc.getTextDimensions(src.name).w > 90 ? { maxWidth: 80 } : null),
                (x = x + 90),
                x + 70 > width ? ((y = y + 90), (x = width / 2), (addNewLine = false)) : (addNewLine = true);
            }
          });
          /* y = y + 75; */
          addNewLine ? (y = y + 100) : null;
          y = incrementY(y);
          break;
        case 'table':
          (input[input.type] as issueTable).values.map((value, idx) => {
            headers.push(value.name);
            if (idx === 0) {
              value.rows.string.map((s: string) =>
                s !== null ? rows.push({ [value.name]: s }) : rows.push({ [value.name]: '' }),
              );
            } else {
              rows = rows.map((s, i) => ({
                ...s,
                [value.name]:
                  value.rows.string[parseInt(i.toString())] !== null ? value.rows.string[parseInt(i.toString())] : '',
              }));
            }
          });

          doc.table(width / 2, y, rows, headers, { autoSize: true });
          y = y + rows.length * 25;
          rows = [];
          headers = [];
          break;
        case 'date':
          doc.setFontSize(10);
          doc.setTextColor('#272848');
          if (input[input.type].values?.length)
            doc.text(`${moment((input[input.type] as issueDate).values[0]).format('YYYY/MM/DD')}`, width / 2, y, {
              maxWidth: width / 2 - 40,
            });
          y = incrementY(y);
          break;
        case 'time':
          doc.setFontSize(10);
          doc.setTextColor('#272848');
          if (input[input.type].values?.length)
            doc.text(
              `${moment({
                year: today.get('year'),
                month: today.get('month'),
                day: today.get('day'),
                hours: parseInt(input[input.type].values[0].split(':')[0]),
                minutes: parseInt(input[input.type].values[0].split(':')[1]),
              }).format('HH:mm')}`,
              width / 2,
              y,
              {
                maxWidth: width / 2 - 40,
              },
            );
          y = incrementY(y);
          break;
        case 'datetime':
          doc.setFontSize(10);
          doc.setTextColor('#272848');
          if (input[input.type].values?.length)
            doc.text(
              `${moment((input[input.type] as issueDate).values[0]).format('YYYY/MM/DD HH:mm:ss')}`,
              width / 2,
              y,
              {
                maxWidth: width / 2 - 40,
              },
            );
          y = incrementY(y);
          break;
        case 'signature':
          doc.setFontSize(10);
          doc.setTextColor('#272848');
          (input[input.type] as issueSignature).values.map((val) => {
            doc.text(val.name ? val.name : '-', width / 2, y, { maxWidth: width / 2 - 40 });
            if (val.image.download.thumbnail?.url || val.image.download.url)
              srcArray.push({
                imageToRender: getImageForReport(val.image.download.thumbnail?.url || val.image.download.url),
                name: val.image.name,
                extension: val.image.extension,
              });
          });
          srcArray.map((src) => {
            doc.addImage(src.imageToRender, src.extension, width / 2, y, 40, 40);
          });
          /* (input[input.type] as issueSignature).values.map(
            (value) => (
              doc.text(value.name ? value.name : '-', width / 2, y, { maxWidth: width / 2 - 40 }),
              (objectToRender = signatureSrcArray
                ? signatureSrcArray.find((image) => image.id === value.image._id)
                : null),
              objectToRender
                ? ((image = getImageForReport(objectToRender.src)),
                  doc.addImage(image, objectToRender.extension, width / 2, y, 40, 40))
                : null
            ),
          );
          y = y + 45; */
          y = y + 45;
          break;
        case 'site':
          doc.setFontSize(10);
          doc.setTextColor('#272848');
          (input[input.type] as issueSiteElement).values.map(
            (value) => (
              doc.text(value.name ? value.name : '-', width / 2, y, { maxWidth: width / 2 - 40 }), (y = incrementY(y))
            ),
          );
          y = incrementY(y);
          break;
        case 'element':
          doc.setFontSize(10);
          doc.setTextColor('#272848');
          (input[input.type] as issueSiteElement).values.map(
            (value) => (
              doc.text(value.name ? value.name : '-', width / 2, y, { maxWidth: width / 2 - 40 }), (y = incrementY(y))
            ),
          );
          y = incrementY(y);
          break;
        default:
          break;
      }

      y += inputNameHeight > inputAnswerHeight ? inputNameHeight : inputAnswerHeight;
      /* y = incrementY(name); */
      doc.setDrawColor(212, 211, 212);
      doc.line(20, y, width - 20, y);

      y = incrementY(y);
      const actionLineHeight = 15;
      const actionsHeight = actionLineHeight * actionsFromInput?.actions?.length + 15;

      if (actionsFromInput?.actions?.length) {
        doc.setFillColor('#F5E8E7');
        doc.rect(20, y - 15, width - 40, actionsHeight, 'F');
        doc.setTextColor('#88899A');
        doc.text(`${t('actions')}`, 30, y, { maxWidth: 30 / 2 - 40 });

        const actionTitleWidth = doc.getTextDimensions(`${t('actions')}`).w;
        actionsFromInput.actions.map((action) => {
          const statusWidth = doc.getTextDimensions(`${action.status}`).w;
          doc.setFillColor(getActionColor(action.status));
          doc.roundedRect(actionTitleWidth + 55, y - 7.5, statusWidth + 10, 10, 5, 5, 'F');
          doc.setFont(undefined, 'bold');
          doc.setTextColor(action.status === 'PENDING' ? '#272848' : '#FFFFFF');
          doc.text(`${action.status}`, actionTitleWidth + 60, y);
          doc.setTextColor('#272848');
          doc.text(`#${action.ID}`, statusWidth + actionTitleWidth + 70, y);
          doc.setFont(undefined, 'normal');
          const idWidth = doc.getTextDimensions(`#${action.ID}`).w;
          doc.text(`${action.name}`, idWidth + statusWidth + actionTitleWidth + 75, y);
          y = y + 15;
        });
        if (!input.note) {
          doc.setDrawColor(212, 211, 212);
          doc.line(20, y, width - 20, y);
          y += 15;
        }
      }

      if (input.note) {
        y = incrementY(y);
        doc.setTextColor('#88899A');
        let x = width / 2;
        doc.text(`${t('files&notes')}`, x, y, { maxWidth: width / 2 - 40 });
        x = x + 55;
        if (input.note.text) {
          doc.setTextColor('#272848');
          const splitedText = doc.splitTextToSize(`${input.note.text}`, width / 2 - 80);
          doc.text(splitedText, x, y);
          y = incrementY(y + doc.getTextDimensions(splitedText).h);
        }
        if (input.note.files) {
          const imagesArray: ImagesOnNotes[] = [];
          let addNewLine = false;
          input.note.files.map((file) =>
            file?.download?.thumbnail?.url
              ? imagesArray.push({
                  imageToRender: getImageForReport(file.download.thumbnail.url),
                  name: file.name,
                  extension: file.extension,
                })
              : null,
          );
          imagesArray.map(
            (image) => (
              doc.addImage(image.imageToRender, image.extension, x, y, 50, 50),
              doc.setFontSize(6),
              doc.setTextColor('#272848'),
              doc.text(image.name, x, y + 60, doc.getTextDimensions(image.name).w > 80 ? { maxWidth: 80 } : null),
              (x = x + 70),
              x + 70 > width ? ((y = y + 75), (x = width / 2 + 55), (addNewLine = false)) : (addNewLine = true)
            ),
          );
          //add some space to prevent overlap of information
          addNewLine ? (y = y + 75) : null;
        }

        doc.setDrawColor(212, 211, 212);
        doc.line(width / 2 - 20, y - 5, width - 20, y - 5);
        y += 15;
      }
    };

    const addInputRecursively = (input, actionsFromInput) => {
      addInput(
        input,
        actionsFromInput.find((i) => input._id === i.inputId),
      );
      if (input[input.type].onResponse?.length) {
        input[input.type].onResponse.map((response) =>
          input[input.type].values.includes(response.ifValues[0])
            ? response.inputs.map((inp) => {
                addInputRecursively(inp, actionsFromInput);
              })
            : null,
        );
      }
    };

    issue.taskGroups
      .filter((group) => group.tasks.length)
      .sort((x, y) => x.order - y.order)
      .forEach((group) => {
        try {
          y = incrementY(y);
          doc.setFontSize(12);
          doc.setFillColor('#F4F5F9');
          doc.roundedRect(20, y - 10, width - 40, 15, 5, 5, 'F');
          doc.setTextColor('#0078FE');
          doc.text(`${group.name}`, width / 2, y, { align: 'center' });
          group.tasks
            .sort((x, y) => x.order - y.order)
            .forEach((task) => {
              try {
                y = incrementY(y);

                // title area
                if (y > doc.internal.pageSize.getHeight() - 100) {
                  doc.addPage();
                  y = 40;
                }

                doc.setFillColor('#F4F5F9');
                doc.roundedRect(20, y, width - 40, 15, 5, 5, 'F');
                doc.setFontSize(10);
                doc.setFont('OpenSans', 'normal');
                doc.setTextColor('#0078FE');
                y = y + doc.getTextDimensions(task.name).h + 3;
                doc.text(`${task.name}`, 30, y, { maxWidth: width - 60 });
                doc.setFontSize(10);
                doc.setFont('OpenSans', 'normal');
                doc.setTextColor('#272848');
                if (issue.scoreInputs && task.score.totalScore > 0) {
                  doc.text(
                    `${t('taskScore')}: ${task.score.currentScore}/${task.score.totalScore} (${Math.round(
                      (task.score.currentScore / task.score.totalScore) * 100,
                    )}%)`,
                    width - 110,
                    y,
                  );
                }
                const checkGroup = new Image();
                checkGroup.src = RoundCheckOrange;

                doc.addImage(checkGroup, 'png', width - 88, y - 5, 5, 5);
                doc.setFontSize(6);
                doc.setFont('OpenSans', 'bold');
                doc.setTextColor('#FE8947');
                doc.text(
                  `${t('completed')} ${
                    task.executionInputs
                      .sort((x, y) => x.order - y.order)
                      .filter(
                        (inp) =>
                          inp.type !== 'instruction' &&
                          ((inp.type !== 'table' && !!inp[inp.type].values?.length) ||
                            (inp.type === 'table' &&
                              !!(inp[inp.type] as issueTable).values.filter(
                                (c) =>
                                  (c.rows[c.type] as string[]).length &&
                                  (c.rows[c.type] as string[]).filter((r) => r?.length && !!r[0]).length ===
                                    (c.rows[c.type] as string[]).length,
                              ).length)),
                      ).length
                  }/${task.executionInputs.filter((i) => i.type !== 'instruction').length}`,
                  width - 80,
                  y,
                );
                y = y + 20;
                // end of title area

                // y = incrementY(y + doc.getTextDimensions(task.name).h);
                task.executionInputs.map((input) => {
                  try {
                    addInputRecursively(input, actionsFromInput);
                  } catch {
                    (e) => console.error(e);
                  }
                });
              } catch {
                (e) => console.error(e);
              }
            });
        } catch {
          (e) => console.error(e);
        }
      });

    addAtatchements(doc, issue, t);

    addHeaders(doc, tenantInfo);
    addFooters(doc, t);
    doc.save(`${issue.name}.pdf`);
  } catch {
    (e) => console.error(e);
  }
};
