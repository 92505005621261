import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    cardContent: {
      height: 'calc(100vh - 55px)',
      maxHeight: 'calc(100vh - 55px)',
      width: '100%',
      padding: '0 !important',
      display: 'flex',
      flexDirection: 'column',
    },
    topBar: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '8px 24px',
      gap: 8,
    },
    body: {
      backgroundColor: theme.palette.background.background1,
      width: '100%',
      display: 'flex',
      overflowY: 'scroll',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      justifyContent: 'flex-start',
    },
    marginLeft: {
      marginLeft: 'auto',
    },
    input: {
      padding: 5,
    },
    border: {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '4px',
      padding: '0px 4px',
    },
  });
