import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    descriptionText: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.secondary,
      marginLeft: '18px',
      cursor: 'pointer',
    },
    descriptionNoteLimit: {
      color: theme.palette.text.secondary,
      fontFamily: 'Poppins',
      /* fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px', */
      /* lineHeight: '22px', */
      alignItems: 'center',
      display: '-webkit-box',
      maxWidth: '100%',
      WebkitLineClamp: 8,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      cursor: 'pointer',
    },
  });
