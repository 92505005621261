import i18n from 'src/utils/translations/i18n';
import Swal from 'sweetalert2';

export function PostDoesNotExistModal() {
  return Swal.fire({
    title: i18n.t('postHasBeenDeleted'),
    text: i18n.t('postYouWereCommentingDeleted'),
    icon: null,
    confirmButtonText: i18n.t('ok'),
  });
}
