import { MutableRefObject, SetStateAction, useEffect } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader.js';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';
import moveObjectToCenter from '../utils/threeUtils';
import { ThreeData } from '..';

interface ICreate3DModelHookArgs {
  threeRef: MutableRefObject<ThreeData>;
  containerRef: MutableRefObject<HTMLDivElement>;
  modelUrl: string;
  fullscreen: boolean;
  refresh: () => void;
  setLoadPercent: (value: SetStateAction<number>) => void;
  setError: (value: SetStateAction<boolean>) => void;
  onLoadPercentage?: (percent: number) => void;
}

const useCreate3DModel = (args: ICreate3DModelHookArgs) => {
  const { threeRef, modelUrl, fullscreen, containerRef, refresh, setLoadPercent, setError, onLoadPercentage } = args;
  useEffect(() => {
    const renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true, alpha: true });
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    const lightStrength = 0.4;
    renderer.toneMappingExposure = lightStrength;
    renderer.outputColorSpace = THREE.SRGBColorSpace;
    renderer.setClearColor(0x000000, 0); //To enable transparency

    const aspectRatio = 1;
    const fov = 45;
    const camera = new THREE.PerspectiveCamera(fov, aspectRatio, 1, 20000);
    camera.position.set(-200, 100, 400);

    const scene = new THREE.Scene();

    const environment = new RoomEnvironment();
    const pmremGenerator = new THREE.PMREMGenerator(renderer);
    scene.background = null; //new THREE.Color(0xe8e8e8); //null for transparent
    scene.environment = pmremGenerator.fromScene(environment).texture;

    const ktx2Loader = new KTX2Loader().setTranscoderPath('js/libs/basis/').detectSupport(renderer);

    const loader = new GLTFLoader();
    loader.setKTX2Loader(ktx2Loader);
    loader.setMeshoptDecoder(MeshoptDecoder);
    loader.load(
      modelUrl, //Url
      function (gltf) {
        //Load Data
        const mesh = gltf.scene;
        const boundingBox = new THREE.Box3();
        boundingBox.setFromObject(mesh);

        const treshhold = 1;

        const vector = new THREE.Vector3();
        const height = boundingBox.getSize(vector).y * treshhold;
        const width = (boundingBox.getSize(vector).x / 1.5) * treshhold;
        const length = (boundingBox.getSize(vector).z / 1.5) * treshhold;

        let maxDimension;
        if (height > width && height > length) {
          maxDimension = height;
        } else if (width > length) {
          maxDimension = width;
        } else {
          maxDimension = length;
        }

        const requiredMaxDimension = 200;
        const requiredScale = requiredMaxDimension / maxDimension;

        mesh.scale.setScalar(requiredScale);

        const boundingBox2 = new THREE.Box3();
        boundingBox2.setFromObject(mesh);

        const centeredObject = moveObjectToCenter(mesh, true);
        centeredObject.position.y = 8;
        threeRef.current.object3d = centeredObject;
        scene.add(centeredObject);

        refresh();
      },
      function (event: ProgressEvent<EventTarget>) {
        //Load progress
        const total = (event.loaded / event.total) * 100;
        const percent = Math.round(total);
        setLoadPercent(percent);
        onLoadPercentage?.(percent);
        setError(false);
      },
      function (error) {
        //Load error
        setError(true);
        console.error('Error loading GLTF model:', error);
      },
    );

    containerRef.current.appendChild(renderer.domElement);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.addEventListener('change', refresh);
    window.addEventListener('resize', refresh);
    controls.minDistance = 10;
    controls.maxDistance = 1000;
    controls.target.set(10, 90, -16);
    controls.update();

    threeRef.current = {
      ...threeRef.current,
      camera,
      scene,
      renderer,
    };

    refresh();

    const canvas = containerRef.current?.firstChild as HTMLDivElement;
    if (canvas) {
      canvas.style.borderRadius = '8px';
    }

    // Cleanup
    return () => {
      renderer.dispose();
      containerRef.current?.removeChild(renderer.domElement);
      setLoadPercent(0);
    };
  }, [modelUrl, fullscreen]);
};

export default useCreate3DModel;
