import { APOLLO_CLIENT } from 'config/apollo.config';
import {
  CommentFragmentFragment,
  CreatePostDocument,
  DeletePostDocument,
  PostCreateInput,
  PostFragmentFragment,
  PostUpdateInput,
  SubscribeDeletedCommentsDocument,
  SubscribeNewCommentsDocument,
  UpdatePostDocument,
} from 'src/gql/graphql';
import { withGraphQLErrors } from 'src/utils/funcs';
import { orderBy, unionBy } from 'lodash';
import { FeedFilters } from 'src/utils/components/glar-filter-popover/preset-filters/feed-filter';
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';

type LoadingState = 'more' | 'reset' | null;

export type CommentType = CommentFragmentFragment;
export type PostType = PostFragmentFragment;

export interface IFeedFilter extends FeedFilters {
  id?: string;
  commentId?: string;
  search?: string;
}
interface IFeedState {
  postsCount: number;
  posts: PostType[];
  hiddenPosts: string[];
  comments: CommentType[];
  filters: IFeedFilter;
  loadingPosts: LoadingState;
}

const initialState = (): IFeedState => ({
  postsCount: 0,
  posts: [],
  hiddenPosts: [],
  comments: [],
  filters: {},
  loadingPosts: null,
});

const feedSlice = createSlice({
  name: 'feed',
  initialState: initialState(),
  reducers: {
    changeFeedFilters(state, action: PayloadAction<IFeedFilter>) {
      state.filters = action.payload;
    },
    setLoadingPosts(state, action: PayloadAction<LoadingState>) {
      state.loadingPosts = action.payload;
    },
    resetFeed() {
      return initialState();
    },
    closePostComments(state, action: PayloadAction<string>) {
      state.comments = state.comments.filter((c) => c.postId != action.payload);
    },
    closeSubComments(state, action: PayloadAction<string>) {
      state.comments = state.comments.filter((c) => !c.parentsTreeIds.includes(action.payload));
    },
    updatePostsState(state, action: PayloadAction<{ posts: PostType[]; reset?: boolean; postsCount?: number }>) {
      state.postsCount = action.payload.postsCount ?? state.postsCount;

      state.posts = orderBy<PostType>(
        action.payload.reset ? action.payload.posts : unionBy(action.payload.posts, state.posts, '_id'),
        'createdAt',
        'desc',
      );
    },
    updateCommentsState(state, action: PayloadAction<CommentType[]>) {
      if (!action.payload.length) {
        return;
      }

      state.comments = unionBy(action.payload, state.comments, '_id');
    },
    deletePostState(state, action: PayloadAction<string>) {
      state.comments = state.comments.filter((c) => c.postId != action.payload);
      state.posts = state.posts.filter((p) => p._id !== action.payload);
    },
    softDeletePostState(state, action: PayloadAction<string>) {
      state.hiddenPosts.push(action.payload);
    },
    undoSoftDeletePostState(state, action: PayloadAction<string>) {
      state.hiddenPosts = state.hiddenPosts.filter((p) => p !== action.payload);
    },
    deleteCommentState(state, action: PayloadAction<string>) {
      const comment = state.comments.find((c) => c._id === action.payload);

      if (!comment) {
        return;
      }

      const children = state.comments.filter((c) => c.parentsTreeIds.includes(comment._id)).map((f) => f._id);

      const allDeletedcomments = [...children, action.payload];

      state.comments = state.comments.filter((c) => !allDeletedcomments.includes(c._id));
    },
  },
});

export const {
  deleteCommentState,
  deletePostState,
  updateCommentsState,
  updatePostsState,
  closePostComments,
  closeSubComments,
  resetFeed,
  setLoadingPosts,
  changeFeedFilters,
  softDeletePostState,
  undoSoftDeletePostState,
} = feedSlice.actions;

export default feedSlice.reducer;

export const createFeedPost = (data: PostCreateInput) =>
  withGraphQLErrors<PostType>(async () => {
    const response = await APOLLO_CLIENT.query({
      variables: {
        data,
      },
      fetchPolicy: 'no-cache',
      query: CreatePostDocument,
    });

    return response.data.createPost;
  });

export const updateFeedPost = (id: string, data: PostUpdateInput) =>
  withGraphQLErrors<PostType>(async () => {
    const response = await APOLLO_CLIENT.query({
      variables: {
        id,
        data,
      },
      fetchPolicy: 'no-cache',
      query: UpdatePostDocument,
    });
    return response.data.updatePost;
  });

export const deleteFeedPost = (id: string) =>
  withGraphQLErrors<void>(
    async () =>
      await APOLLO_CLIENT.query({
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
        query: DeletePostDocument,
      }),
  );

export const subscribeNewComments = (postIds: string[], parentIds: string[]) => (dispatch: Dispatch) =>
  APOLLO_CLIENT.subscribe({ query: SubscribeNewCommentsDocument, variables: { postIds, parentIds } }).subscribe({
    next(res) {
      if (res.data?.commentCreatedV2) {
        dispatch(updateCommentsState([res.data.commentCreatedV2]));
      }
    },
  });

export const subscribeDeletedComments = (comments: string[]) => (dispatch: Dispatch) =>
  APOLLO_CLIENT.subscribe({ query: SubscribeDeletedCommentsDocument, variables: { where: comments } }).subscribe({
    next(res) {
      if (res.data?.commentDeleted) {
        dispatch(deleteCommentState(res.data.commentDeleted));
      }
    },
  });
