import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { styles } from 'src/base/main-container/styles/main-container';
import { render as Routes } from 'src/base/routes';
import Header from 'src/base/header/index';
import Navbar from 'src/base/navbar';
import { NexusGenFieldTypes } from '../../../../server/src/types';
import { createIssueTemplate } from 'src/modules/issue-templates/issue.templates.redux';
import { getIssueTemplateFolders } from 'src/modules/issue-templates/issue.templates.redux';
import { updateLoggedUser } from '../login/login.redux';
import makeStyles from '@mui/styles/makeStyles';
import { useMainContainerContext } from './context/main.container.context';
import OnBoarding from '../on-boarding';
import IntroductionModal from '../on-boarding/components/introduction.modal';
import useUpdateState from './hooks/useUpdateState';
import DomainExpiredModal from './components/domain.expired.modal';

export type MainContainerProps = {
  currentSite?: NexusGenFieldTypes['Site'];
} & ConnectedProps<typeof connector>;

const useStyles = makeStyles(styles);
const MainContainer: React.FC<MainContainerProps> = (props): JSX.Element => {
  const state = useMainContainerContext();
  const { updateMainContainerState } = state;
  const classes = useStyles();
  useUpdateState(props, updateMainContainerState);

  return (
    <div className={`app`}>
      {!props.loggedUser || window.location.hash.includes('/history/issue-report/') ? (
        <>
          {/* @ts-ignore */}
          <Routes.view.default {...props} />
        </>
      ) : (
        <>
          {/* @ts-ignore */}
          <Header {...props} title={`GLARTEK`} />
          <div id='body' className={`app-body ${classes.container}`}>
            {!state.doingOnBoarding && !state.fullpage && <Navbar />}
            <IntroductionModal />
            {state.doingOnBoarding && <OnBoarding />}
            <main
              id='main-container'
              className={`main ${classes.main}`}
              style={{
                paddingTop: !(
                  (state.doingOnBoarding && state.fullpage) ||
                  (!state.doingOnBoarding && !state.fullpage) ||
                  props.room
                )
                  ? '0'
                  : state.doingOnBoarding && state.hideSteps
                    ? '92px'
                    : state.doingOnBoarding && !state.hideSteps
                      ? '144px'
                      : '55px',
                paddingLeft:
                  state.doingOnBoarding || state.fullpage
                    ? 0
                    : props.sidenavMinimized && (props.currentSite || props.loggedUser?.isPlatformAdmin)
                      ? '60px'
                      : !props.currentSite && !props.loggedUser?.isPlatformAdmin
                        ? 0
                        : '270px',
              }}
            >
              {/* @ts-ignore */}
              <Routes.view.default {...props} />
            </main>
            <DomainExpiredModal />
          </div>
        </>
      )}
    </div>
  );
};

const connector = connect(
  (state) => ({
    snackbarMessages: state.rootReducer.snackbarMessages,
    sidenavMinimized: state.rootReducer.sidenavMinimized,
    loggedUser: state.loginReducer.loggedUser,
    room: state.roomReducer.room,
  }),
  { updateLoggedUser, createIssueTemplate, getIssueTemplateFolders },
);

export default connector(MainContainer);
