import Swal, { SweetAlertIcon } from 'sweetalert2';
import i18n from 'src/utils/translations/i18n';
import { css } from '@emotion/css';

interface DeleteModal {
  title?: any;
  text?: any;
  type?: SweetAlertIcon;
  showCancelButton?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  success: (prop?: any) => void;
  dismiss?: () => void;
}

export const DeleteModal = (props: DeleteModal) => {
  const { title, text, type, showCancelButton, confirmButtonText, cancelButtonText, success, dismiss } = props;

  Swal.fire({
    title,
    text,
    icon: type || 'warning',
    reverseButtons: true,
    showCancelButton: showCancelButton ? showCancelButton : true,
    cancelButtonText: cancelButtonText ? cancelButtonText : i18n.t('deleteLabelCancelButton'),
    confirmButtonText: confirmButtonText ? confirmButtonText : i18n.t('deleteLabelConfirmButton'),
    customClass: {
      container: css({
        zIndex: 2000,
      }),
    },
  })
    .then((result) => {
      if (result.value) {
        success(result);
      } else if (dismiss) dismiss();
    })
    .catch((err) => console.error('DeleteModal: ', err));
};
