import { IssueCatalog, TaskTemplateGroup, InputNumber } from 'src/gql/graphql';
import { ISelectListItem } from '../components/select-list-item';

export const issueCatalogToSelectListItem = (
  issueCatalog: IssueCatalog,
  filterTaskVariants: boolean = false,
  selectedInputForResponse?: ISelectListItem,
): ISelectListItem[] => {
  let items = [];
  if (!selectedInputForResponse) {
    if (issueCatalog?.template?.taskTemplateGroups?.length > 1) {
      items = issueCatalog.template.taskTemplateGroups
        .sort((a, b) => a.order - b.order)
        .map((taskTemplateGroup) => {
          return {
            _id: taskTemplateGroup._id,
            name: taskTemplateGroup.name,
            childrens: taskTemplateGroupToSelectListItem(taskTemplateGroup, filterTaskVariants),
            isFinal: false,
          };
        });
    } else {
      items = taskTemplateGroupToSelectListItem(issueCatalog?.template?.taskTemplateGroups[0], filterTaskVariants);
    }
  } else {
    items = responseToSelectListItem(
      issueCatalog?.template?.taskTemplateGroups,
      filterTaskVariants,
      selectedInputForResponse,
    );
  }
  return items;
};

const taskTemplateGroupToSelectListItem = (
  taskTemplateGroup: TaskTemplateGroup,
  filterTaskVariants: boolean,
): ISelectListItem[] => {
  return taskTemplateGroup?.taskTemplates
    ?.filter((t) => {
      return filterTaskVariants ? t?.variant?.position === 0 : true;
    })
    ?.sort((a, b) => a.order - b.order)
    ?.map((taskTemplate) => {
      return {
        _id: taskTemplate._id,
        name: taskTemplate.name,
        childrens: taskTemplate?.inputs
          ?.sort((a, b) => a.order - b.order)
          .map((inp) => {
            return {
              _id: inp._id,
              name: inp.name,
              childrens: [],
              isFinal: true,
              type:
                inp.type !== 'string'
                  ? inp.type.toString()
                  : inp[inp.type]?.multiple
                    ? 'multiple'
                    : inp[inp.type]?.options?.length
                      ? 'selection'
                      : inp.type.toString(),
              unit: (inp[inp.type] as InputNumber)?.unit,
            };
          }),
        isFinal: false,
      };
    });
};

const responseToSelectListItem = (
  taskTemplateGroups: TaskTemplateGroup[],
  filterTaskVariants: boolean,
  selectedInputForResponse: ISelectListItem,
) => {
  return taskTemplateGroups?.flatMap((taskTemplateGroup) =>
    taskTemplateGroup?.taskTemplates
      ?.filter((t) => {
        return filterTaskVariants ? t?.variant?.position === 0 : true;
      })
      ?.sort((a, b) => a.order - b.order)
      ?.flatMap((taskTemplate) => {
        return taskTemplate?.inputs
          ?.filter((inp) => inp._id === selectedInputForResponse?._id)
          ?.sort((a, b) => a.order - b.order)
          .flatMap((inp) => {
            //@ts-ignore
            return inp[inp.type]?.options?.map((option) => {
              return {
                _id: inp._id + option,
                name: option,
                childrens: [],
                isFinal: true,
                type: 'response',
                isSelected: true,
              };
            });
          });
      }),
  );
};

export const getDefaultItem = (issueCatalog: IssueCatalog, inputId: string): ISelectListItem => {
  for (const groups of issueCatalog?.template?.taskTemplateGroups || []) {
    for (const task of groups?.taskTemplates || []) {
      for (const inp of task?.inputs || []) {
        if (inp?._id === inputId) {
          return {
            _id: inp._id,
            name: inp.name,
            childrens: [],
            isFinal: true,
            type:
              inp.type !== 'string'
                ? inp.type.toString()
                : inp[inp.type]?.multiple
                  ? 'multiple'
                  : inp[inp.type]?.options?.length
                    ? 'selection'
                    : inp.type.toString(),
            unit: (inp[inp.type] as InputNumber)?.unit,
          };
        }
      }
    }
  }
  return undefined;
};
