import { useTheme } from '@mui/styles';
import { FormControlLabel, Radio } from '@mui/material';
import { useDrag, useDrop } from 'react-dnd';
import { useRef } from 'react';
import { DragIndicator } from '@mui/icons-material';

interface Item {
  order: number;
  _id: string;
  id: number;
  color: string;
  label: string;
}

interface RadioComponentProps {
  editableOptions: boolean;
  disabled: boolean;
  key: string;
  classes: Record<string, string>;
  moveOption: (draggedId: string, order: number) => void;
  inputsLabel: (lbl: Item, key: number) => JSX.Element;
  item: Item;
  inputIncludedInPresets: boolean;
  required?: boolean;
  handleChange?: (e) => void;
  values?: unknown[];
  index?: number;
  helperText?: JSX.Element;
  dropOption: () => void;
}

const RadioComponent = (props: RadioComponentProps) => {
  const {
    item,
    disabled,
    inputsLabel,
    moveOption,
    editableOptions,
    required,
    handleChange,
    values,
    index,
    inputIncludedInPresets,
  } = props;
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const refHandle = useRef<HTMLDivElement>(null);
  const [{ isDragging }, connectDrag, connectPreview] = useDrag(
    () => ({
      // "type" is required. It is used by the "accept" specification of drop targets.
      type: 'OPTION',
      canDrag: disabled,
      item: { item },
      // The collect function utilizes a "monitor" instance (see the Overview for what this is)
      // to pull important pieces of state from the DnD system.
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end() {
        props.dropOption();
      },
    }),
    [disabled],
  );

  const [, connectDrop] = useDrop({
    accept: 'OPTION',
    hover({ item: draggedItem }: { item: Item }) {
      if (draggedItem._id !== item._id) {
        moveOption(draggedItem._id, item.order);
      }
    },
  });
  connectDrag(refHandle);
  connectDrop(ref);
  connectPreview(ref);

  const handleClick = (value) => {
    if (!required) {
      values.find((val) => val === value) ? handleChange([]) : null;
    }
  };
  return (
    <>
      <FormControlLabel
        key={item._id}
        style={
          isDragging
            ? {
                contentVisibility: 'hidden',
                backgroundColor: theme.palette.text.disabled,
                minHeight: '42px',
                borderRadius: '4px',
                marginLeft: '10px',
                width: '-webkit-fill-available',
              }
            : { display: 'flex', alignItems: 'center' }
        }
        value={item.id}
        ref={ref}
        classes={{
          root: props.classes.formControl2,
          label: props.classes.formControlLabel2,
        }}
        disabled={disabled}
        id={`radio-option-${item.label ?? 'noName'}`}
        data-testid={`radio-option-${item.label ?? 'noName'}`}
        onClick={() => handleClick(item.id)}
        control={
          <div style={{ display: 'flex', alignItems: 'center' }} key={item._id}>
            {editableOptions && !inputIncludedInPresets ? (
              <div
                key={item._id}
                ref={refHandle}
                style={{ display: 'flex', marginLeft: '10px', marginRight: '-10px', width: '23px' }}
              >
                <DragIndicator
                  id={'div-buttons-actions'}
                  style={{ color: theme.palette.text.disabled, display: 'none' }}
                />
              </div>
            ) : undefined}
            <Radio
              disabled={disabled}
              id={`radio-button-${item.label}`}
              data-testid={`radio-button-${item.label}`}
              value={item.id}
              style={{
                color: disabled
                  ? theme.palette.grey.light
                  : item.color
                    ? item.color
                    : values.includes(item.id)
                      ? theme.palette.primary.main
                      : theme.palette.text.secondary,
                width: '32px',
                height: '32px',
              }}
            />
          </div>
        }
        label={inputsLabel(item, index)}
      />
      {props?.helperText && props.values.includes(item.id) && (
        <div style={{ marginBottom: '8px' }}>{props.helperText}</div>
      )}
    </>
  );
};

export default RadioComponent;
