import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { changeDefaultTenant } from './tenant.redux';

export function withDefaultTenant(Component: React.FC) {
  return (props) => {
    const { tenant } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(changeDefaultTenant(tenant));
    }, [tenant]);

    return <Component {...props} />;
  };
}
