interface DocumentIconProps {
  color: string;
  width: number;
  height: number;
  extension: string;
}

const DocumentIcon = (props: DocumentIconProps) => {
  switch (props.extension) {
    case 'pdf':
      return (
        <svg width={props.width} height={props.height} fill='none' viewBox='0 0 20 24'>
          <g opacity='0.3'>
            <path
              opacity='0.3'
              d='M13.9664 4.9248C13.4197 4.92427 12.8956 4.70685 12.509 4.32028C12.1224 3.93371 11.905 3.40956 11.9045 2.86287V0H2.69089C1.97722 0 1.29278 0.283511 0.788145 0.788151C0.283505 1.29279 0 1.97721 0 2.69088V18.9122C0.00105979 19.6252 0.28503 20.3086 0.789555 20.8124C1.29408 21.3161 1.97791 21.5991 2.69089 21.5991H13.7514C14.4644 21.5991 15.1482 21.3161 15.6528 20.8124C16.1573 20.3086 16.4413 19.6252 16.4423 18.9122V4.9248H13.9664Z'
              fill={props.color}
            />
          </g>
          <path
            d='M16.4421 4.9248H13.9662C13.4195 4.92427 12.8954 4.70685 12.5088 4.32028C12.1222 3.93371 11.9048 3.40956 11.9043 2.86287V0L16.4421 4.9248Z'
            fill={props.color}
          />
          <path
            d='M13.0594 15.0834H2.73582C2.63637 15.0834 2.54099 15.0439 2.47067 14.9736C2.40034 14.9033 2.36084 14.8079 2.36084 14.7085C2.3611 14.6092 2.40073 14.514 2.47102 14.4439C2.54132 14.3738 2.63655 14.3345 2.73582 14.3345H13.0594C13.1586 14.3345 13.2537 14.3739 13.3238 14.444C13.394 14.5141 13.4334 14.6093 13.4334 14.7085C13.4335 14.7577 13.4239 14.8064 13.4052 14.8519C13.3865 14.8974 13.3589 14.9387 13.3242 14.9735C13.2895 15.0084 13.2482 15.036 13.2027 15.0549C13.1573 15.0737 13.1086 15.0834 13.0594 15.0834Z'
            fill={props.color}
          />
          <path
            d='M13.0594 10.8596H2.73582C2.63655 10.8596 2.54132 10.8202 2.47102 10.7501C2.40073 10.68 2.3611 10.5849 2.36084 10.4856C2.36084 10.3861 2.40034 10.2907 2.47067 10.2204C2.54099 10.1501 2.63637 10.1106 2.73582 10.1106H13.0594C13.1086 10.1106 13.1573 10.1203 13.2027 10.1392C13.2482 10.158 13.2895 10.1856 13.3242 10.2205C13.3589 10.2553 13.3865 10.2967 13.4052 10.3422C13.4239 10.3876 13.4335 10.4364 13.4334 10.4856C13.4334 10.5848 13.394 10.6799 13.3238 10.75C13.2537 10.8202 13.1586 10.8596 13.0594 10.8596Z'
            fill={props.color}
          />
          <path
            d='M13.0594 12.9715H2.73582C2.63637 12.9715 2.54099 12.932 2.47067 12.8617C2.40034 12.7913 2.36084 12.696 2.36084 12.5965C2.3611 12.4972 2.40073 12.4021 2.47102 12.332C2.54132 12.2619 2.63655 12.2225 2.73582 12.2225H13.0594C13.1586 12.2225 13.2537 12.2619 13.3238 12.3321C13.394 12.4022 13.4334 12.4973 13.4334 12.5965C13.4335 12.6457 13.4239 12.6945 13.4052 12.7399C13.3865 12.7854 13.3589 12.8268 13.3242 12.8616C13.2895 12.8965 13.2482 12.9241 13.2027 12.9429C13.1573 12.9618 13.1086 12.9715 13.0594 12.9715Z'
            fill={props.color}
          />
          <path
            d='M8.98757 8.74763H2.73582C2.63655 8.74763 2.54132 8.70826 2.47102 8.63816C2.40073 8.56805 2.3611 8.47292 2.36084 8.37364C2.36084 8.27419 2.40034 8.17881 2.47067 8.10849C2.54099 8.03817 2.63637 7.99866 2.73582 7.99866H8.98757C9.08684 7.99892 9.18197 8.03854 9.25208 8.10883C9.32218 8.17913 9.36155 8.27436 9.36155 8.37364C9.36129 8.47275 9.3218 8.56771 9.25172 8.63779C9.18164 8.70787 9.08667 8.74736 8.98757 8.74763Z'
            fill={props.color}
          />
          <path
            d='M8.98757 6.63569H2.73582C2.63655 6.63569 2.54132 6.5963 2.47102 6.52619C2.40073 6.45609 2.3611 6.36098 2.36084 6.2617C2.36084 6.16225 2.40034 6.06685 2.47067 5.99653C2.54099 5.92621 2.63637 5.88672 2.73582 5.88672H8.98757C9.08684 5.88698 9.18197 5.9266 9.25208 5.99689C9.32218 6.06719 9.36155 6.16242 9.36155 6.2617C9.36129 6.36081 9.3218 6.45577 9.25172 6.52585C9.18164 6.59593 9.08667 6.63542 8.98757 6.63569Z'
            fill={props.color}
          />
          <path
            d='M18.3931 17.0603H5.88962C5.00269 17.0603 4.28369 17.7793 4.28369 18.6662V22.3941C4.28369 23.281 5.00269 24 5.88962 24H18.3931C19.28 24 19.999 23.281 19.999 22.3941V18.6662C19.999 17.7793 19.28 17.0603 18.3931 17.0603Z'
            fill={props.color}
          />
          <path d='M8.38576 21.3372V22.0641H7.63379V21.3372H8.38576Z' fill='white' />
          <path
            d='M9.49162 21.0092V22.0641H8.76465V19.0573H9.9426C10.2996 19.0573 10.5726 19.1462 10.7596 19.3232C10.8542 19.4162 10.9281 19.5282 10.9763 19.6517C11.0246 19.7753 11.0461 19.9077 11.0396 20.0402C11.0432 20.215 11 20.3876 10.9146 20.5402C10.8273 20.6893 10.6979 20.8093 10.5426 20.8852C10.3553 20.9737 10.1497 21.0165 9.9426 21.0102L9.49162 21.0092ZM10.2996 20.0402C10.2996 19.7735 10.1536 19.6402 9.86161 19.6402H9.49162V20.4232H9.86161C10.1536 20.4259 10.2996 20.2982 10.2996 20.0402Z'
            fill='white'
          />
          <path
            d='M13.9124 21.3391C13.7872 21.5665 13.598 21.7521 13.3684 21.8731C13.11 22.0054 12.8226 22.071 12.5324 22.0641H11.3975V19.0572H12.5324C12.8228 19.0499 13.1106 19.1141 13.3704 19.2442C13.5994 19.3625 13.788 19.5463 13.9124 19.7722C14.0425 20.0119 14.1079 20.2814 14.1023 20.5541C14.1078 20.8278 14.0423 21.0982 13.9124 21.3391ZM13.1304 21.1881C13.2117 21.1047 13.2747 21.0052 13.3153 20.896C13.3559 20.7868 13.3733 20.6704 13.3664 20.5541C13.3734 20.438 13.356 20.3217 13.3153 20.2127C13.2747 20.1037 13.2117 20.0044 13.1304 19.9212C12.9485 19.7616 12.7109 19.6803 12.4694 19.6952H12.1244V21.4131H12.4694C12.7107 21.4278 12.9481 21.3469 13.1304 21.1881Z'
            fill='white'
          />
          <path
            d='M16.4751 19.0573V19.6362H15.2421V20.2952H16.1941V20.8482H15.2421V22.0641H14.5142V19.0573H16.4751Z'
            fill='white'
          />
          <path
            d='M12.8762 5.88672H11.1083C10.8001 5.88672 10.5503 6.13653 10.5503 6.4447V8.21262C10.5503 8.52078 10.8001 8.7706 11.1083 8.7706H12.8762C13.1844 8.7706 13.4342 8.52078 13.4342 8.21262V6.4447C13.4342 6.13653 13.1844 5.88672 12.8762 5.88672Z'
            fill={props.color}
          />
        </svg>
      );
    case 'txt':
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox='0 0 20 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g opacity='0.3'>
            <path
              opacity='0.3'
              d='M13.9664 4.9248C13.4196 4.92427 12.8954 4.70689 12.5086 4.32035C12.1219 3.93381 11.9043 3.40965 11.9035 2.86287V0H2.69088C1.97721 0 1.29277 0.283511 0.788126 0.788151C0.283487 1.29279 0 1.97721 0 2.69088V18.9122C0 19.6259 0.283487 20.3103 0.788126 20.815C1.29277 21.3196 1.97721 21.6031 2.69088 21.6031H13.7504C14.4641 21.6031 15.1485 21.3196 15.6532 20.815C16.1578 20.3103 16.4413 19.6259 16.4413 18.9122V4.9248H13.9664Z'
              fill={props.color}
            />
          </g>
          <path
            d='M16.4411 4.9248H13.9662C13.4195 4.92427 12.8952 4.70689 12.5085 4.32035C12.1217 3.93381 11.9041 3.40965 11.9033 2.86287V0L16.4411 4.9248Z'
            fill={props.color}
          />
          <path
            d='M13.0594 8.37263H2.73482C2.63572 8.37236 2.54075 8.33287 2.47067 8.26279C2.4006 8.19271 2.3611 8.09775 2.36084 7.99864C2.36084 7.89936 2.4002 7.80413 2.47031 7.73383C2.54042 7.66354 2.63554 7.62392 2.73482 7.62366H13.0594C13.1587 7.62392 13.2538 7.66354 13.3239 7.73383C13.394 7.80413 13.4334 7.89936 13.4334 7.99864C13.4331 8.09775 13.3936 8.19271 13.3235 8.26279C13.2535 8.33287 13.1585 8.37236 13.0594 8.37263Z'
            fill={props.color}
          />
          <path
            d='M13.0594 10.6096H2.73482C2.63572 10.6093 2.54075 10.5698 2.47067 10.4997C2.4006 10.4297 2.3611 10.3347 2.36084 10.2356C2.36084 10.1363 2.4002 10.0411 2.47031 9.97077C2.54042 9.90048 2.63554 9.86086 2.73482 9.8606H13.0594C13.1587 9.86086 13.2538 9.90048 13.3239 9.97077C13.394 10.0411 13.4334 10.1363 13.4334 10.2356C13.4331 10.3347 13.3936 10.4297 13.3235 10.4997C13.2535 10.5698 13.1585 10.6093 13.0594 10.6096Z'
            fill={props.color}
          />
          <path
            d='M13.0594 12.8465H2.73482C2.63554 12.8462 2.54042 12.8066 2.47031 12.7363C2.4002 12.666 2.36084 12.5708 2.36084 12.4715C2.3611 12.3724 2.4006 12.2774 2.47067 12.2074C2.54075 12.1373 2.63572 12.0978 2.73482 12.0975H13.0594C13.1585 12.0978 13.2535 12.1373 13.3235 12.2074C13.3936 12.2774 13.4331 12.3724 13.4334 12.4715C13.4334 12.5708 13.394 12.666 13.3239 12.7363C13.2538 12.8066 13.1587 12.8462 13.0594 12.8465Z'
            fill={props.color}
          />
          <path
            d='M13.0594 15.0823H2.73482C2.63564 15.0823 2.54052 15.0429 2.47038 14.9728C2.40025 14.9027 2.36084 14.8075 2.36084 14.7084C2.36084 14.6091 2.4002 14.5138 2.47031 14.4436C2.54042 14.3733 2.63554 14.3336 2.73482 14.3334H13.0594C13.1587 14.3336 13.2538 14.3733 13.3239 14.4436C13.394 14.5138 13.4334 14.6091 13.4334 14.7084C13.4334 14.8075 13.394 14.9027 13.3238 14.9728C13.2537 15.0429 13.1586 15.0823 13.0594 15.0823Z'
            fill={props.color}
          />
          <path
            d='M18.3931 17.0603H5.88963C5.0027 17.0603 4.28369 17.7793 4.28369 18.6662V22.3941C4.28369 23.281 5.0027 24 5.88963 24H18.3931C19.28 24 19.9991 23.281 19.9991 22.3941V18.6662C19.9991 17.7793 19.28 17.0603 18.3931 17.0603Z'
            fill={props.color}
          />
          <path d='M8.55571 21.3372V22.0641H7.80273V21.3372H8.55571Z' fill='white' />
          <path d='M11.0947 19.0573V19.6362H10.2777V22.0641H9.54677V19.6362H8.73779V19.0573H11.0947Z' fill='white' />
          <path
            d='M13.2255 22.0641L12.5915 21.1282L12.0466 22.0641H11.2136L12.1746 20.5162L11.1836 19.0573H12.0466L12.6635 19.9632L13.1955 19.0573H14.0245L13.0765 20.5712L14.0885 22.0641H13.2255Z'
            fill='white'
          />
          <path d='M16.5292 19.0573V19.6362H15.7133V22.0641H14.9813V19.6362H14.1733V19.0573H16.5292Z' fill='white' />
        </svg>
      );
    case 'docx':
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox='0 0 20 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g opacity='0.3'>
            <path
              opacity='0.3'
              d='M13.9664 4.9248C13.4197 4.92427 12.8956 4.70685 12.509 4.32028C12.1224 3.93371 11.905 3.40956 11.9045 2.86287V1.85846e-07H2.69189C2.33844 -0.000131162 1.98841 0.0693617 1.66182 0.204533C1.33523 0.339704 1.03847 0.5379 0.788493 0.787785C0.538515 1.03767 0.340215 1.33435 0.204923 1.66089C0.0696308 1.98743 -2.4405e-08 2.33742 0 2.69088V18.9122C-2.4405e-08 19.2657 0.0696308 19.6157 0.204923 19.9422C0.340215 20.2688 0.538515 20.5654 0.788493 20.8153C1.03847 21.0652 1.33523 21.2634 1.66182 21.3986C1.98841 21.5337 2.33844 21.6032 2.69189 21.6031H13.7514C14.4651 21.6031 15.1495 21.3196 15.6542 20.815C16.1588 20.3103 16.4423 19.6259 16.4423 18.9122V4.9248H13.9664Z'
              fill={props.color}
            />
          </g>
          <path
            d='M16.4421 4.9248H13.9662C13.4195 4.92427 12.8954 4.70685 12.5088 4.32028C12.1222 3.93371 11.9048 3.40956 11.9043 2.86287V0L16.4421 4.9248Z'
            fill={props.color}
          />
          <path
            d='M13.0594 8.37263H2.7358C2.63661 8.37263 2.54149 8.33322 2.47136 8.26308C2.40122 8.19295 2.36182 8.09783 2.36182 7.99864C2.36169 7.94945 2.37127 7.9007 2.39 7.85521C2.40874 7.80973 2.43626 7.76837 2.47101 7.73354C2.50575 7.69871 2.54701 7.67108 2.59245 7.65222C2.63789 7.63336 2.6866 7.62366 2.7358 7.62366H13.0594C13.1588 7.62366 13.2542 7.66317 13.3245 7.73349C13.3948 7.80381 13.4344 7.89919 13.4344 7.99864C13.4341 8.09792 13.3945 8.19305 13.3242 8.26316C13.2539 8.33326 13.1587 8.37263 13.0594 8.37263Z'
            fill={props.color}
          />
          <path
            d='M13.0594 10.6096H2.7358C2.63661 10.6096 2.54149 10.5702 2.47136 10.5C2.40122 10.4299 2.36182 10.3348 2.36182 10.2356C2.36169 10.1864 2.37127 10.1376 2.39 10.0922C2.40874 10.0467 2.43626 10.0053 2.47101 9.97048C2.50575 9.93565 2.54701 9.90802 2.59245 9.88916C2.63789 9.8703 2.6866 9.8606 2.7358 9.8606H13.0594C13.1588 9.8606 13.2542 9.90008 13.3245 9.97041C13.3948 10.0407 13.4344 10.1361 13.4344 10.2356C13.4341 10.3349 13.3945 10.43 13.3242 10.5001C13.2539 10.5702 13.1587 10.6096 13.0594 10.6096Z'
            fill={props.color}
          />
          <path
            d='M13.0594 12.8465H2.7358C2.6866 12.8465 2.63789 12.8368 2.59245 12.8179C2.54701 12.7991 2.50575 12.7715 2.47101 12.7366C2.43626 12.7018 2.40874 12.6604 2.39 12.6149C2.37127 12.5695 2.36169 12.5207 2.36182 12.4715C2.36182 12.3723 2.40122 12.2772 2.47136 12.2071C2.54149 12.1369 2.63661 12.0975 2.7358 12.0975H13.0594C13.1587 12.0975 13.2539 12.1369 13.3242 12.207C13.3945 12.2771 13.4341 12.3722 13.4344 12.4715C13.4344 12.571 13.3948 12.6663 13.3245 12.7367C13.2542 12.807 13.1588 12.8465 13.0594 12.8465Z'
            fill={props.color}
          />
          <path
            d='M8.98554 15.0834H2.7358C2.6866 15.0834 2.63789 15.0737 2.59245 15.0549C2.54701 15.036 2.50575 15.0084 2.47101 14.9735C2.43626 14.9387 2.40874 14.8974 2.39 14.8519C2.37127 14.8064 2.36169 14.7577 2.36182 14.7085C2.36182 14.6093 2.40122 14.5141 2.47136 14.444C2.54149 14.3739 2.63661 14.3345 2.7358 14.3345H8.98554C9.08473 14.3345 9.17985 14.3739 9.24998 14.444C9.32012 14.5141 9.35953 14.6093 9.35953 14.7085C9.35966 14.7577 9.35008 14.8064 9.33134 14.8519C9.3126 14.8974 9.28508 14.9387 9.25034 14.9735C9.2156 15.0084 9.17433 15.036 9.1289 15.0549C9.08346 15.0737 9.03474 15.0834 8.98554 15.0834Z'
            fill={props.color}
          />
          <path
            d='M18.3941 17.0603H5.8906C5.00367 17.0603 4.28467 17.7793 4.28467 18.6662V22.3941C4.28467 23.281 5.00367 24 5.8906 24H18.3941C19.281 24 20 23.281 20 22.3941V18.6662C20 17.7793 19.281 17.0603 18.3941 17.0603Z'
            fill={props.color}
          />
          <path d='M6.11771 21.3372V22.0641H5.36475V21.3372H6.11771Z' fill='white' />
          <path
            d='M9.0115 21.3391C8.88602 21.5662 8.69696 21.7518 8.46752 21.873C8.20908 22.0052 7.92176 22.0708 7.63156 22.064H6.49561V19.0572H7.63156C7.92196 19.0501 8.20967 19.1143 8.46952 19.2441C8.69825 19.3629 8.88681 19.5466 9.0115 19.7721C9.14079 20.0122 9.20589 20.2815 9.2005 20.5541C9.20564 20.8276 9.14057 21.0979 9.0115 21.3391ZM8.22953 21.1881C8.31082 21.1046 8.3738 21.0051 8.41443 20.896C8.45507 20.7868 8.47248 20.6704 8.46553 20.5541C8.47252 20.438 8.45514 20.3216 8.4145 20.2126C8.37385 20.1036 8.31084 20.0043 8.22953 19.9211C8.04735 19.7614 7.80939 19.6801 7.56756 19.6951H7.22358V21.413H7.56756C7.8092 21.4279 8.04697 21.3471 8.22953 21.1881Z'
            fill='white'
          />
          <path
            d='M11.8145 19.2082C12.0433 19.3382 12.2324 19.528 12.3615 19.7572C12.4968 19.9993 12.5658 20.2729 12.5614 20.5502C12.5665 20.8282 12.4975 21.1026 12.3615 21.3451C12.2326 21.5754 12.0431 21.766 11.8135 21.8961C11.5797 22.0296 11.3147 22.0986 11.0455 22.0961C10.7763 22.0986 10.5113 22.0296 10.2775 21.8961C10.0479 21.766 9.8584 21.5754 9.72956 21.3451C9.59354 21.1026 9.52451 20.8282 9.52957 20.5502C9.52438 20.2728 9.59343 19.999 9.72956 19.7572C9.85906 19.5279 10.0485 19.3381 10.2775 19.2082C10.5113 19.0747 10.7763 19.0057 11.0455 19.0082C11.315 19.0059 11.5803 19.0749 11.8145 19.2082ZM10.4745 19.9182C10.327 20.0947 10.252 20.3205 10.2645 20.5502C10.2519 20.7789 10.327 21.0039 10.4745 21.1792C10.5464 21.2572 10.6345 21.3187 10.7326 21.3591C10.8307 21.3995 10.9365 21.4179 11.0425 21.4131C11.1482 21.4175 11.2535 21.3989 11.3513 21.3585C11.449 21.3181 11.5368 21.2569 11.6085 21.1792C11.7567 21.0042 11.8325 20.7792 11.8205 20.5502C11.8325 20.3215 11.7575 20.0968 11.6105 19.9212C11.5387 19.843 11.4506 19.7816 11.3525 19.7412C11.2543 19.7007 11.1485 19.6823 11.0425 19.6872C10.9368 19.6823 10.8312 19.7005 10.7332 19.7404C10.6351 19.7802 10.5469 19.8409 10.4745 19.9182Z'
            fill='white'
          />
          <path
            d='M15.2931 19.3292C15.5467 19.5355 15.7192 19.8249 15.7801 20.1461H15.0101C14.9582 20.013 14.8672 19.8987 14.7492 19.8182C14.6247 19.7371 14.4786 19.6957 14.3302 19.6992C14.2321 19.696 14.1347 19.7155 14.0454 19.7561C13.9561 19.7967 13.8773 19.8573 13.8152 19.9332C13.676 20.1113 13.6051 20.3333 13.6152 20.5591C13.605 20.7841 13.676 21.0052 13.8152 21.1821C13.8778 21.2573 13.9567 21.3171 14.046 21.3571C14.1352 21.3972 14.2324 21.4163 14.3302 21.4131C14.4787 21.417 14.6249 21.3755 14.7492 21.2941C14.8666 21.215 14.9575 21.1025 15.0101 20.9711H15.7801C15.7182 21.2914 15.5459 21.5798 15.2931 21.7861C15.0211 21.9938 14.6851 22.0999 14.3432 22.0861C14.0737 22.0918 13.8078 22.0247 13.5732 21.8921C13.3537 21.7645 13.1756 21.5763 13.0602 21.3501C12.936 21.1021 12.8738 20.8275 12.8792 20.5501C12.8739 20.2728 12.936 19.9982 13.0602 19.7502C13.1756 19.524 13.3537 19.3358 13.5732 19.2082C13.8078 19.0755 14.0737 19.0085 14.3432 19.0142C14.6869 19.0051 15.0229 19.1165 15.2931 19.3292Z'
            fill='white'
          />
          <path
            d='M18.0131 22.0641L17.3791 21.1282L16.8351 22.0641H16.0012L16.9631 20.5162L15.9722 19.0573H16.8351L17.4521 19.9632L17.9831 19.0573H18.8131L17.8641 20.5712L18.8761 22.0641H18.0131Z'
            fill='white'
          />
        </svg>
      );

    case 'xls':
    case 'csv':
    case 'xlsx':
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox='0 0 20 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g opacity='0.3'>
            <path
              opacity='0.3'
              d='M13.9664 4.9248C13.4197 4.92427 12.8956 4.70685 12.509 4.32028C12.1224 3.93371 11.905 3.40956 11.9045 2.86287V0H2.69089C1.97722 0 1.29278 0.283511 0.788143 0.788151C0.283504 1.29279 0 1.97721 0 2.69088V18.9122C0.00105979 19.6252 0.285032 20.3086 0.789556 20.8124C1.29408 21.3161 1.97791 21.5991 2.69089 21.5991H13.7504C14.1039 21.5992 14.4539 21.5297 14.7805 21.3946C15.1071 21.2594 15.4038 21.0612 15.6538 20.8113C15.9038 20.5614 16.1021 20.2647 16.2374 19.9382C16.3727 19.6117 16.4423 19.2617 16.4423 18.9082V4.92079L13.9664 4.9248Z'
              fill={props.color}
            />
          </g>
          <path
            d='M16.4421 4.9248H13.9662C13.4195 4.92427 12.8954 4.70685 12.5088 4.32028C12.1222 3.93371 11.9048 3.40956 11.9043 2.86287V0L16.4421 4.9248Z'
            fill={props.color}
          />
          <path
            d='M18.3931 17.0603H5.88962C5.00269 17.0603 4.28369 17.7793 4.28369 18.6662V22.3941C4.28369 23.281 5.00269 24 5.88962 24H18.3931C19.28 24 19.999 23.281 19.999 22.3941V18.6662C19.999 17.7793 19.28 17.0603 18.3931 17.0603Z'
            fill={props.color}
          />
          <path d='M8.04877 21.3112V22.0361H7.2998V21.3112H8.04877Z' fill='white' />
          <path
            d='M10.7396 19.3132C10.9926 19.518 11.1645 19.8062 11.2246 20.1261H10.4586C10.4075 19.9934 10.3172 19.8793 10.1996 19.7991C10.0759 19.718 9.93047 19.6765 9.78261 19.6802C9.68513 19.6772 9.58828 19.6967 9.49951 19.7371C9.41075 19.7775 9.33244 19.8377 9.27064 19.9131C9.13322 20.0908 9.06404 20.3118 9.07565 20.5361C9.06396 20.7595 9.13319 20.9796 9.27064 21.1561C9.33269 21.2311 9.4111 21.2909 9.49984 21.331C9.58858 21.371 9.68531 21.3902 9.78261 21.3871C9.93047 21.3907 10.0759 21.3492 10.1996 21.2681C10.3171 21.1896 10.4081 21.0773 10.4606 20.9461H11.2266C11.1653 21.265 10.9936 21.5522 10.7416 21.7571C10.4712 21.9649 10.1364 22.0711 9.79561 22.0571C9.52725 22.0629 9.26227 21.9963 9.02865 21.864C8.80976 21.7375 8.63253 21.5498 8.51867 21.3241C8.39542 21.0789 8.33365 20.8075 8.33868 20.5331C8.33352 20.2584 8.3953 19.9866 8.51867 19.7411C8.63253 19.5154 8.80976 19.3277 9.02865 19.2012C9.26227 19.069 9.52725 19.0023 9.79561 19.0082C10.1362 18.9965 10.4702 19.1044 10.7396 19.3132Z'
            fill='white'
          />
          <path
            d='M13.6622 21.6291C13.576 21.7666 13.4526 21.8769 13.3062 21.9471C13.1281 22.0304 12.9329 22.0709 12.7363 22.065C12.4402 22.077 12.1484 21.9909 11.9063 21.8201C11.7994 21.7397 11.7119 21.6363 11.6503 21.5176C11.5886 21.3989 11.5545 21.2678 11.5503 21.1341H12.3213C12.3266 21.2349 12.3698 21.3299 12.4423 21.4001C12.5139 21.4672 12.6092 21.5031 12.7073 21.5001C12.7877 21.5042 12.8665 21.4769 12.9273 21.4241C12.9536 21.398 12.9743 21.3668 12.9881 21.3324C13.0018 21.298 13.0084 21.2611 13.0073 21.2241C13.0082 21.1891 13.0021 21.1543 12.9894 21.1216C12.9767 21.089 12.9576 21.0593 12.9333 21.0341C12.8807 20.9808 12.8182 20.9383 12.7493 20.9091C12.6753 20.8761 12.5743 20.8371 12.4443 20.7921C12.2856 20.7399 12.1306 20.6771 11.9803 20.6041C11.8568 20.5403 11.7507 20.4472 11.6713 20.3331C11.5799 20.1947 11.5348 20.0308 11.5423 19.8651C11.5382 19.7009 11.5854 19.5394 11.6773 19.4032C11.7718 19.2701 11.9017 19.1662 12.0523 19.1032C12.2265 19.0327 12.4134 18.9986 12.6013 19.0032C12.8853 18.9881 13.1659 19.0719 13.3952 19.2402C13.4931 19.3195 13.5733 19.4186 13.6305 19.5309C13.6877 19.6432 13.7206 19.7663 13.7272 19.8921H12.9443C12.9346 19.8042 12.8967 19.7218 12.8363 19.6572C12.805 19.6276 12.7681 19.6046 12.7277 19.5896C12.6873 19.5747 12.6443 19.568 12.6013 19.5702C12.5265 19.5663 12.4531 19.5906 12.3953 19.6381C12.3682 19.6636 12.3472 19.6948 12.3337 19.7294C12.3202 19.7639 12.3146 19.8011 12.3173 19.8381C12.3164 19.8713 12.3222 19.9043 12.3342 19.9352C12.3462 19.9661 12.3643 19.9943 12.3873 20.0181C12.4368 20.0703 12.4968 20.1115 12.5633 20.1391C12.6333 20.1701 12.7353 20.2101 12.8633 20.2581C13.0249 20.3101 13.1823 20.3743 13.3342 20.4501C13.4588 20.5171 13.566 20.6123 13.6472 20.7281C13.74 20.8683 13.7858 21.0342 13.7782 21.2021C13.779 21.3523 13.7389 21.4999 13.6622 21.6291Z'
            fill='white'
          />
          <path
            d='M14.7432 19.0422L15.4762 21.2951L16.2092 19.0422H16.9841L15.9422 22.0361H15.0062L13.9683 19.0422H14.7432Z'
            fill='white'
          />
          <path
            d='M11.8885 15.0793H4.55378C3.91881 15.0793 3.40283 14.4473 3.40283 13.6694V8.3506C3.40283 7.57363 3.91881 6.94165 4.55378 6.94165H11.8885C12.5235 6.94165 13.0394 7.57363 13.0394 8.3506V13.6694C13.0394 14.4473 12.5235 15.0793 11.8885 15.0793ZM4.55378 7.66164C4.2438 7.66164 3.99081 7.97062 3.99081 8.3506V13.6694C3.99081 14.0494 4.2438 14.3584 4.55378 14.3584H11.8885C12.1985 14.3584 12.4505 14.0494 12.4505 13.6694V8.3506C12.4505 7.97062 12.1985 7.66164 11.8885 7.66164H4.55378Z'
            fill={props.color}
          />
          <path d='M12.7454 11.8765H3.69678V12.5974H12.7454V11.8765Z' fill={props.color} />
          <path d='M12.7454 9.40466H3.69678V10.1256H12.7454V9.40466Z' fill={props.color} />
          <path d='M10.4285 7.30164H9.70752V14.7183H10.4285V7.30164Z' fill={props.color} />
          <path d='M6.73464 7.30164H6.01367V14.7183H6.73464V7.30164Z' fill={props.color} />
        </svg>
      );

    case 'zip':
    case 'rar':
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox='0 0 20 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g opacity='0.3'>
            <path
              opacity='0.3'
              d='M13.9664 4.92479C13.4196 4.924 12.8955 4.70637 12.509 4.31964C12.1224 3.93292 11.905 3.40866 11.9045 2.86188V1.85814e-07H2.69189C2.33844 -0.000131162 1.98841 0.0693739 1.66182 0.204545C1.33523 0.339716 1.03847 0.5379 0.788492 0.787785C0.538514 1.03767 0.340215 1.33435 0.204923 1.66089C0.0696308 1.98743 -2.4405e-08 2.33743 0 2.69089V18.9122C-2.4405e-08 19.2657 0.0696308 19.6157 0.204923 19.9422C0.340215 20.2687 0.538514 20.5654 0.788492 20.8153C1.03847 21.0652 1.33523 21.2634 1.66182 21.3986C1.98841 21.5337 2.33844 21.6032 2.69189 21.6031H13.7514C14.4651 21.6031 15.1495 21.3196 15.6542 20.815C16.1588 20.3103 16.4423 19.6259 16.4423 18.9122V4.92479H13.9664Z'
              fill={props.color}
            />
          </g>
          <path
            d='M16.4421 4.92479H13.9662C13.4194 4.924 12.8953 4.70637 12.5088 4.31964C12.1222 3.93292 11.9048 3.40866 11.9043 2.86188V0L16.4421 4.92479Z'
            fill={props.color}
          />
          <path
            d='M18.3941 17.0603H5.8906C5.00367 17.0603 4.28467 17.7793 4.28467 18.6662V22.3941C4.28467 23.281 5.00367 24 5.8906 24H18.3941C19.281 24 20 23.281 20 22.3941V18.6662C20 17.7793 19.281 17.0603 18.3941 17.0603Z'
            fill={props.color}
          />
          <path d='M8.19047 21.3372V22.0641H7.4375V21.3372H8.19047Z' fill='white' />
          <path
            d='M10.1133 22.0641L9.4623 20.9282H9.29631V22.0641H8.56934V19.0573H9.81129C10.0181 19.0516 10.2234 19.0937 10.4113 19.1803C10.5655 19.2536 10.6943 19.3713 10.7812 19.5182C10.865 19.6685 10.9074 19.8383 10.9042 20.0102C10.9101 20.2112 10.8461 20.408 10.7232 20.5672C10.5894 20.7289 10.404 20.8397 10.1983 20.8812L10.9132 22.0641H10.1133ZM9.29631 20.4312H9.74729C9.86249 20.4399 9.97664 20.4041 10.0663 20.3312C10.1018 20.2936 10.129 20.2491 10.1463 20.2003C10.1635 20.1516 10.1703 20.0998 10.1663 20.0482C10.1696 19.9971 10.1624 19.9457 10.1452 19.8974C10.1281 19.8491 10.1012 19.8048 10.0663 19.7672C9.97786 19.6936 9.86399 19.6576 9.74929 19.6672H9.29831L9.29631 20.4312Z'
            fill='white'
          />
          <path
            d='M13.1375 21.5151H12.0106L11.8226 22.0641H11.0576L12.1576 19.0782H12.9945L14.0945 22.0641H13.3205L13.1375 21.5151ZM12.9505 20.9622L12.5756 19.8562L12.1976 20.9622H12.9505Z'
            fill='white'
          />
          <path
            d='M15.9233 22.064L15.2724 20.9281H15.1064V22.064H14.3794V19.0571H15.6213C15.8281 19.0515 16.0334 19.0936 16.2213 19.1801C16.3755 19.2535 16.5044 19.3712 16.5913 19.5181C16.675 19.6684 16.7175 19.8381 16.7143 20.0101C16.7202 20.2111 16.6562 20.4079 16.5333 20.5671C16.3994 20.7288 16.2141 20.8396 16.0083 20.8811L16.7233 22.064H15.9233ZM15.1064 20.4311H15.5573C15.6725 20.4395 15.7866 20.4038 15.8763 20.3311C15.9119 20.2935 15.9391 20.2489 15.9563 20.2002C15.9735 20.1515 15.9804 20.0996 15.9763 20.0481C15.9797 19.9969 15.9725 19.9456 15.9553 19.8973C15.9381 19.849 15.9112 19.8047 15.8763 19.7671C15.7879 19.6934 15.674 19.6575 15.5594 19.6671H15.1084L15.1064 20.4311Z'
            fill='white'
          />
          <path
            d='M9.49633 15.0834H3.24459C3.14531 15.0832 3.05019 15.0436 2.98009 14.9733C2.90998 14.903 2.87061 14.8077 2.87061 14.7085C2.87061 14.6093 2.91001 14.5142 2.98015 14.444C3.05028 14.3739 3.1454 14.3345 3.24459 14.3345H9.49633C9.54553 14.3343 9.59427 14.3439 9.63976 14.3627C9.68525 14.3814 9.72659 14.4089 9.76142 14.4437C9.79625 14.4784 9.82389 14.5197 9.84275 14.5651C9.86161 14.6106 9.87131 14.6593 9.87131 14.7085C9.87131 14.8079 9.8318 14.9033 9.76148 14.9736C9.69116 15.0439 9.59578 15.0834 9.49633 15.0834Z'
            fill={props.color}
          />
          <path
            d='M9.49633 12.8745H3.24459C3.14531 12.8742 3.05019 12.8346 2.98009 12.7643C2.90998 12.694 2.87061 12.5988 2.87061 12.4995C2.87087 12.4004 2.91036 12.3054 2.98044 12.2353C3.05052 12.1652 3.14548 12.1258 3.24459 12.1255H9.49633C9.59561 12.1255 9.69084 12.1649 9.76114 12.235C9.83143 12.3051 9.87105 12.4002 9.87131 12.4995C9.87131 12.5989 9.8318 12.6943 9.76148 12.7646C9.69116 12.8349 9.59578 12.8745 9.49633 12.8745Z'
            fill={props.color}
          />
          <path
            d='M9.49633 10.6656H3.24459C3.1454 10.6656 3.05028 10.6262 2.98015 10.5561C2.91001 10.4859 2.87061 10.3908 2.87061 10.2916C2.87061 10.1923 2.90998 10.0971 2.98009 10.0268C3.05019 9.95651 3.14531 9.91689 3.24459 9.91663H9.49633C9.59578 9.91663 9.69116 9.95612 9.76148 10.0264C9.8318 10.0968 9.87131 10.1922 9.87131 10.2916C9.87131 10.3408 9.86161 10.3895 9.84275 10.435C9.82389 10.4804 9.79625 10.5217 9.76142 10.5564C9.72659 10.5911 9.68525 10.6187 9.63976 10.6374C9.59427 10.6561 9.54553 10.6657 9.49633 10.6656Z'
            fill={props.color}
          />
          <path
            d='M9.49633 8.45671H3.24459C3.1454 8.45671 3.05028 8.41731 2.98015 8.34718C2.91001 8.27704 2.87061 8.18191 2.87061 8.08272C2.87061 7.98354 2.91001 7.88842 2.98015 7.81828C3.05028 7.74815 3.1454 7.70874 3.24459 7.70874H9.49633C9.54553 7.70861 9.59427 7.71819 9.63976 7.73693C9.68525 7.75566 9.72659 7.78319 9.76142 7.81793C9.79625 7.85267 9.82389 7.89394 9.84275 7.93938C9.86161 7.98482 9.87131 8.03353 9.87131 8.08272C9.87131 8.13192 9.86161 8.18064 9.84275 8.22608C9.82389 8.27152 9.79625 8.31279 9.76142 8.34753C9.72659 8.38227 9.68525 8.4098 9.63976 8.42854C9.59427 8.44727 9.54553 8.45684 9.49633 8.45671Z'
            fill={props.color}
          />
          <path
            d='M12.3002 15.2514C11.9637 15.2511 11.6411 15.1174 11.4032 14.8794C11.1653 14.6415 11.0315 14.3189 11.0312 13.9824V12.7135H13.5681V13.9824C13.5679 14.3187 13.4342 14.6412 13.1965 14.8791C12.9588 15.117 12.6365 15.2509 12.3002 15.2514ZM11.6802 13.3625V13.9824C11.6805 14.1468 11.7459 14.3043 11.8621 14.4205C11.9783 14.5368 12.1359 14.6022 12.3002 14.6024C12.4645 14.6022 12.6221 14.5368 12.7383 14.4205C12.8545 14.3043 12.9199 14.1468 12.9202 13.9824V13.3625H11.6802Z'
            fill={props.color}
          />
          <path d='M11.0313 12.7145H12.3032V11.4425H11.0313V12.7145Z' fill={props.color} />
          <path d='M12.2993 11.4435H13.5713V10.1715H12.2993V11.4435Z' fill={props.color} />
          <path d='M11.0313 10.1726H12.3032V8.90066H11.0313V10.1726Z' fill={props.color} />
          <path d='M12.2993 8.90161H13.5713V7.62967H12.2993V8.90161Z' fill={props.color} />
        </svg>
      );

    case 'mp3':
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox='0 0 20 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g opacity='0.3'>
            <path
              opacity='0.3'
              d='M13.967 4.924C13.4203 4.92347 12.8961 4.70606 12.5095 4.31947C12.1229 3.93288 11.9055 3.40871 11.905 2.862V0H2.691C1.9773 0 1.29284 0.283514 0.788177 0.788174C0.283517 1.29283 0 1.9773 0 2.691V18.913C0.00105984 19.626 0.285042 20.3094 0.789587 20.8132C1.29413 21.317 1.97799 21.6 2.691 21.6H13.752C14.4652 21.6 15.1492 21.3169 15.6538 20.8129C16.1583 20.3089 16.4422 19.6252 16.443 18.912V4.924H13.967Z'
              fill={props.color}
            />
          </g>
          <path
            d='M16.4428 4.924H13.9668C13.4201 4.92347 12.8959 4.70606 12.5093 4.31947C12.1227 3.93288 11.9053 3.40871 11.9048 2.862V0L16.4428 4.924Z'
            fill={props.color}
          />
          <path
            d='M18.3942 17.0601H5.89018C5.00321 17.0601 4.28418 17.7791 4.28418 18.666V22.394C4.28418 23.281 5.00321 24 5.89018 24H18.3942C19.2811 24 20.0002 23.281 20.0002 22.394V18.666C20.0002 17.7791 19.2811 17.0601 18.3942 17.0601Z'
            fill={props.color}
          />
          <path d='M7.86579 21.337V22.064H7.11279V21.337H7.86579Z' fill='white' />
          <path
            d='M11.6262 19.078V22.064H10.8992V20.2L10.2522 22.067H9.62316L8.97216 20.196V22.067H8.24316V19.081H9.13217L9.94417 21.161L10.7442 19.081L11.6262 19.078Z'
            fill='white'
          />
          <path
            d='M12.8549 21.009V22.064H12.1279V19.057H13.3059C13.6629 19.057 13.9359 19.146 14.1229 19.323C14.2175 19.4158 14.2913 19.5276 14.3396 19.651C14.3878 19.7744 14.4094 19.9067 14.4029 20.039C14.4065 20.2138 14.3634 20.3864 14.2779 20.539C14.1906 20.6881 14.0612 20.8082 13.9059 20.884C13.7185 20.9721 13.513 21.0149 13.3059 21.009H12.8549ZM13.6629 20.039C13.6629 19.7763 13.5169 19.6447 13.2249 19.644H12.8549V20.427H13.2249C13.5169 20.427 13.6629 20.2977 13.6629 20.039Z'
            fill='white'
          />
          <path
            d='M16.3341 19.034C16.4754 19.0981 16.5947 19.2025 16.6771 19.334C16.7557 19.4628 16.7962 19.6111 16.7941 19.762C16.802 19.9323 16.7484 20.0999 16.6431 20.234C16.5471 20.3502 16.4139 20.4297 16.2661 20.459V20.481C16.6554 20.605 16.8498 20.8473 16.8491 21.208C16.8519 21.3696 16.8108 21.5289 16.7301 21.669C16.6482 21.806 16.5274 21.9157 16.3831 21.984C16.2122 22.0632 16.0254 22.1019 15.8371 22.097C15.4911 22.097 15.2161 22.012 15.0141 21.842C14.8121 21.672 14.7011 21.412 14.6841 21.063H15.3941C15.3932 21.1777 15.4325 21.2891 15.5051 21.378C15.5435 21.4181 15.5903 21.4493 15.6422 21.4691C15.6941 21.489 15.7497 21.4972 15.8051 21.493C15.8507 21.4954 15.8963 21.4884 15.939 21.4722C15.9817 21.456 16.0206 21.4311 16.0531 21.399C16.0839 21.3669 16.1079 21.3289 16.1237 21.2874C16.1395 21.2458 16.1468 21.2014 16.1451 21.157C16.1451 20.9097 15.9664 20.7863 15.6091 20.787H15.4731V20.196H15.6051C15.9251 20.2007 16.0851 20.0857 16.0851 19.851C16.088 19.8081 16.0822 19.765 16.0679 19.7244C16.0537 19.6838 16.0313 19.6466 16.0021 19.615C15.9725 19.5867 15.9375 19.5647 15.8992 19.5504C15.8608 19.5362 15.8199 19.5299 15.7791 19.532C15.7338 19.5293 15.6885 19.5369 15.6466 19.5542C15.6047 19.5715 15.5672 19.5981 15.5371 19.632C15.4742 19.7116 15.4391 19.8096 15.4371 19.911H14.7261C14.7244 19.7751 14.7501 19.6402 14.8016 19.5145C14.8531 19.3887 14.9295 19.2746 15.0261 19.179C15.2101 19.009 15.4681 18.924 15.8001 18.924C15.9841 18.92 16.1666 18.9576 16.3341 19.034Z'
            fill='white'
          />
          <path
            d='M12.0232 13.192H10.9282V8.76599C10.9282 8.61773 10.8693 8.47555 10.7644 8.37071C10.6596 8.26588 10.5174 8.20699 10.3692 8.20699H7.56916C7.49575 8.20699 7.42306 8.22144 7.35524 8.24954C7.28742 8.27763 7.22579 8.3188 7.17388 8.37071C7.12198 8.42262 7.0808 8.48425 7.05271 8.55207C7.02462 8.61989 7.01016 8.69258 7.01016 8.76599V13.192H5.91016V8.39199C5.91016 8.22355 5.94335 8.05676 6.00784 7.90115C6.07233 7.74555 6.16685 7.60418 6.286 7.48512C6.40515 7.36606 6.5466 7.27165 6.70225 7.20728C6.85791 7.14292 7.02472 7.10985 7.19316 7.10999H10.7432C11.0832 7.10999 11.4092 7.24505 11.6497 7.48547C11.8901 7.7259 12.0252 8.05198 12.0252 8.39199L12.0232 13.192Z'
            fill={props.color}
          />
          <path
            d='M10.7248 14.49C11.4416 14.49 12.0228 13.9089 12.0228 13.192C12.0228 12.4752 11.4416 11.894 10.7248 11.894C10.0079 11.894 9.42676 12.4752 9.42676 13.192C9.42676 13.9089 10.0079 14.49 10.7248 14.49Z'
            fill={props.color}
          />
          <path
            d='M5.71792 14.49C6.43479 14.49 7.01592 13.9089 7.01592 13.192C7.01592 12.4752 6.43479 11.894 5.71792 11.894C5.00106 11.894 4.41992 12.4752 4.41992 13.192C4.41992 13.9089 5.00106 14.49 5.71792 14.49Z'
            fill={props.color}
          />
        </svg>
      );

    default:
      return <></>;
  }
};

export default DocumentIcon;
