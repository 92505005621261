import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    onBoardingStepsContainer: {
      opacity: 0,
      overflow: 'hidden',
      maxHeight: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '20px',
      width: '100%',
      transition: 'all .6s ease',
      '&.show': {
        transition: 'all .6s ease',
        opacity: 1,
        maxHeight: '1000px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '20px',
      },
    },
    onBoardingStep: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      background: theme.palette.background.default,
      padding: '4px 6px',
      borderRadius: '16px',
      flexGrow: 1,
      maxWidth: '300px',
    },
    onBoardingStepNumber: {
      borderRadius: '20px',
      border: `1px solid ${theme.palette.primary.main}`,
      padding: '1px 9px',
      width: 'fit-content',
    },
    onBoardingStepNumberDisabled: {
      borderRadius: '20px',
      border: `1px solid ${theme.palette.grey.light}`,
      padding: '1px 9px',
      width: 'fit-content',
      color: theme.palette.grey.light,
    },
    onBoardingStepTextDisabled: {
      color: theme.palette.grey.light,
    },
    stepFinishedText: {
      position: 'relative',
      '&::after': {
        content: "''",
        position: 'absolute',
        bottom: '45%',
        left: 0,
        width: 0,
        height: '1px',
        background: theme.palette.background.default,
        animation: '$lineAnimation 1s linear forwards',
      },
    },
    '@keyframes lineAnimation': {
      '0%': {
        width: 0,
      },
      '100%': {
        width: '100%',
      },
    },
    onBoardingFinishedStepContainer: {
      background: theme.palette.primary.main,
      border: `1px solid ${theme.palette.background.default}`,
      color: theme.palette.background.default,
      maxWidth: '300px',
    },
    arrow: {
      width: 0,
      height: 0,
      top: '-10px',
      left: '10px',
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderBottom: '15px solid white',
      position: 'relative',
      borderRadius: '4px',
    },
    arrowEnd: {
      top: '-10px',
      left: '50%',
    },
    dialogTitleContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    dialogTitle: {
      color: theme.palette.primary.main,
      fontSize: '16px',
    },
    leaveTutorialModal: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    leaveModalWidth: {
      maxWidth: '400px',
    },
    leaveBtn: {
      background: theme.palette.error.main,
      fontSize: '14px',
      color: theme.palette.background.default,
      fontWeight: 500,
      '&:hover': {
        background: '#e31b0c',
      },
    },
    leaveModalTitle: {
      fontWeight: 500,
      fontSize: '16px',
    },
    leaveModalContent: {
      fontWeight: 400,
      fontSize: '16px',
      color: theme.palette.grey.light,
      fontFamily: 'Open Sans',
    },
    skipStartContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    endStepModal: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '16px 24px',
    },
    endOnboardingModalWidth: {
      maxWidth: '720px',
    },
    checkCircle: {
      fill: theme.palette.success.main,
      color: theme.palette.success.main,
      textAlign: 'center',
    },
    finalTitle: {
      fontWeight: 500,
      fontSize: '16px',
      color: theme.palette.text.primary,
    },
    finalSubTitle: {
      fontWeight: 400,
      fontSize: '16px',
      color: theme.palette.grey.light,
      fontFamily: 'Open Sans',
    },
    downloadTitle: {
      fontWeight: 500,
      fontSize: '16px',
      color: theme.palette.primary.main,
    },
    downloadContent: {
      fontWeight: 400,
      fontSize: '14px',
      color: theme.palette.text.primary,
    },
    downloadContainer: {
      marginTop: '-4px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '8px',
    },
    appBtnsContainer: {
      display: 'flex',
      gap: '20px',
    },
    appBtnTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    androidBtn: {
      width: 'fit-content',
      display: 'flex',
      gap: '3px',
      alignItems: 'center',
      backgroundColor: '#000000',
      borderRadius: '8px',
      color: theme.palette.background.default,
      paddingRight: '8px',
      margin: '8px 0',
      '&::last-child': {
        marginRight: '4px',
      },
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'none',
      },
    },
    googleLogo: {
      width: '40px',
      height: '40px',
    },
    appleLogo: {
      width: '30px',
      height: '30px',
      marginLeft: '6px',
    },
    androidBtnPrimaryText: {
      fontSize: '10px',
      lineHeight: '1px',
      marginTop: '8px',
    },
    androidBtnSecondaryText: {
      fontSize: '16px',
      fontWeight: 900,
      alignSelf: 'flex-end',
    },
    taskInputTitle: {
      fontWeight: 500,
      fontSize: '16px',
    },
    taskInputContent: {
      fontWeight: 400,
      fontSize: '16px',
      color: theme.palette.grey.light,
      fontFamily: 'Open Sans',
    },
    skipSubStep: {
      alignSelf: 'flex-end',
      color: theme.palette.text.primary,
      textDecoration: 'underline',
      cursor: 'pointer',
      fontWeight: 500,
      fontSize: '12px',
      width: 'fit-content',
    },
    nextSubStep: {
      color: theme.palette.background.background0,
      width: 'fit-content',
    },
    subStepDialog: {},
    subStepFooter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    subStepSteps: {
      color: theme.palette.grey.light,
    },
    subStepArrow: {
      position: 'absolute',
      width: 10,
      height: 10,
      '&::before': {
        content: '',
        position: 'absolute',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderColor: 'transparent',
      },
    },
    subStepArrowUp: {
      width: 0,
      height: 0,
      position: 'relative',
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderBottom: `15px solid ${theme.palette.background.background0}`,
      borderRadius: '4px',
    },
    subStepArrowDown: {
      width: 0,
      height: 0,
      position: 'relative',
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderTop: `15px solid ${theme.palette.background.background0}`,
      borderRadius: '4px',
    },
    subStepArrowLeft: {
      width: 0,
      height: 0,
      position: 'relative',
      borderRight: `15px solid ${theme.palette.background.background0}`,
      borderTop: '10px solid transparent',
      borderBottom: '10px solid transparent',
      borderRadius: '4px',
    },
    subStepArrowRight: {
      width: 0,
      height: 0,
      position: 'relative',
      borderTop: '10px solid transparent',
      borderBottom: '10px solid transparent',
      borderLeft: `15px solid ${theme.palette.background.background0}`,
      borderRadius: '4px',
    },
    subStepPopperArrowTopLeft: {
      top: '8px',
      left: '5%',
    },
    subStepPopperArrowTopRight: {
      top: '-10px',
      right: '10px',
    },
    subStepPopperArrowTop: {
      top: '8px',
      left: '50%',
    },
    subStepPopperArrowBottom: {
      bottom: '-10px',
      left: '50%',
    },
    subStepPopperArrowBottomLeft: {
      top: '150px',
      left: '3%',
    },
    subStepPopperArrowBottomLeftIssue: {
      top: '130px',
      left: '3%',
    },
    subStepPopperArrowBottomRight: {
      top: '130px',
      left: '90%',
    },
    subStepPopperArrowBottomRightBuild: {
      top: '150px',
      left: '90%',
    },
    subStepPopperArrowRight: {
      top: '58px',
      left: '98.5%',
    },
    subStepPopperArrowLeft: {
      top: '80px',
      right: '2%',
    },
    startSubStepsBtn: {
      minWidth: 'fit-content',
      width: 'fit-content',
      padding: '4px 10px',
    },
    defaultBtnPadding: {
      padding: '4px 10px',
    },
    onboardingModal: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalWidth: {
      maxWidth: '970px',
    },
    welcomeTitle: {
      fontWeight: 700,
      fontSize: '22px',
      lineHeight: '32px',
      textAlign: 'center',
      marginBottom: '8px',
      color: theme.palette.text.primary,
      letterSpacing: '1.25px',
    },
    welcomeSubTitle: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    videoContainer: {
      overflow: 'hidden',
    },
    taskInputModal: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
  });
