import { Chip, MenuItem, Popover, TextField } from '@mui/material';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import { styles } from 'src/utils/components/generic-input-card/styles';
import { ChevronRight, Close, KeyboardArrowDown, AddCircle, CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Ref, useEffect, useState } from 'react';
import { NexusGenFieldTypes } from '../../../../../../server/src/types';
import SelectValidationType from 'src/utils/components/generic-input-card/components/select-validation-type';
import {
  withIssueTemplateConnector,
  WithIssueTemplateConnectorProps,
} from 'src/modules/issue-templates/redux/issue.template.draft.redux';

interface QrCodeValidationChipsProps extends WithIssueTemplateConnectorProps {
  classes: Record<string, string>;
  idx: number;
  active: boolean;
  refSelectOptionTask: Ref<HTMLDivElement>;
  refSelectOptionInputToLink: Ref<HTMLDivElement>;
  input: NexusGenFieldTypes['Input'];
  selectRef: Ref<HTMLDivElement>;
  regexName: any;
  setInput: (data) => void;
  taskGroups: NexusGenFieldTypes['TaskGroup'][];
  validation: NexusGenFieldTypes['InputStringValidation'];
  optionsWithoutLinkWith: { option: string; value: string }[];
  options: { option: string; value: string }[];
  masterValidation: any;
  onBlur?: () => void;
}

const QrCodeValidationChips = (props: QrCodeValidationChipsProps): JSX.Element => {
  const {
    classes,
    input,
    regexName,
    active,
    selectedTask,
    setInput,
    selectRef,
    refSelectOptionTask,
    refSelectOptionInputToLink,
    idx,
    validation,
    taskGroups,
    optionsWithoutLinkWith,
    options,
    masterValidation,
    onBlur,
    templateGroups: templateGroupsContext,
  } = props;

  const templateGroups = templateGroupsContext || taskGroups;
  const [inputsWithQrCode, setInputsWithQrCode] = useState<any>([]);
  const [onlyInputsWithQrCode, setOnlyInputsWithQrCode] = useState<any>([]);
  const [selectedGroupPopover, setSelectedGroupPopover] = useState<any>(null);
  const [selectedTaskPopover, setSelectedTaskPopover] = useState<any>(null);
  const [selectedPopover, setSelectedPopover] = useState<any>(null);
  const [tasks, setTasks] = useState<any>({});

  const onClosePopover = () => {
    setSelectedTaskPopover({ anchor: null });
    setSelectedGroupPopover({ anchor: null });
    setSelectedPopover(null);
  };

  const qrCodeInputsToLink = (inputs) => {
    let inpsToReturn = [];
    let inputEqual = false;
    inputs &&
      inputs.forEach((inp) => {
        if (inputEqual) return;
        if (
          ((inp.type === 'qrCode' || (inp.type === 'string' && inp.string.setValueWithQRCode)) &&
            inp._id !== input._id) ||
          //@ts-ignore
          (inp.type === 'tool' && input.type === 'tool' && inp._id !== input._id)
        ) {
          inpsToReturn.push(inp);
        } else if (inp._id === input._id) {
          inputEqual = true;
          return;
        }
        if (inp[inp.type].onResponse?.length) {
          inp[inp.type].onResponse.map((onResp) => {
            if (onResp.inputs?.length) {
              const onRespInps = qrCodeInputsToLink(onResp.inputs);
              inpsToReturn = [...inpsToReturn, ...onRespInps];
            }
          });
        }
      });
    return inpsToReturn;
  };

  useEffect(() => {
    let inpsWithQrCode = {};
    let breakMaps = false;
    let onlyInpsWithQrCode = [];
    let tasks = {};
    templateGroups?.map((g) => {
      (g.tasks || g.taskTemplates)?.map((t) => {
        if (breakMaps) {
          return;
        }
        const inps = qrCodeInputsToLink(t.inputs);
        if (inps.length) {
          tasks = { ...tasks, [t._id]: t.name };
          inpsWithQrCode = { ...inpsWithQrCode, [g.name]: { ...inpsWithQrCode[g.name], [t._id]: inps } };
          onlyInpsWithQrCode = [...onlyInpsWithQrCode, ...inps];
        }
        if (t._id === selectedTask._id) {
          breakMaps = true;
        }
      });
    });
    setTasks(tasks);
    setInputsWithQrCode(inpsWithQrCode);
    setOnlyInputsWithQrCode(onlyInpsWithQrCode);
  }, [templateGroups]);

  return (
    <div className={classes.containerLinkInput}>
      <Chip
        size='small'
        label={
          <div className={classes.divLabel}>
            {active ? (
              <SelectValidationType
                id={`selection-input-not-sure-1`}
                data-testid={`selection-input-not-sure-1`}
                input={input}
                active={active}
                selectRef={selectRef}
                name={validation.name}
                idx={idx}
                linkWith={true}
                options={options}
                optionsWithoutLinkWith={optionsWithoutLinkWith}
                setInput={setInput}
              />
            ) : (
              regexName(validation.name)
            )}
            <div
              id={`div-input-not-sure-1`}
              data-testid={`div-input-not-sure-1`}
              className={classes.divLinkInputs}
              style={{ borderBottom: active ? '1px solid' : '' }}
              onClick={(e) => {
                if (active) setSelectedPopover(e.target);
              }}
            >
              {validation.linkWithInputs?.length
                ? validation.linkWithInputs.map((linkInp, i) => (
                    <Chip
                      key={i}
                      classes={{ root: classes.inputChip }}
                      size='small'
                      label={
                        onlyInputsWithQrCode.find(
                          //@ts-ignore
                          (inp) => inp._id === (linkInp?._id || linkInp),
                        )?.name
                      }
                    />
                  ))
                : undefined}
            </div>
            <div
              className={classes.divLinkInputs}
              style={{
                //@ts-ignore
                borderBottom: active && input[input.type].validations?.length < 2 ? '1px solid' : '',
                marginLeft: active ? '-5px' : '',
                display: active ? '' : 'none',
                minWidth: '5px',
              }}
            >
              {active ? (
                <KeyboardArrowDown
                  style={{ marginLeft: 'auto', marginRight: 0 }}
                  onClick={(e) => {
                    if (active) setSelectedPopover(e.target);
                  }}
                />
              ) : undefined}
              {input.type !== 'tool' ? (
                <AddCircle
                  /*@ts-ignore*/
                  style={{ display: active && input[input.type].validations?.length < 2 ? 'initial' : 'none' }}
                  classes={{ root: classes.addOrValidation }}
                  onClick={() => {
                    setInput({
                      _id: input._id,
                      [input.type]: {
                        ...input[input.type],
                        //@ts-ignore
                        validations: [...input[input.type].validations, { name: 'contains' }],
                      },
                    });
                  }}
                />
              ) : undefined}
              {/*@ts-ignore*/}
              {input[input.type].validations?.length > 1 ? (
                <Chip
                  label={
                    <div className={classes.divLabel}>
                      {active ? (
                        <SelectValidationType
                          id={`selection-input-not-sure-2`}
                          data-testid={`selection-input-not-sure-2`}
                          input={input}
                          active={active}
                          selectRef={selectRef}
                          name={masterValidation.name}
                          //@ts-ignore
                          idx={input[input.type].validations?.length}
                          linkWith={false}
                          options={options}
                          optionsWithoutLinkWith={optionsWithoutLinkWith}
                          setInput={setInput}
                        />
                      ) : (
                        regexName(masterValidation.name)
                      )}
                      <TextField
                        id={`text-input-not-sure-2`}
                        data-testid={`text-input-not-sure-2`}
                        value={
                          masterValidation.name?.split(':').length > 1 ? masterValidation.name.split(':')[1] : null
                        }
                        type={'text'}
                        disabled={!active}
                        InputProps={{ classes: { input: classes.inputValidations } }}
                        onBlur={onBlur}
                        onChange={(e): void => {
                          let expression: string | null = null;
                          switch (masterValidation.name.split(':')[0]) {
                            case 'contains':
                              expression = `^.*${e.target.value}.*$`;
                              break;
                            case 'notContains':
                              expression = `^((?!${e.target.value}).)*$`;
                              break;
                            case 'startWith':
                              expression = `^${e.target.value}`;
                              break;
                            case 'endWith':
                              expression = `${e.target.value}$`;
                              break;
                            default:
                              expression = null;
                          }

                          setInput({
                            _id: input._id,
                            [input.type]: {
                              ...input[input.type],
                              //@ts-ignore
                              validations: input[input.type]?.validations.map((val, i) =>
                                val.name === masterValidation.name
                                  ? {
                                      ...val,
                                      regex: expression || undefined,
                                      name: `${val.name?.split(':')[0]}:${e.target.value}`,
                                    }
                                  : val,
                              ),
                            },
                          });
                        }}
                        style={active ? null : { marginLeft: '5px' }}
                      />
                    </div>
                  }
                  classes={{ root: classes.advancedChip }}
                />
              ) : undefined}
            </div>
            <Popover
              ref={selectRef}
              open={selectedPopover}
              anchorEl={selectedPopover}
              className={classes.selectOptions}
              onClose={() => onClosePopover()}
            >
              {Object.keys(inputsWithQrCode).map((groupName, idx) => (
                <>
                  <MenuItem
                    id={'menuItem' + groupName}
                    data-testid={'menuItem' + groupName}
                    value={groupName}
                    key={idx}
                    style={{ minWidth: '170px' }}
                    onClick={(e) => {
                      setSelectedGroupPopover({ el: groupName, anchor: e.target });
                    }}
                  >
                    {groupName}
                    <ChevronRight style={{ marginLeft: 'auto', marginRight: '0' }} />
                  </MenuItem>
                  {selectedGroupPopover?.anchor ? (
                    <Popover
                      open={selectedGroupPopover?.anchor}
                      anchorEl={selectedGroupPopover.anchor}
                      ref={refSelectOptionTask}
                      onClose={() => onClosePopover()}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      {Object.keys(inputsWithQrCode[selectedGroupPopover.el]).map((taskId, idx) => (
                        <>
                          <MenuItem
                            id={'menuItem' + tasks[taskId]}
                            data-testid={'menuItem' + tasks[taskId]}
                            value={tasks[taskId]}
                            key={idx}
                            style={{ minWidth: '170px' }}
                            onClick={(e) => {
                              setSelectedTaskPopover({ el: taskId, anchor: e.target });
                            }}
                          >
                            {tasks[taskId]}
                            <ChevronRight style={{ marginLeft: 'auto', marginRight: '0' }} />
                          </MenuItem>
                          {selectedTaskPopover?.anchor ? (
                            <Popover
                              open={selectedTaskPopover?.anchor}
                              anchorEl={selectedTaskPopover.anchor}
                              onClose={() => onClosePopover()}
                              ref={refSelectOptionInputToLink}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                            >
                              {inputsWithQrCode[selectedGroupPopover.el][selectedTaskPopover.el]
                                .filter((inp) => {
                                  if (input.type === 'tool') {
                                    return inp.type === input.type;
                                  }
                                  return ['string', 'qrCode'].includes(inp.type);
                                })
                                .map((inputToLink, idx) => {
                                  return (
                                    <MenuItem
                                      id={'menuItem' + inputToLink.name}
                                      data-testid={'menuItem' + inputToLink.name}
                                      value={input._id}
                                      key={idx}
                                      style={{ minWidth: '170px' }}
                                      onClick={() => {
                                        //@ts-ignore
                                        const vals = input[input.type]?.validations.map((val) => ({
                                          ...val,
                                          linkWithInputs: val.linkWithInputs?.length
                                            ? val.linkWithInputs.includes(inputToLink._id)
                                              ? val.linkWithInputs.filter((inpId) => inpId !== inputToLink._id)
                                              : [...val.linkWithInputs, inputToLink._id]
                                            : [inputToLink._id],
                                        }));

                                        setInput({
                                          _id: input._id,
                                          [input.type]: {
                                            ...input[input.type],
                                            multiple: !!vals.find(
                                              (v) => v.linkWithInputs && v.linkWithInputs.length > 1,
                                            ),
                                            validations: vals,
                                          },
                                        });
                                      }}
                                    >
                                      {validation.linkWithInputs?.length &&
                                      validation.linkWithInputs.includes(inputToLink._id) ? (
                                        <CheckBox classes={{ root: classes.radioBtnChecked }} />
                                      ) : (
                                        <CheckBoxOutlineBlank classes={{ root: classes.radioBtnUnchecked }} />
                                      )}
                                      {inputToLink.name}
                                    </MenuItem>
                                  );
                                })}
                            </Popover>
                          ) : undefined}
                        </>
                      ))}
                    </Popover>
                  ) : undefined}
                </>
              ))}
            </Popover>
            <Close
              classes={{ root: classes.closeIcon }}
              style={{ display: active ? '' : 'none' }}
              onClick={(): void => {
                setInput({
                  _id: input._id,
                  [input.type]: {
                    ...input[input.type],
                    multiple: false,
                    //@ts-ignore
                    validations: input[input.type].validations.filter((_v, i) => i !== idx),
                  },
                });
              }}
            />
          </div>
        }
        classes={{ root: classes.advancedChip }}
      />
    </div>
  );
};

export default compose<any>(withStyles(styles), withIssueTemplateConnector)(QrCodeValidationChips);
