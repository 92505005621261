import { ExpandMore } from '@mui/icons-material';
import { Divider, ListItem, Popover, useTheme } from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import { useEffect, useRef, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';
import CustomDateRangePicker, { DateRange } from '../custom-date-range-picker';
import InputGeneric from '../input-field/input-generic';
import { styles } from './styles';
import { dateRangePresets, getNameOfDateRangePreset } from './utils/custom-date-dropdown.utils';

type CustomDateDropdownProps = {
  t: (key: string) => string;
  customClass?: string;
  timeSpan: DateRange;
  placeholder?: string;
  setTimeSpan: (timeSpan: DateRange) => void;
  withFullCalendar?: boolean;
  'data-testid'?: string;
} & WithTranslation &
  WithStyles<typeof styles>;

const CustomDateDropdown = ({
  t,
  classes,
  timeSpan,
  customClass,
  placeholder,
  setTimeSpan,
  withFullCalendar,
  'data-testid': dataTestID,
}: CustomDateDropdownProps) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const formatted = useRef<boolean>(false);
  const theme = useTheme();

  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const [showDatetimeSelector, setShowDatetimeSelector] = useState<boolean>(false);

  useEffect(() => {
    if (timeSpan && !formatted.current) {
      if (!timeSpan?.type && Object.keys(timeSpan).length) setShowDatetimeSelector(true);

      //@ts-ignore
      if (timeSpan.from || timeSpan.to) setTimeSpan({ startDate: timeSpan.from, endDate: timeSpan.to });

      formatted.current = true;
    }
  }, [timeSpan]);

  const handleOpen = () => {
    setOpenPopover(true);
  };

  const handleClose = () => {
    setOpenPopover(false);
  };

  const handleClick = (item) => {
    if (!item || Object.keys(item).length === 0) setTimeSpan(undefined);
    else setTimeSpan({ type: item.type, [item.type]: item[item.type] });

    setShowDatetimeSelector(false);
    setOpenPopover(false);
  };

  const timeSpanLabel = () => {
    if (!timeSpan) {
      return timeSpan === undefined || timeSpan === null ? t('anyDate') : placeholder;
    } else {
      if (timeSpan?.type && timeSpan?.type !== 'custom') {
        return getNameOfDateRangePreset(timeSpan);
      } else if (timeSpan && Object.keys(timeSpan).length === 0) {
        return t('anyDate');
      } else {
        return t('custom');
      }
    }
  };

  return (
    <>
      <div
        id={dataTestID ?? 'genericDateID'}
        data-testid={dataTestID ?? 'genericDateID'}
        className={customClass ? customClass : `${classes.mainContainer}`}
        style={{
          borderColor: openPopover ? theme.palette.primary.main : '',
          color:
            placeholder && ((timeSpan && Object.keys(timeSpan).length === 0) || timeSpan === null)
              ? theme.palette.text.secondary
              : '',
        }}
        ref={anchorRef}
        key={'timespan-current-selected'}
        onClick={handleOpen}
      >
        <div className={classes.relative}>
          <ExpandMore className={classes.expandIcon} />
        </div>
        <div className={classes.input}>{timeSpanLabel()}</div>
      </div>

      <Popover
        anchorEl={anchorRef.current}
        open={openPopover}
        classes={{ paper: classes.popoverContainer }}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{
          style: {
            width: anchorRef.current?.getBoundingClientRect().width,
          },
        }}
      >
        {/* Any Date */}
        <ListItem
          id={`timespan-item-Any Date`}
          data-testid={`timespan-item-Any Date`}
          key={'timespan-anyDate'}
          classes={{ root: classes.listItem }}
          onClick={() => handleClick(undefined)}
        >
          {t('anyDate')}
        </ListItem>

        {/* Date presets array */}
        {dateRangePresets.map((item) => (
          <ListItem
            id={`timespan-item-${item.name}`}
            data-testid={`timespan-item-${item.name}`}
            key={`timespan-${item.name}`}
            classes={{ root: classes.listItem }}
            onClick={() => handleClick(item)}
          >
            {t(`${item.name}`)}
          </ListItem>
        ))}

        <Divider style={{ margin: '8px 0px' }} />

        {/* Custom Date */}
        <div className={classes.customListItem}>
          {withFullCalendar ? (
            <CustomDateRangePicker
              data-testid={'timespan-date-range-picker'}
              defaultDateRange={timeSpan}
              onApply={(range) => {
                setTimeSpan(range);
                setOpenPopover(false);
              }}
              defaultLabel={t('custom')}
              noIcon={true}
            />
          ) : (
            <ListItem
              key={'timespan-custom'}
              classes={{ root: classes.listItem }}
              onClick={() => {
                setTimeSpan({ startDate: undefined, endDate: undefined });
                setOpenPopover(false);
                setShowDatetimeSelector(true);
              }}
            >
              {t('custom')}
            </ListItem>
          )}
        </div>
      </Popover>

      {showDatetimeSelector && !withFullCalendar ? (
        <div className={classes.horizontal}>
          <InputGeneric
            editable={true}
            disabled={false}
            type={'datetime'}
            data-testid={'input-start-date-from-'}
            placeholder={'yyyy/mm/dd hh:mm'}
            title={t('from')}
            values={timeSpan?.startDate ? [timeSpan.startDate] : []}
            handleChange={(e) =>
              e[0] != null ? setTimeSpan({ ...timeSpan, startDate: e[0] }) : setTimeSpan(undefined)
            }
          />
          <InputGeneric
            editable={true}
            disabled={false}
            type={'datetime'}
            data-testid={'input-start-date-to-'}
            placeholder={'yyyy/mm/dd hh:mm'}
            title={t('to')}
            values={timeSpan?.endDate ? [timeSpan.endDate] : []}
            handleChange={(e) => (e[0] != null ? setTimeSpan({ ...timeSpan, endDate: e[0] }) : setTimeSpan(undefined))}
          />
        </div>
      ) : null}
    </>
  );
};

export default compose<any>(withTranslation('translation'), withStyles(styles))(CustomDateDropdown);
