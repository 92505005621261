import { Add } from '@mui/icons-material';
import { Button, useTheme } from '@mui/material';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { showSnackbar } from 'src/base/root/root.redux';
import { File, FileType } from 'src/gql/graphql';
import { uploadFile } from 'src/modules/file-storage/file-storage.redux';
import { getFileExtension } from 'src/utils/components/show-file/utils/show-file-util-functions';
import { createFileThumbnail } from 'src/utils/funcs/files/utils';
import { styles } from './styles';

interface IButtonAddFileProps extends ConnectedProps<typeof connector> {
  onAdd: (newFiles: string[]) => Promise<void>;
  setUploadingFiles?: Dispatch<SetStateAction<File[]>>;
  setProgressMap?: Dispatch<SetStateAction<Map<string, number>>>;
  text?: string;
  Icon?: React.ElementType;
}

const ButtonAddFile = (props: IButtonAddFileProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const [hovered, setHovered] = useState(false);
  const classes = styles();

  const onUploadFiles = async () => {
    const { files } = inputRef.current;
    if (files.length > 10) {
      props.showSnackbar('error', `${t('limitOfFiles')}: 10`);
      return;
    }
    const uploadingFilesThumbnails: Array<Pick<File, 'name'> & { thumbnailBlob: Blob }> = [];
    const uploading = await Promise.all(
      Array.from(files).map(async (f): Promise<File | null> => {
        try {
          const { thumbnail, totalTriangles } = await createFileThumbnail(f);
          uploadingFilesThumbnails.push({ name: f.name, thumbnailBlob: thumbnail });
          props.setProgressMap((prevMap) => {
            const newMap = new Map(prevMap);
            newMap.set(f.name, 1);
            return newMap;
          });
          return {
            name: f.name,
            size: f.size,
            type: FileType.Unknown,
            details: { triangles: totalTriangles },
            extension: getFileExtension(f.name),
            path: '',
            ...(thumbnail && {
              hasThumbnail: true,
              download: { thumbnail: { url: URL.createObjectURL(thumbnail) } },
            }),
          };
        } catch (error) {
          console.error(error);
          return null;
        }
      }),
    );

    props.setUploadingFiles((prevUploadingFiles) => [
      ...prevUploadingFiles,
      ...uploading.filter((file) => file !== null),
    ]);

    const values = [];

    for (const file of Array.from(files)) {
      const { thumbnail, totalTriangles } = await createFileThumbnail(file);
      const resp = await props.uploadFile(
        file,
        thumbnail,
        totalTriangles,
        (name, progress) => {
          props.setProgressMap((prevMap) => {
            const newMap = new Map(prevMap);
            newMap.set(name, progress);
            return newMap;
          });
        },
        (file_name, error) => {
          props.showSnackbar('error', error.message);
          props.setUploadingFiles((prev) => prev.filter((f) => f.name !== file_name));
        },
      );
      if ('graphQLErrors' in resp) {
        continue;
      }
      if (!resp) {
        return;
      }
      if (resp['response'] && resp['response'].data.errors) {
        props.showSnackbar('error', resp['response'].data.errors[0].error);
        return;
      } else if (resp['response'] && resp['response'].status >= 400) {
        props.showSnackbar('error', t('fileUploadFailed'));
        return;
      }
      values.push(resp.data.data.files[0]._id);
    }

    await props.onAdd([...values]);

    props.setProgressMap((prevProgressMap) => {
      const newProgressMap = new Map(prevProgressMap);
      uploading.forEach((file) => {
        newProgressMap.delete(file.name);
      });
      return newProgressMap;
    });
    props.setUploadingFiles((prevUploadingFiles) => {
      return prevUploadingFiles.filter((file) => !uploading.includes(file));
    });
  };

  return (
    <div className={classes.container}>
      <Button
        style={{
          color: theme.palette.primary.main,
          background: hovered ? theme.palette.primary.light : theme.palette.background.default,
        }}
        id={'attachFileButton'}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className={classes.button}
        component='label'
      >
        {(props.Icon && <props.Icon />) || <Add />}
        <input
          ref={inputRef}
          type='file'
          multiple
          hidden
          onClick={(): void => {
            inputRef.current.value = null;
          }}
          onChange={() => onUploadFiles()}
        />
        {t(props.text ?? 'addFiles')}
      </Button>
    </div>
  );
};

const connector = connect(null, { uploadFile, showSnackbar });

export default connector(ButtonAddFile);
