import { ReactNode, useMemo } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import { useGlarFilterContext } from './glar-filter.context';
import { makeStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

interface GlarFilterlet {
  children: ReactNode;
  accessor: string;
  formatValue?: (val) => string;
  isAllSelected?: boolean;
}

const useStyles = makeStyles(styles);

export default function GlarFilterlet(props: GlarFilterlet) {
  const { accessor, formatValue, children, isAllSelected } = props;

  const classes: ClassNameMap<string> = useStyles();
  const { t } = useTranslation();

  const { value } = useGlarFilterContext(accessor);

  const preview = useMemo(() => {
    if (value == null || (Array.isArray(value) && !value?.length) || Object.keys(value)?.length == 0 || isAllSelected) {
      return t('all');
    }

    if (formatValue) {
      return formatValue(value);
    }

    return value;
  }, [value, isAllSelected, formatValue]);

  return (
    <Accordion classes={{ expanded: classes.noExpand }}>
      <AccordionSummary classes={{ expanded: classes.noExpand }} expandIcon={<ExpandMore />}>
        <div data-testid={`glar-filter-accordion-${accessor}`} className={classes.fieldTitleContainer}>
          <span className={classes.fieldTitle}>{t(accessor)}</span>
          <span className={classes.fieldPreview}>{preview}</span>
        </div>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.noExpand }}>{children}</AccordionDetails>
    </Accordion>
  );
}
