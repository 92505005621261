import gql from 'graphql-tag';

export const Q_GET_ISSUES_TO_APPROVAL = gql`
  query GetIssuesToApproval(
    $where: IssueInstanceWhereInput
    $limit: Int
    $skip: Int
    $orderBy: [IssueInstanceOrderByInput]
  ) {
    issues(where: $where, limit: $limit, skip: $skip, orderBy: $orderBy) {
      name
      startDate
      site {
        name
        _id
        coordinates
      }
      element {
        _id
        name
      }
      approval {
        approved
        approvalsNeeded
        approvalsCount
        executionDate
        approvedBy {
          name
          photo
          _id
        }
        levels {
          accounts {
            name
            email
            photo
            _id
            deleted
          }
          labelValues {
            _id
            labelValue {
              _id
              value
              backgroundColor
            }
          }
        }
        log {
          by {
            name
            email
            photo
            _id
          }
          type
          observation
          createdAt
        }
      }
      assignedAccounts {
        name
        email
        photo
        _id
      }
      labelValues {
        value
        _id
        backgroundColor
        label {
          name
        }
      }
      assignedLabelValues {
        value
        _id
        backgroundColor
        label {
          name
        }
      }
      _id
    }
    issuesCount(where: $where)
  }
`;
