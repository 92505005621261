import { useTranslation } from 'react-i18next';
import { styles } from './styles';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { DragIndicator, VisibilityOff, Visibility } from '@mui/icons-material';
import { useDrag, useDrop } from 'react-dnd';
import { ColumnPopoverOption } from '../../interfaces/columns-popover.interfaces';
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap } from '@mui/styles/withStyles';

const reorderColumn = (draggedColumnId: string, targetColumnId: string, columnOrder: string[]) => {
  columnOrder.splice(
    columnOrder.indexOf(targetColumnId),
    0,
    columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0] as string,
  );
  return [...columnOrder];
};

interface ColumnsPopoverItemProps {
  column: ColumnPopoverOption;
  provColumns: string[];
  visible: boolean;
  setVisible?: () => void;
  setHidden?: () => void;
  setProvColumns: React.Dispatch<React.SetStateAction<string[]>>;
}

const useStyles = makeStyles(styles);

const ColumnsPopoverItem: React.FC<ColumnsPopoverItemProps> = (props): JSX.Element => {
  const { t } = useTranslation();
  const classes: ClassNameMap<string> = useStyles();
  const { column, visible, setVisible, setHidden, provColumns, setProvColumns } = props;
  const dropRef = useRef(null);
  const handleRef = useRef(null);

  const [{ isDragging }, connectDrag, connectPreview] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => column,
    type: 'column',
  });

  const [{ isOver }, connectDrop] = useDrop({
    accept: 'column',
    collect: (monitor) => ({
      //@ts-ignore
      isOver: monitor.isOver(),
    }),
    //@ts-ignore
    hover: (draggedColumn: any, monitor) => {
      if (!dropRef.current) {
        return;
      }

      const dragIndex = draggedColumn.key;
      const hoverIndex = column.key;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = dropRef.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.top - hoverBoundingRect.bottom) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverActualY = clientOffset.y - hoverBoundingRect.top;

      // if dragging down, continue only when hover is smaller than middle X
      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
      // if dragging up, continue only when hover is bigger than middle X
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

      if (dragIndex > hoverIndex) {
        dropRef.current.classList.add(classes.onOverTop);
      } else {
        dropRef.current.classList.add(classes.onOverBottom);
      }
    },
    drop: (draggedColumn: any) => {
      const newColumnOrder = reorderColumn(draggedColumn.key, column.key, provColumns);

      setProvColumns(newColumnOrder);
    },
  });

  useEffect(() => {
    if (!dropRef.current) return;

    if (!isOver) {
      dropRef.current.classList.remove(classes.onOverTop);
      dropRef.current.classList.remove(classes.onOverBottom);
    }
  }, [isOver]);

  connectPreview(dropRef);
  connectDrop(dropRef);
  connectDrag(handleRef);

  return column ? (
    <div
      className={classes.divItem}
      ref={dropRef}
      style={{
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <div ref={handleRef} data-testid={`drag-${column.key}-column`} style={{ display: 'flex' }}>
        <DragIndicator classes={{ root: classes.dragIcon }} />
      </div>
      <span>{t(column.label)}</span>
      {visible ? (
        <Visibility
          data-testid={`hide-${column.key}-column`}
          classes={{ root: classes.visibilityIcon }}
          onClick={setHidden}
        />
      ) : (
        <VisibilityOff
          data-testid={`show-${column.key}-column`}
          classes={{ root: classes.visibilityIcon }}
          onClick={setVisible}
        />
      )}
    </div>
  ) : null;
};

export default ColumnsPopoverItem;
