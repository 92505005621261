import gql from 'graphql-tag';
import { STATE_MACHINE, STATE_MACHINE_FOLDER, STATE_MACHINE_TO_TREE } from 'src/utils/fragments/stateMachine';

export const Q_GET_DEFAULT_STATE_MACHINE = gql`
  query GetDefaultStateMachine {
    stateMachines(orderBy: [createdAt_ASC], limit: 1) {
      _id
      name
    }
  }
`;

export const Q_GET_STATE_MACHINE_FOLDERS = gql`
  query GetStateMachineFolders(
    $limit: Int
    $skip: Int
    $orderBy: [StateMachineFolderOrderByInput!]
    $where: StateMachineFolderWhereInput
  ) {
    stateMachineFolders(limit: $limit, skip: $skip, orderBy: $orderBy, where: $where) {
      _id
      name
      protected
      parentsTree {
        _id
        name
      }
      parent {
        _id
        name
      }
    }
  }
`;

export const Q_GET_STATE_MACHINE_FOLDERS_TO_TREE = gql`
  query GetStateMachineFoldersTree(
    $limit: Int
    $skip: Int
    $orderBy: [StateMachineFolderOrderByInput!]
    $where: StateMachineFolderWhereInput
  ) {
    stateMachineFolders(limit: $limit, skip: $skip, orderBy: $orderBy, where: $where) {
      _id
      name
      protected
      parentsTree {
        _id
        name
      }
      parent {
        _id
        name
      }
      totalItems: stateMachinesCount
      totaltotalFolders: stateMachineFoldersCount
    }
  }
`;

export const M_CREATE_STATE_MACHINE_FOLDER = gql`
  mutation CreateStateMachineFolder($name: String!, $parent: ID) {
    createStateMachineFolder(data: { name: $name, parent: $parent }) {
      ...stateMachineFolder
    }
  }
  ${STATE_MACHINE_TO_TREE}
  ${STATE_MACHINE_FOLDER}
`;

export const M_UPDATE_STATE_MACHINE_FOLDER = gql`
  mutation UpdateStateMachineFolder($name: String!, $parent: ID, $_id: ID!) {
    updateStateMachineFolder(data: { name: $name, parent: $parent }, where: { _id: $_id }) {
      ...stateMachineFolder
    }
  }
  ${STATE_MACHINE_TO_TREE}
  ${STATE_MACHINE_FOLDER}
`;

export const M_DELETE_STATE_MACHINE_FOLDER = gql`
  mutation DeleteStateMachineFolder($where: StateMachineFolderWhereUniqueInput!) {
    deleteOneStateMachineFolder(where: $where) {
      ...stateMachineFolder
    }
  }
  ${STATE_MACHINE_TO_TREE}
  ${STATE_MACHINE_FOLDER}
`;

export const Q_GET_STATE_MACHINES = gql`
  query GetStateMachines($filter: StateMachineWhereInput!) {
    stateMachines(where: $filter) {
      ...stateMachine
    }
  }
  ${STATE_MACHINE}
`;

export const Q_GET_STATE_MACHINES_TO_TREE = gql`
  query GetStateMachineTree($filter: StateMachineWhereInput!) {
    stateMachines(where: $filter) {
      __typename
      name
      _id
      folder {
        name
        _id
      }
    }
  }
`;

export const Q_GET_STATE_MACHINES_COUNT = gql`
  query GetStateMachinesCount($filter: StateMachineWhereInput!) {
    stateMachinesCount(where: $filter)
  }
`;

export const M_CREATE_STATE_MACHINE = gql`
  mutation CreateStateMachine(
    $name: String!
    $initState: String!
    $states: [StateMachineStateCreateInput!]
    $timeBomb: StateMachineTimeBombCreateInput
    $folder: ID!
  ) {
    createStateMachine(
      data: { name: $name, initState: $initState, states: $states, timeBomb: $timeBomb, folder: $folder }
    ) {
      ...stateMachine
    }
  }
  ${STATE_MACHINE}
`;

export const M_DELETE_STATE_MACHINE = gql`
  mutation DeleteStateMachine($id: ID!) {
    deleteOneStateMachine(where: { _id: $id }) {
      ...stateMachine
    }
  }
  ${STATE_MACHINE}
`;

export const M_UPDATE_STATE_MACHINE = gql`
  mutation UpdateStateMachine(
    $name: String
    $initState: String
    $states: [StateMachineStateUpdateInput!]
    $id: ID!
    $timeBomb: StateMachineTimeBombUpdateInput
    $folder: ID
  ) {
    updateStateMachine(
      data: { name: $name, initState: $initState, states: $states, timeBomb: $timeBomb, folder: $folder }
      where: { _id: $id }
    ) {
      ...stateMachine
    }
  }
  ${STATE_MACHINE}
`;

export const Q_SEARCH = gql`
  query SearchStateMachines($searchString: String) {
    stateMachineFolders(where: { name_contains: $searchString }) {
      ...stateMachineFolder
    }
    stateMachines(where: { name_contains: $searchString }) {
      ...stateMachineToTree
    }
  }
  ${STATE_MACHINE_TO_TREE}
  ${STATE_MACHINE_FOLDER}
`;
