import * as React from 'react';
import { convertBytesToString } from 'src/utils/funcs';
import { File } from 'src/gql/graphql';

interface IFileSizeTextProps {
  file: File;
  className: string;
}

const FileSizeText: React.FC<IFileSizeTextProps> = ({ file, className }) => {
  const { size } = file;
  const sizeText = size ? convertBytesToString(size) : 'Size unavailable';
  return <div className={className}>{sizeText}</div>;
};

export default FileSizeText;
