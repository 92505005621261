import { fromFlux } from '@influxdata/giraffe/dist';
import { useMemo } from 'react';
import { styles } from 'src/modules/dashboard/widgets/value/styles/main';
import { withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
import { getLabel } from './utils/widget.value.getLabel';
import moment from 'moment';

const ValueChartWidget = (props: any) => {
  const { t } = useTranslation();
  const value: number | string | null = useMemo(() => {
    if (props.dataType === 'realtime' && props.result?.length) {
      const { table } = fromFlux(props.result);

      if (table.length > 0) {
        const tagValue =
          table.columnKeys?.filter((k) => k === '_value (number)').length > 0 ? '_value (number)' : '_value';
        const _value = table.getColumn(tagValue);
        if (_value && _value[_value.length - 1]) {
          return Math.round(parseFloat(_value[_value.length - 1].toString()) * 100) / 100;
        }
      }
    } else {
      if (props.result?.length) {
        //result already comes sorted from backend
        const result = props.by === 'state' ? props.result : sortBy(props.result, 'name');

        if (result[0].count) {
          return Math.round(result[0].count);
        } else {
          if (props.dataType === 'responsesAverageValue') {
            return typeof result[0]?.averageValue == 'number'
              ? result[0]?.averageValue?.toFixed(2)
              : result[0]?.averageValue;
          }
          if (
            props.dataType === 'responsesLastValue' ||
            props.dataType === 'responsesMediumValue' ||
            props.dataType === 'responsesByRating'
          ) {
            return typeof result[0][result[0].name]?.value == 'number'
              ? result[0][result[0].name]?.value?.toFixed(2)
              : result[0][result[0].name]?.value;
          }
          return Math.round(result[0][result[0].name]);
        }
      }
    }
  }, [props.dataType, props.result]);

  const label = useMemo(() => {
    switch (true) {
      case props.dataType.startsWith('issues') || props.dataType.startsWith('actions'):
        return t(props.dataType);
      case props.dataType === 'responsesAverageValue':
        return getLabel(props) ? t(`${getLabel(props)}`) : null;
      case props.dataType === 'responsesLastValue':
        return props.result[0][props.result[0].name]?.timestamp
          ? moment(props.result[0][props.result[0].name]?.timestamp).format('DD/MM/YYYY HH:mm')
          : null;
      case props.dataType === 'responsesByRating':
        return getLabel(props) ? t(`${getLabel(props)}`) : null;
      default:
        return props.dataset.realtime?.filters.flatMap((f) => f.tags)?.[0]?.name ?? '';
    }
  }, [props.dataType, props.showAsPercentage, props.dataset]);

  const unit: string | null = useMemo(() => {
    return props?.dataset[props.dataset.type]?.filter?.unit?.symbol;
  }, [props.dataset]);

  return (
    <div id={props.id} className={props.classes.valueWrapper}>
      <div className={`single-stat--text ${props.classes.value}`}>
        {value ? value : 0} {unit}
      </div>
      <div className={`single-stat--text ${props.classes.label}`}>{label}</div>

      {props.dataType.endsWith('Duration') ||
      (props.dataType.endsWith('Average') && props.dataset[props.dataType].timeUnit) ? (
        <div className={`single-stat--text ${props.classes.label}`}>
          {t(props.dataset[props.dataType].timeUnit + 's')}
        </div>
      ) : null}
    </div>
  );
};
export default withStyles(styles)(ValueChartWidget);
