import { File } from 'src/gql/graphql';

import FilerobotImageEditor, { FilerobotImageEditorConfig, TABS, TOOLS } from 'react-filerobot-image-editor';
import { alpha, Dialog, SvgIconTypeMap, useTheme } from '@mui/material';

import { useContext, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrandingWatermark,
  Brush,
  Contrast,
  Crop,
  CropFree,
  Flip,
  Gradient,
  LightMode,
  PhotoSizeSelectLarge,
  RotateLeft,
  Thermostat,
  Tune,
  WaterDrop,
} from '@mui/icons-material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { typedConnect } from 'src/utils/funcs';
import { ConnectedProps } from 'react-redux';
import { saveEditedFile } from 'src/modules/file-storage/file-storage.redux';
import { FilesContext } from '../../../context';
import { useTranslation } from 'react-i18next';

interface IImageEditorProps extends ConnectedProps<typeof connector> {
  file: File;
  close: () => void;
  reload: () => void;
}

const ImageEditor = (props: IImageEditorProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const imageEditorTheme: FilerobotImageEditorConfig['theme'] = {
    typography: {
      fontFamily: 'Poppins',
    },
    palette: {
      'accent-primary': theme.palette.primary.main,
      'accent-primary-active': theme.palette.primary.main,
      'accent-primary-disabled': theme.palette.primary.main,
      warning: theme.palette.primary.main,
      'icon-primary': theme.palette.primary.main,
      'icons-secondary': theme.palette.secondary.main,
      'borders-primary': theme.palette.primary.main,
      'bg-primary': theme.palette.primary.main,
      'light-shadow': theme.palette.primary.main,
      'warning-hover': theme.palette.primary.dark,
      'warning-active': theme.palette.secondary.main,
      'accent-stateless': theme.palette.primary.main,
      accent_1_2_opacity: alpha(theme.palette.primary.main, 0.12),
      'accent-primary-hover': theme.palette.primary.dark,
    },
  };

  const translations: FilerobotImageEditorConfig['translations'] = {
    adjustTab: t('adjust'),
    finetuneTab: t('finetune'),
    watermarkTab: t('watermark'),
    resizeTab: t('resize'),
    annotateTabLabel: t('annotate'),
    save: t('save'),
    confirm: t('confirm'),
    cancel: t('cancel'),
    discardChanges: t('discardChanges'),
    discardChangesWarningHint: t('wouldYouLikeDiscardChanges'),
    changesLoseWarningHint: t('changesLoseWarningHint'),
    warning: t('warning'),
    cropTool: t('crop'),
    rotateTool: t('rotate'),
    flipX: t('flipX'),
    flipY: t('flipY'),
    unFlipX: t('flipX'),
    unFlipY: t('flipY'),
    brightnessTool: t('brightness'),
    brightness: t('brightness'),
    contrastTool: t('contrast'),
    blurTool: t('blur'),
    addWatermark: '+ ' + t('add') + ' ' + t('watermark'),
    uploadWatermark: t('upload') + ' ' + t('watermark'),
    addWatermarkAsText: t('addWatermarkAsText'),
    textTool: t('text'),
    imageTool: t('image'),
    rectangleTool: t('rectangle'),
    polygonTool: t('polygon'),
    penTool: t('pen'),
    lineTool: t('line'),
    arrowTool: t('arrow'),
    addImage: '+ ' + t('add') + ' ' + t('image'),
    width: t('width'),
    height: t('height'),
  };

  const swapIcon = (
    selector: string,
    IconComponent: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
      muiName: string;
    },
    iconStyle?: Record<string, string>,
  ) => {
    const element = document.querySelector(selector);

    if (!element) {
      return;
    }
    const svgIcon = element.querySelector('svg:first-of-type');

    if (svgIcon && !svgIcon.classList.contains('replaced-icon')) {
      svgIcon.classList.add('replaced-icon');

      if (iconStyle) {
        Object.entries(iconStyle).forEach(([key, value]) => {
          const kebabCaseKey = key.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`);
          svgIcon.setAttribute('style', `${kebabCaseKey}: ${value}`);
        });
      }

      const root = createRoot(svgIcon);
      root.render(<IconComponent sx={iconStyle} />);
    }
  };

  useEffect(() => {
    const iconComponents = [CropFree, Tune, BrandingWatermark, Brush, PhotoSizeSelectLarge];

    const observer = new MutationObserver(() => {
      //Sidebar tabs
      const tabs = document.querySelectorAll('.FIE_tab');
      tabs.forEach((tabElement, index) => {
        const svgIcon = tabElement.querySelector('svg');
        if (svgIcon && !tabElement.querySelector('.MuiSvgIcon-root')) {
          const IconComponent = iconComponents[index];
          const newIconContainer = document.createElement('div');

          svgIcon.parentNode!.insertBefore(newIconContainer, svgIcon);
          svgIcon.parentNode!.removeChild(svgIcon);

          const root = createRoot(newIconContainer);
          root.render(<IconComponent />);
        }
      });

      //hide warmth tool
      const warmthToolButton = document.querySelector('.FIE_warmth-tool-button') as HTMLElement;
      if (warmthToolButton) {
        warmthToolButton.style.display = 'none';
      }

      //reposition save button
      const saveButton = document.querySelector('.FIE_topbar-save-wrapper') as HTMLElement;
      if (saveButton) {
        saveButton.style.position = 'fixed';
        saveButton.style.bottom = '20px';
        saveButton.style.right = '20px';
        saveButton.style.zIndex = '1000';
      }

      //tools
      swapIcon('.FIE_crop-tool', Crop);
      swapIcon('.FIE_rotate-tool-button', RotateLeft);
      swapIcon('.FIE_flip-x-tool-button', Flip);
      swapIcon('.FIE_flip-y-tool-button', Flip, { transform: 'rotate(90deg)' });
      swapIcon('.FIE_brightness-tool-button', LightMode);
      swapIcon('.FIE_contrast-tool-button', Contrast);
      swapIcon('.FIE_hsv-tool-button', Gradient);
      swapIcon('.FIE_blur-tool-button', WaterDrop);
      swapIcon('.FIE_warmth-tool-button', Thermostat);
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  const { files, handleChange } = useContext(FilesContext) ?? {};

  return (
    <div>
      <Dialog
        open={true}
        sx={{ zIndex: 500, top: '54px' }}
        fullScreen
        onClose={() => {
          props.close();
        }}
      >
        <FilerobotImageEditor
          key={props.file?._id}
          source={props.file?.download?.url || props.file?.download?.thumbnail?.url || ''}
          defaultSavedImageName={props.file?.name}
          disableSaveIfNoChanges
          theme={imageEditorTheme}
          closeAfterSave={true}
          translations={translations}
          savingPixelRatio={21 / 9}
          onBeforeSave={() => false}
          onSave={async (editedImageObject) => {
            if (props?.file && props.file?._id) {
              const response = await props.saveEditedFile(props.file, editedImageObject);

              if (!response) return;
              if ('graphQLErrors' in response) {
                return;
              }
              handleChange?.(
                files.map((f) => {
                  if (f._id === props.file?._id) {
                    return { ...props.file, download: response.download };
                  }
                  return f;
                }),
              );
              props.reload();
            }
          }}
          previewPixelRatio={21 / 9}
          onClose={() => {
            props.close();
          }}
          annotationsCommon={{
            fill: '#ff0000',
          }}
          Text={{ text: 'Type here...' }}
          Rotate={{ angle: 90, componentType: 'slider' }}
          tabsIds={[TABS.ADJUST, TABS.FINETUNE, TABS.WATERMARK, TABS.ANNOTATE, TABS.RESIZE]}
          defaultTabId={TABS.ANNOTATE}
          defaultToolId={TOOLS.PEN}
          useBackendTranslations={false}
        />
      </Dialog>
    </div>
  );
};

const connector = typedConnect(null, {
  saveEditedFile,
});

export default connector(ImageEditor);
