import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

export const styles = makeStyles((theme: Theme) =>
  createStyles({
    arModelRenderContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 512,
      minWidth: 512,
    },
    errorContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'Poppins',
      color: theme.palette.text.secondary,
    },
    errorTextTop: { ...theme.typography.h3, marginTop: '-12px' },
    errorTextBottom: { ...theme.typography.h5 },
    loaderContainer: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
    loaderBlurredThumbnail: {
      position: 'absolute',
      width: '75%',
      height: '75%',
      objectFit: 'cover',
      pointerEvents: 'none',
      WebkitFilter: 'blur(8px)',
      filter: 'blur(8px)',
    },
    loaderProgress: { position: 'absolute', color: 'white' },
    loaderCircularIcon: { position: 'absolute', scale: '1.5', transform: 'translate(0px, 2px)' },
    controlsHelpContainer: {
      position: 'absolute',
      bottom: '12%',
      display: 'flex',
      height: 'fit-content',
      padding: '8px',
    },
    controlsText: { height: '100%', marginLeft: '2px', marginTop: '1%', display: 'block' },
    fullscreenBarLeft: { backgroundColor: 'black', width: 72, height: '100vh', top: 0, left: 0, position: 'fixed' },
    fullscreenBarRight: { backgroundColor: 'black', width: 72, height: '100vh', top: 0, right: 0, position: 'fixed' },
    fulscreenBackgroundContainer: { zIndex: 9001 },
    fullscreenCloseIcon: { position: 'fixed', top: 0, right: 0, margin: 20 },
    rendererFullscreen: {
      display: 'block',
      width: '100%',
      height: '100%',
      position: 'fixed',
      zIndex: 9000,
      top: 0,
      left: 0,
      backgroundColor: 'white',
    },
    rendererNormal: { display: 'block', width: '100%', height: '100%' },
  }),
);
