import { Backdrop, Paper, Popper, PopperPlacementType, Typography } from '@mui/material';
import * as React from 'react';
import { useState, useRef, useEffect, ReactNode } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { styles } from 'src/base/on-boarding/components/styles';
import { useMainContainerContext } from 'src/base/main-container/context/main.container.context';
import { Assignment } from '@mui/icons-material';
import DefaultButton from 'src/utils/components/default-button/default-button';
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useTranslation } from 'react-i18next';

type OnBoardingStepPopperProps = {
  loggedUser: any;
  refCurrent: HTMLDivElement;
} & ConnectedProps<typeof connecter>;

const useStyles = makeStyles(styles);
const OnBoardingStepPopper: React.FC<OnBoardingStepPopperProps> = (props): JSX.Element => {
  const state = useMainContainerContext();
  const { updateMainContainerState } = state;
  const { loggedUser, refCurrent } = props;
  const classes: ClassNameMap<string> = useStyles();
  const [newTextDialog, setNewTextDialog] = useState<{
    placement: PopperPlacementType;
    title: string;
    content: ReactNode;
  }>();
  const arrowRef = useRef<HTMLSpanElement>();
  const { t } = useTranslation();

  useEffect(() => {
    let textDialog: {
      placement: PopperPlacementType;
      title: string;
      content: ReactNode;
    };

    const buildPopperContent = (
      <Typography>
        {t('buildTemplateDialogText')}
        <strong>{t('addTasksAndRules')}</strong>
        {t('toCreateWorkFlow')}
      </Typography>
    );

    switch (state.currentStep) {
      case 'build':
        textDialog = {
          placement: 'bottom-start',
          title: t('issueTemplate'),
          content: buildPopperContent,
        };
        break;
      case 'createIssue':
        textDialog = {
          placement: 'bottom-start',
          title: t('createIssueDialogTitle'),
          content: t('createIssueDialogText'),
        };
        break;
      case 'execute':
        textDialog = {
          placement: 'bottom-end',
          title: t('executeIssueDialogTitle'),
          content: t('executeIssueDialogText'),
        };
        break;
    }

    setNewTextDialog(textDialog);
  }, [state.currentStep]);

  return (
    <Backdrop style={{ zIndex: 1300 }} open={state.open}>
      <Popper
        open={state.open}
        anchorEl={refCurrent}
        style={{
          color: '#fff',
          zIndex: 1302,
        }}
      >
        <span
          ref={arrowRef}
          className={
            loggedUser.onBoarding.step === 'execute' || loggedUser.onBoarding.step === 'createIssue'
              ? `${classes.arrow} ${classes.arrowEnd}`
              : classes.arrow
          }
        ></span>
        <Paper
          style={{
            padding: '14px',
            maxWidth: '360px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            transform: loggedUser.onBoarding.step === 'execute' && 'translateX(-150px)',
          }}
        >
          <div className={classes.dialogTitleContainer}>
            <Assignment color={'primary'} />
            <Typography className={classes.dialogTitle}>{newTextDialog?.title}</Typography>
          </div>
          <div id='text-body' data-testid='text-body'>
            <Typography>{newTextDialog?.content}</Typography>
          </div>
          <DefaultButton
            data-testid={'start-step-button'}
            rounded
            className={classes.startSubStepsBtn}
            onClick={() => updateMainContainerState({ open: false, startSubSteps: true })}
            label={t('start')}
          />
        </Paper>
      </Popper>
    </Backdrop>
  );
};

const connecter = connect(
  (state: any) => ({
    loggedUser: state.loginReducer.loggedUser,
  }),
  {},
);

export default connecter(OnBoardingStepPopper);
