import moment from 'moment';
import { dateRangePresets } from 'src/utils/components/custom-date-dropdown/utils/custom-date-dropdown.utils';

export const getLabel = (props) => {
  let dateFilter;
  switch (props.dataType) {
    case 'responsesLastValue':
      return props.result[0][props.result[0].name]?.timestamp
        ? moment(props.result[0][props.result[0].name]?.timestamp).format('DD/MM/YYYY HH:mm')
        : null;
    case 'responsesAverageValue':
      dateFilter = props.dataset.responsesAverageValue?.filter?.date;

      return dateFilter
        ? dateFilter.type === 'custom'
          ? `${moment(dateFilter.custom.from).format('DD/MM/YYYY')} - ${moment(dateFilter.custom.until).format('DD/MM/YYYY')}`
          : dateRangePresets.find((d) => d.type === dateFilter.type && d[d.type] === dateFilter[dateFilter.type])?.name
        : null;
    case 'responsesByRating':
      if (props.result[0].name === 'lastValue') {
        return props.result[0][props.result[0].name]?.timestamp
          ? moment(props.result[0][props.result[0].name]?.timestamp).format('DD/MM/YYYY HH:mm')
          : null;
      } else if (props.result[0].name === 'averageValue') {
        dateFilter = props.dataset.responsesMediumValue?.filter?.date;

        return dateFilter
          ? dateFilter.type === 'custom'
            ? `${moment(dateFilter.custom.from).format('DD/MM/YYYY')} - ${moment(dateFilter.custom.until).format('DD/MM/YYYY')}`
            : dateRangePresets.find((d) => d.type === dateFilter.type && d[d.type] === dateFilter[dateFilter.type])
                ?.name
          : null;
      }
      return null;
    default:
      return null;
  }
};
