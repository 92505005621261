// import { Message } from 'src/interfaces/chat'; TODO NOTE: uncomment later to impelement chat
//import { FileType } from 'src/interfaces/remote-assistance/file';
//import { SnackbarProps } from 'src/redux/uiReducer'; TODO NOTE: uncomment later and check if it is rly necessary
import {
  DEVICES_ERROR,
  NO_NETWORK_CONNECTION,
  ROOM_NOT_FOUND,
  OPERATION_ABORTED,
  PERMISSION_ERROR,
  INVALID_SESSION,
  INTERNAL_ERROR,
  SESSION_ALREADY_IN_ROOM,
  SESSION_IN_ANOTHER_ROOM,
  ROOM_UNEXPECTED_ERROR,
  UNABLED_TO_CREATE_ROOM,
  CREATE_NEW_SESSION_FEATURE_UNAVAILABLE,
  JOIN_SESSION_FEATURE_UNAVAILABLE,
  INVALID_EXTERNAL_DATA,
  EXTERNAL_DATA_NOT_FOUND,
  INVALID_GUEST_NAME,
} from './genericErrors';
import { FIREFOX, getBrowser, SAFARI } from './detectCompatibility';
import { FeatureErrorMessage, UserFeatureType } from 'src/interfaces/remote-assistance/feature';
import i18n from 'src/utils/translations/i18n';
import { v4 as uuidv4 } from 'uuid';
import validator from 'validator';
//import { LabelDisplayedRowsArgs } from '@mui/material/TablePagination/TablePagination';

/**
 * Returns if is a mobile device
 * @description determine if the incoming userAgent is a mobile device
 */

const supportedFilesExtensions = ['jpg', 'jpeg', 'png', 'mp4', 'webm'];

const imageTypes = ['jpg', 'jpeg', 'png'];

const videoTypes = ['mp4', 'webm'];

const fileRegexArray = (fileName: string) => /.(jpg|jpeg|png|gif|mp4|mov|wmv|flv|avi|avchd|webm|mkv)$/i.exec(fileName);

export const yearInMiliseconds = 31556952000;

export const isMobile = {
  Android: function (): RegExpExecArray {
    return /Android/i.exec(navigator.userAgent);
  },
  BlackBerry: function (): RegExpExecArray {
    return /BlackBerry/i.exec(navigator.userAgent);
  },
  iOS: function (): RegExpExecArray {
    return (
      /iPhone|iPad|iPod/i.exec(navigator.userAgent) ||
      (navigator.maxTouchPoints > 1 && /MacIntel/i.exec(navigator.platform))
    );
  },
  Opera: function (): RegExpExecArray {
    return /Opera Mini/i.exec(navigator.userAgent);
  },
  Windows: function (): RegExpExecArray {
    return /IEMobile/i.exec(navigator.userAgent) || /WPDesktop/i.exec(navigator.userAgent);
  },
  any: function (): RegExpExecArray {
    return isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows();
  },
};

// addLeadingSlash adds a leading slash
// It checks if it already has a prefixed slash
export function addLeadingSlash(string = ''): string {
  return string.length > 0 && string.startsWith('/') ? string : '/' + string;
}

// checkAbsoluteUrl checks incoming string to detect if it is a relative url or absolute url
export function checkAbsoluteUrl(string = ''): boolean {
  const isAbsoluteUrl = new RegExp('^([a-z0-9]*:|.{0})//.*$');

  if (isAbsoluteUrl.exec(string)) return true;

  return false;
}

/**
 * Gets the URL scheme
 * @description reads the url and extracts it's scheme
 */
export function getScheme(url: string): string {
  const match = /([a-z0-9+.-]+):/i.exec(url);

  return match && match[1];
}

export function getWebsocketBasePath(url?: string): string {
  if (!url) {
    return '';
  }

  if (url === '/') {
    return '';
  }

  if (checkAbsoluteUrl(url)) {
    return url;
  }

  return addLeadingSlash(url);
}

export function getAPIBasePath(url: string): string {
  if (!url) {
    return '';
  }

  if (url === '/') {
    return '';
  }

  if (checkAbsoluteUrl(url)) {
    return url;
  }

  return addLeadingSlash(url);
}

/**
 * Format Bytes
 * @description receives bytes and converts it to human readable format
 */
export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

/**
 * UUID validator
 * @description isUUID receives an uuid and validates it's format
 */
export function isUUID(uuid: string): boolean {
  const s = '' + uuid;

  const isUUID = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.exec(s);
  if (isUUID === null) return false;

  return true;
}

/**
 * Current date
 * @description current date is a native javascript function to get the actual time
 */
export function currentDate(currentDate: Date): string {
  const currDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
  const currTime = `${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;
  return currDate + ' ' + currTime;
}

/**
 * Copy Text
 * @description copies to clipboard the incoming text
 */
export async function copyText(text: string): Promise<void> {
  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
}

/**
 * Format Text
 * @description formats an incoming text with spaces
 */
export function formatText(s = '', count = 3): string {
  if (s) {
    const res = [...s]
      .map((d, i) => (i % count === 0 ? ' ' + d : d))
      .join('')
      .trim();

    return res;
  }

  return s;
}

export function checkEmail(email: string): boolean {
  return validator.isEmail(email);
}

export function checkName(name: string): boolean {
  const re = /^[a-z]+$/i;
  const result = name
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .split(/ +/)
    .every((parte) => re.test(parte));
  return result;
}

export function handleEnter(event: React.KeyboardEvent<HTMLInputElement>): void {
  if (event.key === 'Enter') {
    const target = event.target as HTMLInputElement;
    const form = target.form;

    if (!target || !form) return;

    // Get index of current input in the form
    const index = Array.prototype.indexOf.call(form, event.target) as number;
    event.preventDefault();

    // If input is empty, does nothing
    if (!(form.elements[index] as HTMLFormElement).defaultValue) return;

    let nextIdx = index + 1;
    // Check if next element is input/button
    // If it isnt, check the next one
    for (nextIdx; nextIdx < form.elements.length; nextIdx++) {
      if (form.elements[nextIdx].nodeName === 'INPUT') {
        // Focus input if it is empty
        if (!(form.elements[nextIdx] as HTMLFormElement).defaultValue)
          return (form.elements[nextIdx] as HTMLElement).focus();
      } else if (form.elements[nextIdx].nodeName === 'BUTTON') {
        // Click button if isnt 'show password' button
        if ((form.elements[nextIdx] as HTMLFormElement).type === 'submit')
          return (form.elements[nextIdx] as HTMLElement).click();
      }
    }
  }
}

export function handleShiftEnter(event: React.KeyboardEvent<HTMLInputElement>, onShiftEnterPress?: () => void): void {
  if (event.preventDefault && event.key === 'Enter' && event.shiftKey && onShiftEnterPress) {
    // Shift+Enter handler
    onShiftEnterPress();
  }
}

// Format timestamp to "dd/mm/aaaa at hh:mm"
export function formatTimestamp(timestamp: number, type: 'date' | 'time' | 'datetime' | 'extense_date'): string {
  const a = new Date(timestamp);
  const year = a.getFullYear();
  const month = a.getMonth();
  const date = a.getDate();
  let hour = a.getHours();
  let ampm = i18n.t('hourAb')?.toLowerCase();
  if (i18n.language != 'pt') {
    ampm = i18n.t(hour >= 12 ? 'pm' : 'am')?.toLowerCase();
    hour = hour % 12;
    hour = hour ? hour : 12;
  }

  const min = a.getMinutes() < 10 ? `0${a.getMinutes()}` : `${a.getMinutes()}`;
  let res: string;

  switch (type) {
    case 'date':
      res = `${date}/${month + 1}/${year}`;
      break;
    case 'time':
      res = `${hour}:${min} ${ampm}`;
      break;
    case 'datetime':
      res = i18n.t('dateTimeValue', {
        date: `${date}/${month + 1}/${year}`,
        time: `${hour}:${min}${ampm}`,
        interpolation: { escapeValue: false },
      });
      break;
    case 'extense_date':
      res = `${i18n.t(weekDaysList[a.getDay()])}, ${date} ${i18n.t(monthsList[month])?.toLowerCase()}, ${year}`;
      break;

    default:
      break;
  }
  return res;
}

export function getTimestampWithMask(milliseconds: number, timeMask: string): { time: string; mask: string } | null {
  if (milliseconds && milliseconds != 0 && Object.keys(TimeMask).includes(timeMask)) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;

    const minSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    const minMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const minHours = hours < 10 ? `0${hours}` : `${hours}`;

    switch (timeMask) {
      case 'Time':
        return { time: `${minHours}:${minMinutes}:${minSeconds}`, mask: TimeMask[timeMask] };
      default:
        break;
    }
  }

  return null;
}

// Check if date expired
export const isExpired = (unix: number, convertToMillis?: boolean): boolean => {
  if (unix === 0 || unix === undefined) return true;
  if (Date.now() > new Date(convertToMillis ? unix * 1000 : unix).getTime()) return true;
  return false;
};

// Retreive the interval in milsec between two timestamps
export const getTimeInterval = (unix: number): number => {
  if (unix < Date.now()) return 0;
  return unix - Date.now();
};

// Check if session is active
export const isSessionActive = (dateSession: number): boolean => {
  const dateNow = Date.now();
  if (dateSession != 0 && dateSession < dateNow) return false;

  return true;
};

export function formatTime(date: Date): string {
  if (!date) return '-';

  let hours = date?.getHours();
  let minutes: string | number = date?.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const strTime = `${hours}:${minutes} ${ampm}`;
  return strTime;
}

export function formatMiliseconds(milliseconds: number): string {
  if (!milliseconds || milliseconds == 0) {
    return '-';
  }

  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;

  if (hours == 0 && minutes == 0) {
    if (seconds == 1) return i18n.t('oneSecond');

    return i18n.t('timeSeconds', { seconds });
  }

  if (hours == 0 && seconds == 0) {
    if (minutes == 1) return i18n.t('oneMinute');

    return i18n.t('timeMinutes', { minutes });
  }

  if (minutes == 0 && seconds == 0) {
    if (hours == 1) return i18n.t('oneHour');

    return i18n.t('timeHours', { hours });
  }

  let result = '';

  if (hours > 0) result += `${i18n.t('timeHoursAb', { hours })} `;

  if (minutes > 0) result += `${i18n.t('timeMinutesAb', { minutes })} `;

  if (seconds > 0) result += `${i18n.t('timeSecondsAb', { seconds })}`;

  return result;
}

export function formatMilisecondsInMinutes(milliseconds: number): string {
  if (!milliseconds || milliseconds == 0) {
    return '-';
  }

  const minutes = Math.floor(Math.floor(milliseconds / 1000) / 60);

  if (minutes < 1) return i18n.t('lessThanAMinute');

  if (minutes == 1) return i18n.t('oneMinute');

  return i18n.t('timeMinutes', { minutes });
}

export function stringToColour(str: string): string {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}

export const getRandomDarkColor = (): string => {
  const lum = -0.25;
  let hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  let rgb = '#';
  for (let i = 0; i < 3; i++) {
    let colorString: number | string;
    colorString = parseInt(hex.substr(i * 2, 2), 16);
    colorString = Math.round(Math.min(Math.max(0, colorString + colorString * lum), 255)).toString(16);
    rgb += ('00' + colorString).substr(colorString.length);
  }
  return rgb;
};

export const getMessageColor = (message: any /*Message*/, messages: any /* Message[] */): string => {
  const existingSenders = messages?.filter((m) => m.sender === message.sender) || [];

  if (existingSenders.length > 0) {
    return existingSenders[0].senderColor;
  }

  return getRandomDarkColor();
};

// Checks if color is in a dark/light threshold
export const checkColor = (hex: string): 'dark' | 'light' => {
  const c = hex.substring(1); // strip #
  const rgb = parseInt(c, 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  if (luma < 200) {
    return 'dark';
  } else {
    return 'light';
  }
};

// Sets opacity to hex and adds hex to hexcolor
export const addOpacityToHex = (hex: string, opacity: number): string => {
  return hex + (opacity * 255).toString(16);
};

/*
export const handleAgoraErrorStatus = (
  response: StartAgoraMeetingReturn,
  showSnackbar: (data:  SnackbarProps) => AnyAction,
): string => {
  showSnackbar({
    type: 'error',
    message: MeetingErrors(response.error),
  });

  return response.error;
};*/

export const MeetingErrors = (errorType: string): string => {
  switch (errorType) {
    case PERMISSION_ERROR:
      return `${i18n.t('youNeedMicrophonePermission')}. ${i18n.t('pleaseCheckYourSettings')}.`;
    case UNABLED_TO_CREATE_ROOM:
      return `${i18n.t('unableToCreateNewSession')}. ${i18n.t('pleaseTryAgain')}.`;
    case ROOM_NOT_FOUND:
      return i18n.t('thisSessionIsntAvailable');
    case OPERATION_ABORTED:
      return `${i18n.t('unableToConnectToSessionDueToRestrictions')}. ${i18n.t(
        'pleaseContactYourCompanyITDepartmentOrOurSupport',
      )}.`;
    case DEVICES_ERROR:
      return `${i18n.t('thereWasAnErrorCreatingTheSession')}. ${i18n.t('pleaseContactSupport')}.`;
    case NO_NETWORK_CONNECTION:
      return `${i18n.t('unableToConnectToSession')}. ${i18n.t(
        'pleaseCheckYourNetworkSettingsOrTryADifferentNetwork',
      )}.`;
    case SESSION_ALREADY_IN_ROOM:
      return i18n.t('youAlreadyHaveYourSessionOpenInThisRoomInYourBrowser') + '.';
    case SESSION_IN_ANOTHER_ROOM:
      return `${i18n.t('thisSessionIsAlreadyInAnotherRoom')}. ${i18n.t(
        'pleaseDisconnectTheOtherSessionToJoinTheNewSession',
      )}.`;
    case INVALID_SESSION:
      return i18n.t('yourSessionIsInvalidPleaseLoginAgain') + '.';
    case EXTERNAL_DATA_NOT_FOUND:
      return `${i18n.t('noExternalDataWasSent')}. ${i18n.t('pleaseCheckTheDataAndTryAgain')}.`;
    case INVALID_EXTERNAL_DATA:
      return `${i18n.t('invalidExternalData')}. ${i18n.t('pleaseCheckTheDataAndTryAgain')}.`;
    case CREATE_NEW_SESSION_FEATURE_UNAVAILABLE:
      return FeatureErrorMessage(UserFeatureType.RoomCreate);
    case JOIN_SESSION_FEATURE_UNAVAILABLE:
      return FeatureErrorMessage(UserFeatureType.RoomJoin);
    case INVALID_GUEST_NAME:
      return i18n.t('enteredNameIsInvalid') + '.';
    case ROOM_UNEXPECTED_ERROR:
    case INTERNAL_ERROR:
    default:
      return `${i18n.t('thereWasanErrorPleaseTryAgain')}. ${i18n.t('ifTheProblemPersistsPleaseContactSupport')}.`;
  }
};

export const isPermissionStepper = (errorType: string): boolean => {
  const isPermissionError = errorType === PERMISSION_ERROR;
  const isDevicesError = errorType === DEVICES_ERROR;
  const isSafari = getBrowser() === SAFARI;
  const isFirefox = getBrowser() === FIREFOX;

  return isSafari || isFirefox ? isPermissionError || isDevicesError : isPermissionError;
};

export const timeDifference = (current: Date, previous: Date): string => {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = current.valueOf() - previous.valueOf();

  if (elapsed < msPerMinute) {
    return i18n.t('secondsAgo', { number: Math.round(elapsed / 1000) }).toLowerCase();
  } else if (elapsed < msPerHour) {
    return i18n.t('minutesAgo', { number: Math.round(elapsed / msPerMinute) }).toLowerCase();
  } else if (elapsed < msPerDay) {
    return i18n.t('hoursAgo', { number: Math.round(elapsed / msPerHour) }).toLowerCase();
  } else if (elapsed < msPerMonth) {
    return i18n.t('daysAgo', { number: Math.round(elapsed / msPerDay) }).toLowerCase();
  } else if (elapsed < msPerYear) {
    return i18n.t('monthsAgo', { number: Math.round(elapsed / msPerMonth) }).toLowerCase();
  } else {
    return i18n.t('yearsAgo', { number: Math.round(elapsed / msPerYear) }).toLowerCase();
  }
};

export const randomString = (length: number): string => {
  let mask = '';
  mask += 'abcdefghijklmnopqrstuvwxyz';
  mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  mask += '0123456789';
  let result = '';
  for (let i = length; i > 0; --i) result += mask[Math.round(Math.random() * (mask.length - 1))];
  return result;
};

export const isMedia = (name: string): boolean => fileRegexArray(name) && true;

export const getMediaType = (fileName: string): any /*FileType*/ => {
  const extension = getFileExtension(fileName);

  if (imageTypes.includes(extension)) {
    return 'image';
  } else if (videoTypes.includes(extension)) {
    return 'video';
  }

  return extension;
};

const getFileExtension = (fileName: string): string => {
  const splitName = fileName.split('.');

  return splitName[splitName.length - 1];
};

export const isFileSupported = (fileName: string): boolean => {
  const extension = getFileExtension(fileName);

  if (supportedFilesExtensions.includes(extension)) return true;

  return false;
};

export const getVideoImage = async (file: string): Promise<string> => {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.src = file;
    video.currentTime = 0.001;
    video.crossOrigin = 'anonymous';
    const snapshot = function () {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      resolve(canvas.toDataURL('image/png'));
      video.removeEventListener('canplay', snapshot);
    };
    video.addEventListener('canplay', snapshot);
  });
};

export const monthsList = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const monthsAbList = [
  'januaryAb',
  'februaryAb',
  'marchAb',
  'aprilAb',
  'mayAb',
  'juneAb',
  'julyAb',
  'augustAb',
  'septemberAb',
  'octoberAb',
  'novemberAb',
  'decemberAb',
];

export const weekDaysList = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export const TimeMask = {
  Time: `${i18n.t('hh')}:${i18n.t('mm')}:${i18n.t('ss')}`,
};

export const isSameDay = (firstDate: Date, secondDate: Date): boolean => {
  if (!firstDate || !secondDate) return false;

  return (
    firstDate.getFullYear() == secondDate.getFullYear() &&
    firstDate.getMonth() == secondDate.getMonth() &&
    firstDate.getDate() == secondDate.getDate()
  );
};

export const isOk = (status: string): boolean => status === 'ok' || status === 'OK';

export const stringToBoolean = (s: string): boolean => {
  if (!s) return null;

  if (s.toLowerCase() === 'true') {
    return true;
  }
  return false;
};

export const isValidUrl = (url: string): boolean => {
  try {
    new URL(url);
  } catch (_) {
    return false;
  }

  return true;
};

export const equalArray = (arr1: Array<string>, arr2: Array<string>): boolean => {
  if (arr1.length != arr2.length) return false;

  if (arr1.filter((v) => arr2.includes(v)).length != arr1.length) return false;

  return true;
};

export const setFavicon = (src?: string): void => {
  if (!src || src == '') src = '/favicon.ico';

  const link = document.createElement('link');
  const oldLink = document.querySelector("link[rel~='icon']");

  link.id = 'tenant-favicon';
  link.rel = 'icon';
  link.href = src;
  if (oldLink) {
    document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
};

export const generateRandomId = (): string => uuidv4();

export const isMoreThanTime = (startDate: Date, endDate: Date, time: number): boolean =>
  endDate.getTime() - startDate.getTime() > time;

export const getWeek = (date: Date): number => {
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));

  const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));

  return Math.ceil(((date.valueOf() - yearStart.valueOf()) / 86400000 + 1) / 7);
};

export const getFirstDayOfWeek = (date: Date): Date => {
  const d = new Date(date);

  const day = d.getDay();
  const diff = d.getDate() - day + (day == 0 ? -6 : 1);

  return new Date(d.setDate(diff));
};

export const getLastDayOfWeek = (date: Date): Date => {
  const first = date.getDate() - date.getDay();
  const last = first + 6;

  return new Date(date.setDate(last));
};

export const getLastDayOfMonth = (date: Date): Date => new Date(date.getFullYear(), date.getMonth() + 1, 0);
