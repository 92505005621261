import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';
import { AgendaContextProvider, agendaContextInitialValue } from './context/agenda.context';

const Agenda = lazy(() => import('src/modules/agenda/agenda'));

export const render = {
  default: (props) => {
    return (
      <AgendaContextProvider initialValue={agendaContextInitialValue}>
        <Suspense fallback={<LazyLoadingContent />}>
          <Agenda {...props} />
        </Suspense>
      </AgendaContextProvider>
    );
  },
};
