import { ReactNode, createContext, useCallback, useContext, useState } from 'react';
import { AccountInviteState, TableState } from '../types/account.invites.types';

export const pendingInviteContextInitialState: TableState = {
  data: [],
  dataSize: 0,
  columnsToShow: null,
  dataQueryState: {
    limit: 25,
    skip: 0,
    sorted: null,
    filtered: null,
    orderBy: 'extra_email',
    orderType: '_ASC',
  },
  loading: false,
  optionsDropdownOpen: false,
  optionsDropdownRef: null,
  selectedInvites: [],
  updatingData: false,
  filters: [],
  filtersCount: 0,
  refetchRequired: false,
};

export const accountInviteContextInitialValue: AccountInviteState = {
  invitesList: [
    {
      email: '',
      roles: [],
      authorizedSites: [],
      errors: null,
    },
  ],
  tableState: pendingInviteContextInitialState,
  openFilterPopover: false,
  searchActive: false,
};

const AccountInviteContext = createContext<AccountInviteState>(accountInviteContextInitialValue);

interface AccountInviteContextProviderProps {
  children: ReactNode;
  initialValue: AccountInviteState;
}

export const AccountInviteContextProvider = ({
  children,
  initialValue = accountInviteContextInitialValue,
}: AccountInviteContextProviderProps) => {
  const [accountInviteState, setAccountInviteState] = useState<AccountInviteState>(initialValue);

  const updateAccountInviteState = useCallback((newData: Partial<AccountInviteState>) => {
    setAccountInviteState((prevState) => ({ ...prevState, ...newData }));
  }, []);

  const updateTableState = useCallback((newData: Partial<TableState>) => {
    setAccountInviteState((prevState) => ({
      ...prevState,
      tableState: { ...prevState.tableState, ...newData },
    }));
  }, []);

  return (
    <AccountInviteContext.Provider
      value={{ ...accountInviteState, updateAccountInviteState, updateTableState, setAccountInviteState }}
    >
      {children}
    </AccountInviteContext.Provider>
  );
};

export const useAccountInviteContext = () => {
  const context = useContext(AccountInviteContext);
  if (!context) {
    throw new Error('useAccountInviteContext must be used within a AccountInviteContext');
  }
  return context;
};
