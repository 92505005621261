import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';

const IssueHistoryRefresh = () => lazy(() => import('src/modules/issue-history/issue.history'));

let IssueHistory = IssueHistoryRefresh();

export const render = {
  default: ({ ...props }) => {
    if (IssueHistory?._result?.type === 'error') {
      IssueHistory = IssueHistoryRefresh();
    }
    return (
      <Suspense fallback={<LazyLoadingContent />}>
        <IssueHistory {...props} />
      </Suspense>
    );
  },
};
export default render;
