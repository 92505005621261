import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';

const ARImageViewDefaultRefresh = () => lazy(() => import('src/modules/ar-image/ar-image.view.default'));
const ARImageCreateDefaultRefresh = () => lazy(() => import('src/modules/ar-image/ar-image.create.default'));

let ARImageViewDefault = ARImageViewDefaultRefresh();
let ARImageCreateDefault = ARImageCreateDefaultRefresh();

export const render = {
  view: {
    default: ({ ...props }) => {
      if (ARImageViewDefault?._result?.type === 'error') {
        ARImageViewDefault = ARImageViewDefaultRefresh();
      }

      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <ARImageViewDefault {...props} />
        </Suspense>
      );
    },
  },
  create: {
    default: ({ ...props }) => {
      if (ARImageCreateDefault?._result?.type) {
        ARImageCreateDefault = ARImageCreateDefaultRefresh();
      }

      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <ARImageCreateDefault {...props} />
        </Suspense>
      );
    },
  },
};

export default render;
