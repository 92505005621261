import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    addRangeBtn: {
      width: 'fit-content',
      padding: 0,
      marginInline: 0,
    },
    addRangeBtnText: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.main,
    },
    customRangeContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'flex-start',
      gap: '18px',
    },
    customRangeTitle: {
      display: 'flex',
      flexDirection: 'column',
    },
  });
