import {
  ChangeCircle,
  Delete,
  Edit,
  FileCopy,
  Fullscreen,
  FullscreenExit,
  GetApp,
  MoreVert,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { Card, Dialog, IconButton, Theme, Tooltip } from '@mui/material';
import { createStyles } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Document, Image, Page, pdf } from '@react-pdf/renderer';
import FileSaver from 'file-saver';
import * as htmlToImage from 'html-to-image';
import { omit, sortBy } from 'lodash';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps } from 'react-redux';
import { toast } from 'src/base/root/root.redux';
import { Widget } from 'src/gql/graphql';
import { updateWidget } from 'src/modules/dashboard/dashboard.redux';
import { styles } from 'src/modules/dashboard/styles/widget-wrapper';
import { FieldPopoverItem } from 'src/utils/components/better-field-popover/better-list-by-level/popover-reducer';
import { ISelectListItem } from 'src/utils/components/execution-input-select/components/select-list-item';
import { DropdownOption, MultiLevelMenu } from 'src/utils/components/multi-level-select';
import PieChartFilter, { FilterDataType } from 'src/utils/components/pie-chart-filter';
import { typedConnect } from 'src/utils/funcs';
import { withDialogBoundary } from 'src/utils/other/componentErrorBoundary';
import {
  ChartTypeEnum,
  FilterStateEnum,
  FilterStatusEnum,
  InformationEnum,
  MeasurementType,
  WidgetInfo,
} from '../components/new-chart/components/chartCards';
import EditChart from '../components/new-chart/edit-chart';
import NewWidgetModal from '../components/new-chart/new-widget-modal';
import { buildWidgetDataset, calculateTotal, editWidgetInfo, renderValue } from '../widget-helper';
import bar from './bar';
import gauge from './gauge';
import line from './line';
import pie from './pie';
import Refreshable from './refreshable';
import table from './table';
import value from './value';

const useStyles = makeStyles(styles);

const widgetDict: {
  [K in ChartTypeEnum]: any;
} = {
  bar,
  pie,
  line,
  table,
  value,
  gauge,
};

export const statePalette = {
  state: {
    wip: '#2470B3',
    pending: '#FFD279',
    canceled: '#272848',
    done: '#00C48C',
  },
  status: {
    wip: '#2470B3',
    pending: '#FFD279',
    locked: '#272848',
    done: '#00C48C',
  },
};

const chartsWithLegend = ['pie', 'line'];

const supportedFormats = ['pdf', 'png', 'jpeg', 'svg', 'csv'] as const;

type SupportedFormat = (typeof supportedFormats)[number];

type WidgetComponentProps = {
  widget: Widget;
  loading?: boolean;
  fullScreen?: boolean;
  showAsPercentage?: boolean;
  'data-testid'?: string;
};

const useWidgetStyles = makeStyles((theme: Theme) =>
  createStyles({
    emptyError: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    error: {
      fontWeight: 'bold',
      color: theme.palette.text.disabled,
      fontSize: 16,
    },
    center: {
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    widgetContent: {
      display: 'contents',
    },
    widgetLoading: {
      display: 'none',
    },
    progress: {
      color: theme.palette.primary.main,
      width: '50px',
      height: '50px',
      padding: '4px',
      borderRadius: '100%',
    },
  }),
);

const WidgetComponent = (props: WidgetComponentProps) => {
  const { widget, loading = false, showAsPercentage } = props;

  const { t } = useTranslation();
  const classes = useWidgetStyles();

  const Component = useMemo(
    () =>
      withDialogBoundary<any>(widgetDict[widget.chart], {
        fallbackComponent: () => (
          <div className={classes.emptyError}>
            <span className={classes.error}>{t('errorLoadingData')}</span>
          </div>
        ),
      }),
    [widget.chart],
  );

  const id = `${widget._id}-${props.fullScreen ? 'fullscreen' : 'normal'}`;

  const widgetDataset = widget.dataset?.[widget.dataset?.type];

  const by = widgetDataset?.['by'];

  const palette = useMemo(() => {
    if (by === 'state' || by === 'status') {
      return sortBy(Object.entries(statePalette[by]), (x) => x[0]).map((x) => x[1]);
    } else {
      return widget.palette;
    }
  }, [by, widget.palette]);

  return (
    !!Component && (
      <>
        <div className={classes.widgetContent}>
          {widget.data?.length ? (
            <Component
              {...props.widget}
              dataset={widget.dataset || {}}
              data-testid={widget.name + '-' + widget.chart}
              id={id}
              key={widget.chart}
              palette={palette}
              preset={widget.preset}
              showLegend={widget.showLegend}
              showAsPercentage={widgetDataset?.['showAsPercentage'] || showAsPercentage}
              groupBy={widgetDataset?.['groupBy']}
              by={by}
              dataType={widget.dataset?.type || ''}
              result={widget.data}
              minTarget={widgetDataset?.['minTarget']}
              target={widgetDataset?.['target']}
              min={widgetDataset?.['min']}
              max={widgetDataset?.['max']}
            />
          ) : (
            !loading && (
              <div className={classes.emptyError}>
                <span className={classes.error}>{t('noResults')}</span>
              </div>
            )
          )}
        </div>
      </>
    )
  );
};

type WidgetWrapperProps = {
  'data-testid'?: string;
  widget: Widget;
  hideButtons?: boolean;
  showAsPercentage?: boolean;
  loading?: boolean;
  shouldRefresh?: boolean;
  edit?: boolean;
  actions?: {
    refreshWidget?: (force?: boolean) => any;
    duplicateWidget?: () => any;
    changeCard?: (chart: ChartTypeEnum) => any;
    deleteCard?: () => any;
    toggleLegend?: () => any;
  };
} & ConnectedProps<typeof connector>;

const WidgetWrapper = (props: WidgetWrapperProps) => {
  const { actions = {}, showAsPercentage = false, widget, shouldRefresh = false, edit = false, hideButtons } = props;

  const [openWidgetOptions, setOpenWidgetOptions] = useState<boolean>(false);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [editVisible, setEditVisible] = useState<boolean>(false);
  const [editChart, setEditChart] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const popoverRef = useRef(null);
  const [showExtraIcons, setShowExtraIcons] = useState(false);
  const [filterData, setFilterData] = useState<FilterDataType[]>([
    { context: null, type: null, condition: null, values: null, dateValue: null },
  ]);
  const filterDataNoData = useMemo(() => {
    return filterData.some((data) => {
      return Object.values(omit(data, 'context')).every((val) => val === null) && filterData.length === 1;
    });
  }, [filterData]);

  useEffect(() => {
    if (widget.dataset?.[widget.dataset.type]?.customFilters?.length) {
      const mappedCorrectlyFilters = widget.dataset[widget.dataset.type]?.customFilters.map((customFilter) =>
        (customFilter.type.toLowerCase().includes('date') || customFilter.type.toLowerCase().includes('time')) &&
        (!customFilter.dateValue || customFilter.dateValue === null)
          ? { ...customFilter, dateValue: undefined }
          : customFilter,
      );
      setFilterData(mappedCorrectlyFilters);
    } else {
      setFilterData([]);
    }
  }, [widget.dataset?.[widget.dataset?.type]]);

  const onSave = async (updatedFilters?: FilterDataType[]) => {
    setLoading(true);
    const {
      information,
      sort,
      by,
      chartType,
      range,
      filter,
      includeArchives,
      showAsPercentage,
      timeSpan,
      timeUnit,
      decimalDigits,
      ranges,
      _id,
      axis,
    } = editWidgetInfo(widget);
    const timeSpanUnit = timeSpan?.startDate.slice(-1);
    const timeSpanCount = Math.abs(parseInt(timeSpan?.startDate.slice(0, -1)));
    const isSingleValue = ['value', 'gauge'].includes(chartType);

    let timeSpanUnitFormatted = '';

    switch (timeSpanUnit) {
      case 'h':
        timeSpanUnitFormatted = 'hour';
        break;
      case 'd':
        timeSpanUnitFormatted = 'day';
        break;
      case 'w':
        timeSpanUnitFormatted = 'week';
        break;
      case 'm':
        timeSpanUnitFormatted = 'month';
        break;
      case 'y':
        timeSpanUnitFormatted = 'year';
        break;
    }

    try {
      const dataset = buildWidgetDataset({
        information: information as InformationEnum,
        measurement: (widget.dataset.type.endsWith('Count') || widget.dataset.type.endsWith('Total')
          ? 'count'
          : widget.dataset.type.endsWith('Planned')
            ? 'planned'
            : widget.dataset.type.endsWith('Average') || widget.dataset.type.endsWith('MediumValue')
              ? 'average'
              : widget.dataset.type.endsWith('Duration')
                ? 'duration'
                : widget.dataset.type.endsWith('LastValue')
                  ? 'last'
                  : null) as MeasurementType,
        by: by as
          | 'element'
          | 'time'
          | 'assignee'
          | 'assignedLabelValues'
          | 'labelValues'
          | 'state'
          | 'catalog'
          | 'status'
          | 'response',
        chart: chartType,
        ranges,
        range: range as { min: number; max: number },
        tags: filter?.tags as FieldPopoverItem['tag'][],
        elements: filter?.elements as any[],
        catalogs: filter?.catalogs as any,
        assignedAccounts: filter?.assignedAccounts as FieldPopoverItem['account'][],
        assignedLabelValues: filter?.assignedLabelValues as FieldPopoverItem['label'][],
        labelValues: filter?.labelValues as FieldPopoverItem['label'][],
        includeArchives: includeArchives as boolean,
        showAsPercentage: showAsPercentage as boolean,
        timeSpan,
        timeSpanUnit: timeSpanUnitFormatted,
        timeSpanCount,
        timeUnit,
        isSingleValue,
        states: filter?.states as FilterStateEnum[],
        statuses: filter?.statuses as FilterStatusEnum[],
        xAxis: axis?.x as 'issues' | 'responses' | 'assignees' | 'timeSpanAbsolute' | 'timeSpanRelative' | 'sites',
        issueCatalog: filter?.issueCatalog as any,
        input: filter?.input as ISelectListItem,
        groupByResponses: filter?.groupBy as 'hour' | 'day' | 'week' | 'month' | 'year',
        sort,
        decimalDigits,
        customFilters: updatedFilters ? [] : [...filterData],
        responseOptions: filter?.responseOptions as any[],
      });

      await props.updateWidget({ name: widget.name, dataset }, _id).then(async (res) => {
        if (!res.graphQLErrors) {
          toast('success', t('editedWidget'));
          return;
        }
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const context = useMemo(() => {
    let rightContext: string = '';
    switch (true) {
      case widget.dataset?.type.startsWith('issue'):
        rightContext = 'issues';
        break;
      case widget.dataset?.type.startsWith('action'):
        rightContext = 'actions';
        break;
      case widget.dataset?.type.startsWith('response'):
        rightContext = 'responses';
        break;
    }
    return rightContext;
  }, [widget.dataset?.type]);

  const { t } = useTranslation();
  const classes = useStyles();

  const menuOptions = useMemo<DropdownOption[]>(() => {
    const hideChangeCard =
      !edit ||
      (widget.chart === 'line' && widget.preset === 'responsesByTime') ||
      (widget.dataset.type === 'responsesLastValue' &&
        widget?.data?.length &&
        typeof widget?.data[0][widget.data[0].name]?.value === 'string');

    return [
      { id: 'duplicate', label: t('duplicate'), hidden: !edit, icon: <FileCopy /> },
      widget.showLegend
        ? {
            id: 'showLegend',
            label: t('hideLegend'),
            hidden: !chartsWithLegend.some((c) => c === widget.chart),
            icon: <VisibilityOff />,
          }
        : {
            id: 'showLegend',
            label: t('showLegend'),
            hidden: !chartsWithLegend.some((c) => c === widget.chart),
            icon: <Visibility />,
          },
      { id: 'changeCard', label: t('changeCard'), hidden: hideChangeCard, icon: <ChangeCircle /> },
      {
        id: 'export',
        label: t('export'),
        icon: <GetApp />,
        options: [
          { id: 'pdf', label: 'PDF' },
          { id: 'png', label: 'PNG' },
          { id: 'jpeg', label: 'JPEG' },
          { id: 'svg', label: 'SVG' },
          { id: 'csv', label: 'CSV' },
        ],
      },
      { id: 'delete', label: t('delete'), color: 'red', hidden: !edit, icon: <Delete color={'error'} /> },
    ];
  }, [t, edit, widget]);

  const editWidget = useMemo<WidgetInfo | null>(() => {
    if (!editVisible) {
      return null;
    }

    return editWidgetInfo(widget);
  }, [editVisible]);

  const generateCsv = () => {
    if (widget.dataset.realtime) {
      return widget.data;
    }

    const showPercentage = !!widget.dataset[widget.dataset.type]['showAsPercentage'];
    const total = calculateTotal(widget.data);

    return ['name;value']
      .concat(
        widget.data
          .map((inp) => {
            if (inp.metadata) {
              return {
                name: t(inp.metadata[inp.name].name),
                value: renderValue(inp[inp.name], total, showPercentage),
                metadata: inp.metadata[inp.name],
              };
            } else {
              return {
                name: t(inp.name),
                value: renderValue(inp[inp.name] || inp.count || 0, total, showPercentage),
              };
            }
          })
          .map((d) => `${d.name};${d.value}`),
      )
      .join('\n');
  };

  const exportCard = async (format: SupportedFormat) => {
    const chartNode = document.getElementById(widget._id + '-normal');

    const options = {
      width: chartNode.scrollWidth,
      height: chartNode.scrollHeight,
      backgroundColor: 'white',
    };

    const filename = `${widget.name}.${format}`;

    switch (format) {
      case 'svg': {
        const svgURL = new XMLSerializer().serializeToString(chartNode);
        const svgBlob = new Blob([svgURL], { type: `image/svg+xml;charset=utf-8` });
        FileSaver.saveAs(svgBlob, filename);
        break;
      }
      case 'png':
        htmlToImage.toPng(chartNode, options).then((val) => {
          FileSaver.saveAs(val, filename);
        });
        break;
      case 'jpeg': {
        htmlToImage.toJpeg(chartNode, options).then((val) => {
          FileSaver.saveAs(val, filename);
        });
        break;
      }
      case 'csv': {
        const contents = generateCsv();
        const blob = new Blob([contents], { type: 'text/plain' });
        FileSaver.saveAs(blob, filename);
        break;
      }
      case 'pdf': {
        const img = await htmlToImage.toPng(chartNode, options);

        const blob = await pdf(
          <Document>
            <Page>
              <Image src={img} style={{ margin: 32 }} />
            </Page>
          </Document>,
        ).toBlob();

        FileSaver.saveAs(blob, filename);
      }
    }
  };

  const isWidgetPreset = widget.name.includes('t_widget_preset_');
  const widgetLabel = isWidgetPreset ? t(widget.name) : widget.name;

  return (
    <Card
      data-testid={props['data-testid']}
      onMouseEnter={() => setShowExtraIcons(true)}
      onMouseLeave={() => setShowExtraIcons(false)}
      className={`${classes.widget} ${!edit ? classes.allRoundedCorners : ''}`}
    >
      <div className={classes.widgetHeader}>
        <Tooltip title={widget.name} placement={'top'} arrow>
          <span className={classes.widgetName}>{widgetLabel}</span>
        </Tooltip>
        {filterData && filterData.length > 0 && edit && !filterDataNoData && (
          <PieChartFilter
            context={context}
            filterData={filterData}
            setFilterData={setFilterData}
            widgetWrapper={true}
            onSave={onSave}
          />
        )}
        {!hideButtons && (
          <>
            <div className={classes.justifyEnd} />
            <Refreshable
              data-testid={'change-refresh-btn'}
              loading={loading || props.loading}
              hide={!showExtraIcons && !loading && !props.loading}
              onTick={async (force: boolean) => {
                try {
                  setLoading(true);
                  await actions.refreshWidget(force);
                } finally {
                  setLoading(false);
                }
              }}
              refreshTime={shouldRefresh ? 60 : undefined}
            />
            <div css={{ display: showExtraIcons ? 'contents' : 'none' }}>
              <Tooltip title={t('fullScreen').toString()} arrow>
                <IconButton
                  className={classes.widgetConfigure}
                  onClick={() => setFullScreen((fullScreen) => !fullScreen)}
                >
                  <Fullscreen data-testid={'fullscreen-widget'} classes={{ root: classes.icon }} />
                </IconButton>
              </Tooltip>
              {edit && (
                <Tooltip title={t('editWidget').toString()} arrow>
                  <IconButton className={classes.widgetConfigure} onClick={() => setEditVisible(!editVisible)}>
                    <Edit data-testid={'edit-widget'} classes={{ root: classes.icon }} />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <IconButton className={classes.widgetConfigure} onClick={() => setOpenWidgetOptions(true)}>
              <MoreVert
                data-testid={'more-vert-widget'}
                classes={{ root: classes.icon }}
                ref={!fullScreen ? popoverRef : null}
              />
            </IconButton>
          </>
        )}
      </div>
      <div className={`${classes.widgetBody} ${edit ? classes.cursorMove : ''} draggable-handle`}>
        <WidgetComponent loading={loading || props.loading} widget={widget} showAsPercentage={showAsPercentage} />
      </div>
      {fullScreen && (
        <Dialog
          classes={{ paper: classes.fullScreenPaper }}
          open={true}
          onClose={() => setFullScreen(false)}
          maxWidth={'xl'}
        >
          <div className={classes.fullScreenHeader}>
            <h2>{t(widget.name)}</h2>
            <IconButton
              style={{ marginLeft: 'auto' }}
              className={classes.widgetConfigure}
              onClick={() => setFullScreen(false)}
            >
              <FullscreenExit />
            </IconButton>
            <IconButton ref={popoverRef} className={classes.widgetConfigure} onClick={() => setOpenWidgetOptions(true)}>
              <MoreVert />
            </IconButton>
          </div>
          <div className={classes.fullScreenContent}>
            <WidgetComponent
              widget={widget}
              loading={loading || props.loading}
              fullScreen={true}
              showAsPercentage={props.showAsPercentage}
            />
          </div>
        </Dialog>
      )}
      {editVisible ? <NewWidgetModal onClose={() => setEditVisible(false)} edit={editWidget} /> : null}
      {editChart ? (
        <EditChart
          classes={{ paperContainer: classes.overrideHeight, selectionGridItem: classes.overrideGridItem }}
          onChange={(chart) => {
            setEditChart(false);
            return actions.changeCard(chart);
          }}
          onClose={() => setEditChart(false)}
          dataset={widget.dataset}
        />
      ) : null}
      {openWidgetOptions ? (
        <>
          <MultiLevelMenu
            anchorRef={popoverRef}
            options={menuOptions}
            setIsMenuOpen={setOpenWidgetOptions}
            isMenuOpen={true}
            value={null}
            handleChange={(val) => {
              switch (val) {
                case 'showLegend':
                  actions.toggleLegend();
                  break;
                case 'changeCard':
                  setEditChart(true);
                  break;
                case 'duplicate':
                  actions.duplicateWidget();
                  break;
                case 'delete':
                  actions.deleteCard();
                  break;
                default:
                  exportCard(val as SupportedFormat);
              }
              setOpenWidgetOptions(false);
            }}
          />
        </>
      ) : null}
    </Card>
  );
};

const connector = typedConnect(null, { updateWidget });

export default connector(memo(WidgetWrapper));
