import * as React from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';
import { SubjectsContextProvider, subjectsContextInitialValue } from './context/subject.context';
import { SkillGroupsContextProvider, skillGroupsContextInitialValue } from './context/skill-group.context';
import { SkillContextProvider, skillContextInitialValue } from './context/skill.context';
import { SkillMatrixContextProvider, skillMatrixContextInitialValue } from './context/skill-matrix.context';
import SkillSubjectLevels from './skill-subject-levels.default';

const Subjects = React.lazy(() => import('./subjects.default'));
const SkillGroups = React.lazy(() => import('./skill-groups.default'));
const Skill = React.lazy(() => import('./skill.defaut'));
const SubjectMembers = React.lazy(() => import('./subject.members'));
const SkillMatrix = React.lazy(() => import('./matrix.default'));
const SkillLevels = React.lazy(() => import('./skill-levels.default'));

export const render = {
  default: class SubjectDefault extends React.Component<any, any> {
    public render(): JSX.Element {
      return (
        <React.Suspense fallback={<LazyLoadingContent />}>
          <SubjectsContextProvider initialValue={subjectsContextInitialValue}>
            <Subjects {...this.props} />
          </SubjectsContextProvider>
        </React.Suspense>
      );
    }
  },
  skillGroups: class SkillGroupDefault extends React.Component<any, any> {
    public render(): JSX.Element {
      return (
        <React.Suspense fallback={<LazyLoadingContent />}>
          <SkillGroupsContextProvider initialValue={skillGroupsContextInitialValue}>
            <SkillGroups {...this.props} />
          </SkillGroupsContextProvider>
        </React.Suspense>
      );
    }
  },
  skill: class SkillDefault extends React.Component<any, any> {
    public render(): JSX.Element {
      return (
        <React.Suspense fallback={<LazyLoadingContent />}>
          <SkillContextProvider initialValue={skillContextInitialValue}>
            <Skill />
          </SkillContextProvider>
        </React.Suspense>
      );
    }
  },
  members: class MembersDefault extends React.Component<any, any> {
    public render(): JSX.Element {
      return (
        <React.Suspense fallback={<LazyLoadingContent />}>
          <SubjectMembers />
        </React.Suspense>
      );
    }
  },
  matrix: class MatrixDefault extends React.Component<any, any> {
    public render(): JSX.Element {
      return (
        <React.Suspense fallback={<LazyLoadingContent />}>
          <SkillMatrixContextProvider initialValue={skillMatrixContextInitialValue}>
            <SkillMatrix {...this.props} />
          </SkillMatrixContextProvider>
        </React.Suspense>
      );
    }
  },
  levels: class LevelsDefault extends React.Component<any, any> {
    public render(): JSX.Element {
      return (
        <React.Suspense fallback={<LazyLoadingContent />}>
          <SkillLevels />
        </React.Suspense>
      );
    }
  },
  subjectLevels: class SubjectLevelsDefault extends React.Component<any, any> {
    public render(): JSX.Element {
      return (
        <React.Suspense fallback={<LazyLoadingContent />}>
          <SkillSubjectLevels />
        </React.Suspense>
      );
    }
  },
};
export default render;
