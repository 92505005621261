import { Action } from 'src/interfaces/reducers';
import { APOLLO_CLIENT } from 'config/apollo.config';
import { Dispatch } from 'redux';
import { GetTenantInfoDocument, GetTenantInfoQuery, TenantBasicInfo } from 'src/gql/graphql';

export const TENANT_INFO = 'TENANT_INFO';

type AuthenticationState = { tenantInfo: TenantBasicInfo };

const initialState: AuthenticationState = {
  tenantInfo: {},
};

export default (state: AuthenticationState = initialState, action: Action): AuthenticationState => {
  switch (action.type) {
    case TENANT_INFO:
      return {
        ...state,
        tenantInfo: action.payload,
      };
    default:
      return state;
  }
};

export const getTenantInfo =
  (domain) =>
  async (dispatch: Dispatch): Promise<TenantBasicInfo> => {
    const response = await APOLLO_CLIENT.query<GetTenantInfoQuery>({
      variables: {
        domain,
      },
      query: GetTenantInfoDocument,
      fetchPolicy: 'no-cache',
    });

    dispatch({
      type: TENANT_INFO,
      payload: response.data.tenantInfo,
    });

    return response.data.tenantInfo;
  };
