import { User } from './types';

export type StartAgoraMeetingReturn = {
  status: boolean;
  error?: string;
  text?: string;
  roomCode?: string;
};

export enum AgoraIOStates {
  DISCONNECTED = 'DISCONNECTED',
  CONNECTING = 'CONNECTING',
  RECONNECTING = 'RECONNECTING',
  CONNECTED = 'CONNECTED',
  DISCONNECTING = 'DISCONNECTING',
}

export enum AgoraIOReasons {
  CHANNEL_BANNED = 'CHANNEL_BANNED',
  IP_BANNED = 'IP_BANNED',
  LEAVE = 'LEAVE',
  NETWORK_ERROR = 'NETWORK_ERROR',
  SERVER_ERROR = 'SERVER_ERROR',
  UID_BANNED = 'UID_BANNED',
}

/* 
https://docs.agora.io/en/Video/API%20Reference/web_ng/interfaces/networkquality.html
downlinkNetworkQuality: 0 | 1 | 2 | 3 | 4 | 5 | 6
uplinkNetworkQuality: 0 | 1 | 2 | 3 | 4 | 5 | 6

The downlink network quality.
The uplink network quality.

It is calculated based on the uplink transmission bitrate, uplink packet loss rate, RTT (round-trip time) and jitter.

0: The quality is unknown.
1: The quality is excellent.
2: The quality is good, but the bitrate is less than optimal.
3: Users experience slightly impaired communication.
4: Users can communicate with each other, but not very smoothly.
5: The quality is so poor that users can barely communicate.
6: The network is disconnected and users cannot communicate. 
*/
type NetworkQualityRange = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export type AgoraIONetworkQuality = {
  downlinkNetworkQuality: NetworkQualityRange;
  uplinkNetworkQuality: NetworkQualityRange;
};

export type AgoraIOUsers = {
  [key: string]: User;
};

export type VolumeIndicator = {
  uid: string;
  level: number;
};
