import { makeStyles, withStyles } from '@mui/styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { styles } from './styles';
import { useMemo, useRef, useState } from 'react';
import { FilterDataType } from '../..';
import { ExpandMore } from '@mui/icons-material';
import { Popover, useTheme } from '@mui/material';
import { ListItem } from '@mui/material';

type SelectFilterTypeProps = {
  context: string;
  filterData: FilterDataType[];
  index: number;
  setFilterData: (val: FilterDataType[]) => void;
} & WithTranslation;

const issuePossibleFilters = [
  'status',
  'labels',
  'assignedTo',
  'executedBy',
  'creationDate',
  'finishDate',
  'siteElement',
  'startdate',
  'dateUpdated',
];

const actionPossibleFilters = [
  'status',
  'labels',
  'assignedTo',
  'executedBy',
  'creationDate',
  'finishDate',
  'siteElement',
  'dateUpdated',
];

const responsePossbileFilters = ['workOrderStatus', 'executedBy', 'timeRange'];

const useStyles = makeStyles(styles);
const SelectFilterType: React.FC<SelectFilterTypeProps> = (props): JSX.Element => {
  const { t, context, setFilterData, filterData, index } = props;
  const classes = useStyles();
  const anchorRef = useRef();
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const filterType = useMemo(() => filterData[index].type, [filterData[index]]);
  const theme = useTheme();

  const handlePossibleOptions = () => {
    let possibleOptions: string[];

    switch (context) {
      case 'issues':
        possibleOptions = issuePossibleFilters;
        break;
      case 'actions':
        possibleOptions = actionPossibleFilters;
        break;
      case 'responses':
        possibleOptions = responsePossbileFilters;
    }

    return possibleOptions;
  };

  const handleOpen = () => {
    setOpenPopover(true);
  };

  const handleClose = () => {
    setOpenPopover(false);
  };

  const handleClick = (item: string) => {
    const updatedFilterData = [...filterData];
    updatedFilterData[index] = { context, type: item, condition: null, values: null, dateValue: null };
    setFilterData(updatedFilterData);
    setOpenPopover(false);
  };

  return (
    <>
      <div
        className={classes.mainContainer}
        style={{ borderColor: openPopover ? theme.palette.primary.main : '' }}
        ref={anchorRef}
        key={'timespan-current-selected'}
        onClick={handleOpen}
      >
        <span className={filterType?.length === 0 || !filterType ? classes.placeholder : ''}>
          {filterType?.length === 0 || !filterType ? t('selectFilter') : t(filterType)}
        </span>
        <ExpandMore />
      </div>

      <Popover
        anchorEl={anchorRef.current}
        open={openPopover}
        classes={{ paper: classes.popoverContainer }}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {handlePossibleOptions().map((item) => (
          <ListItem
            key={`filter-option-${item}`}
            classes={{ root: filterType === item ? classes.listItemSelected : classes.listItem }}
            onClick={() => filterType !== item && handleClick(item)}
          >
            <span className={filterType === item && classes.selectedSortItem}>{t(`${item}`)}</span>
          </ListItem>
        ))}
      </Popover>
    </>
  );
};

export default withTranslation('translation')(withStyles(styles)(SelectFilterType));
