import { styles } from 'src/utils/components/heat-map/styles';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { MoreVert } from '@mui/icons-material';
import { useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useRef } from 'react';
import { Classes } from '@mui/styles/mergeClasses/mergeClasses';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import i18n from 'src/utils/translations/i18n';
import SelectionItem from './selection-item';
import { column } from './edit-table';
import { idxToDelete } from './group-item';
import { Label } from 'src/gql/graphql';

interface Props {
  classes: Classes;
  column: column[][];
  idx: number;
  labelGroupsSelected: Label[];
  labelValuesIdsByColumn: string[];
  setDuplicatePopover(target: EventTarget): void;
  setChangeColumn(column: column[][]): void;
  setIdxToDelete(item: idxToDelete): void;
  setLabelPopover(target: EventTarget): void;
  setGroupSelected(group: Label): void;
  moveItem(dragIndex: number, hoverIndex: number, type: string): void;
  setSelectedItemIndex(index: number): void;
}

const ColumnItem = (props: Props) => {
  const { classes, column, idx, moveItem, labelValuesIdsByColumn } = props;
  const dragColumnRef = useRef<HTMLDivElement>();
  const dropColumnRef = useRef<HTMLDivElement>();

  const [{ isDragging }, connectDrag, connectPreview] = useDrag({
    type: 'column',
    item: { idx },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, connectDrop] = useDrop({
    accept: 'column',
    collect: (monitor) => ({
      //@ts-ignore
      isOver: monitor.isOver(),
    }),
    hover: (draggedId: { idx }, monitor) => {
      if (!dragColumnRef.current) {
        return;
      }

      const dragIndex = draggedId.idx;
      const hoverIndex = idx;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = dragColumnRef.current?.getBoundingClientRect();
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverActualX = clientOffset.x - hoverBoundingRect.left;

      // if dragging down, continue only when hover is smaller than middle X
      if (dragIndex < hoverIndex && hoverActualX < hoverMiddleX) return;
      // if dragging up, continue only when hover is bigger than middle X
      if (dragIndex > hoverIndex && hoverActualX > hoverMiddleX) return;

      if (dragIndex > hoverIndex) {
        dropColumnRef.current.classList.add(classes.onOverLeft);
      } else {
        dropColumnRef.current.classList.add(classes.onOverRight);
      }
    },
    drop: (draggedId: { idx }, monitor) => {
      if (!dragColumnRef.current) {
        return;
      }

      const dragIndex = draggedId.idx;
      const hoverIndex = idx;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = dragColumnRef.current?.getBoundingClientRect();
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverActualX = clientOffset.x - hoverBoundingRect.left;

      // if dragging down, continue only when hover is smaller than middle Y
      if (dragIndex < hoverIndex && hoverActualX < hoverMiddleX) return;
      // if dragging up, continue only when hover is bigger than middle Y
      if (dragIndex > hoverIndex && hoverActualX > hoverMiddleX) return;

      moveItem(dragIndex, hoverIndex, 'column');

      draggedId.idx = hoverIndex;
    },
  });

  useEffect(() => {
    if (!dropColumnRef.current) return;

    if (isDragging) dropColumnRef.current.classList.add(classes.onDrag);
    else dropColumnRef.current.classList.remove(classes.onDrag);
  }, [isDragging]);

  useEffect(() => {
    if (!dropColumnRef.current) return;

    if (!isOver) {
      dropColumnRef.current.classList.remove(classes.onOverRight);
      dropColumnRef.current.classList.remove(classes.onOverLeft);
    }
  }, [isOver]);

  connectDrag(dragColumnRef);
  connectDrop(dropColumnRef);
  connectPreview(dropColumnRef);

  const hide = isDragging ? { display: 'none' } : {};

  return (
    <div className={`${classes.divColumn}`} ref={dropColumnRef} key={idx}>
      <div
        ref={dragColumnRef}
        className={classes.groupTable}
        style={{ minHeight: '0px', border: 'none', position: 'sticky', top: '0', width: '100%', ...hide }}
        key={idx}
      >
        <div className={classes.divHeader} style={{ width: '100%' }}>
          <Typography>{`${i18n.t('column')} ${idx + 1}`}</Typography>
          <MoreVert
            fontSize='small'
            onClick={(e) => {
              props.setDuplicatePopover(e.target);
              props.setIdxToDelete({
                type: 'column',
                idx: idx,
              });
              props.setChangeColumn(column);
            }}
            data-testid={`column-${idx}-options`}
            className={classes.moreVertIcon}
          />
        </div>
      </div>

      <div style={{ ...hide }}>
        {props.labelGroupsSelected.map((label, i) => (
          <SelectionItem
            index={i}
            label={label}
            column={column}
            columnToEdit={column}
            idx={idx}
            labelValuesIdsByColumn={labelValuesIdsByColumn}
            setLabelPopover={props.setLabelPopover}
            setGroupSelected={props.setGroupSelected}
            setChangeColumn={props.setChangeColumn}
            setIdxToDelete={props.setIdxToDelete}
            setSelectedItemIndex={props.setSelectedItemIndex}
          />
        ))}
      </div>
    </div>
  );
};

export default compose<any>(withTranslation('translation'), withStyles(styles))(ColumnItem);
