import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';

const SiteCreatorDefaultRefresh = () => lazy(() => import('src/modules/site/site.create.default'));
const SiteCreateTemplateRefresh = () => lazy(() => import('src/modules/site/site.create.template'));
const SiteSelectDefaultRefresh = () => lazy(() => import('src/modules/site/site.select.default'));

let SiteCreatorDefault = SiteCreatorDefaultRefresh();
let SiteCreateTemplate = SiteCreateTemplateRefresh();
let SiteSelectDefault = SiteSelectDefaultRefresh();

export const render = {
  create: {
    default: ({ ...props }) => {
      if (SiteCreatorDefault?._result?.type === 'error') {
        SiteCreatorDefault = SiteCreatorDefaultRefresh();
      }
      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <SiteCreatorDefault {...props} />
        </Suspense>
      );
    },
    template: ({ ...props }) => {
      if (SiteCreateTemplate?._result?.type === 'error') {
        SiteCreateTemplate = SiteCreateTemplateRefresh();
      }
      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <SiteCreateTemplate {...props} />
        </Suspense>
      );
    },
  },
  select: {
    default: ({ ...props }) => {
      if (SiteSelectDefault?._result?.type === 'error') {
        SiteSelectDefault = SiteSelectDefaultRefresh();
      }
      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <SiteSelectDefault {...props} />
        </Suspense>
      );
    },
  },
};

export default render;
