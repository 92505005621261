import DefaultButton, { ButtonProps } from '../default-button/default-button';
import { FilterList } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Badge, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface GlarFilterButtonProps extends ButtonProps {
  'data-testid': any;
  onClick: () => any;
  filterCount: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  filterCount: {
    '& .MuiBadge-badge': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      background: theme.palette.background.default,
    },
    marginLeft: '4px',
    marginRigth: '8px',
  },
  btnDimensionsNoFilters: {
    height: '32px',
    width: '97px',
  },
  btnDimensionsFilters: {
    height: '32px',
    width: '115px',
  },
}));

export default function GlarFilterButton(props: GlarFilterButtonProps) {
  const { 'data-testid': dataTestid, filterCount = 0, ...buttonProps } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <DefaultButton
      data-testid={dataTestid}
      onClick={props.onClick}
      rounded
      variant={filterCount > 0 ? 'default' : 'discard'}
      className={filterCount > 0 ? classes.btnDimensionsFilters : classes.btnDimensionsNoFilters}
      {...buttonProps}
    >
      <FilterList style={{ fontSize: '20px', marginRight: 0 }}></FilterList>
      {t('filter')}
      {filterCount > 0 && <Badge badgeContent={filterCount} classes={{ root: classes.filterCount }} />}
    </DefaultButton>
  );
}
