import { RoomFeatureList } from './feature';
//import { EntityRole, UserRole } from './user'; // TODO: analisar depois questao das roles

export enum VideoTrackType {
  NONE = 'none',
  SCREEN_TRACK = 'screen_track',
}

export interface Session {
  id: string;
  is_guest?: string;
  create_at?: number;
  last_activity_at?: number;
  user_id?: string;
  room_id?: string;
  token?: string;
  user_email?: string;
  approved?: boolean;
  props?: null | { [key: string]: string };
  update_at: number;
  expires_at: number;
  device_id?: string;
  // roles?: UserRole;
  // entity_roles?: EntityRole;
  is_oauth: boolean;
  details?: SessionDetails;
  user_contact_list?: Map<string, unknown>;
}

export interface SessionDetails {
  name?: string;
  microphone: boolean;
  video_track: boolean;
  video_track_type: VideoTrackType | '';
  capabilities: null | { [key: string]: string };
  room_features?: null | RoomFeatureList;
  current_state?: null | { [key: string]: number };
}

export const defaultUserDetails = (rtc_uid: string, name: string, microphone?: boolean): SessionDetails => {
  return {
    microphone: microphone ?? true,
    video_track: false,
    video_track_type: VideoTrackType.NONE,
    name,
    capabilities: { device_type: '11', rtc_uid: rtc_uid },
    current_state: {
      color: 1,
    },
  };
};

export const assistUserDetails = (session: SessionDetails): SessionDetails => {
  return {
    ...session,
    video_track: true,
    capabilities: {
      ...session.capabilities,
      is_ar_supported: 'false',
      is_delete_supported: 'false',
      is_torch_supported: 'false',
      is_draw_supported: 'true',
      is_freeze_supported: 'false',
    },
    current_state: {
      ...session.current_state,
      torch: 0,
      volume: 0,
      live_mode: 1,
      freeze: 0,
      tracking: 0,
    },
  };
};

export const assistantUserDetails = (session: SessionDetails): SessionDetails => {
  return {
    ...session,
    video_track: false,
    capabilities: {
      device_type: '11',
      rtc_uid: session.capabilities?.rtc_uid,
    },
    current_state: {
      color: session.current_state?.color,
    },
  };
};
