import { IssueExecutionState } from '../types/issue.execution.type';
import { checkRequiredInputs, checkToolReservation, totalTasks } from 'src/utils/funcs/issue';
import { stringifyUrl } from 'query-string';
import { IssueInstance } from 'src/gql/graphql';

export const changeState = async (
  translation: (key: string) => string,
  changeStateIssueInstance: (
    data: { token: string; action: string; stateMachineId: string },
    id: string,
    note?: string,
  ) => Promise<IssueInstance>,
  issue: IssueInstance,
  action: string,
  showSnackbar: (type: string, message: string) => void,
  note?: string,
  todo?: string,
  changeByConditionalInput?: boolean,
  navigate?,
  doingOnBoarding?: boolean,
): Promise<Partial<IssueExecutionState>> => {
  const data: Partial<IssueExecutionState> = {
    loadingButtons: false,
    issue: issue,
    issueAction: action,
    openSiteElementDialog: false,
    openToolReservation: false,
    openDescriptionModal: false,
    openPreview: false,
    openCantFinnishTask: false,
    uncompletedDialog: false,
  };

  if (action === 'start' && !issue.element && !issue.site) {
    data.openSiteElementDialog = true;
    return data;
  }

  const hasToolToReserve = checkToolReservation(issue);

  if (action === 'start' && hasToolToReserve) {
    data.openToolReservation = true;
    return data;
  }

  if (issue.stateMachineInstance?.currentState == 'Pending' && issue.requestDescription && !issue.description) {
    data.openDescriptionModal = true;
    return data;
  }

  if (
    issue?.acknowledgeRequired &&
    issue.stateMachineInstance?.currentState === issue.stateMachineInstance.catalog.initState &&
    issue.needsAcknowledge
  ) {
    data.openPreview = true;
    return data;
  }

  if (
    issue.completedTasksCount < totalTasks(issue) &&
    action !== 'start' &&
    action !== 'resume' &&
    action !== 'pause' &&
    !changeByConditionalInput
  ) {
    const requiredInputs = checkRequiredInputs(issue);
    if (requiredInputs) {
      data.openCantFinnishTask = true;
      return data;
    } else {
      data.uncompletedDialog = true;
      return data;
    }
  }

  const resp = await changeStateIssueInstance(
    {
      token: null,
      stateMachineId: issue.stateMachineInstance._id,
      action,
    },
    issue._id,
    note ? note : null,
  );

  if (resp && !resp['graphQLErrors'] && !doingOnBoarding) {
    data.issue = { ...data.issue, ...resp };
    data.issueAction = '';
    if (
      issue.stateMachineInstance.states
        .filter((st) => st.name === issue.stateMachineInstance.currentState)[0]
        .transitions.filter((t) => t.isFinal === true && t.action === action).length !== 0
    ) {
      navigate(
        stringifyUrl(
          {
            url: '/issues',
            query: todo
              ? {
                  todo,
                }
              : undefined,
          },
          { arrayFormat: 'comma' },
        ),
      );
      showSnackbar('success', translation('issueFinishedWithSuccess'));
    }

    return data;
  }

  return {};
};
