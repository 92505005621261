import { styles } from 'src/utils/components/navigation-bar/styles';
import * as React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';
import InputField from 'src/utils/components/input-field/input-field';
import { Tab, Tabs, Grid, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';

const NavigationBar: React.FC = (props: any) => {
  const { state, stTitle, ndTitle, additionalTabs } = props;
  const tabActive = props.tabActive || state.tabActive;
  const { t } = useTranslation();

  return (
    <>
      <Grid container classes={{ root: props.classes.nav }}>
        <Grid classes={{ root: !props.isIssueCreation ? props.classes.switchDiv : props.classes.rowWithSwitch }} item>
          <Tabs
            classes={{ root: props.classes.tabs, indicator: props.classes.indicator }}
            scrollButtons={false}
            variant={props.isIssueCreation ? 'standard' : 'fullWidth'}
            value={tabActive}
            onChange={(_e, value) => props.setTabActive(value)}
          >
            <Tab
              value={1}
              key={1}
              label={t(stTitle)}
              id={`tab1`}
              classes={{ selected: props.classes.tabSelected, root: props.classes.tab }}
              disableRipple
              disableFocusRipple
              wrapped
            />
            <Tab
              value={2}
              key={2}
              id={`tab2`}
              label={t(ndTitle)}
              classes={{ selected: props.classes.tabSelected, root: props.classes.tab }}
              disableRipple
              disableFocusRipple
              wrapped
            />
            {additionalTabs?.map((item: { name: string }, i: number) => (
              <Tab
                key={i + 2}
                value={i + 3}
                id={`tab${i + 3}`}
                label={t(item.name)}
                classes={{ selected: props.classes.tabSelected, root: props.classes.tab }}
                disableRipple
                disableFocusRipple
                wrapped
              />
            ))}
          </Tabs>
        </Grid>
        {props.isIssueCreation && tabActive === 3 ? (
          <Grid item>
            <div className={props.classes.switchDiv}>
              <Tooltip arrow title={props.switchValue ? t('tasksSeq').toString() : t('tasksNotSeq').toString()}>
                <span className={props.classes.spanInputHeader}>
                  <InputField
                    t={t}
                    inline
                    labelWhite={true}
                    id={'inputSwitchSequencial'}
                    title={props.switchName}
                    type={'checkbox'}
                    disabled={props.switchDisabled}
                    handleChange={props.onChangeSwitch}
                    value={props.switchValue}
                  />
                </span>
              </Tooltip>
            </div>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default compose<any>(withTranslation('translation'), withStyles(styles))(NavigationBar);
