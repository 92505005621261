import { Chip, IconButton, ListItem, Tooltip, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import * as React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { OptionInputType, PresetWhereInput } from 'src/gql/graphql';
import { Input } from 'src/interfaces/inputs';
import { editPreset } from 'src/modules/issue-templates/issue.templates.redux';
import { styles } from 'src/utils/components/selection-presets-popover/components/preset-item/styles';
import { createPortal } from 'react-dom';
import {
  withIssueTemplateConnector,
  WithIssueTemplateConnectorProps,
} from 'src/modules/issue-templates/redux/issue.template.draft.redux';
import { WithStyles, withStyles } from '@mui/styles';
import useAtomicCallback from 'src/utils/hooks/useAtomicCallback';

interface PresetItemProps
  extends WithIssueTemplateConnectorProps,
    ConnectedProps<typeof connector>,
    WithStyles<typeof styles> {
  preset: PresetWhereInput;
  input: Input;
  listItemHovered: boolean;
  key: number;
  setInput: (input: any) => void;
  scoreInputs: boolean;
  setListItemHovered: (val: boolean) => void;
  setOpenEditResponses: (val: boolean) => void;
  setSelectedPreset: (val: PresetWhereInput) => void;
  setOpen: (val: boolean) => void;
}

type OptionsPortalProps = {
  options: OptionInputType[];
  listItemHovered: boolean;
  classes?: Record<string, string>;
  setFilteredItems: (option: OptionInputType[]) => void;
  preset: PresetWhereInput;
};

const div = document.createElement('div');

const OptionsPortal: React.FC<OptionsPortalProps> = (props): JSX.Element => {
  const { options, classes, listItemHovered, preset, setFilteredItems } = props;
  const [container] = React.useState(document.createElement('div'));

  React.useEffect(() => {
    const filteredItemsInWidth: OptionInputType[] = [];
    let newTotalWidth = 0;

    for (const option of preset.options) {
      const containerDummy = container;
      containerDummy.style.width = 'fit-content';
      containerDummy.style.maxWidth = '94px';
      containerDummy.style.paddingLeft = '8px';
      containerDummy.style.paddingRight = '8px';
      containerDummy.innerHTML = option.response;

      div.appendChild(containerDummy);

      const chipWidth = containerDummy.offsetWidth;

      if (newTotalWidth + chipWidth <= 200) {
        filteredItemsInWidth.push(option);
        newTotalWidth += chipWidth;
      } else {
        break;
      }
    }
    setFilteredItems(filteredItemsInWidth);
  }, [preset, options]);

  React.useEffect(() => {
    document.body.appendChild(div);
    return () => {
      if (document.body.contains(div)) {
        document.body.removeChild(div);
      }
    };
  }, []);

  return createPortal(
    <div>
      {options.map((option, index) => (
        <Chip
          key={index}
          className={!listItemHovered ? classes.responsesChip : classes.responsesChipHovered}
          size='small'
          style={{ whiteSpace: 'nowrap' }}
          label={<Typography style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{option.response}</Typography>}
        />
      ))}
    </div>,
    div,
  );
};

const PresetItem: React.FC<PresetItemProps> = (props): JSX.Element => {
  const {
    classes,
    preset,
    input,
    listItemHovered,
    key,
    setListItemHovered,
    editPreset,
    setInput,
    setOpenEditResponses,
    setSelectedPreset,
    setOpen,
    issueTemplate,
    updateIssueTemplateDraftState,
  } = props;
  const { t } = useTranslation();
  const [filteredItems, setFilteredItems] = React.useState<OptionInputType[]>([]);

  const onClickPreset = useAtomicCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _id, order, ...presetNoID } = preset;
    const response = await editPreset(
      issueTemplate._id,
      {
        ...presetNoID,
        inputs: [input._id],
      },
      preset._id,
    );
    updateIssueTemplateDraftState({
      frame: { ...issueTemplate?.frame, presets: response.frame.presets },
    });
    await setInput(
      input.type === 'selection'
        ? {
            _id: input._id,
            [input.type]: {
              ...input[input.type],
              options: preset.options.map((option, index) => ({
                id: index,
                label: option.response,
                value: option.response,
                order: index,
                _id: `id-${index}`,
              })),
              optionsWithScore: props.scoreInputs
                ? preset.options.map((option) => ({
                    option: option.response,
                    score: 0,
                  }))
                : null,
              onResponse: [],
              preset: {
                presetId: _id,
              },
            },
          }
        : {
            _id: input._id,
            type: 'selection',
            selection: {
              options: preset.options.map((option, index) => ({
                id: index,
                label: option.response,
                value: option.response,
                order: index,
                _id: `id-${index}`,
              })),
              optionsWithScore: props.scoreInputs
                ? preset.options.map((option) => ({
                    option: option.response,
                    score: 0,
                  }))
                : null,
              multiple: false,
              values: [],
              onResponse: [],
              preset: {
                presetId: _id,
              },
            },
            [input.type]: undefined,
          },
    );
    setOpen(false);
  }, [preset, input]);

  return (
    <ListItem
      className={classes.responsesItem}
      button
      onMouseOver={() => setListItemHovered(true)}
      onMouseOut={() => setListItemHovered(false)}
      key={key}
      data-testid={`select-preset-${key}`}
    >
      <div
        style={{
          width: '100%',
          padding: '0 6px 0 16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{ width: '100%', overflowX: 'clip' }}
          className={classes.responsesChipContainer}
          onClick={onClickPreset}
        >
          {filteredItems?.map((option, index) => (
            <Tooltip title={option.response} key={index}>
              <Chip
                key={index}
                className={!listItemHovered ? classes.responsesChip : classes.responsesChipHovered}
                size={'small'}
                style={{ whiteSpace: 'nowrap' }}
                label={
                  <Typography style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{option.response}</Typography>
                }
              />
            </Tooltip>
          ))}

          <OptionsPortal
            options={filteredItems}
            preset={preset}
            setFilteredItems={setFilteredItems}
            listItemHovered={listItemHovered}
            classes={classes}
          />

          {preset.options && preset.options.length - filteredItems.length > 0 && (
            <Chip
              className={!listItemHovered ? classes.responsesChip : classes.responsesChipHovered}
              size={'small'}
              style={{ whiteSpace: 'nowrap' }}
              label={
                <Typography
                  style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                >{`+${preset.options?.length - filteredItems?.length}`}</Typography>
              }
            />
          )}
        </div>
        <Tooltip title={t('edit')}>
          <IconButton
            data-testid={`edit-preset-${key}`}
            style={{ padding: '8px' }}
            onClick={() => {
              setOpenEditResponses(true);
              setSelectedPreset({
                ...preset,
                inputs: !preset.inputs.includes(input._id) ? [...preset.inputs, input._id] : preset.inputs,
              });
            }}
            size='large'
          >
            <Edit className={classes.responsesEdit} />
          </IconButton>
        </Tooltip>
      </div>
    </ListItem>
  );
};

const connector = connect(null, {
  editPreset,
});

export default compose<any>(
  withTranslation('translation'),
  connector,
  withStyles(styles),
  withIssueTemplateConnector,
)(PresetItem);
