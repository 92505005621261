import { ArrowBackIos } from '@mui/icons-material';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DefaultButton from 'src/utils/components/default-button/default-button';
import { styles } from './styles';

const useStyles = makeStyles(styles);

interface MainHeaderLeftProps {
  pageTitle: string;
  setOpenAreYouSureDialog?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MainHeaderLeft = ({ pageTitle, setOpenAreYouSureDialog }: MainHeaderLeftProps) => {
  const classes: ClassNameMap<string> = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.headerLeftContainer}>
      <span
        className={classes.arrowBack}
        onClick={() => {
          setOpenAreYouSureDialog ? setOpenAreYouSureDialog(true) : navigate(-1);
        }}
        data-testid='btn-back'
      >
        <ArrowBackIos style={{ fontSize: '20px', marginRight: '17px' }} />
      </span>
      <Typography variant='h3'>{pageTitle}</Typography>
    </div>
  );
};

export const MainHeaderRight = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <div>
      <DefaultButton variant='discard' onClick={onClick} data-testid='btn-edit' size='medium' icon='edit'>
        {t('editLevels')}
      </DefaultButton>
    </div>
  );
};
