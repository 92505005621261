import i18n from 'i18next';
import translationEN from 'src/utils/translations/en-US.json';
import translationPT from 'src/utils/translations/pt-PT.json';
import translationES from 'src/utils/translations/es.json';
import translationFR from 'src/utils/translations/fr.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: translationEN,
  },
  pt: {
    translation: translationPT,
  },
  es: {
    translation: translationES,
  },
  fr: {
    translation: translationFR,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en',
    react: {
      useSuspense: false,
    },
    ns: ['translation'],
    defaultNS: 'translation',
    debug: false,
    resources,
    interpolation: { escapeValue: false },
  })
  .then(() => '')
  .catch((e) => console.error('i18n: ', e));

export default i18n;
