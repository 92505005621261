import { Suspense } from 'react';
import { Popper, useTheme } from '@mui/material';
import { styles } from './styles';
import { CommentMentionCreateInput, useIssuesAndActionsSuspenseQuery } from 'src/gql/graphql';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(styles);

interface IssueMentionProps {
  anchorEl: HTMLElement;
  textToAutocomplete: string;
  onComplete: (mention: CommentMentionCreateInput) => any;
}

export default function IssueMention(props: IssueMentionProps) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Popper
      className={classes.paper}
      placement={'bottom'}
      open={true}
      anchorEl={props.anchorEl}
      style={{ width: props.anchorEl.getBoundingClientRect().width }}
    >
      <Suspense fallback={<ReactLoading type={'spin'} color={theme.palette.text.secondary} height={50} width={50} />}>
        <FetchIssueMention {...props} />
      </Suspense>
    </Popper>
  );
}

function FetchIssueMention(props: IssueMentionProps) {
  const { textToAutocomplete } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const isInt = !isNaN(textToAutocomplete as any);

  const { data } = useIssuesAndActionsSuspenseQuery({
    variables: {
      issueFilter: {
        glarID_contains: isInt ? textToAutocomplete : undefined,
        name_contains: !isInt ? textToAutocomplete : undefined,
      },
      actionFilter: {
        ID_eq: isInt ? parseInt(textToAutocomplete) : undefined,
        name_contains: !isInt ? textToAutocomplete : undefined,
      },
      skip: 0,
      limit: 5,
    },
    fetchPolicy: 'cache-first',
  });

  return data?.issuesAndActions.map((iss) => (
    <div
      key={iss._id}
      data-testid={'complete-' + iss.name}
      className={classes.listItem}
      onClick={() => {
        const type = iss.__typename === 'IssueInstance' ? 'issue' : ('action' as any);

        props.onComplete({
          type,
          [type]: iss._id,
          text: `#${iss.__typename === 'IssueInstance' ? iss.glarID.slice(1) : iss.ID} - ${iss.name}`,
        });
      }}
    >
      <span>
        {iss.__typename === 'IssueInstance' ? (
          <>
            <span className={classes.glarHash}>{iss.glarID}</span>
            <span>{` - ${iss.name}`}</span>
          </>
        ) : (
          `${iss.ID} - ${iss.name}`
        )}
      </span>
      <span className={classes.disabled}>{t(iss.__typename)}</span>
    </div>
  ));
}
