import * as React from 'react';

export default function useDebounce<T extends (...args: never[]) => unknown>(
  effect: T,
  dependencies: React.DependencyList,
  delay: number,
): void {
  const callback = React.useCallback<T>(effect, dependencies);

  React.useEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => clearTimeout(timeout);
  }, [callback, delay]);
}
