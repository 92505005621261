import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';

const SSOCreateDefaultRefresh = () => lazy(() => import('src/modules/sso/sso.create.default'));

let SSOCreateDefault = SSOCreateDefaultRefresh();

export const render = {
  create: {
    default: ({ ...props }) => {
      if (SSOCreateDefault?._result?.type === 'error') {
        SSOCreateDefault = SSOCreateDefaultRefresh();
      }
      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <SSOCreateDefault {...props} />
        </Suspense>
      );
    },
  },
};

export default render;
