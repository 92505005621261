import { Typography } from '@mui/material';
import { useRef, useEffect, useState } from 'react';
import { CheckCircle } from '@mui/icons-material';
import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { styles } from 'src/base/on-boarding/components/styles';
import { useMainContainerContext } from 'src/base/main-container/context/main.container.context';
import OnBoardingStepPopper from 'src/base/on-boarding/components/step.popper';
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useTranslation } from 'react-i18next';
import { GlarState } from 'src/reducer-manager';
import { useLocation, useNavigate } from 'react-router-dom';

type OnBoardingStepsProps = ConnectedProps<typeof connecter>;

const useStyles = makeStyles(styles);
const OnBoardingSteps: React.FC<OnBoardingStepsProps> = (props): JSX.Element => {
  const state = useMainContainerContext();
  const { loggedUser } = props;
  const classes: ClassNameMap<string> = useStyles();
  const templateStepRef = useRef<HTMLDivElement>();
  const openIssueStepRef = useRef<HTMLDivElement>();
  const executionStepRef = useRef<HTMLDivElement>();
  const allSteps = ['build', 'create', 'execute'];
  const [refCurrent, setRefCurrent] = useState<HTMLDivElement>(null);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const renderStep = (step: string, index: number): JSX.Element => {
    let stepData = { ref: null, label: '' };
    switch (step) {
      case 'build':
        stepData = { ref: templateStepRef, label: t('buildTemplate') };
        break;
      case 'create':
        stepData = { ref: openIssueStepRef, label: t('createWorkOrder') };
        break;
      case 'execute':
        stepData = { ref: executionStepRef, label: t('executeWorkOrder') };
        break;
    }

    return (
      <React.Fragment key={index}>
        <div
          ref={stepData.ref}
          className={`${classes.onBoardingStep} ${!loggedUser.onBoarding.availableSteps.find((aStep: string) => aStep.includes(step)) && !loggedUser.onBoarding.step.includes(step) && classes.onBoardingFinishedStepContainer}`}
        >
          {!loggedUser.onBoarding.availableSteps.find((aStep: string) => aStep.includes(step)) &&
          !loggedUser.onBoarding.step.includes(step) ? (
            <CheckCircle fontSize={'medium'} />
          ) : (
            <Typography
              style={{ padding: index + 1 !== 1 ? '0 8px' : '0 10px' }}
              className={
                !state.currentStep?.includes(step) ? classes.onBoardingStepNumberDisabled : classes.onBoardingStepNumber
              }
            >
              {index + 1}
            </Typography>
          )}
          <Typography
            classes={{
              root:
                !loggedUser.onBoarding.availableSteps.find((aStep: string) => aStep.includes(step)) &&
                !loggedUser.onBoarding.step.includes(step)
                  ? classes.stepFinishedText
                  : !state.currentStep?.includes(step)
                    ? classes.onBoardingStepTextDisabled
                    : '',
            }}
          >
            {stepData.label}
          </Typography>
        </div>
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (state.currentStep !== 'finish' && state.currentStep !== 'unInitialized') {
      let newRef: React.MutableRefObject<HTMLDivElement>;
      switch (state.currentStep) {
        case 'build':
          if (location.pathname !== '/templates/issues/issue')
            navigate(`/templates/issues/issue?id=${loggedUser.onBoarding?.templateDraft._id}&edit=true`);
          newRef = templateStepRef;
          break;
        case 'createIssue':
          newRef = openIssueStepRef;
          break;
        case 'execute':
          newRef = executionStepRef;
          break;
      }

      setRefCurrent(newRef?.current);
    }
  }, [state.currentStep]);

  return (
    <div className={`${classes.onBoardingStepsContainer} ${state.hideSteps ? '' : 'show'} `}>
      {allSteps.map((step, index) => renderStep(step, index))}
      {state.open && <OnBoardingStepPopper refCurrent={refCurrent} />}
    </div>
  );
};

const connecter = connect(
  (state: GlarState) => ({
    loggedUser: state.loginReducer.loggedUser,
  }),
  {},
);

export default connecter(OnBoardingSteps);
