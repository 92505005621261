import { PostWhereInput } from 'src/gql/graphql';
import { IFeedFilter } from 'src/modules/feed/feed.redux';

export const buildOrFilter = (filters: IFeedFilter): PostWhereInput['OR'] => {
  const orFilter = [];

  // OR filter for search by subject/description/createdBy

  if (filters.search) {
    orFilter.push(
      { subject_contains: filters.search },
      { description_contains: filters.search },
      { createdBy: { name_contains: filters.search } },
    );
  }

  return orFilter.length ? orFilter : undefined;
};
