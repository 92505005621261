import { createContext, ReactNode, useCallback, useContext } from 'react';

export type GlarFilterMap = Record<string, any>;

export interface GlarFilter {
  accessor: string;
  value: any;
}

interface GlarFilterContextProviderProps {
  children: ReactNode;
  items: GlarFilterMap;
  setItems: React.Dispatch<React.SetStateAction<GlarFilterMap>>;
}

const GlarFilterContext = createContext<{
  items: GlarFilterMap;
  setItems: React.Dispatch<React.SetStateAction<GlarFilterMap>>;
}>(null);

export const useGlarFilterContext = (accessor: string) => {
  const { items, setItems } = useContext(GlarFilterContext);

  const updateItem = useCallback(
    (value) =>
      setItems((items) => ({
        ...items,
        [accessor]: Array.isArray(value) && !value.length ? undefined : value,
      })),
    [],
  );

  return { value: items[accessor], updateItem };
};

export default function GlarFilterContextProvider({ children, items, setItems }: GlarFilterContextProviderProps) {
  return <GlarFilterContext.Provider value={{ items, setItems }}>{children}</GlarFilterContext.Provider>;
}
