import { Dialog, Divider, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateTenantMutation, useSingleTenantQuery, useUpdateTenantMutation } from 'src/gql/graphql';
import DefaultButton from 'src/utils/components/default-button/default-button';
import InputGeneric from 'src/utils/components/input-field/input-generic';
import LabelInput from 'src/utils/components/input-field/input-label';
import { css } from '@emotion/css';
import { platformLanguages } from '../account/profile/components/preferences/languages';
import { useRefresh } from 'src/utils/components/refresh-provider';
import { DeleteModal } from 'src/utils/components/delete-modal';
import { useSnackbar } from 'notistack';
import { ToastMessage } from '../feed/components/toast';

export enum TenantModalMode {
  Create,
  Update,
}

type CreateTenantModalProps = {
  onClose: () => any;
} & (
  | {
      mode: TenantModalMode.Create;
    }
  | { mode: TenantModalMode.Update; tenantId: string }
);

export function CreateTenantModal(props: CreateTenantModalProps) {
  const { t } = useTranslation();

  const triggerRefresh = useRefresh();

  const { enqueueSnackbar } = useSnackbar();

  const onError = (error) => {
    enqueueSnackbar(<ToastMessage severity='error' message={error.message} />, {
      variant: 'error',
    });
  };

  const onCompleted = () => {
    enqueueSnackbar(<ToastMessage severity='success' message={t('changesSaved')} />, {
      variant: 'success',
    });

    props.onClose();
    triggerRefresh();
  };

  const [createTenant, { loading: loadingCreate }] = useCreateTenantMutation({
    onCompleted,
    onError,
  });
  const [updateTenant, { loading: loadingUpdate }] = useUpdateTenantMutation({
    onCompleted,
    onError,
  });

  const [name, setName] = useState('');
  const [domain, setDomain] = useState('');
  const [language, setLanguage] = useState('');
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState(null);

  const disabled = useMemo(() => {
    if (!domain) {
      return true;
    }

    if (!name) {
      return true;
    }

    if (!language || (props.mode === TenantModalMode.Create && !email)) {
      return true;
    }

    return false;
  }, [name, domain, language, userName, email]);

  const { data: tenantData } = useSingleTenantQuery({
    variables: { where: { _id: props['tenantId'] } },
    skip: props.mode !== TenantModalMode.Update,
    onCompleted(data) {
      setName(data.tenant.name);
      setDomain(data.tenant.domain);
      setLanguage(
        platformLanguages.find((l) => l.id === data.tenant.config.language)?.label ?? platformLanguages[0].label,
      );
    },
    onError,
  });

  const inputDisabled = !tenantData && TenantModalMode.Update === props.mode;

  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      classes={{
        paper: css`
          width: 965px;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          padding: 16px;
          gap: 16px;
        `,
      }}
    >
      <Typography css={{ textAlign: 'center', fontSize: 20, fontWeight: 500 }}>
        {t(props.mode === TenantModalMode.Create ? 'newTenant' : 'editTenant')}
      </Typography>
      <div>
        <LabelInput required={true}>{t('tenantName')}</LabelInput>
        <InputGeneric
          editable={true}
          disabled={inputDisabled}
          placeholder={t('addTenantName')}
          data-testid={'create-tenant-name'}
          type={'string'}
          onlyText
          onlyInput
          values={[name]}
          handleChange={(v) => setName(v[0])}
        />
      </div>
      <div>
        <LabelInput required>{t('domainName')}</LabelInput>
        <InputGeneric
          editable={true}
          disabled={inputDisabled}
          placeholder={t('addDomainName')}
          data-testid={'create-tenant-domain-name'}
          type={'string'}
          onlyText
          onlyInput
          values={[domain]}
          handleChange={(v) => setDomain(v[0])}
        />
      </div>
      <div>
        <LabelInput required>{t('language')}</LabelInput>
        <InputGeneric
          editable={true}
          disabled={inputDisabled}
          placeholder={t('selectLanguage')}
          data-testid={'create-tenant-language'}
          type={'selection'}
          onlyText
          onlyInput
          options={platformLanguages}
          values={[language]}
          handleChange={(v) => setLanguage(v[0])}
        />
      </div>
      {props.mode === TenantModalMode.Create && (
        <>
          <Divider />
          <div>
            <LabelInput>{t('name')}</LabelInput>
            <InputGeneric
              editable={true}
              disabled={inputDisabled}
              placeholder={t('addUserName')}
              data-testid={'create-tenant-user-name'}
              type={'string'}
              onlyText
              onlyInput
              values={[userName]}
              handleChange={(v) => setUserName(v[0])}
            />
          </div>
          <div>
            <LabelInput required>{t('email')}</LabelInput>
            <InputGeneric
              editable={true}
              disabled={inputDisabled}
              placeholder={t('addEmail')}
              data-testid={'create-tenant-email'}
              type={'string'}
              onlyText
              onlyInput
              values={[email]}
              handleChange={(v) => setEmail(v[0])}
            />
          </div>
        </>
      )}
      <div css={{ display: 'flex', justifyContent: 'center', gap: 16 }}>
        <DefaultButton data-testid={'create-tenant-cancel'} variant={'discard'} onClick={() => props.onClose()}>
          {t('cancel')}
        </DefaultButton>
        <DefaultButton
          loader={loadingCreate || loadingUpdate}
          data-testid={'create-tenant-save'}
          variant={'success'}
          disabled={disabled || inputDisabled}
          onClick={async () => {
            const languageId = platformLanguages.find((p) => p.label === language)?.id;

            if (props.mode === TenantModalMode.Create) {
              await createTenant({
                variables: {
                  data: {
                    name,
                    domain,
                    username: userName,
                    email,
                    config: {
                      language: languageId,
                    },
                  },
                },
              });
            } else {
              const update = () =>
                updateTenant({
                  variables: {
                    id: props.tenantId,
                    data: {
                      name,
                      domain,
                      config: {
                        language: languageId,
                      },
                    },
                  },
                });

              if (domain !== tenantData.tenant!.domain) {
                DeleteModal({
                  type: 'info',
                  title: t('changingDomain'),
                  async success() {
                    await update();
                  },
                });
              }

              await update();
            }
          }}
        >
          {t('save')}
        </DefaultButton>
      </div>
    </Dialog>
  );
}
