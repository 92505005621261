/**
 *
 * @param action Changed Action
 * @param issueId Id of the current issue
 * @param taskId Id of the current task
 * @param inputId Id of the current input
 * @returns Action with new associated issues
 */
export const getActionAfterUnlinkActionFromIssue = (action, issueId: string, taskId: string, inputId: string) => {
  let actionIssues = [];
  actionIssues = action.issues
    ?.filter((ac) => !(ac.issue._id === issueId && ac.task === taskId && ac.input === inputId))
    .map((ac) => ({ issue: ac.issue, task: ac.task, input: ac.input }));

  return {
    ...action,
    issues: actionIssues,
  };
};

/**
 *
 * @param action Changed Action
 * @param issue Current issue
 * @param taskId Id of the current task
 * @param inputId Id of the current input
 * @returns Action with new associated issues
 */
export const getActionAfterLinkActionToIssue = (action, issue, taskId, inputId) => {
  const actionToLink = {
    ...action,
    issues: [
      ...(action.issues?.map((ac) => ({ issue: ac.issue?._id || ac.issue, task: ac.task, input: ac?.input })) || []),
      { issue: issue, task: taskId, input: inputId },
    ],
  };

  return actionToLink;
};

export const mapActionsToUpdate = (actions) => {
  const mappedActions = actions?.map((action) => ({
    _id: action._id,
    issues: action?.issues?.map((item) => ({
      //@ts-ignore
      issue: item?.issue?._id || item?.issue,
      task: item?.task,
      input: item?.input,
    })),
  }));

  return mappedActions;
};

export const isInputLinked = (action, issueId: string, taskId: string, inputId: string): boolean => {
  let isInputLinked = false;
  if (action.issues?.find((iss) => iss.issue._id === issueId && iss.task === taskId && iss.input === inputId)) {
    isInputLinked = true;
  }

  return isInputLinked;
};
