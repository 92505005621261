import { Typography } from '@mui/material';
import { Classes } from '@mui/styles/mergeClasses/mergeClasses';
import Markdown from 'markdown-to-jsx';
import { useEffect, useState } from 'react';
import ShowFile from 'src/utils/components/show-file';
import { styles } from 'src/utils/components/description-field/styles';
import { withStyles } from '@mui/styles';
import { File } from 'src/gql/graphql';
import GenericCardInputData from '../generic-input-card/interfaces/GenericCardInputData';

interface Props {
  classes: Classes;
  description: any;
  setAddDescription?: any;
  input?: GenericCardInputData;
  setInput?: (val: Partial<GenericCardInputData>) => void;
  disableClick?: boolean;
}

interface descObject {
  content: string;
  fileId: string;
}

const DescriptionField = (props: Props): JSX.Element => {
  const { classes, description, setAddDescription, setInput, input, disableClick } = props;

  const [showAllText, setShowAllText] = useState<boolean>(false);
  const [valueToRender, setValueToRender] = useState<descObject[]>([]);
  let filesID = [];

  useEffect(() => {
    handleValues();
  }, [description]);

  const getFilesIdFromText = (desc: string) => {
    let filesIDAux = desc.match('<file>(.*)</file>')[1];
    if (filesIDAux.includes('<file>')) {
      let aux = filesIDAux.replaceAll('<file>', '');
      aux = aux.replaceAll('</file>', ',');
      aux = aux.replace(/\s/g, '');
      aux.split(',').map((id) => filesID.push(id));
    } else {
      filesID.push(filesIDAux);
    }
    if (desc.replace(`<file>${filesIDAux}</file>`, '').match('<file>(.*)</file>')) {
      getFilesIdFromText(desc.replace(`<file>${filesIDAux}</file>`, ''));
    }
  };
  const handleValues = () => {
    let auxArray: descObject[] = [];
    if (description?.match('<file>(.*)</file>')) {
      //get the string of files id,
      getFilesIdFromText(description);
    } else {
      setValueToRender([{ content: description, fileId: null }]);
    }
    if (filesID.length > 0) {
      let auxDesc = description;
      filesID.map((file) => {
        auxArray.push({ content: auxDesc.split(`<file>${file}</file>`)[0], fileId: null });
        auxArray.push({ content: null, fileId: file });
        auxDesc = auxDesc.replace(auxDesc.split(`<file>${file}</file>`)[0], '');
        auxDesc = auxDesc.replace(`<file>${file}</file>`, '');
      });
      setValueToRender(auxArray);
    }
  };

  const RenderMarkdown = (text: any) => {
    return <Markdown style={{ maxWidth: '100%' }}>{text}</Markdown>;
  };

  const handleChangeFiles = (files: File[]) => {
    const fileIds = files.map((f) => f._id);
    const updatedFiles = valueToRender.filter((f) => !f.fileId || fileIds.includes(f.fileId));
    setValueToRender(updatedFiles);
    let newDesc = '';
    updatedFiles.map((f) => {
      if (f.fileId) newDesc += `<file>${f.fileId}</file>`;
      else newDesc += f.content;
    });
    setInput?.({ ...input, description: newDesc });
  };

  return (
    <div
      onClick={(e) => {
        if (!disableClick) {
          e.stopPropagation();
          if (setAddDescription) setAddDescription(true);
        }
      }}
    >
      {valueToRender.length > 0
        ? valueToRender.map((val, idx) => (
            <div key={idx}>
              {val.content ? (
                <Typography
                  onClick={() => {
                    if (!disableClick) {
                      return setShowAllText(!showAllText);
                    } else {
                      return null;
                    }
                  }}
                  className={showAllText ? classes.descriptionNote : classes.descriptionNoteLimit}
                  key={idx}
                >
                  {RenderMarkdown(val.content)}
                </Typography>
              ) : null}
              {/* {val.fileId ? (
                <ShowFile noImageInfo noReorder withImage noName value={[val.fileId]} noBold noColon />
              ) : null} */}
            </div>
          ))
        : null}
      {valueToRender?.filter((v) => v.fileId).length > 0 ? (
        <ShowFile
          noImageInfo
          noReorder
          withImage
          noName
          value={valueToRender.filter((v) => v.fileId).map((v) => v.fileId)}
          noBold
          noColon
          handleChange={handleChangeFiles}
        ></ShowFile>
      ) : null}
    </div>
  );
};

export default withStyles(styles)(DescriptionField);
