import { Clear, ExpandMore } from '@mui/icons-material';
import { Checkbox, Chip, ListItem, ListItemText, MenuItem, Popover, Radio } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isEqual } from 'lodash';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styles } from './styles';

interface PopoverOptions<T> {
  id: T;
  label: string;
}

interface TemporaryPopoverProps<T> {
  'data-testid'?: string;
  values: T[];
  options: PopoverOptions<T>[];
  multiple?: boolean;
  disabled?: boolean;
  setValues: (x: T[]) => any;
  placeholder?: string;
}

const useStyles = makeStyles(styles);

export default function TemporaryPopover<T extends string | number>(props: TemporaryPopoverProps<T>) {
  const { options, values, setValues, disabled, multiple, placeholder } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const [openPopover, setOpenPopover] = useState<boolean>(false);

  const anchorRef = useRef<HTMLDivElement>(null);
  return (
    <div className={classes.divContainer} data-testid={props['data-testid']}>
      <div className={classes.relative}>
        <ExpandMore
          className={`${classes.expandIcon} ${!disabled ? classes.expandIconActive : classes.expandIconDisabled}`}
          onClick={(e) => {
            if (disabled) return;
            e.stopPropagation();
            e.preventDefault();
            setOpenPopover(true);
          }}
        />
      </div>
      <div
        ref={anchorRef}
        className={classes.multipleSelect}
        onClick={() => {
          setOpenPopover(true);
        }}
      >
        <span>
          {multiple && (values.length === options.length || !values.length) ? (
            <span>{t('all')}</span>
          ) : !values?.length ? (
            <span className={classes.placehoderSelectInformation}>{placeholder}</span>
          ) : (
            values.map((val) => (
              <Chip
                className={classes.chip}
                key={`chipName-${val}`}
                data-testid={`chipName-${val}`}
                size={'small'}
                variant='outlined'
                deleteIcon={disabled ? undefined : <Clear style={{ fontSize: '14px' }} />}
                onDelete={disabled ? undefined : () => setValues(values.filter((s) => !isEqual(s, val)))}
                label={options.find((o) => isEqual(o.id, val)).label}
              />
            ))
          )}
        </span>
      </div>
      {disabled || !openPopover ? null : (
        <Popover
          open={true}
          anchorEl={anchorRef.current}
          onClose={() => {
            setOpenPopover(false);
          }}
          slotProps={{ paper: { style: { width: anchorRef.current?.getBoundingClientRect().width } } }}
          classes={{ paper: classes.popoverContainer }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          {multiple ? (
            <div>
              <MenuItem
                style={{ height: '36px' }}
                onClick={() => setValues(options.length === values.length ? [] : options.map((l) => l.id))}
              >
                <Checkbox
                  className={classes.listItem}
                  data-testid={'select-state-all'}
                  color={'primary'}
                  checked={options.length === values.length}
                  onClick={() => setValues(options.length === values.length ? [] : options.map((l) => l.id))}
                />
                <span>{t('selectAll')}</span>
                {options.length === values.length ? (
                  <span className={classes.selectedValuesCounter}>{`Selected (${values.length})`}</span>
                ) : null}
              </MenuItem>
            </div>
          ) : null}
          {options.map((opt) => {
            return (
              <ListItem
                key={opt.id}
                classes={{ root: classes.listItem }}
                onClick={() => {
                  if (multiple) {
                    if (values.some((v) => isEqual(v, opt.id))) {
                      setValues(values.filter((s) => !isEqual(s, opt.id)));
                    } else {
                      setValues([...values, opt.id]);
                    }
                  } else {
                    setValues([opt.id]);
                  }
                }}
              >
                <ListItemText classes={{ root: classes.itemText }}>
                  {multiple ? (
                    <Checkbox
                      data-testid={'select-state-' + opt.label}
                      checked={values.includes(opt.id)}
                      onClick={() => {
                        if (values.some((v) => isEqual(v, opt.id))) {
                          setValues(values.filter((s) => !isEqual(s, opt.id)));
                        } else {
                          setValues([...values, opt.id]);
                        }
                      }}
                    />
                  ) : (
                    <Radio
                      style={{ paddingLeft: '-3px' }}
                      checked={values.some((s) => isEqual(s, opt.id))}
                      data-testid={'select-' + t(opt.label)}
                      color={'primary'}
                      size={'small'}
                      onClick={() => {
                        setValues([opt.id]);
                      }}
                    />
                  )}
                  {opt.label}
                </ListItemText>
              </ListItem>
            );
          })}
        </Popover>
      )}
    </div>
  );
}
