import { ApolloQueryResult } from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';
import { APOLLO_CLIENT } from 'config/apollo.config';
import { cloneDeep, concat, filter, orderBy } from 'lodash';
import { Dispatch } from 'redux';
import { GET_LOGGED_USER, UPDATE_CHAT_COUNT, UPDATE_LOGGED_ACCOUNT, UPDATE_TAB } from 'src/base/login/login.redux';
import {
  Account,
  AccountOrderByInput,
  AccountTemplateUpdateInput,
  AccountTemplateWhereUniqueInput,
  GetMeDocument,
  MarkAllReadTypeEnum,
  TabUpdateInput,
  TabWhereUniqueInput,
  UpdateAccountDocument,
  UpdateTabDocument,
} from 'src/gql/graphql';
import { Action, State } from 'src/interfaces/reducers';
import * as queries from 'src/modules/account/account.queries';
import {
  addNewChildren,
  addToTemplate,
  deleteChildren,
  deleteFromParent,
  getErrorObject,
  isLeaf,
  isModalFolderLeaf,
  setChildren,
  setModalFolderChildren,
  updateChildren,
  updateParent,
} from 'src/utils/funcs';
import { addSpace } from 'src/utils/funcs/index';
import i18n from 'src/utils/translations/i18n';
import { NexusGenArgTypes, NexusGenFieldTypes, NexusGenInputs } from '../../../../server/src/types';
import { getDefaultTenant } from '../tenants/tenant.redux';

export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const SET_CURRENT_SITE = 'SET_CURRENT_SITE';
export const SET_DEFAULT_ROUTE = 'SET_DEFAULT_ROUTE';
export const GET_ONE_GLOBAL_ACCOUNT_TEMPLATE_FIELDS = 'GET_ONE_GLOBAL_ACCOUNT_TEMPLATE_FIELDS';
export const CREATE_ONE_GLOBAL_ACCOUNT_TEMPLATE_FIELDS = 'CREATE_ONE_GLOBAL_ACCOUNT_TEMPLATE_FIELDS';
export const DELETE_ONE_GLOBAL_ACCOUNT_TEMPLATE_FIELDS = 'DELETE_ONE_GLOBAL_ACCOUNT_TEMPLATE_FIELDS';
export const GET_ACCOUNT_TEMPLATE_FOLDERS = 'GET_ACCOUNT_TEMPLATE_FOLDERS';
export const CREATE_ACCOUNT_TEMPLATE_FOLDER = 'CREATE_ACCOUNT_TEMPLATE_FOLDER';
export const DELETE_ACCOUNT_TEMPLATE_FOLDER = 'DELETE_ACCOUNT_TEMPLATE_FOLDER';
export const UPDATE_ACCOUNT_TEMPLATE_FOLDER = 'UPDATE_ACCOUNT_TEMPLATE_FOLDER';
export const SEARCH_ACCOUNT_TEMPLATE_FOLDERS = 'SEARCH_ACCOUNT_TEMPLATE_FOLDERS';
export const MODAL_ACCOUNT_TEMPLATE_FOLDERS = 'MODAL_ACCOUNT_TEMPLATE_FOLDERS';
export const GET_ACCOUNT_ACCOUNT_FOLDERS = 'GET_ACCOUNT_ACCOUNT_FOLDERS';
export const CREATE_ACCOUNT_FOLDER = 'CREATE_ACCOUNT_FOLDER';
export const DELETE_ACCOUNT_FOLDER = 'DELETE_ACCOUNT_FOLDER';
export const UPDATE_ACCOUNT_FOLDER = 'UPDATE_ACCOUNT_FOLDER';
export const SEARCH_ACCOUNT_FOLDERS = 'SEARCH_ACCOUNT_FOLDERS';
export const MODAL_ACCOUNT_FOLDERS = 'MODAL_ACCOUNT_FOLDERS';
export const CREATE_ACCOUNT_TEMPLATE = 'CREATE_ACCOUNT_TEMPLATE';
export const UPDATE_ACCOUNT_TEMPLATE = 'UPDATE_ACCOUNT_TEMPLATE';
export const DELETE_ACCOUNT_TEMPLATE = 'DELETE_ACCOUNT_TEMPLATE';
export const MARK_SEEN_ISSUE_ACTION = 'MARK_SEEN_ISSUE_ACTION';
export const MARK_ALL_SEEN_ISSUE_ACTION = 'MARK_ALL_SEEN_ISSUE_ACTION';
export const ERROR = 'ERROR';
export const CREATE_TAB = 'CREATE_TAB';

const initialState: State = {
  accounts: [],
  accountsList: [],
  accountTemplate: [],
  fields: [],
  folders: [],
  accountFolders: [],
  accountModalResults: [],
  accountSearchResults: [],
  searchResults: [],
  queriesAllowed: ['*'],
  mutationsAllowed: ['*'],
  modalResults: [],
  roles: [],
  currentSite: null,
  paths: [],
  defaultRoute: null,
  count: 0,
  myClearances: [],
  error: false,
};

export default function (state: State = initialState, action: Action): State {
  const accountsAux: any[] = [];
  let folders: any = [];
  switch (action.type) {
    case GET_ACCOUNTS:
      action.payload.accounts.forEach((element) => {
        accountsAux.push({
          ...element,
          id: element._id,
        });
      });
      return {
        ...state,
        accountsList: accountsAux,
        count: action.payload.count,
        error: false,
      };
    case CREATE_ACCOUNT:
      folders = addToTemplate(
        cloneDeep(state).accountFolders,
        action.payload.account.folder._id,
        action.payload.account,
        'account',
      );
      return {
        ...state,
        accountsList: concat(cloneDeep(state).accountsList, {
          ...action.payload.account,
          id: action.payload.account._id,
        }),
        accountFolders: folders,
      };
    case UPDATE_ACCOUNT:
      // folders = updateParent(
      //   cloneDeep(state).accountFolders,
      //   action.payload.new.folder._id,
      //   action.payload.new,
      //   'account',
      //   action.payload.old,
      // );
      return {
        ...state,
        accountsList: state.accountsList.map((it) =>
          it._id === action.payload.new._id ? { ...action.payload.new, id: action.payload.new._id } : it,
        ),
        accountSearchResults: state.accountSearchResults.map((s) =>
          s._id === action.payload.new._id ? action.payload.new : s,
        ),
      };
    case DELETE_ACCOUNT:
      return {
        ...state,
        accountsList: filter(state.accountsList, (u) => u._id !== action.payload._id),
        accountSearchResults: state.accountSearchResults.filter((s) => s._id !== action.payload._id),
      };
    case SET_CURRENT_SITE:
      return { ...state, currentSite: action.payload.site, error: false };
    case SET_DEFAULT_ROUTE:
      let defRoute = '';
      defRoute = action.payload.paths.length > 0 ? action.payload.paths[0] : '';
      return {
        ...state,
        paths: action.payload.paths,
        defaultRoute: defRoute,
        error: false,
      };
    case GET_ONE_GLOBAL_ACCOUNT_TEMPLATE_FIELDS:
      return { ...state, fields: action.payload.fields, error: false };
    case CREATE_ONE_GLOBAL_ACCOUNT_TEMPLATE_FIELDS:
      const fields = state.fields;
      action.payload.fields.map((f) => {
        if (f.global) fields.push(f);
      });
      return {
        ...state,
        fields,
        error: false,
      };
    case DELETE_ONE_GLOBAL_ACCOUNT_TEMPLATE_FIELDS:
      return {
        ...state,
        fields: state.fields.filter((f) => f._id !== action.payload.field._id),
        error: false,
      };
    case GET_ACCOUNT_TEMPLATE_FOLDERS:
      folders = [];
      if (action.payload.folders.length > 0 && action.payload.folders[0].parent === null) {
        action.payload.folders.forEach((p) => {
          folders.push({
            ...p,
            leaf: isLeaf(p, 'accountTemplate'),
            children: [],
          });
        });
      } else if (action.payload.folders.length || action.payload.accountTemplates.length) {
        folders = setChildren(
          cloneDeep(state).folders,
          action.payload.folders.length && action.payload.folders[0].parent
            ? action.payload.folders[0].parent._id
            : action.payload.accountTemplates[0].folder._id,
          action.payload,
          'accountTemplate',
        );
      }
      return {
        ...state,
        folders: folders.length === 0 ? state.folders : orderBy(folders, 'name', 'asc'),
      };
    case CREATE_ACCOUNT_TEMPLATE_FOLDER:
      folders = [];
      if (action.payload.parent && action.payload.parent._id) {
        folders = addNewChildren(
          cloneDeep(state).folders,
          action.payload.parent._id,
          action.payload,
          'accountTemplate',
        );
      } else {
        folders = JSON.parse(JSON.stringify(state.folders));
        folders.push(Object.assign({ children: [], leaf: true }, action.payload));
      }
      return { ...state, folders: orderBy(folders, 'name', 'asc') };
    case UPDATE_ACCOUNT_TEMPLATE_FOLDER:
      folders = [];
      if (action.payload.parent !== null)
        folders = updateChildren(cloneDeep(state).folders, action.payload.parent._id, action.payload);
      else
        state.folders.map((folder) => {
          if (folder._id === action.payload._id) folders.push({ ...folder, ...action.payload });
          else folders.push(folder);
        });
      return {
        ...state,
        folders: orderBy(folders, 'name', 'asc'),
        searchResults: state.searchResults.map((s) => (s._id === action.payload._id ? action.payload : s)),
      };
    case DELETE_ACCOUNT_TEMPLATE_FOLDER:
      folders = [];
      if (action.payload.parent !== null) {
        folders = deleteChildren(
          cloneDeep(state).folders,
          action.payload.parent._id,
          action.payload,
          'accountTemplate',
        );
      } else {
        folders = state.folders.filter((st) => st._id !== action.payload._id);
      }
      return {
        ...state,
        folders,
        searchResults: state.searchResults.filter((s) => s._id !== action.payload._id),
      };
    case SEARCH_ACCOUNT_TEMPLATE_FOLDERS:
      return {
        ...state,
        searchResults: concat(action.payload.accountTemplates, action.payload.accountTemplateFolders),
      };
    case CREATE_ACCOUNT_TEMPLATE:
      folders = addToTemplate(
        cloneDeep(state).folders,
        action.payload.accountTemplate.folder._id,
        action.payload.accountTemplate,
        'accountTemplate',
      );
      return {
        ...state,
        folders,
      };
    case UPDATE_ACCOUNT_TEMPLATE:
      folders = updateParent(
        cloneDeep(state).folders,
        action.payload.new.folder._id,
        action.payload.new,
        'accountTemplate',
        action.payload.old,
      );
      return {
        ...state,
        folders,
        searchResults: state.searchResults.map((s) => (s._id === action.payload.new._id ? action.payload.new : s)),
      };
    case DELETE_ACCOUNT_TEMPLATE:
      folders = deleteFromParent(
        cloneDeep(state).folders,
        action.payload.folder._id,
        action.payload,
        'accountTemplate',
      );
      return {
        ...state,
        folders,
        searchResults: state.searchResults.filter((s) => s._id !== action.payload._id),
      };
    case GET_ACCOUNT_ACCOUNT_FOLDERS:
      folders = [];

      if (action.payload.folders.length > 0 && action.payload.folders[0].parent === null) {
        action.payload.folders.forEach((p) => {
          folders.push({
            ...p,
            leaf: isLeaf(p, 'account'),
            children: [],
          });
        });
      } else {
        folders = setChildren(
          cloneDeep(state).accountFolders,
          action.payload.folders.length && action.payload.folders[0].parent
            ? action.payload.folders[0].parent._id
            : action.payload.accounts.length
              ? action.payload.accounts[0].folder._id
              : null,
          action.payload,
          'account',
        );
      }
      return {
        ...state,
        accountFolders: folders.length === 0 ? state.accountFolders : orderBy(folders, 'name', 'asc'),
      };
    case CREATE_ACCOUNT_FOLDER:
      folders = [];
      if (action.payload.parent && action.payload.parent._id) {
        folders = addNewChildren(cloneDeep(state).accountFolders, action.payload.parent._id, action.payload, 'account');
      } else {
        folders = JSON.parse(JSON.stringify(state.accountFolders));
        folders.push(Object.assign({ children: [], leaf: true }, action.payload));
      }
      return { ...state, accountFolders: orderBy(folders, 'name', 'asc') };
    case UPDATE_ACCOUNT_FOLDER:
      folders = [];
      if (action.payload.parent !== null)
        folders = updateChildren(cloneDeep(state).accountFolders, action.payload.parent._id, action.payload);
      else
        state.accountFolders.map((folder) => {
          if (folder._id === action.payload._id) folders.push({ ...folder, ...action.payload });
          else folders.push(folder);
        });
      return {
        ...state,
        accountFolders: orderBy(folders, 'name', 'asc'),
        accountSearchResults: state.accountSearchResults.map((s) =>
          s._id === action.payload._id ? action.payload : s,
        ),
      };
    case DELETE_ACCOUNT_FOLDER:
      folders = [];
      if (action.payload.parent !== null) {
        folders = deleteChildren(cloneDeep(state).accountFolders, action.payload.parent._id, action.payload, 'account');
      } else {
        folders = state.accountFolders.filter((st) => st._id !== action.payload._id);
      }
      return {
        ...state,
        accountFolders: folders,
        accountSearchResults: state.accountSearchResults.filter((s) => s._id !== action.payload._id),
      };
    case SEARCH_ACCOUNT_FOLDERS:
      return {
        ...state,
        accountSearchResults: concat(action.payload.accounts, action.payload.accountFolders),
      };
    case MODAL_ACCOUNT_TEMPLATE_FOLDERS:
      folders = [];
      if (action.payload.folders.length > 0 && action.payload.folders[0].parent === null) {
        action.payload.folders.forEach((p) => {
          folders.push({
            ...p,
            leaf: isModalFolderLeaf(p, 'accountTemplate'),
            children: [],
          });
        });
      } else if (action.payload.folders.length > 0) {
        folders = setModalFolderChildren(
          cloneDeep(state).modalResults,
          action.payload.folders[0].parent ? action.payload.folders[0].parent._id : null,
          action.payload.folders,
          'accountTemplate',
        );
      }
      return {
        ...state,
        modalResults: folders.length === 0 ? state.modalResults : folders,
      };
    case MODAL_ACCOUNT_FOLDERS:
      folders = [];
      if (action.payload.folders.length > 0 && action.payload.folders[0].parent === null) {
        action.payload.folders.forEach((p) => {
          folders.push({
            ...p,
            leaf: isModalFolderLeaf(p, 'account'),
            children: [],
          });
        });
      } else if (action.payload.folders.length > 0) {
        folders = setModalFolderChildren(
          cloneDeep(state).accountModalResults,
          action.payload.folders[0].parent ? action.payload.folders[0].parent._id : null,
          action.payload.folders,
          'account',
        );
      }
      return {
        ...state,
        accountModalResults: folders.length === 0 ? state.accountModalResults : folders,
      };
    case ERROR:
      return { ...state, error: true };
    default:
      return state;
  }
}

export const getAccount =
  (filter: NexusGenArgTypes['Query']['accounts']['where']) =>
  async (dispatch: Dispatch): Promise<Account> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          skip: 0,
          limit: 1,
          filter,
          tenant: getDefaultTenant(),
          orderBy: ['name_ASC'],
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_ACCOUNTS,
      });
      return response.data.accounts[0];
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getAccountWithDeleted =
  (filter: NexusGenInputs['AccountWhereUniqueInput']) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          filter,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_ACCOUNT_WITH_DELETED,
      });
      return response.data.accountWithDeleted;
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getAccountTemplate = (filter) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        filter,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ACCOUNT_TEMPLATE,
    });
    return response.data.accountTemplates[0];
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getAccountsToTree = (filter, item?) => async (dispatch: Dispatch) => {
  try {
    let accounts: ApolloQueryResult<any> | null = null;
    let accountFolders: ApolloQueryResult<any> | null = null;

    /* if (item && item.profile) {
        accounts = await APOLLO_CLIENT.query({
          variables: {
            filter: { parent_eq: item._id },
          },
          fetchPolicy: "no-cache",
          query: queries.Q_GET_ACCOUNTS_TO_TREE,
        });
      } else { */
    accountFolders = await APOLLO_CLIENT.query({
      variables: {
        where: filter,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ACCOUNT_FOLDERS,
    });
    if (item)
      accounts = await APOLLO_CLIENT.query({
        variables: {
          filter: { folder: { _id_eq: item._id } },
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_ACCOUNTS_TO_TREE,
      });
    /* } */

    return orderBy(
      [...(accountFolders ? accountFolders.data.accountFolders : []), ...(accounts ? accounts.data.accounts : [])],
      'name',
      'asc',
    );
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getAccounts =
  (page: number, limit: number, filter, orderBy?: AccountOrderByInput) =>
  async (dispatch: Dispatch): Promise<NexusGenFieldTypes['Account'][]> => {
    try {
      // let f = {};
      // if (filter && filter.trim() !== '') {
      //   f = { name_contains: filter };
      // }

      const response = await APOLLO_CLIENT.query({
        variables: {
          limit,
          // skip: (page - 1) * limit,
          skip: page !== undefined && page < 0 ? 0 : page,
          filter: filter || { name_contains: '' },
          orderBy: orderBy || ['name_ASC'],
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_ACCOUNTS,
      });

      const count = await APOLLO_CLIENT.query({
        variables: {
          filter: filter || { name_contains: '' },
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_ACCOUNTS_COUNT,
      });

      dispatch({
        type: GET_ACCOUNTS,
        payload: { accounts: response.data.accounts, count: count.data },
      });

      return response.data.accounts;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getAccountsCount = (filter) => async (dispatch: Dispatch) => {
  try {
    // let f = {};
    // if (filter && filter.trim() !== '') {
    //   f = { name_contains: filter };
    // }

    const response = await APOLLO_CLIENT.query({
      variables: {
        filter: filter || { name_contains: '' },
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ACCOUNTS_COUNT,
    });

    return response.data.accountsCount;
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const setPathsAndDefaultRoute = (paths) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_DEFAULT_ROUTE,
    payload: { paths },
  });

  return null;
};

export const createAccount = (accountData) => async (dispatch: Dispatch) => {
  try {
    let response;
    if (accountData.template) {
      response = await APOLLO_CLIENT.mutate({
        variables: {
          email: accountData.email,
          name: accountData?.name,
          username: accountData.username,
          password: accountData.password,
          inputs: accountData.inputs,
          authorizedSites: accountData.sites,
          template: accountData?.template?._id,
          folder: accountData?.folder?._id,
          labelValues: accountData.labelValues,
          roles: accountData.roles,
        },
        mutation: queries.M_CREATE_ACCOUNT,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('account') + addSpace(accountData.username) + i18n.t('toastCreateSuccess'),
          severity: 'success',
        },
      });
    }
    // update the local storage with the token using the correct action
    dispatch({
      type: CREATE_ACCOUNT,
      payload: {
        account: response ? response.data.createAccount : accountData,
      },
    });

    if (response) return response.data.createAccount;
  } catch (error) {
    const obj = getErrorObject(error, 'Account.createAccount');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const updateAccount = (accountData /**previousFolderId?*/) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        email: accountData.email,
        name: accountData.name,
        username: accountData.username,
        id: accountData.id,
        inputs: accountData.inputs,
        authorizedSites: accountData.authorizedSites,
        // template: accountData.template?._id,
        password: accountData.password,
        newPassword: accountData.newPassword,
        // folder: accountData.folder._id,
        preferences: accountData.preferences,
        labelValues: accountData.labelValues,
        roles: accountData.roles,
        onBoarding: accountData.onBoarding,
        tenant: getDefaultTenant(),
      },
      mutation: UpdateAccountDocument,
    });

    dispatch({
      type: UPDATE_ACCOUNT,
      payload: { new: response.data.updateAccount },
    });

    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: i18n.t('account') + addSpace(accountData.name) + i18n.t('translation:toastUpdateSuccess'),
        severity: 'success',
      },
    });
    return response.data.updateAccount;
  } catch (error) {
    const obj = getErrorObject(error, 'Account.updateAccount');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const deleteAccount =
  (accountData) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          id: accountData._id,
        },
        mutation: queries.M_DELETE_ACCOUNT,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('account') + addSpace(accountData.username) + i18n.t('translation:toastDeleteSuccess'),
          severity: 'success',
        },
      });
      dispatch({
        type: DELETE_ACCOUNT,
        payload: response ? response.data.deleteOneAccount : accountData,
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const deleteManyAccounts = (_ids: string[]) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        _ids,
      },
      mutation: queries.M_DELETE_MANY_ACCOUNTS,
    });

    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: `${response.data.deleteManyAccounts.length} ${i18n.t('accounts')} ${i18n.t('translation:toastDeleteSuccess')}`,
        severity: 'success',
      },
    });
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const activateAccount = (_id: string) => async (dispatch: Dispatch) => {
  try {
    await APOLLO_CLIENT.mutate({
      variables: {
        _id,
      },
      mutation: queries.M_ACTIVATE_ACCOUNT,
    });

    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: `${i18n.t('account')} ${i18n.t('toastActivatedSuccess')}`,
        severity: 'success',
      },
    });
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const activateManyAccounts = (_ids: string[]) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        _ids,
      },
      mutation: queries.M_ACTIVATE_MANY_ACCOUNTS,
    });

    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: `${response.data.activateManyAccounts.length} ${i18n.t('accounts')} ${i18n.t('toastActivatedSuccess')}`,
        severity: 'success',
      },
    });
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const deactivateAccount = (_id: string) => async (dispatch: Dispatch) => {
  try {
    await APOLLO_CLIENT.mutate({
      variables: {
        _id,
      },
      mutation: queries.M_DEACTIVATE_ACCOUNT,
    });

    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: `${i18n.t('account')} ${i18n.t('toastDeactivatedSuccess')}`,
        severity: 'success',
      },
    });
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const deactivateManyAccounts = (_ids: string[]) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        _ids,
      },
      mutation: queries.M_DEACTIVATE_MANY_ACCOUNTS,
    });

    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: `${response.data.deactivateManyAccounts.length} ${i18n.t('accounts')} ${i18n.t('toastDeactivatedSuccess')}`,
        severity: 'success',
      },
    });
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const accountSetSite =
  (site, backend) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      if (backend) {
        const response = await APOLLO_CLIENT.mutate({
          variables: {
            data: { defaultSite: site ? site._id : null },
          },
          mutation: queries.M_SET_PREFERENCES,
        });

        dispatch({
          type: GET_LOGGED_USER,
          payload: { account: response.data.setPreferences },
        });
      }

      dispatch({
        type: SET_CURRENT_SITE,
        payload: { site },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const changePassword = (passwordData) => async (dispatch: Dispatch) => {
  try {
    const changed = await APOLLO_CLIENT.mutate({
      variables: {
        password: passwordData.password,
        newPassword: passwordData.newPassword,
      },
      mutation: queries.M_CHANGE_PASSWORD,
    });
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: i18n.t('passwordUpdated'),
        severity: 'success',
      },
    });
    return changed;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const getTemplateFolders =
  (options, item, modal = false) =>
  async (dispatch: Dispatch) => {
    try {
      let response: ApolloQueryResult<any> | null = null;
      if (item?.node.accountTemplateFoldersCount || options.parent_exists !== undefined) {
        response = await APOLLO_CLIENT.query({
          variables: { where: options, archivedBy_exists: false },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_ACCOUNT_TEMPLATE_FOLDERS,
        });
      }
      let accountTemplates: ApolloQueryResult<any> | null = null;
      if (options.parent_eq && !modal && item.node.accountTemplatesCount) {
        accountTemplates = await APOLLO_CLIENT.query({
          variables: { filter: { folder: { _id_eq: options.parent_eq }, archived_ne: true } },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_ACCOUNT_TEMPLATE_TO_TREE,
        });
      }
      dispatch({
        type: modal ? MODAL_ACCOUNT_TEMPLATE_FOLDERS : GET_ACCOUNT_TEMPLATE_FOLDERS,
        payload: {
          folders: response ? response.data.accountTemplateFolders : [],
          accountTemplates: accountTemplates ? accountTemplates.data.accountTemplates : [],
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
    }
  };

export const getAccountTemplatesToTree = (filter, item?) => async (dispatch: Dispatch) => {
  try {
    let accountTemplates: ApolloQueryResult<any> | null = null;
    let accountTemplateFolders: ApolloQueryResult<any> | null = null;
    accountTemplateFolders = await APOLLO_CLIENT.query({
      variables: {
        where: filter,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ACCOUNT_TEMPLATE_FOLDERS,
    });

    if (item) {
      accountTemplates = await APOLLO_CLIENT.query({
        variables: {
          filter: { folder: { _id_eq: item._id } },
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_ACCOUNT_TEMPLATE_TO_TREE,
      });
    }

    return orderBy(
      [
        ...(accountTemplateFolders ? accountTemplateFolders.data.accountTemplateFolders : []),
        ...(accountTemplates ? accountTemplates.data.accountTemplates : []),
      ],
      'name',
      'asc',
    );
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const createTemplateFolder =
  ({ name, parent = null }: { name: string; parent: { _id: string } | null }) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { name, parent: parent?._id },
        mutation: queries.M_CREATE_ACCOUNT_TEMPLATE_FOLDER,
      });

      response.data.createAccountTemplateFolder.accountTemplateFolders = 0;
      response.data.createAccountTemplateFolder.accountTemplateFoldersCount = 0;

      dispatch({
        type: CREATE_ACCOUNT_TEMPLATE_FOLDER,
        payload: response.data.createAccountTemplateFolder,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(name) + i18n.t('toastCreateSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const updateTemplateFolder =
  ({ name, parent = null }, { _id }) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { name, parent_eq: parent, _id },
        mutation: queries.M_UPDATE_ACCOUNT_TEMPLATE_FOLDER,
      });
      dispatch({
        type: UPDATE_ACCOUNT_TEMPLATE_FOLDER,
        payload: response.data.updateAccountTemplateFolder,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(name) + i18n.t('toastUpdateSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const deleteTemplateFolder =
  (folderData) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { where: { _id: folderData._id } },
        mutation: queries.M_DELETE_ACCOUNT_TEMPLATE_FOLDER,
      });
      dispatch({
        type: DELETE_ACCOUNT_TEMPLATE_FOLDER,
        payload: response.data.deleteOneAccountTemplateFolder,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(folderData.name) + i18n.t('toastDeleteSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
    }
  };

export const search = (searchString: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { searchString },
      fetchPolicy: 'no-cache',
      mutation: queries.Q_SEARCH,
    });
    dispatch({
      type: SEARCH_ACCOUNT_TEMPLATE_FOLDERS,
      payload: response.data,
    });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
  }
};

export const createAccountTemplate = (template) => async (dispatch: Dispatch) => {
  // access the backend and get the access token
  try {
    let response;
    if (template.frame.inputs) {
      response = await APOLLO_CLIENT.mutate({
        variables: {
          title: Array.isArray(template.title) ? template.title[0] : template.title,
          folder: template.folder._id,
          inputs: template.frame.inputs,
        },
        mutation: queries.M_CREATE_ACCOUNT_TEMPLATE,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('Template') + addSpace(template.title) + i18n.t('toastCreateSuccess'),
          severity: 'success',
        },
      });
    }
    // update the local storage with the token using the correct action
    dispatch({
      type: CREATE_ACCOUNT_TEMPLATE,
      payload: {
        accountTemplate: response ? response.data.createAccountTemplate : template,
      },
    });

    if (response) return response.data.createAccountTemplate;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const updateAccountTemplate =
  (data: AccountTemplateUpdateInput, where: AccountTemplateWhereUniqueInput, previousFolderId) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          data,
          where,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_ACCOUNT_TEMPLATE,
      });

      if (data.archived) {
        dispatch({
          type: DELETE_ACCOUNT_TEMPLATE,
          payload: response ? response.data.updateAccountTemplate : data,
        });
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('Template') + ' ' + i18n.t('toastArchiveSuccess'),
            severity: 'success',
          },
        });
      } else {
        dispatch({
          type: UPDATE_ACCOUNT_TEMPLATE,
          payload: {
            new: response.data.updateAccountTemplate,
            old: previousFolderId,
          },
        });
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('Template') + addSpace(data.title) + i18n.t('toastUpdateSuccess'),
            severity: 'success',
          },
        });
      }
      return response.data.updateAccountTemplate;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const deleteAccountTemplate =
  (templateData) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      let response;
      if (templateData.frame.inputs) {
        response = await APOLLO_CLIENT.mutate({
          variables: {
            id: templateData._id,
          },
          mutation: queries.M_DELETE_ACCOUNT_TEMPLATE,
        });
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('Template') + addSpace(templateData.title) + i18n.t('toastDeleteSuccess'),
            severity: 'success',
          },
        });
      }
      // update the local storage with the token using the correct action
      dispatch({
        type: DELETE_ACCOUNT_TEMPLATE,
        payload: response ? response.data.deleteOneAccountTemplate : templateData,
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getFolders =
  (options, item, modal = false) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      let response: ApolloQueryResult<any> | null = null;
      if (item?.node.accountFoldersCount || options.parent_exists !== undefined) {
        response = await APOLLO_CLIENT.query({
          variables: { where: options },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_ACCOUNT_FOLDERS,
        });
      }

      let accounts: ApolloQueryResult<any> | null = null;
      if (options.parent_eq && !modal && item.node.accountsCount) {
        accounts = await APOLLO_CLIENT.query({
          variables: { filter: { folder: { _id_eq: options.parent_eq } } },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_ACCOUNTS_TO_TREE,
        });
      }

      dispatch({
        type: modal ? MODAL_ACCOUNT_FOLDERS : GET_ACCOUNT_ACCOUNT_FOLDERS,
        payload: {
          folders: response ? response.data.accountFolders : [],
          accounts: accounts ? accounts.data.accounts : [],
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
    }
  };

export const createFolder =
  ({ name, parent = null }: { name: string; parent: { _id: string } | null }) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { name, parent: parent?._id },
        fetchPolicy: 'no-cache',
        mutation: queries.M_CREATE_ACCOUNT_FOLDER,
      });
      dispatch({
        type: CREATE_ACCOUNT_FOLDER,
        payload: response.data.createAccountFolder,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(name) + i18n.t('toastCreateSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const updateFolder =
  ({ name, parent = null }: { name: string; parent: { _id: string } | null }, { _id }) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { name, parent: parent?._id, _id },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_ACCOUNT_FOLDER,
      });
      dispatch({
        type: UPDATE_ACCOUNT_FOLDER,
        payload: response.data.updateAccountFolder,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(name) + i18n.t('toastUpdateSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const deleteFolder =
  (folderData) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { where: { _id: folderData._id } },
        fetchPolicy: 'no-cache',
        mutation: queries.M_DELETE_ACCOUNT_FOLDER,
      });
      dispatch({
        type: DELETE_ACCOUNT_FOLDER,
        payload: response.data.deleteOneAccountFolder,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(folderData.name) + i18n.t('toastDeleteSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
    }
  };

export const getAccountsLight =
  (filter?: NexusGenInputs['AccountWhereInput']) =>
  async (dispatch: Dispatch): Promise<Account[] & { graphQLErrors: GraphQLErrors }> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { filter, tenant: getDefaultTenant() },
        fetchPolicy: 'no-cache',
        mutation: queries.Q_GET_ACCOUNTS_LIGHT,
      });
      return response.data.accounts;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getAccountsByLabels =
  (ids: string[]) =>
  async (dispatch: Dispatch): Promise<Account[]> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { labels: ids },
        fetchPolicy: 'no-cache',
        mutation: queries.Q_GET_ACCOUNTS_BY_LABELS,
      });

      return response.data.accounts;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getMe = () => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      fetchPolicy: 'no-cache',
      mutation: GetMeDocument,
    });

    return response.data.me as Account;
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return obj;
  }
};

export const searchAccounts = (searchString) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { searchString },
      fetchPolicy: 'no-cache',
      mutation: queries.Q_SEARCH_ACCOUNT,
    });
    dispatch({
      type: SEARCH_ACCOUNT_FOLDERS,
      payload: response.data,
    });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
  }
};

export const accountsPaginated =
  (page: number, limit: number, filter) =>
  async (dispatch: Dispatch): Promise<{ accounts: Account[]; count: number }> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          limit,
          skip: page * limit,
          filter,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_ACCOUNTS_TO_ASSIGN,
      });

      const count = await APOLLO_CLIENT.query({
        variables: {
          filter,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_ACCOUNTS_COUNT,
      });

      return {
        accounts: response.data.accounts,
        count: count.data.accountsCount,
      };
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getUnreadMessages = () => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ACCOUNT_UNREAD_MESSAGES,
    });

    dispatch({
      type: UPDATE_CHAT_COUNT,
      payload: {
        value: response.data.allUnreadMessages,
      },
    });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const duplicateAccountTemplate =
  (_id: string, copyChildren: boolean, name: string) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { _id, copyChildren },
        fetchPolicy: 'no-cache',
        mutation: queries.M_DUPLICATE_ACCOUNT_TEMPLATE,
      });

      dispatch({
        type: CREATE_ACCOUNT_TEMPLATE,
        payload: { accountTemplate: response.data.duplicateAccountTemplate },
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: `${name} ${i18n.t('duplicated')}`,
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const markSeenActionIssue = (docId: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { docId },
      fetchPolicy: 'no-cache',
      mutation: queries.M_MARK_SEEN_ACTION_ISSUE,
    });

    dispatch({
      type: MARK_SEEN_ISSUE_ACTION,
      payload: response.data.markSeen,
    });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const markAllSeenActionsIssues =
  (docIds: string[], markType: MarkAllReadTypeEnum) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { docIds, markType },
        fetchPolicy: 'no-cache',
        mutation: queries.M_MARK_ALL_SEEN_ACTION_ISSUE,
      });

      dispatch({
        type: UPDATE_LOGGED_ACCOUNT,
        payload: { account: response.data.markAllSeen },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const updateManyAccountTemplates =
  (where: NexusGenInputs['AccountTemplateWhereInput'], update: NexusGenInputs['AccountTemplateUpdateInput']) =>
  async (dispatch: Dispatch): Promise<NexusGenFieldTypes['AccountTemplate'][] & { graphQLErrors: GraphQLErrors }> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          where,
          update,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_MANY_ACCOUNT_TEMPLATES,
      });

      return response.data.updateManyAccountTemplates;
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const updateTab = (where: TabWhereUniqueInput, data: TabUpdateInput) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        where,
        data,
      },
      fetchPolicy: 'no-cache',
      mutation: UpdateTabDocument,
    });

    dispatch({
      type: UPDATE_TAB,
      payload: { tab: response.data.updateTab },
    });

    return response.data.updateTab;
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};
