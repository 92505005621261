import { APOLLO_CLIENT } from 'config/apollo.config';
import gql from 'graphql-tag';
import { Dispatch } from 'redux';
import { getErrorObject } from 'src/utils/funcs';

type Email = string;
type Account = string;

export const recoverPassword =
  (account: Email | Account, token: string, subDomain: string) => async (dispatch: Dispatch) => {
    try {
      const RECOVER_PW = gql`
        mutation RecoverPassword($account: String!, $token: String, $subDomain: String) {
          recoverPassword(account: $account, recaptcha: $token, domain: $subDomain)
        }
      `;
      await APOLLO_CLIENT.mutate({
        variables: { account, token, subDomain },
        mutation: RECOVER_PW,
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const isValidToken = async (token): Promise<boolean> => {
  try {
    const IS_TOKEN_VALID = gql`
      query IsTokenValid($token: String) {
        isValidToken(token: $token)
      }
    `;
    const response = await APOLLO_CLIENT.query({
      query: IS_TOKEN_VALID,
      variables: { token },
    });
    return response.data.isValidToken;
  } catch (error) {
    console.error('isValidToken: ', error);
    return false;
  }
};

gql`
  mutation ResetPassword($newPassword: String!, $token: String) {
    resetPassword(newPassword: $newPassword, token: $token) {
      _id
      name
      email
    }
  }
`;
