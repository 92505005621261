import { ReactNode, createContext, useContext, useState } from 'react';
import { SkillGroupsState } from '../types/skill-groups.types';

export const skillGroupsContextInitialValue: SkillGroupsState = {
  subject: undefined,
  skillGroups: [],
  selectedSkillGroup: undefined,
  skillGroupToEdit: undefined,
  skills: [],
  newSkill: undefined,

  //Modals
  openCreateModal: false,
  openCreateSkillModal: false,
};

const SkillGroupsContext = createContext<SkillGroupsState>(skillGroupsContextInitialValue);

interface SkillGroupsContextProviderProps {
  children: ReactNode;
  initialValue: SkillGroupsState;
}

export const SkillGroupsContextProvider = ({
  children,
  initialValue = skillGroupsContextInitialValue,
}: SkillGroupsContextProviderProps) => {
  const [skillGroupsState, setSkillGroupsState] = useState<SkillGroupsState>(initialValue);

  const updateSkillGroupsState = (newData: Partial<SkillGroupsState>) => {
    setSkillGroupsState((prevState) => ({ ...prevState, ...newData }));
  };

  return (
    <SkillGroupsContext.Provider value={{ ...skillGroupsState, updateSkillGroupsState, setSkillGroupsState }}>
      {children}
    </SkillGroupsContext.Provider>
  );
};

export const useSkillGroupsContext = () => {
  const context = useContext(SkillGroupsContext);
  if (!context) {
    throw new Error('useSkillGroupsContext must be used within a SkillGroupsContext');
  }
  return context;
};
