import { APOLLO_CLIENT } from 'config/apollo.config';
import { Action, State } from 'src/interfaces/reducers';
import i18n from 'src/utils/translations/i18n';
import { getErrorObject } from 'src/utils/funcs';
import * as queries from 'src/modules/device/device.queries';
import { Device } from 'src/interfaces/devices';
import { addSpace } from 'src/utils/funcs/index';
import { Dispatch } from 'redux';

export const GET_DEVICES = 'GET_DEVICES';
export const CREATE_DEVICE = 'CREATE_DEVICE';
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const DELETE_DEVICE = 'DELETE_DEVICE';
export const BROWSE_NODE = 'BROWSE_NODE';
export const GENERATE_MQTT_AUTH = 'GENERATE_MQTT_AUTH';
export const GET_DEVICES_SEARCH = 'GET_DEVICES_SEARCH';
export const CHANGE_SEARCH_INPUT_VALUE_DEVICE = 'CHANGE_SEARCH_INPUT_VALUE_DEVICE';
export const GET_TAGS_BY_TOPIC = 'GET_TAGS_BY_TOPIC';
export const GET_BUCKET = 'GET_BUCKET';
export const ERROR = 'ERROR';

const initialState: State = {
  devices: [],
  tagKeys: null,
  count: 0,
  searchInputValue: '',
  searchResultsDevice: [],
  bucket: null,
  error: false,
};

export const findNode = (tree, fatherNodeId, newNodes) =>
  tree.map((node) => {
    if (node._id === fatherNodeId) {
      node.children = [];
      newNodes.map((newNode) => {
        node.children.push(
          Object.assign(
            {
              children: [],
              name: newNode.displayName,
              id: newNode._id,
              profile: {},
            },
            newNode,
          ),
        );
      });
    } else if (node.children.length > 0) {
      //@ts-ignore
      node.children = this.findNode(node.children, fatherNodeId, newNodes);
    }
    return node;
  });

export default (state: State = initialState, action: Action): State => {
  let device: any = null;
  let devices: any = null;
  switch (action.type) {
    case GET_DEVICES:
      return {
        ...state,
        devices: action.payload.devices,
        count: action.payload.count,
        error: false,
      };
    case CREATE_DEVICE:
      devices = state.devices.slice(0);
      devices.push(action.payload.device);
      return { ...state, devices, error: false };
    case UPDATE_DEVICE:
      devices = [];
      state.devices.map((device) => {
        if (device._id === action.payload.device._id) {
          devices.push(action.payload.device);
        } else {
          devices.push(device);
        }
      });
      return {
        ...state,
        devices,
        error: false,
        searchResultsDevice: state.searchResultsDevice.map((s) =>
          s._id === action.payload.device._id ? { ...s, ...action.payload.device } : s,
        ),
      };
    case DELETE_DEVICE:
      devices = state.devices.filter((device) => device._id !== action.payload.id);
      return {
        ...state,
        devices,
        error: false,
        searchResultsDevice: state.searchResultsDevice.filter((s) => s._id !== action.payload.id),
      };
    case BROWSE_NODE:
      device = state.devices.filter((dev) => dev._id === action.payload.deviceId)[0];
      if (action.payload.nodes.references.length > 0) {
        const nodeTree: any = [];
        if (device.nodeTree === undefined) {
          action.payload.nodes.references.map((node) => {
            nodeTree.push(
              Object.assign(
                {
                  children: [],
                  name: node.displayName,
                  id: node._id,
                  profile: {},
                },
                node,
              ),
            );
            device.nodeTree = nodeTree;
          });
        } else if (action.payload.fatherNodeId) {
          //@ts-ignore
          device.nodeTree = this.findNode(
            device.nodeTree,
            action.payload.fatherNodeId,
            action.payload.nodes.references,
          );
        }
      }

      devices = state.devices.map((dev) => {
        if (dev._id === action.payload.deviceId) {
          return device;
        } else {
          return dev;
        }
      });

      return { ...state, devices, error: false };
    case GET_TAGS_BY_TOPIC:
      const text = action.payload.tags
        .split('\n')
        .filter((line) => !line.startsWith('#'))
        .filter((line, i) => i > 0 && line.trim() !== '');
      const splited: any = [];
      text.map((line) => {
        splited.push(line.split(','));
      });
      const dataToSelect: any = [];
      splited.forEach((el) => {
        if (!!el[5] || !!el[3]) dataToSelect.push(el[5] || el[3]);
      });
      return {
        ...state,
        tagKeys: [{ key: 'name', values: dataToSelect }],
        error: false,
      };
    case CHANGE_SEARCH_INPUT_VALUE_DEVICE:
      return { ...state, searchInputValue: action.payload.searchInputValue };
    case GET_DEVICES_SEARCH:
      devices = [];
      action.payload.devices.map((dev) => {
        if (dev.name.includes(state.searchInputValue)) {
          devices.push(Object.assign({ parent: dev.site }, dev));
        }
      });
      return {
        ...state,
        searchResultsDevice: [...action.payload.sites, ...devices],
      };
    case GENERATE_MQTT_AUTH:
      devices = [];
      state.devices.map((dev) => {
        if (dev._id === action.payload.deviceId) {
          dev.mqttConfig = action.payload.mqttConfig;
          devices.push(dev);
        } else {
          devices.push(dev);
        }
      });
      return { ...state, devices };
    case ERROR:
      return { ...state, error: true };
    case GET_BUCKET:
      return { ...state, bucket: action.payload };
    default:
      return state;
  }
};

export const devicesSearch =
  (searchString) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({
        type: CHANGE_SEARCH_INPUT_VALUE_DEVICE,
        payload: { searchInputValue: searchString },
      });

      const currentSiteId = localStorage.getItem('current_site_id');
      const responseSites = await APOLLO_CLIENT.query({
        variables: {
          filter: {
            name_contains: searchString,
            OR: [{ _id_eq: currentSiteId }, { parentsTree_some: { _id: currentSiteId } }],
          },
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_SITES,
      });

      const responseDevices = await APOLLO_CLIENT.query({
        variables: {
          filter: {
            name_contains: searchString,
            OR: [{ site_eq: currentSiteId }, { site: { parentsTree_some: { _id: currentSiteId } } }],
          },
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_DEVICES,
      });

      // update the local storage with the token using the correct action
      dispatch({
        type: GET_DEVICES_SEARCH,
        payload: {
          sites: responseSites.data.sites,
          devices: responseDevices.data.devices,
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
    }
  };

export const getDevices =
  (filter) =>
  async (dispatch: Dispatch): Promise<Device[]> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          filter,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_DEVICES,
      });

      return { nodeTree: [], ...response.data.devices[0] };
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getMQTTConfig =
  (filter) =>
  async (dispatch: Dispatch): Promise<Device[]> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          filter,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_MQTT_CONFIG,
      });

      return response.data.devices[0].mqttConfig;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getTagsByTopic =
  (topic) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          topic,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_DEVICE_SENSORS,
      });

      dispatch({
        type: GET_TAGS_BY_TOPIC,
        payload: { tags: response.data.getTimeSeriesDeviceSensors },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
    }
  };

export const createDevice =
  (deviceType, name, siteId, topic) =>
  async (dispatch: Dispatch): Promise<Device> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          deviceType,
          siteId,
          name,
          topic,
        },
        mutation: queries.M_CREATE_DEVICES,
      });

      // update the local storage with the token using the correct action
      dispatch({
        type: CREATE_DEVICE,
        payload: { device: response.data.createDevice },
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('device') + addSpace(name) + i18n.t('toastCreateSuccess'),
          severity: 'success',
        },
      });
      return response.data.createDevice;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const updateDevice =
  (deviceType, name, siteId, topic, id) =>
  async (dispatch: Dispatch): Promise<Device> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          deviceType,
          siteId,
          name,
          topic,
          id,
        },
        mutation: queries.M_UPDATE_DEVICES,
      });

      dispatch({
        type: UPDATE_DEVICE,
        payload: { device: response.data.updateDevice },
      });

      return response.data.updateDevice;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const deleteDevice =
  (deviceData) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const id = deviceData._id;
      await APOLLO_CLIENT.mutate({
        variables: {
          id,
        },
        mutation: queries.M_DELETE_DEVICE,
      });

      // update the local storage with the token using the correct action
      dispatch({
        type: DELETE_DEVICE,
        payload: { id: deviceData._id },
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('device') + addSpace(deviceData.name) + i18n.t('toastDeleteSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const refreshDeviceMQTTAuthentication =
  (deviceId) =>
  async (dispatch: Dispatch): Promise<object> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          deviceId,
        },
        mutation: queries.M_GENERATE_DEVICE_MQTT_AUTHENTICATION,
      });

      dispatch({
        type: GENERATE_MQTT_AUTH,
        payload: {
          mqttConfig: response.data.generateDeviceMQTTAuthentication,
          deviceId,
        },
      });
      return response.data.generateDeviceMQTTAuthentication;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };
