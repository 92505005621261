import { MenuItem, Popover } from '@mui/material';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { styles } from 'src/utils/components/generic-input-card/styles';
import { Ref } from 'react';

interface DatetimePopoverProps {
  anchor: {
    current: Element;
  };
  onClose: () => void;
  classes: {
    popoverAdvanced: string;
    menuItemAdvanced: string;
    checkIcon: string;
    menuItemWithCheck: string;
    menuItemWithoutCheck: string;
  };
  input: {
    subType: string;
    type: string;
    _id: string;
  };
  setInput: (input) => void;
  innerRef: Ref<HTMLDivElement>;
  subRef: Ref<HTMLLIElement>;
  id?: string;
  t?: any;
}

const DatePopover = (props: DatetimePopoverProps) => {
  const { classes, input, setInput, innerRef, subRef, anchor } = props;

  return (
    <Popover
      open={true}
      anchorEl={anchor.current}
      onClose={(): void => {
        props.onClose();
      }}
      ref={innerRef}
      classes={{ root: classes.popoverAdvanced }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <MenuItem
        id={`MenuItemSubTypeDatetime`}
        data-testid={`MenuItemSubTypeDatetime`}
        ref={subRef}
        classes={{ root: classes.menuItemAdvanced }}
        onClick={(): void => {
          setInput({
            _id: input._id,
            [input.type]: {
              ...input[input.type],
              values: [],
              triggerAlert: {
                condition: 'BEFORE',
                amount: undefined,
                showIn: undefined,
              },
            },
          });
          props.onClose();
        }}
      >
        <span>{props.t('createAlert')}</span>
      </MenuItem>
    </Popover>
  );
};

export default compose<any>(withTranslation('translation'), withStyles(styles))(DatePopover);
