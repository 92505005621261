import * as React from 'react';
/* import { hasClearance } from 'src/utils/funcs'; */
import { isEmpty, map, get, cloneDeep } from 'lodash';
import { List, Paper, ListItem, ListItemIcon, Tooltip, Menu, MenuItem, IconButton, Divider } from '@mui/material';
import { styles } from 'src/utils/components/side-bar/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowForwardIos } from '@mui/icons-material';
import { compose } from 'redux';
import { toggleSidenav } from 'src/base/root/root.redux';
import { connect } from 'react-redux';
import { icons } from 'src/base/navbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { withStyles } from '@mui/styles';

interface Entry {
  name: string;
  children: Entry;
  [x: string]: any;
}

const Sidenav = (props: any) => {
  const [anchorEl, setAnchorEl] = useState<any>({});
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const hasDeepChildren = (entry: Entry, key: string) => {
    let has = true;
    if (!entry.children || isEmpty(entry.children)) return true;
    /* !hasClearance(key, props.clearances, false, props.showConfMenu); */ else {
      Object.keys(entry.children).forEach((ckey, i) => {
        const grandChildren = entry.children[`${ckey}`].children;
        if (grandChildren && !isEmpty(grandChildren)) {
          if (!hasDeepChildren(entry.children[`${ckey}`], `${key}-${ckey}-${i}`)) has = false;
        } /* else if (hasClearance(`${key}-${ckey}-${i}`, props.clearances, true, props.showConfMenu)) has = false; */
      });
    }
    return has;
  };

  const fieldIcon = (name: string) => {
    const Icone = icons[name];
    return <Icone />;
  };

  const onClose = (name: string) => {
    const newAnchors = cloneDeep(anchorEl);
    delete newAnchors[`${name}`];
    setAnchorEl(newAnchors);
  };

  const onOpen = (event: React.MouseEvent<HTMLDivElement | HTMLLIElement, MouseEvent>, name: string) => {
    if (!anchorEl[`${name}`]) setAnchorEl({ ...anchorEl, [name]: event.currentTarget });
  };

  const render = (keyMap: Entry[], context = '', level = 0, selectedBefore = false) =>
    map(keyMap, (entry, name: string) => {
      //@ts-ignore
      const isMenu: boolean = get(entry, 'children');
      const key = `${context}-${name}`;

      if (name === 'REMOTEASSIST' && !props.tenantInfo?.isRemoteAssistAllowed) return;
      if (name === 'DIVIDER') return <Divider key={name} sx={{ width: '100%' }} />;

      /* if (hasClearance(key, props.clearances, isMenu, props.showConfMenu)) { */
      if (isMenu && !isEmpty(get(entry, 'children')))
        return level === 0 ? (
          <div key={key}>
            <Tooltip title={t(name).toString()} placement={'right'} arrow>
              <ListItem
                button
                id={`listItem${name}`}
                classes={{ selected: props.classes.active }}
                /* eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with */
                selected={location.pathname === entry.path}
              >
                <ListItemIcon
                  aria-controls={name}
                  /* aria-haspopup={name} */
                  classes={location.pathname === entry.path ? { root: props.classes.active } : {}}
                  onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => onOpen(e, name)}
                >
                  {fieldIcon(entry.icon)}
                </ListItemIcon>
              </ListItem>
            </Tooltip>
            <Menu
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              anchorEl={anchorEl[`${name}`]}
              keepMounted
              id={name}
              classes={{ paper: props.classes.menu }}
              open={Boolean(anchorEl[`${name}`])}
              onClose={() => onClose(name)}
            >
              {render(
                /* @ts-ignore */
                entry.children,
                `${key}`,
                level + 1,
                location.pathname === entry.path,
              )}
            </Menu>
          </div>
        ) : (
          <div key={key}>
            <MenuItem
              aria-controls={name}
              /* aria-haspopup={name} */
              id={`menuItem${name}`}
              classes={{ selected: props.classes.active }}
              /* eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with */
              selected={location.pathname === entry.path && selectedBefore}
              onClick={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => onOpen(e, name)}
            >
              {t(name)}
            </MenuItem>
            <Menu
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              id={name}
              classes={{ paper: props.classes.menu }}
              anchorEl={anchorEl[`${name}`]}
              keepMounted
              open={Boolean(anchorEl[`${name}`])}
              onClose={() => onClose(name)}
            >
              {render(
                /* @ts-ignore */
                entry.children,
                `${key}`,
                level + 1,
                location.pathname === entry.path,
              )}
            </Menu>
          </div>
        );

      return level === 0 ? (
        props.loggedUser.clientMixedRealityEnabled && name === 'AR images' ? undefined : (
          <Tooltip title={t(name).toString()} key={key} arrow placement={'right'}>
            <ListItem
              button
              id={`listItem${name}`}
              onClick={(): void => navigate(entry.path)}
              classes={{ selected: props.classes.active }}
              /* eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with */
              selected={location.pathname === entry.path}
            >
              {/* eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with */}
              <ListItemIcon classes={location.pathname === entry.path ? { root: props.classes.active } : {}}>
                {fieldIcon(entry.icon)}
              </ListItemIcon>
            </ListItem>
          </Tooltip>
        )
      ) : (
        <MenuItem
          classes={{ selected: props.classes.active }}
          key={key}
          id={`menuItem${name}`}
          /* eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with */
          selected={location.pathname === entry.path && selectedBefore}
          onClick={(): void => {
            navigate(entry.path);
            setAnchorEl({});
          }}
        >
          {t(name)}
        </MenuItem>
      );
      /*  }
      return undefined; */
    });

  return (
    <Paper classes={{ root: props.classes.paper }}>
      <List classes={{ root: props.classes.list }}>{render(props.entries)}</List>
      <IconButton onClick={() => props.toggleSidenav(false)} className={props.classes.arrowButton} size='large'>
        <ArrowForwardIos />
      </IconButton>
    </Paper>
  );
};

export default compose<any>(
  connect((state) => ({ tenantInfo: state.authenticationReducer.tenantInfo }), {
    toggleSidenav,
  }),
  withStyles(styles),
)(Sidenav);
