import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { getSite } from 'src/modules/site/site.redux';
import { useEffect, useState } from 'react';
import { styles } from 'src/utils/components/site-show/styles';
import { withStyles } from '@mui/styles';
import DetailsPopover from 'src/utils/components/details-popover';
import { NexusGenInputs } from '@server/src/types';

interface SiteShowProps {
  values: { name: string; _id: string }[];
  getSite: (filter: NexusGenInputs['SiteWhereUniqueInput']) => Promise<{ name: string; _id: string }>;
  classes: {
    divValueActive: string;
    divValue: string;
  };
}

const SiteShow = (props: SiteShowProps): JSX.Element => {
  const [site, setSite] = useState<{ name: string; _id: string } | null>(null);
  const [popoverDetails, setPopoverDetails] = useState<EventTarget | null>(null);

  useEffect(() => {
    if (props.values.length) {
      if (props.values[0].name) {
        setSite(props.values[0]);
      } else {
        props.getSite({ _id: props.values[0]._id }).then((resp) => {
          setSite(resp);
        });
      }
    }
  }, [props.values?.at(0)?._id]);

  return (
    <>
      <div
        onClick={(e): void => {
          e.stopPropagation();
          setPopoverDetails(e.target);
        }}
        className={popoverDetails ? props.classes.divValueActive : props.classes.divValue}
      >
        {site ? site.name : null}
      </div>
      {popoverDetails ? (
        <DetailsPopover
          context={'site'}
          anchor={popoverDetails}
          value={site}
          clear={(): void => {
            setPopoverDetails(null);
          }}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (): object => ({});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getSite,
    },
    dispatch,
  );

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default compose<any>(
  withTranslation('translation'),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(SiteShow);
