import gql from 'graphql-tag';
import { LABEL_VALUE } from './label';

export const INPUT_STRING = gql`
  fragment inputString on InputString {
    validations {
      min
      max
      inclusive
      regex
      linkWithInputs
      name
    }
    values
    options
    multiple
    maxValues
    setValueWithQRCode
    failOptions
    optionsWithScore {
      option
      score
    }
    preset {
      presetId
    }
  }
`;

export const INPUT_TOOL = gql`
  fragment inputTool on InputTool {
    setValueWithQRCode
    validations {
      min
      max
      inclusive
      name
      linkWithInputs
      specificTool
    }
    values {
      _id
    }
    multiple
    maxValues
  }
`;

export const INPUT_NUMBER = gql`
  fragment inputNumber on InputNumber {
    values
    unit {
      _id
      symbol
      name
    }
    options
    multiple
    validations {
      min
      max
      inclusive
      name
      notBetween
    }
    maxValues
  }
`;

export const ON_RESPONSE_COMMON_STRING = gql`
  fragment onResponseStringCommon on InputStringOnResponse {
    ifValues
    actionRequired
    actionDraft
    createAlert
    cancelIssue
    createTasks
    finishIssue
    _id
  }
`;

export const ON_RESPONSE_COMMON_NUMBER = gql`
  fragment onResponseNumberCommon on InputNumberOnResponse {
    ifValues
    condition
    actionRequired
    actionDraft
    createAlert
    createTasks
    failResponse
    finishIssue
    _id
    repeatTasks
    repeatTasksIds
  }
`;

export const INPUT_WITH_RECURSIVE = gql`
  fragment inputWithRecursive on Input {
    name
    type
    required
    container
    order
    description
    _id
    log {
      timestamp
      gpsLocation
      cancelMotive
      note {
        text
        files {
          name
          _id
          extension
          accessToken
          size
          comment
          createdBy {
            name
          }
          updatedAt
          download {
            url
            validUntil
            thumbnail {
              url
              validUntil
            }
          }
          hasThumbnail
        }
      }
      string
      number
      element {
        name
      }
      site {
        name
      }
      toolItem {
        _id
      }
      date
      time
      datetime
      file {
        name
      }
      signature {
        name
      }
      table {
        values {
          name
          rows {
            string
          }
        }
      }
      by {
        name
      }
      _id
    }
    instruction {
      values {
        name
        _id
        extension
        accessToken
        size
        comment
        createdBy {
          name
        }
        updatedAt
        download {
          url
          validUntil
          thumbnail {
            url
            validUntil
          }
        }
        hasThumbnail
      }
    }
    signature {
      values {
        name
        image {
          name
          _id
          extension
          accessToken
          size
          comment
          createdBy {
            name
          }
          updatedAt
          download {
            url
            validUntil
            thumbnail {
              url
              validUntil
            }
          }
          hasThumbnail
        }
      }
    }
    string {
      ...inputString
      onResponse {
        ...onResponseStringCommon
        inputs {
          ...inputWithLog
          string {
            ...inputString
            onResponse {
              ...onResponseStringCommon
              inputs {
                ...inputWithLog
                string {
                  ...inputString
                  onResponse {
                    ...onResponseStringCommon
                    inputs {
                      ...inputWithLog
                      string {
                        ...inputString
                        onResponse {
                          ...onResponseStringCommon
                          inputs {
                            ...inputWithLog
                            string {
                              ...inputString
                              onResponse {
                                ...onResponseStringCommon
                                inputs {
                                  ...inputWithLog
                                  string {
                                    ...inputString
                                    onResponse {
                                      ...onResponseStringCommon
                                    }
                                  }
                                  number {
                                    ...inputNumber
                                    onResponse {
                                      ...onResponseNumberCommon
                                    }
                                  }
                                }
                              }
                            }
                            number {
                              ...inputNumber
                              onResponse {
                                ...onResponseNumberCommon
                                inputs {
                                  ...inputWithLog
                                  string {
                                    ...inputString
                                    onResponse {
                                      ...onResponseStringCommon
                                    }
                                  }
                                  number {
                                    ...inputNumber
                                    onResponse {
                                      ...onResponseNumberCommon
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      number {
                        ...inputNumber
                        onResponse {
                          ...onResponseNumberCommon
                          inputs {
                            ...inputWithLog
                            string {
                              ...inputString
                              onResponse {
                                ...onResponseStringCommon
                                inputs {
                                  ...inputWithLog
                                  string {
                                    ...inputString
                                    onResponse {
                                      ...onResponseStringCommon
                                    }
                                  }
                                  number {
                                    ...inputNumber
                                    onResponse {
                                      ...onResponseNumberCommon
                                    }
                                  }
                                }
                              }
                            }
                            number {
                              ...inputNumber
                              onResponse {
                                ...onResponseNumberCommon
                                inputs {
                                  ...inputWithLog
                                  string {
                                    ...inputString
                                    onResponse {
                                      ...onResponseStringCommon
                                    }
                                  }
                                  number {
                                    ...inputNumber
                                    onResponse {
                                      ...onResponseNumberCommon
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                number {
                  ...inputNumber
                  onResponse {
                    ...onResponseNumberCommon
                    inputs {
                      ...inputWithLog
                      string {
                        ...inputString
                        onResponse {
                          ...onResponseStringCommon
                          inputs {
                            ...inputWithLog
                            string {
                              ...inputString
                              onResponse {
                                ...onResponseStringCommon
                                inputs {
                                  ...inputWithLog
                                  string {
                                    ...inputString
                                    onResponse {
                                      ...onResponseStringCommon
                                    }
                                  }
                                  number {
                                    ...inputNumber
                                    onResponse {
                                      ...onResponseNumberCommon
                                    }
                                  }
                                }
                              }
                            }
                            number {
                              ...inputNumber
                              onResponse {
                                ...onResponseNumberCommon
                                inputs {
                                  ...inputWithLog
                                  string {
                                    ...inputString
                                    onResponse {
                                      ...onResponseStringCommon
                                    }
                                  }
                                  number {
                                    ...inputNumber
                                    onResponse {
                                      ...onResponseNumberCommon
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      number {
                        ...inputNumber
                        onResponse {
                          ...onResponseNumberCommon
                          inputs {
                            ...inputWithLog
                            string {
                              ...inputString
                              onResponse {
                                ...onResponseStringCommon
                                inputs {
                                  ...inputWithLog
                                  string {
                                    ...inputString
                                    onResponse {
                                      ...onResponseStringCommon
                                    }
                                  }
                                  number {
                                    ...inputNumber
                                    onResponse {
                                      ...onResponseNumberCommon
                                    }
                                  }
                                }
                              }
                            }
                            number {
                              ...inputNumber
                              onResponse {
                                ...onResponseNumberCommon
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          number {
            ...inputNumber
            onResponse {
              ...onResponseNumberCommon
            }
          }
        }
      }
    }
    number {
      ...inputNumber
      onResponse {
        ...onResponseNumberCommon
        inputs {
          ...inputWithLog
          string {
            ...inputString
            onResponse {
              ...onResponseStringCommon
              inputs {
                ...inputWithLog
                string {
                  ...inputString
                  onResponse {
                    ...onResponseStringCommon
                    inputs {
                      ...inputWithLog
                      string {
                        ...inputString
                        onResponse {
                          ...onResponseStringCommon
                          inputs {
                            ...inputWithLog
                            string {
                              ...inputString
                              onResponse {
                                ...onResponseStringCommon
                              }
                            }
                            number {
                              ...inputNumber
                              onResponse {
                                ...onResponseNumberCommon
                              }
                            }
                          }
                        }
                      }
                      number {
                        ...inputNumber
                        onResponse {
                          ...onResponseNumberCommon
                          inputs {
                            ...inputWithLog
                            string {
                              ...inputString
                              onResponse {
                                ...onResponseStringCommon
                              }
                            }
                            number {
                              ...inputNumber
                              onResponse {
                                ...onResponseNumberCommon
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                number {
                  ...inputNumber
                  onResponse {
                    ...onResponseNumberCommon
                    inputs {
                      ...inputWithLog
                      string {
                        ...inputString
                        onResponse {
                          ...onResponseStringCommon
                          inputs {
                            ...inputWithLog
                            string {
                              ...inputString
                              onResponse {
                                ...onResponseStringCommon
                              }
                            }
                            number {
                              ...inputNumber
                              onResponse {
                                ...onResponseNumberCommon
                              }
                            }
                          }
                        }
                      }
                      number {
                        ...inputNumber
                        onResponse {
                          ...onResponseNumberCommon
                          inputs {
                            ...inputWithLog
                            string {
                              ...inputString
                              onResponse {
                                ...onResponseStringCommon
                              }
                            }
                            number {
                              ...inputNumber
                              onResponse {
                                ...onResponseNumberCommon
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          number {
            ...inputNumber
            onResponse {
              ...onResponseNumberCommon
              inputs {
                ...inputWithLog
                string {
                  ...inputString
                  onResponse {
                    ...onResponseStringCommon
                    inputs {
                      ...inputWithLog
                      string {
                        ...inputString
                        onResponse {
                          ...onResponseStringCommon
                        }
                      }
                      number {
                        ...inputNumber
                        onResponse {
                          ...onResponseNumberCommon
                        }
                      }
                    }
                  }
                }
                number {
                  ...inputNumber
                  onResponse {
                    ...onResponseNumberCommon
                    inputs {
                      ...inputWithLog
                      string {
                        ...inputString
                        onResponse {
                          ...onResponseStringCommon
                        }
                      }
                      number {
                        ...inputNumber
                        onResponse {
                          ...onResponseNumberCommon
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    element {
      values {
        __typename
        name
        profile {
          name
          _id
        }
        site {
          name
          coordinates
          _id
        }
        parentsTree {
          name
          _id
        }
        _id
      }
      options {
        name
        _id
      }
      multiple
    }
    site {
      values {
        __typename
        name
        _id
      }
      options {
        name
        _id
      }
      multiple
    }
    tool {
      setValueWithQRCode
      validations {
        min
        max
        inclusive
        name
        linkWithInputs
        specificTool
      }
      values {
        _id
        code
      }
      multiple
      maxValues
    }
    date {
      validations {
        min
        max
        inclusive
      }
      values
      options
      multiple
    }
    time {
      validations {
        min
        max
        inclusive
      }
      values
      options
      multiple
    }
    datetime {
      validations {
        min
        max
        inclusive
      }
      values
      options
      multiple
    }
    table {
      values {
        name
        type
        rows {
          string
        }
      }
    }
    file {
      values {
        name
        _id
        extension
        accessToken
        size
        comment
        createdBy {
          name
        }
        updatedAt
        download {
          url
          validUntil
          thumbnail {
            url
            validUntil
          }
        }
        hasThumbnail
      }
      options {
        name
        extension
        _id
      }
      multiple
    }
    note {
      text
      files {
        name
        _id
        extension
        accessToken
        size
        comment
        createdBy {
          name
        }
        updatedAt
        download {
          url
          validUntil
          thumbnail {
            url
            validUntil
          }
        }
        hasThumbnail
      }
    }
  }
`;

export const INPUT = gql`
  fragment input on Input {
    name
    type
    required
    order
    _id
    container
    description
    instruction {
      values {
        name
        _id
        extension
        accessToken
        size
        comment
        createdBy {
          name
        }
        updatedAt
        download {
          url
          validUntil
          thumbnail {
            url
            validUntil
          }
        }
        hasThumbnail
      }
    }
    string {
      validations {
        min
        max
        inclusive
        regex
        linkWithInputs
        name
      }
      values
      options
      multiple
      maxValues
      setValueWithQRCode
      failOptions
    }
    number {
      values
      unit {
        _id
        symbol
        name
      }
      options
      multiple
      validations {
        min
        max
        inclusive
        name
        notBetween
      }
    }
    site {
      values {
        __typename
        name
        coordinates
        _id
      }
      options {
        name
        _id
      }
      multiple
    }
    element {
      values {
        __typename
        name
        profile {
          name
          _id
        }
        site {
          name
          _id
        }
        parentsTree {
          name
          _id
        }
        _id
      }
      options {
        name
        _id
      }
      multiple
    }
    date {
      validations {
        min
        max
        inclusive
      }
      values
      options
      multiple
      triggerAlert {
        condition
        amount
        showIn
      }
    }
    time {
      validations {
        min
        max
        inclusive
      }
      values
      options
      multiple
    }
    datetime {
      validations {
        min
        max
        inclusive
      }
      values
      options
      multiple
    }
    file {
      values {
        name
        _id
        extension
        accessToken
        size
        comment
        createdBy {
          name
        }
        updatedAt
        download {
          url
          validUntil
          thumbnail {
            url
            validUntil
          }
        }
        hasThumbnail
      }
      options {
        name
        extension
        _id
      }
      multiple
    }
    tool {
      setValueWithQRCode
      validations {
        min
        max
        inclusive
        name
        linkWithInputs
        specificTool
      }
      values {
        _id
      }
      multiple
      maxValues
    }
    signature {
      values {
        name
        image {
          name
          _id
          extension
          accessToken
          size
          comment
          createdBy {
            name
          }
          updatedAt
          download {
            url
            validUntil
            thumbnail {
              url
              validUntil
            }
          }
          hasThumbnail
        }
      }
    }
    table {
      values {
        name
        type
        rows {
          string
        }
      }
    }
    labelValue {
      values {
        ...labelValue
      }
    }
    tags {
      values {
        name
        value
        key
        _id
        __typename
        unit {
          name
          symbol
          _id
        }
        device {
          name
          _id
        }
      }
    }
  }
  ${LABEL_VALUE}
`;

export const INPUT_WITH_LOG = gql`
  fragment inputWithLog on Input {
    name
    type
    required
    container
    order
    description
    _id
    instruction {
      values {
        name
        _id
        extension
        accessToken
        size
        comment
        createdBy {
          name
        }
        updatedAt
        download {
          url
          validUntil
          thumbnail {
            url
            validUntil
          }
        }
        hasThumbnail
      }
    }
    string {
      validations {
        min
        max
        inclusive
        regex
        linkWithInputs
        name
      }
      values
      options
      multiple
      maxValues
      setValueWithQRCode
    }
    number {
      values
      unit {
        _id
        symbol
        name
      }
      options
      multiple
      validations {
        min
        max
        inclusive
        name
        notBetween
      }
    }
    element {
      values {
        __typename
        name
        profile {
          name
          _id
        }
        site {
          name
          coordinates
          _id
        }
        parentsTree {
          name
          _id
        }
        _id
      }
      options {
        name
        _id
      }
      multiple
    }
    date {
      validations {
        min
        max
        inclusive
      }
      values
      options
      multiple
    }
    time {
      validations {
        min
        max
        inclusive
      }
      values
      options
      multiple
    }
    datetime {
      validations {
        min
        max
        inclusive
      }
      values
      options
      multiple
    }
    file {
      values {
        name
        _id
        extension
        accessToken
        size
        comment
        createdBy {
          name
        }
        updatedAt
        download {
          url
          validUntil
          thumbnail {
            url
            validUntil
          }
        }
        hasThumbnail
      }
      options {
        name
        extension
        _id
      }
      multiple
    }
    signature {
      values {
        name
        image {
          name
          _id
          extension
          accessToken
          size
          comment
          createdBy {
            name
          }
          updatedAt
          download {
            url
            validUntil
            thumbnail {
              url
              validUntil
            }
          }
          hasThumbnail
        }
      }
    }
    site {
      values {
        __typename
        name
        coordinates
        _id
      }
      options {
        name
        _id
      }
      multiple
    }
    tool {
      setValueWithQRCode
      validations {
        min
        max
        inclusive
        name
        linkWithInputs
        specificTool
      }
      values {
        _id
      }
      multiple
      maxValues
    }
    table {
      values {
        name
        type
        rows {
          string
        }
      }
    }
    log {
      timestamp
      gpsLocation
      string
      number
      cancelMotive
      note {
        text
        files {
          name
          _id
          extension
          accessToken
          size
          comment
          createdBy {
            name
          }
          updatedAt
          download {
            url
            validUntil
            thumbnail {
              url
              validUntil
            }
          }
          hasThumbnail
        }
      }
      element {
        name
      }
      site {
        name
      }
      toolItem {
        _id
      }
      date
      time
      datetime
      file {
        name
      }
      signature {
        name
      }
      table {
        values {
          name
          rows {
            string
          }
        }
      }
      by {
        name
      }
      _id
    }
    note {
      files {
        name
        _id
        extension
        accessToken
        size
        comment
        createdBy {
          name
        }
        updatedAt
        download {
          url
          validUntil
          thumbnail {
            url
            validUntil
          }
        }
        hasThumbnail
      }
      text
    }
  }
`;
