import { MediaLibraryState } from '../types/media-library.types';

export const mediaLibraryContextInitialValue: MediaLibraryState = {
  data: [],
  selectedFiles: [],
  totalFiles: 0,
  showAddFileDialog: false,
  showFilterPopover: false,
  requireRefetch: false,
  filters: {},
  fileUploads: [],
  fileOrderBy: ['createdAt_DESC'],
};
