import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { useEffect, useState } from 'react';
import { styles } from 'src/utils/components/tool-show/styles';
import { withStyles } from '@mui/styles';
import DetailsPopover from 'src/utils/components/details-popover';

interface ToolShowProps {
  values: { name: string; _id: string }[];
  classes: {
    divValueActive: string;
    divValue: string;
  };
}

const ToolShow = (props: ToolShowProps): JSX.Element => {
  const [popoverDetails, setPopoverDetails] = useState<EventTarget | null>(null);

  useEffect(() => {
    if (props.values.length) {
      if (props.values[0].name) {
      } else {
      }
    }
  }, []);

  return (
    <>
      <div
        onClick={(e): void => {
          e.stopPropagation();
          setPopoverDetails(e.target);
        }}
        className={popoverDetails ? props.classes.divValueActive : props.classes.divValue}
      ></div>
      {popoverDetails ? (
        <DetailsPopover
          context={'tool'}
          anchor={popoverDetails}
          //value={}
          clear={(): void => {
            setPopoverDetails(null);
          }}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (): object => ({});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default compose<any>(
  withTranslation('translation'),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ToolShow);
