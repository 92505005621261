import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    topLabel: {
      display: 'none',
      height: '0px',
      width: '0px',
    },
    valueLabel: {
      fontSize: '20px',
      fill: theme.palette.primary.main,
    },
    '.segment-alue': {
      fontWeight: 400,
      fontSize: '14px',
      fill: theme.palette.secondary.main,
    },
    '.current-value': {
      fontWeight: 500,
      fontSize: '16px',
      fill: theme.palette.secondary.main,
    },
    minMaxLabels: {
      position: 'absolute',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      top: '110%',
      fontSize: '14px',
      color: theme.palette.text.primary,
      transform: 'translateY(-100px)',
    },
    customGauge: {
      '& svg g .segment-value': {
        fill: `${theme.palette.text.primary} !important`,
        fontSize: '1rem !important',
        fontWeight: '400 !important',
        fontFamily: 'Open Sans',
      },
      '& svg g .current-value': {
        fill: `${theme.palette.text.primary} !important`,
        fontSize: '42px !important',
        fontWeight: '500 !important',
        fontFamily: 'Poppins',
        transform: 'translateY(20px)',
      },
    },
    minMaxContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      transform: 'translateY(-80px)',
    },
    minMaxText: {
      color: theme.palette.text.secondary,
      fontSize: '20px',
      fontWeight: 400,
      fontFamily: 'Poppins',
    },
  });
