import { ArrowForwardIos, DragIndicator, GetApp, MoreVert, ViewColumnOutlined } from '@mui/icons-material';
import { Checkbox, CircularProgress, Divider, IconButton, Popover, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import {
  AccessorKeyColumnDef,
  CellContext,
  ColumnDef,
  Row,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import { uniq } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ColumnsPopover from 'src/modules/agenda/components/columns-popover';
import { styles } from 'src/utils/components/table/styles';
import useDebouncedCallback from 'src/utils/hooks/useDebouncedCallback';
import useEffectEvent from 'src/utils/hooks/useEffectEvent';
import {
  CHECKBOX_HEADER_ID,
  DEFAULT_COLUMN_MIN_WIDTH,
  DEFAULT_COLUMN_WIDTH,
  DEFAULT_FETCH_SIZE,
  DEFAULT_ROWS_TO_LOAD,
  EXCLUDED_COLUMN_OPTIONS,
  MORE_OPTIONS_HEADER_ID,
} from '../constants/table.constants';
import encodeColumns from '../utils/table-abstract.utils.encodeColumn';
import TableAbstractColumns from './table-abstract-columns';
import TableAbstractRow from './table-abstract-row';

export type TableAbstractProps<TData> = {
  /** How many rows in total will the background fetch (the sum of all rows from all pages, IE: a GraphQL Count)
   */
  dataSize: number;
  /** Promise to fetch data.
   * If you are using PageSize and page logic then:
   * Page = skip / limit
   * PageSize = limit
   * @param skip The graphQL skip parameter
   * @param limit The graphQL limit parameter
   * @param indexed For legacy queries which use startIndex and stopIndex
   */
  loadData: (skip: number, limit: number, indexed?: { startIndex: number; stopIndex: number }) => Promise<TData[]>;
  /** Column Render definition. See mock data for examples */
  columns: ColumnDef<TData>[];
  /** Initially visible columns (visibleColumnOptions keys, which defaults to _columns_ id's) defaults to all visible
   *  Implicitly requires _onColumnsVisibilityAndOrderChange_ to update the prop value
   */
  visibleColumns?: string[];
  /** All column ids, shown on ColumnPopover to be shown/hidden/filtered. Uses ID of columns, so ensure they have translation keys
   * Defaults to the array of all the id's of the parsed _columns_ with the EXCLUDED_COLUMN_OPTIONS filtered out.
   */
  visibleColumnOptions?: { key: string; label: string }[];
  /** Column keys which you want to exclude from the ColumnPopOver (IE: custom options/interactions column, etc...)
   * @defaults Empty string array
   */
  excludedColumnOptionsKeys?: string[];
  /** Hides the download data button, which calls the onClickExportData callback prop */
  showDownload?: boolean;
  /** Overrides the 'Loading...' on table data */
  showLoading?: boolean;
  /** Show the first column to display checkboxes. */
  showCheckboxes?: boolean;
  /** Hides the last columns with the three dots / 'More...' column */
  hideMore?: boolean;
  /** Hides the headers on top of the table */
  hideHeader?: boolean;
  isDraggable?: (row: TData) => boolean;
  /** Number of rows to fetch when reaching the bottom, defaults to 'allColumns' prop */
  rowsToLoad?: number;
  /** Index of data which has their checkbox initially ticked */
  initialTickedCheckboxes?: number[];
  /** Force a refetch, be carefull to set this to _false_ after the refetch is successfull. (using _loadData_ or _onFetchingStateChanged_) */
  requireRefetch?: boolean;
  /** What % of scroll triggers a fetch to next-page of data. Defaults to 70% */
  fetchScrollPercentage?: number;
  /** Optional Dependency array for refetch. Anytime one of these dependencies is changed, it triggers a refetch */
  refetchDependencies?: React.DependencyList;
  cellColumnIdsToHide?: string[];
  /** JSX Elements that are next to the checkboxes (IE: when you want checkboxes with icons) */
  checkboxIcons?: (data?: CellContext<TData, string>) => JSX.Element;
  checkboxIconSize?: number;
  /** Component on click callback */
  checkboxIconOnClick?: (index: number) => void;
  /**
   * Callback when a checkbox changes
   * @param tickedChecboxIndices Index of checkboxes which have been ticked
   * @param data Current array of data , (consult this array for the indices of the checked boxes)
   */
  /** Event for when a draggable row is dragged over another row */
  onRowDrag?: (args: { src: TData; tgt: TData }) => void;
  onCheckboxesChanged?: (tickedChecboxIndices: number[], data?: TData[]) => void;
  /** Callback when internal table data is updated. */
  onDataUpdated?: (data: TData[]) => void;
  /** Callback when table is fetching data. Usefull to disable buttons. */
  onFetchingStateChanged?: (isFetching: boolean) => void;
  /** When user clicks the 'download' button */
  onClickExportData?: () => void;
  /** Callback for when column popover changes order/visibility of columns. Values are column accessors.
   * This is responsible for updating _visibleColumns_.
   * If this function is not provided in the props, the column visibility's state is tracked internally in the
   * component independently. Making this function usefull if you wish to ensure the table is synced with backend.
   */
  onColumnsVisibilityAndOrderChange?: (columns: string[]) => any;
  /** JSX for the 'more' options column */
  moreOptionsCell?: (args: CellContext<TData, string>) => JSX.Element;
  /** Callback for when the user clicks a row */
  onClickRow?: (row: Row<TData>) => void;
  /** Data to join to the main Data array, usefull for things like _subscription data_
   * Important note: it is best to ensure this is a **useCallback** function so as to prevent
   * infinite re-renders.
   */
  dataOverrideFunction?: (currentData: TData[]) => TData[];
  /** Callback when the mouse hovers the <tr> element of each row */
  onRowHoverEnter?: (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    headerGroup: Row<TData>,
    rowIndex: number,
  ) => void;
  /** Callback when the mouse stops hovering the <tr> element of each row */
  onRowHoverExit?: (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    headerGroup: Row<TData>,
    rowIndex: number,
  ) => void;
  /** Component when no data exists */
  noResultComponent?: React.ReactNode;
  'data-testid': string;
  className?: string;
  disableCheckBoxes?: string[];
  onColumnSizeChange?: (col: { newSize: number; name: string }) => any;
};

const useStyles = makeStyles(styles);

/**
 * `TableAbstract` is a highly customizable table component with an internal infinite loader.
 * It is designed to handle large datasets efficiently by loading data in batches.
 *
 * The component expects the total count of elements to load (usually obtained on the first render
 * within a `useEffect`) and passed through the `dataSize` prop. Once initialized, `TableAbstract`
 * will automatically fetch the first batch of data and handle subsequent loads as the user scrolls
 * or interacts with the table.
 *
 * Take care of my magnum opus, -Sam
 *
 * @template TData - The type of the data entries that the table will manage and display.
 */
const TableAbstract = <TData,>(props: TableAbstractProps<TData>) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const dataTestId = props['data-testid'];
  const {
    dataSize,
    columns: columnProps,
    rowsToLoad = DEFAULT_ROWS_TO_LOAD,
    excludedColumnOptionsKeys = [],
    visibleColumnOptions = columnProps
      .map((c: AccessorKeyColumnDef<TData>) => ({
        key: c.id,
        label: t(typeof c.accessorKey === 'string' ? c.accessorKey : c.id),
      }))
      .filter((c) => !EXCLUDED_COLUMN_OPTIONS.includes(c.key) && !excludedColumnOptionsKeys.includes(c.key)),
    visibleColumns: visibleColumnsProps = visibleColumnOptions.map((p) => p.key),
    hideMore,
    showDownload,
    showLoading,
    showCheckboxes,
    initialTickedCheckboxes = null,
    requireRefetch: propRequireRefetch = false,
    fetchScrollPercentage = 75,
    refetchDependencies,
    isDraggable,
    checkboxIcons,
    checkboxIconSize,
    onCheckboxesChanged,
    loadData,
    onClickExportData,
    onDataUpdated,
    onFetchingStateChanged,
    onColumnsVisibilityAndOrderChange,
    moreOptionsCell = () => <div />,
    onClickRow,
    dataOverrideFunction,
    checkboxIconOnClick,
    onRowHoverEnter,
    onRowHoverExit,
    noResultComponent,
    onColumnSizeChange,
    onRowDrag,
    hideHeader = false,
    disableCheckBoxes,
  } = props;
  const [openColumnVisibilityPopover, setOpenColumnVisibilityPopover] = useState<boolean>(false);
  const [openMorePopover, setOpenMorePopover] = useState<boolean>(false);
  const [internalTickedCheckboxes, setInternalTickedCheckboxes] = useState<number[]>(initialTickedCheckboxes || []);
  const [requireRefetch, setRefetchRequired] = useState(propRequireRefetch);
  const [internalVisibleColumns, setInternalVisibleColumns] = useState(visibleColumnsProps);
  const [dataColumnsProtection, setDataColumnsProtection] = useState<string>();
  const tableRef = useRef();
  const refFilter = useRef();
  const [selectedCell, setSelectedCell] = useState<{ rowIndex: number; cellIndex: number; width: number }>();
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number>(null);

  useEffect(() => {
    if (onColumnsVisibilityAndOrderChange) {
      setInternalVisibleColumns(visibleColumnsProps);
    }
  }, [!!onColumnsVisibilityAndOrderChange, JSON.stringify(visibleColumnsProps)]);

  const queryClient = useQueryClient();

  const { data, fetchNextPage, isFetching, isLoading, hasNextPage, refetch, isRefetching } = useInfiniteQuery({
    refetchOnWindowFocus: false,
    queryKey: ['table-data', dataTestId], //adding sorting state as key causes table to reset and fetch from new beginning upon sort | Add data-testid to ensure table query is unique (important in case we use two or more tables in same page)
    queryFn: ({ pageParam = 0 }) => {
      if (!loadData) {
        return Promise.resolve([]);
      }
      const limit = DEFAULT_FETCH_SIZE;
      const skip = pageParam * limit;
      const startIndex = skip;
      const stopIndex = skip + limit - 1;
      setDataColumnsProtection(encodeColumns(columns));
      return loadData(skip, limit, { startIndex, stopIndex });
    },
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage?.length > 0) {
        return allPages.length;
      }
      return null; //Here the hasNextPage will be false.
    },
  });

  useEffect(() => {
    onFetchingStateChanged?.(isFetching);
  }, [isFetching]);

  const flatData = useMemo(() => {
    if (!data?.pages) {
      return [];
    }
    const flattenedData = data.pages.flatMap((p) => p);
    if (dataOverrideFunction) {
      return dataOverrideFunction(flattenedData);
    }
    return flattenedData;
  }, [data, dataOverrideFunction]);

  const memoizedOnDataUpdated = useEffectEvent(() => {
    onDataUpdated?.(flatData);
  });

  useEffect(() => {
    memoizedOnDataUpdated();
  }, [flatData.length, dataColumnsProtection]);

  const tickedCheckboxes = useMemo(() => {
    if (initialTickedCheckboxes !== null) {
      return initialTickedCheckboxes;
    }
    return internalTickedCheckboxes;
  }, [initialTickedCheckboxes, internalTickedCheckboxes]);

  useEffect(() => {
    onCheckboxesChanged?.(internalTickedCheckboxes, flatData);
  }, [internalTickedCheckboxes]);

  const columns = useMemo(() => {
    let columnsToDisplay = columnProps.filter((column) => internalVisibleColumns.includes(column.id));
    if (showCheckboxes) {
      const checkBoxBtn: ColumnDef<TData, string> = {
        id: CHECKBOX_HEADER_ID,
        header: () => (
          <div data-testid={'table-checkbox-all'} className={classes.checkboxIconsCell}>
            {isDraggable && (
              <div
                id='drag-handle'
                className={classes.dragIconContainer}
                style={{
                  visibility: 'hidden',
                }}
              >
                <DragIndicator classes={{ root: classes.dragIcon }} />
              </div>
            )}
            <Checkbox
              checked={flatData.length > 0 && tickedCheckboxes.length > 0}
              classes={{ checked: classes.checkboxChecked }}
              onChange={(_, checked) => {
                const indexes = [];
                if (checked) {
                  for (let index = 0; index < flatData.length; index++) {
                    const element = flatData[index];
                    if (!disableCheckBoxes?.includes(element._id)) indexes.push(index);
                  }
                }
                setInternalTickedCheckboxes(indexes);
              }}
              indeterminate={tickedCheckboxes.length > 0 && tickedCheckboxes.length < flatData.length}
            />
            <div style={{ visibility: 'hidden', pointerEvents: 'none' }}>{checkboxIcons?.()}</div>
          </div>
        ),
        accessorKey: CHECKBOX_HEADER_ID,
        size: checkboxIcons ? (checkboxIconSize ?? 80) : 60,
        minSize: 60,
        enableResizing: false,
        cell: (data) => {
          return (
            <div className={classes.checkboxIconsCell}>
              {isDraggable?.(data.row?.original) && (
                <div
                  id='drag-handle'
                  data-testid={`drag-handle-${data.row.index}`}
                  className={classes.dragIconContainer}
                >
                  <DragIndicator classes={{ root: classes.dragIcon }} />
                </div>
              )}
              <Checkbox
                data-testid={`table-checkbox-index-${data.row.index}`}
                checked={tickedCheckboxes.includes(data.row.index)}
                //@ts-ignore
                disabled={disableCheckBoxes?.includes(data.row?.original._id)}
                onChange={(_, isChecked) => {
                  const { index } = data.row;
                  if (isChecked && !tickedCheckboxes.includes(index)) {
                    setInternalTickedCheckboxes([...tickedCheckboxes, index]);
                  } else if (!isChecked && tickedCheckboxes.includes(index)) {
                    setInternalTickedCheckboxes(tickedCheckboxes.filter((idx) => idx !== index));
                  }
                }}
                classes={{ checked: classes.checkboxChecked }}
              />
              <div onClick={() => checkboxIconOnClick?.(data.row.index)}>{checkboxIcons?.(data)}</div>
            </div>
          );
        },
      };
      columnsToDisplay = [checkBoxBtn, ...columnsToDisplay];
    }
    if (!hideMore) {
      const hideMoreBtn: ColumnDef<TData, string> = {
        id: MORE_OPTIONS_HEADER_ID,
        header: () => (
          <IconButton
            ref={refFilter}
            style={{
              display: 'flex',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            data-testid={'table-more-button'}
            onClick={() => setOpenMorePopover(true)}
          >
            <MoreVert classes={{ root: classes.moreVertIcon }} />
          </IconButton>
        ),
        accessorKey: MORE_OPTIONS_HEADER_ID,
        size: 45,
        minSize: 45,
        enableResizing: false,
        cell: moreOptionsCell, //TODO: possibility of a 'more' button to expand on interaction with individual rows
      };
      columnsToDisplay = [...columnsToDisplay, hideMoreBtn];
    }
    return columnsToDisplay;
  }, [
    data,
    flatData.length,
    columnProps,
    hideMore,
    showCheckboxes,
    tickedCheckboxes.length,
    internalVisibleColumns.length,
    disableCheckBoxes,
  ]);

  //Prevents forcing a table to render data which is incompatible with the provided columns
  if (data?.pages.length > 0 && !dataColumnsProtection) {
    queryClient.invalidateQueries();
    queryClient.clear();
    setRefetchRequired(true);
  }

  //Prevent errors with stale data when user switches tabs quickly mid-fetch
  useEffect(() => {
    queryClient.invalidateQueries();
    queryClient.clear();
    refetch();
    setHoveredRowIndex(-1);
  }, []);

  useEffect(() => {
    if (refetchDependencies != null && !isRefetching) {
      setRefetchRequired(true);
    }
  }, refetchDependencies);
  ///////////////////////
  useEffect(() => {
    if (requireRefetch && !isRefetching) {
      setRefetchRequired(false);
      setInternalTickedCheckboxes([]);
      queryClient.clear();
      refetch();
    }
  }, [requireRefetch, queryClient]);

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

        const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
        const scrollPastFiftyPercent = scrollPercentage > fetchScrollPercentage;

        if (
          dataSize &&
          dataSize > 0 &&
          scrollPastFiftyPercent &&
          !isFetching &&
          !isRefetching &&
          flatData.length < dataSize &&
          !isLoading &&
          hasNextPage
        ) {
          fetchNextPage();
        }
      }
    },
    [isFetching, flatData.length, rowsToLoad, dataSize, isLoading, hasNextPage],
  );

  const table = useReactTable<TData>({
    data: isRefetching ? [] : flatData, //Prevent render errors with wrongly cached data
    columns,
    state: {
      columnOrder: uniq([CHECKBOX_HEADER_ID, ...internalVisibleColumns, MORE_OPTIONS_HEADER_ID]),
    },
    columnResizeMode: 'onEnd',
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: DEFAULT_COLUMN_WIDTH,
      minSize: DEFAULT_COLUMN_MIN_WIDTH,
    },
  });

  const { rows } = table.getRowModel();

  const showNoResultsFound = !isFetching && !rows?.length && !showLoading;
  const showLoadingTableData = isFetching || showLoading || requireRefetch;

  const tickedCheckboxesStyle = useCallback(
    (rowIndex) =>
      tickedCheckboxes.includes(rowIndex)
        ? { color: theme.palette.primary.main, backgroundColor: theme.palette.primary.light }
        : {},
    [tickedCheckboxes.length],
  );

  const debouncedHandleMouseEnterTd = useDebouncedCallback(
    (rowIndex: number, cellIndex: number, width: number) => {
      setSelectedCell({ rowIndex, cellIndex, width });
    },
    500,
    [],
  );

  if (isLoading) {
    return (
      <div data-testid={'loading-table'} className={classes.loadingSpinner}>
        {t('loading')}
        <CircularProgress color='inherit' size={18} />
      </div>
    );
  }

  return (
    <div
      className={classNames(classes.container, props.className)}
      onScroll={(e) => {
        fetchMoreOnBottomReached(e.target as HTMLDivElement);
      }}
    >
      <table
        style={{
          width: table.getCenterTotalSize(),
          borderSpacing: 'unset',
          tableLayout: 'fixed',
        }}
        className={classes.table}
        ref={tableRef}
        data-testid={`${dataTestId}-table`}
        id={`${dataTestId}-table`}
        onMouseLeave={() => setHoveredRowIndex(-1)}
        onBlur={() => setHoveredRowIndex(-1)}
      >
        {!hideHeader && (
          <thead
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 2,
              textAlign: 'left',
            }}
          >
            {table.getHeaderGroups().map((headerGroup, rowIndex) => (
              <tr
                id={`${dataTestId}-table-header-row-${rowIndex}`}
                key={headerGroup.id}
                data-testid={`${dataTestId}-table-header-row-${rowIndex}`}
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <TableAbstractColumns<TData>
                      key={header.id}
                      header={header}
                      table={table}
                      setColumnOrder={(colOrder) => {
                        const newColumnOrder = colOrder.filter(
                          (columnId) =>
                            !EXCLUDED_COLUMN_OPTIONS.includes(columnId) &&
                            !excludedColumnOptionsKeys.includes(columnId),
                        );
                        if (onColumnsVisibilityAndOrderChange) {
                          onColumnsVisibilityAndOrderChange(newColumnOrder);
                        }
                        setInternalVisibleColumns(newColumnOrder);
                      }}
                      tableRef={tableRef}
                      onColumnSizeChange={onColumnSizeChange}
                    />
                  );
                })}
              </tr>
            ))}
          </thead>
        )}
        <tbody>
          {rows.map((row, index) => {
            return (
              <TableAbstractRow<TData>
                id={`${dataTestId}-table-row-${index}`}
                key={row.id}
                className={hoveredRowIndex === index ? classes.rowHovered : classes.row}
                style={tickedCheckboxesStyle(index)}
                onClick={() => onClickRow?.(row)}
                data-testid={`${dataTestId}-table-row-${index}`}
                onMouseEnter={(e) => {
                  e.stopPropagation();
                  onRowHoverEnter?.(e, row, index);
                  setHoveredRowIndex(index);
                }}
                onMouseLeave={(e) => {
                  e.stopPropagation();
                  if (onRowHoverExit) {
                    onRowHoverExit(e, row, index);
                    setHoveredRowIndex(-1);
                  }
                }}
                isDraggable={isDraggable?.(row.original)}
                onRowDrop={onRowDrag}
                rowData={row}
              >
                {row.getVisibleCells().map((cell, cellIndex) => {
                  //@ts-ignore
                  const getTdProps = cell.column.columnDef.meta?.getTdProps; //See use-case in table-issues-and-folders.columns.tsx
                  //@ts-ignore
                  const hide = cell.column.columnDef.meta?.hide;
                  if (hide?.(cell)) {
                    return null; //See use-case in table-issues-and-folders.columns.tsx
                  }

                  const tdProps = getTdProps?.(cell, internalVisibleColumns) ?? {};

                  return (
                    <td
                      key={cell.id}
                      style={{
                        width:
                          selectedCell?.rowIndex === index && selectedCell?.cellIndex === cellIndex
                            ? selectedCell.width
                            : cell.column.getSize(),
                      }}
                      className={`${classes.cell} ${selectedCell?.rowIndex === index && selectedCell?.cellIndex === cellIndex ? classes.cellActive : ''}`}
                      data-testid={`${dataTestId}-table-row-${index}-cell-${cellIndex}`}
                      id={`${dataTestId}-table-row-${index}-cell-${cellIndex}`}
                      onMouseEnter={(e) => {
                        if (!cell.column.getCanResize()) return;
                        debouncedHandleMouseEnterTd(index, cellIndex, e?.currentTarget?.clientWidth);
                      }}
                      onMouseLeave={() => {
                        setSelectedCell(undefined);
                        debouncedHandleMouseEnterTd?.cancel();
                      }}
                      {...tdProps}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </TableAbstractRow>
            );
          })}
        </tbody>
      </table>
      {showNoResultsFound && (
        <div className={classes.noData}>{noResultComponent ? noResultComponent : t('noResultsFound')}</div>
      )}
      {showLoadingTableData && (
        <div data-testid={'loading-table-data'} className={classes.loadingSpinnerTableEnd}>
          {t('loading')}
          <CircularProgress color='inherit' size={16} />
        </div>
      )}
      {openMorePopover && (
        <Popover
          open={openMorePopover}
          anchorEl={refFilter.current}
          onClose={() => {
            setOpenMorePopover(false);
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          classes={{ paper: classes.morePopover }}
        >
          <div>
            <div className={classes.viewOptions}>{t('moreOptions')}</div>
            <div
              style={{ height: '100%' }}
              className={classes.columnsSort}
              data-testid={'columns-visibility-button'}
              onClick={() => {
                setOpenColumnVisibilityPopover(!openColumnVisibilityPopover);
                setOpenColumnVisibilityPopover(!openColumnVisibilityPopover);
                setOpenMorePopover(false);
              }}
            >
              <ViewColumnOutlined style={{ marginRight: '5px' }} />
              {t('columnsSort')}
              {openColumnVisibilityPopover ? (
                <ArrowForwardIos classes={{ root: classes.arrowForwardIos }} style={{ rotate: '-90deg' }} />
              ) : (
                <ArrowForwardIos classes={{ root: classes.arrowForwardIos }} />
              )}
            </div>
            {showDownload ? (
              <>
                <Divider />
                <div
                  className={classes.columnsSort}
                  onClick={() => {
                    setOpenMorePopover(false);
                    onClickExportData();
                  }}
                  data-testid={'download-table-button'}
                >
                  <GetApp style={{ marginRight: '5px' }} />
                  {t('download')}
                </div>
              </>
            ) : undefined}
          </div>
        </Popover>
      )}
      {openColumnVisibilityPopover && (
        <ColumnsPopover
          open={openColumnVisibilityPopover}
          refAnchor={refFilter.current}
          onClose={(): void => {
            setOpenColumnVisibilityPopover(false);
          }}
          allColumns={visibleColumnOptions}
          provColumns={internalVisibleColumns}
          save={(colOrder) => {
            setOpenColumnVisibilityPopover(false);
            const newColumnOrder = colOrder.filter(
              (columnId: string) =>
                !EXCLUDED_COLUMN_OPTIONS.includes(columnId) && !excludedColumnOptionsKeys.includes(columnId),
            );
            if (onColumnsVisibilityAndOrderChange) {
              onColumnsVisibilityAndOrderChange(newColumnOrder);
            }
            setInternalVisibleColumns(newColumnOrder);
          }}
        />
      )}
    </div>
  );
};

export default TableAbstract;
