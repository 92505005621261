import { CheckBox, CheckBoxOutlineBlankOutlined } from '@mui/icons-material';
import { Backdrop, CircularProgress, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isEqual, uniq } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { compose } from 'redux';
import { useMainContainerContext } from 'src/base/main-container/context/main.container.context';
import { showSnackbar } from 'src/base/root/root.redux';
import { Skill, SkillSkillSpecificLevelsDatum, SkillSubject, SkillSubjectSubjectLevelsDatum } from 'src/gql/graphql';
import DefaultBackground from 'src/utils/components/default-background';
import DefaultButton from 'src/utils/components/default-button/default-button';
import ConfirmNavigation from 'src/utils/components/lose-content-modal';
import { typedConnect } from 'src/utils/funcs';
import DialogResetChanges from '../settings/components/appearance/components/dialog-reset-changes';
import { MainHeaderLeft, MainHeaderRight } from './components/main-header';
import { SkillLevelChip } from './components/skill-level';
import { allSkillLevels, getSkillLevelDescription, getSkillLevelName, SkillLevelOptions } from './func/skills-levels';
import { normalizeLevels } from './func/skills-normalizeLevels';
import { getSkill, getSkillSubject, updateSkill, updateSkillSubject } from './skills.redux';
import { styles } from './styles/skill-levels.default';

const useStyles = makeStyles(styles);

type SkillLevelsProps = ConnectedProps<typeof connector>;

const SkillLevels = (props: SkillLevelsProps) => {
  const { getSkillSubject, updateSkillSubject, getSkill, updateSkill } = props;
  const { id, subjectId } = useParams<{ id: string; subjectId: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [subject, setSubject] = useState<SkillSubject>();
  const [skill, setSkill] = useState<Skill>();
  const [edit, setEdit] = useState(false);
  const [subjectLevelsData, setSubjectLevelsData] = useState<SkillSubjectSubjectLevelsDatum>();
  const [openResetDefaultDialog, setOpenResetDefaultDialog] = useState<boolean>(false);
  const [chosenSubjectLevels, setChosenSubjectLevels] = useState<SkillLevelOptions[]>(
    allSkillLevels.filter((s) => s !== 'NOT_APPLICABLE'),
  );
  const [skillSpecificLevelsData, setSkillSpecificLevelsData] = useState<
    SkillSubjectSubjectLevelsDatum | SkillSkillSpecificLevelsDatum
  >();
  const [chosenSkillLevels, setChosenSkillLevels] = useState<SkillLevelOptions[]>([]);
  const [openAreYouSureDialog, setOpenAreYouSureDialog] = useState<boolean>(false);

  const mainContainerState = useMainContainerContext();
  const { updateMainContainerState } = mainContainerState;

  const levelsDataOverride = useMemo(
    () =>
      allSkillLevels.reduce((arr, sub) => {
        arr[sub] = skillSpecificLevelsData?.[sub] ?? subjectLevelsData?.[sub] ?? allSkillLevels[sub];
        return arr;
      }, {}),
    [subjectLevelsData, skillSpecificLevelsData],
  );

  const checkboxLevels = useMemo(
    () =>
      chosenSkillLevels?.length
        ? chosenSkillLevels
        : chosenSubjectLevels?.length
          ? chosenSubjectLevels
          : allSkillLevels,
    [chosenSkillLevels, chosenSubjectLevels],
  );

  const areThereChanges = useMemo(() => {
    if (id) {
      const normalizedSkillLevelsData = normalizeLevels(skillSpecificLevelsData);
      const normalizedSkillSkillLevelsData = normalizeLevels(skill?.skillSpecificLevelsData);

      const skillSpecificLevelsDataEqual = normalizedSkillSkillLevelsData
        ? isEqual(normalizedSkillLevelsData, normalizedSkillSkillLevelsData)
        : !Object.values(normalizedSkillLevelsData || {})?.some((value) => value !== undefined);

      const chosenSkillLevelsEqual = isEqual(chosenSkillLevels, skill?.chosenSkillLevels);

      return !(skillSpecificLevelsDataEqual && chosenSkillLevelsEqual);
    } else {
      const normalizedSubjectLevelsData = normalizeLevels(subjectLevelsData);
      const normalizedSubjectSubjectLevelsData = normalizeLevels(subject?.subjectLevelsData);

      const subjectLevelsDataEqual = normalizedSubjectSubjectLevelsData
        ? isEqual(normalizedSubjectLevelsData, normalizedSubjectSubjectLevelsData)
        : !Object.values(normalizedSubjectLevelsData || {})?.some((value) => value !== undefined);

      const chosenSubjectLevelsEqual = isEqual(chosenSubjectLevels, subject?.chosenSubjectLevels || []);

      return !(subjectLevelsDataEqual && chosenSubjectLevelsEqual);
    }
  }, [chosenSkillLevels, chosenSubjectLevels, subjectLevelsData, skillSpecificLevelsData]);

  useEffect(() => {
    updateMainContainerState({
      headerLeftComponent: (
        <MainHeaderLeft
          pageTitle={id ? t('skillLevels') : t('subjectLevels')}
          setOpenAreYouSureDialog={areThereChanges ? setOpenAreYouSureDialog : undefined}
        />
      ),
    });
  }, [areThereChanges]);

  useEffect(() => {
    updateMainContainerState({
      fullpage: true,
      headerLeftComponent: <MainHeaderLeft pageTitle={id ? t('skillLevels') : t('subjectLevels')} />,
      headerRightComponent: <MainHeaderRight onClick={handleClickEdit} />,
    });
    return () => {
      updateMainContainerState({ fullpage: false, headerLeftComponent: undefined, headerRightComponent: undefined });
    };
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const { data } = await getSkillSubject({ _id: subjectId });
    let skillLevelsData = null;

    const filteredLevels = data.subjectLevelsData
      ? allSkillLevels.reduce((arr, sub) => {
          arr[sub] = data.subjectLevelsData?.[sub] ?? allSkillLevels[sub];
          return arr;
        }, {})
      : [];

    setSubjectLevelsData(filteredLevels);
    setSubject(data);
    const newSubjectLevels = data.chosenSubjectLevels.length ? data.chosenSubjectLevels : [];
    setChosenSubjectLevels(newSubjectLevels);
    setIsLoading(false);

    if (id) {
      const { data: skill } = await getSkill({ _id: id });
      if (!skill) return;
      skillLevelsData = skill.skillSpecificLevelsData;

      const filteredSpecificLevels = allSkillLevels.reduce((arr, sub) => {
        arr[sub] = skillLevelsData ? skillLevelsData[sub] : allSkillLevels[sub];
        return arr;
      }, {});
      setSkillSpecificLevelsData(filteredSpecificLevels);
      setSkill(skill);
      setChosenSkillLevels(skill.chosenSkillLevels);
    }
  };

  const handleClickEdit = () => {
    setEdit(true);
    updateMainContainerState({
      headerRightComponent: undefined,
      headerLeftComponent: <MainHeaderLeft pageTitle={id ? t('skillLevels') : t('subjectLevels')} />,
    });
  };

  const handleDiscard = () => {
    setEdit(false);
    setSubjectLevelsData(
      subject?.subjectLevelsData ??
        allSkillLevels.reduce((arr, sub) => {
          arr[sub] = allSkillLevels[sub];
          return arr;
        }, {}),
    );
    setChosenSubjectLevels(subject?.chosenSubjectLevels as SkillLevelOptions[]);
    setSkillSpecificLevelsData(skill?.skillSpecificLevelsData);
    setChosenSkillLevels(skill?.chosenSkillLevels as SkillLevelOptions[]);
    updateMainContainerState({ headerRightComponent: <MainHeaderRight onClick={handleClickEdit} /> });
  };

  const handleSave = async () => {
    await updateSkillSubject(subject._id, {
      subjectLevelsData: subjectLevelsData,
      chosenSubjectLevels: chosenSubjectLevels,
    });
    if (id)
      await updateSkill(id, { skillSpecificLevelsData: skillSpecificLevelsData, chosenSkillLevels: chosenSkillLevels });
    setEdit(false);
    updateMainContainerState({ headerRightComponent: <MainHeaderRight onClick={handleClickEdit} /> });
    await props.showSnackbar('success', t('levelsUpdated'));
    getData();
  };

  const handleChange = (level, data) => {
    if (!id) {
      if (subjectLevelsData) {
        if (subjectLevelsData[level]) {
          setSubjectLevelsData({ ...subjectLevelsData, [level]: { ...subjectLevelsData[level], ...data } });
        } else {
          setSubjectLevelsData({ ...subjectLevelsData, [level]: { ...data } });
        }
      } else {
        setSubjectLevelsData({ [level]: { ...data } });
      }
    } else {
      if (skillSpecificLevelsData) {
        if (skillSpecificLevelsData[level]) {
          setSkillSpecificLevelsData({
            ...skillSpecificLevelsData,
            [level]: { ...skillSpecificLevelsData[level], ...data },
          });
        } else {
          setSkillSpecificLevelsData({ ...skillSpecificLevelsData, [level]: { ...data } });
        }
      } else {
        setSkillSpecificLevelsData({ [level]: { ...data } });
      }
    }
  };

  const resetToDefault = async () => {
    setEdit(false);

    setSkillSpecificLevelsData(null);
    setChosenSkillLevels([]);
    setOpenResetDefaultDialog(false);

    await updateSkill(id, { skillSpecificLevelsData: null, chosenSkillLevels: [] });

    updateMainContainerState({ headerRightComponent: <MainHeaderRight onClick={handleClickEdit} /> });
    await props.showSnackbar('success', t('levelsResetToDefault'));
    getData();
  };

  return (
    <DefaultBackground enzyme-attr='unit-create-default-container'>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      {!isLoading ? (
        <div className={classes.contentContainer}>
          <div className={classes.mainContainer}>
            <div className={classes.subTitle}>
              <Typography variant='h3'>
                {edit
                  ? t('editLevels')
                  : id
                    ? skill?.name || `${t('loading')}...`
                    : subject?.name || `${t('loading')}...`}
              </Typography>
              <Typography variant='body1' className={classes.skillsSubtitleHelperText}>
                {edit ? t('editSkillsText') : id ? t('operationSkillsText') : t('productionSkillsText')}
              </Typography>
            </div>
            <div>
              {allSkillLevels.map(
                (level, i) =>
                  ((edit && level !== 'NOT_APPLICABLE') ||
                    (!edit && (checkboxLevels?.includes(level) || level === 'NOT_APPLICABLE'))) && (
                    <>
                      <div
                        key={i}
                        className={
                          edit && checkboxLevels?.includes(level)
                            ? classes.highlightedLevelContainer
                            : classes.levelContainer
                        }
                        style={{ height: level !== 'NOT_APPLICABLE' ? '120px' : '60px' }}
                      >
                        <div className={classes.titleContainer}>
                          {edit ? (
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                              <SkillLevelChip level={level} hideText />
                              <TextField
                                data-testid={`inp-name-${level.toLocaleLowerCase()}`}
                                style={{ paddingLeft: '8px', height: '40px' }}
                                classes={{ root: classes.formControl }}
                                inputProps={{ className: classes.inputTitle }}
                                defaultValue={getSkillLevelName(level, subjectLevelsData?.[level])}
                                value={
                                  skillSpecificLevelsData?.[level]?.name ?? subjectLevelsData?.[level]?.name ?? null
                                }
                                onChange={(e) => {
                                  if (e?.target?.value?.length > 20) {
                                    e.target.value = e.target.value.slice(0, 20);
                                  }
                                  handleChange(level, { name: e.target.value });
                                }}
                              />
                            </span>
                          ) : (
                            <SkillLevelChip level={level} subjectLevelsData={levelsDataOverride} />
                          )}
                        </div>
                        <div className={classes.descriptionContainer}>
                          {edit ? (
                            <TextField
                              defaultValue={getSkillLevelDescription(level, subjectLevelsData?.[level])}
                              value={
                                skillSpecificLevelsData?.[level]?.description ??
                                subjectLevelsData?.[level]?.description ??
                                null
                              }
                              onChange={(e): void => {
                                if (e?.target?.value?.length > 200) {
                                  e.target.value = e.target.value.slice(0, 200);
                                }
                                handleChange(level, { description: e.target.value });
                              }}
                              style={{ height: '80px', paddingLeft: '68px' }}
                              classes={{ root: classes.formControl }}
                              inputProps={{ className: classes.inputDescription }}
                              multiline
                              minRows={2}
                              maxRows={2}
                              variant='outlined'
                              data-testid={`inp-description-${level.toLocaleLowerCase()}`}
                            />
                          ) : (
                            <span className={classes.descriptionText}>
                              {getSkillLevelDescription(
                                level,
                                skillSpecificLevelsData?.[level] ?? subjectLevelsData?.[level],
                              )}
                            </span>
                          )}
                        </div>
                        {edit ? (
                          <div className={classes.inUseCheckboxDiv}>
                            {checkboxLevels?.includes(level) ? (
                              <CheckBox
                                id={`checkedBoxIcon-${level}`}
                                data-testid={`checkedBoxIcon-${level}`}
                                className={
                                  checkboxLevels?.length > 2 ? classes.checkboxIcon : classes.checkboxIconDisabled
                                }
                                onClick={() => {
                                  const allSkillLevelsNoNA = allSkillLevels.filter((l) => l !== 'NOT_APPLICABLE');
                                  if (id) {
                                    if (!chosenSkillLevels?.length) {
                                      setChosenSkillLevels(
                                        chosenSubjectLevels?.length
                                          ? chosenSubjectLevels.filter((l) => l !== level)
                                          : allSkillLevelsNoNA.filter((l) => l !== level),
                                      );
                                    } else if (chosenSkillLevels?.length > 2) {
                                      isEqual(
                                        chosenSkillLevels.filter((l) => l !== level),
                                        chosenSubjectLevels?.length ? chosenSubjectLevels : allSkillLevelsNoNA,
                                      )
                                        ? setChosenSkillLevels([])
                                        : setChosenSkillLevels(chosenSkillLevels.filter((l) => l !== level));
                                    }
                                  } else {
                                    const allSkillLevelsNoNA = allSkillLevels.filter((l) => l !== 'NOT_APPLICABLE');
                                    if (!chosenSubjectLevels?.length) {
                                      setChosenSubjectLevels(allSkillLevelsNoNA.filter((l) => l !== level));
                                    } else if (chosenSubjectLevels?.length > 2) {
                                      isEqual(
                                        chosenSubjectLevels.filter((l) => l !== level),
                                        allSkillLevelsNoNA,
                                      )
                                        ? setChosenSubjectLevels([])
                                        : setChosenSubjectLevels(chosenSubjectLevels.filter((l) => l !== level));
                                    }
                                  }
                                }}
                              />
                            ) : (
                              <CheckBoxOutlineBlankOutlined
                                id={`uncheckedBoxIcon-${level}`}
                                data-testid={`uncheckedBoxIcon-${level}`}
                                className={classes.checkboxIconBlank}
                                onClick={() => {
                                  const allSkillLevelsNoNA = allSkillLevels.filter((l) => l !== 'NOT_APPLICABLE');
                                  if (id) {
                                    if (!chosenSkillLevels?.length) {
                                      setChosenSkillLevels(
                                        uniq([
                                          ...(chosenSubjectLevels ? chosenSubjectLevels : allSkillLevelsNoNA),
                                          level,
                                        ]),
                                      );
                                    } else {
                                      isEqual(
                                        uniq([...chosenSkillLevels, level]).sort(),
                                        chosenSubjectLevels?.length
                                          ? chosenSubjectLevels.sort()
                                          : allSkillLevelsNoNA.sort(),
                                      )
                                        ? setChosenSkillLevels([])
                                        : setChosenSkillLevels(uniq([...chosenSkillLevels, level]));
                                    }
                                  } else {
                                    if (!chosenSubjectLevels?.length) {
                                      setChosenSubjectLevels(uniq([...allSkillLevelsNoNA, level]));
                                    } else {
                                      isEqual(uniq([...chosenSubjectLevels, level]).sort(), allSkillLevelsNoNA.sort())
                                        ? setChosenSubjectLevels([])
                                        : setChosenSubjectLevels(uniq([...chosenSubjectLevels, level]));
                                    }
                                  }
                                }}
                              />
                            )}
                            <span style={{ paddingLeft: '4px' }}>{t('inUse')}</span>
                          </div>
                        ) : null}
                      </div>
                      {!edit && level === 'NOT_APPLICABLE' ? <hr className={classes.hr} /> : null}
                    </>
                  ),
              )}
            </div>
          </div>
          {edit ? (
            <div className={classes.btnContainer}>
              <DefaultButton variant='discard' onClick={handleDiscard} data-testid='btn-discard'>
                {t('discard')}
              </DefaultButton>
              {id ? (
                <DefaultButton
                  variant='discard'
                  onClick={() => setOpenResetDefaultDialog(true)}
                  data-testid='btn-reset-to-default'
                >
                  {t('resetToDefault')}
                </DefaultButton>
              ) : null}
              <DefaultButton onClick={handleSave} data-testid='btn-save' disabled={!areThereChanges}>
                {t('save')}
              </DefaultButton>
            </div>
          ) : null}
        </div>
      ) : null}
      {openResetDefaultDialog ? (
        <DialogResetChanges
          onClose={() => setOpenResetDefaultDialog(false)}
          onReset={resetToDefault}
          subTitle={t('resetChangesSubTitleSkills')}
        />
      ) : null}
      {openAreYouSureDialog ? (
        <ConfirmNavigation handleCancel={() => setOpenAreYouSureDialog(false)} handleSubmit={() => navigate(-1)} />
      ) : null}
    </DefaultBackground>
  );
};

const mapStateToProps = (/* state */): object => ({});

const mapDispatchToProps = {
  getSkillSubject,
  updateSkillSubject,
  getSkill,
  updateSkill,
  showSnackbar,
};

const connector = typedConnect(mapStateToProps, mapDispatchToProps);

export default compose(connector)(SkillLevels);
