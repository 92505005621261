import { MessagePayload } from 'src/interfaces/remote-assistance/chat';
import {
  WBS_EVENT_APPROVAL_ACCEPTED,
  WBS_EVENT_APPROVAL_REJECTED,
  WBS_EVENT_HELLO,
  WBS_EVENT_RECORD_STARTED,
  WBS_EVENT_RECORD_STATUS,
  WBS_EVENT_RECORD_STOPPED,
  WBS_EVENT_ROOM_CHAT,
  WBS_EVENT_ROOM_JOIN,
  WBS_EVENT_ROOM_LEAVE,
  WBS_EVENT_SESSION_DETAILS_UPDATE,
  WBS_EVENT_SESSION_DETAILS_UPDATED,
  //WBS_EVENT_USER_CONNECTED,
  WBS_EVENT_USER_DISCONNECTED,
  //WBS_EVENT_WAITING_FOR_APPROVAL,
  WBS_EVENT_LIVE_MODE,
  WBS_EVENT_BUBBLE,
  WBS_EVENT_DACK,
  WBS_EVENT_ROOM_USERS,
  WBS_EVENT_SESSION_CURRENT,
  /*     WBS_EVENT_CALL_REQUEST,
    WBS_EVENT_CALL_RESPONSE,
    WBS_EVENT_CALL_CANCEL,
    WBS_EVENT_CALL_STATUS,
    WBS_EVENT_CALL_CANCELED,
    WBS_EVENT_CALL_ACCEPTED,
    WBS_EVENT_CALL_REJECTED,
    WBS_EVENT_CALL_ERROR,
    WBS_EVENT_USER_CONTACT_ACTIVE,
    WBS_EVENT_USER_CONTACT_INACTIVE, */
  WBS_EVENT_SESSION_CONTACT_LIST_UPDATE,
  WBS_EVENT_USER_CONTACT_LIST_STATUS,
  // WBS_EVENT_CALL_RESPONDED,
  WBS_EVENT_ROOM_INTENT_SCREEN_SHARE,
  // WBS_EVENT_ROOM_KICK,
  WBS_EVENT_ROOM_SESSION_KICK,
  //WBS_EVENT_SESSION_KICKED,
  WBS_EVENT_ROOM_PERMISSIONS_UPDATED,
  // WBS_EVENT_CALL_MISS,
  WBS_EVENT_IMAGE_FREEZE,
  WBS_EVENT_DRAW,
  WBS_EVENT_ROOM_DIRECT_MESSAGE,
  WBS_EVENT_ROOM_INTENT_VIDEO_SHARE,
  WBS_EVENT_DELETE,
  WBS_EVENT_UNDO,
  WBS_EVENT_ROOM_DATA_CHECK,
  WBS_EVENT_ROOM_DATA_CLEAR,
  WBS_EVENT_ROOM_DATA_RECHECK,
  WBS_EVENT_USER_CONNECTED,
  WBS_EVENT_SESSION_KICKED,
  WBS_EVENT_WAITING_FOR_APPROVAL,
  WBS_EVENT_ROOM_KICK,
} from './constants';
import { defaultUserDetails, Session, SessionDetails } from 'src/interfaces/remote-assistance/session';
import {
  setImageFreeze,
  addWebMobileAnnotation,
  setEnableRecord,
  setDeletedFreezeAnnotations,
  setFreezeEvents,
  undoMobileAnnotation,
  setOutdatedAppEvent,
  updateTextBubble,
  //setOutdatedAppEvent,
} from 'src/modules/remote-assistance/agora/agora.redux';
import {
  setName,
  setUserList,
  deleteUser,
  setSocketStatus,
  setSession,
} from 'src/modules/remote-assistance/socket/socket.redux';
import reduxStore from 'src/reducer-manager';
//import { setRequestedMode, setRenderModeModal, setSnackbar } from '../redux/uiReducer'; TODO NOTE: uncomment this later when necessary
import WebSocketClient, { WebSocketError, WebSocketEvent, WebSocketStatus } from './Websocket';
import { setAnchorKey } from 'src/modules/remote-assistance/events/events.redux';
import { setRoom, setRoomStatus } from 'src/modules/remote-assistance/rooms/rooms.redux';
import { Room, RoomStatus } from 'src/interfaces/remote-assistance/room';
import { SocketStatus } from 'src/interfaces/remote-assistance/socket';
// import { Call } from 'src/interfaces/call'; TODO NOTE: import calls later
//import { addCall, removeCall, setContactStatus } from '../redux/calReducer'; TODO NOTE: calls will be implemented later
import { Dispatch, AnyAction } from 'redux';
import { client } from './agorartc/agoraClient';
import { userApproved } from './agorartc/agoraMeeting';
import i18n from '../translations/i18n';
import { showSnackbar } from 'src/base/root/root.redux';
import { setRequestedMode } from 'src/modules/remote-assistance/room-ui/room-ui.redux';
import {
  AnnotationType,
  DispatchedAnnotation,
  FreezeEvents,
  ImageFreeze,
  RoomDataCheck,
} from 'src/modules/remote-assistance/agora/utils/agora.interfaces';
let wsClient: WebSocketClient = null;

/* 
    WebSocket Events:
    'room_chat'         -> new chat message
    'record_started'    -> a record has started
    'record_stopped'    -> record is finished
    'record_status'     -> replies to an event of the same name with current recording status (recording/not recording)
*/

//*****************************************************************************
// annotationAttemps is used to count the attemps of drawing in ar mode
let annotationAttemps = 0;
// maxAnnotationAttemps is used to limit the attemps of drawing in ar mode
const maxAnnotationAttemps = 3;
//*****************************************************************************
interface SocketConfig {
  currentRoom: null | Room;
  isJoining: boolean;
  waitingForCallResponse: boolean;
}
const socketConfig: SocketConfig = {
  currentRoom: null,
  isJoining: false,
  waitingForCallResponse: false,
};
let newUserConnected = false;

export const startWebsocket = async (
  token: string,
  stream: any,
  setStream: any,
  setWsClient: React.Dispatch<React.SetStateAction<WebSocketClient>>,
  redirectedFromMobile?: boolean,
): Promise<void> => {
  const dispatch = reduxStore.dispatch;

  wsClient = new WebSocketClient();

  wsClient.setOnOpen(() => {
    dispatch(setSocketStatus(SocketStatus.CONNECTED));
  });
  wsClient.setOnClose(() => {
    dispatch(setSocketStatus(SocketStatus.DISCONNECTING));
  });
  wsClient.setOnConnecting(() => {
    dispatch(setSocketStatus(SocketStatus.CONNECTING));
  });
  wsClient.setOnError(() => {
    dispatch(setSocketStatus(SocketStatus.ERROR));
  });
  wsClient.setOnDisconnect(() => {
    // Clear room from websocket config
    socketConfig.currentRoom = null;

    dispatch(setSocketStatus(SocketStatus.DISCONNECTED));
  });
  wsClient.setReconnectCallback(() => {
    const state = reduxStore.getState();

    // Check if user is in a room call
    if (!state.agoraReducer?.agoraRtc) return;

    const currentRoom = state.roomReducer?.room;
    if (!currentRoom) {
      reduxStore.dispatch(
        showSnackbar('error', `${i18n.t('unableToReconnectToSession')}. ${i18n.t('pleaseTryAgain')}.`),
      );
      return;
    }

    void joinRoomEvent(currentRoom.id);
  });

  await Promise.all([
    wsClient.initialize(
      window.location.hostname == 'localhost'
        ? 'ws://localhost:8866/api/websocket'
        : `wss://${window.location.host}/api/websocket`,
      token,
    ),
  ]);
  setWsClient(wsClient);

  wsClient.setEventCallback(async (e: WebSocketEvent) => {
    const state = reduxStore.getState();

    switch (e.event) {
      case WBS_EVENT_HELLO: {
        const userSession = (e.data as MessagePayload).session;
        dispatch(setSession(userSession));
        return;
      }
      case WBS_EVENT_ROOM_JOIN: {
        // Check if other session forced to join other room
        const currentRoom = state.roomReducer?.room?.id;

        // Check if context is waiting for event
        if (!currentRoom) return;

        if ((e.data as MessagePayload).room_id != currentRoom || socketConfig.currentRoom) {
          reduxStore.dispatch(showSnackbar('error', i18n.t('joinedAnotherRoomOnAnotherDeviceOrBrowser') + '.'));
          return;
        }

        validUser(stream, setStream, redirectedFromMobile, (e.data as MessagePayload).room_id);
        newUserConnected = true;
        void wsClient.sendMessage(WBS_EVENT_ROOM_USERS, {});
        return;
      }

      // ************************************************************************************
      // ******* TODO NOTE: this will uncommented later when we implement calls ************
      // ************************************************************************************

      /*
      case WBS_EVENT_CALL_REQUEST:
        void dispatch(addCall((e.data as MessagePayload).call));
        return;
      case WBS_EVENT_CALL_CANCELED:
        if (state.call.currentCall?.id == (e.data as MessagePayload).call.id) {
          dispatch(
            setSnackbar({
              open: true,
              type: 'warning',
              message:
                i18n.t('userCanceledTheCall', {
                  user: state.call.currentCall?.calle_name ?? i18n.t('user'),
                }) + '.',
            }),
          );
        }
        void dispatch(removeCall((e.data as MessagePayload).call.id));
        return;
      case WBS_EVENT_CALL_ACCEPTED:
        void dispatch(addCall({ ...(e.data as MessagePayload).call, room: (e.data as MessagePayload).room }));
        return;
      case WBS_EVENT_CALL_REJECTED:
        if (state.call.currentCall?.id == (e.data as MessagePayload).call.id) {
          dispatch(
            setSnackbar({
              open: true,
              type: 'warning',
              message:
                i18n.t('userIsUnavailableToStartASession', {
                  user: state.call.currentCall?.calle_name ?? i18n.t('user'),
                }) + '.',
            }),
          );
        }
        void dispatch(removeCall((e.data as MessagePayload).call.id));
        return;
      case WBS_EVENT_CALL_RESPONDED:
        if (socketConfig.waitingForCallResponse) return;
  
        if (state.call.currentCall?.id == (e.data as MessagePayload).call.id) {
          dispatch(
            setSnackbar({
              open: true,
              type: 'info',
              message: i18n.t('callAcceptedInOtherBrowserOrDevice') + '.',
            }),
          );
        }
  
        void dispatch(removeCall((e.data as MessagePayload).call.id));
        return;
      case WBS_EVENT_CALL_ERROR:
        if (state.call.currentCall?.id == (e.data as MessagePayload).call.id) {
          dispatch(
            setSnackbar({
              open: true,
              type: 'error',
              message: `${i18n.t('unableToCreateASession')}. ${i18n.t('tryCallingTheUserAgain')}.`,
            }),
          );
        }
        void dispatch(removeCall((e.data as MessagePayload).call.id));
        return;
      case WBS_EVENT_USER_CONTACT_ACTIVE:
        void dispatch(setContactStatus(e.broadcast.contact_user_id, true));
        return;
      case WBS_EVENT_USER_CONTACT_INACTIVE:
        void dispatch(setContactStatus(e.broadcast.contact_user_id, false));
        return;
      */

      // ************************************************************************************************
      // ******* TODO NOTE: the code below is related to accepting/refusing guests in a room ************
      // ************************************************************************************************
      case WBS_EVENT_WAITING_FOR_APPROVAL: {
        if (!state.socketReducer.session?.is_guest) return;

        void dispatch(setRoomStatus(RoomStatus.WAITING_FOR_APPROVAL));
        return;
      }
      case WBS_EVENT_APPROVAL_ACCEPTED: {
        if (!state.socketReducer.session?.is_guest) return;

        // validUser(redirectedFromMobile);
        return;
      }
      case WBS_EVENT_APPROVAL_REJECTED: {
        if (!state.socketReducer.session?.is_guest) return;

        // TODO Merge: this is related to the guest
        // sessionInvalid(i18n.t('youWereNotAllowedtoJoinTheSession') + '.');
        return;
      }

      case WBS_EVENT_ROOM_KICK: {
        // TODO Merge: this is related to the guest
        // sessionInvalid(i18n.t('userWasRemovedFromTheSession', { user: i18n.t('guest') }) + '.');
        return;
      }
    }

    // Ignore room events if user isn't in a room
    if (!socketConfig.currentRoom && !socketConfig.isJoining) return;
    const agoraUid = state.agoraReducer.meUid;
    switch (e.event) {
      case WBS_EVENT_ROOM_DATA_RECHECK: {
        const roomData = (e.data as MessagePayload)?.payload;
        void dispatch(setDeletedFreezeAnnotations(true));
        void dispatch(setImageFreeze(roomData['imageFreeze'] as ImageFreeze));

        // Note: Race condition workaround
        await new Promise((r) => setTimeout(r, 1000));

        void dispatch(setFreezeEvents(roomData['events'] as FreezeEvents));
        return;
      }
      case WBS_EVENT_ROOM_DATA_CLEAR: {
        dispatch(setDeletedFreezeAnnotations(true));
        return;
      }

      case WBS_EVENT_DRAW: {
        handleRemoteDraw(e.data as MessagePayload, dispatch, true);
        return;
      }

      case WBS_EVENT_DELETE: {
        dispatch(setDeletedFreezeAnnotations(true));
        return;
      }

      case WBS_EVENT_UNDO: {
        const undoSenderId = (e.data as MessagePayload)?.sender_id;
        if (!undoSenderId) return;

        dispatch(undoMobileAnnotation(undoSenderId));
        return;
      }

      case WBS_EVENT_BUBBLE: {
        const payload = (e.data as MessagePayload)?.payload
          ? (e.data as MessagePayload)?.payload
          : (e.data as MessagePayload)?.key;

        // Check if bubble is a freeze bubble
        if (payload && typeof payload === 'string' && payload?.split(';')?.length == 4) {
          handleRemoteDraw(e.data as MessagePayload, dispatch, false);
          return;
        }

        if (typeof payload === 'string' && (payload.match(/;/g) || []).length === 2) {
          handleRemoteDraw(e.data as MessagePayload, dispatch, false);
        } else {
          const key: string = (e.data as MessagePayload)?.payload
            ? ((e.data as MessagePayload)?.payload['key'] as string)
            : (e.data as MessagePayload)?.key;

          const message: string = (e.data as MessagePayload)?.payload['message'] as string;

          if (key && message) {
            dispatch(
              updateTextBubble({ sessionId: (e.data as MessagePayload)?.sender_id, key: key, message: message }),
            );
          } else {
            dispatch(setAnchorKey(key));
          }
        }
        return;
      }
      case WBS_EVENT_USER_CONNECTED: {
        // Check if user list isn't on the payload
        if (!(e.data as MessagePayload)?.users) {
          newUserConnected = true;
          void wsClient.sendMessage(WBS_EVENT_ROOM_USERS, {});
          return;
        }
        /* falls through */
      }
      case WBS_EVENT_ROOM_USERS: {
        const { imageFreeze } = reduxStore.getState().agoraReducer;
        const wasSessionFrozen = (e.data as MessagePayload).users.some(
          (e) => e.user_details.current_state?.freeze == 1,
        );
        const isSessionFrozen = (e.data as MessagePayload).users.some((e) => e.user_details.current_state?.freeze == 1);

        // Refetch the freeze image if it was lost after the streamer lost the connection
        // and reconnects without doing a new join room event
        if (wasSessionFrozen == isSessionFrozen && !imageFreeze) {
          checkRoomData()
            .then(async (roomData) => {
              const dispatch = reduxStore.dispatch;
              dispatch(setImageFreeze(roomData.imageFreeze));

              // Note: Race condition workaround
              await new Promise((r) => setTimeout(r, 1000));

              dispatch(setFreezeEvents(roomData.events));
            })
            .catch((err) => void err);
        }

        if (newUserConnected) {
          newUserConnected = false;
          const outdatedApp = (e.data as MessagePayload).users.some(
            (session) =>
              session.user_details?.capabilities?.device_type === '1' &&
              session.user_details?.capabilities?.is_freezehq_supported !== '1',
          );
          if (outdatedApp) void reduxStore.dispatch(setOutdatedAppEvent());
        }
        /* falls through */
      }
      case WBS_EVENT_SESSION_DETAILS_UPDATED: {
        reduxStore.dispatch(setUserList({ socketUsers: (e.data as MessagePayload).users, meUid: agoraUid }));
        return;
      }

      case WBS_EVENT_SESSION_KICKED: {
        /*
        const sessionKicked = reduxStore
          .getState()
          .socketReducer.socketUsers.find((el) => el.session_id === (e.data as MessagePayload).session_id);
  
              dispatch(
                  setSnackbar({
                      open: true,
                      type: 'info',
                      message: i18n.t('userWasRemovedFromTheSession', {
                          user: sessionKicked?.user_details?.name ?? i18n.t('guest'),
                      }),
                  }),
              );*/
        return;
      }
      case WBS_EVENT_USER_DISCONNECTED: {
        dispatch(deleteUser((e.data as MessagePayload).session_id));
        return;
      }
      case WBS_EVENT_ROOM_CHAT: {
        // reduxStore.dispatch(sendMessage(e.data as MessagePayload));
        return;
      }
      case WBS_EVENT_ROOM_PERMISSIONS_UPDATED: {
        if (e.data) dispatch(setRoom(e.data as Room));
        return;
      }
      case WBS_EVENT_APPROVAL_ACCEPTED: {
        // validUser(redirectedFromMobile, socketConfig.currentRoom.id);
        return;
      }
      case WBS_EVENT_APPROVAL_REJECTED: {
        // sessionInvalid(i18n.t('youWereNotAllowedtoJoinTheSession') + '.');
        return;
      }
      case WBS_EVENT_RECORD_STARTED: {
        dispatch(
          setEnableRecord({
            recordStatus: true,
            recordEnd: (e.data as MessagePayload).expiredTimeInSeconds,
            recordStartedBy: (e.data as MessagePayload).started_by,
          }),
        );
        return;
      }
      case WBS_EVENT_RECORD_STATUS: {
        dispatch(
          (e.data as MessagePayload)?.status === 'Recording'
            ? setEnableRecord({
                recordStatus: true,
                recordEnd: (e.data as MessagePayload).expiredTimeInSeconds,
                recordStartedBy: (e.data as MessagePayload).started_by,
              })
            : setEnableRecord({ recordStatus: false, recordEnd: 0 }),
        );
        return;
      }
      case WBS_EVENT_RECORD_STOPPED: {
        dispatch(setEnableRecord({ recordStatus: false, recordEnd: 0 }));
        return;
      }
      case WBS_EVENT_LIVE_MODE: {
        const assistedUser = reduxStore.getState().socketReducer.assistedUser;
        if (!assistedUser) return;

        const requestedMode = reduxStore.getState().roomUiReducer.requestedMode;
        const type = (e.data as MessagePayload).payload['type'] as string;
        const newState = ((e.data as MessagePayload).payload['target'] as string) == 'ar' ? 0 : 1;

        if (type === 'set') {
          if (requestedMode === null) return;

          let message = i18n.t('assistedUserSwitchedAnnotations', { mode: Boolean(newState) ? '2D' : 'AR' }) + '.';
          if (newState !== requestedMode) message = i18n.t('assistedUserRejectedToSwitchDrawingModes') + '.';

          reduxStore.dispatch(showSnackbar('info', message));
          reduxStore.dispatch(setRequestedMode(null));
        } else {
          if (requestedMode === null) {
            reduxStore.dispatch(setRequestedMode(newState));
            reduxStore.dispatch(showSnackbar('info', i18n.t('userAskedForPermissionToChangeTheDrawingMode') + '.'));
          }
        }
        return;
      }
      case WBS_EVENT_DACK: {
        if ((e.data as MessagePayload).from == state.socketReducer.assistedUser.session_id) {
          if ((e.data as MessagePayload).payload === '1') {
            if (annotationAttemps >= maxAnnotationAttemps) {
              //      dispatch(setRenderModeModal(true));
              resetAnnotaionAttemps();
              return;
            }
            /*
            dispatch(
              setSnackbar({
                open: true,
                type: 'warning',
                message: `${i18n.t('unableToAnnotate')}. ${i18n.t('pleaseTryAgain')}.`,
              }),
            );*/
            annotationAttemps++;
            return;
          }
          if (annotationAttemps != 0) resetAnnotaionAttemps();
        }
        return;
      }
      case WBS_EVENT_IMAGE_FREEZE: {
        const messagePayload = e.data as MessagePayload;
        const freezePayload = messagePayload?.payload;
        const from = messagePayload?.from;

        if (!freezePayload || !from) return;

        const [width, height, imageURL] = freezePayload.split('|');

        const imageFreezeData: ImageFreeze = {
          width: parseInt(width),
          height: parseInt(height),
          imageURL: imageURL,
          sessionId: from,
        };

        dispatch(setImageFreeze(imageFreezeData));
        return;
      }
      default: {
        return;
      }
    }
  }, 'SOCKETS');

  // TODO: preciso de analisar isto melhor
  const name = localStorage.getItem('name');
  if (name) dispatch(setName(name));
};

export const closeWebSocket = (roomId?: string): void => {
  if (wsClient) {
    // Send room_leave message
    if (roomId) void wsClient.sendMessage(WBS_EVENT_ROOM_LEAVE, { room_id: roomId });

    wsClient.close();

    wsClient = null;
  }
};

const validUser = async (stream: any, setStream: any, redirectedFromMobile: boolean, channel?: string) => {
  const store = reduxStore.getState();

  socketConfig.currentRoom = store.roomReducer.room;
  socketConfig.isJoining = false;

  void reduxStore.dispatch(setRoomStatus(RoomStatus.CONNECTED));

  if (!store.agoraReducer.agoraRtc || client?.channelName != channel) {
    const response = await userApproved(stream, setStream);
    if (!response?.status) {
      reduxStore.dispatch(
        showSnackbar(
          'error',
          `${i18n.t('thereWasanErrorPleaseTryAgain')}. ${i18n.t('ifTheProblemPersistsPleaseContactSupport')}.`,
        ),
      );
      return;
    }
  }

  const meUser = store.socketReducer.meUser;

  // empty AnnotationsAttemps
  resetAnnotaionAttemps();

  // TODO: This must be removed when legacy apps are no longer being supported
  // To avoid send unnecessary events
  void sessionDetailsUpdate(
    meUser
      ? meUser.user_details
      : defaultUserDetails(
          store.roomReducer.roomUser?.agoraUid,
          store.roomReducer.roomUser?.name,
          store.agoraReducer?.audioState,
        ),
  );
};

export const sessionDetailsUpdate = async (userDetails: SessionDetails): Promise<void> => {
  // Check if wsClient is initialized and not null
  if (!wsClient) return;

  await wsClient.sendMessage(WBS_EVENT_SESSION_DETAILS_UPDATE, userDetails, (resp: WebSocketError) => {
    if (resp.status == WebSocketStatus.FAIL) {
      const roomID = reduxStore.getState().socketReducer.session?.room_id;
      // If error was room not found retry to reconnect to room
      if (resp.error.message.includes('room_not_found') && roomID) {
        void joinRoomEvent(roomID);
        return;
      }
    }
  });
};

export const resetAnnotaionAttemps = (): void => {
  annotationAttemps = 0;
};

export const joinRoomEvent = async (room_id: string): Promise<void> => {
  const store = reduxStore.getState();
  const dispatch = reduxStore.dispatch;

  void dispatch(setRoomStatus(RoomStatus.LOADING));
  socketConfig.isJoining = true;
  await wsClient.sendMessage(
    WBS_EVENT_ROOM_JOIN,
    {
      room_id,
    },
    (resp: WebSocketError) => {
      if (resp.status == WebSocketStatus.FAIL) {
        void dispatch(setRoomStatus(RoomStatus.ERROR));

        socketConfig.isJoining = false;

        console.error(
          `${resp.error.status_code}: User (${store.socketReducer.session?.id}) was unable to join room (${room_id}): ${resp.error.message}`,
        );
      } else {
        checkRoomData()
          .then(async (roomData) => {
            void dispatch(setDeletedFreezeAnnotations(true));
            void dispatch(setImageFreeze(roomData.imageFreeze));

            // Note: Race condition workaround
            await new Promise((r) => setTimeout(r, 1000));

            void dispatch(setFreezeEvents(roomData.events));
          })
          .catch((err) => void err);
      }
    },
  );
};

export const leaveRoomEvent = async (roomId: string): Promise<void> => {
  socketConfig.currentRoom = null;

  void reduxStore.dispatch(setRoomStatus(RoomStatus.LEAVING));
  // Send room_leave message
  if (wsClient) await wsClient.sendMessage(WBS_EVENT_ROOM_LEAVE, { room_id: roomId });
};

export const getCurrentSessionEvent = async (): Promise<Session> => {
  return new Promise((resolve, reject) => {
    wsClient
      ?.sendMessage(WBS_EVENT_SESSION_CURRENT, {}, (resp: WebSocketEvent | WebSocketError) => {
        if (resp.status == 'FAIL') reject((resp as WebSocketError)?.error);

        const response = resp as WebSocketEvent;
        resolve(response.data as Session);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// **************************************************************************************************
// ************************ TODO NOTE: uncomment later to implement calls ***************************
// **************************************************************************************************

/*
export const callUser = async (user_id: string): Promise<Call> => {
    return new Promise((resolve, reject) => {
        wsClient
            .sendMessage(WBS_EVENT_CALL_REQUEST, { user_id }, (resp: WebSocketEvent | WebSocketError) => {
                if (resp.status == 'FAIL') reject(resp as WebSocketError);

                const response = resp as WebSocketEvent;
                resolve(response.data as Call);
            })
            .catch((err) => reject(err));
    });
};

export const callResponse = async (id: string, status: boolean): Promise<Room> => {
    socketConfig.waitingForCallResponse = true;
    return new Promise((resolve, reject) => {
        wsClient
            .sendMessage(WBS_EVENT_CALL_RESPONSE, { id, status }, (resp: WebSocketEvent | WebSocketError) => {
                socketConfig.waitingForCallResponse = false;

                if (resp.status == 'FAIL') reject((resp as WebSocketError)?.error);

                const response = resp as WebSocketEvent;
                resolve(response.data as Room);
            })
            .catch((err) => {
                socketConfig.waitingForCallResponse = false;
                reject(err);
            });
    });
};

export const callCancel = async (id: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        wsClient
            .sendMessage(WBS_EVENT_CALL_CANCEL, { id }, (resp: WebSocketEvent | WebSocketError) => {
                if (resp.status == 'FAIL') reject((resp as WebSocketError)?.error);

                resolve();
            })
            .catch((err) => reject(err));
    });
};

export const callMiss = async (id: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        wsClient
            .sendMessage(WBS_EVENT_CALL_MISS, { id }, (resp: WebSocketEvent | WebSocketError) => {
                if (resp.status == 'FAIL') reject((resp as WebSocketError)?.error);

                resolve();
            })
            .catch((err) => reject(err));
    });
};

export const callStatus = async (id: string): Promise<Call> => {
    return new Promise((resolve, reject) => {
        wsClient
            .sendMessage(WBS_EVENT_CALL_STATUS, { id }, (resp: WebSocketEvent | WebSocketError) => {
                if (resp.status == 'FAIL') reject((resp as WebSocketError)?.error);

                const response = resp as WebSocketEvent;
                resolve(response.data as Call);
            })
            .catch((err) => reject(err));
    });
};
*/

export const updateSessionContactList = async (contact_list: Array<string>): Promise<Session> => {
  return new Promise((resolve, reject) => {
    wsClient
      .sendMessage(WBS_EVENT_SESSION_CONTACT_LIST_UPDATE, { contact_list }, (resp: WebSocketEvent | WebSocketError) => {
        if (resp.status == 'FAIL') reject((resp as WebSocketError)?.error);

        const response = resp as WebSocketEvent;
        resolve(response.data as Session);
      })
      .catch((err) => reject(err));
  });
};

export const getUserContactListStatus = async (): Promise<Map<string, boolean>> => {
  return new Promise((resolve, reject) => {
    wsClient
      .sendMessage(WBS_EVENT_USER_CONTACT_LIST_STATUS, {}, (resp: WebSocketEvent | WebSocketError) => {
        if (resp.status == 'FAIL') reject((resp as WebSocketError)?.error);

        const response = resp as WebSocketEvent;
        resolve(response.data as Map<string, boolean>);
      })
      .catch((err) => reject(err));
  });
};

export const intentScreenShare = async (): Promise<void> => {
  return new Promise((resolve, reject) => {
    wsClient
      ?.sendMessage(WBS_EVENT_ROOM_INTENT_SCREEN_SHARE, {}, (resp: WebSocketEvent | WebSocketError) => {
        if (resp.status == 'FAIL') {
          reject((resp as WebSocketError)?.error);
        }
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const intentMobileVideoShare = async (): Promise<void> => {
  return new Promise((resolve, reject) => {
    wsClient
      .sendMessage(WBS_EVENT_ROOM_INTENT_VIDEO_SHARE, {}, (resp: WebSocketEvent | WebSocketError) => {
        if (resp.status == 'FAIL') reject((resp as WebSocketError)?.error);
        resolve();
      })
      .catch((err) => reject(err));
  });
};

export const roomSessionKickEvent = async (session_id: string): Promise<void> => {
  await wsClient.sendMessage(
    WBS_EVENT_ROOM_SESSION_KICK,
    {
      session_id,
    },
    (resp: WebSocketError) => {
      if (resp.status == WebSocketStatus.FAIL) {
        /*
                void reduxStore.dispatch(
                    setSnackbar({
                        open: true,
                        type: 'error',
                        message: i18n.t('unableToRemoveUserFromSession') + '.',
                    }),
                );*/
      }
    },
  );
};

export const sendBubbleAnchorKey = async (
  viewingSessionId: string,
  anchorKey: string,
): Promise<Map<string, boolean>> => {
  return new Promise((_resolve, reject) => {
    wsClient
      .sendMessage(WBS_EVENT_ROOM_DIRECT_MESSAGE, {
        action: 'bubble',
        session_id: viewingSessionId,
        payload: {
          key: anchorKey,
        },
      })
      .catch((err) => reject(err));
  });
};

export const checkRoomData = async (): Promise<RoomDataCheck> => {
  return new Promise((resolve, reject) => {
    wsClient
      .sendMessage(WBS_EVENT_ROOM_DATA_CHECK, {}, (resp: WebSocketEvent | WebSocketError) => {
        if (resp.status == 'FAIL') reject((resp as WebSocketError)?.error);

        const response = resp as WebSocketEvent;
        resolve(response.data as RoomDataCheck);
      })
      .catch((err) => reject(err));
  });
};

export const clearDataRoom = async (): Promise<void> => {
  return new Promise((resolve, reject) => {
    wsClient
      .sendMessage(WBS_EVENT_ROOM_DATA_CLEAR, {}, (resp: WebSocketEvent | WebSocketError) => {
        if (resp.status == 'FAIL') reject((resp as WebSocketError)?.error);
        resolve();
      })
      .catch((err) => reject(err));
  });
};

export const isWbsConnected = (): boolean => {
  if (!wsClient) return false;

  return wsClient.conn != null;
};

const handleRemoteDraw = (message: MessagePayload, dispatch: Dispatch<AnyAction>, isArrow: boolean) => {
  const sessionId = message.from;

  let x: number, y: number, type: number, text: string | undefined;
  if (message.payload.split(';').length == 4) {
    const [strX, strY, strType, strText] = message.payload.split(';');
    x = parseFloat(strX);
    y = parseFloat(strY);
    type = parseFloat(strType);
    text = strText;
  } else {
    [x, y, type] = message.payload.split(';').map(parseFloat);
  }

  const timestamp = Date.now();

  const { socketUsers } = reduxStore.getState().socketReducer;
  const session = socketUsers.find((user) => user.session_id == sessionId);
  const colorIndex =
    session?.user_details?.current_state?.color !== undefined && session?.user_details?.current_state?.color !== null
      ? session.user_details.current_state.color
      : 1;

  const annotation: DispatchedAnnotation =
    text == '' || !text
      ? {
          x,
          y,
          type:
            type === 0 ? AnnotationType.MOUSE_DOWN : type === 1 ? AnnotationType.MOUSE_UP : AnnotationType.MOUSE_MOVE,
          timestamp,
          isArrow,
          colorIndex,
        }
      : {
          x,
          y,
          type:
            type === 0 ? AnnotationType.MOUSE_DOWN : type === 1 ? AnnotationType.MOUSE_UP : AnnotationType.MOUSE_MOVE,
          timestamp,
          isArrow,
          text,
          colorIndex,
        };

  dispatch(addWebMobileAnnotation({ sessionId, annotation }));
};
