import moment from 'moment';

export const dateComparator =
  <T = object>(field: string, order: 'asc' | 'desc') =>
  (a: T, b: T) => {
    const dateA = moment(a[field]).valueOf();
    const dateB = moment(b[field]).valueOf();

    return order === 'asc' ? dateA - dateB : dateB - dateA;
  };
