import { MenuItem, Popover } from '@mui/material';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { styles } from 'src/utils/components/generic-input-card/styles';
import { Ref } from 'react';

interface Input {
  [x: string]: any;
  subType: string;
  type: string;
  _id: string;
}

interface DatetimePopoverProps {
  anchor: {
    current: Element;
  };
  onClose: () => void;
  classes: {
    popoverAdvanced: string;
    menuItemAdvanced: string;
    checkIcon: string;
    menuItemWithCheck: string;
    menuItemWithoutCheck: string;
    popover: string;
    popoverContent: string;
  };
  input: Input;
  setInput: (input: Input) => void;
  innerRef: Ref<HTMLDivElement>;
  open: boolean;
  id?: string;
  t?: any;
}

const DatetimePopover = (props: DatetimePopoverProps): JSX.Element => {
  const { classes, input, setInput, anchor, open } = props;

  return (
    <Popover
      open={open}
      anchorEl={anchor.current}
      ref={props.innerRef}
      onClose={(): void => {
        props.onClose();
      }}
      className={classes.popover}
      classes={{ root: classes.popoverAdvanced, paper: classes.popoverContent }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <MenuItem
        id={`MenuItemSubTypeDatetime`}
        data-testid={`MenuItemSubTypeDatetime`}
        itemID={'datetime'}
        classes={{ root: classes.menuItemAdvanced }}
        onClick={(): void => {
          setInput({
            _id: input._id,
            type: 'datetime',
            subType: 'datetime',
            [input.type]: undefined,
            datetime: { ...input.datetime, values: [], options: [], onResponse: [], multiple: false },
          });
          props.onClose();
        }}
      >
        <span className={input.subType === 'datetime' ? classes.menuItemWithCheck : classes.menuItemWithoutCheck}>
          {props.t('datetime')}
        </span>
      </MenuItem>
      <MenuItem
        id={`MenuItemSubTypeDate`}
        data-testid={`MenuItemSubTypeDate`}
        itemID={'date'}
        classes={{ root: classes.menuItemAdvanced }}
        onClick={(): void => {
          setInput({
            _id: input._id,
            type: 'datetime',
            subType: 'date',
            [input.type]: undefined,
            datetime: { ...input.datetime, values: [], options: [], multiple: false },
          });
          props.onClose();
        }}
      >
        <span className={input.subType === 'date' ? classes.menuItemWithCheck : classes.menuItemWithoutCheck}>
          {props.t('date')}
        </span>
      </MenuItem>
      <MenuItem
        id={`MenuItemSubTypeTime`}
        data-testid={`MenuItemSubTypeTime`}
        itemID={'time'}
        classes={{ root: classes.menuItemAdvanced }}
        onClick={(): void => {
          setInput({
            _id: input._id,
            type: 'datetime',
            subType: 'time',
            [input.type]: undefined,
            datetime: { ...input.datetime, values: [], options: [], multiple: false },
          });
          props.onClose();
        }}
      >
        <span className={input.subType === 'time' ? classes.menuItemWithCheck : classes.menuItemWithoutCheck}>
          {props.t('time')}
        </span>
      </MenuItem>
    </Popover>
  );
};

export default compose<any>(withTranslation('translation'), withStyles(styles))(DatetimePopover);
