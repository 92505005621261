import { MenuItem, Popover } from '@mui/material';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { styles } from 'src/utils/components/generic-input-card/styles';
import { Ref } from 'react';

interface Input {
  subType: string;
  type: string;
  multiple: {
    validations: {
      name: string;
    }[];
  };
  _id: string;
}

interface MultiplePopoverProps {
  anchor: {
    current: Element;
  };
  onClose: () => void;
  classes: {
    popoverAdvanced: string;
    menuItemAdvanced: string;
    checkIcon: string;
    menuItemWithCheck: string;
    menuItemWithoutCheck: string;
    chevronRight: string;
  };
  input: Input;
  setInput: (input: Partial<Input>) => void;
  innerRef: Ref<HTMLDivElement>;
  subRef: Ref<HTMLLIElement>;
  open: boolean;
  t?: any;
}

const MultiplePopover = (props: MultiplePopoverProps): JSX.Element => {
  const { classes, input, setInput, innerRef, subRef, anchor, open } = props;

  return (
    <Popover
      open={open}
      anchorEl={anchor.current}
      onClose={(): void => {
        props.onClose();
      }}
      ref={innerRef}
      classes={{ root: classes.popoverAdvanced }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <MenuItem
        id={`menuItemValidationLimitAnswers`}
        data-testid={`menuItemValidationLimitAnswers`}
        ref={subRef}
        classes={{ root: classes.menuItemAdvanced }}
        onClick={(): void => {
          setInput({
            _id: input._id,
            multiple: { ...input.multiple, validations: [{ name: 'limitAnswers' }] },
          });
          props.onClose();
        }}
      >
        <span className={classes.menuItemWithoutCheck}>{props.t('limitOfAnswers')}</span>
      </MenuItem>
    </Popover>
  );
};

export default compose<any>(withTranslation('translation'), withStyles(styles))(MultiplePopover);
