import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import DefaultButton from '../../default-button';
import DrawSignature from './draw';
import { styles } from './styles';
import { withStyles } from '@mui/styles';

interface SignatureDialogProps {
  onClose: () => void;
  classes: Record<string, string>;
  addFile: (val: string) => Promise<void>;
  file;
  setCurrentFiles: (val: any[]) => void;
  deleteFile: (file: { _id: string }) => void;
}

const SignatureDialog = (props: SignatureDialogProps) => {
  const { classes, file, deleteFile } = props;
  const [save, setSave] = useState<boolean>(false);
  const [imgToSave, setImageToSave] = useState<string>(null);
  const [reset, setReset] = useState<boolean>(false);

  const { t } = useTranslation();
  useEffect(() => {
    if (file === undefined) {
      setReset(false);
    }
  }, [file]);

  return (
    <Dialog open={true} onClose={props.onClose} maxWidth={false} classes={{ paper: classes.children }}>
      <DialogTitle style={{ display: 'flex', justifyContent: 'center' }}>
        <span className={classes.dialogTitleText}>{t('signWithMouse')}</span>
      </DialogTitle>

      <div style={{ height: '350px', width: '650px', border: '1px solid', marginRight: 25, marginLeft: 25 }}>
        <DrawSignature
          height={350}
          width={650}
          save={save}
          saveImage={(val: string) => {
            setImageToSave(val);
            props.addFile(val);
            props.onClose();
          }}
          reset={reset}
          setReset={setReset}
          file={file}
          setCurrentFiles={props.setCurrentFiles}
          deleteFile={deleteFile}
        />
      </div>

      <DialogActions style={{ justifyContent: 'center' }}>
        <DefaultButton id='buttonClear' data-testid='buttonClear' onClick={() => setReset(true)} discard>
          {t('clear')}
        </DefaultButton>
        <DefaultButton
          id='buttonUploadOrEditSignature'
          success
          onClick={() => (imgToSave !== null || file !== undefined ? setReset(true) : setSave(true))}
        >
          {imgToSave !== null || file !== undefined ? t('redoSignature') : t('uploadSignature')}
        </DefaultButton>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default compose<any>(
  withTranslation('translation'),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(SignatureDialog);
