import { useEffect, useRef } from 'react';
import FeedPost from '../post';
import { resetFeed, updateFeedPost, setLoadingPosts, updatePostsState, deleteCommentState } from '../../feed.redux';
import { connect, ConnectedProps } from 'react-redux';
import ReactLoading from 'react-loading';
import { Dialog, Typography, useTheme } from '@mui/material';
import { styles } from './styles';
import {
  PostOrderByInput,
  PostWhereInput,
  useGetPostsLazyQuery,
  useSubscribeDeletedCommentsSubscription,
  useSubscribeNewPostsSubscription,
  useSubscribePostsSubscription,
} from 'src/gql/graphql';
import { useTranslation } from 'react-i18next';
import EmptyPostCard from './empty-post-card';
import { useComponentWillUnmount } from 'src/utils/hooks/useComponentWillUnmount';
import DefaultButton from 'src/utils/components/default-button/default-button';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import { countFilters } from 'src/utils/components/glar-filter-popover';
import { buildOrFilter } from './utils/feed.view.buildOrFilter';
import useEffectEvent from 'src/utils/hooks/useEffectEvent';
import { HiddenPost } from '../post/hidden-post';

type FeedViewProps = ConnectedProps<typeof connector>;

const connector = connect(
  (state) => ({
    posts: state.feedReducer.posts,
    postsCount: state.feedReducer.postsCount,
    comments: state.feedReducer.comments,
    myLabelValues: state.loginReducer.loggedUser.labelValuesIds,
    filters: state.feedReducer.filters,
    loading: state.feedReducer.loadingPosts,
    softDeletedPosts: state.feedReducer.hiddenPosts,
  }),
  {
    updateFeedPost,
    updatePostsState,
    setLoadingPosts,
    resetFeed,
    deleteCommentState,
  },
);

const useStyles = makeStyles(styles);

const POST_PAGINATION = 5;

function FeedView(props: FeedViewProps) {
  const { posts, postsCount, comments, filters, loading } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();

  const containerRef = useRef<HTMLDivElement>(null);

  const [getFeedPosts] = useGetPostsLazyQuery({ fetchPolicy: 'network-only' });

  const fetchPosts = useEffectEvent(async (more: boolean = true) => {
    const whereFilter: PostWhereInput = { OR: [] };

    whereFilter.OR = buildOrFilter(filters);

    if (filters.id) {
      whereFilter._id_eq = filters.id;
    } else {
      whereFilter.labelValues_in = filters.labels?.length ? filters.labels.map((l) => l._id) : undefined;
      whereFilter.createdBy = filters.author?.length ? { _id_in: filters.author.map((a) => a._id) } : undefined;
      whereFilter.createdAt_gte = filters.createdAt?.from ? filters.createdAt.from : undefined;
      whereFilter.createdAt_lte = filters.createdAt?.to ? filters.createdAt.to : undefined;
    }

    const skip = more ? posts.map((p) => p._id) : [];

    const reset = !skip.length;

    props.setLoadingPosts(reset ? 'reset' : 'more');

    getFeedPosts({
      variables: {
        where: { ...whereFilter, _id_nin: skip },
        countWhere: whereFilter,
        limit: POST_PAGINATION,
        orderBy: PostOrderByInput.createdAt_DESC,
      },
      onCompleted(data) {
        props.updatePostsState({
          posts: data.posts,
          reset,
          postsCount: data.postsCount,
        });

        props.setLoadingPosts(null);
      },
    });
  });

  useSubscribeDeletedCommentsSubscription({
    variables: {
      where: comments.map((c) => c._id),
    },
    onData({ data }) {
      if (data.data.commentDeleted) {
        props.deleteCommentState(data.data.commentDeleted);
      }
    },
  });

  useSubscribePostsSubscription({
    variables: {
      where: posts.map((p) => p._id),
    },
    onData({ data }) {
      if (data.data?.postsUpdated) {
        props.updatePostsState({ posts: [data.data.postsUpdated] });
      }
    },
  });

  useSubscribeNewPostsSubscription({
    onData({ data }) {
      if (data.data?.postCreated) {
        props.updatePostsState({ posts: [data.data.postCreated] });
      }
    },
  });

  useComponentWillUnmount(() => {
    props.resetFeed();
  });

  useEffect(() => {
    fetchPosts(false);
  }, [filters]);

  const onScroll = useEffectEvent(() => {
    if (loading) return;

    const el = containerRef?.current;
    const hasMore = postsCount > posts.length;

    if (hasMore) {
      const bottom = el.scrollHeight - Math.ceil(el.scrollTop) <= el.clientHeight;

      if (bottom) {
        fetchPosts();
      }
    }
  });

  useEffect(() => {
    onScroll();

    containerRef?.current?.addEventListener('scroll', onScroll);

    return () => {
      containerRef.current?.removeEventListener('scroll', onScroll);
    };
  }, [posts]);

  return (
    <div className={classes.body} ref={containerRef}>
      {loading !== 'reset' && posts.length
        ? posts.map((post) =>
            props.softDeletedPosts.includes(post._id) ? (
              <HiddenPost key={post._id} post={post} />
            ) : (
              <FeedPost key={post._id} post={post} />
            ),
          )
        : !loading && (
            <div className={classes.emptyContainer}>
              {!countFilters(filters) ? (
                <>
                  <EmptyPostCard />
                  <div style={{ fontSize: 16, fontWeight: 500, textAlign: 'center' }}>
                    <span
                      data-testid={'create-post-middle'}
                      onClick={() => navigate('/feed/create-post')}
                      className={classes.underline}
                    >
                      {t('createAPost')}
                    </span>{' '}
                    <span>{t('communicateYourTeam')}</span>
                  </div>
                </>
              ) : filters.id ? (
                <Dialog classes={{ paper: classes.unauthorized }} open={true} onClose={() => navigate('/feed')}>
                  <Typography variant={'h4'}>{t('postDeleted')}</Typography>
                  <Typography variant={'body1'} color={'textSecondary'}>
                    {t('postDeletedByCreatorOrAdmin')}
                  </Typography>
                  <DefaultButton onClick={() => navigate('/feed')} data-testid={'ok-mega-chefe'} variant={'default'}>
                    {t('ok')}
                  </DefaultButton>
                </Dialog>
              ) : (
                <span className={classes.noResults}>{t('noResultsFound')}</span>
              )}
            </div>
          )}
      {loading && (
        <div className={classes.centerLoading}>
          <ReactLoading type={'spin'} color={theme.palette.primary.main} height={100} width={100} />
        </div>
      )}
    </div>
  );
}

export default connector(FeedView);
