import { memo, ReactNode, useEffect, useMemo, useState } from 'react';
import { Divider, Popover } from '@mui/material';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import GlarFilterContextProvider, { GlarFilterMap } from './glar-filter.context';
import DefaultButton from '../default-button/default-button';
import { formatDateFilters } from './helper';
import { makeStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { sum } from 'lodash';

interface IGlarFilterPopoverProps<T extends GlarFilterMap> {
  onClose: () => any;
  defaultFilters: T;
  onSubmit: (filters: T) => any;
  children: ReactNode;
}

export type GenericFilterPopoverProps<T extends GlarFilterMap> = Omit<IGlarFilterPopoverProps<T>, 'children'>;

const useStyles = makeStyles(styles);

export function countFilters(filters: Record<string, any>) {
  return sum(
    Object.values(filters).map((f) => {
      if (!f) {
        return 0;
      }

      if (typeof f === 'string' || typeof f === 'number') {
        return 1;
      }

      if (f.from || f.to) {
        return Number(!!f.from) + Number(!!f.to);
      }

      if (f.type) {
        return Number(!!f.type);
      }

      if (f.startDate || f.endDate) {
        return Number(!!f.startDate) + Number(!!f.endDate);
      }

      return f.length;
    }),
  );
}

const GlarFilterPopover = memo((props: IGlarFilterPopoverProps<any>) => {
  const { onClose, defaultFilters } = props;

  const classes: ClassNameMap<string> = useStyles();
  const { t } = useTranslation();

  const [items, setItems] = useState<GlarFilterMap>({});

  useEffect(() => {
    setItems(defaultFilters);
  }, []);

  const isDisabled = useMemo(
    () => countFilters(items) === 0 && JSON.stringify(items) === JSON.stringify(defaultFilters),
    [items],
  );

  return (
    <Popover open={true} anchorReference='none' classes={{ paper: classes.popoverContainer }} onClose={onClose}>
      <div className={classes.popoverDivTitle}>
        <span className={classes.popoverTitleSpan}>{t('filter')}</span>
        <Close classes={{ root: classes.closeIconTitle }} onClick={onClose} />
      </div>
      <Divider />
      <div className={classes.rows}>
        <GlarFilterContextProvider items={items} setItems={setItems}>
          {props.children}
        </GlarFilterContextProvider>
      </div>
      <div className={classes.fixedButtons}>
        {/* {!isDisabled && ( */}
        <DefaultButton
          onClick={() =>
            setItems((items) => {
              const newItems = Object.fromEntries(Object.entries(items).map(([k]) => [k, undefined]));

              props.onSubmit(newItems);
              props.onClose();

              return newItems;
            })
          }
          data-testid='glar-filter-reset'
          variant={'discard'}
        >
          {t('reset')}
        </DefaultButton>
        {/* )} */}
        <DefaultButton
          disabled={isDisabled}
          onClick={() => {
            const formattedFilters = formatDateFilters(items);

            props.onSubmit(formattedFilters);
            props.onClose();
          }}
          data-testid='glar-filter-apply'
          variant={'default'}
        >
          {t('apply')}
        </DefaultButton>
      </div>
    </Popover>
  );
});

export default GlarFilterPopover;
