import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    inputField: {
      fontSize: '0.875rem',
      cursor: 'pointer',
      borderBottom: `1px solid ${theme.palette.grey.light}`,
    },
    inputFieldEdit: {
      fontSize: '0.875rem',
      cursor: 'text',
    },
    formControl: {
      width: '100%',
      '& > div': {
        display: 'block',
      },
    },
    formControl2: {
      width: '100%',
      marginLeft: '0px',
      '&:hover #div-buttons-actions': {
        display: 'flex !important',
      },
    },
    formControlError: {
      width: '100%',
      border: `1px solid ${theme.palette.error.main} !important`,
      borderRadius: '5px',
      padding: '10px',
    },
    formControl2Error: {
      width: '100%',
      '&:hover #div-buttons-actions': {
        display: 'flex !important',
      },
      border: `1px solid ${theme.palette.error.main} !important`,
      borderRadius: '5px',
      padding: '10px',
    },
    divBtns: {
      display: 'none',
    },
    divBtnsActive: {
      display: 'flex !important',
    },
    formControlLabel2: {
      width: 'calc(100% - 152px)',
    },
    formControlLabel: {
      width: '100%',
    },
    formControlLabelAdd: {
      width: '100%',
      color: theme.palette.action.disabled,
    },
    divLabel: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '4px',
      borderRadius: '4px',
      '&:hover': {
        background: theme.palette.primary.light,
        cursor: 'pointer',
      },
    },
    divLabelPresetHovered: {
      '&:hover': {
        cursor: 'default',
      },
    },
    deleteIcon: {
      color: theme.palette.error.main,
      alignSelf: 'center',
    },
    buttons: {
      minWidth: 'unset',
      border: `1px solid ${theme.palette.grey.main}`,
      boxSizing: 'border-box',
      borderRadius: '4px',
      marginLeft: '5px',
      padding: '0px 3px',
      maxHeight: '30px',
    },
    btnDelete: {
      minWidth: 'unset',
      padding: '0px 6px',
      maxHeight: '30px',
    },
    optionsDisable: {
      color: theme.palette.text.secondary,
    },
    optionsEnable: {
      color: theme.palette.text.primary,
    },
    buttonConditional: {
      minWidth: 'unset',
      boxSizing: 'border-box',
      borderRadius: '4px',
      marginLeft: '5px',
      padding: '0px 3px',
      maxHeight: '30px',
      color: theme.palette.primary.main,
    },
    buttonConditionalDisabled: {
      minWidth: 'unset',
      boxSizing: 'border-box',
      borderRadius: '4px',
      marginLeft: '5px',
      padding: '0px 3px',
      maxHeight: '30px',
      color: theme.palette.text.secondary,
    },
    scoreDiv: {
      width: 90,
      '& .MuiOutlinedInput-root': {
        height: 30,
      },
    },
    sumValueText: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: theme.palette.text.primary,
    },
    sumValueDiv: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: theme.palette.text.primary,
    },
    inputInPreset: {
      color: theme.palette.text.primary,
      '-webkit-text-fill-color': 'unset !important',
    },
    addOpt: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      color: theme.palette.primary.main,
    },
    addOptLabel: {
      fontSize: '14px',
      fontWeight: 500,
    },
    inputUnderline: {
      '&:not(.Mui-disabled):before': {
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
      },
      '&:not(.Mui-disabled):hover::before': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
      '&:not(.Mui-disabled):after': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
    },
    selectInput: {
      height: '40px',
      display: 'flex !important',
      '&.MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.grey.light,
        },
        '&:hover fieldset': {
          borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.primary.main,
          borderWidth: '1px',
        },
      },
    },
    menuItem: {
      padding: '8px 16px',
      backgroundColor: `${theme.palette.background.default} !important`,
      '&:hover': {
        backgroundColor: `${theme.palette.primary.light} !important`,
      },
      '&.Mui-selected': {
        backgroundColor: `${theme.palette.background.default} !important`,
        color: `${theme.palette.primary.main} !important`,
      },
      '&.Mui-selected:hover': {
        backgroundColor: `${theme.palette.primary.light} !important`,
        color: `${theme.palette.secondary.main} !important`,
      },
    },
    listItemText: {
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
    },
    divMoreSelectionOptions: {
      width: '100%',
      marginTop: '2px',
      marginLeft: '8px',
      cursor: 'pointer',
    },
  });
