import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

export const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      boxShadow: 'none',
      transition: 'all 0.3s, height 1ms',
      minHeight: '35px',
      border: `1px solid ${theme.palette.text.primary}`,
      color: theme.palette.text.primary,
      borderRadius: '5px',
      background: 'transparent',
      '&:hover': {
        border: `1px solid ${theme.palette.text.primary}`,
        color: theme.palette.text.primary,
      },
      '&:focus': {
        color: theme.palette.text.primary,
        border: '1px solid',
      },
    },
    containerError: {
      boxShadow: 'none',
      transition: 'all 0.3s, height 1ms',
      minHeight: '35px',
      border: `1px solid ${theme.palette.error.main}`,
      color: theme.palette.text.primary,
      borderRadius: '5px',
      background: 'transparent',
      '&:hover': {
        border: `1px solid ${theme.palette.error.main}`,
        color: theme.palette.text.primary,
      },
      '&:focus': {
        color: theme.palette.text.primary,
        border: '1px solid',
      },
    },
    containerNoBorder: {
      boxShadow: 'none',
      transition: 'all 0.3s, height 1ms',
      minHeight: '35px',
      color: theme.palette.text.primary,
      borderRadius: '5px',
      background: 'transparent',
      '&:hover': {
        color: theme.palette.text.primary,
      },
      '&:focus': {
        color: theme.palette.text.primary,
      },
    },
    uploadInput: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: '0',
      top: '0',
      opacity: '0',
      cursor: 'pointer',
    },
    row: {
      display: 'flex',
    },
    rowInstruction: {
      display: 'block',
      textAlign: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    icon: {
      paddingRight: '8px',
      paddingLeft: '8px',
      paddingTop: '8px',
    },
    text: {
      paddingTop: '11px',
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
    },
    textInstruction: {
      paddingTop: '11px',
      color: theme.palette.success.main,
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontSize: '11px',
    },
    addIcon: {
      color: theme.palette.primary.main,
      fontSize: '21px',
    },
    addIconInstruction: {
      color: theme.palette.success.main,
      fontSize: '44px',
    },
    uploadDiv: {
      cursor: 'pointer',
      position: 'relative',
      overflow: 'hidden',
      display: 'inline-block',
      border: `1px solid ${theme.palette.primary.light}`,
      borderRadius: '4px',
      paddingRight: '8px',
      '&:hover': {
        background: theme.palette.primary.light,
      },
    },
    uploadDivInstruction: {
      cursor: 'pointer',
      position: 'relative',
      overflow: 'hidden',
      display: 'inline-block',
      border: `2px solid ${theme.palette.success.main}`,
      backgroundColor: theme.palette.background.background1,
      borderRadius: '9px',
      width: '100px',
      height: '100px',
      marginLeft: '19px',
    },
    spanDelete: {
      width: '35px',
    },
    iconDownload: {
      fontSize: '20px',
    },
    iconDelete: {
      fontSize: '20px',
      fill: theme.palette.error.main,
    },
    table: {
      width: '100%',
    },
    disabled: {
      boxShadow: 'none',
      transition: 'all 0.3s, height 1ms',
      minHeight: '35px',
      border: `1px solid ${theme.palette.text.secondary}`,
      color: theme.palette.text.secondary,
      borderRadius: '5px',
    },
    inputCursorDisabled: {
      cursor: 'not-allowed',
    },
    inputCursorEnabled: {
      cursor: 'pointer',
    },
    insideForm: {
      display: 'inline-block',
      width: '100%',
    },
    infoLabel: {
      fontSize: theme.typography.fontSize,
      color: theme.palette.primary.main,
    },
    tr: {
      display: 'flex',
      width: '100%',
      overflow: 'auto',
    },
    rowName: {
      width: 'calc(100% - 0px)',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: theme.palette.text.primary,
      fontSize: '9px',
      fontWeight: 400,
      fontFamily: '"Poppins", sans-serif;',
    },
    spanDownload: {
      paddingLeft: '5px',
      paddingRight: '5px',
      cursor: 'pointer',
    },
    loadingDivMinHeight: {
      minHeight: '200px',
      width: '100%',
    },
    loadingDiv: {
      width: '100%',
    },
    img: {
      width: '107px',
      height: '112px',
      objectFit: 'contain',
    },
    imageTitle: {
      display: 'flex',
      marginTop: '5px',
      maxWidth: '107px',
      alignItems: 'center',
    },
    divContainerImages: {
      display: 'flex',
      flexWrap: 'wrap',
      overflowY: 'auto',
      cursor: 'pointer',
    },
    divImages: {
      height: 'min-content',
      marginTop: '12px',
      marginLeft: '19px',
      marginBottom: '14px',
    },
    divImageNotFound: {
      width: '107px',
      height: '112px',
      textAlign: 'center',
      fontFamily: 'Open Sans',
      backgroundColor: theme.palette.background.background1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconNotFound: {
      width: '80px',
      height: '80px',
      display: 'block',
      margin: 'auto',
      marginTop: '25px',
    },
    iconTypeFile: {
      width: '20px',
      height: '20px',
      marginRight: '3px',
    },
    deleteIcon: {
      color: theme.palette.error.main,
      fontSize: '14px',
    },
    divSize: {
      fontSize: '11px',
      fontWeight: 400,
      fontFamily: '"Poppins", sans-serif;',
    },
    attachIcon: {
      fontSize: '53px',
      color: theme.palette.primary.main,
    },
    videoIcon: {
      position: 'absolute',
      left: 'calc(50% - 20px)',
      color: theme.palette.common.white,
      fontSize: '40px',
      top: 'calc(50% - 20px)',
    },
    showComment: {
      /* position: 'absolute', */
      transform: 'translate(0px, -37px)',
      /* bottom: '137px', */
      width: '107px',
      paddingLeft: '5px',
      paddingRight: '5px',
      backgroundColor: 'rgba(136, 137, 154, 0.78)',
      color: theme.palette.background.default,
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      cursor: 'inherit',
      fontSize: '11px',
      fontFamily: 'Open Sans',
      height: '20px',
      paddingTop: '2px',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.common.white,
      minHeight: '200px',
      position: 'relative',
    },
    deleteAllIcon: {
      display: 'flex',
      marginLeft: 'auto',
      marginRight: 0,
      marginTop: 10,
      color: `${theme.palette.error.main} !important`,
      fontSize: '16px',
      cursor: 'pointer',
    },
    dialog: {
      width: 280,
      height: 155,
      alignItems: 'center',
    },
    dialogHeader: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '19px',
      lineHeight: '26px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.15px',
      justifyContent: 'center',
    },
    closeButton: {
      color: theme.palette.grey[500],
      '&:hover': {
        backgroundColor: 'unset',
      },
      padding: '0 0 0 16px',
      position: 'absolute',
      top: '10px',
      right: '5px',
    },
    attachAnImage: {
      cursor: 'pointer',
    },
    divButtons: {
      display: 'flex',
      gap: '4px',
      marginTop: '12px',
      marginBottom: '12px',
    },
  }),
);
