import { SnackbarMessage, VariantType } from 'notistack';
import { Dispatch } from 'redux';
import { Action, State } from 'src/interfaces/reducers';
import store from 'src/reducer-manager';

const initialState: State = {
  sidenavMinimized: false,
  snackbarMessages: [],
  trailName: '',
};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'SNACKBAR_NEW_MESSAGE':
      return {
        ...state,
        snackbarMessages: [
          {
            message: action.payload.message,
            severity: action.payload.severity,
          },
        ],
      };
    case 'TOGGLE_SIDENAV':
      return { ...state, sidenavMinimized: action.payload };
    case 'SNACKBAR_CLEAR':
      return { ...state, snackbarMessages: [] };
    case 'SET_BREADCRUMB_TRAIL_NAME':
      return { ...state, trailName: action.payload.trailName };
    default:
      return state;
  }
};

export const setBreadcrumbTrailName = (trailName: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: 'SET_BREADCRUMB_TRAIL_NAME',
      payload: {
        trailName,
      },
    });
  };
};

export const showSnackbar = (severity: string, message: string | JSX.Element) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        severity,
        message,
      },
    });
  };
};

export const toast = (severity: VariantType, message: SnackbarMessage) => {
  return store.dispatch({
    type: 'SNACKBAR_NEW_MESSAGE',
    payload: {
      severity,
      message,
    },
  });
};

export const clearSnackbar = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: 'SNACKBAR_CLEAR',
    });
  };
};

export const toggleSidenav = (state: boolean) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: 'TOGGLE_SIDENAV',
      payload: state,
    });
  };
};
