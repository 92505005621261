import { styles } from 'src/utils/components/dialog-files-carousel/styles';
import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { downloadFile, updateFile, getNewDownloadLink } from 'src/modules/file-storage/file-storage.redux';
import { useCallback } from 'react';
import DefaultButton from 'src/utils/components/default-button';
import moment from 'moment';
import axios from 'axios';
import { API_URL } from 'src/utils/env';
import { cloneDeep, isArray } from 'lodash';
import ARModelRender from '../ar-model-render';
import useComponentDidUpdate from 'src/utils/hooks/useComponentDidUpdate';
import ImageNotFoundWithoutBorder from '@assets/images/imageNotFoundWithoutBorder.svg';
import { File } from 'src/gql/graphql';
import { Button, CircularProgress, Dialog, Grid, Slider, TextField, Typography } from '@mui/material';
import {
  Add,
  AttachFile,
  ChevronLeft,
  ChevronRight,
  Close,
  Delete,
  Edit,
  GetApp,
  Remove,
  ZoomIn,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { GlarState } from 'src/reducer-manager';
import { IconTooltipButton } from '../icon-tooltip-button';
import { getFileExtension } from '../show-file/utils/show-file-util-functions';
import ImageEditor from '../show-file/components/media/image-editor';
import { FilesContext } from '../show-file/context';

interface DialogFilesCarouselProps extends ConnectedProps<typeof connector> {
  close?: () => void;
  clickedFile: Partial<File>;
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  showDelete?: boolean;
  onlyDelete?: boolean;
  setRef?: any;
  delete?: (file: File) => void;
}

const DialogFilesCarousel = (props: DialogFilesCarouselProps): JSX.Element => {
  const { files, clickedFile, close, setFiles, setRef } = props;
  const classes = styles();
  const [viewURL, setViewURL] = useState<string>('');
  const [indexShow, setIndexShow] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [zoom, setZoom] = useState<boolean>(false);
  const [widthPreview, setWidthPreview] = useState<number>(0);
  const [heightPreview, setHeightPreview] = useState<number>(0);
  const [scale, setScale] = useState<number>(1);
  const [offsetPreviewHeight, setOffsetPreviewHeight] = useState<number>(0);
  const [offsetPreviewWidth, setOffsetPreviewWidth] = useState<number>(0);
  const [widthFullImg, setWidthFullImg] = useState<number>(0);
  const [heightFullImg, setHeightFullImg] = useState<number>(0);
  const [ratioFullImg, setRatioFullImg] = useState<number>(1);
  const [ratioPrevImg, setRatioPrevImg] = useState<number>(1);
  const [startPointDragFullImg, setStartPointDragFullImg] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDialogComment, setOpenDialogComment] = useState<boolean>(false);
  const [temporaryComment, setTemporaryComment] = useState<string | null>(null);
  const [isCommentOverflow, setIsCommentOverflow] = useState<boolean>(false);
  const [readMore, setReadMore] = useState<boolean>(false);
  const [isARModelFullscreen, setARModelFullscreen] = useState(false);
  const [, setUpdate] = useState();
  //@ts-ignore
  const forceUpdate = useCallback(() => setUpdate({}), []);
  const imagePreview = useRef<HTMLImageElement>(null);
  const zoomContainerPreview = useRef<HTMLDivElement>(null);
  const img = useRef<HTMLImageElement>(null);
  const divModalPreviewRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [edit, setEdit] = useState<boolean>(false);

  const fileIsImage = (
    files[parseInt(`${indexShow}`)]?.extension || getFileExtension(files[parseInt(`${indexShow}`)]?.name)
  ).match(/(jpg|png|gif|webp|tiff|bmp|jpeg)$/i);
  const fileIsPDF = (
    files[parseInt(`${indexShow}`)]?.extension || getFileExtension(files[parseInt(`${indexShow}`)]?.name)
  ).match(/(pdf)$/i);
  const fileIsVideo = (
    files[parseInt(`${indexShow}`)]?.extension || getFileExtension(files[parseInt(`${indexShow}`)]?.name)
  ).match(/(webm|mpg|mp2|mpeg|mpe|mpv|ogg|mp4|m4p|m4v|avi|wmv|mov|qt|flv|swf|avchd)$/i);
  const fileIs3DModel = (
    files[parseInt(`${indexShow}`)]?.extension || getFileExtension(files[parseInt(`${indexShow}`)]?.name)
  ).match(/(glb|gltf)$/i);

  const emptyImage = new Image(1, 1);
  emptyImage.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

  useEffect(() => {
    //@ts-ignore
    setIsCommentOverflow(files[parseInt(`${indexShow}`)]?.comment?.length > 115);
    setReadMore(false);
    if (img.current) onImgLoad(img.current);
  }, [files[parseInt(`${indexShow}`)]?.comment]);

  const getImagesByURL = React.useCallback(async (file) => {
    setLoading(true);
    const resp = await props.getNewDownloadLink(file._id);
    let fileClone = cloneDeep(file);
    fileClone = { ...fileClone, data: resp };
    if (setFiles) setFiles(files.map((f) => (f._id === file._id ? fileClone : f)));
    setLoading(false);
  }, []);

  const goRight = (): void => {
    if (indexShow === files.length - 1) {
      if (files[0].download && moment.unix(files[0].download.validUntil).isBefore(moment())) getImagesByURL(files[0]);
      setIndexShow(0);
    } else {
      if (
        files[parseInt(`${indexShow}`) + 1].download &&
        moment.unix(files[parseInt(`${indexShow}`) + 1].download.validUntil).isBefore(moment())
      )
        getImagesByURL(files[parseInt(`${indexShow}`) + 1]);
      setIndexShow(indexShow + 1);
    }
  };

  const goLeft = (): void => {
    if (indexShow === 0) {
      if (files[0].download && moment.unix(files[0].download.validUntil).isBefore(moment())) getImagesByURL(files[0]);
      setIndexShow(files.length - 1);
    } else {
      if (
        files[parseInt(`${indexShow}`) - 1].download &&
        moment.unix(files[parseInt(`${indexShow}`) - 1].download.validUntil).isBefore(moment())
      )
        getImagesByURL(files[parseInt(`${indexShow}`) - 1]);
      setIndexShow(indexShow - 1);
    }
  };

  const handleKeyDownEvent = (e: globalThis.KeyboardEvent): void => {
    if (!zoom && !openDialogComment) {
      if (e.key === 'ArrowRight') {
        goRight();
      } else if (e.key === 'ArrowLeft') {
        goLeft();
      }
    }
  };

  const handleClickEdit = (e) => {
    e.stopPropagation();
    setEdit(true);
  };

  useEffect(() => {
    const file = files[parseInt(`${indexShow}`)];
    if (file) {
      axios
        .get(API_URL + `/request/file`, {
          headers: { authorization: localStorage.getItem('access_token') },
          params: {
            id: file._id,
            disposition: 'inline',
          },
        })
        .then((v) => {
          setViewURL(v.data);
        });
    }
  }, [indexShow]);

  useEffect(() => {
    if (files && clickedFile) {
      files.map((f, i) => {
        if (f._id === clickedFile._id) {
          if (
            files[parseInt(`${i}`)].download &&
            moment.unix(files[parseInt(`${i}`)].download.validUntil).isBefore(moment())
          )
            getImagesByURL(files[parseInt(`${i}`)]);
          setIndexShow(i);
          const file = isArray(files) ? files[parseInt(`${i}`)] : files;
          axios
            .get(API_URL + `/request/file`, {
              headers: { authorization: localStorage.getItem('access_token') },
              params: {
                id: file._id,
                disposition: 'inline',
              },
            })
            .then((v) => {
              setViewURL(v.data);
            });
        }
      });
    }
  }, []);

  useComponentDidUpdate(() => {
    files.map((f) => {
      if (f._id === files[`${indexShow}`]._id) {
        if (f.download && moment.unix(f.download.validUntil).isBefore(moment())) getImagesByURL(f);
        axios
          .get(API_URL + `/request/file`, {
            headers: { authorization: localStorage.getItem('access_token') },
            params: {
              id: f._id,
              disposition: 'inline',
            },
          })
          .then((v) => {
            setViewURL(v.data);
          });
      }
    });
  }, [indexShow]);

  useEffect(() => {
    if (files.length > 1) window.addEventListener('keydown', handleKeyDownEvent);
    return (): void => {
      window.removeEventListener('keydown', handleKeyDownEvent);
    };
  }, [indexShow, zoom]);

  const downloadFile = (file: File): void => {
    props.downloadFile(file.download?.url || file.name, file._id, file.download?.validUntil || undefined);
  };

  const onImgLoad = (img: HTMLImageElement): void => {
    const containerWidth = img.parentElement?.parentElement.clientWidth;
    const containerHeight = img.parentElement?.parentElement.clientHeight;
    const hRatio = containerWidth / img.naturalWidth;
    const vRatio = containerHeight / img.naturalHeight;
    const ratio = Math.min(hRatio, vRatio);
    setWidth(img.naturalWidth * ratio);
    setHeight(img.naturalHeight * ratio);

    const hRatioFull = window.innerWidth / img.naturalWidth;
    const vRatioFull = window.innerHeight / img.naturalHeight;
    const ratioFull = Math.min(hRatioFull, vRatioFull);
    setWidthFullImg(ratioFull > 1 ? img.naturalWidth : img.naturalWidth * ratioFull);
    setHeightFullImg(ratioFull > 1 ? img.naturalHeight : img.naturalHeight * ratioFull);
    setRatioFullImg(ratioFull > 1 ? 1 : ratioFull);

    const ratioPrev = Math.min(200 / img.naturalHeight, 200 / img.naturalWidth);
    setHeightPreview(img.naturalHeight * ratioPrev);
    setWidthPreview(img.naturalWidth * ratioPrev);
    setRatioPrevImg(ratioPrev);
  };

  const mouseMove = (e: MouseEvent): void => {
    e.stopPropagation();
    if (
      //@ts-ignore
      (imagePreview.current && imagePreview.current.contains(e.target)) ||
      //@ts-ignore
      (zoomContainerPreview.current && zoomContainerPreview.current.contains(e.target))
    ) {
      const offSetHeightPrev =
        e.clientY -
        (window.innerHeight / (heightFullImg * scale) > 1
          ? heightPreview
          : heightPreview * (window.innerHeight / (heightFullImg * scale))) /
          2;
      const offSetWidthPrev =
        e.clientX -
        (window.innerWidth / (widthFullImg * scale) > 1
          ? widthPreview
          : widthPreview * (window.innerWidth / (widthFullImg * scale))) /
          2;
      setOffsetPreviewHeight(
        offSetHeightPrev < 0
          ? 0
          : imagePreview?.current &&
              offSetHeightPrev >
                heightPreview -
                  (window.innerHeight / (heightFullImg * scale) > 1
                    ? heightPreview
                    : heightPreview * (window.innerHeight / (heightFullImg * scale)))
            ? heightPreview -
              (window.innerHeight / (heightFullImg * scale) > 1
                ? heightPreview
                : heightPreview * (window.innerHeight / (heightFullImg * scale)))
            : offSetHeightPrev,
      );
      setOffsetPreviewWidth(
        offSetWidthPrev < 0
          ? 0
          : imagePreview?.current &&
              offSetWidthPrev >
                widthPreview -
                  (window.innerWidth / (widthFullImg * scale) > 1
                    ? widthPreview
                    : widthPreview * (window.innerWidth / (widthFullImg * scale)))
            ? widthPreview -
              (window.innerWidth / (widthFullImg * scale) > 1
                ? widthPreview
                : widthPreview * (window.innerWidth / (widthFullImg * scale)))
            : offSetWidthPrev,
      );
    }
  };

  const onResize = (): void => {
    forceUpdate();
  };

  useEffect(() => {
    window.addEventListener('click', mouseMove);
    window.addEventListener('resize', onResize);
    return (): void => {
      window.removeEventListener('click', mouseMove);
      window.removeEventListener('resize', onResize);
    };
  }, [heightPreview, widthPreview, scale, window.innerHeight, window.innerWidth]);

  const clearZoom = (): void => {
    setZoom(false);
    setScale(1);
    setOffsetPreviewWidth(0);
    setOffsetPreviewHeight(0);
    setRatioFullImg(1);
    setRatioPrevImg(1);
    if (img.current) onImgLoad(img.current);
  };

  const handleImageError = (e) => {
    e.target.src = ImageNotFoundWithoutBorder;
  };

  return (
    <Dialog
      ref={setRef}
      id={'dialog-files-carousel'}
      onClose={(): void => {
        if (zoom) {
          clearZoom();
        } else {
          close();
        }
      }}
      open={true}
      sx={{ zIndex: 400 }}
      classes={{ paper: classes.dialog }}
    >
      {indexShow !== null ? (
        <div className={classes.container}>
          <div className={classes.divHeader}>
            {fileIs3DModel && (
              <IconTooltipButton
                data-testid='icon-button-fullscreen-ar-model'
                variant='fullscreen'
                tooltip={t('fullscreen')}
                onClick={() => setARModelFullscreen(true)}
                size={24}
              />
            )}
            {fileIsImage && <ZoomIn classes={{ root: classes.zoomIcon }} onClick={(): void => setZoom(true)} />}
            {(fileIsPDF || fileIsVideo) && <div style={{ width: '24px' }} />}
            <Typography variant='h4' className={classes.amountOfFiles}>
              {` ${indexShow + 1} of ${files.length}`}
            </Typography>
            <Close id={'buttonCloseDialogFilesCarousel'} classes={{ root: classes.iconClose }} onClick={close} />
          </div>
          {files.length > 1 ? (
            <Button classes={{ root: classes.circularButton }} style={{ left: '-60px' }} onClick={() => goLeft()}>
              <ChevronLeft className={classes.arrow}></ChevronLeft>
            </Button>
          ) : null}
          {files.length > 1 ? (
            <Button classes={{ root: classes.circularButton }} style={{ right: '-60px' }} onClick={() => goRight()}>
              <ChevronRight className={classes.arrow}></ChevronRight>
            </Button>
          ) : null}
          <div className={!files[parseInt(`${indexShow}`)].comment ? classes.divImage : classes.divImageWithComment}>
            {loading ? (
              <CircularProgress />
            ) : (
              <div
                ref={divModalPreviewRef}
                className={classes.divModalPreview}
                style={{
                  justifyContent: fileIsPDF ? 'flex-start' : 'center',
                  alignItems: fileIsPDF ? 'stretch' : 'center',
                }}
              >
                {fileIsImage && (
                  <>
                    <img
                      ref={img}
                      draggable={false}
                      onDragStart={(): boolean => false}
                      onLoad={(e) => onImgLoad(e.target as HTMLImageElement)}
                      alt={files[parseInt(`${indexShow}`)]?.name}
                      src={
                        files[parseInt(`${indexShow}`)]?.download?.url ||
                        files[parseInt(`${indexShow}`)]?.download?.thumbnail?.url ||
                        ''
                      }
                      onError={handleImageError}
                      style={{
                        width: width,
                        height: height,
                      }}
                      className={classes.image}
                    />
                  </>
                )}
                {fileIs3DModel && (
                  <div className={classes.arRenderContainer}>
                    <ARModelRender
                      modelUrl={
                        files[parseInt(`${indexShow}`)]?.download?.url ||
                        files[parseInt(`${indexShow}`)]?.download?.thumbnail?.url
                      }
                      thumbnailUrl={files[parseInt(`${indexShow}`)]?.download?.thumbnail?.url}
                      fullscreen={isARModelFullscreen}
                      onClickCloseFullscreen={() => setARModelFullscreen(false)}
                    />
                  </div>
                )}
                {fileIsVideo && (
                  <video
                    src={
                      files[parseInt(`${indexShow}`)]?.download?.url ||
                      files[parseInt(`${indexShow}`)]?.download?.thumbnail?.url
                    }
                    controls
                    className={classes.video}
                  />
                )}
                {fileIsPDF && (
                  <embed
                    style={{
                      flex: '1',
                    }}
                    type='application/pdf'
                    src={viewURL}
                  />
                )}
                {!fileIsImage && !fileIsVideo && !fileIsPDF && !fileIs3DModel && (
                  <div
                    style={files[parseInt(`${indexShow}`)]?.comment ? { height: '536px' } : {}}
                    className={classes.divImageNotFound}
                  >
                    <AttachFile classes={{ root: classes.attachIcon }} />
                  </div>
                )}
              </div>
            )}
          </div>
          {files[parseInt(`${indexShow}`)]?.comment && readMore ? (
            <div className={classes.showComment} style={{ visibility: 'hidden' }} />
          ) : null}
          {files[parseInt(`${indexShow}`)]?.comment ? (
            <div className={!readMore ? classes.showComment : classes.showCommentReadMore}>
              <span className={classes.comment} style={readMore ? { wordBreak: 'break-word' } : {}}>
                {isCommentOverflow && readMore
                  ? files[parseInt(`${indexShow}`)]?.comment
                  : files[parseInt(`${indexShow}`)]?.comment?.substring(0, 115)}
                {isCommentOverflow && !readMore ? (
                  <>
                    {'... '}
                    <span className={classes.readMore} onClick={() => setReadMore(true)}>
                      {t('readMore')}
                    </span>
                  </>
                ) : isCommentOverflow ? (
                  <span className={classes.readMore} onClick={() => setReadMore(false)}>
                    {t('readLess')}
                  </span>
                ) : null}
              </span>
            </div>
          ) : null}
          <div className={classes.divFooter}>
            {files[parseInt(`${indexShow}`)]?.createdBy?.name ? (
              <>
                <Typography variant='h3' className={classes.fileTitle}>
                  {files[parseInt(`${indexShow}`)]?.name}
                </Typography>
                <div className={classes.fileInfo}>
                  <span className={classes.fileDescription}>
                    {t('addedBy')} <span>{files[parseInt(`${indexShow}`)].createdBy.name} </span>
                    {t('on')}{' '}
                    {new Date(files[parseInt(`${indexShow}`)].updatedAt).toLocaleDateString(
                      props.locale.includes('_') ? props.locale.replace(/_/g, '-') : props.locale,
                    )}{' '}
                    -{' '}
                    {new Date(files[parseInt(`${indexShow}`)].updatedAt).toLocaleTimeString(
                      props.locale.includes('_') ? props.locale.replace(/_/g, '-') : props.locale,
                    )}
                  </span>
                  <div className={classes.icons}>
                    {props.showDelete && !fileIsVideo ? <Edit onClick={handleClickEdit}></Edit> : null}
                    <div
                      id={`buttonDownloadFile${indexShow}`}
                      onClick={(): void => downloadFile(files[parseInt(`${indexShow}`)])}
                    >
                      <GetApp style={{ fontSize: '24px ' }} />
                    </div>
                    {props.showDelete ? (
                      <>
                        <Delete
                          classes={{ root: classes.deleteCommentIcon }}
                          onClick={(e) => {
                            e.stopPropagation();
                            props.delete(files[parseInt(`${indexShow}`)]);
                            props.close?.();
                          }}
                        />
                      </>
                    ) : null}
                  </div>
                </div>
              </>
            ) : undefined}
          </div>
        </div>
      ) : null}
      {zoom ? (
        <div
          className={classes.divZoom}
          style={{
            width: window.innerWidth,
            height: window.innerHeight,
            backgroundColor: 'black',
            justifyContent: window.innerWidth / (widthFullImg * scale) > 1 ? 'center' : 'unset',
            alignItems: window.innerHeight / (heightFullImg * scale) > 1 ? 'center' : 'unset',
          }}
        >
          <div className={classes.divPreview} style={{ backgroundColor: 'rgba(0,0,0,0.3)', padding: '14px' }}>
            <div>
              <img
                draggable={false}
                onDragStart={(): boolean => false}
                ref={imagePreview}
                style={{
                  width: widthPreview,
                  height: heightPreview,
                  userSelect: 'none',
                }}
                alt={files[parseInt(`${indexShow}`)]?.name}
                src={
                  files[parseInt(`${indexShow}`)]?.download?.url ||
                  files[parseInt(`${indexShow}`)]?.download?.thumbnail?.url
                }
                onError={handleImageError}
              />
              <div
                style={{
                  zIndex: 3000,
                  border: '1px solid white',
                  position: 'absolute',
                  width:
                    window.innerWidth / (widthFullImg * scale) > 1
                      ? widthPreview
                      : widthPreview * (window.innerWidth / (widthFullImg * scale)),
                  height:
                    window.innerHeight / (heightFullImg * scale) > 1
                      ? heightPreview
                      : heightPreview * (window.innerHeight / (heightFullImg * scale)),
                  top: offsetPreviewHeight + 14 + 'px',
                  left: offsetPreviewWidth + 14 + 'px',
                }}
                onDragStart={(e): void => e.dataTransfer.setDragImage(new Image(), 0, 0)}
                //@ts-ignore
                onDrag={mouseMove}
                draggable={true}
                ref={zoomContainerPreview}
              />
            </div>
            <Grid container spacing={2}>
              <Grid item>
                <Remove
                  id={`buttonRemoveZoom`}
                  classes={{ root: classes.iconsAddRemove }}
                  onClick={(): void => {
                    if (scale > 1.2) {
                      setScale(scale - 0.2);
                      if (
                        offsetPreviewHeight +
                          (window.innerHeight / (heightFullImg * (scale - 0.2)) > 1
                            ? heightPreview
                            : heightPreview * (window.innerHeight / (heightFullImg * (scale - 0.2)))) >
                        heightPreview
                      ) {
                        setOffsetPreviewHeight(
                          heightPreview -
                            (window.innerHeight / (heightFullImg * (scale - 0.2)) > 1
                              ? heightPreview
                              : heightPreview * (window.innerHeight / (heightFullImg * (scale - 0.2)))),
                        );
                      }
                      if (
                        offsetPreviewWidth +
                          (window.innerWidth / (widthFullImg * (scale - 0.2)) > 1
                            ? widthPreview
                            : widthPreview * (window.innerWidth / (widthFullImg * (scale - 0.2)))) >
                        widthPreview
                      ) {
                        setOffsetPreviewWidth(
                          widthPreview -
                            (window.innerWidth / (widthFullImg * (scale - 0.2)) > 1
                              ? widthPreview
                              : widthPreview * (window.innerWidth / (widthFullImg * (scale - 0.2)))),
                        );
                      }
                    } else {
                      setScale(1);
                      if (
                        offsetPreviewHeight +
                          (window.innerHeight / heightFullImg > 1
                            ? heightPreview
                            : heightPreview * (window.innerHeight / heightFullImg)) >
                        heightPreview
                      ) {
                        setOffsetPreviewHeight(
                          heightPreview -
                            (window.innerHeight / heightFullImg > 1
                              ? heightPreview
                              : heightPreview * (window.innerHeight / heightFullImg)),
                        );
                      }
                      if (
                        offsetPreviewWidth +
                          (window.innerWidth / widthFullImg > 1
                            ? widthPreview
                            : widthPreview * (window.innerWidth / widthFullImg)) >
                        widthPreview
                      ) {
                        setOffsetPreviewWidth(
                          widthPreview -
                            (window.innerWidth / widthFullImg > 1
                              ? widthPreview
                              : widthPreview * (window.innerWidth / widthFullImg)),
                        );
                      }
                    }
                  }}
                />
              </Grid>
              <Grid item xs>
                <Slider
                  id={'sliderZoomImage'}
                  value={(scale - 1) * 100}
                  min={0}
                  max={200}
                  onChange={(_e, numb): void => {
                    const number = parseInt(numb.toString());
                    // @ts-ignore
                    setScale(1 + number * 0.01);
                    // @ts-ignore
                    if (
                      offsetPreviewHeight +
                        (window.innerHeight / (heightFullImg * (1 + number * 0.01)) > 1
                          ? heightPreview
                          : heightPreview * (window.innerHeight / (heightFullImg * (1 + number * 0.01)))) >
                      heightPreview
                    ) {
                      // @ts-ignore
                      setOffsetPreviewHeight(
                        heightPreview -
                          (window.innerHeight / (heightFullImg * (1 + number * 0.01)) > 1
                            ? heightPreview
                            : heightPreview * (window.innerHeight / (heightFullImg * (1 + number * 0.01)))),
                      );
                    }
                    // @ts-ignore
                    if (
                      offsetPreviewWidth +
                        (window.innerWidth / (widthFullImg * (1 + number * 0.01)) > 1
                          ? widthPreview
                          : widthPreview * (window.innerWidth / (widthFullImg * (1 + number * 0.01)))) >
                      widthPreview
                    ) {
                      // @ts-ignore
                      setOffsetPreviewWidth(
                        widthPreview -
                          (window.innerWidth / (widthFullImg * (1 + number * 0.01)) > 1
                            ? widthPreview
                            : widthPreview * (window.innerWidth / (widthFullImg * (1 + number * 0.01)))),
                      );
                    }
                  }}
                  classes={{ root: classes.slider }}
                />
              </Grid>
              <Grid item>
                <Add
                  id={`buttonAddZoom`}
                  classes={{ root: classes.iconsAddRemove }}
                  onClick={(): void => {
                    if (scale < 2.8) {
                      setScale(scale + 0.2);
                    } else {
                      setScale(3);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              display: 'flex',
              width: widthFullImg * scale,
              height: heightFullImg * scale,
            }}
            draggable={true}
            onDragStart={(e): void => {
              e.stopPropagation();
              setStartPointDragFullImg([e.clientX, e.clientY]);
              if (emptyImage.complete) {
                e.dataTransfer.setDragImage(emptyImage, 0, 0);
              }
            }}
            onDrag={(e): void => {
              if (widthFullImg * scale > window.innerWidth || heightFullImg * scale > window.innerHeight) {
                const x = startPointDragFullImg[0] - e.clientX;
                const y = startPointDragFullImg[1] - e.clientY;
                const newOffsetY = offsetPreviewHeight + y * 0.001;
                const newOffsetX = offsetPreviewWidth + x * 0.001;
                const yMax =
                  heightPreview -
                  (window.innerHeight / (heightFullImg * scale) > 1
                    ? heightPreview
                    : heightPreview * (window.innerHeight / (heightFullImg * scale)));
                const xMax =
                  widthPreview -
                  (window.innerWidth / (widthFullImg * scale) > 1
                    ? widthPreview
                    : widthPreview * (window.innerWidth / (widthFullImg * scale)));
                setOffsetPreviewHeight(newOffsetY < 0 ? 0 : newOffsetY > yMax ? yMax : newOffsetY);
                setOffsetPreviewWidth(newOffsetX < 0 ? 0 : newOffsetX > xMax ? xMax : newOffsetX);
              }
            }}
          >
            <img
              style={{
                width: widthFullImg * scale,
                height: heightFullImg * scale,
                transform: `translate(-${((offsetPreviewWidth * ratioFullImg) / ratioPrevImg) * scale}px, -${
                  ((offsetPreviewHeight * ratioFullImg) / ratioPrevImg) * scale
                }px)`,
              }}
              alt={files[parseInt(`${indexShow}`)]?.name}
              src={
                files[parseInt(`${indexShow}`)]?.download?.url ||
                files[parseInt(`${indexShow}`)]?.download?.thumbnail?.url
              }
              onError={handleImageError}
              id={'full-image'}
              draggable={false}
            />
          </div>
          <div className={classes.divCloseZoom}>
            <Close
              id={`buttonClearZoom`}
              classes={{ root: classes.closeZoom }}
              onClick={(): void => {
                clearZoom();
              }}
            />
          </div>
        </div>
      ) : null}
      {openDialogComment ? (
        <Dialog
          onClose={(): void => {
            setTemporaryComment(null);
            setOpenDialogComment(false);
          }}
          open={openDialogComment}
          classes={{ paper: classes.dialogComment }}
        >
          <div className={classes.divHeader}>
            <span className={classes.commentDialogTitle}>{t('addCommentToYourFile')}</span>
            <Close
              id={'buttonCloseDialogFilesCarousel'}
              classes={{ root: classes.iconClose }}
              onClick={() => {
                setTemporaryComment(null);
                setOpenDialogComment(false);
              }}
            />
          </div>
          <div className={classes.commentDialogBody}>
            {files[parseInt(`${indexShow}`)]?.download?.thumbnail?.url &&
            /(jpg|png|gif|webp|tiff|psd|raw|bmp|heif|indd|jpeg|webm|mpg|mp2|mpeg|mpe|mpv|ogg|mp4|m4p|m4v|avi|wmv|mov|qt|flv|swf|avchd)$/i.exec(
              files[parseInt(`${indexShow}`)]?.extension,
            ) ? (
              files[parseInt(`${indexShow}`)].download.thumbnail.url
            ) : (
              <div className={classes.attachIconFile}>
                <AttachFile classes={{ root: classes.attachIcon }} />
              </div>
            )}
            <div className={classes.divComment}>
              <TextField
                multiline
                fullWidth
                value={temporaryComment}
                helperText={`${temporaryComment ? temporaryComment.length : 0}/250`}
                onChange={(e) => setTemporaryComment(e.target.value)}
                variant='outlined'
                inputProps={{ maxLength: 250 }}
              />
            </div>
          </div>
          <div
            style={{
              width: 'fit-content',
              marginLeft: 'auto',
              marginRight: '25px',
              marginTop: '5px',
            }}
          >
            <DefaultButton
              id='buttonCancel'
              data-testid='buttonCancel'
              discard
              onClick={() => {
                setOpenDialogComment(false);
                setTemporaryComment(null);
              }}
            >
              {t('cancel')}
            </DefaultButton>
            <DefaultButton
              id='buttonConfirm'
              data-testid='buttonConfirm'
              success
              onClick={async () => {
                await props.updateFile({ comment: temporaryComment }, { _id_eq: files[parseInt(`${indexShow}`)]._id });
                setFiles(
                  files.map((f) =>
                    f._id === files[parseInt(`${indexShow}`)]._id
                      ? { ...files[parseInt(`${indexShow}`)], comment: temporaryComment }
                      : f,
                  ),
                );
                setOpenDialogComment(false);
                setTemporaryComment(null);
              }}
            >
              {t('confirm')}
            </DefaultButton>
          </div>
        </Dialog>
      ) : null}
      {edit && files?.find((f) => f._id === clickedFile._id) ? (
        <FilesContext.Provider value={{ handleChange: setFiles, files: files }}>
          <ImageEditor
            file={files.find((f) => f._id === clickedFile._id)}
            close={() => {
              setEdit(false);
            }}
            reload={() => {
              forceUpdate();
            }}
          ></ImageEditor>
        </FilesContext.Provider>
      ) : null}
    </Dialog>
  );
};

const connector = connect(
  (state: GlarState) => {
    return {
      locale: state.loginReducer.loggedUser.preferences.locale || navigator?.language || 'pt',
    };
  },
  {
    downloadFile,
    updateFile,
    getNewDownloadLink,
  },
);

export default connector(DialogFilesCarousel);
