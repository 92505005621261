import gql from 'graphql-tag';

export const Q_CREATE_ROOM = gql`
  query CreateRoom {
    createRoom {
      id
      entity_id
      tenant_id
      code
      name
      vision_create_by
      create_by
      create_at
      update_at
      delete_at
      valid_until
      last_activity_at
      duration
      labels
      user_details {
        entity_id
        user_id
        group_id
        name
      }
      permissions {
        allowed_guest_features
      }
      chatroom_id
    }
  }
`;

export const M_GET_ROOM_USERS = gql`
  mutation GetRoomUsers($roomId: String!) {
    getRoomUsers(room_id: $roomId) {
      users {
        session_id
        user_id
        is_guest
        approved
        user_details {
          name
          microphone
          video_track
          video_track_type
          capabilities
          current_state
          room_features
        }
      }
    }
  }
`;

export const M_JOIN_Room_BY_ID = gql`
  mutation JoinRoomById($roomId: String!) {
    joinRoomById(room_id: $roomId) {
      id
      entity_id
      tenant_id
      code
      name
      vision_create_by
      create_by
      create_at
      update_at
      delete_at
      valid_until
      last_activity_at
      duration
      labels
      user_details {
        entity_id
        user_id
        group_id
        name
      }
      permissions {
        allowed_guest_features
      }
      chatroom_id
    }
  }
`;

export const M_JOIN_ROOM_BY_CODE = gql`
  mutation JoinRoomByCode($roomCode: String!) {
    joinRoomByCode(room_code: $roomCode) {
      id
      entity_id
      tenant_id
      code
      name
      vision_create_by
      create_by
      create_at
      update_at
      delete_at
      valid_until
      last_activity_at
      duration
      labels
      user_details {
        entity_id
        user_id
        group_id
        name
      }
      permissions {
        allowed_guest_features
      }
      chatroom_id
    }
  }
`;

export const M_INVITE_TO_ROOM_BY_SMS = gql`
  mutation InviteToRoomBySMS($roomId: String!, $phoneNumberList: [String!]!) {
    inviteToRoomBySMS(room_id: $roomId, phone_number_list: $phoneNumberList) {
      id
      email
      number
      status
      message
    }
  }
`;

export const M_INVITE_TO_ROOM_BY_EMAIL = gql`
  mutation InviteToRoomByEmail($roomId: String!, $emailList: [String!]!) {
    inviteToRoomByEmail(room_id: $roomId, email_list: $emailList) {
      id
      email
      number
      status
      message
    }
  }
`;
