import { Dialog, DialogContent, DialogTitle, Typography, useTheme } from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank, FiberManualRecord } from '@mui/icons-material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
/* import ReactTable from 'react-table'; */
import { styles } from 'src/modules/inventory/components/modal-tool-items/styles';
import DefaultButton from 'src/utils/components/default-button/default-button';
import { getToolItems, getToolItem } from 'src/modules/inventory/inventory.redux';
import QrReader from 'react-qr-reader-es6';
import Alert from '@mui/lab/Alert';
import queryString from 'query-string';
import InputFieldGeneric from 'src/utils/components/input-field/input-generic';
import { makeStyles } from '@mui/styles';
import TableAbstract from 'src/utils/components/table/abstract/table-abstract';
import { ToolItem } from 'src/gql/graphql';
import { mapDeprecatedUntypedColumns } from 'src/utils/components/table/utils/table-abstract.utils.mapUntypedColums';
import { useNavigate } from 'react-router-dom';
import QrCodeIcon from '@assets/images/qrCodeIcon.svg';
import { ColumnDef } from '@tanstack/react-table';

const useStyles = makeStyles(styles);

const connector = connect(null, {
  getToolItems,
  getToolItem,
});

interface ModalToolItemsProps extends ConnectedProps<typeof connector> {
  open?: boolean;
  inputTool?: boolean;
  onClose: () => void;
  onSubmit: (toolItems: ToolItem[]) => void;
  ignoreToolItems?: ToolItem[];
  issueId: string;
  setOpenToolItemPopover?: Dispatch<SetStateAction<boolean>>;
}

const ModalToolItems = (props: ModalToolItemsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const [toolItems, setToolItems] = useState<ToolItem[]>([]);
  const [selectedToolItems, setSelectedToolItems] = useState([]);
  const [openQrCode, setOpenQrCode] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState(null);
  const [searchStr, setSearchStr] = useState(null);
  const navigate = useNavigate();

  const getData = async (skip: number, limit: number) => {
    let where = undefined;
    if (props?.ignoreToolItems?.length) {
      where = { _id_nin: props.ignoreToolItems?.map((e) => e?._id), code_contains: searchStr ? searchStr : undefined };
    } else {
      where = {
        code_contains: searchStr ? searchStr : undefined,
      };
    }
    const res = await props.getToolItems(where, limit, skip);
    setToolItems((prev) => [...prev, ...res.toolItems]);
    return res.toolItems;
  };

  useEffect(() => {
    if (props.open) {
      setSelectedToolItems([]);
      //getData();
    }
  }, [props.open, props.ignoreToolItems]);

  const columns: ColumnDef<ToolItem>[] = [
    {
      header: () => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {selectedToolItems.length && selectedToolItems.length === toolItems.length ? (
            <CheckBox
              data-testid={'toolItemModalSelectAllChecked'}
              className={classes.checkboxSelected}
              onClick={() => {
                setSelectedToolItems([]);
              }}
            />
          ) : (
            <CheckBoxOutlineBlank
              data-testid={'toolItemModalSelectAllBlanck'}
              onClick={() => {
                setSelectedToolItems(toolItems);
              }}
            />
          )}
        </div>
      ),
      accessorKey: 'selection',
      id: 'selection',
      size: 72,
      cell: (props): JSX.Element => (
        <div className={classes.cellWithIcon}>
          {selectedToolItems.find((elem) => elem._id === props.row.original._id) ? (
            <CheckBox
              data-testid={`toolItemModalSelectChecked-${props.row.original.tool?.designation ?? '-'}-${
                props.row.original.code ?? '-'
              }`}
              className={classes.checkboxSelected}
              onClick={() => {
                setSelectedToolItems(selectedToolItems.filter((elem) => elem._id !== props.row.original._id));
              }}
            />
          ) : (
            <CheckBoxOutlineBlank
              data-testid={`toolItemModalSelectBlanck-${props.row.original.tool?.designation ?? '-'}-${
                props.row.original.code ?? '-'
              }`}
              onClick={() => {
                setSelectedToolItems([...selectedToolItems, props.row.original]);
              }}
            />
          )}
        </div>
      ),
    },
    {
      header: t('tool'),
      accessorKey: 'name',
      id: 'name',
      cell: (props): JSX.Element => (
        <div>
          <span data-testid={`${props.row.original.tool?.designation}-toolItemModalName`} className={classes.tableText}>
            {props.row.original.tool?.designation || '-'}
          </span>
        </div>
      ),
    },
    {
      header: t('id'),
      accessorKey: 'id',
      id: 'id',
      cell: (props): JSX.Element => (
        <div>
          <span
            data-testid={`${props.row.original.tool?.partNumber}-toolItemModalPartNumber`}
            className={classes.tableText}
          >
            {props.row.original.tool?.partNumber || '-'}
          </span>
        </div>
      ),
    },
    {
      header: t('code'),
      accessorKey: 'code',
      id: 'code',
      cell: (props): JSX.Element => (
        <div>
          <span data-testid={`${props.row.original.code}-toolItemModalCode`} className={classes.tableText}>
            {props.row.original.code || '-'}
          </span>
        </div>
      ),
    },
    {
      header: t('status'),
      accessorKey: 'status',
      id: 'status',
      cell: (props): JSX.Element => (
        <div
          data-testid={`${props.row.original?.available}-toolItemModalStatus`}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <FiberManualRecord
            style={{ color: props.row.original?.available ? theme.palette.success.main : theme.palette.alert.main }}
          />
          <span className={classes.tableText}>{props.row.original?.available ? t('available') : t('inUse')}</span>
        </div>
      ),
    },
    {
      header: t('storage'),
      accessorKey: 'storage',
      id: 'storage',
      cell: (props): JSX.Element => (
        <div>
          <span
            data-testid={`${props.row.original.location?.storage?.name}-toolItemModalStorage`}
            className={classes.tableText}
          >
            {props.row.original?.location?.storage?.name || '-'}
          </span>
        </div>
      ),
    },
  ];

  const handleError = (err: unknown) => {
    const message = err.toString();

    if (message.toLowerCase().includes('permission')) {
      setAlertData({ severity: 'error', text: t('errorPermissionCamera') });
      setShowAlert(true);
    } else {
      setAlertData({ severity: 'error', text: t('errorQRCodeGeneric') });
      setShowAlert(true);
    }
  };

  const handleScan = async (data: any) => {
    if (data) {
      if (data.includes('/tool/item?id=')) {
        const params = data.split('?')[1];
        const { id } = queryString.parse(params);
        if (!id) {
          setAlertData({ severity: 'error', text: t('notPossibleIdentifyTool') });
          setShowAlert(true);
        } else {
          const item = await props.getToolItem({ _id: Array.isArray(id) ? id[0] : id });
          if (!item) {
            setAlertData({ severity: 'error', text: t('notPossibleIdentifyTool') });
            setShowAlert(true);
          } else if (props?.ignoreToolItems?.find((e) => e._id === id)) {
            setAlertData({ severity: 'error', text: t('toolAlreadyAddedIssue') });
            setShowAlert(true);
          } else {
            navigate(`/tools/item?id=${id}&issueId=${props.issueId}`);
          }
        }
      } else {
        setAlertData({ severity: 'error', text: t('notPossibleIdentifyTool') });
        setShowAlert(true);
      }
    }
  };

  const cols = props.inputTool ? columns.splice(1, columns.length - 1) : columns;

  return (
    <>
      <Dialog
        data-testid={'toolItemModal'}
        open={props.open && !openQrCode}
        onClose={() => props.onClose()}
        fullWidth
        maxWidth={'lg'}
      >
        <div className={classes.mainContainer}>
          <div id='selectToolsToAddTitle' data-testid='selectToolsToAddTitle' className={classes.title}>
            {t('selectToolsToAdd')}
          </div>
          <div className={classes.searchAndQrDiv}>
            <div style={{ width: '100%' }}>
              <InputFieldGeneric
                id='modal-tool-search-input'
                data-testid='modal-tool-search-input'
                placeholder={t('search')}
                editable={true}
                disabled={false}
                type={'string'}
                onlyInput
                values={[searchStr]}
                handleChange={(e) => {
                  setSearchStr(e[0]);
                }}
              />
            </div>
            <DefaultButton
              data-testid={'toolItemModalQr'}
              variant='discard'
              size='medium'
              onClick={() => {
                setOpenQrCode(true);
              }}
            >
              <img src={QrCodeIcon} style={{ marginRight: '5px' }} />
              {t('qrCode')}
            </DefaultButton>
          </div>
          <div data-testid={'toolItemModalSelectedToolItems'} className={classes.selectedContainer}>
            <span>
              {selectedToolItems.length && !props.inputTool ? (
                `${t('Selected')}(${selectedToolItems.length})`
              ) : (
                <div>&nbsp;</div>
              )}
            </span>
          </div>
          <div className={classes.divTable}>
            <TableAbstract<ToolItem>
              data-testid='tools-choose'
              hideMore
              dataSize={toolItems.length}
              loadData={getData}
              columns={mapDeprecatedUntypedColumns(cols)}
              onClickRow={(row) => {
                if (props.inputTool) {
                  props.onSubmit([row.original]);
                }
                props.setOpenToolItemPopover?.(false);
              }}
              key={JSON.stringify([searchStr])}
            />
          </div>
          {!props.inputTool && (
            <div className={classes.btnContainer}>
              <DefaultButton
                data-testid={'toolItemModalCancel'}
                variant='discard'
                onClick={() => {
                  props.onClose();
                }}
              >
                {t('cancel')}
              </DefaultButton>
              <DefaultButton
                data-testid={'toolItemModalAdd'}
                disabled={!selectedToolItems.length}
                onClick={() => {
                  props.onSubmit(selectedToolItems);
                  setSelectedToolItems([]);
                }}
              >
                {t('add')}
              </DefaultButton>
            </div>
          )}
        </div>
      </Dialog>
      <Dialog open={openQrCode} fullWidth maxWidth='xs' onClose={() => setOpenQrCode(false)}>
        <DialogTitle style={{ textAlign: 'center' }}>
          <Typography>{t('scanDialog')}</Typography>
        </DialogTitle>
        <DialogContent>
          <QrReader delay={500} onError={handleError} onScan={handleScan} />
        </DialogContent>
        {showAlert ? (
          <div
            style={{
              position: 'absolute',
              bottom: '15px',
              width: '100%',
              zIndex: 1000,
              paddingRight: '40px',
              paddingLeft: '40px',
            }}
          >
            <Alert
              variant='filled'
              severity={alertData?.severity}
              onClose={() => {
                setShowAlert(false);
              }}
            >
              {alertData?.text}
            </Alert>
          </div>
        ) : null}
      </Dialog>
    </>
  );
};

export default connector(ModalToolItems);
