import { Edit } from '@mui/icons-material';
import { Avatar, Chip, DialogContent } from '@mui/material';
import { withStyles } from '@mui/styles';
import * as PropTypes from 'prop-types';
import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { Account, LabelValue } from 'src/gql/graphql';
import { AssignToShow } from 'src/interfaces/account';
import AssignToField from 'src/utils/components/assign-to-field';
import { styles } from 'src/utils/components/assing-to-show/styles';
import DefaultButton from 'src/utils/components/default-button';
import DetailsPopover from 'src/utils/components/details-popover';
import Modal from 'src/utils/components/modal';

/**
 * @deprecated Use ChipAccount and/or ChipLabel components instead.
 */
const AssignToShowComponent = (props: AssignToShow): JSX.Element => {
  const { table, rowTable } = props;
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [newLabelValues, setNewLabelValues] = useState<any[]>([]);
  const [newAccounts, setNewAccounts] = useState<any[]>([]);
  const [popoverDetails, setPopoverDetails] = useState<any>(null);
  const [selectedValue, setSelectedValue] = useState<any>(null);

  const renderLv = (labelValue: LabelValue, dropdown: boolean, key: number): JSX.Element => {
    if (!labelValue || !labelValue?.label) {
      return null;
    }
    return (
      <span
        key={key}
        className={
          table
            ? props.classes.spanTable
            : !dropdown
              ? rowTable
                ? props.classes.spanLabelRow
                : props.classes.spanLabel
              : props.classes.spanLabelDropdown
        }
      >
        <Chip
          avatar={
            <Avatar classes={{ root: props.classes.avatar }} style={{ backgroundColor: labelValue?.backgroundColor }}>
              {labelValue.label.name}
            </Avatar>
          }
          label={labelValue.value}
          style={{
            borderColor: labelValue?.backgroundColor,
            color: table ? '#FFF' : labelValue?.backgroundColor,
            marginTop: '2px',
            marginBottom: '2px',
          }}
          color={'primary'}
          variant='outlined'
          size='small'
        />
      </span>
    );
  };
  const renderAccount = (account: Account, dropdown: boolean, key: number): JSX.Element => (
    <span
      key={key}
      className={
        table
          ? props.classes.spanTable
          : !dropdown
            ? rowTable
              ? props.classes.spanLabelRow
              : props.classes.spanLabel
            : props.classes.spanLabelDropdown
      }
    >
      <Chip
        avatar={
          account.photo ? (
            <Avatar src={`data:image/webp;base64,${account.photo}`} />
          ) : (
            <Avatar>{account.name.charAt(0).toUpperCase()}</Avatar>
          )
        }
        label={
          !dropdown ? (
            <span
              className={
                popoverDetails && selectedValue._id === account._id
                  ? props.classes.accountNameActive
                  : props.classes.accountNameWithHover
              }
              onClick={(e) => {
                e.stopPropagation();
                setSelectedValue(account);
                setPopoverDetails(e.target);
              }}
            >
              {account.name}
            </span>
          ) : (
            account.name
          )
        }
        style={
          table
            ? {
                color: '#FFF',
                marginTop: '2px',
                marginBottom: '2px',
              }
            : {
                marginTop: '2px',
                marginBottom: '2px',
              }
        }
        variant='outlined'
        size='small'
      />
    </span>
  );
  renderLv.propTypes = {
    classes: PropTypes.object,
  };

  renderAccount.propTypes = {
    classes: PropTypes.object,
  };

  return (
    <div className={props.classes.container}>
      <div className={props.classes.labelsContainer} data-testid='assignTo-issue'>
        {props.hideTitle || props.inline ? null : (
          <div
            className={
              props.titleClassName ? props.titleClassName : props.Bold ? props.classes.labelBold : props.classes.label
            }
          >
            {props.title ? props.title : props.t('Assign to')}
          </div>
        )}
        <div className={table ? props.classes.displayTable : props.classes.disabledWithBadge}>
          {!props.inline || props.hideTitle ? null : (
            <div
              className={
                props.titleClassName
                  ? props.titleClassName
                  : props.noBold
                    ? props.classes.labelInlineNoBold
                    : props.classes.labelInline
              }
            >
              {props.title ? props.title : props.t('Assign to')}
            </div>
          )}
          {props.labelValues?.length ? props.labelValues.map((lv, i) => renderLv(lv, false, i)) : null}
          {props.accounts?.length ? props.accounts.map((acc, i) => renderAccount(acc, false, i)) : null}
          {!props.labelValues?.length && !props.accounts?.length ? ' - ' : null}
        </div>
      </div>
      {props.editable ? (
        <Edit
          classes={{ root: props.classes.editIcon }}
          onClick={(): void => {
            setNewAccounts(props.accounts);
            setNewLabelValues(props.labelValues);
            setOpenEditModal(true);
          }}
        />
      ) : null}
      {openEditModal ? (
        <Modal
          onClose={(): void => setOpenEditModal(false)}
          title={''}
          isOpen={openEditModal}
          size={{ height: '75%', width: '50%' }}
        >
          <DialogContent className={props.classes.modalBody}>
            <AssignToField
              labelValues={props.labelValues}
              accounts={props.accounts}
              onRemoveLabelValuesChip={setNewLabelValues}
              onRemoveAccountsChip={setNewAccounts}
              onChange={(e) => {
                setNewAccounts(e.assignedAccounts);
                setNewLabelValues(e.assignedLabelValues);
              }}
              id={'assignToFieldAssignToShow'}
            />
          </DialogContent>
          <div className={props.classes.modalFooter}>
            <DefaultButton
              id={'buttonDiscard'}
              data-testid={'buttonDiscard'}
              discard
              label={props.t('discard')}
              onClick={(): void => setOpenEditModal(false)}
            />
            <DefaultButton
              id={'buttonSave'}
              data-testid={'buttonSave'}
              success
              label={props.t('Save')}
              onClick={(): void => {
                if (props.reassign)
                  props.reassign({
                    accounts: newAccounts.map((acc) => acc._id),
                    labelValues: newLabelValues.map((lv) => lv._id),
                  });
                setNewLabelValues([]);
                setNewAccounts([]);
                setOpenEditModal(false);
              }}
            />
          </div>
        </Modal>
      ) : null}
      {popoverDetails ? (
        <DetailsPopover
          context={'account'}
          anchor={popoverDetails}
          value={selectedValue}
          clear={() => {
            setPopoverDetails(null);
            setSelectedValue(null);
          }}
        />
      ) : null}
    </div>
  );
};
const mapStateToProps = (): object => ({});

const mapDispatchToProps = (dispatch: Dispatch): object => bindActionCreators({}, dispatch);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
/**
 * @deprecated Use ChipAccount and/or ChipLabel components instead.
 */
export default compose<any>(
  withTranslation('translation'),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(AssignToShowComponent);
