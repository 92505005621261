import gql from 'graphql-tag';
import { ROLE, ROLE_TO_TREE } from 'src/utils/fragments/role';

export const Q_GET_ROLES = gql`
  query Roles($skip: Int, $limit: Int, $filter: RoleWhereInput, $orderBy: [RoleOrderByInput]) {
    roles(skip: $skip, limit: $limit, where: $filter, orderBy: $orderBy) {
      ...role
    }
  }
  ${ROLE}
`;

export const Q_GET_ROLE = gql`
  query Role($id: ID!) {
    role(where: { _id: $id }) {
      ...role
    }
  }
  ${ROLE}
`;

export const Q_GET_ROLES_TO_TREE = gql`
  query RolesTree($skip: Int, $limit: Int, $filter: RoleWhereInput) {
    roles(skip: $skip, limit: $limit, where: $filter, orderBy: name_ASC) {
      ...roleToTree
    }
  }
  ${ROLE_TO_TREE}
`;

export const M_CREATE_ROLE = gql`
  mutation CreateRole($name: String!, $description: String) {
    createRole(data: { name: $name, description: $description }) {
      ...roleToTree
    }
  }
  ${ROLE_TO_TREE}
`;

export const M_REPLACE_ROLES_ASSIGNED_AND_DELETE = gql`
  mutation ReplaceRolesAssignedAndDelete($_idsToDelete: [ID!]!, $_idsToReplace: [ID!]!) {
    replaceRolesAssignedAndDelete(_idsToDelete: $_idsToDelete, _idsToReplace: $_idsToReplace) {
      _id
    }
  }
`;

export const M_UPDATE_ROLE = gql`
  mutation UpdateRole($name: String!, $description: String, $_id: ID!) {
    updateRole(data: { name: $name, description: $description }, where: { _id: $_id }) {
      ...roleToTree
    }
  }
  ${ROLE_TO_TREE}
`;

export const M_DELETE_ROLE = gql`
  mutation DeleteRole($_id: ID!) {
    deleteOneRole(where: { _id: $_id }) {
      ...roleToTree
    }
  }
  ${ROLE_TO_TREE}
`;

export const M_DELETE_MANY_ROLES = gql`
  mutation DeleteManyRoles($ids: [ID!]!) {
    deleteManyRoles(_ids: $ids) {
      _id
    }
  }
`;

export const Q_SEARCH = gql`
  query RoleFolderSearch($searchString: String) {
    roles(where: { name_contains: $searchString }) {
      ...roleToTree
    }
  }
  ${ROLE_TO_TREE}
`;

export const Q_GET_ROLES_COUNT = gql`
  query GetRolesCount($where: RoleWhereInput) {
    rolesCount(where: $where)
  }
`;

export const Q_GET_IS_ROLES_ASSIGNED_TO_ACCOUNTS = gql`
  query GetIsRolesAssignedToAccounts($ids: [ID!]!) {
    isRolesAssignedToAccounts(_ids: $ids)
  }
`;

export const Q_GET_ROLES_TO_SHOW = gql`
  query GetRolesToShow($limitRole: Int, $skipRole: Int, $whereRole: RoleWhereInput) {
    roles(skip: $skipRole, limit: $limitRole, where: $whereRole, orderBy: name_ASC) {
      name
      createdBy {
        name
        _id
      }
      description
      updatedAt
      _id
    }
  }
`;

export const M_DUPLICATE_ROLE = gql`
  mutation DuplicateRole($_id: ID!, $copyChildren: Boolean!) {
    duplicateRole(_id: $_id, copyChildren: $copyChildren) {
      _id
    }
  }
`;

export const M_BUNDLE_ASSIGN = gql`
  mutation BundleAssign($roleType: PermissionRoleType, $role: ID!, $bundles: [PermissionAssignCreateInput!]!) {
    permissionBundleAssign(roleType: $roleType, role: $role, assignBundles: $bundles) {
      role {
        ...role
      }
    }
  }
  ${ROLE}
`;

export const Q_GET_BUNDLES = gql`
  query GetBundles {
    permissionBundles(where: {}) {
      name
      groups {
        name
        order
        _id
      }
      _id
    }
  }
`;
