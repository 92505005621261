import gql from 'graphql-tag';
import { UNIT_BODY, UNIT_FOLDER_BODY, UNIT_TO_TREE } from 'src/utils/fragments/unit';

export const Q_GET_UNITS = gql`
  query GetAllUnits {
    units(orderBy: createdAt_ASC) {
      ...unit
    }
  }
  ${UNIT_BODY}
`;

export const Q_UNITS = gql`
  query GetUnits($where: UnitWhereInput, $limit: Int, $skip: Int, $orderBy: [UnitOrderByInput]) {
    units(where: $where, limit: $limit, skip: $skip, orderBy: $orderBy) {
      __typename
      _id
      name
      protected
      symbol
      order
    }
  }
`;

export const Q_GET_UNIT = gql`
  query GetUnit($filter: UnitWhereUniqueInput!) {
    unit(where: $filter) {
      ...unit
    }
  }
  ${UNIT_BODY}
`;

export const Q_SEARCH = gql`
  query SearchUnits($searchString: String) {
    unitFolders(where: { name_contains: $searchString }) {
      ...unitFolder
    }
    units(where: { name_contains: $searchString }) {
      ...unit
    }
  }
  ${UNIT_BODY}
  ${UNIT_FOLDER_BODY}
`;

export const M_CREATE_UNIT = gql`
  mutation CreateUnit($name: String!, $symbol: String, $folderId: ID!, $labelValues: [ID]) {
    createUnit(data: { name: $name, symbol: $symbol, folder: $folderId, labelValues: $labelValues }) {
      ...unit
    }
  }
  ${UNIT_BODY}
`;

export const M_UPDATE_UNIT = gql`
  mutation UpdateUnit($data: UnitUpdateInput!, $where: UnitWhereUniqueInput!) {
    updateUnit(data: $data, where: $where) {
      ...unit
    }
  }
  ${UNIT_BODY}
`;

export const M_DELETE_UNIT = gql`
  mutation DeleteUnit($_id: ID!) {
    deleteOneUnit(where: { _id: $_id }) {
      ...unit
    }
  }
  ${UNIT_BODY}
`;

export const Q_GET_UNITS_AND_FOLDERS = gql`
  query GetUnitsAndFolders(
    $skip: Int
    $limit: Int
    $templateWhere: UnitWhereInput
    $templateFolderWhere: UnitFolderWhereInput
    $orderByTemplate: [UnitOrderByInput!]!
    $orderByTemplateFolder: [UnitFolderOrderByInput!]!
    $tenant: ID
  ) {
    unitsAndFolders(
      skip: $skip
      limit: $limit
      templateWhere: $templateWhere
      templateFolderWhere: $templateFolderWhere
      templateOrderBy: $orderByTemplate
      templateFolderOrderBy: $orderByTemplateFolder
      tenant: $tenant
    ) {
      ... on Unit {
        ...unitToTree
      }
      ... on UnitFolder {
        ...unitFolder
      }
    }
  }
  ${UNIT_TO_TREE}
  ${UNIT_FOLDER_BODY}
`;

export const Q_GET_UNIT_FOLDERS = gql`
  query GetUnitFolders($limit: Int, $skip: Int, $where: UnitFolderWhereInput, $orderBy: [UnitFolderOrderByInput]) {
    unitFolders(limit: $limit, skip: $skip, orderBy: $orderBy, where: $where) {
      ...unitFolder
    }
  }
  ${UNIT_FOLDER_BODY}
`;

export const Q_UNIT_FOLDERS = gql`
  query GetUnitFoldersSimple(
    $limit: Int
    $skip: Int
    $where: UnitFolderWhereInput
    $orderBy: [UnitFolderOrderByInput]
  ) {
    unitFolders(limit: $limit, skip: $skip, orderBy: $orderBy, where: $where) {
      __typename
      _id
      name
      protected
    }
  }
`;

export const M_CREATE_UNIT_FOLDER = gql`
  mutation CreateUnitFolder($name: String!) {
    createUnitFolder(data: { name: $name }) {
      __typename
      _id
      name
      protected
    }
  }
`;

export const M_UPDATE_UNIT_FOLDER = gql`
  mutation UpdateUnitFolder($name: String!, $_id: ID!) {
    updateUnitFolder(data: { name: $name }, where: { _id: $_id }) {
      __typename
      _id
      name
      protected
    }
  }
`;

export const M_DELETE_UNIT_FOLDER = gql`
  mutation DeleteUnitFolder($where: UnitFolderWhereUniqueInput!) {
    deleteOneUnitFolder(where: $where) {
      __typename
      _id
      name
      protected
    }
  }
`;

export const M_DUPLICATE_UNIT = gql`
  mutation DuplicateUnit($_id: ID!, $copyChildren: Boolean!) {
    duplicateUnit(_id: $_id, copyChildren: $copyChildren) {
      ...unit
    }
  }
  ${UNIT_BODY}
`;

export const M_DELETE_MANY_UNITS = gql`
  mutation DeleteManyUnits($_ids: [ID!]!) {
    deleteManyUnits(_ids: $_ids)
  }
`;

export const M_CREATE_MANY_UNITS = gql`
  mutation CreateManyUnits($data: [UnitCreateInput]) {
    createManyUnits(data: $data) {
      __typename
      _id
      name
      protected
      symbol
      order
    }
  }
`;

export const M_UPDATE_MANY_UNITS = gql`
  mutation UpdateManyUnits($units: [UnitToUpdate]) {
    updateManyUnits(units: $units) {
      __typename
      _id
      name
      protected
      symbol
      order
    }
  }
`;
