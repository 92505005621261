import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    link: {
      '& a': {
        transition: 'color linear 0.1s',
        color: theme.palette.primary.main,
        '&:hover': {
          color: theme.palette.primary.main,
          opacity: 0.5,
          textDecoration: 'none',
        },
        '&:link': {
          textDecoration: 'none',
        },
        '&:visited': {
          textDecoration: 'none',
        },
        '&:active': {
          textDecoration: 'none',
        },
      },
    },
    breadcrumb: {
      borderBottom: 'none !important',
      paddingLeft: '20px',
      margin: 'auto',
    },
  });
