import { lighten } from '@mui/material';
import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    popoverContent: {
      pointerEvents: 'auto',
      padding: '6px 16px',
      width: '680px',
      minWidth: '680px',
    },
    filterBuilderHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    filterBuilderTitle: {
      fontWeight: 500,
      fontSize: '18px',
    },
    filterBuilderActionsContainer: {
      display: 'flex',
      aligntItems: 'center',
      gap: '6px',
    },
    filterBuilderContent: {
      marginTop: '6px',
      padding: '6px',
      borderRadius: '12px',
      backgroundColor: lighten(theme.palette.other.peach, 0.9),
      display: 'flex',
      flexDirection: 'column',
      gap: '6px',
    },
    andChip: {
      width: 'fit-content',
      backgroundColor: theme.palette.other.peach,
      maxHeight: '24px',
      '&:hover': {
        backgroundColor: theme.palette.other.peach,
      },
    },
    chipLabel: {
      fontSize: '12px',
      color: `${theme.palette.background.paper} !important`,
    },
    selectContentContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    deleteIcon: {
      fontSize: '20px',
      cursor: 'pointer',
      color: theme.palette.error.main,
    },
    deleteIconDisabled: {
      fontSize: '20px',
      cursor: 'default',
      color: theme.palette.text.disabled,
    },
  });
