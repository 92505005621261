import { cloneDeep, concat, groupBy, isArray, orderBy, padStart, remove } from 'lodash';
import moment from 'moment';
import { ParsedQuery } from 'query-string';
import { Connect, connect, UseSelector, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import {
  Input,
  IssueTemplateDraftTaskTemplateGroupTaskTemplate,
  MyClearances,
  Tab,
  TabUpdateInput,
  TabWhereUniqueInput,
} from 'src/gql/graphql';
import store, { GlarState } from 'src/reducer-manager';
import * as XLSX from 'xlsx';
import { NexusGenFieldTypes } from '../../../../server/src/types';

const colors = ['#B468FF', '#00BF9D', '#FE8947', '#ACED24'];

/**
 * @description Generates a random round number between a minimum and a maximum.
 * @function rand
 * @param {number} min - The minimum threshold.
 * @param {number} max - The maximum threshold.
 * @returns {number} The randomly generated number.
 */
export function rand(min: number, max: number): number {
  if (min > max) return -1;
  return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
  for when you need to inline statements in jsx :/
 */
export function run<T>(callback: () => T): T {
  return callback();
}

/**
  never use new Promise() again
*/
export function invertedPromise(): {
  promise: Promise<any>;
  resolve: (value?: unknown) => void;
  reject: (value?: unknown) => void;
} {
  let resolve;
  let reject;

  const promise = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  }) as any;

  return { promise, resolve, reject };
}

export function deepFind(data: any[], prop: string, search: (props?: any) => boolean): any {
  let result = null;
  data.forEach((entry) => {
    if (search(entry)) result = entry;
    else if (entry[`${prop}`]) {
      const deepResult = deepFind(entry[`${prop}`], prop, search);
      if (deepResult) result = deepResult;
    }
  });
  return result;
}
export function withGraphQLErrors<R, T extends Dispatch = Dispatch<any>>(fn: (dispatch: T) => any) {
  return (dispatch: T): Promise<R & { graphQLErrors?: string }> => {
    try {
      return fn(dispatch);
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };
}

export function move(array: any[], fromIndex: number, toIndex: number): any[] {
  const newArray = cloneDeep(array);
  newArray.splice(toIndex, 0, newArray.splice(fromIndex, 1)[0]);
  return newArray;
}

/**
 * @description Rounds a number to the specified decimal places.
 * @function round
 * @param {number} num - The number to be rounded.
 * @param {number} decimal - The decimal places to round to.
 * @returns {number} The rounded number.
 */
export function round(num: number, decimal: number): number {
  if (decimal < 0) return NaN;
  let stringPlaces = '1';
  for (let i = 0; i < decimal; i++) stringPlaces += '0';
  const places: number = parseInt(stringPlaces);
  return Math.round(num * places) / places;
}

/**
 * @description Checks if a number is between a minimum and a maximum margins, with the possibility of being inclusive.
 * @function between
 * @param {number} min - The minimum margin.
 * @param {number} num - The value to compare.
 * @param {number} max - The maximum margin.
 * @param {boolean} minInclusive - To check if the value is over or equal to the minimum margin.
 * @param {boolean} maxInclusive - To check if the value is under or equal to the maximum margin.
 * @returns {boolean} If the number is between the specified values.
 */
export function between(
  min: number,
  num: number,
  max: number,
  minInclusive?: boolean,
  maxInclusive?: boolean,
): boolean {
  return (minInclusive ? min <= num : min < num) && (maxInclusive ? num <= max : num < max);
}

/**
 * @description For each of the environment types, returns its capitalized version.
 * @function getCapitalized
 * @param {string} env - The environment.
 * @returns {string} The capitalized environment string.
 */
export function getCapitalized(env: string): string {
  switch (env) {
    case 'development':
      return 'Development';
    case 'test':
      return 'Test';
    case 'preproduction':
      return 'Pre-production';
    default:
      return '???';
  }
}

/**
 * @description For each of the environment types, returns its short version.
 * @function getCapitalized
 * @param {string} env - The environment.
 * @returns {string} The short environment string.
 */
export function getShort(env: string): string {
  switch (env) {
    case 'development':
      return 'DEV';
    case 'test':
      return 'TST';
    case 'preproduction':
      return 'PP';
    default:
      return '???';
  }
}

export function upper(expression: string): string {
  return expression.charAt(0).toUpperCase() + expression.substr(1);
}

export function addSpace(expression: string): string {
  return ' ' + expression + ' ';
}

export function mapGQLTypeToHTMLType(type: string): {
  HTMLType: string;
  empty: any;
} {
  if (!type) return { HTMLType: 'text', empty: '' };
  switch (type.toLowerCase()) {
    case 'number':
      return { HTMLType: 'number', empty: null };
    case 'label':
      return { HTMLType: 'label', empty: null };
    case 'string':
    default:
      return { HTMLType: 'text', empty: '' };
  }
}

export function mapHTMLTypeToGQLType(type: string): string {
  if (!type) return 'string';
  switch (type.toLowerCase()) {
    case 'number':
      return 'number';
    case 'text':
      return 'string';
    default:
      return 'string';
  }
}

function mapCode(code: string): string {
  switch (code) {
    case 'GRAPHQL_VALIDATION_FAILED':
    case 'Validation':
      return 'Validation Failed';
    default:
      return 'Error';
  }
}

export function genUniqueCopyName(original: string, names: string[]): string {
  const newName = original + ' - Copy ';
  let i = 1;

  while (names.some((n) => n === newName + i)) {
    ++i;
  }

  return newName + i;
}

export function getErrorObject(
  error: any,
  mod: string = '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  /**
   * @deprecated
   */
  _?: any,
): { message: string; stacktrace: string[] } {
  let message = '';
  let stacktrace: string[] = [];

  if (!error.networkError) {
    /* GraphQL Error */
    const mainError = error && error.graphQLErrors ? error.graphQLErrors[0] : null;
    if (!mainError) {
      message = 'Something went wrong unexpectedly. Please refresh the browser and try again.';
      return { message, stacktrace };
    }
    message = mainError.message;
    stacktrace = mainError.stacktrace;
  } else if (error.graphQLErrors.length === 0) {
    /* Network Error */
    const errors = error.networkError.result ? error.networkError.result.errors || [] : [];
    if (errors.length === 0) {
      if (error.message !== 'Unable to connect to the server.') {
        store.dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: 'The remote server is unavailable or network are having issues',
            severity: 'error',
          },
        });
      }
      throw new Error(error);
    } else {
      const mainError = errors[0];
      const errCode = mapCode(((mainError || '').extensions || '').code);
      const errMessage = mainError.message;
      message = `(${mod}) ${errCode}: ${errMessage}`;
      stacktrace = (((mainError || '').extensions || '').exception || '').stacktrace;
    }
  } else {
    message = 'Something went wrong unexpectedly. Please refresh the browser and try again.';
  }
  return { message, stacktrace };
}

export function getInputName(content: {
  field: { name: string };
  name: string;
  inputTemplate: { inputData: { name: string }; defaults: { name: string } };
  inputData: { name: string };
  defaults: { name: string };
}): string {
  const input = content.inputTemplate || content;
  if (content.field?.name) {
    return content.field.name;
  }
  if (content.name) {
    return content.name;
  } else if (input.inputData.name != '') {
    return input.inputData.name;
  } else if (input.defaults.name) {
    return input.defaults.name;
  } else {
    return '';
  }
}

export function getBadge(content: { type: string; unit: { symbol: string }; selection: { type: string } }): any[] {
  if (content.type === 'string' || (content.selection && content.selection.type === 'string')) {
    if (content.unit) return [content.unit.symbol, 'Abc'];
    else return ['Abc'];
  } else if (content.type === 'number' || (content.selection && content.selection.type === 'number')) {
    if (content.unit) return [content.unit.symbol, '123'];
    else return ['123'];
  } else {
    return [];
  }
}

export const shortFormUnit = (timeUnit: string): string => {
  switch (timeUnit) {
    case 'hours':
      return 'h';
    case 'seconds':
      return 's';
    case 'minutes':
      return 'min';
    default:
      return timeUnit;
  }
};

export const prependFunction =
  <PRE extends () => void, FN extends (...args: any[]) => any>(pre: PRE, fn: FN) =>
  (...args: Parameters<FN>): ReturnType<FN> => {
    pre();
    return fn(...args);
  };

export const taskComparator = (
  t: IssueTemplateDraftTaskTemplateGroupTaskTemplate,
  y: IssueTemplateDraftTaskTemplateGroupTaskTemplate,
): number => {
  return (
    /* t?.variant?.variantId === y?.variant?.variantId ? 
    t.variant.position - y.variant.position :  */
    t.order - y.order
  );
};

export const sortTask = (
  tasks: Partial<IssueTemplateDraftTaskTemplateGroupTaskTemplate>[],
): Partial<IssueTemplateDraftTaskTemplateGroupTaskTemplate>[] => {
  return Object.values(groupBy(tasks, (t) => t.variant.variantId))
    .flat()
    .sort(taskComparator);
};

export const mapTaskOrder = (
  tasks: IssueTemplateDraftTaskTemplateGroupTaskTemplate[],
): IssueTemplateDraftTaskTemplateGroupTaskTemplate[] => {
  let index = 0;

  return tasks.map((t, i) => ({
    ...t,
    order: !tasks.at(i)?.variant.position ? index++ : index,
  }));
};

export function getChildrenTasks(
  tasks: Partial<IssueTemplateDraftTaskTemplateGroupTaskTemplate>[],
  parentId: string,
): Partial<IssueTemplateDraftTaskTemplateGroupTaskTemplate>[] {
  return tasks.flatMap((t) =>
    t.parentsTree?.length && t.parentsTree[0] == parentId ? [t, ...getChildrenTasks(tasks, t._id)] : [],
  );
}

export interface Tree {
  [x: string]: any;
  children: Tree[];
  accounts: { _id: string; roleMappings: object[] }[];
  id: string;
  _id: string;
  name: string;
  title: string;
  folders: {
    _id: string;
  }[];
}

export const addRoleMapping = (tree: Tree[], role: { account: { _id: string } }) =>
  tree.map((node) => {
    node.accounts &&
      node.accounts.map((account) => {
        if (account._id === role.account._id) {
          account.roleMappings = [];
          account.roleMappings.push(role);
        }
      });
    if (node.children && node.children.length > 0) {
      node.children = addRoleMapping(node.children, role);
    }
    return node;
  });

type Clearance = 'Editor' | 'Viewer' | 'Executor' | 'Manager' | 'Planner' | 'Creator';

export const hasPermission = (
  myClearances: MyClearances[],
  name: string,
  clearance: Clearance | Clearance[],
): boolean =>
  myClearances.some(
    (c) =>
      c.bundle?.name === name && (isArray(clearance) ? clearance : ([clearance] as string[])).includes(c.clearance),
  );

export const isLeaf = (node: { [x: string]: number }, type: string) =>
  (node[type + 'FoldersCount'] ? node[type + 'FoldersCount'] : 0) +
    (node[type + 'sCount'] ? node[type + 'sCount'] : 0) ===
  0;

export const isModalFolderLeaf = (node: Tree, type: string) => node[type + 'FoldersCount'] === 0;

export const setChildren = (tree: Tree[], fatherNodeId: string, newNodes: Tree[] | Tree, type: string) =>
  tree.map((node) => {
    if (node._id === fatherNodeId || node.id === fatherNodeId) {
      const children = node.children?.length && !node.children[0]._id ? [node.children[0]] : [];
      //@ts-ignore
      newNodes.folders.map((newNode) => {
        children.push(Object.assign({ children: [], leaf: isLeaf(newNode, type) }, newNode));
      });
      //@ts-ignore
      node.children = [...children, ...newNodes[type + 's']];
      node.children = orderBy(
        node.children,
        [
          (o): string => {
            return o.name || o.title;
          },
        ],
        'asc',
      );
    } else if (node.children?.length > 0) {
      node.children = setChildren(node.children, fatherNodeId, newNodes, type);
    }
    return node;
  });

export const setModalFolderChildren = (tree: Tree[], fatherNodeId: string, newNodes: Tree[], type: string) =>
  tree.map((node) => {
    if (node._id === fatherNodeId || node.id === fatherNodeId) {
      const children: Tree[] = [];
      newNodes.map((newNode) => {
        children.push(Object.assign({ children: [], leaf: isModalFolderLeaf(newNode, type) }, newNode));
      });
      node.children = orderBy(children, 'name', 'asc');
    } else if (node.children.length > 0) {
      node.children = setModalFolderChildren(node.children, fatherNodeId, newNodes, type);
    }
    return node;
  });

export const addChild = (folders: Tree[], newField: { [x: string]: any }, type: string) =>
  folders.map((node) => {
    if (
      node[type + 'Templates'] &&
      node[type + 'Templates'].filter((template: { _id: string }) => template._id === newField[type + 'Template']._id)
        .length > 0
    ) {
      node[type + 'Templates'].map((tmp: { _id: string; [x: string]: any }) => {
        if (tmp._id === newField[type + 'Template']._id) {
          tmp[type + 'Data'].fields.push(newField);
        }
      });
    } else if (!node[type + 'Templates'] && node._id === newField[type + 'Template']._id) {
      node[type + 'Data'].fields.push(newField);
    } else if (node.children.length > 0) {
      addChild(node.children, newField, type);
    }
    return node;
  });

export const removeChild = (folders: Tree[], field: { [x: string]: any }, type: string) =>
  folders.map((node) => {
    if (
      node[type + 'Templates'] &&
      node[type + 'Templates'].filter((template: { _id: string }) => template._id === field[type + 'Template']._id)
        .length > 0
    ) {
      node[type + 'Templates'].map((tmp: { _id: string; [x: string]: any }) => {
        if (tmp._id === field[type + 'Template']._id) {
          tmp[type + 'Data'].fields = tmp[type + 'Data'].fields.filter((f: { _id: string }) => f._id !== field._id);
        }
      });
    } else if (!node[type + 'Templates'] && node._id === field[type + 'Template']._id) {
      node[type + 'Data'].fields = node[type + 'Data'].fields.filter((f: { _id: string }) => f._id !== field._id);
    } else if (node.children.length > 0) {
      removeChild(node.children, field, type);
    }
    return node;
  });

export const addNewChildren = (tree: Tree[], fatherNodeId: string, newNode: Tree, type: string) =>
  tree.map((node) => {
    if (node._id === fatherNodeId) {
      node[type + 'FoldersCount'] += 1;
      node.leaf = false;
      if (!node.children) node.children = [];
      node.children.push(Object.assign({ children: [], leaf: true }, newNode));
      node.children = orderBy(
        node.children,
        [
          (o): string => {
            return o.name || o.title;
          },
        ],
        'asc',
      );
    } else if (node.children && node.children.length > 0) {
      node.children = addNewChildren(node.children, fatherNodeId, newNode, type);
    }
    return node;
  });

export const deleteChildren = (tree: Tree[], fatherNodeId: string, nodeToDelete: Tree, type: string) =>
  tree.map((node) => {
    if (node._id === fatherNodeId) {
      node.children.forEach((c) => {
        if (c._id === nodeToDelete._id) {
          node[type + 'FoldersCount'] -= 1;
        }
      });
      node.children = node.children.filter((ch) => ch._id !== nodeToDelete._id);
      node.leaf = isLeaf(node, type);
    } else if (node.children && node.children.length > 0) {
      node.children = deleteChildren(node.children, fatherNodeId, nodeToDelete, type);
    }

    return node;
  });

export const addNewChildrenDefault = (
  tree: Tree[],
  fatherNodeId: string,
  newNode: Tree,
  type: string,
  fromMove = false,
) =>
  tree.map((node) => {
    if (node._id === fatherNodeId) {
      node[type + 'sCount'] += 1;
      if (fromMove && (!node.children || !node.children?.length) && !node.leaf) {
      } else {
        node.leaf = false;
        if (!node.children) node.children = [];
        node.children.push(Object.assign({ children: [], leaf: isLeaf(newNode, type) }, newNode));
        node.children = orderBy(node.children, 'name', 'asc');
      }
    } else if (node.children && node.children.length > 0) {
      node.children = addNewChildrenDefault(node.children, fatherNodeId, newNode, type, fromMove);
    }
    return node;
  });

export const deleteChildrenDefault = (tree: Tree[], fatherNodeId: string, nodeToDelete: Tree, type: string) => {
  return tree.map((node) => {
    if (node._id === fatherNodeId) {
      node[type + 'sCount'] -= 1;
      node.children = node.children?.filter((ch) => ch._id !== nodeToDelete._id);
      node.leaf = !node.children?.length;
    } else if (node.children && node.children.length > 0) {
      node.children = deleteChildrenDefault(node.children, fatherNodeId, nodeToDelete, type);
    }
    return node;
  });
};

export const updateChildren = (tree: Tree[], fatherNodeId: string, nodeUpdated: Tree) =>
  tree.map((node) => {
    if (node._id === fatherNodeId) {
      const newChildren: Tree[] = [];
      node.children.map((ch) => {
        if (ch._id === nodeUpdated._id)
          newChildren.push(Object.assign({ children: ch.children ? ch.children : [], leaf: ch.leaf }, nodeUpdated));
        else newChildren.push(ch);
      });
      node.children = orderBy(
        newChildren,
        [
          (o): string => {
            return o.name || o.title;
          },
        ],
        'asc',
      );
    } else if (node.children && node.children.length > 0) {
      node.children = updateChildren(node.children, fatherNodeId, nodeUpdated);
    }

    return node;
  });

export const addToTemplate = (tree: Tree[], fatherNodeId: string, newNode: Tree, type: string) =>
  tree.map((node) => {
    if (node._id === fatherNodeId) {
      node.leaf = false;
      node.children = [...node.children, newNode];
      node[type + 'sCount'] += 1;
      node.children = orderBy(
        node.children,
        [
          (o): string => {
            return o.name || o.title;
          },
        ],
        'asc',
      );
    } else if (node.children && node.children.length > 0) {
      addToTemplate(node.children, fatherNodeId, newNode, type);
    }
    return node;
  });

export const updateParent = (
  tree: Tree[],
  fatherNodeId: string,
  updatedNode: Tree,
  type: string,
  previousFolderId?: string,
): Array<Tree> =>
  tree?.map((node) => {
    if (previousFolderId && node._id === previousFolderId) {
      // @ts-ignore
      node.children = remove(node.children, (n) => n._id !== updatedNode._id);
      node[type + 'sCount'] -= 1;
      node.leaf = isLeaf(node, type);
    }
    if (node._id === fatherNodeId) {
      const index = !node.children ? -1 : node.children.map((n) => n._id).indexOf(updatedNode._id);
      if (index >= 0) {
        node.children = !node.children
          ? []
          : node.children.map((it) => (it._id === updatedNode._id ? updatedNode : it));
      } else {
        node.children = concat(node.children || [], updatedNode);
        node[type + 'sCount'] += 1;
      }
      node.leaf = isLeaf(node, type);
      node.children = orderBy(
        node.children,
        [
          (o): string => {
            return o.name || o.title;
          },
        ],
        'asc',
      );
    }
    if (node.children && node.children.length > 0) {
      node.children = updateParent(node.children, fatherNodeId, updatedNode, type, previousFolderId);
    }
    return node;
  });

export const deleteFromParent = (tree: Tree[], fatherNodeId: string, nodeToDelete: Tree, type: string) =>
  tree.map((node) => {
    if (node._id === fatherNodeId) {
      node.children = !node.children ? [] : node.children.filter((u) => u._id !== nodeToDelete._id);
      node[type + 'sCount'] -= 1;
      node.leaf = isLeaf(node, type);
    } else if (node.children && node.children.length > 0) {
      node.children = deleteFromParent(node.children, fatherNodeId, nodeToDelete, type);
    }
    return node;
  });

// escapeString adds escape characters to a string
export function escapeString(data: string): string {
  return JSON.stringify(data).slice(1, -1);
}

export const duplicateNewName = (existentNames: string[], name: string): string => {
  let newName = name + ' - Copy';
  let i = 1;
  while (existentNames.filter((name) => name === newName).length) {
    newName = name + ` - Copy (${i})`;
    i++;
  }
  return newName;
};

export const convert = (bytes: number): string => {
  if (bytes < 1024) return bytes + ' Bytes';
  else if (bytes < 1048576) return (bytes / 1024).toFixed(2) + ' KB';
  else if (bytes < 1073741824) return (bytes / 1048576).toFixed(2) + ' MB';
  else return (bytes / 1073741824).toFixed(2) + ' GB';
};

export const convertBytesToString = (bytes: number): string => {
  if (bytes < 1024) {
    return bytes + ' bytes';
  } else if (bytes < 1024 * 1024) {
    return Math.round(bytes / 1024) + ' KB';
  } else if (bytes < 1024 * 1024 * 1024) {
    return Math.round(bytes / (1024 * 1024)) + ' MB';
  } else {
    return Math.round(bytes / (1024 * 1024 * 1024)) + ' GB';
  }
};

// Get property information from a collection of objects: GetFields(arg0 , arg1)
// arg0 ----> you need to pass the collection of objects
// arg1 ----> and the name of the property you want to get information.
export const getFields = (input, field: string): any[] => {
  let output = [];
  for (let i = 0; i < input.length; ++i) output.push(input[i][field]);
  return output;
};

export const immutableMove = (arr: string[], oldIndex: number, newIndex: number): string[] => {
  while (oldIndex < 0) {
    oldIndex += arr.length;
  }
  while (newIndex < 0) {
    newIndex += arr.length;
  }
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length;
    while (k-- + 1) {
      //@ts-ignore
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

// export const immutableMove = <T>(array: T[], fromIndex: number, toIndex: number): T[] => {
//   const itemToMove = array[fromIndex];
//   const newArray = [...array];

//   // Remove the item to move from its original position
//   newArray.splice(fromIndex, 1);

//   // Insert the item at the new position
//   newArray.splice(toIndex, 0, itemToMove);

//   return newArray;
// };

// addLeadingSlash adds a leading slash
// It checks if it already has a prefixed slash
export function addLeadingSlash(string = ''): string {
  return string.length > 0 && string.startsWith('/') ? string : '/' + string;
}

// checkAbsoluteUrl checks incoming string to detect if it is a relative url or absolute url
export function checkAbsoluteUrl(string = ''): boolean {
  const isAbsoluteUrl = new RegExp('^([a-z0-9]*:|.{0})//.*$');

  if (isAbsoluteUrl.exec(string)) return true;

  return false;
}

export function timeDiffLowestCommonUnit(a: moment.Moment, b: moment.Moment): [number, string] {
  const diff = Math.abs(a.diff(b, 'seconds'));

  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 31;
  const year = month * 12;

  let num = diff;
  let unit = 'second';

  switch (true) {
    case diff > year:
      num = diff / year;
      unit = 'year';
      break;
    case diff > month:
      num = diff / month;
      unit = 'month';
      break;
    case diff > week:
      num = diff / week;
      unit = 'week';
      break;
    case diff > day:
      num = diff / day;
      unit = 'day';
      break;
    case diff > hour:
      num = diff / hour;
      unit = 'hour';
      break;
    case diff > minute:
      num = diff / minute;
      unit = 'minute';
  }

  return [Math.floor(num), unit];
}

/**
 * Gets the site URL from the window
 * @description extracts the site url from the window.location
 */
export function getSiteURLFromWindowObject(obj: Window): string {
  let siteURL = '';
  if (obj.location.origin) {
    siteURL = obj.location.origin;
  } else {
    const locationPort = obj.location.port ? `:${obj.location.port}` : '';
    siteURL = `${obj.location.protocol}//${obj.location.hostname}${locationPort}`;
  }

  if (siteURL.endsWith('/')) {
    siteURL = siteURL.substring(0, siteURL.length - 1);
  }

  if (siteURL.endsWith('/')) {
    siteURL = siteURL.substring(0, siteURL.length - 1);
  }

  return siteURL;
}

/**
 * Gets the siteURL
 * @description this is a wrapper of getSiteURLFromWindowObject
 */
export function getSiteURL(): string {
  return getSiteURLFromWindowObject(window);
}

/**
 * Gets the URL scheme
 * @description reads the url and extracts it's scheme
 */
export function getScheme(url: string): string | null {
  const match = /([a-z0-9+.-]+):/i.exec(url);

  return match && match[1];
}

export function getWebsocketBasePath(url: string): string {
  const connUrl = new URL(getSiteURL());

  // replace the protocol with a websocket one
  if (connUrl.protocol === 'https:') {
    connUrl.protocol = 'wss:';
  } else {
    connUrl.protocol = 'ws:';
  }

  let connUrlStr = connUrl.toString();

  // Strip any trailing slash before appending the pathname below.
  if (connUrlStr.length > 0 && connUrlStr.endsWith('/')) {
    connUrlStr = connUrlStr.substring(0, connUrlStr.length - 1);
  }

  if (!url) {
    return connUrlStr;
  }

  if (url === '/') {
    return '';
  }

  if (checkAbsoluteUrl(url)) {
    return url;
  }

  return connUrlStr + addLeadingSlash(url);
}

export function getAPIBasePath(url: string): string {
  if (!url || url === '/') {
    return '';
  }

  return url;
}

export const setUrl = (key: string, value: string, pathname: string, search: ParsedQuery<string>) => {
  const keys = Object.keys(search);
  return (
    !keys.length
      ? `${pathname}${'?' + key + '=' + value}`
      : `${pathname}?${keys.map((k, i) =>
          k !== key
            ? k + '=' + search[`${k}`] + (keys.length === i + 1 ? '' : '&')
            : key + '=' + value + (keys.length === i + 1 ? '' : '&'),
        )}
              ${!keys.includes(key) ? '&' + key + '=' + value : ''}`
  )
    .replace(/\s/g, '')
    .replace(/,/g, '');
};

export const differResponsibles = (prev, after): boolean => {
  if (!prev && !after) {
    return false;
  }

  if ((!prev && after) || (prev && !after)) {
    return true;
  }

  if (prev.accounts?.map((acc) => acc._id).join(' ') !== after.accounts?.map((acc) => acc._id).join(' ')) {
    return true;
  }

  if (prev.labelValues?.map((l) => l._id).join(' ') !== after.labelValues?.map((l) => l._id).join(' ')) {
    return true;
  }

  return false;
};

export function isRequiredInputFilled<T extends Partial<Input>>(input: T): boolean {
  if (!input.required) {
    return true;
  }

  const value = input[input.type].values?.at(0);

  if (value == null || value == undefined) {
    return false;
  }

  if (input.type === 'table') {
    return input['table']?.values.every((v) => v.rows[v.type].every((r) => r != null && r != undefined));
  }

  return true;
}

//TODO: Find a way to transition this entire function into a properly typed input.
// This method seems to be pivotal to the workings of the entire platform, albeit it is severly lacking
// self documenting code (it is hard to read) and it is dangerously untyped...
// Since attemptying to type this resulting in massive cascade errors throughout the code
// the best approach is to attempt a transitional refactor where the individual small chunks of code
// use an alternative approach to fetching and processing data from the backend API.
// This comment also applies to 'formatInputs' method
// -Samuel Martins
export const formatExecInps = (inputsOld, options?: { keepOrder: boolean }) => {
  const inputs = cloneDeep(inputsOld);

  inputs?.map((inp) => {
    if (!inp[inp.type]) {
      inp[inp.type] = { values: [] };
    }

    if (inp[inp.type].multiple && inp.type === 'string' && !inp[inp.type].setValueWithQRCode) {
      inp.multiple = inp[inp.type];
      inp.type = 'multiple';
    } else if (!inp[inp.type]?.multiple && inp[inp.type]?.options?.length) {
      inp.selection = inp[inp.type];
      inp.type = 'selection';
    } else if (inp.type === 'time') {
      inp.type = 'datetime';
      inp.subType = 'time';
      inp.datetime = inp.time;
    } else if (inp.type === 'date') {
      inp.type = 'datetime';
      inp.subType = 'date';
      inp.datetime = inp.date;
    } else if (inp.type === 'datetime') {
      inp.subType = 'datetime';
    } else if (inp.type === 'string' && inp[inp.type]?.setValueWithQRCode) {
      inp.qrCode = inp.string;
      inp.type = 'qrCode';
      delete inp.string;
    } else if (inp.type === 'number' && inp[inp.type]?.unit?._id) {
      inp[inp.type].unit = inp[inp.type].unit._id;
    }

    if (!inp._id) {
      inp._id = `id-${inp.order}`;
    }
  });
  let index = 0;
  const test = orderBy(
    inputs?.map((inp) => {
      if (!inp[inp.type]) {
        return inp;
      }

      return {
        ...inp,
        [inp.type]: {
          ...inp[inp.type],
          options: inp[inp.type]?.options
            ? inp[inp.type].options.map((opt, i) => {
                if (
                  inp[inp.type].onResponse?.filter(
                    (onResp: NexusGenFieldTypes['InputNumberOnResponse']) =>
                      onResp.ifValues[0] === opt &&
                      (onResp.inputs?.length ||
                        onResp.actionRequired ||
                        onResp.createAlert ||
                        onResp.condition ||
                        onResp.createTasks ||
                        onResp.finishIssue),
                  ).length
                )
                  index = index + 1;

                return {
                  id: i,
                  label: opt,
                  order: i,
                  color: inp[inp.type].onResponse?.filter(
                    (onResp: NexusGenFieldTypes['InputNumberOnResponse']) =>
                      onResp.ifValues[0] === opt &&
                      (onResp.inputs?.length ||
                        onResp.actionRequired ||
                        onResp.createAlert ||
                        onResp.condition ||
                        onResp.createTasks ||
                        onResp.finishIssue),
                  ).length
                    ? colors[index]
                    : undefined,
                  _id: `id-${i}`,
                };
              })
            : [],
          optionsWithScore: inp[inp.type].optionsWithScore
            ? inp[inp.type].optionsWithScore.map((opt: { option: string; score: number }, i) => {
                return {
                  option: opt.option,
                  score: opt.score,
                  _id: `id-${i}`,
                };
              })
            : undefined,
          onResponse: inp[inp.type].onResponse?.map((onResp) => ({
            ...onResp,
            ifValues:
              inp.type === 'number'
                ? onResp.ifValues
                : onResp.ifValues.map((opt, i) => ({
                    id: i,
                    label: opt,
                    order: i,
                    _id: `id-${i}`,
                  })),
            inputs: onResp.inputs?.length ? formatExecInps(onResp.inputs) : [],
          })),
        },
      };
    }),
    'order',
    'asc',
  ).map((execInp, i) => ({ ...execInp, order: options?.keepOrder ? execInp.order : i }));

  return test.map((inp) => {
    if (!inp[inp.type]) {
      return inp;
    }

    return {
      ...inp,
      [inp.type]: {
        ...inp[inp.type],
        onResponse: inp[inp.type]?.onResponse?.map(
          (onResp: { ifValues: { label: string }[]; actionDraft: { _id: string } }) => ({
            ...onResp,
            ifValues:
              inp.type === 'number'
                ? onResp.ifValues
                : onResp.ifValues.map((opt: { label: string }) => {
                    const opts: { color: string; label: string } = inp[inp.type].options.find(
                      (o: { label: string }) => o.label === opt.label,
                    );
                    return {
                      ...opt,
                      color: opts ? opts.color : undefined,
                    };
                  }),
          }),
        ),
      },
    };
  });
};

export const getChildrenDefault = (tree: Tree[], nodeId: string) => {
  for (const node of tree) {
    if (node._id === nodeId) return node;
    if (node.children && node.children.length > 0) {
      const child = getChildrenDefault(node.children, nodeId);
      if (child) return child;
    }
  }
};

export const printDurationMinutes = (minutes: number, hideSeconds?: boolean): string =>
  `${padStart(Math.floor(minutes / 60).toString(), 2, '0')}:${padStart((minutes % 60).toString(), 2, '0')}${
    !hideSeconds ? ':00' : ''
  }`;

export const downloadTemplateXLSX = (headers: string[]) => {
  const worksheet = XLSX.utils.json_to_sheet([], { header: headers });
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, `TemplateFile.xlsx`);
  XLSX.writeFile(workbook, `TemplateFile.xlsx`);
};

export const validateEmail = (email: string) => {
  const emailValidationRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailValidationRegex.test(email);
};

export const removeEmpty = (obj: object) => {
  const finalObj = {};
  if (obj)
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        const nestedObj = removeEmpty(obj[key]);
        if (Object.keys(nestedObj).length) {
          finalObj[key] = nestedObj;
        }
      } else if (Array.isArray(obj[key])) {
        if (obj[key].length) {
          obj[key].forEach((x) => {
            const nestedObj = removeEmpty(x);
            if (Object.keys(nestedObj).length) {
              finalObj[key] = finalObj[key] ? [...finalObj[key], nestedObj] : [nestedObj];
            }
          });
        }
      } else if (obj[key] !== '' && obj[key] !== undefined && obj[key] !== null) {
        finalObj[key] = obj[key];
      }
    });
  return finalObj;
};

export const formatHexString = (hexString: string): string => {
  return hexString.replace(/.{2}(?!$)/g, (match) => `${match}:`);
};

export const typedConnect = connect as Connect<GlarState>;

export const getColumnSize = (tabType: Tab, name: string) => {
  return tabType?.columns?.find((col) => col.name === name)?.size || 150;
};
export const useTypedSelector = useSelector as UseSelector<GlarState>;

export const updateColsVisibilityOrder = async (
  cols: string[],
  tab: Tab,
  updateTab: (where: TabWhereUniqueInput, data: TabUpdateInput) => Promise<any>,
) => {
  const updatedCols = cols.map((newCol) => {
    const colsIndex = tab.columns.findIndex((col) => newCol === col.name);
    return colsIndex !== -1 ? tab.columns[colsIndex] : { name: newCol, order: cols.length - 1, size: 150 };
  });
  await updateTab({ _id: tab._id }, { columns: updatedCols });
};

export const updateColSize = async (
  col: { newSize: number; name: string },
  tab: Tab,
  updateTab: (where: TabWhereUniqueInput, data: TabUpdateInput) => Promise<any>,
) => {
  const colToUpdate = tab.columns.find((colTab) => colTab.name === col.name);
  await updateTab(
    { _id: tab._id },
    {
      columns: tab.columns.map((colTab) =>
        colToUpdate.name === colTab.name ? { ...colTab, size: col.newSize } : colTab,
      ),
    },
  );
};
