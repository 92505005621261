import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { styles } from 'src/base/site-dropdown/styles/site-dropdown';
import { accountSetSite } from 'src/modules/account/account.redux';
import { MenuItem, ListItemIcon } from '@mui/material';
import { useState, useEffect } from 'react';
import { PlaceOutlined, Build } from '@mui/icons-material';
import CustomDropdown from 'src/utils/components/custom-dropdown/index';
import i18n from 'src/utils/translations/i18n';
import { NexusGenFieldTypes } from '../../../../server/src/types';
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withTheme } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

interface MapStateToProps {
  accountReducer: {
    currentSite: NexusGenFieldTypes['Site'];
  };
  loginReducer: {
    loggedUser: NexusGenFieldTypes['Account'];
  };
}

const useStyles = makeStyles(styles);

export const render = {
  view: {
    default: withTheme(
      //@ts-ignore
      connect(
        (state: MapStateToProps) => ({
          currentSite: state.accountReducer.currentSite,
          loggedUser: state.loginReducer.loggedUser,
        }),
        (dispatch) =>
          bindActionCreators(
            {
              accountSetSite,
            },
            dispatch,
          ),
        //To fix
      )((props: any /* SiteDropdownProps */) => {
        const classes: ClassNameMap<string> = useStyles();
        const [currentSiteName, setCurrentSiteName] = useState<string | null>('-');
        const navigate = useNavigate();

        useEffect(() => {
          if (localStorage.getItem('current_site_name')) {
            setCurrentSiteName(localStorage.getItem('current_site_name'));
          }
        }, []);

        useEffect(() => {
          if (localStorage.getItem('current_site_name')) {
            setCurrentSiteName(localStorage.getItem('current_site_name'));
          } else if (props.currentSite) {
            setCurrentSiteName(props.currentSite.name);
          }
        }, [JSON.stringify(props.currentSite)]);

        return (
          <CustomDropdown
            id={'dropdownChangeSite'}
            button={
              <div className={'site-dropdown'} style={{ display: 'flex', textTransform: 'none' }}>
                <PlaceOutlined classes={{ root: classes.iconSite }} />
                <b className={classes.menuTextSize}>{currentSiteName}</b>
              </div>
            }
          >
            <div className={classes.content}>
              {props.loggedUser && props.loggedUser.authorizedSites && props.loggedUser.authorizedSites.length > 1 ? (
                <MenuItem
                  id={'menuItemChangeSite'}
                  className={classes.menuTextSize}
                  onClick={(): void => {
                    void props.accountSetSite(null, true).then(() => {
                      localStorage.removeItem('current_site_id');
                      localStorage.removeItem('current_site_name');
                      navigate('/select-site');
                    });
                  }}
                >
                  <ListItemIcon>
                    <Build classes={{ root: classes.iconSize }} />
                  </ListItemIcon>
                  {i18n.t('changeSite')}
                </MenuItem>
              ) : (
                <MenuItem id={'noSites'} data-testid={'noSites'}>
                  {i18n.t('noSites')}
                </MenuItem>
              )}
            </div>
          </CustomDropdown>
        );
      }),
    ),
  },
};

export default render;
