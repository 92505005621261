import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    list: {
      paddingTop: 0,
      maxHeight: '100%',
      overflow: 'auto',
    },
    paper: {
      height: '100%',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      zIndex: 60,
      width: '60px',
      webkitTransition: 'all .5s cubic-bezier(.685,.0473,.346,1)',
      transition: 'all .5s cubic-bezier(.685,.0473,.346,1)',
      paddingTop: '55px',
      position: 'fixed',
      backgroundColor: theme.palette.background.default,
      borderRight: `1px solid ${theme.palette.text.disabled}`,
    },
    active: {
      fill: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
    menu: {
      backgroundColor: theme.palette.background.background0,
    },
    arrowButton: {
      color: `${theme.palette.mode === 'light' ? theme.palette.secondary.main : theme.palette.common.white} !important`,
      position: 'absolute',
      bottom: '3px',
      right: '5px',
      '& .MuiButton-label': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
      },
    },
  });
