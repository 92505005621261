import { cloneDeep, concat, filter, orderBy, uniqBy } from 'lodash';
import { APOLLO_CLIENT } from 'config/apollo.config';
import { Action, State } from 'src/interfaces/reducers';
import i18n from 'src/utils/translations/i18n';
import {
  addNewChildren,
  addToTemplate,
  deleteChildren,
  deleteFromParent,
  getErrorObject,
  isLeaf,
  isModalFolderLeaf,
  setChildren,
  setModalFolderChildren,
  updateChildren,
  updateParent,
  addNewChildrenDefault,
  deleteChildrenDefault,
  getChildrenDefault,
  addSpace,
} from 'src/utils/funcs';
import * as queries from 'src/modules/site/site.queries';
import { Q_GET_DEVICES } from 'src/modules/device/device.queries';
import { NexusGenEnums, NexusGenFieldTypes, NexusGenInputs } from '../../../../server/src/types';
import { ApolloQueryResult } from '@apollo/client';
import { Dispatch } from 'redux';
import {
  CREATE_ELEMENT,
  deleteChildrenElement,
  DELETE_ELEMENT,
  setLoadMore,
  MOVE_ELEMENTS,
} from 'src/modules/element/element.redux';
import { GraphQLErrors } from '@apollo/client/errors';
import {
  ElementWhereInput,
  SiteTemplateUpdateInput,
  SiteTemplateWhereUniqueInput,
  SiteWhereInput,
} from 'src/gql/graphql';

export const GET_SITES = 'GET_SITES';
export const GET_SITES_ROOT = 'GET_SITES_ROOT';
export const GET_SITES_SEARCH = 'GET_SITES_SEARCH';
export const GET_SITES_BY_PARENT = 'GET_SITES_BY_PARENT';
export const GET_SITES_BY_FATHER_ID = 'GET_SITES_BY_FATHER_ID';
export const CREATE_SITE = 'CREATE_SITE';
export const UPDATE_SITE = 'UPDATE_SITE';
export const DELETE_SITE = 'DELETE_SITE';
export const CREATE_SITE_TEMPLATE = 'CREATE_SITE_TEMPLATE';
export const DELETE_SITE_TEMPLATE = 'DELETE_SITE_TEMPLATE';
export const ARCHIVE_SITE_TEMPLATE = 'ARCHIVE_SITE_TEMPLATE';
export const UPDATE_SITE_TEMPLATE = 'UPDATE_SITE_TEMPLATE';
export const MODAL_SITE_TEMPLATE_FOLDERS = 'MODAL_SITE_TEMPLATE_FOLDERS';
export const GET_SITE_TEMPLATE_FOLDERS = 'GET_SITE_TEMPLATE_FOLDERS';
export const CREATE_SITE_TEMPLATE_FOLDER = 'CREATE_SITE_TEMPLATE_FOLDER';
export const DELETE_SITE_TEMPLATE_FOLDER = 'DELETE_SITE_TEMPLATE_FOLDER';
export const UPDATE_SITE_TEMPLATE_FOLDER = 'UPDATE_SITE_TEMPLATE_FOLDER';
export const SEARCH_SITE_TEMPLATE_FOLDERS = 'SEARCH_SITE_TEMPLATE_FOLDERS';
export const ERROR = 'ERROR';
export const SHOW_SITE_SPINNER_SEARCH = 'SHOW_SITE_SPINNER_SEARCH';
export const CHANGE_SEARCH_INPUT_VALUE = 'CHANGE_SEARCH_INPUT_VALUE';
export const UPDATE_SITE_PARENT = 'UPDATE_SITE_PARENT';
export const GET_ELEMENT_BY_FATHER_ID = 'GET_ELEMENT_BY_FATHER_ID';
export const UPDATE_ELEMENT = 'UPDATE_ELEMENT';
export const MOVE_SITES = 'MOVE_SITES';

const initialState: State = {
  sites: [],
  searchResults: [],
  allSites: [],
  siteNames: [],
  fields: [],
  folders: [],
  modalResults: [],
  siteTemplates: [],
  sitesTree: [],
  sitesSearchResults: [],
  searchInputValue: '',
  searchSitesShowSpinner: false,
  currentSite: null,
  count: '',
  error: false,
};

export const addMultipleChildren = (tree, fatherNodeId, newNodes) =>
  tree.map((node) => {
    if (node.id === fatherNodeId || node._id === fatherNodeId) {
      newNodes.map((newNode) => {
        node.children.push(newNode);
      });
      node.children = orderBy(uniqBy(node.children, '_id'), 'name', 'asc');
    } else if (node.children.length > 0) {
      node.children = addMultipleChildren(node.children, fatherNodeId, newNodes);
    }
    return node;
  });

export const setChildrenEl = (tree, fatherNodeId, newNodes, type): object[] =>
  tree.map((node) => {
    if (node._id === fatherNodeId || node.id === fatherNodeId) {
      const children: any = [];
      newNodes.map((newNode) => {
        children.push(Object.assign({ children: [], leaf: isLeaf(newNode, 'issueTemplate') }, newNode));
      });
      if (node.children) node.children = orderBy(uniqBy([...node.children, ...children], '_id'), 'name', 'asc');
    } else if (node.children?.length > 0) {
      node.children = setChildrenEl(node.children, fatherNodeId, newNodes, type);
    }
    return node;
  });

/**
 * @function SitesReducer
 * @description Manages the `sites` state of the app. Can, in some cases, keep an error value.
 * @param {object} state - The current state of the reducer (alternatively, the initial).
 * @param {object} action - Action to be reduced.
 * @returns {object} - The new sites state.
 */

export default (state: State = initialState, action: Action): State => {
  let folders: any = [];
  let sitesAux: any = [];
  let sites: any[] = [];
  let allSites: any[] = [];
  let newChildren: any = [];
  let elementsAux: any = [];
  switch (action.type) {
    case CHANGE_SEARCH_INPUT_VALUE:
      return { ...state, searchInputValue: action.payload.searchInputValue };
    case SHOW_SITE_SPINNER_SEARCH:
      return { ...state, searchSitesShowSpinner: true, error: false };
    case GET_ELEMENT_BY_FATHER_ID:
      elementsAux = [];
      newChildren = [];
      action.payload.sites.forEach((site) => {
        newChildren.push({
          ...site,
          id: site._id,
          children: [],
          leaf: !site.hasChildren && !site.hasElements,
          elementsCount: site.hasElements ? 1 : 0,
        });
      });
      action.payload.elements.forEach((element) => {
        const { _id, hasChildren } = element;
        newChildren.push({
          ...element,
          id: _id,
          children: [],
          leaf: !hasChildren,
        });
      });
      newChildren = orderBy(newChildren, 'name', 'asc');
      if (action.payload.more === true && action.payload.loadMore === true) {
        elementsAux = addMultipleChildren(
          cloneDeep(state).sitesTree,
          action.payload?.parentItem?.id || action.payload?.parentItem?._id,
          newChildren,
        );
      } else {
        elementsAux = setChildrenEl(
          cloneDeep(state).sitesTree,
          action.payload?.parentItem?.id || action.payload?.parentItem?._id,
          newChildren,
          'children',
        );
      }

      elementsAux = setLoadMore(elementsAux, action.payload?.parentItem?.id, action.payload.loadMore);

      return { ...state, sitesTree: elementsAux, error: false };
    case GET_SITES:
      action.payload.sites.forEach(async (element) => {
        sites.push({
          ...element,
          id: element._id,
          children: [],
          leaf: !element.hasChildren && !element.hasElements,
          elementsCount: element.elementsCount + element.sitesCount,
          sitesCount: element.sitesCount,
        });
      });
      return action.payload.modal
        ? { ...state, modalSites: sites, error: false }
        : { ...state, sites: sites, error: false };
    case GET_SITES_ROOT:
      sites = [];
      action.payload.sites.map(
        (site: NexusGenFieldTypes['LeanSite'] & { elementsCount: number; sitesCount: number }) => {
          if (!site.coordinates) site.coordinates = [0, 0];
          sites.push({
            ...site,
            children: [],
            leaf: !site.hasChildren && !site.hasElements,
            elementsCount: site.elementsCount + site.sitesCount,
            sitesCount: site.sitesCount,
          });
        },
      );
      return { ...state, sitesTree: sites, error: false };
    case GET_SITES_SEARCH:
      sites = [];
      action.payload.sites.map((site: NexusGenFieldTypes['Site']) => {
        if (!site.coordinates) site.coordinates = [0, 0];
        sites.push(Object.assign({ children: [], leaf: site.sitesCount === 0 }, site));
      });
      return {
        ...state,
        sitesSearchResults: sites,
        searchSitesShowSpinner: false,
        error: false,
      };
    case GET_SITES_BY_PARENT:
      sites = [];
      if (action.payload.sites.length > 0) {
        const sitesAux: any = [];
        action.payload.sites.map((site: NexusGenFieldTypes['Site']) => {
          if (!site.coordinates) site.coordinates = [0, 0];
          sitesAux.push(Object.assign({ children: [], leaf: site.sitesCount === 0 }, site));
        });
        sites = setChildren(cloneDeep(state).sitesTree, action.payload.sites[0].parent._id, sitesAux, 'siteTemplate');
      } else {
        sites = state.sitesTree;
      }
      return { ...state, sitesTree: sites, error: false };
    case GET_SITES_BY_FATHER_ID:
      if (action.payload.sites.length > 0) {
        const children: any = [];
        action.payload.sites.forEach(
          (
            element: NexusGenFieldTypes['LeanSite'] & {
              parent: string;
              id: string;
              _id: string;
              hasChildren: boolean;
            },
          ) => {
            const { _id, hasChildren } = element;
            children.push({
              ...element,
              id: _id,
              leaf: !hasChildren,
            });
          },
        );
        const seeChildren = (
          sites: NexusGenFieldTypes['Site'][],
          children: NexusGenFieldTypes['Site'][] & { parentId: string }[],
        ) => {
          const c: any[] = [];
          sites.map((prof: any) => {
            if (prof._id === children[0].parentId) {
              if (prof.children?.length && !prof.children[0]._id) {
                c.push({
                  ...prof,
                  children: orderBy([...children, prof.children[0]], 'name', 'asc'),
                });
              } else {
                const aux = prof.children ? [...prof.children] : [];
                c.push({ ...prof, children: orderBy([...aux, ...children], 'name', 'asc') });
              }
            } else if (prof.children && prof.children.length > 0) {
              c.push({
                ...prof,
                children: seeChildren(prof.children, children),
              });
            } else c.push(prof);
          });
          return c;
        };
        sitesAux = setLoadMore(
          seeChildren(cloneDeep(state).sitesTree, children),
          action.payload.parentId,
          action.payload.loadMore,
        );
      } else sitesAux = state.sitesTree;

      return { ...state, sitesTree: sitesAux, error: false };
    case CREATE_SITE:
      allSites = state.sites;
      allSites.push(action.payload.site);
      sites = [];
      if (action.payload.site.parent !== null) {
        sites = addNewChildrenDefault(
          cloneDeep(state).sitesTree,
          action.payload.site.parent._id || action.payload.site.parentId,
          action.payload.site,
          'site',
        );
      } else {
        sites = state.sitesTree;
        sites.push(Object.assign({ children: [], leaf: !action.payload.site.hasChildren }, action.payload.site));
      }
      return { ...state, sites: allSites, sitesTree: sites, error: false };
    case UPDATE_SITE:
      sites = [];
      if (!!action.payload.site.parent || !!action.payload.site.parentId) {
        sites = updateChildren(
          cloneDeep(state).sitesTree,
          action.payload.site.parent?._id || action.payload.site.parentId,
          action.payload.site,
        );
      } else {
        state.sitesTree.map(
          (
            site: NexusGenFieldTypes['Site'] & {
              children: NexusGenFieldTypes['Site'][];
            },
          ) => {
            if (site._id === action.payload.site._id) {
              sites.push(
                Object.assign(
                  {
                    children: site.children,
                    leaf: action.payload.site.sitesCount === 0,
                  },
                  action.payload.site,
                ),
              );
            } else {
              sites.push(site);
            }
          },
        );
      }

      allSites = [];

      state.sites.map((s: NexusGenFieldTypes['Site'] & { id: string }) => {
        if (s.id !== action.payload.site._id) {
          allSites.push(s);
        } else {
          allSites.push({
            id: action.payload.site._id,
            name: action.payload.site.name,
            description: action.payload.site.description,
            coordinates: action.payload.site.coordinates,
            fields: action.payload.site.fields,
            leaf: action.payload.site.sitesCount === 0,
            labelValues: action.payload.site.labelValues,
            devices: action.payload.site.devices,
          });
        }
      });

      return {
        ...state,
        sitesTree: sites,
        sites: allSites,
        error: false,
        sitesSearchResults: state.sitesSearchResults.map((s: NexusGenFieldTypes['Site']) =>
          s._id === action.payload.site._id ? action.payload.site : s,
        ),
      };
    case UPDATE_SITE_PARENT:
      let sitesT = [];
      if (!!action.payload.oldParentId) {
        sitesT = deleteChildrenDefault(
          cloneDeep(state).sitesTree,
          action.payload.oldParentId,
          {
            ...action.payload.oldSite,
            parent: { _id: action.payload.oldParentId },
            _id: action.payload.oldSite.id,
            __typename: 'LeanSite',
          },
          'site',
        );
      } else {
        sitesT = state.sitesTree.filter((st: NexusGenFieldTypes['Site']) => st._id !== action.payload.oldSite.id);
      }
      allSites = state.sites.filter((s) => s._id !== action.payload.oldSite._id);
      allSites.push(action.payload.site);
      sites = [];
      if (action.payload.site.parent !== null) {
        action.payload.site.sitesCount += action.payload.site.elementsCount;
        sites = addNewChildrenDefault(
          sitesT,
          action.payload.site.parent._id || action.payload.site.parentId,
          { ...action.payload.site, __typename: 'LeanSite' },
          'site',
        );
      } else {
        sites = sitesT;
        sites.push(Object.assign({ children: [], leaf: !action.payload.site.hasChildren }, action.payload.site));
      }
      return { ...state, sites: allSites, sitesTree: sites, error: false };
    case DELETE_SITE:
      sites = [];

      if (action.payload.site.parent !== null) {
        sites = deleteChildrenDefault(
          cloneDeep(state).sitesTree,
          action.payload.site.parent._id,
          action.payload.site,
          'site',
        );
      } else {
        sites = state.sitesTree.filter((st: NexusGenFieldTypes['Site']) => st._id !== action.payload.site._id);
      }

      return {
        ...state,
        sitesTree: sites,
        error: false,
        sitesSearchResults: state.sitesSearchResults.filter(
          (s: NexusGenFieldTypes['Site']) => s._id !== action.payload.site._id,
        ),
      };
    case DELETE_ELEMENT:
      elementsAux = deleteChildrenElement(
        cloneDeep(state).sitesTree,
        action.payload.element.parent ? action.payload.element.parent._id : action.payload.element.site._id,
        { ...action.payload.element, id: action.payload.element._id },
      );
      return {
        ...state,
        sitesTree: elementsAux,
        error: false,
        sitesSearchResults: state.sitesSearchResults.filter((s) => s._id !== action.payload.element._id),
      };
    case CREATE_ELEMENT:
      const { parent } = action.payload.element;
      const el = {
        ...action.payload.element,
        parentId: parent ? parent._id : null,
        leaf: isLeaf(action.payload.element, 'element'),
        id: action.payload.element._id,
        profile: {},
      };
      elementsAux = addNewChildrenDefault(
        cloneDeep(state).sitesTree,
        action.payload.fatherNodeId ? action.payload.fatherNodeId : el.parentId,
        el,
        'element',
      );
      return { ...state, sitesTree: elementsAux, error: false };
    case UPDATE_ELEMENT:
      elementsAux = [];
      if (action.payload.change) {
        let children = null;
        const getChildrenElement = (tree, elementId): object[] =>
          tree.map((node) => {
            if (node._id === elementId || node.id === elementId) {
              children = node.children;
            } else if (node.children?.length > 0) {
              getChildrenElement(node.children, elementId);
            }
          });
        getChildrenElement(cloneDeep(state).sitesTree, action.payload.element._id);
        elementsAux = deleteChildrenElement(JSON.parse(JSON.stringify(state.sitesTree)), action.payload.change, {
          ...action.payload.element,
          id: action.payload.element._id,
        });
        const { parent } = action.payload.element;
        const el = {
          ...action.payload.element,
          parentId: parent ? parent._id : null,
          leaf: action.payload.element.elementsCount === 0,
          id: action.payload.element._id,
          children: cloneDeep(children),
        };
        elementsAux = addNewChildrenDefault(
          elementsAux,
          action.payload.element.parent ? action.payload.element.parent._id : action.payload.element.site._id,
          el,
          'element',
          action.payload.change,
        );
      } else {
        const elem = {
          ...action.payload.element,
          id: action.payload.element._id,
        };
        elementsAux = updateChildren(
          cloneDeep(state).sitesTree,
          elem.parentId ? elem.parentId : elem.parent ? elem.parent._id : elem.site._id,
          elem,
        );
      }
      return {
        ...state,
        sitesTree: elementsAux,
      };
    case CREATE_SITE_TEMPLATE:
      folders = addToTemplate(
        cloneDeep(state).folders,
        action.payload.siteTemplate.folder._id,
        action.payload.siteTemplate,
        'siteTemplate',
      );
      return {
        ...state,
        siteTemplates: concat(state.siteTemplates, action.payload.siteTemplate),
        folders,
      };
    case DELETE_SITE_TEMPLATE:
      folders = deleteFromParent(cloneDeep(state).folders, action.payload.folder._id, action.payload, 'siteTemplate');
      return {
        ...state,
        siteTemplate: filter(state.siteTemplates, (u) => u._id !== action.payload._id),
        folders,
        searchResults: state.searchResults.filter(
          (s: NexusGenFieldTypes['SiteTemplate']) => s._id !== action.payload._id,
        ),
      };
    //Ask Gilberto
    case ARCHIVE_SITE_TEMPLATE:
      folders = deleteFromParent(cloneDeep(state).folders, action.payload.folder._id, action.payload, 'siteTemplate');
      return {
        ...state,
        siteTemplate: filter(state.siteTemplates, (u) => u._id !== action.payload._id),
        folders,
        searchResults: state.searchResults.filter(
          (s: NexusGenFieldTypes['SiteTemplate']) => s._id !== action.payload._id,
        ),
      };
    case UPDATE_SITE_TEMPLATE:
      folders = updateParent(
        cloneDeep(state).folders,
        action.payload.new.folder._id,
        action.payload.new,
        'siteTemplate',
        action.payload.old,
      );
      return {
        ...state,
        siteTemplates: state.siteTemplates.map((it: NexusGenFieldTypes['SiteTemplate']) =>
          it._id === action.payload.new._id ? action.payload.new : it,
        ),
        folders,
        searchResults: state.searchResults.map((s: NexusGenFieldTypes['SiteTemplate']) =>
          s._id === action.payload.new._id ? action.payload.new : s,
        ),
      };
    case MODAL_SITE_TEMPLATE_FOLDERS:
      folders = [];
      if (action.payload.folders.length > 0 && action.payload.folders[0].parent === null) {
        action.payload.folders.forEach((p: any) => {
          folders.push({
            ...p,
            leaf: isModalFolderLeaf(p, 'siteTemplate'),
            children: [],
          });
        });
      } else if (action.payload.folders.length > 0) {
        folders = setModalFolderChildren(
          cloneDeep(state).modalResults,
          action.payload.folders[0].parent ? action.payload.folders[0].parent._id : null,
          action.payload.folders,
          'siteTemplate',
        );
      }
      return {
        ...state,
        modalResults: folders.length === 0 ? state.modalResults : folders,
      };
    case GET_SITE_TEMPLATE_FOLDERS:
      folders = [];
      if (action.payload.folders.length > 0 && action.payload.folders[0].parent === null) {
        action.payload.folders.forEach((p: any) => {
          folders.push({
            ...p,
            leaf: isLeaf(p, 'siteTemplate'),
            children: [],
          });
        });
      } else {
        folders = setChildren(
          cloneDeep(state).folders,
          action.payload.folders.length && action.payload.folders[0].parent
            ? action.payload.folders[0].parent._id
            : action.payload.siteTemplates.length
              ? action.payload.siteTemplates[0].folder._id
              : null,
          action.payload,
          'siteTemplate',
        );
      }
      return {
        ...state,
        folders: folders.length === 0 ? state.folders : orderBy(folders, 'name', 'asc'),
      };
    case CREATE_SITE_TEMPLATE_FOLDER:
      folders = [];
      if (action.payload.parent && action.payload.parent._id) {
        folders = addNewChildren(cloneDeep(state).folders, action.payload.parent._id, action.payload, 'siteTemplate');
      } else {
        folders = JSON.parse(JSON.stringify(state.folders));
        folders.push(Object.assign({ children: [], leaf: true }, action.payload));
      }
      return { ...state, folders: orderBy(folders, 'name', 'asc') };
    case DELETE_SITE_TEMPLATE_FOLDER:
      folders = [];
      if (action.payload.parent !== null) {
        folders = deleteChildren(cloneDeep(state).folders, action.payload.parent._id, action.payload, 'siteTemplate');
      } else {
        folders = state.folders.filter((st: NexusGenFieldTypes['SiteTemplateFolder']) => st._id !== action.payload._id);
      }
      return {
        ...state,
        folders,
        searchResults: state.searchResults.filter((s: { _id: string }) => s._id !== action.payload._id),
      };
    case UPDATE_SITE_TEMPLATE_FOLDER:
      folders = [];
      if (action.payload.parent !== null)
        folders = updateChildren(cloneDeep(state).folders, action.payload.parent._id, action.payload);
      else
        state.folders.map(
          (
            folder: NexusGenFieldTypes['SiteTemplateFolder'] & {
              children: NexusGenFieldTypes['SiteTemplateFolder'][];
            },
          ) => {
            if (folder._id === action.payload._id) folders.push({ ...folder, ...action.payload });
            else folders.push(folder);
          },
        );
      return {
        ...state,
        folders: orderBy(folders, 'name', 'asc'),
        searchResults: state.searchResults.map((s: { _id: string }) =>
          s._id === action.payload._id ? action.payload : s,
        ),
      };
    case SEARCH_SITE_TEMPLATE_FOLDERS:
      return {
        ...state,
        searchResults: concat(action.payload.siteTemplates, action.payload.siteTemplateFolders),
      };
    case ERROR:
      return { ...state, error: true };
    case MOVE_SITES:
      let treeAux;
      for (const site of action.payload.sites) {
        const oldNode = getChildrenDefault(state.sitesTree, site._id);
        oldNode.parentId = site.parentId;
        oldNode.parentsTreeIds = site.parentsTreeIds;
        if (site.parentId) {
          treeAux = deleteChildrenDefault(
            cloneDeep(state).sitesTree,
            site.parentId,
            {
              ...site,
              parent: { _id: site.parentId },
              _id: site._id,
              __typename: 'LeanSite',
            },
            'site',
          );
        } else {
          treeAux = state.sitesTree.filter((st: NexusGenFieldTypes['Site']) => st._id !== site.parentId);
        }
        if (action.payload.destination !== null) {
          treeAux = addNewChildrenDefault(
            treeAux,
            action.payload.destination._id,
            { ...oldNode, __typename: 'LeanSite' },
            'site',
          );
        } else {
          treeAux.push(Object.assign({ children: [], leaf: !oldNode.hasChildren }, oldNode));
        }
      }
      return { ...state, sitesTree: treeAux };
    case MOVE_ELEMENTS:
      let treeAuxiliar;
      for (const element of action.payload.elements) {
        const oldNode = getChildrenDefault(cloneDeep(state).sitesTree, element._id);
        oldNode.parentId = element.parentId;
        oldNode.parentsTreeIds = element.parentsTreeIds;
        oldNode.siteId = element.siteId;
        treeAuxiliar = deleteChildrenDefault(
          cloneDeep(state).sitesTree,
          element.parentId || element.siteId,
          {
            ...element,
            parent: { _id: element.parentId || element.siteId },
            _id: element._id,
            __typename: 'LeanElement',
          },
          'element',
        );
        if (action.payload.destination !== null) {
          treeAuxiliar = addNewChildrenDefault(
            treeAuxiliar,
            action.payload.destination._id,
            { ...oldNode, __typename: 'LeanElement' },
            'element',
          );
        } else {
          treeAuxiliar.push(Object.assign({ children: [], leaf: !oldNode.hasChildren }, oldNode));
        }
      }
      return { ...state, sitesTree: treeAuxiliar };
    default:
      return state;
  }
};

/**
 * @function getSites
 * @description returns the sites available on the backend.
 */

export const getSitesRoot =
  (devices = false, doDispatch = true) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          filter: localStorage.getItem('current_site_id')
            ? { _id_eq: localStorage.getItem('current_site_id') }
            : { parent_exists: false },
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_SITES,
      });

      if (devices)
        for (let i = 0; i < response.data.sitesV2.length; i++) {
          if (response.data.sitesV2[parseInt(`${i}`)].devicesCount) {
            const devices = await APOLLO_CLIENT.query({
              variables: { filter: { site_eq: response.data.sitesV2[parseInt(`${i}`)]._id } },
              query: Q_GET_DEVICES,
              fetchPolicy: 'no-cache',
            });
            response.data.sitesV2[parseInt(`${i}`)].devices = devices.data.devices;
            response.data.sitesV2[parseInt(`${i}`)].counter = response.data.sitesV2[parseInt(`${i}`)].devicesCount;
          }
        }

      if (doDispatch) {
        dispatch({
          type: GET_SITES_ROOT,
          payload: { sites: response.data.sitesV2 },
        });
      }
      return response.data.sitesV2;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getSite = (filter: NexusGenInputs['SiteWhereUniqueInput']) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        filter: filter,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_SITE,
    });
    return response.data.site;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getSiteWithDeleted = (filter: NexusGenInputs['SiteWhereUniqueInput']) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        filter: filter,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_SITE_WITH_DELETED,
    });
    return response.data.siteWithDeleted;
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getSiteToMap =
  (filter, radius = 5000) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          filter: { _id: filter._id_eq },
          radius,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_TO_MAP,
      });
      return response.data.site;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getNextEventOfSite = (id) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_NEXT_EVENT,
    });
    return response.data.calendar;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getSiteToTree = (filter, _item?) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        filter: filter.parent_exists !== undefined ? { _id_eq: localStorage.getItem('current_site_id') } : filter,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_SITE_TO_TREE,
    });
    return response.data.sitesV2;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getInputApprovals =
  (
    siteWhere: SiteWhereInput,
    elementWhere: ElementWhereInput,
    orderBy: NexusGenEnums['InputApprovalOrderBy'][],
    skip: number,
    limit: number,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          siteWhere,
          elementWhere,
          limit,
          skip,
          orderBy: orderBy ?? undefined,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_INPUT_APPROVALS,
      });

      return {
        count: response.data.inputApprovalsCount,
        approvals: response.data.inputApprovals,
      };
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getSitesByParentId = (parentId: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        filter: { parent: { _id: parentId } },
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_SITES,
    });

    dispatch({
      type: GET_SITES_BY_PARENT,
      payload: { sites: response.data.sitesV2 },
    });

    return response.data.sitesV2;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const siteSearch = (searchString: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: SHOW_SITE_SPINNER_SEARCH,
    });

    dispatch({
      type: CHANGE_SEARCH_INPUT_VALUE,
      payload: { searchInputValue: searchString },
    });

    const response = await APOLLO_CLIENT.query({
      variables: {
        filterSite: { name_contains: searchString },
        filterElement: { name_contains: searchString },
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_SITES_ELEMENTS_WITH_PARENT_TREE,
    });

    // update the local storage with the token using the correct action
    dispatch({
      type: GET_SITES_SEARCH,
      payload: { sites: [...response.data.sites, ...response.data.elements] },
    });
    return response.data.sites;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getSiteTemplate = (filter: NexusGenInputs['SiteTemplateWhereInput']) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        filter,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_SITE_TEMPLATES,
    });
    return response.data.siteTemplates[0];
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const getSites =
  (page: number, itemsByPage: number, filter: NexusGenInputs['SiteWhereInput']) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          limit: itemsByPage,
          skip: (page - 1) * itemsByPage,
          filter,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_SITES,
      });

      dispatch({
        type: GET_SITES,
        payload: { sites: response.data.sitesV2 },
      });
      return response.data.sitesV2;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const createSite =
  (site: NexusGenInputs['SiteCreateInput'] & { templateId: string }) => async (dispatch: Dispatch) => {
    try {
      let response;
      if (site.templateId) {
        response = await APOLLO_CLIENT.mutate({
          variables: {
            name: site.name,
            description: site.description,
            geoPoint: site.coordinates,
            inputs: site.inputs,
            parent: site.parent,
            templateId: site.templateId,
            labelValues: site.labelValues,
          },
          mutation: queries.M_CREATE_SITE,
        });
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('site') + addSpace(site.name) + i18n.t('toastCreateSuccess'),
            severity: 'success',
          },
        });
      }
      dispatch({
        type: CREATE_SITE,
        payload: { site: response ? response.data.createSite : site },
      });
      if (response) return response.data.createSite;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const importSites = (data) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        data,
      },
      mutation: queries.M_CREATE_MANY_SITES,
    });
    return response.data.createManySites;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const updateGetRoot =
  (devices = false) =>
  async (dispatch: Dispatch): Promise<void | unknown> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          filter: localStorage.getItem('current_site_id')
            ? { _id_eq: localStorage.getItem('current_site_id') }
            : { parent_exists: false },
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_SITES,
      });

      if (devices)
        for (let i = 0; i < response.data.sitesV2.length; i++) {
          const devices = await APOLLO_CLIENT.query({
            variables: { filter: { site_eq: response.data.sitesV2[parseInt(`${i}`)]._id } },
            query: Q_GET_DEVICES,
            fetchPolicy: 'no-cache',
          });
          response.data.sitesV2[parseInt(`${i}`)].devices = devices.data.devices;
        }

      dispatch({
        type: UPDATE_SITE,
        payload: { site: response.data.sitesV2[0] },
      });

      return null;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const updateSite =
  (
    site: NexusGenInputs['SiteUpdateInput'] & {
      _id: string;
      template: { _id: string };
      parent: { _id: string };
      pendingInputs: any[];
      id?: string;
    },
    showToast = true,
    devices = false,
    updateParent = false,
    oldParentId?: string,
  ) =>
  async (dispatch: Dispatch): Promise<object | unknown> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          name: site.name,
          description: site.description,
          geoPoint: site.coordinates,
          inputs: site.inputs,
          _id: site._id || site.id,
          templateId: site?.template?._id,
          parentId: site?.parent?._id,
          labelValues: site.labelValues,
        },
        mutation: queries.M_UPDATE_SITE,
      });

      if (devices) {
        const devices = await APOLLO_CLIENT.query({
          variables: { filter: { site_eq: response.data.updateSite._id } },
          query: Q_GET_DEVICES,
          fetchPolicy: 'no-cache',
        });
        response.data.updateSite.devices = devices.data.devices;
      }

      if (updateParent) {
        dispatch({
          type: UPDATE_SITE_PARENT,
          payload: { site: response.data.updateSite, oldParentId: oldParentId, oldSite: site },
        });
      } else {
        dispatch({
          type: UPDATE_SITE,
          payload: { site: response.data.updateSite },
        });
      }

      if (showToast) {
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('site') + addSpace(site.name || '') + i18n.t('toastUpdateSuccess'),
            severity: 'success',
          },
        });
      }
      return response.data.updateSite;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const changeInputsResponsibles =
  (id: string, responsibles: NexusGenInputs['InputsApprovalResponsibles'], propagateDownwards: boolean) =>
  async (dispatch: Dispatch) => {
    try {
      const resp = await APOLLO_CLIENT.mutate({
        variables: {
          id,
          responsibles,
          propagateDownwards,
        },
        mutation: queries.M_CHANGE_INPUTS_RESPONSIBLES,
      });

      return resp.data.changeInputsResponsibles;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const deleteSite =
  (siteData: NexusGenFieldTypes['Site']) =>
  async (dispatch: Dispatch): Promise<void | unknown> => {
    try {
      let response;
      if (siteData.template) {
        response = await APOLLO_CLIENT.mutate({
          variables: {
            id: siteData._id,
          },
          mutation: queries.M_DELETE_SITE,
        });
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('site') + addSpace(siteData.name) + i18n.t('toastDeleteSuccess'),
            severity: 'success',
          },
        });
      }

      dispatch({
        type: DELETE_SITE,
        payload: { site: response ? response.data.deleteOneSite : siteData },
      });
      if (response) return response.data.deleteOneSite;

      return null;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const createSiteTemplate = (template: NexusGenFieldTypes['SiteTemplate']) => async (dispatch: Dispatch) => {
  try {
    let response;
    if (template.frame?.inputs) {
      response = await APOLLO_CLIENT.mutate({
        variables: {
          title: Array.isArray(template.title) ? template.title[0] : template.title,
          folder: template.folder._id,
          inputs: template.frame.inputs,
          icon: template?.icon,
        },
        mutation: queries.M_CREATE_SITE_TEMPLATE,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('Template') + addSpace(template.title) + i18n.t('toastCreateSuccess'),
          severity: 'success',
        },
      });
    }

    dispatch({
      type: CREATE_SITE_TEMPLATE,
      payload: {
        siteTemplate: response ? response.data.createSiteTemplate : template,
      },
    });

    if (response) return response.data.createSiteTemplate;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const deleteSiteTemplate =
  (siteTemplateData: NexusGenFieldTypes['SiteTemplate']) =>
  async (dispatch: Dispatch): Promise<void | unknown> => {
    try {
      let response;
      if (siteTemplateData.frame?.inputs) {
        response = await APOLLO_CLIENT.mutate({
          variables: {
            id: siteTemplateData._id,
          },
          mutation: queries.M_DELETE_SITE_TEMPLATE,
        });
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('Template') + addSpace(siteTemplateData.title) + i18n.t('toastDeleteSuccess'),
            severity: 'success',
          },
        });
      }
      dispatch({
        type: DELETE_SITE_TEMPLATE,
        payload: response ? response.data.deleteOneSiteTemplate : siteTemplateData,
      });

      return null;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const updateSiteTemplate =
  (data: SiteTemplateUpdateInput, where: SiteTemplateWhereUniqueInput, previousFolderId: string) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          data,
          where,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_SITE_TEMPLATE,
      });

      if (data.archived) {
        dispatch({
          type: DELETE_SITE_TEMPLATE,
          payload: response ? response.data.updateSiteTemplate : data,
        });
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('Template') + ' ' + i18n.t('toastArchiveSuccess'),
            severity: 'success',
          },
        });
      } else {
        dispatch({
          type: UPDATE_SITE_TEMPLATE,
          payload: {
            new: response.data.updateSiteTemplate,
            old: previousFolderId,
          },
        });
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('Template') + addSpace(data.title) + i18n.t('toastUpdateSuccess'),
            severity: 'success',
          },
        });
      }

      return response.data.updateSiteTemplate;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getSiteTemplatesToTree = (filter, item?) => async (dispatch: Dispatch) => {
  try {
    let siteTemplates: ApolloQueryResult<any> | null = null;
    let siteTemplateFolders: ApolloQueryResult<any> | null = null;

    siteTemplateFolders = await APOLLO_CLIENT.query({
      variables: {
        where: filter,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_SITE_TEMPLATE_FOLDERS,
    });
    if (item)
      siteTemplates = await APOLLO_CLIENT.query({
        variables: {
          filter: { folder: { _id_eq: item._id } },
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_SITE_TEMPLATES_TO_TREE,
      });
    /* } */

    return orderBy(
      [
        ...(siteTemplates ? siteTemplates.data.siteTemplates : []),
        ...(siteTemplateFolders ? siteTemplateFolders.data.siteTemplateFolders : []),
      ],
      'name',
      'asc',
    );
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getTemplateFolders =
  (options: any, item: any, modal = false) =>
  async (dispatch: Dispatch) => {
    try {
      let response: ApolloQueryResult<any> | null = null;
      if (item?.node.siteTemplateFoldersCount || options.parent_exists !== undefined) {
        response = await APOLLO_CLIENT.query({
          variables: { where: options, archivedBy_exists: false },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_SITE_TEMPLATE_FOLDERS,
        });
      }

      let siteTemplates: ApolloQueryResult<any> | null = null;
      if (options.parent_eq && !modal && item.node.siteTemplatesCount) {
        siteTemplates = await APOLLO_CLIENT.query({
          variables: { filter: { folder: { _id_eq: options.parent_eq }, archived_ne: true } },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_SITE_TEMPLATES_TO_TREE,
        });
      }

      dispatch({
        type: modal ? MODAL_SITE_TEMPLATE_FOLDERS : GET_SITE_TEMPLATE_FOLDERS,
        payload: {
          folders: response ? response.data.siteTemplateFolders : [],
          siteTemplates: siteTemplates ? siteTemplates.data.siteTemplates : [],
        },
      });
      return orderBy(
        [
          ...(response ? response.data.siteTemplateFolders : []),
          ...(siteTemplates ? siteTemplates.data.siteTemplates : []),
        ],
        'name',
        'asc',
      );
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const createFolder =
  ({ name, parent = null }: { name: string; parent: { _id: string } | null }) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { name, parent: parent?._id },
        fetchPolicy: 'no-cache',
        mutation: queries.M_CREATE_SITE_TEMPLATE_FOLDER,
      });
      dispatch({
        type: CREATE_SITE_TEMPLATE_FOLDER,
        payload: response.data.createSiteTemplateFolder,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(name) + i18n.t('toastCreateSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const deleteTemplateFolder =
  (folderData: { _id: string; name: string }) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { where: { _id: folderData._id } },
        fetchPolicy: 'no-cache',
        mutation: queries.M_DELETE_SITE_TEMPLATE_FOLDER,
      });
      dispatch({
        type: DELETE_SITE_TEMPLATE_FOLDER,
        payload: response.data.deleteOneSiteTemplateFolder,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(folderData.name) + i18n.t('toastDeleteSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
    }
  };

export const updateTemplateFolder =
  ({ name, parent = null }: { name: string; parent: string | null }, { _id }: { _id: string }) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { name, parent_eq: parent, _id },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_SITE_TEMPLATE_FOLDER,
      });
      dispatch({
        type: UPDATE_SITE_TEMPLATE_FOLDER,
        payload: response.data.updateSiteTemplateFolder,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(name) + i18n.t('toastUpdateSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const duplicateSite = (_id: string, copyChildren: boolean, name: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { _id, copyChildren },
      fetchPolicy: 'no-cache',
      mutation: queries.M_DUPLICATE_SITE,
    });
    dispatch({
      type: CREATE_SITE,
      payload: { site: response.data.duplicateSite },
    });
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: `${name} ${i18n.t('duplicated')}`,
        severity: 'success',
      },
    });
    return response.data.duplicateSite;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const duplicateSiteTemplate =
  (_id: string, copyChildren: boolean, name: string) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { _id, copyChildren },
        fetchPolicy: 'no-cache',
        mutation: queries.M_DUPLICATE_SITE_TEMPLATE,
      });
      dispatch({
        type: CREATE_SITE_TEMPLATE,
        payload: { siteTemplate: response.data.duplicateSiteTemplate },
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: `${name} ${i18n.t('duplicated')}`,
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const search = (searchString: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { searchString },
      fetchPolicy: 'no-cache',
      mutation: queries.Q_SEARCH,
    });

    dispatch({
      type: SEARCH_SITE_TEMPLATE_FOLDERS,
      payload: response.data,
    });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
  }
};

export const getSitesByFatherId =
  (parentId: string, skip: number, limit: number, devices = false) =>
  async (dispatch: Dispatch): Promise<void> => {
    let loadMore = false;
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          parentId,
          skip,
          limit: limit ? limit + 1 : undefined,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_SITES_BY_FATHER_ID,
      });

      if (response.data.sitesV2.length === limit + 1) {
        response.data.sitesV2.pop();
        loadMore = loadMore || true;
      }

      if (devices)
        for (let i = 0; i < response.data.sitesV2.length; i++) {
          if (response.data.sitesV2[parseInt(`${i}`)].devicesCount) {
            const devices = await APOLLO_CLIENT.query({
              variables: { filter: { site_eq: response.data.sitesV2[parseInt(`${i}`)]._id } },
              query: Q_GET_DEVICES,
              fetchPolicy: 'no-cache',
            });
            response.data.sitesV2[parseInt(`${i}`)].devices = devices.data.devices;
            response.data.sitesV2[parseInt(`${i}`)].counter = response.data.sitesV2[parseInt(`${i}`)].devicesCount;
          }
        }

      dispatch({
        type: GET_SITES_BY_FATHER_ID,
        payload: {
          sites: response.data.sitesV2,
          parentId,
          more: skip > 0,
          loadMore,
        },
      });

      return response.data.sitesV2;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
    }
  };

export const getElementsByFatherId =
  (item, skip: number, limit: number /* , modal = false */, doDispatch = true) =>
  async (dispatch: Dispatch) => {
    try {
      let elements: ApolloQueryResult<any> | null = null;
      let sites: ApolloQueryResult<any> | null = null;
      let loadMore = false;
      if (item.siteId || item.site) {
        elements = await APOLLO_CLIENT.query({
          variables: {
            parentId: item._id,
            skip: item.children?.filter((data) => data.profile !== undefined).length || skip,
            limit: limit + 1,
          },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_ELEMENT_BY_FATHER_ID,
        });

        if (elements.data.elementsV2.length === limit + 1) {
          elements.data.elementsV2.pop();
          loadMore = loadMore || true;
        }
      } else {
        sites = await APOLLO_CLIENT.query({
          variables: {
            filter: { parent_eq: item._id },
            skip: item.children?.filter((data) => data.profile === undefined).length || skip,
            limit: limit + 1,
          },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_SITES,
        });
        if (sites.data.sitesV2.length === limit + 1) {
          sites.data.sitesV2.pop();
          loadMore = loadMore || true;
        }
        if (sites.data.sitesV2.length < 50) {
          const lim = 50 - sites.data.sitesV2.length;
          elements = await APOLLO_CLIENT.query({
            variables: {
              id: item._id,
              skip: item.children?.filter((data) => data.profile !== undefined).length || skip,
              limit: lim + 1,
            },
            fetchPolicy: 'no-cache',
            query: queries.Q_GET_ELEMENT_ROOT_BY_SITE,
          });

          if (elements.data.elementsV2.length === lim + 1) {
            elements.data.elementsV2.pop();
            loadMore = loadMore || true;
          }
        }
      }

      if (doDispatch) {
        // update the local storage with the token using the correct action
        dispatch({
          type: GET_ELEMENT_BY_FATHER_ID,
          payload: {
            elements: elements ? elements.data.elementsV2 : [],
            sites: sites ? sites.data.sitesV2 : [],
            parentItem: item,
            more: skip > 0,
            loadMore,
          },
        });
      }

      return {
        elements: elements ? elements.data.elementsV2 : [],
        sites: sites ? sites.data.sitesV2 : [],
        parentItem: item,
        more: skip > 0,
        loadMore,
      };
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const moveManySites = (nodes, parent) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { _ids: nodes.map((elem) => elem._id), parentId: parent._id },
      fetchPolicy: 'no-cache',
      mutation: queries.M_MOVE_MANY_SITES,
    });

    dispatch({
      type: MOVE_SITES,
      payload: {
        sites: nodes,
        destination: parent,
      },
    });

    return response.data.moveManySites;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const updateManySiteTemplates =
  (where: NexusGenInputs['SiteTemplateWhereInput'], update: NexusGenInputs['SiteTemplateUpdateInput']) =>
  async (dispatch: Dispatch): Promise<NexusGenFieldTypes['SiteTemplate'][] & { graphQLErrors: GraphQLErrors }> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          where,
          update,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_MANY_SITE_TEMPLATES,
      });

      return response.data.updateManySiteTemplates;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };
