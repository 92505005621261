import { Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import { styles } from 'src/utils/components/generic-input-card/components/action-draft-popover/styles';
import { Close } from '@mui/icons-material';
import InputFieldGeneric from 'src/utils/components/input-field/input-generic';
import { useState, useEffect, useContext } from 'react';
import { NexusGenFieldTypes } from '../../../../../../../server/src/types';
import DefaultButton from 'src/utils/components/default-button';
import { createActionDraft, updateActionDraft, getActionDraft } from 'src/modules/actions/actions.redux';
import { bindActionCreators, Dispatch, compose } from 'redux';
import { connect } from 'react-redux';
import BetterFieldPopover from 'src/utils/components/better-field-popover';
import { partition } from 'lodash';
import { BlockCloseDropdownContext } from '../..';
import StaticFieldPopover from 'src/utils/components/static-field-popover';

const ActionDraftPopover = (props): JSX.Element => {
  const { classes, onClose, actionDraft, createActionDraft, updateActionDraft, getActionDraft } = props;
  const { t } = useTranslation();
  const [action, setAction] = useState<any>({
    name: '',
    assignedLabelValues: [],
    assignedAccounts: [],
    labelValues: [],
    dueDate: t('none'),
    custom: {
      amount: 1,
      timeUnit: t('days'),
    },
  });
  const frequencyOptions = [
    { id: '', label: t('none') },
    { id: '1,days', label: t('oneDay') },
    { id: '1,weeks', label: t('oneWeek') },
    {
      id: '1,months',
      label: t('oneMonth'),
    },
    {
      id: '1,years',
      label: t('oneYear'),
    },
    { id: 'custom', label: `${t('custom')}...` },
  ];
  const timeUnitOptions = [
    { id: 'days', label: t('days') },
    { id: 'weeks', label: t('weeks') },
    { id: 'months', label: t('months') },
    { id: 'years', label: t('years') },
  ];

  const setBlockClose = useContext(BlockCloseDropdownContext);

  useEffect(() => {
    setBlockClose(true);

    return () => {
      setBlockClose(false);
    };
  }, []);

  useEffect(() => {
    if (actionDraft) {
      getActionDraft({ _id: actionDraft }).then((resp) => {
        setAction({
          ...resp,
          custom:
            !resp.dueDate ||
            (resp.dueDate && frequencyOptions.find((fo) => fo.id === `${resp.dueDate.amount},${resp.dueDate.timeUnit}`))
              ? {
                  amount: 1,
                  timeUnit: t('days'),
                }
              : {
                  amount: resp.dueDate.amount,
                  timeUnit: t(resp.dueDate.timeUnit),
                },
          dueDate: !resp.dueDate
            ? t('none')
            : frequencyOptions.find((fo) => fo.id === `${resp.dueDate.amount},${resp.dueDate.timeUnit}`)
              ? frequencyOptions.find((fo) => fo.id === `${resp.dueDate.amount},${resp.dueDate.timeUnit}`).label
              : `${t('custom')}...`,
        });
      });
    }
  }, []);

  const save = async () => {
    const freqOpt = frequencyOptions.find((freqOpt) => freqOpt.label === action.dueDate);
    const dueDateSplit = freqOpt?.id.split(',');
    const dataToSend = {
      name: action.name,
      assignedLabelValues: action.assignedLabelValues.map((lv: NexusGenFieldTypes['LabelValue']) => lv._id),
      assignedAccounts: action.assignedAccounts.map((acc: NexusGenFieldTypes['Account']) => acc._id),
      labelValues: action.labelValues.map((lv: NexusGenFieldTypes['LabelValue']) => lv._id),
      dueDate:
        action.dueDate === t('none')
          ? null
          : action.dueDate === `${t('custom')}...`
            ? {
                amount: action.custom.amount,
                timeUnit: timeUnitOptions.find((tu) => tu.label === action.custom.timeUnit)?.id,
              }
            : { amount: parseInt(dueDateSplit[0]), timeUnit: dueDateSplit[1] },
    };
    if (actionDraft) {
      const resp = await updateActionDraft({ _id: actionDraft }, dataToSend);
      if (resp?.graphQLErrors) {
        return;
      }
      onClose();
    } else {
      const resp = await createActionDraft(dataToSend);
      if (resp?.graphQLErrors) {
        return;
      }
      onClose(resp);
    }
  };

  return (
    <Popover open={true} onClose={onClose} classes={{ paper: classes.popoverContainer }}>
      <div id='popover-default-action-head' data-testid='popover-default-action-head' className={classes.headerInfo}>
        <div style={{ display: 'flex' }}>
          <Close classes={{ root: classes.closeIcon }} onClick={() => onClose()} />
        </div>
        <div className={classes.title}>{`${t('defaultSettingsPopover')}`}</div>
        <div className={classes.subTitle}>{`${t('defaultSettingsPopoverSubTitle')}`}</div>
      </div>
      <div id='popover-default-action-body' data-testid='popover-default-action-body' className={classes.body}>
        <InputFieldGeneric
          id='inputTitleAction'
          data-testid='inputTitleAction'
          editable={true}
          disabled={false}
          type={'string'}
          title={t('title')}
          values={action.name ? [action.name] : []}
          handleChange={(e: string[]): void => setAction({ ...action, name: e[0] })}
        />
        <BetterFieldPopover
          value={[...action.assignedLabelValues, ...action.assignedAccounts]}
          title={t('Assign to')}
          data-testid={'inputAssignToField'}
          context={['assignee', 'labelAccount']}
          multiple
          onChange={(e) => {
            const [accs, lbls] = partition(e, (e) => e.__typename === 'Account');

            setAction({ ...action, assignedAccounts: accs, assignedLabelValues: lbls });
          }}
        />
        <BetterFieldPopover
          title={t('Labels')}
          data-testid={`inputLabelField`}
          context={'labelAction'}
          multiple
          value={action.labelValues}
          onChange={(e) => setAction({ ...action, labelValues: e })}
        />
        <div style={{ marginTop: '15px' }}>
          <StaticFieldPopover
            title={t('dueDate')}
            data-testid='inputDueDateAction'
            options={frequencyOptions}
            selected={action.dueDate}
            onSelect={(e) => {
              setAction({
                ...action,
                dueDate: e.id,
                custom:
                  e.id === 'custom'
                    ? action.custom
                    : {
                        amount: 0,
                        timeUnit: t('days'),
                      },
              });
            }}
          />
          {action.dueDate === `${t('custom')}...` ? (
            <div className={classes.divCustom}>
              <div style={{ width: '50%' }}>
                <InputFieldGeneric
                  id='inputCustomDaysNumber'
                  data-testid='inputCustomDaysNumber'
                  editable={true}
                  disabled={false}
                  type={'number'}
                  onlyInput
                  values={action.custom.amount ? [action.custom.amount] : []}
                  handleChange={(e: string[]): void =>
                    setAction({ ...action, custom: { ...action.custom, amount: e[0] } })
                  }
                />
              </div>
              <div style={{ width: '50%' }}>
                <StaticFieldPopover
                  data-testid='inputCustomDaysNumberType'
                  options={timeUnitOptions}
                  selected={action.custom?.timeUnit}
                  onSelect={(e) => setAction({ ...action, custom: { ...action.custom, timeUnit: e.id } })}
                />
              </div>
            </div>
          ) : undefined}
        </div>
        <div id='popover-default-action-note' data-testid='popover-default-action-note' className={classes.note}>
          {t('defaultSettingsPopoverNote')}
        </div>
      </div>
      <div className={classes.footer}>
        <DefaultButton id={'buttonCancel'} data-testid={'buttonCancel'} discard onClick={onClose}>
          {t('cancel')}
        </DefaultButton>
        <DefaultButton id={'buttonSave'} data-testid={'buttonSave'} success onClick={save}>
          {t('save')}
        </DefaultButton>
      </div>
    </Popover>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createActionDraft,
      updateActionDraft,
      getActionDraft,
    },
    dispatch,
  );

export default compose<any>(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(ActionDraftPopover);
