import * as Leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { Map, TileLayer } from 'react-leaflet';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import MarkerClusterGroup from 'src/utils/components/markercluster';
import { styles } from 'src/modules/site/styles/site-map';
import { setCoordinates } from 'src/utils/funcs/store/actions';
import { addMarker, showAllSiteMarkers } from 'src/utils/funcs/marker';
import { withStyles } from '@mui/styles';
import { useState, useCallback, useEffect, useRef, ReactNode } from 'react';
import { LatLng } from 'leaflet';
import { NexusGenFieldTypes } from '../../../../../server/src/types';

interface SiteMapProps {
  isConfigurator: boolean;
  state: {
    showAll: boolean;
    createMode: boolean;
    isOpen: boolean;
    coordinates: {
      lat: number;
      lng: number;
    };
    markers: { data: ReactNode }[];
    selectedNode: {
      coordinates: number[];
    };
  };
  elements: NexusGenFieldTypes['Element'][];
  dispatch: Dispatch;
  classes: Record<string, string>;
  toggleZoom: boolean;
}

const SiteMap: React.FC<SiteMapProps> = (props: SiteMapProps) => {
  const [, setUpdate] = useState();
  const forceUpdate = useCallback(() => setUpdate(() => undefined), []);
  const [handleChange, setHandleChange] = useState<boolean>(false);
  const { state, dispatch } = props;
  const { coordinates, markers } = state;
  const southWest: LatLng = Leaflet.latLng(90, 180),
    northEast = Leaflet.latLng(-90, -180),
    bounds = Leaflet.latLngBounds(southWest, northEast),
    zoom = 7;
  const mapRef = useRef(null);
  const defaultCoordinates = {
    lat: 39.414020619,
    lng: -8.075006228,
  };

  useEffect(() => {
    if (handleChange) {
      showAllSiteMarkers(props.elements, { dispatch, state });
      setHandleChange(false);
      forceUpdate();
    }
  }, [state.coordinates, state.showAll]);

  useEffect(() => {
    if (state.selectedNode && state.selectedNode.coordinates) {
      setCoordinates(dispatch, {
        lat: !state.createMode ? state.selectedNode.coordinates[0] : 0,
        lng: !state.createMode ? state.selectedNode.coordinates[1] : 0,
        toggle: true,
      });
      setHandleChange(true);
    }
  }, [state.selectedNode, state.isOpen]);

  return (
    <Map
      enzyme-attr='component-site-map'
      className={`markercluster-map ${props.classes.map}`}
      center={
        coordinates.lat !== 0 && coordinates.lng !== 0
          ? [coordinates.lat, coordinates.lng]
          : [defaultCoordinates.lat, defaultCoordinates.lng]
      }
      zoom={mapRef.current && !props.toggleZoom ? mapRef.current?.leafletElement._zoom : zoom}
      maxZoom={20}
      minZoom={2}
      doubleClickZoom={true}
      maxBounds={bounds}
      maxBoundsViscosity={0.1}
      ref={(m) => (mapRef.current = m)}
      onclick={(e) => {
        if (props.isConfigurator) addMarker(e.latlng.lat, e.latlng.lng, { dispatch, state });
      }}
    >
      <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
      <MarkerClusterGroup>{markers && markers.map((marker) => marker.data)}</MarkerClusterGroup>
    </Map>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default compose(
  withTranslation('translation'),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(SiteMap);
