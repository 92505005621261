import {
  OnBoardingCatalogRefs,
  OnBoardingExecutionRefs,
  OnBoardingTemplateRefs,
} from 'src/base/main-container/context/main.container.context';

/**
 * This will return the necessary information for each sub step according to the current
 * major step during the on boarding
 * @param currentStep - current major step
 * @param t - translations function
 * @param templateRefs - refs for template step
 * @param catalogsRefs - refs for catalog step
 * @param executionRefs - refs for execution step
 * @returns the necessary data for a sub step according to the current major step
 */
export const getTourStepsData = (
  currentStep: string,
  t: (key: string) => string,
  templateRefs: OnBoardingTemplateRefs,
  catalogsRefs: OnBoardingCatalogRefs,
  executionRefs: OnBoardingExecutionRefs,
) => {
  let dataToReturn = [];
  switch (currentStep) {
    case 'build':
      dataToReturn = [
        { title: t('nameTemplate'), target: templateRefs.templateTitleRef, placement: 'bottomLeft' },
        { title: t('addTasksToProcess'), target: templateRefs.addTaskRef, placement: 'bottomLeft' },
        { title: t('addQuestionsAndInstructions'), target: templateRefs.addInputRef, placement: 'bottom' },
        { title: t('insertQuestion'), target: templateRefs.inputTitleRef, placement: 'bottomLeft' },
        { title: t('selectTypeResponse'), target: templateRefs.inputTypeRef, placement: 'bottom' },
        { title: t('addRestrictionsCustomize'), target: templateRefs.inputSettingsRef, placement: 'bottomTop' },
        { title: t('addVariantsAndSwitch'), target: templateRefs.taskVariantsRef, placement: 'bottomLeft' },
      ];
      break;
    case 'createIssue':
      dataToReturn = [
        { title: t('nameIssue'), target: catalogsRefs.catalogTitleRef, placement: 'bottomLeft' },
        { title: t('selectTemplateForIssue'), target: catalogsRefs.catalogTemplateRef, placement: 'bottomLeft' },
        { title: t('addLabelsToIssue'), target: catalogsRefs.catalogLabelsRef, placement: 'bottomLeft' },
      ];
      break;
    case 'createTasks':
      dataToReturn = [
        { title: t('navigateThroughTasks'), target: catalogsRefs.catalogTasksRef, placement: 'topRight' },
        { title: t('addEditInformationOnTask'), target: catalogsRefs.catalogTaskHeaderRef, placement: 'topRight' },
      ];
      break;
    case 'execute':
      dataToReturn = [
        { title: t('startExecutingIssue'), target: executionRefs.executionStartRef, placement: 'bottom' },
        { title: t('accessTasksCheckStatus'), target: executionRefs.executionTaskListRef, placement: 'topTop' },
        { title: t('executeTasksAddNotesEtc'), target: executionRefs.executionTaskRef, placement: 'topTop' },
      ];
  }
  return dataToReturn;
};
