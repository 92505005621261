import { Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import DefaultButton from 'src/utils/components/default-button/default-button';
import { styles } from './styles';
import { Warning } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap } from '@mui/styles/withStyles';

const useStyles = makeStyles(styles);
const DomainExpiredModal = (): JSX.Element => {
  const { t } = useTranslation();
  const classes: ClassNameMap<string> = useStyles();
  const subDomain = location.hostname.split('.')[0];
  const serverName = location.hostname.split('.')[1];
  const newUrl = `https://${subDomain}.glartek.com`;
  const [domainExpired, setDomainExpired] = React.useState(serverName === 'glarcloud');

  return (
    <Dialog classes={{ paper: classes.modal }} open={domainExpired} onClose={() => setDomainExpired(false)}>
      <DialogTitle style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Warning className={classes.alertIcon} />
        <Typography className={classes.title}>{t('migratingSoon')}</Typography>
      </DialogTitle>
      <DialogContent
        style={{ maxWidth: '490px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '12px' }}
      >
        <Typography className={classes.content}>
          {t('domainWillBeMigrated')}
          <strong>{t('migrationDate')}</strong>
        </Typography>
        <Typography className={classes.content}>
          {t('makeNoteNewDomain')}{' '}
          <Link href={newUrl} target='_blank'>
            {newUrl}
          </Link>
          {'. '}
          {t('updateMobileSettings')}
        </Typography>
        <Typography className={classes.content}>{t('thankYouForAttention')}</Typography>
      </DialogContent>
      <DialogActions style={{ paddingTop: '20px' }}>
        <DefaultButton
          className={classes.dismiss}
          data-testid={'dismiss-button'}
          onClick={() => setDomainExpired(false)}
          label={t('dismiss')}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DomainExpiredModal;
