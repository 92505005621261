import { AnyAction } from 'redux';
//import { SnackbarProps } from '../redux/uiReducer'; // TODO: analisar o redux
import i18n from '../../utils/translations/i18n';

export enum RoomFeatureType {
  RoomP2PDrawing = 'RoomP2PDrawing',
  RoomP2PTextBubbles = 'RoomP2PTextBubbles',
  RoomP2PDeleteAnnotations = 'RoomP2PDeleteAnnotations',
  RoomP2PUndoAnnotation = 'RoomP2PUndoAnnotation',
  RoomP2PSetTorch = 'RoomP2PSetTorch',
  RoomP2PSetVolume = 'RoomP2PSetVolume',
  RoomP2PFreeze = 'RoomP2PFreeze',
  RoomP2PRender = 'RoomP2PRender',
  RoomRecording = 'RoomRecording',
  RoomChat = 'RoomChat',
  RoomUsers = 'RoomUsers',
  RoomFileTransfer = 'RoomFileTransfer',
  RoomInviteEmail = 'RoomInviteEmail',
  RoomInviteSMS = 'RoomInviteSMS',
  RoomReport = 'RoomReport',
  RoomScreenShare = 'RoomScreenShare',
}

export enum OwnRoomFeatureType {
  RoomScreenshot = 'RoomScreenshot',
}

export enum EntityFeatureType {
  EntityCreate = 'EntityCreate',
  EntityUsers = 'EntityUsers',
  EntityDeleteUser = 'EntityDeleteUser',
  EntityInviteUser = 'EntityInviteUser',
  EntityPendingInvites = 'EntityPendingInvites',
  EntityRoomHistory = 'EntityRoomHistory',
  EntityUpdate = 'EntityUpdate',
  EntityUpdateUserRole = 'EntityUpdateUserRole',
  EntityUpdateUserStatus = 'EntityUpdateUserStatus',
  EntityManageLabels = 'EntityManageLabels',
  EntityRoomDashboard = 'EntityRoomDashboard',
}

export enum UserFeatureType {
  UserEntityInvite = 'UserEntityInvite',
  UserLogin = 'UserLogin',
  UserSSOLogin = 'UserSSOLogin',
  UserQRCodeLogin = 'UserQRCodeLogin',
  UserRegistration = 'UserRegistration',
  UserRegistrationByEntityInvite = 'UserRegistrationByEntityInvite',
  UserResetPassword = 'UserResetPassword',
  UserRoomHistory = 'UserRoomHistory',
  UserUpdatePassword = 'UserUpdatePassword',
  UserUpdateProfile = 'UserUpdateProfile',
  UserVerifyEmail = 'UserVerifyEmail',
  UserContactList = 'UserContactList',
  UserManageLabels = 'UserManageLabels',

  /* User Join Room Features */
  RoomCreate = 'RoomCreate',
  RoomJoin = 'RoomJoin',

  /* See room details */
  RoomHistory = 'RoomHistory',
}

export type FeatureTypes = RoomFeatureType | EntityFeatureType | UserFeatureType;

export type RoomFeatureList = {
  [key in RoomFeatureType]: boolean;
};

export type EntityFeatureList = {
  [key in EntityFeatureType]: boolean;
};

export type UserFeatureList = {
  [key in UserFeatureType]: boolean;
};

export type FeatureList = RoomFeatureList & EntityFeatureList & UserFeatureList;

export const IsRoomFeatureAvailable = (roomFeature: RoomFeatureType, featureList: RoomFeatureList): boolean => {
  if (!featureList) return false;

  return featureList[roomFeature];
};

export const HandleFeatureUnavailable = (
  feature: FeatureTypes,
  byAssistantUser: boolean,
  setSnackbar: (data: any /*SnackbarProps*/) => AnyAction,
): void => {
  setSnackbar({
    open: true,
    type: 'error',
    message: FeatureErrorMessage(feature, byAssistantUser),
  });
};

export const FeatureErrorMessage = (featureType: FeatureTypes, byAssistantUser?: boolean): string => {
  let feature = '';

  switch (featureType) {
    case RoomFeatureType.RoomP2PDrawing:
      feature = i18n.t('lineAndArrowDrawing');
      break;
    case RoomFeatureType.RoomP2PTextBubbles:
      feature = i18n.t('textBubbles');
      break;
    case RoomFeatureType.RoomP2PDeleteAnnotations:
      feature = i18n.t('deleteAnnotations');
      break;
    case RoomFeatureType.RoomP2PUndoAnnotation:
      feature = i18n.t('undoAnnotations');
      break;
    case RoomFeatureType.RoomP2PSetTorch:
      feature = i18n.t('changeTorch');
      break;
    case RoomFeatureType.RoomP2PSetVolume:
      feature = i18n.t('changeVolume');
      break;
    case RoomFeatureType.RoomP2PFreeze:
      feature = i18n.t('freezeVideo');
      break;
    case RoomFeatureType.RoomP2PRender:
      feature = i18n.t('changeRenderMode');
      break;
    case RoomFeatureType.RoomRecording:
      feature = i18n.t('recording');
      break;
    case RoomFeatureType.RoomChat:
      feature = i18n.t('chat');
      break;
    case RoomFeatureType.RoomUsers:
      feature = i18n.t('seeParticipants');
      break;
    case RoomFeatureType.RoomFileTransfer:
      feature = i18n.t('fileTransfer');
      break;
    case RoomFeatureType.RoomInviteEmail:
      feature = i18n.t('inviteUsersByEmail');
      break;
    case RoomFeatureType.RoomInviteSMS:
      feature = i18n.t('inviteUsersBySMS');
      break;
    case RoomFeatureType.RoomScreenShare:
      feature = i18n.t('screenShare');
      break;
    case EntityFeatureType.EntityCreate:
      feature = i18n.t('createOrganization');
      break;
    case EntityFeatureType.EntityUsers:
      feature = i18n.t('organizationManagement');
      break;
    case EntityFeatureType.EntityDeleteUser:
      feature = i18n.t('deleteOrganizationUsers');
      break;
    case EntityFeatureType.EntityInviteUser:
      feature = i18n.t('inviteUsersToOrganization');
      break;
    case EntityFeatureType.EntityPendingInvites:
      feature = i18n.t('seePendingInvitesOfOrganization');
      break;
    case EntityFeatureType.EntityRoomHistory:
      feature = i18n.t('organizationRoomHistory');
      break;
    case EntityFeatureType.EntityUpdate:
      feature = i18n.t('organizationUpdate');
      break;
    case EntityFeatureType.EntityUpdateUserRole:
      feature = i18n.t('updateOrganizationUserRole');
      break;
    case EntityFeatureType.EntityUpdateUserStatus:
      feature = i18n.t('updateOrganizationUserStatus');
      break;
    case EntityFeatureType.EntityManageLabels:
      feature = i18n.t('manageOrganizationLabels');
      break;
    case EntityFeatureType.EntityRoomDashboard:
      feature = i18n.t('sessionDashboard');
      break;
    case UserFeatureType.UserEntityInvite:
      feature = i18n.t('joinOrganizationByInvite');
      break;
    case UserFeatureType.UserLogin:
      feature = i18n.t('login');
      break;
    case UserFeatureType.UserSSOLogin:
      feature = i18n.t('ssoLogin');
      break;
    case UserFeatureType.UserQRCodeLogin:
      feature = i18n.t('loginByQRCode');
      break;
    case UserFeatureType.UserRegistration:
      feature = i18n.t('registration');
      break;
    case UserFeatureType.UserRegistrationByEntityInvite:
      feature = i18n.t('registrationByOrganizationInvite');
      break;
    case UserFeatureType.UserResetPassword:
      feature = i18n.t('resetPassword');
      break;
    case UserFeatureType.UserRoomHistory:
      feature = i18n.t('userRoomHistory');
      break;
    case UserFeatureType.UserUpdatePassword:
      feature = i18n.t('updatePassword');
      break;
    case UserFeatureType.UserUpdateProfile:
      feature = i18n.t('updateProfile');
      break;
    case UserFeatureType.UserVerifyEmail:
      feature = i18n.t('verifyUserEmail');
      break;
    case UserFeatureType.UserManageLabels:
      feature = i18n.t('manageUserLabels');
      break;
    case UserFeatureType.RoomCreate:
      feature = i18n.t('createNewSession');
      break;
    case UserFeatureType.RoomJoin:
      feature = i18n.t('joinSession');
      break;
    case UserFeatureType.RoomHistory:
      feature = i18n.t('roomHistory');
      break;
    case UserFeatureType.UserContactList:
      feature = i18n.t('contactList');
      break;
  }

  return i18n.t('featureNotAvailable', {
    user: byAssistantUser ? 'assisted ' : '',
    feature: feature != '' ? feature : i18n.t('current'),
  });
};
