import { Dialog, DialogContent, DialogTitle, Fade, Typography } from '@mui/material';
import { styles } from './styles/new-widget-modal-styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  BarChartCard,
  ChartPreset,
  ChartTypeEnum,
  GaugeCard,
  LineCard,
  PieCard,
  TableCard,
  TextCard,
  byFilterMapping,
} from './components/chartCards';
import { useMemo } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { WidgetDataset } from 'src/gql/graphql';

type EditChartProps = {
  dataset: WidgetDataset;
  onChange: (newChart: ChartTypeEnum) => any;
  onClose: () => any;
} & WithStyles<typeof styles> &
  WithTranslation;

export const chartsTable: {
  [K in ChartTypeEnum]: ChartPreset;
} = {
  pie: PieCard,
  line: LineCard,
  table: TableCard,
  bar: BarChartCard,
  gauge: GaugeCard,
  value: TextCard,
};

const EditChart = withStyles(styles)(
  withTranslation()(function (props: EditChartProps) {
    const { onChange, onClose, t, classes, dataset } = props;

    const isSingleValue = () => {
      const by = dataset[dataset.type]['by'];
      const filters = dataset[dataset.type]['filter'];
      const sum = byFilterMapping[by]?.reduce((sum, f) => (filters[f]?.length || 0) + sum, 0) ?? 0;

      return sum === 1;
    };

    const twoDimensionalCharts = ['bar', 'line', 'table', 'pie'];
    const axisCharts = ['bar', 'line'];
    const nonAxisCharts = ['table', 'pie'];
    const nonAxisAlternativeCharts = ['table', 'bar'];
    const tableAlternativeCharts = ['pie', 'bar'];
    const singleValueCharts = ['value', 'gauge'];
    const allCharts = Object.keys(chartsTable);

    const suitableCharts = useMemo(() => {
      if (dataset.realtime) {
        if (dataset.realtime.filters.flatMap((f) => f['tags']).length === 1) {
          return singleValueCharts.filter((chart) => chart !== dataset[dataset.type].chart);
        } else {
          return ['value', 'gauge', 'line'];
        }
      } else if (singleValueCharts.includes(dataset[dataset.type].chart)) {
        if (isSingleValue()) {
          return allCharts;
        } else {
          return singleValueCharts.filter((chart) => chart !== dataset[dataset.type].chart);
        }
      } else if (dataset.type === 'responsesTotalValues') {
        return ['table', 'bar'].filter((chart) => chart !== dataset[dataset.type].chart);
      }
      //axis layout from edit widget can convert to any
      else if (axisCharts.includes(dataset[dataset.type].chart)) {
        if (dataset.type === 'responsesTotal') return nonAxisCharts;
        else return twoDimensionalCharts;
      } else if (nonAxisCharts.includes(dataset[dataset.type].chart)) {
        switch (true) {
          case ['issuesCount', 'issuesAverage', 'issuesDuration'].includes(dataset.type) &&
            dataset[dataset.type]['by'] === 'catalog':
          case dataset.type === 'issuesCount' &&
            (dataset[dataset.type].by === 'element' || dataset[dataset.type].by === 'time'):
          case ['actionsCount', 'issuesCount', 'issuesAverage', 'issuesDuration'].includes(dataset.type) &&
            dataset[dataset.type]['by'] === 'assignee':
            return isSingleValue() ? allCharts : twoDimensionalCharts;
          case ['responsesTotal'].includes(dataset.type):
            return dataset[dataset.type].chart === 'table' ? tableAlternativeCharts : nonAxisAlternativeCharts;
          default:
            return nonAxisCharts;
        }
      }

      return allCharts;
    }, []);

    return (
      <Dialog open={true} classes={{ paper: classes.editChartPaperContainer }} onClose={onClose}>
        <DialogTitle style={{ textAlign: 'center' }}>
          <Typography className={classes.editChartTitle}>{t('changeCard')}</Typography>
          <Typography className={classes.editChartText}>{t('selectNewChartView')}</Typography>
        </DialogTitle>
        <DialogContent>
          <Fade in={true}>
            <div
              className={
                suitableCharts.length === 1
                  ? classes.editChartItem
                  : `${classes.editChartItem} ${classes.editChartItemTwoColumns}`
              }
            >
              {suitableCharts
                .map((s) => chartsTable[s])
                .map((CardComponent, i) => (
                  <CardComponent key={i} classes={classes} t={t} setEditInfo={(info) => onChange(info.chartType)} />
                ))}
            </div>
          </Fade>
        </DialogContent>
      </Dialog>
    );
  }),
);

export default EditChart;
