import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { withStyles } from '@mui/styles';
import { Divider, Popover } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronRight, Close, FeedbackOutlined } from '@mui/icons-material';
import { styles } from 'src/utils/components/actions-popover/styles';
import { getActions, updateAction } from 'src/modules/actions/actions.redux';
import ModalCreateAction from 'src/modules/actions/components/create-modal';
import { Anchorme } from 'react-anchorme';
import { NexusGenFieldTypes, NexusGenInputs } from '../../../../../server/src/types';
import { useNavigate } from 'react-router-dom';
import DefaultButton from 'src/utils/components/default-button/default-button';
import {
  isInputLinked,
  getActionAfterLinkActionToIssue,
  mapActionsToUpdate,
  getActionAfterUnlinkActionFromIssue,
} from './utils/actions-popover.utils';

interface ActionsPopoverProps {
  classes: Record<string, string>;
  isHistory: boolean;
  task: {
    name: string;
    _id: string;
  };
  input: { _id: string; name: string };
  issue: {
    name: string;
    _id: string;
    site: { _id: string };
    element?: { _id: string };
  };
  onClose: () => void;
  getActions: (filter: NexusGenInputs['ActionWhereInput']) => Promise<NexusGenFieldTypes['Action'][]>;
  updateAction: (id, data) => Promise<NexusGenFieldTypes['Action'] & { graphQLErrors?: unknown }>;
  onUnlink?: (action: NexusGenFieldTypes['Action']) => void;
  onLink?: (action: NexusGenFieldTypes['Action']) => void;
  'data-testid'?: string;
}

const ActionsPopover = (props: ActionsPopoverProps): JSX.Element => {
  const { classes, onClose, issue, isHistory, task, input } = props;
  const [actions, setActions] = useState<NexusGenFieldTypes['Action'][]>([]);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    props
      .getActions({
        site: { _id_eq: issue?.site._id },
        element: issue?.element ? { _id_eq: issue?.element._id } : {},
        issues_every: task ? { issue_eq: issue._id, task_eq: task._id } : { issue_eq: issue._id },
      })
      .then((resp) => {
        setActions(resp);
      })
      .catch((e) => console.error(e));
  }, []);

  const showStatus = (action) => {
    switch (action.status) {
      case 'PENDING':
        return (
          <div
            data-testid={`action-status-${action.name ?? 'noNamedAction'}`}
            className={`${classes.statusShow} ${classes.statusPending}`}
          >
            {t('pending')}
          </div>
        );
      case 'CANT_DO':
        return (
          <div
            data-testid={`action-status-${action.name ?? 'noNamedAction'}`}
            className={`${classes.statusShow} ${classes.statusCantDo}`}
          >
            {t('cantDo')}
          </div>
        );
      case 'DOING':
        return (
          <div
            data-testid={`action-status-${action.name ?? 'noNamedAction'}`}
            className={`${classes.statusShow} ${classes.statusDoing}`}
          >
            {t('doing')}
          </div>
        );
      case 'SOLVED':
        return (
          <div
            data-testid={`action-status-${action.name ?? 'noNamedAction'}`}
            className={`${classes.statusShow} ${classes.statusSolved}`}
          >
            {t('solved')}
          </div>
        );
      default:
        return (
          <div data-testid={`action-status-${action.name ?? 'noNamedAction'}`} className={`${classes.statusShow}`}>
            {' - '}
          </div>
        );
    }
  };

  const linkActionToIssue = (action) => {
    const actionToLink = getActionAfterLinkActionToIssue(action, issue, task._id, input._id);
    const actionIssues = mapActionsToUpdate([actionToLink])[0].issues;
    props
      .updateAction(
        { _id: action._id },
        {
          issues: actionIssues,
        },
      )
      .then((resp) => {
        setActions(actions.map((act) => (act._id === action._id ? resp : act)));
        if (props.onLink) {
          props.onLink(resp);
        }
      })
      .catch((e) => console.error(e));
  };

  const unlinkActionToIssue = (action) => {
    const actionToUnlink = getActionAfterUnlinkActionFromIssue(action, issue._id, task._id, input._id);
    const actionIssues = mapActionsToUpdate([actionToUnlink])[0].issues;
    props
      .updateAction(
        { _id: action._id },
        {
          issues: actionIssues,
        },
      )
      .then((resp) => {
        setActions(actions.map((act) => (act._id === action._id ? resp : act)));
        if (props.onUnlink) {
          props.onUnlink(resp);
        }
      })
      .catch((e) => console.error(e));
  };

  const actionCard = (action) => {
    const isLinked = isInputLinked(action, issue?._id, task?._id, input?._id);

    return (
      <div key={action._id} className={classes.actionCardContainer}>
        <div style={{ margin: '10px', display: 'flex' }}>{showStatus(action)}</div>
        <div
          data-testid={`popup-open-action-${action.name ?? 'nameLessAction'}`}
          className={classes.divActionName}
          onClick={() => navigate(`/action?name=${action.name}&id=${action._id}`)}
        >
          <Anchorme target='_blank'>{action.name}</Anchorme>
          <ChevronRight style={{ marginLeft: 'auto' }} />
        </div>
        {isLinked ? (
          <div className={classes.popoverLinkButtonDiv}>
            <DefaultButton
              data-testid='btn-unlink-actions-popover'
              onClick={() => unlinkActionToIssue(action)}
              variant='delete-alt'
              size='small'
            >
              {t('unlinkFromInput')}
            </DefaultButton>
          </div>
        ) : input ? (
          <div className={classes.popoverLinkButtonDiv}>
            <DefaultButton
              data-testid='btn-link-actions-popover'
              onClick={() => linkActionToIssue(action)}
              variant='discard'
              size='small'
            >
              {t('linkToInput')}
            </DefaultButton>
          </div>
        ) : undefined}
        <Divider style={{ marginLeft: '-25px', marginRight: '-20px' }} />
      </div>
    );
  };

  return (
    <Popover
      open={true}
      onClose={(): void => {
        onClose();
      }}
      classes={{ paper: classes.popoverContainer }}
    >
      <div data-testid={props['data-testid']} className={classes.headerInfo}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={classes.infoType}>
            <FeedbackOutlined style={{ marginRight: '5px' }} />
            {`${t('actions')}`}
          </div>
          <Close
            data-testid='button-close-actions-history-popup'
            classes={{ root: classes.closeIcon }}
            onClick={() => onClose()}
          />
        </div>
        <div className={classes.infoTitle}>
          {issue ? (
            <div>
              <span className={classes.issueSpan}>{`${t('issue')}: `}</span>
              <span className={classes.issueNameSpan}>
                <Anchorme target='_blank'>{issue.name}</Anchorme>
              </span>
            </div>
          ) : null}
          {task ? (
            <div>
              <span className={classes.issueSpan}>{`${t('task')}: `}</span>
              <span className={classes.issueNameSpan}>
                <Anchorme target='_blank'>{task.name}</Anchorme>
              </span>
            </div>
          ) : null}
          {input ? (
            <div style={{ marginBottom: '20px' }}>
              <span className={classes.issueSpan}>{`${t('input')}: `}</span>
              <span className={classes.issueNameSpan}>
                <Anchorme target='_blank'>{input.name}</Anchorme>
              </span>
            </div>
          ) : null}
        </div>
      </div>
      <div className={classes.bodyInfo}>
        <Divider style={{ marginLeft: '-25px', marginRight: '-20px' }} />
        {actions
          .filter((a) =>
            input
              ? a?.input
                ? a.input === input._id
                  ? a
                  : true
                : a?.issues?.find((i) => i.task === task._id && i.input === input._id) || true
              : true,
          )
          .map((action) => actionCard(action))}
      </div>
      {isHistory ? undefined : (
        <div className={classes.divFooter}>
          <div
            style={{
              width: 'fit-content',
              marginRight: '10px',
              marginLeft: 'auto',
              display: 'flex',
            }}
          >
            <DefaultButton
              data-testid='createNewActionButton'
              variant={'default'}
              onClick={() => setOpenCreateModal(true)}
            >
              {t('createAction')}
            </DefaultButton>
          </div>
        </div>
      )}
      {openCreateModal ? (
        <ModalCreateAction
          onClose={() => {
            setOpenCreateModal(false);
          }}
          issue={issue}
        />
      ) : undefined}
    </Popover>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getActions,
      updateAction,
    },
    dispatch,
  );

export default compose<any>(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(ActionsPopover);
