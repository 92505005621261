import { clearRoom } from 'src/modules/remote-assistance/rooms/rooms.redux';
import {
  audioTrack,
  clearAudioTrack,
  clearRemoteUsers,
  clearScreenVideoTrack,
  client,
  screenVideoTrack,
  setAudioTrack,
} from './agoraClient';
import store from 'src/reducer-manager';
import { closeWebSocket, leaveRoomEvent } from '../sockets';
import { clearRoomData, clearSocket } from 'src/modules/remote-assistance/socket/socket.redux';
import {
  exitAgora,
  setAudioState,
  setRemoteNetworkQuality,
  setUserAudio,
} from 'src/modules/remote-assistance/agora/agora.redux';
import { NavigateFunction } from 'react-router-dom';
import WebSocketClient from '../Websocket';
import { constraints } from '../webrtc';
import AgoraRTC, { IAgoraRTCRemoteUser, IRemoteAudioTrack, IRemoteVideoTrack } from 'agora-rtc-sdk-ng';
import { WBS_EVENT_SESSION_DETAILS_UPDATE } from '../constants';
import { exitSession } from 'src/modules/remote-assistance/meeting-sidebar/meeting-sidebar.redux';
import reduxStore from 'src/reducer-manager';
import { showSnackbar } from 'src/base/root/root.redux';
import i18n from 'src/utils/translations/i18n';
import { setSelectedSpeaker } from 'src/modules/remote-assistance/rtc/rtc.redux';

export const stopMeetingServices = async (stream: any, setStream: any, keepRoom?: boolean): Promise<void> => {
  // Clearing of all transmissions of video and audio and the user object

  if (audioTrack) {
    audioTrack?.stop();
    audioTrack?.close();
    clearAudioTrack();
  }

  if (screenVideoTrack) {
    screenVideoTrack?.close();
    screenVideoTrack?.stop();
    clearScreenVideoTrack();
  }

  // TODO: this will be necessary if we implement camera
  /*
  if (cameraVideoTrack) {
    closeAndStopCameraVideoTrack();
  }*/

  if (client) {
    client.removeAllListeners();

    // Leave Channels
    try {
      await client.leave();
    } catch (error) {
      console.error(error);
    }
  }
  clearRemoteUsers();

  if (!keepRoom) {
    if (stream && typeof stream !== 'number' && stream.active) {
      // get all tracks from the MediaStream and stop each of them
      (stream?.getTracks() ?? [])?.forEach((track) => track?.stop());
    }

    if (stream) setStream(null);
    store.dispatch(clearRoom());

    const userConfig = store.getState().loginReducer.userConfig;
    await leaveRoomEvent(userConfig?.room?.id);
  }

  closeWebSocket();
  store.dispatch(clearSocket());
  if (!keepRoom) store.dispatch(clearRoomData());

  // Clear variables
  store.dispatch(exitSession());
  store.dispatch(exitAgora());
};

export const leaveAgora = async (
  navigate: NavigateFunction,
  stream: any,
  setStream: any,
  isOriginTab: boolean,
): Promise<void> => {
  try {
    await stopMeetingServices(stream, setStream);

    //const url = specificUrl ? specificUrl.url : '/remote-assistance';

    // TODO: this here will be to have custom navigations when we have the summary page for the session to see the screenshots that were taken
    /*
    history.push(
      state.meetingSidebarReducer.screenshots.length !== 0 ? `/session/${userConfig.room.code}/summary` : url,
      state.meetingSidebarReducer.screenshots.length !== 0
        ? { roomId: userConfig.room.id, screenshots: state.meetingSidebarReducer.screenshots, action: url }
        : null,
    );*/
    if (isOriginTab) {
      window.close();
    } else {
      navigate('/remote-assistance');
    }
  } catch (error) {
    reduxStore.dispatch(
      showSnackbar(
        'error',
        `${i18n.t('thereWasanErrorPleaseTryAgain')}. ${i18n.t('ifTheProblemPersistsPleaseContactSupport')}.`,
      ),
    );
  }
};

// Change microphone status
export const changeMicrophoneState = async (
  status: boolean,
  socket: WebSocketClient,
  showBlockedMicrophoneDialog: () => void,
): Promise<void> => {
  try {
    await navigator.mediaDevices.getUserMedia(constraints);
    const state = store.getState();

    if (!audioTrack) {
      const newAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      setAudioTrack(newAudioTrack);
    }

    await audioTrack.setEnabled(status);
    store.dispatch(setAudioState(status));

    const meUser = state.socketReducer.meUser;

    await socket.sendMessage(WBS_EVENT_SESSION_DETAILS_UPDATE, {
      ...meUser?.user_details,
      microphone: status,
    });

    if (status) {
      await client.publish(audioTrack);
    }
  } catch (error) {
    showBlockedMicrophoneDialog();
  }
};

// Change user microphone status
export const changeRemoteMicrophoneState = (uid: string, status: boolean): void => {
  const remoteUsers = store?.getState()?.agoraReducer?.usersAgora;
  if (!remoteUsers) return;

  if (remoteUsers[uid] && (remoteUsers[uid] as IAgoraRTCRemoteUser)?.audioTrack) {
    (remoteUsers[uid] as IAgoraRTCRemoteUser)?.audioTrack?.setVolume(status ? 0 : 100);
    store.dispatch(setUserAudio({ uId: uid, audioState: status }));
  }
};

// Change microphone device
export const changeMicrophoneDevice = async (deviceId: string): Promise<void> => {
  if (audioTrack || deviceId) {
    try {
      await audioTrack?.setDevice(deviceId);
    } catch (error) {
      void error;
    }
  }
};

// Change speaker device
export const changeSpeakerDevice = (deviceId: string): void => {
  if (!deviceId) return;

  const remoteUsers = store?.getState()?.agoraReducer?.usersAgora;
  if (!remoteUsers) return;

  if (Object.keys(remoteUsers).length !== 0)
    Object.keys(remoteUsers).forEach((uid: string) => {
      if ((remoteUsers[uid] as IAgoraRTCRemoteUser)?.audioTrack) {
        (remoteUsers[uid] as IAgoraRTCRemoteUser)?.audioTrack?.setPlaybackDevice(deviceId);
      }
    });
};

export const changeSpeaker = (speaker: string): void => {
  changeSpeakerDevice(speaker);
  localStorage.setItem('speaker', speaker);

  reduxStore.dispatch(setSelectedSpeaker(speaker));
};

// Change status of all users on call
export const changeStatusOfAllMicrophones = (status: boolean): void => {
  const remoteUsers = store?.getState()?.agoraReducer?.usersAgora;
  if (!remoteUsers) return;

  if (Object.keys(remoteUsers).length === 0) return;
  Object.entries(remoteUsers).forEach((user) => {
    const [key] = user;
    if ((remoteUsers[key] as IAgoraRTCRemoteUser).audioTrack) {
      (remoteUsers[key] as IAgoraRTCRemoteUser).audioTrack.setVolume(!status ? 0 : 100);
    }
  });
};

// Change network quality status
export const changeNetWorkQualityState = (uid: string, assistedUserQuality?: number): void => {
  const remoteUsers = store?.getState()?.agoraReducer?.usersAgora;
  if (!remoteUsers) return;

  const assistedUser = remoteUsers[uid];

  if (!assistedUser || !assistedUser._videoTrack) return;

  const { receiveFrameRate, receiveBitrate, receiveResolutionWidth, receiveResolutionHeight } = (
    assistedUser._videoTrack as IRemoteVideoTrack
  ).getStats();

  let uplinkNetworkQuality = 0;

  if (client?.getRemoteNetworkQuality())
    uplinkNetworkQuality = client?.getRemoteNetworkQuality()[uid]?.uplinkNetworkQuality;

  store.dispatch(
    setRemoteNetworkQuality({
      bitRate: receiveBitrate,
      frameRate: receiveFrameRate,
      networkQuality: assistedUserQuality ?? uplinkNetworkQuality,
      resolutionWidth: receiveResolutionWidth,
      resolutionHeight: receiveResolutionHeight,
    }),
  );
};

export const startUsersAudioPlay = (): void => {
  const remoteUsers = store?.getState()?.agoraReducer?.usersAgora;
  if (!remoteUsers) return;

  Object.keys(remoteUsers).forEach((uid) => {
    if (remoteUsers[uid]?.audioTrack && !remoteUsers[uid]?.audioTrack?.isPlaying)
      (remoteUsers[uid].audioTrack as IRemoteAudioTrack).play();
  });
};
