import { withStyles, WithStyles } from '@mui/styles';
import { styles } from './styles';
import { ISelectListItem } from '../select-list-item';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Checkbox, Typography } from '@mui/material';
import SelectResponseListItem from '../select-response-list-item';

type SelectResponseListProps = {
  options: ISelectListItem[];
  setOptions: (opts: ISelectListItem[]) => void;
} & WithStyles<typeof styles>;

const SelectResponseList = ({ classes, options, setOptions }: SelectResponseListProps) => {
  const { t } = useTranslation();

  const handleItemClick = (item: ISelectListItem) => {
    const updatedOptions = options?.map((option) =>
      option._id === item._id ? { ...option, isSelected: !option.isSelected } : option,
    );
    setOptions?.(updatedOptions);
  };

  const areAllChecked = useMemo(() => options?.every((o) => o.isSelected), [options]);

  const selectAllOptions = () => {
    const updatedOptions = options?.map((o) => ({ ...o, isSelected: areAllChecked ? false : true }));
    setOptions?.(updatedOptions);
  };

  return (
    <>
      <div>
        <div className={classes.popoverHeaderDiv}>
          <Checkbox
            key={'better-dropdown-response-all'}
            data-testid={'better-dropdown-response-all'}
            checked={areAllChecked}
            onClick={() => {
              selectAllOptions();
            }}
            size={'small'}
            className={classes.checkboxIcon}
          />
          <div>
            <Typography variant='body2'>
              <span>{t('all')}</span>
            </Typography>
          </div>
        </div>
        <hr className={classes.divider} />
      </div>

      <ul className={'MuiList-root MuiList-padding'} style={{ overflowY: 'auto', height: '100%', margin: 0 }}>
        {options?.map((response) => (
          <SelectResponseListItem key={response._id} item={response} onClick={handleItemClick} />
        ))}
      </ul>
    </>
  );
};

export default withStyles(styles)(SelectResponseList);
