import gql from 'graphql-tag';

export const ALERT_TO_TABLE = gql`
  fragment alertToTable on Alert {
    __typename
    name
    _id
    createdAt
    closedAt
    trigger {
      condition
      amount
      showIn
    }
    task
    input {
      name
      _id
      type
      date {
        values
      }
      string {
        values
      }
      number {
        values
      }
    }
    element {
      name
      _id
      site {
        name
        _id
      }
      profile {
        name
      }
    }
    site {
      _id
      name
      coordinates
    }
  }
`;
