import { Divider, TextField, Tooltip, Typography } from '@mui/material';
import { Delete, DragIndicator } from '@mui/icons-material';
import * as React from 'react';
import { DragSourceMonitor, XYCoord, useDrag, useDrop } from 'react-dnd';
import { WithTranslation, useTranslation, withTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { OptionInputType, PresetWhereInput } from 'src/gql/graphql';
import { styles } from 'src/utils/components/selection-presets-popover/components/response-settings-popover/components/response-item/styles';
import { WithStyles, withStyles } from '@mui/styles';

type ResponseItemProps = {
  classes: Record<string, string>;
  index: number;
  addPreset: boolean;
  selectedPreset: PresetWhereInput;
  option: OptionInputType;
  editingResponse: number | null;
  setEditingResponse: (val: number | null) => void;
  setSelectedPreset: (preset: ((prevPreset: PresetWhereInput) => PresetWhereInput) | PresetWhereInput) => void;
} & ConnectedProps<typeof connecter> &
  WithStyles<typeof styles> &
  WithTranslation;

const ResponseItem: React.FC<ResponseItemProps> = (props): JSX.Element => {
  const { classes, index, addPreset, selectedPreset, option, editingResponse, setEditingResponse, setSelectedPreset } =
    props;
  const [showDelete, setDeleteShow] = React.useState(null);
  const [hoveredIndex, setHoveredIndex] = React.useState(null);
  const [draggedPosition, setDraggedPosition] = React.useState<null | { aboveMiddle: boolean; belowMiddle: boolean }>(
    null,
  );
  const [hoverDragIcon, setHoverDragIcon] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>();
  const refHandle = React.useRef<HTMLDivElement>();
  const { t } = useTranslation();
  const presetEditOneResponse = React.useMemo<boolean>(
    () => selectedPreset.options.length === 1,
    [selectedPreset.options.length, addPreset],
  );

  const [{ isDragging }, connectGroupDrag, connectGroupPreview] = useDrag(
    () => ({
      type: 'RESPONSE',
      canDrag: true,
      item: { id: index, originalIndex: option.order, type: 'RESPONSE' },
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [option, index],
  );

  const [{ isOver }, connectGroupDrop] = useDrop(
    () => ({
      accept: 'RESPONSE',
      canDrop: () => true,
      hover: (draggedId: { id: number }, monitor) => {
        if (!refHandle.current) {
          setDraggedPosition(null);
          setHoveredIndex(null);
          return;
        }

        const dragIndex = draggedId.id;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          setDraggedPosition(null);
          setHoveredIndex(null);
          return;
        }

        const targetBoundingRect = ref.current?.getBoundingClientRect();

        const hoverMiddleY = (targetBoundingRect.bottom - targetBoundingRect.top) / 2;

        const clientOffset = monitor.getClientOffset();

        const hoverClientY = (clientOffset as XYCoord).y - targetBoundingRect.top;

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          setDraggedPosition(null);
          setHoveredIndex(null);
          return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          setDraggedPosition(null);
          setHoveredIndex(null);
          return;
        }

        setHoveredIndex(hoverIndex);

        setSelectedPreset((prevPreset: PresetWhereInput) => {
          const draggedResponse = prevPreset.options[dragIndex];
          const updatedOptions = [...prevPreset.options];
          updatedOptions.splice(dragIndex, 1);
          updatedOptions.splice(hoverIndex, 0, draggedResponse);
          return {
            ...prevPreset,
            options: updatedOptions,
          };
        });

        draggedId.id = hoverIndex;
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [],
  );

  connectGroupDrag(refHandle);
  connectGroupDrop(refHandle);
  connectGroupPreview(ref);

  React.useEffect(() => {
    if (draggedPosition) {
      setDraggedPosition(null);
    }
  }, [isOver]);

  return (
    <>
      {isDragging && ref.current ? (
        <div className={classes.responseSetting}></div>
      ) : (
        <>
          <Divider
            className={
              index === hoveredIndex && draggedPosition?.aboveMiddle ? classes.dividerHovered : classes.dividerResponse
            }
          />
          <div
            className={classes.responseSetting}
            key={index}
            onMouseOver={() => {
              setDeleteShow(index);
            }}
            onMouseLeave={() => setDeleteShow(null)}
            ref={ref}
          >
            <div
              onMouseOver={() => {
                setHoverDragIcon(true);
              }}
              onMouseLeave={() => setHoverDragIcon(false)}
              ref={refHandle}
              data-testid={`draggable-preset-${index}`}
            >
              <DragIndicator className={classes.dragIcon} />
            </div>
            {(addPreset && selectedPreset.options.length === 1) ||
            editingResponse === index ||
            option.response.trim().length === 0 ? (
              <TextField
                autoFocus={addPreset || editingResponse === index}
                data-testid={`response-preset-${index}`}
                onFocus={() => setEditingResponse(index)}
                onChange={(e) => {
                  const updatedValue = e.target.value;
                  setSelectedPreset((prev) => ({
                    ...prev,
                    options: prev.options.map((option, i) =>
                      i === index ? { ...option, response: updatedValue } : option,
                    ),
                  }));
                }}
                onBlur={() => setEditingResponse(null)}
                fullWidth
                size={'small'}
                margin={'none'}
                variant={'outlined'}
                classes={{ root: classes.editResponse }}
                placeholder={t('newResponse')}
                value={option.response}
              />
            ) : (
              <div
                style={{ flexGrow: 1, cursor: 'text' }}
                className={option.conditionals.length > 0 && classes.hasConditionalsContainer}
                onClick={() => !option.conditionals.length && setEditingResponse(index)}
              >
                <Typography data-testid={`response-preset-${index}`} classes={{ root: classes.response }}>
                  {option.response}
                </Typography>
                {option.conditionals.length > 0 && (
                  <Typography
                    data-testid={`response-preset-conditional-${index}`}
                    classes={{ root: classes.responseInConditional }}
                  >
                    {t('responseUsedInConditional')}
                  </Typography>
                )}
              </div>
            )}
            <Tooltip
              title={t('delete')}
              style={{
                visibility:
                  showDelete === index && !hoverDragIcon && !option.conditionals.length ? 'visible' : 'hidden',
              }}
            >
              <Delete
                data-testid={`delete-preset-${index}`}
                color={presetEditOneResponse ? 'disabled' : 'error'}
                style={{
                  justifySelf: 'flex-end',
                  cursor: presetEditOneResponse ? 'default' : 'pointer',
                  visibility:
                    showDelete === index && !hoverDragIcon && !option.conditionals.length ? 'visible' : 'hidden',
                }}
                className={classes.deleteIcon}
                onClick={() => {
                  if (!presetEditOneResponse) {
                    const updatedPreset = [...selectedPreset.options];
                    updatedPreset.splice(index, 1);
                    setSelectedPreset({ ...selectedPreset, options: updatedPreset });
                  }
                }}
              />
            </Tooltip>
          </div>
          <Divider
            className={
              index === hoveredIndex && draggedPosition?.belowMiddle ? classes.dividerHovered : classes.dividerResponse
            }
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

const connecter = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation('translation')(withStyles(styles)(connecter(ResponseItem)));
