import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    badge: {
      height: '20px',
      display: 'flex',
      padding: '0 6px',
      zIndex: 0,
      marginRight: '30px',
      flexWrap: 'wrap',
      fontSize: '0.75rem',
      minWidth: '20px',
      boxSizing: 'border-box',
      alignItems: 'center',
      fontWeight: 500,
      alignContent: 'center',
      borderRadius: '10px',
      flexDirection: 'row',
      justifyContent: 'center',
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
    },
  });
