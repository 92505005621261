import * as React from 'react';
import { styles } from './styles';
import { Chip, Avatar, Tooltip, Typography } from '@mui/material';
import { LabelValue as GQLLabelValue } from 'src/gql/graphql';
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap } from '@mui/styles/withStyles';

type LabelValue = Pick<GQLLabelValue, '_id' | 'backgroundColor' | 'label' | 'value' | 'description'>;

export type ChipLabelProps = {
  /** Array of Label Values with required fields: _id, backgroundColor, label, value and description */
  labelValues: LabelValue[];
  sideMenu?: boolean;
};

const useStyles = makeStyles(styles);

const ChipLabel: React.FC<ChipLabelProps> = (props): JSX.Element => {
  const { labelValues, sideMenu } = props;
  const classes: ClassNameMap<string> = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.labelsContainer} data-testid='chips-labels'>
        <div className={sideMenu ? classes.sideMenu : classes.disabledWithBadge}>
          {labelValues?.length
            ? labelValues.map((labelValue, index) => (
                <div key={index}>
                  {labelValue?.description ? (
                    <Tooltip
                      PopperProps={{ style: { zIndex: 3333 } }}
                      title={labelValue?.description}
                      placement='top'
                      arrow
                    >
                      <div key={index}>
                        <ChipBody sideMenu={sideMenu} labelValue={labelValue} index={index} />
                      </div>
                    </Tooltip>
                  ) : (
                    <div key={index}>
                      <ChipBody sideMenu={sideMenu} labelValue={labelValue} index={index} />
                    </div>
                  )}
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

type ChipBodyProps = {
  labelValue: LabelValue;
  index: number;
  sideMenu: boolean;
};

const ChipBody: React.FC<ChipBodyProps> = (props) => {
  const classes: ClassNameMap<string> = useStyles();
  const { labelValue, index, sideMenu } = props;
  return (
    <span
      data-testid={`chip-label-[${labelValue?._id}]`}
      className={sideMenu ? classes.spanLabelSideMenu : index === 0 ? classes.spanLabel : classes.spanLabelNotFirst}
    >
      <Chip
        avatar={
          <Avatar
            classes={{ root: classes.avatar }}
            style={{ backgroundColor: labelValue?.backgroundColor, margin: '0px 0px 0px 3px', borderRadius: '16px' }}
          >
            <Typography variant='caption'>{labelValue?.label.name}</Typography>
          </Avatar>
        }
        label={<Typography variant='caption'>{labelValue?.value}</Typography>}
        style={{
          borderColor: labelValue?.backgroundColor,
          color: labelValue?.backgroundColor,
        }}
        color={'primary'}
        variant='outlined'
        size='small'
        classes={{
          root: `${classes.chip} ${labelValue?.description ? classes.pointer : ''}`,
          labelSmall: classes.labelSmall,
        }}
      />
    </span>
  );
};

export default ChipLabel;
