import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    card: {
      borderRadius: '12px',
      border: 'none',
      width: '100%',
      margin: 0,
      height: '20vh',
    },
    container: {
      padding: 0,
    },
    dropzone: {
      height: '100%',
      backgroundColor: theme.palette.action.disabledBackground,
      borderRadius: '5px',
      cursor: 'pointer',
    },
    row: {
      textAlign: 'center',
    },
    icon: {
      margin: '5px 15px 0 0',
      paddingTop: '20px',
    },
    text: {
      margin: '-5px 15px 0 0',
      /* color: theme.palette.common.white, */
    },
    preview: {
      marginTop: '35px',
      display: 'flex',
      flexDirection: 'column',
    },
    previewLabel: {
      width: '100%',
      textAlign: 'center',
      fontWeight: 500,
    },
    imageCropper: {
      position: 'relative',
      overflow: 'hidden',
      borderRadius: '50%',
    },
    center: {
      margin: 'auto',
    },
    photo: {
      display: 'inline',
      height: '100%',
      width: '100%',
    },
    left: {
      margin: 'auto auto auto 0',
    },
  });
