import { APOLLO_CLIENT } from 'config/apollo.config';
import { Dispatch } from 'redux';
import { getErrorObject } from 'src/utils/funcs';
import { CreateAccountByInviteDocument, IsValidDomainDocument } from 'src/gql/graphql';

export const createAccountByInvite =
  (domain: string, token: string, password: string, repeatPassword: string) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          domain,
          token,
          password,
          repeatPassword,
        },
        fetchPolicy: 'no-cache',
        mutation: CreateAccountByInviteDocument,
      });

      return response.data.createAccountByInvite;
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const isValidDomain = (domain: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: { domain },
      fetchPolicy: 'no-cache',
      query: IsValidDomainDocument,
    });

    return response.data.isValidDomain;
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};
