import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DefaultButton from '../default-button';
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { styles } from 'src/utils/components/lose-content-modal/styles';

type Props = {
  handleSubmit: () => void;
  handleCancel: () => void;
};

const useStyles = makeStyles(styles);

const ConfirmNavigation = ({ handleSubmit, handleCancel }: Props) => {
  const { t } = useTranslation();
  const classes: ClassNameMap<string> = useStyles();

  return (
    <Dialog open={true} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <DialogTitle className={classes.title} id='alert-dialog-title'>
        {t('leaveWithoutSaving')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description' className={classes.subTitle}>
          {t('leaveWithoutSavingSubText')}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.footer}>
        <DefaultButton data-testid={'cancel-changes-btn'} discard onClick={handleCancel} label={t('cancel')} />
        <DefaultButton data-testid={'leave-modal-btn'} success onClick={handleSubmit} label={t('leave')} />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmNavigation;
