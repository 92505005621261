import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { issueSignature /* , TaskInstance */ } from 'src/interfaces/issue';
import { getActionsCount } from 'src/modules/actions/actions.redux';
import { getAlerts } from 'src/modules/alert/alert.redux';
import { getFiles } from 'src/modules/file-storage/file-storage.redux';
import { basicReport } from 'src/utils/components/basic-report';
import { detailedReport } from 'src/utils/components/detailed-report';
import { getIssueRecord } from 'src/modules/issue-history/issue.history.redux';
import { getActions } from 'src/modules/actions/actions.redux';
import { Action, TenantBasicInfo } from 'src/gql/graphql';
import { typedConnect } from 'src/utils/funcs';
import { ConnectedProps } from 'react-redux';

/* interface TaskGroup {
  name: string;
  _id: string;
  tasks: TaskInstance[];
} */

export type ActionsDictionary = {
  inputId: string;
  actions: Action[];
};

interface signatureSourceLinks {
  src: string;
  extension: string;
  id: string;
}

interface IssueReportProps extends ConnectedProps<typeof connector> {
  location: {
    search: string;
  };
}

const InputReport = (props: IssueReportProps) => {
  const { id, detailed } = useParams<{ id: string; detailed: string }>();

  const { t } = useTranslation();

  const findConditionalInputsRecursively = (resp) => {
    const condInputsList = [];
    const findConditionalInputs = (executionInputs) => {
      executionInputs.flatMap((ei) => {
        const inputType = ei[ei.type];
        if (inputType?.onResponse?.length) {
          inputType.onResponse.flatMap((or) =>
            or.inputs.flatMap((inp) => {
              condInputsList.push(inp._id);
              findConditionalInputs([inp]);
            }),
          );
        }
      });
    };
    resp.taskGroups.flatMap((tg) => {
      tg.tasks.flatMap((t) => {
        findConditionalInputs(t.executionInputs);
      });
    });
    return condInputsList;
  };

  useEffect(() => {
    if (!id) {
      window.close();
    }
    if (!props.tenantInfo?.resources) return;
    props.getIssueRecord(id).then(
      async (resp) => {
        if (!('graphQLErrors' in resp)) {
          const actionsCount = await props.getActionsCount({
            issues_some: { issue_eq: resp._id },
          });
          const inputs = resp.taskGroups.flatMap((tg) =>
            tg.tasks.flatMap((t) => t.executionInputs.map((ei) => ei._id)),
          );

          const allInputs = inputs.concat(findConditionalInputsRecursively(resp));
          const actionsFromInputs = await props.getActions({
            issues_some: { issue_eq: resp._id },
          });

          const actionsDict: ActionsDictionary[] = [];
          allInputs.map((inputId) => {
            const tmpActions = actionsFromInputs.filter((action) =>
              action.issues.some((iss) => {
                //@ts-ignore
                return iss.issue._id === resp._id && iss.input === inputId;
              }),
            );
            actionsDict.push({
              inputId: inputId,
              actions: tmpActions as Action[],
            });
          });

          /* let tasks: TaskInstance[] = [];
          resp.taskGroups.forEach((exec) => {
            tasks = [
              ...tasks,
              ...exec.tasks.filter((t) => (t.onResponseId && t.isActiveByOnResponse) || !t.onResponseId),
            ];
          }); */

          renderReport(resp, actionsCount, actionsDict, props.tenantInfo)
            .then(() => {
              window.open('', '_self', '');
              setTimeout(() => window.close(), 100);
            })
            .catch(() => {});
        } else {
          window.close();
        }
      },
      (e) => {
        console.error(e);
      },
    );
  }, [props.tenantInfo]);

  const totalTasks = (taskGroups) => {
    let total = 0;
    taskGroups.forEach((ex) => {
      total = total + ex.tasks.filter((t) => (t.onResponseId && t.isActiveByOnResponse) || !t.onResponseId).length;
    });

    return total;
  };

  const totalFinishedTasks = (taskGroups) => {
    let total = 0;
    taskGroups.forEach((ex) => {
      total =
        total +
        ex.tasks.filter(
          (task) =>
            task.stateMachineInstance.finishedAt &&
            ((task.onResponseId && task.isActiveByOnResponse) || !task.onResponseId),
        ).length;
    });

    return total;
  };

  const getSignatureImageSrc = (issue) => {
    const srcArray: signatureSourceLinks[] = [];
    issue.taskGroups.map((taskGroup) =>
      taskGroup.tasks.map((task) =>
        task.executionInputs.map((input) =>
          input.type === 'signature'
            ? (input[input.type] as issueSignature).values.map((value) =>
                props
                  .getFiles({ _id_in: [value.image._id] })
                  .then((resp) =>
                    srcArray.push({ src: resp[0].download.url, extension: resp[0].extension, id: value.image._id }),
                  ),
              )
            : null,
        ),
      ),
    );
    return srcArray;
  };

  const renderReport = async (
    issue,
    actionsCount: number,
    inputsWithActionsDict: ActionsDictionary[],
    tenantInfo: TenantBasicInfo,
  ) => {
    const srcArray = getSignatureImageSrc(issue);

    detailed === 'true'
      ? await detailedReport(
          issue,
          t,
          totalFinishedTasks(issue.taskGroups),
          totalTasks(issue.taskGroups),
          actionsCount,
          srcArray.length > 0 ? srcArray : null,
          inputsWithActionsDict,
          tenantInfo,
        )
      : detailed === 'false'
        ? basicReport(
            issue,
            t,
            totalFinishedTasks(issue.taskGroups),
            totalTasks(issue.taskGroups),
            srcArray.length > 0 ? srcArray : null,
            inputsWithActionsDict,
            actionsCount,
            tenantInfo,
          )
        : null;
  };

  return null;
};

const connector = typedConnect(
  (state) => ({
    tenantInfo: state.authenticationReducer.tenantInfo,
  }),
  {
    getIssueRecord,
    getActionsCount,
    getAlerts,
    getFiles,
    getActions,
  },
);

export default connector(InputReport);
