import { APOLLO_CLIENT } from 'config/apollo.config';
import { Action, State } from 'src/interfaces/reducers';
import i18n from 'src/utils/translations/i18n';
import { getErrorObject, addSpace } from 'src/utils/funcs';
import * as queries from 'src/modules/role/role.queries';
import { Dispatch } from 'redux';
import {
  PermissionAssignCreateInput,
  PermissionBundle,
  Role,
  RoleCreateInput,
  RoleWhereInput,
  GetIsRolesAssignedToAccountsDocument,
  DeleteManyRolesDocument,
  ReplaceRolesAssignedAndDeleteDocument,
} from 'src/gql/graphql';
import { GraphQLErrors } from '@apollo/client/errors';

export const GET_ROLES = 'GET_ROLES';

export const ERROR = 'ERROR';

const initialState: State = {
  roles: [],
  folders: [],
  searchResults: [],
  modalResults: [],
  countRoles: 0,
  bundles: [],
  error: false,
};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case GET_ROLES:
      return { ...state, roles: action.payload };
    default:
      return state;
  }
};

export const getRoles = (filter: RoleWhereInput, limit, skip) => async (dispatch: Dispatch) => {
  try {
    const response: { data: { roles: Role[] } } = await APOLLO_CLIENT.query({
      variables: {
        filter,
        limit,
        skip,
        orderBy: ['name_ASC'],
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ROLES,
    });
    dispatch({
      type: GET_ROLES,
      payload: { roles: response.data.roles },
    });
    return response.data.roles;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    dispatch({
      type: ERROR,
      payload: error,
    });

    return error;
  }
};

export const getRolesCount = (filter: RoleWhereInput) => async (dispatch: Dispatch) => {
  try {
    const response: { data: { rolesCount: number } } = await APOLLO_CLIENT.query({
      variables: {
        filter,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ROLES_COUNT,
    });
    return response.data.rolesCount;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    dispatch({
      type: ERROR,
      payload: error,
    });

    return error;
  }
};

export const isRolesAssignedToAccounts = (ids) => async (dispatch: Dispatch) => {
  try {
    const response: { data: { isRolesAssignedToAccounts: boolean } } = await APOLLO_CLIENT.query({
      variables: {
        ids,
      },
      fetchPolicy: 'no-cache',
      query: GetIsRolesAssignedToAccountsDocument,
    });
    return response.data.isRolesAssignedToAccounts;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    dispatch({
      type: ERROR,
      payload: error,
    });

    return error;
  }
};

export const createRole =
  (data: Partial<RoleCreateInput> & { clearances: object[] }) =>
  async (dispatch: Dispatch): Promise<Role | { graphQLErrors: GraphQLErrors }> => {
    try {
      let response;
      if (data.clearances) {
        response = await APOLLO_CLIENT.mutate({
          variables: {
            name: data.name,
            description: data.description,
          },
          mutation: queries.M_CREATE_ROLE,
        });
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('role') + addSpace(data.name) + i18n.t('toastCreateSuccess'),
            severity: 'success',
          },
        });
      }
      if (response) return response.data.createRole;
      return null;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      dispatch({
        type: ERROR,
        payload: error,
      });

      return error;
    }
  };

export const updateRole = (data: Partial<Role>) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        name: data.name,
        description: data.description,
        _id: data._id,
      },
      mutation: queries.M_UPDATE_ROLE,
    });

    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: i18n.t('role') + addSpace(data.name) + i18n.t('toastUpdateSuccess'),
        severity: 'success',
      },
    });
    return response.data.updateRole;
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    dispatch({
      type: ERROR,
      payload: error,
    });

    return error;
  }
};

export const deleteManyRoles =
  (ids: string[]) =>
  async (dispatch: Dispatch): Promise<void | unknown> => {
    try {
      const resp = await APOLLO_CLIENT.mutate({
        variables: {
          ids: ids,
        },
        mutation: DeleteManyRolesDocument,
      });

      return resp.data.deleteManyRoles;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      dispatch({
        type: ERROR,
        payload: error,
      });

      return error;
    }
  };

export const replaceRolesAssignedAndDelete =
  (_idsToDelete: string[], _idsToReplace: string[]) =>
  async (dispatch: Dispatch): Promise<void | unknown> => {
    try {
      const resp = await APOLLO_CLIENT.mutate({
        variables: {
          _idsToDelete: _idsToDelete,
          _idsToReplace: _idsToReplace,
        },
        mutation: ReplaceRolesAssignedAndDeleteDocument,
      });

      return resp.data.replaceRolesAssignedAndDelete;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      dispatch({
        type: ERROR,
        payload: error,
      });

      return error;
    }
  };

export const duplicateRole =
  (_id: string, copyChildren: boolean) =>
  async (dispatch: Dispatch): Promise<Role> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { _id, copyChildren },
        fetchPolicy: 'no-cache',
        mutation: queries.M_DUPLICATE_ROLE,
      });

      return response.data.duplicateRole;
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getRole =
  (id: string) =>
  async (dispatch: Dispatch): Promise<Role> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_ROLE,
      });

      return response.data.role;
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      dispatch({
        type: ERROR,
        payload: error,
      });

      return error;
    }
  };

export const assignBundle =
  (roleType: string, { _id }: { _id: string }, bundles: PermissionAssignCreateInput[]) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          roleType,
          role: _id,
          bundles,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_BUNDLE_ASSIGN,
      });

      return response.data.permissionBundleAssign.role;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getBundles =
  () =>
  async (dispatch: Dispatch): Promise<PermissionBundle[]> => {
    try {
      const response = await APOLLO_CLIENT.query({
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_BUNDLES,
      });

      return response.data.permissionBundles;
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };
