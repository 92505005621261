import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { v4 } from 'uuid';

const RefreshContext = createContext<() => any>(null);

export function RefreshProvider({ children }: { children: ReactNode }) {
  const [randomKey, setRandomKey] = useState<string>(null);

  const triggerRefresh = useCallback(() => setRandomKey(v4()), []);

  return (
    <RefreshContext.Provider value={triggerRefresh} key={randomKey}>
      {children}
    </RefreshContext.Provider>
  );
}

export function useRefresh() {
  const ret = useContext(RefreshContext);

  if (!ret) {
    throw new Error('no refresh context');
  }

  return ret;
}

export function withRefresh<T>(Component: React.FC<T>) {
  return (props: T) => (
    <RefreshProvider>
      <Component {...props} />
    </RefreshProvider>
  );
}
