import { MainContainerState } from 'src/base/main-container/context/main.container.context';
import { LeaveTutorialModalProps } from '../components/leave.tutorial.modal';

const leaveTutorialInTemplate = async (props: LeaveTutorialModalProps) => {
  props.updateLoggedUser(
    {
      id: props.loggedUser._id,
      onBoarding: {
        ...props.loggedUser?.onBoarding,
        doingOnboarding: false,
        templateDraft: props.loggedUser?.onBoarding?.templateDraft?._id,
        template: null,
        openIssueDraft: null,
        openIssue: null,
        issueInstance: null,
      },
    },
    false,
  );
};

const leaveTutorialInOpenIssue = async (props: LeaveTutorialModalProps) => {
  props.updateLoggedUser(
    {
      id: props.loggedUser._id,
      onBoarding: {
        ...props.loggedUser?.onBoarding,
        doingOnboarding: false,
        template: props.loggedUser?.onBoarding?.template._id,
        openIssueDraft: props.loggedUser?.onBoarding?.openIssueDraft._id,
        openIssue: null,
        issueInstance: null,
      },
    },
    false,
  );
};

const leaveTutorialInExecution = (props: LeaveTutorialModalProps) => {
  props.updateLoggedUser(
    {
      id: props.loggedUser._id,
      onBoarding: {
        ...props.loggedUser?.onBoarding,
        doingOnboarding: false,
        template: props.loggedUser?.onBoarding?.template._id,
        openIssueDraft: null,
        openIssue: props.loggedUser?.onBoarding?.openIssue._id,
        issueInstance: props.loggedUser?.onBoarding?.issueInstance._id,
      },
    },
    false,
  );
};

/**
 * This will handle the progress save whenever the user clicks to leave
 * the tutorial and redirect him to the root page
 * @param state
 * @param props
 * @param updateMainContainerState
 * @param navigate
 */
export const leaveTutorialMode = (
  state: MainContainerState,
  props: LeaveTutorialModalProps,
  updateMainContainerState: (newData: Partial<MainContainerState>) => void,
  navigate,
) => {
  switch (state.currentStep) {
    case 'build':
      leaveTutorialInTemplate(props);
      break;
    case 'createIssue':
    case 'createTasks':
      leaveTutorialInOpenIssue(props);
      break;
    case 'execute':
      leaveTutorialInExecution(props);
      break;
  }
  updateMainContainerState({ doingOnBoarding: false, openModalLeaveTutorial: false, startSubSteps: false });
  navigate('/');
};
