import { Info } from '@mui/icons-material';
import { FormGroup, InputAdornment, OutlinedInput } from '@mui/material';
import { withStyles } from '@mui/styles';
import * as React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { styles } from 'src/base/login/styles/login';
import DefaultButton from 'src/utils/components/default-button/default-button';

interface ResetForm {
  emailRef: React.RefObject<HTMLInputElement>;
  t: (prop: string) => string;
  callback: (token: string) => void;
  saving: boolean;
  recover: () => void;
  classes: Record<string, string>;
}

const ResetForm = (props: ResetForm) => {
  const { emailRef, t, callback, saving, recover } = props;
  const [token] = useState<string>('');

  return (
    <>
      <FormGroup classes={{ root: props.classes.formGroup }}>
        <OutlinedInput
          disabled={saving}
          inputRef={emailRef}
          classes={{ root: props.classes.inputs }}
          type='text'
          placeholder={t('email')}
          id={'inputResetPasswordEmailOrUsername'}
          autoComplete='off'
          onKeyPress={(e) => e.key === 'Enter' && callback(token)}
          startAdornment={
            <InputAdornment position='start'>
              <Info classes={{ root: props.classes.iconSize }} />
            </InputAdornment>
          }
        />
      </FormGroup>
      <div style={{ gap: '24px', marginLeft: '-5px', display: 'flex', justifyContent: 'center' }}>
        <DefaultButton
          data-testid={'buttonReturnToLogin'}
          loader={saving}
          enzyme-attr='a-login'
          label={t('returnLogin')}
          variant='discard'
          onClick={recover}
        />
        <DefaultButton
          data-testid={'buttonResetPassword'}
          loader={saving}
          enzyme-attr='a-login'
          label={t('resetPassword')}
          variant='default'
          onClick={(): void => callback(token)}
        />
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default compose<any>(
  withStyles(styles),
  connect(
    () => ({}),
    (dispatch) => bindActionCreators({}, dispatch),
  ),
)(ResetForm);
