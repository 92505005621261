import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    '@global': {
      '& ::-webkit-input-placeholder': {
        color: `${theme.palette.text.secondary} !important`,
      },
      '& ::placeholder': {
        color: `${theme.palette.text.secondary} !important`,
      },
    },
    postContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      borderRadius: '8px',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
      width: '681px',
      maxWidth: '80%',
      margin: 8,
      padding: 16,
      boxSizing: 'border-box',
      transition: 'all 0.3s ease',
      backgroundColor: theme.palette.background.default,
      overflowY: 'visible',
      //overflowX: 'auto',
      height: 'auto',
      gap: 16,
    },
    inPostImage: {
      maxWidth: '100%',
    },
    postHeader: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      gap: 8,
    },
    postContent: {
      textWrap: 'wrap',
    },
    postFooter: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
    },
    accountInfo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 8,
    },
    flexDateLabels: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    blueColor: {
      color: theme.palette.primary.main,
      userSelect: 'none',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    disabled: {
      color: theme.palette.grey.main,
    },
    clickable: {
      cursor: 'pointer',
    },
    marginLeft: {
      marginLeft: 'auto',
    },
    spacing: {
      margin: 'auto 4px',
    },
    noMargin: {
      padding: 0,
      margin: 0,
      '& > *': {
        margin: '0 !important',
      },
    },
    commentGrid: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    commentContainer: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'flex-start',
      width: '100%',
      justifyContent: 'stretch',
      gap: 8,
      overflow: 'hidden',
    },
    commentContainerDraft: {
      gap: 8,
      display: 'flex',
      alignItems: 'center',
    },
    commentBox: {
      width: '100%',
      borderRadius: 12,
      backgroundColor: theme.palette.background.background1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      gap: 8,
      padding: 8,
      overflow: 'hidden',
      position: 'relative',
    },
    mediumFont: {
      fontSize: 16,
    },
    bigFont: {
      fontSize: 32,
    },
    mediumBold: {
      fontWeight: 400,
    },
    commentMoreVert: {
      position: 'absolute',
      right: 0,
      top: 0,
    },
    commentBody: {
      textAlign: 'start',
      wordBreak: 'break-all',
    },
    subCommentContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    subCommentsBorder: {
      minHeight: '100%',
      width: 4,
      backgroundColor: '#CACACA',
      borderRadius: '1px',
      '&:hover': {
        cursor: 'pointer',
        boxShadow: '0 4px 0 4px #f4f4f4',
      },
    },
    subComments: {
      paddingLeft: 12,
      display: 'flex',
      gap: 8,
      flexDirection: 'column',
      width: '100%',
    },
    replyWidth: {
      width: 'fit-content',
    },
    newChipRoot: {
      backgroundColor: theme.palette.primary.main,
      height: 30,
    },
    newChipLabel: {
      color: `${theme.palette.common.white} !important`,
      padding: '0px 12px',
    },
    draftEdit: {
      borderRadius: 16,
      /* padding: '4px 8px', */
      '&:has(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
        border: `${theme.palette.primary.main} !important`,
        borderWidth: '1px !important',
      },
      '&:hover fieldset': {
        borderColor: `${theme.palette.primary.main} !important`,
      },
    },
    sendIcon: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderRadius: '50%',
      padding: 6,
    },
    sendIconDisabled: {
      backgroundColor: theme.palette.grey[400],
    },
    colorTransition: {
      transition: 'background-color 0.3s',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    noPadding: {
      padding: '0 !important',
    },
    breakWord: {
      overflowWrap: 'break-word',
    },
    marginBottom: {
      marginBottom: 8,
    },
    seenPopover: {
      minWidth: 150,
      minHeight: 100,
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
    },
    spinnerContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    mentionedLink: {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    draftActionButtons: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: 8,
      padding: 8,
    },
    smallBtn: {
      minHeight: '25px !important',
    },
  });
