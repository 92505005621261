import { useState, useEffect } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { styles } from './styles';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';
import { ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import ModalToolItems from 'src/modules/inventory/components/modal-tool-items';
import { getIssueInstance, updateIssue } from 'src/modules/issue-execution/issue.execution.redux';
import ToolsInUsePopover from '../tools-in-use-popover';
import { ArrowForwardIos, Close, FilterCenterFocus } from '@mui/icons-material';
import { showSnackbar } from 'src/base/root/root.redux';
import QrReader from 'react-qr-reader-es6';
import { getToolItem, getToolItemWithDeleted, getToolItems } from 'src/modules/inventory/inventory.redux';
import { getTool } from 'src/modules/inventory/inventory.redux';
import { useIssueExecutionContext } from 'src/modules/issue-execution/context/issue.execution.context';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToolItem } from 'src/gql/graphql';
import { IssueExecutionState } from 'src/modules/issue-execution/types/issue.execution.type';
import { typedConnect } from 'src/utils/funcs';

interface ToolField extends WithStyles<typeof styles>, ConnectedProps<typeof connector> {
  disabled: boolean;
  handleChange: (e: any) => void;
  values: string[];
  error: boolean;
  id?: string;
  data: any;
  validations: any;
}

const ToolField = (props: ToolField) => {
  const {
    classes,
    disabled,
    error,
    data,
    updateIssue,
    showSnackbar,
    getToolItems,
    handleChange,
    validations,
    getToolItemWithDeleted,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [openToolItemPopover, setOpenToolItemPopover] = useState<boolean>(false);
  const [openToolsInUse, setOpenToolsInUse] = useState<boolean>(false);
  const [toolsToAdd, setToolsToAdd] = useState([]);
  const [scannedCode, setScannedCode] = useState<boolean>(false);
  const [openQrScan, setOpenQrScan] = useState<boolean>(false);
  const [specificTool, setSpecificTool] = useState({});
  const state = useIssueExecutionContext();
  const setState = (data: Partial<IssueExecutionState>) => {
    state.updateIssueExecutionState(data);
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (data?.tool?.validations.length) {
      const specificToolValidation = data?.tool?.validations.filter((val) => val.name === 'specificTool');
      specificToolValidation && setSpecificTool(specificToolValidation[0]?.specificTool[0]);
    }
  }, [data?.tool?.validations]);

  useEffect(() => {
    if (data.tool?.values.length && !location.pathname.includes('template')) {
      getToolItemWithDeleted(
        { _id: data.tool?.values[0]._id ? data.tool?.values[0]._id : data.tool?.values[0] },
        false,
      ).then((resp) => {
        setToolsToAdd([resp]);
      });
    }
    if (!data.tool?.values.length) {
      setToolsToAdd([]);
    }
  }, [data.tool?.values]);

  const validateTool = (itemCode: string, validation) => {
    let isValid = true;
    const validationName = validation.name.split(':')[0];
    const validationValue = validation.name.split(':')[1];
    const itemCodeContains = validationName === 'contains' && !itemCode.includes(validationValue);
    const itemCodeNotContains = validationName === 'notContains' && itemCode.includes(validationValue);
    const itemCodeStartsWith = validationName === 'startsWith' && !itemCode.startsWith(validationValue);
    const itemCodeEndsWith = validationName === 'notContains' && !itemCode.endsWith(validationValue);
    if (itemCodeContains || itemCodeNotContains || itemCodeEndsWith || itemCodeStartsWith) {
      isValid = false;
    }
    return isValid;
  };

  const handleAddToolItems = async (toolItems: ToolItem[]) => {
    const item = toolItems[0];
    let toolItemIsValid = validations.length ? validateTool(item.code, validations[0]) : true;
    if (
      (validations.length && validations[0].name === 'specificTool' && item.tool._id !== specificTool) ||
      !toolItemIsValid
    ) {
      showSnackbar('error', `${item.code} ${t('notCorrespondIssue')}`);
      setToolsToAdd([]);
    } else if ((validations.length && item.tool._id === specificTool) || !validations.length || toolItemIsValid) {
      validations.length && showSnackbar('success', `${item.code} ${t('correspondsIssue')}`);
      await updateIssue(
        { _id: state.issue._id },
        { toolItems: [...state.issue.toolItems.map((ti) => ti?._id), toolItems[0]._id] },
        {
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: t('newItemAdded'),
            severity: 'success',
          },
        },
      );
      setState({ issue: { ...state.issue, toolItems: [...state.issue.toolItems, toolItems[0]] } });
      setToolsToAdd(toolItems);
      setOpenToolItemPopover(false);
      handleChange(toolItems.map((ti) => ti?._id));
    }
  };

  const handleRemoveToolItems = async () => {
    const issueToolItems = state.issue.toolItems.filter((ti) => ti?._id !== toolsToAdd[0]?._id);
    await updateIssue(
      { _id: state.issue._id },
      { toolItems: issueToolItems.map((ti) => ti?._id) },
      {
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: t('itemRemoved'),
          severity: 'success',
        },
      },
    );
    setState({ issue: { ...state.issue, toolItems: issueToolItems } });
    setToolsToAdd([]);
    handleChange([]);
    setScannedCode(false);
  };

  const handleError = async (err: unknown) => {
    const message = err.toString();

    if (message.toLowerCase().includes('permission')) {
      await showSnackbar('error', t('errorPermissionCamera'));
    } else {
      await showSnackbar('error', t('errorQRCodeGeneric'));
    }
  };

  function extractIdFromUrl(url) {
    try {
      const urlObj = new URL(url);
      const hash = urlObj.hash;
      const params = new URLSearchParams(hash.split('?')[1]);
      return params.get('id');
    } catch (err) {
      return '';
    }
  }

  const handleScan = async (data: any) => {
    if (data) {
      const id = extractIdFromUrl(data);
      if (!id) {
        showSnackbar('error', t('toolItemNotFound'));
      }
      const resp = (await getToolItems({ code_eq: data })).toolItems;
      const item = resp[0];
      if (!item) {
        showSnackbar('error', t('notPossibleIdentifyTool'));
      } else if (validations.length && item.tool._id !== specificTool) {
        showSnackbar('error', `${item.code} ${t('notCorrespondIssue')}`);
      } else if (validations.length && item.tool._id === specificTool) {
        showSnackbar('success', `${item.code} ${t('correspondsIssue')}`);
        handleAddToolItems([item]);
        handleChange([item._id]);
        setScannedCode(true);
        setOpenQrScan(false);
      } else if (state.issue?.toolItems?.find((ti) => ti?.code === data)) {
        showSnackbar('error', t('toolAlreadyAddedIssue'));
      } else if (!item.available) {
        navigate(`/tools/item?id=${item._id}`, {
          state: { issueId: state.issue._id, setToolsToAdd },
        });
        setOpenQrScan(false);
      } else {
        handleAddToolItems([item]);
        setScannedCode(true);
        setOpenQrScan(false);
      }
    }
  };

  return (
    <>
      <div className={classes.divContainer}>
        <div
          data-testid={`inputToolSelect`}
          className={disabled ? classes.inputDisable : error ? classes.inputError : classes.input}
          onClick={(e) => {
            e.stopPropagation();
            !toolsToAdd[0] && setOpenToolItemPopover(true);
            toolsToAdd[0] && navigate('/tools/item?id=' + toolsToAdd[0]._id);
          }}
        >
          <div className={classes.divValue}>
            <span
              data-testid={`${toolsToAdd[0]?.code}-inputToolSelectText`}
              className={toolsToAdd[0] ? classes.toolDesignation : classes.placeholder}
              style={{ marginLeft: 10 }}
            >
              {toolsToAdd[0] ? `${toolsToAdd[0].code} - ${toolsToAdd[0].tool?.designation}` : t('selectToolOrScan')}
            </span>
            {toolsToAdd[0] ? <ArrowForwardIos /> : undefined}
          </div>
        </div>
        {toolsToAdd[0] && location.pathname.includes('/execution') ? (
          <IconButton
            data-testid={`inputToolRemoveTool`}
            className={classes.closeBtn}
            onClick={() => handleRemoveToolItems()}
            size='large'
          >
            <Close className={classes.closeIcon} />
          </IconButton>
        ) : undefined}
        <Button
          data-testid={`inputToolQRButton`}
          variant={'outlined'}
          className={classes.qrScanBtn}
          disabled={disabled}
          onClick={() => {
            setOpenQrScan(true);
          }}
        >
          <FilterCenterFocus className={`material-icons ${disabled ? '' : classes.qrIcon}`} />
          <Typography data-testid={`inputToolQRButtonText`} classes={{ root: disabled ? '' : classes.qrText }}>
            {disabled ? t('scan') : scannedCode ? t('reScan') : t('scan')}
          </Typography>
        </Button>
      </div>
      <ModalToolItems
        open={openToolItemPopover}
        onClose={() => setOpenToolItemPopover(false)}
        inputTool
        onSubmit={(toolItems) => {
          const haveToolInUse = !!toolItems.find((e) => !e.available);
          if (haveToolInUse) {
            setToolsToAdd(toolItems);
            setOpenToolItemPopover(false);
            setOpenToolsInUse(true);
          } else {
            handleAddToolItems(toolItems);
          }
        }}
        ignoreToolItems={state.issue?.toolItems}
        issueId={state.issue?._id}
        setOpenToolItemPopover={setOpenToolItemPopover}
      />
      <ToolsInUsePopover
        openToolsInUse={openToolsInUse}
        toolsToAdd={toolsToAdd}
        setOpenToolsInUse={setOpenToolsInUse}
        handleAddToolItems={handleAddToolItems}
        setToolsToAdd={setToolsToAdd}
      />
      <Dialog
        data-testid={`inputToolQRPopup`}
        open={openQrScan}
        fullWidth
        maxWidth='xs'
        onClose={() => setOpenQrScan(false)}
      >
        <DialogTitle style={{ textAlign: 'center' }}>
          <Typography>{t('scanDialog')}</Typography>
        </DialogTitle>
        <DialogContent>
          <QrReader delay={500} onError={handleError} onScan={handleScan} />
        </DialogContent>
      </Dialog>
    </>
  );
};

const connector = typedConnect(null, {
  getIssueInstance,
  updateIssue,
  showSnackbar,
  getToolItem,
  getToolItems,
  getTool,
  getToolItemWithDeleted,
});

export default compose<any>(withTranslation('translation'), withStyles(styles), connector)(ToolField);
