import { APOLLO_CLIENT } from 'config/apollo.config';
import * as mutations from '../agora.queries';
import { CustomError } from 'src/interfaces/remote-assistance/custom-error';
import { getErrorObject } from 'src/utils/funcs';
import { StartedByProps } from './agora.interfaces';

export interface TokenType {
  rtc_token: string;
  uid: string;
}

export interface RecordType {
  expired_time_in_seconds: number;
  started_by: StartedByProps;
  started_at_in_seconds?: number;
  status: 'Recording' | 'Not Recording';
}

export interface GetRecord {
  file_name: string;
  link: string;
  valid_until: Date;
}

export interface RecordInfo {
  content_type: string;
  file_name: string;
  last_modified: number;
  size: number;
}

export interface HandleRecording {
  expiredTimeInSeconds?: number;
  resourceId: string;
  sid: string;
}

export const getRTCToken = async (roomId: string): Promise<[TokenType | null, CustomError | null]> => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      mutation: mutations.M_GET_RTC_TOKEN,
      fetchPolicy: 'no-cache',
      variables: {
        roomId: roomId,
      },
    });

    const { getRTCToken } = response.data;
    return [getRTCToken as TokenType, null];
  } catch (error) {
    const errMsg = getErrorObject(error, '');
    return [null, { message: errMsg } as unknown as CustomError];
  }
};

export const startRecording = async (
  roomId: string,
  videoTrackId: string,
  sessionId?: string,
): Promise<[HandleRecording | null, CustomError | null]> => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      mutation: mutations.M_START_RECORDING,
      fetchPolicy: 'no-cache',
      variables: {
        roomId: roomId,
        videoTrackId: videoTrackId,
        sessionId: sessionId,
      },
    });

    const { startRecording } = response.data;
    return [startRecording as HandleRecording, null];
  } catch (error) {
    const errMsg = getErrorObject(error, '');
    return [null, { message: errMsg } as unknown as CustomError];
  }
};

export const stopRecording = async (roomId: string): Promise<[HandleRecording | null, CustomError | null]> => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      mutation: mutations.M_STOP_RECORDING,
      fetchPolicy: 'no-cache',
      variables: {
        roomId: roomId,
      },
    });

    const { stopRecording } = response.data;
    return [stopRecording as HandleRecording, null];
  } catch (error) {
    const errMsg = getErrorObject(error, '');
    return [null, { message: errMsg } as unknown as CustomError];
  }
};

export const updateRecordLayout = async (
  roomId: string,
  uid: string,
): Promise<[boolean | null, CustomError | null]> => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      mutation: mutations.M_UPDATE_RECORD_LAYOUT,
      fetchPolicy: 'no-cache',
      variables: {
        roomId: roomId,
        uid: uid,
      },
    });

    const { updateRecordLayout } = response.data;
    return [updateRecordLayout, null];
  } catch (error) {
    const errMsg = getErrorObject(error, '');
    return [null, { message: errMsg } as unknown as CustomError];
  }
};

export const checkRecording = async (roomId: string): Promise<[RecordType | null, CustomError | null]> => {
  try {
    const response = await APOLLO_CLIENT.query({
      query: mutations.Q_CHECK_RECORDING,
      fetchPolicy: 'no-cache',
      variables: {
        roomId: roomId,
      },
    });

    const { checkRecording } = response.data;
    return [checkRecording as RecordType, null];
  } catch (error) {
    const errMsg = getErrorObject(error, '');
    return [null, { message: errMsg } as unknown as CustomError];
  }
};

export const getRecord = async (roomId: string, fileName: string): Promise<[GetRecord | null, CustomError | null]> => {
  try {
    const response = await APOLLO_CLIENT.query({
      query: mutations.Q_GET_RECORD,
      fetchPolicy: 'no-cache',
      variables: {
        roomId: roomId,
        fileName: fileName,
      },
    });

    const { getRecord } = response.data;
    return [getRecord as GetRecord, null];
  } catch (error) {
    const errMsg = getErrorObject(error, '');
    return [null, { message: errMsg } as unknown as CustomError];
  }
};

export const getRecordInfo = async (
  roomId: string,
  fileName: string,
): Promise<[RecordInfo | null, CustomError | null]> => {
  try {
    const response = await APOLLO_CLIENT.query({
      query: mutations.Q_GET_RECORD_INFO,
      fetchPolicy: 'no-cache',
      variables: {
        roomId: roomId,
        fileName: fileName,
      },
    });

    const { getRecordInfo } = response.data;
    return [getRecordInfo as RecordInfo, null];
  } catch (error) {
    const errMsg = getErrorObject(error, '');
    return [null, { message: errMsg } as unknown as CustomError];
  }
};
