import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    isResizing: {
      background: theme.palette.primary.main,
      opacity: 1,
      zIndex: 999,
    },
    resizer: {
      position: 'absolute',
      right: 0,
      top: 0,
      height: '100%',
      width: '5px',
      /* background: "rgba(0, 0, 0, 0.5)", */
      cursor: 'col-resize',
      userSelect: 'none',
      touchAction: 'none',
      /* "&:hover": {
      opacity: 1
    } */
    },
    onOverRight: {
      borderRight: `4px solid ${theme.palette.primary.main}`,
      position: 'absolute',
      right: 0,
      top: 0,
    },
    onOverLeft: {
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      position: 'absolute',
      left: 0,
      top: 0,
    },
    header: {
      backgroundColor: theme.palette.background.background1,
      color: theme.palette.text.secondary,
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 400,
      position: 'relative',
      paddingInline: '8px',
      height: '34px !important',
      borderRight: `0.4px solid ${theme.palette.text.disabled}`,
      '& > span': {
        alignSelf: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '8px',
      },
    },
  });
