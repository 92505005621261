import { MainContainerState } from 'src/base/main-container/context/main.container.context';

/**
 * Used whenever the user wants to redo de walk through only, meaning the progress will
 * reset and the substeps will all appear again
 * @param mainContainerState
 * @param props
 * @param updateMainContainerState
 */
export const handleWalkthrough = (
  mainContainerState: MainContainerState,
  props,
  updateMainContainerState: (newData: Partial<MainContainerState>) => void,
) => {
  switch (mainContainerState.currentStep) {
    case 'build':
      props.updateLoggedUser(
        {
          id: props.loggedUser._id,
          onBoarding: {
            ...props.loggedUser.onBoarding,
            templateDraft: props.loggedUser?.onBoarding?.templateDraft._id,
            template: null,
            openIssueDraft: null,
            openIssue: null,
            issueInstance: null,
            progressTotal: 0,
            currentSubStep: 0,
          },
        },
        false,
      );
      break;
    case 'createIssue':
      props.updateLoggedUser(
        {
          id: props.loggedUser._id,
          onBoarding: {
            ...props.loggedUser.onBoarding,
            templateDraft: null,
            template: props.loggedUser?.onBoarding?.template._id,
            openIssueDraft: props.loggedUser?.onBoarding?.openIssueDraft._id,
            openIssue: null,
            issueInstance: null,
            // 100 % completion divided by the number of major steps
            progressTotal: Math.floor(100 / 3),
            currentSubStep: 0,
          },
        },
        false,
      );
      break;
    case 'createTasks':
      props.updateLoggedUser(
        {
          id: props.loggedUser._id,
          onBoarding: {
            ...props.loggedUser.onBoarding,
            templateDraft: null,
            template: props.loggedUser?.onBoarding?.template._id,
            openIssueDraft: props.loggedUser?.onBoarding?.openIssueDraft._id,
            openIssue: null,
            issueInstance: null,
            // 100 % completion divided by the number of major steps + half of the second major step
            progressTotal: Math.floor(100 / 3 + 100 / 3 / 2),
            currentSubStep: 0,
          },
        },
        false,
      );
      updateMainContainerState({ startSubSteps: true });
      break;
    case 'execute':
      props.updateLoggedUser(
        {
          id: props.loggedUser._id,
          onBoarding: {
            ...props.loggedUser.onBoarding,
            templateDraft: null,
            template: props.loggedUser?.onBoarding?.template._id,
            openIssueDraft: null,
            openIssue: props.loggedUser?.onBoarding?.openIssue._id,
            issueInstance: props.loggedUser?.onBoarding?.issueInstance._id,
            progressTotal: Math.floor((100 / 3) * 2),
            currentSubStep: 0,
          },
        },
        false,
      );
      break;
  }
  mainContainerState.currentStep !== 'createTasks' &&
    updateMainContainerState({ open: true, hideSteps: false, startSubSteps: false });
};
