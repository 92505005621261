import { useRef, useState } from 'react';
import * as React from 'react';
import * as THREE from 'three';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconTooltipButton } from '../icon-tooltip-button';
import { styles } from './styles';
import useCreate3DModel from './hooks/useCreate3DModel';
import useRefreshRender from './hooks/useRefreshRenderer';
import { Error, ThreeSixty } from '@mui/icons-material';

export type ThreeData = {
  camera: THREE.PerspectiveCamera | null;
  scene: THREE.Scene | null;
  object3d: THREE.Object3D | null;
  renderer: THREE.WebGLRenderer | null;
};

interface RenderProps {
  modelUrl: string;
  thumbnailUrl: string;
  fullscreen?: boolean;
  onLoadPercentage?: (percent: number) => void;
  onClickCloseFullscreen?: () => void;
}

const ARModelRender: React.FC<RenderProps> = (props) => {
  const { modelUrl, thumbnailUrl, onLoadPercentage, fullscreen } = props;
  const classes = styles();
  const [loadPercent, setLoadPercent] = useState(0);
  const [showControls, setShowControls] = useState(true);
  const [error, setError] = useState(false);
  const containerRef = useRef<HTMLDivElement>();
  const threeRef = useRef<ThreeData>({
    camera: null,
    scene: null,
    object3d: null,
    renderer: null,
  });

  const refresh = useRefreshRender({ threeRef, containerRef, fullscreen });

  useCreate3DModel({
    threeRef,
    fullscreen,
    modelUrl,
    containerRef,
    setError,
    setLoadPercent,
    onLoadPercentage,
    refresh,
  });

  const isLoading = loadPercent < 100;

  return (
    <div className={classes.arModelRenderContainer} data-testid='3d-renderer' onClick={() => setShowControls(false)}>
      {error ? (
        <ErrorMessage />
      ) : (
        <>
          {fullscreen && <FullscreenBackground onClickCloseFullscreen={props.onClickCloseFullscreen} />}
          {isLoading && <Loader loadPercent={loadPercent} thumbnailUrl={thumbnailUrl} />}
          {!isLoading && showControls && <ControlsHelp />}
        </>
      )}
      <div
        style={{
          display: 'block',
          visibility: isLoading || error ? 'hidden' : 'visible',
          width: '100%',
          height: '100%',
        }}
      >
        <div
          id='three-renderer'
          data-testid='3d-renderer-model'
          className={fullscreen ? classes.rendererFullscreen : classes.rendererNormal}
          ref={containerRef}
        />
      </div>
    </div>
  );
};

const ErrorMessage = () => {
  const { t } = useTranslation();
  const classes = styles();
  const iconSize = 40;
  return (
    <div className={classes.errorContainer} data-testid='3d-renderer-window-error'>
      <div style={{ fontSize: `${iconSize}px` }}>
        <Error fontSize='inherit' />
      </div>
      <span className={classes.errorTextTop}>{t('ar_model_error_top')}</span>
      <span className={classes.errorTextBottom}>{t('ar_model_error_bottom')}</span>
    </div>
  );
};

const FullscreenBackground: React.FC<{ onClickCloseFullscreen?: () => void }> = ({ onClickCloseFullscreen }) => {
  const { t } = useTranslation();
  const classes = styles();
  return (
    <div className={classes.fulscreenBackgroundContainer} data-testid='3d-renderer-fullscreen-background'>
      <div className={classes.fullscreenBarLeft}></div>
      <div className={classes.fullscreenBarRight}></div>
      <div className={classes.fullscreenCloseIcon}>
        <IconTooltipButton
          data-testid='icon-button-fullscreen-ar-model'
          variant='fullscreen'
          tooltip={t('exitFullscreen')}
          onClick={() => onClickCloseFullscreen?.()}
          position='left'
          size={32}
          colorIdle='white'
        />
      </div>
    </div>
  );
};

const Loader: React.FC<{ loadPercent: number; thumbnailUrl: string }> = ({ thumbnailUrl, loadPercent }) => {
  const classes = styles();
  return (
    <div className={classes.loaderContainer} data-testid='3d-renderer-loader'>
      <img className={classes.loaderBlurredThumbnail} src={thumbnailUrl} crossOrigin='anonymous' />
      <span className={classes.loaderProgress}>{`${loadPercent}%`}</span>
      <div className={classes.loaderCircularIcon}>
        <CircularProgress />
      </div>
    </div>
  );
};

const ControlsHelp = () => {
  const { t } = useTranslation();
  const classes = styles();
  return (
    <div className={classes.controlsHelpContainer} data-testid='3d-renderer-controls'>
      <ThreeSixty />
      <span className={classes.controlsText}>{t('click_and_hold_to_rotate')}</span>
    </div>
  );
};

export default ARModelRender;
