import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export default function styles(theme: Theme) {
  return createStyles({
    legendContainer: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      margin: 0,
    },
    tooltip: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.background1,
      padding: 8,
      borderRadius: 5,
      '& span': {
        textAlign: 'center',
      },
    },
  });
}
