import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    headerBrand: {
      fontWeight: 500,
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      fontSize: '30px',
      marginBottom: '65px',
    },
    spanFirstPart: {
      color: theme.palette.text.primary,
      verticalAlign: 'middle',
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '15px',
    },
    spanSecondPart: {
      verticalAlign: 'middle',
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.main,
    },
    forgotPasswordRow: {
      marginBottom: '15px',
      flexFlow: 'column',
    },
    label: {
      fontSize: '16px',
    },
    inputs: {
      fontSize: theme.typography.fontSize,
      '& ::-webkit-input-placeholder': {
        color: `${theme.palette.text.primary} !important`,
      },
    },
    link: {
      color: `${theme.palette.primary.main} !important`,
      cursor: 'pointer',
      '&:hover': {
        opacity: '0.5',
      },
    },
    unselected: {
      opacity: 0.5,
    },
    ldapBtn: {
      backgroundColor: theme.palette.primary.main,
      margin: '0 5px 0 5px',
    },
    local: {
      backgroundColor: theme.palette.warning.main,
      margin: '0 5px 0 5px',
    },
    iconSize: {
      fontSize: 20,
    },
    login: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.background.default,
    },
    formGroup: {
      marginBottom: '1rem',
    },
    loginWrapper: {
      padding: '2rem',
      fontSize: theme.typography.fontSize,
      width: '300px',
      [theme.breakpoints.up(500)]: {
        width: '500px',
      },
    },
    mutedText: {
      color: `${theme.palette.text.secondary} !important`,
    },
  });
