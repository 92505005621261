import * as React from 'react';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

interface INavigationArrowProps {
  type: 'next' | 'prev';
  onClick: () => void;
}

const NavigationArrow: React.FC<INavigationArrowProps> = ({ onClick, type }) => {
  return (
    <div style={{ height: '100%', cursor: 'pointer' }} onClick={onClick}>
      {type === 'next' ? <ChevronRight /> : <ChevronLeft />}
    </div>
  );
};

export default NavigationArrow;
