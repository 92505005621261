import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';

const ElementCreateTemplateRefresh = () => lazy(() => import('src/modules/element/element.create.template'));

let ElementCreateTemplate = ElementCreateTemplateRefresh();

export const render = {
  create: {
    template: ({ ...props }) => {
      if (ElementCreateTemplate?._result?.type === 'error') {
        ElementCreateTemplate = ElementCreateTemplateRefresh();
      }

      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <ElementCreateTemplate {...props} />
        </Suspense>
      );
    },
  },
};

export default render;
