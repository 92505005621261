import gql from 'graphql-tag';
import { INPUT } from 'src/utils/fragments/input';
import {
  ELEMENT_TEMPLATE,
  ELEMENT,
  ELEMENT_TEMPLATE_FOLDER,
  ELEMENT_TEMPLATE_TO_TREE,
  ELEMENT_TO_TREE,
} from 'src/utils/fragments/element';
import { SITE_TO_TREE } from 'src/utils/fragments/site';

export const Q_GET_SITES = gql`
  query Sites($skip: Int, $limit: Int, $filter: SiteWhereInput!) {
    sitesV2(skip: $skip, limit: $limit, where: $filter, orderBy: name_ASC) {
      ...siteToTree
    }
  }
  ${SITE_TO_TREE}
`;

export const Q_GET_SITES_ROOT = gql`
  query SitesRoot($skip: Int, $limit: Int, $filter: SiteWhereInput!) {
    sitesV2(skip: $skip, limit: $limit, where: $filter) {
      ...siteToTree
    }
  }
  ${SITE_TO_TREE}
`;

export const Q_GET_ELEMENT_ROOT = gql`
  query ElementsRoot($filter: ElementWhereInput) {
    elementsV2(where: $filter) {
      ...elementToTree
    }
  }
  ${INPUT}
  ${ELEMENT_TO_TREE}
`;

export const Q_GET_ELEMENT_TO_TREE = gql`
  query ElementsTree($filter: ElementWhereInput) {
    elementsV2(where: $filter, limit: 1000, orderBy: name_ASC) {
      name
      __typename
      _id
      siteId
      parentId
      parentsTreeIds
      profileId
      profile: profileId
      tags {
        __typename
        name
        value
        key
        unitId
        id: _id
        _id
      }
      site {
        _id
        name
        parentsTreeIds
      }
      hasChildren
      totalItems: hasChildren
      markerId
    }
  }
`;

export const Q_GET_ELEMENTS = gql`
  query GetElements($filter: ElementWhereUniqueInput!) {
    element(where: $filter) {
      ...element
    }
  }
  ${INPUT}
  ${ELEMENT_TEMPLATE}
  ${ELEMENT}
`;

export const Q_GET_ELEMENTS_V2 = gql`
  query GetElementsV2($skip: Int, $limit: Int, $filter: ElementWhereInput!) {
    elementsV2(skip: $skip, limit: $limit, where: $filter, orderBy: name_ASC) {
      _id
      name
      description
      parentId
      parentsTreeIds
      profileId
      templateId
    }
  }
`;

export const Q_GET_SITES_AND_ELEMENTS_V2 = gql`
  query GetSitesAndElements(
    $skip: Int
    $limit: Int
    $siteFilter: SiteWhereInput
    $elementFilter: ElementWhereInput
    $orderBy: [SiteOrderByInput]
    $withTags: Boolean
    $tenant: ID
  ) {
    sitesAndElements(
      skip: $skip
      limit: $limit
      siteWhere: $siteFilter
      elementWhere: $elementFilter
      orderBy: $orderBy
      withTags: $withTags
      tenant: $tenant
    ) {
      ... on LeanSite {
        ...siteToTree
      }
      ... on LeanElement {
        ...elementToTree
        site {
          _id
          name
          parentsTreeIds
        }
      }
      ... on LeanElementTag {
        __typename
        _id
        name
        value
        key
        unitId
      }
    }
  }
  ${SITE_TO_TREE}
  ${ELEMENT_TO_TREE}
`;

export const M_DUPLICATE_ELEMENT = gql`
  mutation DuplicateElement($_id: ID!, $copyChildren: Boolean!) {
    duplicateElement(_id: $_id, copyChildren: $copyChildren) {
      ...element
    }
  }
  ${INPUT}
  ${ELEMENT_TEMPLATE}
  ${ELEMENT}
`;

export const M_DUPLICATE_ELEMENT_TEMPLATE = gql`
  mutation DuplicateElementTemplate($_id: ID!, $copyChildren: Boolean!) {
    duplicateElementTemplate(_id: $_id, copyChildren: $copyChildren) {
      ...elementTemplate
    }
  }
  ${INPUT}
  ${ELEMENT_TEMPLATE}
`;

export const Q_GET_ELEMENT_ROOT_BY_SITE = gql`
  query ElementsRootBySite($id: ID!, $limit: Int, $skip: Int, $name: String) {
    elementsV2(
      limit: $limit
      skip: $skip
      orderBy: name_ASC
      where: { name_contains: $name, site_eq: $id, AND: { parent_exists: false } }
    ) {
      name
      __typename
      _id
      siteId
      parentId
      parentsTreeIds
      profileId
      profile: profileId
      tags {
        __typename
        name
        value
        key
        unitId
        id: _id
        _id
      }
      hasChildren
      totalItems: hasChildren
      site {
        _id
        name
        parentsTreeIds
      }
      markerId
    }
  }
`;

export const Q_GET_ELEMENT_BY_ID = gql`
  query ElementById($id: ID!) {
    element(where: { _id: $id }) {
      ...element
    }
  }
  ${INPUT}
  ${ELEMENT}
`;

export const Q_GET_ELEMENT_BY_FATHER_ID = gql`
  query ElementsByFatherId($parentId: ID, $limit: Int, $skip: Int) {
    elementsV2(limit: $limit, skip: $skip, where: { parent_eq: $parentId }, orderBy: name_ASC) {
      ...elementToTree
    }
  }
  ${ELEMENT_TO_TREE}
`;

export const Q_ELEMENT_SEARCH_FILTER = gql`
  query ElementSearchFilter($filter: ElementWhereInput!) {
    elements(where: $filter) {
      name
      __typename
      _id
      parentId
      parentsTreeIds
      parentsTree {
        _id
        name
      }
      siteId
      site {
        _id
        name
        parentsTree {
          _id
          name
        }
      }
    }
  }
`;

export const M_ELEMENT_SEARCH = gql`
  query ElementSearch($searchString: String!) {
    elements(where: { name_contains: $searchString }) {
      name
      __typename
      _id
      parentId
      parentsTreeIds
      parentsTree {
        _id
        name
      }
      siteId
      site {
        _id
        name
        parentsTree {
          _id
          name
        }
      }
    }
  }
`;

export const Q_ELEMENT_WITH_TAGS = gql`
  query ElementWithTags($filter: ElementWhereInput) {
    elements(where: $filter) {
      _id
      name
      tags {
        __typename
        name
        value
        unitId
        key
        _id
      }
      parent {
        name
        _id
      }
    }
  }
`;

export const M_CREATE_ELEMENT = gql`
  mutation CreateElement(
    $name: String!
    $description: String
    $parentId: ID
    $profileId: ID
    $inputs: [InputCreateInput!]
    $site: ID!
    $template: ID!
    $tags: [ElementTagCreateInput]
    $labelValues: [ID]
    $imageId: ID
    $icon: String
    $imageDetails: ElementImageDetailCreateInput
  ) {
    createElement(
      data: {
        name: $name
        description: $description
        parent: $parentId
        profile: $profileId
        inputs: $inputs
        site: $site
        template: $template
        tags: $tags
        labelValues: $labelValues
        image: $imageId
        icon: $icon
        imageDetails: $imageDetails
      }
    ) {
      ...element
    }
  }
  ${INPUT}
  ${ELEMENT_TEMPLATE}
  ${ELEMENT}
`;

export const M_CREATE_ELEMENT_ROOT = gql`
  mutation CreateElementRoot(
    $name: String!
    $description: String
    $profileId: ID
    $inputs: [InputCreateInput!]
    $site: ID!
    $template: ID!
    $tags: [ElementTagCreateInput]
    $labelValues: [ID]
    $imageId: ID
    $icon: String
    $imageDetails: ElementImageDetailCreateInput
  ) {
    createElement(
      data: {
        name: $name
        description: $description
        profile: $profileId
        inputs: $inputs
        site: $site
        template: $template
        tags: $tags
        labelValues: $labelValues
        image: $imageId
        icon: $icon
        imageDetails: $imageDetails
      }
    ) {
      ...element
    }
  }
  ${INPUT}
  ${ELEMENT_TEMPLATE}
  ${ELEMENT}
`;

export const M_DELETE_ELEMENT = gql`
  mutation DeleteElement($id: ID!) {
    deleteOneElement(where: { _id: $id }) {
      _id
      parent {
        _id
      }
      site {
        _id
      }
    }
  }
`;

export const M_UPDATE_ELEMENT = gql`
  mutation UpdateElement(
    $name: String
    $description: String
    $parentId: ID
    $profileId: ID
    $siteId: ID
    $id: ID!
    $inputs: [InputUpdateInput!]
    $tags: [ElementTagUpdateInput]
    $templateId: ID
    $labelValues: [ID]
    $imageId: ID
    $icon: String
    $imageDetails: ElementImageDetailUpdateInput
  ) {
    updateElement(
      data: {
        name: $name
        description: $description
        parent: $parentId
        profile: $profileId
        inputs: $inputs
        site: $siteId
        tags: $tags
        template: $templateId
        labelValues: $labelValues
        image: $imageId
        icon: $icon
        imageDetails: $imageDetails
      }
      where: { _id: $id }
    ) {
      ...element
    }
  }
  ${INPUT}
  ${ELEMENT_TEMPLATE}
  ${ELEMENT}
`;

export const Q_GET_ELEMENT_TEMPLATE_FOLDERS = gql`
  query ElementTemplateFolders($where: ElementTemplateFolderWhereInput) {
    elementTemplateFolders(where: $where, orderBy: name_ASC) {
      ...elementTemplateFolder
    }
  }
  ${ELEMENT_TEMPLATE_FOLDER}
`;

export const Q_GET_ELEMENT_TEMPLATES_AND_FOLDERS = gql`
  query ElementTemplatesAndFolders(
    $skip: Int
    $limit: Int
    $templateWhere: ElementTemplateWhereInput
    $templateFolderWhere: ElementTemplateFolderWhereInput
    $orderByTemplate: [ElementTemplateOrderByInput!]!
    $orderByTemplateFolder: [ElementTemplateFolderOrderByInput!]!
    $tenant: ID
  ) {
    elementTemplatesAndFolders(
      skip: $skip
      limit: $limit
      templateWhere: $templateWhere
      templateFolderWhere: $templateFolderWhere
      templateOrderBy: $orderByTemplate
      templateFolderOrderBy: $orderByTemplateFolder
      tenant: $tenant
    ) {
      ... on ElementTemplate {
        ...elementTemplate
      }
      ... on ElementTemplateFolder {
        ...elementTemplateFolder
      }
    }
  }
  ${INPUT}
  ${ELEMENT_TEMPLATE}
  ${ELEMENT_TEMPLATE_FOLDER}
`;

export const M_CREATE_ELEMENT_TEMPLATE_FOLDER = gql`
  mutation CreateElementTemplateFolder($name: String!, $parent: ID) {
    createElementTemplateFolder(data: { name: $name, parent: $parent }) {
      ...elementTemplateFolder
    }
  }
  ${ELEMENT_TEMPLATE_FOLDER}
`;

export const M_UPDATE_ELEMENT_TEMPLATE_FOLDER = gql`
  mutation UpdateElementTemplateFolder($name: String, $parent: ID, $_id: ID!) {
    updateElementTemplateFolder(data: { name: $name, parent: $parent }, where: { _id: $_id }) {
      ...elementTemplateFolder
    }
  }
  ${ELEMENT_TEMPLATE_FOLDER}
`;

export const M_DELETE_ELEMENT_TEMPLATE_FOLDER = gql`
  mutation DeleteElementTemplateFolder($where: ElementTemplateFolderWhereUniqueInput!) {
    deleteOneElementTemplateFolder(where: $where) {
      ...elementTemplateFolder
    }
  }
  ${ELEMENT_TEMPLATE_FOLDER}
`;

export const M_CREATE_ELEMENT_TEMPLATE = gql`
  mutation CreateElementTemplate($title: String!, $folder: ID!, $inputs: [InputCreateInput!], $icon: String) {
    createElementTemplate(data: { title: $title, folder: $folder, frame: { inputs: $inputs }, icon: $icon }) {
      ...elementTemplate
    }
  }
  ${INPUT}
  ${ELEMENT_TEMPLATE}
`;

export const M_DELETE_ELEMENT_TEMPLATE = gql`
  mutation DeleteElementTemplate($id: ID!) {
    deleteOneElementTemplate(where: { _id: $id }) {
      ...elementTemplateToTree
    }
  }
  ${ELEMENT_TEMPLATE_TO_TREE}
`;

export const M_UPDATE_ELEMENT_TEMPLATE = gql`
  mutation UpdateElementTemplate($data: ElementTemplateUpdateInput!, $where: ElementTemplateWhereUniqueInput!) {
    updateElementTemplate(data: $data, where: $where) {
      ...elementTemplateToTree
    }
  }
  ${ELEMENT_TEMPLATE_TO_TREE}
`;

export const Q_GET_ELEMENT_TEMPLATES = gql`
  query GetElementTemplates($filter: ElementTemplateWhereInput) {
    elementTemplates(where: $filter) {
      ...elementTemplate
    }
  }
  ${INPUT}
  ${ELEMENT_TEMPLATE}
`;

export const Q_GET_ELEMENT_TEMPLATES_TO_TREE = gql`
  query GetElementTemplatesToTree($filter: ElementTemplateWhereInput) {
    elementTemplates(where: $filter, orderBy: title_ASC) {
      ...elementTemplateToTree
    }
  }
  ${ELEMENT_TEMPLATE_TO_TREE}
`;

export const Q_SEARCH = gql`
  query SearchElementTemplates($searchString: String) {
    elementTemplateFolders(where: { name_contains: $searchString }) {
      ...elementTemplateFolder
    }
    elementTemplates(where: { folder: { name_contains: $searchString } }) {
      ...elementTemplateToTree
    }
  }
  ${ELEMENT_TEMPLATE_TO_TREE}
  ${ELEMENT_TEMPLATE_FOLDER}
`;

export const M_DELETE_MARKER_BY_ANCHOR_ID = gql`
  mutation DeleteMarkersByAnchorId($anchorId: String!) {
    deleteMarkersByAnchorId(anchorId: $anchorId) {
      status
      found
      deleted
    }
  }
`;

export const Q_GET_ELEMENT_PARENT = gql`
  query ElementsParent($filter: ElementWhereInput) {
    elementsV2(where: $filter, limit: 1000, orderBy: name_ASC) {
      name
      _id
      siteId
      parentId
      parentsTreeIds
    }
  }
`;

export const M_MOVE_MANY_ELEMENT = gql`
  mutation MoveManyElements($_ids: [ID!]!, $siteId: ID, $parentId: ID) {
    moveManyElements(_ids: $_ids, siteId: $siteId, parentId: $parentId) {
      ...element
    }
  }
  ${INPUT}
  ${ELEMENT_TEMPLATE}
  ${ELEMENT}
`;

export const Q_GET_ELEMENT_WITH_DELETED = gql`
  query ElementsWithDeleted($filter: ElementWhereUniqueInput!) {
    elementWithDeleted(where: $filter) {
      ...element
    }
  }
  ${INPUT}
  ${ELEMENT_TEMPLATE}
  ${ELEMENT}
`;

export const M_UPDATE_MANY_ELEMENT_TEMPLATES = gql`
  mutation UpdateManyElementTemplates($where: ElementTemplateWhereInput!, $update: ElementTemplateUpdateInput!) {
    updateManyElementTemplates(where: $where, update: $update) {
      _id
      title
    }
  }
`;
