import { Box, Dialog, Grid, Tab, Tabs, Typography } from '@mui/material';
import { styles } from './styles';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { File } from 'src/gql/graphql';
import { chunk, isEmpty } from 'lodash';
import FileImage from '../media/FileImage';
import DocumentItem from '../documents/document-item';

interface IGalleryDialogProps {
  close: () => void;
  media: File[];
  files: File[];
  initialTab?: number;
  setOpenCarousel?: (file: File) => void;
}

export const GalleryDialog = (props: IGalleryDialogProps) => {
  const classes = styles();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<number>(props.initialTab || 0);

  const mediaRows = chunk(props.media, 4);

  return (
    <Dialog open maxWidth={false} onClose={props.close}>
      <div className={classes.dialog}>
        <div className={classes.textContainer}>
          <Typography variant='h4' className={classes.text}>
            {t('gallery')}
          </Typography>
        </div>
        {!isEmpty(props.media) && !isEmpty(props.files) && (
          <Box sx={{ width: '100%' }}>
            <Tabs
              variant={'fullWidth'}
              value={selectedTab}
              onChange={(_e, value) => {
                setSelectedTab(value);
              }}
              indicatorColor='primary'
              TabIndicatorProps={{
                sx: {
                  height: '3px',
                },
              }}
              textColor='primary'
              centered
            >
              <Tab label={t('media')} id={'mediaTab'} classes={{ root: classes.tab }} />
              <Tab label={t('files')} id={'filesTab'} classes={{ root: classes.tab }} />
            </Tabs>
          </Box>
        )}

        <div className={classes.container}>
          {!selectedTab && (
            <Grid container spacing={2} justifyContent='center'>
              {mediaRows.map((rowFiles, idx) => {
                return (
                  <Grid container item xs={12} spacing={2} key={idx}>
                    {rowFiles.map((file) => (
                      <Grid item xs={3} key={file.name} data-testid={`media-${file.name}`}>
                        <FileImage
                          file={file}
                          size={{ width: 200, height: 200 }}
                          setOpenCarousel={props.setOpenCarousel}
                        ></FileImage>
                      </Grid>
                    ))}
                  </Grid>
                );
              })}
            </Grid>
          )}
          {!!selectedTab && (
            <Grid container spacing={1} justifyContent='center'>
              {props.files.map((file, idx) => {
                return (
                  <Grid container item xs={12} spacing={2} key={idx}>
                    <Grid item xs={12} key={file.name} data-testid={`file-${file.name}`}>
                      <DocumentItem file={file} setOpenCarousel={props.setOpenCarousel} />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </div>
      </div>
    </Dialog>
  );
};
