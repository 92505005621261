import { NexusGenEnums, NexusGenInputs } from '@server/src/types';
import { FieldPopoverContextTemplates, FieldPopoverItem } from '.';
import { APOLLO_CLIENT } from 'config/apollo.config';
import { Q_GET_ELEMENT_TEMPLATES_AND_FOLDERS, Q_GET_SITES_AND_ELEMENTS_V2 } from 'src/modules/element/element.queries';
import { Q_GET_ISSUE_TEMPLATES_AND_FOLDERS } from 'src/modules/issue-templates/issue.templates.queries';
import { Q_GET_SITE_TEMPLATES_AND_FOLDERS } from 'src/modules/site/site.queries';
import {
  Q_GET_ACCOUNTS_AND_FOLDERS,
  Q_GET_ACCOUNTS_TO_ASSIGN,
  Q_GET_ACCOUNT_TEMPLATES_AND_FOLDERS,
} from 'src/modules/account/account.queries';
import { Q_GET_PROFILES_AND_FOLDERS, Q_GET_PROFILE_TEMPLATES_AND_FOLDERS } from 'src/modules/profile/profile.queries';
import { Q_GET_UNITS_AND_FOLDERS } from 'src/modules/unit/unit.queries';
import { Q_GET_ROLES } from 'src/modules/role/role.queries';
import {
  GetIssueCatalogFoldersSimpleDocument,
  GetIssueCatalogsAndFoldersDocument,
  IssueCatalogFolderWhereInput,
} from 'src/gql/graphql';
import { getDefaultTenant } from 'src/modules/tenants/tenant.redux';
import { Q_GET_MATERIALS_SEARCH, Q_GET_TOOLS_SEARCH } from 'src/modules/inventory/inventory.queries';

export const getSitesAndElements = async (
  siteFilter: NexusGenInputs['SiteWhereInput'],
  elementFilter: NexusGenInputs['ElementWhereInput'],
  skip: number,
  limit: number,
  withTags?: boolean,
  orderBy: NexusGenEnums['SiteOrderByInput'][] = ['name_ASC'],
): Promise<Array<FieldPopoverItem['element']>> => {
  const response = await APOLLO_CLIENT.query({
    variables: {
      siteFilter,
      elementFilter,
      limit,
      skip,
      tenant: getDefaultTenant(),
      orderBy,
      withTags,
    },
    fetchPolicy: 'no-cache',
    query: Q_GET_SITES_AND_ELEMENTS_V2,
  });

  if (withTags) {
    return response.data.sitesAndElements.map((el) => ({ ...el, totalItems: el.totalItems || !!el.tags?.length }));
  }

  return response.data.sitesAndElements;
};

export const getAssignees = async (
  filter: NexusGenInputs['AccountWhereInput'],
  skip: number,
  limit: number,
  orderBy = ['name_ASC'],
): Promise<Array<FieldPopoverItem['assignee']>> => {
  const response = await APOLLO_CLIENT.query({
    variables: {
      filter,
      skip,
      limit,
      orderBy,
      tenant: getDefaultTenant(),
    },
    fetchPolicy: 'no-cache',
    query: Q_GET_ACCOUNTS_TO_ASSIGN,
  });

  return response.data.accounts;
};

export const getRoles = async (
  filter: NexusGenInputs['RoleWhereInput'],
  skip: number,
  limit: number,
  orderBy: NexusGenEnums['RoleOrderByInput'][] = ['name_ASC'],
) => {
  const response = await APOLLO_CLIENT.query({
    variables: {
      filter,
      skip,
      limit,
      orderBy,
      tenant: getDefaultTenant(),
    },
    fetchPolicy: 'no-cache',
    query: Q_GET_ROLES,
  });

  return response.data.roles;
};

export const getTools = async (
  where: NexusGenInputs['ToolWhereInput'],
  skip: number,
  limit: number,
  orderBy: NexusGenEnums['ToolOrderByInput'][] = ['description_ASC'],
) => {
  const response = await APOLLO_CLIENT.query({
    variables: {
      where,
      skip,
      limit,
      orderBy,
    },
    fetchPolicy: 'no-cache',
    query: Q_GET_TOOLS_SEARCH,
  });

  return response.data.toolsSearch;
};

export const getMaterials = async (
  where: NexusGenInputs['MaterialWhereInput'],
  skip: number,
  limit: number,
  orderBy: NexusGenEnums['MaterialOrderByInput'][] = ['name_ASC'],
) => {
  const response = await APOLLO_CLIENT.query({
    variables: {
      where,
      skip,
      limit,
      orderBy,
    },
    fetchPolicy: 'no-cache',
    query: Q_GET_MATERIALS_SEARCH,
  });

  return response.data.materialsSearch;
};

export const getIssueTemplatesAndFolders: GenericWithFolderQuery<'issueTemplate'> = async (
  templateFilter,
  templateFolderFilter,
  skip,
  limit,
  orderByTemplate = ['title_ASC'],
  orderByTemplateFolder = ['name_ASC'],
) => {
  const response = await APOLLO_CLIENT.query({
    variables: {
      templateWhere: templateFilter,
      templateFolderWhere: templateFolderFilter,
      skip,
      limit,
      orderByTemplate,
      orderByTemplateFolder,
      tenant: getDefaultTenant(),
    },
    fetchPolicy: 'no-cache',
    query: Q_GET_ISSUE_TEMPLATES_AND_FOLDERS,
  });

  return response.data.issueTemplatesAndFolders;
};

export const getSiteTemplatesAndFolders: GenericWithFolderQuery<'siteTemplate'> = async (
  templateFilter,
  templateFolderFilter,
  skip,
  limit,
  orderByTemplate = ['title_ASC'],
  orderByTemplateFolder = ['name_ASC'],
) => {
  const response = await APOLLO_CLIENT.query({
    variables: {
      templateWhere: templateFilter,
      templateFolderWhere: templateFolderFilter,
      skip,
      limit,
      orderByTemplate,
      orderByTemplateFolder,
      tenant: getDefaultTenant(),
    },
    fetchPolicy: 'no-cache',
    query: Q_GET_SITE_TEMPLATES_AND_FOLDERS,
  });

  return response.data.siteTemplatesAndFolders;
};

export const getElementTemplatesAndFolders: GenericWithFolderQuery<'elementTemplate'> = async (
  templateFilter,
  templateFolderFilter,
  skip,
  limit,
  orderByTemplate = ['title_ASC'],
  orderByTemplateFolder = ['name_ASC'],
) => {
  const response = await APOLLO_CLIENT.query({
    variables: {
      templateWhere: templateFilter,
      templateFolderWhere: templateFolderFilter,
      skip,
      limit,
      orderByTemplate,
      orderByTemplateFolder,
      tenant: getDefaultTenant(),
    },
    fetchPolicy: 'no-cache',
    query: Q_GET_ELEMENT_TEMPLATES_AND_FOLDERS,
  });

  return response.data.elementTemplatesAndFolders;
};

export const getAccountTemplatesAndFolders: GenericWithFolderQuery<'accountTemplate'> = async (
  templateFilter,
  templateFolderFilter,
  skip,
  limit,
  orderByTemplate = ['title_ASC'],
  orderByTemplateFolder = ['name_ASC'],
) => {
  const response = await APOLLO_CLIENT.query({
    variables: {
      templateWhere: templateFilter,
      templateFolderWhere: templateFolderFilter,
      skip,
      limit,
      orderByTemplate,
      orderByTemplateFolder,
      tenant: getDefaultTenant(),
    },
    fetchPolicy: 'no-cache',
    query: Q_GET_ACCOUNT_TEMPLATES_AND_FOLDERS,
  });

  return response.data.accountTemplatesAndFolders;
};

export const getProfileTemplatesAndFolders: GenericWithFolderQuery<'profileTemplate'> = async (
  templateFilter,
  templateFolderFilter,
  skip,
  limit,
  orderByTemplate = ['title_ASC'],
  orderByTemplateFolder = ['name_ASC'],
) => {
  const response = await APOLLO_CLIENT.query({
    variables: {
      templateWhere: templateFilter,
      templateFolderWhere: templateFolderFilter,
      skip,
      limit,
      orderByTemplate,
      orderByTemplateFolder,
      tenant: getDefaultTenant(),
    },
    fetchPolicy: 'no-cache',
    query: Q_GET_PROFILE_TEMPLATES_AND_FOLDERS,
  });

  return response.data.profileTemplatesAndFolders;
};

export const getProfilesAndFolders: GenericWithFolderQuery<'profile'> = async (
  templateFilter,
  templateFolderFilter,
  skip,
  limit,
  orderByTemplate = ['name_ASC'],
  orderByTemplateFolder = ['name_ASC'],
): Promise<FieldPopoverItem['profile'][]> => {
  const response = await APOLLO_CLIENT.query({
    variables: {
      templateWhere: templateFilter,
      templateFolderWhere: templateFolderFilter,
      skip,
      limit,
      orderByTemplate,
      orderByTemplateFolder,
      tenant: getDefaultTenant(),
    },
    fetchPolicy: 'no-cache',
    query: Q_GET_PROFILES_AND_FOLDERS,
  });

  return response.data.profilesAndFolders;
};

export const getAccountsAndFolders: GenericWithFolderQuery<'account'> = async (
  templateFilter,
  templateFolderFilter,
  skip,
  limit,
  orderByTemplate = ['name_ASC'],
  orderByTemplateFolder = ['name_ASC'],
): Promise<FieldPopoverItem['account'][]> => {
  const response = await APOLLO_CLIENT.query({
    variables: {
      templateWhere: templateFilter,
      templateFolderWhere: templateFolderFilter,
      skip,
      limit,
      orderByTemplate,
      orderByTemplateFolder,
      tenant: getDefaultTenant(),
    },
    fetchPolicy: 'no-cache',
    query: Q_GET_ACCOUNTS_AND_FOLDERS,
  });

  return response.data.accountsAndFolders;
};

export const getUnitsAndFolders: GenericWithFolderQuery<'unit'> = async (
  templateFilter,
  templateFolderFilter,
  skip,
  limit,
  orderByTemplate = ['order_ASC'],
  orderByTemplateFolder = ['name_ASC'],
): Promise<FieldPopoverItem['unit'][]> => {
  const response = await APOLLO_CLIENT.query({
    variables: {
      templateWhere: templateFilter,
      templateFolderWhere: templateFolderFilter,
      skip,
      limit,
      orderByTemplate,
      orderByTemplateFolder,
      tenant: getDefaultTenant(),
    },
    fetchPolicy: 'no-cache',
    query: Q_GET_UNITS_AND_FOLDERS,
  });

  return response.data.unitsAndFolders;
};

export const getIssueCatalogsAndFolders: GenericWithFolderQuery<'issueCatalog'> = async (
  templateFilter,
  templateFolderFilter,
  skip,
  limit,
  orderByTemplate = ['name_ASC'],
  orderByTemplateFolder = ['name_ASC'],
) => {
  const response = await APOLLO_CLIENT.query({
    variables: {
      templateWhere: templateFilter,
      templateFolderWhere: templateFolderFilter,
      skip,
      limit,
      orderByTemplate,
      orderByTemplateFolder,
      tenant: getDefaultTenant(),
    },
    fetchPolicy: 'no-cache',
    query: GetIssueCatalogsAndFoldersDocument,
  });

  return response.data.issueCatalogsAndFolders;
};

export const getIssueCatalogsFolders = async (
  limit: number,
  skip: number,
  where: IssueCatalogFolderWhereInput,
): Promise<FieldPopoverItem['issueCatalogScheduledFolder'][]> => {
  const response = await APOLLO_CLIENT.query({
    variables: {
      limit,
      skip,
      where,
      tenant: getDefaultTenant(),
    },
    fetchPolicy: 'no-cache',
    query: GetIssueCatalogFoldersSimpleDocument,
  });

  return response.data.issueCatalogFolders;
};

export type GenericWithFolderQuery<T extends FieldPopoverContextTemplates> = (
  templateFilter?: NexusGenInputs[`${Capitalize<T>}WhereInput`],
  templateFolderFilter?: NexusGenInputs[`${Capitalize<T>}FolderWhereInput`],
  skip?: number,
  limit?: number,
  orderByTemplate?: NexusGenEnums[`${Capitalize<T>}OrderByInput`][],
  orderByTemplateFolder?: NexusGenEnums[`${Capitalize<T>}FolderOrderByInput`][],
) => Promise<FieldPopoverItem[T][]>;

export const getWithFoldersGenerics: {
  [P in FieldPopoverContextTemplates]: GenericWithFolderQuery<P>;
} = {
  issueCatalog: getIssueCatalogsAndFolders,
  issueTemplate: getIssueTemplatesAndFolders,
  siteTemplate: getSiteTemplatesAndFolders,
  elementTemplate: getElementTemplatesAndFolders,
  accountTemplate: getAccountTemplatesAndFolders,
  profileTemplate: getProfileTemplatesAndFolders,
  profile: getProfilesAndFolders,
  account: getAccountsAndFolders,
  unit: getUnitsAndFolders,
};
