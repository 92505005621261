import { Breadcrumbs } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import * as React from 'react';
import { styles } from 'src/base/breadcrumb/styles/breadcrumb';
import i18n from 'src/utils/translations/i18n';
import { connect } from 'react-redux';
import BreadcrumbItem from './components/breadcrumb.item';
import { useLocation } from 'react-router-dom';

interface BreadcrumbProps {
  classes: Record<string, string>;
  trailName: string;
}

const generateBreadcrumbItems = (path: string[], classes: Record<string, string>, trailName: string) => {
  return path.map((nav, index, pathArray) => {
    const label: string[] = nav.split('-');
    const labelJoined = label.map((n) => i18n.t(n)).join(' ');

    //Get entire path until current breadcrumb
    const navPath = () => {
      return pathArray.slice(0, index + 1).join('/') + '/';
    };

    // For render the middle parts of path
    if (index !== 0 && index !== path.length - 1) {
      return <BreadcrumbItem key={index} label={labelJoined} path={navPath()} classes={classes} isLink={true} />;
    }
    // If path only have the "/" render "/" as "Home" without link
    else if (index === 0 && index === path.length - 2 && path[1] === '') {
      return <BreadcrumbItem key={index} label={'home'} path={path[index]} classes={classes} isLink={false} />;
    }
    //For render last part of breadcrumb without Link
    else if (index === path.length - 1 && path[parseInt(`${index}`)] !== '') {
      return (
        <BreadcrumbItem
          key={index}
          label={trailName ? trailName : pathArray[index as number]}
          path={path[index]}
          classes={classes}
          isLink={false}
        />
      );
    }
    // When path have more than "/" to render the "/" with name of "Home"
    else if (index === 0) {
      return <BreadcrumbItem key={index} label={'home'} path={path[index]} classes={classes} isLink={true} />;
    }
    return undefined;
  });
};

const Breadcrumb: React.FC<BreadcrumbProps> = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const { trailName } = props;
  const { classes } = props;
  const path = pathname.split('/');

  const breadcrumbItems = generateBreadcrumbItems(path, classes, trailName);

  return (
    <Breadcrumbs classes={{ root: classes.breadcrumb }} enzyme-attr='component-breadcrumb'>
      {breadcrumbItems}
    </Breadcrumbs>
  );
};

const mapStateToProps = (state) => {
  const { trailName } = state.rootReducer;
  return {
    trailName,
  };
};

const mapDispatchToProps = () => ({});

export const render = {
  view: {
    default:
      // @ts-ignore
      withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Breadcrumb)),
  },
};

export default render;
