import gql from 'graphql-tag';
import { LABEL_BODY, LABEL_VALUE } from 'src/utils/fragments/label';

export const Q_GET_LABELS = gql`
  query GetLabels($skip: Int, $limit: Int, $filter: LabelWhereInput, $tenant: ID) {
    labels(skip: $skip, limit: $limit, where: $filter, orderBy: name_ASC, tenant: $tenant) {
      ...label
    }
  }
  ${LABEL_BODY}
`;

export const M_CREATE_LABEL = gql`
  mutation CreateLabel($name: String!, $context: LabelContext, $singleSelection: Boolean, $order: Int) {
    createLabel(data: { name: $name, context: $context, singleSelection: $singleSelection, order: $order }) {
      ...label
    }
  }
  ${LABEL_BODY}
`;

export const M_CREATE_VALUE = gql`
  mutation CreateValue($labelId: ID!, $name: String!, $backgroundColor: String, $description: String) {
    createLabelValue(
      data: { label: $labelId, value: $name, backgroundColor: $backgroundColor, description: $description }
    ) {
      label {
        ...label
      }
    }
  }
  ${LABEL_BODY}
  ${LABEL_VALUE}
`;

export const M_CREATE_MANY_VALUES = gql`
  mutation CreateManyValues($data: [LabelValueCreateInput]) {
    createManyLabelValues(data: $data) {
      _id
    }
  }
`;

export const M_UPDATE_LABEL = gql`
  mutation UpdateLabel($name: String, $_id: ID!, $context: LabelContext) {
    updateLabel(data: { name: $name, context: $context }, where: { _id: $_id }) {
      ...label
    }
  }
  ${LABEL_BODY}
  ${LABEL_VALUE}
`;

export const M_UPDATE_LABELS = gql`
  mutation UpdateLabels($_ids: [ID!]!) {
    changeLabelsOrder(_ids: $_ids) {
      ...label
    }
  }
  ${LABEL_BODY}
  ${LABEL_VALUE}
`;

export const M_CHANGE_LABEL_VALUES_ORDER = gql`
  mutation ChangeLabelValuesOrder($labelId: ID!, $labelValues: [labelValuesReorder]) {
    changeLabelValuesOrder(labelId: $labelId, labelValues: $labelValues) {
      ...labelValue
    }
  }
  ${LABEL_VALUE}
`;

export const M_DELETE_VALUE = gql`
  mutation DeleteValue($_id: ID!) {
    deleteOneLabelValue(where: { _id: $_id }) {
      label {
        ...label
      }
    }
  }
  ${LABEL_BODY}
  ${LABEL_VALUE}
`;

export const M_UPDATE_VALUE = gql`
  mutation UpdateValue($name: String, $_id: ID!, $labelId: ID, $backgroundColor: String, $description: String) {
    updateLabelValue(
      data: { value: $name, backgroundColor: $backgroundColor, label: $labelId, description: $description }
      where: { _id: $_id }
    ) {
      label {
        ...label
      }
    }
  }
  ${LABEL_BODY}
  ${LABEL_VALUE}
`;

export const M_DELETE_LABEL = gql`
  mutation DeleteLabel($_id: ID!) {
    deleteOneLabel(where: { _id: $_id }) {
      ...label
    }
  }
  ${LABEL_BODY}
  ${LABEL_VALUE}
`;

export const Q_GET_LABEL_VALUES_PAGINATED = gql`
  query GetLabelValuesPaginated($limit: Int, $skip: Int, $filter: LabelValueWhereInput, $tenant: ID) {
    labelValues(limit: $limit, skip: $skip, where: $filter, orderBy: value_ASC, tenant: $tenant) {
      ...labelValue
    }
  }
  ${LABEL_VALUE}
`;

export const Q_GET_LABEL_VALUES_COUNT = gql`
  query GetLabelValuesCount($where: LabelValueWhereInput, $tenant: ID) {
    labelValuesCount(where: $where, tenant: $tenant)
  }
`;

export const Q_FILTER_BUILDER_CONDITION_LABEL_VALUES = gql`
  query GetFilterBuilderConditionLabelValues($filter: TaskTemplateAdvancedLabelSelectionFilterUpdateInput) {
    filterBuilderConditionLabelValues(filter: $filter) {
      ...labelValue
    }
  }
  ${LABEL_VALUE}
`;
