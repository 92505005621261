import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    popoverContainer: {
      height: '100vh',
      left: 'unset !important',
      right: '0',
      width: '392px',
      maxHeight: 'unset !important',
      display: 'flex',
      flexDirection: 'column',
      top: '0 !important',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '20px 10px',
    },
    editResponsesTitle: {
      fontSize: '20px',
      fontWeight: 500,
    },
    closeSettings: {
      cursor: 'pointer',
    },
    responsesSettingsContainer: {
      flexGrow: 1,
      overflow: 'auto',
    },
    responseSetting: {
      display: 'flex',
      background: theme.palette.background.background0,
      padding: '21px 4px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: '6px',
    },
    settingsDivider: {
      height: '2px',
    },
    dragIcon: {
      fill: theme.palette.text.disabled,
      cursor: 'pointer',
    },
    addResponseSettingsBtn: {
      margin: '24px 10px',
    },
    addResponseSettingsIcon: {
      fontSize: '14px',
      fontWeight: 400,
    },
    addResponseSettings: {
      fontSize: '14px',
      fontWeight: 500,
    },
    actionGroup: {
      display: 'flex',
      alignSelf: 'flex-end',
      alignItems: 'center',
      justifyItems: 'flex-end',
      gap: '16px',
      padding: '18px 24px',
    },
    btnAction: {
      padding: '6px 16px',
    },
    deleteIcon: {
      marginLeft: '19px',
      marginRight: '16px',
      cursor: 'pointer',
    },
    modalContainer: {
      background: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      gap: '26px',
      padding: '18px 28px 12px 28px',
      width: 'fit-content',
      height: 'fit-content',
      position: 'absolute',
      top: '50%',
      left: '50%',
      border: 'unset',
      transform: 'translate(-50%, -50%)',
      borderRadious: '6px',
    },
    modalContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '12px',
      maxWidth: '350px',
    },
    modalTitle: {
      fontSize: '16px',
      fontWeight: 500,
    },
    modalContent: {
      fontSize: '16px',
      fontWeight: 400,
      color: theme.palette.grey.light,
      textAlign: 'center',
    },
    modalBtnContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '12px',
    },
  });
