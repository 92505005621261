import { Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteDialogContainer: {
      maxWidth: '100%',
      padding: '24px',
    },
    deleteDialogTitle: {
      color: theme.palette.secondary.main,
      textAlign: 'center',
      fontFamily: 'Poppins',
      fontSize: '16px',
      marginBottom: '24px',
    },
    deleteDialogSubtitle: {
      color: theme.palette.grey.main,
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontSize: '16px',
      marginBottom: '24px',
    },
    deleteDialogBtns: {
      display: 'flex',
      justifyContent: 'center',
      gap: '8px',
    },
  }),
);
