export const getImageForReport = (src: string): HTMLImageElement => {
  const image = new Image();
  image.crossOrigin = 'Anonymous';
  image.src = src;
  return image;
};

export const getActionColor = (status: string) => {
  if (status === 'DOING') return '#2470B3';
  else if (status === 'PENDING') return '#FFD279';
  else if (status === 'SOLVED') return '#00C48C';
  else if (status === 'CANT_DO') return '#F44336';
  else return '#2470B3';
};
