import { Fragment, Suspense } from 'react';
import { Popper, useTheme } from '@mui/material';
import { styles } from './styles';
import { CommentMentionCreateInput, PostFragmentFragment, useGetAccountsSuspenseQuery } from 'src/gql/graphql';
import ReactLoading from 'react-loading';
import makeStyles from '@mui/styles/makeStyles';
import { APOLLO_CLIENT } from 'config/apollo.config';
import { PostFragment } from '../../feed.queries';

const useStyles = makeStyles(styles);

interface AccountMentionProps {
  anchorEl: HTMLElement;
  textToAutocomplete: string;
  onComplete: (mention: CommentMentionCreateInput) => any;
  postId: string;
}

export default function AccountMention(props: AccountMentionProps) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Popper
      className={classes.paper}
      placement={'bottom'}
      open={true}
      anchorEl={props.anchorEl}
      style={{ width: props.anchorEl.getBoundingClientRect().width }}
    >
      <Suspense fallback={<ReactLoading type={'spin'} color={theme.palette.text.secondary} height={50} width={50} />}>
        <FetchAccountMention {...props} />
      </Suspense>
    </Popper>
  );
}

function FetchAccountMention(props: AccountMentionProps) {
  const { textToAutocomplete, postId } = props;

  const post = APOLLO_CLIENT.readFragment<PostFragmentFragment>({
    id: `Post:${postId}`,
    fragment: PostFragment,
    fragmentName: 'postFragment',
  });

  const { data } = useGetAccountsSuspenseQuery({
    variables: {
      skip: 0,
      limit: 5,
      filter: {
        name_contains: textToAutocomplete ? textToAutocomplete : undefined,
        labelValues_in: post.labelValues.length ? post.labelValues.map((l) => l._id) : undefined,
      },
    },
    fetchPolicy: 'cache-first',
  });

  const classes = useStyles();

  return data.accounts.map((acc) => (
    <div
      key={acc._id}
      data-testid={'complete-' + acc.name}
      className={classes.listItem}
      onClick={() =>
        props.onComplete({
          type: 'account' as any,
          text: `@${acc.name}`,
          account: acc._id,
        })
      }
    >
      <span>
        <span className={classes.glarHash}>@</span>
        {acc.name.split(textToAutocomplete).map((t, index, arr) => (
          <Fragment key={index}>
            <span key={t}>{t}</span>
            {index < arr.length - 1 ? <span className={classes.glarHash}>{textToAutocomplete}</span> : null}
          </Fragment>
        ))}
      </span>
    </div>
  ));
}
