import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';

const RolesRefresh = () => lazy(() => import('src/modules/role/role'));
const RoleBodyRefresh = () => lazy(() => import('src/modules/role/role.body'));

let Roles = RolesRefresh();
let RoleBody = RoleBodyRefresh();

export const render = {
  view: {
    default: ({ ...props }) => {
      if (Roles?._result?.type === 'error') {
        Roles = RolesRefresh();
      }

      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <Roles {...props} />
        </Suspense>
      );
    },
  },
  edit: {
    default: ({ ...props }) => {
      if (RoleBody?._result?.type === 'error') {
        RoleBody = RoleBodyRefresh();
      }

      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <RoleBody {...props} />
        </Suspense>
      );
    },
  },
};

export default render;
