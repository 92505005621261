import { Popover, useTheme } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ArrowForwardIos, Close } from '@mui/icons-material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { styles } from 'src/utils/components/input-field/input-history/styles';

interface InputHistoryProps {
  classes: Record<string, string>;
  onClose: () => void;
  logs: Record<string, unknown>[];
  actions: Record<string, unknown>[];
}

interface LogsToShow {
  createdAt?: Date;
  timestamp?: Date;
  __typename?: string;
  status?: string;
  name?: string;
  _id?: string;
  string?: string;
}

const InputHistoryPopover = (props: InputHistoryProps): JSX.Element => {
  const { classes, onClose, logs, actions } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const [dataToShow, setDataToShow] = useState<LogsToShow[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const auxArray = [];
    auxArray.push(...(logs ?? []));
    auxArray.push(...actions);
    setDataToShow(
      auxArray.sort((a, b) =>
        a.createdAt || a.timestamp < b.createdAt || b.timestamp ? -1 : a.createdAt > b.createdAt ? 1 : 0,
      ),
    );

    setLoading(false);
  }, []);

  const showStatus = (status: string) => {
    switch (status) {
      case 'PENDING':
        return <div className={`${classes.statusShow} ${classes.statusPending}`}>{t('pending')}</div>;
      case 'CANT_DO':
        return <div className={`${classes.statusShow} ${classes.statusCantDo}`}>{t('cantDo')}</div>;
      case 'DOING':
        return <div className={`${classes.statusShow} ${classes.statusDoing}`}>{t('doing')}</div>;
      case 'SOLVED':
        return <div className={`${classes.statusShow} ${classes.statusSolved}`}>{t('solved')}</div>;
      default:
        return <div className={`${classes.statusShow}`}>{' - '}</div>;
    }
  };

  return (
    <>
      {loading ? null : (
        <Popover
          style={{ top: 0 }}
          open={true}
          onClose={onClose}
          marginThreshold={0}
          classes={{ paper: classes.fullPopoverContainer }}
        >
          <div className={classes.headerInfo}>
            <div className={classes.title}>{`${t('History')}`}</div>
            <Close classes={{ root: classes.closeIcon }} onClick={() => onClose()} />
          </div>
          {dataToShow.length ? (
            <div className={classes.popoverContent}>
              {dataToShow.map((data, key) => (
                <>
                  <div
                    key={key}
                    className={classes.divLineNestedLog}
                    style={{ marginLeft: '5px', display: 'flex', height: 'inherit' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        className={classes.divCircleNestedLog}
                        style={
                          key === 0
                            ? { backgroundColor: theme.palette.primary.main }
                            : { backgroundColor: theme.palette.text.secondary }
                        }
                      />
                      <div
                        className={classes.divLine}
                        style={
                          key === 0
                            ? { backgroundColor: theme.palette.primary.dark }
                            : { backgroundColor: theme.palette.background.background1 }
                        }
                      />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                      <div
                        style={{
                          width: '-webkit-fill-available',
                          marginRight: '15px',
                          marginLeft: 10,
                          marginBottom: 10,
                        }}
                      >
                        <div className={classes.timeTextFormat}>
                          {moment(data?.createdAt || data?.timestamp).format('dddd, Do MMMM YYYY HH:mm')}
                        </div>
                        {data?.__typename === 'Action' ? (
                          <div style={{ paddingTop: 15 }}>
                            <div style={{ width: 'fit-content' }}>{showStatus(data?.status)}</div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: 10,
                              }}
                            >
                              <div className={classes.actionName}>{data.name}</div>
                              <ArrowForwardIos
                                classes={{ root: classes.arrowIcon }}
                                onClick={() => navigate(`/action?name=${data.name}&id=${data._id}`)}
                              />
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className={classes.responseValueText} style={{ marginTop: 10 }}>
                              {data.name ? data.name : data.string ? data.string : '-'}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          ) : (
            <div className={classes.nothingToShow}>{t('nothingToShow')}</div>
          )}
        </Popover>
      )}
    </>
  );
};

export default compose<any>(withTranslation('translation'), withStyles(styles))(InputHistoryPopover);
