import { ApolloProvider } from '@apollo/client';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import i18n from 'src/utils/translations/i18n';
import '@assets/vendor/global-styles/style.css';
import { render as Root } from './base/root';
import { APOLLO_CLIENT } from 'config/apollo.config';
import store from 'src/reducer-manager';
import '@fontsource/open-sans';
import '@fontsource/poppins';
import '@fontsource/roboto';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import { useEffect } from 'react';
import { CustomDndProvider } from './base/header/components/custom-dnd-provider';

const queryClient = new QueryClient();

const router = createHashRouter([
  {
    path: '*',
    element: <Root.view.default />,
  },
]);

const ViteHotReload = ({ children }) => {
  useEffect(() => {
    const onPreloadErrorHandler = () => {
      window.location.reload();
    };

    window.addEventListener('vite:preloadError', onPreloadErrorHandler);

    return () => {
      window.removeEventListener('vite:preloadError', onPreloadErrorHandler);
    };
  }, []);

  return <>{children}</>;
};

const render = () => {
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(
    <ViteHotReload>
      <ApolloProvider client={APOLLO_CLIENT}>
        <I18nextProvider i18n={i18n}>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <CustomDndProvider>
                <RouterProvider router={router} />
              </CustomDndProvider>
            </QueryClientProvider>
          </Provider>
        </I18nextProvider>
      </ApolloProvider>
    </ViteHotReload>,
  );
};

render();
