import * as React from 'react';
import { Link } from 'react-router-dom';
import i18n from 'src/utils/translations/i18n';

interface BreadcrumbItemProps {
  label: string;
  path: string;
  classes: Record<string, string>;
  isLink: boolean;
}

const BreadcrumbItem: React.FC<BreadcrumbItemProps> = (props) => {
  const { label } = props;
  const { path } = props;
  const { classes } = props;
  const { isLink } = props;

  return (
    <div
      id={`breadcrumb-${label}`}
      data-testid={`breadcrumb-${label}`}
      className={`d-inline ${classes.link}`}
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '200px',
      }}
    >
      {isLink ? (
        <Link id={`anchorTo${path}`} to={path}>
          {i18n.t(`${label}`)}
        </Link>
      ) : (
        i18n.t(`${label}`)
      )}
    </div>
  );
};

export default BreadcrumbItem;
