import { Chip } from '@mui/material';
import { CallSplit, Close, LiveHelpOutlined } from '@mui/icons-material';
import { cloneDeep } from 'lodash';
import { WithStyles, withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { styles } from 'src/utils/components/generic-input-card/styles';

interface AnswerNewQuestionChipProps extends WithStyles<typeof styles> {
  input: any;
  active: boolean;
  setInput: (val) => void;
}

const AnswerNewQuestionChip = (props: AnswerNewQuestionChipProps) => {
  const { t } = useTranslation();

  return (
    <div className={props.classes.createTaskChip}>
      {props?.input[props.input.type]?.onResponse?.map(
        (response): JSX.Element =>
          response.inputs.length ? (
            <Chip
              key={response._id}
              style={{ margin: '5px' }}
              icon={<CallSplit classes={{ root: props.classes.iconAlertChipRotated }} />}
              label={
                <div className={props.classes.divLabel}>
                  {t('if')}
                  <span
                    style={{
                      color: response.ifValues?.at(0)?.color,
                      backgroundColor: `${response.ifValues?.at(0)?.color}21`,
                    }}
                    className={props.classes.secondSpanCreateTask}
                  >
                    {response.ifValues?.length && response.ifValues[0] ? response.ifValues[0].label : undefined}
                  </span>
                  {t('selected')}
                  <span className={props.classes.secondSpanCreateTask}>
                    <LiveHelpOutlined fontSize='small' classes={{ root: props.classes.liveHelpIcon }} />
                    {t('answerNewQuestion')}
                  </span>
                  {props.active ? (
                    <Close
                      classes={{ root: props.classes.closeIcon }}
                      onClick={() => {
                        const clonedInput = cloneDeep(props.input);
                        const onResponse = clonedInput[props.input.type].onResponse.find(
                          (elem) => elem.ifValues[0].label === response.ifValues[0].label,
                        );
                        if (
                          onResponse.createTasks ||
                          onResponse.actionRequired ||
                          onResponse.createAlert ||
                          onResponse.finishIssue
                        ) {
                          onResponse.inputs = [];
                        } else {
                          clonedInput[props.input.type].onResponse = clonedInput[props.input.type].onResponse.filter(
                            (elem) => elem.ifValues[0].label !== response.ifValues[0].label,
                          );
                        }
                        props.setInput({
                          ...clonedInput,
                        });
                      }}
                    />
                  ) : null}
                </div>
              }
              classes={{ root: props.classes.alertChipExecution }}
            ></Chip>
          ) : null,
      )}
    </div>
  );
};

export default withStyles(styles)(AnswerNewQuestionChip);
