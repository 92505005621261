import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import i18n from 'src/utils/translations/i18n';
import { logout } from 'src/base/login/login.redux';
import { clearPhoto } from 'src/base/profile-dropdown/profile-dropdown.redux';
import { accountSetSite } from 'src/modules/account/account.redux';
import { styles } from 'src/base/profile-dropdown/styles/profile-dropdown';
import { MenuItem, ListItemIcon, List, ListSubheader, useTheme } from '@mui/material';
import CustomDropdown from 'src/utils/components/custom-dropdown/index';
import { Person, ExitToApp } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { WithTheme, withTheme } from '@mui/styles';
import { getTenantInfo } from 'src/modules/authentication/authentication.redux';
import { useNavigate } from 'react-router-dom';
import { AccountAvatar } from 'src/utils/components/account-avatar';

interface ProfileDropdownProps extends ConnectedProps<typeof connector>, WithTheme {
  setSavingPhoto(arg0: boolean): void;
  savingPhoto: boolean;
  open: React.SetStateAction<boolean>;
}

const useStyles = makeStyles(styles);

const ProfileDropdown = (props: ProfileDropdownProps) => {
  const classes = useStyles();
  const [, setDropdownOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    /* eslint-disable-next-line security/detect-non-literal-fs-filename */
    setDropdownOpen(props.open);
  }, [props.open]);

  const loadAccounts = async (account) => {
    if (account) {
      localStorage.setItem('username', account.name);
    }
  };

  useEffect(() => {
    loadAccounts(props.loggedUser);
    const splitHostname = window.location.hostname.split('.');
    props.getTenantInfo(
      /(.glarcloud.com)$/i.exec(window.location.hostname) || /(.glartek.com)$/i.exec(window.location.hostname)
        ? splitHostname.length > 3
          ? `${splitHostname[splitHostname.length - 4]}.${splitHostname[splitHostname.length - 3]}`
          : splitHostname[0]
        : '',
    );
  }, [props.loggedUser?._id]);

  const logout = async () => {
    props.clearPhoto();
    props.accountSetSite(null, false);
    await props.logout();
    navigate('/');
  };

  return (
    <CustomDropdown
      id={'profileSettingsDropdown'}
      iconBtn
      button={
        <div
          id='profile-icon-button'
          data-testid='profile-icon-button'
          className={'profile-settings-item'}
          style={{ display: 'flex' }}
        >
          {props.loggedIn && <AccountAvatar data-testid={'profile-photo'} size={35} account={props.loggedUser} />}
        </div>
      }
    >
      <List
        style={{ background: theme.palette.background.default }}
        subheader={
          <ListSubheader classes={{ root: classes.subHeader }}>
            <strong id='currentUsername' data-testid='currentUsername'>
              {localStorage.getItem('username')}
            </strong>
          </ListSubheader>
        }
      >
        <MenuItem
          id={'menuItemProfileSettings'}
          enzyme-attr='profile-settings-item'
          classes={{ root: classes.listItem }}
          className={`profile-settings-item`}
          onClick={() =>
            // openProfileSettings
            navigate(`/user-profile?id=${props.loggedUser._id}`)
          }
        >
          <ListItemIcon>
            <Person classes={{ root: classes.icon }} />
          </ListItemIcon>
          {i18n.t('profileSettings')}
        </MenuItem>
        <MenuItem
          id={'menuItemLogout'}
          enzyme-attr='a-logout'
          classes={{ root: classes.listItem }}
          className={`a-logout`}
          onClick={logout}
        >
          <ListItemIcon>
            <ExitToApp classes={{ root: classes.icon }} />
          </ListItemIcon>
          {i18n.t('logout')}
        </MenuItem>
      </List>
    </CustomDropdown>
  );
};

const connector = connect(
  (state) => ({ loggedUser: state.loginReducer.loggedUser, loggedIn: state.loginReducer.loggedIn }),
  {
    logout,
    clearPhoto,
    accountSetSite,
    getTenantInfo,
  },
);

export const render = {
  view: {
    default: compose(withTheme, withTranslation('translation'), connector)(ProfileDropdown),
  },
};

export default render;
