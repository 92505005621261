import { useMemo, useCallback, memo } from 'react';
import { Pie, PieChart, ResponsiveContainer, Cell, Tooltip, Legend } from 'recharts';
import { calculateTotal, convertToPercentage, renderPercentage } from '../../widget-helper';
import { sortBy } from 'lodash';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { createStyles, useTheme } from '@mui/styles';
import i18n from 'src/utils/translations/i18n';

const RADIAN = Math.PI / 180;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.background1,
      padding: 8,
      borderRadius: 5,
      '& span': {
        textAlign: 'center',
      },
    },
  }),
);

const PieChartWidget = (props: any) => {
  const classes = useStyles();
  const theme = useTheme();

  const total = useMemo(() => calculateTotal(props.result), [props.result, props.showAsPercentage]);

  const data = useMemo<{ [x: string]: any; name: any }[]>(() => {
    if (props.result?.length) {
      return sortBy(props.result, 'name').map((inp) => {
        if (inp.metadata) {
          return {
            value: inp[inp.name] || inp.count || 0,
            name:
              props.by === 'labelValues' || props.by === 'assignedLabelValues'
                ? `${inp.metadata[inp.name].label.name}: ${inp.metadata[inp.name].name}`
                : inp.metadata[inp.name]?.name,
            color:
              inp.metadata?.[inp.name]?.backgroundColor && inp.metadata[inp.name].backgroundColor != '#000'
                ? inp.metadata?.[inp.name]?.backgroundColor
                : undefined,
          };
        } else {
          return {
            name: i18n.t(inp.name),
            value: inp[inp.name] || inp.count || 0,
            color: undefined,
          };
        }
      });
    } else {
      return [];
    }
  }, [props.result, props.showAsPercentage, props.by]);

  const renderCustomizedLabel = useCallback(
    ({ cx, cy, midAngle, innerRadius, outerRadius, value, startAngle, endAngle }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 1.1;
      const diffAngle = endAngle - startAngle;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      const perimeter = radius * 2 * Math.PI * (diffAngle / 360);

      return (
        <text
          x={x}
          y={y}
          fill={theme.palette.text.primary}
          textAnchor={x > cx ? 'start' : 'end'}
          fontFamily={'Open Sans'}
          dominantBaseline='central'
        >
          {perimeter < 10
            ? undefined
            : renderPercentage(
                props.showAsPercentage ? convertToPercentage(value, total, props.showAsPercentage) : value,
                props.showAsPercentage,
              )}
        </text>
      );
    },
    [props.showAsPercentage, props.result],
  );

  return props.dataType === 'realtime' ? (
    <div />
  ) : (
    <ResponsiveContainer id={props.id} width={'100%'} height='100%'>
      <PieChart width={730} height={250}>
        {useMemo(
          () => (
            <Pie
              data={data.map((d, i) => ({ ...d, color: d.color ?? props.palette[i % props.palette.length] }))}
              cx='50%'
              cy='50%'
              labelLine={false}
              isAnimationActive={false}
              label={renderCustomizedLabel}
              nameKey={'name'}
              dataKey='value'
            >
              {data.map((k, i) => (
                <Cell key={k.name} fill={k.payload?.color ?? props.palette[i % props.palette.length]} />
              ))}
            </Pie>
          ),
          [data, props.showAsPercentage, props.palette],
        )}
        <Tooltip
          content={({ active, payload }) => {
            if (!active || !payload.length) {
              return null;
            }

            const value = payload[0].value as any;
            const label = payload[0].name;

            return (
              <div className={classes.tooltip}>
                <span>
                  {renderPercentage(
                    props.showAsPercentage ? parseInt(value) : convertToPercentage(parseInt(value), total, true),
                    !props.showAsPercentage,
                  )}
                </span>
                <span>{label}</span>
              </div>
            );
          }}
        />
        {props.showLegend ? (
          <Legend
            wrapperStyle={{
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
            }}
            height={70}
            iconType={'circle'}
            layout='horizontal'
            verticalAlign='bottom'
            align='center'
          />
        ) : null}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default memo(PieChartWidget);
