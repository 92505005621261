import { AGORA_APP_ID, ASSIST_WEBSOCKET_URL, SENTRY_WEB_DSN } from '../env';
import { getWebsocketBasePath } from './utilities';

// CHAT REDUCER --------------------------------------------------------------------------------
export const REMOVE_FILE_FROM_MESSAGE = 'REMOVE_FILE_FROM_MESSAGE';

export const SET_FILE = 'SET_FILE';

export const ADD_FILE = 'ADD_FILE';

export const REMOVE_FILE = 'REMOVE_FILE';

export const SET_FILES = 'SET_FILES';

export const TOGGLE_PARTICIPANTS_DISPLAY = 'TOGGLE_PARTICIPANTS_DISPLAY';

export const TOGGLE_INVITE_PARTICPANTS_DISPLAY = 'TOGGLE_INVITE_PARTICPANTS_DISPLAY';

export const TOGGLE_QR_CODE_DISPLAY = 'TOGGLE_QR_CODE_DISPLAY';
// ---------------------------------------------------------------------------------------------
export const ERROR_MESSAGE = 'ERROR_MESSAGE';

export const LOGIN = 'LOGIN';

export const LOGOUT = 'LOGOUT';

export const UPDATE_TUTORIAL = 'UPDATE_TUTORIAL';

export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const UPDATE_USER = 'UPDATE_USER';

export const SET_PROFILE_PHOTO = 'SET_PROFILE_PHOTO';

export const SET_EMAIL_VERIFY = 'SET_EMAIL_VERIFY';

export const TUTORIAL_DIALOG_SHOWED = 'TUTORIAL_DIALOG_SHOWED';

export const SET_WELCOME_MESSAGE = 'SET_WELCOME_MESSAGE';

export const SET_SNACKBAR = 'SET_SNACKBAR';

export const SNACKBAR_CLEAR = 'SNACKBAR_CLEAR';

export const SET_MEETING_BUTTON = 'SET_MEETING_BUTTON';

export const GET = 'GET';

export const SET_STREAM = 'SET_STREAM';

export const SET_CAMERAS = 'SET_CAMERAS';

export const SET_MICROPHONES = 'SET_MICROPHONES';

export const SET_SPEAKERS = 'SET_SPEAKERS';

export const SET_SELECTED_CAMERA = 'SET_SELECTED_CAMERA';

export const SET_SELECTED_MICROPHONE = 'SET_SELECTED_MICROPHONE';

export const SET_SELECTED_SPEAKER = 'SET_SELECTED_SPEAKER';

export const AUDIO_PAUSED = 'AUDIO_PAUSED';

export const VIDEO_PAUSED = 'VIDEO_PAUSED';

export const CAPTURE_MOUSE = 'CAPTURE_MOUSE';

export const SET_REMOTE_PEER_DETAILS = 'SET_REMOTE_PEER_DETAILS';

export const SET_DATACHANNEL = 'SET_DATACHANNEL';

export const SET_LOCAL_VIDEO = 'SET_LOCAL_VIDEO';

export const SET_VIDEO_RATIO = 'SET_VIDEO_RATIO';

export const SET_RTC_PEER_CONN = 'SET_RTC_PEER_CONN';

export const SET_SOCKET = 'SET_SOCKET';

export const SET_ROOM = 'SET_ROOM';

export const SET_NAME = 'SET_NAME';

export const SET_SESSION_ID = 'SET_SESSION_ID';

export const SET_USER_LIST = 'SET_USER_LIST';

export const CLEAR_SOCKET = 'CLEAR_SOCKET';

export const SET_ROOM_SESSION = 'SET_ROOM_SESSION';

export const ADD_USER = 'ADD_USER';

export const DELETE_USER = 'DELETE_USER';

export const SET_ROOM_PARTICIPANTS = 'SET_ROOM_PARTICIPANTS';

export const SET_AUDIO_STATE = 'SET_AUDIO_STATE';

export const SET_AGORA_RTC = 'SET_AGORA_RTC';

export const SEND_TEXT_MESSAGE = 'SEND_TEXT_MESSAGE';

export const SET_ENTITY = 'SET_ENTITY';

export const SET_ENABLE_BUTTON = 'SET_ENABLE_BUTTON';

export const SET_USERS = 'SET_USERS';

export const SET_CHANNEL = 'SET_CHANNEL';

export const SET_CHANNEL_ID = 'SET_CHANNEL_ID';

export const SET_UID = 'SET_UID';

export const SET_AR_PLAYING = 'SET_AR_PLAYING';

export const SET_AR_TORCH = 'SET_AR_TORCH';

export const SET_USER_AUDIO = 'SET_USER_AUDIO';

export const SET_AR_CONFIG = 'SET_AR_CONFIG';

export const SET_AR_UID = 'SET_AR_UID';

export const SET_VIDEO_TRACK_ID = 'SET_VIDEO_TRACK_ID';

export const SET_ENABLE_RECORD = 'SET_ENABLE_RECORD';

export const EXIT_AGORA = 'EXIT_AGORA';

export const SET_CHAT_DISPLAY = 'SET_CHAT_DISPLAY';

export const SET_UNREAD_MESSAGES = 'SET_UNREAD_MESSAGES';

export const SET_MESSAGES = 'SET_MESSAGES';

export const CLEAR_CHAT = 'CLEAR_CHAT';

export const SEND_MESSAGE = 'SEND_MESSAGE';

export const SHOW_CHAT_SNACKBAR = 'SHOW_CHAT_SNACKBAR';

export const SET_GALLERY_DISPLAY = 'SET_GALLERY_DISPLAY';

export const SET_UNSEEN_SCREENSHOT = 'SET_UNSEEN_SCREENSHOT';

export const ADD_SCREENSHOT = 'ADD_SCREENSHOT';

export const DELETE_SCREENSHOT = 'DELETE_SCREENSHOT';

export const SHOW_SCREENSHOT_PREVIEW = 'SHOW_SCREENSHOT_PREVIEW';

export const CLEAR_GALLERY = 'CLEAR_GALLERY';

export const SET_MESSAGE_SENDED = 'SET_MESSAGE_SENDED';

export const SET_ANCHOR_KEY = 'SET_ANCHOR_KEY';

export const CLEAR_EVENTS = 'CLEAR_EVENTS';

export const SET_ORGANIZATION_DATA = 'SET_ORGANIZATION_DATA';

export const SET_ORGANIZATION_MEMBERS = 'SET_ORGANIZATION_MEMBERS';

export const SET_INVITES_NUMBER = 'SET_INVITES_NUMBER';

export const SET_MEMBERS_NUMBER = 'SET_MEMBERS_NUMBER';

export const DECREMENT_MEMBERS_NUMBER = 'DECREMENT_MEMBERS_NUMBER';

export const SET_ORGANIZATION_LOADING = 'SET_ORGANIZATION_LOADING';

export const SET_ORG_DEFAULT_TABLE = 'SET_ORG_DEFAULT_TABLE';

export const SET_ORG_PENDING_TABLE = 'SET_ORG_PENDING_TABLE';

export const SET_TOKEN = 'SET_TOKEN';

export const SET_LAST_ROOM_TOKEN = 'SET_LAST_ROOM_TOKEN';

export const SET_RENDER_MODE_MODAL = 'SET_RENDER_MODE_MODAL';

export const SET_SOCKET_STATUS = 'SET_SOCKET_STATUS';

export const SET_SHOW_QUALITY_CONTROL = 'SET_SHOW_QUALITY_CONTROL';

export const EXIT_SESSION = 'EXIT_SESSION';

export const SDP_OFFER = 'SDP_OFFER';
export const SDP_ANSWER = 'SDP_ANSWER';
export const ICE_CANDIDATE = 'ICE_CANDIDATE';
export const WS_SERVER_URL = getWebsocketBasePath(ASSIST_WEBSOCKET_URL || '/api/websocket');

export let AGORA_ID = `${AGORA_APP_ID}`;
export let SENTRY_DSN = SENTRY_WEB_DSN;
export let WEB_DEBUG = false;

export function setAgoraID(id: string): void {
  AGORA_ID = id;
}
export function setSentryDSN(dsn: string): void {
  SENTRY_DSN = dsn;
}
export function setWebDebug(status: boolean): void {
  WEB_DEBUG = status;
}

//export const IS_DEV_ENV = CURRENT_ENVIRONMENT == 'dev';

// This a regex used to match with the room codes
// They are random numeric values between 0-9 with a lenght of 9
export const ROOM_CODE_REGEXP = new RegExp(`^[0-9]{9}$`);

// This is a regex used to match with tokens generated from the server
export const TOKEN_REGEXP = new RegExp(`^[a-z0-9]{26}$`);

// Max participants contains the max participants in the room
export const MAX_PARTICIPANTS = 2;

// Request Source is used to identify who did what
export const REQUEST_SOURCE = {
  LOCAL: 'local',
  REMOTE: 'remote',
};

export const LANGUAGE_PORTUGUESE = 'pt-PT';

export const LANGUAGE_ENGLISH = 'en-US';

// EXTERNAL URLS    ----------------------------------------------------------------------------
export const URL_FAQ = 'https://help.glartek.com/category/glarassist/glarassist-frequently-asked-questions';
export const URL_USER_GUIDE = 'https://help.glartek.com/category/glarvision/add-ons/visual-remote-assistance';
export const URL_ANDROID = 'https://play.google.com/store/apps/details?id=com.glartek.remoteassistance';
export const URL_VUZIX = 'https://help.glartek.com/article/glarassist-how-to-install-glarassist-on-vuzix-smartglasses';
export const URL_EPSON = 'https://help.glartek.com/article/glarassist-how-to-install-glarassist-on-epson-smartglasses';
export const URL_REALWARE = 'https://help.glartek.com/article/how-to-install-glarassist-on-realwear-smartglasses';
export const URL_HOLOLENS_2 = 'https://help.glartek.com/article/how-to-install-glarassist-on-hololens-2';

// WEBSOCKET EVENTS ----------------------------------------------------------------------------
export const WBS_EVENT_RESPONSE = 'response';

export const WBS_EVENT_HELLO = 'hello';

export const WBS_EVENT_USER_CONNECTED = 'user_connected';

export const WBS_EVENT_USER_DISCONNECTED = 'user_disconnected';

export const WBS_EVENT_RECORD_START = 'record_start';

export const WBS_EVENT_RECORD_STARTED = 'record_started';

export const WBS_EVENT_RECORD_STOP = 'record_stop';

export const WBS_EVENT_RECORD_STOPPED = 'record_stopped';

export const WBS_EVENT_RECORD_UPDATE_LAYOUT = 'record_update_layout';

export const WBS_EVENT_RECORD_UPDATED = 'record_updated';

export const WBS_EVENT_RECORD_STATUS = 'record_status';

export const WBS_EVENT_SESSION_DETAILS_UPDATE = 'session_details_update';

export const WBS_EVENT_SESSION_DETAILS_UPDATED = 'session_details_updated';

export const WBS_EVENT_AUTHENTICATION_CHALLENGE = 'authentication_challenge';

export const WBS_EVENT_APPROVAL_ACCEPTED = 'approval_accepted';

export const WBS_EVENT_APPROVAL_REJECTED = 'approval_rejected';

export const WBS_EVENT_WAITING_FOR_APPROVAL = 'waiting_for_approval';

export const WBS_EVENT_ROOM_JOIN = 'room_join';

export const WBS_EVENT_ROOM_LEAVE = 'room_leave';

export const WBS_EVENT_ROOM_COUNT = 'room_count';

export const WBS_EVENT_ROOM_CHAT = 'room_chat';

export const WBS_EVENT_ROOM_GUEST_APPROVAL = 'room_guest_approval';

export const WBS_EVENT_ROOM_INTENT_SCREEN_SHARE = 'room_intent_screen_share';

export const WBS_EVENT_ROOM_INTENT_VIDEO_SHARE = 'room_intent_video_share';

export const WBS_EVENT_RENDER_MODE = 'render_mode';

export const WBS_EVENT_SIGNALING = 'signaling';

export const WBS_EVENT_LIVE_MODE = 'live_mode';

export const WBS_EVENT_DACK = 'dack';

export const WBS_EVENT_BUBBLE = 'bubble';

export const WBS_EVENT_ROOM_DIRECT_MESSAGE = 'room_direct_message';

export const WBS_EVENT_ROOM_BROADCAST_MESSAGE = 'room_broadcast_message';

export const WBS_EVENT_ROOM_DATA_CHECK = 'room_data_check';

export const WBS_EVENT_ROOM_DATA_RECHECK = 'room_data_recheck';

export const WBS_EVENT_ROOM_DATA_CLEAR = 'room_data_clear';

export const WBS_EVENT_ROOM_USERS = 'room_users';

export const WBS_EVENT_ROOM_KICK = 'room_kick';

export const WBS_EVENT_ROOM_SESSION_KICK = 'room_session_kick';

export const WBS_EVENT_ROOM_PERMISSIONS_UPDATED = 'room_permissions_updated';

export const WBS_EVENT_SESSION_CURRENT = 'session_current';

export const WBS_EVENT_CALL_REQUEST = 'call_request';

export const WBS_EVENT_CALL_RESPONSE = 'call_response';

export const WBS_EVENT_CALL_RESPONDED = 'call_responded';

export const WBS_EVENT_CALL_CANCEL = 'call_cancel';

export const WBS_EVENT_CALL_MISS = 'call_miss';

export const WBS_EVENT_CALL_CANCELED = 'call_canceled';

export const WBS_EVENT_CALL_STATUS = 'call_status';

export const WBS_EVENT_CALL_ACCEPTED = 'call_accepted';

export const WBS_EVENT_CALL_REJECTED = 'call_rejected';

export const WBS_EVENT_CALL_ERROR = 'call_error';

export const WBS_EVENT_SESSION_CONTACT_LIST_UPDATE = 'session_contact_list_update';

export const WBS_EVENT_SESSION_KICKED = 'session_kicked';

export const WBS_EVENT_USER_CONTACT_LIST_STATUS = 'user_contact_list_status';

export const WBS_EVENT_USER_CONTACT_ACTIVE = 'user_contact_active';

export const WBS_EVENT_USER_CONTACT_INACTIVE = 'user_contact_inactive';

export const WBS_EVENT_IMAGE_FREEZE = 'image_freeze';

export const WBS_EVENT_DRAW = 'draw';

export const WBS_EVENT_DELETE = 'delete';

export const WBS_EVENT_UNDO = 'undo';
// ---------------------------------------------------------------------------------------------
