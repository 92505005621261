import { Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

export const styles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      '.swiper': {
        whiteSpace: 'pre-wrap',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
      '.swiper-slide': {
        display: 'flex',
        width: '108px',
        fontSize: '18px',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        background: 'transparent',
      },
    },
    iconDelete: {
      fontSize: '16px !important',
      color: theme.palette.error.main,
    },
    hiddenArrow: {
      visibility: 'hidden',
    },
  }),
);
