import gql from 'graphql-tag';
import { ELEMENT_TO_TREE } from 'src/utils/fragments/element';
import { INPUT } from 'src/utils/fragments/input';
import { LABEL_VALUE } from 'src/utils/fragments/label';
import {
  SITE,
  SITE_TEMPLATE,
  SITE_TEMPLATE_FOLDER,
  SITE_TO_TREE,
  SITE_TEMPLATE_TO_TREE,
  INPUT_APPROVAL,
} from 'src/utils/fragments/site';

export const Q_GET_SITES = gql`
  query GetSites($skip: Int, $limit: Int, $filter: SiteWhereInput!) {
    sitesV2(skip: $skip, limit: $limit, where: $filter, orderBy: name_ASC) {
      ...siteToTree
    }
  }
  ${SITE_TO_TREE}
`;

export const Q_GET_SITE = gql`
  query GetSite($filter: SiteWhereUniqueInput!) {
    site(where: $filter) {
      ...site
    }
  }
  ${INPUT}
  ${SITE}
`;

export const Q_GET_SITE_WITH_DELETED = gql`
  query GetSitesWithDeleted($filter: SiteWhereUniqueInput!) {
    siteWithDeleted(where: $filter) {
      ...site
    }
  }
  ${INPUT}
  ${SITE}
`;

export const Q_GET_SITES_WITH_PARENT_TREE = gql`
  query GetSitesWithParentTree($filter: SiteWhereInput!) {
    sites(where: $filter, orderBy: name_ASC) {
      __typename
      _id
      name
      parentId
      parentsTree {
        _id
        name
      }
      parentsTreeIds
      coordinates
      devicesCount
    }
  }
`;

export const Q_GET_ELEMENTS_WITH_PARENT_TREE = gql`
  query GetElementsWithParentTree($filter: ElementWhereInput!) {
    elements(where: $filter, orderBy: name_ASC) {
      name
      __typename
      _id
      parentId
      parentsTreeIds
      parentsTree {
        _id
        name
      }
      siteId
      site {
        _id
        name
        parentsTree {
          _id
          name
        }
      }
    }
  }
`;

export const Q_GET_SITES_ELEMENTS_WITH_PARENT_TREE = gql`
  query GetSitesElementsWithParentTree($filterSite: SiteWhereInput!, $filterElement: ElementWhereInput!) {
    sites(where: $filterSite, orderBy: name_ASC) {
      __typename
      _id
      name
      parentId
      parentsTree {
        _id
        name
      }
      parentsTreeIds
      coordinates
      devicesCount
    }
    elements(where: $filterElement) {
      name
      __typename
      _id
      parentId
      parentsTreeIds
      parentsTree {
        _id
        name
      }
      siteId
      site {
        _id
        name
        parentsTree {
          _id
          name
        }
      }
    }
  }
`;

export const Q_GET_TO_MAP = gql`
  query GetSiteToMap($filter: SiteWhereUniqueInput!, $radius: Float!) {
    site(where: $filter) {
      ...site
      actionsCount(where: { status_ne: SOLVED })
      nearbySites(radius: $radius) {
        issuesCount(where: { closedAt_eq: null })
        actionsCount(where: { status_ne: SOLVED })
        name
        coordinates
        _id
      }
    }
  }
  ${INPUT}
  ${SITE}
`;

export const Q_NEXT_EVENT = gql`
  query GetNextEvent($id: ID) {
    calendar(where: { data: { site_eq: $id }, nextRunAt_exists: true }, limit: 1, orderBy: nextRunAt_ASC) {
      nextRunAt
    }
  }
`;

export const M_DUPLICATE_SITE = gql`
  mutation DuplicateSite($_id: ID!, $copyChildren: Boolean!) {
    duplicateSite(_id: $_id, copyChildren: $copyChildren) {
      ...site
    }
  }
  ${INPUT}
  ${SITE}
`;

export const M_DUPLICATE_SITE_TEMPLATE = gql`
  mutation DuplicateSiteTemplate($_id: ID!, $copyChildren: Boolean!) {
    duplicateSiteTemplate(_id: $_id, copyChildren: $copyChildren) {
      ...siteTemplate
    }
  }
  ${INPUT}
  ${SITE_TEMPLATE}
`;

export const Q_GET_SITE_TO_TREE = gql`
  query SiteToTree($filter: SiteWhereInput!) {
    sitesV2(where: $filter, limit: 1000) {
      name
      _id
      __typename
      parentId
      parentsTreeIds
      coordinates
      totalItems: hasChildren
    }
  }
`;

export const Q_GET_SITE_TEMPLATES = gql`
  query GetSiteTemplates($filter: SiteTemplateWhereInput!) {
    siteTemplates(where: $filter) {
      ...siteTemplate
    }
  }
  ${INPUT}
  ${SITE_TEMPLATE}
`;

export const Q_GET_SITE_TEMPLATES_TO_TREE = gql`
  query GetSiteTemplatesToTree($filter: SiteTemplateWhereInput!) {
    siteTemplates(where: $filter, orderBy: title_ASC) {
      ...siteTemplateToTree
    }
  }
  ${SITE_TEMPLATE_TO_TREE}
`;

export const Q_GET_SITE_TEMPLATES_AND_FOLDERS = gql`
  query GetSiteTemplatesAndFolders(
    $skip: Int
    $limit: Int
    $templateWhere: SiteTemplateWhereInput
    $templateFolderWhere: SiteTemplateFolderWhereInput
    $orderByTemplate: [SiteTemplateOrderByInput!]!
    $orderByTemplateFolder: [SiteTemplateFolderOrderByInput!]!
    $tenant: ID
  ) {
    siteTemplatesAndFolders(
      skip: $skip
      limit: $limit
      templateWhere: $templateWhere
      templateFolderWhere: $templateFolderWhere
      templateOrderBy: $orderByTemplate
      templateFolderOrderBy: $orderByTemplateFolder
      tenant: $tenant
    ) {
      ... on SiteTemplate {
        ...siteTemplate
      }
      ... on SiteTemplateFolder {
        ...siteTemplateFolder
      }
    }
  }
  ${INPUT}
  ${SITE_TEMPLATE}
  ${SITE_TEMPLATE_FOLDER}
`;

export const M_CREATE_SITE = gql`
  mutation CreateSite(
    $name: String!
    $description: String
    $geoPoint: [Float!]!
    $inputs: [InputCreateInput!]
    $parent: ID
    $templateId: ID!
    $labelValues: [ID]
  ) {
    createSite(
      data: {
        name: $name
        description: $description
        coordinates: $geoPoint
        inputs: $inputs
        parent: $parent
        template: $templateId
        labelValues: $labelValues
      }
    ) {
      ...site
    }
  }
  ${INPUT}
  ${SITE}
`;

export const M_CREATE_MANY_SITES = gql`
  mutation CreateManySites($data: [SiteCreateInput]) {
    createManySites(data: $data)
  }
`;

export const M_UPDATE_SITE = gql`
  mutation UpdateSite(
    $name: String
    $description: String
    $geoPoint: [Float]
    $_id: ID!
    $inputs: [InputUpdateInput!]
    $templateId: ID
    $parentId: ID
    $labelValues: [ID]
  ) {
    updateSite(
      data: {
        name: $name
        description: $description
        coordinates: $geoPoint
        inputs: $inputs
        template: $templateId
        labelValues: $labelValues
        parent: $parentId
      }
      where: { _id: $_id }
    ) {
      ...site
    }
  }
  ${INPUT}
  ${SITE}
`;

export const M_CHANGE_INPUTS_RESPONSIBLES = gql`
  mutation ChangeInputsResponsibles($id: ID!, $responsibles: InputsApprovalResponsibles, $propagateDownwards: Boolean) {
    changeInputsResponsibles(
      where: { _id: $id }
      responsibles: $responsibles
      propagateDownwards: $propagateDownwards
    ) {
      _id
      needsApproval
      responsibles {
        accounts {
          __typename
          username
          name
          email
          photo
          _id
        }
        labelValues {
          ...labelValue
        }
      }
    }
  }
  ${LABEL_VALUE}
`;

gql`
  mutation ChangeManyInputsApprovalsLight($fields: [ApprovalsWhereInput!]!, $approved: Boolean!) {
    changeManyInputsApprovals(fields: $fields, approved: $approved) {
      _id
    }
  }

  mutation ChangeManyInputsApprovals($fields: [ApprovalsWhereInput!]!, $approved: Boolean!) {
    changeManyInputsApprovals(fields: $fields, approved: $approved) {
      _id
      name
      image {
        _id
        name
      }
      description
      coordinates
      tags {
        __typename
        _id
        name
        value
        key
        unit {
          name
          symbol
          _id
        }
        device {
          name
          _id
        }
      }
      labelValues {
        ...labelValue
      }
      awaitingApproval
      pendingInputs {
        ...input
      }
      inputs {
        ...input
      }
    }
  }
`;

export const M_DELETE_SITE = gql`
  mutation DeleteSite($id: ID!) {
    deleteOneSite(where: { _id: $id }) {
      _id
      parent {
        _id
      }
    }
  }
`;

export const M_CREATE_SITE_TEMPLATE = gql`
  mutation CreateSiteTemplate($title: String!, $folder: ID!, $inputs: [InputCreateInput!], $icon: String) {
    createSiteTemplate(data: { title: $title, folder: $folder, frame: { inputs: $inputs }, icon: $icon }) {
      ...siteTemplate
    }
  }
  ${INPUT}
  ${SITE_TEMPLATE}
`;

export const M_DELETE_SITE_TEMPLATE = gql`
  mutation DeleteSiteTemplate($id: ID!) {
    deleteOneSiteTemplate(where: { _id: $id }) {
      ...siteTemplateToTree
    }
  }
  ${SITE_TEMPLATE_TO_TREE}
`;

export const M_UPDATE_SITE_TEMPLATE = gql`
  mutation UpdateSiteTemplate($data: SiteTemplateUpdateInput!, $where: SiteTemplateWhereUniqueInput!) {
    updateSiteTemplate(data: $data, where: $where) {
      ...siteTemplate
    }
  }
  ${INPUT}
  ${SITE_TEMPLATE}
`;

export const Q_GET_SITE_TEMPLATE_FOLDERS = gql`
  query GetSiteTemplateFolders($where: SiteTemplateFolderWhereInput) {
    siteTemplateFolders(where: $where, orderBy: name_ASC) {
      ...siteTemplateFolder
    }
  }
  ${SITE_TEMPLATE_FOLDER}
`;

export const M_CREATE_SITE_TEMPLATE_FOLDER = gql`
  mutation CreateSiteTemplateFolder($name: String!, $parent: ID) {
    createSiteTemplateFolder(data: { name: $name, parent: $parent }) {
      ...siteTemplateFolder
    }
  }
  ${SITE_TEMPLATE_FOLDER}
`;

export const M_DELETE_SITE_TEMPLATE_FOLDER = gql`
  mutation DeleteSiteTemplateFolder($where: SiteTemplateFolderWhereUniqueInput!) {
    deleteOneSiteTemplateFolder(where: $where) {
      ...siteTemplateFolder
    }
  }
  ${SITE_TEMPLATE_FOLDER}
`;

export const M_UPDATE_SITE_TEMPLATE_FOLDER = gql`
  mutation UpdateSiteTemplateFolder($name: String, $parent: ID, $_id: ID!) {
    updateSiteTemplateFolder(data: { name: $name, parent: $parent }, where: { _id: $_id }) {
      ...siteTemplateFolder
    }
  }
  ${SITE_TEMPLATE_FOLDER}
`;

export const Q_SEARCH = gql`
  query SearchSiteTemplates($searchString: String) {
    siteTemplateFolders(where: { name_contains: $searchString }) {
      ...siteTemplateFolder
    }
    siteTemplates(where: { folder: { name_contains: $searchString } }) {
      ...siteTemplateToTree
    }
  }
  ${SITE_TEMPLATE_TO_TREE}
  ${SITE_TEMPLATE_FOLDER}
`;

export const Q_GET_INPUT_APPROVALS = gql`
  query GetInputApprovals(
    $siteWhere: SiteWhereInput!
    $elementWhere: ElementWhereInput!
    $limit: Int!
    $skip: Int!
    $orderBy: [InputApprovalOrderBy]
  ) {
    inputApprovals(siteWhere: $siteWhere, elementWhere: $elementWhere, limit: $limit, skip: $skip, orderBy: $orderBy) {
      ...inputApproval
    }
    inputApprovalsCount(siteWhere: $siteWhere, elementWhere: $elementWhere)
  }
  ${INPUT_APPROVAL}
  ${INPUT}
`;

export const Q_GET_SITES_BY_FATHER_ID = gql`
  query GetSitesByFatherId($parentId: ID, $limit: Int, $skip: Int) {
    sitesV2(limit: $limit, skip: $skip, where: { parent_eq: $parentId }, orderBy: name_ASC) {
      ...siteToTree
    }
  }
  ${SITE_TO_TREE}
`;

export const Q_GET_ELEMENT_BY_FATHER_ID = gql`
  query GetElementByFatherId($parentId: ID, $limit: Int, $skip: Int) {
    elementsV2(limit: $limit, skip: $skip, where: { parent_eq: $parentId }, orderBy: name_ASC) {
      ...elementToTree
    }
  }
  ${ELEMENT_TO_TREE}
`;

export const Q_GET_ELEMENT_ROOT_BY_SITE = gql`
  query GetElementRootBySite($id: ID!, $limit: Int, $skip: Int) {
    elementsV2(limit: $limit, skip: $skip, orderBy: name_ASC, where: { site_eq: $id, AND: { parent_exists: false } }) {
      name
      __typename
      _id
      siteId
      parentId
      parentsTreeIds
      profileId
      profile: profileId
      tags {
        __typename
        name
        value
        key
        unitId
        id: _id
        _id
      }
      hasChildren
      totalItems: hasChildren
      markerId
      icon
      templateIcon
    }
  }
`;

export const M_MOVE_MANY_SITES = gql`
  mutation MoveManySites($_ids: [ID!]!, $parentId: ID) {
    moveManySites(_ids: $_ids, parentId: $parentId) {
      ...site
    }
  }
  ${INPUT}
  ${SITE}
`;

export const M_UPDATE_MANY_SITE_TEMPLATES = gql`
  mutation UpdateManySiteTemplates($where: SiteTemplateWhereInput!, $update: SiteTemplateUpdateInput!) {
    updateManySiteTemplates(where: $where, update: $update) {
      _id
      title
    }
  }
`;
