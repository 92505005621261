import gql from 'graphql-tag';
import { INPUT } from 'src/utils/fragments/input';
import { DEVICE, DEV_MQTT_CONFIG } from 'src/utils/fragments/device';
import { SITE } from 'src/utils/fragments/site';

export const Q_GET_DEVICES = gql`
  query GetDevices($skip: Int, $limit: Int, $filter: DeviceWhereInput!) {
    devices(skip: $skip, limit: $limit, where: $filter, orderBy: createdAt_ASC) {
      ...device
    }
  }
  ${DEVICE}
`;

export const Q_GET_MQTT_CONFIG = gql`
  query GetDevicesMQTTConfig($skip: Int, $limit: Int, $filter: DeviceWhereInput!) {
    devices(skip: $skip, limit: $limit, where: $filter, orderBy: createdAt_ASC) {
      ...mqttConfig
    }
  }
  ${DEV_MQTT_CONFIG}
`;

export const Q_GET_SITES = gql`
  query SitesDevice($skip: Int, $limit: Int, $filter: SiteWhereInput!) {
    sites(skip: $skip, limit: $limit, where: $filter, orderBy: createdAt_ASC) {
      ...site
    }
  }
  ${INPUT}
  ${SITE}
`;

export const M_CREATE_DEVICES = gql`
  mutation CreateDevices($deviceType: DeviceDeviceType!, $siteId: ID, $name: String, $topic: String) {
    createDevice(data: { site: $siteId, name: $name, deviceType: $deviceType, topic: $topic }) {
      ...device
    }
  }
  ${DEVICE}
`;

export const M_UPDATE_DEVICES = gql`
  mutation UpdateDevices($deviceType: DeviceDeviceType, $siteId: ID, $name: String, $id: ID!, $topic: String) {
    updateDevice(data: { deviceType: $deviceType, site: $siteId, name: $name, topic: $topic }, where: { _id: $id }) {
      ...device
    }
  }
  ${DEVICE}
`;

export const M_DELETE_DEVICE = gql`
  mutation DeleteDevice($id: ID!) {
    deleteOneDevice(where: { _id: $id }) {
      ...device
    }
  }
  ${DEVICE}
`;

export const Q_DEVICE_SENSORS = gql`
  query BrowseNode($topic: String!) {
    getTimeSeriesDeviceSensors(topic: $topic)
  }
`;

export const M_GENERATE_DEVICE_MQTT_AUTHENTICATION = gql`
  mutation GenerateDeviceMQTTAuthentication($deviceId: ID!) {
    generateDeviceMQTTAuthentication(device: { _id: $deviceId }) {
      id
      username
      password
      clientId
      topic
      name
    }
  }
`;
