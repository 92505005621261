import gql from 'graphql-tag';
import { LABEL_VALUE } from './label';

export const SKILL_SUBJECT = gql`
  fragment skillSubject on SkillSubject {
    _id
    name
    skillGroupCount
    skillCount
    userCount
    subjectLevelsData {
      NOT_APPLICABLE {
        name
        description
      }
      NO_COMPETENCE {
        name
        description
      }
      LOW_COMPETENCE {
        name
        description
      }
      MEDIUM_COMPETENCE {
        name
        description
      }
      HIGH_COMPETENCE {
        name
        description
      }
      EXPERT {
        name
        description
      }
    }
    chosenSubjectLevels
  }
`;

export const SKILL_GROUP = gql`
  fragment skillGroup on SkillGroup {
    _id
    name
    skillCount
    subject {
      _id
    }
  }
`;

export const SKILL_GROUP_COMPLETE = gql`
  fragment skillGroupComplete on SkillGroup {
    _id
    name
    skillCount
    subject {
      _id
      userCount
    }
    skills {
      _id
      name
      description
      renewal {
        condition
        amount
        showIn
      }
      renewalWarning {
        condition
        amount
        showIn
      }
      createdBy {
        _id
        name
        email
        photo
      }
      createdAt
    }
  }
`;

export const SKILL = gql`
  fragment skill on Skill {
    _id
    name
    description
    createdAt
    createdBy {
      name
      photo
      _id
    }
    renewal {
      condition
      amount
      showIn
    }
    renewalWarning {
      condition
      amount
      showIn
    }
    skillGroup {
      _id
      subject {
        _id
        subjectLevelsData {
          NOT_APPLICABLE {
            name
            description
          }
          NO_COMPETENCE {
            name
            description
          }
          LOW_COMPETENCE {
            name
            description
          }
          MEDIUM_COMPETENCE {
            name
            description
          }
          HIGH_COMPETENCE {
            name
            description
          }
          EXPERT {
            name
            description
          }
        }
        chosenSubjectLevels
      }
    }
    skillSpecificLevelsData {
      NOT_APPLICABLE {
        name
        description
      }
      NO_COMPETENCE {
        name
        description
      }
      LOW_COMPETENCE {
        name
        description
      }
      MEDIUM_COMPETENCE {
        name
        description
      }
      HIGH_COMPETENCE {
        name
        description
      }
      EXPERT {
        name
        description
      }
    }
    chosenSkillLevels
  }
`;

export const SKILL_LEVEL = gql`
  fragment skillLevel on SkillLevel {
    _id
    skill {
      _id
      renewal {
        condition
        amount
        showIn
      }
      renewalWarning {
        condition
        amount
        showIn
      }
    }
    account {
      _id
      name
      photo
      labelValues {
        ...labelValue
      }
    }
    level
    requiredLevel
    acquisitionDate
    files {
      name
      _id
      extension
      accessToken
      size
      comment
      createdBy {
        name
      }
      updatedAt
      download {
        url
        validUntil
        thumbnail {
          url
          validUntil
        }
      }
      hasThumbnail
    }
  }
  ${LABEL_VALUE}
`;
