import { Dispatch } from 'redux';
import { MarkerProps } from 'src/utils/funcs/marker/marker.types';

export const SET_SELECTED_IN_TREE = 'SET_SELECTED_IN_TREE';
export const SET_SELECTED_FOLDER_IN_TREE = 'SET_SELECTED_FOLDER_IN_TREE';
export const SET_PALETTE_SWITCH = 'SET_PALETTE_SWITCH';
export const SET_CREATE_MODE = 'SET_CREATE_MODE';
export const SET_SAVING = 'SET_SAVING';
export const SET_SEARCHING = 'SET_SEARCHING';
export const SET_EDIT_STATIC = 'SET_EDIT_STATIC';
export const SET_INPUTS_SELECTED_IN_TREE = 'SET_INPUTS_SELECTED_IN_TREE';
export const SET_INPUTS_TEMPLATE = 'SET_INPUTS_TEMPLATE';
export const SET_INPUT_TO_EDIT = 'SET_INPUT_TO_EDIT';
export const SET_UPDATE_MODE = 'SET_UPDATE_MODE';
export const SET_UPDATED_FIELD = 'SET_UPDATED_FIELD';
export const SET_EXPANDED_TEMPLATE = 'SET_EXPANDED_TEMPLATE';
export const SET_TASKS = 'SET_TASKS';
export const SET_TAB_ACTIVE = 'SET_TAB_ACTIVE';
export const SET_TASKS_EXPANDED = 'SET_TASKS_EXPANDED';
export const SET_CLEAN_TEMPLATE = 'SET_CLEAN_TEMPLATE';
export const SET_CHECKBOX_SELECTED = 'SET_CHECKBOX_SELECTED';
export const SET_IS_OPEN = 'SET_IS_OPEN';
export const SET_SELECTED_TO_SHOW = 'SET_SELECTED_TO_SHOW';
export const SET_SELECTED_PROFILE = 'SET_SELECTED_PROFILE';
export const SET_SELECTED_NODE = 'SET_SELECTED_NODE';
export const SET_OPEN_PROFILE_TREE_MODAL = 'SET_OPEN_PROFILE_TREE_MODAL';
export const SET_SCHEMA_FIELDS = 'SET_SCHEMA_FIELDS';
export const SET_SCHEMA_FIELDS_PROFILES = 'SET_SCHEMA_FIELDS_PROFILES';
export const SET_MARKERS = 'SET_MARKERS';
export const SET_COORDINATES = 'SET_COORDINATES';
export const SET_SHOW_ALL = 'SET_SHOW_ALL';
export const SET_LABEL_VALUES = 'SET_LABEL_VALUES';
export const SET_CLEAN_LABEL_VALUES = 'SET_CLEAN_LABEL_VALUES';
export const SET_LOADING = 'SET_LOADING';
export const SET_CHANGE_ASSIGN = 'SET_CHANGE_ASSIGN';
export const SET_ADD_FROM_TREE = 'SET_ADD_FROM_TREE';
export const SET_EXPANDED_DETAILS = 'SET_EXPANDED_DETAILS';
export const SET_EXPANDED_INPUTS_TO_TASK = 'SET_EXPANDED_INPUTS_TO_TASK';
export const SET_LOADING_DATA_TREE = 'SET_LOADING_DATA_TREE';
export const SET_ALLOWED_ELEMENTS = 'SET_ALLOWED_ELEMENTS';
export const SET_ALLOWED_WORKFLOWS = 'SET_ALLOWED_WORKFLOWS';
export const SET_SECTIONS = 'SET_SECTIONS';
export const SET_PROFILE_SECTIONS = 'SET_PROFILE_SECTIONS';

interface ReturnDispatch {
  type: string;
  payload: any;
}

export const setSelectedInTree = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_SELECTED_IN_TREE, payload });
export const setSelectedFolderInTree = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_SELECTED_FOLDER_IN_TREE, payload });
export const setPaletteSwitch = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_PALETTE_SWITCH, payload });
export const setCreateMode = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_CREATE_MODE, payload });
export const setSaving = (dispatch: Dispatch, payload: any): ReturnDispatch => dispatch({ type: SET_SAVING, payload });
export const setSearching = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_SEARCHING, payload });
export const setEditStatic = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_EDIT_STATIC, payload });
export const setInputsSelectedInTree = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_INPUTS_SELECTED_IN_TREE, payload });
export const setInputsTemplate = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_INPUTS_TEMPLATE, payload });
export const setInputToEdit = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_INPUT_TO_EDIT, payload });
export const setUpdateMode = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_UPDATE_MODE, payload });
export const setUpdatedField = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_UPDATED_FIELD, payload });
export const setExpandedTemplate = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_EXPANDED_TEMPLATE, payload });
export const setTasks = (dispatch: Dispatch, payload: any): ReturnDispatch => dispatch({ type: SET_TASKS, payload });
export const setTabActive = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_TAB_ACTIVE, payload });
export const setTasksExpanded = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_TASKS_EXPANDED, payload });
export const setLabelValues = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_LABEL_VALUES, payload });
export const setCleanLabelValues = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_CLEAN_LABEL_VALUES, payload });
//Hierarchy creation
export const setCleanTemplate = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_CLEAN_TEMPLATE, payload });
export const setCheckBoxSelected = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_CHECKBOX_SELECTED, payload });
export const setIsOpen = (dispatch: Dispatch, payload: any): ReturnDispatch => dispatch({ type: SET_IS_OPEN, payload });
export const setSelectedToShow = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_SELECTED_TO_SHOW, payload });
export const setSelectedProfile = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_SELECTED_PROFILE, payload });
export const setSelectedNode = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_SELECTED_NODE, payload });
export const setOpenProfileTreeModal = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_OPEN_PROFILE_TREE_MODAL, payload });
export const setMarkers = (dispatch: Dispatch, payload: MarkerProps[] | [MarkerProps] | []): ReturnDispatch =>
  dispatch({ type: SET_MARKERS, payload });
export const setCoordinates = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_COORDINATES, payload });
export const setShowAll = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_SHOW_ALL, payload });
export const setLoading = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_LOADING, payload });
export const setChangeAssign = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_CHANGE_ASSIGN, payload });
export const setAddFromTree = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_ADD_FROM_TREE, payload });
export const setExpandedDetails = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_EXPANDED_DETAILS, payload });
export const setExpandedInputsToTask = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_EXPANDED_INPUTS_TO_TASK, payload });
export const setLoadingDataToTree = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_LOADING_DATA_TREE, payload });
export const setSchemaFields = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_SCHEMA_FIELDS, payload });
export const setAllowedElements = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_ALLOWED_ELEMENTS, payload });
export const setAllowedWorkflows = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_ALLOWED_WORKFLOWS, payload });
export const setSections = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_SECTIONS, payload });
export const setProfileSections = (dispatch: Dispatch, payload: any): ReturnDispatch =>
  dispatch({ type: SET_PROFILE_SECTIONS, payload });
