import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    paper: {
      zIndex: theme.zIndex.drawer,
      background: theme.palette.background.default,
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    },
    listItem: {
      padding: '8px 16px',
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      justifyItems: 'center',
      alignItems: 'flex-start',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    glarHash: {
      color: theme.palette.primary.dark,
    },
    disabled: {
      color: theme.palette.grey.main,
    },
  });
