import { isArray } from 'lodash';
import { styles } from 'src/utils/components/action-field/styles';
import { useState } from 'react';
import DetailsPopover from 'src/utils/components/details-popover';
import { withStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';

interface ActionFieldProps {
  disabled: boolean;
  fireEvent?: (props?: any) => any;
  reset?: () => void;
  placeholder?: string;
  values?: any[];
  mouseOver?: boolean;
  id?: string;
  classes?: any;
  error?: boolean;
  cursorEnable?: boolean;
  context?: string;
  onlyText?: boolean;
}

const ActionField = (props: ActionFieldProps) => {
  const { disabled, fireEvent, placeholder, values, reset, mouseOver, id, error, cursorEnable, onlyText } = props;
  const [popoverDetails, setPopoverDetails] = useState<any>(null);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  let call = true;

  return (
    <div
      className={`${onlyText ? '' : values ? props.classes.font : props.classes.ph}
       ${
         onlyText
           ? ''
           : mouseOver || cursorEnable
             ? props.classes.inputCursorEnabled
             : disabled
               ? props.classes.inputCursorDisabled
               : ''
       }
       ${
         onlyText
           ? ''
           : disabled
             ? props.classes.disabledWithBadge
             : error
               ? props.classes.withBadgeError
               : props.classes.withBadge
       }`}
      id={id}
      onClick={() => {
        if (!disabled && call && !popoverDetails && fireEvent) fireEvent();
        call = true;
      }}
    >
      <div className={props.classes.values}>
        <span className={props.classes.spanValues} data-testid={'siteWO'}>
          {values && isArray(values) && values.length
            ? values.map((v, i) =>
                i === 0 ? (
                  <span
                    data-testid={'siteWO1'}
                    key={i}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (props.context) {
                        setSelectedValue(v);
                        setPopoverDetails(e.target);
                      }
                    }}
                    className={
                      props.context
                        ? selectedValue && selectedValue._id === v._id
                          ? props.classes.valActive
                          : props.classes.valWithHover
                        : props.classes.val
                    }
                  >
                    {v?.name || v}
                  </span>
                ) : (
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      if (props.context) {
                        setSelectedValue(v);
                        setPopoverDetails(e.target);
                      }
                    }}
                    className={
                      props.context
                        ? selectedValue && selectedValue._id === v._id
                          ? props.classes.valActive
                          : props.classes.valWithHover
                        : props.classes.val
                    }
                  >{`, ${v.name || v}`}</span>
                ),
              )
            : placeholder
              ? placeholder
              : '-'}
        </span>
      </div>
      {onlyText ? null : (
        <div className={props.classes.separator}>
          <div
            id={`${id}Reset`}
            hidden={disabled || !values}
            onClick={() => {
              call = false;
              if (!disabled && reset) reset();
            }}
          >
            <Close style={{ fontSize: '24px' }} />
          </div>
          <div id='line' className={disabled ? props.classes.leftShade : ''} />
          <div id='circle' className={disabled ? props.classes.rightShade : ''}>
            <div id='center-circle' className={disabled ? props.classes.centerShade : ''} />
          </div>
        </div>
      )}
      {popoverDetails ? (
        <DetailsPopover
          context={props.context}
          anchor={popoverDetails}
          value={selectedValue}
          clear={() => {
            setPopoverDetails(null);
            setSelectedValue(null);
          }}
        />
      ) : null}
    </div>
  );
};

export default withStyles(styles)(ActionField);
