import { useCallback, useMemo, useRef } from 'react';
import useForceUpdate from './useForceUpdate';

type OptimisticSetter<T> = (x: T) => void;

export default function useOptimistic<T>(
  state: T,
  updateFn: (currentState: T, optimisticValue: T) => T = (_, val) => val,
): [T, OptimisticSetter<T>] {
  const optimisticState = useRef<T>(null);
  const forceUpdate = useForceUpdate();

  useMemo(() => {
    optimisticState.current = state;

    return optimisticState.current;
  }, [state]);

  const addOptimistic = useCallback<OptimisticSetter<T>>((val) => {
    optimisticState.current = updateFn(optimisticState.current, val);

    forceUpdate();
  }, []);

  return [optimisticState.current, addOptimistic];
}
