import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styles } from './styles';
import { Chip, Divider, Grow, MenuItem, Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Account, useDeletePostMutation, useMarkSeenPostMutation } from 'src/gql/graphql';
import moment from 'moment';
import { ContentCopy, DeleteOutline, Edit, MoreVert, Visibility } from '@mui/icons-material';
import AssingToShow from 'src/utils/components/assing-to-show';
import Markdown from 'markdown-to-jsx';
import CommentSection from './comment-section';
import { AccountAvatar } from 'src/utils/components/account-avatar';
import ShowFile from 'src/utils/components/show-file';
import AccountPopoverDetails from './account-popover-details';
import { connect, ConnectedProps } from 'react-redux';
import { deleteFeedPost, PostType, softDeletePostState } from '../../feed.redux';
import { stringifyUrl } from 'query-string';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { hasPermission } from 'src/utils/funcs';
import queryString from 'query-string';
import { highlightText } from './utils/feed.post.highlightText.util';
import HighlightText from 'src/utils/components/highlight-text';
import { showSnackbar } from 'src/base/root/root.redux';
import useAtomicCallback from 'src/utils/hooks/useAtomicCallback';
import { PostDoesNotExistModal } from './utils';

type FeedPostProps = {
  post: PostType;
};

const useStyles = makeStyles(styles);

const connector = connect(
  (state, props: FeedPostProps) => ({
    loggedUser: state.loginReducer.loggedUser,
    isManager: hasPermission(state.loginReducer.loggedUser.myClearances, 'Feed', 'Manager'),
    singledCommentId:
      state.feedReducer.filters.id === props.post._id ? state.feedReducer.filters?.commentId : undefined,
    shouldExpandComments: !!state.feedReducer.filters.id,
  }),
  {
    deleteFeedPost,
    showSnackbar,
    softDeletePostState,
  },
);

function FeedPost(props: FeedPostProps & ConnectedProps<typeof connector>) {
  const { post, loggedUser, isManager, shouldExpandComments, singledCommentId } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = queryString.parse(location.search) as Record<string, string>;

  const [openPopover, setOpenPopover] = useState<HTMLElement>(null);
  const [openSeenPopover, setOpenSeenPopover] = useState<HTMLElement>(null);
  const [showComments, setShowComments] = useState(false);

  const highlightedDescription = useMemo(() => highlightText(post.description, search), [post.description, search]);

  useEffect(() => {
    if (shouldExpandComments) {
      setShowComments(true);
    }
  }, [singledCommentId]);

  const postRef = useRef<HTMLDivElement>(null);
  const hasSeen = useRef<boolean>(false);

  hasSeen.current = post.hasSeen;

  const isEdited = useMemo(() => Boolean(post.editedAt), [post.createdAt, post.updatedAt]);

  const [softDeletePost] = useDeletePostMutation({});

  const softDeletePostHandler = useAtomicCallback(
    () =>
      softDeletePost({
        variables: {
          id: post._id,
        },
        onCompleted(data) {
          if (data.deleteOnePost) {
            props.softDeletePostState(post._id);
          }
        },
        onError(error) {
          if (error.message.startsWith('Not found')) {
            PostDoesNotExistModal().then(() => setOpenPopover(null));
          }
        },
      }),
    [post._id],
  );

  const [markSeenPost] = useMarkSeenPostMutation({ variables: { id: post._id } });

  const editPost = useCallback(
    () => navigate(stringifyUrl({ url: '/feed/create-post', query: { postId: post._id } })),
    [post._id],
  );

  const isLoggedUserPost = useMemo(() => loggedUser._id === post.createdBy._id, [post.createdBy._id, loggedUser._id]);

  const canDelete = isLoggedUserPost || isManager;

  const isNewPost = useMemo(() => !isLoggedUserPost && !post.hasSeen, []);

  useEffect(() => {
    const onScroll = () => {
      if (!postRef.current) return;

      const offset = 100;

      const { top, bottom } = postRef.current.getBoundingClientRect();

      const isInViewport = bottom - offset > 0 && top + offset < window.innerHeight;

      if (isInViewport) {
        if (!hasSeen.current) {
          markSeenPost();
        }
      }
    };
    postRef.current.parentElement.addEventListener('scroll', onScroll);

    onScroll();

    return () => {
      postRef.current?.parentElement.removeEventListener('scroll', onScroll);
    };
  }, []);

  function copyPost(): void {
    navigator.clipboard?.writeText(
      window.location.origin +
        '/#' +
        stringifyUrl({
          url: '/feed',
          query: {
            id: post._id,
          },
        }),
    );
    props.showSnackbar('success', t('postLinkCopied'));
  }

  return (
    <Grow in={true} timeout={1000}>
      <div
        id={`full-post-${post.description.trim() ?? 'noDescription'}`}
        data-testid={`full-post-${post.description.trim() ?? 'noDescription'}`}
        ref={postRef}
        className={classes.postContainer}
      >
        <div className={classes.postHeader}>
          <AccountAvatar account={post.createdBy} />
          <div className={classes.accountInfo}>
            <AccountPopoverDetails
              className={classes.mediumFont}
              account={post.createdBy as Account}
              highlightSearch={search}
            />
            <div className={classes.flexDateLabels}>
              <span>{moment(post.createdAt).format(`Do MMMM YYYY [${t('at')}] h:mm`)}</span>{' '}
              {isEdited && <span className={`${classes.disabled} ${classes.spacing}`}>{t('edited')}</span>}
              {post.labelValues.length ? <Divider orientation='vertical' flexItem style={{ marginLeft: 8 }} /> : null}
              {post.labelValues.map((lbl) => (
                <AssingToShow key={lbl._id} accounts={[]} labelValues={[lbl]} className={classes.noMargin} hideTitle />
              ))}
            </div>
          </div>
          <div className={classes.marginLeft} />
          {isNewPost && <Chip classes={{ root: classes.newChipRoot, label: classes.newChipLabel }} label={t('new')} />}
          {isLoggedUserPost && !!post.seenBy?.length && (
            <>
              <Visibility
                data-testid={'open-seen-popover'}
                fontSize={'small'}
                className={classes.clickable}
                onClick={(ev) => setOpenSeenPopover(ev.target as any)}
              />
              {post.seenBy.length}
            </>
          )}
          <MoreVert
            data-testid={'open-post-popover'}
            onClick={(ev) => setOpenPopover(ev.target as HTMLElement)}
            className={classes.clickable}
          />
        </div>
        <Divider />
        {post.subject ? (
          <span className={classNames(classes.bigFont, classes.mediumBold, classes.breakWord)}>
            <HighlightText text={post.subject} highlight={search} />
          </span>
        ) : null}
        <Markdown
          options={{
            overrides: {
              p: { props: { className: classes.breakWord } },
              img: { props: { className: classes.inPostImage } },
            },
          }}
        >
          {highlightedDescription}
        </Markdown>
        {post.filesIds.length ? <ShowFile noImageInfo noName noBold noDelete value={post.filesIds} /> : null}
        <div className={classes.postFooter}>
          <span
            data-testid={`show-comments-${post.subject}`}
            className={`${classes.blueColor} ${classes.clickable}`}
            onClick={() => setShowComments((show) => !show)}
          >
            {showComments ? t('hideComments') : t(post.totalComments > 0 ? 'showComments' : 'addFirstComment')}
          </span>
          <span className={classes.disabled}>{`${post.totalComments} ${t('comment') + 's'}`}</span>
        </div>
        {showComments ? <CommentSection post={post} singledCommentId={singledCommentId} /> : null}
        {openPopover ? (
          <Popover
            open={true}
            anchorEl={openPopover}
            onClick={() => setOpenPopover(null)}
            onClose={() => setOpenPopover(null)}
          >
            <MenuItem data-testid={'copy-post-link'} onClick={copyPost}>
              <ContentCopy fontSize='small' />
              <span>{t('copyLink')}</span>
            </MenuItem>
            {isLoggedUserPost && (
              <>
                <Divider />
                <MenuItem data-testid={'edit-post-popover'} onClick={editPost}>
                  <Edit fontSize='small' />
                  <span>{t('editPost')}</span>
                </MenuItem>
              </>
            )}
            {canDelete && (
              <MenuItem data-testid={'delete-post-popover'} onClick={softDeletePostHandler} style={{ color: 'red' }}>
                <DeleteOutline fontSize={'medium'} />
                <span>{t('deletePost')}</span>
              </MenuItem>
            )}
          </Popover>
        ) : null}
        {openSeenPopover ? (
          <Popover
            classes={{ paper: classes.seenPopover }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            open={true}
            anchorEl={openSeenPopover}
            onClose={() => setOpenSeenPopover(null)}
          >
            {post.seenBy.map((seen) => (
              <AssingToShow key={seen._id} accounts={[seen]} labelValues={[]} hideTitle />
            ))}
          </Popover>
        ) : null}
      </div>
    </Grow>
  );
}

export default connector(FeedPost);
