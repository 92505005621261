import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    'input:placeholder-shown': {
      fontStyle: 'italic !important',
    },
    inline: {
      display: 'inline-flex',
      flexDirection: 'row',
    },
    flex: {
      flexDirection: 'column',
    },
    inputContainer: {
      width: '100%',
      marginBottom: '20px',
    },
    unflex: {
      flex: 'unset !important',
    },
    required: {
      color: theme.palette.common.white,
      width: '15px',
      paddingBottom: '10px',
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      overflow: 'hidden',
      transition: '0.3s',
      paddingTop: '5px',
      flex: '1',
      color: `${theme.palette.text.primary} !important`,
      marginBottom: '3px',
      fontWeight: 500,
    },
    labelName: {
      color: theme.palette.primary.main,
      float: 'left',
      fontSize: theme.typography.fontSize,
    },
    labelNameWhite: {
      color: theme.palette.text.primary,
      float: 'left',
      fontSize: theme.typography.fontSize,
    },
    labelNameTemplate: {
      color: theme.palette.text.secondary,
      float: 'right',
      fontSize: theme.typography.fontSize,
    },
    italicUndefined: {
      fontStyle: 'italic',
    },
    labelTest: {
      width: '100%',
      margin: '0',
    },
    warningIcon: {
      fill: theme.palette.error.main,
      marginLeft: '10px',
      fontSize: '13px',
    },
  });
