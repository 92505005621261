import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    checkbox: {
      paddingLeft: '7px',
      '& > span': {
        cursor: 'pointer',
        '& > svg': {
          transition: 'fill 0.3s',
          fill: theme.palette.primary.main,
          '&:hover': {
            fill: `${theme.palette.primary.main}, !important`,
          },
        },
        '&:hover': {
          fill: theme.palette.common.black,
          opacity: '0.2',
        },
      },
    },
    disabled: {
      '& > span': {
        cursor: 'not-allowed !important',
      },
    },
    handles: {
      left: '5px',
      "& > [class='react-switch-bg']": {
        height: '18px !important',
        width: '44px !important',
        left: '4px',
        top: '3px',
        '& > div': {
          top: '-3px !important',
          right: '-3px !important',
        },
      },
    },
    disabledHandle: {
      cursor: 'not-allowed !important',
      "& > [class='react-switch-bg']": {
        cursor: 'unset !important',
      },
      "> [class='react-switch-handle']": {
        cursor: 'unset !important',
      },
    },
    handle: {
      position: 'absolute',
      fontWeight: 500,
      fontSize: '10px',
      color: theme.palette.primary.main,
      marginTop: '5px',
    },
    inline: {
      marginTop: '15px',
      marginLeft: '10px',
    },
    on: {
      left: '6px',
      top: '3px',
      overflow: 'hidden',
      display: 'contents',
    },
    off: {
      left: '6px',
      top: '3px',
      color: `${theme.palette.grey.main} !important`,
      display: 'contents',
    },
    inputCursorDisabled: {
      cursor: 'not-allowed',
    },
    inputCursorEnabled: {
      cursor: 'pointer',
    },
  });
