import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    'input:placeholder-shown': {
      fontStyle: 'italic !important',
    },
    inline: {
      display: 'inline-flex',
      flexDirection: 'row',
    },
    flex: {
      flexDirection: 'column',
    },
    inputContainer: {
      width: '100%',
      marginBottom: '16px',
    },
    unflex: {
      flex: 'unset !important',
    },
    required: {
      color: theme.palette.common.white,
      width: '15px',
      paddingBottom: '10px',
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      overflow: 'hidden',
      transition: '0.3s',
      flex: '1',
      marginBottom: '7px',
      fontWeight: 500,
    },
    labelName: {
      color: theme.palette.text.primary,
      float: 'left',
      fontSize: '14px',
      lineHeight: '22px',
      fontFamily: 'Open Sans',
      fontWeight: 400,
    },
    labelNamewhite: {
      color: theme.palette.text.primary,
      float: 'left',
      fontSize: theme.typography.fontSize,
    },
    labelNameTemplate: {
      color: theme.palette.text.secondary,
      float: 'right',
      fontSize: theme.typography.fontSize,
    },
    labelNameDisabled: {
      color: theme.palette.text.secondary,
    },
    italicUndefined: {
      fontStyle: 'italic',
    },
    labelTest: {
      width: '100%',
      margin: '0',
    },
    numberUnit: {
      marginLeft: '8px',
    },
    textInput: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: '22px',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.primary,
      marginBottom: '10px',
    },
    deleteIcon: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 8,
      marginRight: 0,
      color: `${theme.palette.error.main} !important`,
      fontSize: '18px',
      cursor: 'pointer',
    },
    dialog: {
      width: 280,
      height: 150,
    },
    dialogHeader: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '19px',
      lineHeight: '26px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.15px',
      justifyContent: 'center',
    },
    closeButton: {
      color: theme.palette.grey[500],
      '&:hover': {
        backgroundColor: 'unset',
      },
      padding: '0 0 0 16px',
      position: 'absolute',
      top: '10px',
      right: '5px',
    },
    dialogContent: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
    },
    titleHeader: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '13px',
    },
    text: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '11px',
    },
    notesIcons: {
      fontSize: '17px',
      marginRight: '5px',
      color: theme.palette.text.secondary,
    },
    historyIcon: {
      fontSize: '20px',
      marginRight: '5px',
    },
    notesIconsActive: {
      fontSize: '17px',
      cursor: 'pointer',
      color: theme.palette.text.primary,
      marginRight: '5px',
    },
    descriptionText: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.secondary,
    },
    actionOrangeIcon: {
      color: theme.palette.other.peach,
      fontSize: '17px',
    },
    actionIconText: {
      color: theme.palette.other.peach,
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '18px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
    },
    optionText: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '11px',
      lineHeight: '18px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'right',
      color: theme.palette.text.primary,
      cursor: 'pointer',
    },
    optionTextDisabled: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '11px',
      lineHeight: '18px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'right',
      color: theme.palette.text.secondary,
    },
    iconSize: {
      fontSize: '20px',
      marginRight: '5px',
    },
    chip: {
      color: theme.palette.background.default,
      marginRight: '10px',
      '& .MuiChip-label': {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
      },
    },
    statusPending: {
      color: '#272848 !important',
      backgroundColor: theme.palette.alert.main,
    },
    statusCantDo: {
      backgroundColor: theme.palette.error?.main,
    },
    statusDoing: {
      backgroundColor: theme.palette.primary.main,
    },
    statusSolved: {
      backgroundColor: theme.palette.success?.main,
    },
    spanName: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '21px',
      letterSpacing: '0.1px',
    },
    moreActionsDiv: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: theme.palette.primary.main,
      textDecorationLine: 'underline',
      cursor: 'pointer',
    },
    noteDiv: {
      color: theme.palette.text.primary,
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '19px',
      letterSpacing: '0.1px',
      padding: '8px 16px 8px 16px',
      borderRadius: '4px',
      cursor: 'pointer',
      '&:hover': {
        background: theme.palette.background.background1,
      },
    },
    actionContainerDiv: {
      background: theme.palette.background.background1,
      marginTop: '16px',
      borderRadius: '4px',
      padding: '12px 8px',
      display: 'flex',
    },
    iconAnnouncement: {
      color: theme.palette.other.peach,
    },
    requiredSignal: {
      color: theme.palette.error.main,
    },
    divider: {
      padding: '4px 0px 4px 0px',
      color: theme.palette.grey.light,
    },
  });
