import { createStyles } from '@mui/styles';

export const styles = () =>
  createStyles({
    body: {
      display: 'flex',
      flex: '1 1',
      padding: '0px !important',
      maxHeight: '100%',
    },
    bodyElementView: {
      display: 'flex',
      flex: '1 1',
      padding: '0px !important',
    },
    bodyPadding: {
      display: 'block',
      /* height: '-webkit-fill-available', */
      /* position: 'fixed', */
      width: '100%',
      transform: 'translate3d(0,0,0)',
      maxHeight: '100%',
    },
    bodyLogin: {
      alignItems: 'center',
      alignSelf: 'center',
      display: 'block',
      width: '100%',
      transform: 'translate3d(0, 0, 0) perspective(1px)',
    },
    cardContentLogin: {
      display: 'flex',
      flex: '1 1',
      padding: '0px !important',
      alignSelf: 'center',
    },
  });
