import { Action, State } from 'src/interfaces/reducers';
import { GET_LOGGED_USER } from 'src/base/login/login.redux';
import { getErrorObject } from 'src/utils/funcs';
import { APOLLO_CLIENT } from 'config/apollo.config';
import { Dispatch } from 'redux';
import i18n from 'src/utils/translations/i18n';
import { SetPreferencesDocument, UpdatePhotoDocument, DeletePhotoDocument } from 'src/gql/graphql';
import { GlarState } from 'src/reducer-manager';

export const GET_PHOTO = 'GET_PHOTO';
export const UPDATE_PHOTO = 'UPDATE_PHOTO';
export const CLEAR_PHOTO = 'CLEAR_PHOTO';
export const ERROR = 'ERROR';
export const UPDATE_LOGGED_ACCOUNT = 'UPDATE_LOGGED_ACCOUNT';

const initialState: State = {
  accountPhoto: null,
};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case GET_PHOTO:
    case UPDATE_PHOTO:
      return { ...state, accountPhoto: action.payload.photo };
    case CLEAR_PHOTO:
      return initialState;
    case ERROR:
    default:
      return state;
  }
};

const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr = dataurl.split(',');
  //@ts-ignore
  const mime = /:(.*?);/.exec(arr[0])[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[parseInt(`${n}`)] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updatePhoto =
  (photo: string, accountId?: string) =>
  async (dispatch: Dispatch, getState: () => GlarState): Promise<void> => {
    try {
      const file = dataURLtoFile(photo, 'photo');
      const response = await APOLLO_CLIENT.mutate({
        variables: { file, accountId },
        fetchPolicy: 'no-cache',
        mutation: UpdatePhotoDocument,
      });

      if (!accountId || accountId === getState().loginReducer.loggedUser._id)
        dispatch({
          type: GET_LOGGED_USER,
          payload: { account: response.data.setPhoto },
        });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      dispatch({
        type: ERROR,
        payload: error,
      });

      return error;
    }
  };

// Delete profile photo
export const deletePhoto =
  (accountId?: string) =>
  async (dispatch: Dispatch, getState: () => GlarState): Promise<void> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        fetchPolicy: 'no-cache',
        variables: { accountId },
        mutation: DeletePhotoDocument,
      });

      if (!accountId || accountId === getState().loginReducer.loggedUser._id) {
        dispatch({
          type: GET_LOGGED_USER,
          payload: { account: response.data.deletePhoto },
        });
      }
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      dispatch({
        type: ERROR,
        payload: error,
      });

      return error;
    }
  };

export const clearPhoto = () => async (dispatch: Dispatch) => dispatch({ type: CLEAR_PHOTO });

export const setUserPreferences =
  (data) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          data,
        },
        fetchPolicy: 'no-cache',
        mutation: SetPreferencesDocument,
      });

      dispatch({
        type: UPDATE_LOGGED_ACCOUNT,
        payload: { account: response.data.setPreferences },
      });

      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('translation:accountToastUpdateSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      dispatch({
        type: ERROR,
        payload: error,
      });

      return error;
    }
  };
