import { default as ActionIcon, default as ActionImage } from '@assets/images/actionIcon.png';
import GlarvisionImage from '@assets/images/GlarVision.png';
import RoundCheckGreen from '@assets/images/roundCheckGreen.png';
import RoundCheckOrange from '@assets/images/roundCheckOrange.png';
import { Document, Font, Image as ImageComponent, Page, pdf, StyleSheet, Text, View } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import humanizeDuration from 'humanize-duration';
import jsPDF from 'jspdf';
import moment from 'moment';
import { TenantBasicInfo } from 'src/gql/graphql';
import {
  issueDate,
  IssueInstance,
  issueInstruction,
  issueNumber,
  issueSignature,
  issueSiteElement,
  issueString,
  issueTable,
} from 'src/interfaces/issue';
import { ActionsDictionary } from 'src/modules/issue-history/components/pdf-report';
import { getActionColor, getImageForReport } from 'src/utils/funcs/reports';
import { openSans } from 'src/utils/other/pdf-fonts/openSans';
import { openSansBold } from 'src/utils/other/pdf-fonts/openSansBold';
import { openSansItalic } from 'src/utils/other/pdf-fonts/openSansItalic';
import { ImagesOnNotes } from '../basic-report';

interface signatureSourceLinks {
  src: string;
  extension: string;
  id: string;
}

const incrementY = (n: number): number => {
  return (n = n + 10);
};

const countTotalTasks = (group) => {
  let total = 0;
  for (const task of group.tasks) {
    total += task.executionInputs.filter((i) => i.type !== 'instruction').length;
  }
  return total;
};

const countCompletedTasks = (group) => {
  let total = 0;
  for (const task of group.tasks) {
    total += task.executionInputs.filter(
      (inp) =>
        inp.type !== 'instruction' &&
        ((inp.type !== 'table' && !!inp[inp.type].values?.length) ||
          (inp.type === 'table' &&
            !!(inp[inp.type] as issueTable).values.filter(
              (c) =>
                (c.rows[c.type] as string[]).length &&
                (c.rows[c.type] as string[]).filter((r) => r?.length && !!r[0]).length ===
                  (c.rows[c.type] as string[]).length,
            ).length)),
    ).length;
  }
  return total;
};

Font.register({
  family: 'OpenSans',
  fonts: [
    {
      src: require('src/utils/components/detailed-report/font/OpenSans-Regular.ttf'),
      fontWeight: 400,
    },
    {
      src: require('src/utils/components/detailed-report/font/OpenSans-Bold.ttf'),
      fontWeight: 'bold',
    },
  ],
});

const stylesPdf = StyleSheet.create({
  page: {
    paddingTop: '16px',
    paddingBottom: '40px',
    paddingHorizontal: '16px',
    fontSize: '12',
    fontFamily: 'OpenSans',
  },
  headerImg: {
    width: '91px',
    height: '20px',
    textAlign: 'center',
    margin: '0 auto',
    marginBottom: '7px',
  },
  issueMainContainer: {
    backgroundColor: '#F4F5F9',
    borderRadius: '10px',
    padding: '5px 16px',
  },
  issueTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '8px',
  },
  detailsContainer: {
    width: '25%',
    flexDirection: 'column',
  },
  issueTitle: {
    color: '#0078FE',
    fontSize: '14px',
  },
  icon: {
    width: '10px',
    height: '10px',
  },
  completedLabel: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  completedText: {
    marginLeft: '3px',
    fontSize: '9px',
  },
  subTitle: {
    fontSize: '10px',
    color: '#88899A',
  },
  text: {
    fontSize: '10px',
    color: '#272848',
  },
  row: {
    borderBottomWidth: 1,
    borderBottomColor: '#CACACA',
    borderBottomStyle: 'solid',
    marginTop: '6px',
  },
  mainTitle: {
    textAlign: 'center',
    color: '#0078FE',
    fontSize: '14px',
    marginTop: '8px',
  },
  groupContainer: {
    width: '100%',
    backgroundColor: '#F4F5F9',
    borderRadius: '10px',
    padding: '5px 16px',
    marginTop: '8px',
  },
  groupTitle: {
    textAlign: 'center',
    color: '#0078FE',
    fontSize: '10px',
  },
  groupCompletion: {
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    top: '5px',
    right: '16px',
    fontSize: '9px',
    color: '#FE8947',
    fontWeight: 'bold',
  },
  taskHeaderContainer: {
    width: '100%',
    backgroundColor: '#F4F5F9',
    borderRadius: '10px',
    padding: '5px 16px',
    marginTop: '8px',
  },
  taskTitle: {
    color: '#0078FE',
    fontSize: '10px',
  },
  taskHeaderLabelContainer: {
    flexDirection: 'row',
    padding: '8px 0',
    borderBottomWidth: 1,
    borderBottomColor: '#EFF0F8',
    borderBottomStyle: 'solid',
  },
  taskHeaderLabelTitle: {
    width: '50%',
    fontSize: '10px',
    color: '#88899A',
  },
  taskHeaderLabelText: {
    width: '50%',
    fontSize: '10px',
    color: '#272848',
  },
  inputArea: {
    marginTop: '16px',
    borderBottomWidth: 1,
    borderBottomColor: '#EFF0F8',
    borderBottomStyle: 'solid',
  },
  inputContainer: {
    marginHorizontal: '8px',
    padding: '16px 3px',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    display: 'flex',
  },
  inputName: {
    fontSize: '10px',
    color: '#88899A',
    flexGrow: 0,
    marginRight: 'auto',
    width: '50%',
  },
  inputText: {
    width: '50%',
    fontSize: '10px',
    color: '#272848',
  },
  fileNotesArea: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  fileNotesContainer: {
    width: '50%',
    maxWidth: 'calc(50%)',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#EFF0F8',
    borderBottomStyle: 'solid',
    paddingVertical: '6px',
  },
  fileNotesTitle: {
    width: '50%',
    fontSize: '10px',
    color: '#88899A',
  },
  fileNotesText: {
    width: '100%',
    fontSize: '10px',
    color: '#272848',
  },
  imageNoteArea: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  imageWrapper: {
    marginTop: '5px',
    marginRight: '6px',
    maxWidth: '60px',
  },
  imageText: { fontSize: '6px', color: '#272848' },
  signatureText: { fontSize: '8px', color: '#272848' },
  attachmentTitleContainer: {
    padding: '3px 16px',
    backgroundColor: '#F4F5F9',
    borderRadius: '10px',
  },
  attachmentTitle: {
    color: '#0078FE',
    fontSize: '14px',
  },
  tableHeader: {
    backgroundColor: '#F4F5F9',
    color: '#272848',
    fontSize: '10px',
    border: '1px solid #EFF0F8',
    padding: '2px',
  },
  tableCell: {
    backgroundColor: '#FFFFFF',
    color: '#272848',
    fontSize: '10px',
    border: '1px solid #EFF0F8',
    padding: '2px',
  },
  accountChip: {
    borderRadius: '20px',
    border: '1px solid #CACACA',
    flexDirection: 'row',
    padding: '1px 5px 1px 1px',
    alignItems: 'center',
  },
  accountChipIcon: {
    borderRadius: '20px',
    backgroundColor: '#CACACA',
    padding: '1px',
    color: '#FFFFFF',
    fontSize: '9px',
    minWidth: '15px',
    minHeight: '15px',
    alignItems: 'center',
  },
  accountChipText: {
    marginLeft: '3px',
    color: '#272848',
    fontSize: '9px',
    alignItems: 'center',
  },
  labelChip: {
    borderRadius: '20px',
    flexDirection: 'row',
    padding: '1px 5px 1px 1px',
    alignItems: 'center',
    marginHorizontal: '4px',
  },
  labelChipIcon: {
    borderRadius: '20px',
    padding: '1px 3px',
    color: '#FFFFFF',
    fontSize: '9px',
    minWidth: '15px',
    minHeight: '15px',
    alignItems: 'center',
  },
  labelChipText: {
    marginLeft: '3px',
    fontSize: '9px',
    alignItems: 'center',
  },
  inputActionsCounter: {
    fontWeight: 'semibold',
    color: '#FE8947',
    fontSize: '11.5px',
  },
  inputActionsContainer: {
    display: 'flex',
    gap: '2px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: '5px',
    marginRight: '5px',
    color: '#FE8947',
  },
  inputActionsIcon: {
    width: '10px',
    height: '10px',
  },
  actionsArea: {
    marginBottom: '8px',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionsAreaWithoutNote: {
    marginBottom: '8px',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    borderBottomWidth: 1,
    borderBottomColor: '#EFF0F8',
    borderBottomStyle: 'solid',
  },
  actionsContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingVertical: '6px',
    backgroundColor: '#F5E8E7',
  },
  actionsTitle: {
    width: '15%',
    paddingLeft: '12px',
    fontSize: '10px',
    color: '#88899A',
  },
  actionsDetailsArea: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  actionsText: {
    width: '80%',
    fontSize: '10px',
    color: '#272848',
    gap: '3px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '4px',
  },
  actionsID: {
    fontWeight: 'bold',
  },
  actionsStatusChip: {
    width: 'auto',
    minWidth: '20px',
    padding: '2px 4px',
    fontWeight: 'bold',
    borderRadius: '8px',
  },
});

const renderTable = (input) => {
  return (input[input.type] as issueTable).values.map((item) => (
    <View>
      <View style={stylesPdf.tableHeader}>
        <Text>{item.name}</Text>
      </View>
      {item.rows.string.map((row) => (
        <View style={stylesPdf.tableCell}>
          <Text>{row}</Text>
        </View>
      ))}
    </View>
  ));
};

const renderAccountLabel = (account) => {
  return (
    <View style={stylesPdf.accountChip}>
      <View style={stylesPdf.accountChipIcon}>
        <Text>{account.name[0]}</Text>
      </View>
      <View style={stylesPdf.accountChipText}>
        <Text>{account.name}</Text>
      </View>
    </View>
  );
};

const renderLabel = (label) => {
  return (
    <View style={{ ...stylesPdf.labelChip, border: `1px solid ${label.backgroundColor}` }}>
      <View style={{ ...stylesPdf.labelChipIcon, backgroundColor: label.backgroundColor }}>
        <Text>{label.label.name}</Text>
      </View>
      <View style={{ ...stylesPdf.labelChipText, color: label.backgroundColor }}>
        <Text>{label.value}</Text>
      </View>
    </View>
  );
};

const renderStatusChip = (status) => {
  return (
    <View
      style={{
        ...stylesPdf.actionsStatusChip,
        backgroundColor: getActionColor(status),
        color: status === 'PENDING' ? '#272848' : '#FFFFFF',
      }}
    >
      <Text>{status}</Text>
    </View>
  );
};

const addFooters = (doc: jsPDF, t: (s: string) => string) => {
  const pageCount = doc.internal.pages.length - 1;

  doc.setTextColor('#CACACA');
  doc.setFont('OpenSans', 'normal');
  doc.setFontSize(10);
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.text(`${t('reportGenerate')}`, 20, doc.internal.pageSize.height - 10);
    const text = String(i) + `/` + String(pageCount);
    doc.text(text, doc.internal.pageSize.width - 20 - doc.getTextDimensions(text).w, doc.internal.pageSize.height - 10);
  }
};

const getAttachemnts = (issue: IssueInstance) => {
  let attachments = [];
  for (const group of issue.taskGroups) {
    for (const task of group.tasks) {
      for (const executionInput of task.executionInputs) {
        try {
          if (executionInput.type === 'instruction' || executionInput.type === 'file') {
            attachments = [
              ...attachments,
              ...executionInput[executionInput.type].values.map((elem) => ({
                url: elem.download.thumbnail?.url || elem.download.url,
                name: elem.name,
              })),
            ];
          }
          if (executionInput?.note?.files) {
            attachments = [
              ...attachments,
              ...executionInput.note.files.map((elem) => {
                return { url: elem.download.thumbnail.url, name: elem.name };
              }),
            ];
          }
        } catch (error) {
          (error) => console.error(error);
        }
      }
    }
  }
  return attachments;
};

const addInputAndConditionals = (input, t, actionsFromInput?: ActionsDictionary[]) => {
  const mainInput = addInput(
    input,
    t,
    actionsFromInput.find((i) => input._id === i.inputId),
  );

  const conditionalInput = (condInput) => {
    const condType = condInput[condInput.type];
    if (condType && condType.onResponse?.length) {
      return condType.onResponse.map((response) => {
        if (condType.values.includes(response.ifValues[0])) {
          return response.inputs.map((inp) => {
            const nestedInput = addInput(
              inp,
              t,
              actionsFromInput.find((i) => inp._id === i.inputId),
            );
            return [nestedInput, ...conditionalInput(inp)];
          });
        }
        return [];
      });
    }
    return [];
  };

  const conditionalInputs = conditionalInput(input);

  return (
    <>
      {mainInput}
      {conditionalInputs}
    </>
  );
};

const addInput = (input, t, actionsFromInput?: ActionsDictionary) => {
  const today = moment();
  return (
    <View style={{ marginBottom: '16px' }}>
      <View style={stylesPdf.inputArea}>
        <View style={stylesPdf.inputContainer}>
          <Text style={stylesPdf.inputName}>{`${input.name}`}</Text>
          {actionsFromInput?.actions?.length ? (
            <View style={stylesPdf.inputActionsContainer}>
              <ImageComponent src={ActionImage} style={stylesPdf.inputActionsIcon}></ImageComponent>
              <Text style={stylesPdf.completedText}>{actionsFromInput.actions.length}</Text>
            </View>
          ) : null}
          {input.type === 'string' ? (
            <Text style={stylesPdf.inputText}>{`${(input[input.type] as issueString).values.join(', ')}`}</Text>
          ) : null}
          {input.type === 'number' ? (
            <Text style={stylesPdf.inputText}>{(input[input.type] as issueNumber).values.toString()}</Text>
          ) : null}
          {input.type === 'time' && input[input.type].values?.length ? (
            <Text style={stylesPdf.inputText}>{`${moment({
              year: today.get('year'),
              month: today.get('month'),
              day: today.get('day'),
              hours: parseInt(input[input.type].values[0].split(':')[0]),
              minutes: parseInt(input[input.type].values[0].split(':')[1]),
            }).format('HH:mm')}`}</Text>
          ) : null}
          {input.type === 'date' && input[input.type].values?.length ? (
            <Text style={stylesPdf.inputText}>{`${moment((input[input.type] as issueDate).values[0]).format(
              'YYYY/MM/DD',
            )}`}</Text>
          ) : null}
          {input.type === 'datetime' && input[input.type].values?.length ? (
            <Text style={stylesPdf.inputText}>{`${moment((input[input.type] as issueDate).values[0]).format(
              'YYYY/MM/DD HH:mm:ss',
            )}`}</Text>
          ) : null}
          {input.type === 'site' ? (
            <Text style={stylesPdf.inputText}>
              {(input[input.type] as issueSiteElement).values.map((elem) => elem.name).join(', ')}
            </Text>
          ) : null}
          {input.type === 'element' ? (
            <Text style={stylesPdf.inputText}>
              {(input[input.type] as issueSiteElement).values.map((elem) => elem.name).join(', ')}
            </Text>
          ) : null}
          {input.type === 'file' || input.type === 'instruction' ? (
            <View style={stylesPdf.imageNoteArea}>
              {(input[input.type] as issueInstruction).values.map((val) => (
                <View style={stylesPdf.imageWrapper}>
                  <ImageComponent
                    style={{ width: '60px' }}
                    src={val.download.thumbnail?.url || val.download.url}
                  ></ImageComponent>
                  <Text style={stylesPdf.imageText}>{val.name}</Text>
                </View>
              ))}
            </View>
          ) : null}
          {input.type === 'signature' ? (
            <View style={stylesPdf.imageNoteArea}>
              {(input[input.type] as issueSignature).values.map((val) => (
                <View style={stylesPdf.imageWrapper}>
                  <Text style={stylesPdf.signatureText}>{val.name}</Text>
                  <ImageComponent
                    style={{ width: '60px' }}
                    src={val.image.download.thumbnail?.url || val.image.download.url}
                  ></ImageComponent>
                </View>
              ))}
            </View>
          ) : null}
          {input.type === 'table' ? <View style={{ flexDirection: 'row' }}>{renderTable(input)}</View> : null}
        </View>
      </View>
      {actionsFromInput?.actions?.length ? (
        <View style={!input.note ? stylesPdf.actionsAreaWithoutNote : stylesPdf.actionsArea}>
          <View style={stylesPdf.actionsContainer}>
            <Text style={stylesPdf.actionsTitle}>{`${t('actions')}`}</Text>
            <View style={{ width: '70%' }}>
              <View style={stylesPdf.actionsDetailsArea}>
                {actionsFromInput.actions.map((action) => (
                  <View key={action.ID} style={stylesPdf.actionsText}>
                    {renderStatusChip(action.status)}
                    <Text style={stylesPdf.actionsID}>{`#${action.ID}`}</Text>
                    <Text>{action.name}</Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </View>
      ) : null}
      {input.note ? (
        <View style={stylesPdf.fileNotesArea}>
          <View style={stylesPdf.fileNotesContainer}>
            <Text style={stylesPdf.fileNotesTitle}>{`${t('files&notes')}`}</Text>
            <View style={{ width: '50%' }}>
              {input.note.text ? <Text style={stylesPdf.fileNotesText}>{input.note.text}</Text> : null}
              <View style={stylesPdf.imageNoteArea}>
                {input.note.files
                  ? input.note.files.map((file) =>
                      file?.download?.thumbnail?.url ? (
                        <View style={stylesPdf.imageWrapper}>
                          <ImageComponent style={{ width: '60px' }} src={file.download.thumbnail.url}></ImageComponent>
                          <Text style={stylesPdf.imageText}>{file.name}</Text>
                        </View>
                      ) : null,
                    )
                  : null}
              </View>
            </View>
          </View>
        </View>
      ) : null}
    </View>
  );
};

const createPdf = async (
  t,
  issue,
  totalActions,
  totalFinishedTasks,
  totalTasks,
  actionsFromInput?: ActionsDictionary[],
  tenantInfo?: TenantBasicInfo,
) => {
  let totalScore = 0;
  let currentScore = 0;
  if (issue.scoreInputs) {
    issue.taskGroups.map((taskGroup) =>
      taskGroup.tasks.map((task) => ((totalScore += task.score.totalScore), (currentScore += task.score.currentScore))),
    );
  }
  const attachments = getAttachemnts(issue);
  const today = moment().format('LLL');
  const logo = tenantInfo?.resources?.logo_light?.url ?? GlarvisionImage;
  const blob = await pdf(
    <Document>
      <Page style={stylesPdf.page}>
        <View style={stylesPdf.headerImg} fixed>
          <ImageComponent src={logo} />
        </View>
        <View style={stylesPdf.issueMainContainer}>
          <View style={stylesPdf.issueTitleContainer}>
            <View>
              <Text style={stylesPdf.issueTitle}>{issue.name}</Text>
            </View>
            <View style={stylesPdf.completedLabel}>
              {totalActions > 0 ? (
                <View style={{ ...stylesPdf.completedLabel, marginRight: '5px' }}>
                  <ImageComponent src={ActionImage} style={stylesPdf.icon}></ImageComponent>
                  <Text
                    style={{
                      ...stylesPdf.completedText,
                      color: '#FE8947',
                    }}
                  >{`${totalActions.toString()}`}</Text>
                </View>
              ) : null}
              <ImageComponent
                src={totalFinishedTasks === totalTasks ? RoundCheckGreen : RoundCheckOrange}
                style={stylesPdf.icon}
              ></ImageComponent>
              <Text
                style={{ ...stylesPdf.completedText, color: totalFinishedTasks === totalTasks ? '#00C48C' : '#FE8947' }}
              >{`${t('completed')} ${totalFinishedTasks}/${totalTasks}`}</Text>
            </View>
          </View>
          <View style={stylesPdf.rowContainer}>
            <View style={stylesPdf.detailsContainer}>
              <Text style={stylesPdf.subTitle}>{`${t('StartedAt')}:`}</Text>
              <Text style={stylesPdf.text}>{`${
                issue.stateMachineInstance?.log.length
                  ? moment(issue.stateMachineInstance.log[0].createdAt).format('YYYY/MM/DD HH:mm:ss')
                  : ' - '
              }`}</Text>
            </View>
            <View style={stylesPdf.detailsContainer}>
              <Text style={stylesPdf.subTitle}>{`${t('finishAt')}:`}</Text>
              <Text style={stylesPdf.text}>{`${moment(issue.closedAt).format('YYYY/MM/DD HH:mm:ss')}`}</Text>
            </View>
            <View style={stylesPdf.detailsContainer}>
              <Text style={stylesPdf.subTitle}>{`${t('executionTime')}:`}</Text>
              <Text style={stylesPdf.text}>{`${
                issue.stateMachineInstance.log.length
                  ? (humanizeDuration(
                      moment(issue.closedAt).diff(
                        moment(
                          issue.stateMachineInstance.log.length
                            ? issue.stateMachineInstance.log[0].createdAt
                            : issue.closedAt,
                        ),
                      ),
                      { round: true },
                    ) as string)
                  : ' - '
              }`}</Text>
            </View>
            <View style={stylesPdf.detailsContainer}>
              <Text style={stylesPdf.subTitle}>{`${t('score')}:`}</Text>
              <Text style={stylesPdf.text}>
                {issue.scoreInputs
                  ? `${currentScore}/${totalScore} (${Math.round((currentScore / totalScore) * 100)}%)`
                  : '-'}
              </Text>
            </View>
          </View>
          <View style={stylesPdf.row}></View>
          <View style={stylesPdf.rowContainer}>
            <View style={stylesPdf.detailsContainer}>
              <Text style={stylesPdf.subTitle}>{`${t('executedBy')}:`}</Text>
              <View style={{ flexDirection: 'row' }}>
                {issue.executedBy.length ? (
                  renderAccountLabel(issue.executedBy[0])
                ) : (
                  <Text style={stylesPdf.text}>-</Text>
                )}
              </View>
            </View>
            <View style={stylesPdf.detailsContainer}>
              <Text style={stylesPdf.subTitle}>{`${t('element')}:`}</Text>
              <Text style={stylesPdf.text}>{`${issue.element ? issue.element.name : '-'}`}</Text>
            </View>
            <View style={stylesPdf.detailsContainer}>
              <Text style={stylesPdf.subTitle}>{`${t('site')}:`}</Text>
              <Text style={stylesPdf.text}>{`${issue.site ? issue.site.name : '-'}`}</Text>
            </View>
          </View>
          <View style={{ marginTop: '8px', flexDirection: 'row' }}>
            <Text style={stylesPdf.text}>
              <View style={stylesPdf.subTitle}>{`${t('labels')}: `}</View>
            </Text>
            {issue.labelValues.length > 0 ? (
              issue.labelValues.map((elem) => renderLabel(elem))
            ) : (
              <Text style={stylesPdf.text}> - </Text>
            )}
          </View>
          <View style={{ marginTop: '24px' }}>
            <Text style={stylesPdf.text}>
              <View style={stylesPdf.subTitle}>{`${t('description')}: `}</View>
              {`${issue.description ? issue.description : ' - '}`}
            </Text>
          </View>
          <View style={{ marginTop: '24px' }}>
            <Text style={stylesPdf.text}>
              <View style={stylesPdf.subTitle}>{`${t('finishReason')}: `}</View>
              {`${issue.note ? issue.note : ' - '}`}
            </Text>
          </View>
          <View>
            <Text style={stylesPdf.subTitle}>{`${t('inputs')}:`}</Text>
            {issue.inputs.map((input) => {
              return addInput(input, t);
            })}
          </View>
        </View>
        <View>
          <Text style={stylesPdf.mainTitle}>{`${t('tasks')}`}</Text>
        </View>
        <View>
          {issue.taskGroups
            .filter((group) => group.tasks.length)
            .sort((x, y) => x.order - y.order)
            .map((group) => (
              <View>
                <View style={stylesPdf.groupContainer}>
                  <Text style={stylesPdf.groupTitle}>{group.name}</Text>
                  <View style={stylesPdf.groupCompletion}>
                    <ImageComponent src={RoundCheckOrange} style={stylesPdf.icon}></ImageComponent>
                    <Text style={{ marginLeft: '5px' }}>
                      Completed {`${countCompletedTasks(group)}`}/{`${countTotalTasks(group)}`}
                    </Text>
                  </View>
                </View>
                <View>
                  {group.tasks
                    .filter((t) => (t.onResponseId && t.isActiveByOnResponse) || !t.onResponseId)
                    .sort((x, y) => x.order - y.order)
                    .map((task) => (
                      <View>
                        <View style={stylesPdf.taskHeaderContainer}>
                          <View>
                            <Text style={stylesPdf.taskTitle}>{task.name}</Text>
                          </View>
                          <View style={stylesPdf.taskHeaderLabelContainer}>
                            <Text style={stylesPdf.taskHeaderLabelTitle}>{`${t('description')}`}</Text>
                            <Text style={stylesPdf.taskHeaderLabelText}>{`${
                              task.description ? task.description : '-'
                            }`}</Text>
                          </View>
                          <View style={stylesPdf.taskHeaderLabelContainer}>
                            <Text style={stylesPdf.taskHeaderLabelTitle}>{`${t('site')}`}</Text>
                            <Text style={stylesPdf.taskHeaderLabelText}>{`${task.site ? task.site.name : '-'}`}</Text>
                          </View>
                          <View style={stylesPdf.taskHeaderLabelContainer}>
                            <Text style={stylesPdf.taskHeaderLabelTitle}>{`${t('element')}`}</Text>
                            <Text style={stylesPdf.taskHeaderLabelText}>{`${
                              task.element ? task.element.name : '-'
                            }`}</Text>
                          </View>
                          <View style={stylesPdf.taskHeaderLabelContainer}>
                            <Text style={stylesPdf.taskHeaderLabelTitle}>{`${t('executedBy')}`}</Text>
                            {task.executedBy.length ? (
                              renderAccountLabel(task.executedBy[0])
                            ) : (
                              <Text style={stylesPdf.taskHeaderLabelText}>-</Text>
                            )}
                          </View>
                          <View style={stylesPdf.taskHeaderLabelContainer}>
                            <Text style={stylesPdf.taskHeaderLabelTitle}>{`${t('totalTaskTime')}`}</Text>
                            <Text style={stylesPdf.taskHeaderLabelText}>{`${
                              humanizeDuration(
                                moment(task.stateMachineInstance?.finishedAt).diff(
                                  moment(
                                    task.stateMachineInstance.log.length
                                      ? task.stateMachineInstance.log[0].createdAt
                                      : task.stateMachineInstance.finishedAt,
                                  ),
                                ),
                                { round: true },
                              ) as string
                            }`}</Text>
                          </View>
                          <View style={stylesPdf.taskHeaderLabelContainer}>
                            <Text style={stylesPdf.taskHeaderLabelTitle}>{`${t('taskScore')}`}</Text>
                            <Text style={stylesPdf.taskHeaderLabelText}>
                              {issue.scoreInputs && task.score.totalScore > 0
                                ? `${task.score.currentScore}/${task.score.totalScore} (${Math.round(
                                    (task.score.currentScore / task.score.totalScore) * 100,
                                  )}%)`
                                : '-'}
                            </Text>
                          </View>
                          <View style={stylesPdf.taskHeaderLabelContainer}>
                            <Text style={stylesPdf.taskHeaderLabelTitle}>{`${t('labels')}`}</Text>
                            {task.labelValues.length > 0 ? (
                              task.labelValues.map((elem) => renderLabel(elem))
                            ) : (
                              <Text style={stylesPdf.text}>' - '</Text>
                            )}
                          </View>
                        </View>
                        <View>
                          {task.executionInputs.map((input) => addInputAndConditionals(input, t, actionsFromInput))}
                        </View>
                      </View>
                    ))}
                </View>
              </View>
            ))}
        </View>
        <Text
          style={{ position: 'absolute', bottom: '8px', left: '16px', color: '#CACACA', fontSize: '10px' }}
          fixed
        >{`${t('reportGenerate')} ${t('on')} ${today}`}</Text>
        <Text
          style={{ position: 'absolute', bottom: '8px', right: '16px', fontSize: '10px', color: '#88899A' }}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
      {attachments.length ? (
        <Page style={stylesPdf.page}>
          <View style={stylesPdf.headerImg} fixed>
            <ImageComponent source={logo}></ImageComponent>
          </View>
          <View>
            <View style={stylesPdf.attachmentTitleContainer}>
              <Text style={stylesPdf.attachmentTitle}>{`${t('attachments')}`}</Text>
            </View>
            <View style={{ marginTop: '10px' }}>
              {attachments.map((attachment) => (
                <View style={{ marginBottom: '24px' }}>
                  <Text style={{ fontSize: '16px', color: '#88899A', marginBottom: '16px' }}>{attachment.name}</Text>
                  <ImageComponent style={{ maxWidth: '200px' }} src={attachment.url} />
                </View>
              ))}
            </View>
          </View>
          <Text
            style={{ position: 'absolute', bottom: '8px', left: '16px', color: '#CACACA', fontSize: '10px' }}
            fixed
          >{`${t('reportGenerate')}`}</Text>
          <Text
            style={{ position: 'absolute', bottom: '8px', right: '16px', fontSize: '10px', color: '#88899A' }}
            render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
            fixed
          />
        </Page>
      ) : null}
    </Document>,
  ).toBlob();
  saveAs(blob, issue.name + '.pdf');
};

let rows: Record<string, string>[] = [];
export const detailedReport = async (
  issue: IssueInstance,
  t: (s: string) => string,
  totalFinishedTasks: number,
  totalTasks: number,
  totalActions: number,
  _signatureSrcArray: signatureSourceLinks[],
  actionsFromInput?: ActionsDictionary[],
  tenantInfo?: TenantBasicInfo,
): Promise<void> => {
  const doc = new jsPDF('p', 'px');

  doc.addFileToVFS('OpenSans-Regular.ttf', openSans);
  doc.addFileToVFS('OpenSans-Bold.ttf', openSansBold);
  doc.addFileToVFS('OpenSans-Italic.ttf', openSansItalic);
  doc.addFont('OpenSans-Regular.ttf', 'OpenSans', 'normal');
  doc.addFont('OpenSans-Bold.ttf', 'OpenSans', 'bold');
  doc.addFont('OpenSans-Italic.ttf', 'OpenSans', 'italic');
  doc.setFont('OpenSans');

  const width = doc.internal.pageSize.getWidth();

  const logo = new Image();
  logo.src = tenantInfo?.resources?.logo_light?.url ?? GlarvisionImage;
  doc.addImage(logo, 'png', width / 2 - 20, 10, 50, 10);

  doc.setFillColor('#F4F5F9');
  doc.roundedRect(20, 25, width - 40, 125, 5, 5, 'F');
  doc.setTextColor('#0C38E8');
  doc.setFont('OpenSans', 'bold');
  doc.text(issue.name, 40, 40);

  doc.setTextColor('#777777');
  doc.setFont('OpenSans', 'normal');
  doc.setFontSize(9);

  doc.text(`${t('StartedAt')}:`, 40, 60);

  doc.text(`${t('finishAt')}:`, 120, 60);

  doc.text(`${t('executionTime')}:`, 200, 60);

  doc.setFontSize(10);
  doc.setTextColor('#000000');

  doc.text(
    `${
      issue.stateMachineInstance?.log.length
        ? moment(issue.stateMachineInstance.log[0].createdAt).format('YYYY/MM/DD HH:mm:ss')
        : ' - '
    }`,
    40,
    70,
  );
  doc.text(`${moment(issue.closedAt).format('YYYY/MM/DD HH:mm:ss')}`, 120, 70);
  doc.text(
    `${
      issue.stateMachineInstance.log.length
        ? (humanizeDuration(
            moment(issue.closedAt).diff(
              moment(
                issue.stateMachineInstance.log.length ? issue.stateMachineInstance.log[0].createdAt : issue.closedAt,
              ),
            ),
            { round: true },
          ) as string)
        : ' - '
    }`,
    200,
    70,
  );

  if (issue.scoreInputs) {
    const auxXHeader =
      200 +
      doc.getTextDimensions(
        humanizeDuration(
          moment(issue.closedAt).diff(
            moment(
              issue.stateMachineInstance.log.length ? issue.stateMachineInstance.log[0].createdAt : issue.closedAt,
            ),
          ),
          { round: true },
        ) as string,
      ).w;

    let totalScore = 0;
    let currentScore = 0;
    issue.taskGroups.map((taskGroup) =>
      taskGroup.tasks.map((task) => ((totalScore += task.score.totalScore), (currentScore += task.score.currentScore))),
    );

    doc.setTextColor('#777777');
    doc.setFontSize(9);
    doc.text(`${t('score')}:`, auxXHeader + 10, 60);

    doc.setFontSize(10);
    doc.setTextColor('#000000');
    doc.text(`${currentScore}/${totalScore} (${Math.round((currentScore / totalScore) * 100)}%)`, auxXHeader + 10, 70);
  }
  doc.setTextColor('#0C38E8');
  doc.setFontSize(14);
  doc.setFont('OpenSans', 'bold');

  doc.text(`${t('issueInformation')}`, 40, 100);

  if (totalActions > 0) {
    const action = new Image();
    action.src = ActionIcon;
    doc.addImage(action, 'png', width - 155, 95, 5, 5);
    doc.setTextColor('#FE8947');
    doc.setFont('OpenSans', 'normal');
    doc.setFontSize(9);
    doc.text(totalActions.toString(), width - 147, 100);
  }

  doc.setTextColor(totalFinishedTasks === totalTasks ? '#00C48C' : '#FE8947');
  const check = new Image();
  check.src = totalFinishedTasks === totalTasks ? RoundCheckGreen : RoundCheckOrange;

  doc.addImage(check, 'png', width - 108, 95, 5, 5);
  doc.setFont('OpenSans', 'normal');
  doc.setFontSize(9);

  doc.text(`${t('completed')} ${totalFinishedTasks}/${totalTasks}`, width - 100, 100);

  doc.setDrawColor('#0C38E8');
  doc.line(40, 102, width - 40, 102);

  doc.setTextColor('#777777');

  doc.text(`${t('executedBy')}:`, 40, 110);
  doc.text(`${issue.executedBy.length ? issue.executedBy[0].name : '-'}`, 40, 120);

  doc.text(`${t('element')}:`, 100, 110);
  doc.text(`${issue.element ? issue.element.name : '-'}`, 100, 120);

  let x = 100;

  x = x + doc.getTextDimensions(issue.element ? issue.element.name : t('element')).w + 10;

  doc.text(`${t('site')}:`, x, 110);
  doc.text(`${issue.site ? issue.site.name : '-'}`, x, 120);

  x = x + doc.getTextDimensions(issue.site ? issue.site.name : t('site')).w + 10;
  doc.text(`${t('description')}:`, x, 110);
  doc.text(`${issue.description ? issue.description : '-'}`, x, 120);

  //x = doc.getTextDimensions(issue.description ? issue.description : t('description')).w + 10;

  doc.text(`${t('inputs')}:`, 300, 110);
  if (issue.inputs.length > 0) {
    x = 300;
    issue.inputs.map((input) => (doc.text(`${input.name}`, x, 120), (x = x + doc.getTextDimensions(input.name).w + 5)));
  } else {
    doc.text('-', 300, 120);
  }
  doc.text(`${t('labels')}:`, 40, 135);

  doc.setFontSize(10);
  doc.setTextColor('#000000');

  if (issue.labelValues.length > 0) {
    let y = 145;
    issue.labelValues.map(
      (lv) => (
        doc.setFillColor(lv.backgroundColor),
        doc.roundedRect(
          40,
          y - 6,
          doc.getTextDimensions(lv.label.name).w + doc.getTextDimensions(lv.value).w + 10,
          doc.getTextDimensions(lv.label.name).h,
          5,
          5,
          'F',
        ),
        doc.setTextColor('#FFFFFF'),
        doc.text(`${lv.label.name}: ${lv.value}`, 43, y),
        (y = y + doc.getTextDimensions(lv.value).w + doc.getTextDimensions(lv.label.name).w + 5)
      ),
    );
  } else {
    doc.text('-', 40, 145);
  }

  doc.setTextColor('#777777');
  let y = 160;

  if (issue.note) {
    y = y + 10;
    doc.setFontSize(10);
    doc.setTextColor('#272848');
    doc.text(`${t('ObservationPendingTasks')}`, 20, y);
    doc.setFontSize(8);
    doc.setTextColor('#88899A');
    doc.text(`${issue.note}`, 20, y + 10);

    y = y + doc.getTextDimensions(issue.note).h + 20;
  }

  doc.setFontSize(14);
  doc.setTextColor('#0C38E8');
  doc.setFont('OpenSans', 'bold');
  doc.text(`${t('tasks')}:`, width / 2, y, { align: 'center' });

  y = y + 10;

  issue.taskGroups
    .sort((x, y) => x.order - y.order)
    .forEach((group) => {
      y = incrementY(y);
      doc.setFontSize(12);
      doc.setFont('OpenSans', 'normal');
      doc.setFillColor('#F4F5F9');
      doc.setTextColor('#0C38E8');
      doc.roundedRect(20, y - 10, width - 40, 15, 5, 5, 'F');
      doc.text(`${group.name}`, width / 2, y, { align: 'center' });
      group.tasks
        .filter((t) => (t.onResponseId && t.isActiveByOnResponse) || !t.onResponseId)
        .sort((x, y) => x.order - y.order)
        .forEach((task) => {
          y = incrementY(y);
          const checkGroup = new Image();
          checkGroup.src = RoundCheckOrange;

          doc.addImage(checkGroup, 'png', width - 80, y - 5, 5, 5);
          doc.setFontSize(9);
          doc.setTextColor('#FE8947');
          doc.text(
            `${t('completed')} ${
              task.executionInputs
                .sort((x, y) => x.order - y.order)
                .filter(
                  (inp) =>
                    inp.type !== 'instruction' &&
                    ((inp.type !== 'table' && !!inp[inp.type].values?.length) ||
                      (inp.type === 'table' &&
                        !!(inp[inp.type] as issueTable).values.filter(
                          (c) =>
                            (c.rows[c.type] as string[]).length &&
                            (c.rows[c.type] as string[]).filter((r) => r?.length && !!r[0]).length ===
                              (c.rows[c.type] as string[]).length,
                        ).length)),
                ).length
            }/${task.executionInputs.filter((i) => i.type !== 'instruction').length}`,
            width - 70,
            y,
          );

          // title area
          if (y > doc.internal.pageSize.getHeight() - 100) {
            doc.addPage();
            y = 40;
          }
          doc.setTextColor('#777777');
          doc.text(`${t('title')}:`, 40, y);
          y = y + 10;
          doc.setFontSize(14);
          doc.setFont('OpenSans', 'bold');
          doc.setTextColor('#0C38E8');

          y = y + doc.getTextDimensions(task.name).h + 5;
          doc.text(`${task.name}`, 40, y, { maxWidth: width - 60 });
          y = y + 20;

          /* doc.text(`${task.name}`, 40, y); */
          // end of title area
          doc.setFontSize(9);
          doc.setFont('OpenSans', 'normal');
          doc.setTextColor('#777777');
          y = incrementY(y);
          y = incrementY(y + doc.getTextDimensions(task.name).h);
          doc.text(`${t('description')}:`, 40, y);

          doc.text(`${t('site')}:`, 100, y);

          doc.text(`${t('element')}:`, 160, y);

          doc.text(`${t('executedBy')}:`, 220, y);

          doc.text(`${t('totalTaskTime')}:`, 300, y);

          y = incrementY(y);

          doc.setTextColor('#272848');

          doc.text(`${task.description ? task.description : '-'}`, 40, y);

          doc.text(`${task.site ? task.site.name : '-'}`, 100, y);

          doc.text(`${task.element ? task.element.name : '-'}`, 160, y);

          doc.text(`${task.executedBy.length ? task.executedBy[0].name : '-'}`, 220, y);

          doc.text(
            `${
              humanizeDuration(
                moment(task.stateMachineInstance?.finishedAt).diff(
                  moment(
                    task.stateMachineInstance.log.length
                      ? task.stateMachineInstance.log[0].createdAt
                      : task.stateMachineInstance.finishedAt,
                  ),
                ),
                { round: true },
              ) as string
            }`,
            300,
            y,
          );
          if (issue.scoreInputs && task.score.totalScore > 0) {
            let auxX =
              300 +
              doc.getTextDimensions(
                humanizeDuration(
                  moment(task.stateMachineInstance?.finishedAt).diff(
                    moment(
                      task.stateMachineInstance.log.length
                        ? task.stateMachineInstance.log[0].createdAt
                        : task.stateMachineInstance.finishedAt,
                    ),
                  ),
                  { round: true },
                ) as string,
              ).w;

            doc.setFontSize(10);
            doc.setFont('OpenSans', 'normal');

            if (
              auxX +
                doc.getTextDimensions(`
               ${task.score.currentScore}/${task.score.totalScore} (${Math.round(
                 (task.score.currentScore / task.score.totalScore) * 100,
               )}%)`).w >
              width - 40
            ) {
              auxX = 40;
            }
            y = incrementY(y);
            doc.setTextColor('#777777');

            doc.text(`${t('score')}:`, 40, y);

            doc.setTextColor('#272848');

            y = incrementY(y);
            doc.text(
              `${task.score.currentScore}/${task.score.totalScore} (${Math.round(
                (task.score.currentScore / task.score.totalScore) * 100,
              )}%)`,
              40,
              y,
            );
          }

          y = incrementY(y);

          if (task.labelValues.length > 0) {
            doc.text(`${t('labels')}:`, 40, y);

            y = incrementY(y);
          }

          if (task.labelValues.length > 0) {
            let x = 40;
            task.labelValues.map(
              (lv) => (
                doc.setFillColor(lv.backgroundColor),
                doc.roundedRect(
                  x,
                  y - 6,
                  doc.getTextDimensions(lv.label.name).w + doc.getTextDimensions(lv.value).w + 10,
                  doc.getTextDimensions(lv.label.name).h,
                  5,
                  5,
                  'F',
                ),
                doc.setTextColor('#FFFFFF'),
                doc.text(`${lv.label.name}: ${lv.value}`, x + 3, y),
                (x = x + doc.getTextDimensions(lv.value).w + doc.getTextDimensions(lv.label.name).w + 15)
              ),
            );
            y = incrementY(y);
          } else {
            doc.text('-', 40, 145);
          }
          y = incrementY(y);
          doc.setTextColor('#777777');
          if (task.assignedMaterials.length > 0) {
            doc.setDrawColor('#CACACA');
            doc.text(`${t('materials')}:`, 40, y);
            y = incrementY(y);
            //create headers of the table
            const headers = ['code', 'name', 'quantity'];
            const data: Record<string, string>[] = [];
            task.assignedMaterials.map((aM) =>
              data.push({ code: aM.material.code, name: aM.material.name, quantity: aM.quantity.toString() }),
            );
            doc.table(40, y, data, headers, {
              autoSize: true,
              fontSize: 9,
              headerBackgroundColor: '#FFFFFF',
              headerTextColor: '#272848',
            });
            y = y + (data.length + 1) * 25;
          }

          if (task.assignedTools.length > 0) {
            doc.setDrawColor('#CACACA');
            doc.text(`${t('tools')}:`, 40, y);
            y = incrementY(y);
            //create headers of the table
            const headers = ['code', 'name', 'quantity'];
            const data: Record<string, string>[] = [];
            task.assignedTools.map((aT) =>
              data.push({ code: aT.tool.partNumber, name: aT.tool.designation, quantity: aT.quantity.toString() }),
            );

            doc.table(40, y, data, headers, {
              autoSize: true,
              fontSize: 9,
              headerBackgroundColor: '#FFFFFF',
              headerTextColor: '#272848',
            });
            y = y + (data.length + 1) * 25;
          }

          doc.setDrawColor('#0C38E8');
          doc.setTextColor('#0C38E8');
          doc.setFontSize(14);
          doc.text(`${t('inputs')}`, 40, y);
          y = y + 10;
          doc.line(40, y, width - 40, y);
          doc.setFontSize(9);
          doc.setFont('OpenSans', 'normal');
          doc.setTextColor('#777777');

          task.executionInputs.map((input) => {
            /* try { */
            doc.setTextColor('#777777');
            y = incrementY(y);
            if (y > doc.internal.pageSize.getHeight() - 150) {
              doc.addPage();
              y = 40;
            }
            let x = 40;
            if (input.required) {
              doc.text(`${input.name}*`, x, y, {
                maxWidth: (input.type === 'instruction' ? width / 2 : width) - 40,
              });
            } else {
              doc.text(`${input.name}`, x, y, { maxWidth: (input.type === 'instruction' ? width / 2 : width) - 40 });
            }
            doc.setFontSize(9);
            if (doc.getTextDimensions(input.name).w > width - 80) {
              y = y + 20;
            } else {
              y = incrementY(y);
            }
            const srcArray: ImagesOnNotes[] = [];
            let headers: string[] = [];
            switch (input.type) {
              case 'number':
                doc.text(input[input.type].values.toString(), x, y);
                y = y + doc.getTextDimensions(input[input.type].values.toString()).h;
                break;
              case 'string': {
                doc.text(input[input.type].values.toString(), x, y, { maxWidth: width - 60 });
                // y = y + doc.getTextDimensions((input[input.type] as issueString).values.toString()).h;
                const widthMax = doc.getTextDimensions(input[input.type].values.toString()).w;
                const multiple = widthMax / width;
                if (multiple < 1) {
                  y = incrementY(y);
                } else {
                  y += multiple * 15;
                }

                break;
              }
              case 'file':
              case 'instruction':
                x = width / 2;
                input[input.type].values.map((val) => {
                  if (val.download.thumbnail?.url || val.download.url)
                    srcArray.push({
                      imageToRender: getImageForReport(val.download.thumbnail?.url || val.download.url),
                      name: val.name,
                      extension: val.extension,
                    });
                });
                let addNewLine = false;
                srcArray.map((src) => {
                  if (src.extension !== 'pdf') {
                    doc.addImage(src.imageToRender, src.extension, x, y, 70, 70),
                      doc.text(src.name, x, y + 80, doc.getTextDimensions(src.name).w > 80 ? { maxWidth: 80 } : null),
                      (x = x + 90),
                      x + 70 > width ? ((y = y + 90), (x = width / 2), (addNewLine = false)) : (addNewLine = true);
                  }
                });
                /* y = y + 75; */
                addNewLine ? (y = y + 100) : null;

                break;
              case 'table':
                doc.setDrawColor('#CACACA');

                (input[input.type] as issueTable).values.map((value, idx) => {
                  headers.push(value.name);
                  if (idx === 0) {
                    value.rows.string.map((s) =>
                      s !== null ? rows.push({ [value.name]: s }) : rows.push({ [value.name]: '' }),
                    );
                  } else {
                    rows = rows.map((s, i) => ({
                      ...s,
                      [value.name]:
                        value.rows.string[parseInt(i.toString())] !== null
                          ? value.rows.string[parseInt(i.toString())]
                          : '',
                    }));
                  }
                });
                doc.table(x, y, rows, headers, {
                  autoSize: true,
                  fontSize: 9,
                  headerBackgroundColor: '#FFFFFF',
                  headerTextColor: '#272848',
                });
                y = y + rows.length * 25;
                rows = [];
                headers = [];
                break;
              case 'time':
                if (input[input.type].values?.length) {
                  const today = moment();
                  doc.text(
                    `${moment({
                      year: today.get('year'),
                      month: today.get('month'),
                      day: today.get('day'),
                      hours: parseInt(input[input.type].values[0].split(':')[0]),
                      minutes: parseInt(input[input.type].values[0].split(':')[1]),
                    }).format('HH:mm')}`,
                    x,
                    y,
                  );
                  y =
                    y +
                    doc.getTextDimensions(
                      `${moment({
                        year: today.get('year'),
                        month: today.get('month'),
                        day: today.get('day'),
                        hours: parseInt(input[input.type].values[0].split(':')[0]),
                        minutes: parseInt(input[input.type].values[0].split(':')[1]),
                      }).format('HH:mm')}`,
                    ).h;
                }
                break;
              case 'date':
                if (input[input.type].values?.length) {
                  doc.text(`${moment(input[input.type].values[0]).format('YYYY/MM/DD')}`, x, y);
                  y =
                    y + doc.getTextDimensions(`${moment(input[input.type].values[0]).format('YYYY/MM/DD HH:mm:ss')}`).h;
                }
                break;
              case 'datetime':
                if (input[input.type].values?.length) {
                  doc.text(`${moment(input[input.type].values[0]).format('YYYY/MM/DD HH:mm:ss')}`, x, y);
                  y =
                    y + doc.getTextDimensions(`${moment(input[input.type].values[0]).format('YYYY/MM/DD HH:mm:ss')}`).h;
                }
                break;
              case 'signature':
                (input[input.type] as issueSignature).values.map((val) => {
                  doc.text(val.name ? val.name : '-', width / 2, y, { maxWidth: width / 2 - 40 });
                  if (val.image.download.thumbnail?.url || val.image.download.url)
                    srcArray.push({
                      imageToRender: getImageForReport(val.image.download.thumbnail?.url || val.image.download.url),
                      name: val.image.name,
                      extension: val.image.extension,
                    });
                });
                srcArray.map((src) => {
                  doc.addImage(src.imageToRender, src.extension, width / 2, y, 40, 40);
                });
                y = y + 45;
                break;
              case 'site':
                input[input.type].values.map(
                  (value) => (
                    doc.text(value.name ? value.name : '-', x, y),
                    (y = y + doc.getTextDimensions(value.name ? value.name : '-').h)
                  ),
                );
                break;
              case 'element':
                input[input.type].values.map(
                  (value) => (
                    doc.text(value.name ? value.name : '-', x, y),
                    (y = y + doc.getTextDimensions(value.name ? value.name : '-').h)
                  ),
                );

                break;
              default:
                break;
            }

            y = incrementY(y);

            if (input.note) {
              doc.text(`${t('files&notes')}`, 40, y);
              let x = 40 + 5 + doc.getTextDimensions(`${t('files&notes')}`).w;
              if (input.note.text) {
                doc.text(`${input.note.text}`, x, y);
                y = y + doc.getTextDimensions(input.note.text).h;
              }
              if (input.note.files) {
                const imagesArray: ImagesOnNotes[] = [];
                let addNewLine = false;
                input.note.files.map((file) =>
                  imagesArray.push({
                    imageToRender: getImageForReport(file.download.thumbnail.url),
                    name: file.name,
                    extension: file.extension,
                  }),
                );
                imagesArray.map(
                  (image) => (
                    doc.addImage(image.imageToRender, image.extension, x, y, 70, 70),
                    doc.text(image.name, x, y + 80, doc.getTextDimensions(image.name).w > 90 ? { maxWidth: 80 } : null),
                    (x = x + 90),
                    x + 70 > width ? ((y = y + 90), (x = width / 2), (addNewLine = false)) : (addNewLine = true),
                    y > doc.internal.pageSize.getHeight() - 100 ? (doc.addPage(), (y = 40)) : null
                  ),
                );
                //add some space to prevent overlap of information
                addNewLine ? (y = y + 100) : null;
              }
            }

            /* if (idx !== task.executionInputs.length - 1) { */
            doc.line(40, y, width - 40, y);
            /* } */
            if (input[input.type]['onResponse']?.length) {
              input[input.type]['onResponse'].map((response) =>
                input[input.type].values.includes(response.ifValues[0] as unknown)
                  ? response.inputs.map((inp) => {
                      doc.setTextColor('#777777');
                      y = incrementY(y);
                      if (y > doc.internal.pageSize.getHeight() - 150) {
                        doc.addPage();
                        y = 40;
                      }
                      if (inp.required) {
                        doc.text(`${inp.name}*`, 40, y, {
                          maxWidth: (inp.type === 'instruction' ? width / 2 : width) - 40,
                        });
                      } else {
                        doc.text(`${inp.name}`, 40, y, {
                          maxWidth: (inp.type === 'instruction' ? width / 2 : width) - 40,
                        });
                      }
                      doc.setFontSize(9);
                      if (doc.getTextDimensions(inp.name).w > width - 80) {
                        y = y + 20;
                      } else {
                        y = incrementY(y);
                      }
                      const srcArray: ImagesOnNotes[] = [];
                      let headers: string[] = [];
                      let x = 40;
                      switch (inp.type) {
                        case 'number':
                          doc.text((inp[inp.type] as issueNumber).values.toString(), x, y);
                          y = y + doc.getTextDimensions((inp[inp.type] as issueNumber).values.toString()).h;
                          break;
                        case 'string':
                          doc.text((inp[inp.type] as issueString).values.toString(), x, y, { maxWidth: width - 60 });
                          // y = y + doc.getTextDimensions((input[input.type] as issueString).values.toString()).h;
                          const widthMax = doc.getTextDimensions((inp[inp.type] as issueString).values.toString()).w;
                          const multiple = widthMax / width;
                          if (multiple < 1) {
                            y = incrementY(y);
                          } else {
                            y += multiple * 15;
                          }

                          break;
                        case 'file':
                        case 'instruction':
                          x = width / 2;
                          (inp[inp.type] as issueInstruction).values.map((val) => {
                            if (val.download.thumbnail?.url || val.download.url)
                              srcArray.push({
                                imageToRender: getImageForReport(val.download.thumbnail?.url || val.download.url),
                                name: val.name,
                                extension: val.extension,
                              });
                          });
                          let addNewLine = false;
                          srcArray.map((src) => {
                            if (src.extension !== 'pdf') {
                              doc.addImage(src.imageToRender, src.extension, x, y, 70, 70),
                                doc.text(
                                  src.name,
                                  x,
                                  y + 80,
                                  doc.getTextDimensions(src.name).w > 80 ? { maxWidth: 80 } : null,
                                ),
                                (x = x + 90),
                                x + 70 > width
                                  ? ((y = y + 90), (x = width / 2), (addNewLine = false))
                                  : (addNewLine = true);
                            }
                          });
                          /* y = y + 75; */
                          addNewLine ? (y = y + 100) : null;

                          break;
                        case 'table':
                          doc.setDrawColor('#CACACA');

                          (inp[inp.type] as issueTable).values.map((value, idx) => {
                            headers.push(value.name);
                            if (idx === 0) {
                              value.rows.string.map((s) =>
                                s !== null ? rows.push({ [value.name]: s }) : rows.push({ [value.name]: '' }),
                              );
                            } else {
                              rows = rows.map((s, i) => ({
                                ...s,
                                [value.name]:
                                  value.rows.string[parseInt(i.toString())] !== null
                                    ? value.rows.string[parseInt(i.toString())]
                                    : '',
                              }));
                            }
                          });
                          doc.table(x, y, rows, headers, {
                            autoSize: true,
                            fontSize: 9,
                            headerBackgroundColor: '#FFFFFF',
                            headerTextColor: '#272848',
                          });
                          y = y + rows.length * 25;
                          rows = [];
                          headers = [];
                          break;
                        case 'time':
                          if (input[input.type].values?.length) {
                            const today = moment();
                            doc.text(
                              `${moment({
                                year: today.get('year'),
                                month: today.get('month'),
                                day: today.get('day'),
                                hours: parseInt(input[input.type].values[0].split(':')[0]),
                                minutes: parseInt(input[input.type].values[0].split(':')[1]),
                              }).format('HH:mm')}`,
                              x,
                              y,
                            );
                            y =
                              y +
                              doc.getTextDimensions(
                                `${moment({
                                  year: today.get('year'),
                                  month: today.get('month'),
                                  day: today.get('day'),
                                  hours: parseInt(input[input.type].values[0].split(':')[0]),
                                  minutes: parseInt(input[input.type].values[0].split(':')[1]),
                                }).format('HH:mm')}`,
                              ).h;
                          }
                          break;
                        case 'date':
                          if (input[input.type].values?.length) {
                            doc.text(`${moment(input[input.type].values[0]).format('YYYY/MM/DD')}`, x, y);
                            y =
                              y +
                              doc.getTextDimensions(
                                `${moment(input[input.type].values[0]).format('YYYY/MM/DD HH:mm:ss')}`,
                              ).h;
                          }
                          break;
                        case 'datetime':
                          if (input[input.type].values?.length) {
                            doc.text(`${moment(input[input.type].values[0]).format('YYYY/MM/DD HH:mm:ss')}`, x, y);
                            y =
                              y +
                              doc.getTextDimensions(
                                `${moment(input[input.type].values[0]).format('YYYY/MM/DD HH:mm:ss')}`,
                              ).h;
                          }
                          break;
                        case 'signature':
                          (inp[inp.type] as issueSignature).values.map((val) => {
                            doc.text(val.name ? val.name : '-', width / 2, y, { maxWidth: width / 2 - 40 });
                            if (val.image.download.thumbnail?.url || val.image.download.url)
                              srcArray.push({
                                imageToRender: getImageForReport(
                                  val.image.download.thumbnail?.url || val.image.download.url,
                                ),
                                name: val.image.name,
                                extension: val.image.extension,
                              });
                          });
                          srcArray.map((src) => {
                            doc.addImage(src.imageToRender, src.extension, width / 2, y, 40, 40);
                          });
                          y = y + 45;
                          break;
                        case 'site':
                          (inp[inp.type] as issueSiteElement).values.map(
                            (value) => (
                              doc.text(value.name ? value.name : '-', x, y),
                              (y = y + doc.getTextDimensions(value.name ? value.name : '-').h)
                            ),
                          );
                          break;
                        case 'element':
                          (inp[inp.type] as issueSiteElement).values.map(
                            (value) => (
                              doc.text(value.name ? value.name : '-', x, y),
                              (y = y + doc.getTextDimensions(value.name ? value.name : '-').h)
                            ),
                          );

                          break;
                        default:
                          break;
                      }

                      y = incrementY(y);

                      if (inp.note) {
                        doc.text(`${t('files&notes')}`, 40, y);
                        let x = 40 + 5 + doc.getTextDimensions(`${t('files&notes')}`).w;
                        if (inp.note.text) {
                          doc.text(`${inp.note.text}`, x, y);
                          y = y + doc.getTextDimensions(inp.note.text).h;
                        }
                        if (inp.note.files) {
                          const imagesArray: ImagesOnNotes[] = [];
                          let addNewLine = false;
                          inp.note.files.map((file) =>
                            imagesArray.push({
                              imageToRender: getImageForReport(file.download.thumbnail.url),
                              name: file.name,
                              extension: file.extension,
                            }),
                          );
                          imagesArray.map(
                            (image) => (
                              doc.addImage(image.imageToRender, image.extension, x, y, 70, 70),
                              doc.text(
                                image.name,
                                x,
                                y + 80,
                                doc.getTextDimensions(image.name).w > 90 ? { maxWidth: 80 } : null,
                              ),
                              (x = x + 90),
                              x + 70 > width
                                ? ((y = y + 90), (x = width / 2), (addNewLine = false))
                                : (addNewLine = true),
                              y > doc.internal.pageSize.getHeight() - 100 ? (doc.addPage(), (y = 40)) : null
                            ),
                          );
                          //add some space to prevent overlap of information
                          addNewLine ? (y = y + 100) : null;
                        }
                      }

                      /* if (idx !== task.executionInputs.length - 1) { */
                      doc.line(40, y, width - 40, y);
                      /*  } */
                    })
                  : null,
              );
            }
            /*  } catch{(e) => console.error(e)} */
          });
        });
    });

  addFooters(doc, t);
  /* doc.save(`${issue.name}.pdf`); */
  await createPdf(t, issue, totalActions, totalFinishedTasks, totalTasks, actionsFromInput, tenantInfo);
};
