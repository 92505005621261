import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';

const AlertViewDefaultRefresh = () => lazy(() => import('src/modules/alert/alert.view.default'));
const AlertCreateDefaultRefresh = () => lazy(() => import('src/modules/alert/alert.create.default'));

let AlertViewDefault = AlertViewDefaultRefresh();
let AlertCreateDefault = AlertCreateDefaultRefresh();

export const render = {
  view: {
    default: ({ ...props }) => {
      if (AlertViewDefault?._result?.type === 'error') {
        AlertViewDefault = AlertViewDefaultRefresh();
      }
      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <AlertViewDefault {...props} />
        </Suspense>
      );
    },
  },
  create: {
    default: ({ ...props }) => {
      if (AlertCreateDefault?._result?.type === 'error') {
        AlertCreateDefault = AlertCreateDefaultRefresh();
      }

      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <AlertCreateDefault {...props} />
        </Suspense>
      );
    },
  },
};

export default render;
