import { Action, State } from 'src/interfaces/reducers';
import { getErrorObject } from 'src/utils/funcs';
import * as queries from 'src/modules/inventory/inventory.queries';
import { APOLLO_CLIENT } from 'config/apollo.config';
import { Dispatch } from 'redux';
import i18n from 'src/utils/translations/i18n';
import {
  Material,
  MaterialItemUpdateInput,
  MaterialItemWhereInput,
  MaterialItemWhereUniqueInput,
  MaterialUpdateInput,
  MaterialWhereUniqueInput,
  NfcTagWhereInput,
  StorageWhereInput,
  ToolItemStorageDistinctLocationsResult,
  ToolItemWhereInput,
} from 'src/gql/graphql';
import { GraphQLErrors } from '@apollo/client/errors';
import { MaterialWhereInput, ToolItem, ToolItemWhereUniqueInput } from 'src/gql/graphql';
import { NexusGenInputs } from '@server/src/types';
const initialState: State = {};

export default (_state: State = initialState, action: Action): State => {
  switch (action.type) {
    default:
      return {};
  }
};

export const deleteToolItemsBatch = (args: { storageId: string; toolId: string }) => async (dispatch: Dispatch) => {
  try {
    const { storageId, toolId } = args;
    const response = await APOLLO_CLIENT.query({
      variables: {
        storageId,
        toolId,
      },
      fetchPolicy: 'no-cache',
      query: queries.M_DELETE_TOOLITEMS_BATCH,
    });
    return response.data.deletedCount;
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getMaterial = (where) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_MATERIAL,
    });
    return response.data.material;
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getMaterials =
  (where?: NexusGenInputs['MaterialWhereInput'], limit?: number, skip?: number, orderBy?) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          where,
          limit,
          skip,
          orderBy,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_MATERIALS,
      });
      return response.data.materials;
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getMaterialsSearch =
  (where: MaterialWhereInput, limit?: number, skip?: number, orderBy?) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          where,
          limit,
          skip,
          orderBy,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_MATERIALS_SEARCH,
      });

      return response.data.materialsSearch;
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getMaterialsCount = (where?: NexusGenInputs['MaterialWhereInput']) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_MATERIALS_COUNT,
    });
    return response.data.materialsCount;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const createMaterial = (data) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        data,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_CREATE_MATERIAL,
    });

    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: i18n.t('materialAdded'),
        severity: 'success',
      },
    });

    return resp.data.createMaterial;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const createMaterials = (data: string | ArrayBuffer) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        data,
      },
      mutation: queries.M_CREATE_MATERIALS,
    });

    if (response.data.createMaterials.totalImported === 0) {
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('allItemsExist', { context: 'materials' }),
          severity: 'error',
        },
      });
    } else if (
      response.data.createMaterials.totalImported > 0 &&
      response.data.createMaterials.totalImported < response.data.createMaterials.total
    ) {
      const existentItemsLength = response.data.createMaterials.total - response.data.createMaterials.totalImported;
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('partialImportedItems', {
            itemsImported: response.data.createMaterials.totalImported,
            existingItems: existentItemsLength,
            context: 'materials',
          }),
          severity: 'success',
        },
      });
    } else {
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('allItemsImported', { context: 'materials' }),
          severity: 'success',
        },
      });
    }

    return response.data.createMaterials.totalImported;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const deleteMaterial = (where) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_DELETE_MATERIAL,
    });

    return resp.data.deleteOneMaterial;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const updateMaterial =
  (data: MaterialUpdateInput, where: MaterialWhereUniqueInput) =>
  async (dispatch: Dispatch): Promise<Material | { graphQLErrors: GraphQLErrors }> => {
    try {
      const resp = await APOLLO_CLIENT.mutate({
        variables: {
          data,
          where,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_MATERIAL,
      });

      return resp.data.updateMaterial;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getToolItemsLocations =
  (toolId: string) =>
  async (dispatch: Dispatch): Promise<ToolItemStorageDistinctLocationsResult[]> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          toolId,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_TOOL_ITEMS_LOCATIONS,
      });
      return response.data.toolItemsLocations;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getTools = (where?, limit?: number, skip?: number, orderBy?) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
        limit,
        skip,
        orderBy,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_TOOLS,
    });
    return response.data.tools;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getToolsSearch = (where: Record<string, string>) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_TOOLS_SEARCH,
    });

    return response.data.toolsSearch;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};
export const getToolsCount = (where?) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_TOOLS_COUNT,
    });
    return response.data.toolsCount;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const createTool = (data) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        data,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_CREATE_TOOL,
    });

    return resp.data.createTool;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const deleteTool = (where) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_DELETE_TOOL,
    });

    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: i18n.t('toolDeleted'),
        severity: 'success',
      },
    });

    return resp.data.deleteOneTool;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const updateTool = (data, where) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        data,
        where,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_UPDATE_TOOL,
    });

    return resp.data.updateTool;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const createStorage = (data) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        data,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_CREATE_STORAGE,
    });

    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: i18n.t('storageCreated'),
        severity: 'success',
      },
    });

    return resp.data.createStorage;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getStorages =
  (where?: StorageWhereInput, limit?: number, skip?: number, orderBy?) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          where,
          limit,
          skip,
          orderBy,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_STORAGES,
      });
      return response.data.storages;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getStoragesCount = (where?) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_STORAGES_COUNT,
    });
    return response.data.storagesCount;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getStorage = (where) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_STORAGE,
    });
    return response.data.storage;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const updateStorage = (data, where) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        data,
        where,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_UPDATE_STORAGE,
    });

    return resp.data.updateStorage;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const deleteStorage = (where) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_DELETE_STORAGE,
    });

    return resp.data.deleteOneStorage;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const createLocation = (data) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        data,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_CREATE_LOCATION,
    });

    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: i18n.t('locationCreated'),
        severity: 'success',
      },
    });

    return resp.data.createLocation;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getLocations = (where?, limit?: number, skip?: number, orderBy?) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
        limit,
        skip,
        orderBy,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_LOCATIONS,
    });
    return response.data.locations;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const getLocationsCount = (where?) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_LOCATIONS_COUNT,
    });
    return response.data.locationsCount;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const deleteManyLocations = (data) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        _ids: data,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_DELETE_MANY_LOCATIONS,
    });

    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: `${resp.data.deleteManyLocations.length} ${i18n.t('locationDeleted')}`,
        severity: 'success',
      },
    });

    return resp.data.deleteManyLocations;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const createMaterialItem = (data) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        data,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_CREATE_MATERIAL_ITEM,
    });

    // dispatch({
    //   type: 'SNACKBAR_NEW_MESSAGE',
    //   payload: {
    //     message: i18n.t('locationCreated'),
    //     severity: 'success',
    //   },
    // });

    return resp.data.createMaterialItem;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getMaterialItems =
  (where?: MaterialItemWhereInput, limit?: number, skip?: number, orderBy?) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          where,
          limit,
          skip,
          orderBy,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_MATERIAL_ITEMS,
      });
      return response.data.materialItems;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getMaterialItemsCount = (where?: MaterialItemWhereInput) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_MATERIAL_ITEMS_COUNT,
    });
    return response.data.materialItemsCount;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const deleteMaterialItem = (where) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_DELETE_MATERIAL_ITEM,
    });

    return resp.data.deleteOneMaterialItem;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const deleteManyMaterialItems = (data) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: data,
      fetchPolicy: 'no-cache',
      mutation: queries.M_DELETE_MANY_MATERIAL_ITEMS,
    });

    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: `${i18n.t('materialItemDeleted')}`,
        severity: 'success',
      },
    });

    return resp.data.deleteManyMaterialItems;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const updateMaterialItem =
  (data: MaterialItemUpdateInput, where: MaterialItemWhereUniqueInput) => async (dispatch: Dispatch) => {
    try {
      const resp = await APOLLO_CLIENT.mutate({
        variables: {
          data,
          where,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_MATERIAL_ITEM,
      });

      return resp.data.updateMaterialItem;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getMaterialsByStorage = (storage, limit, skip, order, where) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        storage,
        limit,
        skip,
        orderBy: order,
        where,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_MATERIALS_BY_STORAGE,
    });
    return response.data;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const createToolItem = (data) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        data,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_CREATE_TOOL_ITEM,
    });

    return resp.data.createToolItem;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const createToolItems = (data: string | ArrayBuffer, toolId: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        data,
        toolId,
      },
      mutation: queries.M_CREATE_TOOL_ITEMS,
    });

    if (response.data.createToolItems.totalImported === 0) {
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('allItemsExist', { context: 'tool items' }),
          severity: 'error',
        },
      });
    } else if (
      response.data.createToolItems.totalImported > 0 &&
      response.data.createToolItems.totalImported < response.data.createToolItems.total
    ) {
      const existentItemsLength = response.data.createToolItems.total - response.data.createToolItems.totalImported;
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('partialImportedItems', {
            itemsImported: response.data.createToolItems.totalImported,
            existingItems: existentItemsLength,
            context: 'tool items',
          }),
          severity: 'success',
        },
      });
    } else {
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('allItemsImported', { context: 'tool items' }),
          severity: 'success',
        },
      });
    }

    return response.data.createToolItems.totalImported;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getToolItems =
  (where?: ToolItemWhereInput, limit?: number, skip?: number, orderBy?) =>
  async (dispatch: Dispatch): Promise<{ toolItems: ToolItem[]; total: number }> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          where,
          limit,
          skip,
          orderBy,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_TOOL_ITEMS,
      });
      return { toolItems: response.data.toolItems, total: response.data.total };
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getToolItem = (where: ToolItemWhereUniqueInput) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_TOOL_ITEM,
    });
    return response.data.toolItem;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const updateToolItem = (data, where) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        data,
        where,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_UPDATE_TOOL_ITEM,
    });

    return resp.data.updateToolItem;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const deleteManyToolItems = (data) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: data,
      fetchPolicy: 'no-cache',
      mutation: queries.M_DELETE_MANY_TOOL_ITEMS,
    });

    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: `${i18n.t('toolItemDeleted')}`,
        severity: 'success',
      },
    });

    return resp.data.deleteManyToolItems;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getTool = (where) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_TOOL,
    });
    return response.data.tool;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getSuggestedItems = (tools) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        tools,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_SUGGESTED_TOOL_ITEMS,
    });
    return response.data.getSuggestedToolItems;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const deleteToolItem = (filter: ToolItemWhereUniqueInput) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where: filter,
      },
      fetchPolicy: 'no-cache',
      query: queries.M_DELETE_TOOL_ITEM,
    });
    return response.data.deleteOneToolItem;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getToolItemWithDeleted =
  (filter: ToolItemWhereUniqueInput, doDispatch = true) =>
  async (dispatch: Dispatch): Promise<ToolItem> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          where: filter,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_TOOL_ITEM_WITH_DELETED,
      });
      return response.data.toolItemWithDeleted;
    } catch (error) {
      const obj = getErrorObject(error, '');
      if (doDispatch) {
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: obj.message,
            severity: 'error',
          },
        });
      }

      return error;
    }
  };

export const getNfcTagsLinkedToToolItem =
  (filter: NfcTagWhereInput) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          where: filter,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_NFC_TAGS_LINKED_TO_TOOL_ITEM,
      });

      return response.data.nfcTags;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const unlinkNfcTag =
  (tagId: string) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          id: tagId,
          data: {
            linkedTo: null,
          },
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UNLINK_NFC_TAG,
      });

      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: `${i18n.t('nfcTagUnlinked')}`,
          severity: 'success',
        },
      });

      return response.data.updateNfcTag;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };
