import { MutableRefObject, useContext, useRef } from 'react';
import { PopperPlacementType } from '@mui/material';
import { ReactNode, createContext, useCallback, useState } from 'react';
import { mainContainerContextInitialValue } from '../constants/initialContext';

export interface OnBoardingTemplateRefs {
  templateTitleRef: MutableRefObject<HTMLDivElement>;
  addTaskRef: MutableRefObject<HTMLDivElement>;
  addInputRef: MutableRefObject<HTMLDivElement>;
  inputTitleRef: MutableRefObject<HTMLDivElement>;
  inputTypeRef: MutableRefObject<HTMLDivElement>;
  inputSettingsRef: MutableRefObject<HTMLDivElement>;
  taskHeaderRef: MutableRefObject<HTMLDivElement>;
  taskVariantsRef: MutableRefObject<HTMLDivElement>;
}

export interface OnBoardingCatalogRefs {
  catalogTitleRef: MutableRefObject<HTMLDivElement>;
  catalogTemplateRef: MutableRefObject<HTMLDivElement>;
  catalogAssigneeRef: MutableRefObject<HTMLDivElement>;
  catalogLabelsRef: MutableRefObject<HTMLDivElement>;
  catalogTasksRef: MutableRefObject<HTMLDivElement>;
  catalogTaskHeaderRef: MutableRefObject<HTMLDivElement>;
}

export interface OnBoardingExecutionRefs {
  executionStartRef: MutableRefObject<HTMLDivElement>;
  executionTaskListRef: MutableRefObject<HTMLDivElement>;
  executionTaskRef: MutableRefObject<HTMLDivElement>;
  executionPlusRef: MutableRefObject<HTMLDivElement>;
}
export interface MainContainerState {
  fullpage: boolean;
  blankPage: boolean;
  hideSteps: boolean;
  openModalIntroduction: boolean;
  openModalLeaveTutorial: boolean;
  anchorEl: HTMLDivElement;
  open: boolean;
  placement: PopperPlacementType;
  textDialog: { title: string; content: string; flag: string };
  doingOnBoarding: boolean;
  sidenavMinimized: boolean;
  openEndStepModal: boolean;
  openNoTaskAndInputModal: boolean;
  currentStep: string;
  openEndOnBoardingModal?: boolean;
  onboarding?: OnboardingState;
  templateRefs?: OnBoardingTemplateRefs;
  catalogsRefs?: OnBoardingCatalogRefs;
  executionRefs?: OnBoardingExecutionRefs;
  startSubSteps?: boolean;
  generateSubComponent?: string;
  headerLeftComponent: JSX.Element;
  headerRightComponent: JSX.Element;
  userPermissions?: {
    actions: 'viewer' | 'creator' | 'manager';
  };
  updateOnboardingState?: (newState: Partial<OnboardingState>) => void;
  updateMainContainerState?: (newData: Partial<MainContainerState>) => void;
  setMainContainerState?: React.Dispatch<React.SetStateAction<MainContainerState>>;
}

interface OnboardingState {}

interface MainContainerContextProviderProps {
  children: ReactNode;
  initialValue: MainContainerState;
}

const MainContainerContext = createContext<MainContainerState>(mainContainerContextInitialValue);

export const MainContainerContextProvider = ({
  children,
  initialValue = mainContainerContextInitialValue,
}: MainContainerContextProviderProps) => {
  const [mainContainerState, setMainContainerState] = useState<MainContainerState>(initialValue);
  const executionRefs: OnBoardingExecutionRefs = {
    executionStartRef: useRef<HTMLDivElement>(),
    executionTaskListRef: useRef<HTMLDivElement>(),
    executionTaskRef: useRef<HTMLDivElement>(),
    executionPlusRef: useRef<HTMLDivElement>(),
  };
  const templateRefs: OnBoardingTemplateRefs = {
    templateTitleRef: useRef<HTMLDivElement>(),
    addTaskRef: useRef<HTMLDivElement>(),
    addInputRef: useRef<HTMLDivElement>(),
    inputTitleRef: useRef<HTMLDivElement>(),
    inputTypeRef: useRef<HTMLDivElement>(),
    inputSettingsRef: useRef<HTMLDivElement>(),
    taskHeaderRef: useRef<HTMLDivElement>(),
    taskVariantsRef: useRef<HTMLDivElement>(),
  };
  const catalogsRefs: OnBoardingCatalogRefs = {
    catalogTitleRef: useRef<HTMLDivElement>(),
    catalogTemplateRef: useRef<HTMLDivElement>(),
    catalogAssigneeRef: useRef<HTMLDivElement>(),
    catalogLabelsRef: useRef<HTMLDivElement>(),
    catalogTasksRef: useRef<HTMLDivElement>(),
    catalogTaskHeaderRef: useRef<HTMLDivElement>(),
  };

  const updateMainContainerState = useCallback((newData: Partial<MainContainerState>) => {
    setMainContainerState((prevState) => ({ ...prevState, ...newData }));
  }, []);

  return (
    <MainContainerContext.Provider
      value={{
        ...mainContainerState,
        templateRefs,
        catalogsRefs,
        executionRefs,
        updateMainContainerState,
        setMainContainerState,
      }}
    >
      {children}
    </MainContainerContext.Provider>
  );
};

export const useMainContainerContext = () => {
  const context = useContext(MainContainerContext);
  if (!context) {
    throw new Error('useMainContext must be used within a MainContainerContext');
  }
  return context;
};
