import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    mainContainer: {
      width: '196px',
      height: '40px !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: '5px',
      cursor: 'pointer',
      padding: '8px',

      fontFamily: 'Open Sans',
      fontWeight: 400,
      color: theme.palette.text.primary,
      border: `1px solid ${theme.palette.grey.light}`,
      background: theme.palette.background.paper,
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
      '&:active': {
        borderColor: theme.palette.primary.main,
      },
    },
    popoverContainer: {
      width: '200px',
      maxHeight: '250px',
      // overflow: 'hidden',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    placeholder: {
      color: theme.palette.text.secondary,
    },
    betterFieldPopover: {
      width: '196px',
      marginBottom: 0,
    },
  });
