import { NexusGenInputs } from '@server/src/types';
import { APOLLO_CLIENT } from 'config/apollo.config';
import { Dispatch } from 'redux';
import {
  SkillCreateInput,
  SkillGroupCreateInput,
  SkillGroupOrderByInput,
  SkillLevelOrderByInput,
  SkillOrderByInput,
  SkillSubjectCreateInput,
} from 'src/gql/graphql';
import { getErrorObject } from 'src/utils/funcs';
import i18n from 'src/utils/translations/i18n';
import * as queries from './skills.queries';

export const getSkillSubjects =
  (where?: NexusGenInputs['SkillSubjectWhereInput'], limit?: number, skip?: number, orderBy?) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          where,
          limit,
          skip,
          orderBy,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_SKILL_SUBJECTS,
      });
      return response.data.skillSubjects;
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getSkillSubjectsCount =
  (where?: NexusGenInputs['SkillSubjectWhereInput']) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          where,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_SKILL_SUBJECTS_COUNT,
      });
      return response.data.skillSubjectsCount;
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getSkillSubject =
  (where?: NexusGenInputs['SkillSubjectWhereUniqueInput']) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          where,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_SKILL_SUBJECT,
      });
      return { data: response.data.skillSubject };
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return { error };
    }
  };

export const createSkillSubject = (data: NexusGenInputs['SkillSubjectCreateInput']) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        data,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_CREATE_SKILL_SUBJECT,
    });

    return resp.data.createSkillSubject;
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const updateSkillSubject =
  (id: string, data: Partial<SkillSubjectCreateInput>) => async (dispatch: Dispatch) => {
    try {
      const resp = await APOLLO_CLIENT.mutate({
        variables: {
          where: {
            _id: id,
          },
          data,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_SKILL_SUBJECT,
      });

      return { data: resp.data.updateSkillSubject };
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return { error };
    }
  };

export const deleteSkillSubject = (id: string) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        where: {
          _id: id,
        },
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_DELETE_SKILL_SUBJECT,
    });

    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: i18n.t('subjectDeleted'),
        severity: 'success',
      },
    });

    return { data: resp.data.deleteOneSkillSubject };
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return { error };
  }
};

export const deleteSkillSubjects = (ids: string[]) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        ids,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_DELETE_SKILL_SUBJECTS,
    });

    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: i18n.t('subjectsDeleted'),
        severity: 'success',
      },
    });

    return { data: resp.data.removeSkillSubjects };
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return { error };
  }
};

export const getSkillGroups =
  (
    where?: NexusGenInputs['SkillGroupWhereInput'],
    limit?: number,
    skip?: number,
    orderBy: SkillGroupOrderByInput = SkillGroupOrderByInput.name_ASC,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          where,
          limit,
          skip,
          orderBy,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_SKILL_GROUPS,
      });
      return { data: response.data.skillGroups };
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return { error };
    }
  };

export const getSkillGroup = (where?: NexusGenInputs['SkillGroupWhereUniqueInput']) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_SKILL_GROUP,
    });
    return { data: response.data.skillGroup };
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return { error };
  }
};

export const createSkillGroup = (data: SkillGroupCreateInput) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        data,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_CREATE_SKILL_GROUP,
    });

    return { data: resp.data.createSkillGroup };
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return { error };
  }
};

export const updateSkillGroup = (id: string, data: Partial<SkillGroupCreateInput>) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        where: {
          _id: id,
        },
        data,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_UPDATE_SKILL_GROUP,
    });

    return { data: resp.data.updateSkillGroup };
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return { error };
  }
};

export const deleteSkillGroup = (id: string) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        where: {
          _id: id,
        },
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_DELETE_SKILL_GROUP,
    });

    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: i18n.t('groupDeleted'),
        severity: 'success',
      },
    });

    return { data: resp.data.deleteOneSkillGroup };
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return { error };
  }
};

export const getSkills =
  (
    where?: NexusGenInputs['SkillWhereInput'],
    limit?: number,
    skip?: number,
    orderBy: SkillOrderByInput = SkillOrderByInput.name_ASC,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          where,
          limit,
          skip,
          orderBy,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_SKILLS,
      });
      return { data: response.data.skills };
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return { error };
    }
  };

export const getSkill = (where?: NexusGenInputs['SkillWhereUniqueInput']) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_SKILL,
    });
    return { data: response.data.skill };
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return { error };
  }
};

export const createSkill = (data: SkillCreateInput) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        data,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_CREATE_SKILL,
    });

    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: i18n.t('newSkillAdded'),
        severity: 'success',
      },
    });

    return { data: resp.data.createSkill };
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return { error };
  }
};

export const updateSkill = (id: string, data: Partial<SkillCreateInput>) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        where: {
          _id: id,
        },
        data,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_UPDATE_SKILL,
    });

    return { data: resp.data.updateSkill };
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return { error };
  }
};

export const deleteSkill = (id: string) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        where: {
          _id: id,
        },
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_DELETE_SKILL,
    });

    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: i18n.t('skillDeleted'),
        severity: 'success',
      },
    });

    return { data: resp.data.deleteOneSkill };
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return { error };
  }
};

export const getSkillLevels =
  (where?: NexusGenInputs['SkillLevelWhereInput'], limit?: number, skip?: number, orderBy?: SkillLevelOrderByInput) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          where,
          limit,
          skip,
          orderBy,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_SKILL_LEVELS,
      });
      return { data: response.data.skillLevels };
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getSkillLevelsCount = (where?: NexusGenInputs['SkillLevelWhereInput']) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_COUNT_SKILL_LEVELS,
    });
    return response.data.skillLevelsCount;
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const updateSkillLevel = (id: string, data: Partial<SkillCreateInput>) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        where: {
          _id: id,
        },
        data,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_UPDATE_SKILL_LEVEL,
    });

    return { data: resp.data.updateSkillLevel };
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return { error };
  }
};

export const createSkillLevels = (subjectId: string, accountIds: string[]) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        subjectId,
        accountIds,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_CREATE_SKILL_LEVELS,
    });

    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: `${accountIds.length} ${i18n.t('usersAddedToSubject')}`,
        severity: 'success',
      },
    });

    return { data: resp.data.createSkillLevels };
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return { error };
  }
};

export const getSkillLevelMembers =
  (subjectId: string, nameContains?: string, labelValuesIn?: string[], limit?: number, skip?: number) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          subjectId,
          nameContains,
          labelValuesIn,
          limit,
          skip,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_SKILL_LEVEL_MEMBERS,
      });
      return { data: response.data.getSkillLevelMembers };
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return { error };
    }
  };

export const removeSkillLevels = (subjectId: string, accountIds: string[]) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        subjectId,
        accountIds,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_REMOVE_SKILL_LEVELS,
    });

    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: `${i18n.t('usersRemovedFromSubject')}`,
        severity: 'success',
      },
    });

    return { data: resp.data.removeSkillLevels };
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return { error };
  }
};

export const getMatrixHeaderData =
  (where?: NexusGenInputs['SkillGroupWhereInput'], limit?: number, skip?: number, orderBy?) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          where,
          limit,
          skip,
          orderBy,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_MATRIX_HEADER,
      });
      return { data: response.data.skillGroups };
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return { error };
    }
  };

export const getMatrixData =
  (skillSubjectId: string, where?: NexusGenInputs['SkillMatrixWhere']) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          skillSubjectId,
          where,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_MATRIX_DATA,
      });
      return { data: response.data.getMatrixData };
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return { error };
    }
  };
