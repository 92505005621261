import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { TextField, TextFieldVariants } from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import { ChangeEvent, CSSProperties } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { styles } from './styles';

type CustomNumberInputProps = {
  hasErrors?: boolean;
  onChangeFunction: (e?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  value: number;
  placeholder: string;
  variant: TextFieldVariants;
  onClickToIncrease: () => void;
  onClickToDecrease: () => void;
  errorMessage?: string;
  minNumber?: number;
  maxNumber?: number;
  style?: CSSProperties;
  id: string;
} & WithTranslation &
  WithStyles<typeof styles>;

const CustomNumberInput = ({
  t,
  classes,
  hasErrors,
  value,
  placeholder,
  variant,
  errorMessage,
  minNumber,
  maxNumber,
  style,
  id,
  onChangeFunction,
  onClickToIncrease,
  onClickToDecrease,
}: CustomNumberInputProps) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', minWidth: '205px' }}>
      <TextField
        id={id}
        data-testid={id}
        inputProps={{
          type: 'number',
          className: classes.minMaxInput,
          min: minNumber,
          max: maxNumber,
        }}
        variant={variant}
        value={value}
        style={style}
        onChange={(e) => /^[0-9]*$/.test(e.target.value) && Number(e.target.value) <= maxNumber && onChangeFunction(e)}
        placeholder={placeholder}
        className={hasErrors ? `${classes.numberInput} ${classes.errorInput}` : classes.numberInput}
        InputProps={{
          endAdornment: (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <KeyboardArrowUp
                id='custom-input-arrow-up'
                onClick={() => onClickToIncrease()}
                style={{ fontSize: '16px', cursor: 'pointer' }}
              />
              <KeyboardArrowDown
                id='custom-input-arrow-down'
                onClick={() => onClickToDecrease()}
                style={{ fontSize: '16px', cursor: 'pointer' }}
              />
            </div>
          ),
        }}
      />
      {hasErrors && (
        <div id='custom-input-error-message' data-testid='custom-input-error-message' className={classes.errorText}>
          {t(`${errorMessage}`)}
        </div>
      )}
    </div>
  );
};

export default withTranslation('translation')(withStyles(styles)(CustomNumberInput));
