import { fromFlux } from '@influxdata/giraffe/dist';
import { Warning } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { useTheme, withStyles } from '@mui/styles';
import { sortBy } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Gauge from 'react-svg-gauge';
import { Cell, Pie, PieChart } from 'recharts';
import { styles } from 'src/modules/dashboard/widgets/gauge/styles/main';

type Range = {
  name: string;
  max: number;
  color: string;
};

const GaugeChartWidget = (props: any) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const chartContainerRef = useRef<HTMLDivElement>();
  const [dimensions, setDimensions] = useState({ width: 400, height: 500, cx: 200, cy: 250, iR: 100, oR: 150 });
  const RADIAN = Math.PI / 180;

  const currentValue = useMemo(
    () =>
      props.dataset.realtime
        ? props.result
        : props.result[0][props.result[0].name].value || props.result[0][props.result[0].name],
    [props.result],
  );
  const maxValue = useMemo(
    () =>
      props.dataset[props.dataType].ranges
        ? props.dataset[props.dataType].ranges[props.dataset[props.dataType].ranges.length - 1]?.max || 100
        : props.max || 100,
    [props.dataset[props.dataType]?.ranges, props.max],
  );

  const data = useMemo(() => {
    if (props.dataType === 'responsesLastValue' || props.dataType === 'responsesMediumValue') {
      return [
        {
          value: props.result[0][props.result[0].name].value,
          name: props.result[0].name,
        },
      ];
    } else if (props.dataType === 'realtime' && props.result?.length) {
      const { table } = fromFlux(props.result);
      const tagValue =
        table.columnKeys?.filter((k) => k === '_value (number)').length > 0 ? '_value (number)' : '_value';
      if (table.length > 0) {
        const _value = table.getColumn(tagValue);
        if (_value && _value[_value.length - 1]) {
          return [
            {
              name: '',
              value: Math.round(parseFloat(_value[_value.length - 1].toString()) * 100) / 100,
            },
          ];
        }
      }

      return [];
    } else {
      return (
        sortBy(props.result ?? [], 'name').map((inp) => {
          if (inp.metadata) {
            return {
              value: inp[inp.name],
              name: inp.metadata[inp.name].name || inp.metadata[inp.name].value,
            };
          } else {
            return {
              name: inp.name,
              value: inp[inp.name] || inp.count || 0,
            };
          }
        }) ?? []
      );
    }
  }, [props.result, props.dataType, props.showAsPercentage]);

  const color = useMemo(() => {
    if (data.length && data[0].value > props.minTarget?.value && data[0].value < props.target?.value) {
      return '#FFD279';
    } else if (data.length && data[0].value < props.minTarget?.value) {
      return props.minTarget.color;
    } else if (data.length && data[0].value > props.target?.value) {
      return props.target.color;
    } else {
      return theme.palette.primary.main;
    }
  }, [data, props.minTarget, props.target]);

  const formatValue = useCallback(
    (val: number) => {
      const symbol =
        props.dataset?.realtime?.filters[0]?.tags?.unit?.symbol ||
        props?.dataset[props.dataset.type]?.filter?.unit?.symbol ||
        '';
      return `${val} ${symbol}`;
    },
    [props.dataset],
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries.length) {
        const { width, height } = entries[0].contentRect;
        // Define here the position of the gauge
        const cx = width / 2;
        const cy = height / 1.6;
        // Alter this value (4.2) or the one bellow (2.4) to enlarge or make the gauge thinner
        const iR = Math.min(width, height) / 4.2;
        const oR = Math.min(width, height) / 2.4;

        setDimensions({ width, height, cx, cy, iR, oR });
      }
    });

    if (chartContainerRef.current) {
      resizeObserver.observe(chartContainerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const measureTextWidth = (text: string, fontSize = 14) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = `${fontSize}px Poppins`;
    return context.measureText(text).width;
  };

  const truncateText = useCallback(
    (text: string, maxWidth: number) => {
      const ellipsis = '...';
      let truncatedText = text;

      if (measureTextWidth(text) > maxWidth) {
        for (let i = text.length - 1; i > 0; i--) {
          truncatedText = text.substring(0, i) + ellipsis;
          if (measureTextWidth(truncatedText) < maxWidth) {
            break;
          }
        }
      }

      return truncatedText;
    },
    [dimensions],
  );

  const renderCustomizedLabel = ({ cx, cy, midAngle, outerRadius, index }) => {
    const radius = outerRadius * 1.1;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const label = truncateText(
      props.dataset[props.dataType].ranges[index].name,
      chartContainerRef?.current?.getBoundingClientRect().width / 5.3,
    );

    return (
      <Tooltip title={label.includes('...') ? props.dataset[props.dataType].ranges[index].name : null}>
        <text
          x={x}
          y={y}
          fill={theme.palette.text.primary}
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline='central'
          fontSize={14}
          style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
        >
          {label}
        </text>
      </Tooltip>
    );
  };

  /**
   *
   * @param value - value where the needle needs to point to
   * @param cx - center x of the gauge
   * @param cy - center y of the gauge
   * @param iR - inner radius of the gauge
   * @param oR - outer radius of the gauge
   * @param color - color of the needle
   * @returns
   */
  const needle = useCallback(
    (value: number, cx: number, cy: number, iR: number, oR: number) => {
      const total = maxValue || 1; // Use max value as the total
      const ang = value > maxValue ? 0 : 180 * (1 - value / total); // Angle where the needle should point to
      const length = (iR + 2.4 /* Increase this number to increase the length of the needle */ * oR) / 3; // Length of the needle
      const sin = Math.sin(-RADIAN * ang);
      const cos = Math.cos(-RADIAN * ang);
      const r = 2; // Radius of the base of the needle
      const x0 = cx; // Base x of the needle
      const y0 = cy; // Base y of the needle
      const xba = x0 + r * sin; // x coordinate for one side of the needle
      const yba = y0 - r * cos; // y coordinate for one side of the needle
      const xbb = x0 - r * sin; // x coordinate for the other side of the needle
      const ybb = y0 + r * cos; // y coordinate for the other side of the needle
      const xpa = x0 + length * cos + r * sin;
      const ypa = y0 + length * sin - r * cos;
      const xpb = x0 + length * cos - r * sin;
      const ypb = y0 + length * sin + r * cos;
      const xp = x0 + length * cos; // x coordinate for the tip of the needle
      const yp = y0 + length * sin; // y coordinate for the tip of the needle
      const color = value > maxValue ? theme.palette.error.main : theme.palette.text.primary;

      return (
        <>
          <circle cx={x0} cy={y0} r={r} fill={color} stroke='none' />
          <path d={`M${xba} ${yba}L${xbb} ${ybb} L${xpb} ${ypb} L${xpa} ${ypa} Z`} stroke='#none' fill={color} />
          <circle cx={xp} cy={yp} r={r} fill={color} stroke='none' />
        </>
      );
    },
    [maxValue],
  );

  const renderMinAndMaxLabels = useCallback(() => {
    const { cx, cy, iR, oR } = dimensions;

    // Midpoint between inner and outer radius
    const midR = (iR + oR) / 2;

    // Angle for start (180 degrees) and end (0 degrees)
    const startAngle = 180 * RADIAN;
    const endAngle = 0 * RADIAN;

    // Calculate positions
    const startX = cx + midR * Math.cos(startAngle);
    const startY = cy + midR * Math.sin(startAngle) + 20;

    const endX = cx + midR * Math.cos(endAngle);
    const endY = cy + midR * Math.sin(endAngle) + 20;

    const maxValueLabel = truncateText(`${maxValue}`, dimensions.oR - dimensions.iR);

    return (
      <>
        <text
          x={startX}
          y={startY} // Adjust this value to position below the arc
          fill={theme.palette.text.secondary}
          textAnchor='middle'
          dominantBaseline='central'
          fontSize={12}
        >
          {0}
        </text>
        <text
          x={endX}
          y={endY} // Adjust this value to position below the arc
          fill={theme.palette.text.secondary}
          textAnchor='middle'
          dominantBaseline='central'
          fontSize={12}
        >
          {maxValueLabel}
        </text>
      </>
    );
  }, [truncateText, maxValue]);
  return props.dataType !== 'realtime' ? (
    <div id={props.id} ref={chartContainerRef} style={{ width: '100%', height: '80%', position: 'relative' }}>
      <PieChart width={dimensions.width} height={dimensions.height}>
        <Pie
          dataKey='value'
          startAngle={180}
          endAngle={0}
          data={props.dataset[props.dataType].ranges.map((entry: Range, index: number) => ({
            name: entry.name,
            value: index > 0 ? entry.max - props.dataset[props.dataType].ranges[index - 1]?.max : entry.max, // Display the actual max value
            color: entry.color,
          }))}
          cx={dimensions.cx}
          cy={dimensions.cy}
          innerRadius={dimensions.iR}
          outerRadius={dimensions.oR}
          stroke='none'
          label={renderCustomizedLabel}
          labelLine={false}
        >
          {props.dataset[props.dataType].ranges.map((entry: Range, index: number) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        {needle(currentValue, dimensions.cx + 5, dimensions.cy, dimensions.iR, dimensions.oR)}
        {renderMinAndMaxLabels()}
      </PieChart>
      <div
        style={{
          position: 'absolute',
          left: '50%',
          top: `${dimensions.cy + dimensions.oR * 0.1}px`, // Position it under the gauge
          transform: 'translate(-50%, 0)',
          textAlign: 'center',
          fontSize: '16px',
          fontWeight: 600,
          color: currentValue > maxValue ? theme.palette.error.main : theme.palette.text.primary,
        }}
      >
        {formatValue(currentValue)}
      </div>
      {currentValue > maxValue && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '6px',
            transform: 'translate(0, -40%)',
          }}
        >
          <Warning color={'error'} fontSize={'small'} />
          <Typography style={{ fontSize: '12px', fontFamily: 'Open Sans', fontWeight: 400, lineHeight: 1.2 }}>
            {t('valueHigherThanMax')}
          </Typography>
        </div>
      )}
    </div>
  ) : (
    <svg id={props.id} width={'100%'} height={'100%'} viewBox={`0 0 250 250`}>
      <Gauge
        color={color}
        value={data.length > 0 ? data[0].value : 0}
        topLabelStyle={{ display: 'none', height: '0px', width: '0px' }}
        valueLabelStyle={{
          fontSize: '20px',
          fill: theme.palette.primary.main,
        }}
        width={250}
        height={250}
        min={props.min}
        label={
          (props.dataset?.realtime?.filters?.length && props.dataset?.realtime?.filters[0]?.tags?.unit?.symbol) ?? ''
        }
        max={maxValue || props.max}
        valueFormatter={formatValue}
      />
    </svg>
  );
};
export default withStyles(styles)(GaugeChartWidget);
