import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SocketStatus } from 'src/interfaces/remote-assistance/socket';
import { RoomUser } from 'src/interfaces/remote-assistance/types';
import { User } from 'src/interfaces/remote-assistance/types';
import RTCPeer from 'src/utils/remote-assistance/RTCPeer';
import { Session } from 'src/interfaces/remote-assistance/session';
import { Room } from 'src/interfaces/remote-assistance/room';

export interface Participant {
  id?: string;
  remote?: boolean;
  name?: string;
  rtcPeer?: RTCPeer;
}

export interface RoomData {
  data?: Room;
  error?: string;
  code?: string;
}

export interface SocketStateProps {
  name?: string;
  socketUsers?: Array<RoomUser>;
  socketUser?: RoomUser;
  users?: Array<User>;
  user?: User;
  roomParticipant?: Participant;
  meUid?: string;
  session?: Session;
  sessionToDelete?: string;
  roomParticipants?: Array<Participant>;
  socketStatus?: SocketStatus;
  assistedUser?: RoomUser;
  shareScreenUser?: RoomUser;
  meUser?: RoomUser;
}

const defaultState: SocketStateProps = {
  name: '',
  socketUsers: [],
  session: null,
  sessionToDelete: '',
  roomParticipants: [],
  socketStatus: SocketStatus.DISCONNECTED,
  assistedUser: null,
  shareScreenUser: null,
  meUser: null,
};

const setAssistedUser = (socketUsers: Array<RoomUser>): RoomUser | null => {
  if (socketUsers.length === 0) return null;
  return socketUsers.find((user) => user?.user_details?.video_track) ?? null;
};

const socketSlice = createSlice({
  name: 'socket',
  initialState: defaultState,
  reducers: {
    setName(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    setSocketStatus(state, action: PayloadAction<SocketStatus>) {
      state.socketStatus = action.payload;
    },
    setSession(state, action: PayloadAction<Session>) {
      state.session = action.payload;
    },
    setUserList(state, action: PayloadAction<{ socketUsers: Array<RoomUser>; meUid: string }>) {
      const { socketUsers, meUid } = action.payload;
      const usersCopy = socketUsers.map((user) => ({ ...user }));
      let count = 0;

      usersCopy.forEach((user, index) => {
        const alreadyExists =
          user.user_details.capabilities != null &&
          state.socketUsers.find(
            (socketUser) => socketUser.user_details?.capabilities?.rtc_uid === user.user_details?.capabilities?.rtc_uid,
          );

        if (alreadyExists) {
          user.idx = alreadyExists.idx;
        } else if (!user.user_details?.name) {
          while (count <= usersCopy.length) {
            count++;
            if (state.socketUsers.findIndex((user) => user.idx === count) === -1) {
              usersCopy[index] = { ...user, idx: count };
              break;
            }
          }
        }
      });

      const userSharingIdx = usersCopy.findIndex((user) => user.user_details.video_track === true);
      if (userSharingIdx > -1) {
        const [userSharing] = usersCopy.splice(userSharingIdx, 1);
        usersCopy.unshift(userSharing);
      }

      const myUserIdx = usersCopy.findIndex((user) => user.user_details.capabilities?.rtc_uid === meUid);
      if (myUserIdx > -1) {
        const [myUser] = usersCopy.splice(myUserIdx, 1);
        usersCopy.unshift(myUser);
      }

      state.socketUsers = usersCopy;
      state.assistedUser = setAssistedUser(usersCopy);
      state.meUser = usersCopy.find((user) => user.session_id === state.session?.id);
    },
    deleteUser(state, action: PayloadAction<string>) {
      const sessionToDelete = action.payload;
      const newUsers = state.socketUsers.filter((item) => item.session_id !== sessionToDelete);

      state.socketUsers = [...newUsers];
      state.assistedUser = setAssistedUser(newUsers);
      state.meUser = newUsers.find((user) => user.session_id === state.session?.id);
    },
    clearRoomData(state) {
      return { ...defaultState, socketStatus: state.socketStatus };
    },
    clearSocket() {
      return { ...defaultState };
    },
  },
});

export const { setName, setSocketStatus, setSession, setUserList, deleteUser, clearRoomData, clearSocket } =
  socketSlice.actions;

export default socketSlice.reducer;

export interface SocketDispatchProps {
  setName?: typeof setName;
  setSession?: typeof setSession;
  setUserList?: typeof setUserList;
  deleteUser?: typeof deleteUser;
  setSocketStatus?: typeof setSocketStatus;
  clearSocket?: typeof clearSocket;
  clearRoomData?: typeof clearRoomData;
}
