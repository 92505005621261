import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ScreenshotProps } from 'src/interfaces/remote-assistance/chat';
import { generateRandomId } from 'src/utils/remote-assistance/utilities';

export interface MeetingSidebarStateProps {
  chatDisplay?: boolean;
  showSnackbar?: boolean;
  galleryDisplay?: boolean;
  screenshots?: Array<ScreenshotProps>;
  screenshot?: ScreenshotProps | null;
  screenshotsId?: Array<string>;
  screenshotsToAttach?: Array<File> | null;
  unseenScreenshot?: boolean;
  showPreview?: boolean;
  participantsDisplay?: boolean;
  inviteParticipantsDisplay?: boolean;
  qrCodeDisplay?: boolean;
  status?: boolean;
}

const initialState: MeetingSidebarStateProps = {
  chatDisplay: false,
  showSnackbar: false,
  galleryDisplay: false,
  screenshots: [],
  screenshot: null,
  screenshotsToAttach: null,
  unseenScreenshot: false,
  showPreview: false,
  participantsDisplay: false,
  inviteParticipantsDisplay: false,
  qrCodeDisplay: false,
};

const meetingSidebarSlice = createSlice({
  name: 'meetingSidebar',
  initialState,
  reducers: {
    setChatDisplay(state, action: PayloadAction<boolean>) {
      state.chatDisplay = action.payload;
      state.galleryDisplay = false;
      state.participantsDisplay = false;
      state.inviteParticipantsDisplay = false;
      state.qrCodeDisplay = false;
    },
    setGalleryDisplay(state, action: PayloadAction<boolean>) {
      state.galleryDisplay = action.payload;
      state.chatDisplay = false;
      state.participantsDisplay = false;
      state.inviteParticipantsDisplay = false;
      state.qrCodeDisplay = false;
      state.unseenScreenshot = false;
      state.showPreview = false;
    },
    toggleParticipantsDisplay(state, action: PayloadAction<boolean>) {
      state.participantsDisplay = action.payload;
      state.galleryDisplay = false;
      state.chatDisplay = false;
      state.inviteParticipantsDisplay = false;
      state.qrCodeDisplay = false;
    },
    toggleInviteParticipantsDisplay(state, action: PayloadAction<boolean>) {
      state.inviteParticipantsDisplay = action.payload;
      state.chatDisplay = false;
      state.galleryDisplay = false;
      state.participantsDisplay = false;
      state.qrCodeDisplay = false;
    },
    toggleQrCodeDisplay(state, action: PayloadAction<boolean>) {
      state.qrCodeDisplay = action.payload;
      state.chatDisplay = false;
      state.galleryDisplay = false;
      state.participantsDisplay = false;
      state.inviteParticipantsDisplay = false;
    },
    showChatSnackbar(state, action: PayloadAction<boolean>) {
      state.showSnackbar = action.payload;
    },
    setUnseenScreenshot(state, action: PayloadAction<boolean>) {
      state.unseenScreenshot = action.payload;
    },
    addScreenshot(state, action: PayloadAction<string>) {
      const screenshot: ScreenshotProps = {
        date: new Date(),
        src: action.payload,
        id: generateRandomId(),
      };
      state.screenshots.unshift(screenshot);
      state.unseenScreenshot = !state.galleryDisplay;
      state.showPreview = !state.galleryDisplay;
    },
    deleteScreenshot(state, action: PayloadAction<Array<string>>) {
      const screenshotsId = action.payload;
      if (screenshotsId.length > 0) {
        state.screenshots = state.screenshots.filter((screenshot) => !screenshotsId.includes(screenshot.id));
      }
    },
    setScreenshotsToAttach(state, action: PayloadAction<Array<File> | null>) {
      state.screenshotsToAttach = action.payload;
    },
    showScreenshotPreview(state, action: PayloadAction<boolean>) {
      state.showPreview = action.payload;
    },
    clearGallery(state) {
      state.screenshots = [];
      state.unseenScreenshot = false;
    },
    exitSession() {
      return initialState;
    },
  },
});

export const {
  setChatDisplay,
  setGalleryDisplay,
  toggleParticipantsDisplay,
  toggleInviteParticipantsDisplay,
  toggleQrCodeDisplay,
  showChatSnackbar,
  setUnseenScreenshot,
  addScreenshot,
  deleteScreenshot,
  setScreenshotsToAttach,
  showScreenshotPreview,
  clearGallery,
  exitSession,
} = meetingSidebarSlice.actions;

export default meetingSidebarSlice.reducer;

export interface MeetingSidebarDispatchProps {
  setChatDisplay?: typeof setChatDisplay;
  showChatSnackbar?: typeof showChatSnackbar;
  setGalleryDisplay?: typeof setGalleryDisplay;
  setUnseenScreenshot?: typeof setUnseenScreenshot;
  addScreenshot?: typeof addScreenshot;
  deleteScreenshot?: typeof deleteScreenshot;
  setScreenshotsToAttach?: typeof setScreenshotsToAttach;
  showScreenshotPreview?: typeof showScreenshotPreview;
  clearGallery?: typeof clearGallery;
  toggleParticipantsDisplay?: typeof toggleParticipantsDisplay;
  toggleInviteParticipantsDisplay?: typeof toggleInviteParticipantsDisplay;
  toggleQrCodeDisplay?: typeof toggleQrCodeDisplay;
  exitSession?: typeof exitSession;
}
