import { Suspense, lazy } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';
import { ChatContextProvider, chatContextInitialValue } from './context/chat.context';

const ChatViewDefault = lazy(() => import('src/modules/chat/chat.view.default'));

export const render = {
  view: {
    default: ({ ...props }) => {
      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <ChatContextProvider initialValue={chatContextInitialValue}>
            <ChatViewDefault {...props} />
          </ChatContextProvider>
        </Suspense>
      );
    },
  },
};

export default render;
