import { Chip } from '@mui/material';
import { AnyAction, compose } from 'redux';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { styles } from 'src/utils/components/generic-input-card/styles';
import { Close, CallSplit, ReportProblemOutlined } from '@mui/icons-material';

const colors = ['#B468FF', '#00BF9D', '#FE8947', '#ACED24'];
const backgroundColors: string[] = [
  'rgba(180, 104, 255, 0.13)',
  'rgba(0, 191, 157, 0.13)',
  'rgba(254, 137, 71, 0.13)',
  'rgba(172, 237, 36, 0.13)',
];

const AlertChipExecution = (props: AnyAction): JSX.Element => {
  const { classes, input, setInput, active } = props;

  const showChip = (response, i): JSX.Element => {
    let color: number = i;
    if (input.type === 'selection' && response.createAlert) {
      return (
        <Chip
          icon={<CallSplit classes={{ root: classes.iconAlertChip }} />}
          label={
            <div className={classes.divLabel}>
              {props.t('if')}
              <span
                style={{
                  backgroundColor: backgroundColors[parseInt(`${color}`)],
                  color: colors[parseInt(`${color}`)],
                }}
                className={classes.firstSpanAlert}
              >
                {response.ifValues[0].label}
              </span>
              {props.t('selected')}
              <span className={classes.secondSpanAlert}>
                <ReportProblemOutlined style={{ fontSize: '15px' }} />
                {props.t('createAlert')}
              </span>
              {!active ? null : (
                <Close
                  id='buttonCloseConditional'
                  data-testid='buttonCloseConditional'
                  classes={{ root: classes.closeIcon }}
                  onClick={(): void => {
                    setInput({
                      _id: input._id,
                      [input.type]: {
                        ...input[input.type],
                        onResponse: input[input.type].onResponse.map((onRespon) =>
                          onRespon.ifValues[0].label === response.ifValues[0].label
                            ? { ...onRespon, createAlert: false }
                            : onRespon,
                        ),
                      },
                    });
                  }}
                />
              )}
            </div>
          }
          classes={{ root: /* props.error ? classes.advancedChipError : */ classes.alertChipExecution }}
        />
      );
    } else return <div />;
  };

  return (
    <div className={classes.alertChipExecContainer}>
      {input[input.type].onResponse
        ?.filter((r) => r.inputs?.length || r.actionRequired || r.createAlert || r.condition || r.finishIssue)
        .map((response, i) => showChip(response, i))}
    </div>
  );
};

export default compose<any>(withTranslation('translation'), withStyles(styles))(AlertChipExecution);
