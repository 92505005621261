import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      boxShadow: 'none',
      transition: 'all 0.3s, height 1ms',
      minHeight: '34px',
      border: `1px solid ${theme.palette.text.primary}`,
      color: theme.palette.text.primary,
      borderRadius: '5px',
      background: 'transparent',
      '&:hover': {
        border: `1px solid ${theme.palette.text.primary}`,
        color: theme.palette.text.primary,
      },
      '&:focus': {
        color: theme.palette.text.primary,
        border: '1px solid',
      },
    },
    containerError: {
      boxShadow: 'none',
      transition: 'all 0.3s, height 1ms',
      minHeight: '34px',
      border: `1px solid ${theme.palette.error.main}`,
      color: theme.palette.text.primary,
      borderRadius: '5px',
      background: 'transparent',
      '&:hover': {
        border: `1px solid ${theme.palette.error.main}`,
        color: theme.palette.text.primary,
      },
      '&:focus': {
        color: theme.palette.text.primary,
        border: '1px solid',
      },
    },
    containerNoBorder: {
      boxShadow: 'none',
      transition: 'all 0.3s, height 1ms',
      minHeight: '34px',
      color: theme.palette.text.primary,
      borderRadius: '5px',
      background: 'transparent',
      '&:hover': {
        color: theme.palette.text.primary,
      },
      '&:focus': {
        color: theme.palette.text.primary,
      },
    },
    uploadInput: {
      fontSize: '100px',
      position: 'absolute',
      left: '0',
      top: '0',
      opacity: '0',
      cursor: 'pointer',
    },
    row: {
      display: 'flex',
    },
    rowInstruction: {
      display: 'block',
      textAlign: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    icon: {
      paddingRight: '6px',
      paddingLeft: '6px',
      paddingTop: '6px',
    },
    text: {
      color: theme.palette.primary.main,
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: '16px',
      paddingTop: '6px',
    },
    textDisabled: {
      color: theme.palette.text.disabled,
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: '16px',
      paddingTop: '6px',
      '&:hover': {
        cursor: 'default',
      },
    },
    textInstruction: {
      paddingTop: '11px',
      color: theme.palette.success.main,
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontSize: '11px',
    },
    addIcon: {
      color: theme.palette.primary.main,
      fontSize: '21px',
    },
    addIconDisabled: {
      color: theme.palette.text.disabled,
      fontSize: '21px',
    },
    addIconInstruction: {
      color: theme.palette.success.main,
      fontSize: '44px',
    },
    uploadDiv: {
      cursor: 'pointer',
      position: 'relative',
      overflow: 'hidden',
      display: 'inline-block',
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '4px',
      paddingRight: '8px',
      marginLeft: 'auto',
      marginBottom: '9px',
      marginTop: '7px',
      marginRight: '12px',
    },
    uploadDivDisable: {
      cursor: 'pointer',
      position: 'relative',
      overflow: 'hidden',
      display: 'inline-block',
      border: `1px solid ${theme.palette.text.disabled}`,
      borderRadius: '4px',
      paddingRight: '8px',
      marginLeft: 'auto',
      marginBottom: '9px',
      marginTop: '7px',
      marginRight: '12px',
      '&:hover': {
        cursor: 'default',
      },
    },
    spanDelete: {
      width: '35px',
    },
    iconDownload: {
      fontSize: '20px',
    },
    iconDelete: {
      fontSize: '20px',
      fill: theme.palette.error.main,
    },
    table: {
      width: '100%',
    },
    disabled: {
      boxShadow: 'none',
      transition: 'all 0.3s, height 1ms',
      minHeight: '34px',
      border: `1px solid ${theme.palette.text.secondary}`,
      color: theme.palette.text.secondary,
      borderRadius: '5px',
    },
    inputCursorDisabled: {
      cursor: 'not-allowed',
    },
    inputCursorEnabled: {
      cursor: 'pointer',
    },
    insideForm: {
      display: 'inline-block',
      width: '100%',
    },
    infoLabel: {
      fontSize: theme.typography.fontSize,
      color: theme.palette.primary.main,
    },
    tr: {
      display: 'flex',
      width: '100%',
      overflow: 'auto',
    },
    rowName: {
      width: 'calc(100% - 0px)',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: theme.palette.text.primary,
      fontSize: '9px',
      fontWeight: 400,
      fontFamily: '"Poppins", sans-serif;',
    },
    spanDownload: {
      paddingLeft: '5px',
      paddingRight: '5px',
      cursor: 'pointer',
    },
    loadingDivMinHeight: {
      minHeight: '200px',
      width: '100%',
    },
    loadingDiv: {
      width: '100%',
    },
    img: {
      width: '107px',
      height: '112px',
      objectFit: 'contain',
    },
    imageTitle: {
      display: 'flex',
      marginTop: '5px',
      maxWidth: '107px',
      alignItems: 'center',
    },
    divContainerImages: {
      display: 'flex',
      flexWrap: 'wrap',
      overflowY: 'auto',
      cursor: 'pointer',
    },
    divImages: {
      height: 'min-content',
      marginTop: '12px',
      marginBottom: '14px',
    },
    divImageNotFound: {
      width: '107px',
      height: '112px',
      textAlign: 'center',
      fontFamily: 'Open Sans',
      backgroundColor: theme.palette.background.background1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconNotFound: {
      width: '80px',
      height: '80px',
      display: 'block',
      margin: 'auto',
      marginTop: '25px',
    },
    iconTypeFile: {
      width: '20px',
      height: '20px',
      marginRight: '3px',
    },
    deleteIcon: {
      color: theme.palette.error.main,
      fontSize: '14px',
    },
    divSize: {
      fontSize: '11px',
      fontWeight: 400,
      fontFamily: '"Poppins", sans-serif;',
    },
    attachIcon: {
      fontSize: '53px',
      color: theme.palette.primary.main,
    },
    videoIcon: {
      position: 'absolute',
      left: 'calc(50% - 20px)',
      color: theme.palette.common.white,
      fontSize: '40px',
      top: 'calc(50% - 20px)',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.common.white,
      minHeight: '200px',
      position: 'relative',
    },
    divWithIcon: {
      width: 'fit-content',
      '& > svg': {
        opacity: 0,
      },
      '&:hover': {
        '& > img': {
          background: 'rgba(0, 0, 0, 0.35)',
        },
        '& > svg': {
          opacity: '1 !important',
          cursor: 'pointer',
        },
      },
    },
  });
