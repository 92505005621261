import gql from 'graphql-tag';
import { LABEL_VALUE } from './label';

export const ACTION_TO_TABLE = gql`
  fragment actionToTable on Action {
    __typename
    name
    input
    issues {
      issue {
        name
        _id
        glarID
      }
      task
      input
    }
    _id
    ID
    assignedAccounts {
      __typename
      name
      email
      photo
      _id
      deleted
    }
    assignedLabelValues {
      ...labelValue
    }
    labelValues {
      ...labelValue
    }
    createdAt
    status
    description
    site {
      __typename
      name
      _id
      coordinates
      __typename
      parentsTree {
        _id
        name
      }
    }
    element {
      __typename
      name
      site {
        name
        coordinates
        _id
      }
      profile {
        name
        _id
      }
      parentsTree {
        name
        _id
      }
      _id
    }
    finishDate
    dueDate
    updatedAt
    updatedBy {
      name
      email
      photo
      _id
    }
    seenByIds
  }
  ${LABEL_VALUE}
`;

export const ACTION = gql`
  fragment action on Action {
    __typename
    name
    dueDate
    description
    isLocked
    lockedBy {
      name
      _id
    }
    issues {
      issue {
        name
        _id
        glarID
        closedAt
        deleted
        taskGroups {
          tasks {
            _id
            name
          }
        }
      }
      task
      input
    }
    input
    _id
    ID
    site {
      name
      _id
      coordinates
      parentsTreeIds
      __typename
    }
    assignedAccounts {
      __typename
      name
      email
      photo
      _id
      deleted
    }
    assignedLabelValues {
      ...labelValue
    }
    element {
      __typename
      name
      site {
        name
        coordinates
        parentsTreeIds
        _id
      }
      parentsTreeIds
      profile {
        name
        _id
      }
      parentsTree {
        name
        parentsTreeIds
        _id
      }
      _id
    }
    labelValues {
      ...labelValue
    }
    createdAt
    status
    log {
      type
      message
      date
      files {
        name
        _id
        extension
        accessToken
        size
        comment
        createdBy {
          name
        }
        updatedAt
        download {
          url
          validUntil
          thumbnail {
            url
            validUntil
          }
        }
        hasThumbnail
      }
      by {
        name
      }
      modified {
        name
        description
        status
        dueDate
        files {
          name
          _id
          extension
          accessToken
          size
          comment
          createdBy {
            name
          }
          updatedAt
          download {
            url
            validUntil
            thumbnail {
              url
              validUntil
            }
          }
          hasThumbnail
        }
        assignedAccounts {
          _id
          name
        }
        assignedLabelValues {
          _id
          value
        }
        labelValues {
          _id
          value
        }
        element {
          name
        }
        site {
          name
        }
        finishDate
      }
    }
    finishDate
  }
  ${LABEL_VALUE}
`;

export const ACTION_TO_ISSUE = gql`
  fragment actionToIssue on Action {
    __typename
    name
    input
    issues {
      issueId
      task
      input
    }
    _id
    ID
    assignedAccounts {
      __typename
      name
      email
      photo
      _id
      deleted
    }
    assignedLabelValues {
      ...labelValue
    }
    labelValues {
      ...labelValue
    }
    createdAt
    status
    description
    site {
      __typename
      name
      _id
      coordinates
      __typename
      parentsTree {
        _id
        name
      }
    }
    element {
      __typename
      name
      site {
        name
        coordinates
        _id
      }
      profile {
        name
        _id
      }
      parentsTree {
        name
        _id
      }
      _id
    }
  }
  ${LABEL_VALUE}
`;
