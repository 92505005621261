import { MainContainerState } from 'src/base/main-container/context/main.container.context';

/**
 * Used to start the on boarding experiance
 * @param props
 * @param updateMainContainerState
 * @param history
 */
export const startTutorial = async (
  props,
  updateMainContainerState: (newData: Partial<MainContainerState>) => void,
  navigate,
) => {
  const folders = await props.getIssueTemplateFolders({ name_eq: 'Drafts' }, 1, 0);
  if (folders?.length) {
    props.createIssueTemplate(folders[0]._id).then(async (temp) => {
      if (temp?.graphQLErrors) {
        return;
      }
      props.updateLoggedUser(
        {
          id: props.loggedUser._id,
          firstLogin: false,
          onBoarding: {
            ...props.loggedUser?.onBoarding,
            step: 'build',
            availableSteps: ['createIssue', 'createTasks', 'execute', 'finish'],
            finished: false,
            templateDraft: temp._id,
            template: null,
            openIssueDraft: null,
            openIssue: null,
            issueInstance: null,
            doingOnboarding: true,
            progressTotal: 0,
            currentSubStep: 0,
            maxSubStep: 7,
          },
        },
        false,
      );
      updateMainContainerState({ currentStep: 'build', doingOnBoarding: true, openEndOnBoardingModal: false });
      navigate(`/templates/issues/issue?id=${temp._id}&edit=true`);
    });
    updateMainContainerState({ hideSteps: false, open: true, startSubSteps: false });
  }
};
