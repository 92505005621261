import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { isMobile } from 'react-device-detect';

export const CustomDndProvider = ({ children }) => {
  if (isMobile) {
    return (
      <DndProvider key='TOUCH' backend={TouchBackend}>
        {children}
      </DndProvider>
    );
  }
  return (
    <DndProvider key='HTML5' backend={HTML5Backend}>
      {children}
    </DndProvider>
  );
};
