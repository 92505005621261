import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    closeIcon: {
      marginRight: '10px',
      marginTop: '10px',
      marginLeft: 'auto',
      cursor: 'pointer',
    },
    modalTitle: {
      fontWeight: 600,
      fontFamily: 'Open Sans',
      fontSize: '19px',
      width: '100%',
      textAlign: 'center',
      marginTop: '10px',
    },
    moreDetailsHeader: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '13px',
    },
    moreDetailsContainer: {
      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
      minHeight: '45px',
      paddingLeft: '15px',
      paddingRight: '15px',
      marginBottom: '20px',
    },
    moreDetailsHeaderDiv: {
      display: 'flex',
      height: '45px',
      alignItems: 'center',
    },
    expandIcon: {
      marginLeft: 'auto',
      cursor: 'pointer',
    },
    divFooter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '20px',
      margin: '16px 0px',
    },
    mainContent: {
      marginTop: '25px',
      paddingLeft: '30px',
      paddingRight: '30px',
      height: '365px',
      overflow: 'auto',
    },
    issueSpan: {
      fontFamily: 'Open Sans',
      fontWeight: 600,
      color: theme.palette.text.secondary,
    },
    issueNameSpan: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '17px',
    },
    chip: {
      color: theme.palette.background.default,
      marginRight: '10px',
      '& .MuiChip-label': {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '16px',
      },
    },
    statusPending: {
      color: '#272848 !important',
      backgroundColor: theme.palette.alert.main,
    },
    statusCantDo: {
      backgroundColor: theme.palette.error.main,
    },
    statusDoing: {
      backgroundColor: theme.palette.primary.main,
    },
    statusSolved: {
      backgroundColor: theme.palette.success.main,
    },
    formControl: {
      width: '100%',
      marginBottom: '16px',
      '& .MuiOutlinedInput-multiline': {
        padding: '16px',
        fontSize: '14px',
      },
      // focused color for input with variant='outlined'
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderWidth: '1px',
          borderColor: theme.palette.grey.light,
        },
        '&:hover fieldset': {
          borderWidth: '1px',
          borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused fieldset': {
          borderWidth: '1px',
          borderColor: theme.palette.primary.main,
        },
      },
    },
    fieldsLabel: {
      fontSize: '14px',
    },
    subTitle: {
      color: theme.palette.text.secondary,
      fontFamily: 'Open Sans',
      fontSize: '14x',
      marginTop: '16px',
      marginBottom: '16px',
      alignSelf: 'center',
    },
    actionListName: {
      color: theme.palette.text.primary,
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '18px',
    },
    nothingToShow: {
      fontFamily: 'Poppins',
      fontStyle: 'Italic',
      fontSize: '18px',
      color: theme.palette.text.disabled,
      height: 'inherit',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    btnLink: {
      marginTop: '4px',
      width: 'fit-content',
    },
    actionMainDiv: {
      padding: '8px 24px',
      cursor: 'pointer',
      border: `3px solid ${theme.palette.background.background1}`,
      borderBottom: 'none',
      display: 'flex',
      justifyContent: 'space-between',
    },
    lastActionMainDiv: {
      display: 'flex',
      padding: '8px 24px',
      cursor: 'pointer',
      border: `3px solid ${theme.palette.background.background1}`,
      justifyContent: 'space-between',
    },
    divRelatedAction: {
      display: 'flex',
      padding: '10px 24px',
      alignItems: 'flex-start',
      gap: '10px',
      alignSelf: 'stretch',
      background: theme.palette.background.background0,
    },
    dialogMainContainer: {
      width: '615px',
      height: '480px',
      display: 'flex',
      flexDirection: 'column',
    },
  });
