import { InputBase } from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import { useMemo, useState } from 'react';
import { styles } from './styles';
import SelectListItem, { ISelectListItem } from '../select-list-item';
import { KeyboardArrowLeft, Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
/* import { debounce } from 'lodash'; */

type SelectListProps = {
  initialOptions: ISelectListItem[];
  inputTypes?: string[];
  onChange: (item: ISelectListItem) => void;
} & WithStyles<typeof styles>;

const SelectList = ({ classes, initialOptions, inputTypes, onChange }: SelectListProps) => {
  const { t } = useTranslation();
  const [itemHistory, setItemHistory] = useState<ISelectListItem[]>([]);
  const [search, setSearch] = useState<string>();

  const options = useMemo(() => {
    let values = initialOptions;
    if (itemHistory?.length) {
      values = itemHistory[itemHistory.length - 1].childrens;
    }
    return values.filter((value) =>
      search ? value.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) : true,
    );
  }, [initialOptions, itemHistory, search]);

  /* const onSearch = debounce((value: string) => {
    setSearch(value);
  }, 500); */

  const handleItemClick = (item: ISelectListItem) => {
    const optionSelected = options.find((option) => option._id === item._id);
    setSearch('');
    if (!optionSelected?.isFinal) {
      setItemHistory([...itemHistory, optionSelected]);
    } else {
      onChange(item);
    }
  };

  const handleClickBack = () => {
    setSearch('');
    setItemHistory((prev) => prev.slice(0, -1));
  };

  return (
    <>
      <div>
        {itemHistory?.length ? (
          <div className={classes.backContainer} onClick={handleClickBack}>
            <KeyboardArrowLeft className={classes.arrowLeftIcon} />
            <span className={classes.spanParent}>{itemHistory[itemHistory.length - 1].name}</span>
          </div>
        ) : null}
        <div>
          <InputBase
            key={'better-dropdown-input'}
            data-testid={'better-dropdown-input'}
            fullWidth
            value={search}
            classes={{ root: classes.searchInput }}
            onChange={(e) => setSearch(e.currentTarget.value)}
            placeholder={t('search') + '...'}
            endAdornment={<Search className={classes.searchIcon} />}
          />
          <hr className={classes.divider} />
        </div>
      </div>
      <ul className={'MuiList-root MuiList-padding'} style={{ overflowY: 'auto', height: '100%', margin: 0 }}>
        {options?.map((taskTemplate) => (
          <SelectListItem
            key={taskTemplate._id}
            item={taskTemplate}
            inputTypes={inputTypes}
            onClick={handleItemClick}
          />
        ))}
      </ul>
    </>
  );
};

export default withStyles(styles)(SelectList);
