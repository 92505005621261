import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { getLoggedUser } from 'src/base/login/login.redux';
import { accountSetSite } from 'src/modules/account/account.redux';
import { render as Feed } from 'src/modules/feed';
import { render as Dashboard } from 'src/modules/dashboard';
import { render as Login } from 'src/base/login';
import { render as Site } from 'src/modules/site';
import { render as Archive } from 'src/modules/archive';
import { render as Account } from 'src/modules/account/index';
/* import { render as Alarm } from 'src/modules/alarm'; */
import { render as Alert } from 'src/modules/alert';
import { render as Device } from 'src/modules/device';
import { render as Element } from 'src/modules/element';
import { render as Label } from 'src/modules/label';
import { render as Role } from 'src/modules/role';
import { render as Profile } from 'src/modules/profile';
import { render as ResetPassword } from 'src/base/reset-password';
import { render as CreateAccount } from 'src/base/create-account';
import { render as ARImage } from 'src/modules/ar-image';
import { render as ModelLibrary } from 'src/modules/media-library';
import { render as Actions } from 'src/modules/actions';
import { render as Report } from 'src/modules/report';
import { render as Inventory } from 'src/modules/inventory';
import { render as Timesheet } from 'src/modules/timesheets';
import { render as LDAP } from 'src/modules/ldap';
import { render as SSO } from 'src/modules/sso';
import { render as ARMapping } from 'src/modules/ar-mapping';
import { render as IssueTemplates } from 'src/modules/issue-templates';
import { render as IssueHistory } from 'src/modules/issue-history';
import { render as IssueExecution } from 'src/modules/issue-execution';
import { render as IssueCatalogs } from 'src/modules/issue-catalogs';
import { render as Agenda } from 'src/modules/agenda';
import { render as Settings } from 'src/modules/settings';
import { render as Approvals } from 'src/modules/approvals';
import { render as Timesheets } from 'src/modules/timesheets';
import { render as Skills } from 'src/modules/skills';
import { render as Rooms } from 'src/modules/remote-assistance/rooms';
import { render as AgoraPage } from 'src/modules/remote-assistance/pages/meeting';
import { render as Chat } from 'src/modules/chat';
import i18n from 'src/utils/translations/i18n';
import ErrorBoundary from 'src/utils/other/errorHandling';
import queryString from 'query-string';
import { Backdrop, CircularProgress } from '@mui/material';
import HeatMap from 'src/utils/components/heat-map';
import EditTable from 'src/utils/components/heat-map/edit-table';
import InputReport from 'src/modules/issue-history/components/pdf-report';
import { logoutWithoutBack } from 'src/base/login/login.redux';
import TaskMaterialsTable from 'src/modules/issue-execution/components/task-materials-table';
import { omit } from 'lodash';
import { ACCESS_ASSIST_TOKEN, ACCESS_TOKEN } from 'config/apollo.config';
import { GlarState } from 'src/reducer-manager';
import { TenantsDefault } from 'src/modules/tenants/tenants';
import { TenantsAccounts } from 'src/modules/tenants/tenant-accounts';
import StaticDashboard from 'src/modules/dashboard/static-dashboard';
import { changeDefaultTenant } from 'src/modules/tenants/tenant.redux';
import VariantComparison from 'src/modules/issue-templates/components/variants-comparison';

type AllRoutesProps = ConnectedProps<typeof connector>;

const mapStateToProps = (state: GlarState) => ({
  defaultRoute: state.accountReducer.defaultRoute,
  loggedUser: state.loginReducer.loggedUser,
  theme: state.loginReducer.theme,
  tenantInfo: state.authenticationReducer.tenantInfo,
});

const mapDispatchToProps = {
  getLoggedUser,
  accountSetSite,
  logoutWithoutBack,
  changeDefaultTenant,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const AllRoutes: React.FC<AllRoutesProps> = (props) => {
  const { accountSetSite, loggedUser, tenantInfo, logoutWithoutBack, getLoggedUser } = props;
  const [loadingUser, setLoadingUser] = useState<boolean>(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const setDefSite = async (site): Promise<void> => {
    if (site) {
      await accountSetSite(omit(site, 'accessToSites'), false);
    } else {
      accountSetSite(null, false);
      localStorage.removeItem('current_site_id');
      localStorage.removeItem('current_site_name');
    }
  };

  useEffect(() => {
    const searchUrl = queryString.parse(location.search);
    if (searchUrl.access_token) {
      localStorage.setItem(ACCESS_TOKEN, searchUrl.access_token.toString());
      if (searchUrl?.access_assist_token) {
        localStorage.setItem(ACCESS_ASSIST_TOKEN, searchUrl.access_assist_token.toString());
      }
    }
    if (!loggedUser && localStorage.getItem('access_token')) {
      setLoadingUser(true);
      getLoggedUser().then((acc) => {
        if (acc.graphQLErrors) {
          return;
        }
        if (acc.preferences?.defaultSite) {
          setDefSite(acc.preferences.defaultSite);
        }
        if (acc.preferences?.locale) {
          i18n.changeLanguage(acc.preferences.locale.substring(0, 2));
        }
        setLoadingUser(false);
      });
    } else {
      if (loggedUser?.preferences.locale) {
        i18n.changeLanguage(loggedUser?.preferences.locale.substring(0, 2));
      }
      setDefSite(loggedUser?.preferences.defaultSite);
    }
  }, []);

  useEffect(() => {
    if (loggedUser?.preferences.locale) i18n.changeLanguage(loggedUser?.preferences.locale.substring(0, 2));
  }, [loggedUser?.preferences.locale]);

  useEffect(() => {
    if (loggedUser && !localStorage.getItem('access_token')) {
      logoutWithoutBack();
    }
  }, [loggedUser, localStorage]);

  useEffect(() => {
    if (location.pathname === '/select-site' && !localStorage.getItem('access_token')) navigate('/');
    else if (
      loggedUser &&
      !loggedUser.isPlatformAdmin &&
      location.pathname !== '/select-site' &&
      !localStorage.getItem('current_site_id') &&
      localStorage.getItem('access_token') &&
      !!loggedUser.roles?.length
    )
      navigate('/select-site');
  }, [location]);

  const isLoggedUser = useMemo(() => !!loggedUser?._id, [loggedUser]);

  const isAdminUser = useMemo(() => !!loggedUser?.isPlatformAdmin, [loggedUser]);

  return (
    <ErrorBoundary location={location}>
      {localStorage.getItem('access_token') ? (
        isLoggedUser && isAdminUser ? (
          <Routes>
            <Route index path='*' element={<StaticDashboard />} />
            <Route path='/dashboards' element={<StaticDashboard />} />
            <Route path='/tenants' element={<TenantsDefault />} />
            <Route path='/tenants/:tenant' element={<TenantsAccounts />} />
            <Route path='/tenants/:tenant/profile' element={<Account.profile location={location} />}></Route>
            <Route path='/tenants/:tenant/add-users' element={<Account.invite />} />
            <Route path='/tenants/:tenant/pending-invites' element={<Account.pending />} />
            <Route path='/resetPassword' element={<ResetPassword.reset.default location={location} />} />
            <Route path='/create-account' element={<CreateAccount.create.default location={location} />} />
            <Route path='/user-profile' element={<Account.profile location={location} />} />
          </Routes>
        ) : isLoggedUser ? (
          <Routes>
            <Route path='/feed/create-post' element={<Feed.create location={location} />} />
            <Route path='/feed' element={<Feed.view location={location} />} />
            <Route path='/dashboards' element={<Dashboard.view.default location={location} />} />
            {!localStorage.getItem('current_site_id') && localStorage.getItem('access_token') && (
              <Route path='/select-site' element={<Site.select.default location={location} />} />
            )}
            <Route path='/user-profile' element={<Account.profile location={location} />} />
            <Route path='/sites' element={<Site.create.default location={location} />} />
            <Route path='/templates/sites' element={<Site.create.template location={location} />} />
            <Route path='/archive/sites' element={<Archive.archive.site location={location} />} />
            <Route path='/scheduled' element={<IssueCatalogs.default location={location} />} />
            <Route path='/templates/issues/issue' element={<IssueTemplates.template location={location} />} />
            <Route path='/templates/issues/issue/comparison/:variant' element={<VariantComparison />} />
            <Route path='/templates/issues/library' element={<IssueTemplates.libraryTemplates location={location} />} />
            <Route path='/templates/issues' element={<IssueTemplates.templates location={location} />} />
            <Route path='/archive/issues' element={<Archive.archive.issue location={location} />} />
            <Route path='/templates' element={<IssueTemplates.templates location={location} />} />
            <Route path='/issues' element={<Agenda.default location={location} />} />
            <Route path='/open' element={<IssueCatalogs.default location={location} />} />
            <Route path='/execution' element={<IssueExecution.default location={location} />} />
            <Route path='/approval' element={<IssueExecution.default location={location} />} />
            <Route path='/planned' element={<IssueExecution.default location={location} />} />
            <Route path='/history' element={<IssueHistory.default location={location} />} />
            <Route path='/timesheets' element={<Timesheet.default location={location} />} />
            <Route path='/accounts-manager' element={<Account.create.default location={location} />} />
            <Route path='/accounts-manager/add-users' element={<Account.invite location={location} />} />
            <Route path='/accounts-manager/pending-invites' element={<Account.pending location={location} />} />
            <Route path='/accounts-manager/profile' element={<Account.profile location={location} />} />
            <Route path='/alerts' element={<Alert.view.default location={location} />} />
            <Route path='/alert' element={<Alert.create.default location={location} />} />
            <Route path='/devices' element={<Device.create.default location={location} />} />
            <Route path='/templates/elements' element={<Element.create.template location={location} />} />
            <Route path='/archive/elements' element={<Archive.archive.element location={location} />} />
            <Route path='/labels' element={<Label.create.default location={location} />} />
            <Route path='/roles' element={<Role.view.default location={location} />} />
            <Route path='/role' element={<Role.edit.default location={location} />} />
            <Route path='/profiles' element={<Profile.create.default location={location} />} />
            <Route path='/templates/profiles' element={<Profile.create.template location={location} />} />
            <Route path='/archive/profiles' element={<Archive.archive.profile location={location} />} />
            <Route path='/ar-image' element={<ARImage.view.default location={location} />} />
            <Route path='/media-library' element={<ModelLibrary.view.default location={location} />} />
            <Route path='/anchor' element={<ARImage.create.default location={location} />} />
            <Route path='/actions' element={<Actions.view.default location={location} />} />
            <Route path='/action' element={<Actions.create.default location={location} />} />
            <Route path='/issue-report' element={<Report.report.issue.default location={location} />} />
            <Route path='/site-element-report' element={<Report.report.siteElement.default location={location} />} />
            <Route path='/storages' element={<Inventory.view.storages location={location} />} />
            <Route path='/materials' element={<Inventory.create.material location={location} />} />
            <Route path='/tools' element={<Inventory.create.tool location={location} />} />
            {tenantInfo?.isRemoteAssistAllowed && (
              <>
                <Route path='/remote-assistance' element={<Rooms.default location={location} />} />
              </>
            )}
            <Route path='/session/:id' element={<AgoraPage.default location={location} />} />
            <Route path='/heat-map' element={<HeatMap location={location} />} />
            <Route path='/heat-map/edit-table' element={<EditTable location={location} />} />
            <Route path='/history/issue-report/:id/:detailed' element={<InputReport location={location} />} />
            <Route path='/ldap' element={<LDAP.create.default location={location} />} />
            <Route path='/samlsso' element={<SSO.create.default location={location} />} />
            <Route path='/work-packages' element={<Agenda.default location={location} />} />
            <Route path='/ar-mapping' element={<ARMapping.create.default location={location} />} />
            <Route path='/settings' element={<Settings.create.default location={location} />} />
            <Route path='/approvals' element={<Approvals.default location={location} />} />
            <Route path={'/execution/items'} element={<TaskMaterialsTable location={location} />} />
            <Route path={'/execution/:id/addTime'} element={<Timesheets.default location={location} />} />
            <Route path={'/execution/:issueName/addTool'} element={<Inventory.assign.tool location={location} />} />
            <Route path={'/tools/item'} element={<Inventory.view.toolItem location={location} />} />
            <Route path={'/skills'} element={<Skills.default location={location} />} />
            <Route path={'/skill'} element={<Skills.skill location={location} />} />
            <Route path={'/skills/members'} element={<Skills.members location={location} />} />
            <Route path={'/skills/:id/matrix'} element={<Skills.matrix location={location} />} />
            <Route path={'/skills/:subjectId/levels/:id'} element={<Skills.levels location={location} />} />
            <Route path={'/skills/:subjectId/subject'} element={<Skills.subjectLevels location={location} />} />
            <Route path={'/skills/:subjectId'} element={<Skills.skillGroups location={location} />} />
            <Route path={'/chat'} element={<Chat.view.default />} />
            <Route path='/resetPassword' element={<ResetPassword.reset.default location={location} />} />
            <Route path='/create-account' element={<CreateAccount.create.default location={location} />} />
            <Route index path='*' element={<Navigate to='/feed' />} />
            {/* <Navigate to='/' /> */}
          </Routes>
        ) : loadingUser ? null : (
          <Routes>
            <Route
              index
              path='*'
              element={
                <div
                  style={{
                    display: 'flex',
                    alignSelf: 'center',
                    height: 'calc(100% - 55px)',
                    position: 'absolute',
                    alignItems: 'center',
                    fontSize: '20px',
                    fontStyle: 'italic',
                    fontWeight: 600,
                    opacity: 0.7,
                  }}
                >
                  {t('noPermission')}
                </div>
              }
            />
          </Routes>
        )
      ) : (
        <Routes>
          <Route index path='*' element={<Login.view.default location={location} />} />
          <Route
            path='/sso'
            element={
              <Backdrop open={true}>
                <CircularProgress color='inherit' />
              </Backdrop>
            }
          />
          <Route path='/resetPassword' element={<ResetPassword.reset.default location={location} />} />
          <Route path='/create-account' element={<CreateAccount.create.default location={location} />} />
        </Routes>
      )}
    </ErrorBoundary>
  );
};

export const render = {
  view: {
    default: connector(AllRoutes),
  },
};

export default render;
