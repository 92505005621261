import gql from 'graphql-tag';
import { ACTION_TO_TABLE, ACTION, ACTION_TO_ISSUE } from 'src/utils/fragments/actions';
import { LABEL_VALUE } from 'src/utils/fragments/label';

export const Q_GET_ACTIONS = gql`
  query GetActions($where: ActionWhereInput, $limit: Int, $skip: Int, $orderBy: [ActionOrderByInput]) {
    actions(where: $where, orderBy: $orderBy, limit: $limit, skip: $skip) {
      ...actionToTable
    }
  }
  ${ACTION_TO_TABLE}
`;

export const Q_GET_ACTIONS_COUNT = gql`
  query GetActionsCount($where: ActionWhereInput) {
    actionsCount(where: $where)
  }
`;

export const Q_GET_ACTIONS_COUNT_MULTIPLE = gql`
  query ActionscountMultiple($where: [ActionWhereInput!]!) {
    actionsCountMultiple(where: $where) {
      count
    }
  }
`;

export const M_CREATE_ACTION = gql`
  mutation CreateAction($data: ActionCreateInput!) {
    createAction(data: $data) {
      ...action
    }
  }
  ${ACTION}
`;

export const M_CREATE_ACTION_DRAFT = gql`
  mutation CreateActionDraft($data: ActionDraftCreateInput!) {
    createActionDraft(data: $data) {
      __typename
      name
      _id
    }
  }
`;

export const Q_GET_INPUT_NAME = gql`
  query GetInputName($_id: ID!, $taskId: ID!) {
    executionInput(_id: $_id, taskId: $taskId) {
      _id
      name
    }
  }
`;

export const Q_GET_ACTION = gql`
  query GetAction($where: ActionWhereUniqueInput!) {
    action(where: $where) {
      ...action
    }
  }
  ${ACTION}
`;

export const M_UPDATE_ACTION = gql`
  mutation UpdateAction($where: ActionWhereUniqueInput!, $data: ActionUpdateInput!) {
    updateAction(where: $where, data: $data) {
      ...action
    }
  }
  ${ACTION}
`;

export const M_LINK_INPUTS_TO_MANY_ACTIONS = gql`
  mutation LinkInputsToManyActions($data: [ActionIssuesMultipleUpdateInput!]!) {
    linkInputsToManyActions(data: $data) {
      ...action
    }
  }
  ${ACTION}
`;

export const M_UPDATE_ACTION_DRAFT = gql`
  mutation UpdateActionDraft($where: ActionDraftWhereUniqueInput!, $data: ActionDraftUpdateInput!) {
    updateActionDraft(where: $where, data: $data) {
      __typename
      name
      _id
    }
  }
`;

export const M_ADD_LOG_MESSAGE = gql`
  mutation AddLogMessage($where: ActionWhereUniqueInput!, $message: String!, $files: [ID]) {
    addLogMessageToAction(where: $where, message: $message, files: $files) {
      ...action
    }
  }
  ${ACTION}
`;

export const Q_GET_DISTINCT_ACTION_VALUES = gql`
  query GetDistinctActionValues {
    distinctActionValues {
      names
      labelValues {
        ...labelValue
      }
      issues {
        name
        _id
      }
      assignedAccounts {
        name
        _id
      }
      lastUpdater {
        name
        _id
      }
      sites {
        name
        _id
        parentsTree {
          _id
          name
        }
        parent {
          _id
          name
        }
      }
      elements {
        name
        _id
        parentsTree {
          _id
          name
        }
        parent {
          _id
          name
        }
      }
      rootSites {
        name
        _id
      }
      rootElements {
        name
        _id
      }
      parentSites {
        name
        _id
      }
      parentElements {
        name
        _id
      }
      status
      ID
    }
  }
  ${LABEL_VALUE}
`;

export const M_DELETE_ACTION = gql`
  mutation DeleteAction($id: ID!) {
    deleteOneAction(where: { _id: $id }) {
      ...action
    }
  }
  ${ACTION}
`;

export const M_DELETE_ACTION_DRAFT = gql`
  mutation DeleteOneActionDraft($id: ID!) {
    deleteOneActionDraft(where: { _id: $id }) {
      __typename
      name
      _id
    }
  }
`;

export const Q_GET_ACTION_DRAFT = gql`
  query ActionDraft($where: ActionDraftWhereUniqueInput!) {
    actionDraft(where: $where) {
      __typename
      name
      dueDate {
        amount
        timeUnit
      }
      _id
      assignedAccounts {
        name
        _id
        photo
      }
      assignedLabelValues {
        ...labelValue
      }
      labelValues {
        ...labelValue
      }
    }
  }
  ${LABEL_VALUE}
`;

export const M_DELETE_MANY_ACTION_DRAFTS = gql`
  mutation DeleteManyActionDrafts($ids: [ID]) {
    deleteManyActionDrafts(_ids: $ids)
  }
`;

gql`
  query GetActionToIssue($where: ActionWhereInput, $limit: Int, $skip: Int, $orderBy: [ActionOrderByInput]) {
    actions(where: $where, orderBy: $orderBy, limit: $limit, skip: $skip) {
      ...actionToIssue
    }
  }
  ${ACTION_TO_ISSUE}
`;
