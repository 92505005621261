import { withStyles } from '@mui/styles';
import { pick, sortBy } from 'lodash';
import { useMemo, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Bar, BarChart, CartesianGrid, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import {
  calculateTotal,
  convertToPercentage,
  formatTimeUnit,
  percentageFormatter,
  renderPercentage,
  renderValue,
} from '../../widget-helper';
import styles from './helpers/styles';
import { run } from 'src/utils/funcs';

const BarChartWidget = (props: any) => {
  const { t } = useTranslation();

  const xLabel = useMemo(() => {
    if (props.groupBy) {
      return `${t(`editWidgetBy.${props.by}`)} (${t(props.groupBy + 's')})`;
    }
    if (props.by === undefined) return '';
    return t(`editWidgetBy.${props.by}`);
  }, [props.groupBy, props.by]);

  const yLabel = useMemo(() => {
    const timeUnit = props.dataset[props.dataType].timeUnit ? t(props.dataset[props.dataType].timeUnit + 's') : null;

    const yLabelText = props.dataType === 'responsesTotalValues' ? 'responsesCount' : props.dataType;

    return `${t(yLabelText)} ${timeUnit ?? ''}`.trim();
  }, [props.dataType, props.dataset]);

  const total = useMemo(() => calculateTotal(props.result), [props.result, props.showAsPercentage]);

  const data = useMemo(() => {
    if (props.result?.length) {
      const resultsToBeMapped = sortBy(props.result, 'name');
      return resultsToBeMapped.map((inp, index) => ({
        name: inp.name,
        value: props.showAsPercentage ? convertToPercentage(inp.count, total, props.showAsPercentage) : inp.count,
        color: props.palette[index % props.palette.length],
      }));
    }
    return [];
  }, [props.result, props.showAsPercentage, total]);

  return props.dataType === 'realtime' ? (
    <div />
  ) : (
    <ResponsiveContainer id={props.id} width={'100%'} height='100%'>
      <BarChart width={730} height={250} data={data}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis
          tickFormatter={(value) => {
            if (!data) {
              return null;
            }
            return formatTimeUnit(value, null);
          }}
          dataKey={'name'}
          interval={0}
        >
          <Label position={'insideBottom'} value={xLabel} />
        </XAxis>
        <YAxis
          dataKey={'value'}
          tickFormatter={percentageFormatter(props.showAsPercentage, props.dataType === 'responsesTotal', total)}
          allowDecimals={false}
        >
          <Label angle={270} value={yLabel} />
        </YAxis>
        <Tooltip
          content={({ active, payload, label }) => {
            const lbl = run(() => {
              if (props.groupBy) {
                return formatTimeUnit(label, props.groupBy);
              }
              return label;
            });

            if (!active || !payload?.length) {
              return null;
            }

            return (
              <div className={props.classes.tooltip}>
                <span>
                  {props.dataType !== 'responsesTotal'
                    ? renderPercentage(parseInt(payload[0].value.toString()), props.showAsPercentage)
                    : renderValue(parseInt(payload[0].value.toString()), total, props.showAsPercentage)}{' '}
                  {yLabel}
                </span>
                <span>{lbl}</span>
              </div>
            );
          }}
          /*labelFormatter={(label) => {
        }}
        formatter={(value) => }*/
        />
        <Bar
          isAnimationActive={false}
          dataKey={'value'}
          shape={({ payload, ...rest }) => <rect {...pick(rest, ['x', 'y', 'width', 'height'])} fill={payload.color} />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
export default withStyles(styles)(memo(BarChartWidget));
