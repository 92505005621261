import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    '@global': {
      '.MuiListItem-root.Mui-selected': {
        /* borderLeft: `2px solid ${theme.palette.primary.main}`, */
      },
    },
    nested: {
      paddingLeft: theme.spacing(2),
      '& span': {
        fontFamily: '"Open Sans", sans-serif !important;',
      },
      backgroundColor: `${theme.palette.background.background1} !important`,
    },
    list: {
      paddingTop: 0,
      maxHeight: '100%',
      overflow: 'auto',
      marginBottom: '55px',
    },
    badge: {
      height: '20px',
      display: 'flex',
      padding: '0 6px',
      zIndex: 0,
      marginRight: '30px',
      /* position: "absolute", */
      flexWrap: 'wrap',
      fontSize: '0.75rem',
      minWidth: '20px',
      boxSizing: 'border-box',
      alignItems: 'center',
      fontWeight: 500,
      alignContent: 'center',
      borderRadius: '10px',
      flexDirection: 'row',
      justifyContent: 'center',
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
    },
    badgeBeta: {
      backgroundColor: theme.palette.other.purpleDark,
      color: theme.palette.common.white,
      fontWeight: 700,
      marginRight: '13px',
      width: '51px',
    },
    active: {
      fill: theme.palette.primary.main,
      color: theme.palette.primary.main,
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
    paper: {
      height: '100%',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      zIndex: 60,
      width: '270px',
      webkitTransition: 'all .5s cubic-bezier(.685,.0473,.346,1)',
      transition: 'all .5s cubic-bezier(.685,.0473,.346,1)',
      color: `${theme.palette.mode === 'light' ? theme.palette.secondary.main : theme.palette.common.white} !important`,
      paddingTop: '55px',
      position: 'fixed',
      boxShadow: 'none',
      borderRight: `1px solid ${theme.palette.text.disabled}`,
      backgroundColor: theme.palette.background.default,
    },
    arrowButton: {
      color: `${theme.palette.mode === 'light' ? theme.palette.secondary.main : theme.palette.common.white} !important`,
      position: 'absolute',
      bottom: '3px',
      right: '5px',
      '& .MuiButton-label': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    item: {
      color: theme.palette.text.primary,
    },
  });
