import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
      width: '320px',
      minWidth: '320px',
    },
    popoverContent: {
      pointerEvents: 'auto',
      padding: '6px 16px',
      paddingBottom: '0',
      width: '320px',
      minWidth: '320px',
    },
    searchInput: {
      fontSize: '16px',
    },
    searchIcon: {
      color: theme.palette.grey.light,
    },
    divider: {
      margin: '0 -16px',
    },
    responsesHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '70px',
      marginTop: '10px',
    },
    responsesTitle: {
      color: theme.palette.text.disabled,
      fontSize: '14px',
      fontWeight: 400,
    },
    responsesAdd: {
      fontSize: '14px',
      fontWeight: 400,
    },
    responsesContainer: {
      margin: '0 -16px',
      overflowY: 'auto',
      overflowX: 'hidden',
      maxHeight: '200px',
    },
    addResponsesBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: '4px',
      color: theme.palette.primary.main,
      margin: '0 -16px',
      paddingLeft: '16px',
      height: '40px',
      '&:hover': {
        cursor: 'pointer',
        background: theme.palette.primary.light,
      },
    },
    customResponseChip: {
      margin: '16px 0',
      color: theme.palette.primary.main,
      background: theme.palette.background.background1,
      cursor: 'pointer',
    },
    customResponseChipLabel: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '4px',
      fontSize: '12px',
    },
    customResponseText: {
      fontSize: '12px',
    },
    customResponseChipContainer: {
      margin: '0 -16px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingLeft: '16px',
      '&:hover': {
        cursor: 'pointer',
        background: theme.palette.primary.light,
      },
    },
  });
