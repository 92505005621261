// Get number of selected items per column
const getOptionsLengthPerColumn = (column) => {
  const columnOptions = [];
  column.map((item) => (item.labelValue ? columnOptions.push(item) : null));

  return columnOptions.length;
};

// Check if all columns have at least 1 option selected
const checkColumnsOptionsPresence = (columns) => {
  let columnsHaveOptions = true;
  columns.forEach((column) => {
    const optionsLengthPerColumn = getOptionsLengthPerColumn(column);
    if (optionsLengthPerColumn < 1) {
      columnsHaveOptions = false;
      return;
    }
  });

  return columnsHaveOptions;
};

// Check if all columns have at least 1 option selected
const checkLinesOptionsPresence = (columns) => {
  let linesHaveOptions = true;
  const numberOfLines = columns[0].length;
  for (let i = 0; i < numberOfLines; i++) {
    let numberOfItemsPerLine = 0;

    columns.forEach((column) => {
      if (column[i].labelValue) numberOfItemsPerLine++;
    });

    if (numberOfItemsPerLine < 1) return (linesHaveOptions = false);
  }

  return linesHaveOptions;
};

// Reorder items of columns after move label groups
const reorderItemsOfColumns = (columns, from: number, to: number) => {
  const auxColumnsArr = [];
  columns.map((column) => {
    const item = column.splice(from, 1)[0];
    column.splice(to, 0, item);
    auxColumnsArr.push(column);
  });

  return auxColumnsArr;
};

// Update Columns after change label group
const updateColumnsAfterChangeLabelGroup = (columns, selectedLabelGroupIdx) => {
  const updatedColumns = [];
  columns.map((column) =>
    updatedColumns.push(
      column.map((item, i) => (i === selectedLabelGroupIdx ? { label: null, labelValue: null } : item)),
    ),
  );

  return updatedColumns;
};

// Extract data of all selected label values
const extractSelectedLabelValues = (values) => {
  const labelValuesAux = [];
  values.map((column) => labelValuesAux.push(...column.labelValues));

  return labelValuesAux;
};

// Get the name of all selected labels (not label values)
const getLabelsNames = (cols) => {
  const names = [];
  const selectedLabelValues = extractSelectedLabelValues(cols);

  for (let i = 0; i < cols[0].labelValuesIds.length; i++) {
    const labelValueId = cols.find((col) => col.labelValuesIds[i])?.labelValuesIds[i];
    const labelName = selectedLabelValues.find((lv) => lv?._id === labelValueId)?.label?.name;

    if (labelName) names.push(labelName);
  }

  return names;
};

const getStyledTableCellColorFilter = (
  colorFilters,
  val,
  taskTime,
  choosedGroups,
  choosedColumns,
  group,
  cIdx,
  theme,
) => {
  return colorFilters?.length > 0 && taskTime !== null
    ? colorFilters.find(
        (filter) => (val.time / taskTime) * 100 >= filter.min && (val.time / taskTime) * 100 <= filter.max,
      )
      ? {
          background: colorFilters.find(
            (filter) => (val.time / taskTime) * 100 >= filter.min && (val.time / taskTime) * 100 <= filter.max,
          ).color,
        }
      : {
          background:
            choosedGroups.includes(group._id) || choosedColumns.find((e) => e.idx === cIdx)
              ? theme.palette.primary.light
              : '',
          color:
            choosedGroups.includes(group._id) || choosedColumns.find((e) => e.idx === cIdx)
              ? theme.palette.primary.main
              : '',
          border:
            choosedGroups.includes(group._id) || choosedColumns.find((e) => e.idx === cIdx)
              ? `1px solid ${theme.palette.primary.main}`
              : '',
        }
    : {
        background:
          choosedGroups.includes(group._id) || choosedColumns.find((e) => e.idx === cIdx)
            ? theme.palette.primary.light
            : '',
        color:
          choosedGroups.includes(group._id) || choosedColumns.find((e) => e.idx === cIdx)
            ? theme.palette.primary.main
            : '',
        border:
          choosedGroups.includes(group._id) || choosedColumns.find((e) => e.idx === cIdx)
            ? `1px solid ${theme.palette.primary.main}`
            : '',
      };
};

export {
  checkColumnsOptionsPresence,
  checkLinesOptionsPresence,
  getOptionsLengthPerColumn,
  reorderItemsOfColumns,
  updateColumnsAfterChangeLabelGroup,
  extractSelectedLabelValues,
  getLabelsNames,
  getStyledTableCellColorFilter,
};
