import { ExpandMore } from '@mui/icons-material';
import { Popover } from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import { useEffect, useRef, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { IssueCatalog } from 'src/gql/graphql';
import { getIssueCatalogsToPreview } from 'src/modules/issue-catalogs/issue.catalogs.redux';
import SelectList from './components/select-list';
import SelectListItem, { ISelectListItem } from './components/select-list-item';
import SelectResponseList from './components/select-response-list';
import { getDefaultItem, issueCatalogToSelectListItem } from './funcs/issueCatalogToSelectListItem.func';
import { styles } from './styles';

type ExecutionInputSelectProps = {
  'data-testid': string;

  issueCatalogId?: string;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: string;
  onChange?: (id: ISelectListItem) => void;
  responseOptions?: ISelectListItem[];
  setResponseOptions?: (opts: ISelectListItem[]) => void;
  inputTypes?: string[];
  selectedInputForResponse?: ISelectListItem;
  edit?: boolean;
} & WithTranslation &
  WithStyles<typeof styles> &
  ConnectedProps<typeof connector>;

const ExecutionInputSelect = ({
  'data-testid': dataTestID,

  classes,
  placeholder,
  issueCatalogId,
  disabled,
  defaultValue,
  inputTypes,
  onChange,
  getIssueCatalogsToPreview,
  responseOptions,
  setResponseOptions,
  selectedInputForResponse,
  edit,
}: ExecutionInputSelectProps) => {
  const anchorRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);
  const [issueCatalog, setIssueCatalog] = useState<IssueCatalog>();
  const [selectedItem, setSelectedItem] = useState<ISelectListItem>();
  const [isEditing, setIsEditing] = useState<boolean>(edit);

  useEffect(() => {
    setSelectedItem(undefined);
    if (issueCatalogId) {
      getIssueCatalogData(issueCatalogId);
    }
  }, [issueCatalogId]);

  useEffect(() => {
    onChange?.(selectedItem);
  }, [selectedItem]);

  useEffect(() => {
    setSelectedItem(getDefaultItem(issueCatalog, defaultValue));
  }, [issueCatalog, defaultValue]);

  useEffect(() => {
    if (inputTypes?.includes('response') && selectedInputForResponse) {
      if (isEditing) {
        setIsEditing(false);
        const tmpResponses = issueCatalogToSelectListItem(issueCatalog, true, selectedInputForResponse);
        const findOptions = tmpResponses.map((option) => {
          const optionSelected = responseOptions.some((previousOption) => previousOption.name === option.name);
          return optionSelected ? { ...option, isSelected: true } : { ...option, isSelected: false };
        });
        setResponseOptions(findOptions);
      } else setResponseOptions?.(issueCatalogToSelectListItem(issueCatalog, true, selectedInputForResponse));
    }
  }, [selectedInputForResponse]);

  const getIssueCatalogData = async (id: string) => {
    const data = await getIssueCatalogsToPreview({ _id: id });
    setIssueCatalog(data);
  };

  const handleOpen = () => {
    if (disabled) return;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        data-testid={`${dataTestID ?? 'generic-input-id'}`}
        className={`${classes.mainContainer} ${disabled ? classes.disabled : classes.enabled}`}
        ref={anchorRef}
        onClick={handleOpen}
      >
        {inputTypes?.includes('response') ? (
          <span>
            {responseOptions?.every((o) => o.isSelected)
              ? placeholder
              : responseOptions?.filter((o) => o.isSelected)?.map((o) => o.name + '; ')}
          </span>
        ) : (
          <span>{selectedItem ? <SelectListItem item={selectedItem} noHover={true} /> : placeholder}</span>
        )}
        <ExpandMore />
      </div>
      <Popover
        anchorEl={anchorRef.current}
        open={open}
        classes={{ paper: classes.popoverContainer }}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{
          style: {
            width: anchorRef.current?.getBoundingClientRect().width,
          },
        }}
      >
        {inputTypes?.includes('response') ? (
          <SelectResponseList options={responseOptions} setOptions={setResponseOptions}></SelectResponseList>
        ) : (
          <SelectList
            initialOptions={issueCatalogToSelectListItem(issueCatalog, true, selectedInputForResponse)}
            onChange={(item) => {
              setSelectedItem(item);
              setOpen(false);
            }}
            inputTypes={inputTypes}
          />
        )}
      </Popover>
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  getIssueCatalogsToPreview,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default compose<any>(withTranslation('translation'), connector, withStyles(styles))(ExecutionInputSelect);
