import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';

const SettingsDefaultRefresh = () => lazy(() => import('src/modules/settings/settings.default'));

let SettingsDefault = SettingsDefaultRefresh();

export const render = {
  create: {
    default: ({ ...props }) => {
      if (SettingsDefault?._result?.type === 'error') {
        SettingsDefault = SettingsDefaultRefresh();
      }
      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <SettingsDefault {...props} />
        </Suspense>
      );
    },
  },
};

export default render;
