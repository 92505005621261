import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';

const IssueCatalogsRefresh = () => lazy(() => import('src/modules/issue-catalogs/issue.catalogs'));

let IssueCatalogs = IssueCatalogsRefresh();

export const render = {
  default: (props) => {
    if (IssueCatalogs?._result?.type) {
      IssueCatalogs = IssueCatalogsRefresh();
    }
    return (
      <Suspense fallback={<LazyLoadingContent />}>
        <IssueCatalogs {...props} key={props.location.pathname.includes('open')} />
      </Suspense>
    );
  },
};

export default render;
