import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { styles } from 'src/modules/issue-execution/components/task-materials-table/styles';
import { withStyles } from '@mui/styles';
import { CardContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getTasksMaterialsOrTools } from 'src/modules/task/task.redux';
import queryString from 'query-string';
import { Material, Tool } from 'src/interfaces/inventory';

interface TaskMaterialTableProps {
  classes: Record<string, string>;
  getTasksMaterialsOrTools: (
    _id: string,
    material: boolean,
  ) => Promise<{ tool: Tool; material: Material; quantity: string }[]>;
  location: {
    search: string;
    pathname: string;
  };
}

interface ShowItems {
  code: string;
  name: string;
  quantity: string;
}

const StyledTableCell = withStyles((theme) => ({
  body: {
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.background.background1}`,
    height: 40,
    maxWidth: 90,
    padding: 0,
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
}))(TableCell);

const StyledTableCellHeader = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.background.background1}`,
    height: 56,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    marginLeft: 15,
  },
}))(TableCell);

const TaskMaterialTable = (props: TaskMaterialTableProps): JSX.Element => {
  const { classes, getTasksMaterialsOrTools } = props;
  const { id, materials } = queryString.parse(props.location.search) as Record<string, string>;
  const { t } = useTranslation();
  const theme = useTheme();

  const navigate = useNavigate();

  const [itemsToShow, setItemsToShow] = useState<ShowItems[]>([]);

  useEffect(() => {
    getTasksMaterialsOrTools(id, materials === 'true' ? true : false).then((resp) => {
      if (resp.length) {
        const itemsAux: ShowItems[] = [];
        if (materials === 'true') {
          resp.map((item) =>
            itemsAux.push({ code: item.material.code, name: item.material.name, quantity: item.quantity }),
          );
        } else {
          resp.map((item) =>
            itemsAux.push({ code: item.tool?.partNumber, name: item.tool.designation, quantity: item.quantity }),
          );
        }
        setItemsToShow(itemsAux);
      }
    });
  }, []);

  return (
    <Grid container style={{ maxHeight: '90vh', overflow: 'hidden' }}>
      <Grid container md={12} direction='column'>
        <CardContent classes={{ root: classes.containerHeader }}>
          <div style={{ display: 'flex', alignItems: 'center', zIndex: 1000 }}>
            <ArrowBackIos style={{ cursor: 'pointer' }} fontSize='small' onClick={() => navigate(-1)} />
          </div>

          <div className={classes.tableTitle}>{materials === 'true' ? t('materials') : t('tools')}</div>
        </CardContent>

        <Grid container style={{ margin: 20 }}>
          {itemsToShow.length > 0 ? (
            <Table style={{ maxWidth: '95%' }}>
              <TableHead>
                <TableRow>
                  <StyledTableCellHeader style={{ width: '300px' }}>
                    <span style={{ marginLeft: 45 }}>{t('code')}</span>{' '}
                  </StyledTableCellHeader>
                  <StyledTableCellHeader>{t('name')}</StyledTableCellHeader>
                  <StyledTableCellHeader style={{ width: '270px' }}>{t('quantity')}</StyledTableCellHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {itemsToShow.map((row, idx) => (
                  <TableRow
                    key={row.name}
                    style={
                      idx % 2 === 0
                        ? { background: theme.palette.background.background1 }
                        : { background: theme.palette.background.default }
                    }
                  >
                    <StyledTableCell>
                      <span style={{ marginLeft: 60 }}>{row.code}</span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <span style={{ marginLeft: 15 }}>{row.name}</span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <span style={{ marginLeft: 15 }}>{row.quantity}</span>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <div
              style={{
                top: '46%',
                height: '220px',
                width: '100%',
                textAlign: 'center',
              }}
            >
              {t('noData')}
            </div>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getTasksMaterialsOrTools,
    },
    dispatch,
  );

export default compose<any>(
  withTranslation('translation'),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(TaskMaterialTable);
