import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      width: 365,
    },
    button: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '22px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      marginRight: '5px',
      '&.MuiSvgIcon-root': {
        fontSize: 20,
      },
    },
    dialogTitle: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '22px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
    },
    dialogContent: {
      overflowY: 'unset',
      '&.MuiDialogContent-root': {
        padding: '17px 17px',
      },
    },
    closeButton: {
      color: theme.palette.grey[500],
      '&:hover': {
        backgroundColor: 'unset',
      },
      padding: '0 0 0 16px',
      position: 'absolute',
      top: '10px',
      right: '5px',
    },
    deleteButton: {
      color: theme.palette.error.main,
      marginLeft: 'auto',
      marginRight: 0,
      '&:hover': {
        backgroundColor: 'unset',
      },
      '&.MuiIconButton-root': {
        padding: 0,
      },
      top: -4,
      right: -5,
    },
    chipValue: {},
    chipValueMatch: {
      backgroundColor: `${theme.palette.primary.light}`,
    },
    chipText: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
      color: theme.palette.text.primary,
    },
    chipTextMatch: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
      color: theme.palette.primary.main,
    },
    checkIcon: {
      fontSize: '14px',
      color: theme.palette.success.main,
      marginLeft: '5px',
    },
    qrCodeDisable: {
      color: theme.palette.text.secondary,
      fontSize: '10px',
      fontWeight: 600,
      fontFamily: 'Open Sans',
    },
    unlinkTitle: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '16px',
      textAlign: 'center',
      marginTop: '25px',
    },
    linkTitle: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '16px',
      textAlign: 'center',
      marginTop: '25px',
    },
    linkSubTitle: {
      fontFamily: 'Open Sans',
      textAlign: 'center',
      fontSize: '16px',
      color: theme.palette.text.secondary,
      marginTop: '15px',
    },
    unlinkSubTitle: {
      fontFamily: 'Open Sans',
      textAlign: 'center',
      fontSize: '16px',
      color: theme.palette.text.secondary,
      marginTop: '30px',
    },
    radioBtnUnchecked: {
      marginRight: '8px',
    },
    radioBtnChecked: {
      marginRight: '8px',
      color: theme.palette.primary.main,
    },
    qrScanBtn: {
      padding: '6 px 22px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '5px',
      borderColor: theme.palette.primary.main,
      /* '&:hover': {
      backgroundColor: theme.palette.error.lv4,
    }, */
    },
    qrIcon: {
      marginRight: '5px',
      color: theme.palette.primary.main,
    },
    qrText: {
      color: theme.palette.primary.main,
    },
  });
