import { ReactNode, createContext, useContext, useState } from 'react';
import { SkillMatrixState } from '../types/skill-matrix.types';

export const skillMatrixContextInitialValue: SkillMatrixState = {
  subjectId: '',
  subject: undefined,
  matrix: undefined,
  isLoading: true,
  options: [
    {
      text: 'actual',
      visible: true,
    },
    {
      text: 'should',
      visible: false,
    },
  ],
  selectedSkillGroupId: '',
  selectedSkillGroup: undefined,
  selectedSkill: undefined,
  filters: undefined,
  searchActive: false,
};

const SkillMatrixContext = createContext<SkillMatrixState>(skillMatrixContextInitialValue);

interface SkillMatrixContextProviderProps {
  children: ReactNode;
  initialValue: SkillMatrixState;
}

export const SkillMatrixContextProvider = ({
  children,
  initialValue = skillMatrixContextInitialValue,
}: SkillMatrixContextProviderProps) => {
  const [skillMatrixState, setSkillMatrixState] = useState<SkillMatrixState>(initialValue);

  const updateSkillMatrixState = (newData: Partial<SkillMatrixState>) => {
    setSkillMatrixState((prevState) => ({ ...prevState, ...newData }));
  };

  return (
    <SkillMatrixContext.Provider value={{ ...skillMatrixState, updateSkillMatrixState, setSkillMatrixState }}>
      {children}
    </SkillMatrixContext.Provider>
  );
};

export const useSkillMatrixContext = () => {
  const context = useContext(SkillMatrixContext);
  if (!context) {
    throw new Error('useSkillMatrixContext must be used within a SubjectContext');
  }
  return context;
};
