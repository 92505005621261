import { Link, Menu, MenuItem, Typography } from '@mui/material';
import { startTutorial } from 'src/base/on-boarding/utils/onBoarding.utils.startTutorial';
import { resumeTutorial } from 'src/base/on-boarding/utils/onBoarding.utils.resumeTutorial';
import { useMainContainerContext } from 'src/base/main-container/context/main.container.context';
import { handleWalkthrough } from 'src/base/on-boarding/utils/onBoarding.utils.handleWalkThrough';
import { ConnectedProps } from 'react-redux';
import { updateLoggedUser } from 'src/base/login/login.redux';
import { getIssueTemplateFolders, createIssueTemplate } from 'src/modules/issue-templates/issue.templates.redux';
import { styles } from './styles';
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { HelpCenter, LiveHelp, Quiz } from '@mui/icons-material';
import { typedConnect } from 'src/utils/funcs';

type HelpMenuProps = {
  openHelpDropdown: HTMLDivElement;
  setOpenHelpDropdown: (data: HTMLDivElement | null) => void;
} & ConnectedProps<typeof connector>;

const useStyles = makeStyles(styles);
const HelpMenu: FC<HelpMenuProps> = (props): JSX.Element => {
  const { openHelpDropdown, setOpenHelpDropdown, canDoOnBoarding } = props;
  const mainContainerState = useMainContainerContext();
  const { updateMainContainerState } = mainContainerState;
  const classes: ClassNameMap<string> = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const walkMeThroughContent = (): string => {
    let content = '';

    switch (props.loggedUser?.onBoarding?.step) {
      case 'build':
        content = t('walkMeThroughTemplate');
        break;
      case 'createIssue':
      case 'createTasks':
        content = t('walkMeThroughOpenIssue');
        break;
      case 'execute':
        content = t('walkMeThroughExecution');
        break;
    }

    return content;
  };
  return (
    <Menu
      open={!!openHelpDropdown}
      anchorEl={openHelpDropdown}
      transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      onClose={() => setOpenHelpDropdown(null)}
      className={classes.helpDropdown}
      classes={{ list: classes.helpDropdownList }}
    >
      {canDoOnBoarding && (
        <MenuItem
          data-testid={'walkthrough-menu-item'}
          className={classes.helpDropdownItem}
          onClick={() => {
            if (!mainContainerState.doingOnBoarding) {
              props.loggedUser?.onBoarding?.finished
                ? startTutorial(props, updateMainContainerState, navigate)
                : resumeTutorial(props, navigate, updateMainContainerState);
            } else {
              handleWalkthrough(mainContainerState, props, updateMainContainerState);
            }
            setOpenHelpDropdown(null);
          }}
        >
          {mainContainerState.doingOnBoarding ? <LiveHelp /> : <Quiz />}
          <div className={classes.helpDropdownItemContentContainer}>
            <Typography style={{ fontFamily: 'Open Sans', lineHeight: '20px' }}>
              {mainContainerState.doingOnBoarding ? t('walkMeThrough') : t('tutorialMode')}
            </Typography>
            <Typography className={classes.helpDropdownItemContent}>
              {mainContainerState.doingOnBoarding ? walkMeThroughContent() : t('getStartedTutorial')}
            </Typography>
          </div>
        </MenuItem>
      )}
      <MenuItem
        data-testid={'help-center-menu-item'}
        component={Link}
        target={'_blank'}
        href={'https://help.glartek.com'}
        className={classes.helpDropdownItem}
        onClick={() => setOpenHelpDropdown(null)}
      >
        <HelpCenter className={classes.helpDropdownItemIcon} />
        <div className={classes.helpDropdownItemContentContainer}>
          <Typography style={{ fontFamily: 'Open Sans', lineHeight: '20px' }}>{t('helpCenter')}</Typography>
          <Typography className={classes.helpDropdownItemContent}>{t('findHelpInArticles')}</Typography>
        </div>
      </MenuItem>
    </Menu>
  );
};

const connector = typedConnect(
  (state) => ({
    loggedUser: state.loginReducer.loggedUser,
    canDoOnBoarding: state.loginReducer.loggedUser?.roles[0].permissions.some(
      (permission) =>
        permission.fieldName === 'createIssueTemplate' ||
        (permission.parentType === 'Mutation' &&
          permission.fieldName === '*' &&
          !state.loginReducer.loggedUser.isPlatformAdmin &&
          !state.loginReducer.impersonatedBy),
    ),
  }),
  {
    updateLoggedUser,
    getIssueTemplateFolders,
    createIssueTemplate,
  },
);

export default connector(HelpMenu);
