import React, { useState, useRef, useEffect } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Popover, ListItem } from '@mui/material';
import { styles } from './styles';
import { withStyles, WithStyles } from '@mui/styles';

export type MeasurementPreset = {
  name: string;
  type: 'last' | 'average';
};

type CustomSelectMeasurementProps = {
  selectedMeasurement: MeasurementPreset;
  classes: Record<string, string>;
  setSelectedMeasurement: (measurementPreset: MeasurementPreset) => void;
  setMeasurement: (measurement: 'last' | 'average') => void;
} & WithTranslation &
  WithStyles<typeof styles>;

export const measurementPresets: MeasurementPreset[] = [
  {
    name: 'lastValue',
    type: 'last',
  },
  {
    name: 'averageValue',
    type: 'average',
  },
];

const CustomSelectMeasurement: React.FC<CustomSelectMeasurementProps> = (props): JSX.Element => {
  const { selectedMeasurement, classes, setSelectedMeasurement, setMeasurement, t } = props;
  const anchorRef = useRef<HTMLDivElement>(null);

  const [openPopover, setOpenPopover] = useState<boolean>(false);

  const handleOpen = () => {
    setOpenPopover(true);
  };

  const handleClose = () => {
    setOpenPopover(false);
  };

  const handleClick = (item: MeasurementPreset) => {
    setSelectedMeasurement(item);
    setMeasurement(item.type);
    setOpenPopover(false);
  };

  useEffect(() => setMeasurement(selectedMeasurement.type), []);

  return (
    <>
      <div className={classes.divContainer} onClick={handleOpen}>
        {/* <div className={classes.mainContainer} ref={anchorRef} key={'measurement-current-selected'} onClick={handleOpen}>
        <span>{t(`${selectedMeasurement.name}`)}</span>
        <ExpandMore />
      </div> */}
        <div className={classes.relative}>
          <ExpandMore className={classes.expandIcon} />
        </div>
        <div ref={anchorRef} className={classes.input} key={'measurement-current-selected'}>
          <span>{t(`${selectedMeasurement.name}`)}</span>
        </div>
      </div>
      <Popover
        anchorEl={anchorRef.current}
        open={openPopover}
        classes={{ paper: classes.popoverContainer }}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{
          style: {
            width: anchorRef.current?.getBoundingClientRect().width,
          },
        }}
      >
        {measurementPresets.map((item) => (
          <ListItem
            key={`measurement-${item.name}`}
            classes={{ root: selectedMeasurement.name === item.name ? classes.listItemSelected : classes.listItem }}
            onClick={() => handleClick(item)}
          >
            <span className={selectedMeasurement.name === item.name && classes.selectedMeasurementItem}>
              {t(`${item.name}`)}
            </span>
          </ListItem>
        ))}
      </Popover>
    </>
  );
};

export default withTranslation('translation')(withStyles(styles)(CustomSelectMeasurement));
