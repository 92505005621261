import { APOLLO_CLIENT } from 'config/apollo.config';
import { ChatRoomWhereUniqueInput, AddChatGroupParticipantsDocument } from 'src/gql/graphql';

export const addChatGroupParticipant = async (where: ChatRoomWhereUniqueInput, accounts: string[]) => {
  const response = await APOLLO_CLIENT.mutate({
    variables: {
      where,
      accounts,
    },
    fetchPolicy: 'no-cache',
    mutation: AddChatGroupParticipantsDocument,
  });

  return response.data.addChatGroupParticipants;
};
