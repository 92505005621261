import gql from 'graphql-tag';
import { ALERT_TO_TABLE } from 'src/utils/fragments/alert';
import { ELEMENT_TEMPLATE, ELEMENT } from 'src/utils/fragments/element';
import {
  INPUT,
  INPUT_NUMBER,
  INPUT_STRING,
  INPUT_WITH_LOG,
  INPUT_WITH_RECURSIVE,
  ON_RESPONSE_COMMON_NUMBER,
  ON_RESPONSE_COMMON_STRING,
} from 'src/utils/fragments/input';
import { ISSUE_INSTANCE } from 'src/utils/fragments/issue';
import { SITE } from 'src/utils/fragments/site';
import { STATE_MACHINE_INSTANCE } from 'src/utils/fragments/stateMachine';

export const Q_GET_ALERTS = gql`
  query GetAlerts($where: AlertWhereInput, $limit: Int, $skip: Int, $orderBy: [AlertOrderByInput]) {
    alerts(where: $where, orderBy: $orderBy, limit: $limit, skip: $skip) {
      ...alertToTable
    }
    alertsCount(where: $where)
  }
  ${ALERT_TO_TABLE}
`;

export const Q_GET_ALERT = gql`
  query GetAlert($where: AlertWhereInput) {
    alerts(where: $where) {
      ...alertToTable
      element {
        ...element
      }
      site {
        ...site
      }
      task
      taskGroup
      input {
        _id
        type
        date {
          values
        }
        string {
          values
        }
      }
      issue {
        ...issueInstance
        taskGroups {
          _id
          tasks {
            _id
            executionInputs {
              ...inputWithRecursive
            }
          }
        }
      }
      log {
        by {
          name
          email
          _id
          photo
        }
        type
        message
        date
      }
    }
  }
  ${ALERT_TO_TABLE}
  ${INPUT}
  ${ELEMENT_TEMPLATE}
  ${ELEMENT}
  ${INPUT_WITH_LOG}
  ${INPUT_WITH_RECURSIVE}
  ${STATE_MACHINE_INSTANCE}
  ${ISSUE_INSTANCE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
  ${SITE}
`;

export const M_CLOSE_ALERT = gql`
  mutation CloseAlert($where: AlertWhereUniqueInput, $data: InputUpdateInput) {
    closeAlert(where: $where, data: $data) {
      ...alertToTable
      element {
        ...element
      }
      task
      taskGroup
      input {
        _id
        type
        date {
          values
        }
        string {
          values
        }
      }
      issue {
        ...issueInstance
        taskGroups {
          _id
          tasks {
            _id
            executionInputs {
              ...inputWithRecursive
            }
          }
        }
      }
      log {
        by {
          name
          email
          _id
          photo
        }
        type
        message
        date
      }
    }
  }
  ${ALERT_TO_TABLE}
  ${INPUT}
  ${ELEMENT_TEMPLATE}
  ${ELEMENT}
  ${INPUT_WITH_LOG}
  ${INPUT_WITH_RECURSIVE}
  ${STATE_MACHINE_INSTANCE}
  ${ISSUE_INSTANCE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
`;
