import { OwnRoomFeatureType, RoomFeatureType, RoomFeatureList } from './feature';
import { VideoTrackType } from './session';

export const maxLabelsPerRoom = 20;

export interface Room {
  code: string;
  create_at: Date;
  delete_at: Date;
  vision_create_by: string;
  create_by: string;
  id: string;
  last_activity_at: Date;
  name: string;
  update_at: Date;
  valid_until: Date;
  user_details?: UserDetails;
  external_data?: ExternalData;
  entity_id?: string;
  permissions?: RoomPermissions;
  chatroom_id?: string;
}

// RoomPermissions
export interface RoomPermissions {
  allowed_guest_features: Array<AllowedGuestFeatures>;
}

export type AllowedGuestFeatures =
  | RoomFeatureType.RoomRecording
  | RoomFeatureType.RoomFileTransfer
  | RoomFeatureType.RoomScreenShare
  | OwnRoomFeatureType.RoomScreenshot;

export interface UserDetails {
  name?: string;
  microphone: boolean;
  video_track: boolean;
  video_track_type: VideoTrackType | '';
  capabilities: null | { [key: string]: string };
  room_features?: null | RoomFeatureList;
  current_state?: null | { [key: string]: number };
}

export interface ExternalData {
  name: string;
  rows: Array<ExternalDataRow>;
}

export type ExternalDataRowChild = {
  type: ExternalDataRowType;
  rows: Array<unknown>;
};

export type ExternalDataRowNode = ExternalDataRowChild | Array<ExternalDataRow>;

export type ExternalDataRow = {
  [key: string]: ExternalDataRowNode;
};

export enum ExternalDataRowType {
  String = 'string',
  Date = 'date',
  Number = 'number',
}

export const isValidExternalRowType = (type: string): boolean =>
  Object.values(ExternalDataRowType).includes(type as ExternalDataRowType);

export const isNodeChild = (row: ExternalDataRowNode): boolean =>
  (row as ExternalDataRowChild)?.type &&
  (row as ExternalDataRowChild)?.rows &&
  isValidExternalRowType((row as ExternalDataRowChild).type) &&
  Array.isArray((row as ExternalDataRowChild)?.rows);

export enum RoomStatus {
  CONNECTED = 'CONNECTED',
  LEAVING = 'LEAVING',
  CLEAR = 'CLEAR',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
}

export const canGuestUseFeature = (feature: AllowedGuestFeatures, roomPermissions?: RoomPermissions): boolean => {
  if (!roomPermissions || !roomPermissions.allowed_guest_features) return false;

  return roomPermissions.allowed_guest_features.includes(feature);
};

export const isLoadingRoom = (status: RoomStatus): boolean =>
  status == RoomStatus.LOADING ||
  status == RoomStatus.ERROR ||
  status == RoomStatus.LEAVING ||
  status == RoomStatus.CLEAR;
