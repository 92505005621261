import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';
import {
  AccountInviteContextProvider,
  accountInviteContextInitialValue,
} from './invites/context/account.invite.context';
import {
  AccountProfileContextProvider,
  accountProfileContextInitialValue,
} from './profile/context/account.profile.context';
import { AccountContextProvider, accountContextInitialValue } from './context/account.context';

const AccountCreateDefaultRefresh = () => lazy(() => import('src/modules/account/accounts'));
const AccountCreateTemplateRefresh = () => lazy(() => import('src/modules/account/account.create.template'));
const AccountCreateInviteRefresh = () => lazy(() => import('src/modules/account/invites'));
const AccountPendingInvitesRefresh = () => lazy(() => import('src/modules/account/pending-invites'));
const AccountProfileRefresh = () => lazy(() => import('src/modules/account/profile'));

let AccountCreateDefault = AccountCreateDefaultRefresh();
let AccountCreateTemplate = AccountCreateTemplateRefresh();
let AccountCreateInvite = AccountCreateInviteRefresh();
let AccountPendingInvites = AccountPendingInvitesRefresh();
let AccountProfile = AccountProfileRefresh();

export const render = {
  create: {
    default: ({ ...props }) => {
      if (AccountCreateDefault?._result?.type === 'error') {
        AccountCreateDefault = AccountCreateDefaultRefresh();
      }

      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <AccountContextProvider initialValue={accountContextInitialValue}>
            <AccountCreateDefault {...props} />
          </AccountContextProvider>
        </Suspense>
      );
    },
    template: ({ ...props }) => {
      if (AccountCreateTemplate?._result?.type === 'error') {
        AccountCreateTemplate = AccountCreateTemplateRefresh();
      }

      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <AccountCreateTemplate {...props} />
        </Suspense>
      );
    },
  },
  invite: ({ ...props }) => {
    if (AccountCreateInvite?._result?.type === 'error') {
      AccountCreateInvite = AccountCreateInviteRefresh();
    }

    return (
      <Suspense fallback={<LazyLoadingContent />}>
        <AccountInviteContextProvider initialValue={accountInviteContextInitialValue}>
          <AccountCreateInvite {...props} />
        </AccountInviteContextProvider>
      </Suspense>
    );
  },
  pending: ({ ...props }) => {
    if (AccountPendingInvites?._result?.type === 'error') {
      AccountPendingInvites = AccountPendingInvitesRefresh();
    }

    return (
      <Suspense fallback={<LazyLoadingContent />}>
        <AccountInviteContextProvider initialValue={accountInviteContextInitialValue}>
          <AccountPendingInvites {...props} />
        </AccountInviteContextProvider>
      </Suspense>
    );
  },
  profile: ({ ...props }) => {
    if (AccountProfile?._result?.type === 'error') {
      AccountProfile = AccountProfileRefresh();
    }

    return (
      <Suspense fallback={<LazyLoadingContent />}>
        <AccountProfileContextProvider initialValue={accountProfileContextInitialValue}>
          <AccountProfile {...props} />
        </AccountProfileContextProvider>
      </Suspense>
    );
  },
};

export default render;
