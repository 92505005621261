import { Action, State } from 'src/interfaces/reducers';
import { Action as ActionType } from 'src/gql/graphql';
import { getErrorObject } from 'src/utils/funcs';
import * as queries from 'src/modules/actions/actions.queries';
import { APOLLO_CLIENT } from 'config/apollo.config';
import { Dispatch } from 'redux';
import i18n from 'src/utils/translations/i18n';
import { GetActionToIssueDocument } from 'src/gql/graphql';

const initialState: State = {};

export default (_state: State = initialState, action: Action): State => {
  switch (action.type) {
    default:
      return {};
  }
};

export const getActions =
  (where?: any, limit?: number, skip?: number, orderBy?: string) =>
  async (dispatch: Dispatch): Promise<ActionType[]> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          where,
          limit,
          skip: skip !== undefined && skip < 0 ? 0 : skip,
          orderBy,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_ACTIONS,
      });
      return response.data.actions;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getActionsToIssue =
  (where?: any, limit?: number, skip?: number, orderBy?: string) =>
  async (dispatch: Dispatch): Promise<ActionType[]> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          where,
          limit,
          skip: skip !== undefined && skip < 0 ? 0 : skip,
          orderBy,
        },
        fetchPolicy: 'no-cache',
        query: GetActionToIssueDocument,
      });
      return response.data.actions;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getActionsCount = (where?: any) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ACTIONS_COUNT,
    });
    return response.data.actionsCount;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getActionsCountMultiple = (where?: any) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ACTIONS_COUNT_MULTIPLE,
    });
    return response.data.actionsCountMultiple.count;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const createAction = (data: any) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        data,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_CREATE_ACTION,
    });
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: i18n.t('actionToastCreateSuccess'),
        severity: 'success',
      },
    });

    dispatch({
      type: 'UPDATE_ACTION_COUNT',
      payload: {
        value: 1,
      },
    });

    return resp.data.createAction;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getAction = (where?: { _id: string }) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ACTION,
    });
    return response.data.action;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const updateAction = (where: { _id: string }, data: any) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        data,
        where,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_UPDATE_ACTION,
    });
    return resp.data.updateAction;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const linkInputsToManyActions = (data) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        data,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_LINK_INPUTS_TO_MANY_ACTIONS,
    });
    return resp.data.linkInputsToManyActions;
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const addLogMessage = (where: any, message: string, files: string[]) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        where,
        message,
        files,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_ADD_LOG_MESSAGE,
    });
    return resp.data.addLogMessageToAction;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getInputName = (inputId, taskId) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        _id: inputId,
        taskId,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_INPUT_NAME,
    });
    return response.data.executionInput;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const deleteAction = (_id: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        id: _id,
      },
      fetchPolicy: 'no-cache',
      query: queries.M_DELETE_ACTION,
    });
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: i18n.t('Action') + ' ' + i18n.t('toastDeleteSuccess'),
        severity: 'success',
      },
    });

    dispatch({
      type: 'UPDATE_ACTION_COUNT',
      payload: {
        value: -1,
      },
    });

    return response.data.deleteOneAction;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const distinctActionValues = () => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_DISTINCT_ACTION_VALUES,
    });

    return response.data.distinctActionValues;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const createActionDraft = (data: any) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        data,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_CREATE_ACTION_DRAFT,
    });
    return resp.data.createActionDraft;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const updateActionDraft = (where: { _id: string }, data: any) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        data,
        where,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_UPDATE_ACTION_DRAFT,
    });
    return resp.data.updateActionDraft;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const deleteActionDraft = (_id: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        id: _id,
      },
      fetchPolicy: 'no-cache',
      query: queries.M_DELETE_ACTION_DRAFT,
    });

    return response.data.deleteOneActionDraft;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const deleteManyActionDrafts = (_ids: string[]) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        ids: _ids,
      },
      fetchPolicy: 'no-cache',
      query: queries.M_DELETE_MANY_ACTION_DRAFTS,
    });

    return response.data.deleteManyActionDrafts;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getActionDraft = (where?: { _id: string }) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ACTION_DRAFT,
    });
    return response.data.actionDraft;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};
