import { ReactNode, createContext, useCallback, useContext, useState } from 'react';
import { MediaLibraryState } from '../types/media-library.types';
import { mediaLibraryContextInitialValue } from '../constants/media-library.constants';

const MediaLibraryContext = createContext<MediaLibraryState>(mediaLibraryContextInitialValue);

interface MediaLibraryContextProviderProps {
  children: ReactNode;
  initialValue: MediaLibraryState;
}

export const MediaLibraryContextProvider = ({
  children,
  initialValue = mediaLibraryContextInitialValue,
}: MediaLibraryContextProviderProps) => {
  const [state, setState] = useState<MediaLibraryState>(initialValue);

  const updateState = useCallback((newData: Partial<MediaLibraryState>) => {
    setState((prevState) => ({ ...prevState, ...newData }));
  }, []);

  return (
    <MediaLibraryContext.Provider value={{ ...state, updateState, setState }}>{children}</MediaLibraryContext.Provider>
  );
};

export const useMediaLibraryContext = () => {
  const context = useContext(MediaLibraryContext);
  if (!context) {
    throw new Error('useMediaLibraryContext must be used within a MediaLibraryContext');
  }
  return context;
};
