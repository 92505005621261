import * as React from 'react';
// import { Chip, Dialog, TextField } from '@mui/material';
import { /* Close, */ ChevronRight, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Chip, Dialog, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { partition } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Anchorme } from 'react-anchorme';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { showSnackbar } from 'src/base/root/root.redux';
import {
  createAction,
  getActionDraft,
  getActions,
  getActionsCount,
  linkInputsToManyActions,
  updateAction,
} from 'src/modules/actions/actions.redux';
import { styles } from 'src/modules/actions/components/create-modal/styles';
import { inputLinkedWhere, inputWhere, siteElementWhere } from 'src/modules/actions/utils/action.util.whereClauses';
import { getElementsLoadMore, getElementToTree } from 'src/modules/element/element.redux';
import {
  getActionAfterLinkActionToIssue,
  getActionAfterUnlinkActionFromIssue,
  mapActionsToUpdate,
} from 'src/utils/components/actions-popover/utils/actions-popover.utils';
import BetterFieldPopover from 'src/utils/components/better-field-popover';
import DefaultButton from 'src/utils/components/default-button/default-button';
import InputFieldGeneric from 'src/utils/components/input-field/input-generic';
import { withDialogBoundary } from 'src/utils/other/componentErrorBoundary';
import { NexusGenEnums, NexusGenFieldTypes, NexusGenInputs } from '../../../../../../server/src/types';

interface ModalCreateActionProps {
  classes: Record<string, string>;
  onClose: () => void;
  issue: NexusGenFieldTypes['IssueInstance'];
  task: NexusGenFieldTypes['Task'];
  input: NexusGenFieldTypes['Input'];
  showSnackbar: (severity: string, message: string) => Promise<void>;
  createAction: (
    data: NexusGenInputs['ActionCreateInput'],
  ) => Promise<NexusGenFieldTypes['Action'] & { graphQLErrors?: unknown }>;
  getElementToTree: (filter, skip, limit) => Promise<{ _id?: string; name: string; totalFolders: number }[]>;
  alert: NexusGenFieldTypes['Alert'] & { element: NexusGenFieldTypes['Element']; site: NexusGenFieldTypes['Site'] };
  execution: boolean;
  getElementsLoadMore: (
    filter,
    item,
    limit: number,
    skip: { sites: number; elements: number },
  ) => Promise<{
    sites: { _id?: string; name: string; totalFolders: number }[];
    elements: { _id?: string; name: string; totalFolders: number }[];
  }>;
  element: NexusGenFieldTypes['Element'];
  site: NexusGenFieldTypes['Site'];
  getActions: any;
  getActionsCount: any;
  updateAction: any;
  answerInput?: boolean;
  getActionDraft: any;
  onSave?: (action: NexusGenFieldTypes['Action']) => void;
  onUnlink?: (action: NexusGenFieldTypes['Action']) => void;
  onLink?: (action: NexusGenFieldTypes['Action']) => void;
  onLinkActions?: (actions: NexusGenFieldTypes['Action'][]) => void;
  linkInputsToManyActions: (actions: any[]) => Promise<NexusGenFieldTypes['Action'][] & { graphQLErrors?: unknown }>;
}

interface Action {
  name: string;
  description: string;
  assignedLabelValues: NexusGenFieldTypes['LabelValue'][];
  assignedAccounts: NexusGenFieldTypes['Account'][];
  element: NexusGenFieldTypes['Element'] | undefined;
  labelValues: NexusGenFieldTypes['LabelValue'][];
  dueDate: string | undefined;
  files: string[];
  site: NexusGenFieldTypes['Site'] | undefined;
}

interface ActionTableStateProps {
  limit: number;
  skip: number;
  isFinished: boolean;
}

const ModalCreateAction: React.FC<ModalCreateActionProps> = (props: ModalCreateActionProps) => {
  const { classes, onClose, issue, task, input, alert, execution } = props;
  const { t }: { t: (key: string) => string } = useTranslation();
  const [status, setStatus] = useState<NexusGenEnums['ActionStatus']>('PENDING');
  const [element, setElement] = useState<NexusGenFieldTypes['Element'] | null>(props.element);
  const [site, setSite] = useState<NexusGenFieldTypes['Site'] | null>(props.site);
  const [action, setAction] = useState<Action>({
    name: alert ? alert.input.name : '',
    description: '',
    files: [],
    assignedLabelValues: [],
    assignedAccounts: [],
    element: alert?.element ? alert.element : element ? element : undefined,
    site: alert?.site ? alert.site : site ? site : undefined,
    labelValues: alert?.element
      ? (alert.element.labelValues as NexusGenFieldTypes['LabelValue'][])
      : alert?.site
        ? (alert.site.labelValues as NexusGenFieldTypes['LabelValue'][])
        : [],
    dueDate: undefined,
  });
  const [expandedDetails, setExpandedDetails] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [showAllActions, setShowAllActions] = useState<boolean>(false);
  const navigate = useNavigate();
  const [inputRelatedActions, setInputRelatedActions] = useState<NexusGenFieldTypes['Action'][]>([]);
  const [siteElementRelatedActions, setSiteElementRelatedActions] = useState<NexusGenFieldTypes['Action'][]>([]);
  const [updatedActionsIds, setUpdatedActionsIds] = useState<string[]>([]);
  const [inputTableState, setInputTableState] = useState<ActionTableStateProps>({
    limit: 10,
    skip: 0,
    isFinished: false,
  });
  const [inputLinkedTableState, setInputLinkedTableState] = useState<ActionTableStateProps>({
    limit: 10,
    skip: 0,
    isFinished: false,
  });
  const [siteElementTableState, setSiteElementTableState] = useState<ActionTableStateProps>({
    limit: 10,
    skip: 0,
    isFinished: false,
  });

  const createNewAction = () => {
    if (!action.name) {
      void props.showSnackbar('error', t('requiredTitle'));
      return;
    }
    if (!action.site) {
      void props.showSnackbar('error', t('noSiteAssociated'));
      return;
    }

    setSaving(true);

    const formattedAction = {
      ...action,
      dueDate: action.dueDate ? moment(action.dueDate).toISOString() : undefined,
    };
    void props
      .createAction({
        ...formattedAction,
        assignedLabelValues: action.assignedLabelValues.map((lv: NexusGenFieldTypes['LabelValue']) => lv._id),
        assignedAccounts: action.assignedAccounts.map((acc: NexusGenFieldTypes['Account']) => acc._id),
        labelValues: action.labelValues.map((lv: NexusGenFieldTypes['LabelValue']) => lv._id),
        element: action.element ? action.element?._id : undefined,
        site:
          action.site && !action.element?.name
            ? action.site?._id || localStorage.getItem('current_site_id')
            : undefined,
        issues:
          issue || task
            ? [
                {
                  issue: issue ? issue._id : undefined,
                  task: task ? task._id : undefined,
                  input: input ? input._id : undefined,
                },
              ]
            : [],
        input: input ? input._id : undefined,
        alert: alert ? alert._id : undefined,
        status: status,
      })
      .then((resp: any) => {
        if (resp && resp.graphQLErrors) {
          setSaving(false);
          return;
        }

        if (props.onSave) {
          props.onSave(resp);
        }
        props.answerInput
          ? onClose()
          : execution
            ? setShowAllActions(true)
            : navigate(`/action?name=${resp.name}&id=${resp._id || ''}`);
        setSaving(false);
      });
  };

  useEffect(() => {
    if (issue && !task && issue.element) {
      //@ts-ignore
      setAction((prev) => ({ ...prev, element: issue.element, site: issue.site }));
    } else if (issue && !task && issue.site) {
      //@ts-ignore
      setAction((prev) => ({ ...prev, element: undefined, site: issue.site }));
    } else if (issue && task) {
      //@ts-ignore
      setAction((prev) => ({ ...prev, element: task.element || issue.element, site: task.site || issue.site }));
    }
  }, [input]);

  const getRelatedActions = async (where, limit, skip) => {
    try {
      const relatedActions = await props.getActions(where, limit, skip);

      return relatedActions;
    } catch (e) {
      console.error(e);
      return e;
    }
  };

  useEffect(() => {
    const fetchActions = async () => {
      if (showAllActions) {
        /** Get input linked actions */
        const inputLinkedRelActions = await getRelatedActions(inputLinkedWhere(props), inputTableState.limit, 0);
        setInputLinkedTableState({
          ...inputLinkedTableState,
          skip: inputLinkedTableState.limit + inputLinkedTableState.skip,
          isFinished: inputLinkedRelActions.length < inputLinkedTableState.limit ? true : false,
        });

        if (inputLinkedRelActions.length >= inputLinkedTableState.limit) {
          setInputRelatedActions(inputLinkedRelActions);
        } else {
          /**  */
          const inputRelActions = await getRelatedActions(inputWhere(props), inputTableState.limit, 0);
          if (inputRelActions) {
            setInputRelatedActions([...inputLinkedRelActions, ...inputRelActions]);
          }
          setInputTableState({
            ...inputTableState,
            skip: inputTableState.limit + inputTableState.skip,
            isFinished: inputRelActions.length < inputTableState.limit ? true : false,
          });
          /** Get site/element related actions if input relacted actions are less than limit and we need to present other actions too */
          if (inputRelActions.length < inputTableState.limit) {
            /** Site/Element Related Actions */
            const siteElementRelActions = await getRelatedActions(
              siteElementWhere(props),
              siteElementTableState.limit,
              0,
            );
            if (siteElementRelActions) {
              setSiteElementRelatedActions(siteElementRelActions);
            }

            setSiteElementTableState({
              ...siteElementTableState,
              skip: siteElementTableState.limit + siteElementTableState.skip,
              isFinished: siteElementRelActions.length < siteElementTableState ? true : false,
            });
          }
        }
      }
    };

    fetchActions();
  }, [showAllActions]);

  useEffect(() => {
    if (execution) {
      setShowAllActions(true);
    }
  }, []);

  const chipActionStatus = (status) => {
    switch (status) {
      case 'CANT_DO':
        return <Chip label={t('cantDo')} size={'small'} className={`${classes.statusCantDo} ${classes.chip}`} />;
      case 'DOING':
        return <Chip label={t('doing')} size={'small'} className={`${classes.statusDoing} ${classes.chip}`} />;
      case 'SOLVED':
        return <Chip label={t('solved')} size={'small'} className={`${classes.statusSolved} ${classes.chip}`} />;
      case 'PENDING':
      default:
        return <Chip label={t('pending')} size={'small'} className={`${classes.statusPending} ${classes.chip}`} />;
    }
  };

  const unlinkActionToIssue = (action) => {
    const actionToUnlink = getActionAfterUnlinkActionFromIssue(action, issue._id, task._id, input._id);

    if (inputRelatedActions.find((i) => i._id.toString() === actionToUnlink._id.toString()))
      setInputRelatedActions(inputRelatedActions.map((act) => (act._id === action._id ? actionToUnlink : act)));
    else
      setSiteElementRelatedActions(
        siteElementRelatedActions.map((act) => (act._id === action._id ? actionToUnlink : act)),
      );

    if (!updatedActionsIds.find((d) => d.toString() === action._id.toString()))
      setUpdatedActionsIds([...updatedActionsIds, action._id]);
  };

  const linkActionToIssue = (action) => {
    const actionToLink = getActionAfterLinkActionToIssue(action, issue, task._id, input._id);

    if (inputRelatedActions.find((i) => i._id.toString() === actionToLink._id.toString()))
      setInputRelatedActions(inputRelatedActions.map((act) => (act._id === action._id ? actionToLink : act)));
    else
      setSiteElementRelatedActions(
        siteElementRelatedActions.map((act) => (act._id === action._id ? actionToLink : act)),
      );

    if (!updatedActionsIds.find((d) => d.toString() === action._id.toString()))
      setUpdatedActionsIds([...updatedActionsIds, action._id]);
  };

  const renderActionItem = (action, isLast) => {
    return (
      <div
        className={isLast ? props.classes.lastActionMainDiv : props.classes.actionMainDiv}
        onClick={() => navigate(`/action?name=${action.name}&id=${action._id || ''}`)}
      >
        <div>
          <div>{chipActionStatus(action.status)}</div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className={classes.actionListName}>{action.name}</span>
          </div>
          {action.issues?.filter(
            (iss) => iss.issue._id === issue._id && iss.task === task._id && iss.input === input._id,
          ).length ? (
            <DefaultButton
              data-testid='btn-link-to-input-action-modal'
              size='small'
              className={classes.btnLink}
              onClick={(e) => {
                e.stopPropagation();
                unlinkActionToIssue(action);
              }}
              variant='delete-alt'
            >
              {t('unlinkFromInput')}
            </DefaultButton>
          ) : (
            <DefaultButton
              data-testid='btn-link-to-input-action-modal'
              size='small'
              className={classes.btnLink}
              onClick={(e) => {
                e.stopPropagation();
                linkActionToIssue(action);
              }}
              variant='discard'
            >
              {t('linkToInput')}
            </DefaultButton>
          )}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ChevronRight />
        </div>
      </div>
    );
  };

  const renderActionsList = () => {
    return (
      <>
        {inputRelatedActions.length > 0 && (
          <>
            <div className={classes.divRelatedAction}>{t('inputRelatedActions')}</div>
            {inputRelatedActions.map((act, idx) => renderActionItem(act, idx === inputRelatedActions.length - 1))}
          </>
        )}
        {siteElementRelatedActions.length > 0 && (
          <>
            <div className={classes.divRelatedAction}>{t('siteElementRelatedActions')}</div>
            {siteElementRelatedActions.map((act, idx) =>
              renderActionItem(act, idx === siteElementRelatedActions.length - 1),
            )}
          </>
        )}
      </>
    );
  };

  const handleSave = async () => {
    const updatedInputRelatedActions = inputRelatedActions.filter((act) =>
      updatedActionsIds.find((i) => (i.toString() === act._id.toString() ? act : undefined)),
    );
    const updatedSiteElementRelatedActions = siteElementRelatedActions.filter((act) =>
      updatedActionsIds.find((i) => (i.toString() === act._id.toString() ? act : undefined)),
    );

    const actionsToUpdate = [...updatedInputRelatedActions, ...updatedSiteElementRelatedActions];

    const mappedActions = mapActionsToUpdate(actionsToUpdate);

    const updatedActions = await props.linkInputsToManyActions(mappedActions);

    if (!updatedActions.graphQLErrors) {
      props.onLinkActions(updatedActions);
      onClose();
    }
  };

  const handleScroll = async (e) => {
    if (e.target.scrollHeight - e.target.scrollTop - 50 <= e.target.clientHeight) {
      let totalActionsGotten = 0;
      /** Get actions which are linked to the input if not every action of this type is already on list */
      if (!inputLinkedTableState.isFinished) {
        const inpActions = await getRelatedActions(
          inputLinkedWhere(props),
          inputLinkedTableState.limit,
          inputLinkedTableState.skip,
        );
        setInputRelatedActions([...inputRelatedActions, ...inpActions]);
        setInputLinkedTableState({
          ...inputLinkedTableState,
          skip: inputLinkedTableState.skip + inputLinkedTableState.limit,
          isFinished: inpActions.length < inputLinkedTableState.limit ? true : false,
        });

        totalActionsGotten += inpActions.length;
      }

      if (totalActionsGotten >= inputLinkedTableState.limit) return;

      /** Get actions with input field filled if not every action of this type is already on list */
      if (!inputTableState.isFinished) {
        const inpActions = await getRelatedActions(inputWhere(props), inputTableState.limit, inputTableState.skip);
        setInputRelatedActions([...inputRelatedActions, ...inpActions]);
        /** Get Site/element related actions if there are no more input related actions to get */
        setInputTableState({
          ...inputTableState,
          skip: inputTableState.skip + inputTableState.limit,
          isFinished: inpActions.length < inputTableState.limit ? true : false,
        });

        totalActionsGotten += inpActions.length;
      }

      if (totalActionsGotten >= inputTableState.limit) return;

      /** Get actions related to site/element if not every action of this type is already on list */
      if (!siteElementTableState.isFinished) {
        const siteElementActions = await getRelatedActions(
          siteElementWhere(props),
          siteElementTableState.limit,
          siteElementTableState.skip,
        );
        setSiteElementRelatedActions([...siteElementRelatedActions, ...siteElementActions]);
        setSiteElementTableState({
          ...siteElementTableState,
          skip: siteElementTableState.skip + siteElementTableState.limit,
          isFinished: siteElementActions.length < siteElementTableState.limit ? true : false,
        });
      }
    }
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth={false}>
      {!showAllActions ? (
        <div style={{ width: '615px', height: '480px' }}>
          <div style={{ width: '100%', display: 'flex' }}>
            <span className={classes.modalTitle}>{t('createNewAction')}</span>
            {/* <Close id={`buttonCloseCreateActionModal`} classes={{ root: classes.closeIcon }} onClick={onClose} /> */}
          </div>
          <div className={classes.mainContent}>
            {alert ? (
              <div style={{ marginBottom: '4px' }}>
                <span className={classes.issueNameSpan}>{`${alert.element?.name || alert.site?.name}/${
                  alert.input.name
                }`}</span>
              </div>
            ) : null}
            {issue ? (
              <div>
                <span className={classes.issueSpan}>{`${t('issue')}: `}</span>
                <span className={classes.issueNameSpan}>
                  <Anchorme target='_blank'>{issue.name}</Anchorme>
                </span>
              </div>
            ) : null}
            {task ? (
              <div>
                <span className={classes.issueSpan}>{`${t('task')}: `}</span>
                <span className={classes.issueNameSpan}>
                  <Anchorme target='_blank'>{task.name}</Anchorme>
                </span>
              </div>
            ) : null}
            {input ? (
              <div style={{ marginBottom: '20px' }}>
                <span className={classes.issueSpan}>{`${t('input')}: `}</span>
                <span className={classes.issueNameSpan}>
                  <Anchorme target='_blank'>{input.name}</Anchorme>
                </span>
              </div>
            ) : null}
            <InputFieldGeneric
              editable={true}
              disabled={false}
              type={'string'}
              id={`action-create-title-field`}
              required
              title={t('title')}
              values={action.name ? [action.name] : []}
              handleChange={(e: string[]): void => setAction({ ...action, name: e[0] })}
              placeholder={t('addTitle')}
            />

            <div className={classes.fieldsLabel} style={{ marginBottom: '8px' }}>{`${t('description')}`}</div>
            <TextField
              // defaultValue={action.description ? [action.description] : []}
              value={action?.description}
              onChange={(e): void => {
                setAction({ ...action, description: e.target.value });
              }}
              data-testid={`action-create-description-field`}
              type={'string'}
              multiline
              fullWidth
              size={'small'}
              maxRows={4}
              variant='outlined'
              onBlur={(e): void => {
                setAction({ ...action, description: e.target.value });
              }}
              style={{ marginBottom: '15px' }}
              classes={{ root: classes.formControl }}
              placeholder={t('addDescription')}
            />
            {execution ? (
              <div style={{ display: 'flex', marginBottom: '10px' }} key={status}>
                <Chip
                  label={t('pending')}
                  onClick={() => setStatus('PENDING')}
                  data-testid={`action-create-status-pending`}
                  className={status === 'PENDING' ? `${classes.statusPending} ${classes.chip}` : classes.chip}
                />
                <Chip
                  label={t('cantDo')}
                  onClick={() => setStatus('CANT_DO')}
                  data-testid={`action-create-status-cant-do`}
                  className={status === 'CANT_DO' ? `${classes.statusCantDo} ${classes.chip}` : classes.chip}
                />
                <Chip
                  label={t('doing')}
                  onClick={() => setStatus('DOING')}
                  data-testid={`action-create-status-doing`}
                  className={status === 'DOING' ? `${classes.statusDoing} ${classes.chip}` : classes.chip}
                />
                <Chip
                  label={t('solved')}
                  onClick={() => setStatus('SOLVED')}
                  data-testid={`action-create-status-solved`}
                  className={status === 'SOLVED' ? `${classes.statusSolved} ${classes.chip}` : classes.chip}
                />
              </div>
            ) : null}
            <InputFieldGeneric
              editable={true}
              disabled={false}
              type={'file'}
              id={`action-create-add-file-field`}
              title={t('addFile')}
              values={action.files}
              handleChange={(e: string[]): void => setAction((action) => ({ ...action, files: e }))}
            />
            <div style={expandedDetails ? { paddingBottom: '15px' } : {}} className={classes.moreDetailsContainer}>
              <div
                className={classes.moreDetailsHeaderDiv}
                data-testid={`action-create-change-expand-details`}
                onClick={() => setExpandedDetails(!expandedDetails)}
              >
                <span className={classes.moreDetailsHeader}>{t('moreDetails')}</span>
                {expandedDetails ? (
                  <ExpandLess className={classes.expandIcon} />
                ) : (
                  <ExpandMore className={classes.expandIcon} />
                )}
              </div>
              {expandedDetails ? (
                <div>
                  <BetterFieldPopover
                    disabled={false}
                    title={t('Assign to')}
                    data-testid={`action-create-aassign-to-field`}
                    context={['assignee', 'labelAccount']}
                    multiple
                    value={[...action.assignedLabelValues, ...action.assignedAccounts]}
                    onChange={(e) => {
                      const [accs, lbls] = partition(e, (e) => e.__typename === 'Account');

                      setAction((action) => ({
                        ...action,
                        assignedAccounts: accs as NexusGenFieldTypes['Account'][],
                        assignedLabelValues: lbls as NexusGenFieldTypes['LabelValue'][],
                      }));
                    }}
                    placeholder={t('selectAccounts')}
                  />
                  <BetterFieldPopover
                    title={t('siteOrElement')}
                    context={'siteElement'}
                    data-testid={`action-create-site-or-element-field`}
                    disabled={false}
                    //@ts-ignore
                    value={action.element || action.site}
                    onChange={(e): void => {
                      if (e)
                        setAction((action) => ({
                          ...action,
                          element: e.__typename.endsWith('Site') ? undefined : e,
                          site: e.__typename.endsWith('Site') ? e : e.site,
                        }));
                      else {
                        setElement(null);
                        setSite(null);

                        setAction((action) => ({ ...action, element: null, site: null }));
                      }
                    }}
                    placeholder={t('selectSite/Element')}
                  />
                  <BetterFieldPopover
                    title={t('Labels')}
                    data-testid={`action-create-labels-field`}
                    context={'labelAction'}
                    multiple
                    value={action.labelValues}
                    onChange={(e) => setAction((action) => ({ ...action, labelValues: e as any[] }))}
                    placeholder={t('selectLabels')}
                  />
                  <InputFieldGeneric
                    editable={true}
                    disabled={false}
                    type={'datetime'}
                    title={t('dueDate')}
                    id={`action-create-due-date-field`}
                    values={action.dueDate ? [moment(action.dueDate).toISOString()] : []}
                    handleChange={(e: string[]): void => setAction((action) => ({ ...action, dueDate: e[0] }))}
                    placeholder={t('addDate')}
                  />
                </div>
              ) : undefined}
            </div>
          </div>
          <div className={classes.divFooter}>
            <DefaultButton
              data-testid={'action-create-cancel-button'}
              variant={'discard'}
              onClick={onClose}
              disabled={saving}
            >
              {t('cancel')}
            </DefaultButton>
            <DefaultButton
              data-testid={'action-create-button-create'}
              onClick={() => createNewAction()}
              disabled={saving}
            >
              {t('createAction')}
            </DefaultButton>
          </div>
        </div>
      ) : (
        <div className={classes.dialogMainContainer}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', margin: '20px 24px 0px 20px' }}>
              <span className={classes.modalTitle}>{t('actions')}</span>

              <div style={{ position: 'absolute', right: '24px' }}>
                <DefaultButton
                  data-testid={'buttonCreateAction'}
                  variant={'discard'}
                  noBorder
                  size={'small'}
                  onClick={() => {
                    if (
                      input &&
                      //@ts-ignore
                      input[input.type].onResponse.find(
                        //@ts-ignore
                        (onResp) => onResp.ifValues[0] === input[input.type]?.values[0],
                      ) &&
                      //@ts-ignore
                      input[input.type].onResponse.find((onResp) => onResp.ifValues[0] === input[input.type].values[0])
                        .actionDraft
                    ) {
                      props
                        .getActionDraft({
                          //@ts-ignore
                          _id: input[input.type].onResponse.find(
                            //@ts-ignore
                            (onResp) => onResp.ifValues[0] === input[input.type].values[0],
                          ).actionDraft,
                        })
                        .then((resp) => {
                          let newDueDate = undefined;
                          if (resp.dueDate) {
                            newDueDate = moment().add(resp.dueDate.amount, resp.dueDate.timeUnit);
                          }
                          setAction((action) => ({
                            ...action,
                            assignedAccounts: resp.assignedAccounts?.length
                              ? resp.assignedAccounts
                              : action.assignedAccounts,
                            assignedLabelValues: resp.assignedLabelValues?.length
                              ? resp.assignedLabelValues
                              : action.assignedLabelValues,
                            name: resp.name ? resp.name : action.name,
                            labelValues: resp.labelValues?.length ? resp.labelValues : action.labelValues,
                            dueDate: newDueDate ? newDueDate.toDate() : action.dueDate,
                          }));
                        });
                    }
                    setShowAllActions(false);
                  }}
                  loader={saving}
                >
                  {t('createAction')}
                </DefaultButton>
              </div>
            </div>
            <span className={classes.subTitle}>{t('linkExistentActions')}</span>
          </div>
          <div style={{ height: '315px', overflow: 'auto' }} onScroll={handleScroll}>
            {!inputRelatedActions.length && !siteElementRelatedActions.length && (
              <div className={classes.nothingToShow}>{t('nothingToShow')}</div>
            )}
            {renderActionsList()}
          </div>
          <div className={classes.divFooter}>
            <DefaultButton data-testid='buttonCancel' variant={'discard'} onClick={onClose} loader={saving}>
              {/* props.answerInput ? t('later') :  */ t('cancel')}
            </DefaultButton>
            <DefaultButton
              data-testid='save'
              onClick={async () => await handleSave()}
              // disabled={JSON.stringify(actionsFromInput) === JSON.stringify(initialActions) ? true : false}
            >
              {t('save')}
            </DefaultButton>
          </div>
        </div>
      )}
    </Dialog>
  );
};

const mapStateToProps = (): Record<string, never> => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getElementToTree,
      createAction,
      showSnackbar,
      getElementsLoadMore,
      getActions,
      getActionsCount,
      updateAction,
      getActionDraft,
      linkInputsToManyActions,
    },
    dispatch,
  );

export default compose<any>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(withDialogBoundary(ModalCreateAction));
