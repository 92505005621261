import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divHeader: {
      width: '100%',
      textAlignLast: 'justify',
      display: 'flex',
      '&:hover': {
        '& > svg': {
          visibility: 'visible',
        },
      },
    },
    sortArrowIcon: {
      fontSize: '17px',
      marginLeft: 'auto',
      marginRight: '10px',
      '&:hover': {
        cursor: 'pointer',
      },
      '&<div': {
        visibility: 'visible',
      },
    },
    columnSorting: {
      color: theme.palette.primary.main,
    },
    sortArrowIconVisible: {
      visibility: 'visible',
    },
    sortArrowIconHidden: {
      visibility: 'hidden',
    },
  }),
);
