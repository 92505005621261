import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.primary.main,
    },
    mainContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: '40px',
      paddingTop: '80px',
      flexDirection: 'column',
      overflow: 'auto',
    },
    levelContainer: {
      display: 'flex',
      width: '1000px',
      height: '120px',
      background: theme.palette.background.background1,
      borderRadius: '4px',
      padding: '8px 18px',
      marginBottom: '16px',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    titleContainer: {
      width: '30%',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      paddingTop: '8px',
    },
    descriptionContainer: {
      width: '60%',
      display: 'flex',
      justifyContent: 'flex-start',
      paddingTop: '8px',
    },
    descriptionText: {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: theme.palette.text.primary,
      wordBreak: 'break-word',
    },
    btnContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      justifyContent: 'flex-end',
      padding: '16px',
      marginTop: 'auto',
    },
    formControl: {
      width: '100%',
      '& .MuiOutlinedInput-multiline': {
        padding: '16px',
        fontSize: '14px',
      },
      // focused color for input with variant='outlined'
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.grey.light,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.text.primary,
        },
      },
    },
    inputTitle: {
      fontSize: '14px',
      fontFamily: 'Poppins',
      lineHeight: '16px',
      letterSpacing: '1.25px',
      fontWeight: 500,
    },
    inputDescription: {
      fontFamily: 'Open Sans',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      fontWeight: 400,
    },
    inUseCheckboxDiv: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '24px',
    },
    checkboxIcon: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
    checkboxIconBlank: {
      cursor: 'pointer',
      color: theme.palette.grey.main,
    },
    highlightedLevelContainer: {
      display: 'flex',
      width: '1000px',
      height: '120px',
      background: theme.palette.background.background1,
      borderRadius: '4px',
      padding: '8px 18px',
      marginBottom: '24px',
      '&:last-child': {
        marginBottom: 0,
      },
      border: `2px solid ${theme.palette.primary.main}`,
    },
    subTitle: {
      display: 'flex',
      width: '1000px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: '24px',
    },
    skillsSubtitleHelperText: {
      color: theme.palette.grey.main,
    },
    checkboxIconDisabled: {
      cursor: 'not-allowed',
      color: theme.palette.grey.main,
    },
    hr: {
      opacity: 0.3,
      color: theme.palette.grey.light,
      borderWidth: 1,
      marginBottom: '16px',
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflow: 'hidden',
    },
  });
