import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';

const LDAPCreateDefaultRefresh = () => lazy(() => import('src/modules/ldap/ldap.create.default'));

let LDAPCreateDefault = LDAPCreateDefaultRefresh();

export const render = {
  create: {
    default: ({ ...props }) => {
      if (LDAPCreateDefault?._result?.type === 'error') {
        LDAPCreateDefault = LDAPCreateDefaultRefresh();
      }
      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <LDAPCreateDefault {...props} />
        </Suspense>
      );
    },
  },
};

export default render;
