import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { styles } from 'src/base/on-boarding/components/styles';
import { useMainContainerContext } from 'src/base/main-container/context/main.container.context';
import { updateLoggedUser } from 'src/base/login/login.redux';
import { CheckCircle } from '@mui/icons-material';
import {
  deleteOneIssueTemplateDraft,
  createIssueTemplate,
  upsertIssueTemplateFromDraft,
  getIssueTemplateFolders,
} from 'src/modules/issue-templates/issue.templates.redux';
import {
  deleteIssueDraft,
  getCatalogFolders,
  createIssueCatalogDraft,
  runIssues,
  draftToIssue,
} from 'src/modules/issue-catalogs/issue.catalogs.redux';
import { getStateMachines } from 'src/modules/state-machine/state-machine.redux';
import { deleteIssue } from 'src/modules/issue-history/issue.history.redux';
import { changeStateIssueInstance } from 'src/modules/issue-execution/issue.execution.redux';
import { handleNextStep } from '../utils/onboarding.utils.handleNextStep';
import { handleRedoStep } from '../utils/onboarding.utils.handleRedoStep';
import DefaultButton from 'src/utils/components/default-button/default-button';
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export type FinishStepModalProps = {
  loggedUser: any;
} & ConnectedProps<typeof connecter>;

const useStyles = makeStyles(styles);
const FinishStepModal: React.FC<FinishStepModalProps> = (props): JSX.Element => {
  const state = useMainContainerContext();
  const { updateMainContainerState } = state;
  const classes: ClassNameMap<string> = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [modalText, setModalText] = useState<{
    title: string;
    content: string;
    step: number;
  }>();

  useEffect(() => {
    if (state.openEndStepModal) {
      let newModalText: {
        title: string;
        content: string;
        step: number;
      };
      switch (state.currentStep) {
        case 'build':
          newModalText = {
            title: t('youBuitTemplate'),
            content: t('canGoNextStep'),
            step: 1,
          };
          break;
        case 'createTasks':
          newModalText = {
            title: t('youCreatedOpenIssue'),
            content: t('canGoNextStep'),
            step: 2,
          };
          break;
        case 'execute':
          newModalText = {
            title: t('yourIssueExecuted'),
            content: t('justOneQuickStep'),
            step: 3,
          };
          break;
      }
      setModalText(newModalText);
    }
  }, []);

  return (
    <Dialog
      open={state.openEndStepModal}
      classes={{
        root: classes.endStepModal,
        paperWidthSm: classes.leaveModalWidth,
      }}
      onClose={() => updateMainContainerState({ openEndStepModal: false })}
    >
      <DialogTitle style={{ padding: '18px 24px 0 24px' }}>
        <CheckCircle fontSize={'large'} className={classes.checkCircle} />
        <Typography className={classes.taskInputTitle}>{modalText?.title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography className={classes.taskInputContent}>{modalText?.content}</Typography>
      </DialogContent>
      <DialogActions classes={{ root: classes.skipStartContainer }}>
        <DefaultButton
          data-testid={'re-do-step-button'}
          variant={'outlined'}
          className={classes.defaultBtnPadding}
          onClick={() => handleRedoStep(state, props, navigate, updateMainContainerState, t)}
          label={`${t('redoStep')} ${modalText?.step}`}
        />
        <DefaultButton
          className={classes.defaultBtnPadding}
          data-testid={'next-step-button'}
          onClick={() => handleNextStep(state, props, updateMainContainerState, navigate)}
          label={t(props.loggedUser?.onBoarding?.step === 'execute' ? 'finalStep' : 'nextStep')}
        />
      </DialogActions>
    </Dialog>
  );
};

const connecter = connect((state: any) => ({ loggedUser: state.loginReducer.loggedUser }), {
  updateLoggedUser,
  runIssues,
  createIssueCatalogDraft,
  createIssueTemplate,
  deleteIssueDraft,
  deleteIssue,
  deleteOneIssueTemplateDraft,
  getCatalogFolders,
  getStateMachines,
  changeStateIssueInstance,
  upsertIssueTemplateFromDraft,
  draftToIssue,
  getIssueTemplateFolders,
});

export default connecter(FinishStepModal);
