import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    popoverDetailsContainer: {
      width: '340px',
    },
    popoverDetailsHeader: {
      display: 'flex',
      marginTop: '10px',
    },
    closeDiv: {
      marginLeft: 'auto',
      marginRight: '10px',
      cursor: 'pointer',
    },
    divBreadcrumb: {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      color: theme.palette.text.secondary,
      fontSize: '11px',
      display: 'flex',
      alignItems: 'center',
      marginLeft: '15px',
      marginTop: '5px',
    },
    popoverTitle: {
      marginLeft: '25px',
      fontSize: '18px',
      fontWeight: 500,
      fontFamily: 'Poppins',
    },
    divFooter: {
      height: '41px',
      marginTop: '30px',
      borderTop: `1px solid ${theme.palette.text.disabled}`,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '10px',
    },
    moreInfo: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    divLocation: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: '11px',
      marginTop: '10px',
    },
    profileLabel: {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '11px',
      marginTop: '10px',
      color: theme.palette.text.secondary,
    },
    profileTitle: {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '14px',
      marginTop: '5px',
    },
    fullPopoverContainer: {
      left: 'unset !important',
      right: '0',
      top: '0 !important',
      width: '370px',
      height: '100vh',
      maxHeight: '100% !important',
    },
    fullPopoverCloseIcon: {
      marginRight: '15px',
      marginLeft: 'auto',
      cursor: 'pointer',
    },
    fullPopoverTitle: {
      fontSize: '21px',
      fontFamily: 'Poppins',
      fontWeight: 400,
      marginTop: '15px',
      marginLeft: '25px',
      paddingBottom: '15px',
    },
    fullPopoverHeader: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '20px',
      borderBottom: `1px solid ${theme.palette.text.disabled}`,
      paddingBottom: '20px',
    },
    fullPopoverBody: {},
    formGroup: {
      display: 'block',
      overflowWrap: 'break-word',
      marginBottom: '12px',
    },
    infoLabel: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      lineHeight: '24px',
      fontSize: '16px',
      marginBottom: '6px',
      marginRight: '10px',
      marginLeft: '10px',
    },
    divContainer: {
      marginBlockEnd: '12px',
    },
    mainInfo: {
      marginLeft: '16px',
      marginRight: '16px',
      marginTop: '25px',
    },
    divName: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '10px',
      marginRight: '10px',
    },
    icon: {
      fontSize: '18px',
      marginRight: '10px',
    },
    name: {
      fontSize: '16px',
      fontWeight: 500,
      fontFamily: 'Poppins',
    },
    divPath: {
      fontSize: '12px',
      color: theme.palette.text.secondary,
      fontFamily: 'Open Sans',
      marginTop: '5px',
      marginLeft: '10px',
      marginRight: '10px',
    },
    photoContainer: {
      position: 'relative',
      marginBottom: '15px',
    },
    imgField: {
      height: '200px',
      width: '100%',
      objectFit: 'cover',
    },
    tag: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.action.disabledBackground,
      padding: '3px 6px',
      margin: '3px 5px',
      borderRadius: '4px',
    },
    tagValue: {
      marginRight: '5px',
    },
    badge: {
      backgroundColor: theme.palette.grey.main,
      color: theme.palette.common.white,
      position: 'relative',
      transform: 'none',
    },
    divDeleted: {
      backgroundColor: theme.palette.alert.main,
      height: '61px',
      width: '396px',
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      cursor: 'default',
    },
    divDeletedContent: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '10px',
      marginLeft: '10px',
      paddingRight: '28px',
    },
    deletedText: {
      marginLeft: '8px',
      fontFamily: 'Open Sans',
      fontWeight: 700,
      lineHeight: '22px',
      fontSize: '14px',
    },
    field: {
      borderTop: `1px solid ${theme.palette.grey.light}`,
      paddingTop: '16px',
      marginBottom: '15px',
    },
  });
