import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    nav: {
      backgroundColor: theme.palette.background.default,
      top: '0',
      position: 'sticky',
      zIndex: 1002,
      width: '100%',
      boxShadow:
        '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
    },
    spanInputHeader: {
      float: 'right',
      display: 'flex',
      height: '70px',
      position: 'absolute',
      right: '26px',
    },
    row: {
      marginRight: '0 !important',
      marginLeft: '0 !important',
      height: '100%',
      width: '100%',
      position: 'relative',
      border: 'none !important',
    },
    rowWithSwitch: {
      marginRight: '0 !important',
      marginLeft: '0 !important',
      height: '100%',
      width: 'calc(100% - 160px)',
      position: 'relative',
      border: 'none !important',
    },
    col: {
      padding: '0 !important',
    },
    navTabActive: {
      color: `${theme.palette.common.white} !important`,
      backgroundColor: `${theme.palette.primary.main} !important`,
      textAlign: 'center',
      cursor: 'pointer',
    },
    navTab: {
      color: `${theme.palette.common.white} !important`,
      textAlign: 'center',
      backgroundColor: 'unset',
      cursor: 'pointer',
    },
    textTab: {
      paddingLeft: '20px',
    },
    iconTab: {
      display: 'flex',
      position: 'absolute',
    },
    tabSelected: {
      backgroundColor: `${theme.palette.background.background0} !important`,
      color: theme.palette.primary.main,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      flexBasis: 'unset',
    },
    switchDiv: {
      width: '100%',
    },
    tabs: {
      width: '100%',
      borderBottom: 'none',
      display: 'flex',
    },
    indicator: {
      display: 'hidden',
      backgroundColor: 'transparent',
    },
    gridBackground: {
      backgroundColor: theme.palette.background.background1,
    },
    tab: {
      backgroundColor: theme.palette.background.default,
      fontSize: theme.typography.fontSize,
      fontFamily: '"Open Sans", sans-serif !important;',
    },
  });
