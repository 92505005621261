import modelToImage from 'src/utils/components/ar-model-render/modelToImage';
import VideoSnapshot from 'video-snapshot';

export const dataUrlToFile = async (dataUrl: string, fileName: string): Promise<File> => {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: 'image/png' });
};

export const dataURItoBlob = (dataURI: string): Blob => {
  const bytes =
    dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
  const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const max = bytes.length;
  const ia = new Uint8Array(max);
  for (let i = 0; i < max; i++) ia[parseInt(`${i}`)] = bytes.charCodeAt(i);
  return new Blob([ia], { type: mime });
};

export const createFileThumbnail = async (file: File): Promise<{ thumbnail: Blob; totalTriangles?: number }> => {
  if (!file) {
    return null;
  }
  let thumbnail;
  const fileIsMedia =
    file.type.match(`^video/`) &&
    file.type !== 'video/avi' &&
    file.type !== 'video/x-ms-wmv' &&
    file.type !== 'video/x-ms-asf' &&
    file.type !== 'video/mpeg';
  const fileIsImage = file.type.match(/(jpg|png|gif|webp|bmp|jpeg)$/i);
  const fileIs3DModel = file.name.endsWith('.gltf') || file.name.endsWith('.glb');
  if (fileIsMedia) {
    const snapThumbnail = new VideoSnapshot(file);
    const previewSr = await snapThumbnail.takeSnapshot(0);
    const previewFile = await dataUrlToFile(previewSr, 'preview_video');
    thumbnail = await resizeImage({ maxSize: 200, file: previewFile });
  } else if (fileIsImage) {
    thumbnail = await resizeImage({ maxSize: 200, file: file });
  } else if (fileIs3DModel) {
    const { blob, totalTriangles } = await create3DFileThumbnail(file);
    thumbnail = await resizeImage({ file: blob, maxSize: 200 });
    return { thumbnail, totalTriangles };
  }
  return { thumbnail };
};

interface IResizeImageOptions {
  maxSize: number;
  file: Blob;
}

export const create3DFileThumbnail = async (file: Blob) => {
  const modelUrl = URL.createObjectURL(file);

  try {
    console.warn = () => {};
    const { base64Data, totalTriangles } = await modelToImage(modelUrl);
    const byteCharacters = atob(base64Data.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' });

    return { blob, totalTriangles };
  } catch (error) {
    console.error('Error creating 3D file thumbnail:', error);
    throw error;
  }
};

export const resizeImage = (settings: IResizeImageOptions) => {
  const file = settings.file;
  const maxSize = settings.maxSize;
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement('canvas');
  const type = settings.file.type;

  const resize = () => {
    let width = image.width;
    let height = image.height;

    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      }
    } else {
      if (height > maxSize) {
        width *= maxSize / height;
        height = maxSize;
      }
    }

    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d')?.drawImage(image, 0, 0, width, height);
    const nType: string | undefined = type?.toString();
    const dataUrl = canvas.toDataURL(nType);
    return dataURItoBlob(dataUrl);
  };

  return new Promise((ok, no) => {
    if (!file.type.match(/image.*/)) {
      no(new Error('Not an image'));
      return;
    }

    reader.onload = (readerEvent) => {
      image.onload = () => ok(resize());
      image.src = readerEvent.target.result.toString();
    };

    reader.readAsDataURL(file);
  });
};
export const checkImage = (extension: string) => {
  return extension.match(/(jpg|png|gif|webp|tiff|bmp|jpeg)$/i);
};

export const checkVideo = (extension: string) => {
  return extension.match(/(webm|mpg|mp2|mpeg|mpe|mpv|ogg|mp4|m4p|m4v|avi|wmv|mov|qt|flv|swf|avchd)$/i);
};
