import gql from 'graphql-tag';
import { LABEL_VALUE } from './label';

export const MATERIAL = gql`
  fragment material on Material {
    type
    name
    code
    description
    brand
    brandModel
    unit {
      name
      symbol
      _id
    }
    price
    iva
    sellingPrice
    _id
    quantity
    dimension {
      height
      width
      lenght
      unit
    }
    labelValues {
      ...labelValue
    }
    images {
      _id
      name
    }
  }
  ${LABEL_VALUE}
`;

export const TOOL = gql`
  fragment tool on Tool {
    _id
    partNumber
    designation
    description
    dimension {
      height
      width
      length
      unit
    }
    images {
      _id
      name
    }
    labelValues {
      ...labelValue
    }
  }
  ${LABEL_VALUE}
`;

export const STORAGE = gql`
  fragment storage on Storage {
    _id
    name
    description
    address {
      type
      street
      site {
        _id
        name
      }
      element {
        _id
        name
      }
    }
  }
`;

export const LOCATION = gql`
  fragment location on Location {
    _id
    description
    storage {
      ...storage
    }
    address {
      type
      street
      site {
        _id
        name
      }
      element {
        _id
        name
      }
    }
  }
`;

export const MATERIAL_ITEM = gql`
  fragment materialItem on MaterialItem {
    _id
    quantity
    createdAt
    expirationDate
    expirationDateType
    description
    code
    serialNumber
    buyOrder
    state
    location {
      ...location
    }
    material {
      ...material
    }
    hiddenFields
    customFields {
      type
      name
      string
      number
      datetime
    }
  }
`;

export const TOOL_ITEM = gql`
  fragment toolItem on ToolItem {
    _id
    available
    code
    description
    serialNumber
    status
    createdAt
    location {
      ...location
    }
    tool {
      ...tool
    }
    hiddenFields
    customFields {
      type
      name
      string
      number
      datetime
    }
    associatedWith {
      _id
      code
      location {
        _id
        storage {
          _id
          name
        }
      }
      tool {
        _id
        designation
        partNumber
      }
    }
  }
`;
