import { Chip, TextField, Select, MenuItem } from '@mui/material';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { styles } from 'src/utils/components/generic-input-card/styles';
import { Close, ReportProblemOutlined } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';

const AlertChip = (props) => {
  const { classes, input, setInput, active, refNumberCondFirstSelect, refNumberCondSecondSelect } = props;
  const [custom, setCustom] = useState<boolean>(false);

  useEffect(() => {
    const trigger = input.datetime?.triggerAlert?.amount + input.datetime?.triggerAlert?.showIn;
    if (
      input.datetime?.triggerAlert &&
      trigger !== '1DAYS' &&
      trigger !== '2DAYS' &&
      trigger !== '1WEEKS' &&
      trigger !== '2WEEKS' &&
      trigger !== '1MONTHS'
    ) {
      setCustom(true);
    }
  }, []);

  const showChip = (): JSX.Element => {
    if (input.subType === 'date' && input.datetime?.triggerAlert) {
      return (
        <Chip
          icon={<ReportProblemOutlined classes={{ root: classes.iconAlertChip }} />}
          label={
            <div className={classes.divLabel}>
              <span className={classes.spanAlert}>{`${props.t('createAlert')}`}</span>
              <Select
                value={custom ? 'custom' : input.datetime?.triggerAlert.amount + input.datetime?.triggerAlert.showIn}
                disabled={!active}
                style={{ marginLeft: '10px' }}
                variant={'standard'}
                MenuProps={{ ref: refNumberCondFirstSelect }}
                classes={{ select: classes.selectConditional }}
                onChange={(e) => {
                  if (e.target.value !== 'custom') {
                    if (custom) {
                      setCustom(false);
                    }
                    setInput({
                      _id: input._id,
                      datetime: {
                        ...input.datetime,
                        triggerAlert: {
                          ...input.datetime.triggerAlert,
                          amount: parseInt(e.target.value.toString().charAt(0)),
                          showIn: e.target.value.toString().substring(1),
                        },
                      },
                    });
                  } else {
                    setCustom(true);
                  }
                }}
              >
                <MenuItem id={'menuItem1Day'} data-testid={'menuItem1Day'} value={'1DAYS'}>
                  {props.t('1DayBefore')}
                </MenuItem>
                <MenuItem id={'menuItem2Days'} data-testid={'menuItem2Days'} value={'2DAYS'}>
                  {props.t('2DayBefore')}
                </MenuItem>
                <MenuItem id={'menuItem1Week'} data-testid={'menuItem1Week'} value={'1WEEKS'}>
                  {props.t('1WeekBefore')}
                </MenuItem>
                <MenuItem id={'menuItem2Weeks'} data-testid={'menuItem2Weeks'} value={'2WEEKS'}>
                  {props.t('2WeekBefore')}
                </MenuItem>
                <MenuItem id={'menuItem1Month'} data-testid={'menuItem1Month'} value={'1MONTHS'}>
                  {props.t('1MonthBefore')}
                </MenuItem>
                <MenuItem id={'menuItemCustom'} data-testid={'menuItemCustom'} value={'custom'}>{`${props.t(
                  'custom',
                )}...`}</MenuItem>
              </Select>
              {custom ? (
                <>
                  <div style={{ marginLeft: '10px' /* marginTop: "10px"  */ }}>
                    <TextField
                      value={input.datetime.triggerAlert.amount}
                      variant={'standard'}
                      type={'number'}
                      disabled={!active}
                      InputProps={{ classes: { input: classes.textFieldAmountAlert } }}
                      onChange={(e): void => {
                        setInput({
                          _id: input._id,
                          datetime: {
                            ...input.datetime,
                            triggerAlert: { ...input.datetime.triggerAlert, amount: parseInt(e.target.value) },
                          },
                        });
                      }}
                    />
                  </div>
                  <div style={{ marginLeft: '5px' /*  marginTop: "1px" */ }}>
                    <Select
                      value={input.datetime?.triggerAlert.showIn}
                      disabled={!active}
                      variant={'standard'}
                      style={{ marginLeft: '10px' }}
                      MenuProps={{ ref: refNumberCondSecondSelect }}
                      classes={{ select: classes.selectConditional }}
                      onChange={(e) => {
                        setInput({
                          _id: input._id,
                          datetime: {
                            ...input.datetime,
                            triggerAlert: {
                              ...input.datetime.triggerAlert,
                              showIn: e.target.value.toString(),
                            },
                          },
                        });
                      }}
                    >
                      <MenuItem id={'menuItemDays'} data-testid={'menuItemDays'} value={'DAYS'}>
                        {props.t('daysBefore')}
                      </MenuItem>
                      <MenuItem id={'menuItemWeeks'} data-testid={'menuItemWeeks'} value={'WEEKS'}>
                        {props.t('weeksBefore')}
                      </MenuItem>
                      <MenuItem id={'menuItemMonths'} data-testid={'menuItemMonths'} value={'MONTHS'}>
                        {props.t('monthsBefore')}
                      </MenuItem>
                    </Select>
                  </div>
                </>
              ) : null}
              {!active ? null : (
                <Close
                  classes={{ root: classes.closeIcon }}
                  onClick={(): void => {
                    setInput({
                      _id: input._id,
                      datetime: { ...input.datetime, triggerAlert: undefined },
                    });
                  }}
                />
              )}
            </div>
          }
          classes={{ root: /* props.error ? classes.advancedChipError : */ classes.advancedAlertChip }}
        />
      );
    } else return <div />;
  };

  return <div className={classes.numberChipCondContainer}>{showChip()}</div>;
};

export default compose<any>(withTranslation('translation'), withStyles(styles))(AlertChip);
