import { styles } from 'src/utils/components/label-field/styles';
import { Avatar, Chip, TextField, Tooltip, InputAdornment, useTheme } from '@mui/material';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { labelValuesPaginated } from 'src/modules/label/label.redux';
import { accountsPaginated } from 'src/modules/account/account.redux';
import { withTranslation } from 'react-i18next';
import { AssignToField } from 'src/interfaces/account';
import ReactLoading from 'react-loading';
import NavigationBar from 'src/utils/components/navigation-bar';
import { setChangeAssign } from 'src/utils/funcs/store/actions';
import * as PropTypes from 'prop-types';
import { useState, useRef, useEffect } from 'react';
import { Clear, ExpandMore, Warning } from '@mui/icons-material';
import DetailsPopover from 'src/utils/components/details-popover';
import useDebounce from 'src/utils/hooks/useDebounce';
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from '@mui/icons-material/Search';
import { Account, LabelValue } from 'src/gql/graphql';

interface DispatchToProps {
  labelValuesPaginated: (pageLabels, itemsByPage, filter) => void;
  accountsPaginated: (pageLabels, itemsByPage, filter) => void;
}

interface AccountFilter {
  name_contains?: string;
  OR?: AccountFilter[];
}

interface LabelFilter {
  value_contains?: string;
  label?: {
    name_contains?: string;
    context_eq: string;
  };
  OR?: LabelFilter[];
}

interface SelectedLV {
  selectedLabel?: LabelValue;
  dispatch?: {
    action: string;
    type: string;
    contextKey: any;
    context: any;
    payload: LabelValue;
  };
}

interface SelectedAcc {
  selectedAccount: Account;
  dispatch?: {
    action: string;
    type: string;
    contextKey: any;
    context: any;
    payload: Account;
  };
}

let totalPagesLabels = 0;
let pageLabels = 1;
let totalPagesAccounts = 0;
let pageAccounts = 1;
let labelValues: LabelValue[] = [];
let searchLabelValues: LabelValue[] = [];
let accounts: any = [];
let selectedLV: SelectedLV[] = [];
let selectedAcc: SelectedAcc[] = [];
let searchAccountsList: any = [];
let isFirstRender = true;

const AssignToFieldComponent = (props: AssignToField): JSX.Element => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedLabelValues, setSelectedLabelValues] = useState<LabelValue[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<Account[]>([]);
  const [tabActive, setTabActive] = useState<number>(1);
  const [popoverDetails, setPopoverDetails] = useState<any>(null);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [searchAccountString, setSearchAccountString] = useState<string>('');
  const [searchLabelString, setSearchLabelString] = useState<string>('');
  const refDropdown = useRef<HTMLDivElement>(null);
  const refInput = useRef<HTMLDivElement>(null);
  const itemsByPage = 10;
  const [filterAccounts, setFilterAccounts] = useState<AccountFilter>({});
  const [filterLabelValues, setFilterLabelValues] = useState<LabelFilter>({ label: { context_eq: 'Account' } });
  const theme = useTheme();

  useEffect(() => {
    isFirstRender = true;
    totalPagesLabels = 0;
    pageLabels = 1;
    totalPagesAccounts = 0;
    pageAccounts = 1;
    labelValues = [];
    searchLabelValues = [];
    accounts = [];
    selectedLV = [];
    selectedAcc = [];
    searchAccountsList = [];
  }, []);

  const getLabelValues = (): void => {
    setLoading(true);
    props.labelValuesPaginated(pageLabels - 1, itemsByPage, filterLabelValues).then((resp) => {
      totalPagesLabels = Math.ceil(resp.count / itemsByPage);
      if (pageLabels > 1) {
        labelValues = [...labelValues, ...resp.labelValues];
      } else {
        labelValues = resp.labelValues;
      }
      searchLabelValues = labelValues;
      setLoading(false);
    });
  };

  const getAccounts = (): void => {
    setLoading(true);
    props.accountsPaginated(pageAccounts - 1, itemsByPage, filterAccounts).then((resp) => {
      totalPagesAccounts = Math.ceil(resp.count / itemsByPage);
      if (pageAccounts > 1) {
        accounts = [...accounts, ...resp.accounts];
      } else {
        accounts = resp.accounts;
      }
      searchAccountsList = accounts;
      setLoading(false);
    });
  };

  useDebounce(getAccounts, [searchAccountString], 200);

  useDebounce(getLabelValues, [searchLabelString], 200);

  const handleClick = (e): void => {
    if (
      refDropdown.current &&
      !refDropdown.current.contains(e.target) &&
      refInput.current &&
      !refInput.current.contains(e.target)
    ) {
      setDropdownOpen(false);
      setSearchAccountString('');
      setSearchLabelString('');
      setTabActive(1);
      setFilterAccounts({});
    }
  };

  const saveOnClose = (): void => {
    const assignedValues = {
      assignedAccounts: selectedAccounts,
      assignedLabelValues: selectedLabelValues,
    };

    props.onChange(assignedValues);
  };

  const isBottom = (e): boolean => {
    return parseFloat(e.target.scrollHeight) - parseFloat(e.target.clientHeight) <= parseFloat(e.target.scrollTop) + 10;
  };

  const onScroll = (e): void => {
    if (isBottom(e) && pageAccounts != totalPagesAccounts) {
      pageAccounts = pageAccounts + 1;
      getAccounts();
    }
  };

  const onScrollLabels = (e): void => {
    if (isBottom(e) && pageLabels != totalPagesLabels) {
      pageLabels = pageLabels + 1;
      getLabelValues();
    }
  };

  useEffect(() => {
    if (!dropdownOpen && !isFirstRender) {
      saveOnClose();
    }

    if (dropdownOpen) {
      isFirstRender = false;
    }
  }, [dropdownOpen]);

  useEffect(() => {
    totalPagesLabels = 0;
    pageLabels = 1;
    labelValues = [];
    totalPagesAccounts = 0;
    pageAccounts = 1;
    accounts = [];
    document.addEventListener('mousedown', handleClick, false);
    return (): void => {
      document.removeEventListener('mousedown', handleClick, false);
    };
  }, []);

  useEffect(() => {
    setSelectedLabelValues(props.labelValues ? props.labelValues : []);
  }, [props.labelValues]);

  useEffect(() => {
    setSelectedAccounts(props.accounts ? props.accounts : []);
  }, [props.accounts]);

  const renderLv = (labelValue, dropdown, i): JSX.Element => (
    <span key={i} className={!dropdown ? props.classes.spanLabel : props.classes.spanLabelDropdown}>
      <Tooltip
        title={labelValue.value}
        placement='top'
        arrow
        classes={{ tooltip: props.classes.tooltipCustom, arrow: props.classes.arrowCustom }}
      >
        <Chip
          avatar={
            <Avatar classes={{ root: props.classes.avatarL }} style={{ backgroundColor: labelValue.backgroundColor }}>
              {labelValue.label.name}
            </Avatar>
          }
          label={labelValue.value}
          style={{
            borderColor: labelValue.backgroundColor,
            color: labelValue.backgroundColor,
          }}
          deleteIcon={<Clear style={{ fontSize: '14px', color: labelValue.backgroundColor }} />}
          onDelete={
            !dropdown && !props.disabled
              ? () => {
                  setSelectedLabelValues(selectedLabelValues.filter((labelVal) => labelVal._id !== labelValue._id));
                  props.onRemoveLabelValuesChip(
                    selectedLabelValues.filter((labelVal) => labelVal._id !== labelValue._id),
                  );
                  if (props.dispatch)
                    setChangeAssign(props.dispatch, {
                      action: 'remove',
                      type: 'labelValue',
                      contextKey: props.contextKey,
                      context: props.context,
                      payload: labelValue,
                    });

                  selectedLV = selectedLV.filter((labelVal) => labelVal.selectedLabel._id !== labelValue._id);
                }
              : undefined
          }
          color={'primary'}
          variant='outlined'
          size='small'
        />
      </Tooltip>
    </span>
  );

  const renderAccount = (account, dropdown, i): JSX.Element => (
    <span key={i} className={!dropdown ? props.classes.spanAccount : props.classes.spanAccountDropdown}>
      <Tooltip
        title={account.name}
        placement='top'
        arrow
        classes={{ tooltip: props.classes.tooltipCustom, arrow: props.classes.arrowCustom }}
      >
        <Chip
          avatar={
            account.photo ? (
              <Avatar src={`data:image/webp;base64,${account.photo}`} />
            ) : (
              <Avatar>{account.name.charAt(0).toUpperCase()}</Avatar>
            )
          }
          label={
            !dropdown ? (
              <span
                className={
                  popoverDetails && selectedValue._id === account._id
                    ? props.classes.accountNameActive
                    : props.classes.accountNameWithHover
                }
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedValue(account);
                  setPopoverDetails(e.target);
                }}
              >
                {account.name}
              </span>
            ) : (
              account.name
            )
          }
          deleteIcon={<Clear style={{ fontSize: '14px' }} />}
          onDelete={
            !dropdown && !props.disabled
              ? () => {
                  setSelectedAccounts(selectedAccounts.filter((acc) => acc._id !== account._id));
                  props.onRemoveAccountsChip(selectedAccounts.filter((acc) => acc._id !== account._id));
                  if (props.dispatch)
                    setChangeAssign(props.dispatch, {
                      action: 'remove',
                      type: 'account',
                      contextKey: props.contextKey,
                      context: props.context,
                      payload: account,
                    });

                  selectedAcc = selectedAcc.filter((acc) => acc.selectedAccount._id !== account._id);
                }
              : undefined
          }
          variant='outlined'
          size='small'
          classes={{ root: props.classes.chipRoot }}
        />
      </Tooltip>
    </span>
  );

  const toggle = (): void => {
    if (!labelValues.length) {
      getLabelValues();
      setAuxArrays();
    }
    if (!props.disabled) {
      if (!dropdownOpen) {
        setSearchAccountString('');
        setSearchLabelString('');
        setTabActive(1);
        setFilterAccounts({});
      }
      setDropdownOpen(!dropdownOpen);
    }
  };

  const setAuxArrays = (): void => {
    if (selectedLabelValues.length) {
      // Write on auxiliar array the selected values we have
      selectedLV = selectedLabelValues.map((lv) => {
        if (props.dispatch) {
          return {
            selectedLabel: lv,
            dispatch: {
              action: 'add',
              type: 'labelValue',
              contextKey: props.contextKey,
              context: props.context,
              payload: lv,
            },
          };
        } else {
          return {
            selectedLabel: lv,
          };
        }
      });
    } else {
      selectedLV = [];
    }

    if (selectedAccounts.length) {
      // Write on auxiliar array the selected accounts we have
      selectedAcc = selectedAccounts.map((account) => {
        if (props.dispatch) {
          return {
            selectedAccount: account,
            dispatch: {
              action: 'add',
              type: 'account',
              contextKey: props.contextKey,
              context: props.context,
              payload: account,
            },
          };
        } else {
          return {
            selectedAccount: account,
          };
        }
      });
    } else {
      selectedAcc = [];
    }
  };

  const searchLabels = (searchString: string): void => {
    if (searchString !== searchLabelString) {
      labelValues = [];
      searchLabelValues = [];
    }
    setSearchLabelString(searchString);
    pageLabels = 1;
    if (searchString && searchString.trim() !== '') {
      setFilterLabelValues({
        OR: [
          { value_contains: searchString, label: { context_eq: 'Account' } },
          { label: { name_contains: searchString, context_eq: 'Account' } },
        ],
      });
    } else {
      searchLabelValues = [];
      setFilterLabelValues({ label: { context_eq: 'Account' } });
    }
  };

  const searchAccounts = (searchString: string): void => {
    setLoading(true);
    if (searchString !== searchAccountString) {
      accounts = [];
      searchAccountsList = [];
    }
    setSearchAccountString(searchString);
    pageAccounts = 1;
    if (searchString && searchString.trim() !== '') {
      setFilterAccounts({ OR: [{ name_contains: searchString }] });
    } else {
      setFilterAccounts({});
    }
  };

  renderLv.propTypes = {
    classes: PropTypes.object,
    disabled: PropTypes.bool,
    onRemoveLabelValuesChip: PropTypes.func,
    dispatch: PropTypes.any,
    context: PropTypes.string,
    contextKey: PropTypes.string,
  };

  renderAccount.propTypes = {
    classes: PropTypes.object,
    disabled: PropTypes.bool,
    onRemoveAccountsChip: PropTypes.func,
    context: PropTypes.string,
    contextKey: PropTypes.string,
    dispatch: PropTypes.any,
  };

  return (
    <div className={props.classes.root}>
      {props.noTitle ? null : (
        <div className={props.classes.label}>
          {props.title ? props.title : `${props.t('Assign to')} *`}
          {props.error ? <Warning classes={{ root: props.classes.warningIcon }} /> : null}
        </div>
      )}
      <div
        id={props.id}
        data-testid={props.id}
        ref={refInput}
        className={
          props.disabled
            ? props.classes.disabledWithBadge
            : props.error
              ? props.classes.withBadgeError
              : props.classes.withBadge
        }
        onClick={toggle}
      >
        {!selectedLabelValues.length && !selectedAccounts.length && props.placeholder ? (
          <div style={{ marginTop: '7px', marginLeft: '7px' }}>{props.placeholder}</div>
        ) : null}
        <div
          style={selectedLabelValues.length || selectedAccounts.length ? { marginTop: '7px', marginBottom: '7px' } : {}}
        >
          {selectedLabelValues.map((lv, i) => renderLv(lv, false, i))}
          {selectedAccounts.map((acc, i) => renderAccount(acc, false, i))}
        </div>
        <div className={props.classes.separator}>
          <div id='reset' hidden={props.disabled}>
            <ExpandMore style={{ fontSize: '24px' }} />
          </div>
        </div>
      </div>
      {dropdownOpen && !props.disabled ? (
        <div ref={refDropdown} className={`${props.classes.refDropdown}`}>
          {!props.onlyAccounts && (
            <NavigationBar
              stTitle={props.t('Labels')}
              ndTitle={props.t('Accounts')}
              tabActive={tabActive}
              setTabActive={(e): void => {
                setTabActive(e);
                if (!accounts.length && e === 2) {
                  getAccounts();
                }
              }}
              isIssueCreation={false}
            />
          )}
          <div>
            {tabActive === 1 && !props.onlyAccounts ? (
              <div className={props.classes.divDropdown} onScroll={onScrollLabels}>
                <div className={props.classes.first}>
                  <TextField
                    id={`${props.id}SearchLabelValues`}
                    key={'labels'}
                    title={''}
                    variant='outlined'
                    type={'text'}
                    placeholder={`${props.t('search')}...`}
                    onChange={(e): void => searchLabels(e.target.value)}
                    value={searchLabelString}
                    fullWidth
                    className={props.classes.textField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' className={props.classes.inputAdornment}>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className={props.classes.second}>
                  {props.t('Selected')} ({selectedLabelValues.length})
                </div>
                <div className={props.classes.scrollableArea} onScroll={onScrollLabels}>
                  {loading && !labelValues.length ? (
                    <div
                      className={props.classes.middle}
                      style={{ justifyContent: 'center', fontWeight: 'bold', display: 'flex' }}
                      id={`${props.id}LoadMore`}
                    >
                      <ReactLoading
                        type={'spin'}
                        className={`${props.classes.spinner}`}
                        color={theme.palette.primary.main}
                        height={15}
                        width={15}
                      />
                    </div>
                  ) : (
                    (searchLabelString.length > 0 ? searchLabelValues : labelValues).map((lv, key) => (
                      <div
                        key={key}
                        className={
                          key === labelValues.length - 1 && pageLabels === totalPagesLabels
                            ? props.classes.last
                            : props.classes.middle
                        }
                        id={`${props.id}LabelValues`}
                        onClick={(): void => {
                          if (selectedLabelValues.filter((labelVal) => labelVal._id === lv._id).length) {
                            setSelectedLabelValues(selectedLabelValues.filter((labelVal) => labelVal._id !== lv._id));
                            if (props.dispatch) {
                              selectedLV.filter((labelVal) => {
                                if (labelVal.selectedLabel._id === lv._id) {
                                  labelVal.dispatch = {
                                    action: 'remove',
                                    type: 'labelValue',
                                    contextKey: props.contextKey,
                                    context: props.context,
                                    payload: lv,
                                  };
                                }
                              });
                            } else {
                              selectedLV = selectedLV.filter((labelVal) => labelVal.selectedLabel._id !== lv._id);
                            }
                          } else {
                            setSelectedLabelValues([...selectedLabelValues, lv]);
                            if (props.dispatch) {
                              selectedLV.push({
                                selectedLabel: lv,
                                dispatch: {
                                  action: 'add',
                                  type: 'labelValue',
                                  contextKey: props.contextKey,
                                  context: props.context,
                                  payload: lv,
                                },
                              });
                            } else {
                              selectedLV.push({ selectedLabel: lv });
                            }
                          }
                        }}
                      >
                        <Checkbox
                          style={{
                            padding: 0,
                          }}
                          color='primary'
                          checked={selectedLabelValues.filter((labelVal) => labelVal._id === lv._id).length !== 0}
                        ></Checkbox>
                        {renderLv(lv, true, key)}
                      </div>
                    ))
                  )}
                  {(pageLabels < totalPagesLabels || loading) && labelValues.length ? (
                    <div
                      className={props.classes.last}
                      id={`${props.id}LoadMoreLabels`}
                      style={{ justifyContent: 'center', fontWeight: 'bold', display: 'flex' }}
                    >
                      {loading && (
                        <ReactLoading
                          type={'spin'}
                          className={`${props.classes.spinner}`}
                          color={theme.palette.primary.main}
                          height={15}
                          width={15}
                        />
                      )}
                    </div>
                  ) : (
                    searchLabelString.length > 0 &&
                    searchLabelValues.length === 0 &&
                    !loading && (
                      <div
                        className={props.classes.last}
                        style={{ justifyContent: 'center', fontWeight: 'bold', display: 'flex' }}
                      >
                        {props.t('noData')}
                      </div>
                    )
                  )}
                </div>
              </div>
            ) : (
              <div className={props.classes.divDropdown} onScroll={onScroll}>
                <div className={props.classes.first}>
                  <TextField
                    id={`${props.id}SearchAccounts`}
                    key={'accounts'}
                    title={''}
                    variant='outlined'
                    placeholder={`${props.t('search')}...`}
                    onChange={(e): void => searchAccounts(e.target.value)}
                    value={searchAccountString}
                    fullWidth
                    className={props.classes.textField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' className={props.classes.inputAdornment}>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className={props.classes.second}>
                  {props.t('Selected')} ({selectedAccounts.length})
                </div>
                <div className={props.classes.scrollableArea} onScroll={onScroll}>
                  {loading && !accounts.length ? (
                    <div
                      className={props.classes.middle}
                      style={{ justifyContent: 'center', fontWeight: 'bold', display: 'flex' }}
                      id={`${props.id}Accounts`}
                    >
                      <ReactLoading
                        type={'spin'}
                        className={`${props.classes.spinner}`}
                        color={theme.palette.primary.main}
                        height={15}
                        width={15}
                      />
                    </div>
                  ) : (
                    (searchAccountString.length > 0 ? searchAccountsList : accounts).map((account, key) => (
                      <div
                        key={key}
                        className={
                          key === accounts.length - 1 && pageAccounts === totalPagesAccounts
                            ? props.classes.last
                            : props.classes.middle
                        }
                        id={`${props.id}SelectAccounts`}
                        onClick={(): void => {
                          if (selectedAccounts.filter((acc) => acc._id === account._id).length) {
                            setSelectedAccounts(selectedAccounts.filter((acc) => acc._id !== account._id));
                            if (props.dispatch) {
                              selectedAcc.filter((acc) => {
                                if (acc.selectedAccount._id === account._id) {
                                  acc.dispatch = {
                                    action: 'remove',
                                    type: 'account',
                                    contextKey: props.contextKey,
                                    context: props.context,
                                    payload: account,
                                  };
                                }
                              });
                            } else {
                              selectedAcc = selectedAcc.filter((acc) => acc.selectedAccount._id !== account._id);
                            }
                          } else {
                            setSelectedAccounts([...selectedAccounts, account]);
                            if (props.dispatch) {
                              selectedAcc.push({
                                selectedAccount: account,
                                dispatch: {
                                  action: 'add',
                                  type: 'account',
                                  contextKey: props.contextKey,
                                  context: props.context,
                                  payload: account,
                                },
                              });
                            } else {
                              selectedAcc.push({ selectedAccount: account });
                            }
                          }
                        }}
                      >
                        <Checkbox
                          style={{
                            padding: 0,
                          }}
                          color='primary'
                          checked={selectedAccounts.filter((labelVal) => labelVal._id === account._id).length !== 0}
                        ></Checkbox>
                        <span>{renderAccount(account, true, key)}</span>
                      </div>
                    ))
                  )}
                  {(pageAccounts < totalPagesAccounts || loading) &&
                  accounts.length &&
                  (searchAccountString.length === 0 || searchAccountsList.length) ? (
                    <div
                      className={props.classes.last}
                      style={{ justifyContent: 'center', fontWeight: 'bold', display: 'flex' }}
                      id={`${props.id}LoadMoreAccounts`}
                    >
                      {loading && (
                        <ReactLoading
                          type={'spin'}
                          className={`${props.classes.spinner}`}
                          color={theme.palette.primary.main}
                          height={15}
                          width={15}
                        />
                      )}
                    </div>
                  ) : (
                    searchAccountString.length > 0 &&
                    searchAccountsList.length === 0 &&
                    !loading && (
                      <div
                        className={props.classes.last}
                        style={{ justifyContent: 'center', fontWeight: 'bold', display: 'flex' }}
                      >
                        {props.t('noData')}
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
      {popoverDetails ? (
        <DetailsPopover
          context={'account'}
          anchor={popoverDetails}
          value={selectedValue}
          clear={() => {
            setPopoverDetails(null);
            setSelectedValue(null);
          }}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (): object => ({});

const mapDispatchToProps = (dispatch): DispatchToProps =>
  bindActionCreators(
    {
      labelValuesPaginated,
      accountsPaginated,
    },
    dispatch,
  );

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default compose<any>(
  withTranslation('translation'),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(AssignToFieldComponent);
