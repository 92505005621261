import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    '@global': {
      '.fullscreen': {
        height: 'calc(100% - 140px)',
        backgroundColor: theme.palette.background.default,
      },
    },
    editButton: {
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
      border: `1px solid ${theme.palette.primary.light}`,
      marginRight: '16px',
      padding: '8px 16px',
      // backgroundColor: theme.palette.background.default,
      // border: `1px solid ${theme.palette.text.disabled}`,
      // color: theme.palette.text.primary,
      // marginRight: 20,
    },
    editText: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '1.25px',
    },
    rootGrid: {
      margin: 20,
      width: 'calc(100% - 270px)',
      flexGrow: 1,
    },
    addGroupBtn: {
      display: 'flex',
      marginLeft: 'auto',
      marginRight: 0,
      color: theme.palette.primary.main,
      background: theme.palette.background.background1,
      height: 35,
    },
    addColumnBtn: {
      color: theme.palette.primary.main,
      background: theme.palette.background.default,
      border: `1px solid ${theme.palette.primary.main}`,
      marginLeft: 10,
      height: 35,
    },
    backBtn: {
      color: theme.palette.primary.main,
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '28px',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    groupTable: {
      width: '255px',
      border: `1px solid ${theme.palette.background.background1}`,
      borderRadius: '4px',
      backgroundColor: theme.palette.background.default,
      marginLeft: '0px',
      marginBottom: '0px',
      position: 'relative',
      minHeight: '62px',
    },
    addGroupText: {
      display: 'flex',
      color: theme.palette.primary.main,
      fontSize: '14px',
      '& .MuiSvgIcon-root': {
        fontSize: '12px',
      },
      marginTop: '2px',
      marginLeft: '24px',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    addGroupDiv: {
      borderRadius: '4px',
      marginBottom: '5px',
      marginTop: '5px',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '15px',
      minHeight: '32px',
      cursor: 'pointer',
      '&:hover': {
        '& > svg': {
          display: 'flex !important',
        },
      },
      flexGrow: 1,
      justifyContent: 'space-between',
      // marginLeft: '8px',
    },
    addGroupDivText: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '22px',
      alignItems: 'center',
      // textAlign: 'center',
      textAlign: 'left',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: 'inline-block',
      whiteSpace: 'nowrap',
      maxWidth: '17ch',
      width: '100%',
    },
    divHeader: {
      backgroundColor: theme.palette.text.primary,
      height: '35px',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.background.default,
      paddingLeft: '15px',
      borderRadius: '4px 4px 0px 0px',
    },
    headerText: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'center',
      padding: '8px 4px',
      zIndex: 100,
      position: 'inherit',
    },
    tableText: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '22px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'center',
      // paddingLeft: '15px',
      padding: '8px 4px',
    },
    optionColor: {
      color: theme.palette.primary.main,
      '& .Mui-checked': {
        color: theme.palette.primary.main,
      },
    },
    optionDisabled: {
      color: theme.palette.text.disabled,
    },
    removeLabelIcon: {
      display: 'none',
      marginLeft: 'auto',
      marginRight: 5,
      color: theme.palette.background.default,
      background: theme.palette.error.main,
      cursor: 'pointer',
      '&.MuiSvgIcon-root': {
        width: 12,
        height: 12,
      },
    },
    moreVertIcon: {
      marginLeft: 'auto',
      marginRight: 10,
      display: 'flex',
      cursor: 'pointer',
    },
    iconSize: {
      '&.MuiSvgIcon-root': {
        width: 12,
        height: 12,
      },
    },
    dialogText: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '18px',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.primary,
      marginLeft: '5px',
    },
    paperInfo: {
      border: `1px solid ${theme.palette.text.disabled}`,
      backgroundColor: theme.palette.background.background1,
      height: '100%',
    },
    paperText: {
      fontFamily: 'Poppins',
      fontStyle: 'italic',
      fontSize: '18px',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.secondary,
      justifyContent: 'center',
      height: '100%',
    },
    radioButtonChecked: {
      fontSize: '16px',
      color: theme.palette.primary.main,
      marginRight: '10px',
    },
    radioButtonUnchecked: {
      fontSize: '16px',
      marginRight: '10px',
    },
    deleteColor: {
      color: theme.palette.error.main,
    },
    scrollBar: {
      display: '-webkit-box',
      width: '100%',
      overflowX: 'auto',
    },
    filterDiv: {
      display: 'flex',
      marginBottom: '24px',
      justifyContent: 'flex-end',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.common.white,
    } /* 
  tooltipCustom: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.text.secondary,
    maxWidth: 240,
  }, */,
    '.MuiPopover-paper': {
      width: '198px',
    },
    arrowDown: {
      '&.MuiSvgIcon-root': {
        display: 'block',
        height: '1.3em',
      },
    },
    tooltipCustom: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.text.secondary,
      // maxWidth: 240,
      padding: '4px 8px 4px 8px',
      textAlign: 'center',
      border: `1px solid ${theme.palette.text.secondary}`,
      top: '5px',
    },
    arrowCustom: {
      color: theme.palette.text.secondary,
    },
    divOption: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      // display: 'inline-block',
      whiteSpace: 'nowrap',
      display: 'flex',
      alignItems: 'center',
    },
    spanGroup: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: 'inline-block',
      whiteSpace: 'nowrap',
    },
    divLabelGroups: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '8px 0px',
    },
    scrollableDiv: {
      height: '98%',
      overflowY: 'auto',
      overflowX: 'auto',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: '8px 8px 8px 0px',
      marginLeft: '8px',
    },
    tableHeader: {
      paddingTop: '0px',
      height: 'fit-content',
      display: 'flex',
      flexDirection: 'row',
      position: 'sticky',
      top: '0',
      zIndex: 4,
    },
    blankSpace: {
      position: 'sticky',
      zIndex: 3,
      left: 0,
      /* backgroundColor: theme.palette.common.white, */
    },
    fixedColumn: {
      position: 'sticky',
      zIndex: 3,
      left: 0,
      /* backgroundColor: theme.palette.common.white, */
      paddingRight: '8px',
    },
    minH100: {
      minHeight: '100%',
    },
    h100: {
      height: '100%',
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    menuItemDelete: {
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    menuItemDuplicate: {
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
      '& > span': {
        color: theme.palette.primary.main,
      },
      '& > svg': {
        color: theme.palette.primary.main,
      },
    },
    menuItem: {
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        '& > *': {
          color: `${theme.palette.primary.main} !important`,
        },
        '& > * > span': {
          color: `${theme.palette.primary.main} !important`,
        },
      },
    },
    topButton: {
      fontSize: '18px',
      marginRight: '8px',
      color: theme.palette.primary.main,
    },
    divColumn: {
      width: '255px',
      border: `1px solid ${theme.palette.background.background1}`,
      borderRadius: '5px',
      backgroundColor: theme.palette.background.default,
      marginRight: '8px',
      marginBottom: '0px',
      position: 'relative',
      minHeight: '62px',
    },
    onOverRight: {
      borderRight: `4px solid ${theme.palette.primary.main}`,
      borderRadius: '0px 6px 6px 0px',
    },
    onOverLeft: {
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      borderRadius: '6px 0px 0px 6px',
    },
    onDrag: {
      backgroundColor: `${theme.palette.background.background1} !important`,
      color: `${theme.palette.background.background1} !important`,
      border: 'none',
      opacity: 1,
      '& > *': {
        border: 'none !important',
        backgroundColor: theme.palette.background.background1 + ' !important',
        color: theme.palette.background.background1 + ' !important',
      },
    },
    onOverLine: {
      width: '100%',
      height: '4px',
      backgroundColor: `${theme.palette.primary.main}`,
      borderRadius: '4px',
    },
    onDragLine: {
      backgroundColor: `${theme.palette.common.white} !important`,
      color: `${theme.palette.common.white} !important`,
      border: 'none',
      opacity: 1,
      '& > *': {
        border: 'none !important',
        backgroundColor: theme.palette.background.background1 + ' !important',
        color: theme.palette.background.background1 + ' !important',
      },
    },
    divDragIndicator: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    dragIndicator: {
      color: theme.palette.text.disabled,
    },
    table: {
      '&.MuiTable-root': {
        borderSpacing: 'none',
        borderCollapse: 'unset',
      },
    },
    w120: {
      width: '120px',
    },
    refreshWidgets: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '16px',
      cursor: 'pointer',
    },
    fullScreen: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '16px',
      cursor: 'pointer',
    },
    groupCell: {
      width: '150px',
      position: 'absolute',
      left: '0',
      height: '40px',
    },
    hiddenGroupCell: {
      width: '150px',
      position: 'absolute',
      left: '0',
      height: '40px',
      border: 'none',
    },
    posRelative: {
      position: 'relative',
    },
    scrollableTable: {
      overflowX: 'auto',
      marginLeft: '150px',
      overflowY: 'auto',
      padding: 0,
    },
    divLoadingAnimation: {
      height: '100%',
      textAlign: 'center',
      fontFamily: 'Open Sans',
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    spanLoadingAnimation: {
      color: theme.palette.text.primary,
      textAlign: 'center',
      fontSize: '16px',
      fontFamily: 'Poppins',
      fontWeight: 500,
      lineHeight: '24px',
    },
    hideLinePopover: {
      padding: '6px 16px',
      color: theme.palette.primary.main,
      background: theme.palette.primary.light,
      fontSize: '14px',
      cursor: 'pointer',
      pointerEvents: 'fill',
      display: 'flex',
      alignItems: 'center',
    },
    unfoldIconBefore: {
      position: 'absolute',
      top: 0,
      transform: 'translateY(-50%)',
      zIndex: 9999,
      cursor: 'pointer',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        background: theme.palette.primary.light,
        color: theme.palette.primary.main,
      },
    },
    unfoldIconAfter: {
      position: 'absolute',
      top: 'calc(100%)',
      transform: 'translateY(-50%)',
      zIndex: 9999,
      cursor: 'pointer',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        background: theme.palette.primary.light,
        color: theme.palette.primary.main,
      },
    },
    unfoldIconBeforeColumn: {
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 9999,
      cursor: 'pointer',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        background: theme.palette.primary.light,
        color: theme.palette.primary.main,
      },
    },
    unfoldIconAfterColumn: {
      position: 'absolute',
      top: '50%',
      left: 'calc(100%)',
      transform: 'translate(-50%, -50%)',
      zIndex: 9999,
      cursor: 'pointer',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        background: theme.palette.primary.light,
        color: theme.palette.primary.main,
      },
    },
    loadingTable: {
      background: theme.palette.common.white,
      opacity: 0.15,
    },
    circularRefresh: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  });
