import { useMemo } from 'react';
import { styles } from './styles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(styles);

export const highlightRegex = (searchTerm) => {
  const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  return new RegExp(`(${escapedSearchTerm})`, 'giu');
};

const HighlightText = ({ text, highlight }: { text: string; highlight: string }) => {
  const classes = useStyles();

  const highlightedText = useMemo(() => {
    if (!highlight) return <span>{text}</span>;

    const parts = text.split(highlightRegex(highlight));

    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <span key={index} className={classes.highlight}>
              {part}
            </span>
          ) : (
            part
          ),
        )}
      </span>
    );
  }, [text, highlight]);

  return highlightedText;
};

export default HighlightText;
