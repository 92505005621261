import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    isResizing: {
      background: theme.palette.primary.main,
      opacity: 1,
    },
    table: {
      width: 'fit-content',
      minWidth: '100%',
    },
    resizer: {
      position: 'absolute',
      right: 0,
      top: 0,
      height: '100%',
      width: '5px',
      background: theme.palette.primary.main,
      cursor: 'col-resize',
      userSelect: 'none',
      touchAction: 'none',
      opacity: 1,
    },
    row: {
      width: 'fit-content',
      position: 'relative',
    },
    rowHovered: {
      width: 'fit-content',
      position: 'relative',
      backgroundColor: theme.palette.primary.light,
      '& div,& button': {
        visibility: 'visible',
      },
      '& td': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    container: {
      width: 'inherit',
      height: '100%',
      overflowX: 'auto',
    },
    header: {
      backgroundColor: theme.palette.background.background1,
      color: theme.palette.text.secondary,
      fontFamily: 'Poppins',
      position: 'relative',
      paddingInline: '8px',
      height: '34px !important',
      borderRight: `0.4px solid ${theme.palette.text.disabled}`,
      '& > span': {
        alignSelf: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    cell: {
      paddingInline: '8px',
      position: 'relative',
      paddingRight: '8px',
      height: '48px !important',
      borderRight: `0.4px solid ${theme.palette.text.disabled}`,
      borderBottom: `0.4px solid ${theme.palette.text.disabled}`,
      alignContent: 'center',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    cellActive: {
      //TODO: This acts weird when hovering...take a look into this
      position: 'absolute',
      textOverflow: 'unset',
      whiteSpace: 'unset',
      wordBreak: 'break-all',
      minHeight: '48px !important',
      height: 'fit-content !important',
      zIndex: 999,
      backgroundColor: theme.palette.background.default,
      paddingTop: '9px',
      paddingBottom: '9px',
    },
    moreVertIcon: {
      color: theme.palette.text.primary,
    },
    viewOptions: {
      fontWeight: 700,
      marginTop: '6px',
      marginBottom: '6px',
      paddingLeft: '16px',
      fontFamily: 'Open Sans',
    },
    morePopover: {
      /* height: "320px", */
      width: '390px',
    },
    columnsSort: {
      padding: '8px 16px 8px 16px',
      fontFamily: 'Open Sans',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
      },
    },
    arrowForwardIos: {
      width: '16px',
      height: '16px',
      marginLeft: 'auto',
      marginRight: '5px',
    },
    noData: {
      textAlignLast: 'center',
      fontFamily: 'Poppins',
      marginTop: '24px',
      color: theme.palette.text.secondary,
    },
    checkboxChecked: {
      color: `${theme.palette.primary.main} !important`,
    },
    loadingSpinner: {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 8,
      gap: 18,
      maxHeight: 24,
      ...theme.typography.h3,
    },
    loadingSpinnerTableEnd: {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 8,
      gap: 18,
      maxHeight: 24,
      ...theme.typography.h4,
    },
    headerSortArrowIcon: {
      fontSize: '17px',
      position: 'absolute',
      right: '8px',
      left: 'auto',
      display: 'none',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.background.background1,
      },
    },
    dragIcon: {
      color: theme.palette.text.disabled,
      cursor: 'pointer',
    },
    dragIconContainer: {
      display: 'flex',
      width: 12,
      marginLeft: -8,
    },
    checkboxIconsCell: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'fit-content',
    },
    dragBarPreview: {
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      width: '100%',
      height: 4,
      left: 0,
      right: 0,
      bottom: 0,
    },
  });
