import { Button, LinearProgress, Typography } from '@mui/material';
import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { styles } from 'src/base/on-boarding/styles';
import { useMainContainerContext } from '../main-container/context/main.container.context';
import OnBoardingSteps from 'src/base/on-boarding/components/steps';
import LeaveTutorialModal from './components/leave.tutorial.modal';
import FinishTutorialModal from './components/finish.tutorial.modal';
import FinishStepModal from './components/finish.step.modal';
import TourSubSteps from './components/tour.sub.steps';
import TaskInputModal from './components/task.input.modal';
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useTranslation } from 'react-i18next';

type OnBoardingProps = {
  loggedUser: any;
} & ConnectedProps<typeof connecter>;

const useStyles = makeStyles(styles);
const OnBoarding: React.FC<OnBoardingProps> = (props): JSX.Element => {
  const state = useMainContainerContext();
  const { updateMainContainerState } = state;
  const { loggedUser } = props;
  const classes: ClassNameMap<string> = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {!!loggedUser.onBoarding && (
        <div className={`${classes.onBoardingContainer} ${state.hideSteps ? '' : 'show'}`}>
          <div className={classes.onBoardingBarContainer}>
            <Typography className={classes.onBoardingGetStarted}>{t('getStartedVision')}</Typography>
            <div className={classes.onBoardingBarContainerSec}>
              <LinearProgress
                data-testid='onBoarding-progress-bar'
                variant='determinate'
                className={classes.onBoardingBar}
                classes={{
                  barColorSecondary: classes.onBoardingBarColorBackground,
                  barColorPrimary: classes.onBoardingBarColorPrimary,
                }}
                value={loggedUser.onBoarding?.progressTotal || 0}
              />
              <Typography
                className={classes.onBoardingBarValue}
              >{`${loggedUser.onBoarding?.progressTotal || 0}%`}</Typography>
            </div>
            <Button
              data-testid={`${state.hideSteps ? 'show' : 'hide'}-steps-button`}
              className={classes.onBoardingHideSteps}
              onClick={() => updateMainContainerState({ hideSteps: !state.hideSteps })}
            >
              {state.hideSteps ? t('showSteps') : t('hideSteps')}
            </Button>
          </div>
          <OnBoardingSteps />
        </div>
      )}
      {state.openNoTaskAndInputModal && <TaskInputModal />}
      {state.openModalLeaveTutorial && <LeaveTutorialModal />}
      {state.openEndStepModal && <FinishStepModal />}
      {state.openEndOnBoardingModal && <FinishTutorialModal />}
      {state.startSubSteps && <TourSubSteps />}
    </>
  );
};

const connecter = connect(
  (state: any) => ({
    loggedUser: state.loginReducer.loggedUser,
  }),
  {},
);

export default connecter(OnBoarding);
