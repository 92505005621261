import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import GlarFilterlet from './glar-filter-let';
import {
  accountFormatter,
  dateValueFormatter,
  defaultValueFormatter,
  issueFormatter,
  labelAccountFormatter,
  labelValueFormatter,
  siteElementFormatter,
} from './helper';
import BetterFieldPopover from '../better-field-popover';
import { FieldPopoverContext } from '../better-field-popover/better-list-by-level/popover-reducer';
import { Checkbox, FormControl, InputAdornment, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import InputGeneric from '../input-field/input-generic';
import moment from 'moment';
import classNames from 'classnames';
import { useGlarFilterContext } from './glar-filter.context';
import { ElementSiteReportTaskInputDistinct, IssueInstance, IssueReportTaskInputDistinct } from 'src/gql/graphql';
import { SkillLevelChip } from 'src/modules/skills/components/skill-level';
import i18n from 'src/utils/translations/i18n';
import CustomDateDropdown from '../custom-date-dropdown';
import makeStyles from '@mui/styles/makeStyles';
import { ExpandMore, Search } from '@mui/icons-material';

export type FilterItemType = React.FunctionComponent<{ accessor: string }>;

const useStyles = makeStyles(styles);

export const RoleFilterItem = ({ accessor }: { accessor: string }) => {
  const { t } = useTranslation();
  const { value, updateItem } = useGlarFilterContext(accessor);

  return (
    <GlarFilterlet accessor={accessor} formatValue={defaultValueFormatter}>
      <BetterFieldPopover
        data-testid={`preset-filter-${accessor}`}
        placeholder={t('select')}
        context={'role'}
        value={value ?? []}
        onChange={updateItem}
        multiple
      />
    </GlarFilterlet>
  );
};

export const SiteElementFilterItem = <C extends 'site' | 'element' | 'siteElement' | 'profile'>({
  accessor,
  context,
}: {
  accessor: string;
  context: C;
}) => {
  const { t } = useTranslation();
  const { value, updateItem } = useGlarFilterContext(accessor);
  const [isAllSelected, setIsAllSelected] = useState(false);

  return (
    <GlarFilterlet accessor={accessor} formatValue={siteElementFormatter} isAllSelected={isAllSelected}>
      <BetterFieldPopover
        data-testid={`preset-filter-${accessor}`}
        placeholder={t('select')}
        context={context}
        value={value ?? []}
        onChange={updateItem}
        multiple
        isAllSelected={(data) => {
          setIsAllSelected(data);
        }}
      />
    </GlarFilterlet>
  );
};

export const LabelValueFilterItem = <C extends FieldPopoverContext>({
  accessor,
  context,
}: {
  accessor: string;
  context: C extends `label${infer U}` ? `label${U}` : never;
}) => {
  const { t } = useTranslation();
  const { value, updateItem } = useGlarFilterContext(accessor);
  const [isAllSelected, setIsAllSelected] = useState(false);

  return (
    <GlarFilterlet accessor={accessor} formatValue={labelValueFormatter} isAllSelected={isAllSelected}>
      <BetterFieldPopover
        data-testid={`preset-filter-${accessor}`}
        placeholder={t('select')}
        context={context}
        value={value ?? []}
        onChange={updateItem}
        multiple
        isAllSelected={(data) => {
          setIsAllSelected(data);
        }}
      />
    </GlarFilterlet>
  );
};

export const AccountFilterItem: FilterItemType = ({ accessor }) => {
  const { t } = useTranslation();
  const { value, updateItem } = useGlarFilterContext(accessor);
  const [isAllSelected, setIsAllSelected] = useState(false);

  return (
    <GlarFilterlet accessor={accessor} formatValue={accountFormatter} isAllSelected={isAllSelected}>
      <BetterFieldPopover
        data-testid={`preset-filter-${accessor}`}
        placeholder={t('select')}
        context={'assignee'}
        value={value ?? []}
        onChange={updateItem}
        multiple
        isAllSelected={(data) => {
          setIsAllSelected(data);
        }}
      />
    </GlarFilterlet>
  );
};

export const IssueFilterMaker = (issues: IssueInstance[]): FilterItemType =>
  CheckboxFilterItem(
    issues.map((i) => ({
      id: i._id,
      label: i.name,
    })),
  );

export const IssueCatalogFilterItem: FilterItemType = ({ accessor }) => {
  const { t } = useTranslation();
  const { value, updateItem } = useGlarFilterContext(accessor);

  return (
    <GlarFilterlet accessor={accessor} formatValue={issueFormatter}>
      <BetterFieldPopover
        data-testid={`preset-filter-${accessor}`}
        placeholder={t('select')}
        context={['issueCatalogOpen', 'issueCatalogScheduled']}
        value={value ?? []}
        onChange={updateItem}
        multiple
      />
    </GlarFilterlet>
  );
};

export const AssignToFilterItem: FilterItemType = ({ accessor }) => {
  const { t } = useTranslation();
  const { value, updateItem } = useGlarFilterContext(accessor);

  return (
    <GlarFilterlet accessor={accessor} formatValue={labelAccountFormatter}>
      <BetterFieldPopover
        data-testid={`preset-filter-${accessor}`}
        placeholder={t('select')}
        context={['labelAccount', 'assignee']}
        value={value ?? []}
        onChange={updateItem}
        multiple
      />
    </GlarFilterlet>
  );
};

export const CheckboxFilterItem: (
  values: { id: string | number; icon?: ReactNode; value?: any; label: string | number }[],
) => FilterItemType =
  (values) =>
  ({ accessor }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const { value, updateItem } = useGlarFilterContext(accessor);

    return (
      <GlarFilterlet accessor={accessor} formatValue={defaultValueFormatter}>
        <div className={classes.verticalListing}>
          {values?.length ? (
            <div key={'selectAll-' + accessor} className={classes.horizontal}>
              <Checkbox
                color={'primary'}
                data-testid={`preset-filter-checkbox-${accessor}-select-all`}
                checked={values.length === value?.length}
                onChange={() =>
                  updateItem(values.length === value?.length ? undefined : values.map((v) => v.value ?? v.id))
                }
              />
              <span>{t('selectAll')}</span>
            </div>
          ) : null}
          {values.map((state) => (
            <div className={classes.horizontal} key={state.id}>
              <Checkbox
                color={'primary'}
                data-testid={`preset-filter-checkbox-${accessor}-${state.label}`}
                checked={!!value?.some((v) => (v._id ?? v) === state.id)}
                onChange={() =>
                  updateItem(
                    value?.some((v) => (v._id ?? v) === state.id)
                      ? value.filter((v) => (v._id ?? v) != state.id)
                      : [...(value ?? []), state.value ?? state.id],
                  )
                }
              />
              {state.icon}
              <span>{state.label}</span>
            </div>
          ))}
        </div>
      </GlarFilterlet>
    );
  };

export const CheckboxFilterItemLabel = (values: (string | number)[]) =>
  CheckboxFilterItem(
    values.map((v) => ({
      id: v,
      label: i18n.t(v as string) as string,
    })),
  );

export const IssueStateFilterItem = CheckboxFilterItemLabel([
  'Pending',
  'Work In Progress',
  'Canceled',
  'Canceled By System',
  'Done',
  'Paused',
  //'Blocked',
]);

export const ActionStatusFilterItem = CheckboxFilterItemLabel(['PENDING', 'CANT_DO', 'DOING', 'SOLVED']);

export const ApprovalStatusFilterItem = CheckboxFilterItem([
  { id: 'pending', label: i18n.t('pending') },
  { id: 'waitingApproval', label: i18n.t('waitingApproval') },
  { id: 'rejected', label: i18n.t('rejected') },
  { id: 'approved', label: i18n.t('approved') },
]);

export const ProductTypeFilterItem = CheckboxFilterItem([
  {
    id: 'STOCKED',
    label: i18n.t('stockedProduct'),
  },
  {
    id: 'SERIALIZED',
    label: i18n.t('serializedProduct'),
  },
]);

export const SkillLevelFilterItem = CheckboxFilterItem([
  {
    id: 'NOT_APPLICABLE',
    label: '',
    icon: <SkillLevelChip level={'NOT_APPLICABLE'} />,
  },
  {
    id: 'NO_COMPETENCE',
    label: '',
    icon: <SkillLevelChip level={'NO_COMPETENCE'} />,
  },
  {
    id: 'LOW_COMPETENCE',
    label: '',
    icon: <SkillLevelChip level={'LOW_COMPETENCE'} />,
  },
  {
    id: 'MEDIUM_COMPETENCE',
    label: '',
    icon: <SkillLevelChip level={'MEDIUM_COMPETENCE'} />,
  },
  {
    id: 'HIGH_COMPETENCE',
    label: '',
    icon: <SkillLevelChip level={'HIGH_COMPETENCE'} />,
  },
  {
    id: 'EXPERT',
    label: '',
    icon: <SkillLevelChip level={'EXPERT'} />,
  },
]);

export const TimeDurationFilterItem: FilterItemType = ({ accessor }) => {
  const { value, updateItem } = useGlarFilterContext(accessor);

  return (
    <GlarFilterlet accessor={accessor}>
      <InputGeneric
        editable={true}
        disabled={false}
        onlyInput
        type={'string'}
        data-testid={`preset-filter-${accessor}`}
        placeholder={'0:0'}
        values={!isNaN(value) ? [`${Math.floor(value / 60).toString()}:${(value % 60).toString()}`] : ['0:0']}
        handleChange={(e) => {
          const splitted = e[0].split(':');

          if (splitted.length === 2 && (!splitted[0] || !isNaN(splitted[0])) && (!splitted[1] || !isNaN(splitted[1]))) {
            updateItem(parseInt(splitted[0] || 0) * 60 + parseInt(splitted[1] || 0));
          } else {
            updateItem(undefined);
          }
        }}
      />
    </GlarFilterlet>
  );
};

export const DateFilterItem: FilterItemType = ({ accessor }) => {
  const classes = useStyles();
  const { value, updateItem } = useGlarFilterContext(accessor);

  return (
    <GlarFilterlet accessor={accessor} formatValue={dateValueFormatter}>
      <div
        data-testid={`preset-filter-${accessor}`}
        className={classNames(classes.verticalListing, classes.wideGap, classes.fullWidth)}
      >
        <div className={classes.fullWidth}>
          <CustomDateDropdown
            timeSpan={value}
            setTimeSpan={(timeSpan) => {
              updateItem(timeSpan);
            }}
          />
        </div>
      </div>
    </GlarFilterlet>
  );
};

export const SelectFilterItem: (
  values: { id: string | number; value?: any; label: string | number }[],
) => FilterItemType =
  (values) =>
  ({ accessor }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const { value, updateItem } = useGlarFilterContext(accessor);

    const [search, setSearch] = useState('');
    const [measuredWidths, setMeasuredWidths] = useState([]);

    const selectRef = useRef(null);
    const spanRefs = useRef([]);

    useEffect(() => {
      if (spanRefs.current.length === (value?.length ?? 0)) {
        const widths = spanRefs.current.map((span) => span?.offsetWidth ?? 0);
        setMeasuredWidths(widths);
      }
    }, [value]);

    const valueRender = useCallback(() => {
      if ((value?.length ?? 0) <= 0) {
        return { components: <span>{t('select')}</span>, renderString: '' };
      }

      let totalWidth = 0;
      const maxWidth = selectRef.current ? selectRef.current.offsetWidth - 78 : 0;
      const components = [];
      let renderedString = '';

      for (let i = 0; i < (value?.length ?? 0); i++) {
        const spanWidth = measuredWidths[i] || 0;

        if (totalWidth + spanWidth > maxWidth) {
          components.push(<span key='more'>+{value.length - i}</span>);
          renderedString += ` +${value.length - i}`;
          break;
        }

        totalWidth += spanWidth;
        renderedString += `${value[i]} `;
        components.push(
          <span ref={(el) => (spanRefs.current[i] = el)} key={i}>
            {value[i]}{' '}
          </span>,
        );
      }
      return { components, renderString: renderedString.trim() };
    }, [value, measuredWidths, selectRef.current]);

    return (
      <GlarFilterlet
        accessor={accessor}
        formatValue={() => {
          const { renderString } = valueRender();
          return renderString;
        }}
        isAllSelected={values?.length === value?.length}
      >
        <FormControl fullWidth>
          <Select
            ref={selectRef}
            multiple
            displayEmpty
            value={value || []}
            IconComponent={ExpandMore}
            MenuProps={{
              slotProps: {
                paper: {
                  style: {
                    maxHeight: '250px',
                  },
                },
              },
            }}
            renderValue={() => {
              const { components } = valueRender();
              return components;
            }}
            className={classes.selectInput}
          >
            <MenuItem onKeyDown={(e) => e.stopPropagation()} className={classes.textFieldMenuItem}>
              <TextField
                data-testid={`preset-filter-checkbox-${accessor}-search`}
                variant='standard'
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  style: { padding: '8px 16px' },
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Search />
                    </InputAdornment>
                  ),
                }}
                placeholder={t('Search')}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </MenuItem>
            {!search && (
              <MenuItem
                key={`selectAll-${accessor}`}
                data-testid={`preset-filter-checkbox-${accessor}-select-all`}
                onClick={() => {
                  updateItem(values.length === value?.length ? undefined : values.map((v) => v.value ?? v.id));
                }}
                className={classNames([classes.menuItem])}
              >
                <Checkbox
                  checked={values.length === value?.length}
                  style={{ fontSize: '16px', padding: '0', marginRight: '8px' }}
                />
                <ListItemText
                  primary={t('selectAll')}
                  className={classNames({ [classes.selectedText]: values.length === value?.length })}
                />
                {values.length === value?.length ? (
                  <ListItemText primary={`${values.length} ${t('Selected')}`} className={classes.selectedCounter} />
                ) : null}
              </MenuItem>
            )}
            {values
              ?.filter(
                (v) =>
                  !search ||
                  `${v.id}`.toLowerCase().includes(search.toLowerCase()) ||
                  `${v.label}`.toLowerCase().includes(search.toLowerCase()),
              )
              ?.map((v) => (
                <MenuItem
                  data-testid={`preset-filter-checkbox-${accessor}-${v.label}`}
                  key={v.id}
                  onClick={() => {
                    updateItem(
                      value?.some((x) => (x._id ?? x) === v.id)
                        ? value.filter((x) => (x._id ?? x) != v.id)
                        : [...(value ?? []), v.value ?? v.id],
                    );
                  }}
                  className={classNames([classes.menuItem])}
                  value={`${v.label}`}
                >
                  <Checkbox
                    checked={!!value?.some((x) => (x._id ?? x) === v.id)}
                    style={{ fontSize: '16px', padding: '0', marginRight: '8px' }}
                  />
                  <ListItemText
                    primary={t(`${v.label}`)}
                    className={classNames({
                      [classes.listItemText]: true,
                      [classes.selectedText]: !!value?.some((x) => x === v),
                    })}
                  />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </GlarFilterlet>
    );
  };

export const DistinctInputFilterItem = ({
  taskInput,
}: {
  taskInput: IssueReportTaskInputDistinct | ElementSiteReportTaskInputDistinct;
}) => {
  let Component: FilterItemType = () => <></>;

  if (taskInput.string?.length) {
    Component = SelectFilterItem(
      taskInput.string.map((v) => ({
        id: v,
        label: i18n.t(v as string) as string,
      })),
    );
  } else if (taskInput.number?.length) {
    Component = SelectFilterItem(
      taskInput.number.map((v) => ({
        id: v,
        label: i18n.t(`${v}`) as string,
      })),
    );
  } else if (taskInput.site?.length) {
    Component = SelectFilterItem(
      taskInput.site.map((s) => ({
        id: s._id,
        value: s,
        label: s.name,
      })),
    );
  } else if (taskInput.element?.length) {
    Component = SelectFilterItem(
      taskInput.element.map((s) => ({
        id: s._id,
        value: s,
        label: s.name,
      })),
    );
  } else if (taskInput.labelValue?.length) {
    Component = SelectFilterItem(
      taskInput.labelValue.map((l) => ({
        id: l._id,
        value: l,
        label: l.value,
      })),
    );
  } else if (taskInput.datetime) {
    Component = SelectFilterItem(
      taskInput.datetime.map((d) => ({
        id: moment(d).format('YYYY/MM/DD HH:mm'),
        label: moment(d).format('YYYY/MM/DD HH:mm'),
      })),
    );
  }

  return <Component accessor={taskInput.name} />;
};
