import { useEffect, useMemo } from 'react';

const useUpdateState = (props, updateMainContainerState) => {
  const userRoles = useMemo(
    () => props.loggedUser?.roles.flatMap((role) => role?.permissions),
    [props.loggedUser?.roles],
  );

  useEffect(() => {
    let correctPermision: 'viewer' | 'creator' | 'manager' | '' = '';
    const actionPermissions = userRoles?.filter((permission) =>
      ['createAction', 'updateAction', 'deleteOneAction', '*'].includes(permission.fieldName),
    );

    switch (true) {
      case actionPermissions?.every((permission) => permission.access === 'DENY') || !actionPermissions?.length:
        correctPermision = 'viewer';
        break;
      case actionPermissions?.every((permission) => permission.access === 'ALLOW') &&
        actionPermissions?.some(
          (permission) => permission.fieldName === 'deleteOneAction' || permission.fieldName === '*',
        ):
        correctPermision = 'manager';
        break;
      case actionPermissions?.some(
        (permission) => permission.fieldName === 'deleteOneAction' && permission.access === 'DENY',
      ):
        correctPermision = 'creator';
        break;
    }

    updateMainContainerState({
      userPermissions: {
        actions: correctPermision,
      },
    });
  }, [props.loggedUser?.roles]);

  useEffect(() => {
    if (props.loggedUser?.onBoarding) {
      updateMainContainerState({
        doingOnBoarding: props.loggedUser?.onBoarding?.doingOnboarding,
        currentStep: props.loggedUser?.onBoarding?.step,
        hideSteps: false,
        openEndOnBoardingModal: props.loggedUser?.onBoarding?.step === 'finish',
      });
    }
  }, [props.loggedUser?.onBoarding?.doingOnboarding, props.loggedUser?.onBoarding?.step]);

  useEffect(() => {
    updateMainContainerState({ openModalIntroduction: props.loggedUser?.firstLogin });
  }, [props.loggedUser?.firstLogin]);

  useEffect(() => {
    updateMainContainerState({ sidenavMinimized: props.sidenavMinimized });
  }, [props.sidenavMinimized]);
};

export default useUpdateState;
