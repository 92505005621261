import { NexusGenFieldTypes } from '@server/src/types';
import _, { isEqual } from 'lodash';
import {
  Input,
  InputNumberOnResponse,
  InputStringOnResponse,
  IssueTemplateDraftTaskTemplateGroupTaskTemplateAdvancedLabelSelection,
} from 'src/gql/graphql';

export const getTaskRuleMapGraphQLAdvancedLabelSelectionInput = (task) => {
  if (!task.advancedLabelSelection) {
    return null;
  }
  const rule: IssueTemplateDraftTaskTemplateGroupTaskTemplateAdvancedLabelSelection =
    _.cloneDeep<IssueTemplateDraftTaskTemplateGroupTaskTemplateAdvancedLabelSelection>(task.advancedLabelSelection);
  if (rule?.filter.length == 0) {
    return null;
  }
  for (const [i, filter] of rule.filter.entries()) {
    const taskFilter = task.advancedLabelSelection?.filter[i];
    if (!taskFilter) {
      continue;
    }
    for (const [j, condition] of filter.conditions.entries()) {
      const taskCondition = taskFilter?.conditions[j];
      if (!taskCondition) {
        continue;
      }
      condition.labelValue = taskCondition.labelValue._id;
    }
  }
  return rule;
};

/**
 * Maps a Task's assigned tools to IssueTemplateDraftTaskTemplateGroupTaskTemplateAssignedToolUpdateInput format
 * @param task - The target task
 */
export const mapTaskAssignedMaterials = (task) => {
  return task.assignedMaterials.flatMap((m: NexusGenFieldTypes['TaskAssignedMaterial']) => ({
    material: m.material._id,
    quantity: m.quantity,
  }));
};

/**
 * Maps a Task's assigned tools to IssueTemplateDraftTaskTemplateGroupTaskTemplateAssignedMaterialUpdateInput format
 * @param task - The target task
 */
export const mapTaskAssignedTools = (task) => {
  return task.assignedTools.flatMap((t: NexusGenFieldTypes['TaskTemplateAssignedTool']) => ({
    tool: t.tool._id,
    quantity: t.quantity,
    reserveAtStart: t.reserveAtStart,
    associatedWith: t.associatedWith?._id,
  }));
};

export function* inputIterator<T extends Partial<Input>>(inputs?: T[]): Generator<Partial<Input>> {
  for (const inp of inputs || []) {
    yield inp;

    //@ts-ignore
    const onResponse: InputNumberOnResponse[] | InputStringOnResponse[] = inp[inp.type]?.onResponse;

    for (const resp of onResponse || []) {
      yield* inputIterator(resp.inputs || []);
    }
  }
}

export function* activeInputIterator<T extends Partial<Input>>(inputs?: T[]): Generator<Partial<Input>> {
  for (const inp of inputs || []) {
    yield inp;

    //@ts-ignore
    const onResponse: InputNumberOnResponse[] | InputStringOnResponse[] = inp[inp.type]?.onResponse;

    for (const resp of onResponse || []) {
      if (
        resp.ifValues.some((v) =>
          inp[inp.type]['values']?.some((g) => isEqual(v?.label != null ? v.label : v, g?.label != null ? g.label : g)),
        )
      ) {
        yield* inputIterator(resp.inputs || []);
      }
    }
  }
}
