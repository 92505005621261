import { File } from 'src/gql/graphql';

export const getFileExtension = (file_name: string) => {
  return file_name.substring(file_name.lastIndexOf('.') + 1, file_name.length) || file_name;
};

const fileIsMedia = (file: File) => {
  let extension = file.extension;
  if (!extension) {
    extension = getFileExtension(file.name);
  }
  return extension.match(
    /(jpg|png|gif|webp|tiff|psd|raw|bmp|heif|indd|jpeg|webm|mpg|mp2|mpeg|mpe|mpv|ogg|mp4|m4p|m4v|avi|wmv|mov|qt|flv|swf|avchd|glb|gltf)$/i,
  );
};

const fileIsVideo = (file: File) => {
  let extension = file.extension;
  if (!extension) {
    extension = getFileExtension(file.name);
  }
  return (
    file.hasThumbnail && extension.match(/(webm|mpg|mp2|mpeg|mpe|mpv|ogg|mp4|m4p|m4v|avi|wmv|mov|qt|flv|swf|avchd)$/i)
  );
};

export const fileIsImage = (file: File) => {
  let extension = file.extension;

  if (!extension) {
    extension = getFileExtension(file.name);
  }
  return file.hasThumbnail && extension.match(/(jpg|png|gif|webp|tiff|bmp|jpeg)$/i);
};

function moveObjectAtIndex<T>(array: T[], currentIndex: number, targetIndex: number): T[] {
  // Ensure the provided indices are valid
  if (currentIndex < 0 || currentIndex >= array.length || targetIndex < 0 || targetIndex >= array.length) {
    throw new Error('Invalid index values');
  }

  // Create a copy of the original array to avoid mutating it
  const newArray = [...array];

  // Get the object to move
  const movedObject = newArray[currentIndex];

  // Remove the object from the original position
  newArray.splice(currentIndex, 1);

  // Adjust the target index if it exceeds the array length
  if (targetIndex > newArray.length) {
    targetIndex = newArray.length;
  }

  // Insert the object at the adjusted target index
  newArray.splice(targetIndex, 0, movedObject);

  return newArray;
}

export { fileIsMedia, fileIsVideo, moveObjectAtIndex };
