import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';

const ARMappingCreateDefaultRefresh = () => lazy(() => import('src/modules/ar-mapping/ar-mapping.create.default'));

let ARMappingCreateDefault = ARMappingCreateDefaultRefresh();

export const render = {
  create: {
    default: ({ ...props }) => {
      if (ARMappingCreateDefault?._result?.type === 'error') {
        ARMappingCreateDefault = ARMappingCreateDefaultRefresh();
      }

      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <ARMappingCreateDefault {...props} />
        </Suspense>
      );
    },
  },
};

export default render;
