import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    popoverContainer: {
      height: '100vh',
      left: 'unset !important',
      right: '0',
      width: '400px',
      maxHeight: 'unset !important',
      top: '0 !important',
    },
    popoverTitleText: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '18px',
      color: theme.palette.text.primary,
    },
    checkBoxColor: {
      color: theme.palette.primary.main,
    },
    chipSecondaryText: {
      marginLeft: 15,
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.default,
      borderRadius: 30,
      width: 20,
      height: 20,
      justifyContent: 'center',
      marginRight: -7,
    },
    chipPrimaryText: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      color: theme.palette.background.default,
    },
    labelsCountText: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '11px',
      lineHeight: '15px',
      marginLeft: 5,
      marginRight: 5,
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.disabled,
    },
    dialogText: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.primary,
    },
    iconSize: {
      fontSize: 15,
      color: theme.palette.text.primary,
    },
    divBackgroundColor: {
      width: '36px',
      height: '36px',
      borderRadius: '5px',
      marginLeft: '16px',
      cursor: 'pointer',
    },
    textField: {
      width: '24%',
      height: '40px !important',
      '& .MuiOutlinedInput-root': {
        height: '100%',
      },
    },
    textArea: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      color: theme.palette.text.primary,
      marginBottom: '16px',
    },
    editIcon: {
      fontSize: 20,
      color: theme.palette.text.secondary,
      cursor: 'pointer',
    },
    deleteIcon: {
      fontSize: 24,
      color: theme.palette.error.main,
      cursor: 'pointer',
      right: '0',
    },
    colorValues: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.palette.text.primary,
    },
    separator: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      color: theme.palette.text.primary,
      margin: '0px 8px',
    },
    btnAddRange: {
      display: 'flex',
      color: theme.palette.primary.main,
      fontSize: '14px',
      '& .MuiSvgIcon-root': {
        fontSize: '16px',
        marginRight: '8px',
      },
      marginTop: '2px',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    btnClose: {
      fontSize: '20px',
      marginLeft: 'auto',
      marginRight: 0,
      cursor: 'pointer',
    },
    divContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 55px)',
    },
    divSettings: {
      padding: '24px 8px 24px 24px',
      display: 'flex',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.grey.light}`,
    },
    divTaskTime: {
      display: 'flex',
      flexDirection: 'column',
      margin: '24px 24px 0px 24px',
    },
    divApply: {
      alignSelf: 'flex-end',
      marginRight: '12px',
      marginBottom: '8px',
    },
  });
