import { useState } from 'react';
import * as React from 'react';
import { styles } from './styles';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { Fullscreen } from '@mui/icons-material';

interface IconTooltipButtonProps {
  /**
   * Data Test ID for testing with Cypress
   */
  'data-testid': string;
  /**
   * Click Event when icon is pressed
   */
  onClick: () => void;
  /**
   * Variant that determines icon that will be displayed
   */
  variant: IconTooltipButtonVariants;
  /**
   * Default size (which determines width and height)
   */
  size?: number;
  /**
   * Optional tooltip text that displays on pointer hover
   */
  tooltip?: string;
  /**
   * Which position does tooltip show relative to icon. Defaults to right
   */
  position?: TooltipPosition;
  /**
   * Icon's color when not hovered
   */
  colorIdle?: 'black' | 'white';
}

const useStyles = makeStyles(styles);

type IconTooltipButtonVariants = 'fullscreen';

const IconFromVariant = (variant: IconTooltipButtonVariants) => {
  switch (variant) {
    case 'fullscreen':
      return <Fullscreen fontSize='inherit' />;
    default:
      return <></>;
  }
};

type TooltipPosition = 'left' | 'right';

interface TooltipProps {
  showTooltip: boolean;
  children: React.ReactNode;
  text: string;
  position: TooltipPosition;
}

const Tooltip: React.FC<TooltipProps> = (props) => {
  const classes: ClassNameMap<string> = useStyles();
  const { children, text, showTooltip, position = 'right' } = props;
  if (!text) {
    return <>{children}</>;
  }

  return (
    <div className={classes.tooltipContainer}>
      {children}
      {showTooltip && (
        <div className={position === 'left' ? classes.tooltipTextLeft : classes.tooltipTextRight}>{text}</div>
      )}
    </div>
  );
};

const IconTooltipButton: React.FC<IconTooltipButtonProps> = (props) => {
  const {
    'data-testid': dataTestId,
    tooltip,
    variant,
    size = 24,
    position = 'right',
    colorIdle = 'black',
    onClick,
  } = props;
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();

  return (
    <div data-testid={dataTestId}>
      <Tooltip text={tooltip} showTooltip={isHovered} position={position}>
        <div
          style={{
            display: 'flex',
            backgroundColor: isHovered ? theme.palette.primary.dark : 'transparent',
            borderRadius: `${size * 2}px`,
            width: size,
            height: size,
            fontSize: size,
            cursor: 'pointer',
            color: !isHovered ? colorIdle : 'black',
          }}
          onPointerEnter={() => setIsHovered(true)}
          onPointerLeave={() => setIsHovered(false)}
          onClick={onClick}
        >
          {IconFromVariant(variant)}
        </div>
      </Tooltip>
    </div>
  );
};

export { IconTooltipButton };
