import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    '@global': {
      '.MuiChip-sizeSmall': {
        height: '22px',
      },
    },
    root: {},
    input: {
      background: theme.palette.background.default,
      boxShadow: 'none !important',
      color: `${theme.palette.text.primary} !important`,
      transition: 'all 0.3s, height 1ms',
      height: '40px',
      width: '100%',
      alignItems: 'flex-start',
      display: 'flex',
      cursor: 'pointer',
      maxWidth: '100%',
      padding: '8px',
      paddingRight: '32px',
      border: `1px solid ${theme.palette.grey.light}`,
      borderRadius: '5px',
      overflow: 'hidden',
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.text.primary,
      },
      '&:focus': {
        color: theme.palette.common.white,
        border: '1px solid',
      },
    },
    border: {},
    activeBorder: {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    inputError: {
      border: `1px solid ${theme.palette.error.main} !important`,
      width: '100%',
      alignItems: 'center',
      display: 'flex',
      cursor: 'pointer',
      maxWidth: '100%',
      padding: '8px 16px',
      '&:hover': {
        border: `1px solid ${theme.palette.error.main}`,
        color: theme.palette.text.primary,
      },
      '&:focus': {
        color: theme.palette.common.white,
        border: '1px solid',
      },
    },
    inputDisable: {
      height: '36px !important',
      boxShadow: 'none !important',
      border: `1px solid ${theme.palette.grey.light}`,
      cursor: 'auto',
    },
    scheduleSettingsInput: {},
    title: {
      color: theme.palette.text.primary,
      cursor: 'default',
      marginBottom: '8px',
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
    },
    placeholder: {
      color: theme.palette.text.secondary,
    },
    divContainer: {
      width: '100%',
      marginBottom: '16px',
    },
    popoverContainer: {
      width: '280px',
      //maxHeight: '250px',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    divValueWithHover: {
      position: 'relative',
      fontFamily: 'Open Sans',
      fontWeight: 400,
      cursor: 'pointer',
      width: 'inherit',
      display: 'flex',
      flexFlow: 'row wrap',
      gap: 4,
    },
    warningIcon: {
      fill: theme.palette.error.main,
      marginLeft: '10px',
      fontSize: '13px',
    },
    relative: {
      marginRight: 8,
      position: 'relative',
    },
    expandIcon: {
      position: 'absolute',
      right: 0,
      top: 8,
    },
    overflowBadge: {
      width: 24,
      height: 24,
      fontSize: 11,
      textAlign: 'center',
      userSelect: 'none',
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.dark,
      padding: '1px 4px',
      borderRadius: '50%',
    },
    expandIconActive: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    expandIconDisabled: {
      color: theme.palette.grey.light,
    },
    chipWidth: {
      display: 'flex',
      /* '&:hover': {
        color: theme.palette.background.default,
        textDecorationLine: 'underline',
      }, */
    },
    avatar: {
      width: 'unset !important',
      display: 'block !important',
      lineHeight: '18px !important',
      borderRadius: '17px',
      paddingLeft: '5px',
      paddingRight: '5px',
      maxWidth: '165px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: `${theme.palette.common.white} !important`,
    },
    errorMessage: {
      color: theme.palette.error.main,
      marginTop: '8px',
      height: '18px',
      display: 'flex',
      alignItems: 'flex-start',
    },
    placeholderContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '6px',
      color: theme.palette.text.disabled,
      bottom: '2px',
    },
  });
