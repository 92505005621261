import * as L from 'leaflet';
import { LeafletContext, MapLayer, withLeaflet } from 'react-leaflet';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

class MarkerClusterGroup extends MapLayer {
  createLeafletElement(props: LeafletContext & { leaflet: Record<string, any> }) {
    //@ts-ignore
    const el = new L.markerClusterGroup(props);

    //@ts-ignore
    this.contextValue = {
      ...props.leaflet,
      layerContainer: el,
    };
    return el;
  }
}

export default withLeaflet(MarkerClusterGroup);
