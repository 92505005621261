import { Dialog } from '@mui/material';
import DefaultButton from '../default-button/default-button';
import { useStyles } from 'src/utils/components/delete-dialog/styles';
import { useTranslation } from 'react-i18next';

interface DeleteDialogProps {
  open: boolean;
  onCancel: () => void;
  onDelete: () => void;
  title: string;
  subtitle: string;
}

const DeleteDialog = ({ open, onCancel, onDelete, title, subtitle }: DeleteDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={onCancel}>
      <div className={classes.deleteDialogContainer}>
        <div className={classes.deleteDialogTitle}>{title}</div>
        <div className={classes.deleteDialogSubtitle}>{subtitle}</div>
        <div className={classes.deleteDialogBtns}>
          <DefaultButton
            data-testid='cancelButtonConfirmationDialog'
            variant='discard'
            onClick={() => {
              onCancel();
            }}
          >
            {t('cancel')}
          </DefaultButton>
          <DefaultButton
            data-testid='deleteButtonConfirmationDialog'
            variant='delete'
            onClick={() => {
              onDelete();
            }}
          >
            {t('delete')}
          </DefaultButton>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteDialog;
