import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    divContainer: {
      marginBottom: '20px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '5px',
      width: '100%',
    },
    input: {
      flexGrow: 1,
      background: 'transparent !important',
      boxShadow: 'none !important',
      color: `${theme.palette.text.primary} !important`,
      transition: 'all 0.3s, height 1ms',
      minHeight: '30px',
      border: `1px solid ${theme.palette.text.primary}`,
      borderRadius: '5px !important',
      alignItems: 'center',
      display: 'flex',
      cursor: 'pointer',
      maxWidth: '100%',
      '&:hover': {
        border: `1px solid ${theme.palette.text.primary}`,
        color: theme.palette.text.primary,
      },
      '&:focus': {
        color: theme.palette.common.white,
        border: '1px solid',
      },
    },
    inputError: {
      flexGrow: 1,
      background: 'transparent !important',
      boxShadow: 'none !important',
      color: `${theme.palette.text.primary} !important`,
      transition: 'all 0.3s, height 1ms',
      minHeight: '30px',
      border: `1px solid ${theme.palette.error.main} !important`,
      borderRadius: '5px !important',
      alignItems: 'center',
      display: 'flex',
      cursor: 'pointer',
      maxWidth: '100%',
      '&:hover': {
        border: `1px solid ${theme.palette.error.main}`,
        color: theme.palette.text.primary,
      },
      '&:focus': {
        color: theme.palette.common.white,
        border: '1px solid',
      },
    },
    inputDisable: {
      flexGrow: 1,
      boxShadow: 'none !important',
      transition: '0.3s',
      minHeight: '30px',
      border: `1px solid ${theme.palette.text.disabled} !important`,
      borderRadius: '5px !important',
      alignItems: 'center',
      display: 'flex',
      pointerEvents: 'none',
      cursor: 'pointer',
    },
    placeholder: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '21px',
      letterSpacing: '0.1px',
      color: theme.palette.text.disabled,
    },
    toolDesignation: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '21px',
      letterSpacing: '0.1px',
      color: theme.palette.text.primary,
    },
    divValue: {
      paddingLeft: '10px',
      paddingRight: '10px',
      width: '100%',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: '35px',
    },
    qrScanBtn: {
      padding: '6 px 22px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '5px',
      borderColor: theme.palette.primary.main,
      /* '&:hover': {
      backgroundColor: theme.palette.error.lv4,
    }, */
    },
    qrIcon: {
      marginRight: '5px',
      color: theme.palette.primary.main,
    },
    qrText: {
      color: theme.palette.primary.main,
    },
    closeBtn: {
      width: '32px',
      height: '32px',
    },
    closeIcon: {
      width: '28px',
      height: '28px',
    },
  });
