import '@fontsource/poppins/500.css';
import { Theme } from '@mui/material/styles';

const theme: Theme = {
  palette: {
    primary: {
      main: '#2470B3',
      light: '#E9F5FE',
      dark: '#1C598F',
    },
    secondary: {
      main: '#272848',
      dark: '#17182B',
    },
    background: {
      default: '#FFFFFF',
      background0: '#F9FAFB',
      background1: '#F2F4F7',
      background2: '#DDE9F1',
      background3: '#232227',
    },
    grey: {
      main: '#667085',
      light: '#D0D5DD',
      medium: '#98A2B3',
    },
    text: {
      primary: '#272848',
      disabled: '#D0D5DD',
      secondary: '#667085',
    },
    success: {
      main: '#00C48C',
      dark: '#02AB7B',
    },
    alert: {
      main: '#FFD279',
      light: '#FFF2D7',
      dark: '#FFC654',
    },
    error: {
      main: '#F44336',
      light: '#F5E8E7',
      medium: '#F9A09A',
      dark: '#E31B0C',
    },
    info: {
      main: '#54C1FB',
      light: '#E9F5FE',
    },
    other: {
      pink: '#EB43B2',
      purpleDark: '#7C18FB',
      purpleLight: '#B468FF',
      peach: '#FE8947',
    },
    label: {
      label1: '#000000',
      label2: '#264653',
      label3: '#2A9D8F',
      label4: '#E9C46A',
      label5: '#F4A261',
      label6: '#E76F5',
      label7: '#EF476F',
      label8: '#FFD166',
      label9: '#06D6AD',
      label10: '#118AB2',
      label11: '#073B4C',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '55px',
          display: 'flex',
          flexDirection: 'row',
          boxShadow: 'none',
          borderBottom: '1px solid #D4D4D4',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          overflow: 'unset',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '35px',
          color: '#272848',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontSize: '0.875rem',
          fontFamily: '"Poppins", sans-serif;',
        },
        body2: {
          fontFamily: 'Open Sans',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '22px',
        },
        caption: {
          fontFamily: 'Open Sans',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '18px',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          minHeight: '0px !important',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          zIndex: 0,
          fontFamily: '"Poppins", sans-serif;',
          fontWeight: 500,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& ::-webkit-input-placeholder': {
            color: `#000 !important`,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '& ::-webkit-input-placeholder': {
            color: `#000 !important`,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& ::-webkit-input-placeholder': {
            color: `#000 !important`,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#E9F5FE',
          },
        },
      },
    },
  },
  typography: {
    subtitle1: {
      fontFamily: '"Open Sans", sans-serif;',
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '16px',
    },
    body1: {
      fontFamily: '"Open Sans", sans-serif;',
      fontWeight: 400,
      fontSize: '0.875rem',
      /* fontSize: '16px', */
      lineHeight: '24px',
    },
    h5: {
      fontFamily: '"Poppins", sans-serif;',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      textAlign: 'center',
    },
    h4: {
      fontFamily: '"Poppins", sans-serif;',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '26px',
      textAlign: 'center',
    },
    h3: {
      fontFamily: '"Poppins", sans-serif;',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '28px',
      textAlign: 'center',
    },
    fontSize: 14,
    button: {
      textTransform: 'none',
    },
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji","Open Sans", sans-serif, "Poppins", sans-serif;',
  },
} as any;

export default theme;
