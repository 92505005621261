import { CheckCircle, Delete, FilterCenterFocus, RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { Button, Chip, Dialog, Grid, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap } from '@mui/styles/withStyles';
import _, { uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { showSnackbar } from 'src/base/root/root.redux';
import { IssueInstance } from 'src/gql/graphql';
import { ToolItem } from 'src/interfaces/inventory';
import { getToolItems } from 'src/modules/inventory/inventory.redux';
import { linkedWithThisInputCount, updateIssue } from 'src/modules/issue-execution/issue.execution.redux';
import store from 'src/reducer-manager';
import DefaultButton from 'src/utils/components/default-button';
import InputField from 'src/utils/components/input-field/input-field';
import { NexusGenFieldTypes } from '../../../../../server/src/types';
import ScanModal from './components/scan-modal';
import { styles } from './styles';

interface QRCodeProps extends ConnectedProps<typeof connector> {
  validations: (NexusGenFieldTypes['InputStringValidation'] & { isAvailable: boolean; inputs: { type: string }[] })[];
  disabled: boolean;
  inputsToValidate?: any[];
  handleChange: (e: any) => void;
  values: any[];
  inputId: string;
  taskId: string;
  data?: any;
  isTool?: boolean;
  issue?: Partial<IssueInstance>;
}

const useStyles = makeStyles(styles);

const QrCode = (props: QRCodeProps) => {
  const {
    validations,
    disabled,
    handleChange,
    values,
    inputId,
    taskId,
    isTool,
    getToolItems,
    updateIssue,
    issue,
    inputsToValidate,
  } = props;

  const classes: ClassNameMap<string> = useStyles();
  const dispatch = useDispatch();

  const validationName = validations.length ? validations[0].name?.split(':') : undefined;
  let result: boolean = true;
  const [qrResult, setQrResult] = useState<string[]>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [unlinkModal, setUnlinkModal] = useState<boolean>(false);
  const [linkInputOptions, setLinkInputOptions] = useState<any>(null);
  const [selectedValidation, setSelectedValidation] = useState<any>(null);
  const [toolItems, setToolItems] = useState<ToolItem[]>();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    setQrResult(isTool ? values.map((val) => val?._id || val) : values);
  }, [values]);

  useEffect(() => {
    if (
      isTool &&
      values.length &&
      validations?.length &&
      validations[0]?.name === 'linkWith' &&
      !validations[0]?.inputs?.filter((i) => i[i.type].values.some((val) => val._id || val)).length
    ) {
      setQrResult([]);
      setToolItems([]);
    }
  }, [values, validations[0]?.inputs]);

  const getItem = async () => {
    const v = validations[0]?.inputs?.filter((inp) => inp[inp.type]?.values?.some((val) => val._id || val));
    let resp = [];
    if (v && v.length) {
      const r = (
        await getToolItems({
          _id_in: v?.flatMap((vl) => vl[vl.type]?.values?.map((val: { _id: string }) => val?._id || val)),
        })
      ).toolItems;
      if ('graphQLErrors' in r) return;
      resp.push(r);
    } else {
      const r = (await getToolItems({ _id_in: qrResult })).toolItems;
      if ('graphQLErrors' in r) return;
      resp.push(r);
    }
    setToolItems(resp[0]);
  };

  useEffect(() => {
    if (isTool && !location.pathname.includes('template')) {
      getItem();
    }
  }, [values, qrResult.length, validations[0]?.inputs]);

  const handleRemoveToolItems = async (changedTool?: boolean, itemToRemove?: string) => {
    const issueToolItems = issue.toolItems
      .filter((ti) => ti?._id !== values.find((val) => val._id === ti?._id)?._id)
      ?.map((ti) => ti?._id);
    await props.updateIssue(
      { _id: issue?._id },
      { toolItems: itemToRemove ? [itemToRemove] : issueToolItems },
      {
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: t('itemRemoved'),
          severity: 'success',
        },
      },
    );
    if (!changedTool) {
      setToolItems([]);
      setQrResult([]);
      handleChange([]);
    }
  };

  function extractIdFromUrl(url) {
    try {
      const urlObj = new URL(url);
      const hash = urlObj.hash;
      const params = new URLSearchParams(hash.split('?')[1]);
      return params.get('id');
    } catch (err) {
      return '';
    }
  }

  const handleScan = async (data: any) => {
    if (data) {
      let toolItem;
      if (isTool) {
        const id = extractIdFromUrl(data);
        if (!id) {
          props.showSnackbar('error', t('toolItemNotFound'));
        }

        if (values.includes(id)) {
          return;
        }

        const resp = (await getToolItems({ _id_eq: id })).toolItems;
        if ('graphQLErrors' in resp) {
          return;
        }

        if (_.isEmpty(resp) && isTool) {
          props.showSnackbar('error', t('toolItemNotFound'));
          return;
        }
        toolItem = isTool && resp;
      }
      const newData = isTool ? toolItem[0]?._id : data;

      if (
        inputsToValidate?.filter((inp) => inp[inp.type]?.values?.map((val) => val?._id || val).length).length !==
        inputsToValidate?.length
      ) {
        return;
      } else if (validations.find((v) => v.name === 'linkWith')) {
        if (!isTool && inputValidWithoutMessage(newData)) {
          const v = inputsToValidate?.map((inp) => inp[inp.type]?.values[0]);
          if (!v.includes(newData)) {
            dispatch({
              type: 'SNACKBAR_NEW_MESSAGE',
              payload: {
                message: `${newData} ${t('doesNotMatch')}`,
                severity: 'error',
              },
            });
            return;
          }

          if (!values.includes(newData)) {
            const newValues = uniq([...qrResult, newData]).filter((x) => v.includes(x));

            handleChange(newValues);
            setQrResult(newValues);

            dispatch({
              type: 'SNACKBAR_NEW_MESSAGE',
              payload: {
                message: `${newData} ${t('scanned')}`,
                severity: 'success',
              },
            });

            if (v?.filter((x) => !newValues.includes(x))?.length) {
              return;
            }
          } else {
            return;
          }
        } else if (
          inputsToValidate?.filter(
            (inp) =>
              inp[inp.type]?.values?.map((val) => val?._id || val).length &&
              (inp[inp.type].values[0] === newData || inp[inp.type].values[0]?._id === newData),
          ).length
        ) {
          const v = inputsToValidate?.map((inp) => inp[inp.type]?.values[0]);
          if (isTool && !qrResult.includes(newData)) {
            await updateIssue(
              { _id: issue?._id },
              {
                toolItems: [
                  ...issue.toolItems?.filter((ti) => !qrResult.includes(ti._id)).map((ti) => ti._id),
                  newData,
                ],
              },
              {
                type: 'SNACKBAR_NEW_MESSAGE',
                payload: {
                  message: t('newItemAdded'),
                  severity: 'success',
                },
              },
            );
          }

          const newValues = uniq([...qrResult, newData]);
          handleChange(newValues);
          setQrResult(newValues);

          if (v?.filter((x) => !newValues.includes(x?._id || x))?.length) {
            return;
          }
        } else {
          store.dispatch({
            type: 'SNACKBAR_NEW_MESSAGE',
            payload: {
              message: `${data} ${t('doesntMatchValidation')}`,
              severity: 'error',
            },
          });
          isTool && setQrResult(qrResult.filter((qr) => qr !== newData));
        }
      } else {
        result = validations.length ? inputValid(data) : true;
        if (result === true) {
          handleChange([newData]);
          setQrResult([newData]);
        }
      }
      handleClose();
    }
  };

  const handleError = (err: any) => {
    const message: string = err.toString();

    handleClose();

    if (message.toLowerCase().includes('permission')) {
      store.dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: `${t('errorPermissionCamera')}`,
          severity: 'error',
        },
      });
    } else {
      store.dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: `${t('errorQRCodeGeneric')}`,
          severity: 'error',
        },
      });
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const inputValid = (QRCodeResult: string): boolean => {
    const val = validations.find((v) => v.name !== 'linkWith');
    if (val && val.min && QRCodeResult.length < val.min) {
      store.dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: `${t('errorNumberChar_1')} ${t('qrCode')} ${t('errorNumberCharMin_2')}`,
          severity: 'error',
        },
      });
      return false;
    }

    if (val && val.max && QRCodeResult.length > val.max) {
      store.dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: `${t('errorNumberChar_1')} ${t('qrCode')} ${t('errorNumberCharMax_2')}`,
          severity: 'error',
        },
      });
      return false;
    }

    if (val && val.regex) {
      // eslint-disable-next-line security/detect-non-literal-regexp
      const regex = new RegExp(val.regex);

      if (!regex.test(QRCodeResult)) {
        store.dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: `${t('qrCode')} ${t('doesntMatchRegexValidation')}`,
            severity: 'error',
          },
        });
        return false;
      }
    }

    return result;
  };

  const inputValidWithoutMessage = (QRCodeResult: string): boolean => {
    const val = validations.find((v) => v.name !== 'linkWith');
    if (val && val.min && QRCodeResult.length < val.min) {
      return false;
    }

    if (val && val.max && QRCodeResult.length > val.max) {
      return false;
    }

    if (val && val.regex) {
      // eslint-disable-next-line security/detect-non-literal-regexp
      const regex = new RegExp(val.regex);

      if (!regex.test(QRCodeResult)) {
        return false;
      }
    }

    return true;
  };

  return (
    <>
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            data-testid={'qrCodeButton'}
            onClick={() => setDialogOpen(true)}
            color='primary'
            variant={'outlined'}
            className={classes.qrScanBtn}
            disabled={disabled}
          >
            <FilterCenterFocus className={`material-icons ${disabled ? '' : classes.qrIcon}`} />
            <Typography data-testid={'qrCodeButtonText'} classes={{ root: disabled ? '' : classes.qrText }}>
              {disabled ? t('scan') : qrResult.length ? t('reScan') : t('scan')}
            </Typography>
          </Button>
          {qrResult.length && !validations.find((v) => v.name === 'linkWith') ? (
            <CheckCircle
              data-testid={'qrCodeButtonValid'}
              style={{ fontSize: '26px' }}
              classes={{ root: classes.checkIcon }}
            />
          ) : validationName?.length > 1 && validationName[1] ? undefined : undefined}
        </div>
        <div style={{ display: 'flex' }}>
          {validations.find((v) => v.name === 'linkWith') ? (
            <div style={{ marginTop: '20px', gap: '10px', display: 'flex' }}>
              {!validations.find((v) => v.name === 'linkWith')?.isAvailable
                ? undefined
                : inputsToValidate
                    ?.filter(
                      (inp) => inp[inp.type]?.values?.map((val) => val?._id).length || inp[inp.type].values.length,
                    )
                    .map((inp, idx) =>
                      toolItems?.length ? (
                        toolItems?.find(
                          (ti) => ti?._id === inp[inp.type]?.values[0]?._id || ti?._id === inp[inp.type]?.values[0],
                        )?.code &&
                        toolItems?.find(
                          (ti) => ti?._id === inp[inp.type]?.values[0]?._id || ti?._id === inp[inp.type]?.values[0],
                        )?.tool?.designation ? (
                          <Chip
                            data-testid={'qrCodeChipTool'}
                            key={idx}
                            size='small'
                            classes={{
                              root: values?.find((val) =>
                                inp[inp.type]?.values?.some((ival) => (ival?._id || ival) === (val?._id || val)),
                              )
                                ? classes.chipValueMatch
                                : classes.chipValue,
                            }}
                            label={
                              <div
                                data-testid={`${
                                  toolItems.find((ti) => ti?._id === inp[inp.type].values[0]?._id)?.tool?.designation
                                }-qrCodeChipToolText`}
                                className={
                                  values?.find((val) =>
                                    inp[inp.type]?.values?.some((ival) => (ival?._id || ival) === (val?._id || val)),
                                  )
                                    ? classes.chipTextMatch
                                    : classes.chipText
                                }
                              >
                                {`${
                                  toolItems?.find(
                                    (ti) =>
                                      ti?._id === inp[inp.type]?.values[0]?._id || ti?._id === inp[inp.type]?.values[0],
                                  )?.code
                                } - ${
                                  toolItems?.find(
                                    (ti) =>
                                      ti?._id === inp[inp.type]?.values[0]?._id || ti?._id === inp[inp.type]?.values[0],
                                  )?.tool?.designation
                                }`}
                                {values?.find((val) =>
                                  inp[inp.type]?.values?.some((ival) => (ival?._id || ival) === (val?._id || val)),
                                ) ? (
                                  <CheckCircle
                                    data-testid={'qrCodeChipToolValid'}
                                    classes={{ root: classes.checkIcon }}
                                  />
                                ) : undefined}
                              </div>
                            }
                          />
                        ) : null
                      ) : (
                        <Chip
                          data-testid={'qrCodeChip'}
                          key={idx}
                          size='small'
                          classes={{
                            root: values?.includes(inp[inp.type]?.values[0])
                              ? classes.chipValueMatch
                              : classes.chipValue,
                          }}
                          label={
                            <div
                              data-testid={`${
                                isTool
                                  ? inp[inp.type]?.values?.map((val) => val?._id)?.length
                                  : inp[inp.type].values.length
                              }-qrCodeChipText`}
                              className={
                                values?.includes(inp[inp.type]?.values[0]) ? classes.chipTextMatch : classes.chipText
                              }
                            >
                              {isTool ? inp[inp.type]?.values?.map((val) => val?._id) : inp[inp.type].values}
                              {values?.includes(inp[inp.type]?.values[0]) ? (
                                <CheckCircle classes={{ root: classes.checkIcon }} />
                              ) : undefined}
                            </div>
                          }
                        />
                      ),
                    )}
              {!validations.find((v) => v.name === 'linkWith')?.isAvailable &&
              !location.pathname.includes('templates') ? (
                <div className={classes.qrCodeDisable}>{t('qrCodeDisable')}</div>
              ) : undefined}
            </div>
          ) : (
            <div data-testid={'qrCodeInputField'} style={{ display: qrResult.length ? 'contents' : 'none' }}>
              <InputField
                data-testid={'validation'}
                title={''}
                type={'string'}
                placeholder={
                  qrResult.length && toolItems?.length
                    ? `${toolItems[0].code} - ${toolItems[0].tool?.designation}`
                    : qrResult.length
                      ? qrResult[0]
                      : validationName?.length > 1 && validationName[1]
                        ? `${t(validationName[0])} ${validationName[1]}`
                        : ''
                }
                disabled={true}
                handleChange={null}
                error={result === true ? false : true}
              />
            </div>
          )}
          <IconButton
            data-testid={'qrCodeDelete'}
            onClick={() => {
              props
                .linkedWithThisInputCount(inputId, taskId)
                .then((resp) => {
                  if (resp > 0) {
                    setUnlinkModal(true);
                  } else {
                    setQrResult([]);
                    setToolItems([]);
                    handleChange([]);
                  }
                })
                .catch((e) => console.error(e));
            }}
            className={classes.deleteButton}
            style={{
              display: qrResult.length && !validations.find((v) => v.name === 'linkWith') ? 'flex' : 'none',
            }}
            disabled={qrResult.length ? false : validationName?.length > 1 && validationName[1] ? true : true}
            size='large'
          >
            <Delete fontSize='small' />
          </IconButton>
        </div>
      </>
      <Dialog data-testid={'qrCodeUnlinkModal'} open={unlinkModal} onClose={() => setUnlinkModal(false)}>
        <div style={{ paddingLeft: '25px', paddingRight: '25px', width: '410px' }}>
          <div className={classes.unlinkTitle}>{t('wantToDelete')}</div>
          <div className={classes.unlinkSubTitle}>{t('wantToDeleteSubText')}</div>
          <div
            style={{ marginTop: '35px', display: 'flex', justifyContent: 'center', gap: '5px', marginBottom: '10px' }}
          >
            <DefaultButton data-testid={'qrCodeUnlinkModalCancel'} discard onClick={() => setUnlinkModal(false)}>
              {t('cancel')}
            </DefaultButton>
            <DefaultButton
              data-testid={'qrCodeUnlinkModalDelete'}
              remove
              onClick={() => {
                if (isTool) {
                  handleRemoveToolItems();
                } else {
                  setQrResult([]);
                  handleChange([]);
                }
                setUnlinkModal(false);
              }}
            >
              {t('delete')}
            </DefaultButton>
          </div>
        </div>
      </Dialog>

      <ScanModal
        open={dialogOpen}
        inputValues={values}
        labels={inputsToValidate?.filter(
          (inp) => inp[inp.type]?.values?.map((val) => val?._id).length || inp[inp.type].values.length,
        )}
        onClose={handleClose}
        handleError={handleError}
        handleScan={handleScan}
        isTool={isTool}
        toolItems={toolItems}
      />

      {/* <Dialog
        data-testid={'qrCodeScanModal'}
        open={dialogOpen}
        onClose={handleClose}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle>
          <Typography className={classes.dialogTitle}>{t('scanDialog')}</Typography>
          <IconButton
            data-testid={'qrCodeScanModalClose'}
            aria-label='close'
            className={classes.closeButton}
            onClick={handleClose}
            size='large'
          >
            <Close fontSize={'small'} />
          </IconButton>
        </DialogTitle>
        <DialogContent data-testid={'qrCodeScanModalScan'} className={classes.dialogContent}>
          <QrReader delay={500} onError={handleError} onScan={handleScan} />
        </DialogContent>
      </Dialog> */}
      {linkInputOptions ? (
        <Dialog data-testid={'qrCodeLinkInputOptions'} open={true} onClose={() => setLinkInputOptions(false)}>
          <div style={{ paddingLeft: '25px', paddingRight: '25px', width: '536px' }}>
            <div className={classes.linkTitle}>{t('chooseCodeWishValidate')}</div>
            <div className={classes.linkSubTitle}>{t('codeWillReplaceOriginalCode')}</div>
            <Grid container md={12} spacing={3} style={{ marginTop: '10px' }}>
              {inputsToValidate
                ?.filter(
                  (inp) =>
                    (isTool ? inp[inp.type]?.values?.map((val) => val._id).length : inp[inp.type].values.length) &&
                    !(
                      ((isTool ? inp[inp.type]?.values?.map((val) => val._id).length : inp[inp.type].values.length) &&
                        values.length &&
                        values?.includes(isTool ? inp[inp.type].values[0]._id : inp[inp.type].values[0])) ||
                      values.filter((v) => inputValidWithoutMessage(isTool ? v._id : v)).length
                    ),
                )
                ?.map((inp) =>
                  (isTool ? inp[inp.type]?.values?.map((val) => val._id) : inp[inp.type]?.values)?.map((v, idx) => (
                    <Grid key={idx} item xs={6} style={{ display: 'flex' }}>
                      {selectedValidation?.validation === v ? (
                        <RadioButtonChecked
                          data-testid={'qrCodeLinkInputOptionsRadioChecked'}
                          classes={{ root: classes.radioBtnChecked }}
                        />
                      ) : (
                        <RadioButtonUnchecked
                          data-testid={'qrCodeLinkInputOptionsRadioUnchecked'}
                          classes={{ root: classes.radioBtnUnchecked }}
                          onClick={() => {
                            setSelectedValidation({ validation: v, index: idx });
                          }}
                        />
                      )}
                      <Chip
                        data-testid={'qrCodeLinkInputOptionsChip'}
                        size='small'
                        classes={{
                          root: classes.chipValue,
                        }}
                        label={
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {isTool
                              ? `${toolItems.find((ti) => ti?._id === inp[inp.type].values[0]._id)?.code} - ${
                                  toolItems.find((ti) => ti?._id === inp[inp.type].values[0]._id)?.tool?.designation
                                }`
                              : v}
                          </div>
                        }
                      />
                    </Grid>
                  )),
                )}
            </Grid>
            <div
              style={{ marginTop: '35px', display: 'flex', justifyContent: 'center', gap: '5px', marginBottom: '10px' }}
            >
              <DefaultButton
                data-testid={'qrCodeLinkInputOptionsOk'}
                success
                disabled={!selectedValidation}
                onClick={async () => {
                  await updateIssue(
                    { _id: issue?._id },
                    {
                      toolItems: [...issue.toolItems.map((ti) => ti?._id), selectedValidation.validation],
                    },
                    {
                      type: 'SNACKBAR_NEW_MESSAGE',
                      payload: {
                        message: t('newItemAdded'),
                        severity: 'success',
                      },
                    },
                  );
                  handleChange([...qrResult, selectedValidation.validation]);
                  setQrResult([...qrResult, selectedValidation.validation]);
                  setLinkInputOptions(false);
                  handleClose();
                }}
              >
                {t('ok')}
              </DefaultButton>
            </div>
          </div>
        </Dialog>
      ) : undefined}
    </>
  );
};

const connector = connect(null, {
  linkedWithThisInputCount,
  getToolItems,
  showSnackbar,
  updateIssue,
});

export default connector(QrCode);
