import {
  ApolloClient,
  ApolloLink,
  disableFragmentWarnings,
  HttpLink,
  InMemoryCache,
  NextLink,
  Operation,
  split,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import fetch from 'cross-fetch';
import { createClient } from 'graphql-ws';
import { find, isEmpty } from 'lodash';
import omitDeep from 'omit-deep-lodash';
import { GRAPHQL_URL, WEBSOCKET_URL } from 'src/utils/env';
import { getAPIBasePath, getWebsocketBasePath } from 'src/utils/funcs';
import { processErrors } from 'src/utils/funcs/error-utils';
import { v4 as uuidV4 } from 'uuid';

const ACCESS_TOKEN = 'access_token';
const ACCESS_ASSIST_TOKEN = 'access_assist_token';
const UNIQUE_ID = uuidV4();

const httpLink = new HttpLink({
  fetch,
  uri: getAPIBasePath(GRAPHQL_URL),
});

const authLink = setContext((_, { headers }) => {
  //localStorage.removeItem("token");
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(ACCESS_TOKEN);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? token : '',
      wsUniqueId: UNIQUE_ID,
    },
  };
});

const uploadLink = createUploadLink({ uri: getAPIBasePath(GRAPHQL_URL) });

const wsLink = new GraphQLWsLink(
  createClient({
    url: getWebsocketBasePath(WEBSOCKET_URL),
    connectionParams: {
      get authorization() {
        return localStorage.getItem(ACCESS_TOKEN);
      },
      wsUniqueId: UNIQUE_ID,
    },
    retryAttempts: Infinity,
    keepAlive: 10000,
    shouldRetry: () => !!localStorage.getItem(ACCESS_TOKEN),
  }),
);

const retryLink = new RetryLink({
  delay: {
    jitter: true,
  },
  attempts: {
    max: 2,
    retryIf: (error, _operation) => !!error,
  },
});

const isFile = (value) => {
  return (
    (typeof value !== 'undefined' && value instanceof File) || (typeof value !== 'undefined' && value instanceof Blob)
  );
};

const isUpload = ({ variables }) => {
  // @ts-ignore
  return Object.values(variables).some(isFile);
};

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const flowLink = split(isUpload, uploadLink, splitLink);

const errorHandling = onError((handler) => {
  // TODO all errors are in 'errors' var
  if (
    handler.networkError &&
    (handler.networkError.message === 'Failed to fetch' || handler.networkError.message === 'Network request failed')
  ) {
    handler.networkError.message = 'Unable to connect to the server.';
  }
  const errors = processErrors(handler);
  if (
    !isEmpty(errors) &&
    find(errors, ['message', "Authentication failed: 'Authorization Token Not Valid!'"]) &&
    window.location.pathname !== '/login'
  ) {
    window.localStorage.clear();
    window.location.href = window.location.origin;
  }
});

const removeTypenameFromMutation = (operation: Operation, forward: NextLink) => {
  const definition = operation?.query?.definitions.find((def) => def.kind === 'OperationDefinition');

  if (definition?.kind == 'OperationDefinition' && definition.operation === 'mutation') {
    operation.variables = omitDeep(operation.variables, '__typename');
  }

  return forward(operation);
};

const removeTypenameFromMutationLink = new ApolloLink(removeTypenameFromMutation);

const customMergeFunction = (existing = {}, incoming = {}) => {
  return {
    ...existing,
    ...incoming,
  };
};

const APOLLO_CLIENT = new ApolloClient({
  link: removeTypenameFromMutationLink.concat(authLink.concat(retryLink).concat(errorHandling.concat(flowLink))),
  cache: new InMemoryCache({
    typePolicies: {
      IssueTemplateDraft: {
        fields: {
          frame: {
            merge: customMergeFunction,
          },
        },
      },
    },
  }),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
  },
});

disableFragmentWarnings();

export { APOLLO_CLIENT, ACCESS_TOKEN, ACCESS_ASSIST_TOKEN };
