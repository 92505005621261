import { Fragment, useMemo } from 'react';
import { styles } from './styles/main';
import i18n from 'src/utils/translations/i18n';
import { calculateTotal, renderValue } from '../../widget-helper';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import ChipLabelAccount from 'src/utils/components/chips/chip-label-account';

const useStyles = makeStyles(styles);

const TableChartWidget = (props: any) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const amountType = useMemo(() => {
    if (props.showAsPercentage) {
      return 'percentage';
    }

    if (
      props.dataType.endsWith('Count') ||
      props.dataType === 'responsesTotalValues' ||
      props.dataType.endsWith('Planned') ||
      props.dataType.endsWith('Total')
    ) {
      return 'count';
    }

    if (props.dataType.endsWith('Duration')) {
      return 'duration';
    }
    if (props.dataType.endsWith('Average')) {
      return 'average';
    }

    if (!props.dataType) {
      return 'date';
    }

    return '';
  }, [props.dataType, props.showAsPercentage]);

  const total = useMemo(() => calculateTotal(props.result), [props.result, props.showAsPercentage]);

  const data = useMemo(() => {
    if (props.result?.length) {
      const sortData = props.dataType.includes('response') ? props.result : sortBy(props.result, 'name');
      return sortData.map((inp) => {
        if (inp.metadata) {
          return {
            name: props.dataType.includes('response') ? inp.name : i18n.t(inp.metadata[inp.name].name),
            value:
              typeof inp[inp.name] === 'number'
                ? renderValue(inp[inp.name], total, props.showAsPercentage)
                : moment(inp.value).format('DD/MM/YYYY HH:mm'),
            metadata: inp.metadata[inp.name],
          };
        } else {
          return {
            name: i18n.t(inp.name),
            value: renderValue(inp[inp.name] || inp.count || 0, total, props.showAsPercentage),
          };
        }
      });
    }
    return [];
  }, [props.result, props.showAsPercentage]);

  return (
    <div id={props.id} className={classes.tableGrid}>
      <div className={classes.tableHeader}>{t(props.by ? 'editWidgetBy.' + props.by : props.result[0].name)}</div>
      <div className={classes.tableHeader}>{t(amountType)}</div>
      {data.map((r, index) => (
        <Fragment key={index}>
          {props.by?.toLowerCase().endsWith('labelvalues') ? (
            <ChipLabelAccount
              data-testid={`chip-labelaccount-${index}`}
              labelValues={r.metadata ? [r.metadata] : []}
              accounts={[]}
            />
          ) : props.by === 'assignee' || r.metadata?.__typename === 'Account' ? (
            <ChipLabelAccount
              data-testid={`chip-labelaccount-${index}`}
              accounts={r.metadata ? [r.metadata] : []}
              labelValues={[]}
            />
          ) : (
            <div>{r.name}</div>
          )}
          <div>
            {`${r.value} ${amountType === 'duration' ? (props.dataset[props.dataType]?.timeUnit ? t(props.dataset[props.dataType]?.timeUnit + 's') : t('hours')) : ''}
                  `}
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default TableChartWidget;
