export const PERMISSION_ERROR = 'permission_error';
export const DEVICES_ERROR = 'devices_error';
export const OPERATION_ABORTED = 'OPERATION_ABORTED';
export const NO_NETWORK_CONNECTION = 'NO_NETWORK_CONNECTION';
export const ROOM_NOT_FOUND = 'ROOM_NOT_FOUND';
export const ROOM_UNEXPECTED_ERROR = 'ROOM_UNEXPECTED_ERROR';
export const UNABLED_TO_CREATE_ROOM = 'UNABLED_TO_CREATE_ROOM';
export const UNABLED_TO_CREATE_ROOM_SESSION = 'UNABLED_TO_CREATE_ROOM_SESSION';
export const UNABLED_GET_ROOM_USERS = 'UNABLED_GET_ROOM_USERS';
export const UNABLED_AGORA_IO_TOKENS = 'UNABLED_AGORA_IO_TOKENS';
export const INVALID_AGORA_IO_TOKENS = 'INVALID_AGORA_IO_TOKENS';
export const UNABLED_TO_JOIN_AGORA_SERVICES = 'UNABLED_TO_JOIN_AGORA_SERVICES';
export const ERROR = 'ERROR';
export const INVALID_SESSION = 'INVALID_SESSION';
export const GUEST_ALREADY_IN_ROOM = 'GUEST_ALREADY_IN_ROOM';
export const INVALID_GUEST_NAME = 'INVALID_GUEST_NAME';
export const INTERNAL_ERROR = 'INTERNAL_ERROR';
export const SESSION_ALREADY_IN_ROOM = 'SESSION_ALREADY_IN_ROOM';
export const SESSION_IN_ANOTHER_ROOM = 'SESSION_IN_ANOTHER_ROOM';
export const CREATE_NEW_SESSION_FEATURE_UNAVAILABLE = 'CREATE_NEW_SESSION_FEATURE_UNAVAILABLE';
export const JOIN_SESSION_FEATURE_UNAVAILABLE = 'JOIN_SESSION_FEATURE_UNAVAILABLE';
export const EXTERNAL_DATA_NOT_FOUND = 'EXTERNAL_DATA_NOT_FOUND';
export const INVALID_EXTERNAL_DATA = 'INVALID_EXTERNAL_DATA';
