import { MainContainerState } from 'src/base/main-container/context/main.container.context';
import { TourSubStepsProps } from '../components/tour.sub.steps';

/**
 * This will handle the logic of whenever the user clicks to skip in a substep,
 * the progress will be updated accordingily
 * @param state
 * @param maxSubSteps
 * @param props
 * @param loggedUser
 * @param updateMainContainerState
 */
export const handleSkipSubSteps = (
  state: MainContainerState,
  maxSubSteps: number,
  props: TourSubStepsProps,
  loggedUser: any,
  updateMainContainerState: (newData: Partial<MainContainerState>) => void,
) => {
  const totalMajorSteps = 3;
  const stepsInMajorStep = loggedUser.onBoarding.maxSubStep + 1;

  let progressTotal: number;
  switch (state.currentStep) {
    case 'build':
      progressTotal = ((1 - 1) * stepsInMajorStep + maxSubSteps) * (100 / (totalMajorSteps * stepsInMajorStep));
      props.updateLoggedUser(
        {
          id: loggedUser._id,
          onBoarding: {
            ...loggedUser.onBoarding,
            templateDraft: loggedUser.onBoarding.templateDraft._id,
            template: null,
            openIssueDraft: null,
            openIssue: null,
            issueInstance: null,
            currentSubStep: maxSubSteps,
            progressTotal: Math.floor(progressTotal),
          },
        },
        false,
      );
      break;
    case 'createIssue':
      progressTotal = ((2 - 1) * stepsInMajorStep * 2 + maxSubSteps) * (100 / (totalMajorSteps * stepsInMajorStep * 2));
      props.updateLoggedUser(
        {
          id: loggedUser._id,
          onBoarding: {
            ...loggedUser.onBoarding,
            templateDraft: null,
            template: loggedUser.onBoarding.template._id,
            openIssueDraft: loggedUser.onBoarding.openIssueDraft._id,
            openIssue: null,
            issueInstance: null,
            currentSubStep: maxSubSteps,
            progressTotal: Math.floor(progressTotal),
          },
        },
        false,
      );
      break;
    case 'createTasks':
      // 50 because of being half of a step and 33 is the 100 / 3
      progressTotal =
        ((2 - 1) * stepsInMajorStep * 2 + maxSubSteps) * (50 / (totalMajorSteps * stepsInMajorStep * 2)) + 33;
      props.updateLoggedUser(
        {
          id: loggedUser._id,
          onBoarding: {
            ...loggedUser.onBoarding,
            templateDraft: null,
            template: loggedUser.onBoarding.template._id,
            openIssueDraft: loggedUser.onBoarding.openIssueDraft._id,
            openIssue: null,
            issueInstance: null,
            currentSubStep: maxSubSteps,
            progressTotal: Math.floor(progressTotal),
          },
        },
        false,
      );
      break;
    case 'execute':
      progressTotal = ((3 - 1) * stepsInMajorStep + maxSubSteps) * (100 / (totalMajorSteps * stepsInMajorStep));
      props.updateLoggedUser(
        {
          id: loggedUser._id,
          onBoarding: {
            ...loggedUser.onBoarding,
            templateDraft: null,
            template: loggedUser.onBoarding.template._id,
            openIssueDraft: null,
            openIssue: loggedUser.onBoarding.openIssue._id,
            issueInstance: loggedUser.onBoarding.issueInstance._id,
            currentSubStep: maxSubSteps,
            progressTotal: Math.floor(progressTotal),
          },
        },
        false,
      );
      break;
  }
  updateMainContainerState({ startSubSteps: false, generateSubComponent: '' });
};
