import gql from 'graphql-tag';
import { LABEL_VALUE } from 'src/utils/fragments/label';

export const PostFragment = gql`
  fragment postFragment on Post {
    __typename
    _id
    subject
    description
    createdAt
    updatedAt
    totalComments
    filesIds
    labelValues {
      ...labelValue
    }
    createdBy {
      _id
      name
      username
      photo
    }
    hasSeen
    seenBy {
      _id
      name
      username
      photo
    }
    editedAt
  }

  ${LABEL_VALUE}
`;

export const CommentFragment = gql`
  fragment commentFragment on Comment {
    __typename
    _id
    content
    mentions {
      text
      type
      issueId
      issue {
        _id
        name
        stateMachineInstance {
          isRunning
        }
      }
      actionId
      accountId
    }
    postId
    parentId
    parentsTreeIds
    createdBy {
      _id
      name
      username
      photo
    }
    createdAt
    updatedAt
  }
`;

gql`
  mutation CreatePost($data: PostCreateInput!) {
    createPost(data: $data) {
      _id
    }
  }

  mutation UpdatePost($id: ID!, $data: PostUpdateInput!) {
    updatePost(where: { _id: $id }, data: $data) {
      _id
    }
  }

  mutation DeletePost($id: ID!) {
    deleteOnePost(where: { _id: $id }) {
      _id
    }
  }

  mutation UnDeletePost($id: ID!) {
    unDeleteOnePost(where: { _id: $id })
  }

  mutation MarkSeenPost($id: ID!) {
    markSeenPost(postId: $id) {
      _id
    }
  }

  query GetPost($id: ID!) {
    post(where: { _id: $id }) {
      ...postFragment
    }
  }

  query GetPosts($where: PostWhereInput!, $countWhere: PostWhereInput!, $limit: Int!, $orderBy: [PostOrderByInput!]!) {
    posts(where: $where, limit: $limit, orderBy: $orderBy) {
      ...postFragment
    }

    postsCount(where: $countWhere)
  }

  query GetComments(
    $where: CommentWhereInput!
    $skip: Int!
    $limit: Int!
    $orderBy: [CommentOrderByInput!]!
    $withRootComment: Boolean
  ) {
    comments(where: $where, skip: $skip, limit: $limit, orderBy: $orderBy, withRootComment: $withRootComment) {
      ...commentFragment
    }
  }

  query GetCommentsCount($where: CommentWhereInput!) {
    commentsCount(where: $where)
  }

  query GetAdjacentCommentsCount($beforeWhere: CommentWhereInput!, $afterWhere: CommentWhereInput!) {
    beforeCount: commentsCount(where: $beforeWhere)
    afterCount: commentsCount(where: $afterWhere)
  }

  subscription SubscribeComment($id: ID!) {
    commentUpdatedV2(_id: $id, ignoreSender: false) {
      ...commentFragment
    }
  }

  subscription SubscribePosts($where: [ID!]!) {
    postsUpdated(where: $where, ignoreSender: false) {
      ...postFragment
    }
  }

  subscription SubscribeNewPosts {
    postCreated {
      ...postFragment
    }
  }

  subscription SubscribeNewComments($postIds: [ID], $parentIds: [ID]) {
    commentCreatedV2(postIds: $postIds, parentIds: $parentIds, ignoreSender: false) {
      ...commentFragment
    }
  }

  subscription SubscribeDeletedComments($where: [ID!]!) {
    commentDeleted(where: $where, ignoreSender: false)
  }

  mutation CreateComment($data: CommentCreateInput!) {
    createComment(data: $data) {
      ...commentFragment
    }
  }

  mutation UpdateComment($id: ID!, $data: CommentUpdateInput!) {
    updateComment(where: { _id: $id }, data: $data) {
      ...commentFragment
    }
  }
  mutation DeleteComment($id: ID!) {
    deleteOneComment(where: { _id: $id }) {
      _id
    }
  }
`;
