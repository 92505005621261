import gql from 'graphql-tag';
import { LABEL_VALUE } from './label';

export const TASK_INSTANCE = gql`
  fragment taskInstance on Task {
    __typename
    stateMachineInstance {
      ...stateMachineInstance
    }
    order
    score {
      currentScore
      totalScore
    }
    onResponseId
    isActiveByOnResponse
    parentsTree
    possibleActions
    assignedAccounts {
      name
      email
      photo
      _id
      deleted
    }
    estimatedDuration
    assignedTools {
      tool {
        designation
        partNumber
        _id
      }
      quantity
      reserveAtStart
      associatedWith {
        designation
        partNumber
        _id
      }
    }
    assignedMaterials {
      material {
        code
        name
        _id
      }
      quantity
    }
    advancedLabelSelection {
      name
    }
    inputs {
      ...input
    }
    assignedLabelValues {
      ...labelValue
    }
    labelValues {
      ...labelValue
    }
    name
    description
    _id
    isLocked
    site {
      name
      _id
    }
    element {
      name
      site {
        name
        coordinates
        _id
      }
      profile {
        name
        _id
      }
      parentsTree {
        name
        _id
      }
      _id
    }
    executionInputs {
      ...inputWithRecursive
    }
    isRepeatable
  }
  ${LABEL_VALUE}
`;

export const TASK_INSTANCE_TO_TABLE = gql`
  fragment taskInstanceToTable on Task {
    __typename
    order
    isLocked
    parentsTree
    assignedLabelValuesIds
    assignedLabelValues {
      ...labelValue
    }
    assignedAccountsIds
    assignedAccounts {
      _id
      name
      username
      email
      photo
      deleted
    }
    executionInputs {
      ...inputWithRecursive
    }
    estimatedDuration
    isRepeatable
    possibleActions
    stateMachineInstance {
      ...stateMachineInstance
    }
    name
    _id
  }
  ${LABEL_VALUE}
`;
