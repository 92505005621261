import { ReactNode, createContext, useContext, useState } from 'react';
import { IssueExecutionState } from '../types/issue.execution.type';
import { useSubscription } from '@apollo/client';
import { S_ON_ISSUE_UPDATE } from '../issue.execution.queries';

export const issueExecutionContextInitialValue: IssueExecutionState = {
  location: null,
  loading: false,
  loadingButtons: false,
  issue: null,
  selectedTask: null,
  selectedTaskIndex: null,
  selectedExecGroup: null,
  issueAction: '',
  actionsCount: 0,
  issueActions: [],
  responsesIds: [],
  resetIssue: false,
  currentInput: null,
  alerts: [],
  loadingRepeatTask: {},

  // MODALS
  openLogs: false,
  openMoreInfo: false,
  openResetPopover: false,
  openSiteElementDialog: false,
  openToolReservation: false,
  openDescriptionModal: false,
  openPreview: false,
  uncompletedDialog: false,
  openCantFinnishTask: false,
  openModalApprovals: null,
  openActionCreateModal: null,
  openActionsPopover: false,
  openStartIssueDialog: false,
  openMotivPopover: false,
  openIssueWillFinishModal: false,
  openTaskDetails: false,
  openFinishTask: false,
  openConditionalCancelDialog: false,
  openModalDeleteAction: null,
  openFinishIssueModal: false,
  collapsed: false,
  collapsedGroupIds: [],
  openChat: false,
};

const IssueExecutionContext = createContext<IssueExecutionState>(issueExecutionContextInitialValue);

interface IssueExecutionContextProviderProps {
  children: ReactNode;
  initialValue: IssueExecutionState;
}

export const IssueExecutionContextProvider = ({
  children,
  initialValue = issueExecutionContextInitialValue,
}: IssueExecutionContextProviderProps) => {
  const [issueExecutionState, setIssueExecutionState] = useState<IssueExecutionState>(initialValue);

  const updateIssueExecutionState = (newData: Partial<IssueExecutionState>) => {
    setIssueExecutionState((prevState) => ({ ...prevState, ...newData }));
  };

  useSubscription(S_ON_ISSUE_UPDATE, {
    variables: { _id: issueExecutionState?.issue?._id },
    skip: !issueExecutionState?.issue?._id,
    onData: ({ data }) => {
      if (data?.data?.issueInstanceUpdated) {
        updateIssueExecutionState({ issue: data.data.issueInstanceUpdated });
        if (issueExecutionState?.selectedExecGroup) {
          updateIssueExecutionState({
            selectedExecGroup: data.data.issueInstanceUpdated.taskGroups.find(
              (tG) => tG._id === issueExecutionState.selectedExecGroup._id,
            ),
          });
        }
        if (issueExecutionState?.selectedTask) {
          const selectedGroup = data.data.issueInstanceUpdated.taskGroups.find(
            (tG) => tG._id === issueExecutionState.selectedExecGroup._id,
          );
          updateIssueExecutionState({
            selectedTask: selectedGroup.tasks.find((t) => t._id === issueExecutionState.selectedTask?._id),
          });
        }
      }
    },
  });

  return (
    <IssueExecutionContext.Provider
      value={{ ...issueExecutionState, updateIssueExecutionState, setIssueExecutionState }}
    >
      {children}
    </IssueExecutionContext.Provider>
  );
};

export const useIssueExecutionContext = () => {
  const context = useContext(IssueExecutionContext);
  if (!context) {
    throw new Error('useIssueExecutionContext must be used within a AgendaContext');
  }
  return context;
};
