/** Where clause to get actions linked to input */
export const inputLinkedWhere = (props) => {
  return {
    site: { _id_eq: props.issue?.site._id },
    element: props.issue?.element ? { _id_eq: props.issue?.element._id } : undefined,
    element_exists: props.issue?.element ? undefined : false,
    status_nin: ['CANT_DO', 'SOLVED'],
    input_ne: props.input?._id,
    issues_some: {
      issue_eq: props.issue?._id,
      task_eq: props.task?._id,
      input_eq: props.input?._id,
    },
  };
};

/** Where clause to get actions which have input related */
export const inputWhere = (props) => {
  return {
    site: { _id_eq: props.issue?.site._id },
    element: props.issue?.element ? { _id_eq: props.issue?.element._id } : undefined,
    element_exists: props.issue?.element ? undefined : false,
    status_nin: ['CANT_DO', 'SOLVED'],
    input_eq: props.input._id,
  };
};

/** Where clause to get site/element related actions */
export const siteElementWhere = (props) => {
  return {
    site: { _id_eq: props.task.site ? props.task.site._id : props.issue?.site._id },
    element: props.task?.element
      ? { _id_eq: props.task?.element._id }
      : props.issue?.element
        ? { _id_eq: props.issue?.element._id }
        : undefined,
    element_exists: props.task?.element ? undefined : props.issue?.element ? undefined : false,
    input_ne: props.input._id,
    issues_none: {
      issue_eq: props.issue?._id,
      task_eq: props.task?._id,
      input_eq: props.input?._id,
    },
    status_nin: ['CANT_DO', 'SOLVED'],
  };
};
