import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

export const styles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      borderRadius: '4px',
      width: '965px',
      height: '700px',
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      gap: '16px',
      flexDirection: 'column',
      paddingBottom: '16px',
    },
    textContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: 'fit-content',
      gap: '4px',
      paddingTop: '16px',
    },
    text: {
      fontFamily: 'Poppins',
      fontSize: '16px',
    },
    tab: {
      fontFamily: 'Poppins',
      color: theme.palette.grey.main,
      borderBottom: `3px solid ${theme.palette.grey.light}`,
    },
    container: { display: 'flex', justifyContent: 'space-between', padding: '0 24px 0 24px', overflow: 'auto' },
  }),
);
