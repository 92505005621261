import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

export const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: { display: 'flex', height: '40px' },
    button: {
      width: 'fit',
      position: 'relative',
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 4,
      fontFamily: 'Poppins',
      display: 'flex',
      alignItems: 'center',
      marginLeft: 4,
      gap: '8px',
      padding: '6px 16px 6px 16px',
      borderColor: theme.palette.primary.light,
    },
  }),
);
