import { Delete, Visibility, VisibilityOff } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  useActivateManyAccountsMutation,
  useDeactivateManyAccountsMutation,
  useDeleteManyAccountsMutation,
} from 'src/gql/graphql';
import DefaultButton from 'src/utils/components/default-button/default-button';
import Swal from 'sweetalert2';
import { ToastMessage } from '../feed/components/toast';
import { css } from '@emotion/css';
import { useRefresh } from 'src/utils/components/refresh-provider';
import { Divider } from '@mui/material';
import { getDefaultTenant } from './tenant.redux';

type TenantAccountsHeaderProps = {
  selected: string[];
  clear: () => any;
};

export function TenantAccountsHeader(props: TenantAccountsHeaderProps) {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const triggerRefresh = useRefresh();

  const [deleteAccounts, { loading: loadingDelete }] = useDeleteManyAccountsMutation({
    onCompleted(data) {
      enqueueSnackbar(
        <ToastMessage
          severity='success'
          message={`${data.deleteManyAccounts.length} ${t('accounts')} ${t('translation:toastDeleteSuccess')}`}
        />,
        { variant: 'success' },
      );
      triggerRefresh();
      props.clear();
    },
  });

  const [activateAccounts, { loading: loadingActivate }] = useActivateManyAccountsMutation({
    onCompleted(data) {
      enqueueSnackbar(
        <ToastMessage
          severity='success'
          message={`${data.activateManyAccounts.length} ${t('accounts')} ${t('toastActivatedSuccess')}`}
        />,
        { variant: 'success' },
      );
      triggerRefresh();
      props.clear();
    },
  });

  const [deactivateAccounts, { loading: loadingDeactivate }] = useDeactivateManyAccountsMutation({
    onCompleted(data) {
      enqueueSnackbar(
        <ToastMessage
          severity='success'
          message={`${data.deactivateManyAccounts.length} ${t('accounts')} ${t('toastActivatedSuccess')}`}
        />,
        { variant: 'success' },
      );
      triggerRefresh();
      props.clear();
    },
  });

  return (
    <>
      <span
        css={{
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
          fontFamily: 'Open Sans',
        }}
        data-testid={'selected-accounts-count'}
      >
        {props.selected.length} {t('selected')}
      </span>
      <Divider orientation={'vertical'} variant={'middle'} css={{ borderWidth: '1px', height: '80%' }} />
      <DefaultButton
        className={css({
          cursor: 'pointer',
        })}
        loader={loadingDelete}
        data-testid={'delete-selected-accounts-btn'}
        variant={'delete-alt'}
        size={'small'}
        onClick={() =>
          Swal.fire({
            reverseButtons: true,
            showCancelButton: true,
            title: t('deleteAccount'),
            text: t('selectedAccountsWillBeDeleted'),
          }).then((val) => {
            if (val.isConfirmed) {
              deleteAccounts({
                variables: {
                  _ids: props.selected,
                  tenant: getDefaultTenant(),
                },
              });
            }
          })
        }
      >
        <Delete
          css={{
            width: '20px',
            height: '20px',
          }}
        />
        <span>{t('delete')}</span>
      </DefaultButton>
      <DefaultButton
        data-testid={'activate-selected-accounts-btn'}
        loader={loadingActivate}
        className={css({
          cursor: 'pointer',
        })}
        variant={'discard'}
        size={'small'}
        onClick={() =>
          Swal.fire({
            showCancelButton: true,
            reverseButtons: true,
            title: t('activateAccount'),
            text: t('selectedAccountsWillBeActivated'),
          }).then((val) => {
            if (val.isConfirmed) {
              activateAccounts({ variables: { _ids: props.selected, tenant: getDefaultTenant() } });
            }
          })
        }
      >
        <Visibility
          css={{
            width: '20px',
            height: '20px',
          }}
        />
        <span>{t('activate')}</span>
      </DefaultButton>
      <DefaultButton
        data-testid={'deactivate-selected-accounts-btn'}
        loader={loadingDeactivate}
        className={css({
          cursor: 'pointer',
        })}
        variant={'discard'}
        size={'small'}
        onClick={() =>
          Swal.fire({
            reverseButtons: true,
            showCancelButton: true,
            title: t('deactivateAccount'),
            text: t('selectedAccountsWillBeDeactivated'),
          }).then((val) => {
            if (val.isConfirmed) {
              deactivateAccounts({ variables: { _ids: props.selected, tenant: getDefaultTenant() } });
            }
          })
        }
      >
        <VisibilityOff
          css={{
            width: '20px',
            height: '20px',
          }}
        />
        <span>{t('deactivate')}</span>
      </DefaultButton>
    </>
  );
}
