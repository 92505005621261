import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    headerLeftContainer: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.main,
      fontFamily: 'Poppins',
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '28px',
    },
    arrowBack: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
  });
