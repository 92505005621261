import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';

const IssueReportDefaultRefresh = () => lazy(() => import('src/modules/report/issue.report.default'));
const SiteElementReportDefaultRefresh = () => lazy(() => import('src/modules/report/site-element.report.default'));

let IssueReportDefault = IssueReportDefaultRefresh();
let SiteElementReportDefault = SiteElementReportDefaultRefresh();

export const render = {
  report: {
    issue: {
      default: ({ ...props }) => {
        //@ts-ignore
        if (IssueReportDefault?._result?.type === 'error') {
          IssueReportDefault = IssueReportDefaultRefresh();
        }

        return (
          <Suspense fallback={<LazyLoadingContent />}>
            <IssueReportDefault {...props} />
          </Suspense>
        );
      },
    },
    siteElement: {
      default: ({ ...props }) => {
        if (SiteElementReportDefault?._result?.type === 'error') {
          SiteElementReportDefault = SiteElementReportDefaultRefresh();
        }

        return (
          <Suspense fallback={<LazyLoadingContent />}>
            <SiteElementReportDefault {...props} />
          </Suspense>
        );
      },
    },
  },
};

export default render;
