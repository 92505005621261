import gql from 'graphql-tag';

export const STATE_MACHINE = gql`
  fragment stateMachine on StateMachine {
    __typename
    _id
    name
    initState
    protected
    folder {
      parentsTree {
        _id
        name
      }
      parent {
        _id
      }
      protected
      name
      _id
    }
    states {
      name
      _id
      executable
      transitions {
        action
        to
        color
        icon
        _id
      }
      _id
    }
  }
`;

export const STATE_MACHINE_TO_TREE = gql`
  fragment stateMachineToTree on StateMachine {
    __typename
    _id
    name
    protected
    folder {
      protected
      name
      _id
    }
  }
`;

export const STATE_MACHINE_FOLDER = gql`
  fragment stateMachineFolder on StateMachineFolder {
    __typename
    _id
    name
    protected
    parentsTree {
      _id
      name
    }
    parent {
      _id
      name
    }
  }
`;

export const STATE_MACHINE_INSTANCE = gql`
  fragment stateMachineInstance on StateMachineInstance {
    __typename
    currentState
    canceledBySystem
    updatedAt
    canceled
    canceledBy {
      name
      photo
      _id
    }
    catalog {
      name
      initState
      _id
    }
    wip
    changeStateToken
    isRunning
    finishedAt
    log {
      action
      from
      to
      color
      icon
      by {
        name
        _id
      }
      createdAt
    }
    states {
      name
      color
      _id
      executable
      transitions {
        color
        icon
        action
        to
        position
        order
        isFinal
        _id
      }
    }
    _id
  }
`;
