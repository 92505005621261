import { Close, ExpandMore, InfoOutlined, KeyboardArrowLeft } from '@mui/icons-material';
import {
  Divider,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  SelectProps,
  Switch,
  Typography,
} from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import { NexusGenEnums } from '@server/src/types';
import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { showSnackbar } from 'src/base/root/root.redux';
import theme from 'src/lightTheme';
import { createWidget, DashboardTreeState, updateWidget } from 'src/modules/dashboard/dashboard.redux';
import { buildWidgetDataset } from 'src/modules/dashboard/widget-helper';
import BetterFieldPopover from 'src/utils/components/better-field-popover';
import { FieldPopoverItem } from 'src/utils/components/better-field-popover/better-list-by-level/popover-reducer';
import CustomDateDropdown from 'src/utils/components/custom-date-dropdown';
import { DateRange } from 'src/utils/components/custom-date-range-picker';
import CustomNumberInput from 'src/utils/components/custom-number-input';
import CustomSelectMeasurement, { MeasurementPreset } from 'src/utils/components/custom-select-measurement';
import CustomSortBy from 'src/utils/components/custom-sort-by';
import { SortOrder, SortPreset, SortTypes } from 'src/utils/components/custom-sort-by/types';
import CustomValueRangePicker, { CustomRange } from 'src/utils/components/custom-value-range-picker';
import DefaultButton from 'src/utils/components/default-button/default-button';
import ExecutionInputSelect from 'src/utils/components/execution-input-select';
import { ISelectListItem } from 'src/utils/components/execution-input-select/components/select-list-item';
import InputGeneric from 'src/utils/components/input-field/input-generic';
import LabelInput from 'src/utils/components/input-field/input-label';
import PieChartFilter, { FilterDataType } from 'src/utils/components/pie-chart-filter';
import StaticFieldPopover from 'src/utils/components/static-field-popover';
import { run } from 'src/utils/funcs';
import i18n from 'src/utils/translations/i18n';
import { chartsTable } from '../../edit-chart';
import {
  ActionByEnum,
  axis,
  BarChartIcon,
  ChartTypeEnum,
  FilterStateEnum,
  FilterStatusEnum,
  GaugeChartIcon,
  InformationEnum,
  InputByEnum,
  IssueByEnum,
  LineChartIcon,
  MeasurementType,
  PieChartIcon,
  TableChartIcon,
  TextChartIcon,
  TimespanUnit,
  timespanUnits,
  WidgetInfo,
  XAxis,
  YAxis,
} from '../chartCards';
import { styles } from './styles';
import TemporaryPopover from './temporary-popover';

const listStates: Array<{
  value: FilterStateEnum;
  label: string;
}> = [
  { value: 'wip', label: i18n.t('workInProgress') },
  { value: 'notWip', label: i18n.t('pending') },
  { value: 'done', label: i18n.t('done') },
  { value: 'canceled', label: i18n.t('canceled') },
];

const listGroupBy = ['hour', 'day', 'week', 'month', 'year'];

const yAxisValues = ['average', 'highest', 'lowest', 'sum'];

const listStatus: Array<{
  value: FilterStatusEnum;
  label: string;
}> = [
  { value: 'PENDING', label: i18n.t('pending') },
  { value: 'CANT_DO', label: i18n.t('cantDo') },
  { value: 'DOING', label: i18n.t('doing') },
  { value: 'SOLVED', label: i18n.t('solved') },
];
const informations = ['issues', 'actions', 'realtime', 'responses'];

const measurements = {
  issues: ['count', 'average', 'duration', 'planned'],
  actions: ['count', 'duration'],
  realtime: ['last', 'values'],
  responses: ['time', 'average', 'lowest', 'highest', 'sum'],
};

const groupings = {
  issues: ['state', 'catalog', 'element', 'assignee', 'assignedLabelValues', 'labelValues'],
  actions: ['status', 'element', 'assignee', 'assignedLabelValues', 'labelValues'],
  realtime: [],
};

const unitsOptions = [
  {
    id: 'second',
    label: i18n.t('second'),
  },
  {
    id: 'minute',
    label: i18n.t('minute'),
  },
  {
    id: 'hour',
    label: i18n.t('hour'),
  },
];

const menuProps: Partial<SelectProps['MenuProps']> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};

const iconsByName: Record<ChartTypeEnum, JSX.Element> = {
  gauge: GaugeChartIcon,
  bar: BarChartIcon,
  line: LineChartIcon,
  pie: PieChartIcon,
  table: TableChartIcon,
  value: TextChartIcon,
};

type IEditWidgetProps = {
  goBack: (close?: boolean) => any;
  info?: WidgetInfo;
  edit?: boolean;
} & ConnectedProps<typeof connector> &
  WithStyles<typeof styles> &
  WithTranslation;

const EditWidget: React.FC<IEditWidgetProps> = (props) => {
  const { classes, t, draftId, info } = props;
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState<string>('');
  const [chartType, setChartType] = useState<any>(null);
  const [information, setInformation] = useState<InformationEnum>(null);
  const [measurement, setMeasurement] = useState<MeasurementType>(null);
  const [includeArchives, setIncludeArchives] = useState<boolean>(false);
  const [showAsPercentage, setShowAsPercentage] = useState<boolean>(false);
  //const [showArchive, setShowArchive] = useState<boolean>(false);
  const [by, setBy] = useState<IssueByEnum | ActionByEnum | InputByEnum>(null);
  const [xAxis, setXAxis] = useState<XAxis>(null);
  const [yAxis, setYAxis] = useState<YAxis>(null);
  const [elements, setElements] = useState<FieldPopoverItem['siteElement'][]>([]);
  const [range, setRange] = useState<{ min: number; max: number }>({ min: 0, max: 100 });
  const [timeUnit, setTimeUnit] = useState<NexusGenEnums['WidgetDatasetIssuesAverageTimeUnit']>(null);
  const [labelValues, setLabelValues] = useState<FieldPopoverItem['label'][]>([]);
  const [assignedLabelValues, setAssignedLabelValues] = useState<FieldPopoverItem['label'][]>([]);
  const [assignedAccounts, setAssignedAccounts] = useState<FieldPopoverItem['account'][]>([]);
  const [states, setStates] = useState<FilterStateEnum[]>([]);
  const [statuses, setStatuses] = useState<FilterStatusEnum[]>([]);
  const [catalogs, setCatalogs] = useState<FieldPopoverItem['issueCatalog'][]>([]);
  const [tags, setTags] = useState<any[]>([]);
  const [timeSpan, setTimeSpan] = useState<DateRange>(undefined);
  const [timeSpanCount, setTimespanCount] = useState(0);
  const [timeSpanUnit, setTimespanUnit] = useState<TimespanUnit>(null);
  const [groupBy, setGroupBy] = useState<any>(null);
  /* const [questions, setQuestions] = useState<any>([]);
   */
  const [issueCatalog, setIssueCatalog] = useState<FieldPopoverItem['issueCatalog']>();
  const [input, setInput] = useState<ISelectListItem>();
  const [selectedInput, setSelectedInput] = useState<string>('');
  const [selectedMeasurement, setSelectedMeasurement] = useState<MeasurementPreset>({
    name: 'lastValue',
    type: 'last',
  });
  const [customRanges, setCustomRanges] = useState<CustomRange[]>([
    {
      name: 'Target 1',
      max: null,
      color: '#F44336',
    },
  ]);
  const [, setErrorExists] = useState<boolean>(false);
  const [openInfoPopover, setOpenInfoPopover] = useState<Element>(null);
  const [selectedSort, setSelectedSort] = useState<SortPreset>({
    name: 'valuesCountLowestHighest',
    type: SortTypes.valueCount,
    order: SortOrder.asc,
  });
  const [decimalDigits, setDecimalDigits] = useState<number>(props.info.decimalDigits ? props.info.decimalDigits : 0);
  const [pieFilterData, setPieFilterData] = useState<FilterDataType[]>(
    props.info?.customFilters?.length
      ? props.info?.customFilters
      : [{ context: null, type: null, condition: null, values: null, dateValue: null }],
  );
  const [responseOptions, setResponseOptions] = useState<ISelectListItem[]>();

  const isAxis = useMemo(() => ['bar', 'line'].includes(chartType), [chartType]);

  const isSingleValue = useMemo(() => ['value', 'gauge'].includes(chartType), [chartType]);

  const isPreset = useMemo(() => info.header && info.header !== chartType, [info, chartType]);

  const excludeSortPresets =
    info.header === 'responsesTotal' ? ['responsesLowestHighest', 'responsesHighestLowest'] : ['aZ', 'zA'];

  useEffect(() => {
    switch (true) {
      case props.info.sort?.type === 'valueCount':
        setSelectedSort({
          name: props.info.sort?.order === 'asc' ? 'valuesCountLowestHighest' : 'valuesCountHighestLowest',
          type: props.info.sort.type,
          order: props.info.sort.order,
        });
        break;
      case props.info.sort?.type === 'value':
        setSelectedSort({
          name: props.info.sort?.order === 'asc' ? 'responsesLowestHighest' : 'responsesHighestLowest',
          type: props.info.sort.type,
          order: props.info.sort.order,
        });

        break;
      case props.info.sort?.type === 'alphabetical':
        setSelectedSort({
          name: props.info.sort?.order === 'asc' ? 'aZ' : 'zA',
          type: props.info.sort.type,
          order: props.info.sort.order,
        });
        break;
    }
  }, [props.info.sort]);

  const inputTypes = useMemo(() => {
    let inpTypes: string[] = [];
    switch (true) {
      case props.info.information === 'responses' && props.info.header === 'responsesTotal':
        inpTypes = ['selection', 'multiple'];
        break;
      case props.info.information === 'responses' &&
        (props.info.measurement === 'average' ||
          props.info.measurement === 'count' ||
          ['responsesByTime', 'responsesByRating'].includes(props.info.header)):
        inpTypes = ['number'];
        break;
      case props.info.information === 'responses' && props.info.measurement === 'last' && !props.info.axis:
        inpTypes = ['number', 'selection'];
        break;
      default:
        inpTypes = ['number'];
    }
    return inpTypes;
  }, [info]);

  const isGroupByLocked = useMemo(() => {
    //info.by && info.filter?.[byFilterMapping[info.by][0]]?.length

    return ['plannedIssuesBySite'].includes(info.header);
  }, [chartType]);

  const buildResponseOptions = (responses: string[]): ISelectListItem[] => {
    return responses?.map((option) => {
      return {
        _id: input?._id + option,
        name: option,
        childrens: [],
        isFinal: true,
        type: 'response',
        isSelected: true,
      };
    });
  };

  useEffect(() => {
    if (info.header === 'responsesByRating') {
      switch (props.info.ranges?.length > 0) {
        case props.info.measurement === 'last':
          setSelectedMeasurement({
            name: 'lastValue',
            type: 'last',
          });
          break;
        case props.info.measurement === 'average':
          setSelectedMeasurement({
            name: 'averageValue',
            type: 'average',
          });
          break;
      }
    }
  }, [props.info.measurement]);

  useEffect(() => {
    if (props.info) {
      if (props.info.name) {
        setName(props.info.name);
      }
      if (props.info.chartType) {
        setChartType(props.info.chartType);
      }

      if (props.info.information) {
        setInformation(props.info.information);
        setTimeUnit(props.info.timeUnit);
      }

      if (props.info.measurement && props.info.measurement !== 'custom') {
        setMeasurement(props.info.measurement);
      }

      switch (props.info.type) {
        case 'responsesAverageValue':
          setMeasurement('average');
          break;
        case 'responsesLowestValue':
          setMeasurement('lowest');
          break;
        case 'responsesHighestValue':
          setMeasurement('highest');
          break;
        case 'responsesSum':
          setMeasurement('sum');
          break;
        default:
          break;
      }

      if (props.info.axis) {
        setXAxis(props.info.axis.x);
        setYAxis(props.info.axis.y);

        if (props.info.axis.x === 'timeSpanRelative') {
          const count = Math.abs(parseInt(props.info.timeSpan.startDate.slice(0, -1)));
          const unit = props.info.timeSpan.startDate.slice(-1);

          setTimespanCount(count);

          switch (unit) {
            case 'h':
              setTimespanUnit('hour');
              break;
            case 'd':
              setTimespanUnit('day');
              break;
            case 'w':
              setTimespanUnit('week');
              break;
            case 'm':
              setTimespanUnit('month');
              break;
            case 'y':
              setTimespanUnit('year');
              break;
          }
        }
      } else if (props.info.by) {
        setBy(props.info.by);
      }

      if (props.info.showAsPercentage) {
        setShowAsPercentage(props.info.showAsPercentage);
      }

      if (props.info.includeArchives) {
        setIncludeArchives(props.info.includeArchives);
      }

      if (props.info.timeSpan?.startDate && props.info.timeSpan?.endDate) {
        setTimeSpan({
          startDate: moment(props.info.timeSpan.startDate).toDate(),
          endDate: moment(props.info.timeSpan.endDate).toDate(),
        });
      }

      if (props.info.range) {
        setRange(props.info.range);
      }

      if (props.info.ranges) {
        setCustomRanges(props.info.ranges);
      }

      if (props.info.filter) {
        if (props.info.filter.states) {
          setStates(props.info.filter.states);
        }
        if (props.info.filter.statuses) {
          setStatuses(props.info.filter.statuses);
        }

        if (props.info.filter.labelValues) {
          //@ts-ignore
          setLabelValues(props.info.filter.labelValues);
        }

        if (props.info.filter.assignedLabelValues) {
          //@ts-ignore
          setAssignedLabelValues(props.info.filter.assignedLabelValues);
        }

        if (props.info.filter.assignedAccounts) {
          //@ts-ignore
          setAssignedAccounts(props.info.filter.assignedAccounts);
        }
        if (props.info.filter.catalogs) {
          //@ts-ignore
          setCatalogs(props.info.filter.catalogs);
        }

        //@ts-ignore
        setElements([...(props.info.filter.sites ?? []), ...(props.info.filter.elements ?? [])]);

        if (props.info.filter.tags) {
          setTags(props.info.filter.tags);
        }

        if (props.info.filter.decimalDigits) {
          setDecimalDigits(props.info.filter.decimalDigits);
        }

        if (props.info.filter.groupBy) {
          setGroupBy(props.info.filter.groupBy);
        }

        if (props.info.filter.issueCatalog) {
          //@ts-ignore
          setIssueCatalog(props.info.filter.issueCatalog);
        }

        if (props.info.filter.input) {
          //@ts-ignore
          setSelectedInput(props.info.filter.input);
        }

        if (props.info.filter.date) {
          //@ts-ignore
          const date = props.info.filter.date;
          if (date.type === 'current' || date.type === 'last') {
            setTimeSpan({ type: date.type, [date.type]: date[date.type] });
          } else {
            setTimeSpan({
              startDate: moment(date?.custom?.from).toDate(),
              endDate: moment(date?.custom?.until).toDate(),
            });
          }
        }
        if (props.info.filter.responseOptions) {
          setResponseOptions(buildResponseOptions(props.info.filter.responseOptions));
        }
      }
    }
  }, []);

  useEffect(() => {
    switch (true) {
      case !xAxis || !yAxis:
        return;
      case xAxis === 'sites' && yAxis === 'issuesCount': {
        setInformation('issues');
        setMeasurement('count');
        setBy('element');
        return;
      }
      case xAxis === 'issues' && yAxis === 'issuesCount': {
        setInformation('issues');
        setMeasurement('count');
        setBy('catalog');
        return;
      }
      case xAxis === 'issues' && yAxis === 'executionAverageTime': {
        setInformation('issues');
        setMeasurement('average');
        setBy('catalog');
        return;
      }
      case xAxis === 'issues' && yAxis === 'timeSpan': {
        setInformation('issues');
        setMeasurement('count');
        setBy('time');
        return;
      }
      case xAxis === 'assignees' && yAxis === 'actionsCount': {
        setInformation('actions');
        setMeasurement('count');
        setBy('assignee');
        return;
      }
      case xAxis === 'assignees' && yAxis === 'issuesCount': {
        setInformation('issues');
        setMeasurement('count');
        setBy('assignee');
        return;
      }
      case xAxis === 'assignees' && yAxis === 'issuesAverage': {
        setInformation('issues');
        setMeasurement('average');
        setBy('assignee');
        return;
      }
      case xAxis === 'assignees' && yAxis === 'timeSpent': {
        setInformation('issues');
        setMeasurement('duration');
        setBy('assignee');
        return;
      }
      case xAxis?.startsWith('timeSpan') && yAxis === 'realtime': {
        setInformation('realtime');
        return;
      }
      case xAxis === 'responses' && yAxis === 'responsesCount': {
        setInformation('responses');
        setMeasurement('count');
        setBy('response');
        return;
      }
    }
  }, [xAxis, yAxis]);

  const disabled = run(() => {
    const validateRanges = (ranges) => {
      let valueToReturn = false;
      ranges.map((range, index) => {
        if (index === 0) {
          return;
        } else {
          let valueToSet = false;
          const val = range?.max;
          for (let ind = 0; ind < index; ind++) {
            const element = ranges[ind];
            if (element?.max > val) valueToSet = true;
          }
          if (valueToSet) valueToReturn = true;
        }
      });
      return valueToReturn;
    };

    if (information === 'responses' && info.header !== 'responsesByRating') {
      if (
        !name?.trim() ||
        !issueCatalog ||
        !input ||
        (props.info.header === 'responsesTotal' && !responseOptions?.filter((o) => o?.isSelected)?.length)
      ) {
        return true;
      } else {
        if (chartType === 'line') {
          if (groupBy && measurement) {
            return false;
          } else {
            return true;
          }
        } else if (chartType === 'gauge') {
          const missingValuesInRanges = customRanges?.some(
            (range) => isNaN(range.max) || !range.max || !range.name.trim() || !range.color.trim(),
          );

          if (missingValuesInRanges || validateRanges(customRanges)) {
            return true;
          }
          return false;
        } else {
          return false;
        }
      }
    }

    if (!name || !information || (!measurement && information !== 'realtime')) {
      return true;
    }

    if (chartType === 'gauge') {
      const missingValuesInRanges = customRanges?.some(
        (range) => isNaN(range.max) || !range.max || !range.name.trim() || !range.color.trim(),
      );

      if (missingValuesInRanges || validateRanges(customRanges)) {
        return true;
      }

      if (information === 'realtime' && isSingleValue && tags.length === 1) return false;
    }

    if (information === 'realtime' && isSingleValue && tags.length != 1)
      if (isAxis) {
        return !xAxis || !yAxis;
      }

    if (isSingleValue) {
      switch (by) {
        case 'state':
          return states.length != 1;
        case 'status':
          return statuses.length != 1;
        case 'element':
          return elements.length != 1;
        case 'assignedLabelValues':
          return assignedLabelValues.length != 1;
        case 'assignee':
          return assignedAccounts.length != 1;
        case 'catalog':
          return catalogs.length != 1;
        case 'labelValues':
          return labelValues.length != 1;
        default:
          return !(chartType !== 'gauge' || info.header === 'responsesByRating');
      }
    }

    if (isAxis) {
      return !xAxis || !yAxis;
    }

    return !by;
  });

  const onSave = async () => {
    setLoading(true);

    try {
      const customSort = { type: selectedSort.type, order: selectedSort.order };
      const dataset = buildWidgetDataset({
        information,
        measurement,
        by,
        chart: chartType,
        range,
        tags,
        elements,
        catalogs,
        assignedAccounts,
        assignedLabelValues,
        labelValues,
        includeArchives,
        showAsPercentage,
        timeSpan,
        timeSpanUnit,
        timeSpanCount,
        timeUnit,
        isSingleValue,
        states,
        statuses,
        xAxis,
        issueCatalog,
        input,
        ranges: customRanges,
        groupByResponses: groupBy,
        sort: customSort,
        decimalDigits,
        customFilters: pieFilterData.every(
          (data) => data.type === null && data.condition === null && data.values === null && data.dateValue === null,
        )
          ? []
          : pieFilterData,
        responseOptions,
      });

      const preset = Object.keys(chartsTable).includes(info.header) ? null : info.header;

      if (props.edit) {
        if (information !== 'realtime') dataset.realtime = null;

        await props.updateWidget({ name, dataset }, info._id).then(async (res) => {
          if (!res.graphQLErrors) {
            props.showSnackbar('success', t('editedWidget'));
            props.goBack(true);
            return;
          }
          props.goBack();
        });
      } else {
        await props
          .createWidget({
            name,
            draft: draftId,
            preset,
            layout: { x: 0, y: 0 },
            dataset,
          })
          .then(async (res) => {
            if (!res.graphQLErrors) {
              props.showSnackbar('success', t('addedWidget'));
              props.goBack(true);
              return;
            }
            props.goBack();
          });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={classes.topBar}>
        {!props.edit && (
          <IconButton style={{ padding: 0 }} onClick={() => props.goBack()} size='large'>
            <KeyboardArrowLeft />
          </IconButton>
        )}
        {iconsByName[chartType]}
        <div className={classes.widgetHeaderBox}>
          <span className={classes.widgetHeader}>{t(`chartCards.${props.info.header ?? chartType}.header`)}</span>
          <span className={classes.widgetDescription}>
            {t(`chartCards.${props.info.header ?? chartType}.description`)}
          </span>
        </div>
      </div>
      <div className={`${classes.widgetEditContainer}`}>
        <div>
          <LabelInput required>{t('chartName')}</LabelInput>
          <InputGeneric
            editable={true}
            placeholder={t('addName')}
            data-testid={'input-widget-name'}
            type={'string'}
            onlyText
            onlyInput
            values={[name]}
            handleChange={(v) => setName(v[0])}
          />
        </div>
        {(!info.information || !isPreset) && !isAxis ? (
          <div>
            <LabelInput required>{t('information')}</LabelInput>
            <Select
              data-testid={'input-widget-information'}
              value={information}
              endAdornment={<ExpandMore className={classes.adornment} />}
              classes={{ select: classes.removeStroke }}
              displayEmpty
              MenuProps={{
                ...menuProps,
                /* getContentAnchorEl: null, */
              }}
              onChange={(e) => {
                const newInformation = e.target.value as any;
                setInformation(newInformation);

                if (!measurements[newInformation].includes(measurement)) {
                  setMeasurement(null);
                }

                if (newInformation !== information) {
                  setBy(null);
                  setStates([]);
                  setStatuses([]);
                  setCatalogs([]);
                  setLabelValues([]);
                  setAssignedAccounts([]);
                  setAssignedLabelValues([]);
                  setTags([]);
                }
              }}
              renderValue={(selected) => {
                if (!selected) {
                  return <span className={classes.placehoderSelectInformation}>{t('select')}</span>;
                }

                return t(selected);
              }}
              input={
                <InputBase classes={{ root: classes.selectWidgetInfo, input: classes.selectInputInput }}></InputBase>
              }
            >
              {informations
                //no group by in tags
                .filter((x) => (!isAxis && !isSingleValue ? x != 'realtime' : true))
                .map((opt) => (
                  <MenuItem data-testid={'select-information-' + opt} key={opt} value={opt}>
                    {t(opt)}
                  </MenuItem>
                ))}
            </Select>
          </div>
        ) : null}
        {((!info.measurement && yAxis !== 'timeSpent') || !info.header) &&
        information &&
        information !== 'realtime' &&
        information !== 'responses' &&
        !isAxis ? (
          <div>
            <LabelInput required>{t('measurement')}</LabelInput>
            <Select
              data-testid={'input-widget-measurement'}
              value={measurement}
              key={information}
              endAdornment={<ExpandMore className={classes.adornment} />}
              classes={{ select: classes.removeStroke }}
              MenuProps={{
                ...menuProps,
                /* getContentAnchorEl: null, */
              }}
              displayEmpty
              renderValue={(selected) => {
                if (!selected) {
                  return <span className={classes.placehoderSelectInformation}>{t('select')}</span>;
                }

                return t(selected);
              }}
              onChange={(e) => {
                const newMeasurement = e.target.value as any;

                setMeasurement(newMeasurement);
              }}
              input={<InputBase classes={{ root: classes.selectWidgetInfo, input: classes.selectInputInput }} />}
            >
              {measurements[information].map((opt) => (
                <MenuItem data-testid={'select-measurement-' + opt} key={opt} value={opt}>
                  {t(opt)}
                </MenuItem>
              ))}
            </Select>
          </div>
        ) : null}
        {by !== 'element' ? (
          <div>
            <LabelInput>{t('site/Element')}</LabelInput>
            <BetterFieldPopover
              classes={{ root: classes.noMargin, placeholder: classes.placeholder }}
              data-testid={'edit-widget-site-element-filter'}
              context={'siteElement'}
              placeholder={t('all')}
              value={elements}
              multiple
              onChange={setElements}
            />
          </div>
        ) : null}
        {information === 'responses' && info.header === 'responsesTotalValues' && (
          <div>
            <InputLabel className={classes.inputLabel}>{t('issue')}</InputLabel>
            <BetterFieldPopover
              classes={{ root: classes.noMargin, placeholder: classes.placeholder }}
              placeholder={t('select')}
              data-testid={'edit-widget-single-issue-catalog-filter'}
              context={['issueCatalogOpen', 'issueCatalogScheduled']}
              multiple={false}
              value={issueCatalog}
              onChange={(issueCatalog) => {
                setIssueCatalog(issueCatalog);
              }}
            />
          </div>
        )}
        {information === 'responses' && info.header === 'responsesTotal' && chartType === 'bar' ? (
          <div className={classes.multipleSelectRowContainer}>
            <div className={classes.multipleSelectBox}>
              <LabelInput required>{t('issue')}</LabelInput>
              <BetterFieldPopover
                classes={{ root: classes.noMargin, placeholder: classes.placeholder }}
                placeholder={t('select')}
                data-testid={'edit-widget-single-issue-catalog-filter'}
                context={['issueCatalogOpen', 'issueCatalogScheduled']}
                multiple={false}
                value={issueCatalog}
                onChange={(issueCatalog) => {
                  setIssueCatalog(issueCatalog);
                }}
              />
            </div>

            <div className={classes.multipleSelectBox}>
              <LabelInput required>{t('question')}</LabelInput>
              <ExecutionInputSelect
                data-testid='input-question'
                id='input-question'
                disabled={!issueCatalog}
                issueCatalogId={issueCatalog?._id}
                placeholder={t('select')}
                onChange={(item) => setInput(item)}
                defaultValue={selectedInput}
                inputTypes={inputTypes}
              />
            </div>
          </div>
        ) : null}
        {isSingleValue && information === 'realtime' ? (
          <div>
            <LabelInput required>{t('tag')}</LabelInput>
            <BetterFieldPopover
              classes={{ root: classes.noMargin, placeholder: classes.placeholder }}
              placeholder={t('select')}
              data-testid={'edit-widget-single-tag-filter'}
              context={'tag'}
              value={tags[0]}
              onChange={(tag) => setTags(tag ? [tag] : [])}
            />
          </div>
        ) : null}
        {information && information !== 'realtime' && information !== 'responses' && !isAxis && !isGroupByLocked && (
          <div className={classes.horizontalBox}>
            {!(props.info.by && isPreset) && (
              <div className={classes.inputBox} style={{ minWidth: '50%' }}>
                <LabelInput required>{t('groupBy')}</LabelInput>
                <Select
                  data-testid={'input-widget-groupby'}
                  value={by}
                  endAdornment={<ExpandMore className={classes.adornment} />}
                  classes={{ select: classes.removeStroke }}
                  MenuProps={{
                    ...menuProps,
                    /* getContentAnchorEl: null, */
                  }}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <span className={classes.placehoderSelectInformation}>{t('select')}</span>;
                    }
                    const sel = t(selected);
                    return sel;
                  }}
                  onChange={(e): void => {
                    setBy(e.target.value as any);

                    setCatalogs([]);
                    setAssignedAccounts([]);
                    setAssignedLabelValues([]);
                    setStates([]);
                    setStatuses([]);
                  }}
                  input={<InputBase classes={{ root: classes.selectWidgetInfo, input: classes.selectInputInput }} />}
                >
                  {groupings[information]?.map((opt) => (
                    <MenuItem data-testid={'select-groupby-' + opt} key={opt} value={opt}>
                      {t(`editWidgetBy.${opt}`)}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )}
            {by && (
              <div className={classes.inputBox}>
                {by === 'state' && info.header !== 'issuesFinished' ? (
                  <>
                    <LabelInput required={isSingleValue}>{t(isSingleValue ? 'State' : 'States')}</LabelInput>
                    <TemporaryPopover
                      data-testid='select-states'
                      multiple={!isSingleValue}
                      options={listStates.map((s) => ({ id: s.value, label: t(s.label) }))}
                      values={states}
                      setValues={(val) => setStates(val as any)}
                      placeholder={t('select')}
                    />
                  </>
                ) : null}
                {by === 'state' && info.header === 'issuesFinished' ? (
                  <>
                    <LabelInput required={isSingleValue}>{t(`editWidgetBy.catalog`)}</LabelInput>
                    <BetterFieldPopover
                      placeholder={t('all')}
                      classes={{ root: classes.noMargin, placeholder: classes.placeholder }}
                      context={['issueCatalogOpen', 'issueCatalogScheduled']}
                      data-testid={'edit-widget-catalog-by'}
                      multiple
                      value={catalogs}
                      onChange={setCatalogs}
                    />
                  </>
                ) : null}
                {by === 'status' ? (
                  <>
                    <LabelInput required={isSingleValue}>{t(isSingleValue ? 'Status' : 'Statuses')}</LabelInput>
                    <TemporaryPopover
                      data-testid='select-statuses'
                      multiple={!isSingleValue}
                      options={listStatus.map((s) => ({ id: s.value, label: t(s.label) }))}
                      values={statuses}
                      setValues={(val) => setStatuses(val as any)}
                      placeholder={t('select')}
                    />
                  </>
                ) : null}
                {by === 'element' ? (
                  <>
                    <LabelInput required={isSingleValue}>{t(`editWidgetBy.element`)}</LabelInput>
                    {isSingleValue ? (
                      <BetterFieldPopover
                        classes={{ root: classes.noMargin, placeholder: classes.placeholder }}
                        context={'siteElement'}
                        data-testid={'edit-widget-element-by'}
                        value={elements[0]}
                        onChange={(el) => setElements(el ? [el] : [])}
                        placeholder={t('select')}
                      />
                    ) : (
                      <BetterFieldPopover
                        placeholder={t('all')}
                        classes={{ root: classes.noMargin, placeholder: classes.placeholder }}
                        context={'siteElement'}
                        data-testid={'edit-widget-element-by'}
                        multiple
                        value={elements}
                        onChange={setElements}
                      />
                    )}
                  </>
                ) : null}
                {by === 'catalog' ? (
                  <>
                    <LabelInput required={isSingleValue}>{t(`editWidgetBy.catalog`)}</LabelInput>
                    {isSingleValue ? (
                      <BetterFieldPopover
                        placeholder={t('select')}
                        classes={{ root: classes.noMargin, placeholder: classes.placeholder }}
                        context={['issueCatalogOpen', 'issueCatalogScheduled']}
                        data-testid={'edit-widget-catalog-by'}
                        value={catalogs[0]}
                        onChange={(c) => setCatalogs(c ? [c] : [])}
                      />
                    ) : (
                      <BetterFieldPopover
                        placeholder={t('all')}
                        classes={{ root: classes.noMargin, placeholder: classes.placeholder }}
                        context={['issueCatalogOpen', 'issueCatalogScheduled']}
                        data-testid={'edit-widget-catalog-by'}
                        multiple
                        value={catalogs}
                        onChange={setCatalogs}
                      />
                    )}
                  </>
                ) : null}
                {by === 'assignee' ? (
                  <>
                    <LabelInput required={isSingleValue}>{t(`editWidgetBy.assignee`)}</LabelInput>
                    {isSingleValue ? (
                      <BetterFieldPopover
                        classes={{ root: classes.noMargin, placeholder: classes.placeholder }}
                        context={'assignee'}
                        data-testid={'edit-widget-assignee-by'}
                        value={assignedAccounts[0]}
                        onChange={(acc) => setAssignedAccounts(acc ? [acc] : [])}
                        placeholder={t('select')}
                      />
                    ) : (
                      <BetterFieldPopover
                        placeholder={t('all')}
                        classes={{ root: classes.noMargin, placeholder: classes.placeholder }}
                        context={'assignee'}
                        data-testid={'edit-widget-assignee-by'}
                        multiple
                        value={assignedAccounts}
                        onChange={setAssignedAccounts}
                      />
                    )}
                  </>
                ) : null}
                {by === 'assignedLabelValues' ? (
                  <>
                    <LabelInput required={isSingleValue}>{t(`editWidgetBy.assignedLabelValues`)}</LabelInput>
                    {isSingleValue ? (
                      <BetterFieldPopover
                        classes={{ root: classes.noMargin, placeholder: classes.placeholder }}
                        context={'labelAccount'}
                        data-testid={'edit-widget-label-account-by'}
                        value={assignedLabelValues[0]}
                        onChange={(l) => setAssignedLabelValues(l ? [l] : [])}
                        placeholder={t('select')}
                      />
                    ) : (
                      <BetterFieldPopover
                        placeholder={t('all')}
                        classes={{ root: classes.noMargin, placeholder: classes.placeholder }}
                        context={'labelAccount'}
                        data-testid={'edit-widget-label-account-by'}
                        multiple
                        value={assignedLabelValues}
                        onChange={setAssignedLabelValues}
                      />
                    )}
                  </>
                ) : null}
                {by === 'labelValues' ? (
                  <>
                    <LabelInput required={isSingleValue}>{t(`editWidgetBy.labelValues`)}</LabelInput>
                    {isSingleValue ? (
                      <BetterFieldPopover
                        classes={{ root: classes.noMargin, placeholder: classes.placeholder }}
                        key={information}
                        context={
                          information === 'issues'
                            ? 'labelIssue'
                            : information === 'actions'
                              ? 'labelAction'
                              : undefined
                        }
                        data-testid={'edit-widget-label-issue-by'}
                        value={labelValues[0]}
                        onChange={(l) => setLabelValues(l ? [l] : [])}
                        placeholder={t('select')}
                      />
                    ) : (
                      <BetterFieldPopover
                        placeholder={t('all')}
                        classes={{ root: classes.noMargin, placeholder: classes.placeholder }}
                        key={information}
                        context={
                          information === 'issues'
                            ? 'labelIssue'
                            : information === 'actions'
                              ? 'labelAction'
                              : undefined
                        }
                        multiple
                        data-testid={'edit-widget-label-issue-by'}
                        value={labelValues}
                        onChange={setLabelValues}
                      />
                    )}
                  </>
                ) : null}
              </div>
            )}
          </div>
        )}
        {information === 'responses' && chartType === 'line' && (
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <InputLabel className={classes.inputLabel}>{t('issue')}</InputLabel>
              <BetterFieldPopover
                classes={{ root: classes.noMargin, placeholder: classes.placeholder }}
                placeholder={t('select')}
                data-testid={'edit-widget-single-issue-catalog-filter'}
                context={['issueCatalogOpen', 'issueCatalogScheduled']}
                multiple={false}
                value={issueCatalog}
                onChange={(issueCatalog) => {
                  setIssueCatalog(issueCatalog);
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <InputLabel className={classes.inputLabel}>{t('question')}</InputLabel>
              <ExecutionInputSelect
                data-testid='input-question'
                disabled={!issueCatalog}
                issueCatalogId={issueCatalog?._id}
                placeholder={t('select')}
                onChange={(item) => setInput(item)}
                defaultValue={selectedInput}
                inputTypes={inputTypes}
              />
            </Grid>
            <Grid item xs={2}>
              <InputLabel className={classes.inputLabel}>
                <span className={classes.decimalDigitsInfoSpan}>
                  {t('decimalDigits')}
                  <InfoOutlined
                    onClick={(e) => setOpenInfoPopover(e.currentTarget)}
                    className={classes.decimalDigitsInfoIcon}
                  />
                  <Popover
                    open={!!openInfoPopover}
                    anchorEl={openInfoPopover}
                    onClose={() => setOpenInfoPopover(null)}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    classes={{ paper: classes.infoPopoverContainer }}
                  >
                    <div className={classes.infoDivStyle}>
                      <Typography className={classes.infoText}>{t('defineDecimalDigits')}</Typography>
                      <Close onClick={() => setOpenInfoPopover(null)} classes={{ root: classes.infoCloseIcon }} />
                    </div>
                  </Popover>
                </span>
              </InputLabel>
              <CustomNumberInput
                data-testid='input-decimal-digits'
                id='input-decimal-digits'
                maxNumber={3}
                minNumber={0}
                variant='outlined'
                placeholder='0'
                value={decimalDigits}
                onChangeFunction={(e) => setDecimalDigits(parseInt(e.target.value))}
                onClickToIncrease={() => {
                  if (decimalDigits < 3) setDecimalDigits(decimalDigits + 1);
                }}
                onClickToDecrease={() => {
                  if (decimalDigits > 0) setDecimalDigits(decimalDigits - 1);
                }}
                style={{ background: theme.palette.background.default }}
              />
            </Grid>
          </Grid>
        )}
        {isAxis && (
          <>
            <div className={classNames(classes.horizontalBox, classes.axisBackground)}>
              <div className={classes.inputBox} style={{ minWidth: '50%' }}>
                <InputLabel required className={classNames(classes.inputLabel, classes.bold)}>
                  {t('xAxis')}
                </InputLabel>
                {!(information === 'responses') ? (
                  <Select
                    data-testid={'select-xaxis'}
                    value={xAxis}
                    endAdornment={<ExpandMore className={classes.adornment} />}
                    disabled={!!props.info.axis?.x && isPreset && info.header !== 'tagByTime'}
                    classes={{ select: classes.removeStroke }}
                    MenuProps={menuProps}
                    onChange={(e) => {
                      setXAxis(e.target.value as any);
                    }}
                    input={
                      <InputBase
                        classes={{
                          root: classes.selectWidgetInfo,
                          input: classNames(classes.selectInputInput, classes.borderDisabled),
                        }}
                      />
                    }
                  >
                    {(info.header === 'tagByTime' ? ['timeSpanAbsolute', 'timeSpanRelative'] : Object.keys(axis)).map(
                      (opt) => (
                        <MenuItem data-testid={'select-xaxis-' + opt} key={opt} value={opt}>
                          {t(opt)}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                ) : information === 'responses' && chartType === 'line' ? (
                  <div className={classes.responseByTimeLine}>
                    <div className={classes.responseByTimeLineTimeRange}>
                      <InputLabel className={classNames(classes.inputLabel, classes.bold)}>{t('timeRange')}</InputLabel>
                      {/* <CustomDateRangePicker
                        data-testid={'timespan-date-range-picker'}
                        defaultDateRange={timeSpan}
                        onApply={setTimeSpan}
                      /> */}
                      <CustomDateDropdown
                        data-testid={'timespan-date-range-picker'}
                        timeSpan={timeSpan}
                        setTimeSpan={setTimeSpan}
                        withFullCalendar={true}
                      />
                    </div>
                    <div className={classes.responseByTimeLineGroupBy}>
                      <InputLabel className={classNames(classes.inputLabel, classes.bold)}>{t('groupBy')}</InputLabel>
                      <Select
                        data-testid={'select-xaxis'}
                        value={groupBy}
                        classes={{ select: classes.removeStroke }}
                        MenuProps={menuProps}
                        onChange={(e) => {
                          setGroupBy(e.target.value as any);
                        }}
                        input={
                          <InputBase
                            classes={{
                              root: classes.selectWidgetInfo,
                              input: classNames(classes.selectInputInput, classes.borderDisabled),
                            }}
                          />
                        }
                      >
                        {listGroupBy.map((opt) => (
                          <MenuItem data-testid={'select-xaxis-' + opt} key={opt} value={opt}>
                            {t(opt)}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                ) : info.header !== 'responsesTotal' ? (
                  <div style={{ display: 'flex', alignItems: 'center', width: '80%', gap: '24px' }}>
                    <div style={{ flexGrow: 1 }}>
                      <InputLabel className={classes.inputLabel}>{t('question')}</InputLabel>
                      <ExecutionInputSelect
                        data-testid='input-question'
                        disabled={!issueCatalog}
                        issueCatalogId={issueCatalog?._id}
                        placeholder={t('select')}
                        onChange={(item) => setInput(item)}
                        defaultValue={selectedInput}
                        inputTypes={inputTypes}
                      />
                    </div>
                    <div style={{ width: '20%' }}>
                      <div className={classes.decimalDigitsInfoSpan}>
                        <InputLabel className={classes.inputLabel}>{t('decimalDigits')}</InputLabel>
                        <InfoOutlined
                          className={classes.decimalDigitsInfoIcon}
                          onClick={(e) => setOpenInfoPopover(e.currentTarget)}
                        />
                        <Popover
                          open={!!openInfoPopover}
                          anchorEl={openInfoPopover}
                          onClose={() => setOpenInfoPopover(null)}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          classes={{ paper: classes.infoPopoverContainer }}
                        >
                          <div className={classes.infoDivStyle}>
                            <Typography className={classes.infoText}>{t('defineDecimalDigits')}</Typography>
                            <Close onClick={() => setOpenInfoPopover(null)} classes={{ root: classes.infoCloseIcon }} />
                          </div>
                        </Popover>
                      </div>
                      <CustomNumberInput
                        data-testid='input-define-decimal-digits'
                        id='input-define-decimal-digits'
                        maxNumber={3}
                        minNumber={0}
                        variant='outlined'
                        placeholder='0'
                        value={decimalDigits}
                        onChangeFunction={(e) => setDecimalDigits(parseInt(e.target.value))}
                        onClickToIncrease={() => {
                          if (decimalDigits < 3) setDecimalDigits(decimalDigits + 1);
                        }}
                        onClickToDecrease={() => {
                          if (decimalDigits > 0) setDecimalDigits(decimalDigits - 1);
                        }}
                        style={{ background: theme.palette.background.default }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={classes.multipleSelectBox}>
                    <LabelInput required> {t('responses')}</LabelInput>
                    <ExecutionInputSelect
                      data-testid='input-responses'
                      disabled={!issueCatalog || !input}
                      issueCatalogId={issueCatalog?._id}
                      placeholder={t('all')}
                      responseOptions={responseOptions}
                      setResponseOptions={setResponseOptions}
                      inputTypes={['response']}
                      selectedInputForResponse={input}
                      edit={props.edit}
                    />
                  </div>
                )}
              </div>
              {!!xAxis && information !== 'responses' && (
                <div className={classes.inputBox}>
                  <LabelInput required={xAxis.startsWith('timeSpan')}>{t(xAxis)}</LabelInput>
                  {xAxis === 'issues' ? (
                    <BetterFieldPopover
                      placeholder={t('all')}
                      classes={{
                        root: classes.noMargin,
                        placeholder: classes.placeholder,
                      }}
                      data-testid={'xaxis-issues'}
                      multiple
                      context={['issueCatalogOpen', 'issueCatalogScheduled']}
                      value={catalogs}
                      onChange={setCatalogs}
                    />
                  ) : xAxis === 'assignees' ? (
                    <BetterFieldPopover
                      placeholder={t('all')}
                      classes={{
                        root: classes.noMargin,
                        placeholder: classes.placeholder,
                      }}
                      data-testid={'xaxis-assignees'}
                      multiple
                      context={'assignee'}
                      value={assignedAccounts}
                      onChange={setAssignedAccounts}
                    />
                  ) : xAxis === 'sites' ? (
                    <BetterFieldPopover
                      placeholder={t('all')}
                      classes={{
                        root: classes.noMargin,
                        placeholder: classes.placeholder,
                      }}
                      data-testid={'xaxis-sites'}
                      multiple
                      context={'siteElement'}
                      value={elements}
                      onChange={setElements}
                    />
                  ) : xAxis === 'timeSpanAbsolute' ? (
                    <CustomDateDropdown
                      data-testid={'timespan-date-range-picker'}
                      timeSpan={timeSpan}
                      setTimeSpan={setTimeSpan}
                      withFullCalendar={true}
                    />
                  ) : xAxis === 'timeSpanRelative' ? (
                    <div className={classes.horizontalBox}>
                      <InputGeneric
                        data-testid='input-relative-date-count'
                        type={'number'}
                        onlyInput
                        values={timeSpanCount ? [timeSpanCount] : []}
                        handleChange={(e) => setTimespanCount(e[0])}
                      />
                      <Select
                        data-testid={'input-relative-date-unit'}
                        className={classes.halfWidth}
                        endAdornment={<ExpandMore className={classes.adornment} />}
                        value={timeSpanUnit}
                        classes={{ select: classes.removeStroke }}
                        MenuProps={menuProps}
                        onChange={(e) => {
                          const val = e.target.value;

                          if (val && val !== 'none') {
                            setTimespanUnit(val as any);
                          } else {
                            setTimespanUnit(null);
                          }
                        }}
                        input={
                          <InputBase
                            classes={{
                              root: classes.selectWidgetInfo,
                              input: classNames(classes.selectInputInput, classes.borderDisabled),
                            }}
                          />
                        }
                      >
                        {timespanUnits.map((unit) => (
                          <MenuItem data-testid={'input-relative-date-unit-' + unit} key={unit} value={unit}>
                            {t(unit === 'none' ? unit : unit + 's')}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
            <div className={classNames(classes.horizontalBox, classes.axisBackground)}>
              <div
                className={classes.inputBox}
                style={{ minWidth: '50%', maxWidth: info.header === 'responsesTotal' ? '50%' : 'inherit' }}
              >
                <InputLabel className={classNames(classes.inputLabel, classes.bold)}>{t('yAxis')}</InputLabel>
                {!(information === 'responses' && chartType === 'line') ? (
                  <Select
                    data-testid={'select-yaxis'}
                    value={yAxis}
                    endAdornment={<ExpandMore className={classes.adornment} />}
                    disabled={!xAxis || (!!props.info.axis?.y && !!info.header)}
                    classes={{ select: classes.removeStroke }}
                    MenuProps={menuProps}
                    onChange={(e) => {
                      setYAxis(e.target.value as any);

                      setCatalogs([]);
                      setTags([]);
                      setTimeSpan(null);
                      setAssignedAccounts([]);
                      setAssignedLabelValues([]);
                    }}
                    input={
                      <InputBase
                        classes={{
                          root: classes.selectWidgetInfo,
                          input: classNames(classes.selectInputInput, classes.borderDisabled),
                        }}
                      />
                    }
                  >
                    {axis[xAxis]?.map((opt) => (
                      <MenuItem key={opt} data-testid={'select-yaxis-' + opt} value={opt}>
                        {t(opt)}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <div style={{ width: '50%' }}>
                    <InputLabel className={classNames(classes.inputLabel, classes.bold)}>{t('value')}</InputLabel>
                    <Select
                      data-testid={'select-yaxis'}
                      value={measurement}
                      classes={{ select: classes.removeStroke }}
                      MenuProps={menuProps}
                      onChange={(e) => {
                        setMeasurement(e.target.value as any);
                      }}
                      input={
                        <InputBase
                          classes={{
                            root: classes.selectWidgetInfo,
                            input: classNames(classes.selectInputInput, classes.borderDisabled),
                          }}
                        />
                      }
                    >
                      {yAxisValues.map((opt) => (
                        <MenuItem data-testid={'select-yaxis-' + opt} key={opt} value={opt}>
                          {t(opt)}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )}
              </div>
              {(['executionAverageTime', 'timeSpan', 'issuesAverage', 'realtime'].includes(yAxis) ||
                (yAxis === 'issuesCount' && xAxis != 'issues')) && (
                <div className={classes.inputBox}>
                  <LabelInput required={['realtime', 'timeSpan', 'issuesAverage'].includes(yAxis)}>
                    {t(yAxis)}
                  </LabelInput>
                  {yAxis === 'executionAverageTime' ? (
                    <StaticFieldPopover
                      options={unitsOptions}
                      selected={timeUnit}
                      onSelect={(e) => {
                        setTimeUnit(e.id as any);
                      }}
                      data-testid='select-execution-average-time'
                    />
                  ) : yAxis === 'timeSpan' || yAxis === 'issuesAverage' ? (
                    <div>
                      <CustomDateDropdown
                        data-testid={'timespan-date-range-picker'}
                        timeSpan={timeSpan}
                        setTimeSpan={setTimeSpan}
                        withFullCalendar={true}
                      />
                    </div>
                  ) : yAxis === 'realtime' ? (
                    <BetterFieldPopover
                      placeholder={t('select')}
                      classes={{
                        root: classes.noMargin,
                        placeholder: classes.placeholder,
                      }}
                      data-testid={'tag-selection-yaxis'}
                      context={'tag'}
                      multiple
                      value={tags}
                      onChange={setTags}
                    />
                  ) : yAxis === 'issuesCount' ? (
                    <BetterFieldPopover
                      placeholder={t('all')}
                      classes={{
                        root: classes.noMargin,
                        placeholder: classes.placeholder,
                      }}
                      data-testid={'issues-selection-yaxis'}
                      context={['issueCatalogOpen', 'issueCatalogScheduled']}
                      multiple
                      value={catalogs}
                      onChange={setCatalogs}
                    />
                  ) : null}
                </div>
              )}
            </div>
          </>
        )}
        {information === 'responses' && info.header !== 'responsesTotalValues' && chartType !== 'line' ? (
          <>
            {info.header !== 'responsesTotal' || (info.header === 'responsesTotal' && chartType !== 'bar') ? (
              <div className={classes.multipleSelectRowContainer}>
                <div
                  className={
                    props.info.header === 'responsesTotal'
                      ? classes.multipleSelectBoxMaxWidth
                      : classes.multipleSelectBox
                  }
                >
                  <LabelInput required>{t('issue')}</LabelInput>
                  <BetterFieldPopover
                    classes={{ root: classes.noMargin, placeholder: classes.placeholder }}
                    placeholder={t('select')}
                    data-testid={'edit-widget-single-issue-catalog-filter'}
                    context={['issueCatalogOpen', 'issueCatalogScheduled']}
                    multiple={false}
                    value={issueCatalog}
                    onChange={(issueCatalog) => {
                      setIssueCatalog(issueCatalog);
                    }}
                  />
                </div>
                {info.header !== 'responsesTotal' ? (
                  <div className={classes.multipleSelectBox}>
                    <LabelInput required>{t('question')}</LabelInput>
                    <ExecutionInputSelect
                      data-testid='input-question'
                      disabled={!issueCatalog}
                      issueCatalogId={issueCatalog?._id}
                      placeholder={t('select')}
                      onChange={(item) => setInput(item)}
                      defaultValue={selectedInput}
                      inputTypes={inputTypes}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            {info.header === 'responsesTotal' && chartType !== 'bar' ? (
              <div className={classes.multipleSelectRowContainer}>
                <div className={classes.multipleSelectBox}>
                  <LabelInput required>{t('question')}</LabelInput>
                  <ExecutionInputSelect
                    data-testid='input-question'
                    disabled={!issueCatalog}
                    issueCatalogId={issueCatalog?._id}
                    placeholder={t('select')}
                    onChange={(item) => setInput(item)}
                    defaultValue={selectedInput}
                    inputTypes={inputTypes}
                  />
                </div>
                <div className={classes.multipleSelectBox}>
                  <LabelInput required> {t('responses')}</LabelInput>
                  <ExecutionInputSelect
                    data-testid='input-responses'
                    disabled={!issueCatalog || !input}
                    issueCatalogId={issueCatalog?._id}
                    placeholder={t('all')}
                    responseOptions={responseOptions}
                    setResponseOptions={setResponseOptions}
                    inputTypes={['response']}
                    selectedInputForResponse={input}
                    edit={props.edit}
                  />
                </div>
              </div>
            ) : null}
          </>
        ) : null}
        {(chartType === 'gauge' || chartType === 'value') && (
          <>
            {information !== 'realtime' && information !== 'issues' && information !== 'actions' && (
              <div className={classes.multipleSelectRowContainer}>
                <div className={classes.multipleSelectBox}>
                  <InputLabel className={classes.inputLabel}>{t('measurement')}</InputLabel>
                  <CustomSelectMeasurement
                    selectedMeasurement={selectedMeasurement}
                    setSelectedMeasurement={setSelectedMeasurement}
                    setMeasurement={setMeasurement}
                  />
                </div>
                <div className={classes.multipleSelectBox}>
                  <InputLabel className={classes.inputLabel}>{t('timeRange')}</InputLabel>
                  <div style={{ marginBottom: '16px' }}>
                    <CustomDateDropdown timeSpan={timeSpan} setTimeSpan={setTimeSpan} withFullCalendar={true} />
                  </div>
                </div>
              </div>
            )}
            {chartType === 'gauge' && (
              <>
                <Divider />
                <CustomValueRangePicker
                  setErrorExists={setErrorExists}
                  customRanges={customRanges}
                  setCustomRanges={setCustomRanges}
                />
              </>
            )}
          </>
        )}
        {chartType === 'table' && info.header === 'responsesTotalValues' && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '16px' }}>
            <div style={{ flexGrow: 1, maxWidth: '100%' }} className={classes.multipleSelectBox}>
              <InputLabel required className={classes.inputLabel}>
                {t('question')}
              </InputLabel>
              <ExecutionInputSelect
                data-testid='input-question'
                disabled={!issueCatalog}
                issueCatalogId={issueCatalog?._id}
                placeholder={t('select')}
                onChange={(item) => setInput(item)}
                defaultValue={selectedInput}
                inputTypes={inputTypes}
              />
            </div>
            <div style={{ width: '16%' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <InputLabel className={classes.inputLabel}>{t('decimalDigits')}</InputLabel>
                <InfoOutlined
                  style={{ cursor: 'pointer', marginBottom: '6px' }}
                  onClick={(e) => setOpenInfoPopover(e.currentTarget)}
                />
                <Popover
                  open={!!openInfoPopover}
                  anchorEl={openInfoPopover}
                  onClose={() => setOpenInfoPopover(null)}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  classes={{ paper: classes.infoPopoverContainer }}
                >
                  <div className={classes.infoDivStyle}>
                    <Typography className={classes.infoText}>{t('defineDecimalDigits')}</Typography>
                    <Close onClick={() => setOpenInfoPopover(null)} classes={{ root: classes.infoCloseIcon }} />
                  </div>
                </Popover>
              </div>
              <CustomNumberInput
                data-testid='input-decimal-digits-other'
                id='input-decimal-digits-other'
                maxNumber={3}
                minNumber={0}
                variant='outlined'
                placeholder='0'
                value={decimalDigits}
                onChangeFunction={(e) => setDecimalDigits(parseInt(e.target.value))}
                onClickToIncrease={() => {
                  if (decimalDigits < 3) setDecimalDigits(decimalDigits + 1);
                }}
                onClickToDecrease={() => {
                  if (decimalDigits > 0) setDecimalDigits(decimalDigits - 1);
                }}
                style={{ background: theme.palette.background.default }}
              />
            </div>
          </div>
        )}
        {info.header === 'responsesTotalValues' || (info.header === 'responsesTotal' && chartType !== 'pie') ? (
          <div>
            <InputLabel className={classes.inputLabel}>{t('sortBy')}</InputLabel>
            <CustomSortBy
              selectedSort={selectedSort}
              setSelectedSort={setSelectedSort}
              excludePresets={excludeSortPresets}
            />
          </div>
        ) : null}
        {((measurement === 'average' && info.header !== 'responsesByRating' && info.header !== 'responsesByTime') ||
          info.header === 'responsesTotalValues' ||
          info.header === 'responsesTotal') &&
        chartType !== 'gauge' ? (
          <div>
            <InputLabel className={classes.inputLabel}>{t('timeRange')}</InputLabel>
            <div style={{ marginBottom: '16px' }}>
              <CustomDateDropdown timeSpan={timeSpan} setTimeSpan={setTimeSpan} withFullCalendar={true} />
            </div>
          </div>
        ) : null}
        {(information === 'issues' || !isSingleValue) &&
          info.header !== 'responsesTotalValues' &&
          chartType !== 'line' && (
            <div className={classes.showPercentage}>
              {information === 'issues' && (
                <div>
                  <Switch
                    data-testid={'switch-archives'}
                    checked={includeArchives}
                    color={'primary'}
                    onClick={() => setIncludeArchives(!includeArchives)}
                  />
                  <span>{t('includeArchives')}</span>
                </div>
              )}
              {!isSingleValue && (
                <div>
                  <Switch
                    data-testid={'switch-showpercentage'}
                    checked={showAsPercentage}
                    color={'primary'}
                    onClick={() => setShowAsPercentage(!showAsPercentage)}
                  />
                  <span>{t('showAsPercentage')}</span>
                </div>
              )}
            </div>
          )}
        {['issues', 'actions', 'responses'].includes(information) && (
          <PieChartFilter filterData={pieFilterData} setFilterData={setPieFilterData} context={information} />
        )}
      </div>
      <div className={classes.editWidgetButtons}>
        <DefaultButton
          data-testid={'cancel-widget'}
          variant='discard'
          label={t('cancel')}
          onClick={() => props.goBack()}
        />
        <DefaultButton
          data-testid={'save-widget'}
          loader={loading}
          disabled={disabled}
          label={t('save')}
          onClick={onSave}
        />
      </div>
    </>
  );
};

type MapStateToProps = {
  dashboardReducer: DashboardTreeState;
};

const connector = connect(
  (state: MapStateToProps) => ({
    draftId: state.dashboardReducer.currentDashboardDraft._id,
  }),
  {
    createWidget,
    updateWidget,
    showSnackbar,
  },
);

export default connector(withTranslation('translation')(withStyles(styles)(EditWidget)));
