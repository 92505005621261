import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AgoraIOUsers } from 'src/interfaces/remote-assistance/agoraIO';
import { User } from 'src/interfaces/remote-assistance/types';
import {
  AgoraStateProps,
  AnnotationType,
  FreezeEvents,
  ImageFreeze,
  LastEventAnnotation,
  ScreenShareDialogType,
  ScreenShareEnum,
  ScreenShareType,
  StartedByProps,
  TextBubbleUpdate,
  WebMobileAnnotation,
  WebMobileAnnotations,
} from './utils/agora.interfaces';
import { handleMouseDown, handleMouseMove, handleMouseUp, undoLastAnnotation } from './utils/agora.redux-helpers';

const initialState: AgoraStateProps = {
  audioState: true,
  videoState: true,
  agoraRtc: false,
  buttonsEnable: false,
  usersAgora: {},
  meUid: '',
  recordStatus: false,
  recordEnd: 0,
  recordStartedBy: { session_id: '', user_id: '' },
  microphoneVolume: 0,
  requiresMicrophone: false,
  remoteNetworkQuality: null,
  autoplayFailed: false,
  screenShare: {
    status: ScreenShareEnum.NO_SHARING,
    loading: false,
    isUserSelecting: false,
    showDialog: null,
  },
  videoShare: {
    status: false,
    loading: false,
  },
  imageFreeze: null,
  freezeEvents: null,
  webMobileAnnotations: {},
  toggleTextBubbles: false,
  isOpenTextBubble: false,
  textBubbleValue: '',
  deletedFreezeAnnotations: false,
  undoFreezeChanges: 0,
  outdatedAppEvents: 0,
  sessionId: '',
  annotation: null,
  disableFreezeButton: false,
  hasOwnAnnotations: false,
};

const lastEvents: LastEventAnnotation = {};
let newLine = true;
let lineIndex = 0;

const agoraSlice = createSlice({
  name: 'agora',
  initialState,
  reducers: {
    setAudioState(state, action: PayloadAction<boolean>) {
      localStorage.setItem('microphone_state', action.payload.toString());
      state.audioState = action.payload;
    },
    setAgoraRtc(state, action: PayloadAction<boolean>) {
      state.agoraRtc = action.payload;
    },
    setHasOwnAnnotations(state, action: PayloadAction<boolean>) {
      state.hasOwnAnnotations = action.payload;
    },
    setMicrophoneState(state, action: PayloadAction<boolean>) {
      state.requiresMicrophone = action.payload;
    },
    setEnableButton(state, action: PayloadAction<boolean>) {
      state.buttonsEnable = action.payload;
    },
    setAgoraUsers(state, action: PayloadAction<AgoraIOUsers>) {
      state.usersAgora = { ...action.payload };
    },
    setUid(state, action: PayloadAction<string>) {
      state.meUid = action.payload;
    },
    setUserAudio(state, action: PayloadAction<{ uId: string; audioState: boolean }>) {
      if (state.usersAgora) {
        (state.usersAgora[action.payload.uId] as User)._audio_muted_ = action.payload.audioState;
      }
    },
    setEnableRecord(
      state,
      action: PayloadAction<{
        recordStatus: boolean;
        recordEnd: number;
        recordStartedBy?: StartedByProps;
      }>,
    ) {
      state.recordStatus = action.payload.recordStatus;
      state.recordEnd = action.payload.recordEnd;
      state.recordStartedBy = action.payload.recordStartedBy || state.recordStartedBy;
    },
    setMicrophoneVolume(state, action: PayloadAction<{ id: string; microphoneVolume: number }>) {
      if (state.meUid == action.payload.id) {
        state.microphoneVolume = action.payload.microphoneVolume;
      }
      if (state.usersAgora && state.usersAgora[action.payload.id]) {
        (state.usersAgora[action.payload.id] as User).microphoneVolume = action.payload.microphoneVolume;
      }
    },
    setRemoteNetworkQuality(
      state,
      action: PayloadAction<{
        bitRate: number;
        frameRate: number;
        networkQuality: number;
        resolutionWidth: number;
        resolutionHeight: number;
      }>,
    ) {
      state.remoteNetworkQuality = action.payload;
    },
    setImageFreeze(state, action: PayloadAction<ImageFreeze | null>) {
      state.imageFreeze = action.payload;
    },
    setFreezeEvents(state, action: PayloadAction<FreezeEvents | null>) {
      state.freezeEvents = action.payload;
    },
    setAutoplayFailed(state, action: PayloadAction<boolean>) {
      state.autoplayFailed = action.payload;
    },
    setScreenShare(
      state,
      action: PayloadAction<{
        status: ScreenShareType;
        loading: boolean;
        isUserSelecting?: boolean;
        showDialog?: ScreenShareDialogType | null;
      }>,
    ) {
      state.screenShare = {
        ...state.screenShare,
        ...action.payload,
        isUserSelecting:
          action.payload.isUserSelecting !== undefined
            ? action.payload.isUserSelecting
            : state.screenShare.isUserSelecting,
        showDialog: action.payload.showDialog !== undefined ? action.payload.showDialog : state.screenShare.showDialog,
      };
    },
    setScreenShareDialog(state, action: PayloadAction<null | ScreenShareDialogType>) {
      state.screenShare.showDialog = action.payload;
    },
    setVideoShare(state, action: PayloadAction<{ status: boolean; loading: boolean }>) {
      state.videoShare = action.payload;
    },
    addWebMobileAnnotation(state, action: PayloadAction<WebMobileAnnotation>) {
      const { sessionId, annotation } = action.payload;

      if (annotation.type === AnnotationType.MOUSE_DOWN) {
        handleMouseDown(lastEvents, sessionId, annotation);
      } else if (annotation.type === AnnotationType.MOUSE_UP) {
        const result = handleMouseUp(state, sessionId, annotation, lastEvents, lineIndex, newLine);
        lineIndex = result.lineIndex;
        newLine = result.newLine;
      } else if (annotation.type === AnnotationType.MOUSE_MOVE) {
        const result = handleMouseMove(state, sessionId, annotation, lastEvents, lineIndex, newLine);
        lineIndex = result.lineIndex;
        newLine = result.newLine;
      }
    },
    undoMobileAnnotation(state, action: PayloadAction<string>) {
      const sessionId = action.payload;
      lineIndex = undoLastAnnotation(state, sessionId, lineIndex);
    },
    popArrowAnnotation(state, action: PayloadAction<string>) {
      const sessionAnnotations = state.webMobileAnnotations[action.payload];
      if (sessionAnnotations && sessionAnnotations.arrows.length) {
        sessionAnnotations.arrows.pop();
      }
    },
    popFreezeLine(state, action: PayloadAction<string>) {
      const sessionAnnotations = state.webMobileAnnotations[action.payload];
      if (sessionAnnotations && sessionAnnotations.freezeLines.length) {
        sessionAnnotations.freezeLines.pop();
      }
    },
    popTextAnnotation(state, action: PayloadAction<string>) {
      const sessionAnnotations = state.webMobileAnnotations[action.payload];
      if (sessionAnnotations && sessionAnnotations.texts.length) {
        sessionAnnotations.texts.pop();
      }
    },
    setDeletedFreezeAnnotations(state, action: PayloadAction<boolean>) {
      state.deletedFreezeAnnotations = action.payload;
    },
    setUndoFreezeChanges(state) {
      state.undoFreezeChanges += 1;
    },
    setOutdatedAppEvent(state) {
      state.outdatedAppEvents += 1;
    },
    setToggleTextBubbles(state, action: PayloadAction<boolean>) {
      state.toggleTextBubbles = action.payload;
    },
    setTextBubbleValue(state, action: PayloadAction<string>) {
      state.textBubbleValue = action.payload;
    },
    updateTextBubble(state, action: PayloadAction<TextBubbleUpdate>) {
      const { sessionId, key, message } = action.payload;
      const sessionAnnotations = state.webMobileAnnotations[sessionId];
      if (sessionAnnotations) {
        sessionAnnotations.texts = sessionAnnotations.texts.map((tb) => {
          if (tb.key === key) {
            return { ...tb, value: message, timestamp: Date.now() };
          }
          return tb;
        });
      }
    },
    setOpenTextBubble(state, action: PayloadAction<boolean>) {
      state.isOpenTextBubble = action.payload;
    },
    setDisableFreezeButton(state, action: PayloadAction<boolean>) {
      state.disableFreezeButton = action.payload;
    },
    setWebMobileAnnotations(state, action: PayloadAction<WebMobileAnnotations>) {
      state.webMobileAnnotations = action.payload;
    },
    clearImageFreeze(state) {
      state.imageFreeze = null;
    },
    clearWebMobileAnnotations(state) {
      state.webMobileAnnotations = {};
    },
    clearFreezeEvents(state) {
      state.freezeEvents = null;
    },
    assistedLeft(state) {
      state.remoteNetworkQuality = null;
    },
    exitAgora() {
      return initialState;
    },
  },
});

export const {
  setAudioState,
  setAgoraRtc,
  setHasOwnAnnotations,
  setMicrophoneState,
  setEnableButton,
  setAgoraUsers,
  setUid,
  setUserAudio,
  setEnableRecord,
  setMicrophoneVolume,
  setRemoteNetworkQuality,
  setImageFreeze,
  setFreezeEvents,
  setAutoplayFailed,
  setScreenShare,
  setScreenShareDialog,
  setVideoShare,
  addWebMobileAnnotation,
  undoMobileAnnotation,
  popArrowAnnotation,
  popFreezeLine,
  popTextAnnotation,
  setDeletedFreezeAnnotations,
  setUndoFreezeChanges,
  setOutdatedAppEvent,
  setToggleTextBubbles,
  setTextBubbleValue,
  updateTextBubble,
  setOpenTextBubble,
  setDisableFreezeButton,
  setWebMobileAnnotations,
  clearImageFreeze,
  clearWebMobileAnnotations,
  clearFreezeEvents,
  assistedLeft,
  exitAgora,
} = agoraSlice.actions;

export default agoraSlice.reducer;

export interface AgoraDispatchProps {
  setAgoraRtc?: typeof setAgoraRtc;
  setEnableButton?: typeof setEnableButton;
  setAgoraUsers?: typeof setAgoraUsers;
  setUid?: typeof setUid;
  setUserAudio?: typeof setUserAudio;
  setEnableRecord?: typeof setEnableRecord;
  updateTextBubble?: typeof updateTextBubble;
  exitAgora?: typeof exitAgora;
  setMicrophoneVolume?: typeof setMicrophoneVolume;
  setRemoteNetworkQuality?: typeof setRemoteNetworkQuality;
  setImageFreeze?: typeof setImageFreeze;
  setFreezeEvents?: typeof setFreezeEvents;
  setAutoplayFailed?: typeof setAutoplayFailed;
  setScreenShare?: typeof setScreenShare;
  setScreenShareDialog?: typeof setScreenShareDialog;
  setVideoShare?: typeof setVideoShare;
  setDeletedFreezeAnnotations?: typeof setDeletedFreezeAnnotations;
  setWebMobileAnnotations?: typeof setWebMobileAnnotations;
  setDisableFreezeButton?: typeof setDisableFreezeButton;
  setHasOwnAnnotations?: typeof setHasOwnAnnotations;
}
