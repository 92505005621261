import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) => {
  return createStyles({
    root: {
      marginBottom: '8px',
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
      color: theme.palette.text.primary,
    },
    required: {
      '& .MuiInputLabel-asterisk': {
        color: theme.palette.error.main,
      },
    },
  });
};
