import { IAgoraRTCClient } from 'agora-rtc-sdk-ng';
import React, { createContext, useContext, useState, ReactNode } from 'react';
import WebSocketClient from 'src/utils/remote-assistance/Websocket';

interface RemoteAssistanceContextType {
  client: IAgoraRTCClient | null;
  setClient: React.Dispatch<React.SetStateAction<IAgoraRTCClient | null>>;

  wsClient: WebSocketClient | null;
  setWsClient: React.Dispatch<React.SetStateAction<WebSocketClient | null>>;

  stream?: number | MediaStream | null;
  setStream: React.Dispatch<React.SetStateAction<number | MediaStream | null>>;

  screen?: MediaStream | null;
  setScreen?: React.Dispatch<React.SetStateAction<MediaStream | null>>;

  microphones?: MediaDeviceInfo[] | null;
  cameras?: MediaDeviceInfo[] | null;
  speakers?: MediaDeviceInfo[] | null;
  setMicrophones: React.Dispatch<React.SetStateAction<MediaDeviceInfo[]>>;
  setCameras: React.Dispatch<React.SetStateAction<MediaDeviceInfo[]>>;
  setSpeakers: React.Dispatch<React.SetStateAction<MediaDeviceInfo[]>>;
}

const RemoteAssistanceContext = createContext<RemoteAssistanceContextType | undefined>(undefined);

export const useRemoteAssistance = (): RemoteAssistanceContextType => {
  const context = useContext(RemoteAssistanceContext);
  if (!context) {
    throw new Error('useRemoteAssistance must be used within a WebSocketProvider');
  }
  return context;
};

interface RemoteAssistanceProviderProps {
  children: ReactNode;
}

export const RemoteAssistanceProvider = ({ children }: RemoteAssistanceProviderProps) => {
  const [client, setClient] = useState<IAgoraRTCClient | null>(null);
  const [wsClient, setWsClient] = useState<WebSocketClient | null>(null);

  const [stream, setStream] = useState<number | MediaStream | null>(null);
  //const [screen, setScreen] = useState<MediaStream | null>(null);

  const [microphones, setMicrophones] = useState<MediaDeviceInfo[]>([]);
  const [speakers, setSpeakers] = useState<MediaDeviceInfo[]>([]);
  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]);

  return (
    <RemoteAssistanceContext.Provider
      value={{
        client,
        setClient,
        wsClient,
        setWsClient,
        stream,
        setStream,
        microphones,
        setMicrophones,
        speakers,
        setSpeakers,
        cameras,
        setCameras,
      }}
    >
      {children}
    </RemoteAssistanceContext.Provider>
  );
};
