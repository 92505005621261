import { useCallback, useEffect, useRef, useState } from 'react';
import { useStyles } from './styles';
import { AttachFileOutlined, CameraAlt } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import CameraDialog from '../file-field/camera-dialog';
import { showSnackbar } from 'src/base/root/root.redux';
import { getFiles, uploadFile } from 'src/modules/file-storage/file-storage.redux';
import { ConnectedProps, connect } from 'react-redux';
import { createFileThumbnail, dataURItoBlob, dataUrlToFile } from 'src/utils/funcs/files/utils';
import { getFileExtension } from '../show-file/utils/show-file-util-functions';
import { File, FileType } from 'src/gql/graphql';
import ShowFile from '../show-file';

interface IUploadFilesProps extends ConnectedProps<typeof connector> {
  camera?: boolean;
  fileIds: string[];
  setFileIds: (ids: string[]) => void;
}

const UploadFiles = (props: IUploadFilesProps) => {
  const [openCamera, setOpenCamera] = useState<boolean>(false);
  const uploadFileRef = useRef<HTMLInputElement>();
  const [progressMap, setProgressMap] = useState<Map<string, number>>(new Map());
  const [currentFiles, setCurrentFiles] = useState<File[]>([]);

  const { t } = useTranslation();
  const classes = useStyles();

  const fetchFiles = useCallback(async () => {
    const resp = await props.getFiles({ _id_in: props.fileIds }, true);
    if (!resp || 'graphQLErrors' in resp) {
      return;
    }
    setCurrentFiles(resp);
  }, [props.fileIds]);

  useEffect(() => {
    if (props.fileIds) fetchFiles();
  }, [props.fileIds]);

  const addNewFile = async () => {
    const newFiles = Array.from(uploadFileRef.current.files || []);
    if (newFiles.length > 10) {
      props.showSnackbar('error', t('limitOfFiles') + ': ' + 10);
      return;
    }

    //Generate thumbnails
    const uploadingFilesThumbnails: Array<Pick<File, 'name'> & { thumbnailBlob: Blob }> = [];
    const uploading = await Promise.all(
      Array.from(newFiles).map(async (f): Promise<File> => {
        try {
          const { thumbnail, totalTriangles } = await createFileThumbnail(f);
          uploadingFilesThumbnails.push({ name: f.name, thumbnailBlob: thumbnail });
          setProgressMap((prevMap) => {
            const newMap = new Map(prevMap);
            newMap.set(f.name, 1);
            return newMap;
          });
          return {
            name: f.name,
            size: f.size,
            type: FileType.Unknown,
            details: { triangles: totalTriangles },
            extension: getFileExtension(f.name),
            path: '',
            ...(thumbnail && { hasThumbnail: true, download: { thumbnail: { url: URL.createObjectURL(thumbnail) } } }),
          };
        } catch (error) {
          props.showSnackbar('error', `Error generating thumbnail for file: ${f.name}`);
          console.error(error);
          return null;
        }
      }),
    );

    setCurrentFiles((prev) => [...prev, ...uploading]);
    const ids = [];
    for (const [, file] of newFiles.entries() || []) {
      const resp = await props.uploadFile(
        file,
        uploadingFilesThumbnails.find((f) => f.name === file.name).thumbnailBlob,
        uploading.find((f) => f.name === file.name).details?.triangles,
        (name, progress) => {
          setProgressMap((prevMap) => {
            const newMap = new Map(prevMap);
            newMap.set(name, progress);
            return newMap;
          });
        },
        (name, error) => {
          props.showSnackbar('error', `Error uploading file ${name}: ${error.message}`);
          setCurrentFiles((prev) =>
            prev.filter((f) => {
              if (f.name !== name) return true;
              if (f._id) return true;
              return false;
            }),
          );
        },
      );
      if ('graphQLErrors' in resp) {
        continue;
      }
      if (!resp) {
        return;
      }
      if (resp['response'] && resp['response'].data.errors) {
        props.showSnackbar('error', resp['response'].data.errors[0].error);
        return;
      } else if (resp['response'] && resp['response'].status >= 400) {
        props.showSnackbar('error', t('fileUploadFailed'));
        return;
      }
      ids.push(resp.data.data.files[0]._id);
    }
    props.setFileIds([...props.fileIds, ...ids]);
    setProgressMap(new Map());
  };

  const addImage = async (imgSrc: string) => {
    const thumbnail = dataURItoBlob(imgSrc);
    const file = await dataUrlToFile(imgSrc, `image${currentFiles.length + 1}.png`);
    const resp = await props.uploadFile(file, thumbnail, undefined, undefined, (name, error) => {
      props.showSnackbar('error', `Error uploading file ${name}: ${error.message}`);
    });

    if (!resp) {
      return;
    }

    if ('graphQLErrors' in resp) {
      return;
    }

    if (resp['response'] && resp['response'].data.errors) {
      props.showSnackbar('error', resp['response'].data.errors[0].error);
      return;
    } else if (resp['response'] && resp['response'].status >= 400) {
      props.showSnackbar('error', t('fileUploadFailed'));
      return;
    }

    props.setFileIds([...props.fileIds, resp.data.data.files[0]._id]);
  };

  return (
    <div>
      <div className={classes.fileBtns}>
        {props.camera && (
          <div data-testid={'open-camera'} className={classes.uploadDiv} onClick={() => setOpenCamera(true)}>
            <CameraAlt className={classes.addIcon} />
            <div className={classes.text}>{t('camera')}</div>
          </div>
        )}
        <div className={classes.uploadDiv}>
          <AttachFileOutlined className={classes.addIcon} />
          <div className={classes.text}>{t('file')}</div>
          <input
            data-testid={'file-input'}
            multiple
            ref={uploadFileRef}
            type='file'
            title=' '
            name='fileinput'
            className={classes.uploadInput}
            onClick={(): void => {
              uploadFileRef.current.value = null;
            }}
            onChange={addNewFile}
          />
        </div>
      </div>
      <ShowFile
        value={currentFiles || []}
        noImageInfo
        noName
        noBold
        handleChange={(files: File[]) => {
          props.setFileIds(files.map((file) => file._id));
        }}
        allowReorder={true}
        progressMap={progressMap}
      ></ShowFile>
      {openCamera ? <CameraDialog onClose={() => setOpenCamera(false)} addImage={addImage} /> : null}
    </div>
  );
};

const connector = connect(null, {
  showSnackbar,
  uploadFile,
  getFiles,
});

export default connector(UploadFiles);
