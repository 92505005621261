import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

const styles = (theme: Theme) =>
  createStyles({
    datePicker: {
      position: 'fixed',
      boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.2), 0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12)',
      width: 560,
      zIndex: theme.zIndex.tooltip,
      backgroundColor: theme.palette.common.white,
    },
    spacing: {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingRight: 20,
      gap: 20,
    },
    btn: {
      color: theme.palette.primary.main,
    },
    btnDimensions: {
      height: '32px',
      width: '130px',
      paddingTop: '0 !important',
      /* border: `1px solid ${theme.palette.primary.light}`, */
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  });

export default styles;
