import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import {
  AttachFile,
  CalendarToday,
  CategoryOutlined,
  CheckBoxOutlined,
  Construction,
  FilterCenterFocus,
  Gesture,
  InfoOutlined,
  KeyboardArrowRight,
  LooksOne,
  PlaceOutlined,
  RadioButtonCheckedOutlined,
  TableChartOutlined,
  TextFields,
} from '@mui/icons-material';
import { useMemo } from 'react';
import { styles } from './styles';
import { Unit } from 'src/gql/graphql';

export type ISelectListItem = {
  _id: string;
  name: string;
  childrens: ISelectListItem[];
  isFinal: boolean;
  type?: string;
  unit?: Unit;
  isSelected?: boolean;
};

type SelectListItemProps = {
  item: ISelectListItem;
  noHover?: boolean;
  inputTypes?: string[];
  onClick?: (item: ISelectListItem) => void;
} & WithStyles<typeof styles>;

const SelectListItem = ({ classes, item, noHover, inputTypes, onClick }: SelectListItemProps) => {
  const canGoFoward = useMemo(() => {
    return item?.childrens?.length > 0;
  }, [item]);

  const isDisabled = useMemo(() => {
    return !canGoFoward && !item.isFinal;
  }, [item]);

  const correctType = useMemo(() => {
    return item?.type && inputTypes?.includes(item?.type);
  }, [item, inputTypes]);

  const handleClick = (item: ISelectListItem) => {
    if (isDisabled) return;
    onClick?.(item);
  };

  const renderIcon = (type: string) => {
    switch (type) {
      case 'string':
        return <TextFields classes={{ root: `${classes.textFieldIcon}` }} />;
      case 'instruction':
        return <InfoOutlined classes={{ root: `${classes.elementIcon}` }} />;
      case 'number':
        return <LooksOne classes={{ root: `${classes.oneIcon}` }} />;
      case 'multiple':
        return <CheckBoxOutlined classes={{ root: `${classes.checkboxOutIcon}` }} />;
      case 'selection':
        return <RadioButtonCheckedOutlined classes={{ root: `${classes.radioOutIcon}` }} />;
      case 'datetime':
        return <CalendarToday classes={{ root: `${classes.calendarIcon}` }} />;
      case 'file':
        return <AttachFile classes={{ root: `${classes.fileIcon}` }} />;
      case 'element':
        return <CategoryOutlined classes={{ root: `${classes.elementIcon}` }} />;
      case 'site':
        return <PlaceOutlined classes={{ root: `${classes.elementIcon}` }} />;
      case 'table':
        return <TableChartOutlined classes={{ root: `${classes.tableIcon}` }} />;
      case 'signature':
        return <Gesture classes={{ root: `${classes.signatureIcon}` }} />;
      case 'qrCode':
        return <FilterCenterFocus classes={{ root: `${classes.qrCodeIcon}` }} />;
      case 'tool':
        return <Construction id='genericInputCardTool' className={classes.toolIcon} />;
      default:
        return null;
    }
  };

  return !item.isFinal || correctType || noHover ? (
    <ListItem
      id={`select-item-${classes.itemText ?? 'NoName'}`}
      data-testid={`select-item-${item?.name ?? 'NoName'}`}
      key={item._id}
      classes={{ root: isDisabled ? classes.listItemDisabled : noHover ? classes.listItemNoHover : classes.listItem }}
      onClick={() => handleClick(item)}
    >
      {item?.type ? <ListItemIcon>{renderIcon(item.type)}</ListItemIcon> : null}
      <ListItemText
        style={{ cursor: 'pointer' }}
        classes={{
          root: classes.itemText,
        }}
      >
        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          <Tooltip title={item?.name} placement='top-start'>
            <span className={isDisabled ? classes.disabledText : ''}>{item?.name}</span>
          </Tooltip>
        </div>
      </ListItemText>
      {canGoFoward ? (
        <KeyboardArrowRight classes={{ root: classes.rightIcon }} data-testid={`list-by-level-${item.name}-go-to`} />
      ) : null}
    </ListItem>
  ) : (
    <></>
  );
};

export default withStyles(styles)(SelectListItem);
