import { IconProps } from '@mui/material';
import { upperFirst } from 'lodash';
import { forwardRef, Suspense, useMemo } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';
import * as Icons from '@mui/icons-material';

export function pascalCase(str: string): string {
  return str
    ?.split('_')
    .map((s) => upperFirst(s))
    .join('');
}

export const DynamicIcon = forwardRef(({ name, ...props }: IconProps & { name: string }, ref) => {
  const formattedName = pascalCase(name);
  const Icon = useMemo(() => Icons[formattedName], [name]);

  return <Suspense fallback={<LazyLoadingContent />}>{Icon ? <Icon ref={ref} {...props} /> : undefined}</Suspense>;
});
