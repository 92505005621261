import i18n from 'src/utils/translations/i18n';
import {
  M_CREATE_ACCOUNT_FILTER,
  M_DELETE_ACCOUNT_FILTER,
  M_UPDATE_ACCOUNT_FILTER,
  M_UPDATE_LOGGED_ACCOUNT,
  M_SET_GPS_LOCATION,
  M_UPDATE_TUTORIAL,
} from 'src/modules/account/account.queries';
import { addSpace, getErrorObject } from 'src/utils/funcs';
import { ACCESS_ASSIST_TOKEN, ACCESS_TOKEN, APOLLO_CLIENT } from 'config/apollo.config';
import { Dispatch, UnknownAction } from 'redux';
import moment from 'moment-timezone';
import 'moment/dist/locale/es';
import 'moment/dist/locale/fr';
import 'moment/dist/locale/pt';
import {
  Account,
  AccountFilterUpdateInput,
  GetLoggedUserDocument,
  LoginDocument,
  LogoutDocument,
  MyClearances,
} from 'src/gql/graphql';
import { getDefaultUserConfig, UserConfig } from 'src/utils/remote-assistance/agorartc/agoraClient';
import 'moment/dist/locale/pt';
import 'moment/dist/locale/fr';
import 'moment/dist/locale/es';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ERROR_LOGIN = 'ERROR_LOGIN';
export const GET_LOGGED_USER = 'GET_LOGGED_USER';
export const ERROR = 'ERROR';
export const UPDATE_LOGGED_ACCOUNT = 'UPDATE_LOGGED_ACCOUNT';
export const UPDATE_LOGGED_FILTERS_ACCOUNT = 'UPDATE_LOGGED_FILTERS_ACCOUNT';
export const SET_GPS_LOCATION = 'SET_GPS_LOCATION';
export const UPDATE_POST_SEEN = 'UPDATE_POST_SEEN';
export const UPDATE_ACTION_COUNT = 'UPDATE_ACTION_COUNT';
export const UPDATE_USER_CONFIG = 'UPDATE_USER_CONFIG';
export const CLEAR_USER_CONFIG = 'CLEAR_USER_CONFIG';
export const UPDATE_TUTORIAL = 'UPDATE_TUTORIAL';
export const UPDATE_CHAT_COUNT = 'UPDATE_CHAT_COUNT';
export const UPDATE_TAB = 'UPDATE_TAB';

export interface LoginReducerProps {
  error: boolean;
  theme: string;
  clearances: MyClearances[];
  loggedIn: boolean;
  loggedUser?: Account;
  userConfig?: UserConfig;
  impersonatedBy?: Account;
}

const initialState: LoginReducerProps = {
  loggedIn: localStorage.getItem(ACCESS_TOKEN) !== null,
  loggedUser: null,
  userConfig: getDefaultUserConfig(),
  clearances: [],
  theme: 'light',
  error: false,
};

export default function (
  state: LoginReducerProps = initialState,
  action: { type: string; payload: any },
): LoginReducerProps {
  switch (action.type) {
    case LOGIN:
      localStorage.setItem(ACCESS_TOKEN, action.payload.token);
      if (action.payload?.assistToken) {
        localStorage.setItem(ACCESS_ASSIST_TOKEN, action.payload.assistToken);
      }
      if (action.payload.account?._id != null) {
        localStorage.setItem('accountId', action.payload.account._id);
      }
      localStorage.setItem('username', action.payload.account.username);

      if (action.payload.account.preferences.defaultSite) {
        localStorage.setItem('current_site_id', action.payload.account.preferences.defaultSite?._id);
        localStorage.setItem('current_site_name', action.payload.account.preferences.defaultSite.name);
      }
      return {
        ...state,
        loggedIn: true,
        loggedUser: action.payload.account,
        impersonatedBy: action.payload.impersonatedBy,
        clearances: action.payload.account.myClearances.map((c) => ({
          bundle: c.bundle.name,
          level: c.level,
        })),
        theme: action.payload.account.preferences.theme,
        error: false,
      };
    case UPDATE_LOGGED_ACCOUNT:
      if (action.payload.account.preferences.locale) {
        moment.locale(action.payload.account.preferences.locale);
      } else {
        const browserLocale = navigator.language;
        moment.locale(browserLocale);
      }
      moment.tz.setDefault(action.payload.account.preferences.timeZone || action.payload.account.defaultTimeZone);
      return {
        ...state,
        clearances: state.clearances,
        error: state.error,
        loggedIn: state.loggedIn,
        loggedUser: action.payload.account,
        theme: action.payload.account.preferences.theme,
      };
    case GET_LOGGED_USER:
      if (action.payload.account.preferences.defaultSite) {
        localStorage.setItem('current_site_id', action.payload.account.preferences.defaultSite._id);
        localStorage.setItem('current_site_name', action.payload.account.preferences.defaultSite.name);
      }
      if (action.payload.account.preferences.locale) {
        moment.locale(action.payload.account.preferences.locale);
      } else {
        const browserLocale = navigator.language;
        moment.locale(browserLocale);
      }

      moment.tz.setDefault(action.payload.account.preferences.timeZone || action.payload.account.defaultTimeZone);
      return {
        ...state,
        loggedIn: true,
        clearances: action.payload.account.myClearances.map((c) => ({
          bundle: c.bundle.name,
          level: c.level,
        })),
        loggedUser: action.payload.account,
        impersonatedBy:
          action.payload.impersonatedBy === undefined ? state.impersonatedBy : action.payload.impersonatedBy,
        theme: action.payload.account.preferences?.theme ? action.payload.account.preferences.theme : state.theme,
        error: false,
      };
    case LOGOUT:
      window.localStorage.clear();
      return {
        clearances: state.clearances,
        theme: state.theme,
        loggedIn: false,
        loggedUser: null,
        impersonatedBy: null,
        error: false,
      };
    case ERROR_LOGIN:
      // do something with the payload
      window.localStorage.clear();
      return { ...state, loggedIn: false, error: true };
    case UPDATE_LOGGED_FILTERS_ACCOUNT:
      return {
        ...state,
        loggedUser: { ...state.loggedUser, filters: action.payload.filters },
      };
    case UPDATE_USER_CONFIG:
      return {
        ...state,
        userConfig: { ...action.payload.userConfig },
      };
    case CLEAR_USER_CONFIG:
      return {
        ...state,
        userConfig: getDefaultUserConfig(),
      };
    case SET_GPS_LOCATION:
      return {
        ...state,
        loggedUser: action.payload.account,
      };
    case UPDATE_POST_SEEN:
      return {
        ...state,
        loggedUser: {
          ...state.loggedUser,
          feed: state.loggedUser.feed - 1,
        },
      };
    case UPDATE_ACTION_COUNT: {
      const actions = state.loggedUser.actions + action.payload.value;
      return {
        ...state,
        loggedUser: {
          ...state.loggedUser,
          actions: actions < 0 ? 0 : actions,
        },
      };
    }
    case UPDATE_TUTORIAL: {
      return {
        ...state,
        loggedUser: {
          ...state.loggedUser,
          tutorialCompleted: action.payload.isCompleted,
        },
      };
    }
    case UPDATE_CHAT_COUNT: {
      return {
        ...state,
        loggedUser: {
          ...state.loggedUser,
          chat: action.payload.value,
        },
      };
    }
    case UPDATE_TAB: {
      const existingTab = state.loggedUser.tabs.some((t) => t._id === action.payload.tab._id);

      return {
        ...state,
        loggedUser: {
          ...state.loggedUser,
          tabs: existingTab
            ? state.loggedUser.tabs.map((tab) => (tab._id === action.payload.tab._id ? action.payload.tab : tab))
            : [...state.loggedUser.tabs, action.payload.tab],
        },
      };
    }
    default:
      return state;
  }
}

export const login = (username: string, password?: string, subDomain?: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        username,
        password,
        subDomain,
      },
      fetchPolicy: 'no-cache',
      mutation: LoginDocument,
    });
    if (response) {
      // must place the correct classes for the App to be rendered perfectly
      document.getElementById('root').classList.remove();
      document.body.classList.remove();
      // update the local storage with the token using the correct action

      dispatch({
        type: LOGIN,
        payload: {
          token: response.data.login._id,
          assistToken: response.data.login.assistToken,
          account: response.data.login.account,
          impersonatedBy: response.data.login.account.impersonatedById,
        },
      });
    } else {
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('translation:wrongUsernamePassword'),
          severity: 'error',
        },
      });
    }
  } catch (error) {
    const isActiveError = error.message === 'Your account is inactive. Contact the administrator.' ? true : false;
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: isActiveError ? i18n.t('translation:accountIsInactive') : i18n.t('translation:wrongUsernamePassword'),
        severity: 'error',
      },
    });
    return error;
  }
};

/**
 * @function logout
 * @description dispatches an action to the Login reducer, to end the user's session.
 */
export const logout =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    await APOLLO_CLIENT.mutate({
      variables: {},
      fetchPolicy: 'no-cache',
      mutation: LogoutDocument,
    });
    // must place the correct classes for the Login page to be rendered perfectly
    document.getElementById('root').classList.add('container');
    document.body.classList.remove();
    dispatch({
      type: LOGOUT,
    });
  };

export const logoutWithoutBack =
  () =>
  (dispatch: Dispatch): void => {
    // must place the correct classes for the Login page to be rendered perfectly
    document.getElementById('root').classList.add('container');
    document.body.classList.remove();
    dispatch({
      type: LOGOUT,
    });
  };

export const updateLoggedUser =
  (accountData: Record<string, any>, doDispatch = true) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          email: accountData.email,
          name: accountData.name,
          username: accountData.username,
          id: accountData.id || accountData._id,
          inputs: accountData.inputs,
          authorizedSites: accountData.authorizedSites || accountData['sites'],
          // template: accountData.template?._id,
          // folder: accountData.folder?._id,
          password: accountData.password,
          newPassword: accountData.newPassword,
          preferences: accountData.preferences,
          labelValues: accountData.labelValues,
          roles: accountData?.roles,
          onBoarding: accountData.onBoarding,
          firstLogin: accountData.firstLogin,
        },
        fetchPolicy: 'no-cache',
        mutation: M_UPDATE_LOGGED_ACCOUNT,
      });

      dispatch({
        type: UPDATE_LOGGED_ACCOUNT,
        payload: { account: response.data.updateAccount },
      });

      if (doDispatch)
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('translation:accountToastUpdateSuccess'),
            severity: 'success',
          },
        });
      return response.data.updateAccount;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      dispatch({
        type: ERROR,
        payload: error,
      });

      return error;
    }
  };

export const getLoggedUser = () => async (dispatch: Dispatch) => {
  // access the backend and get the access token
  try {
    const response = await APOLLO_CLIENT.query({
      fetchPolicy: 'no-cache',
      query: GetLoggedUserDocument,
    });

    const { impersonatedById: impersonatedBy, ...account } = response.data.me;

    // update the local storage with the token using the correct action
    dispatch({
      type: GET_LOGGED_USER,
      payload: { account, impersonatedBy },
    });

    return response.data.me;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    dispatch({
      type: ERROR_LOGIN,
    });

    return error;
  }
};

export const createFilter =
  (filterData: AccountFilterUpdateInput, showSnackbar: boolean) =>
  async (dispatch: Dispatch): Promise<Account> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          name: filterData.name,
          labelValues: filterData.labelValues,
          columns: filterData.columns,
          filters: [],
          context: filterData.context,
        },
        fetchPolicy: 'no-cache',
        mutation: M_CREATE_ACCOUNT_FILTER,
      });

      dispatch({
        type: UPDATE_LOGGED_FILTERS_ACCOUNT,
        payload: { filters: response.data.accountCreateFilter.filters },
      });
      if (showSnackbar)
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('tab') + addSpace(filterData.name) + i18n.t('toastCreateSuccess'),
            severity: 'success',
          },
        });
      return response.data.accountCreateFilter;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      dispatch({
        type: ERROR,
        payload: error,
      });

      return error;
    }
  };

export const updateFilter = (filterData: Record<string, any>) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        _id: filterData.filterId,
        name: filterData.name,
        columns: filterData.columns,
        context: filterData.context,
        filters: filterData.filters,
        order: filterData.order,
      },
      fetchPolicy: 'no-cache',
      mutation: M_UPDATE_ACCOUNT_FILTER,
    });

    dispatch({
      type: UPDATE_LOGGED_FILTERS_ACCOUNT,
      payload: { filters: response.data.accountUpdateFilter.filters },
    });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    dispatch({
      type: ERROR,
      payload: error,
    });

    return error;
  }
};

export const deleteFilter =
  (filterData: { _id?: string; name?: string }) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          filterId: filterData._id,
        },
        fetchPolicy: 'no-cache',
        mutation: M_DELETE_ACCOUNT_FILTER,
      });

      dispatch({
        type: UPDATE_LOGGED_FILTERS_ACCOUNT,
        payload: { filters: response.data.accountDeleteFilter.filters },
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('tab') + addSpace(filterData.name) + i18n.t('toastDeleteSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      dispatch({
        type: ERROR,
        payload: error,
      });

      return error;
    }
  };

export const setGpsLocation =
  (coordinates: number[]) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          coordinates: coordinates,
        },
        fetchPolicy: 'no-cache',
        mutation: M_SET_GPS_LOCATION,
      });
      dispatch({
        type: SET_GPS_LOCATION,
        payload: { account: response.data.setGpsLocation },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      dispatch({
        type: ERROR,
        payload: error,
      });

      return error;
    }
  };

export const updateUserConfig = (userConfig: UserConfig): UnknownAction => {
  return {
    type: UPDATE_USER_CONFIG,
    payload: {
      userConfig,
    },
  };
};

export const clearUserConfig = (): UnknownAction => {
  return {
    type: CLEAR_USER_CONFIG,
    payload: {},
  };
};

export const updateTutorial = (accountId: string, isCompleted: boolean) => async (dispatch: Dispatch) => {
  try {
    await APOLLO_CLIENT.mutate({
      variables: { accountId, tutorial: isCompleted },
      mutation: M_UPDATE_TUTORIAL,
    });

    dispatch({
      type: 'UPDATE_TUTORIAL',
      payload: { accountId, isCompleted },
    });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};
