import { useSnackbar, VariantType } from 'notistack';
import { useEffect, useRef } from 'react';

const useToast = (snackbarMessages: { severity: string; message: string }[], clearSnackbar: () => void) => {
  const prevSnackbarMessagesRef = useRef(snackbarMessages);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (
      JSON.stringify(snackbarMessages) !== JSON.stringify(prevSnackbarMessagesRef.current) &&
      snackbarMessages.length
    ) {
      enqueueSnackbar(snackbarMessages[0].message, {
        variant: snackbarMessages[0].severity as VariantType,
      });
      clearSnackbar();
    }
  }, [snackbarMessages, enqueueSnackbar, clearSnackbar]);
};

export default useToast;
