import gql from 'graphql-tag';
import {
  INPUT,
  INPUT_NUMBER,
  INPUT_STRING,
  INPUT_WITH_LOG,
  INPUT_WITH_RECURSIVE,
  ON_RESPONSE_COMMON_NUMBER,
  ON_RESPONSE_COMMON_STRING,
} from 'src/utils/fragments/input';
import { PRESET } from 'src/utils/fragments/issue';
import { LABEL_VALUE } from 'src/utils/fragments/label';
import { STATE_MACHINE_INSTANCE } from 'src/utils/fragments/stateMachine';
import { TASK_INSTANCE } from 'src/utils/fragments/task';

export const Q_GET_TASK_INSTANCE = gql`
  query TaskInstance($id: ID!) {
    taskInstance(where: { _id: $id }) {
      ...taskInstance
    }
  }
  ${INPUT}
  ${INPUT_WITH_LOG}
  ${INPUT_WITH_RECURSIVE}
  ${STATE_MACHINE_INSTANCE}
  ${TASK_INSTANCE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
`;

export const M_TASK_EXECUTION_UPDATE_INPUT_NOTE = gql`
  mutation TaskExecutionUpdateInputNote($id: ID!, $inputId: ID!, $onResponsePath: [ID], $data: InputNoteUpdateInput!) {
    setTaskExecutionInputNote(_id: $id, _inputId: $inputId, onResponsePath: $onResponsePath, data: $data) {
      ...taskInstance
    }
  }
  ${INPUT}
  ${INPUT_WITH_LOG}
  ${INPUT_WITH_RECURSIVE}
  ${STATE_MACHINE_INSTANCE}
  ${TASK_INSTANCE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
`;

export const M_TASK_INSTANCE_SET_INPUT_VALUE = gql`
  mutation SetTaskExecutionInputs($id: ID!, $data: [InputValueUpdateInput!]!, $cancelMotive: String, $issueId: ID) {
    setTaskExecutionInputs(_id: $id, data: $data, cancelMotive: $cancelMotive, issueId: $issueId) {
      _id
    }
  }
`;

export const M_STATE_MACHINE_CHANGE_STATE = gql`
  mutation ChangeTaskStateMachineState($token: String, $id: ID!, $action: String!) {
    changeTaskState(token: $token, _id: $id, action: $action) {
      possibleActions
      stateMachineInstance {
        ...stateMachineInstance
      }
    }
  }
  ${STATE_MACHINE_INSTANCE}
`;

export const M_REASSIGN_TASK_INSTANCES = gql`
  mutation ReassignTaskInstances($data: [TaskMultipleReassignInput!]!) {
    reassignTasks(data: $data) {
      _id
    }
  }
`;

export const M_UPDATE_TASK_INSTANCES = gql`
  mutation UpdateTaskInstances($data: [TaskMultipleUpdateInput!]!, $where: IssueInstanceWhereUniqueInput) {
    updateIssueTasks(data: $data, where: $where) {
      _id
      taskGroups {
        _id
        tasks {
          _id
          order
        }
      }
    }
  }
`;

export const M_UPDATE_TASK_DRAFT = gql`
  mutation UpdateTaskDraft($id: ID!, $update: IssueCatalogDraftTaskMappingUpdateInput!) {
    updateTaskMappingDraft(_id: $id, update: $update) {
      _id
    }
  }
`;

export const M_UPDATE_MANY_TASKS_DRAFT = gql`
  mutation UpdateManyTasksDraft(
    $issueCatalogDraft: ID!
    $update: IssueCatalogDraftTaskMappingUpdateInput!
    $taskMappingsToUpdate: [ID!]!
  ) {
    updateManyTaskMappingDrafts(
      issueCatalogDraft: $issueCatalogDraft
      update: $update
      taskMappingsToUpdate: $taskMappingsToUpdate
    ) {
      _id
    }
  }
`;

export const M_DELETE_MANY_TASK_TEMPLATES = gql`
  mutation DeleteManyTaskTemplates($ids: [ID!]!, $id: ID!) {
    deleteManyTaskTemplateDrafts(taskTemplatesToDelete: $ids, _id: $id) {
      _id
      frame {
        ...preset
      }
      taskTemplateGroups {
        name
        order
        sequential
        _id
        markersToShow
        taskTemplates {
          name
          manualId
          order
          estimatedDuration
          estimatedDurationDivided {
            valueAdded
            nonValueAdded
            waste
          }
          onResponseId
          parentsTree
          _id
          log {
            type
            action
            component
            from
            to
            time {
              from {
                value
                unit
              }
              to {
                value
                unit
              }
            }
            dateTime
          }
          variant {
            variantId
            name
            position
          }
          advancedLabelSelection {
            name
            filter {
              includeAllLabels
              conditions {
                operator
                labelValue {
                  value
                  _id
                  backgroundColor
                  label {
                    name
                    _id
                    labelValues {
                      ...labelValue
                    }
                  }
                  description
                }
              }
            }
          }
          labelValues {
            ...labelValue
          }
          inputs {
            ...inputWithRecursive
          }
        }
      }
    }
  }
  ${INPUT_WITH_RECURSIVE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
  ${INPUT_WITH_LOG}
  ${PRESET}
  ${LABEL_VALUE}
`;

export const M_UPDATE_TASK_TEMPLATE_DRAFTS_VARIANT_ORDER = gql`
  mutation UpdateTaskTemplateDraftsVariantOrder($issueId: ID!, $groupId: ID!, $data: [TaskVariantOrder!]!) {
    updateTaskTemplateDraftsVariantOrder(taskTemplateDraft: $issueId, taskTemplateGroup: $groupId, tasks: $data) {
      _id
    }
  }
`;

export const M_CREATE_MANY_TASK_TEMPLATES = gql`
  mutation CreateManyTaskTemplates(
    $issueId: ID!
    $groupId: ID!
    $data: [IssueTemplateDraftTaskTemplateGroupTaskTemplateUpdateInput!]!
  ) {
    createManyTaskTemplateDrafts(issueTemplateDraft: $issueId, taskTemplateGroup: $groupId, taskTemplates: $data) {
      _id
      frame {
        ...preset
      }
      taskTemplateGroups {
        name
        order
        sequential
        _id
        taskTemplates {
          name
          manualId
          order
          estimatedDuration
          estimatedDurationDivided {
            valueAdded
            nonValueAdded
            waste
          }
          isRepeatable
          log {
            type
            action
            component
            from
            to
            time {
              from {
                value
                unit
              }
              to {
                value
                unit
              }
            }
            dateTime
          }
          _id
          variant {
            variantId
            name
            position
          }
          assignedTools {
            tool {
              designation
              partNumber
              _id
            }
            quantity
          }
          assignedMaterials {
            material {
              code
              name
              _id
            }
            quantity
          }
          advancedLabelSelection {
            name
            filter {
              includeAllLabels
              conditions {
                operator
                labelValue {
                  value
                  _id
                  backgroundColor
                  label {
                    name
                    _id
                    labelValues {
                      ...labelValue
                    }
                  }
                  description
                }
              }
            }
          }
          labelValues {
            ...labelValue
          }
          name
          order
          inputs {
            ...input
          }
          estimatedDuration
          _id
          variant {
            variantId
            name
            position
          }
        }
      }
    }
  }
  ${PRESET}
  ${LABEL_VALUE}
  ${INPUT}
`;

export const M_UPDATE_TASK_TEMPLATE = gql`
  mutation UpdateTaskTemplate(
    $draftId: ID!
    $data: IssueTemplateDraftTaskTemplateGroupTaskTemplateUpdateInput!
    $id: ID!
    $groupId: ID!
  ) {
    updateTaskTemplateDraft(
      issueTemplateDraft: $draftId
      taskTemplateGroup: $groupId
      update: $data
      taskToUpdate: $id
    ) {
      _id
      log {
        type
        action
        component
        from
        to
        time {
          from {
            value
            unit
          }
          to {
            value
            unit
          }
        }
        dateTime
      }
      inputs {
        ...inputWithRecursive
      }
    }
  }
  ${INPUT_WITH_RECURSIVE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
  ${INPUT_WITH_LOG}
`;

gql`
  mutation UpdateTaskTemplateLogs(
    $draftId: ID!
    $data: IssueTemplateDraftTaskTemplateGroupTaskTemplateUpdateInput!
    $id: ID!
    $groupId: ID!
  ) {
    updateTaskTemplateDraft(
      issueTemplateDraft: $draftId
      taskTemplateGroup: $groupId
      update: $data
      taskToUpdate: $id
    ) {
      _id
      log {
        type
        action
        component
        from
        to
        time {
          from {
            value
            unit
          }
          to {
            value
            unit
          }
        }
        dateTime
      }
    }
  }
`;

export const M_UPDATE_TASK_TEMPLATE_INPUT = gql`
  mutation UpdateTaskTemplateInput(
    $draftId: ID!
    $groupId: ID!
    $taskId: ID!
    $inputId: ID!
    $data: InputUpdateInput!
  ) {
    updateInputTemplateDraft(
      issueTemplateDraft: $draftId
      taskTemplateGroup: $groupId
      taskToUpdate: $taskId
      inputToUpdate: $inputId
      update: $data
    ) {
      ...inputWithRecursive
    }
  }
  ${INPUT_WITH_RECURSIVE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
  ${INPUT_WITH_LOG}
`;

export const M_ADD_TASK_TEMPLATE_INPUT = gql`
  mutation AddTaskTemplateInput(
    $draftId: ID!
    $groupId: ID!
    $taskId: ID!
    $onResponseId: ID
    $data: InputUpdateInput!
  ) {
    addInputTemplateDraft(
      issueTemplateDraft: $draftId
      taskTemplateGroup: $groupId
      taskToUpdate: $taskId
      onResponse: $onResponseId
      data: $data
    ) {
      ...input
    }
  }
  ${INPUT}
`;

export const M_UPDATE_MANY_TASK_TEMPLATES = gql`
  mutation UpdateManyTaskTemplates(
    $issueId: ID!
    $update: TaskTemplateUpdateManyInput!
    $taskTemplatesToUpdate: [ID!]!
  ) {
    updateManyTaskTemplateDrafts(
      issueTemplateDraft: $issueId
      update: $update
      taskTemplatesToUpdate: $taskTemplatesToUpdate
    ) {
      _id
    }
  }
`;

export const M_UPDATE_MULTIPLE_TASK_TEMPLATES = gql`
  mutation UpdateMultipleTaskTemplates(
    $data: [TaskMultipleUpdateInput!]!
    $taskTemplateGroup: ID!
    $issueDraftId: ID!
  ) {
    updateMultipleTaskTemplateDrafts(data: $data, taskTemplateGroup: $taskTemplateGroup, issueDraftId: $issueDraftId) {
      _id
    }
  }
`;

export const M_START_GROUP_TIMER = gql`
  mutation StartGroupTimer($issueId: ID!, $groupId: ID!) {
    startGroupTimer(_id: $issueId, groupId: $groupId) {
      _id
    }
  }
`;

export const M_PAUSE_GROUP_TIMER = gql`
  mutation PauseGroupTimer($issueId: ID!, $groupId: ID!) {
    pauseGroupTimer(_id: $issueId, groupId: $groupId) {
      _id
    }
  }
`;

export const M_RESUME_GROUP_TIMER = gql`
  mutation ResumeGroupTimer($issueId: ID!, $groupId: ID!, $timer: Int!) {
    resumeGroupTimer(_id: $issueId, groupId: $groupId, timer: $timer) {
      _id
    }
  }
`;

export const Q_GROUP_TIMER_JOBS = gql`
  query GroupTimerJobs($issue: ID!) {
    groupTimerJobs(issue: $issue) {
      _id
      nextRunAt
      data {
        task {
          _id
        }
      }
    }
  }
`;

gql`
  mutation MoveTaskTemplateDrafts(
    $issueTemplateDraft: ID!
    $fromTaskTemplateGroup: ID!
    $toTaskTemplateGroup: ID!
    $taskTemplatesToMove: [ID!]!
    $taskTemplateNewOrder: Int
  ) {
    moveTaskTemplateDrafts(
      issueTemplateDraft: $issueTemplateDraft
      fromTaskTemplateGroup: $fromTaskTemplateGroup
      toTaskTemplateGroup: $toTaskTemplateGroup
      taskTemplatesToMove: $taskTemplatesToMove
      taskTemplateNewOrder: $taskTemplateNewOrder
    ) {
      _id
      taskTemplateGroups {
        name
        order
        sequential
        _id
        taskTemplates {
          name
          manualId
          order
          estimatedDuration
          estimatedDurationDivided {
            valueAdded
            nonValueAdded
            waste
          }
          assignedMaterials {
            material {
              code
              name
              _id
            }
            quantity
          }
          assignedTools {
            tool {
              designation
              partNumber
              _id
            }
            quantity
          }
          onResponseId
          parentsTree
          _id
          log {
            type
            action
            component
            from
            to
            time {
              from {
                value
                unit
              }
              to {
                value
                unit
              }
            }
            dateTime
          }
          variant {
            variantId
            name
            position
          }
          advancedLabelSelection {
            name
            filter {
              includeAllLabels
              conditions {
                operator
                labelValue {
                  value
                  _id
                  backgroundColor
                  label {
                    name
                    _id
                    labelValues {
                      ...labelValue
                    }
                  }
                  description
                }
              }
            }
          }
          labelValues {
            ...labelValue
          }
          inputs {
            ...inputWithRecursive
          }
        }
      }
    }
  }
`;

export const Q_TASK_TEMPLATE_DRAFT = gql`
  query GetTaskTemplateDraft($draftId: ID!, $groupId: ID!, $taskId: ID!) {
    taskTemplateDraft(issueTemplateDraft: $draftId, taskTemplateGroup: $groupId, taskTemplate: $taskId) {
      name
      manualId
      order
      estimatedDuration
      estimatedDurationDivided {
        valueAdded
        nonValueAdded
        waste
      }
      onResponseId
      parentsTree
      isRepeatable
      _id
      log {
        type
        action
        component
        from
        to
        time {
          from {
            value
            unit
          }
          to {
            value
            unit
          }
        }
        dateTime
      }
      variant {
        variantId
        name
        position
      }
      advancedLabelSelection {
        name
        filter {
          includeAllLabels
          conditions {
            operator
            labelValue {
              value
              _id
              backgroundColor
              label {
                name
                _id
                labelValues {
                  ...labelValue
                }
              }
              description
            }
          }
        }
      }
      labelValues {
        ...labelValue
      }
      inputs {
        ...inputWithRecursive
      }
      assignedTools {
        tool {
          designation
          partNumber
          _id
        }
        quantity
        reserveAtStart
        associatedWith {
          designation
          partNumber
          _id
        }
      }
      assignedMaterials {
        material {
          code
          name
          _id
        }
        quantity
      }
      arConfiguration {
        markersType
        markersColor
        markersOnFinishingTask
        markersSize
        typeOfInput
        timerFinishTask
      }
    }
  }
  ${INPUT_WITH_RECURSIVE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
  ${INPUT_WITH_LOG}
  ${LABEL_VALUE}
`;

export const Q_TASK_TEMPLATE_DRAFTS = gql`
  query GetTaskTemplateDrafts($draftId: ID!, $taskIds: [ID!]!) {
    taskTemplateDrafts(issueTemplateDraft: $draftId, taskTemplates: $taskIds) {
      name
      manualId
      order
      estimatedDuration
      estimatedDurationDivided {
        valueAdded
        nonValueAdded
        waste
      }
      onResponseId
      parentsTree
      _id
      log {
        type
        action
        component
        from
        to
        time {
          from {
            value
            unit
          }
          to {
            value
            unit
          }
        }
        dateTime
      }
      variant {
        variantId
        name
        position
      }
      advancedLabelSelection {
        name
        filter {
          includeAllLabels
          conditions {
            operator
            labelValue {
              value
              _id
              backgroundColor
              label {
                name
                _id
                labelValues {
                  ...labelValue
                }
              }
              description
            }
          }
        }
      }
      labelValues {
        ...labelValue
      }
      inputs {
        ...inputWithRecursive
      }
      assignedTools {
        tool {
          designation
          partNumber
          _id
        }
        quantity
        reserveAtStart
        associatedWith {
          designation
          partNumber
          _id
        }
      }
      assignedMaterials {
        material {
          code
          name
          _id
        }
        quantity
      }
    }
  }
  ${INPUT_WITH_RECURSIVE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
  ${INPUT_WITH_LOG}
  ${LABEL_VALUE}
`;

export const Q_GET_MATERIALS = gql`
  query GetTaskMaterials($id: ID!) {
    taskInstance(where: { _id: $id }) {
      assignedMaterials {
        material {
          code
          name
          _id
        }
        quantity
      }
    }
  }
`;

export const Q_GET_TOOLS = gql`
  query GetTaskTools($id: ID!) {
    taskInstance(where: { _id: $id }) {
      assignedTools {
        tool {
          partNumber
          designation
          _id
        }
        quantity
        reserveAtStart
        associatedWith {
          designation
          partNumber
          _id
        }
      }
    }
  }
`;

export const M_RETAKE_TASK = gql`
  mutation RetakeTask($task: ID!) {
    retakeTask(where: { _id: $task }) {
      ...taskInstance
    }
  }
  ${INPUT}
  ${INPUT_WITH_LOG}
  ${INPUT_WITH_RECURSIVE}
  ${STATE_MACHINE_INSTANCE}
  ${TASK_INSTANCE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
`;
