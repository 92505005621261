import SkillExpertBlue from '@assets/images/skill-expert-blue.svg';
import SkillExpert from '@assets/images/skill-expert.svg';
import SkillHighBlue from '@assets/images/skill-high-blue.svg';
import SkillHigh from '@assets/images/skill-high.svg';
import SkillLowBlue from '@assets/images/skill-low-blue.svg';
import SkillLow from '@assets/images/skill-low.svg';
import SkillMediumBlue from '@assets/images/skill-medium-blue.svg';
import SkillMedium from '@assets/images/skill-medium.svg';
import SkillNaBlue from '@assets/images/skill-na-blue.svg';
import SkillNa from '@assets/images/skill-na.svg';
import SkillNoBlue from '@assets/images/skill-no-blue.svg';
import SkillNo from '@assets/images/skill-no.svg';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Popover } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useMemo, useState } from 'react';
import { SkillSubjectSubjectLevelsDatum } from 'src/gql/graphql';
import { SkillLevelOptions, allSkillLevels, getSkillLevelOptions } from '../../func/skills-levels';
import { styles } from './styles';

const skillIcons = {
  'skill-na': SkillNa,
  'skill-na-blue': SkillNaBlue,
  'skill-no': SkillNo,
  'skill-no-blue': SkillNoBlue,
  'skill-low': SkillLow,
  'skill-low-blue': SkillLowBlue,
  'skill-medium': SkillMedium,
  'skill-medium-blue': SkillMediumBlue,
  'skill-high': SkillHigh,
  'skill-high-blue': SkillHighBlue,
  'skill-expert': SkillExpert,
  'skill-expert-blue': SkillExpertBlue,
};

const useStyles = makeStyles(styles);

const Chip = ({
  level,
  subjectLevelsData,
  selected,
  hideText,
  smallFontSize,
}: {
  level: SkillLevelOptions;
  subjectLevelsData?: SkillSubjectSubjectLevelsDatum;
  selected?: boolean;
  hideText?: boolean;
  smallFontSize?: boolean;
}) => {
  const classes: ClassNameMap<string> = useStyles();

  const option = useMemo(() => {
    return getSkillLevelOptions(level, subjectLevelsData?.[level]);
  }, [level, subjectLevelsData]);

  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={skillIcons[`${selected ? option.selectedIcon : option.icon}`.replace('.svg', '')]}
        className={classes.skillLabelIcon}
      />
      {hideText ? null : (
        <span
          className={`${smallFontSize ? classes.skillLevelTitleSmall : classes.skillLevelTitle} ${selected && classes.selectedSkillLevelTitle}`}
        >
          {option.name}
        </span>
      )}
    </span>
  );
};

interface SkillLevelProps {
  level: SkillLevelOptions;
  withSelection?: boolean;
  onChange?: (value) => void;
  hideText?: boolean;
  subjectLevelsData?: SkillSubjectSubjectLevelsDatum;
  chosenSubjectLevels?: string[];
  smallFontSize?: boolean;
}

export const SkillLevelChip = ({
  level = 'NOT_APPLICABLE',
  withSelection,
  onChange,
  hideText = false,
  subjectLevelsData,
  chosenSubjectLevels,
  smallFontSize,
}: SkillLevelProps) => {
  const classes = useStyles();

  const filteredLevels = chosenSubjectLevels?.length
    ? allSkillLevels.filter((s) => chosenSubjectLevels.includes(s) || s === 'NOT_APPLICABLE')
    : allSkillLevels;

  const [anchor, setAnchor] = useState(null);

  const handleClick = (e) => {
    if (withSelection) {
      setAnchor(e.currentTarget);
    }
  };

  return (
    <>
      <div
        className={classes.skillLabelContainer}
        style={{
          width: withSelection ? '100%' : '',
          cursor: withSelection ? 'pointer' : 'auto',
          justifyContent: withSelection ? 'space-between' : 'center',
        }}
        onClick={handleClick}
        data-testid={`level-select`}
      >
        <Chip level={level} hideText={hideText} subjectLevelsData={subjectLevelsData} smallFontSize={smallFontSize} />
        {withSelection ? <KeyboardArrowDown className={classes.selectionIcon} /> : null}
      </div>
      <Popover
        open={!!anchor}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {filteredLevels.map((skillLevel, i) => (
          <div
            key={i}
            className={`${classes.skillLevelOption} ${skillLevel === level ? classes.skillLevelOptionSelected : ''}`}
            onClick={(ev) => {
              ev.preventDefault();
              onChange(skillLevel);
              setAnchor(null);
            }}
            data-testid={`level-${skillLevel}`}
          >
            <Chip
              level={skillLevel}
              selected={skillLevel === level}
              subjectLevelsData={subjectLevelsData}
              smallFontSize={smallFontSize}
            />
          </div>
        ))}
      </Popover>
    </>
  );
};
