import { makeStyles, withStyles } from '@mui/styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { styles } from './styles';
import { useMemo, useRef, useState } from 'react';
import { FilterDataType } from '../..';
import { ExpandMore } from '@mui/icons-material';
import { Popover, useTheme } from '@mui/material';
import { ListItem } from '@mui/material';

type SelectFilterConditionProps = {
  filterData: FilterDataType[];
  index: number;
  setFilterData: (val: FilterDataType[]) => void;
} & WithTranslation;

const conditions = ['equals', 'notEquals'];

const useStyles = makeStyles(styles);
const SelectFilterCondition: React.FC<SelectFilterConditionProps> = (props): JSX.Element => {
  const { t, setFilterData, filterData, index } = props;
  const classes = useStyles();
  const anchorRef = useRef();
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const filterCondition = useMemo(() => filterData[index].condition, [filterData[index]]);
  const theme = useTheme();

  const handleOpen = () => {
    setOpenPopover(true);
  };

  const handleClose = () => {
    setOpenPopover(false);
  };

  const handleClick = (item: string) => {
    const updatedFilterData = [...filterData];
    updatedFilterData[index] = { ...updatedFilterData[index], condition: item };
    setFilterData(updatedFilterData);
    setOpenPopover(false);
  };

  return (
    <>
      <div
        className={classes.mainContainer}
        style={{ borderColor: openPopover ? theme.palette.primary.main : '' }}
        ref={anchorRef}
        key={'timespan-current-selected'}
        onClick={handleOpen}
      >
        <span className={filterCondition?.length === 0 || !filterCondition ? classes.placeholder : ''}>
          {filterCondition?.length === 0 || !filterCondition ? t('operator') : t(filterCondition)}
        </span>
        <ExpandMore />
      </div>

      <Popover
        anchorEl={anchorRef.current}
        open={openPopover}
        classes={{ paper: classes.popoverContainer }}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {conditions.map((item) => (
          <ListItem
            key={`filter-option-${item}`}
            classes={{ root: filterCondition === item ? classes.listItemSelected : classes.listItem }}
            onClick={() => filterCondition !== item && handleClick(item)}
          >
            <span className={filterCondition === item && classes.selectedSortItem}>{t(`${item}`)}</span>
          </ListItem>
        ))}
      </Popover>
    </>
  );
};

export default withTranslation('translation')(withStyles(styles)(SelectFilterCondition));
