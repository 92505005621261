const translateNotification = (message: string, t): string => {
  //remove name that isn't to be translated
  const match = message.match(/"([^"]*)"/);

  const name = match ? match[1] : '';

  switch (true) {
    case /^Issue.*new approval.$/.test(message): {
      // rejected issue needs new approval
      const matchAccount = message.match(/by\s+(.+?)\s+and\s+needs/);
      const account = matchAccount ? matchAccount[1] : '';

      return t('issueRejected', { name: name, by: account });
    }
    case /^New Issue.*pending.$/.test(message):
      // new issue is pending
      return t('newIssueIsPending', { name: name });

    case /^Work.*started.$/.test(message):
      // work package was started
      return t('workPackageWasStarted', { name: name });

    case /^Issue.*finished.$/.test(message):
      // issue was finished
      return t('issueWasFinished', { name: name });

    case /^New Action.*created\.?$/.test(message):
      // new action was created
      return t('newActionWasCreated', { name: name });

    case /^The due date.*past.$/.test(message):
      // Due date is past
      return t('dueDatePast', { name: name });

    case /^Issue.*today.$/.test(message):
      // Due date is today
      return t('dueDateToday', { name: name });

    case /^Issue.*started.$/.test(message):
      // issue was started
      return t('issueWasStarted', { name: name });

    case /^Issue.*canceled.$/.test(message):
      // issue was canceled
      return t('issueCanceled', { name: name });

    case /^Task.*pending.$/.test(message):
      // task is pending
      return t('taskPending', { name: name });

    case /^Issue.*approval.$/.test(message):
      // issue needs approval
      return t('issueNeedsApproval', { name: name });

    case /^Site.*approval.$/.test(message):
      // Site needs approval
      return t('siteApproval', { name: name });

    case /^Element.*approval.$/.test(message):
      // Element needs approval
      return t('elementApproval', { name: name });

    case /mentioned.*comment/.test(message):
      // Element needs approval
      return t('commentMention');

    case /^Changes.*responsible.$/.test(message): {
      // Site/element approved
      const matchKeyword = message.match(/were\s+(\w+)\s+by/);
      const keyword = matchKeyword ? matchKeyword[1] : '';
      const byResponsible = t(keyword + 'ByResponsible');
      return t('siteApproved', { name: name, byResponsible: byResponsible });
    }

    case /Someone.*commented/.test(message):
      // Post commented
      return t('someoneCommentedYourPost');

    case /^You were mentioned in issue.*$/.test(message):
      return t('youWereMentioned', { name: name });
    default:
      return message;
  }
};

export default translateNotification;
