import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import store from 'src/reducer-manager';
import * as Sentry from '@sentry/browser';

interface DialogErrorBoundaryProps {
  fallbackComponent?: () => JSX.Element | React.ReactNode;
}

export function withDialogBoundary<P extends React.FC<any>>(WrappedComponent: P, data?: DialogErrorBoundaryProps) {
  const HOC = (props) => {
    return (
      <ErrorBoundary
        FallbackComponent={() => (data?.fallbackComponent ? <>{data.fallbackComponent}</> : <span></span>)}
        onError={(error, _info) => {
          console.error(error);
          Sentry.captureException(error);

          store.dispatch({
            type: 'SNACKBAR_NEW_MESSAGE',
            payload: {
              message: error.message,
              severity: 'error',
            },
          });
        }}
      >
        <WrappedComponent {...props} />
      </ErrorBoundary>
    );
  };

  HOC.displayName = WrappedComponent.name;

  return HOC as P;
}
