import { ReactNode, createContext, useContext, useState } from 'react';
import { SkillState } from '../types/skill.types';

export const skillContextInitialValue: SkillState = {
  skill: undefined,
  editedSkill: undefined,
  editMode: false,
  requiredRefetch: false,

  tabValue: 'information',

  skillLevels: [],
  selectedSkillLevels: [],
  updatedData: [],
  totalSkillLevels: 0,
  tableState: {
    limit: 25,
  },

  searchActive: false,
  openFilterPopover: false,
  filters: {},

  openAddUsers: false,

  skillLevelOrderBy: null,
};

const SkillContext = createContext<SkillState>(skillContextInitialValue);

interface SkillContextProviderProps {
  children: ReactNode;
  initialValue: SkillState;
}

export const SkillContextProvider = ({
  children,
  initialValue = skillContextInitialValue,
}: SkillContextProviderProps) => {
  const [skillState, setSkillState] = useState<SkillState>(initialValue);

  const updateSkillState = (newData: Partial<SkillState>) => {
    setSkillState((prevState) => ({ ...prevState, ...newData }));
  };

  return (
    <SkillContext.Provider value={{ ...skillState, updateSkillState, setSkillState }}>{children}</SkillContext.Provider>
  );
};

export const useSkillContext = () => {
  const context = useContext(SkillContext);
  if (!context) {
    throw new Error('useSkillContext must be used within a SkillContext');
  }
  return context;
};
