import * as React from 'react';
import { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { ColumnPopoverOption } from './interfaces/columns-popover.interfaces';
import ColumnsPopoverItem from './components/column-popover-item';

interface ContainerActiveProps {
  allColumns: ColumnPopoverOption[];
  provColumns: string[];
  setProvColumns: React.Dispatch<React.SetStateAction<string[]>>;
}

const ContainerActive: React.FC<ContainerActiveProps> = (props): JSX.Element => {
  const { provColumns, setProvColumns, allColumns } = props;
  const containerRef = useRef(null);

  const [, connectDrop] = useDrop({
    accept: 'column',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    hover: (draggedColumn: any) => {
      if (!provColumns.find((col) => col === draggedColumn.key)) {
        setProvColumns([...provColumns, draggedColumn.key]);
      }
    },
  });

  connectDrop(containerRef);

  return (
    <div ref={containerRef}>
      {provColumns.map((col) => {
        const column = allColumns.find((co) => co.key === col);

        return (
          <ColumnsPopoverItem
            column={column}
            key={column?.key}
            visible
            setHidden={() => {
              setProvColumns(provColumns.filter((c) => c !== column.key));
            }}
            provColumns={provColumns}
            setProvColumns={setProvColumns}
          />
        );
      })}
    </div>
  );
};

export default ContainerActive;
