import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    divider: {
      opacity: '0.3 !important',
      color: theme.palette.grey.light,
      borderWidth: 1,
      margin: 0,
    },
    checkboxIcon: {
      cursor: 'pointer',
    },
    popoverHeaderDiv: {
      display: 'flex',
      alignItems: 'center',
      padding: '8px 16px',
      height: '40px',
    },
  });
