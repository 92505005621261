import * as React from 'react';
import { AppBar, Toolbar, Box, IconButton, Typography, Button } from '@mui/material';
import { styles } from 'src/base/header/styles/header';
import { toggleSidenav } from 'src/base/root/root.redux';
import { useState, useEffect } from 'react';
import { clearSnackbar } from 'src/base/root/root.redux';
import { render as Breadcrumb } from 'src/base/breadcrumb';
import NotificationDropdown from 'src/base/notification-dropdown';
import { render as SiteDropdown } from 'src/base/site-dropdown';
import { render as ProfileDropdown } from 'src/base/profile-dropdown';
import { ExitToApp, HelpOutlineOutlined } from '@mui/icons-material';
import { useMainContainerContext } from '../main-container/context/main.container.context';
import { useTranslation } from 'react-i18next';
import { ConnectedProps } from 'react-redux';
import { updateLoggedUser } from '../login/login.redux';
import HelpMenu from './components/help.menu';
import makeStyles from '@mui/styles/makeStyles';
import GlartekDark from '@assets/images/glartek_dark.png';
import GlartekLight from '@assets/images/GlarVision.png';
import { useNavigate } from 'react-router-dom';
import { typedConnect } from 'src/utils/funcs';
import { ImpersonatedHeader } from './components/impersonated-header';

export type HeaderProps = {
  title: string;
} & ConnectedProps<typeof connector>;

const useStyles = makeStyles(styles);
const Header: React.FC<HeaderProps> = (props): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [savingPhoto, setSavingPhoto] = useState<boolean>(false);
  const [openProfileDropdown, setOpenProfileDropdown] = useState<boolean>(false);
  const [openHelpDropdown, setOpenHelpDropdown] = useState(null);
  const helperRef = React.useRef();
  const mainContainerState = useMainContainerContext();
  const { updateMainContainerState } = mainContainerState;
  const navigate = useNavigate();

  const delay = (ms: number): Promise<void> => new Promise((res) => setTimeout(res, ms));

  useEffect(() => {
    if (props.snackbarMessages.length) {
      void delay(1000).then((): void => {
        props.clearSnackbar();
      });
    }
  }, [JSON.stringify(props.snackbarMessages)]);

  const onBoardingStepTitle = (): JSX.Element => {
    let title = '';

    switch (props.loggedUser?.onBoarding?.step) {
      case 'build':
        title = t('issueTemplate');
        break;
      case 'createIssue':
      case 'createTasks':
        title = t('creatingOpenIssue');
        break;
      case 'execute':
      case 'finish':
        title = `${props.loggedUser?.onBoarding?.issueInstance?.glarID} - ${props.loggedUser?.onBoarding?.issueInstance?.name}`;
        break;
    }

    return <Typography className={classes.onBoardingTitle}>{title}</Typography>;
  };

  return (
    <AppBar enzyme-attr='component-header' id='header' classes={{ root: classes.appBar }}>
      <ImpersonatedHeader />
      <Toolbar classes={{ root: classes.toolbar }}>
        <Box display='flex' alignItems='center'>
          {!props.loggedUser?.onBoarding?.doingOnboarding ? (
            mainContainerState?.fullpage ? (
              mainContainerState?.headerLeftComponent
            ) : (
              <>
                <div
                  className={`hidden ${classes.headerBrand}`}
                  onClick={() => {
                    navigate('/');
                  }}
                >
                  <span className={classes.spanImg}>
                    <img
                      data-testid={`logo-${
                        props.themeSelected === 'dark'
                          ? props.tenantInfo?.resources?.logo_dark?.url || 'default'
                          : props.tenantInfo?.resources?.logo_light?.url || 'default'
                      }`}
                      src={
                        props.themeSelected === 'dark'
                          ? props.tenantInfo?.resources?.logo_dark?.url || GlartekDark
                          : props.tenantInfo?.resources?.logo_light?.url || GlartekLight
                      }
                      height={30}
                      width={'auto'}
                      alt={'logo'}
                    />
                  </span>
                </div>
                <div>
                  <div className={'application-path'}>
                    {/* @ts-ignore */}
                    <Breadcrumb.view.default {...history} />
                  </div>
                </div>
              </>
            )
          ) : (
            onBoardingStepTitle()
          )}
        </Box>
        <Box display='flex' alignItems='center' justifyContent='end' className={`${classes.mlAuto}`}>
          {mainContainerState.fullpage && !props.loggedUser?.onBoarding?.doingOnboarding ? (
            mainContainerState?.headerRightComponent
          ) : (
            <>
              {!props.loggedUser?.onBoarding?.doingOnboarding ? (
                //@ts-ignore
                <NotificationDropdown {...props} />
              ) : (
                <Button
                  data-testid={'leave-tutorial-mode-button'}
                  variant={'outlined'}
                  classes={{ /* label: classes.leaveTutorialBtnLabel, */ root: classes.leaveTutorialBtn }}
                  onClick={() => updateMainContainerState({ openModalLeaveTutorial: true, startSubSteps: false })}
                >
                  <ExitToApp color={'error'} fontSize='small' />
                  <Typography color={'error'} className={classes.leaveTutorialBtnText}>
                    {t('leaveTutorialMode')}
                  </Typography>
                </Button>
              )}
              <IconButton
                data-testid={'help-menu-button'}
                style={{ padding: '8px' }}
                ref={helperRef}
                color={'primary'}
                onClick={() => setOpenHelpDropdown(helperRef.current)}
              >
                <HelpOutlineOutlined className={classes.helpDropdownIcon} />
              </IconButton>
              {!!props.loggedUser?.roles?.length && !!props.loggedUser?.roles[0].permissions && (
                <HelpMenu openHelpDropdown={openHelpDropdown} setOpenHelpDropdown={setOpenHelpDropdown} />
              )}
              {!mainContainerState.doingOnBoarding && (
                <>
                  <SiteDropdown.view.default {...props} />
                  <ProfileDropdown.view.default
                    {...props}
                    savingPhoto={savingPhoto}
                    open={openProfileDropdown}
                    setOpen={setOpenProfileDropdown}
                    setSavingPhoto={(p) => setSavingPhoto(p)}
                  />
                </>
              )}
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const connector = typedConnect(
  (state) => ({
    currentSite: state.accountReducer.currentSite,
    loggedUser: state.loginReducer.loggedUser,
    sidenavMinimized: state.rootReducer.sidenavMinimized,
    snackbarMessages: state.rootReducer.snackbarMessages,
    themeSelected: state.loginReducer.theme,
    tenantInfo: state.authenticationReducer.tenantInfo,
  }),
  {
    toggleSidenav,
    clearSnackbar,
    updateLoggedUser,
  },
);

export default connector(Header);
