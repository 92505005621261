import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {},
    divContainer: {
      width: '100%',
      overflow: 'auto',
    },
    input: {
      background: theme.palette.background.default,
      boxShadow: 'none !important',
      color: `${theme.palette.text.primary} !important`,
      transition: 'all 0.3s, height 1ms',
      height: '40px',
      width: '100%',
      alignItems: 'flex-start',
      display: 'flex',
      cursor: 'pointer',
      maxWidth: '100%',
      padding: '8px',
      border: `1px solid ${theme.palette.grey.light}`,
      borderRadius: '5px',
      overflow: 'hidden',
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.text.primary,
      },
      '&:focus': {
        color: theme.palette.common.white,
        border: '1px solid',
      },
    },
    inputError: {
      background: theme.palette.background.default,
      boxShadow: 'none !important',
      color: `${theme.palette.text.primary} !important`,
      transition: 'all 0.3s, height 1ms',
      height: '40px',
      width: '100%',
      alignItems: 'flex-start',
      display: 'flex',
      cursor: 'pointer',
      maxWidth: '100%',
      padding: '8px',
      border: `1px solid ${theme.palette.error.main}`,
      borderRadius: '5px',
      overflow: 'hidden',
      '&:hover': {
        border: `1px solid ${theme.palette.error.main}`,
        color: theme.palette.text.primary,
      },
      '&:focus': {
        color: theme.palette.common.white,
        border: '1px solid',
      },
    },
    inputDisabled: {
      background: theme.palette.background.default,
      boxShadow: 'none !important',
      color: `${theme.palette.text.primary} !important`,
      height: '40px',
      width: '100%',
      alignItems: 'flex-start',
      display: 'flex',
      maxWidth: '100%',
      padding: '8px',
      border: `1px solid ${theme.palette.grey.light}`,
      borderRadius: '5px',
      overflow: 'hidden',
    },
    relative: {
      marginRight: 8,
      position: 'relative',
    },
    placeholder: {
      color: theme.palette.text.secondary,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingRight: '10px',
    },
    popoverContainer: {
      width: '280px',
      maxHeight: '250px',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    expandIcon: {
      position: 'absolute',
      right: 0,
      top: 8,
    },
    expandIconActive: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    expandIconDisabled: {
      color: theme.palette.grey.light,
    },
    searchInput: {
      marginTop: 8,
      padding: '0 16px',
      fontSize: 14,
      '& input': {
        padding: '0 !important',
      },
    },
    searchIcon: {
      opacity: '0.8 !important',
      color: theme.palette.grey.light,
    },
    activeBorder: {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    itemText: {
      margin: 0,
      '& >span': {
        marginLeft: 8,
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontFamily: 'Open sans',
        wordBreak: 'break-all',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        maxWidth: '100%',
      },
    },
    listItem: {
      padding: '2 12 2 12',
      fontFamily: 'Open sans',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    itemDisabled: {
      fontFamily: 'Open Sans',
      cursor: 'not-allowed',
      color: theme.palette.grey.main,
      '&:hover': {
        backgroundColor: 'white',
      },
    },
    selectedItem: {
      color: theme.palette.text.primary,
      fontFamily: 'Open sans',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingRight: '10px',
    },
    divider: {
      opacity: '0.3 !important',
      color: theme.palette.grey.light,
      borderWidth: 1,
    },
    noResults: {
      display: 'block',
      fontSize: 14,
      fontWeight: 500,
      width: '100%',
      padding: '8px 0px 12px 0px',
      color: theme.palette.grey.main,
      textAlign: 'center',
    },
  });
