import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    popoverContainer: {
      height: '100%',
      left: 'unset !important',
      right: '0',
      top: '0 !important',
      width: '370px',
      maxHeight: '100% !important',
      display: 'flex',
      flexDirection: 'column',
    },
    headerInfo: {
      padding: '25px',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.13)',
    },
    closeIcon: {
      /* color: theme.palette.text.secondary, */
      marginLeft: 'auto',
    },
    title: {
      fontFamily: 'Poppins',
      fontSize: '20px',
      fontWeight: 500,
      marginTop: '-20px',
    },
    subTitle: {
      fontFamily: 'Open Sans',
      color: theme.palette.text.secondary,
      marginTop: '10px',
    },
    body: {
      padding: '25px',
      height: 'inherit',
    },
    footer: {
      padding: '25px',
      textAlignLast: 'right',
      '&>div': {
        marginBottom: 0,
        marginRight: 0,
      },
    },
    note: {
      fontFamily: 'Open Sans',
      fontSize: '10px',
      color: theme.palette.text.disabled,
      marginTop: '40px',
      display: 'flex',
      justifyContent: 'center',
    },
    divCustom: {
      display: 'flex',
      gap: '15px',
      borderRadius: '5px',
      paddingTop: '10px',
      paddingBottom: '5px',
      paddingLeft: '40px',
      paddingRight: '40px',
    },
  });
