import * as React from 'react';

interface IFileOrderIconProps {
  index: number;
  className?: string;
}

const FileOrderIcon: React.FC<IFileOrderIconProps> = ({ index, className }) => {
  const orderText = index + 1;
  return (
    <div className={className}>
      <div style={{ fontSize: orderText > 9 ? '11px' : '14px' }}>{orderText}</div>
    </div>
  );
};

export default FileOrderIcon;
