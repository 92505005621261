import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    dialogTitleText: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      letterSpacing: '0.15px',
      color: theme.palette.text.primary,
    },
    children: {
      width: 700,
      height: 500,
    },
    grantCameraAccesContainer: {
      height: 350,
      width: '100%',
      display: 'flex',
    },
    grantCameraAccessText: {
      margin: 'auto',
    },
  });
