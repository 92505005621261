import * as React from 'react';
import { styles } from 'src/modules/settings/components/appearance/components/dialog-reset-changes/styles';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DefaultButton from 'src/utils/components/default-button';
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap } from '@mui/styles/withStyles';

type DialogResetChangesProps = {
  onClose: () => void;
  onReset: () => void;
  subTitle?: string;
};

const useStyles = makeStyles(styles);

const DialogResetChanges: React.FC<DialogResetChangesProps> = (props) => {
  const { onClose, onReset, subTitle } = props;
  const classes: ClassNameMap<string> = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog classes={{ paper: classes.dialog }} data-testid={'dialog-reset-changes'} open={true} onClose={onClose}>
      <div className={classes.title}>{t('resetChanges')}</div>
      <div className={classes.subTitle}>{subTitle ?? t('resetChangesSubTitle')}</div>
      <div className={classes.footer}>
        <DefaultButton onClick={onClose} discard id={`dialog-reset-cancel-button`}>
          {t('cancel')}
        </DefaultButton>
        <DefaultButton onClick={onReset} success id={`dialog-reset-reset-button`}>
          {t('reset')}
        </DefaultButton>
      </div>
    </Dialog>
  );
};

export default DialogResetChanges;
