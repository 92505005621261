import { useEffect, useRef } from 'react';

const useComponentDidUpdate = (effect: () => any, dependencies: any[]) => {
  const hasMounted = useRef(false);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      return () => null;
    }
    const ret = effect();

    if (ret && typeof ret === 'function') {
      return ret;
    }
  }, dependencies);
};

export default useComponentDidUpdate;
