import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SnackbarProps {
  open?: boolean;
  type?: string;
  message?: string;
  notHide?: boolean;
  position?: {
    vertical?: string;
    horizontal?: string;
  };
}

export interface UIStateProps {
  snackbar?: SnackbarProps;
  contactUsDialog?: boolean;
  renderModeModal?: boolean;
  requestedMode?: number | null;
  errorMessage?: string;
  inputError?: string;
  userInviteSent?: boolean;
}

const defaultState: UIStateProps = {
  snackbar: {
    open: false,
    type: 'info',
    message: '',
    position: {
      vertical: 'bottom',
      horizontal: 'center',
    },
  },
  contactUsDialog: false,
  renderModeModal: false,
  requestedMode: null,
  errorMessage: '',
  inputError: '',
  userInviteSent: false,
};

// Create a slice of the UI state
const roomUiSlice = createSlice({
  name: 'roomUi',
  initialState: defaultState,
  reducers: {
    setSnackbar(state, action: PayloadAction<SnackbarProps>) {
      state.snackbar = action.payload;
    },
    setContactUsDialog(state, action: PayloadAction<boolean>) {
      state.contactUsDialog = action.payload;
    },
    setRenderModeModal(state, action: PayloadAction<boolean>) {
      state.renderModeModal = action.payload;
    },
    setRequestedMode(state, action: PayloadAction<number | null>) {
      state.requestedMode = action.payload;
    },
    sendErrorMessage(state, action: PayloadAction<{ errorMessage: string; inputError: string }>) {
      state.errorMessage = action.payload.errorMessage;
      state.inputError = action.payload.inputError;
    },
    clearErrorMessage(state) {
      state.errorMessage = '';
      state.inputError = '';
    },
    notifyInviteSent(state) {
      state.userInviteSent = true;
    },
    resetInvite(state) {
      state.userInviteSent = false;
    },
  },
});

export const {
  setSnackbar,
  setContactUsDialog,
  setRenderModeModal,
  setRequestedMode,
  sendErrorMessage,
  clearErrorMessage,
  notifyInviteSent,
  resetInvite,
} = roomUiSlice.actions;

export default roomUiSlice.reducer;

export interface UIDispatchProps {
  setSnackbar?: typeof setSnackbar;
  clearErrorMessage?: typeof clearErrorMessage;
  sendErrorMessage?: typeof sendErrorMessage;
  setContactUsDialog?: typeof setContactUsDialog;
  setRenderModeModal?: typeof setRenderModeModal;
  setRequestedMode?: typeof setRequestedMode;
  notifyInviteSent?: typeof notifyInviteSent;
  resetInvite?: typeof resetInvite;
}
