import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

export const styles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      width: '100%',
    },
    insideForm: {
      display: 'inline-block',
      width: '100%',
    },
    infoLabel: {
      fontSize: theme.typography.fontSize,
      color: theme.palette.primary.main,
    },
    tr: {
      display: 'flex',
      width: '100%',
      overflow: 'auto',
    },
    rowName: {
      width: 'calc(100% - 0px)',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: theme.palette.text.primary,
      fontSize: '10px',
      lineHeight: '16px',
      fontWeight: 400,
      fontFamily: '"Poppins", sans-serif;',
      textAlign: 'start',
    },
    spanDownload: {
      paddingLeft: '5px',
      paddingRight: '5px',
      cursor: 'pointer',
      height: '24px',
    },
    loadingDiv: {
      width: '100%',
    },
    loadingDivMinHeight: {
      minHeight: '200px',
      width: '100%',
    },
    img: {
      width: '107px',
      height: '112px',
      objectFit: 'cover',
      borderRadius: '4px',
    },
    imageTitle: {
      display: 'flex',
      marginTop: '5px',
      maxWidth: '107px',
      alignItems: 'center',
    },
    divContainerImages: {
      display: 'flex',
      flexWrap: 'wrap',
      overflowY: 'auto',
      cursor: 'pointer',
    },
    divImages: {
      height: 'min-content',
      marginTop: '12px',
      marginLeft: '19px',
      marginBottom: '14px',
    },
    divImageNotFound: {
      width: '107px',
      height: '112px',
      textAlign: 'center',
      fontFamily: 'Open Sans',
      backgroundColor: theme.palette.background.background0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconNotFound: {
      width: '80px',
      height: '80px',
      display: 'block',
      margin: 'auto',
      marginTop: '25px',
    },
    iconTypeFile: {
      width: '20px',
      height: '20px',
      marginRight: '3px',
    },
    videoIcon: {
      position: 'absolute',
      left: 'calc(50% - 20px)',
      color: theme.palette.common.white,
      fontSize: '40px',
      top: 'calc(50% - 20px)',
    },
    attachIcon: {
      fontSize: '53px',
      color: theme.palette.primary.main,
    },
    divSize: {
      fontSize: '11px',
      fontWeight: 400,
      fontFamily: '"Poppins", sans-serif;',
    },
    showComment: {
      /* position: 'absolute', */
      transform: 'translate(0px, -37px)',
      /* bottom: '30px', */
      width: '107px',
      paddingLeft: '5px',
      paddingRight: '5px',
      backgroundColor: 'rgba(136, 137, 154, 0.78)',
      color: theme.palette.background.default,
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      cursor: 'inherit',
      fontSize: '11px',
      fontFamily: 'Open Sans',
      height: '20px',
      paddingTop: '2px',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.common.white,
      position: 'relative',
      minHeight: '200px',
    },
    sizeAndOrder: {
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      position: 'relative',
    },
    orderIcon: {
      backgroundColor: theme.palette.secondary.main,
      height: '19px',
      width: '19px',
      borderRadius: '34px',
      color: theme.palette.background.default,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '-7px',
      marginRight: '-4px',
    },
    sizeText: {
      marginLeft: '0px',
      marginRight: 'auto',
      fontSize: '10px',
      lineHeight: '16px',
      fontWeight: 400,
      font: 'Open Sans',
    },
    reorderSeparator: {
      width: '4px',
      height: '112px',
      backgroundColor: theme.palette.primary.main,
      transform: 'translateX(-14x)',
      borderRadius: '8px',
      position: 'absolute',
      zIndex: 1,
    },
    filePreviewDiv: { gap: '16px', display: 'flex', flexDirection: 'column' },
  }),
);
