import { APOLLO_CLIENT } from 'config/apollo.config';
import { Dispatch } from 'redux';
import { IssueInstance, IssueInstanceWhereInput } from 'src/gql/graphql';
import * as queries from 'src/modules/issue-planning/issue.planning.queries';
import { getErrorObject } from 'src/utils/funcs';

export default (): void => null;

export const getIssuesToApproval =
  (where: IssueInstanceWhereInput, limit: number, skip: number, orderBy) =>
  async (
    dispatch: Dispatch,
  ): Promise<{ issues: IssueInstance[]; issuesCount: number } & { graphQLErrors?: string }> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          where,
          limit,
          skip,
          orderBy,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_ISSUES_TO_APPROVAL,
      });

      return {
        issues: response.data.issues,
        issuesCount: response.data.issuesCount,
      };
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };
