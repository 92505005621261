import { Add, Delete, DeleteOutline } from '@mui/icons-material';
import { Dialog, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Fragment, Ref, useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { styles } from 'src/utils/components/table-field/styles';
import DefaultButton from '../default-button/default-button';

const initState = [
  {
    name: 'Column 1',
    type: 'string',
    rows: { string: [''] },
  },
];

const initTemporary = {
  value: '',
  column: null,
  row: null,
};

interface Row {
  string?: (string | null)[];
  [x: string]: any;
}

interface Column {
  type: string;
  rows: Row;
  name: string | null;
}

interface TableFieldProps {
  classes: Record<string, string>;
  disabled: boolean;
  editableOptions: boolean;
  callback: (data: any) => void;
  value: Column[];
  t: (st: string) => string;
  innerRef: Ref<HTMLDivElement>;
}

let colscols = [];

const TableField = (props: TableFieldProps): JSX.Element => {
  const { classes, disabled, editableOptions, callback } = props;
  const [modalDeleteColumn, setModalDeleteColumn] = useState<number>(null);
  const [modalDeleteRow, setModalDeleteRow] = useState<number>(null);
  const [temporaryValue, setTemporaryValue] = useState<any>(initTemporary);
  const [columns, setColumns] = useState<any>([]);
  const [editing, setEditing] = useState<boolean>(false);
  const ref = useRef();
  const theme = useTheme();

  useEffect(() => {
    colscols = columns;
  }, []);

  const handleClick = (e: MouseEvent): void => {
    if (!disabled || editableOptions) {
      //@ts-ignore
      if (ref?.current && ref?.current.contains(e.target)) {
        setEditing(true);
      } else if (editing) {
        setEditing(false);
        //callback(columns);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleClick, false);
    return (): void => {
      window.removeEventListener('mousedown', handleClick, false);
    };
  }, [ref, columns, editableOptions, editing, disabled]);

  useEffect(() => {
    return () => {
      if (callback && colscols.length > 0 && JSON.stringify(colscols) !== JSON.stringify(props.value)) {
        callback(colscols);
      }
    };
  }, []);

  useEffect(() => {
    const newCols = props.value.map((col) => ({
      ...col,
      rows: {
        ...col.rows,
        [col.type]: !col.rows[col.type] || !col.rows[col.type].length ? [null] : col.rows[col.type],
      },
    }));
    if (props.value.length && JSON.stringify(newCols) !== JSON.stringify(columns)) {
      setColumns(newCols);
    } else if (!columns.length && JSON.stringify(initState) !== JSON.stringify(columns)) {
      setColumns(initState);
    }
  }, [props.value]);

  const addLine = () => {
    const cols = columns.map((column) => ({
      ...column,
      rows: { ...column.rows, [column.type]: [...column.rows[column.type], null] },
    }));
    setColumns(cols);
  };

  return (
    <div ref={ref} style={{ marginBottom: '8px' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {editableOptions && (
          <DefaultButton
            data-testid={'add-column-btn'}
            variant={'edit'}
            className={classes.addColumnBtn}
            onClick={() => {
              const cols = [
                ...columns,
                {
                  name: 'Column ' + (columns.length + 1),
                  type: 'string',
                  rows: { string: columns[0].rows[columns[0].type] },
                },
              ];
              setColumns(cols);
              callback(cols);
            }}
            label={
              <div style={{ display: 'flex', alignItems: 'center', color: theme.palette.primary.main }}>
                <Add />
                <Typography>{props.t('addColumn')}</Typography>
              </div>
            }
          />
        )}
        <table className={classes.table}>
          {columns.map((column: Column, i: number) => (
            <tr key={i} style={{ display: 'table-cell', width: '-webkit-fill-available' }}>
              <th id={`th-${i}`} data-testid={`th-${i}`} className={classes.thHeader}>
                <div style={{ display: 'flex', width: 'inherit' }}>
                  <Tooltip title={column.name || ''}>
                    <TextField
                      placeholder={`${props.t('column')} ${i + 1}`}
                      variant={'standard'}
                      classes={{ root: classes.textField }}
                      InputProps={{
                        classes: { input: editableOptions ? classes.textFieldInput : classes.textFieldInputBold },
                      }}
                      value={column.name}
                      onChange={(e) => {
                        const cols = columns.map((col: Column, index: number) =>
                          i === index ? { ...col, name: e.target.value } : col,
                        );
                        setColumns(cols);
                        colscols = cols;
                      }}
                      onBlur={(e) => {
                        const cols = columns.map((col: Column, index: number) =>
                          i === index ? { ...col, name: e.target.value } : col,
                        );
                        callback(cols);
                      }}
                      disabled={!editableOptions}
                    />
                  </Tooltip>
                  {editableOptions && columns.length > 1 ? (
                    <DeleteOutline
                      classes={{ root: classes.iconDeleteColumn }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (column.name) {
                          setModalDeleteColumn(i);
                        } else {
                          setColumns((prev) => prev.filter((_column, index) => i !== index));
                          callback(columns.filter((_column, index) => i !== index));
                        }
                      }}
                    />
                  ) : null}
                </div>
              </th>
              {column.rows[column.type].map((row, index) => (
                <Fragment key={index}>
                  <td
                    key={index}
                    id={`td-${i}${index}`}
                    data-testid={`td-${i}${index}`}
                    className={disabled ? classes.tdTable : classes.tdTableActive}
                    style={
                      i === 0 &&
                      index === 0 &&
                      i === columns.length - 1 &&
                      index === column.rows[column.type].length - 1
                        ? { borderRadius: '3px' }
                        : column.rows[column.type].length - 1 === 0 && i === columns.length - 1
                          ? { borderTopRightRadius: '3px', borderBottomRightRadius: '3px' }
                          : index === 0 && i === columns.length - 1
                            ? { borderTopRightRadius: '3px', borderBottom: 0 }
                            : column.rows[column.type].length - 1 === 0 && i === 0
                              ? { borderTopLeftRadius: '3px', borderBottomLeftRadius: '3px', borderRight: 0 }
                              : i === 0 && index === 0
                                ? { borderTopLeftRadius: '3px', borderBottom: 0, borderRight: 0 }
                                : i === columns.length - 1 && index === column.rows[column.type].length - 1
                                  ? { borderBottomRightRadius: '3px' }
                                  : index === column.rows[column.type].length - 1 && i === 0
                                    ? { borderBottomLeftRadius: '3px', borderRight: 0 }
                                    : index !== column.rows[column.type].length - 1 &&
                                        index !== 0 &&
                                        i === columns.length - 1
                                      ? { borderBottom: 0 }
                                      : index === column.rows[column.type].length - 1 &&
                                          i !== 0 &&
                                          i !== columns.length - 1
                                        ? { borderRight: 0 }
                                        : { borderBottom: 0, borderRight: 0 }
                    }
                  >
                    {editableOptions ? (
                      <Typography style={{ color: theme.palette.text.disabled }}>{props.t('addText')}</Typography>
                    ) : (
                      <Tooltip
                        title={
                          temporaryValue.value !== undefined &&
                          i === temporaryValue.column &&
                          index === temporaryValue.row
                            ? temporaryValue.value
                            : row
                              ? row
                              : ''
                        }
                      >
                        <TextField
                          classes={{ root: classes.textField }}
                          id={`input-${i}${index}`}
                          variant={'standard'}
                          data-testid={`input-${i}${index}`}
                          InputProps={{ classes: { input: classes.textFieldInput }, disableUnderline: true }}
                          value={
                            temporaryValue.value !== undefined &&
                            i === temporaryValue.column &&
                            index === temporaryValue.row
                              ? temporaryValue.value
                              : row
                                ? row
                                : ''
                          }
                          type={column.type}
                          onBlur={(e) => {
                            callback(
                              columns.map((colum, iCol) => ({
                                ...colum,
                                rows: {
                                  [colum.type]: colum.rows[colum.type].map((rw, ind) =>
                                    ind === index && i === iCol ? e.target.value : rw,
                                  ),
                                },
                              })),
                            );
                            setColumns((prev) =>
                              prev.map((colum, iCol) => ({
                                ...colum,
                                rows: {
                                  [colum.type]: colum.rows[colum.type].map((rw, ind) =>
                                    ind === index && i === iCol ? e.target.value : rw,
                                  ),
                                },
                              })),
                            );
                            setTemporaryValue(initTemporary);
                          }}
                          onChange={(e) => {
                            setTemporaryValue({ value: e.target.value, column: i, row: index });
                          }}
                          disabled={disabled}
                          placeholder={props.t('addText')}
                        />
                      </Tooltip>
                    )}
                  </td>
                </Fragment>
              ))}
            </tr>
          ))}

          {disabled || !columns.length ? null : (
            <tr style={{ display: 'table-cell', width: '-webkit-fill-available', whiteSpace: 'nowrap' }}>
              <th className={classes.thHeader} />
              {columns[0].rows[columns[0].type].map((_r, index) => (
                <td key={index} className={classes.tdDeleteTable}>
                  <Delete
                    id={`delete-${index}`}
                    data-testid={`delete-${index}`}
                    classes={{
                      root:
                        columns[0].rows[columns[0].type].length === 1
                          ? classes.iconDeleteRowDeleted
                          : classes.iconDeleteRow,
                    }}
                    onClick={() => {
                      if (columns[0].rows[columns[0].type].length > 1) {
                        if (columns.filter((col) => !!col.rows[col.type][parseInt(`${index}`)]).length) {
                          setModalDeleteRow(index);
                        } else {
                          setColumns((prev) =>
                            prev.map((column) => ({
                              ...column,
                              rows: { [column.type]: column.rows[column.type].filter((_row, ind) => ind !== index) },
                            })),
                          );
                        }
                      }
                    }}
                  />
                </td>
              ))}
            </tr>
          )}
        </table>
      </div>
      {disabled ? null : (
        <DefaultButton
          className={classes.addLineBtn}
          data-testid={`add-line-button`}
          variant={'discard'}
          onClick={addLine}
        >
          <div style={{ display: 'contents' }}>
            <Add />
            {props.t('addLine')}
          </div>
        </DefaultButton>
      )}
      {!!modalDeleteColumn ? (
        <Dialog
          ref={props.innerRef}
          open={!!modalDeleteColumn}
          onClose={(): void => {
            setModalDeleteColumn(null);
          }}
        >
          <div className={classes.dialogContent}>
            <div className={classes.divText}>{`${props.t('deleteColumn')}?`}</div>
            <div className={classes.divBtnsModal}>
              <DefaultButton
                data-testid={'buttonNoModalColumn'}
                variant={'discard'}
                onClick={(): void => {
                  setModalDeleteColumn(null);
                }}
              >
                {props.t('no')}
              </DefaultButton>
              <DefaultButton
                data-testid={'buttonDeleteModalColumn'}
                variant={'delete'}
                onClick={(): void => {
                  callback(columns.filter((_column, index) => modalDeleteColumn !== index));
                  setColumns((prev) => prev.filter((_column, index) => modalDeleteColumn !== index));
                  setModalDeleteColumn(null);
                }}
              >
                {props.t('delete')}
              </DefaultButton>
            </div>
          </div>
        </Dialog>
      ) : null}
      {modalDeleteRow !== null ? (
        <Dialog
          open={modalDeleteRow !== null}
          onClose={(): void => {
            setModalDeleteRow(null);
          }}
        >
          <div className={classes.dialogContent}>
            <div className={classes.divText}>{`${props.t('deleteLine')}?`}</div>
            <div className={classes.divBtnsModal}>
              <DefaultButton
                data-testid={'buttonNoModalRow'}
                variant={'discard'}
                onClick={(): void => {
                  setModalDeleteRow(null);
                }}
              >
                {props.t('No')}
              </DefaultButton>
              <DefaultButton
                data-testid={'buttonDeleteModalRow'}
                variant={'success'}
                onClick={(): void => {
                  setColumns((prev) =>
                    prev.map((column) => ({
                      ...column,
                      rows: { [column.type]: column.rows[column.type].filter((_row, ind) => ind !== modalDeleteRow) },
                    })),
                  );
                  setModalDeleteRow(null);
                }}
              >
                {props.t('Delete')}
              </DefaultButton>
            </div>
          </div>
        </Dialog>
      ) : null}
    </div>
  );
};

const mapStateToProps = (): object => ({});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default compose<any>(
  withTranslation('translation'),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(TableField);
