import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    '@global': {
      '.MuiInput-underline:before': {
        borderBottom: 'none',
        borderBottomStyle: 'none !important',
      },
    },
    tdTable: {
      height: '40px',
      border: `1px solid ${theme.palette.grey.light}`,
      padding: '8px',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
    tdTableActive: {
      height: '40px',
      border: `1px solid ${theme.palette.grey.light}`,
      padding: '8px',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
    tdDeleteTable: {
      height: '40px',
      padding: '8px',
      display: 'flex',
      alignItems: 'center',
    },
    table: {
      fontSize: '14px',
      minHeight: '75px',
      fontFamily: 'Open Sans',
      width: '100%',
      fontWeight: 400,
      borderCollapse: 'collapse',
      borderSpacing: '0 !important',
      tableLayout: 'auto',
    },
    addColumnTd: {
      height: '40px',
      border: `1px solid ${theme.palette.text.disabled}`,
      display: 'block',
      borderRadius: '3px',
    },
    tableAddColumn: {
      fontSize: '14px',
      minHeight: '75px',
      width: '116px',
      fontFamily: 'Open Sans',
      fontWeight: 400,
      marginLeft: '10px',
      borderSpacing: '0 !important',
      cursor: 'pointer',
    },
    textFieldInput: {
      fontSize: '14px',
      width: '100%',
      fontFamily: 'Open Sans',
      fontWeight: 400,
      color: `${theme.palette.text.primary} !important`,
      textOverflow: 'ellipsis',
    },
    textFieldInputBold: {
      fontSize: '14px',
      width: '100%',
      fontFamily: 'Open Sans',
      fontWeight: 600,
      textOverflow: 'ellipsis',
      '-webkit-text-fill-color': `${theme.palette.text.primary} !important`,
    },
    textFieldInputAddColumn: {
      fontSize: '14px',
      width: '100%',
      fontFamily: 'Open Sans',
      fontWeight: 400,
      color: `${theme.palette.text.secondary} !important`,
      textOverflow: 'ellipsis',
    },
    textField: {
      width: '100%',
    },
    iconDeleteColumn: {
      color: theme.palette.error.main,
      marginLeft: '5px',
      marginRight: '5px',
      display: 'none',
      cursor: 'pointer',
    },
    iconDeleteRow: {
      color: theme.palette.grey.light,
      marginLeft: '5px',
      marginRight: '5px',
      cursor: 'pointer',
    },
    iconDeleteRowDeleted: {
      visibility: 'hidden',
      marginLeft: '5px',
      marginRight: '5px',
    },
    iconDeleteRowDisabled: {
      color: theme.palette.text.disabled,
      marginLeft: '5px',
      marginRight: '5px',
    },
    thHeader: {
      marginBottom: '5px',
      minHeight: '31.63px',
      display: 'block',
      fontFamily: 'Open Sans',
      fontWeight: 400,
      '&:hover': {
        '& > div': {
          '& > svg': {
            display: 'block',
          },
        },
      },
    },
    dialogContent: {
      width: '310px',
    },
    divHeader: {
      display: 'flex',
    },
    divText: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      textAlign: 'center',
      marginLeft: '40px',
      marginRight: '40px',
      marginTop: '15px',
    },
    divBtnsModal: {
      display: 'flex',
      justifyContent: 'space-around',
      marginTop: '20px',
      marginBottom: '10px',
    },
    addColumnBtn: {
      width: 'fit-content',
      alignSelf: 'flex-end',
      padding: 0,
    },
    addLineBtn: {
      marginTop: '8px',
      width: 'fit-content',
      padding: '6px 8px',
    },
  });
