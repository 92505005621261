import * as React from 'react';
import { Menu, Button, IconButton } from '@mui/material';
import { useState, useEffect } from 'react';

// Custom material-ui dropdown
const CustomMenu = (props: any) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  useEffect(() => {}, [props.setOpen]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    props?.handleClick?.();
    if (props.changeOpen) props.changeOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (props.changeOpen) props.changeOpen(false);
  };

  return (
    <div>
      {props.iconBtn ? (
        <IconButton
          size={'small'}
          className={props.classes?.button}
          color={'primary'}
          aria-haspopup='true'
          onClick={handleClick}
        >
          {props.button}
        </IconButton>
      ) : (
        <Button className={props.classes?.button} color={'primary'} aria-haspopup='true' onClick={handleClick}>
          {props.button}
        </Button>
      )}
      <Menu
        id={props.id}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.children}
      </Menu>
    </div>
  );
};

export default CustomMenu;
