import { APOLLO_CLIENT } from 'config/apollo.config';
import * as mutations from './sessions.queries';
import { Session } from 'src/interfaces/remote-assistance/session';
import { CustomError } from 'src/interfaces/remote-assistance/custom-error';
import { getErrorObject } from 'src/utils/funcs';

export const getSessionByToken = async (token: string): Promise<[Session | null, CustomError | null]> => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      mutation: mutations.M_GET_SESSION_BY_TOKEN,
      fetchPolicy: 'no-cache',
      variables: {
        token: token,
      },
    });

    const { getSessionByToken } = response.data;

    if (getSessionByToken.__typename === 'Session') {
      return [getSessionByToken as Session, null];
    } else if (getSessionByToken.__typename === 'CustomError') {
      return [null, getSessionByToken as CustomError];
    } else {
      throw new Error('Unexpected response type');
    }
  } catch (error) {
    const errMsg = getErrorObject(error, '');
    return [null, { message: errMsg } as unknown as CustomError];
  }
};
