import { MutableRefObject, useCallback } from 'react';
import { ThreeData } from '..';

interface IRefreshRenderHookArgs {
  threeRef: MutableRefObject<ThreeData>;
  containerRef: MutableRefObject<HTMLDivElement>;
  fullscreen: boolean;
}

const useRefreshRender = ({ threeRef, containerRef, fullscreen }: IRefreshRenderHookArgs) =>
  useCallback(() => {
    const { renderer, camera, scene } = threeRef.current;
    if (!renderer || !camera || !containerRef.current || !scene) {
      return;
    }

    if (fullscreen) {
      const height = window.innerHeight;
      const width = window.innerWidth;
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    } else {
      const { height, width } = containerRef.current.parentElement.getBoundingClientRect();
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    }

    renderer.render(scene, camera);

    threeRef.current = { ...threeRef.current, renderer, camera };
  }, [fullscreen]);

export default useRefreshRender;
