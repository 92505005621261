import React, { useCallback, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Size } from 'react-virtualized';
import { File } from 'src/gql/graphql';
import { downloadFile } from 'src/modules/file-storage/file-storage.redux';
import { Swiper as SwiperRef } from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import NavigationArrow from '../NavigationArrow';
import { styles } from '../styles';
import DraggableMedia from './DraggableMedia';
import { styles as mediaStyles } from './styles';

interface IMediaCarouselProps extends ConnectedProps<typeof connector> {
  showInfo?: boolean;
  showClose?: boolean;
  imageSize?: Size;
  currentFiles: File[];
  allowReorder?: boolean;
  allowEdit?: boolean;
  allowNavigation?: boolean;
  setOpenCarousel: React.Dispatch<React.SetStateAction<object>>;
  setCurrentFiles: React.Dispatch<React.SetStateAction<object>>;
  onDeleteFile: (file: File) => void;
  setGallery?: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      initialTab: number;
    }>
  >;
  progressMap?: Map<string, number>;
  showDelete?: boolean;
  showDownload?: boolean;
}

const MediaCarousel: React.FC<IMediaCarouselProps> = (props) => {
  const { currentFiles, allowReorder, setOpenCarousel, setCurrentFiles } = props;
  const [slidesPerView, setSlidesPerView] = useState<number>(5);
  const [dragFile, setDragFile] = useState<null | File>(null);
  const [candidateFileOrder, setCandidateFileOrder] = useState<null | File>(null);
  const swiperRef = useRef<SwiperRef>();
  const handleSlideNavigation = useCallback((type: 'next' | 'previous') => {
    if (type === 'next') {
      swiperRef.current?.slideNext();
    } else {
      swiperRef.current?.slidePrev();
    }
    handleArrowVisibility(swiperRef.current);
  }, []);

  const leftArrowRef = useRef<HTMLDivElement>();
  const rightArrowRef = useRef<HTMLDivElement>();

  const classes = styles();
  const mediaClasses = mediaStyles();
  const location = useLocation();

  function handleArrowVisibility(swiper: SwiperRef): void {
    if (swiper.isBeginning) {
      leftArrowRef.current.classList.add(classes.hiddenArrow);
    } else {
      leftArrowRef.current.classList.remove(classes.hiddenArrow);
    }
    rightArrowRef.current.style.visibility = swiper.isEnd ? 'hidden' : 'visible';
  }

  React.useEffect(() => {
    if (swiperRef.current) handleArrowVisibility(swiperRef.current);
  }, []);

  const showClose = React.useMemo(() => {
    if (location.pathname.includes('/feed/create-post')) {
      return true;
    }
    return false;
  }, [location.pathname]);

  const imageSize: Size = React.useMemo(() => {
    if (location.pathname.includes('/feed/create-post')) {
      return { width: 150, height: 150 };
    }
    if (location.pathname.includes('/feed')) {
      return { width: 200, height: 200 };
    }

    return undefined;
  }, [location.pathname]);

  return currentFiles.length ? (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <div ref={leftArrowRef} className={classes.hiddenArrow}>
        {props.allowNavigation && <NavigationArrow onClick={() => handleSlideNavigation('previous')} type='prev' />}
      </div>
      <Swiper
        onResize={(swiper) => {
          setSlidesPerView(Math.floor(swiper.width / (imageSize?.width || 128)));
        }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
          setSlidesPerView(Math.floor(swiperRef.current.width / (imageSize?.width || 128)));
        }}
        slidesPerView={props.allowNavigation ? 'auto' : slidesPerView}
        height={119}
        draggable={false}
        allowTouchMove={false}
        spaceBetween={24}
        className={mediaClasses.swiper}
      >
        {currentFiles.map((file, i, files) => {
          return (
            <SwiperSlide key={file._id} style={imageSize?.width ? { width: imageSize?.width } : {}}>
              <DraggableMedia
                file={file}
                index={i}
                files={files}
                showClose={showClose}
                currentFiles={currentFiles}
                setCurrentFiles={setCurrentFiles}
                showDelete={props.showDelete}
                showDownload={props.showDownload}
                showInfo={props.showInfo}
                imageSize={imageSize}
                setOpenCarousel={setOpenCarousel}
                allowEdit={props.allowEdit}
                allowReorder={!!allowReorder}
                allowNavigation={props.allowNavigation}
                onDeleteFile={props.onDeleteFile}
                setGallery={props.setGallery}
                progressMap={props.progressMap}
                slidesPerView={slidesPerView}
                dragFile={dragFile}
                setDragFile={setDragFile}
                candidateFileOrder={candidateFileOrder}
                setCandidateFileOrder={setCandidateFileOrder}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div ref={rightArrowRef}>
        {props.allowNavigation && <NavigationArrow onClick={() => handleSlideNavigation('next')} type='next' />}
      </div>
    </div>
  ) : null;
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  downloadFile,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(MediaCarousel);
