import i18n from 'src/utils/translations/i18n';

export type SkillLevelOptions =
  | 'NOT_APPLICABLE'
  | 'NO_COMPETENCE'
  | 'LOW_COMPETENCE'
  | 'MEDIUM_COMPETENCE'
  | 'HIGH_COMPETENCE'
  | 'EXPERT';

export const allSkillLevels: SkillLevelOptions[] = [
  'NOT_APPLICABLE',
  'NO_COMPETENCE',
  'LOW_COMPETENCE',
  'MEDIUM_COMPETENCE',
  'HIGH_COMPETENCE',
  'EXPERT',
];

const options = {
  NOT_APPLICABLE: {
    name: 'notApplicable',
    icon: 'skill-na.svg',
    selectedIcon: 'skill-na-blue.svg',
    description: 'thisCompetenceDoesntApplyToTheUser',
  },
  NO_COMPETENCE: {
    name: 'noCompetence',
    icon: 'skill-no.svg',
    selectedIcon: 'skill-no-blue.svg',
    description: 'doesNotDemonstrateKnowledge',
  },
  LOW_COMPETENCE: {
    name: 'lowCompetence',
    icon: 'skill-low.svg',
    selectedIcon: 'skill-low-blue.svg',
    description: 'demonstratesKnowledgeToPerformBasicOperations',
  },
  MEDIUM_COMPETENCE: {
    name: 'mediumCompetence',
    icon: 'skill-medium.svg',
    selectedIcon: 'skill-medium-blue.svg',
    description: 'demonstratesKnowledgeToExecuteAllButNeedsAssistence',
  },
  HIGH_COMPETENCE: {
    name: 'highCompetence',
    icon: 'skill-high.svg',
    selectedIcon: 'skill-high-blue.svg',
    description: 'demonstratesKnowledgeToExecuteAllWithoutAssistence',
  },
  EXPERT: {
    name: 'expert',
    icon: 'skill-expert.svg',
    selectedIcon: 'skill-expert-blue.svg',
    description: 'demonstratesHighKnowledge',
  },
};

export const getSkillLevelOptions = (level: SkillLevelOptions, data?: { name?: string; description?: string }) => {
  return {
    ...options[level],
    name: getSkillLevelName(level, data),
    description: getSkillLevelName(level, data),
  };
};

export const getSkillLevelDescription = (level: SkillLevelOptions, data?: { description?: string }): string => {
  return data?.description === '' ? '' : data?.description || i18n.t(options[level].description);
};

export const getSkillLevelName = (level: SkillLevelOptions, data?: { name?: string }): string => {
  return data?.name ? data?.name : i18n.t(options[level].name);
};
