import React, { useState, useRef } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { compose } from 'redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Popover, ListItem } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';
import { styles } from './styles';
import { SortOrder, SortPreset, SortTypes } from './types';

type CustomSortByProps = {
  selectedSort: SortPreset;
  setSelectedSort: (sortPreset: SortPreset) => void;
  excludePresets: string[];
} & WithTranslation &
  WithStyles<typeof styles>;

export const sortPresets: SortPreset[] = [
  // {
  //   name: 'originalOrder',
  //   type: SortTypes.default,
  //   order: SortOrder.default,
  // },
  {
    name: 'responsesLowestHighest',
    type: SortTypes.value,
    order: SortOrder.asc,
  },
  {
    name: 'responsesHighestLowest',
    type: SortTypes.value,
    order: SortOrder.desc,
  },
  {
    name: 'valuesCountLowestHighest',
    type: SortTypes.valueCount,
    order: SortOrder.asc,
  },
  {
    name: 'valuesCountHighestLowest',
    type: SortTypes.valueCount,
    order: SortOrder.desc,
  },
  {
    name: 'aZ',
    type: SortTypes.alphabetical,
    order: SortOrder.asc,
  },
  {
    name: 'zA',
    type: SortTypes.alphabetical,
    order: SortOrder.desc,
  },
];

const CustomSortBy: React.FC<CustomSortByProps> = (props): JSX.Element => {
  const { selectedSort, setSelectedSort, t, classes, excludePresets } = props;
  const anchorRef = useRef<HTMLDivElement>(null);

  const [openPopover, setOpenPopover] = useState<boolean>(false);

  const handleOpen = () => {
    setOpenPopover(true);
  };

  const handleClose = () => {
    setOpenPopover(false);
  };

  const handleClick = (item: SortPreset) => {
    setSelectedSort(item);
    setOpenPopover(false);
  };

  return (
    <>
      <div className={classes.divContainer} ref={anchorRef} key={'timespan-current-selected'} onClick={handleOpen}>
        <div className={classes.relative}>
          <ExpandMore className={classes.expandIcon} />
        </div>
        <div ref={anchorRef} className={classes.input}>
          <span className={classes.selectedItem}>{t(`${selectedSort.name}`)}</span>
        </div>
      </div>
      <Popover
        anchorEl={anchorRef.current}
        open={openPopover}
        classes={{ paper: classes.popoverContainer }}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{
          style: {
            width: anchorRef.current?.getBoundingClientRect().width,
          },
        }}
      >
        {/* Sort presets array */}
        {(excludePresets?.length ? sortPresets.filter((i) => !excludePresets.includes(i.name)) : sortPresets).map(
          (item) => (
            <ListItem
              key={`timespan-${item.name}`}
              classes={{ root: selectedSort.name === item.name ? classes.listItemSelected : classes.listItem }}
              onClick={() => handleClick(item)}
            >
              <span className={selectedSort.name === item.name && classes.selectedSortItem}>{t(`${item.name}`)}</span>
            </ListItem>
          ),
        )}
      </Popover>
    </>
  );
};

export default compose<any>(withTranslation('translation'), withStyles(styles))(CustomSortBy);
