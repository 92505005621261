import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';

const ActionsViewDefaultRefresh = () => lazy(() => import('src/modules/actions/actions.view.default'));
const ActionsCreateDefaultRefresh = () => lazy(() => import('src/modules/actions/actions.create.default'));

let ActionsViewDefault = ActionsViewDefaultRefresh();
let ActionsCreateDefault = ActionsCreateDefaultRefresh();

export const render = {
  view: {
    default: ({ ...props }) => {
      if (ActionsViewDefault?._result?.type === 'error') {
        ActionsViewDefault = ActionsViewDefaultRefresh();
      }

      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <ActionsViewDefault {...props} />
        </Suspense>
      );
    },
  },
  create: {
    default: ({ ...props }) => {
      if (ActionsCreateDefault?._result?.type === 'error') {
        ActionsCreateDefault = ActionsCreateDefaultRefresh();
      }

      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <ActionsCreateDefault {...props} />
        </Suspense>
      );
    },
  },
};

export default render;
