import { useRef } from 'react';
import { useDrop } from 'react-dnd';
import ColumnsPopoverItem from './components/column-popover-item';

const ContainerHidden = (props): JSX.Element => {
  const { provColumns, setProvColumns, allColumns } = props;
  const containerRef = useRef(null);

  const [, connectDrop] = useDrop({
    accept: 'column',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    hover: (draggedColumn: any) => {
      if (provColumns.find((col) => col === draggedColumn.key)) {
        setProvColumns(provColumns.filter((c) => c !== draggedColumn.key));
      }
    },
  });

  connectDrop(containerRef);

  return (
    <div ref={containerRef}>
      {allColumns
        .filter((c) => !provColumns.includes(c.key))
        .map((column) => (
          <ColumnsPopoverItem
            column={column}
            key={column.key}
            visible={false}
            setVisible={() => {
              setProvColumns([...provColumns, column.key]);
            }}
            provColumns={provColumns}
            setProvColumns={setProvColumns}
          />
        ))}
    </div>
  );
};

export default ContainerHidden;
