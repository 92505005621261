import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';
import { useState } from 'react';
import { Account } from 'src/gql/graphql';
import DetailsPopover from 'src/utils/components/details-popover';
import makeStyles from '@mui/styles/makeStyles';
import HighlightText from 'src/utils/components/highlight-text';

const useStyles = makeStyles(function (theme: Theme) {
  return createStyles({
    hoverable: {
      fontWeight: 'bolder',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
      },
    },
  });
});

export default function AccountPopoverDetails({
  account,
  highlightSearch,
  className,
}: {
  account: Account;
  highlightSearch?: string;
  className?: string;
}) {
  const [openPopoverDetails, setOpenPopoverDetails] = useState<HTMLElement>(null);

  const classes = useStyles();

  return (
    <>
      <span
        onClick={(ev) => setOpenPopoverDetails(ev.target as any)}
        className={`${classes.hoverable} ${className ?? ''}`}
      >
        <HighlightText text={account.name} highlight={highlightSearch} />
      </span>
      {openPopoverDetails ? (
        <DetailsPopover
          context={'account'}
          anchor={openPopoverDetails}
          value={account}
          clear={() => setOpenPopoverDetails(null)}
        />
      ) : null}
    </>
  );
}
