import { withStyles } from '@mui/styles';
import * as React from 'react';
import { styles } from 'src/utils/other/errorHandling/styles';
import i18n from 'src/utils/translations/i18n';
import * as Sentry from '@sentry/browser';
import { Location } from 'history';
import ErrorFicha from '@assets/images/error_ficha.svg';

interface ErrorBoundaryProps {
  classes: Record<string, string>;
  location: Location;
  children?: React.ReactNode;
}

interface ErrorBoundaryState {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error(error);
    Sentry.captureException(error);
    this.setState({
      error,
      errorInfo,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.clearError();
    }
  }

  clearError = () => {
    this.setState({ error: null, errorInfo: null });
  };

  handleRefresh = () => {
    this.clearError();
    window.location.reload();
  };

  handleGoBack = () => {
    this.clearError();
    window.history.go(-1);
  };

  render() {
    if (this.state.errorInfo) {
      return (
        <div className={this.props.classes.body}>
          <div className={this.props.classes.divtitle}>
            <h2 className={this.props.classes.title}>Oops!</h2>
            <h2 className={this.props.classes.title}>{i18n.t('somethingWentWrong')}</h2>
          </div>
          <img src={ErrorFicha} className={this.props.classes.logo} alt='fireSpot' />

          <div className={this.props.classes.divInfo}>
            <span className={this.props.classes.content}>
              {i18n.t('tryAgainBy')}{' '}
              <a className={this.props.classes.clickableContent} onClick={this.handleRefresh}>
                {i18n.t('refreshingThePage')}
              </a>{' '}
              {i18n.t('or')}
            </span>
            <span className={this.props.classes.content}>
              {i18n.t('youCan')}{' '}
              <a className={this.props.classes.clickableContent} onClick={this.handleGoBack}>
                {i18n.t('goback')}
              </a>{' '}
              {i18n.t('toWhereYouWere')}.
            </span>
          </div>

          <div>
            <span className={this.props.classes.contactUs}>
              {i18n.t('ifKeepsHappening')}{' '}
              <a
                className={this.props.classes.clickableContactUs}
                target='_blank'
                href='https://glartek.com/contacts/'
                onClick={this.handleGoBack}
              >
                {i18n.t('contactUs')}.
              </a>
            </span>
          </div>
        </div>
      );
    }
    // Render children if there's no error
    return this.props.children;
  }
}

export default withStyles(styles)(ErrorBoundary);
