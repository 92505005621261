import { File } from 'src/gql/graphql';
import DocumentItem from './document-item';
import { chunk } from 'lodash';
import { Grid, GridSize, Typography } from '@mui/material';
import { styles } from './styles';
import { useEffect, useMemo, useRef, useState } from 'react';

interface IDocumentGridProps {
  currentFiles: File[];
  edit: boolean;
  multiline?: boolean;
  onDeleteFile: (file: File) => void;
  setOpenCarousel?: (file: File) => void;
  setGallery?: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      initialTab: number;
    }>
  >;
  progressMap?: Map<string, number>;
  showDownload?: boolean;
  showDelete?: boolean;
}

const DocumentGrid = (props: IDocumentGridProps) => {
  const classes = styles();
  const containerRef = useRef(null);
  const [itemsPerRow, setItemsPerRow] = useState<number>(3);

  const rows = useMemo(() => {
    return chunk(props.currentFiles, itemsPerRow);
  }, [itemsPerRow, props.currentFiles]);

  const deleteFile = (file: File) => {
    props.onDeleteFile?.(file);
  };

  useEffect(() => {
    const updateItemsPerRow = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const maxItems = Math.floor(containerWidth / (192 + 16));
        if (maxItems > props.currentFiles.length) {
          setItemsPerRow(props.currentFiles?.length > 3 ? 3 : props.currentFiles?.length);
        } else {
          setItemsPerRow(maxItems > 3 ? 3 : maxItems >= 1 ? maxItems : 1);
        }
      }
    };

    updateItemsPerRow();
    window.addEventListener('resize', updateItemsPerRow);
    return () => {
      window.removeEventListener('resize', updateItemsPerRow);
    };
  }, [props.currentFiles?.length]);

  return props.currentFiles ? (
    <Grid container spacing={1} justifyContent='center' ref={containerRef}>
      {rows.map((rowFiles, idx) => {
        if (!props.multiline && idx > 0) return null;
        return (
          <Grid container item xs={12} spacing={1} key={idx}>
            {rowFiles.map((file, idx) => {
              return (
                <Grid
                  item
                  xs={Math.floor(12 / itemsPerRow) as GridSize}
                  key={file.name + idx}
                  data-testid={`file-${file.name}`}
                  style={{ position: 'relative' }}
                >
                  {!props.multiline && idx === itemsPerRow - 1 && idx !== props.currentFiles.length - 1 && (
                    <div className={classes.plusDiv} onClick={() => props.setGallery({ open: true, initialTab: 1 })}>
                      <Typography variant='h2' className={classes.plusText}>
                        {`+${props.currentFiles.length - idx - 1}`}
                      </Typography>
                    </div>
                  )}
                  <DocumentItem
                    file={file}
                    delete={props.showDelete ? deleteFile : undefined}
                    showDownload={!props.edit && props.showDownload}
                    setOpenCarousel={props.setOpenCarousel}
                    uploadProgress={!file._id ? props.progressMap?.get(file.name) : undefined}
                  />
                </Grid>
              );
            })}
          </Grid>
        );
      })}
    </Grid>
  ) : null;
};

export default DocumentGrid;
