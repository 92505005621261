import gql from 'graphql-tag';

export const DEVICE = gql`
  fragment device on Device {
    __typename
    protocol
    deviceType
    name
    lastBrowse
    lastRead
    topic
    _id
    mqttBrockerAddress
    site {
      name
      coordinates
      _id
      parentsTree {
        _id
        name
      }
    }
    address
    status
    deleted
  }
`;

export const DEV_MQTT_CONFIG = gql`
  fragment mqttConfig on Device {
    __typename
    mqttConfig {
      id
      username
      password
      clientId
      topic
      name
    }
  }
`;
