import { cloneDeep, concat, filter } from 'lodash';
import { Action, State } from 'src/interfaces/reducers';
import i18n from 'src/utils/translations/i18n';
import {
  getErrorObject,
  isModalFolderLeaf,
  setModalFolderChildren,
  addToTemplate,
  updateParent,
  deleteFromParent,
  addSpace,
} from 'src/utils/funcs';
import * as queries from 'src/modules/unit/unit.queries';
import { ERROR } from 'src/modules/account/account.redux';
import { APOLLO_CLIENT } from 'config/apollo.config';
import { NexusGenFieldTypes } from '../../../../server/src/types';
import { ApolloQueryResult, FetchResult } from '@apollo/client';
import { Dispatch } from 'redux';
import {
  Unit,
  UnitCreateInput,
  UnitFolder,
  UnitFolderWhereInput,
  UnitToUpdate,
  UnitWhereInput,
  UnitWhereUniqueInput,
} from 'src/gql/graphql';

export const GET_UNITS = 'GET_UNITS';
export const CREATE_UNIT = 'CREATE_UNIT';
export const UPDATE_UNIT = 'UPDATE_UNIT';
export const DELETE_UNIT = 'DELETE_UNIT';
export const GET_UNIT_FOLDERS = 'GET_UNIT_FOLDERS';
export const CREATE_UNIT_FOLDER = 'CREATE_UNIT_FOLDER';
export const UPDATE_UNIT_FOLDER = 'UPDATE_UNIT_FOLDER';
export const DELETE_UNIT_FOLDER = 'DELETE_UNIT_FOLDER';
export const SEARCH_UNITS_FOLDERS = 'SEARCH_UNITS_FOLDERS';
export const MODAL_UNIT_FOLDERS = 'MODAL_UNIT_FOLDERS';

const initialState: State = {
  folders: [],
  units: [],
  searchResults: [],
  modalResults: [],
};

export default (state: State = initialState, action: Action): State => {
  let folders: any = [];
  switch (action.type) {
    case GET_UNITS:
      return { ...state, units: action.payload.units };
    case CREATE_UNIT:
      folders = addToTemplate(cloneDeep(state).folders, action.payload.unit.folder._id, action.payload.unit, 'unit');
      return {
        ...state,
        units: concat(state.units, action.payload.unit),
        folders,
      };
    case UPDATE_UNIT:
      folders = updateParent(
        cloneDeep(state).folders,
        action.payload.new.folder._id,
        action.payload.new,
        'unit',
        action.payload.old,
      );
      return {
        ...state,
        units: state.units.map((u: NexusGenFieldTypes['Unit']) =>
          u._id === action.payload.new._id ? action.payload.new : u,
        ),
        folders,
        searchResults: state.searchResults.map((s: NexusGenFieldTypes['Unit']) =>
          s._id === action.payload.new._id ? action.payload.new : s,
        ),
      };
    case DELETE_UNIT:
      folders = deleteFromParent(cloneDeep(state).folders, action.payload.folder._id, action.payload, 'unit');
      return {
        ...state,
        units: filter(state.units, (u) => u._id !== action.payload._id),
        folders,
        searchResults: state.searchResults.filter((s: NexusGenFieldTypes['Unit']) => s._id !== action.payload._id),
      };
    case SEARCH_UNITS_FOLDERS:
      return { ...state, searchResults: concat(action.payload.units, action.payload.unitFolders) };
    case MODAL_UNIT_FOLDERS:
      folders = [];
      if (action.payload.folders.length > 0 && action.payload.folders[0].parent === null) {
        action.payload.folders.forEach((p: any) => {
          folders.push({
            ...p,
            leaf: isModalFolderLeaf(p, 'unit'),
            children: [],
          });
        });
      } else if (action.payload.folders.length > 0) {
        folders = setModalFolderChildren(
          cloneDeep(state).modalResults,
          action.payload.folders[0].parent ? action.payload.folders[0].parent._id : null,
          action.payload.folders,
          'unit',
        );
      }
      return {
        ...state,
        modalResults: folders.length === 0 ? state.modalResults : folders,
      };
    default:
      return state;
  }
};

export const getUnits = () => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      query: queries.Q_GET_UNITS,
    });

    dispatch({
      type: GET_UNITS,
      payload: { units: response.data.units },
    });

    return response.data.units;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    dispatch({
      type: ERROR,
      payload: error,
    });

    return error;
  }
};

export const getUnit =
  (filter: UnitWhereUniqueInput) =>
  async (dispatch: Dispatch): Promise<Unit> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: { filter },
        query: queries.Q_GET_UNIT,
      });

      return response.data.unit;
    } catch (error) {
      const obj = getErrorObject(error);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      dispatch({
        type: ERROR,
        payload: error,
      });

      return error;
    }
  };

export const createUnit = (unit: NexusGenFieldTypes['Unit'] & { frame: object }) => async (dispatch: Dispatch) => {
  try {
    let response;
    if (!unit.frame) {
      response = await APOLLO_CLIENT.mutate({
        variables: {
          name: Array.isArray(unit.name) ? unit.name[0] : unit.name,
          symbol: unit.symbol?.length ? unit.symbol[0] : undefined,
          folderId: unit.folder,
          labelValues: unit.labelValues,
        },
        mutation: queries.M_CREATE_UNIT,
        fetchPolicy: 'no-cache',
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('unit') + addSpace(unit.name) + i18n.t('toastCreateSuccess'),
          severity: 'success',
        },
      });
    }
    dispatch({
      type: CREATE_UNIT,
      payload: { unit: response ? response.data.createUnit : unit },
    });

    if (response) return response.data.createUnit;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    dispatch({
      type: ERROR,
      payload: error,
    });

    return error;
  }
};

export const deleteUnit =
  (unitData: NexusGenFieldTypes['Unit'] & { frame: object }) =>
  async (dispatch: Dispatch): Promise<void | unknown> => {
    try {
      let response;
      if (!unitData.frame) {
        response = await APOLLO_CLIENT.mutate({
          variables: { _id: unitData._id },
          mutation: queries.M_DELETE_UNIT,
        });
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('unit') + addSpace(unitData.name) + i18n.t('toastDeleteSuccess'),
            severity: 'success',
          },
        });
      }
      dispatch({
        type: DELETE_UNIT,
        payload: response ? response.data.deleteOneUnit : unitData,
      });
      return null;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      dispatch({
        type: ERROR,
        payload: error,
      });

      return error;
    }
  };

export const search = (searchString: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { searchString },
      fetchPolicy: 'no-cache',
      mutation: queries.Q_SEARCH,
    });
    dispatch({
      type: SEARCH_UNITS_FOLDERS,
      payload: response.data,
    });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    dispatch({
      type: ERROR,
      payload: error,
    });

    return error;
  }
};

export const duplicateUnit = (_id: string, copyChildren: boolean, name: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { _id, copyChildren },
      fetchPolicy: 'no-cache',
      mutation: queries.M_DUPLICATE_UNIT,
    });
    dispatch({
      type: CREATE_UNIT,
      payload: { unit: response.data.duplicateUnit },
    });
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: `${name} ${i18n.t('duplicated')}`,
        severity: 'success',
      },
    });
    return response.data.duplicateUnit;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const unitFolders =
  (where: UnitFolderWhereInput, limit?: number, skip?: number, orderBy?: string[]) =>
  async (dispatch: Dispatch): Promise<UnitFolder[]> => {
    try {
      const response: ApolloQueryResult<{ unitFolders: UnitFolder[] }> = await APOLLO_CLIENT.query({
        variables: { where, limit, skip, orderBy },
        fetchPolicy: 'no-cache',
        query: queries.Q_UNIT_FOLDERS,
      });

      return response.data.unitFolders;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      dispatch({
        type: ERROR,
        payload: error,
      });

      return error;
    }
  };

export const units =
  (where: UnitWhereInput, limit?: number, skip?: number, orderBy?: string[]) =>
  async (dispatch: Dispatch): Promise<Unit[]> => {
    try {
      const response: ApolloQueryResult<{ units: Unit[] }> = await APOLLO_CLIENT.query({
        variables: { where, limit, skip, orderBy },
        query: queries.Q_UNITS,
        fetchPolicy: 'no-cache',
      });

      return response.data.units;
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      dispatch({
        type: ERROR,
        payload: error,
      });

      return error;
    }
  };

export const createUnitFolder = (name: string) => async (dispatch: Dispatch) => {
  try {
    const response: FetchResult<{ createUnitFolder: UnitFolder }> = await APOLLO_CLIENT.mutate({
      variables: { name },
      fetchPolicy: 'no-cache',
      mutation: queries.M_CREATE_UNIT_FOLDER,
    });

    return response.data.createUnitFolder;
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const updateUnitFolder = (name: string, _id: string) => async (dispatch: Dispatch) => {
  try {
    const response: FetchResult<{ updateUnitFolder: UnitFolder }> = await APOLLO_CLIENT.mutate({
      variables: { name, parent_eq: parent, _id },
      fetchPolicy: 'no-cache',
      mutation: queries.M_UPDATE_UNIT_FOLDER,
    });

    return response.data.updateUnitFolder;
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const deleteUnitFolder = (_id: string) => async (dispatch: Dispatch) => {
  try {
    const response: FetchResult<{ deleteOneUnitFolder: UnitFolder }> = await APOLLO_CLIENT.mutate({
      variables: { where: { _id: _id } },
      fetchPolicy: 'no-cache',
      mutation: queries.M_DELETE_UNIT_FOLDER,
    });

    return response.data.deleteOneUnitFolder;
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const deleteManyUnits = (_ids: string[]) => async (dispatch: Dispatch) => {
  try {
    const response: FetchResult<{ deleteManyUnits: number }> = await APOLLO_CLIENT.mutate({
      variables: { _ids },
      fetchPolicy: 'no-cache',
      mutation: queries.M_DELETE_MANY_UNITS,
    });

    return response.data.deleteManyUnits;
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const createManyUnits = (data: UnitCreateInput[]) => async (dispatch: Dispatch) => {
  try {
    const response: FetchResult<{ createManyUnits: Unit[] }> = await APOLLO_CLIENT.mutate({
      variables: { data },
      fetchPolicy: 'no-cache',
      mutation: queries.M_CREATE_MANY_UNITS,
    });

    return response.data.createManyUnits;
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const updateManyUnits = (data: UnitToUpdate[]) => async (dispatch: Dispatch) => {
  try {
    const response: FetchResult<{ updateManyUnits: Unit[] }> = await APOLLO_CLIENT.mutate({
      variables: { units: data },
      fetchPolicy: 'no-cache',
      mutation: queries.M_UPDATE_MANY_UNITS,
    });

    return response.data.updateManyUnits;
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};
