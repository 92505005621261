import { ExternalData, Room } from 'src/interfaces/remote-assistance/room';
import {
  DEVICES_ERROR,
  INTERNAL_ERROR,
  INVALID_AGORA_IO_TOKENS,
  INVALID_SESSION,
  NO_NETWORK_CONNECTION,
  OPERATION_ABORTED,
  ROOM_NOT_FOUND,
  ROOM_UNEXPECTED_ERROR,
  SESSION_ALREADY_IN_ROOM,
  UNABLED_GET_ROOM_USERS,
  UNABLED_TO_CREATE_ROOM,
  UNABLED_TO_JOIN_AGORA_SERVICES,
} from '../genericErrors';
import startWebRTC from '../webrtc';
import { StartAgoraMeetingReturn } from 'src/interfaces/remote-assistance/agoraIO';
import store from 'src/reducer-manager';
import { setAudioState, setAutoplayFailed, setMicrophoneState } from 'src/modules/remote-assistance/agora/agora.redux';
import { clearUserConfig, updateUserConfig } from 'src/base/login/login.redux';
import { getCurrentSessionEvent, joinRoomEvent, startWebsocket } from '../sockets';
import { Session } from 'src/interfaces/remote-assistance/session';
import { CustomError } from 'src/interfaces/remote-assistance/custom-error';
import { getSessionByToken } from 'src/modules/remote-assistance/sessions/sessions.fetch';
import { isExpired } from '../utilities';
import {
  createNewRoom,
  getRoomUsers,
  joinRoomByCode,
  joinRoomById,
} from 'src/modules/remote-assistance/rooms/utils/rooms.fetch';
import { RoomUser, setRoom, setRoomUser } from 'src/modules/remote-assistance/rooms/rooms.redux';
import { getRTCToken, TokenType } from 'src/modules/remote-assistance/agora/utils/agora.fetch';
import { setSession } from 'src/modules/remote-assistance/socket/socket.redux';
import { SocketStatus } from 'src/interfaces/remote-assistance/socket';
import { AGORA_ID, ROOM_CODE_REGEXP } from '../constants';
import {
  audioTrack,
  client,
  handleConnectionStateChange,
  handleNetworkQuality,
  handleUserJoined,
  handleUserLeft,
  handleUserPublished,
  handleUserUnPublished,
  handleVolumeIndicator,
  setAudioTrack,
  updateMeetingRedux,
} from './agoraClient';
import { stopMeetingServices } from './agoraState';
import i18n from 'src/utils/translations/i18n';
import AgoraRTC, { IRemoteAudioTrack, IRemoteVideoTrack } from 'agora-rtc-sdk-ng';
import { UnknownType } from 'src/interfaces/remote-assistance/types';
import { ACCESS_ASSIST_TOKEN } from 'config/apollo.config';
import { addChatGroupParticipant } from 'src/modules/chat/chat.fetch';

const handleChannel = async (channel: string | Room, accountId: string) => {
  let room: Room = null;
  let error: CustomError = null;

  if (channel) {
    if (typeof channel === 'string') {
      // Determine whether to join by room code or room ID
      const isRoomCode = ROOM_CODE_REGEXP.test(channel);
      [room, error] = isRoomCode ? await joinRoomByCode(channel) : await joinRoomById(channel);

      if (error) {
        return { status: false, error: ROOM_NOT_FOUND };
      }
    } else {
      // Channel is already a Room object
      room = channel;
    }

    // Assign user to room chat
    const accounts = [];
    accounts.push(accountId);
    await addChatGroupParticipant({ _id: room.chatroom_id }, accounts);
  } else {
    // No channel provided, create a new room
    [room, error] = await createNewRoom();

    if (error) {
      return { status: false, error: UNABLED_TO_CREATE_ROOM };
    }
  }

  return { room, error };
};

// Return true - if the join was successful
export const startAgoraMeeting = async (
  {
    channel,
    requiresMicrophone,
    //redirectedFromMobile,
    setStream,
    setMicrophones,
    setSpeakers,
    setWsClient,
    accountId,
    //name,
    screenshots,
    //externalData,
    //directAccess,
  }: {
    channel: string | Room;
    requiresMicrophone: boolean;
    redirectedFromMobile: boolean;
    setStream: any;
    setMicrophones: any;
    setSpeakers: any;
    setWsClient: any;
    accountId: string;
    name?: string;
    screenshots?: Array<any /*ScreenshotProps*/>;
    externalData?: ExternalData;
    directAccess?: boolean;
  } = {
    channel: '',
    requiresMicrophone: true,
    setStream: null,
    setMicrophones: [],
    setSpeakers: [],
    setWsClient: null,
    accountId: null,
    redirectedFromMobile: false,
    name: null,
    screenshots: null,
    externalData: null,
    directAccess: false,
  },
): Promise<StartAgoraMeetingReturn> => {
  let userConfig = store.getState().loginReducer.userConfig;
  let stream: string | MediaStream | void;
  const dispatch = store.dispatch;

  if (!requiresMicrophone) {
    dispatch(setMicrophoneState(false));
    dispatch(setAudioState(false));
  } else {
    stream = await startWebRTC(dispatch, setStream, setMicrophones, setSpeakers);
    if (stream === DEVICES_ERROR) return { status: false, error: stream };

    dispatch(setMicrophoneState(true));
  }

  // User denied access to devices
  const { room, error } = await handleChannel(channel, accountId);
  if (error) return { status: false, error: error.toString() };

  dispatch(clearUserConfig());
  dispatch(
    updateUserConfig({
      ...userConfig,
      room: room,
    }),
  );

  const state = store.getState();

  const isUserLoggedIn = state.loginReducer.loggedIn;

  //case user return to session, we need do give them the screenshots they taken
  screenshots ? (state.meetingSidebarReducer.screenshots = screenshots) : null;

  if (isUserLoggedIn) {
    let session = null;
    try {
      session = await getCurrentSessionEvent();
    } catch (error) {
      void error;
    }

    if (!session) {
      //      dispatch(logoutReducer()); TODO: Check later
      return { status: false, error: INVALID_SESSION };
    }

    session.room_id = room.id;
    userConfig = store.getState().loginReducer.userConfig;
    dispatch(
      updateUserConfig({
        ...userConfig,
        session: session,
        name: session?.details?.name,
      }),
    );
  } else {
    if (localStorage.getItem(ACCESS_ASSIST_TOKEN)) {
      const [session]: [Session, CustomError] = await getSessionByToken(localStorage.getItem(ACCESS_ASSIST_TOKEN));
      if (session && session.room_id == userConfig.room.id && !isExpired(session.expires_at)) {
        // TODO: check this later
        // directAccess is used to check if the user joined the room directly from a url
        // If user directly entered, use the last room token to avoid unnecessary room token creation
        // NOTE: For now, lets always force join to avoid temp issues while developing
        /*if (directAccess || session?.details?.name == name)*/
        userConfig = store.getState().loginReducer.userConfig;
        dispatch(
          updateUserConfig({
            ...userConfig,
            session: session,
            name: session?.details?.name,
          }),
        );
      }
    } else {
      return { status: false, error: INVALID_SESSION };
    }

    // TODO: here we can validate if user is not logged in, if we need guests in the future*/
  }
  userConfig = store.getState().loginReducer.userConfig;

  const [users, err]: [Array<RoomUser>, CustomError] = await getRoomUsers(userConfig.room.id);
  if (err) return { status: false, error: UNABLED_GET_ROOM_USERS };

  // Check if this session id is already in session
  if (users.length !== 0 && users.find((user: RoomUser) => user.session_id === userConfig.session.id) !== undefined) {
    return { status: false, error: SESSION_ALREADY_IN_ROOM };
  }

  let isPageRefresh = false;
  const pagePerformanceNavigation = performance.getEntriesByType('navigation');
  if (
    pagePerformanceNavigation.length === 1 &&
    (pagePerformanceNavigation[0] as unknown as { type: string }).type === 'reload'
  )
    isPageRefresh = true;

  const [agoraInfo, cErr]: [TokenType, CustomError] = await getRTCToken(userConfig.room.id);
  if (cErr) return { status: false, error: INVALID_AGORA_IO_TOKENS };

  userConfig = store.getState().loginReducer.userConfig;
  dispatch(
    updateUserConfig({
      ...userConfig,
      rtcToken: agoraInfo.rtc_token,
      uid: agoraInfo.uid,
    }),
  );

  try {
    userConfig = store.getState().loginReducer.userConfig;
    await Promise.all([
      /* Meeting data */
      dispatch(setRoom(userConfig.room)),
      dispatch(setRoomUser({ agoraUid: userConfig.uid, name: userConfig.name })),
      /* If directAccess check set previous microphone state */
      dispatch(setAudioState(isPageRefresh && localStorage.getItem('microphone_state') === 'false' ? false : true)),

      /* Websocket */
      dispatch(setSession(userConfig.session)),
    ]);
  } catch (error) {
    void error;
    return { status: false, error: INVALID_SESSION };
  }

  if (userConfig.session?.user_id && state.socketReducer.socketStatus == SocketStatus.CONNECTED) {
    void joinRoomEvent(userConfig.room.id);
  } else {
    await startWebsocket(userConfig.session.token, stream, setStream, setWsClient /*, redirectedFromMobile*/);
  }

  return { status: true, roomCode: userConfig.room.code };
};

export const userApproved = async (
  stream: any,
  setStream: any,
  //redirectedFromMobile: boolean,
  //startWithVideo: boolean,
  //skipSelectRole?: boolean,
): Promise<StartAgoraMeetingReturn> => {
  /*
    if (!skipSelectRole) {
      const [users]: [Array<RoomUser>, CustomError] = await getRoomUsers(userConfig.room?.id);
      // If user is on Mobile and there isn't any user sharing video
  
      if (
        isMobile.any() !== null &&
        !users.some((e: RoomUser) => e.user_details.video_track === true) &&
        !redirectedFromMobile
      ) {
        // Redirect to select role
        return { status: true, roomCode: userConfig.room.code + '/select_role' };
      }
    }
    */
  const userConfig = store.getState().loginReducer.userConfig;
  return !userConfig.rtcToken
    ? await getTokensAndJoin(stream, setStream /*, startWithVideo*/)
    : await join(stream, setStream /*, startWithVideo*/);
};

export const getTokensAndJoin = async (
  stream: any,
  setStream: any,
  //startWithVideo: boolean,
): Promise<StartAgoraMeetingReturn> => {
  const userConfig = store.getState().loginReducer.userConfig;
  if (userConfig.room?.id === null) return { status: false, error: INTERNAL_ERROR };

  try {
    const [agoraInfo, err]: [TokenType, CustomError] = await getRTCToken(userConfig.room.id);
    if (err) return { status: false, error: INVALID_AGORA_IO_TOKENS };

    store.dispatch(
      updateUserConfig({
        ...userConfig,
        rtcToken: agoraInfo.rtc_token,
        uid: agoraInfo.uid,
      }),
    );

    return await join(stream, setStream /*, startWithVideo*/);
  } catch (err) {
    return { status: false, error: ROOM_UNEXPECTED_ERROR };
  }
};

const join = async (stream: any, setStream: any /*, startWithVideo: boolean*/): Promise<StartAgoraMeetingReturn> => {
  const userConfig = store.getState().loginReducer.userConfig;
  if (client.connectionState != 'DISCONNECTED') {
    // Check if user is on the right channel
    if (client.channelName == userConfig.room.id) {
      return { status: false, error: OPERATION_ABORTED };
    }

    // if not leave channel and join the requested channel
    await stopMeetingServices(stream, setStream, true);
  }

  // Start listen agora RTC events
  // Events from the Signaling Server
  // Handlers are async
  client.enableAudioVolumeIndicator();
  client.on('user-published', handleUserPublished());
  client.on('user-unpublished', handleUserUnPublished());
  client.on('user-joined', handleUserJoined());
  client.on('user-left', handleUserLeft());
  client.on('connection-state-change', handleConnectionStateChange(userConfig.room.id));
  client.on('network-quality', handleNetworkQuality());
  client.on('token-privilege-will-expire', () =>
    store.dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: i18n.t('yourSessionWillExpireinAFewSeconds'),
        severity: 'warning',
        notHide: true,
      },
    }),
  );
  client.on('token-privilege-did-expire', () =>
    store.dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: i18n.t('sessionExpiredCreateANewSession'),
        severity: 'error',
        notHide: true,
      },
    }),
  );
  client.on('volume-indicator', handleVolumeIndicator());

  // Autoplay is a setting from the browsers to prevent the webpage from playing sound without permission
  // https://docs.agora.io/en/Voice/autoplay_policy_web_ng?platform=Web
  AgoraRTC.onAutoplayFailed = (): void => {
    store.dispatch(setAutoplayFailed(true));
    const remoteUsers = store?.getState()?.agoraReducer?.usersAgora;

    Object.keys(remoteUsers).forEach((uid) => {
      if (remoteUsers[uid]?._videoTrack) (remoteUsers[uid]._videoTrack as IRemoteVideoTrack)?.stop();
      if (remoteUsers[uid]?.audioTrack) (remoteUsers[uid].audioTrack as IRemoteAudioTrack)?.stop();
    });
  };

  // Join to the channel
  // Validations are done like this because there is no way to validate beforehand if the user can connect with the channel
  // AgoraRTC.createMicrophoneAudioTrack() and AgoraRTC.createCameraVideoTrack() user microphone and camera activation
  //const path = '/session/' + userConfig.room.code;

  const requiresMicrophone: boolean = store.getState().agoraReducer.requiresMicrophone;
  try {
    const [, newAudioTrack] = await Promise.all([
      await client.join(AGORA_ID, userConfig.room.id, userConfig.rtcToken, Number(userConfig.uid)),
      requiresMicrophone && (await AgoraRTC.createMicrophoneAudioTrack()),
      client.channelName && (await updateMeetingRedux()),
    ]);

    setAudioTrack(newAudioTrack);
  } catch (error) {
    // if code is OPERATION_ABORTED it was stopped by function checkJoinChannelConnection() and time limit reached to join channel
    if (((error as UnknownType).code as string) === OPERATION_ABORTED) {
      return {
        status: false,
        error: !navigator.onLine ? NO_NETWORK_CONNECTION : OPERATION_ABORTED,
      };
    }
    console.error(UNABLED_TO_JOIN_AGORA_SERVICES, error);
    return { status: false, error: UNABLED_TO_JOIN_AGORA_SERVICES };
  }

  // Validation when the user wasn't unable entering the call (HomePage)
  if (!client.channelName) {
    await client.leave();
    return { status: false, error: OPERATION_ABORTED };
  }

  // Publish the user’s video and audio to the channel and element
  if (requiresMicrophone) {
    await audioTrack.setEnabled(store.getState().agoraReducer.audioState);
    await client.publish(audioTrack).catch((error) => {
      // TODO handle error
      void error;
      return { status: false, error: OPERATION_ABORTED };
    });
  }

  return { status: true, roomCode: userConfig.room.code };
};
