import gql from 'graphql-tag';

export const M_GET_RTC_TOKEN = gql`
  mutation GetRTCToken($roomId: String!) {
    getRTCToken(room_id: $roomId) {
      rtc_token
      rtm_token
      uid
    }
  }
`;

export const M_START_RECORDING = gql`
  mutation StartRecording($roomId: String!, $videoTrackId: String!, $sessionId: String) {
    startRecording(room_id: $roomId, video_track_id: $videoTrackId, session_id: $sessionId) {
      expiredTimeInSeconds
      resourceId
      sid
    }
  }
`;

export const M_STOP_RECORDING = gql`
  mutation StopRecording($roomId: String!) {
    stopRecording(room_id: $roomId) {
      expiredTimeInSeconds
      resourceId
      sid
    }
  }
`;

export const M_UPDATE_RECORD_LAYOUT = gql`
  mutation UpdateRecordLayout($roomId: String!, $uid: String!) {
    updateRecordLayout(room_id: $roomId, uid: $uid)
  }
`;

export const Q_CHECK_RECORDING = gql`
  query CheckRecording($roomId: String!) {
    checkRecording(room_id: $roomId) {
      expired_time_in_seconds
      started_by
      started_at_in_seconds
      status
    }
  }
`;

export const Q_GET_RECORD = gql`
  query GetRecord($roomId: String!, $fileName: String!) {
    getRecord(room_id: $roomId, file_name: $fileName) {
      file_name
      link
      valid_until
    }
  }
`;

export const Q_GET_RECORD_INFO = gql`
  query GetRecordInfo($roomId: String!, $fileName: String!) {
    getRecordInfo(room_id: $roomId, file_name: $fileName) {
      content_type
      file_name
      last_modified
      size
    }
  }
`;
