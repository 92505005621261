import { debounce } from 'lodash';
import { useCallback } from 'react';
import * as React from 'react';

export default function useDebouncedCallback<T extends any[], R>(
  callback: (...args: T) => R,
  delay: number,
  dependencies: React.DependencyList,
) {
  return useCallback(debounce(callback, delay), dependencies);
}
