import { useTheme } from '@mui/styles';
import { FormControlLabel, Checkbox } from '@mui/material';
import { useDrag, useDrop } from 'react-dnd';
import { useRef } from 'react';
import { DragIndicator } from '@mui/icons-material';

interface Item {
  order: number;
  _id: string;
  id: number;
  color: string;
  label: string;
}

interface CheckboxComponentProps {
  editableOptions?: boolean;
  disabled: boolean;
  classes: Record<string, string>;
  moveOption: (draggedId: string, order: number) => void;
  inputsLabel: (lbl: Item) => JSX.Element;
  item: Item;
  values: string[];
  handleChange: (vals: string[]) => void;
  helperText?: JSX.Element;
  dropOption: () => void;
}

const CheckboxComponent = (props: CheckboxComponentProps) => {
  const { item, handleChange, values, disabled, inputsLabel, moveOption } = props;
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const refHandle = useRef<HTMLDivElement>(null);

  const [{ isDragging }, connectDrag, connectPreview] = useDrag(
    () => ({
      // "type" is required. It is used by the "accept" specification of drop targets.
      type: 'OPTION',
      canDrag: disabled,
      item: { item },
      // The collect function utilizes a "monitor" instance (see the Overview for what this is)
      // to pull important pieces of state from the DnD system.
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end() {
        props.dropOption();
      },
    }),
    [disabled],
  );

  const [, connectDrop] = useDrop({
    accept: 'OPTION',
    hover({ item: draggedItem }: { item: Item }) {
      if (draggedItem._id !== item._id) {
        moveOption(draggedItem._id, item.order);
      }
    },
  });

  connectDrag(refHandle);
  connectDrop(ref);
  connectPreview(ref);

  return (
    <>
      <FormControlLabel
        ref={ref}
        style={
          isDragging
            ? {
                contentVisibility: 'hidden',
                backgroundColor: theme.palette.text.disabled,
                minHeight: '42px',
                borderRadius: '4px',
                marginLeft: '10px',
                width: '-webkit-fill-available',
              }
            : { display: 'flex', alignItems: 'center' }
        }
        classes={{
          root: props.classes.formControl2,
          label:
            /* focus !== null && focus === item.id ? props.classes.formControlLabel : */ props.classes
              .formControlLabel2,
        }}
        control={
          <>
            {props.editableOptions && (
              <div ref={refHandle} style={{ display: 'flex', marginLeft: '10px', marginRight: '-10px', width: '23px' }}>
                <DragIndicator
                  id={'div-buttons-actions'}
                  style={{ color: theme.palette.text.disabled, display: 'none' }}
                />
              </div>
            )}
            <Checkbox
              style={{
                color: disabled
                  ? theme.palette.text.secondary
                  : item.color
                    ? item.color
                    : Array.isArray(values) && !!values?.filter((v) => v === item.label).length // checked = blue color
                      ? theme.palette.primary.main
                      : theme.palette.text.secondary,
              }}
              disabled={disabled}
              id={`checkbox-${item.label}`}
              data-testid={`checkbox-${item.label}`}
              checked={Array.isArray(values) ? !!values?.filter((v) => v === item.label).length : false}
              onChange={(): void => {
                if (!values) {
                  handleChange([item.label]);
                } else if (values.filter((v) => v === item.label).length) {
                  handleChange(values.filter((v) => v !== item.label));
                } else {
                  handleChange([...values, item.label]);
                }
              }}
              name={item.id.toString()}
            />
          </>
        }
        label={inputsLabel(item)}
      />
      {props?.helperText && (Array.isArray(values) ? !!values?.filter((v) => v === item.label).length : false) && (
        <div>{props.helperText}</div>
      )}
    </>
  );
};

export default CheckboxComponent;
