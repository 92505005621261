import { Chip, TextField } from '@mui/material';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import { styles } from 'src/utils/components/generic-input-card/styles';
import { useEffect, useState } from 'react';
import { NexusGenFieldTypes } from '../../../../../../server/src/types';
import {
  withIssueTemplateConnector,
  WithIssueTemplateConnectorProps,
} from 'src/modules/issue-templates/redux/issue.template.draft.redux';

interface QrShowValidationProps extends WithIssueTemplateConnectorProps {
  classes: Record<string, string>;
  input: NexusGenFieldTypes['Input'];
  regexName: any;
  taskGroups: NexusGenFieldTypes['TaskGroup'][];
  masterValidation: any;
  valInput: any;
  t: any;
}

const QrShowValidation = (props: QrShowValidationProps): JSX.Element => {
  const {
    classes,
    input,
    selectedTask,
    regexName,
    taskGroups,
    masterValidation,
    valInput,

    templateGroups: contextTemplateGroups,
  } = props;
  const templateGroups = contextTemplateGroups || taskGroups;
  const [onlyInputsWithQrCode, setOnlyInputsWithQrCode] = useState<any>([]);

  const qrCodeInputsToLink = (inputs) => {
    let inpsToReturn = [];
    let inputEqual = false;
    inputs?.forEach((inp) => {
      if (inputEqual) return;
      if (
        (inp.type === 'qrCode' || inp.type === 'tool' || (inp.type === 'string' && inp.string.setValueWithQRCode)) &&
        inp._id !== input._id
      ) {
        inpsToReturn.push(inp);
      } else if (inp._id === input._id) {
        inputEqual = true;
        return;
      }
      if (inp[inp.type].onResponse?.length) {
        inp[inp.type].onResponse.map((onResp) => {
          if (onResp.inputs?.length) {
            const onRespInps = qrCodeInputsToLink(onResp.inputs);
            inpsToReturn = [...inpsToReturn, ...onRespInps];
          }
        });
      }
    });
    return inpsToReturn;
  };

  useEffect(() => {
    let inpsWithQrCode = {};
    let breakMaps = false;
    let onlyInpsWithQrCode = [];
    templateGroups?.map((g) => {
      (g.tasks || g.taskTemplates)?.map((t) => {
        if (breakMaps) {
          return;
        }
        const inps = qrCodeInputsToLink(t.inputs);
        if (inps.length) {
          inpsWithQrCode = { ...inpsWithQrCode, [g.name]: { ...inpsWithQrCode[g.name], [t.name]: inps } };
          onlyInpsWithQrCode = [...onlyInpsWithQrCode, ...inps];
        }
        if (t._id === selectedTask._id) {
          breakMaps = true;
        }
      });
    });
    setOnlyInputsWithQrCode(onlyInpsWithQrCode);
  }, []);

  return (
    <div className={classes.containerLinkInput}>
      <Chip
        size='small'
        label={
          <div className={classes.divLabel}>
            <div className={classes.divLinkInputs}>
              <Chip
                classes={{ root: classes.inputChip }}
                size='small'
                label={
                  onlyInputsWithQrCode.find(
                    //@ts-ignore
                    (inp) => inp._id === (valInput?._id || valInput),
                  )?.name
                }
              />
            </div>
            <div className={classes.divLinkInputs}>
              {/*@ts-ignore*/}
              {input[input.type].validations?.length > 1 ? (
                <Chip
                  label={
                    <div className={classes.divLabel}>
                      <Chip label={props.t('OR')} size={'small'} classes={{ root: classes.orChip }} />
                      {regexName(masterValidation.name)}
                      <TextField
                        id='qr-show-validation-input'
                        data-testid='qr-show-validation-input'
                        value={
                          masterValidation.name?.split(':').length > 1 ? masterValidation.name?.split(':')[1] : null
                        }
                        type={'text'}
                        disabled={true}
                        InputProps={{ classes: { input: classes.inputValidations } }}
                        style={{ marginLeft: '5px' }}
                      />
                    </div>
                  }
                  classes={{ root: classes.advancedChip }}
                />
              ) : undefined}
            </div>
          </div>
        }
        classes={{ root: classes.advancedChip }}
      />
    </div>
  );
};

export default compose<any>(withStyles(styles), withIssueTemplateConnector)(QrShowValidation);
