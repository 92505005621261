import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    inputs: {
      fontSize: theme.typography.fontSize,
    },
    iconSize: {
      fontSize: 20,
    },
    formGroup: {
      marginBottom: '1rem',
    },
    loginWrapper: {
      padding: '2rem',
      fontSize: theme.typography.fontSize,
      width: '300px',
      [theme.breakpoints.up(500)]: {
        width: '500px',
      },
    },
    mutedText: {
      color: `${theme.palette.text.secondary} !important`,
    },
    headerBrand: {
      fontWeight: 500,
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      fontSize: '30px',
      marginBottom: '65px',
    },
    label: {
      fontSize: '16px',
    },
  });
