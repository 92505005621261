import { ApolloQueryResult } from '@apollo/client';
import { APOLLO_CLIENT } from 'config/apollo.config';
import { cloneDeep, concat, filter, orderBy } from 'lodash';
import { Dispatch } from 'redux';
import { Action, State } from 'src/interfaces/reducers';
import * as queries from 'src/modules/profile/profile.queries';
import {
  addNewChildren,
  addSpace,
  addToTemplate,
  deleteChildren,
  deleteFromParent,
  getErrorObject,
  isLeaf,
  isModalFolderLeaf,
  setChildren,
  setModalFolderChildren,
  updateChildren,
  updateParent,
} from 'src/utils/funcs';
import i18n from 'src/utils/translations/i18n';
import { NexusGenFieldTypes, NexusGenInputs } from '../../../../server/src/types';
import { GraphQLErrors } from '@apollo/client/errors';
import { ProfileTemplateUpdateInput, ProfileTemplateWhereUniqueInput } from 'src/gql/graphql';

export const GET_PROFILES_ROOT = 'GET_PROFILES_ROOT';
export const GET_SITES_ROOT_PROFILE = 'GET_SITES_ROOT_PROFILE';
export const GET_PROFILES_SEARCH_RESULTS = 'GET_PROFILES_SEARCH_RESULTS';
export const GET_PROFILES_BY_FATHER_ID = 'GET_PROFILES_BY_FATHER_ID';
export const CREATE_PROFILE = 'CREATE_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CREATE_PROFILE_TEMPLATE = 'CREATE_PROFILE_TEMPLATE';
export const DELETE_PROFILE_TEMPLATE = 'DELETE_PROFILE_TEMPLATE';
export const UPDATE_PROFILE_TEMPLATE = 'UPDATE_PROFILE_TEMPLATE';
export const MODAL_PROFILE_TEMPLATE_FOLDERS = 'MODAL_PROFILE_TEMPLATE_FOLDERS';
export const GET_PROFILE_TEMPLATE_FOLDERS = 'GET_PROFILE_TEMPLATE_FOLDERS';
export const CREATE_PROFILE_TEMPLATE_FOLDER = 'CREATE_PROFILE_TEMPLATE_FOLDER';
export const DELETE_PROFILE_TEMPLATE_FOLDER = 'DELETE_PROFILE_TEMPLATE_FOLDER';
export const UPDATE_PROFILE_TEMPLATE_FOLDER = 'UPDATE_PROFILE_TEMPLATE_FOLDER';
export const SEARCH_PROFILE_TEMPLATE_FOLDERS = 'SEARCH_PROFILE_TEMPLATE_FOLDERS';
export const ERROR = 'ERROR';
export const CHANGE_SEARCH_INPUT_VALUE = 'CHANGE_SEARCH_INPUT_VALUE';
export const CREATE_PROFILE_FOLDER = 'CREATE_PROFILE_FOLDER';
export const MODAL_PROFILE_FOLDERS = 'MODAL_PROFILE_FOLDERS';
export const GET_PROFILE_FOLDERS = 'GET_PROFILE_FOLDERS';
export const DELETE_PROFILE_FOLDER = 'DELETE_PROFILE_FOLDER';
export const UPDATE_PROFILE_FOLDER = 'UPDATE_PROFILE_FOLDER';
export const MOVE_PROFILES = 'MOVE_PROFILES';
export const MOVE_PROFILE_FOLDERS = 'MOVE_PROFILE_FOLDERS';

const initialState: State = {
  profiles: [],
  profilesSearchResults: [],
  map: null,
  error: false,
  searchInputValue: '',
  searchResults: [],
  allProfiles: [],
  profileNames: [],
  fields: [],
  folders: [],
  modalResults: [],
  profileTemplates: [],
};
/**
 * @function ProfileReducer
 * @description Manages the `users` state of the app. Can, in some cases, keep an error value.
 * @param {object} state - The current state of the reducer (alternatively, the initial).
 * @param {object} action - Action to be reduced.
 * @returns {object} - The new UserManagement state.
 */
export default (state: State = initialState, action: Action): State => {
  let folders: any = [];
  const sites: NexusGenFieldTypes['Site'][] = [];
  let profilesAux: any = [];
  switch (action.type) {
    case CHANGE_SEARCH_INPUT_VALUE:
      return { ...state, searchInputValue: action.payload.searchInputValue };
    case GET_PROFILES_SEARCH_RESULTS:
      action.payload.profiles.forEach((element: NexusGenFieldTypes['Profile']) => {
        const { _id, parent, profilesCount } = element;
        profilesAux.push({
          ...element,
          id: _id,
          parentId: parent !== null ? parent._id : null,
          leaf: profilesCount === 0,
        });
      });
      return { ...state, profilesSearchResults: profilesAux, error: false };
    case GET_SITES_ROOT_PROFILE:
      action.payload.sites.forEach(async (profile: any) => {
        sites.push({
          ...profile,
          id: profile._id,
          children: [],
          leaf: profile.sitesCount === 0 && profile.profilesCount === 0,
          profilesCount: profile.profilesCount + profile.sitesCount,
          sitesCount: profile.sitesCount,
        });
      });
      return { ...state, profiles: sites, error: false };
    case GET_PROFILES_ROOT:
      action.payload.profiles.forEach((element: NexusGenFieldTypes['Profile']) => {
        const { _id, name, description, profilesCount } = element;
        profilesAux.push({
          ...element,
          id: _id,
          name,
          description,
          parentId: null,
          leaf: profilesCount === 0,
        });
      });
      const foldersAux = [];
      action.payload.folders.forEach((folder: NexusGenFieldTypes['ProfileFolder']) => {
        foldersAux.push({
          ...folder,
          id: folder._id,
          parentId: null,
          leaf: folder.profilesCount === 0 && folder.profileFoldersCount === 0,
        });
      });
      return {
        ...state,
        profiles: [...orderBy(profilesAux, 'name', 'asc'), ...orderBy(foldersAux, 'name', 'asc')],
        error: false,
      };
    case CREATE_PROFILE_TEMPLATE:
      folders = addToTemplate(
        state.folders,
        action.payload.profileTemplate.folder._id,
        action.payload.profileTemplate,
        'profileTemplate',
      );
      return {
        ...state,
        profileTemplates: concat(state.profileTemplates, action.payload.profileTemplate),
        folders,
      };
    case DELETE_PROFILE_TEMPLATE:
      folders = deleteFromParent(
        cloneDeep(state).folders,
        action.payload.folder._id,
        action.payload,
        'profileTemplate',
      );
      return {
        ...state,
        profileTemplate: filter(state.profileTemplates, (u) => u._id !== action.payload._id),
        folders,
        searchResults: state.searchResults.filter((s: { _id: string }) => s._id !== action.payload._id),
      };
    case UPDATE_PROFILE_TEMPLATE:
      folders = updateParent(
        cloneDeep(state).folders,
        action.payload.new.folder._id,
        action.payload.new,
        'profileTemplate',
        action.payload.old,
      );
      return {
        ...state,
        profileTemplates: state.profileTemplates.map((it: NexusGenFieldTypes['ProfileTemplate']) =>
          it._id === action.payload.new._id ? action.payload.new : it,
        ),
        folders,
        searchResults: state.searchResults.map((s: { _id: string }) =>
          s._id === action.payload.new._id ? action.payload.new : s,
        ),
      };
    case MODAL_PROFILE_TEMPLATE_FOLDERS:
      folders = [];
      if (action.payload.folders.length > 0 && action.payload.folders[0].parent === null) {
        action.payload.folders.forEach((p: any) => {
          folders.push({
            ...p,
            leaf: isModalFolderLeaf(p, 'profileTemplate'),
            children: [],
          });
        });
      } else if (action.payload.folders.length > 0) {
        folders = setModalFolderChildren(
          cloneDeep(state).modalResults,
          action.payload.folders[0].parent ? action.payload.folders[0].parent._id : null,
          action.payload.folders,
          'profileTemplate',
        );
      }
      return {
        ...state,
        modalResults: folders.length === 0 ? state.modalResults : folders,
      };
    case GET_PROFILE_TEMPLATE_FOLDERS:
      folders = [];
      if (action.payload.folders.length > 0 && action.payload.folders[0].parent === null) {
        action.payload.folders.forEach((p: any) => {
          folders.push({
            ...p,
            leaf: isLeaf(p, 'profileTemplate'),
            children: [],
          });
        });
      } else {
        folders = setChildren(
          cloneDeep(state).folders,
          action.payload.folders.length && action.payload.folders[0].parent
            ? action.payload.folders[0].parent._id
            : action.payload.profileTemplates.length
              ? action.payload.profileTemplates[0].folder._id
              : null,
          action.payload,
          'profileTemplate',
        );
      }
      return {
        ...state,
        folders: folders.length === 0 ? state.folders : orderBy(folders, 'name', 'asc'),
      };
    case CREATE_PROFILE_TEMPLATE_FOLDER:
      folders = [];
      if (action.payload.parent && action.payload.parent._id) {
        folders = addNewChildren(
          cloneDeep(state).folders,
          action.payload.parent._id,
          action.payload,
          'profileTemplate',
        );
      } else {
        folders = JSON.parse(JSON.stringify(state.folders));
        folders.push(Object.assign({ children: [], leaf: true }, action.payload));
      }
      return { ...state, folders: orderBy(folders, 'name', 'asc') };
    case DELETE_PROFILE_TEMPLATE_FOLDER:
      folders = [];
      if (action.payload.parent !== null) {
        folders = deleteChildren(
          cloneDeep(state).folders,
          action.payload.parent._id,
          action.payload,
          'profileTemplate',
        );
      } else {
        folders = state.folders.filter(
          (st: NexusGenFieldTypes['ProfileTemplateFolder']) => st._id !== action.payload._id,
        );
      }
      return {
        ...state,
        folders,
        searchResults: state.searchResults.filter((s: { _id: string }) => s._id !== action.payload._id),
      };
    case UPDATE_PROFILE_TEMPLATE_FOLDER:
      folders = [];
      if (action.payload.parent !== null)
        folders = updateChildren(cloneDeep(state).folders, action.payload.parent._id, action.payload);
      else
        state.folders.map(
          (
            folder: NexusGenFieldTypes['ProfileTemplateFolder'] & {
              children: NexusGenFieldTypes['ProfileTemplateFolder'][];
            },
          ) => {
            if (folder._id === action.payload._id) folders.push({ ...folder, ...action.payload });
            else folders.push(folder);
          },
        );
      return {
        ...state,
        folders: orderBy(folders, 'name', 'asc'),
        searchResults: state.searchResults.map((s: { _id: string }) =>
          s._id === action.payload._id ? action.payload : s,
        ),
      };
    case SEARCH_PROFILE_TEMPLATE_FOLDERS:
      return {
        ...state,
        searchResults: concat(action.payload.profileTemplateFolders, action.payload.profileTemplates),
      };
    case GET_PROFILES_BY_FATHER_ID:
      profilesAux = [];
      if (action.payload.profiles.length > 0) {
        const children: any = [];
        action.payload.profiles.forEach(
          (element: NexusGenFieldTypes['Profile'] & { parent: { _id: string }; profileFoldersCount: number }) => {
            const { _id, parent, profilesCount, profileFoldersCount, folder } = element;
            const foldersCount = profileFoldersCount || 0;
            children.push({
              ...element,
              id: _id,
              parentId: parent?._id || folder?._id,
              leaf: profilesCount === 0 && foldersCount === 0,
            });
          },
        );

        const seeChildren = (
          profiles: any,
          children: NexusGenFieldTypes['ProfileTemplate'][] & { parentId: string }[],
        ) => {
          const c: any = [];
          profiles.map((prof: any) => {
            if (prof.id === children[0].parentId) {
              if (prof.children?.length && !prof.children[0]._id) {
                c.push({
                  ...prof,
                  children: orderBy([...children, prof.children[0]], 'name', 'asc'),
                });
              } else {
                c.push({ ...prof, children: orderBy(children, 'name', 'asc') });
              }
            } else if (prof.children && prof.children.length > 0) {
              c.push({
                ...prof,
                children: seeChildren(prof.children, children),
              });
            } else c.push(prof);
          });
          return c;
        };
        profilesAux = seeChildren(cloneDeep(state).profiles, children);
      } else profilesAux = state.profiles;

      return {
        ...state,
        profiles: orderBy(profilesAux, 'name', 'asc'),
        error: false,
      };
    case CREATE_PROFILE_FOLDER:
    case CREATE_PROFILE:
      const { parent, _id } = action.payload.profile;
      const folder = action.payload.profile?.folder;
      if (action.payload.profile.parent === null && !folder) {
        profilesAux = [
          ...state.profiles,
          {
            ...action.payload.profile,
            id: action.payload.profile._id,
            leaf: isLeaf(action.payload.profile, 'profile'),
          },
        ];
      } else {
        const children = {
          ...action.payload.profile,
          parentId: parent?._id || folder?._id,
          id: _id,
          leaf: isLeaf(action.payload.profile, 'profile'),
        };
        const seeChildren = (profiles, children) => {
          const c: any[] = [];
          profiles.map((prof) => {
            if (prof.id === children.parentId || (prof._id === children.parentId && !prof.children)) {
              c.push({
                ...prof,
                leaf: false,
                children: prof.children
                  ? orderBy([...prof.children, children], 'name', 'asc')
                  : orderBy([children], 'name', 'asc'),
              });
            } else if (prof.id === children.parentId || (prof._id === children.parentId && prof.children)) {
              c.push({
                ...prof,
                leaf: false,
                children: orderBy([...(prof.children || []), children], 'name', 'asc'),
              });
            } else if (prof.children && prof.children.length > 0) {
              c.push({
                ...prof,
                children: seeChildren(prof.children, children),
              });
            } else {
              c.push(prof);
            }
          });
          return c;
        };
        profilesAux = seeChildren(cloneDeep(state).profiles, children);
      }
      return {
        ...state,
        profiles: orderBy(profilesAux, 'name', 'asc'),
        error: false,
      };
    case DELETE_PROFILE_FOLDER:
    case DELETE_PROFILE:
      if (action.payload.profile.parent === null && !action.payload.profile?.folder) {
        profilesAux = state.profiles.filter((p) => p._id !== action.payload.profile._id)
          ? state.profiles.filter((p) => p._id !== action.payload.profile._id)
          : [];
      } else {
        const seeChildren = (profiles) => {
          const c: any[] = [];
          profiles.map((prof) => {
            const children = prof.children ? prof.children.filter((p) => p.id !== action.payload.profile._id) : [];
            if (prof.id === action.payload.profile?.parent?._id || prof.id === action.payload.profile?.folder?._id) {
              c.push({ ...prof, children, leaf: children.length === 0 });
            } else if (prof.children && prof.children.length > 0) {
              c.push({
                ...prof,
                children: seeChildren(prof.children),
              });
            } else {
              c.push(prof);
            }
          });
          return c;
        };
        profilesAux = seeChildren(cloneDeep(state).profiles);
      }
      return {
        ...state,
        profiles: profilesAux,
        error: false,
        profilesSearchResults: state.profilesSearchResults.filter((s) => s._id !== action.payload.profile._id),
      };
    case UPDATE_PROFILE_FOLDER:
    case UPDATE_PROFILE:
      if (action.payload.profile.parent === null && !action.payload.profile?.folder) {
        profilesAux = state.profiles.map((prof) => {
          if (prof._id === action.payload.profile._id) {
            return { ...prof, ...action.payload.profile };
          } else {
            return prof;
          }
        });
      } else {
        const updateChild = (tree): object[] =>
          tree.map((node) => {
            if (node._id === action.payload.profile?.parent?._id || node._id === action.payload.profile?.folder?._id) {
              const newChildren: any = [];
              if (node.children?.length) {
                node.children?.map((ch) => {
                  if (ch._id === action.payload.profile._id)
                    newChildren.push(
                      Object.assign(
                        {
                          children: ch.children ? ch.children : [],
                          leaf: ch.children ? ch.children : true,
                        },
                        {
                          ...ch,
                          ...action.payload.profile,
                          parentId: null,
                          id: action.payload.profile._id,
                        },
                      ),
                    );
                  else newChildren.push(ch);
                });
              } else {
                newChildren.push({
                  ...action.payload.profile,
                  parentId: null,
                  leaf: !action.payload.profile.profilesCount,
                  id: action.payload.profile._id,
                });
                node.leaf = false;
              }
              node.children = orderBy(newChildren, 'name', 'asc');
            } else if (node.children && node.children.length > 0) {
              node.children = updateChild(node.children);
            }
            return node;
          });
        profilesAux = updateChild(cloneDeep(state).profiles);
      }
      return {
        ...state,
        profiles: orderBy(profilesAux, 'name', 'asc'),
        error: false,
        profilesSearchResults: state.profilesSearchResults.map((s) =>
          s._id === action.payload.profile._id ? action.payload.profile : s,
        ),
      };
    case MOVE_PROFILES:
    case MOVE_PROFILE_FOLDERS:
      for (const profile of action.payload.profiles) {
        let removedProfile;

        const removeProfile = (tree, profile) =>
          tree.map((node) => {
            if (node._id === profile._id) {
              removedProfile = node;
              return;
            } else if (node._id === profile?.parent?._id || node._id === profile?.folder?._id) {
              node.children = node?.children?.filter((elem) => {
                if (elem._id === profile?._id) {
                  removedProfile = elem;
                }
                return elem._id !== profile._id;
              });
              if (profile.__typename == 'Profile') node.profilesCount -= 1;
              if (profile.__typename == 'ProfileFolder') node.profileFoldersCount -= 1;
              node.leaf = !node.profilesCount && !node.profileFoldersCount;
            } else {
              if (node.children && node.children.length > 0) {
                node.children = removeProfile(node.children, profile);
              }
            }
            return node;
          });
        profilesAux = removeProfile(cloneDeep(state).profiles, profile).filter((elem) => elem !== undefined);

        const addProfile = (tree, removedProfile, newParentId) => {
          return tree.map((node) => {
            if (node._id === newParentId) {
              if (node.children) node.children = [...node.children, removedProfile];
              if (profile.__typename == 'Profile') node.profilesCount += 1;
              if (profile.__typename == 'ProfileFolder') node.profileFoldersCount += 1;
              node.leaf = !node.profilesCount && !node.profileFoldersCount;
            } else {
              if (node.children) node.children = addProfile(node.children, removeProfile, newParentId);
            }
            return node;
          });
        };
        profilesAux = addProfile(profilesAux, removedProfile, action.payload.destination._id);
      }
      return {
        ...state,
        profiles: orderBy(profilesAux, 'name', 'asc'),
        error: false,
        profilesSearchResults: state.profilesSearchResults.map((s) =>
          s._id === action.payload.profile._id ? action.payload.profile : s,
        ),
      };
    case ERROR:
      if (action.payload.profile?.parent === null) {
        profilesAux = state.profiles.filter((p) => p._id !== action.payload.profile._id)
          ? state.profiles.filter((p) => p._id !== action.payload.profile._id)
          : [];
      } else {
        const seeChildren = (profiles) => {
          const c: any[] = [];
          profiles.map((prof) => {
            const children = prof.children ? prof.children.filter((p) => p.id !== action.payload.profile._id) : [];
            if (prof.id === action.payload.profile.parent._id || prof._id === action.payload.profile.parent._id) {
              c.push({ ...prof, children, leaf: children.length === 0 });
            } else if (prof.children && prof.children.length > 0) {
              c.push({
                ...prof,
                children: seeChildren(prof.children),
              });
            } else {
              c.push(prof);
            }
          });
          return c;
        };
        profilesAux = seeChildren(cloneDeep(state).profiles);
      }
      return {
        ...state,
        profiles: profilesAux,
        error: false,
        profilesSearchResults: state.profilesSearchResults.filter((s) => s._id !== action.payload.profile._id),
      };
    default:
      return { ...state, error: true };
  }
};

export const getProfilesRoot =
  (withoutDispatch = false) =>
  async (dispatch: Dispatch) => {
    try {
      const [profilesResponse, profileFoldersResponse] = await Promise.all([
        APOLLO_CLIENT.query({
          query: queries.Q_GET_PROFILES_ROOT,
          fetchPolicy: 'no-cache',
        }),
        APOLLO_CLIENT.query({
          variables: { where: { parent_exists: false } },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_PROFILE_FOLDERS,
        }),
      ]);

      for (let i = 0; i < profilesResponse.data.profiles.length; i++) {
        profilesResponse.data.profiles[parseInt(`${i}`)].counter =
          profilesResponse.data.profiles[parseInt(`${i}`)].elementsCount;
      }

      if (!withoutDispatch)
        dispatch({
          type: GET_PROFILES_ROOT,
          payload: {
            profiles: profilesResponse.data.profiles,
            folders: profileFoldersResponse.data.profileFolders,
            siteRoot: null,
          },
        });

      return [...profilesResponse.data.profiles, ...profileFoldersResponse.data.profileFolders];
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getProfilesByFatherId = (parentId?: string, folderId?: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        parentId,
        folderId,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_PROFILES_BY_FATHER_ID,
    });

    for (let i = 0; i < response.data.profiles.length; i++) {
      response.data.profiles[parseInt(`${i}`)].counter = response.data.profiles[parseInt(`${i}`)].elementsCount;
    }

    let folders = [];
    if (folderId) {
      const folderResponse = await APOLLO_CLIENT.query({
        variables: { where: { parent_eq: folderId } },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_PROFILE_FOLDERS,
      });

      for (let i = 0; i < folderResponse.data.profileFolders.length; i++) {
        folderResponse.data.profileFolders[parseInt(`${i}`)].counter =
          folderResponse.data.profileFolders[parseInt(`${i}`)].elementsCount;
      }
      folders = folderResponse.data.profileFolders;
    }

    dispatch({
      type: GET_PROFILES_BY_FATHER_ID,
      payload: { profiles: [...response.data.profiles, ...folders] },
    });
    return [...response.data.profiles, ...folders];
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getProfile = (filter: NexusGenInputs['ProfileWhereInput']) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        filter,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_PROFILES,
    });

    return response.data.profiles[0];
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getProfileWithDeleted =
  (filter: NexusGenInputs['ProfileWhereUniqueInput']) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          filter,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_PROFILE_WITH_DELETED,
      });

      return response.data.profileWithDeleted;
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const profileSearch = (stringSearch: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: CHANGE_SEARCH_INPUT_VALUE,
      payload: { searchInputValue: stringSearch },
    });
    const response = await APOLLO_CLIENT.query({
      variables: {
        searchString: stringSearch,
      },
      fetchPolicy: 'no-cache',
      query: queries.M_PROFILE_SEARCH,
    });

    dispatch({
      type: GET_PROFILES_SEARCH_RESULTS,
      payload: { profiles: response.data.profiles },
    });

    return response.data.profiles;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getProfilesPaginated =
  (page: number, itemsByPage: number, filter: NexusGenInputs['ProfileWhereInput']) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          limit: itemsByPage,
          skip: (page - 1) * itemsByPage,
          filter,
        },
        fetchPolicy: 'no-cache',
        query: queries.M_PROFILE_SEARCH,
      });

      dispatch({
        type: GET_PROFILES_SEARCH_RESULTS,
        payload: { profiles: response.data.profiles },
      });

      return response.data.profiles;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const createProfile =
  (
    profileData: NexusGenInputs['ProfileCreateInput'] & {
      templateId: string;
      parentId: string;
    },
  ) =>
  async (dispatch: Dispatch) => {
    try {
      let response;
      if (profileData.templateId) {
        if (profileData.parentId) {
          response = await APOLLO_CLIENT.mutate({
            variables: {
              name: profileData.name,
              description: profileData.description,
              parentId: profileData.parentId,
              inputs: profileData.inputs,
              templateId: profileData.templateId,
              labelValues: profileData.labelValues,
              folder: profileData.folder,
            },
            mutation: queries.M_CREATE_PROFILE,
          });
        } else {
          response = await APOLLO_CLIENT.mutate({
            variables: {
              name: profileData.name,
              description: profileData.description,
              inputs: profileData.inputs,
              templateId: profileData.templateId,
              labelValues: profileData.labelValues,
              folder: profileData.folder,
            },
            mutation: queries.M_CREATE_PROFILE_ROOT,
          });
        }
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('profile') + addSpace(profileData.name) + i18n.t('toastCreateSuccess'),
            severity: 'success',
          },
        });
      }
      dispatch({
        type: CREATE_PROFILE,
        payload: {
          profile: response ? response.data.createProfile : profileData,
        },
      });
      if (response) return response.data.createProfile;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const deleteProfile =
  (profileData: NexusGenFieldTypes['Profile']) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      let response;
      if (profileData.template) {
        response = await APOLLO_CLIENT.mutate({
          variables: {
            id: profileData._id,
          },
          mutation: queries.M_DELETE_PROFILE,
        });
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('profile') + addSpace(profileData.name) + i18n.t('toastDeleteSuccess'),
            severity: 'success',
          },
        });
      }
      // update the local storage with the token using the correct action
      dispatch({
        type: DELETE_PROFILE,
        payload: {
          profile: response ? response.data.deleteOneProfile : profileData,
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const updateProfile =
  (
    profileData: NexusGenFieldTypes['Profile'] & {
      parentId: string;
      fatherNodeId: string;
      id: string;
      templateId: string;
      folder: string;
      oldParent: any;
      oldFolder: any;
    },
  ) =>
  async (dispatch: Dispatch) => {
    try {
      let response;
      if (profileData.parentId !== null && profileData.folder !== null) {
        response = await APOLLO_CLIENT.mutate({
          variables: {
            name: profileData.name,
            parentId: profileData.parentId
              ? profileData.parentId
              : profileData.fatherNodeId
                ? profileData.fatherNodeId
                : null,
            description: profileData.description,
            id: profileData.id,
            inputs: profileData.inputs,
            templateId: profileData.templateId,
            labelValues: profileData.labelValues,
            folder: profileData.folder,
          },
          fetchPolicy: 'no-cache',
          mutation: queries.M_UPDATE_PROFILE,
        });
      } else {
        response = await APOLLO_CLIENT.mutate({
          variables: {
            name: profileData.name,
            description: profileData.description,
            id: profileData.id,
            inputs: profileData.inputs,
            templateId: profileData.templateId,
            labelValues: profileData.labelValues,
            folder: profileData.folder,
            parentId: profileData.parentId,
          },
          fetchPolicy: 'no-cache',
          mutation: queries.M_UPDATE_PROFILE_ROOT,
        });
      }
      dispatch({
        type: DELETE_PROFILE,
        payload: {
          profile: {
            ...profileData,
            _id: profileData.id,
            parent: profileData.oldParent
              ? profileData.oldParent
              : profileData.parentId
                ? { _id: profileData.parentId }
                : null,
            folder: profileData.oldFolder
              ? profileData.oldFolder
              : profileData.folder && profileData.oldFolder === undefined
                ? { _id: profileData.folder }
                : null,
          },
        },
      });
      dispatch({
        type: CREATE_PROFILE,
        payload: { profile: response.data.updateProfile },
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('profile') + addSpace(profileData.name) + i18n.t('toastUpdateSuccess'),
          severity: 'success',
        },
      });
      return response.data.updateProfile;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getProfileTemplate =
  (filter: NexusGenInputs['ProfileTemplateWhereInput']) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          filter,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_PROFILE_TEMPLATE,
      });
      return response.data.profileTemplates[0];
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const createProfileTemplate =
  (template: NexusGenFieldTypes['ProfileTemplate']) => async (dispatch: Dispatch) => {
    try {
      let response;
      if (template.frame?.inputs) {
        response = await APOLLO_CLIENT.mutate({
          variables: {
            title: Array.isArray(template.title) ? template.title[0] : template.title,
            folder: template.folder._id,
            inputs: template.frame.inputs,
          },
          mutation: queries.M_CREATE_PROFILE_TEMPLATE,
        });
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('Template') + addSpace(template.title) + i18n.t('toastCreateSuccess'),
            severity: 'success',
          },
        });
      }
      // update the local storage with the token using the correct action
      dispatch({
        type: CREATE_PROFILE_TEMPLATE,
        payload: {
          profileTemplate: response ? response.data.createProfileTemplate : template,
        },
      });

      if (response) return response.data.createProfileTemplate;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const deleteProfileTemplate =
  (profileTemplateData: NexusGenFieldTypes['ProfileTemplate']) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      let response;
      if (profileTemplateData.frame?.inputs) {
        response = await APOLLO_CLIENT.mutate({
          variables: {
            id: profileTemplateData._id,
          },
          mutation: queries.M_DELETE_PROFILE_TEMPLATE,
        });
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('Template') + addSpace(profileTemplateData.title) + i18n.t('toastDeleteSuccess'),
            severity: 'success',
          },
        });
      }
      dispatch({
        type: DELETE_PROFILE_TEMPLATE,
        payload: response ? response.data.deleteOneProfileTemplate : profileTemplateData,
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const updateProfileTemplate =
  (data: ProfileTemplateUpdateInput, where: ProfileTemplateWhereUniqueInput, previousFolderId: string) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          data,
          where,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_PROFILE_TEMPLATE,
      });

      if (data.archived) {
        dispatch({
          type: DELETE_PROFILE_TEMPLATE,
          payload: response ? response.data.updateProfileTemplate : data,
        });
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('Template') + ' ' + i18n.t('toastArchiveSuccess'),
            severity: 'success',
          },
        });
      } else {
        // update the local storage with the token using the correct action
        dispatch({
          type: UPDATE_PROFILE_TEMPLATE,
          payload: {
            new: response.data.updateProfileTemplate,
            old: previousFolderId,
          },
        });
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('Template') + addSpace(data.title) + i18n.t('toastUpdateSuccess'),
            severity: 'success',
          },
        });
      }

      return response.data.updateProfileTemplate;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getTemplateFolders =
  (options: any, item: any, modal = false) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      let response: ApolloQueryResult<any> | null = null;
      if (item?.node.profileTemplateFoldersCount || options.parent_exists !== undefined) {
        response = await APOLLO_CLIENT.query({
          variables: { where: options, archivedBy_exists: false },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_PROFILE_TEMPLATE_FOLDERS,
        });
      }

      let profileTemplates: ApolloQueryResult<any> | null = null;
      if (options.parent_eq && !modal && item.node.profileTemplatesCount) {
        profileTemplates = await APOLLO_CLIENT.query({
          variables: { filter: { folder: { _id_eq: options.parent_eq }, archived_ne: true } },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_PROFILE_TEMPLATE_TO_TREE,
        });
      }

      dispatch({
        type: modal ? MODAL_PROFILE_TEMPLATE_FOLDERS : GET_PROFILE_TEMPLATE_FOLDERS,
        payload: {
          folders: response ? response.data.profileTemplateFolders : [],
          profileTemplates: profileTemplates ? profileTemplates.data.profileTemplates : [],
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
    }
  };

export const createFolder =
  ({ name, parent = null }: { name: string; parent: { _id: string } | null }) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { name, parent: parent?._id },
        fetchPolicy: 'no-cache',
        mutation: queries.M_CREATE_PROFILE_TEMPLATE_FOLDER,
      });
      dispatch({
        type: CREATE_PROFILE_TEMPLATE_FOLDER,
        payload: response.data.createProfileTemplateFolder,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(name) + i18n.t('toastCreateSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const deleteTemplateFolder =
  (folderData: { _id: string; name: string }) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { where: { _id: folderData._id } },
        fetchPolicy: 'no-cache',
        mutation: queries.M_DELETE_PROFILE_TEMPLATE_FOLDER,
      });
      dispatch({
        type: DELETE_PROFILE_TEMPLATE_FOLDER,
        payload: response.data.deleteOneProfileTemplateFolder,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(folderData.name) + i18n.t('toastDeleteSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
    }
  };

export const updateTemplateFolder =
  ({ name, parent = null }: { name: string; parent: string | null }, { _id }: { _id: string }) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { name, parent_eq: parent, _id },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_PROFILE_TEMPLATE_FOLDER,
      });
      dispatch({
        type: UPDATE_PROFILE_TEMPLATE_FOLDER,
        payload: response.data.updateProfileTemplateFolder,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(name) + i18n.t('toastUpdateSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const search = (searchString: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { searchString },
      fetchPolicy: 'no-cache',
      mutation: queries.Q_SEARCH,
    });

    dispatch({
      type: SEARCH_PROFILE_TEMPLATE_FOLDERS,
      payload: response.data,
    });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
  }
};

export const getSitesRoot = () => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        filter: localStorage.getItem('current_site')
          ? { _id_eq: localStorage.getItem('current_site') }
          : { parent_exists: false },
        filterElements: localStorage.getItem('current_site')
          ? { site_eq: localStorage.getItem('current_site') }
          : { parent_exists: false },
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_SITES_ROOT,
    });

    dispatch({
      type: GET_SITES_ROOT_PROFILE,
      payload: { sites: response.data.sitesV2 },
    });
    return response.data.sites;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const duplicateProfile = (_id: string, copyChildren: boolean, name: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { _id, copyChildren },
      fetchPolicy: 'no-cache',
      mutation: queries.M_DUPLICATE_PROFILE,
    });

    dispatch({
      type: CREATE_PROFILE,
      payload: { profile: response.data.duplicateProfile },
    });
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: `${name} ${i18n.t('duplicated')}`,
        severity: 'success',
      },
    });
    return response.data.duplicateProfile;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const duplicateProfileTemplate =
  (_id: string, copyChildren: boolean, name: string) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { _id, copyChildren },
        fetchPolicy: 'no-cache',
        mutation: queries.M_DUPLICATE_PROFILE_TEMPLATE,
      });

      dispatch({
        type: CREATE_PROFILE_TEMPLATE,
        payload: { profileTemplate: response.data.duplicateProfileTemplate },
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: `${name} ${i18n.t('duplicated')}`,
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const createProfileFolder =
  ({ name, parent = null }: { name: string; parent: { _id: string } | null }) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { name, parent: parent?._id },
        fetchPolicy: 'no-cache',
        mutation: queries.M_CREATE_PROFILE_FOLDER,
      });
      dispatch({
        type: CREATE_PROFILE_FOLDER,
        payload: { profile: response.data.createProfileFolder },
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(name) + i18n.t('toastCreateSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getProfileFolders =
  (options: any, modal = false) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      let response: ApolloQueryResult<any> | null = null;
      response = await APOLLO_CLIENT.query({
        variables: { where: options },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_PROFILE_FOLDERS,
      });

      let profiles: ApolloQueryResult<any> | null = null;
      profiles = await APOLLO_CLIENT.query({
        variables: { filter: { folder_eq: false, parent_exists: false } },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_PROFILE_TO_TREE,
      });

      const payload = {
        folders: response ? response.data.profileFolders : [],
        profiles: profiles ? profiles.data.profiles : [],
      };
      dispatch({
        type: modal ? MODAL_PROFILE_FOLDERS : GET_PROFILE_FOLDERS,
        payload,
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
    }
  };

export const getProfileFolder = (filter: NexusGenInputs['ProfileFolderWhereInput']) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        filter,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_PROFILE_FOLDER,
    });
    return response.data.profileFolders[0];
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const updateProfileFolder = (id: string, name: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { id, name },
      fetchPolicy: 'no-cache',
      mutation: queries.M_UPDATE_PROFILE_FOLDER,
    });
    dispatch({
      type: UPDATE_PROFILE_FOLDER,
      payload: {
        profile: response.data.updateProfileFolder,
      },
    });
    return response.data.updateProfileFolder;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const deleteProfileFolder =
  (folderData: { _id: string; name: string }) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { where: { _id: folderData._id } },
        fetchPolicy: 'no-cache',
        mutation: queries.M_DELETE_PROFILE_FOLDER,
      });
      dispatch({
        type: DELETE_PROFILE_FOLDER,
        payload: {
          profile: response ? response.data.deleteOneProfileFolder : folderData,
        },
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(folderData.name) + i18n.t('toastDeleteSuccess'),
          severity: 'success',
        },
      });
      return response.data.deleteProfileFolder;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
    }
  };

export const moveManyProfiles = (nodes, parent, folder) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { _ids: nodes.map((elem) => elem._id), parentId: parent?._id, folderId: folder?._id },
      fetchPolicy: 'no-cache',
      mutation: queries.M_MOVE_MANY_PROFILES,
    });

    dispatch({
      type: MOVE_PROFILES,
      payload: {
        profiles: nodes,
        destination: parent || folder,
      },
    });

    return response.data.moveManyProfiles;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
  }
};

export const moveManyProfileFolders = (nodes, folder) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { _ids: nodes.map((elem) => elem._id), folderId: folder._id },
      fetchPolicy: 'no-cache',
      mutation: queries.M_MOVE_MANY_PROFILE_FOLDERS,
    });
    dispatch({
      type: MOVE_PROFILE_FOLDERS,
      payload: {
        profiles: nodes,
        destination: folder,
      },
    });
    return response.data.moveManyProfileFolders;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
  }
};

export const updateManyProfileTemplates =
  (where: NexusGenInputs['ProfileTemplateWhereInput'], update: NexusGenInputs['ProfileTemplateUpdateInput']) =>
  async (dispatch: Dispatch): Promise<NexusGenFieldTypes['ProfileTemplate'][] & { graphQLErrors: GraphQLErrors }> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          where,
          update,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_MANY_PROFILE_TEMPLATES,
      });

      return response.data.updateManyProfileTemplates;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };
