import gql from 'graphql-tag';
import { LABEL_VALUE } from './label';

export const ELEMENT_TEMPLATE = gql`
  fragment elementTemplate on ElementTemplate {
    __typename
    title
    icon
    protected
    folder {
      parentsTree {
        _id
        name
      }
      parent {
        _id
        name
      }
      name
      _id
      protected
    }
    frame {
      inputs {
        ...input
      }
    }
    _id
  }
`;

export const ELEMENT_TEMPLATE_TO_TREE = gql`
  fragment elementTemplateToTree on ElementTemplate {
    __typename
    title
    icon
    protected
    folder {
      _id
      name
      parentsTree {
        name
        _id
      }
    }
    _id
  }
`;

export const ELEMENT = gql`
  fragment element on Element {
    __typename
    _id
    name
    elementsCount
    description
    deleted
    deletedAt
    deletedBy {
      name
    }
    imageDetails {
      position {
        x
        y
      }
      imageId
      zoomLevel
    }
    marker {
      anchorId
      description
      _id
    }
    markerId
    site {
      __typename
      name
      coordinates
      _id
      parentsTree {
        name
        _id
      }
    }
    template {
      ...elementTemplate
    }
    parent {
      __typename
      _id
      name
    }
    labelValues {
      ...labelValue
    }
    parentsTree {
      name
      _id
    }
    tags {
      name
      value
      key
      _id
      __typename
      unit {
        name
        symbol
        _id
      }
      device {
        name
        _id
      }
    }
    profile {
      __typename
      _id
      name
      description
      labelValues {
        ...labelValue
      }
      parent {
        _id
      }
      parentId
      parentsTreeIds
      folder {
        _id
        name
      }
      folderId
      template {
        title
      }
      _id
      inputs {
        ...input
      }
    }
    inputs {
      ...input
    }
    pendingInputs {
      ...input
    }
    pendingAt
    pendingBy {
      _id
      name
      username
      photo
    }
    needsApproval
    awaitingApproval
    responsibles {
      accounts {
        __typename
        username
        name
        email
        photo
        _id
      }
      labelValues {
        ...labelValue
      }
    }
    image {
      _id
      name
    }
    icon
    log {
      action
      field
      status
      type
      to {
        value
        labelValues {
          ...labelValue
        }
        tags {
          name
          value
          key
          _id
          __typename
          unit {
            name
            symbol
            _id
          }
        }
      }
      by {
        name
      }
      createdAt
    }
  }
  ${LABEL_VALUE}
`;

export const ELEMENT_TO_TREE = gql`
  fragment elementToTree on LeanElement {
    name
    __typename
    _id
    siteId
    parentId
    parentsTreeIds
    profileId
    tags {
      name
      value
      key
      unitId
      id: _id
      _id
      __typename
    }
    totalItems: hasChildren
    hasChildren
  }
`;

export const ELEMENT_TEMPLATE_FOLDER = gql`
  fragment elementTemplateFolder on ElementTemplateFolder {
    __typename
    name
    parent {
      _id
      name
    }
    totalItems: elementTemplatesCount(where: { archived_ne: true })
    totalFolders: elementTemplateFoldersCount
    parentsTreeCount
    elementTemplatesCount(where: { archived_ne: true })
    elementTemplateFoldersCount
    parentsTree {
      _id
      name
    }
    protected
    _id
  }
`;
