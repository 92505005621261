import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    menuTextSize: {
      fontSize: theme.typography.fontSize,
      fontFamily: '"Poppins", sans-serif;',
      color: theme.palette.text.primary,
    },
    iconSize: {
      fontSize: 20,
    },
    iconSite: {
      color: theme.palette.text.primary,
    },
    content: {
      background: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
  });
