import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Link, Typography } from '@mui/material';
import { ConnectedProps, connect } from 'react-redux';
import { styles } from 'src/base/on-boarding/components/styles';
import { useMainContainerContext } from 'src/base/main-container/context/main.container.context';
import { updateLoggedUser } from 'src/base/login/login.redux';
import { CheckCircle } from '@mui/icons-material';
import appleLogo from '@assets/images/appleLogo.svg';
import androidLogo from '@assets/images/androidLogo.svg';
import { deleteIssueCatalog } from 'src/modules/issue-catalogs/issue.catalogs.redux';
import { deleteIssue } from 'src/modules/issue-history/issue.history.redux';
import { startTutorial } from '../utils/onBoarding.utils.startTutorial';
import {
  createIssueTemplate,
  getIssueTemplateFolders,
  deleteIssueTemplate,
} from 'src/modules/issue-templates/issue.templates.redux';
import DefaultButton from 'src/utils/components/default-button/default-button';
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type FinishTutorialModalProps = {
  loggedUser: any;
} & ConnectedProps<typeof connecter>;

const useStyles = makeStyles(styles);
const FinishTutorialModal: React.FC<FinishTutorialModalProps> = (props): JSX.Element => {
  const state = useMainContainerContext();
  const { updateMainContainerState } = state;
  const { loggedUser } = props;
  const classes: ClassNameMap<string> = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const deleteAllData = async () => {
    const dataToDelete = {
      template: loggedUser.onBoarding.template,
      openIssue: loggedUser.onBoarding.openIssue,
      issueInstance: loggedUser.onBoarding.issueInstance,
    };
    await props.deleteIssueTemplate(
      {
        title: dataToDelete.template.name,
        _id: dataToDelete.template._id,
      },
      state.doingOnBoarding,
    );
    await props.deleteIssueCatalog([dataToDelete.openIssue._id], state.doingOnBoarding);
    await props.deleteIssue(dataToDelete.issueInstance._id);
    await props.updateLoggedUser(
      {
        id: loggedUser._id,
        onBoarding: {
          ...loggedUser.onBoarding,
          templateDraft: null,
          template: null,
          openIssueDraft: null,
          openIssue: null,
          issueInstance: null,
          finished: true,
          doingOnboarding: false,
        },
      },
      false,
    );
  };

  return (
    <Dialog
      open={state.openEndOnBoardingModal}
      classes={{
        root: classes.endStepModal,
        paperWidthSm: classes.endOnboardingModalWidth,
      }}
    >
      <DialogTitle style={{ padding: '18px 24px 0 24px' }}>
        <CheckCircle fontSize={'large'} className={classes.checkCircle} />
        <Typography className={classes.finalTitle}>{t('youCompletedTutorial')}</Typography>
        <Typography className={classes.finalSubTitle}>{t('youAreReadyToUsePlatform')}</Typography>
      </DialogTitle>
      <Divider style={{ margin: '16px 24px' }} />
      <DialogContent className={classes.downloadContainer}>
        <Typography className={classes.downloadTitle}>{t('executeAnywhere')}</Typography>
        <Typography className={classes.downloadContent}>{t('downloadOurMobileApp')}</Typography>
        <div className={classes.appBtnsContainer}>
          <Link
            data-testid={'apple-store-button'}
            target={'_blanck'}
            className={classes.androidBtn}
            href={'https://apps.apple.com/pt/app/glarvision/id1528705539'}
          >
            <img src={appleLogo} className={classes.appleLogo} />
            <div className={classes.appBtnTextContainer}>
              <Typography className={classes.androidBtnPrimaryText}>{t('downloadOn')}</Typography>
              <Typography className={classes.androidBtnSecondaryText}>{'Apple Store'}</Typography>
            </div>
          </Link>
          <Link
            data-testid={'play-store-button'}
            target={'_blanck'}
            className={classes.androidBtn}
            href={'https://play.google.com/store/apps/details?id=com.glartek.glarmobile'}
          >
            <img src={androidLogo} className={classes.googleLogo} />
            <div style={{ marginLeft: '-4px' }} className={classes.appBtnTextContainer}>
              <Typography className={classes.androidBtnPrimaryText}>{t('getOn')}</Typography>
              <Typography className={classes.androidBtnSecondaryText}>{'Google Play'}</Typography>
            </div>
          </Link>
        </div>
      </DialogContent>
      <DialogActions classes={{ root: classes.skipStartContainer }}>
        <DefaultButton
          data-testid={'restart-tutorial-button'}
          variant={'outlined'}
          className={classes.defaultBtnPadding}
          onClick={async () => {
            deleteAllData().then(() => startTutorial(props, updateMainContainerState, navigate));
          }}
          label={t('restartTutorial')}
        />
        <DefaultButton
          data-testid={'leave-tutorial-button'}
          className={classes.defaultBtnPadding}
          onClick={async () => {
            deleteAllData().then(() => {
              updateMainContainerState({ openEndOnBoardingModal: false, doingOnBoarding: false });
              navigate('/');
            });
          }}
          label={t('leaveTutorialMode')}
        />
      </DialogActions>
    </Dialog>
  );
};

const connecter = connect(
  (state: any) => ({
    loggedUser: state.loginReducer.loggedUser,
  }),
  {
    updateLoggedUser,
    deleteIssue,
    deleteIssueCatalog,
    deleteIssueTemplate,
    createIssueTemplate,
    getIssueTemplateFolders,
  },
);

export default connecter(FinishTutorialModal);
