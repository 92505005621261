import { IAgoraRTCRemoteUser, UID } from 'agora-rtc-sdk-ng';
import { AgoraIOUsers } from 'src/interfaces/remote-assistance/agoraIO';

export enum LiveModeTypes {
  'ON_HOLD' = -1,
  'AR' = 0,
  '2D' = 1,
}

export interface StartedByProps {
  session_id: string;
  user_id: string;
}

export interface ImageFreeze {
  width: number;
  height: number;
  imageURL: string;
  sessionId: string;
}

export interface FreezeEvents {
  [sessionId: string]: FreezeEvent;
}

export interface FreezeEvent {
  events: string[];
}

export interface RoomDataCheck {
  imageFreeze: ImageFreeze;
  events: FreezeEvents;
}

export enum ScreenShareEnum {
  NO_SHARING = 'no_sharing',
  ME_SHARING = 'me_sharing',
}

export type ScreenShareType = UID | ScreenShareEnum;

export enum ScreenShareDialogType {
  STOP = 'stop',
  ASK = 'ask',
}

export enum AnnotationType {
  MOUSE_DOWN = 'mousedown',
  MOUSE_UP = 'mouseup',
  MOUSE_MOVE = 'mousemove',
}

export interface TextBubbleUpdate {
  sessionId: string;
  key: string;
  message: string;
}

export interface DispatchedAnnotation {
  x: number;
  y: number;
  timestamp: number;
  type: 'mousedown' | 'mouseup' | 'mousemove';
  isArrow?: boolean;
  text?: string;
  colorIndex?: number;
}

export interface Annotation {
  x: number;
  y: number;
  timestamp?: number;
  colorIndex?: number;
  color?: string;
  number?: number;
}

export interface TextAnnotation {
  key?: string;
  x?: number;
  y?: number;
  timestamp?: number;
  value?: string;
  colorIndex?: number;
  color?: string;
}

export interface FreezeLines {
  lines: Annotation[];
  timestamp?: number;
}

export interface SessionAnnotations {
  arrows: Annotation[];
  lines?: Annotation[];
  freezeLines: FreezeLines[];
  texts: TextAnnotation[];
}

export interface WebMobileAnnotations {
  [sessionId: string]: SessionAnnotations;
}

export interface EventAnnotation {
  type: AnnotationType;
  annotation: DispatchedAnnotation;
}

export interface LastEventAnnotation {
  [sessionId: string]: EventAnnotation;
}

export interface AgoraStateProps {
  audioState?: boolean;
  videoState?: boolean;
  agoraRtc?: boolean;
  buttonsEnable?: boolean;
  usersAgora?: AgoraIOUsers | IAgoraRTCRemoteUser;
  meUid?: string;
  uId?: string;
  recordStatus?: boolean;
  recordEnd?: number;
  recordStartedBy?: StartedByProps;
  microphoneVolume?: number;
  requiresMicrophone?: boolean;
  id?: string;
  remoteNetworkQuality?: {
    bitRate: number;
    frameRate: number;
    networkQuality: number;
    resolutionWidth: number;
    resolutionHeight: number;
  } | null;
  autoplayFailed?: boolean;
  screenShare?: {
    status: ScreenShareType;
    loading: boolean;
    isUserSelecting: boolean;
    showDialog: null | ScreenShareDialogType;
  };
  videoShare?: {
    status: boolean;
    loading: boolean;
  };
  imageFreeze?: ImageFreeze | null;
  freezeEvents?: FreezeEvents | null;
  disableFreezeButton?: boolean;
  outdatedAppEvents?: number;

  // web mobile
  hasOwnAnnotations?: boolean;
  webMobileAnnotations?: WebMobileAnnotations;
  toggleTextBubbles?: boolean;
  isOpenTextBubble?: boolean;
  textBubbleValue?: string;
  deletedFreezeAnnotations?: boolean;
  undoFreezeChanges?: number;
  sessionId?: string;
  annotation?: TextAnnotation | Annotation | FreezeLines | DispatchedAnnotation;
}

export interface WebMobileAnnotation {
  sessionId: string;
  annotation: DispatchedAnnotation;
}
