import { useCallback } from 'react';
import * as React from 'react';

export default function useAtomicCallback<T extends any[], R>(
  callback: (...args: T) => Promise<R>,
  dependencies: React.DependencyList,
): (...args: T) => Promise<R | undefined> {
  const waiting = React.useRef(false);

  return useCallback((...args: Parameters<typeof callback>) => {
    if (waiting.current === false) {
      waiting.current = true;

      return Promise.resolve(callback(...args)).then(
        (x) => {
          waiting.current = false;
          return x;
        },
        () => {
          waiting.current = false;
          return undefined;
        },
      );
    }

    return Promise.resolve(undefined);
  }, dependencies);
}
