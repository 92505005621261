import { APOLLO_CLIENT } from 'config/apollo.config';
import { Action, State } from 'src/interfaces/reducers';
import i18n from 'src/utils/translations/i18n';
import { addSpace, getErrorObject } from 'src/utils/funcs';
import * as queries from 'src/modules/label/label.queries';
import { Dispatch } from 'redux';
import { Label, LabelValue, LabelWhereInput } from 'src/gql/graphql';
import { getDefaultTenant } from '../tenants/tenant.redux';

const initialState: State = {};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    default:
      return state;
  }
};

export const getLabels =
  (filter: LabelWhereInput, skip?: number, limit?: number) =>
  async (dispatch: Dispatch): Promise<Label[]> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          filter,
          skip,
          limit,
          tenant: getDefaultTenant(),
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_LABELS,
      });

      return response.data.labels;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const createLabel =
  (labelData) =>
  async (dispatch: Dispatch): Promise<unknown> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          name: labelData.name,
          context: labelData.context,
          singleSelection: labelData.singleSelection,
          order: labelData.order,
        },
        mutation: queries.M_CREATE_LABEL,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: addSpace(labelData.name) + i18n.t('toastCreateSuccess'),
          severity: 'success',
        },
      });
      return response.data.createLabel;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const createValue =
  (labelId, name, backgroundColor, description) =>
  async (dispatch: Dispatch): Promise<unknown> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          name,
          labelId,
          backgroundColor,
          description,
        },
        mutation: queries.M_CREATE_VALUE,
      });

      return response.data.createLabelValue;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const createManyValues =
  (data) =>
  async (dispatch: Dispatch): Promise<unknown> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          data,
        },
        mutation: queries.M_CREATE_MANY_VALUES,
      });

      return response.data.createManyLabelValues;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const updateLabel =
  (id, name, context) =>
  async (dispatch: Dispatch): Promise<object> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          name: name,
          _id: id,
          context,
        },
        mutation: queries.M_UPDATE_LABEL,
      });

      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('group') + ' ' + i18n.t('toastUpdateSuccess'),
          severity: 'success',
        },
      });
      return response.data.updateLabel;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const updateLabels =
  (_ids: string[]) =>
  async (dispatch: Dispatch): Promise<object> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          _ids,
        },
        mutation: queries.M_UPDATE_LABELS,
      });

      return response.data.changeLabelsOrder;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const changeLabelValuesOrder =
  (labelId: string, labelValues: { _id: string; order: number }[]) =>
  async (dispatch: Dispatch): Promise<object> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          labelId,
          labelValues,
        },
        mutation: queries.M_CHANGE_LABEL_VALUES_ORDER,
      });

      return response.data.changeLabelValuesOrder;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const deleteValue =
  (valueId) =>
  async (dispatch: Dispatch): Promise<void> => {
    // access the backend and get the access token
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          _id: valueId,
        },
        mutation: queries.M_DELETE_VALUE,
      });

      return response.data.deleteOneLabelValue;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
    }
  };

export const updateValue =
  (labelId, id, name, backgroundColor, description) =>
  async (dispatch: Dispatch): Promise<object> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          name,
          labelId,
          _id: id,
          backgroundColor,
          description,
        },
        mutation: queries.M_UPDATE_VALUE,
      });

      return response.data.updateLabelValue.label;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const deleteLabel =
  (id) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await APOLLO_CLIENT.mutate({
        variables: {
          _id: id,
        },
        mutation: queries.M_DELETE_LABEL,
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const labelValuesPaginated =
  (page: number, limit: number, filter) =>
  async (dispatch: Dispatch): Promise<{ labelValues: LabelValue[]; count: number }> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          limit,
          skip: page * limit,
          filter,
          tenant: getDefaultTenant(),
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_LABEL_VALUES_PAGINATED,
      });

      const count = await APOLLO_CLIENT.query({
        variables: {
          where: filter,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_LABEL_VALUES_COUNT,
      });

      return {
        labelValues: response.data.labelValues,
        count: count.data.labelValuesCount,
      };
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const filterBuilderConditionLabelValues = (filter) => async (dispatch: Dispatch) => {
  try {
    const response: { data: { filterBuilderConditionLabelValues: LabelValue[] } } = await APOLLO_CLIENT.query({
      variables: {
        filter,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_FILTER_BUILDER_CONDITION_LABEL_VALUES,
    });

    return response.data.filterBuilderConditionLabelValues;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};
