import { Visibility, VisibilityOff, VpnKey } from '@mui/icons-material';
import { Box, FormGroup, Grid, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps } from 'react-redux';
import { styles } from 'src/base/login/styles/login';
import { TenantBasicInfo } from 'src/gql/graphql';
import { getTenantInfo } from 'src/modules/authentication/authentication.redux';
import DefaultButton from 'src/utils/components/default-button';
import { typedConnect } from 'src/utils/funcs';

interface CredentialForm extends ConnectedProps<typeof connector> {
  userRef: React.RefObject<HTMLInputElement>;
  pwRef: React.RefObject<HTMLInputElement>;
  domainRef: React.RefObject<HTMLInputElement>;
  callback: () => void;
  recover: () => void;
  saving: boolean;
  emailError: boolean;
}

const useStyles = makeStyles(styles);

const connector = typedConnect((state) => ({ tenantInfo: state.authenticationReducer.tenantInfo }), { getTenantInfo });

const CredentialForm = (props: CredentialForm) => {
  const { userRef, pwRef, callback, recover, saving, domainRef } = props;

  const { t } = useTranslation();
  const splitHostname = window.location.hostname.split('.');
  const classes = useStyles();
  const [seePass, setSeePass] = useState<boolean>(false);
  const [domain, setDomain] = useState<string | undefined>(
    /(.glarcloud.com)$/i.exec(window.location.hostname) ||
      /^(?!app\.glartek\.com$).*\.glartek\.com$/i.exec(window.location.hostname)
      ? splitHostname.length > 3
        ? `${splitHostname[splitHostname.length - 4]}.${splitHostname[splitHostname.length - 3]}`
        : splitHostname[0]
      : '',
  );
  const [showDomainCard, setShowDomainCard] = useState<boolean>(false);
  const [tenantInfo, setTenantInfo] = useState<TenantBasicInfo>(null);

  useEffect(() => {
    props
      .getTenantInfo(domain)
      .then((resp) => {
        if (domain) domainRef.current.value = domain;
        setTenantInfo(resp);
      })
      .catch(() => {
        setDomain('');
        setShowDomainCard(false);
      });
  }, [JSON.stringify(domain)]);

  return (
    <>
      <div style={showDomainCard && !domain ? {} : { display: 'none' }}>
        <FormGroup classes={{ root: classes.formGroup }}>
          <div className={classes.label}>{t('domain')}</div>
          <OutlinedInput
            disabled={saving}
            classes={{ root: classes.inputs }}
            inputRef={domainRef}
            id={'inputDomain'}
            data-testid={'inputDomain'}
            aria-label='domainInput'
            type='text'
            autoComplete='off'
            onKeyPress={(e) => {
              if (e.key === 'Enter' && 'value' in domainRef.current) {
                setDomain(domainRef.current.value.trim());
              }
            }}
          />
        </FormGroup>
        <FormGroup classes={{ root: classes.formGroup }}>
          <Box
            style={{ marginTop: '25px', alignSelf: 'center' }}
            flexDirection='column'
            justifyContent='flex-end'
            alignItems='flex-end'
          >
            <DefaultButton
              id={'buttonReturnToLogin'}
              data-testid={'buttonReturnToLogin'}
              aria-label={'buttonReturnToLogin'}
              loader={saving}
              label={t('returnLogin')}
              discard
              onClick={() => {
                setShowDomainCard(false);
              }}
            />
            <DefaultButton
              id={'submitDomain'}
              data-testid={'submitDomain'}
              aria-label={'submitDomain'}
              loader={saving}
              label={t('submit')}
              success
              onClick={() => {
                if ('value' in domainRef.current) {
                  setDomain(domainRef.current.value.trim());
                }
              }}
            />
          </Box>
        </FormGroup>
      </div>
      {showDomainCard && !domain ? undefined : (
        <form>
          <FormGroup classes={{ root: classes.formGroup }}>
            <div className={classes.label}>{t('email')}</div>
            <OutlinedInput
              disabled={saving}
              classes={{ root: classes.inputs }}
              inputRef={userRef}
              id={'inputUsernameLogin'}
              data-testid={'inputUsernameLogin'}
              aria-label='usernameInput'
              type='text'
              autoComplete='off'
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  if ('value' in domainRef.current) {
                    domainRef.current.value = domain;
                  }
                  callback();
                }
              }}
            />
            {/* {emailError && <InputHelperText error>{t('enterValidEmail')}</InputHelperText>} */}
          </FormGroup>
          <FormGroup classes={{ root: classes.formGroup }}>
            <div className={classes.label}>{t('accountPassword')}</div>
            <OutlinedInput
              disabled={saving}
              classes={{ root: classes.inputs }}
              inputRef={pwRef}
              id={'inputPasswordLogin'}
              data-testid={'inputPasswordLogin'}
              aria-label={'passwordInput'}
              className='form-control'
              type={seePass ? 'text' : 'password'}
              autoComplete='off'
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  if ('value' in domainRef.current) {
                    domainRef.current.value = domain;
                  }
                  callback();
                }
              }}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    id='togglePasswordVisibilityButton'
                    data-testid='togglePasswordVisibilityButton'
                    aria-label='togglePasswordVisibilityButton'
                    onClick={() => setSeePass(!seePass)}
                    edge='end'
                    size='large'
                  >
                    {seePass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormGroup>
          <Grid container classes={{ root: classes.forgotPasswordRow }}>
            <a
              className={classes.link}
              onClick={recover}
              id={'anchorForgotPassword'}
              data-testid={'anchorForgotPassword'}
            >
              {t('forgotPassword')}
            </a>
          </Grid>
          <FormGroup classes={{ root: classes.formGroup }}>
            <div style={{ marginTop: '25px', width: '100%', display: 'flex', justifyContent: 'center' }}>
              {domain ? undefined : (
                <DefaultButton
                  aria-label='domainButton'
                  id={'buttonDomain'}
                  data-testid={'buttonDomain'}
                  label={t('domain')}
                  discard
                  onClick={() => setShowDomainCard(true)}
                />
              )}
              {tenantInfo?.samlAuthEndpoint ? (
                <DefaultButton
                  id='SSOButton'
                  data-testid='SSOButton'
                  aria-label='SSOButton'
                  label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <VpnKey style={{ fontSize: '16px', marginRight: '5px' }} />
                      {t('SSO')}
                    </div>
                  }
                  discard
                  onClick={() => {
                    const link = document.createElement('a');
                    if (tenantInfo) {
                      link.href = `${window.location.origin}/${tenantInfo.samlAuthEndpoint}`;
                    }
                    document.body.appendChild(link);
                    link.click();
                  }}
                />
              ) : null}
              <DefaultButton
                id={'buttonLogin'}
                data-testid={'buttonLogin'}
                aria-label='loginButton'
                loader={saving}
                enzyme-attr='a-login'
                label={t('login')}
                success
                onClick={() => {
                  if ('value' in domainRef.current) {
                    domainRef.current.value = domain;
                  }
                  callback();
                }}
              />
            </div>
          </FormGroup>
        </form>
      )}
    </>
  );
};

export default connector(CredentialForm);
