import gql from 'graphql-tag';

export const M_UPDATE_TIME_ZONE = gql`
  mutation UpdateTenantTimeZone($_id: ID!, $timeZone: String!) {
    updateTenantTimeZone(where: { _id: $_id }, timeZone: $timeZone)
  }
`;

export const M_UPDATE_PALETTE = gql`
  mutation UpdateTenantPalette(
    $_id: ID!
    $paletteLight: TenantConfigPaletteLightUpdateInput!
    $paletteDark: TenantConfigPaletteDarkUpdateInput!
  ) {
    updateTenantPalette(where: { _id: $_id }, paletteLight: $paletteLight, paletteDark: $paletteDark) {
      _id
    }
  }
`;

export const M_GET_UPLOAD_LOGO_LIGHT = gql`
  mutation GetUploadLogoLight($name: String!, $size: Int!) {
    getUploadLogoLightFileRequest(name: $name, size: $size) {
      url
      formData
      validUntil
    }
  }
`;

export const M_GET_UPLOAD_LOGO_DARK = gql`
  mutation GetUploadLogoDark($name: String!, $size: Int!) {
    getUploadLogoDarkFileRequest(name: $name, size: $size) {
      url
      formData
      validUntil
    }
  }
`;
