import { Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingDiv: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '80px',
      marginBottom: '80px',
    },
    uploadInput: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: '0',
      top: '0',
      opacity: '0',
      cursor: 'pointer',
    },
    uploadDiv: {
      cursor: 'pointer',
      position: 'relative',
      width: 'fit-content',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px solid ${theme.palette.primary.light}`,
      borderRadius: '4px',
      gap: 8,
      padding: '4px 12px',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        border: `1px solid ${theme.palette.primary.light}`,
      },
    },
    text: {
      color: theme.palette.primary.main,
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '16px',
    },
    addIcon: {
      color: theme.palette.primary.main,
      fontSize: '21px',
    },
    fileBtns: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      margin: '8px 8px 8px 0',
      gap: 16,
    },
    bold: {
      fontFamily: 'Poppins',
      fontWeight: 700,
    },
  }),
);
