import { CardContent, IconButton, InputBase, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styles } from './feed.styles';
import DefaultButton from 'src/utils/components/default-button/default-button';
import { ArrowBackIos, Close, SearchOutlined } from '@mui/icons-material';
import { stringifyUrl } from 'query-string';
import useDebouncedCallback from 'src/utils/hooks/useDebouncedCallback';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { changeFeedFilters } from './feed.redux';
import { ConnectedProps, connect } from 'react-redux';
import { hasPermission } from 'src/utils/funcs';
import { withStyles, WithStyles } from '@mui/styles';
import FeedFilterPopover from 'src/utils/components/glar-filter-popover/preset-filters/feed-filter';
import GlarFilterButton from 'src/utils/components/glar-filter-popover/glar-filter-button';
import { countFilters } from 'src/utils/components/glar-filter-popover';
import { getDateToFilterQuery } from 'src/utils/components/glar-filter-popover/helper';

const connector = connect(
  (state: any) => ({
    isCreator: !hasPermission(state.loginReducer.loggedUser.myClearances, 'Feed', 'Viewer'),
  }),
  { changeFeedFilters },
);

const FeedPage = connector(
  withStyles(styles)(function (
    props: { children: React.FC; type: 'create' | 'view' } & ConnectedProps<typeof connector> &
      WithStyles<typeof styles>,
  ) {
    const { classes, children, isCreator, type } = props;
    const [showSearch, setShowSearch] = useState(false);
    const [filters, setFilters] = useState({});
    const [openFilterPopover, setOpenFilterPopover] = useState(false);

    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const { id, commentId, search } = queryString.parse(location.search) as Record<string, string>;

    const buildQueryFilters = (filters) => {
      return {
        ...filters,
        createdAt: {
          from: getDateToFilterQuery(filters?.createdAt, 'from'),
          to: getDateToFilterQuery(filters?.createdAt, 'to'),
        },
      };
    };

    useEffect(() => {
      if (id) {
        props.changeFeedFilters({
          id,
          commentId,
        });
      } else {
        const where = buildQueryFilters(filters);

        props.changeFeedFilters({
          ...where,
          search,
        });
      }
    }, [id, commentId, search, filters]);

    const changeSearchFilter = useDebouncedCallback(
      (val?: string) => {
        navigate(
          stringifyUrl({
            url: location.pathname,
            query: {
              search: val ? val : undefined,
            },
          }),
        );
      },
      400,
      [],
    );

    return (
      <CardContent className={`animated fadeIn ${classes.cardContent}`}>
        <>
          <div className={classes.topBar}>
            {type === 'view' ? (
              <>
                <Typography variant={'h6'} color={'primary'}>
                  {t('feed')}
                </Typography>
                {showSearch ? (
                  <InputBase
                    data-testid={'post-search'}
                    defaultValue={search ?? ''}
                    placeholder={t('search') + '...'}
                    className={`${classes.marginLeft} ${classes.border}`}
                    classes={{ input: classes.input }}
                    onChange={(e) => {
                      const val = e.target.value;
                      changeSearchFilter(val);
                    }}
                    onKeyUp={(e) => {
                      if (e.key === 'Escape') {
                        setShowSearch(false);
                        changeSearchFilter();
                      }
                    }}
                    endAdornment={
                      <IconButton
                        data-testid={'hide-search'}
                        style={{ padding: 0 }}
                        onClick={() => {
                          changeSearchFilter();
                          setShowSearch(false);
                        }}
                      >
                        <Close fontSize={'small'} />
                      </IconButton>
                    }
                  />
                ) : (
                  <IconButton
                    data-testid={'feed-search'}
                    style={{ padding: 0 }}
                    className={classes.marginLeft}
                    onClick={() => setShowSearch(true)}
                  >
                    <SearchOutlined />
                  </IconButton>
                )}
                <GlarFilterButton
                  data-testid={'filter-feed'}
                  onClick={() => setOpenFilterPopover(true)}
                  filterCount={countFilters(filters)}
                />
                {isCreator && (
                  <DefaultButton
                    data-testid={'create-post-feed'}
                    variant={'default'}
                    onClick={() =>
                      navigate(
                        stringifyUrl({
                          url: '/feed/create-post',
                        }),
                      )
                    }
                  >
                    {t('createPost')}
                  </DefaultButton>
                )}
              </>
            ) : (
              <>
                <ArrowBackIos
                  style={{ cursor: 'pointer' }}
                  color={'primary'}
                  fontSize='small'
                  onClick={() => navigate(-1)}
                />
                <Typography variant={'h6'} color={'primary'}>
                  {t('createPost')}
                </Typography>
              </>
            )}
          </div>
          {children}
          {openFilterPopover && (
            <FeedFilterPopover
              onClose={() => setOpenFilterPopover(false)}
              onSubmit={setFilters}
              defaultFilters={filters}
            />
          )}
        </>
      </CardContent>
    );
  }),
);

export default FeedPage;
