import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export default function styles(theme: Theme) {
  return createStyles({
    '@global': {
      '.MuiAccordion-root::before': {
        opacity: '1 !important',
      },
    },
    glarfilterContainer: {
      width: '100%',
      paddingInline: 24,
      padding: 8,
      gap: 8,
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexFlow: 'row wrap',
    },
    popoverContainer: {
      height: '100%',
      left: 'unset !important',
      right: '0',
      top: '0 !important',
      width: '392px',
      maxHeight: 'unset !important',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    popoverDivTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '14px 20px',
    },
    popoverTitleSpan: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '20px',
      display: 'flex',
      alignItems: 'center',
    },
    closeIconTitle: {
      fontSize: '20px',
      cursor: 'pointer',
    },
    fixedButtons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      gap: 8,
      padding: 16,
    },
    fieldTitleContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    fieldTitle: {
      fontWeight: 550,
      fontSize: 16,
    },
    fieldPreview: {
      fontSize: 14,
    },
    verticalListing: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    rows: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      flex: 1,
      overflow: 'auto',
    },
    wideGap: {
      gap: 16,
    },
    horizontal: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 8,
    },
    datePresets: {
      width: '100%',
      padding: '4px 0',
      fontSize: 16,
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.light,
      },
    },
    chip: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      lineHeight: '22px',
      letterSpacing: '1.25px',
      marginLeft: '5px',
      userSelect: 'none',
      backgroundColor: `${theme.palette.primary.light} !important`,
      fontWeight: '500',
      color: theme.palette.text.primary,
    },
    noExpand: {
      marginTop: '0px !important',
      marginBottom: '0px !important',
      transition: 'none !important',
    },
    fullWidth: {
      width: '100%',
    },
    selectInput: {
      height: '40px',
      '&.MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.grey.light,
        },
        '&:hover fieldset': {
          borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.primary.main,
          borderWidth: '1px',
        },
      },
    },
    menuItem: {
      padding: '8px 16px',
      backgroundColor: `${theme.palette.background.default} !important`,
      '&:hover': { backgroundColor: `${theme.palette.primary.light} !important` },
    },
    listItemText: {
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
    },
    selectedText: {
      color: theme.palette.primary.main,
    },
    textFieldMenuItem: {
      position: 'sticky',
      top: 0,
      zIndex: 1,
      backgroundColor: `${theme.palette.background.default} !important`,
      padding: 0,
      borderBottom: `1px solid ${theme.palette.grey.light}`,
    },
    selectedCounter: {
      color: theme.palette.primary.main,
      textAlign: 'right',
    },
    filterToastBar: {
      width: '100%',
      padding: 8,
      gap: 8,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexFlow: 'row wrap',
      maxHeight: `82px`, //Height of 2 rows of chips
      overflow: 'hidden',
      flexShrink: 0,
    },
  });
}
