import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

export const styles = makeStyles((theme: Theme) =>
  createStyles({
    document: {
      borderColor: theme.palette.grey.light,
      padding: '12px 8px 12px 8px',
      borderRadius: '4px',
      borderWidth: '0.4px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '56px',
      minWidth: '192px',
    },
    documentName: {
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontSize: '11px',
      lineHeight: '16px',
      color: theme.palette.primary.main,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      textWrap: 'nowrap',
      maxWidth: '80%',
    },
    pdfIcon: {
      width: '16px',
      height: '16px',
    },
    topContainer: { gap: '4px', display: 'flex' },
    bottomContainer: { padding: '0px 16px 0px 20px', display: 'flex', gap: '8px', alignItems: 'center' },
    baseContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      cursor: 'pointer',
      width: 'calc(100% - 12px)',
    },
    documentDescription: {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '11px',
      lineHeight: '16px',
      color: theme.palette.grey.main,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      textWrap: 'nowrap',
    },
    iconDelete: {
      fontSize: '16px !important',
      color: theme.palette.error.main,
      cursor: 'pointer',
    },
    iconDownload: {
      fontSize: '16px !important',
      color: theme.palette.secondary.main,
    },
    plusText: {
      fontFamily: 'Poppins',
      color: theme.palette.background.default,
      fontSize: '22px',
      lineHeight: '32px',
      fontWeight: '500',
      position: 'absolute',
    },
    plusDiv: {
      position: 'absolute',
      backgroundColor: 'rgba(0,0,0, 0.4)',
      display: 'flex',
      height: 'calc(100% - 8px)',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'calc(100% - 8px)',
      borderRadius: '4px',
    },
    documentLoading: {
      backgroundColor: theme.palette.primary.main,
    },
  }),
);
