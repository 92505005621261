import { Tooltip, useTheme } from '@mui/material';
import { identity } from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Cached } from '@mui/icons-material';
import ReactLoading from 'react-loading';

type RefreshableProps = {
  onTick: (force?: boolean) => any;
  hide?: boolean;
  loading?: boolean;
  refreshTime?: number;
  'data-testid'?: string;
};

export default function Refreshable(props: RefreshableProps) {
  const { onTick, refreshTime, hide = false, loading = false } = props;

  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    onTick();

    if (!refreshTime) {
      return identity;
    }

    const cleanup = setInterval(() => onTick(true), refreshTime * 1000);

    return () => {
      clearInterval(cleanup);
    };
  }, [refreshTime]);

  const commonProps = {
    onClick: () => onTick(true),
    'data-testid': props['data-testid'],
  };

  return !hide ? (
    <>
      {loading ? (
        <ReactLoading
          css={{ marginRight: 2, cursor: 'not-allowed' }}
          type={'spin'}
          color={theme.palette.primary.dark}
          {...commonProps}
          width={20}
          height={20}
        />
      ) : (
        <Tooltip placement={'top'} arrow title={t('updateCard').toString()}>
          <Cached css={{ cursor: 'pointer' }} {...commonProps} color={undefined} />
        </Tooltip>
      )}
    </>
  ) : null;
}
