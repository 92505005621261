import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import * as React from 'react';
import { useState } from 'react';
import { styles } from 'src/utils/components/expandable-section/styles';

interface ExpandableSectionProps {
  classes: Record<string, string>;
  title: string;
  hide?: boolean;
  children?: React.ReactNode;
}

const ExpandableSection: React.FC<ExpandableSectionProps> = (props) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <>
      {!props.hide ? (
        <div className={props.classes.section}>
          <div className={props.classes.header} onClick={() => setExpanded(!expanded)}>
            <span className={props.classes.title}>{props.title}</span>
            <span className={props.classes.expandIcon}>
              {expanded ? <ExpandLess></ExpandLess> : <ExpandMore></ExpandMore>}
            </span>
          </div>
          <div className={expanded ? props.classes.content : props.classes.displayNone}>{props.children}</div>
        </div>
      ) : (
        props.children
      )}
    </>
  );
};

export default withStyles(styles)(ExpandableSection);
