import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    divItem: {
      padding: '6px 16px 6px 16px',
      fontFamily: 'Open Sans',
      display: 'flex',
      alignItems: 'center',
      height: '36px',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    dragIcon: {
      color: theme.palette.text.disabled,
      marginRight: '8px',
      cursor: 'pointer',
    },
    visibilityIcon: {
      marginLeft: 'auto',
      marginRight: 0,
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    onOverTop: {
      borderTop: `4px solid ${theme.palette.primary.main}`,
    },
    onOverBottom: {
      borderBottom: `4px solid ${theme.palette.primary.main}`,
    },
  });
