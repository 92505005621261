import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    backContainer: {
      padding: '8px 12px',
      color: theme.palette.text.primary,
      fontFamily: 'Open Sans',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '&:hover': {
        background: theme.palette.primary.light,
        color: theme.palette.primary.main,
        '& svg': {
          color: theme.palette.primary.main,
        },
      },
    },
    spanParent: {
      alignSelf: 'center',
      fontFamily: 'Open Sans',
      fontSize: '14px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '-webkit-line-clamp': '2',
      display: '-webkit-box',
      marginRight: '16px',
      '-webkit-box-orient': 'vertical',
      '-ms-hyphens': 'auto',
      '-moz-hyphens': 'auto',
      '-webkit-hyphens': 'auto',
      hyphens: 'auto',
    },
    arrowLeftIcon: {
      marginRight: '5px',
      color: theme.palette.text.primary,
      cursor: 'pointer',
    },
    divider: {
      opacity: '0.3 !important',
      color: theme.palette.grey.light,
      borderWidth: 1,
      marginBottom: 0,
    },
    searchInput: {
      marginTop: 8,
      padding: '0 16px',
      fontSize: 14,
      '& input': {
        padding: '0 !important',
      },
    },
    searchIcon: {
      opacity: '0.8 !important',
      color: theme.palette.grey.light,
    },
  });
