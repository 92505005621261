import * as React from 'react';
import { styles } from './styles';
import makeStyles from '@mui/styles/makeStyles';
import { LabelValue as GQLLabelValue } from 'src/gql/graphql';
import ChipLabel from './chip-label';
import ChipAccount from './chip-account';
import { Account as GQLAccount } from 'src/gql/graphql';

type Account = Pick<GQLAccount, '_id' | 'name' | 'photo'>;

type LabelValue = Pick<GQLLabelValue, '_id' | 'backgroundColor' | 'label' | 'value' | 'description'>;

export type ChipLabelAccountProps = {
  /** Array of Label Values with required fields: _id, backgroundColor, label, value and description */
  labelValues?: LabelValue[];
  accounts?: Account[];
  sideMenu?: boolean;
  'data-testid': string;
};

const useStyles = makeStyles(styles);

const ChipLabelAccount: React.FC<ChipLabelAccountProps> = (props) => {
  const { labelValues, accounts, sideMenu, 'data-testid': dataTestId } = props;
  const classes = useStyles();

  return (
    <div
      data-testid={dataTestId}
      className={sideMenu ? classes.divAssigneesColumnsSideMenu : classes.divAssigneesColumns}
    >
      {labelValues?.length ? <ChipLabel sideMenu={sideMenu} labelValues={labelValues} /> : null}
      {accounts?.length ? <ChipAccount sideMenu={sideMenu} accounts={accounts} /> : null}
      {!labelValues?.length && !accounts?.length ? ' - ' : null}
    </div>
  );
};

export default ChipLabelAccount;
