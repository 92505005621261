import {
  AccountFilterFilter,
  AndAgendaJobsWhereInput,
  DataIssueWhere,
  Element,
  JobWhereInput,
  Site,
} from 'src/gql/graphql';
import { buildAndConditions } from './buildLogicalConditions';
import { findFilterBaseData, findFilterValue } from './agenda.filter.findFilterValue';
import { isEmpty, map } from 'lodash';
import { getDateToFilterQuery } from 'src/utils/components/glar-filter-popover/helper';

export function buildDataIssueAndCondition(
  filters: AccountFilterFilter[],
  accessToSites?: Site[],
  accessToElements?: Element[],
): AndAgendaJobsWhereInput[] {
  const {
    sites,
    elements,
    rootElements,
    rootSites,
    parentElements,
    parentSites,
    labelValues,
    assignedLabelValues,
    assignedAccounts,
    search,
  } = findFilterBaseData(filters);

  return [
    ...buildAndConditions({
      accessToSites,
      accessToElements,
      elements,
      sites,
      rootElements,
      rootSites,
      parentElements,
      parentSites,
      search,
      isInstance: false,
    }),
    {
      OR: [
        {
          assignedLabelValues_in: assignedLabelValues?.length ? map(assignedLabelValues, '_id') : undefined,
          assignedAccounts_in: assignedAccounts?.length ? map(assignedAccounts, '_id') : undefined,
          labelValues_in: labelValues?.length ? map(labelValues, '_id') : undefined,
        },
      ],
    },
  ]
    .map((c) => ({
      OR: c.OR.filter((o) => Object.values(o)?.some((o) => !!o)).map((o) => ({
        data: {
          issue: o,
        },
      })),
    }))
    .filter((a) => a.OR.length);
}

interface BuildIssueInstanceToScheduleFilterArgs {
  filters: AccountFilterFilter[];
  initDate?: Date;
  finishDate?: Date;
  accessToSites?: Site[];
  accessToElements?: Element[];
}

export const buildIssueInstanceToScheduleFilter = (args: BuildIssueInstanceToScheduleFilterArgs) => {
  const { filters, initDate, finishDate, accessToSites = [], accessToElements = [] } = args;

  const and = buildDataIssueAndCondition(filters, accessToSites, accessToElements);

  const assignedAccounts = map(findFilterValue(filters, 'assignedAccounts'), '_id');
  const assignedLabelValues = map(findFilterValue(filters, 'assignedLabelValues'), '_id');
  const labelValues = map(findFilterValue(filters, 'labelValues'), '_id');
  const executedByFilter = map(findFilterValue(filters, 'executedBy'), '_id');
  const frequencyFilter = filters.find((elem: { name: string }) => elem.name === 'frequency');
  const state = findFilterValue(filters, 'state');
  const cancelledFilter = state?.some((state: string) => state === 'Canceled');
  const stateFilter = state?.filter((state: string) => state !== 'Canceled');

  const stateOrFilter = [];
  if (cancelledFilter)
    stateOrFilter.push({ canceledBy_exists: cancelledFilter }, { canceledBySystem_exists: cancelledFilter });
  if (state?.length) isEmpty(stateFilter) ? undefined : stateOrFilter.push({ currentState_in: stateFilter });

  const issueFilter: DataIssueWhere = {
    assignedAccounts_in: assignedAccounts?.length ? assignedAccounts : undefined,
    assignedLabelValues_in: assignedLabelValues?.length ? assignedLabelValues : undefined,
    labelValues_in: labelValues?.length ? labelValues : undefined,
    OR: stateOrFilter.length ? stateOrFilter : [{}],
    instance: executedByFilter?.length
      ? { executedBy_in: executedByFilter.length ? executedByFilter : undefined }
      : undefined,
    frequency: frequencyFilter?.frequency?.length ? { timeUnit_eq: frequencyFilter.frequency[0] as any } : undefined,
  };

  const dataFilter = {
    startDate_gte: getDateToFilterQuery(initDate, 'from') ?? initDate,
    startDate_lt: getDateToFilterQuery(finishDate, 'to') ?? finishDate,
    issue: Object.values(issueFilter)?.some((f) => !!f) ? issueFilter : undefined,
    issue_exists: true,
  };

  const filterInstances: JobWhereInput = {
    data: Object.values(dataFilter)?.some((f) => !!f) ? dataFilter : undefined,
    OR: [{ name_eq: 'Work Package' }, { AND: and.length ? and : undefined }],
  };
  return filterInstances;
};
