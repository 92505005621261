import * as React from 'react';
import { Dialog, DialogActions, DialogContent, Typography, DialogTitle } from '@mui/material';
import { useMainContainerContext } from 'src/base/main-container/context/main.container.context';
import { useTranslation } from 'react-i18next';
import DefaultButton from 'src/utils/components/default-button/default-button';
import { styles } from 'src/base/on-boarding/components/styles';
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap } from '@mui/styles/withStyles';

const useStyles = makeStyles(styles);
const TaskInputModal: React.FC = (): JSX.Element => {
  const state = useMainContainerContext();
  const { updateMainContainerState } = state;
  const { t } = useTranslation();
  const classes: ClassNameMap<string> = useStyles();

  return (
    <Dialog
      open={state.openNoTaskAndInputModal}
      classes={{
        root: classes.taskInputModal,
        paperWidthSm: classes.leaveModalWidth,
      }}
      onClose={() => updateMainContainerState({ openNoTaskAndInputModal: false })}
    >
      <DialogTitle style={{ padding: '18px 24px 0 24px' }}>
        <Typography className={classes.taskInputTitle}>{t('addTaskAndInput')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography className={classes.taskInputContent}>{t('addTaskAndInputToAdvance')}</Typography>
      </DialogContent>
      <DialogActions classes={{ root: classes.skipStartContainer }}>
        <DefaultButton
          data-testid={'ok-button'}
          className={classes.defaultBtnPadding}
          onClick={() => updateMainContainerState({ openNoTaskAndInputModal: false })}
        >
          {t('ok')}
        </DefaultButton>
      </DialogActions>
    </Dialog>
  );
};

export default TaskInputModal;
