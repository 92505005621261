import { MessagePayload } from 'src/interfaces/remote-assistance/chat';
import { WebsocketMessage } from 'src/interfaces/remote-assistance/types';
import { WBS_EVENT_AUTHENTICATION_CHALLENGE } from './constants';
import store from 'src/reducer-manager';
//import { stopActiveStreams } from './video-streaming/camera';

const MAX_WEBSOCKET_FAILS = 7;
const MIN_WEBSOCKET_RETRY_TIME = 3000; // 3 sec
const MAX_WEBSOCKET_RETRY_TIME = 300000; // 5 mins

interface Broadcast {
  omit_users: unknown;
  room_id: string;
  session_id: string;
  contact_user_id: string;
}

export type WebSocketEvent = {
  broadcast: Broadcast;
  data: MessagePayload | unknown;
  event: string;
  seq: number;
  status?: WebSocketStatus;
};

export enum WebSocketStatus {
  FAIL = 'FAIL',
  OK = 'OK',
}

export type WebSocketError = {
  error: WebSocketErrorData;
  event: string;
  seq_reply: number;
  status: WebSocketStatus;
};

export type WebSocketErrorData = {
  id: string;
  detailed_error: string;
  message: string;
  status_code: number;
};

type Callback = (e?: unknown) => Promise<void> | void | WebSocketEvent;

export default class WebSocketClient {
  conn: WebSocket | null;
  connectionUrl: string;
  sequence: number;
  eventSequence: number;
  connectFailCount: number;
  eventCallback: Array<unknown>;
  responseCallbacks = {};
  firstConnectCallback: Callback = null;
  reconnectCallback: () => void = null;
  missedEventCallback: () => void = null;
  errorCallback: (evt: WebSocketError) => void = null;
  closeCallback: (num: number) => void = null;
  token: string;
  eventListeners: Array<string>;
  onOpen: () => void = null;
  onConnecting: () => void = null;
  onDisconnect: () => void = null;
  onClose: () => void = null;
  onError: () => void = null;

  constructor() {
    this.conn = null;
    this.connectionUrl = '';
    this.sequence = 1;
    this.eventSequence = 0;
    this.connectFailCount = 0;
    this.eventCallback = [];
    this.responseCallbacks = {};
    this.firstConnectCallback = null;
    this.reconnectCallback = null;
    this.missedEventCallback = null;
    this.errorCallback = null;
    this.closeCallback = null;
    this.token = '';
    this.eventListeners = [];
    this.onOpen = null;
    this.onConnecting = null;
    this.onDisconnect = null;
    this.onClose = null;
    this.onError = null;
  }

  initialize(connectionUrl = this.connectionUrl, token: string): void {
    if (this.conn) {
      return;
    }

    if (connectionUrl == null) {
      return;
    }

    this.conn = new WebSocket(connectionUrl);
    this.connectionUrl = connectionUrl;
    this.onConnecting();

    this.conn.onopen = (): void => {
      this.eventSequence = 0;

      if (token) {
        this.token = token;
        void this.sendMessage(WBS_EVENT_AUTHENTICATION_CHALLENGE, { token });
      }

      if (this.connectFailCount > 0) {
        console.warn('websocket re-established connection');
        if (this.reconnectCallback) {
          this.reconnectCallback();
        }
      } else if (this.firstConnectCallback) {
        void this.firstConnectCallback();
      }

      this.connectFailCount = 0;
      void this.onOpen();
    };

    this.conn.onclose = (): void => {
      this.conn = null;
      this.sequence = 1;

      this.connectFailCount++;

      if (this.closeCallback) {
        this.closeCallback(this.connectFailCount);
      }

      let retryTime = MIN_WEBSOCKET_RETRY_TIME;

      // If we've failed a bunch of connections then start backing off
      if (this.connectFailCount > MAX_WEBSOCKET_FAILS) {
        retryTime = MIN_WEBSOCKET_RETRY_TIME * this.connectFailCount * this.connectFailCount;
        if (retryTime > MAX_WEBSOCKET_RETRY_TIME) {
          retryTime = MAX_WEBSOCKET_RETRY_TIME;
        }
      }

      this.onDisconnect();
      // TODO Merge: this will be useful if we implement the feature to share video on mobile
      //stopActiveStreams();

      setTimeout(() => {
        this.initialize(connectionUrl, this.token);
      }, retryTime);
    };

    this.conn.onerror = (evt): void => {
      console.error('websocket-error:', JSON.stringify(evt));

      if (this.errorCallback) {
        this.errorCallback(evt as unknown as WebSocketError);
      }

      this.onError();
    };

    this.conn.onmessage = async (evt): Promise<void> => {
      let msg: WebsocketMessage = {};
      if (evt.data instanceof Blob) {
        // Response message
        // status		Size (1)		Buffer (0:1)
        // event		Size (26)		Buffer (1:27)
        // reserved		Size (52)		Buffer (27:79)
        // context		Size (79)		Buffer (79:105)
        // data			Size (156)      Buffer (105:261)
        const buf = await evt.data.arrayBuffer();
        const context = new TextDecoder().decode(buf.slice(79, 105));

        // Ignore status of message sended
        if (store.getState().socketReducer?.session?.id == context) return;

        const action = new TextDecoder().decode(buf.slice(1, 27)).replace(/\0/g, '');
        const payload = new TextDecoder().decode(buf.slice(105, buf.byteLength)).replace(/\0/g, '');

        msg = {
          event: action,
          data: {
            payload: payload,
            from: context,
          },
        };
      } else {
        msg = JSON.parse(evt.data) as WebsocketMessage;
      }

      if (msg.seq_reply) {
        if (this.responseCallbacks[msg.seq_reply]) {
          (this.responseCallbacks[msg.seq_reply] as (arg: unknown) => void)(msg);
          Reflect.deleteProperty(this.responseCallbacks, msg.seq_reply);
        }
      } else if (this.eventCallback) {
        if (msg.seq !== this.eventSequence && this.missedEventCallback) {
          // TODO check if this is really necessary
          console.warn(`missed websocket event, act_seq=${msg.seq} exp_seq=${this.eventSequence}`);
          this.missedEventCallback();
        }
        this.eventSequence = msg.seq + 1;

        // Publish the message to the correct listener
        this.eventListeners.forEach((listenerId) => {
          if (this.eventCallback[listenerId]) (this.eventCallback[listenerId] as (arg: unknown) => void)(msg);
        });
      }
    };
  }

  setEventCallback(callback: Callback, id: string): void {
    if (!this.eventListeners.includes(id)) this.eventListeners.push(id);
    this.eventCallback[id] = callback;
  }

  setFirstConnectCallback(callback: Callback): void {
    this.firstConnectCallback = callback;
  }

  setReconnectCallback(callback: Callback): void {
    this.reconnectCallback = callback;
  }

  setMissedEventCallback(callback: Callback): void {
    this.missedEventCallback = callback;
  }

  setErrorCallback(callback: Callback): void {
    this.errorCallback = callback;
  }

  setCloseCallback(callback: Callback): void {
    this.closeCallback = callback;
  }

  setOnOpen(callback: Callback): void {
    this.onOpen = callback;
  }

  setOnConnecting(callback: Callback): void {
    this.onConnecting = callback;
  }

  setOnDisconnect(callback: Callback): void {
    this.onDisconnect = callback;
  }

  setOnClose(callback: Callback): void {
    this.onClose = callback;
  }

  setOnError(callback: Callback): void {
    this.onError = callback;
  }

  close(): void {
    this.connectFailCount = 0;
    this.sequence = 1;
    if (this.conn && this.conn.readyState === WebSocket.OPEN) {
      this.conn.onclose = (): void => {
        return;
      };
      this.conn.close();
      this.conn = null;
    }

    // AQUI ????
    this.onClose();
  }

  // waitForOpenConnection will wait until the connection is ready
  waitForOpenConnection = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const maxNumberOfAttempts = 50;
      const intervalTime = 200; //ms

      let currentAttempt = 0;
      const interval = setInterval(() => {
        if (currentAttempt > maxNumberOfAttempts - 1) {
          clearInterval(interval);
          reject(new Error('websocket maximum number of attempts exceeded'));
        } else if (this.conn && this.conn.readyState === WebSocket.OPEN) {
          clearInterval(interval);
          resolve();
        }
        currentAttempt++;
      }, intervalTime);
    });
  };

  sendMessage = async (action: string, data: unknown, responseCallback?: Callback): Promise<void> => {
    const msg = {
      action,
      seq: this.sequence++,
      data,
    };

    if (responseCallback) {
      this.responseCallbacks[msg.seq] = responseCallback;
    }

    if (this.conn && this.conn.readyState === WebSocket.OPEN) {
      this.conn.send(JSON.stringify(msg));
    } else if (this.conn && this.conn.readyState === WebSocket.CONNECTING) {
      try {
        await this.waitForOpenConnection();
        this.conn.send(JSON.stringify(msg));
      } catch (err) {
        console.error(err);
      }
    } else if (!this.conn || this.conn.readyState === WebSocket.CLOSED) {
      this.conn = null;
      this.initialize(this.connectionUrl, this.token);
    }
  };

  sendBinaryMessage = async (
    action: string,
    session_id: string,
    data: unknown,
    isBubble: boolean,
    responseCallback?: Callback,
  ): Promise<void> => {
    // Binary message structure
    // action       Size (26)       Buffer (0:26)
    // reserved                     Buffer (26:78)
    // session_id   Size (26)       Buffer (78:104)
    // payload      Size (156)      Buffer (104:260)

    if ((!isBubble && action.length > 26) || session_id.length > 26 || JSON.stringify(data).length > 156) {
      return;
    } else if ((isBubble && action.length > 26) || JSON.stringify(data).length > 187) {
      return;
    }

    let buffer = new Uint8Array(260);
    if (!isBubble) {
      const enc = new TextEncoder();
      buffer.set(enc.encode(action), 0);
      buffer.set(enc.encode(session_id), 78);
      buffer.set(enc.encode(data as string), 104);
    } else {
      buffer = new Uint8Array(213);
      const enc = new TextEncoder();
      buffer.set(enc.encode(action), 0);
      buffer.set(enc.encode(data as string), 26);
    }

    if (responseCallback) {
      this.responseCallbacks[this.sequence++] = responseCallback;
    }

    if (this.conn && this.conn.readyState === WebSocket.OPEN) {
      this.conn.send(buffer);
    } else if (this.conn && this.conn.readyState === WebSocket.CONNECTING) {
      try {
        await this.waitForOpenConnection();
        this.conn.send(buffer);
      } catch (err) {
        console.error(err);
      }
    } else if (!this.conn || this.conn.readyState === WebSocket.CLOSED) {
      this.conn = null;
      this.initialize(this.connectionUrl, this.token);
    }
  };
}
