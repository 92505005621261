import { makeStyles, withStyles } from '@mui/styles';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { styles } from './styles';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FilterDataType } from '../../../..';
import { ExpandMore } from '@mui/icons-material';
import { Checkbox, Popover, useTheme } from '@mui/material';
import { ListItem } from '@mui/material';
import { createPortal } from 'react-dom';

type StatusPopoverProps = {
  filterData: FilterDataType[];
  index: number;
  context: string;
  setFilterData: (val: FilterDataType[]) => void;
} & WithTranslation;

type ValuesPortalProps = {
  visibleItems: string[];
  containerWidth: number;
  filterDataValues: string[];
  setVisibleItems: (val: string[]) => void;
};

const div = document.createElement('div');

const ValuesPortal: React.FC<ValuesPortalProps> = (props): JSX.Element => {
  const { visibleItems, containerWidth, filterDataValues, setVisibleItems } = props;
  const { t } = useTranslation();

  useEffect(() => {
    // Create a dummy container for measurements
    const dummyContainer = document.createElement('div');
    dummyContainer.style.visibility = 'hidden';
    dummyContainer.style.position = 'absolute';
    dummyContainer.style.whiteSpace = 'nowrap';
    document.body.appendChild(dummyContainer);

    const filteredItemsInWidth: string[] = [];
    let newTotalWidth = 0;

    for (const value of filterDataValues) {
      dummyContainer.innerHTML = t(value);

      const chipWidth = dummyContainer.getBoundingClientRect().width;

      if (newTotalWidth + chipWidth <= containerWidth - 20) {
        filteredItemsInWidth.push(t(value));
        newTotalWidth += chipWidth;
      } else {
        break;
      }
    }

    setVisibleItems(filteredItemsInWidth);

    // Cleanup the dummy container
    document.body.removeChild(dummyContainer);
  }, [containerWidth, filterDataValues]);

  useEffect(() => {
    document.body.appendChild(div);
    return () => {
      if (document.body.contains(div)) {
        document.body.removeChild(div);
      }
    };
  }, []);

  return createPortal(
    <div>
      {visibleItems.map((value, optionIndex) => (
        <span id={'item-element'} key={optionIndex}>
          {visibleItems?.length > 1 && optionIndex !== 0 && ', '}
          {t(`${value}`)}
        </span>
      ))}
    </div>,
    div,
  );
};

const statusOptionsActions = ['solved', 'doing', 'pending', 'cantDo'];
const statusOptionsWorkOrders = ['done', 'wip', 'pending', 'canceled'];

const useStyles = makeStyles(styles);
const StatusPopover: React.FC<StatusPopoverProps> = (props): JSX.Element => {
  const { t, setFilterData, filterData, index, context } = props;
  const classes = useStyles();
  const anchorRef = useRef<HTMLDivElement>();
  const valuesContainerRef = useRef<HTMLDivElement>();
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const [visibleItems, setVisibleItems] = useState<string[]>([]);
  const theme = useTheme();
  const overflowCount = useMemo(
    () => filterData[index]?.values?.length - visibleItems.length,
    [filterData, index, visibleItems],
  );

  const handleOpen = () => {
    setOpenPopover(true);
  };

  const handleClose = () => {
    setOpenPopover(false);
  };

  const handleCheckboxChange = (item: string, checked: boolean) => {
    const newValues = !checked
      ? filterData[index].values?.filter((val) => val !== item)
      : !filterData[index].values
        ? [item]
        : [...filterData[index].values, item];

    const newFilterData = [...filterData];
    newFilterData[index] = {
      ...newFilterData[index],
      values: newValues.length === 0 ? null : newValues,
    };
    setFilterData(newFilterData);
  };

  return (
    <>
      <div
        className={classes.mainContainer}
        style={{ borderColor: openPopover ? theme.palette.primary.main : '' }}
        ref={anchorRef}
        key={'timespan-current-selected'}
        onClick={handleOpen}
      >
        {filterData[index].values?.length === 0 || !filterData[index].values ? (
          <span className={classes.placeholder}>{t('selectOption')}</span>
        ) : (
          <div ref={valuesContainerRef} className={classes.valuesContainer}>
            <ValuesPortal
              filterDataValues={filterData[index].values}
              visibleItems={visibleItems}
              setVisibleItems={setVisibleItems}
              containerWidth={valuesContainerRef?.current?.offsetWidth}
            />
            {visibleItems?.map((value, optionIndex) => (
              <span id={'item-element'} key={optionIndex}>
                {visibleItems?.length > 1 && optionIndex !== 0 && ', '}
                {t(`${value}`)}
              </span>
            ))}
            {overflowCount > 0 && (
              <span className={classes.counter}>
                {'+ '}
                {overflowCount}
              </span>
            )}
          </div>
        )}
        <ExpandMore />
      </div>

      <Popover
        anchorEl={anchorRef.current}
        open={openPopover}
        classes={{ paper: classes.popoverContainer }}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {(context === 'actions' ? statusOptionsActions : statusOptionsWorkOrders).map((item) => (
          <ListItem key={`filter-option-${item}`} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <Checkbox
              checked={!!filterData[index].values?.find((val) => val === item)}
              onChange={(e) => handleCheckboxChange(item, e.target.checked)}
            />
            <span className={filterData[index].values?.find((val) => val === item) ? classes.selectedSortItem : ''}>
              {t(`${item}`)}
            </span>
          </ListItem>
        ))}
      </Popover>
    </>
  );
};

export default withTranslation('translation')(withStyles(styles)(StatusPopover));
