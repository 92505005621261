import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const styles = (theme: Theme) => {
  return createStyles({
    modal: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    alertIcon: {
      color: theme.palette.alert.main,
      fontSize: '40px',
    },
    title: {
      fontWeight: 500,
      fontSize: '16px',
    },
    content: {
      fontWeight: 400,
      fontSize: '16px',
      fontFamily: 'Open Sans',
      color: theme.palette.text.secondary,
    },
    dismiss: {
      width: 'fit-content',
      padding: '0 4px',
    },
  });
};
