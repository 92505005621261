import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    customFilterContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'flex-start',
      gap: '18px',
    },
    customFilterTitle: {
      display: 'flex',
      flexDirection: 'column',
    },
    addFilterBtn: {
      width: 'fit-content',
      marginInline: 0,
      padding: '4px 6px',
    },
    addFilterBtnText: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.main,
    },
    filterBtn: { width: 'fit-content' },
    filterBtnNoText: {
      padding: '0 12px',
      cursor: 'pointer',
    },
    filterBtnText: {
      display: 'flex',
      alignItems: 'center',
      gap: '6px',
    },
    badge: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.paper,
    },
    badgeWidgetWrapper: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.paper,
      width: '16px',
      height: '16px',
    },
  });
