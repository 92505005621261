import NoImg from '@assets/images/construction_blk.svg';
import MarkerGreen from '@assets/images/markerGreen.svg';
import {
  AccountCircle,
  Category,
  Close,
  Construction,
  FeaturedPlayList,
  LocationOn,
  WarningAmber,
} from '@mui/icons-material';
import { Badge, Popover } from '@mui/material';
import { withStyles } from '@mui/styles';
import * as Leaflet from 'leaflet';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Anchorme } from 'react-anchorme';
import { withTranslation } from 'react-i18next';
import { Marker } from 'react-leaflet';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { getAccount, getAccountWithDeleted } from 'src/modules/account/account.redux';
import { getElement, getElementWithDeleted } from 'src/modules/element/element.redux';
import { getFile } from 'src/modules/file-storage/file-storage.redux';
import { getProfile, getProfileWithDeleted } from 'src/modules/profile/profile.redux';
import SiteMap from 'src/modules/site/components/site-map';
import { getSite, getSiteWithDeleted } from 'src/modules/site/site.redux';
import { styles } from 'src/utils/components/details-popover/styles';
import ExpandableSection from 'src/utils/components/expandable-section';
import InputFieldGeneric from 'src/utils/components/input-field/input-generic';
import { NexusGenFieldTypes } from '../../../../../server/src/types';
import ChipLabelAccount from '../chips/chip-label-account';

const lIconGreen = new Leaflet.Icon({ iconUrl: MarkerGreen, iconSize: [80, 80], popupAnchor: [0, -30] });

const DetailsPopover = (props: any) => {
  const { value, clear, context, classes, t } = props;
  const [item, setItem] = useState<any>(null);
  const [sections, setSections] = useState<any>([]);
  const [imgPreview, setImgPreview] = useState<string>();

  const updateSections = (inps) => {
    const sects = [];
    inps
      ?.sort((x, y) => x.order - y.order)
      .map((inp) => {
        if (inp.container && !sects.includes(inp.container)) {
          sects.push(inp.container);
        }
      });
    setSections(sects);
  };

  useEffect(() => {
    switch (context) {
      case 'account':
        props.getAccountWithDeleted({ _id: value._id }).then((resp) => {
          updateSections(resp?.inputs);
          setItem(resp);
        });
        break;
      case 'site':
      case 'parentSite':
      case 'rootSite':
        props.getSiteWithDeleted({ _id: value._id }).then((resp) => {
          updateSections(resp?.inputs);
          setItem(resp);
        });
        break;
      case 'element':
      case 'parentElement':
      case 'rootElement':
        props.getElementWithDeleted({ _id: value._id }).then(async (resp) => {
          if (resp?.image?._id) {
            const fileObj = await props.getFile(resp?.image?._id);
            setImgPreview(fileObj);
          } else {
            setImgPreview(null);
          }
          updateSections(resp?.inputs);
          setItem(resp);
        });
        break;
      case 'tool':
        props.getTool({ _id_eq: value._id }).then(async (resp) => {
          updateSections(resp.inputs);
          setItem(resp);
        });
        break;
      case 'profile':
      default:
        props.getProfileWithDeleted({ _id: value._id }).then((resp) => {
          updateSections(resp?.inputs);
          setItem(resp);
        });
        break;
    }
  }, []);

  const iconByType = () => {
    switch (context) {
      case 'account':
        return <AccountCircle classes={{ root: classes.icon }} />;
      case 'site':
        return <LocationOn classes={{ root: classes.icon }} />;
      case 'element':
        return <Category classes={{ root: classes.icon }} />;
      case 'tool':
        return <Construction className={classes.icon} />;
      case 'profile':
      default:
        return <FeaturedPlayList classes={{ root: classes.icon }} />;
    }
  };

  const showPath = () => {
    switch (context) {
      case 'account':
        return (
          <>
            {item.folder?.parentsTree?.map((p) => `${p.name} / `)}
            {item.folder?.name}
          </>
        );
      case 'site':
        return (
          <>{item.parentsTree.map((p, i) => (i === item.parentsTree.length - 1 ? `${p.name}` : `${p.name} / `))}</>
        );
      case 'element':
        return (
          <>
            {item.site.parentsTree.map((p) => `${p.name} / `)}
            {item.site && item.parentsTree.length ? `${item.site.name} / ` : `${item.site.name}`}
            {item.parentsTree.map((p, i) => (i === item.parentsTree.length - 1 ? `${p.name}` : `${p.name} / `))}
          </>
        );
      case 'tool':
        return <>{item}</>;
      case 'profile':
      default:
        return (
          <>
            {item.folder?.parentsTree?.map((p) => `${p.name} / `)}
            {!item.folder ? '' : item.parentsTree?.length ? `${item.folder?.name} / ` : item.folder?.name}
            {item.parentsTree?.map((p, i) => (i === item.parentsTree?.length - 1 ? `${p.name}` : `${p.name} / `))}
          </>
        );
    }
  };

  const fieldsByType = () => {
    switch (context) {
      case 'account':
        return (
          <>
            <div
              data-testid={`popup-username-${item.username}`}
              id={`popup-username-${item.username}`}
              style={{ paddingBottom: '10px' }}
            >
              <div className={`${classes.infoLabel}`}>{t('username')}</div>
              <div style={{ minHeight: '30px', marginRight: '10px', marginLeft: '10px' }}>{item.username}</div>
            </div>
            <div
              data-testid={`popup-username-${item.email}`}
              id={`popup-username-${item.email}`}
              className={classes.field}
            >
              <div className={`${classes.infoLabel}`}>{t('email')}</div>
              <div style={{ minHeight: '30px', marginRight: '10px', marginLeft: '10px' }}>
                <Anchorme target='_blank'>{item.email}</Anchorme>
              </div>
            </div>
          </>
        );
      case 'site':
        return (
          <>
            <div style={{ height: '200px', marginBottom: '15px' }}>
              <SiteMap
                //@ts-ignore
                state={{
                  coordinates: {
                    lat: item.coordinates?.length ? item.coordinates[0] : 0,
                    lng: item.coordinates?.length ? item.coordinates[1] : 0,
                  },
                  markers: [
                    {
                      data: (
                        <Marker
                          icon={lIconGreen}
                          key={item._id}
                          id={item._id}
                          position={[
                            item.coordinates ? item.coordinates[0] : 0,
                            item.coordinates ? item.coordinates[1] : 0,
                          ]}
                        >
                          {item.name}
                        </Marker>
                      ),
                    },
                  ],
                }}
              />
            </div>
            <div style={{ paddingBottom: '10px' }}>
              <div className={`${classes.infoLabel}`}>{t('Location')}</div>
              <div style={{ minHeight: '30px', marginRight: '10px', marginLeft: '10px' }}>
                {item.coordinates[0] + ', ' + item.coordinates[1]}
              </div>
            </div>
            <div className={classes.field}>
              <div className={`${classes.infoLabel}`}>{t('description')}</div>
              <div style={{ minHeight: '30px', marginRight: '10px', marginLeft: '10px' }}>
                <Anchorme target='_blank'>{item.description ? item.description : '-'}</Anchorme>
              </div>
            </div>
          </>
        );
      case 'element':
        return (
          <>
            <div className={classes.photoContainer}>
              <img src={imgPreview ? imgPreview : NoImg} className={classes.imgField} />
            </div>
            <div style={{ paddingBottom: '10px' }}>
              <div className={`${classes.infoLabel}`}>{t('description')}</div>
              <div style={{ minHeight: '30px', marginRight: '10px', marginLeft: '10px' }}>
                <Anchorme target='_blank'>{item.description ? item.description : '-'}</Anchorme>
              </div>
            </div>
            <div className={classes.field}>
              <div className={`${classes.infoLabel}`}>{t('profile')}</div>
              <div style={{ minHeight: '30px', marginRight: '10px', marginLeft: '10px' }}>
                <InputFieldGeneric
                  type={'action'}
                  values={[item.profile]}
                  editable={false}
                  disabled={true}
                  onlyText
                  onlyInput
                  context={'profile'}
                  handleChange={(): void | null => null}
                />
              </div>
            </div>
            <div className={classes.field}>
              <div>
                <div className={`${classes.infoLabel}`}>{t('tags')}</div>
                <div style={{ minHeight: '30px', marginRight: '10px', marginLeft: '10px' }}>
                  {item.tags?.length ? (
                    <div style={{ display: 'flex' }}>
                      {item.tags.map((tag, key) => (
                        <div key={key} className={classes.tag}>
                          <span className={classes.tagValue}>{tag.name}</span>
                          {tag.unit ? (
                            <Badge
                              color='secondary'
                              classes={{ badge: classes.badge }}
                              badgeContent={tag.unit.symbol}
                              onClick={() => ''}
                            />
                          ) : null}
                        </div>
                      ))}
                    </div>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            </div>
          </>
        );
      case 'profile':
      default:
        return (
          <>
            <div style={{ paddingBottom: '10px' }}>
              <div className={`${classes.infoLabel}`}>{t('description')}</div>
              <div style={{ minHeight: '30px', marginRight: '10px', marginLeft: '10px' }}>
                <Anchorme target='_blank'>{item.description ? item.description : '-'}</Anchorme>
              </div>
            </div>
          </>
        );
    }
  };

  const showDeletedInfo = (item) => {
    return (
      <>
        <div id='div-main-deleted' data-testid='div-main-deleted' className={classes.divDeleted}>
          <div id='deleted-div-content' data-testid='deleted-div-content' className={classes.divDeletedContent}>
            <WarningAmber id='deleted-warning-icon' data-testid='deleted-warning-icon'></WarningAmber>
            <span id='deleted-text' data-testid='deleted-text' className={classes.deletedText}>
              {`This ${item.__typename.toLowerCase()} has been deleted ${item.__typename.toLowerCase() === 'account' ? '' : `by ${item.deletedBy?.name} `}on ${moment(item.deletedAt).format('DD/MM/YYYY')}`}
            </span>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {item ? (
        <Popover
          open={true}
          onClose={(e): void => {
            //@ts-ignore
            e.stopPropagation();
            clear();
          }}
          classes={{ paper: classes.fullPopoverContainer }}
        >
          <div className={classes.fullPopoverHeader}>
            <div
              id={`popup-full-${context}Details`}
              data-testid={`popup-full-${context}Details`}
              className={classes.popoverTitle}
            >
              {t(`${context}Details`)}
            </div>
            <Close
              id='close-popup-button'
              data-testid='close-popup-button'
              onClick={(e): void => {
                e.stopPropagation();
                clear();
              }}
              classes={{ root: classes.fullPopoverCloseIcon }}
            />
          </div>
          <div className={classes.fullPopoverBody}>
            {item.deleted && <div>{showDeletedInfo(item)}</div>}
            <div className={classes.mainInfo}>
              <div className={classes.divName}>
                {iconByType()}
                <span className={classes.name}>{item.name}</span>
              </div>
              <div className={classes.divPath}>{showPath()}</div>
              <div style={{ marginTop: '42px' }}>{fieldsByType()}</div>
              <div className={classes.field}>
                <div className={`${classes.infoLabel}`}>{t('labels')}</div>
                <div style={{ minHeight: '30px', marginRight: '10px', marginLeft: '10px' }}>
                  <ChipLabelAccount data-testid='chip-labels-details-popover' labelValues={item?.labelValues} />
                </div>
              </div>
              {item.inputs?.map((field: NexusGenFieldTypes['Input'], i: number) =>
                !field?.container ? (
                  <div key={i} className={classes.field}>
                    <div className={`${classes.infoLabel}`}>{field.name}</div>
                    <div
                      style={{
                        minHeight: '30px',
                        marginRight: '10px',
                        marginLeft: '10px',
                      }}
                    >
                      <InputFieldGeneric
                        key={i}
                        input={field}
                        editable={false}
                        disabled={true}
                        onlyText
                        onlyInput
                        handleChange={(): void | null => null}
                      />
                    </div>
                  </div>
                ) : undefined,
              )}
            </div>
            <div style={{ marginTop: '16px' }}>
              {sections.map((section: string, i: number) => (
                <ExpandableSection key={i} title={section} hide={false}>
                  {item?.inputs
                    .filter((inp: NexusGenFieldTypes['Input']) => inp.container === section)
                    .sort(
                      (x: NexusGenFieldTypes['Input'], y: NexusGenFieldTypes['Input']) =>
                        (x.order || 0) - (y.order || 0),
                    )
                    .map((field: NexusGenFieldTypes['Input'], i: number) => (
                      <div key={i} style={{ marginLeft: '25px' }}>
                        <div className={`${classes.infoLabel}`}>{field.name}</div>
                        <div style={{ minHeight: '30px' }}>
                          <InputFieldGeneric
                            key={i}
                            input={field}
                            editable={false}
                            disabled={true}
                            onlyText
                            onlyInput
                            handleChange={(): void | null => null}
                          />
                        </div>
                      </div>
                    ))}
                </ExpandableSection>
              ))}
            </div>
          </div>
        </Popover>
      ) : undefined}
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getElement,
      getAccount,
      getSite,
      getProfile,
      getFile,
      getSiteWithDeleted,
      getElementWithDeleted,
      getProfileWithDeleted,
      getAccountWithDeleted,
    },
    dispatch,
  );

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default compose<any>(
  withTranslation('translation'),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(DetailsPopover);
