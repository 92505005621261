import { Chip } from '@mui/material';
import { withStyles } from '@mui/styles';
import { CallSplit, Close, HighlightOffOutlined } from '@mui/icons-material';
import { cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { styles } from 'src/utils/components/generic-input-card/styles';

const colors: string[] = ['#B468FF', '#00BF9D', '#FE8947', '#ACED24'];
const backgroundColors: string[] = [
  'rgba(180, 104, 255, 0.13)',
  'rgba(0, 191, 157, 0.13)',
  'rgba(254, 137, 71, 0.13)',
  'rgba(172, 237, 36, 0.13)',
];

interface FinishIssueChipProps {
  t: (key) => string;
  classes: Record<string, string>;
  input: any;
  active: boolean;
  setInput: (val: any) => void;
}

const FinishIssueChip = (props: FinishIssueChipProps) => {
  const { classes, input, active, setInput, t } = props;

  const showChip = (response: { finishIssue: boolean; _id: string; ifValues: { label: string }[] }, i): JSX.Element => {
    const color: number = i;
    if (input.type === 'selection' && response.finishIssue) {
      return (
        <Chip
          icon={<CallSplit classes={{ root: classes.iconFinishIssueChip }} />}
          label={
            <div className={classes.divLabel}>
              <span
                style={{
                  backgroundColor: backgroundColors[parseInt(`${color}`)],
                  color: colors[parseInt(`${color}`)],
                }}
                className={classes.firstSpanAlert}
              >
                {response.ifValues[0].label}
              </span>
              {t('selected')}
              <span className={classes.secondSpanAlert}>
                <HighlightOffOutlined classes={{ root: classes.finishIssueIcon }} />
                {t('finishIssue')}
              </span>
              {active && (
                <Close
                  classes={{ root: classes.closeIcon }}
                  onClick={(): void => {
                    const clonedInput = cloneDeep(input);

                    const onResponse = clonedInput[input.type].onResponse.find(
                      (elem) => elem.ifValues[0].label === response.ifValues[0].label,
                    );

                    if (
                      onResponse.inputs.length ||
                      onResponse.actionRequired ||
                      onResponse.createAlert ||
                      onResponse.createTasks
                    ) {
                      onResponse.finishIssue = false;
                    } else {
                      clonedInput[props.input.type].onResponse = clonedInput[props.input.type].onResponse.filter(
                        (elem) => elem.ifValues[0].label !== response.ifValues[0].label,
                      );
                    }

                    setInput({
                      ...clonedInput,
                    });
                  }}
                />
              )}
            </div>
          }
          classes={{ root: classes.finishIssueChip }}
        />
      );
    }
    return <></>;
  };

  return (
    <div className={classes.finishIssueChipContainer}>
      {input[input.type].onResponse
        ?.filter((r) => r.finishIssue)
        .map((response, i): JSX.Element => showChip(response, i))}
    </div>
  );
};

export default compose<any>(withTranslation('translation'), withStyles(styles))(FinishIssueChip);
