import * as React from 'react';
import { ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import ErrorBoundary from 'src/utils/other/errorHandling';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { createTheme, Theme, StyledEngineProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import lightTheme from 'src/lightTheme';
import darkTheme from 'src/darkTheme';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { styles } from 'src/base/root/styles';
import { WithStyles, withStyles } from '@mui/styles';
import { CloseRounded } from '@mui/icons-material';
import * as Sentry from '@sentry/browser';
import { errorMessagesToIgnore } from 'src/utils/funcs/error-utils';
import { MainContainerContextProvider } from '../main-container/context/main.container.context';
import { removeEmpty, typedConnect } from 'src/utils/funcs/index';
import MainContainer from '../main-container/main.container';
import { mainContainerContextInitialValue } from '../main-container/constants/initialContext';
import { RemoteAssistanceProvider } from 'src/modules/remote-assistance/remote-assistance.context';
import ChatPushNotifications from 'src/modules/chat/components/chat-push-notifications';
import { getTenantInfo } from 'src/modules/authentication/authentication.redux';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

interface RootProps extends ConnectedProps<typeof connector>, WithStyles<typeof styles> {
  defaultRoute: string;
}

const connector = typedConnect(
  (state) => ({
    loggedIn: state.loginReducer.loggedIn,
    themeSelected: state.loginReducer.theme,
    defaultRoute: state.accountReducer.defaultRoute,
    currentSite: state.accountReducer.currentSite,
    tenantInfo: state.authenticationReducer.tenantInfo,
  }),
  { getTenantInfo },
);

export const render = {
  view: {
    default: compose(
      connector,
      withStyles(styles),
    )(
      class Root extends React.Component<RootProps> {
        notistackRef: React.RefObject<SnackbarProvider>;
        constructor(props) {
          super(props);
          this.notistackRef = React.createRef();
        }

        componentDidMount(): void {
          const splitHostname = window.location.hostname.split('.');
          this.props
            .getTenantInfo(
              /(.glarcloud.com)$/i.exec(window.location.hostname) || /(.glartek.com)$/i.exec(window.location.hostname)
                ? splitHostname.length > 3
                  ? `${splitHostname[splitHostname.length - 4]}.${splitHostname[splitHostname.length - 3]}`
                  : splitHostname[0]
                : '',
            )
            .then(
              (resp) => {
                // Initialize Sentry
                // Sentry will act as exception logger
                //if (resp.sentryWebDSN?.length > 0) {
                if (resp.sentryWebDSN?.length > 0) {
                  Sentry.init({
                    dsn: resp.sentryWebDSN,
                    release: resp.version,
                    enableTracing: true,
                    environment: resp.sentryEnvironment,
                    // This sets the sample rate to be 10%. You may want this to be 100% while
                    // in development and sample at a lower rate in production
                    replaysSessionSampleRate: 0.1,

                    // If the entire session is not sampled, use the below sample rate to sample
                    // sessions when an error occurs.
                    replaysOnErrorSampleRate: 1.0,
                    integrations: [
                      Sentry.captureConsoleIntegration(),
                      Sentry.replayIntegration({
                        maskAllText: false,
                        blockAllMedia: false,
                        networkDetailAllowUrls: ['/graphql'],
                        networkRequestHeaders: ['Cache-Control'],
                        networkResponseHeaders: ['Referrer-Policy'],
                      }),
                      Sentry.browserTracingIntegration({
                        enableInp: true,
                      }),
                      Sentry.httpContextIntegration(),
                      Sentry.httpClientIntegration(),
                      Sentry.extraErrorDataIntegration(),
                    ],
                    beforeSend(event) {
                      const notToSend =
                        errorMessagesToIgnore.includes(event?.extra?.arguments[1]?.code) ||
                        /Socket closed/gi.test(event?.exception?.values[0]?.value);
                      if (notToSend) return null;
                      return event;
                    },
                  });

                  Sentry.setTag('branch', import.meta.env.SENTRY_BRANCH);
                }
                this.forceUpdate();
              },
              (e) => console.error(e),
            );
        }

        chooseMainContainer = () => {
          if (localStorage.getItem('access_token') === null) {
            Sentry.setUser(null);
            Sentry.setContext('Site', null);
            return <MainContainer login {...this.props} />;
          }
          if (localStorage.getItem('access_token') !== null) {
            Sentry.setUser({
              username: localStorage.getItem('username'),
              accountId: localStorage.getItem('accountId'),
            });
            Sentry.setContext('Site', {
              id: localStorage.getItem('current_site_id'),
              name: localStorage.getItem('current_site_name'),
            });
            return <MainContainer {...this.props} />;
          }
          return undefined;
        };

        private onClickDismiss = (key: SnackbarKey) => (): void => {
          this.notistackRef.current?.closeSnackbar(key);
        };

        render() {
          const theme =
            this.props.themeSelected === 'dark'
              ? //@ts-ignore
                createTheme({
                  ...darkTheme,
                  palette: { ...darkTheme.palette, ...removeEmpty(this.props.tenantInfo?.paletteDark), mode: 'dark' },
                })
              : //@ts-ignore
                createTheme({
                  ...lightTheme,
                  palette: {
                    ...lightTheme.palette,
                    ...removeEmpty(this.props.tenantInfo?.paletteLight),
                    mode: 'light',
                  },
                });

          const mainContainer = this.chooseMainContainer();
          return (
            <StyledEngineProvider injectFirst>
              {/*{' '}
              <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
                {' '}
                */}
              <EmotionThemeProvider theme={theme}>
                <MuiThemeProvider theme={theme}>
                  <CssBaseline />
                  <ChatPushNotifications />
                  <SnackbarProvider
                    id='snackbar'
                    data-testid='snackbar'
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    hideIconVariant
                    ref={this.notistackRef}
                    classes={{
                      /*@ts-ignore*/
                      variantSuccess: this.props.classes.success,
                      variantError: this.props.classes.error,
                      variantWarning: this.props.classes.warning,
                    }}
                    action={(key): JSX.Element => (
                      <CloseRounded id='closeSnackbar' data-testid='closeSnackbar' onClick={this.onClickDismiss(key)} />
                    )}
                  >
                    <RemoteAssistanceProvider>
                      <div className={'app-wrapper'}>
                        {/*@ts-ignore*/}
                        <ErrorBoundary location={window.location} defaultRoute={this.props.defaultRoute}>
                          <MainContainerContextProvider initialValue={mainContainerContextInitialValue}>
                            {mainContainer}
                          </MainContainerContextProvider>
                        </ErrorBoundary>
                      </div>
                    </RemoteAssistanceProvider>
                  </SnackbarProvider>
                </MuiThemeProvider>
              </EmotionThemeProvider>
              {/*{' '}
              </Sentry.ErrorBoundary>{' '}
              */}
            </StyledEngineProvider>
          );
        }
      },
    ),
  },
};

export default render;
