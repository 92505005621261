import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    title: {
      alignSelf: 'center',
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '16px',
    },
    subTitle: {
      fontFamily: 'Open Sans',
      fontSize: '16px',
      fontWeight: 400,
      color: theme.palette.grey.main,
      textAlign: 'center',
      padding: '0px 24px 24px',
    },
    footer: {
      alignSelf: 'center',
      padding: '16px 96px',
    },
  });
