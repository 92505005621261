import gql from 'graphql-tag';
import {
  INPUT,
  INPUT_WITH_LOG,
  INPUT_WITH_RECURSIVE,
  ON_RESPONSE_COMMON_NUMBER,
  ON_RESPONSE_COMMON_STRING,
  INPUT_NUMBER,
  INPUT_STRING,
} from 'src/utils/fragments/input';
import { ISSUE_INSTANCE, ISSUE_INSTANCE_TO_TABLE } from 'src/utils/fragments/issue';
import { LABEL_VALUE } from 'src/utils/fragments/label';
import { STATE_MACHINE_INSTANCE } from 'src/utils/fragments/stateMachine';
import { TASK_INSTANCE_TO_TABLE } from 'src/utils/fragments/task';

export const Q_GET_ISSUES_INSTANCES = gql`
  query GetIssuesInstances(
    $filter: IssueInstanceWhereInput!
    $skip: Int
    $limit: Int
    $orderBy: [IssueInstanceOrderByInput!]
  ) {
    issues(skip: $skip, limit: $limit, where: $filter, orderBy: $orderBy) {
      ...issueInstanceToTable
      taskGroups {
        tasks {
          _id
          score {
            currentScore
            totalScore
          }
          assignedLabelValues {
            ...labelValue
          }
          assignedAccounts {
            _id
            name
            email
            photo
            _id
            deleted
          }
        }
      }
      closedAt
    }
  }
  ${ISSUE_INSTANCE_TO_TABLE}
  ${STATE_MACHINE_INSTANCE}
  ${LABEL_VALUE}
`;

export const Q_COUNT_ISSUES_INSTANCES = gql`
  query CountIssuesInstances($filter: IssueInstanceWhereInput!) {
    issuesCount(where: $filter)
  }
`;

gql`
  query GetIssuesInstancesAndCount(
    $filter: IssueInstanceWhereInput!
    $skip: Int
    $limit: Int
    $orderBy: [IssueInstanceOrderByInput!]
  ) {
    issues(skip: $skip, limit: $limit, where: $filter, orderBy: $orderBy) {
      ...issueInstanceToTable
      taskGroups {
        tasks {
          _id
          score {
            currentScore
            totalScore
          }
          assignedLabelValues {
            ...labelValue
          }
          assignedAccounts {
            _id
            name
            email
            photo
            _id
            deleted
          }
        }
      }
      closedAt
    }

    issuesCount(where: $filter)
  }
`;

export const Q_GET_ISSUE_INSTANCE = gql`
  query GetIssueInstance($filter: ID!) {
    issue(where: { _id: $filter }) {
      ...issueInstance
      startDate
      taskGroups {
        name
        _id
        sequential
        order
        tasks {
          ...taskInstanceToTable
          element {
            name
          }
          site {
            name
          }
          assignedAccounts {
            _id
            name
          }
          assignedLabelValues {
            _id
            value
          }
          onResponseId
          parentsTree
          isActiveByOnResponse
        }
      }
    }
  }
  ${INPUT}
  ${INPUT_WITH_LOG}
  ${INPUT_WITH_RECURSIVE}
  ${STATE_MACHINE_INSTANCE}
  ${ISSUE_INSTANCE}
  ${TASK_INSTANCE_TO_TABLE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
`;

export const Q_GET_ISSUES_INSTANCES_TO_SCHEDULE = gql`
  query GetIssuesInstancesToSchedule($filterInstances: JobWhereInput) {
    myCalendarJobs(where: $filterInstances) {
      name
      nextRunAt
      data {
        workPackage {
          workPackageName
          issueCatalogs {
            name
            _id
          }
          totalIssuesDone
          totalIssues
        }
        startDate
        endDate
        issue {
          name
          canceledBy {
            _id
          }
          closedAt
          canceledBySystem
          wip
          currentState
          conflictHandler
          canceledBy {
            _id
          }
          usersNotBlocked
        }
      }
      lastRunAt
      _id
    }
  }
`;

gql`
  query GetIssuesInstancesToTable($filters: JobWhereInput, $limit: Int, $skip: Int, $order: [JobOrderByInput]) {
    myCalendarJobs(where: $filters, limit: $limit, skip: $skip, orderBy: $order) {
      _id
      nextRunAt
      data {
        startDate
        endDate
        issue {
          name
          site {
            name
            coordinates
            _id
            parent {
              _id
              name
              __typename
            }
            parentsTree {
              name
              _id
            }
          }
          frequency {
            amount
            timeUnit
          }
          assignedAccounts {
            name
            email
            photo
            _id
            deleted
          }
          assignedLabelValues {
            ...labelValue
          }
          element {
            name
            site {
              name
              coordinates
              _id
            }
            parentsTree {
              name
              _id
            }
            profile {
              name
              _id
            }
            _id
          }
          labelValues {
            ...labelValue
          }
        }
      }
    }
    myCalendarJobsCount(where: $filters)
  }
`;

export const Q_GET_MY_CALENDAR_JOBS_COUNT = gql`
  query MyCalendarJobsCount($filters: JobWhereInput) {
    myCalendarJobsCount(where: $filters)
  }
`;

export const Q_JOB = gql`
  query AgendaJob($id: ID!) {
    agendaJob(where: { _id: $id }) {
      lastRunAt
      nextRunAt
      _id
      name
      data {
        _id
        startDate
        workPackage {
          workPackageName
          totalIssuesDone
          totalIssues
          issueCatalogs {
            _id
            name
            assignedLabelValues
            assignedAccounts
            taskMappings {
              _id
              assignedAccounts
              assignedLabelValues
            }
          }
        }
        issue {
          _id
          name
          assignedAccounts {
            name
            email
            photo
            _id
            deleted
          }
          closedAt
          element {
            name
            site {
              name
              coordinates
              _id
            }
            profile {
              name
              _id
            }
            _id
          }
          assignedLabelValues {
            ...labelValue
          }
          frequency {
            amount
            timeUnit
          }
          catalog {
            _id
            needsApproval
            isActive
          }
          currentState
          canceledBySystem
          canceledBy {
            _id
          }
          wip
          instance {
            _id
            executedBy {
              name
              email
              photo
              _id
              deleted
            }
            needsApproval
          }
        }
      }
    }
  }
  ${LABEL_VALUE}
`;

export const Q_JOB_ISSUE_INFO = gql`
  query AgendaJobInfo($id: ID!) {
    agendaJob(where: { _id: $id }) {
      _id
      nextRunAt
      data {
        issue {
          _id
          name
          needsApproval
          inputs {
            ...input
          }
          createdBy {
            __typename
            _id
            username
            name
            email
            protected
            tenantId
            labelValues {
              ...labelValue
            }
            preferences {
              defaultSite {
                name
                coordinates
                _id
              }
              locale
              theme
            }
            template {
              __typename
              title
              folder {
                parentsTree {
                  _id
                  name
                }
                parent {
                  _id
                }
                protected
                name
                _id
              }
              _id
            }
            authorizedSites {
              name
              _id
              coordinates
            }
            roles {
              name
              _id
            }
          }
          element {
            _id
            name
            parentsTree {
              name
              _id
            }
          }
          site {
            _id
            name
            parentsTree {
              _id
              name
            }
            parent {
              _id
              name
              __typename
            }
          }
          frequency {
            amount
            timeUnit
          }
          currentState
          wip
          closedAt
          assignedAccountsIds
          assignedAccounts {
            name
            email
            photo
            _id
            deleted
          }
          assignedLabelValuesIds
          assignedLabelValues {
            ...labelValue
          }
          catalog {
            sequential
            inputs {
              ...input
            }
            taskMappings {
              _id
              assignedAccounts {
                _id
                name
              }
              assignedLabelValues {
                ...labelValue
              }
              element {
                _id
                name
              }
              site {
                _id
                name
              }
              taskTemplate {
                _id
                name
                order
                inputs {
                  ...input
                }
                group {
                  _id
                  name
                  order
                  sequential
                }
              }
            }
          }
        }
      }
    }
  }

  ${LABEL_VALUE}
  ${INPUT}
`;

export const Q_ISSUES_AND_ACTIONS = gql`
  query IssuesAndActions(
    $issueFilter: IssueInstanceWhereInput!
    $actionFilter: ActionWhereInput
    $skip: Int!
    $limit: Int!
  ) {
    issuesAndActions(issueWhere: $issueFilter, actionWhere: $actionFilter, skip: $skip, limit: $limit) {
      __typename
      ... on IssueInstance {
        _id
        name
        glarID
      }
      ... on Action {
        _id
        name
        ID
      }
    }
  }
`;

export const M_UPDATE_WORK_PACKAGE = gql`
  mutation UpdateWorkPackage($jobId: ID!, $issueCatalogs: [WorkPackageData], $name: String, $executionDate: DateTime) {
    updateWorkPackageJob(jobId: $jobId, issueCatalogs: $issueCatalogs, name: $name, executionDate: $executionDate) {
      _id
    }
  }
`;

export const M_CREATE_JOBS = gql`
  mutation CreateJobs($executionDate: DateTime!, $IssuesCatalogs: [ID!]!, $site: ID, $element: ID) {
    createJobs(executionDate: $executionDate, catalogId: $IssuesCatalogs, site: $site, element: $element) {
      _id
    }
  }
`;

export const M_ADD_ISSUES_TO_WORK_PACKAGE = gql`
  mutation AddIssuesToWorkPackage($jobId: ID!, $issueCatalogs: [WorkPackageData!]!) {
    instantiateIssuesOnWorkPackage(jobId: $jobId, issueCatalogs: $issueCatalogs) {
      _id
    }
  }
`;

export const Q_GET_JOBS = gql`
  query GetJobs($filter: JobWhereInput!, $limit: Int, $skip: Int) {
    jobs(where: $filter, limit: $limit, skip: $skip) {
      nextRunAt
      name
      _id
      data {
        startDate
        workPackage {
          _id
          finishedAt
          workPackageName
          issueCatalogs {
            _id
            name
            assignedLabelValues
            assignedAccounts
            taskMappings {
              _id
              assignedLabelValues
              assignedAccounts
            }
          }
          totalIssuesDone
          totalIssues
        }
      }
    }
  }
`;

export const Q_GET_JOBS_COUNT = gql`
  query GetJobsCount($filter: JobWhereInput!) {
    jobsCount(where: $filter)
  }
`;

export const M_CANCEL_WORK_PACKAGE = gql`
  mutation CancelWorkPackage($jobId: ID!) {
    cancelWorkPackage(where: { _id: $jobId }) {
      _id
    }
  }
`;

export const M_RUN_JOB = gql`
  mutation runJob($jobId: ID!) {
    runJob(where: { _id: $jobId }) {
      _id
      data {
        issue {
          name
          instance {
            _id
            catalog {
              needsApproval
            }
          }
        }
      }
    }
  }
`;

export const Q_GET_ISSUE_INSTANCES_WORK_PACKAGES = gql`
  query IssueInstancesWorkPackages($filter: IssueInstanceWhereInput!, $limit: Int, $skip: Int) {
    issues(where: $filter, limit: $limit, skip: $skip) {
      ...issueInstance
      taskGroups {
        name
        _id
        sequential
        order
        tasks {
          ...taskInstanceToTable
          element {
            name
          }
          site {
            name
          }
          onResponseId
          parentsTree
          isActiveByOnResponse
          labelValues {
            ...labelValue
          }
        }
      }
    }
  }
  ${INPUT}
  ${INPUT_WITH_LOG}
  ${INPUT_WITH_RECURSIVE}
  ${STATE_MACHINE_INSTANCE}
  ${ISSUE_INSTANCE}
  ${TASK_INSTANCE_TO_TABLE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
  ${LABEL_VALUE}
`;

export const Q_GET_ISSUES_CATALOGS_TO_WORK_PACKAGE = gql`
  query IssuesCatalogsToWorkPackage($filter: IssueCatalogWhereInput!) {
    issueCatalogs(where: $filter) {
      issueCatalogId: _id
      assignedAccounts: assignedAccountsIds
      assignedLabelValues: assignedLabelValuesIds
      templateId
      taskMappings {
        _id
        assignedAccounts: assignedAccountsIds
        assignedLabelValues: assignedLabelValuesIds
      }
    }
  }
`;

export const M_CREATE_WORK_PACKAGE = gql`
  mutation CreateWorkPackage($executionDate: DateTime!, $name: String!, $issueCatalogs: [WorkPackageData!]!) {
    createWorkPackageJob(executionDate: $executionDate, name: $name, issueCatalogs: $issueCatalogs) {
      _id
    }
  }
`;

export const Q_GET_ISSUES_INSTANCES_TO_NOTIFICATION = gql`
  query GetIssuesInstancesNotification($where: IssueInstanceWhereInput) {
    issues(where: $where) {
      _id
    }
  }
`;

export const S_ON_JOB_UPDATE = gql`
  subscription OnJobUpdate($_id: ID!) {
    jobUpdated(_id: $_id) {
      nextRunAt
      name
      _id
      data {
        startDate
        workPackage {
          _id
          finishedAt
          workPackageName
          issueCatalogs {
            _id
            name
            assignedLabelValues
            assignedAccounts
            taskMappings {
              _id
              assignedLabelValues
              assignedAccounts
            }
          }
          totalIssuesDone
          totalIssues
        }
      }
    }
  }
`;

export const S_ON_JOBS_UPDATE = gql`
  subscription OnJobsUpdate($where: [ID!]!) {
    jobsUpdated(where: $where) {
      nextRunAt
      name
      _id
      data {
        startDate
        workPackage {
          _id
          finishedAt
          workPackageName
          issueCatalogs {
            _id
            name
            assignedLabelValues
            assignedAccounts
            taskMappings {
              _id
              assignedLabelValues
              assignedAccounts
            }
          }
          totalIssuesDone
          totalIssues
        }
      }
    }
  }
`;
