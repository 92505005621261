import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { Close } from '@mui/icons-material';
import { CircularProgress, useTheme } from '@mui/material';
import { Divider, IconButton, Popover, Switch } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TenantConfigMixedRealityUpdateInput,
  useSingleTenantConfigQuery,
  useUpdateTenantMutation,
} from 'src/gql/graphql';
import DefaultButton from 'src/utils/components/default-button/default-button';
import InputGeneric from 'src/utils/components/input-field/input-generic';
import LabelInput from 'src/utils/components/input-field/input-label';
import { useRefresh } from 'src/utils/components/refresh-provider';

type ArConfigurationPopoverProps = {
  tenantId: string;
  onClose: () => any;
};

const FullWidthDiv = styled.div({
  width: '100%',
});

export function ArConfigurationPopover(props: ArConfigurationPopoverProps) {
  const { tenantId } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const triggerRefresh = useRefresh();

  const [cloudAnchors, setCloudAnchors] = useState<TenantConfigMixedRealityUpdateInput>(null);

  const arImage = useMemo(() => !cloudAnchors, [cloudAnchors]);

  const { loading } = useSingleTenantConfigQuery({
    variables: { where: { _id: tenantId } },
    skip: typeof tenantId != 'string',
    onCompleted(data) {
      setCloudAnchors(data.tenant.config.mixedReality);
    },
  });

  const [updateTenant, { loading: loadingSave }] = useUpdateTenantMutation();

  const disabled = useMemo(() => {
    if (cloudAnchors) {
      return !cloudAnchors.accountDomain || !cloudAnchors.accountId || !cloudAnchors.accountKey;
    }

    return false;
  }, [cloudAnchors]);

  return (
    <Popover
      onClose={props.onClose}
      open={true}
      anchorReference='none'
      marginThreshold={0}
      classes={{
        paper: css({
          height: '100%',
          left: 'unset !important',
          right: '0',
          top: '0 !important',
          width: '392px',
          maxHeight: 'unset !important',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          padding: 8,
          gap: 8,
        }),
      }}
    >
      <div css={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span css={{ fontWeight: 'bolder', fontSize: 18, margin: 8 }}>{t('advancedSettings')}</span>
        <IconButton onClick={() => props.onClose()}>
          <Close htmlColor={theme.palette.common.black} />
        </IconButton>
      </div>
      <Divider orientation='horizontal' css={{ width: '120%', margin: '0 -8px' }} />

      {!loading ? (
        <>
          <div>
            <Switch color={'primary'} checked={arImage} onChange={() => setCloudAnchors(arImage ? {} : null)} />
            <span>{t('ARImage')}</span>
          </div>
          <div>
            <Switch
              color={'primary'}
              checked={!!cloudAnchors}
              onChange={() => setCloudAnchors((prev) => (prev ? null : {}))}
            />
            <span>{t('cloudAnchors')}</span>
          </div>
          {cloudAnchors ? (
            <>
              <FullWidthDiv>
                <LabelInput>{t('accountID')}</LabelInput>
                <InputGeneric
                  type={'string'}
                  placeholder={t('insertID')}
                  data-testid={'insert-id-input'}
                  onlyText
                  onlyInput
                  values={[cloudAnchors.accountId]}
                  handleChange={(e) => setCloudAnchors((prev) => ({ ...prev, accountId: e[0] }))}
                />
              </FullWidthDiv>
              <FullWidthDiv>
                <LabelInput>{t('accountDomain')}</LabelInput>
                <InputGeneric
                  type={'string'}
                  placeholder={t('insertDomain')}
                  data-testid={'insert-domain-input'}
                  onlyText
                  onlyInput
                  values={[cloudAnchors.accountDomain]}
                  handleChange={(e) => setCloudAnchors((prev) => ({ ...prev, accountDomain: e[0] }))}
                />
              </FullWidthDiv>
              <FullWidthDiv>
                <LabelInput>{t('accountKey')}</LabelInput>
                <InputGeneric
                  type={'string'}
                  placeholder={t('insertKey')}
                  data-testid={'insert-key-input'}
                  onlyText
                  onlyInput
                  values={[cloudAnchors.accountKey]}
                  handleChange={(e) => setCloudAnchors((prev) => ({ ...prev, accountKey: e[0] }))}
                />
              </FullWidthDiv>
            </>
          ) : null}
        </>
      ) : (
        <div css={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={100} />
        </div>
      )}
      <div
        css={{ marginTop: 'auto', width: '100%', display: 'flex', flexDirection: 'row-reverse', padding: '8px 12px' }}
      >
        <DefaultButton
          loader={loadingSave}
          data-testid={'save-tenant-config'}
          disabled={disabled}
          onClick={() =>
            updateTenant({
              variables: { id: tenantId, data: { config: { mixedReality: arImage ? null : cloudAnchors } } },
            }).then(() => {
              props.onClose();
              triggerRefresh();
            })
          }
        >
          {t('save')}
        </DefaultButton>
      </div>
    </Popover>
  );
}
