import gql from 'graphql-tag';

export const LABEL_VALUE = gql`
  fragment labelValue on LabelValue {
    __typename
    _id
    value
    color
    backgroundColor
    label {
      _id
      __typename
      icon
      name
      context
    }
    description
    order
  }
`;

export const LABEL_BODY = gql`
  fragment label on Label {
    __typename
    _id
    name
    context
    singleSelection
    labelValues {
      ...labelValue
    }
    order
  }

  ${LABEL_VALUE}
`;
