import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    '@global': {
      'div > button[title="Remove Link"]': {
        display: 'none',
      },
      'div > button[title="Link"]': {
        display: 'none',
      },
      '::placeholder': {
        color: `${theme.palette.grey.main} !important`,
      },
      '.DraftEditor-editorContainer > div': {
        height: '250px',
      },
    },
    backdrop: {
      width: '100%',
      height: '100%',
      padding: '24px 24px 0 24px',
      backgroundColor: theme.palette.background.background1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
      overflow: 'hidden',
    },
    editCard: {
      width: '100%',
      height: '100%',
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.default,
      borderRadius: '8px 8px 0 0',
      overflow: 'hidden',
    },
    editor: {
      overflowY: 'auto',
      fontSize: 14,
      fontFamily: 'Open Sans',
      fontWeight: 400,
      backgroundColor: theme.palette.background.default,
      '& .public-DraftEditor-content': {
        fontSize: 14,
        fontFamily: 'Open Sans',
        fontWeight: 400,
      },
      '& .public-DraftEditorPlaceholder-root': {
        color: theme.palette.grey.main,
      },
    },
    editorToolbar: {
      backgroundColor: theme.palette.background.default,
      margin: '0px !important',
      padding: 10,
      display: 'flex',
      flexFlow: 'row wrap',
      gap: 8,
      alignItems: 'center',
      justifyContent: 'flex-start',
      '& > div': {
        margin: '0px !important',
        lineHeight: 0,
      },
    },
    inputLabel: {
      color: theme.palette.text.primary,
      margin: '8px 0',
      fontSize: 14,
      fontFamily: 'Open Sans',
      fontWeight: 400,
      lineHeight: '22px',
    },
    input: {
      width: '100%',
      padding: '8px 16px',
      borderRadius: 5,
      border: `1px solid ${theme.palette.grey.light}`,
      '& .MuiInputBase-input': {
        padding: 'unset',
        fontSize: 14,
        fontFamily: 'Open Sans',
        fontWeight: 400,
      },
      '& .MuiInputBase-input::placeholder': {
        fontSize: 14,
        fontFamily: 'Open Sans',
        fontWeight: 400,
        color: `${theme.palette.grey.main} !important`,
        opacity: 1,
      },
      '& .MuiInputBase-input:focus::placeholder': {
        color: `${theme.palette.grey.main} !important`,
      },
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
      '&:has(.Mui-focused)': {
        borderColor: `${theme.palette.primary.main} !important`,
      },
    },
    img: {
      width: '107px',
      height: '112px',
      objectFit: 'contain',
    },
    uploadInput: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: '0',
      top: '0',
      opacity: '0',
      cursor: 'pointer',
    },
    uploadDiv: {
      cursor: 'pointer',
      position: 'relative',
      width: 'fit-content',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px solid ${theme.palette.primary.light}`,
      borderRadius: '4px',
      gap: 8,
      padding: '4px 12px',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        border: `1px solid ${theme.palette.primary.light}`,
      },
    },
    row: {
      display: 'flex',
    },
    text: {
      color: theme.palette.primary.main,
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '16px',
    },
    addIcon: {
      color: theme.palette.primary.main,
      fontSize: '21px',
    },
    fileBtns: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      margin: '8px 8px 8px 0',
      gap: 16,
    },
    fixedButtons: {
      // position: 'fixed',
      // right: '10px',
      // bottom: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginRight: '10px',
      marginTop: '10px',
      marginBottom: '10px',
    },
    publishPaper: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 32px',
      minWidth: '400px',
      textAlign: 'center',
    },
    saving: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 16,
      gap: 8,
    },
    bold: {
      fontFamily: 'Poppins',
      fontWeight: 700,
    },
    discardModalButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: '24px',
    },
    generalInput: {
      border: `1px solid ${theme.palette.grey.light}`,
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
    generalPlaceholder: {
      color: theme.palette.secondary.main,
    },
  });
