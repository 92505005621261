import {
  Block,
  Bookmark,
  CategoryOutlined,
  Folder,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Link,
  LocationOn,
  Person,
  Search,
} from '@mui/icons-material';
import { Checkbox, InputBase, ListItem, ListItemText, Radio, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { NexusGenFieldTypes } from '@server/src/types';
import { debounce, isArray, uniqBy } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import 'react-tooltip/dist/react-tooltip.css';
import { Account, LabelValue } from 'src/gql/graphql';
import { withDialogBoundary } from 'src/utils/other/componentErrorBoundary';
import ChipLabelAccount from '../../chips/chip-label-account';
import DefaultButton from '../../default-button';
import {
  BetterFieldPopoverNavigationContext,
  FieldPopoverContext,
  FieldPopoverFolder,
  FieldPopoverItem,
  useFieldPopoverNavigation,
} from './popover-reducer';
import { styles } from './styles';

type BetterListByLevelProps<C extends FieldPopoverContext> = {
  context: C[];
  disabledItems?: string[];
  changeSelected: (item?: FieldPopoverItem[C][]) => void;
  selectedItem: FieldPopoverItem[C][];
  multiple?: boolean;
  resetOption?: boolean | string;
  labelField?: string;
  filterIds?: string[];
  close?: () => void;
  create?: string;
  iconAnchor?: boolean;
  onNavigate?: () => void;
  successButton?: string;
  onClickSuccess?: (item: FieldPopoverItem[C]) => void;
  isAllSelected?: (data: boolean) => void;
};

function renderContext<T extends FieldPopoverContext>(context: T): JSX.Element {
  switch (true) {
    case context.startsWith('label'):
      return <Bookmark fontSize='small' />;
    case context.startsWith('issueCatalog'):
    case context.endsWith('Folder'):
      return <Folder fontSize='small' />;
    case context === 'account':
    case context === 'assignee':
      return <Person fontSize='small' />;
    default:
      return <></>;
  }
}

function isInContext<C extends FieldPopoverContext>(context: C, item: FieldPopoverItem[FieldPopoverContext]): boolean {
  switch (true) {
    case context === 'label':
      return item.__typename === 'LabelValue';
    case context.startsWith('label'):
      return (
        item.__typename === 'LabelValue' &&
        (item as NexusGenFieldTypes['LabelValue']).label.context === context.split('label')[1]
      );
    case context === 'assignee' || context === 'account':
      return item.__typename?.endsWith('Account');
    case context === 'site':
      return item.__typename?.endsWith('Site');
    case context === 'element':
      return item.__typename?.endsWith('Element');
    case context === 'siteElement':
      return item.__typename?.endsWith('Site') || item.__typename?.endsWith('Element');
    case context === 'tag':
      return (
        item.__typename?.endsWith('Site') || item.__typename?.endsWith('Element') || item?.__typename.endsWith('Tag')
      );
    case context === 'issueCatalog':
      return item.__typename === 'IssueCatalog';
    case context === 'issueCatalogScheduled' && item.__typename === 'IssueCatalog' && !!item['scheduler']:
      return true;
    case context === 'issueCatalogOpen' && item.__typename === 'IssueCatalog' && !item['scheduler']:
      return true;
    default:
      return item.__typename.endsWith(context[0].toUpperCase() + context.slice(1));
  }
}

function isEnterable<C extends FieldPopoverContext>(context: C, item: FieldPopoverItem[C]) {
  return (
    item.__typename &&
    !(item.__typename.endsWith('Folder') && !context.endsWith('Folder')) &&
    item.__typename !== 'Label' &&
    (context === 'tag' ? item.__typename.endsWith('Tag') : true) &&
    (context === 'element' ? item.__typename.endsWith('Element') : true)
  );
}

function countInFolder<C extends FieldPopoverContext>(
  context: C,
  folderItem: FieldPopoverFolder[C],
  items: Array<FieldPopoverItem[C]>,
): number {
  switch (true) {
    case ['element', 'siteElement'].includes(context):
      return items.filter(
        (i: any) =>
          i.parentsTreeIds?.some((p) => p === folderItem._id) ||
          i.site?._id === folderItem._id ||
          i.site?.parentsTreeIds?.includes(folderItem._id),
      ).length;
    case 'site' === context:
      return items.filter((i: any) => i.parentsTreeIds?.some((p) => p === folderItem._id)).length;
    case context.startsWith('label'):
      return items.filter((i) => folderItem['labelValues']?.some((l) => l._id === i._id)).length;
    case folderItem.__typename.endsWith('Folder') || context === 'profile':
      return items.filter(
        (i: any) =>
          i.folderId === folderItem._id ||
          i.folder?._id === folderItem._id ||
          i.parentsTreeIds?.includes(folderItem._id) ||
          i.folder?.parentsTreeIds?.includes(folderItem._id),
      ).length;
    default:
      return 0;
  }
}

const useStyles = makeStyles(styles);

const BetterListByLevel = <C extends FieldPopoverContext>(props: BetterListByLevelProps<C>) => {
  const {
    context,
    changeSelected,
    selectedItem,
    multiple,
    iconAnchor,
    resetOption,
    filterIds = [],
    disabledItems = [],
    isAllSelected,
  } = props;

  const classes = useStyles();

  const { t } = useTranslation();

  const [selectedContext, setSelectedContext] = useState<FieldPopoverContext>(null);
  const [selected, setSelected] = useState<any>(null);

  //meant only for unit-testing purposes
  const navigationFactoryMap = useContext(BetterFieldPopoverNavigationContext);

  const {
    state: { parentsTree, items, loading, loadingMore, hasMore },
    actions,
  } = useFieldPopoverNavigation(selectedContext, navigationFactoryMap, filterIds);

  const parent = parentsTree.at(0);
  const searchRef = useRef<HTMLInputElement>(null);

  const disabledLevel = useMemo<boolean>(
    () => multiple && parentsTree.some((p) => selectedItem.some((i) => p._id === i._id)),
    [selectedItem, parentsTree],
  );

  const isLabelSingleSelection = selectedContext?.startsWith('label') && parentsTree.at(0)?.['singleSelection'];

  useEffect(() => {
    if (context.length === 1 && !resetOption) {
      setSelectedContext(context[0]);
    }
  }, []);

  useEffect(() => {
    if (selectedContext) {
      if (searchRef.current) {
        searchRef.current.value = '';
      }
      actions.onEnter();
    }
  }, [selectedContext]);

  const data = useMemo(() => {
    if (selectedContext) {
      return items;
    }

    const topLevels = (isArray(context) ? context : [context]).map((n) => ({
      _id: n,
      name: t(n),
      icon: renderContext(n),
      totalFolders: 1,
    }));

    if (resetOption) {
      topLevels.push({
        _id: null,
        name: typeof resetOption === 'string' ? resetOption : t('general'),
        icon: null,
        totalFolders: 0,
      });
    }

    return topLevels;
  }, [selectedContext, items]);

  const onSearch = useCallback(
    debounce(() => {
      actions.onSearch((searchRef.current?.value || '').trim());
    }, 400),
    [parentsTree],
  );

  const onSelect = (item) => {
    props.onNavigate?.();
    if (!selectedContext) {
      setSelectedContext(item._id);
      return;
    }

    if (!isEnterable(selectedContext, item)) {
      return;
    } else if (item.__typename.endsWith('Site')) {
      item.__typename = 'Site';
    } else if (item.__typename.endsWith('Element')) {
      item.__typename = 'Element';
    }

    if (!disabledLevel) {
      if (selectedItem.some((i) => i._id === item._id)) {
        if (multiple) {
          const newItems = selectedItem.filter((i) => i._id != item._id);
          changeSelected(newItems);
          isAllSelected?.(newItems?.length === items?.length && !hasMore);
        }
      } else {
        if (multiple) {
          const filterSelected = selectedItem
            .filter(
              (i: any) =>
                ![
                  ...(i.folder ? [i.folder, ...(i.folder.parentsTree ?? [])] : []).map((f) => f._id),
                  ...(i.parentsTreeIds ?? []),
                  ...(i.site ? [i.site._id, ...(i.site.parentsTreeIds ?? [])] : []),
                ].some((f) => f === item._id),
            )
            .filter((i) => {
              if (!isLabelSingleSelection || i.__typename !== 'LabelValue') {
                return true;
              }

              return i['label']._id !== item.label._id;
            });

          const newItems = uniqBy([...filterSelected, item], '_id');
          changeSelected(newItems);
          isAllSelected?.(newItems?.length === items?.length && !hasMore);
        } else {
          const newItems = [item];
          changeSelected(newItems);
          isAllSelected?.(newItems?.length === items?.length && !hasMore);
        }
      }
    }
  };

  const handleScrollNew = (containerRefElement?: HTMLDivElement | null) => {
    if (containerRefElement) {
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

      const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
      const scrollPastSpecificPercent = scrollPercentage > 75;
      if (scrollPastSpecificPercent && hasMore) {
        actions.onScroll();
      }
    }
  };

  const resize = () => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 20);
  };

  function renderItem<T extends FieldPopoverContext>(item: FieldPopoverItem[T], context: T): JSX.Element {
    switch (true) {
      case !!props.labelField:
        return item[props.labelField];
      case item.__typename === 'LabelValue':
        return <ChipLabelAccount data-testid='chip-label-value-field-popover' labelValues={[item as LabelValue]} />;
      case item.__typename === 'Account' && context === 'assignee':
        return <ChipLabelAccount data-testid='chip-account-field-popover' accounts={[item as Account]} />;
      case item['name'] === 'labelAccount':
        return <>{'label'}</>;
      default:
        return item['title'] || item['name'];
    }
  }

  return (
    <>
      {parent || (selectedContext && ((isArray(context) && context.length > 1) || resetOption)) ? (
        <div
          className={classes.parentDiv}
          style={{
            display: 'flex',
            paddingTop: '6px',
            paddingLeft: '12px',
            paddingRight: '12px',
            paddingBottom: '6px',
          }}
          onClick={() => {
            resize();
            props.onNavigate?.();
            if (parentsTree.length) {
              if (searchRef.current) {
                searchRef.current.value = '';
              }
              actions.onBack();
            } else {
              setSelectedContext(null);
            }
          }}
        >
          <KeyboardArrowLeft
            classes={{ root: classes.arrowLeftIcon }}
            style={{ marginRight: '4px' }}
            id={`buttonGoBack`}
            data-testid={`list-by-level-btn-go-back`}
          />
          <span
            className={`${classes.spanParent} ${classes.spanParentEllipsis} ${classes.hyphenate}`}
            data-tooltip-id='tooltip'
            data-tooltip-content={parent?.['name'] || selectedContext}
          >
            {parent?.['name'] || t(selectedContext)}
          </span>
        </div>
      ) : null}
      {selectedContext ? (
        <div>
          <InputBase
            autoFocus
            key={'better-dropdown-input'}
            data-testid={'better-dropdown-input'}
            fullWidth
            classes={{ root: classes.searchInput }}
            inputRef={searchRef}
            onChange={onSearch}
            placeholder={t('search') + '...'}
            endAdornment={<Search className={classes.kaka} />}
          />
          <hr className={classes.divider} />
        </div>
      ) : null}
      <ul
        onScroll={(e) => handleScrollNew(e.target as HTMLDivElement)}
        style={{
          overflowY: 'auto',
          maxHeight: '280px',
          padding: 0,
          margin: 0,
          ...(loading ? { display: 'none' } : {}),
        }}
      >
        {!loading &&
          data.map((item) => {
            const canEnter =
              selectedContext === 'site'
                ? item.hasSites
                : item.hasSites || item.hasElements || item.totalFolders || item.totalItems || item.children;

            const isItemDisabled = disabledItems.includes(item._id);

            const isResetOption = !item._id && !selectedContext;
            const numSelected =
              !selectedContext && !isResetOption
                ? selectedItem.filter((i) => isInContext(item._id, i)).length
                : canEnter
                  ? countInFolder(selectedContext, item, selectedItem)
                  : 0;

            return (
              <ListItem
                data-testid={`list-item-${item.name || item.title || item.value}`}
                style={canEnter || isResetOption ? { cursor: 'pointer' } : { userSelect: 'none' }}
                disabled={isItemDisabled}
                classes={{
                  root: `${classes.listItem} ${isResetOption && !selectedItem.length ? classes.hoveredColor : ''}`,
                }}
                key={item._id}
                onClick={() => {
                  resize();
                  props.onNavigate?.();
                  if (isResetOption) {
                    changeSelected([]);
                    props.close?.();
                  } else if (!selectedContext) {
                    setSelectedContext(item._id);
                  } else if (canEnter) {
                    if (searchRef.current) {
                      searchRef.current.value = '';
                    }
                    actions.onEnter(item);
                  }
                }}
              >
                <div
                  css={{ display: 'flex', alignItems: 'center', width: '100%', padding: '5px', overflow: 'hidden' }}
                  data-testid={`list-by-level-${item.name || item.title || item.value}-select`}
                >
                  {(!multiple || isLabelSingleSelection) && isEnterable(selectedContext, item) ? (
                    <Radio
                      checked={selectedItem.some((s) => s._id === item._id) || selected?._id == item?._id}
                      disabled={isItemDisabled}
                      data-testid={item.name || item.title || item.value}
                      color={'primary'}
                      size={'small'}
                      onClick={(ev) => {
                        ev.stopPropagation();

                        if (props.onClickSuccess) {
                          setSelected(item);
                        } else {
                          onSelect(item);
                        }
                      }}
                      className={`${classes.svgRadioButton} ${classes.inputAreaRadioButton} `}
                    />
                  ) : null}
                  {!!multiple && !isLabelSingleSelection && isEnterable(selectedContext, item) ? (
                    <Checkbox
                      data-testid={item.name || item.title || item.value}
                      checked={disabledLevel || selectedItem.some((s) => s?._id === item._id)}
                      onClick={(ev) => {
                        ev.stopPropagation();
                        onSelect(item);
                        if (searchRef.current && selectedContext === 'labelAction' && searchRef.current.value !== '') {
                          searchRef.current.value = '';
                          onSearch();
                        }
                      }}
                      color={'primary'}
                      disabled={disabledLevel || isItemDisabled}
                      size={'small'}
                      className={`${classes.svgRadioButton} ${classes.inputAreaRadioButton} `}
                    />
                  ) : null}
                  {item.__typename?.endsWith('Folder') ? (
                    <Folder style={{ margin: '0 8px 0 0' }} fontSize='small' />
                  ) : item.__typename?.endsWith('Element') ? (
                    <CategoryOutlined fontSize='small' />
                  ) : item.__typename?.endsWith('Site') ? (
                    <LocationOn fontSize='small' />
                  ) : null}
                  {item.icon}

                  <ListItemText
                    classes={{
                      root: classes.itemText,
                    }}
                  >
                    <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                      <Tooltip title={item.name || item.title || item.value} placement='top-start'>
                        <span className={selectedItem.some((i) => i._id === item._id) ? classes.selected : null}>
                          {renderItem(item, selectedContext)}
                        </span>
                      </Tooltip>
                    </div>
                  </ListItemText>

                  {iconAnchor &&
                  item.marker &&
                  item.marker &&
                  !selectedItem?.some((i) => item.marker.element?._id !== i._id) ? (
                    <Link
                      classes={{ root: classes.iconLink }}
                      data-tooltip-id='tooltip'
                      data-tooltip-content={t('elementAlreadyConnected').toString()}
                    />
                  ) : null}
                  <span>{numSelected ? `(${numSelected})` : null}</span>
                  {canEnter ? (
                    <KeyboardArrowRight
                      classes={{ root: classes.rightIcon }}
                      data-testid={`list-by-level-${item.name || item.title || item.value}-go-to`}
                    />
                  ) : null}
                </div>
              </ListItem>
            );
          })}
      </ul>
      {props.successButton && selected ? (
        <DefaultButton
          id={'buttonSuccess'}
          data-testid={`list-by-level-btn-success`}
          disabled={!selectedItem}
          success
          className={classes.successBtn}
          onClick={() => props.onClickSuccess(selected)}
        >
          {props.successButton}
        </DefaultButton>
      ) : null}
      {!loading && data.length === 0 && <span className={classes.spanNoResults}>{t('noResults')}</span>}
      {loadingMore || loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', padding: 8 }}>
          <ReactLoading type={'spin'} color={'grey'} height={50} width={50} />
        </div>
      ) : null}
    </>
  );
};

export default withDialogBoundary(BetterListByLevel, {
  fallbackComponent: () => (
    <Block fontSize={'large'} color={'disabled'} css={{ alignSelf: 'center', margin: '20px 50%' }} />
  ),
}) as typeof BetterListByLevel;
