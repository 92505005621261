import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';

const TimeSheetsRefresh = () => lazy(() => import('src/modules/timesheets/timesheets'));

let TimeSheets = TimeSheetsRefresh();

export const render = {
  default: ({ ...props }) => {
    if (TimeSheets?._result?.type === 'error') {
      TimeSheets = TimeSheetsRefresh();
    }
    return (
      <Suspense fallback={<LazyLoadingContent />}>
        <TimeSheets {...props} />
      </Suspense>
    );
  },
};
export default render;
