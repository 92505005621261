import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';
import { SequentialContextProvider } from 'src/utils/hooks/useSequential';

const IssueTemplatesRefresh = () => lazy(() => import('src/modules/issue-templates/issue.templates'));
const IssueTemplateBodyRefresh = () => lazy(() => import('src/modules/issue-templates/issue.template.body'));
const TemplateLibrarySelection = lazy(
  () => import('src/modules/issue-templates/components/task-template-body/components/template-library-selection'),
);

let IssueTemplates = IssueTemplatesRefresh();
let IssueTemplateBody = IssueTemplateBodyRefresh();

export const render = {
  templates: ({ ...props }) => {
    if (IssueTemplates?._result?.type === 'error') {
      IssueTemplates = IssueTemplatesRefresh();
    }
    return (
      <Suspense fallback={<LazyLoadingContent />}>
        <IssueTemplates {...props} />
      </Suspense>
    );
  },
  libraryTemplates: (props) => (
    <Suspense fallback={<LazyLoadingContent />}>
      <TemplateLibrarySelection {...props} />
    </Suspense>
  ),
  template: ({ ...props }) => {
    if (IssueTemplateBody?._result?.type === 'error') {
      IssueTemplateBody = IssueTemplateBodyRefresh();
    }
    return (
      <Suspense fallback={<LazyLoadingContent />}>
        <SequentialContextProvider>
          <IssueTemplateBody {...props} />
        </SequentialContextProvider>
      </Suspense>
    );
  },
};
export default render;
