import { useEffect } from 'react';
import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { styles } from 'src/base/main-container/styles/main-container';
import { clearSnackbar } from 'src/base/root/root.redux';
import { NexusGenFieldTypes } from '../../../../server/src/types';
import { WithTranslation, withTranslation } from 'react-i18next';
import useToast from './hooks/useToast';
import MainContainerLogin from './main.container.login';
import MainContainerDefault from './main.container.default';
import { withStyles, WithStyles } from '@mui/styles';
import { useMainContainerContext } from './context/main.container.context';

type MainContainerProps = {
  currentSite?: NexusGenFieldTypes['Site'];
  forceUpdate?: any;
  login?: boolean;
} & ConnectedProps<typeof connector> &
  WithStyles<typeof styles> &
  WithTranslation;

const MainContainer: React.FC<MainContainerProps> = (props) => {
  const { snackbarMessages, clearSnackbar } = props;

  useToast(snackbarMessages, clearSnackbar);

  const { blankPage } = useMainContainerContext();

  const login = props.login || blankPage;

  useEffect(() => {
    document.body.classList.remove();
    document.body.classList.add(
      ...(login
        ? 'app flex-row align-items-center pace-done'
        : 'app header-fixed sidebar-fixed aside-menu-fixed pace-done sidebar-show'
      ).split(' '),
    );
    document.getElementById('root').classList.add('container');
  }, [login]);

  return login ? <MainContainerLogin {...props} /> : <MainContainerDefault {...props} />;
};

const connector = connect(
  (state) => ({
    snackbarMessages: state.rootReducer.snackbarMessages,
    sidenavMinimized: state.rootReducer.sidenavMinimized,
  }),
  { clearSnackbar },
);

export default withTranslation('translation')(withStyles(styles)(connector(MainContainer)));
