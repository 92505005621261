import loginReducer from 'src/base/login/login.redux';
import notificationdropdownReducer from 'src/base/notification-dropdown/notification-dropdown.redux';
import profiledropdownReducer from 'src/base/profile-dropdown/profile-dropdown.redux';
import rootReducer from 'src/base/root/root.redux';
import accountReducer from 'src/modules/account/account.redux';
import alarmReducer from 'src/modules/alarm/alarm.redux';
import authenticationReducer from 'src/modules/authentication/authentication.redux';
import dashboardReducer from 'src/modules/dashboard/dashboard.redux';
import deviceReducer from 'src/modules/device/device.redux';
import elementReducer from 'src/modules/element/element.redux';
import filestorageReducer from 'src/modules/file-storage/file-storage.redux';
import labelReducer from 'src/modules/label/label.redux';
import profileReducer from 'src/modules/profile/profile.redux';
import roleReducer from 'src/modules/role/role.redux';
import siteReducer from 'src/modules/site/site.redux';
import statemachineReducer from 'src/modules/state-machine/state-machine.redux';
import taskReducer from 'src/modules/task/task.redux';
import unitReducer from 'src/modules/unit/unit.redux';
import reportReducer from 'src/modules/report/report.redux';
import inventoryReducer from 'src/modules/inventory/inventory.redux';
import agendaReducer from 'src/modules/agenda/agenda.redux';
import issueCatalogsReducer from 'src/modules/issue-catalogs/issue.catalogs.redux';
import issueHistoryReducer from 'src/modules/issue-history/issue.history.redux';
import issuePlanningReducer from 'src/modules/issue-planning/issue.planning.redux';
import settingsReducer from 'src/modules/settings/settings.redux';
import timesheetReducer from 'src/modules/timesheets/timesheet.redux';
import agoraReducer from 'src/modules/remote-assistance/agora/agora.redux';
import rtcReducer from 'src/modules/remote-assistance/rtc/rtc.redux';
import socketReducer from 'src/modules/remote-assistance/socket/socket.redux';
import roomReducer from 'src/modules/remote-assistance/rooms/rooms.redux';
import meetingSidebarReducer from 'src/modules/remote-assistance/meeting-sidebar/meeting-sidebar.redux';
import eventsReducer from './modules/remote-assistance/events/events.redux';
import configReducer from './modules/remote-assistance/config/config.redux';
import feedReducer from './modules/feed/feed.redux';
import issueTemplateDraftReducer from './modules/issue-templates/redux/issue.template.draft.redux';
import { configureStore, ThunkAction } from '@reduxjs/toolkit';
import roomUiReducer from 'src/modules/remote-assistance/room-ui/room-ui.redux';
import { tenantReducer } from './modules/tenants/tenant.redux';

const store = configureStore({
  reducer: {
    loginReducer,
    notificationdropdownReducer,
    profiledropdownReducer,
    rootReducer,
    accountReducer,
    alarmReducer,
    authenticationReducer,
    dashboardReducer,
    deviceReducer,
    elementReducer,
    feedReducer,
    filestorageReducer,
    labelReducer,
    profileReducer,
    roleReducer,
    siteReducer,
    statemachineReducer,
    taskReducer,
    tenantReducer,
    unitReducer,
    reportReducer,
    inventoryReducer,
    agendaReducer,
    issueCatalogsReducer,
    issueHistoryReducer,
    issuePlanningReducer,
    issueTemplateDraftReducer,
    settingsReducer,
    timesheetReducer,
    agoraReducer,
    rtcReducer,
    socketReducer,
    roomReducer,
    roomUiReducer,
    meetingSidebarReducer,
    eventsReducer,
    configReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type GlarState = ReturnType<typeof store.getState>;

export type GlarDispatch = typeof store.dispatch;

export type GlarThunk<R = any> = ThunkAction<R, GlarState, never, any>;

export default store;
