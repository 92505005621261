import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    listItem: {
      padding: '8px 16px',
      height: '40px',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
      },
    },
    checkboxIcon: {
      cursor: 'pointer',
    },
    itemText: {
      margin: 0,
      '& >span': {
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        lineHeight: '22px',
        wordBreak: 'break-all',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        maxWidth: '100%',
      },
    },
  });
