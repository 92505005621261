import {
  Add,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckBoxOutlineBlankOutlined,
  Delete,
  DeleteOutline,
  DragIndicator,
  FileCopyOutlined,
  MoreVert,
  Settings,
  Warning,
} from '@mui/icons-material';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { Chip, IconButton, MenuItem, Popover, TextField, Tooltip } from '@mui/material';
import { useTheme, WithStyles, withStyles } from '@mui/styles';
import { cloneDeep } from 'lodash';
import { createContext, Ref, startTransition, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Anchorme } from 'react-anchorme';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { ConnectedProps } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { compose } from 'redux';
import { useMainContainerContext } from 'src/base/main-container/context/main.container.context';
import { File, PresetWhereInput } from 'src/gql/graphql';
import { Input } from 'src/interfaces/inputs';
import { editPreset } from 'src/modules/issue-templates/issue.templates.redux';
import {
  InputOptions,
  withIssueTemplateConnector,
  WithIssueTemplateConnectorProps,
} from 'src/modules/issue-templates/redux/issue.template.draft.redux';
import DescriptionField from 'src/utils/components/description-field';
import AlertChip from 'src/utils/components/generic-input-card/components/alert-chip';
import AlertChipExecution from 'src/utils/components/generic-input-card/components/alert-chip-execution';
import CancelIssueChip from 'src/utils/components/generic-input-card/components/cancel-issue-chip';
import CreateTaskChip from 'src/utils/components/generic-input-card/components/create-task-chip';
import DatePopover from 'src/utils/components/generic-input-card/components/date-popover';
import FailResponseChip from 'src/utils/components/generic-input-card/components/fail-response-chip';
import IconInput from 'src/utils/components/generic-input-card/components/icon-input';
import MultiplePopover from 'src/utils/components/generic-input-card/components/multiple-popover';
import NumberChipConditional from 'src/utils/components/generic-input-card/components/number-chip-conditional';
import NumberPopover from 'src/utils/components/generic-input-card/components/number-popover';
import RichTextBox from 'src/utils/components/generic-input-card/components/richTextBox';
import SelectType from 'src/utils/components/generic-input-card/components/select-type';
import ShowValidations from 'src/utils/components/generic-input-card/components/show-validations';
import StringPopover from 'src/utils/components/generic-input-card/components/string-popover';
import { styles } from 'src/utils/components/generic-input-card/styles';
import InputFieldGeneric from 'src/utils/components/input-field/input-generic';
import { typedConnect } from 'src/utils/funcs';
import useOptimistic from 'src/utils/hooks/useOptimistic';
import usePrevious from 'src/utils/hooks/usePrevious';
import useDetectClickOutside from 'src/utils/libraries/react-detect-click-outside';
import AnswerNewQuestionChip from './components/answer-new-question-chip';
import ButtonAddFile from './components/button-add-file';
import FinishIssueChip from './components/finish-issue-chip';
import UnitField from './components/unit-field';
import GenericCardInputData from './interfaces/GenericCardInputData';

interface OnResponse {
  inputs: Input[];
  actionRequired?: boolean;
  createAlert?: boolean;
  createTasks?: boolean;
  finishIssue?: boolean;
  cancelIssue?: boolean;
  failResponse?: boolean;
  repeatTasks?: boolean;
  ifValues: { label: string; color?: string; _id: string }[];
  _id?: string;
  color?: string;
  condition?: string;
}

interface GenericInputCardProps
  extends WithIssueTemplateConnectorProps,
    ConnectedProps<typeof connector>,
    WithStyles<typeof styles> {
  input: GenericCardInputData;
  setInput: (input: Partial<GenericCardInputData>) => Promise<any>;
  duplicate?: (input: GenericCardInputData) => void;
  delete: (inputId: string) => void;
  checkboxInputsSelect: string[];
  setCheckboxInputsSelect: (inps: string[]) => void;
  disable?: boolean;
  error: any;
  cursorEnable?: boolean;
  instruction?: boolean;
  id?: string;
  sections?: string[];
  level?: number;
  option?: { label: string; color: string };
  innerRef: Ref<HTMLDivElement>;
  setChildEdit?: (editing: boolean) => void;
  moveCard?: (draggedId: string, input: any) => void;
  allErrors: any;
  context?: string;
  updateOrder?: any;
  enableCheckbox?: boolean;
  group?: any;
  addTask?: any;
  associateTaskToInput?: any;
  dropCard?: (groupId) => void;
  clearOnResponseIdOnTask?: any;
  onInputBlur?: () => void;
  firstTime?: string;
  addInput?: (order: number, onResponseId?: string, defaultInput?: any) => Promise<void>;
  addInputSection?: (order: number | null, sec?: string) => void;
  sectionText?: string;
  setActionDraftsToDeletefromDraft: (data) => void;
  setActionDraftsToDeletefromTemplate: (data) => void;
  templateGroupId?: string;
  scoreInputs?: boolean;
  selectedConditionalInputs?: string[];
  setSelectedCOnditionalInputs?: (data) => void;
  conditionalNotRemovable?: boolean;
  checkboxInputsSelectConditionals?: string[];
  setCheckboxInputsSelectConditionals?: (inps: string[]) => void;
  repeatTasks?: (tasksIds: string[]) => void;
  presets?: PresetWhereInput[];
  templateId?: string;
}

export const GenericInputCardContext = createContext(null);

export const BlockCloseDropdownContext = createContext<(x: boolean) => any>(null);

const colors = ['#B468FF', '#00BF9D', '#FE8947', '#ACED24'];
let childEditing = {};

const GenericInput = (props: GenericInputCardProps) => {
  const {
    classes,
    setInput,
    checkboxInputsSelect,
    setCheckboxInputsSelect,
    checkboxInputsSelectConditionals,
    setCheckboxInputsSelectConditionals,
    disable,
    cursorEnable,
    instruction,
    level,
    setChildEdit,
    enableCheckbox,
    onInputBlur,
    firstTime,
    addInput,
    scoreInputs,
    input,
    presets,
    templateId,
    editPreset,
    issueTemplate,
    updateIssueTemplateDraftState,
  } = props;

  const inputRef = useRef<typeof input>();
  inputRef.current = input;

  const [editing, setEditing] = useState<boolean>(false);
  const [blockClose, setBlockClose] = useState<boolean>(false);
  const [anchorMore, setAnchorMore] = useState<EventTarget>(null);
  const [openAdvancedPopover, setOpenAdvancedPopover] = useState<boolean>(false);
  const refUnit = useRef<HTMLDivElement>(null);
  const refInput = useRef<Element>(null);
  const refPopoverAdvanced = useRef<HTMLDivElement>(null);
  const refSubPopoverAdvanced = useRef<HTMLDivElement>(null);
  const refTypeMenu = useRef(null);
  const refNumberCondFirstSelect = useRef<Element>(null);
  const refNumberCondSecondSelect = useRef<Element>(null);
  const refNumberCondThirdSelect = useRef<Element>(null);
  const refSelectTask = useRef<Element>(null);
  const refSelectOption = useRef<Element>(null);
  const refSelectOptionTask = useRef<Element>(null);
  const refSelectOptionInputToLink = useRef<Element>(null);
  const [currentColorIndex, setCurrentColorIndex] = useState<number>(0);
  const [description, setDescription] = useState<string>(null);
  const theme = useTheme();

  const refAdvanced = useRef<HTMLDivElement>(null);
  const refHandle = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [addDescription, setAddDescription] = useState<boolean>(false);
  const [opts, setOpts] = useState<any[]>([]);
  const [scoreOpts, setScoreOpts] = useState<
    {
      _id: string;
      score: number;
      option: string;
    }[]
  >([]);
  const [checkboxOver, setCheckboxOver] = useState<string>('');
  const [selectedConditionalInputs, setSelectedConditionalInputs] = useState<string[]>([]);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const location = useLocation();
  const { templateRefs, startSubSteps } = useMainContainerContext();
  const placeholder = useMemo(() => {
    let placeholder = '';

    switch (input.type) {
      case 'string':
        placeholder = t('addText');
        break;
      case 'number':
        placeholder = t('addNumber');
        break;
      case 'datetime':
        switch (input.subType) {
          case 'date':
            placeholder = t('selectDate');
            break;
          case 'time':
            placeholder = t('selectTime');
            break;
          default:
            placeholder = t('selectDateTime');
            break;
        }
        break;
    }

    return placeholder;
  }, [input.type, input.subType]);
  const [refConditional, setRefConditional] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState<File[]>([]);
  const [progressMap, setProgressMap] = useState<Map<string, number>>(new Map());

  const [inputTitle, setInputTitleOptimistic] = useOptimistic<string>(input.name);

  const closeDropdown = useCallback(() => {
    if (
      !refUnit.current &&
      !refTypeMenu.current &&
      !refSelectOption.current &&
      !refNumberCondFirstSelect.current &&
      !refNumberCondSecondSelect.current &&
      !document.getElementById('dialog-files-carousel') &&
      !refPopoverAdvanced.current &&
      !refConditional
    ) {
      setAddDescription(false);
      setEditing(false);
      if (setChildEdit) setChildEdit(false);
    }
  }, []);

  useEffect(() => {
    if (setInput && addDescription) {
      props.setInput({
        _id: input._id,
        description: description,
      });
    }
  }, [description]);

  useEffect(() => {
    if (addDescription === false && description && description !== input.description) {
      setInput({
        _id: input._id,
        description: description ? description : input.description,
      });
    }
  }, [addDescription]);

  const refs = {
    refBox: useDetectClickOutside({
      onTriggered: closeDropdown,
      skip: blockClose,
    }),
    refText: useRef<HTMLLIElement>(null),
    refInstruction: useRef<HTMLLIElement>(null),
    refNumber: useRef<HTMLLIElement>(null),
    refMultiple: useRef<HTMLLIElement>(null),
    refSelect: useRef<HTMLLIElement>(null),
    refDateTime: useRef<HTMLLIElement>(null),
    refFiles: useRef<HTMLLIElement>(null),
    refElement: useRef<HTMLLIElement>(null),
    refSite: useRef<HTMLLIElement>(null),
    refSignature: useRef<HTMLLIElement>(null),
    refTool: useRef<HTMLLIElement>(null),
    refQrCode: useRef<HTMLLIElement>(null),
    refDelete: useRef<HTMLLIElement>(null),
    refDuplicate: useRef<HTMLLIElement>(null),
    refMove: useRef(),
    refTable: useRef<HTMLLIElement>(null),
    refPopoverAdvanced: useRef(),
    refMoveMenu: useRef(),
    refInput: useRef(),
    refCreateAction: useRef(),
    refCreateAlert: useRef(),
    refNewAnswer: useRef(),
    refFailResponse: useRef(),
    refCancelIssue: useRef(),
    refToFocus: useRef<HTMLLIElement>(),
    refRichText: useRef(),
    refExecuteNewTask: useRef(),
    refFinishIssue: useRef(),
  };

  const [{ isDragging }, connectDrag, connectPreview] = useDrag(
    () => ({
      // "type" is required. It is used by the "accept" specification of drop targets.
      type: 'INPUT',
      canDrag: true,
      item: { id: input._id, originalIndex: input.order, type: 'INPUT' },
      // The collect function utilizes a "monitor" instance (see the Overview for what this is)
      // to pull important pieces of state from the DnD system.
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [disable],
  );

  const [, connectDrop] = useDrop(
    () => ({
      accept: 'INPUT',
      collect: (monitor) => ({
        /* isOver: monitor.isOver(),
      canDrop: monitor.canDrop(), */
        didDrop: monitor.didDrop(),
      }),
      //@ts-ignore
      canDrop: (item) => item.type === 'INPUT',
      hover({ id: draggedId }: { id: string; originalIndex: number }) {
        let cardsToMove = [];
        if (selectedConditionalInputs?.includes(draggedId)) {
          cardsToMove = selectedConditionalInputs;
        } else {
          cardsToMove.push(draggedId);
        }
        cardsToMove.forEach((draggedId) => {
          if (draggedId !== input._id && props.moveCard) {
            props.moveCard(draggedId, instruction ? input : input.order);
            setSelectedConditionalInputs([]);
          }
        });
      },
      drop(_item: unknown, _monitor) {
        if (props.dropCard) props.dropCard(props.templateGroupId);
      },
    }),
    [props.templateGroupId, input, instruction, selectedConditionalInputs],
  );

  useEffect(() => {
    if (firstTime === input._id) {
      setEditing(true);
      refs.refToFocus.current?.focus();
    } else {
      setEditing(false);
    }
  }, [firstTime]);

  useEffect(() => {
    const handleClick = (e: any): void => {
      if (!disable) {
        const keys = Object.keys(refs);
        if (
          //@ts-ignore
          keys.filter((k) => refs[`${k}`].current?.contains(e.target)).length ||
          refUnit.current?.contains(e.target) ||
          refInput.current?.contains(e.target) ||
          refTypeMenu.current?.contains(e.target) ||
          refPopoverAdvanced.current?.contains(e.target) ||
          refSubPopoverAdvanced.current?.contains(e.target) ||
          refNumberCondFirstSelect.current?.contains(e.target) ||
          refNumberCondSecondSelect.current?.contains(e.target) ||
          refNumberCondThirdSelect.current?.contains(e.target) ||
          refSelectOption.current?.contains(e.target) ||
          refSelectTask.current?.contains(e.target) ||
          refSelectOptionTask.current?.contains(e.target) ||
          refSelectOptionInputToLink.current?.contains(e.target)
        ) {
          setEditing(true);
          if (setChildEdit) setChildEdit(true);
        }
        //@ts-ignore
        if (!refs.refRichText?.current?.contains(e.target) && addDescription) {
          setAddDescription(false);
        }
      }
    };

    window.addEventListener('click', handleClick, false);
    return (): void => {
      window.removeEventListener('click', handleClick, false);
    };
  }, [disable, addDescription]);

  const previousOptions = usePrevious(props.input.selection?.options);

  useEffect(() => {
    if (
      props.input.type !== 'selection' ||
      !previousOptions ||
      (input.type === 'selection' && input[input.type].preset?.presetId)
    )
      return;

    //only proceed if at least one option changed fr
    if (props.input[props.input.type].options?.length !== previousOptions.length) {
      return;
    }

    setTimeout(() =>
      setInput({
        _id: input._id,
        [input.type]: {
          ...input[input.type],
          onResponse: input[input.type].onResponse?.map((response: OnResponse) => ({
            ...response,
            ifValues: response.ifValues.map((val) => {
              const optionIndex = previousOptions.findIndex((o) => o.label === val.label);

              const newLabel = props.input.selection.options[optionIndex]?.label || '';

              return {
                ...val,
                label: newLabel,
              };
            }),
          })),
        },
      }),
    );
  }, [props.input.selection?.options.map((o) => o.label).join(' ')]);

  const duplicateSubInput = (inpToDup, idResp) => {
    const newInput = {
      ...inpToDup,
      _id: undefined,
      log: undefined,
      name: inpToDup.name + ' - Copy',
    };

    addInput(inpToDup.order, idResp, newInput);
  };

  const removeInputs = () => {
    const inpToDel = {
      ...input,
      [input.type]: {
        ...input[input.type],
        onResponse: input[input.type].onResponse
          .map((response: OnResponse) => ({
            ...response,
            inputs: response.inputs.filter((resInp) => !selectedConditionalInputs.includes(resInp._id)),
          }))
          .filter(
            (resp: OnResponse) =>
              resp.inputs.length || resp.actionRequired || resp.createAlert || resp.createTasks || resp.finishIssue,
          ),
      },
    };
    setInput({
      _id: inpToDel._id,
      [inpToDel.type]: {
        ...inpToDel[inpToDel.type],
        options: inpToDel[inpToDel.type].options.map((opt: { label: string; color?: string }) =>
          inpToDel[inpToDel.type].onResponse.filter((r: OnResponse) => r.ifValues[0].label === opt.label).length
            ? opt
            : { ...opt, color: undefined },
        ),
      },
    });
    setSelectedConditionalInputs([]);
  };

  connectDrag(refHandle);
  connectDrop(refs.refBox);
  connectPreview(refs.refBox);
  useEffect(() => {
    if (input[input.type].preset?.presetId && location.pathname.includes('templates/issues')) {
      setOpts(
        issueTemplate.frame?.presets
          ?.find((preset) => preset._id === input[input.type].preset.presetId)
          .options.map((option, index) => ({
            id: index,
            label: option.response,
            value: option.response,
            order: index,
            _id: `id-${index}`,
          })),
      );
    } else {
      setOpts(input[input.type].options);
    }
    if (scoreInputs) {
      const scoreOptsAux: { option: string; score: number; _id: string }[] = [];
      input[input.type]?.options?.map((opt) => {
        scoreOptsAux.push({
          _id: opt._id,
          option: opt.label,
          score: input[input.type].optionsWithScore?.find((scoreOpt) => scoreOpt.option === opt.label)
            ? input[input.type].optionsWithScore.find((scoreOpt) => scoreOpt.option === opt.label).score
            : 0,
        });
      });
      setScoreOpts(scoreOptsAux);
    }
  }, [input[input.type]?.options, input[input.type]?.optionsWithScore, JSON.stringify(issueTemplate?.frame?.presets)]);

  const inputCardColor = (): string => {
    let cardColor: string;

    switch (input.type) {
      case 'signature':
      case 'string':
        cardColor = theme.palette.primary.main;
        break;
      case 'number':
        cardColor = theme.palette.other.peach;
        break;
      case 'element':
      case 'site':
      case 'instruction':
        cardColor = theme.palette.common.black;
        break;
      case 'multiple':
        cardColor = theme.palette.primary.dark;
        break;
      case 'selection':
        cardColor = theme.palette.success.main;
        break;
      case 'datetime':
        cardColor = theme.palette.success.dark;
        break;
      case 'tool':
      case 'file':
        cardColor = theme.palette.error.main;
        break;
      case 'table':
        cardColor = '#7C18FB';
        break;
      default:
        cardColor = theme.palette.alert.main;
    }

    return cardColor;
  };

  useEffect(() => {
    setBlockClose(
      !!(popoverAnchorEl || anchorMore || startSubSteps || !editing || openAdvancedPopover || refConditional),
    );
  }, [popoverAnchorEl, anchorMore, startSubSteps, editing, openAdvancedPopover, refConditional]);

  return (
    <BlockCloseDropdownContext.Provider value={setBlockClose}>
      <div ref={props.innerRef}>
        <div className={classes.divInput}>
          {(!disable && !props.option && !isDragging) || enableCheckbox ? (
            checkboxInputsSelect?.includes(input._id) ? (
              <CheckBox
                color={'primary'}
                style={{ alignSelf: 'center', marginRight: '15px' }}
                onClick={(e): void => {
                  e.stopPropagation();
                  setCheckboxInputsSelect(checkboxInputsSelect?.filter((id) => id !== input._id));
                }}
                id={`unselectInput`}
                data-testid={`unselectInput-${input?.name ?? '---'}`}
                className={props.classes.moreVertIcon}
              />
            ) : (
              <CheckBoxOutlineBlankOutlined
                color={'primary'}
                style={{
                  alignSelf: 'center',
                  marginRight: '15px',
                  display: 'none',
                }}
                onClick={(e): void => {
                  e.stopPropagation();
                  setCheckboxInputsSelect([...checkboxInputsSelect, input._id]);
                }}
                id={`selectInput`}
                data-testid={`selectInput-${input?.name ?? '---'}`}
                className={props.classes.moreVertIcon}
              />
            )
          ) : null}
          <div
            data-testid={'genericCard'}
            ref={refs.refBox}
            key={input._id + '-box' + editing + disable}
            style={
              isDragging
                ? {
                    width: !disable ? 'calc(100% - 39px)' : '100%',
                    //@ts-ignore
                    contentVisibility: 'hidden',
                    backgroundColor: theme.palette.text.disabled,
                    minHeight: '127px',
                    display: 'flex',
                  }
                : editing
                  ? props.option
                    ? {
                        borderLeft: `6px solid ${props.option.color}`,
                        display: 'flex',
                      }
                    : {
                        borderLeft: `6px solid ${inputCardColor()}`,
                        display: 'flex',
                      }
                  : {
                      borderLeft: `6px solid ${inputCardColor()}`,
                      width: !disable || enableCheckbox ? 'calc(100% - 39px)' : '100%',
                      display: 'flex',
                    }
            }
            className={classes.divInsideDivInput}
          >
            {!disable ? (
              <div data-testid={'idNotSelectedInput'} ref={refHandle} style={{ width: '35px', alignSelf: 'center' }}>
                <DragIndicator
                  className={props.classes.moreVertIcon}
                  data-testid={'idDragIndicator'}
                  style={{ color: theme.palette.text.disabled }}
                />
              </div>
            ) : null}
            <div id={'idNotSelectedInput'} style={{ width: !disable ? 'calc(100% - 35px)' : '100%' }}>
              <div style={{ display: 'flex' }}>
                <div ref={templateRefs.inputTitleRef} className={classes.divInputName}>
                  {disable || !editing ? (
                    <div className={!inputTitle ? classes.inputNameDisableEmpty : classes.inputNameDisable}>
                      <IconInput input={input} classes={classes} />
                      <Anchorme target='_blank'>{inputTitle ? inputTitle : t('addTitle')}</Anchorme>
                      <span
                        id={`is-input-required`}
                        data-testid={`is-input-required`}
                        className={classes.requiredDisable}
                      >
                        {input.required ? ' *' : ''}
                      </span>
                    </div>
                  ) : (
                    <TextField
                      autoFocus={editing}
                      value={inputTitle}
                      key={input.name}
                      id={`input-name-${input.order}-${inputTitle}`}
                      data-testid={`input-name-${input.order}-${inputTitle}`}
                      variant={'standard'}
                      onFocus={() => {
                        setEditing(true);
                        if (setChildEdit) setChildEdit(true);
                      }}
                      onChange={(e) => {
                        const val = e.target.value;
                        setInputTitleOptimistic(val);
                      }}
                      onBlur={async (e) => {
                        const val = e.target.value;
                        if (val !== input.name) {
                          await setInput({ _id: input._id, name: val });
                        }
                      }}
                      InputProps={{ classes: { input: classes.textField, underline: classes.textFieldUnderline } }}
                      placeholder={t('addTitle')}
                      size={'small'}
                      multiline={true}
                      fullWidth={true}
                    />
                  )}
                  {props.error?.name ? <Warning classes={{ root: classes.warningIcon }} /> : null}
                </div>
                {!disable && editing ? (
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    {input.type === 'string' ||
                    input.type === 'number' ||
                    input.type === 'multiple' ||
                    input.type === 'qrCode' ||
                    input.type === 'tool' ||
                    (input.type === 'datetime' && input.subType === 'date' && props.context === 'element') ? (
                      <div
                        style={{ marginLeft: '10px' }}
                        ref={templateRefs.inputSettingsRef}
                        id={`divOpenAdvancedPopover`}
                        data-testid={`divOpenAdvancedPopover`}
                        onClick={(): void => setOpenAdvancedPopover(true)}
                      >
                        <div ref={refAdvanced} />
                        <Tooltip title={t('advanced').toString()}>
                          <IconButton size={'small'}>
                            <Settings classes={{ root: classes.iconSettingsAndMore }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    ) : null}
                    <div>
                      <IconButton
                        classes={{ root: classes.iconButtonMore }}
                        onClick={(e): void => setAnchorMore(e.target)}
                        size='large'
                      >
                        <MoreVert
                          data-testid={`generic-input-${input.name}-more`}
                          classes={{ root: classes.iconSettingsAndMore }}
                        />
                      </IconButton>
                    </div>
                  </span>
                ) : null}
              </div>
              <div>
                <div style={{ marginTop: '20px', display: addDescription ? 'block' : 'none' }} ref={refs.refRichText}>
                  <RichTextBox
                    input={input}
                    setInput={() => null}
                    setDesc={(e) => {
                      setDescription(e || '');
                    }}
                    desc={description ? description : input.description}
                    addDescription={addDescription}
                  />
                </div>
                <div
                  style={{
                    display:
                      !addDescription && (description || input.description) && !disable && editing ? 'block' : 'none',
                  }}
                >
                  <DescriptionField
                    disableClick={!editing}
                    description={description ? description : input.description}
                    setAddDescription={setAddDescription}
                  />
                </div>
                <div
                  id='addDescriptionButton'
                  data-testid='addDescriptionButton'
                  style={{
                    display:
                      !addDescription && !description && !input.description && !disable && editing ? 'flex' : 'none',
                  }}
                  className={classes.addButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAddDescription(true);
                  }}
                >
                  <Add style={{ fontSize: '18px', marginRight: '2px' }} />
                  {t('addDescription')}
                </div>
                <div
                  style={{
                    display:
                      !addDescription && (description || input.description) && (disable || !editing) ? 'block' : 'none',
                  }}
                >
                  <DescriptionField
                    disableClick={!editing}
                    description={description ? description : input.description}
                    setInput={setInput}
                    input={input}
                  />
                </div>
              </div>
              <div
                className={classes.inputContainer}
                style={input.type === 'instruction' && editing ? { display: 'flex', flexDirection: 'column' } : {}}
              >
                {!disable && editing && (
                  <div ref={templateRefs.inputTypeRef} style={{ display: 'flex', flexDirection: 'row' }}>
                    <SelectType
                      innerRef={refTypeMenu}
                      classes={classes}
                      input={input}
                      scoreInputs={scoreInputs}
                      setInput={setInput}
                      instruction={instruction}
                      refs={refs}
                      popoverAnchorEl={popoverAnchorEl}
                      setPopoverAnchorEl={(val) => startTransition(() => setPopoverAnchorEl(val))}
                      presets={presets}
                      templateId={templateId}
                    />
                    {input.type === 'instruction' && (
                      <ButtonAddFile
                        setUploadingFiles={setUploadingFiles}
                        setProgressMap={setProgressMap}
                        onAdd={async (files) => {
                          const newInput = cloneDeep(input);
                          newInput[input.type].values = [...files, ...input[input.type].values];
                          await setInput(newInput);
                        }}
                      />
                    )}
                  </div>
                )}
                {/* This div below needs to be taken out of this component ASAP, it is too big to remain here */}
                <div
                  key={input._id + '-main-field' + editing + disable}
                  style={{
                    width: '100%',
                    display: !editing && input.type === 'number' ? 'none' : undefined,
                  }}
                >
                  <GenericInputCardContext.Provider value={{ input, setRefConditional }}>
                    <InputFieldGeneric
                      placeholder={placeholder}
                      scoreInputs={scoreInputs}
                      id={`inputValue`}
                      input={
                        input.number && input.number.unit
                          ? { ...input, number: { ...input.number, unit: undefined } }
                          : input
                      }
                      required={input.required}
                      editable={input.type === 'instruction' && editing}
                      disabled={input.type !== 'instruction' || !editing}
                      cursorEnable={cursorEnable}
                      setScoreOptions={async (data): Promise<void> => {
                        const oldOption =
                          input[input.type].optionsWithScore?.length != 0
                            ? input[input.type].optionsWithScore.find((opt) => opt._id === data._id)
                            : input[input.type].options.find((opt) => opt._id === data._id);

                        const newFailOptions =
                          input[input.type].failOptions && oldOption
                            ? input[input.type].failOptions.map((opt) => (opt !== oldOption.label ? opt : data.label))
                            : [];

                        setInput({
                          _id: input._id,
                          [input.type]: {
                            ...input[input.type],
                            optionsWithScore:
                              input[input.type].optionsWithScore?.length === input[input.type].options.length
                                ? input[input.type].optionsWithScore.map((opt) =>
                                    opt._id === data._id || data.option === opt.option
                                      ? {
                                          option: data.option,
                                          score: parseInt(data.score),
                                        }
                                      : { option: opt.option, score: opt.score ? opt.score : 0 },
                                  )
                                : input[input.type].options.map((opt) =>
                                    opt._id === data._id
                                      ? {
                                          option: data.option,
                                          score: parseInt(data.score),
                                        }
                                      : { option: opt.label, score: opt.score ? opt.score : 0 },
                                  ),
                            onResponse: input[input.type].onResponse?.map((response: OnResponse) => ({
                              ...response,
                              ifValues:
                                (data?.label || oldOption) === (response?.ifValues[0].label || response?.ifValues[0])
                                  ? [data]
                                  : response.ifValues,
                            })),
                            failOptions: newFailOptions.length > 0 ? newFailOptions : input[input.type].failOptions,
                          },
                        });
                      }}
                      setOpts={setOpts}
                      setOptions={async (type: string, data: InputOptions): Promise<void> => {
                        const input = inputRef.current;

                        switch (type) {
                          case 'ADD':
                            await setInput({
                              _id: input._id,
                              [input.type]: {
                                ...input[input.type],
                                options: [...input[input.type].options, data],
                                optionsWithScore:
                                  scoreInputs && !input[input.type].optionsWithScore?.length
                                    ? [...input[input.type].options, data].map((opt) => ({
                                        option: opt.label,
                                        score: 0,
                                      }))
                                    : [
                                        ...(input[input.type].optionsWithScore ?? [{}]),
                                        {
                                          option: data.label,
                                          score: 0,
                                        },
                                      ],
                              },
                            });
                            break;
                          case 'REMOVE':
                            if (input[input.type].onResponse?.find((opt) => opt._id === data._id)) {
                              await props.clearOnResponseIdOnTask(data._id);
                            }

                            await setInput({
                              _id: input._id,
                              [input.type]: {
                                _id: input._id,
                                ...input[input.type],
                                options: (input[input.type].options as InputOptions[]).filter(
                                  (opt) => opt.id !== data.id,
                                ),
                                onResponse: input[input.type].onResponse?.filter((resp) =>
                                  resp.ifValues.some((v) => v.label != data.label),
                                ),
                                failOptions:
                                  instruction && input[input.type].failOptions?.filter((fo) => fo !== data.label),
                              },
                            });
                            break;
                          case 'MOVE':
                            await setInput({
                              _id: input._id,
                              [input.type]: {
                                ...input[input.type],
                                options: opts,
                              },
                            });
                            break;
                          case 'CHANGE': {
                            const oldOption = input[input.type].options.find((opt) => opt._id === data._id);

                            const newFailOptions =
                              input[input.type].failOptions && oldOption
                                ? input[input.type].failOptions.map((opt) =>
                                    opt !== oldOption.label ? opt : data.label,
                                  )
                                : [];
                            await setInput({
                              _id: input._id,
                              [input.type]: {
                                ...input[input.type],
                                options: input[input.type].options.map((opt) => (opt._id === data._id ? data : opt)),
                                onResponse: input[input.type].onResponse?.map((response: OnResponse) => ({
                                  ...response,
                                  ifValues:
                                    (data?.label || oldOption) ===
                                    (response?.ifValues[0].label || response?.ifValues[0])
                                      ? [data]
                                      : response.ifValues,
                                })),
                                failOptions: newFailOptions.length > 0 ? newFailOptions : input[input.type].failOptions,
                              },
                            });
                            break;
                          }
                          default:
                            return;
                        }
                      }}
                      validations={{ level }}
                      options={opts}
                      scoreOptions={scoreOpts}
                      handleChange={async (e: string[]) => {
                        if (input.type === 'instruction' || input.type === 'table') {
                          await setInput({
                            _id: input._id,
                            [input.type]: { ...input[input.type], values: e },
                          });
                        }
                      }}
                      onFocus={(): void => {
                        if (!editing) setEditing(true);
                        if (setChildEdit) setChildEdit(true);
                      }}
                      addCondition={
                        instruction
                          ? (item: { label: string; color?: string; id: string }) => {
                              let color: string | null = null;
                              input[input.type].options.map((opt: { label: string; color?: string; id: string }) => {
                                if (opt.label === item.label && opt.color) {
                                  color = opt.color;
                                } else if (opt.label === item.label && !opt.color) {
                                  color = colors[parseInt(`${currentColorIndex}`)];
                                  if (currentColorIndex === 3) {
                                    setCurrentColorIndex(0);
                                  } else {
                                    setCurrentColorIndex(currentColorIndex + 1);
                                  }
                                }
                              });
                              setInput({
                                _id: input._id,
                                [input.type]: {
                                  ...input[input.type],
                                  options: input[input.type].options.map(
                                    (opt: { label: string; color?: string; id: string }) =>
                                      opt.label === item.label ? { ...opt, color } : opt,
                                  ),
                                  onResponse: input[input.type].onResponse.filter(
                                    (onResp: OnResponse) =>
                                      //@ts-ignore
                                      onResp.ifValues[0].label === item.label ||
                                      //@ts-ignore
                                      onResp.ifValues[0] === item.label,
                                  ).length
                                    ? input[input.type].onResponse.map((onResp: OnResponse) =>
                                        //@ts-ignore
                                        onResp.ifValues[0].label === item.label ||
                                        //@ts-ignore
                                        onResp.ifValues[0] === item.label
                                          ? {
                                              ...onResp,
                                              ifValues: [{ ...item, color }],
                                              inputs: [
                                                {
                                                  order: 0,
                                                  name: 'New input',
                                                  type: 'string',
                                                  required: false,
                                                  string: {
                                                    options: [],
                                                    values: [],
                                                    validations: [],
                                                    multiple: false,
                                                    onResponse: [],
                                                  },
                                                },
                                              ],
                                            }
                                          : onResp,
                                      )
                                    : [
                                        ...input[input.type].onResponse,
                                        {
                                          ifValues: [{ ...item, color }],
                                          inputs: [
                                            {
                                              order: 0,
                                              name: 'New input',
                                              type: 'string',
                                              required: false,
                                              string: {
                                                options: [],
                                                values: [],
                                                validations: [],
                                                multiple: false,
                                                onResponse: [],
                                              },
                                            },
                                          ],
                                        },
                                      ],
                                },
                              });
                              setEditing(false);
                            }
                          : undefined
                      }
                      editableOptions={!disable && editing}
                      extended
                      noBorder={input.type === 'instruction'}
                      onlyText={!editing}
                      setActionRequired={(item: { label: string }) => {
                        setInput({
                          _id: input._id,
                          [input.type]: {
                            ...input[input.type],
                            onResponse: input[input.type].onResponse.filter(
                              (onResp: OnResponse) => onResp.ifValues[0].label === item.label,
                            ).length
                              ? input[input.type].onResponse.map((onResp: OnResponse) =>
                                  onResp.ifValues[0].label === item.label
                                    ? { ...onResp, actionRequired: true }
                                    : onResp,
                                )
                              : [
                                  ...input[input.type].onResponse,
                                  {
                                    actionRequired: true,
                                    inputs: [],
                                    ifValues: [item],
                                  },
                                ],
                          },
                        });
                      }}
                      setAlert={(item: { label: string }) => {
                        setInput({
                          _id: input._id,
                          [input.type]: {
                            ...input[input.type],
                            onResponse: input[input.type].onResponse.filter(
                              (onResp: OnResponse) => onResp.ifValues[0].label === item.label,
                            ).length
                              ? input[input.type].onResponse.map((onResp: OnResponse) =>
                                  onResp.ifValues[0].label === item.label ? { ...onResp, createAlert: true } : onResp,
                                )
                              : [
                                  ...input[input.type].onResponse,
                                  {
                                    createAlert: true,
                                    inputs: [],
                                    ifValues: [item],
                                  },
                                ],
                          },
                        });
                      }}
                      setFinishIssue={(item: { label: string }) => {
                        setInput({
                          _id: input._id,
                          [input.type]: {
                            ...input[input.type],
                            onResponse: input[input.type].onResponse.filter(
                              (onResp: OnResponse) => onResp.ifValues[0].label === item.label,
                            ).length
                              ? input[input.type].onResponse.map((onResp: OnResponse) =>
                                  onResp.ifValues[0].label === item.label ? { ...onResp, finishIssue: true } : onResp,
                                )
                              : [
                                  ...input[input.type].onResponse,
                                  {
                                    finishIssue: true,
                                    inputs: [],
                                    ifValues: [item],
                                  },
                                ],
                          },
                        });
                      }}
                      refCreateAction={refs.refCreateAction}
                      refCreateAlert={refs.refCreateAlert}
                      refNewAnswer={refs.refNewAnswer}
                      refFailResponse={refs.refFailResponse}
                      refCancelIssue={refs.refCancelIssue}
                      refExecuteNewTask={refs.refExecuteNewTask}
                      refFinishIssue={refs.refFinishIssue}
                      onlyInput
                      innerRef={refInput}
                      addFailResponse={
                        instruction
                          ? (item: { label: string }) => {
                              const color = theme.palette.error.main;
                              setInput({
                                _id: input._id,
                                [input.type]: {
                                  ...input[input.type],
                                  options: input[input.type].options.map(
                                    (opt: { label: string; color?: string; id: string }) =>
                                      opt.label === item.label ? { ...opt, color } : opt,
                                  ),
                                  failOptions: input[input.type].failOptions
                                    ? [...input[input.type].failOptions, item.label]
                                    : [item.label],
                                },
                              });
                            }
                          : null
                      }
                      setCancelIssue={(item: { label: string }) => {
                        instruction
                          ? setInput({
                              _id: input._id,
                              [input.type]: {
                                ...input[input.type],
                                onResponse: input[input.type].onResponse.filter(
                                  (onResp: OnResponse) => onResp.ifValues[0].label === item.label,
                                ).length
                                  ? input[input.type].onResponse.map((onResp: OnResponse) =>
                                      onResp.ifValues[0].label === item.label
                                        ? { ...onResp, cancelIssue: true }
                                        : onResp,
                                    )
                                  : [
                                      ...input[input.type].onResponse,
                                      {
                                        cancelIssue: true,
                                        inputs: [],
                                        ifValues: [item],
                                      },
                                    ],
                              },
                            })
                          : null;
                      }}
                      addNewTask={(item) => {
                        setInput({
                          _id: input._id,
                          [input.type]: {
                            ...input[input.type],
                            onResponse: input[input.type].onResponse.filter(
                              (onResp: OnResponse) => onResp.ifValues[0].label === item.label,
                            ).length
                              ? input[input.type].onResponse.map((onResp: OnResponse) =>
                                  onResp.ifValues[0].label === item.label ? { ...onResp, createTasks: true } : onResp,
                                )
                              : [
                                  ...input[input.type].onResponse,
                                  {
                                    createTasks: true,
                                    inputs: [],
                                    ifValues: [item],
                                  },
                                ],
                          },
                        });
                      }}
                      progressMap={progressMap}
                      uploadingFiles={uploadingFiles}
                    />
                  </GenericInputCardContext.Provider>
                </div>
                {input.type === 'number' &&
                (instruction || editing) &&
                (!editing ? !!input.number?.unit : true) &&
                !props.isMasterTenant ? (
                  <UnitField
                    disabled={disable || !editing}
                    data-testid={'select-unit-field'}
                    classes={{
                      input: editing ? classes.adjustUnitField : classes.noBorder,
                      chipWidth: editing ? undefined : classes.noBorder,
                    }}
                    placeholder={t('addUnit')}
                    onChange={(e) =>
                      setInput({
                        _id: input._id,
                        number: { ...input.number, unit: e },
                      })
                    }
                    value={input.number?.unit?._id || (input.number?.unit as any)}
                    popoverRef={refUnit}
                  />
                ) : null}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  marginTop: '18px',
                  gap: '5px',
                  /* marginLeft: !disable && editing ? '195px' : '5px', */
                }}
              >
                <ShowValidations
                  setInput={setInput}
                  input={input}
                  key={!disable && editing}
                  active={!disable && editing}
                  onBlur={onInputBlur}
                  selectRef={refSelectOption}
                  refSelectOptionTask={refSelectOptionTask}
                  refSelectOptionInputToLink={refSelectOptionInputToLink}
                  setActionDraftsToDeletefromDraft={props.setActionDraftsToDeletefromDraft}
                  setActionDraftsToDeletefromTemplate={props.setActionDraftsToDeletefromTemplate}
                />
                <NumberChipConditional
                  refNumberCondFirstSelect={refNumberCondFirstSelect}
                  refNumberCondSecondSelect={refNumberCondSecondSelect}
                  setInput={setInput}
                  input={input}
                  level={level}
                  active={!disable && editing}
                  error={props.error}
                  setActionDraftsToDeletefromDraft={props.setActionDraftsToDeletefromDraft}
                  setActionDraftsToDeletefromTemplate={props.setActionDraftsToDeletefromTemplate}
                  associateTaskToInput={props.associateTaskToInput}
                  addTask={props.addTask}
                  repeatTasksFunction={props.repeatTasks}
                />
                <AlertChip
                  refNumberCondFirstSelect={refNumberCondFirstSelect}
                  refNumberCondSecondSelect={refNumberCondSecondSelect}
                  refNumberCondThirdSelect={refNumberCondThirdSelect}
                  setInput={setInput}
                  input={input}
                  level={level}
                  active={!disable && editing}
                  error={props.error}
                />
                <AlertChipExecution setInput={setInput} input={input} level={level} active={!disable && editing} />
                <FailResponseChip
                  input={input}
                  setInput={setInput}
                  refNumberCondFirstSelect={refNumberCondFirstSelect}
                  active={!disable && editing}
                />
                <CancelIssueChip setInput={setInput} input={input} active={!disable && editing} />
                <FinishIssueChip setInput={setInput} input={input} active={!disable && editing} />
                <CreateTaskChip
                  input={input}
                  setInput={setInput}
                  active={!disable && editing}
                  addTask={props.addTask}
                  associateTaskToInput={props.associateTaskToInput}
                />
                {input.type !== 'number' ? (
                  <AnswerNewQuestionChip input={input} setInput={setInput} active={!disable && editing} />
                ) : undefined}
                <div
                  id='requiredButton'
                  data-testid='requiredButton'
                  className={classes.divRequired}
                  style={{ display: !disable && editing ? 'flex' : 'none' }}
                >
                  <CheckBox
                    style={{ fontSize: '18px', display: input.required ? 'block' : 'none' }}
                    id={`unsetRequired`}
                    className={props.classes.moreVertIcon}
                    onClick={(): void => {
                      if (!input[input.type].validations?.find((v) => v.name === 'linkWith'))
                        setInput({ _id: input._id, required: false });
                    }}
                  />
                  <CheckBoxOutlineBlankOutlined
                    style={{ fontSize: '18px', display: !input.required ? 'block' : 'none' }}
                    id={`setRequired`}
                    className={props.classes.moreVertIcon}
                    onClick={(): void => {
                      setInput({ _id: input._id, required: true });
                    }}
                  />
                  <span>{t('required')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!!anchorMore && (
          <Popover
            open={true}
            // @ts-ignore
            anchorEl={anchorMore}
            onClose={() => startTransition(() => setAnchorMore(null))}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem
              ref={refs.refDuplicate}
              data-testid={`task-popover-${input.name}-duplicate`}
              onClick={async () => {
                if (input.type === 'selection' && input[input.type]?.preset?.presetId) {
                  const presetSelected = issueTemplate.frame.presets.find(
                    (preset) => preset._id === input[input.type]?.preset?.presetId,
                  );
                  const response = await editPreset(
                    issueTemplate._id,
                    //@ts-ignore
                    { inputs: [input._id], options: presetSelected.options },
                    input[input.type].preset?.presetId,
                  );
                  updateIssueTemplateDraftState({
                    frame: { ...issueTemplate.frame, presets: response.frame.presets },
                  });
                }
                props.duplicate(input);
                setAnchorMore(null);
                setEditing(false);
              }}
            >
              <FileCopyOutlined style={{ fontSize: '21px', marginRight: '5px' }} />
              {t('duplicate')}
            </MenuItem>
            <MenuItem
              ref={refs.refDelete}
              classes={{ root: classes.menuItemDelete }}
              data-testid={`task-popover-${input.name}-delete`}
              onClick={(): void => {
                props.delete(input._id);
                setAnchorMore(null);
                setEditing(false);
                if (setChildEdit) setChildEdit(false);
              }}
            >
              <DeleteOutline style={{ fontSize: '21px', marginRight: '5px' }} />
              {t('delete')}
            </MenuItem>
          </Popover>
        )}
        {openAdvancedPopover && (input.type === 'string' || input.type === 'qrCode' || input.type === 'tool') ? (
          <StringPopover
            setInput={setInput}
            input={input}
            anchor={refAdvanced}
            subRef={refSubPopoverAdvanced}
            innerRef={refPopoverAdvanced}
            onClose={() => startTransition(() => setOpenAdvancedPopover(false))}
          />
        ) : null}
        {openAdvancedPopover && input.subType === 'date' ? (
          <DatePopover
            setInput={setInput}
            input={input}
            anchor={refAdvanced}
            subRef={refSubPopoverAdvanced}
            innerRef={refPopoverAdvanced}
            onClose={() => startTransition(() => setOpenAdvancedPopover(false))}
          />
        ) : null}
        {openAdvancedPopover && input.type === 'number' ? (
          <NumberPopover
            setInput={setInput}
            input={input}
            instruction={instruction}
            anchor={refAdvanced}
            innerRef={refPopoverAdvanced}
            subRef={refSubPopoverAdvanced}
            onClose={() => startTransition(() => setOpenAdvancedPopover(false))}
          />
        ) : null}
        {openAdvancedPopover && input.type === 'multiple' ? (
          <MultiplePopover
            open={openAdvancedPopover}
            setInput={setInput}
            input={input}
            anchor={refAdvanced}
            innerRef={refPopoverAdvanced}
            onClose={() => startTransition(() => setOpenAdvancedPopover(false))}
          />
        ) : null}
        {input[input.type].onResponse?.map((resp, i) =>
          !resp.inputs?.length ? (
            <></>
          ) : (
            <div key={i} style={{ marginLeft: '39px' }}>
              <Timeline style={{ paddingRight: 0 }}>
                <TimelineItem classes={{ root: classes.timelineItem }}>
                  <TimelineSeparator>
                    <TimelineDot
                      style={{
                        backgroundColor: resp.inputs.filter((k) => childEditing[k._id]).length
                          ? resp.color || (resp.ifValues?.length && resp.ifValues[0] && resp.ifValues[0].color)
                          : '#CACACA',
                        marginBottom: 0,
                        padding: '10px',
                      }}
                    />
                    <TimelineConnector
                      style={{
                        backgroundColor: resp.inputs.filter((k) => childEditing[k._id]).length
                          ? resp.color || (resp.ifValues?.length && resp.ifValues[0] && resp.ifValues[0].color)
                          : '#CACACA',
                        width: '4px',
                      }}
                    />
                  </TimelineSeparator>
                  <TimelineContent style={{ paddingRight: 0 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Chip
                        style={{
                          backgroundColor:
                            resp.color || (resp.ifValues?.length && resp.ifValues[0] && resp.ifValues[0].color),
                          marginBottom: '10px',
                        }}
                        label={
                          <div style={{ color: '#FFF' }}>
                            {t('ifAnswerIs')}{' '}
                            <span
                              style={{
                                fontWeight: 700,
                                fontFamily: 'Open Sans',
                              }}
                            >
                              {resp.condition
                                ? !(resp.condition === 'BETWEEN' || resp.condition === 'NOT_BETWEEN')
                                  ? t(resp.condition) + ' ' + resp.ifValues[0]
                                  : t(resp.condition) + ' ' + resp.ifValues[0] + ` ${t('and')} ` + resp.ifValues[1]
                                : ' ' + resp.ifValues[0].label}
                            </span>
                          </div>
                        }
                      />
                      {selectedConditionalInputs?.length && !props.conditionalNotRemovable ? (
                        <Delete style={{ color: 'red', cursor: 'pointer' }} onClick={removeInputs} />
                      ) : null}
                    </div>
                    {disable ? undefined : (
                      <div className={classes.divDivider}>
                        <IconButton
                          id={`addButtonTop${resp._id}`}
                          classes={{ root: classes.buttonAddInput }}
                          onClick={(): void => {
                            addInput(-1, resp._id);
                          }}
                          size='large'
                        >
                          <Add classes={{ root: classes.iconAdd }} />
                        </IconButton>
                      </div>
                    )}
                    {resp.inputs.map((inp) => (
                      <div
                        className={classes.conditionalDiv}
                        key={inp._id}
                        onMouseEnter={() => setCheckboxOver(inp._id)}
                        onMouseLeave={() => setCheckboxOver('')}
                      >
                        {!props.conditionalNotRemovable ? (
                          selectedConditionalInputs.includes(inp._id) ? (
                            <div className={classes.checkBoxInput}>
                              <CheckBox
                                onClick={() =>
                                  setSelectedConditionalInputs(
                                    selectedConditionalInputs.filter((elem) => elem !== inp._id),
                                  )
                                }
                              />
                            </div>
                          ) : (
                            <div
                              className={classes.checkBoxInput}
                              style={checkboxOver === inp._id ? { display: 'block' } : { display: 'none' }}
                            >
                              <CheckBoxOutlineBlank
                                onClick={() => setSelectedConditionalInputs([...selectedConditionalInputs, inp._id])}
                              />
                            </div>
                          )
                        ) : null}
                        <div style={{ width: '100%' }}>
                          <GenericInputCard
                            firstTime={firstTime}
                            instruction={instruction}
                            enableCheckbox={enableCheckbox}
                            dropCard={props.dropCard}
                            error={props.allErrors && props.allErrors[inp._id] && props.allErrors[inp._id]}
                            allErrors={props.allErrors}
                            moveCard={props.moveCard}
                            duplicate={(inp) => duplicateSubInput(inp, resp._id || '')}
                            option={resp.ifValues ? resp.ifValues[0] : []}
                            classes={classes}
                            disable={disable}
                            level={level !== undefined ? level + 1 : 0}
                            checkboxInputsSelect={checkboxInputsSelectConditionals}
                            clearOnResponseIdOnTask={props.clearOnResponseIdOnTask}
                            setCheckboxInputsSelect={setCheckboxInputsSelectConditionals}
                            setChildEdit={(e) => {
                              if (childEditing[inp._id] !== e)
                                childEditing = {
                                  ...childEditing,
                                  [inp._id]: e,
                                };
                            }}
                            delete={(e): void => {
                              const inpToDel = {
                                ...input,
                                [input.type]: {
                                  ...input[input.type],
                                  onResponse: input[input.type].onResponse
                                    .map((response: OnResponse) => ({
                                      ...response,
                                      inputs: response.inputs.filter((resInp) => resInp._id !== e),
                                    }))
                                    .filter(
                                      (resp: OnResponse) =>
                                        resp.inputs.length ||
                                        resp.actionRequired ||
                                        resp.createAlert ||
                                        resp.createTasks ||
                                        resp.finishIssue,
                                    ),
                                },
                              };

                              const cleanOptions = inpToDel[inpToDel.type].options.map(
                                (opt: { label: string; color?: string }) =>
                                  inpToDel[inpToDel.type].onResponse.filter(
                                    (r: OnResponse) => r.ifValues[0].label === opt.label,
                                  ).length
                                    ? opt
                                    : { ...opt, color: undefined },
                              );

                              setInput({
                                _id: inpToDel._id,
                                [inpToDel.type]: {
                                  ...inpToDel[inpToDel.type],
                                  options: cleanOptions,
                                },
                              });
                            }}
                            input={inp}
                            setInput={setInput}
                            addTask={props.addTask}
                            addInput={addInput}
                            associateTaskToInput={props.associateTaskToInput}
                            setActionDraftsToDeletefromDraft={props.setActionDraftsToDeletefromDraft}
                            setActionDraftsToDeletefromTemplate={props.setActionDraftsToDeletefromTemplate}
                            templateGroupId={props.templateGroupId}
                            repeatTasks={props.repeatTasks}
                            checkboxInputsSelectConditionals={props.checkboxInputsSelectConditionals}
                            setCheckboxInputsSelectConditionals={props.setCheckboxInputsSelectConditionals}
                            conditionalNotRemovable={props.conditionalNotRemovable}
                          />
                        </div>
                        {disable ? undefined : (
                          <div className={classes.divDivider}>
                            <IconButton
                              id={`addButtonBottom-${inp.order}-${inp.name}`}
                              classes={{ root: classes.buttonAddInput }}
                              onClick={(): void => {
                                addInput(inp.order, resp._id);
                              }}
                              size='large'
                            >
                              <Add classes={{ root: classes.iconAdd }} />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    ))}
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </div>
          ),
        )}
      </div>
    </BlockCloseDropdownContext.Provider>
  );
};

const connector = typedConnect((state) => ({ isMasterTenant: state.authenticationReducer.tenantInfo.master }), {
  editPreset,
});

const GenericInputCard = compose<any>(withStyles(styles), connector, withIssueTemplateConnector)(GenericInput);

export default GenericInputCard;
