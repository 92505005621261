import { Box, Card, Typography } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import DefaultBackground from 'src/utils/components/default-background';
import DefaultButton from 'src/utils/components/default-button';
// import { isValidToken, resPtPassword } from 'src/base/reset-password/reset-password.backend';
import GlartekDark from '@assets/images/glartek_dark.png';
import GlartekLight from '@assets/images/GlarVision.png';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { createAccountByInvite, isValidDomain } from 'src/base/create-account/create-account.redux';
import { styles } from 'src/base/create-account/styles/create-account';
import { login } from 'src/base/login/login.redux';
import { showSnackbar } from 'src/base/root/root.redux';
import { useResetPasswordMutation } from 'src/gql/graphql';
import { isValidInvite } from 'src/modules/account/invites/account.invites.redux';
import { getTenantInfo } from 'src/modules/authentication/authentication.redux';
import InputField from 'src/utils/components/input-field/input-field';
import { withBlankPage } from '../main-container/hocs/with-blank-page';

interface CreateAccountProps extends ConnectedProps<typeof connector>, WithStyles<typeof styles> {}

const CreateAccount = (props: CreateAccountProps) => {
  const [password, setPassword] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const location = useLocation();
  const { domain, token, fresh = false } = queryString.parse(location.search) as Record<string, string>;
  const { t } = useTranslation();
  const [tenantName, setTenantName] = useState<string>('');
  const navigate = useNavigate();

  const [resetPassword] = useResetPasswordMutation();

  useEffect(() => {
    const validateParameters = async () => {
      const isDomainValid = await props.isValidDomain(domain);
      if (!isDomainValid || isDomainValid?.graphQLErrors) {
        navigate('/');
      }

      if (!fresh) {
        const isInviteValid = await props.isValidInvite(domain, token);
        if (!isInviteValid || isInviteValid?.graphQLErrors) {
          navigate('/');
        }
      }

      const tenantInfo = await props.getTenantInfo(domain);
      if (tenantInfo) setTenantName(tenantInfo.name);
    };

    validateParameters();
  }, [domain, token]);

  const validate = () => {
    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
    if (password === '') return t('errorPasswordNotDefined');
    if (repeatPassword === '') return t('errorConfirmPasswordNotDefined');
    if (password !== repeatPassword) return t('errorPasswordNotMatch');
    if (!pattern.test(password)) return t('accountErrorStrongPassword');
    return null;
  };

  const handleSave = async () => {
    setSaving(true);

    const error = validate();
    if (error) {
      await props.showSnackbar('error', error);
      setSaving(false);
      return;
    }

    let response;

    if (!fresh) {
      response = await props.createAccountByInvite(domain, token, password, repeatPassword);
    } else {
      response = (await resetPassword({ variables: { newPassword: password, token } })).data.resetPassword;
    }

    if (response?.graphQLErrors || !response) {
      await props.showSnackbar('error', 'Error');
      setSaving(false);
      return;
    } else {
      await props.showSnackbar('success', 'Account created with success!');
      await props.login(response.email, password, domain);

      setSaving(false);
      navigate('/');
    }
  };

  const check = (t: { charCode: number }) => t.charCode === 13 && handleSave();

  return (
    <DefaultBackground>
      <div className={`hidden ${props.classes.headerBrand}`}>
        <span>
          <img
            src={
              props.themeSelected === 'dark'
                ? props.tenantInfo?.resources?.logo_dark?.url || GlartekDark
                : props.tenantInfo?.resources?.logo_light?.url || GlartekLight
            }
            height={56}
            width={'auto'}
          />
        </span>
      </div>
      <Box boxShadow={6}>
        <form>
          <Card
            enzyme-attr='component-login-card'
            className={`card-body`}
            onKeyPress={check}
            classes={{ root: props.classes.loginWrapper }}
          >
            <div className={props.classes.divCard}>
              <Typography variant='h5' className={props.classes.titleJoinCompany}>
                {t('youAreAboutToJoinTeam', { team: tenantName })}
              </Typography>
              <span className={props.classes.spanDefinePassword}>{t('defineYourPassword')}</span>
            </div>
            <input hidden type='text' autoComplete='username' value={null} />
            <div className={props.classes.divInput}>
              <InputField
                title={t('password')}
                required
                type={'password'}
                autocomplete={'password'}
                disabled={false}
                showPassword
                id={'inputPassword'}
                handleChange={(e: string[]): void => setPassword(e[0])}
                values={[password]}
              />
            </div>
            <div className={props.classes.divInput}>
              <InputField
                title={t('repeatPassword')}
                required
                type={'password'}
                autocomplete={'password'}
                showPassword
                id={'inputRepeatPassword'}
                disabled={false}
                handleChange={(e: string[]): void => setRepeatPassword(e[0])}
                values={[repeatPassword]}
              />
            </div>
            <Box display='flex' style={{ justifyContent: 'center', marginTop: '24px' }}>
              <DefaultButton
                loader={saving}
                id={'buttonCreateAccount'}
                enzyme-attr='a-login'
                label={t('save')}
                success
                onClick={handleSave}
              />
            </Box>
            <div className={props.classes.divCancel}>
              <span className={props.classes.spanNotIntended}>
                {t('notWhatYouIntended')}{' '}
                <span onClick={() => navigate('/')} className={props.classes.spanCancel}>
                  {t('cancel')}
                </span>
              </span>
            </div>
          </Card>
        </form>
      </Box>
    </DefaultBackground>
  );
};

const connector = connect(
  (state) => ({
    defaultRoute: state.accountReducer.defaultRoute,
    themeSelected: state.loginReducer.theme,
    tenantInfo: state.authenticationReducer.tenantInfo,
  }),
  { showSnackbar, createAccountByInvite, isValidDomain, isValidInvite, login, getTenantInfo },
);

export const render = {
  create: {
    default: compose<any>(withBlankPage, withTranslation('translation'), withStyles(styles), connector)(CreateAccount),
  },
};

export default render;
