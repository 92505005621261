import { createContext } from 'react';
import { File } from 'src/gql/graphql';

//This context could possibly grow into something bigger but for now only need to have a single function here
interface IFilesContext {
  files: File[];
  handleChange: (values: File[]) => void;
}

export const FilesContext = createContext<IFilesContext>(null);
