import GlarFilterPopover, { GenericFilterPopoverProps } from '..';
import { IFiltersDateRange } from '../helper';
import { AccountFilterItem, DateFilterItem, LabelValueFilterItem } from '../preset-filter-items';
import { Account, LabelValue } from 'src/gql/graphql';

export type FeedFilters = Partial<{
  createdAt: IFiltersDateRange;
  author: Account[];
  labels: LabelValue[];
}>;

export default function FeedFilterPopover(props: GenericFilterPopoverProps<FeedFilters>) {
  return (
    <GlarFilterPopover {...props}>
      <DateFilterItem accessor={'createdAt'} />
      <AccountFilterItem accessor={'author'} />
      <LabelValueFilterItem accessor={'labels'} context={'labelAccount'} />
    </GlarFilterPopover>
  );
}
