import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    onBoardingContainer: {
      display: 'flex',
      flexDirection: 'column',
      transition: 'all .6s cubic-bezier(.685,.0473,.346,1)',
      background: theme.palette.primary.main,
      padding: '0 24px 12px 24px',
      marginTop: '55px',
      maxHeight: '38px',
      gap: '8px',
      '&.show': {
        transition: 'all .6s ease',
        maxHeight: '100px',
      },
      width: '100%',
      position: 'fixed',
      zIndex: 1,
    },
    onBoardingBarContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '16px',
    },
    onBoardingGetStarted: {
      fontSize: '14px',
      color: theme.palette.background.default,
      fontWeight: 400,
      lineHeight: '16px',
    },
    onBoardingBar: {
      maxWidth: '190px',
      width: '100%',
      height: '16px',
      borderRadius: '16px',
      display: 'flex',
    },
    onBoardingBarColorBackground: {
      background: 'rgba(255, 255, 255, 0.01)',
    },
    onBoardingBarColorPrimary: {
      background: theme.palette.background.default,
      borderRadius: '16px',
    },
    onBoardingBarContainerSec: {
      position: 'relative',
      display: 'flex',
      width: '190px',
    },
    onBoardingBarValue: {
      position: 'absolute',
      left: '50%',
      bottom: '-4px',
      transform: 'translateX(-50%)',
      fontSize: '12px',
    },
    onBoardingHideSteps: {
      color: theme.palette.background.default,
      marginLeft: 'auto',
      fontSize: '14px',
      padding: '6px 8px',
    },
  });
