import { Warning } from '@mui/icons-material';
import { InputLabel } from '@mui/material';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import ActionField from 'src/utils/components/action-field';
import CheckboxField from 'src/utils/components/checkbox-field';
import FileField from 'src/utils/components/file-field';
import InputBox from 'src/utils/components/input-field/input-box';
import { styles } from 'src/utils/components/input-field/input-field/styles';
import PhotoField from 'src/utils/components/photo-field';
import SelectBox from 'src/utils/components/select-box';
import i18n from 'src/utils/translations/i18n';

export interface Size {
  width: string;
  height: string;
}
interface InputFieldProps {
  id?: string;
  title?: string;
  type: string;
  showPassword?: boolean;
  disabled?: boolean;
  required?: boolean;
  handleChange: (arg1?: any, arg2?: any) => void;
  listValues?: any[];
  values?: any;
  value?: any;
  editable?: boolean;
  size?: Size;
  classNameInput?: any;
  classNameContainer?: any;
  placeholder?: string;
  onClick?: any;
  onKeyDown?: any;
  onKeyPress?: any;
  min?: number;
  max?: number;
  onBlur?: any;
  defaultValue?: any;
  t?: (prop: string) => string;
  checkbox?: boolean;
  inline?: boolean;
  action?: (props?: any) => any;
  reset?: () => void;
  template?: any;
  labelWhite?: boolean;
  multiple?: boolean;
  mouseOver?: boolean;
  minDate?: Date;
  autoFocus?: boolean;
  classes?: any;
  error?: boolean;
  autocomplete?: string;
}

const InputField = (props: InputFieldProps) => {
  const renderInput = (type: string) => {
    const {
      id,
      disabled,
      editable,
      size,
      values,
      handleChange,
      listValues,
      value,
      classNameInput,
      placeholder,
      onClick,
      onKeyDown,
      onKeyPress,
      min,
      max,
      onBlur,
      defaultValue,
      checkbox,
      inline,
      action,
      reset,
      template,
      multiple,
      mouseOver,
      minDate,
      autoFocus,
      error,
      showPassword,
      autocomplete,
    } = props;
    switch (type) {
      case 'action':
        return (
          <ActionField
            mouseOver={mouseOver}
            disabled={disabled || false}
            fireEvent={action}
            placeholder={placeholder}
            values={values}
            id={id}
            reset={reset}
            error={error}
          />
        );
      case 'label':
        return (
          <SelectBox
            mouseOver={mouseOver}
            disabled={disabled}
            id={id}
            values={values}
            handleChange={handleChange}
            placeholder={placeholder || ''}
            error={error}
            listValues={
              listValues && listValues.length > 0 && listValues[0].id && listValues[0].label
                ? listValues
                : listValues
                  ? listValues.map((lv) => ({ id: lv._id, label: lv.value }))
                  : []
            }
            multiple={multiple}
          />
        );
      case 'photo':
        return (
          <PhotoField mouseOver={mouseOver} editable={editable} size={size} callback={handleChange} value={value} />
        );
      case 'file':
        return (
          <FileField keyValue={moment().toISOString()} editable={editable} handleChange={handleChange} value={value} />
        );
      case 'checkbox':
        return (
          <CheckboxField
            mouseOver={mouseOver}
            disabled={disabled}
            handleChange={handleChange}
            id={id}
            value={value}
            checkbox={checkbox}
            inline={inline}
          />
        );
      default:
        return (
          <InputBox
            mouseOver={mouseOver}
            id={id}
            onClick={onClick}
            min={min}
            max={max}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            defaultValue={defaultValue}
            onKeyDown={onKeyDown}
            type={type}
            disabled={disabled || false}
            values={values}
            onChange={handleChange}
            className={classNameInput}
            placeholder={placeholder || ''}
            template={template}
            minDate={minDate}
            autoFocus={autoFocus}
            error={error}
            showPassword={showPassword}
            options={[]}
            autocomplete={autocomplete}
          />
        );
    }
  };

  renderInput.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    handleChange: PropTypes.func,
    listValues: PropTypes.any,
    values: PropTypes.any,
    value: PropTypes.any,
    editable: PropTypes.bool,
    size: PropTypes.object,
    classNameInput: PropTypes.any,
    classNameContainer: PropTypes.any,
    placeholder: PropTypes.string,
    onClick: PropTypes.any,
    onKeyDown: PropTypes.any,
    onKeyPress: PropTypes.any,
    min: PropTypes.string,
    max: PropTypes.string,
    onBlur: PropTypes.any,
    defaultValue: PropTypes.any,
    t: PropTypes.any,
    checkbox: PropTypes.bool,
    inline: PropTypes.bool,
    action: PropTypes.any,
    reset: PropTypes.any,
    template: PropTypes.any,
    labelWhite: PropTypes.bool,
    multiple: PropTypes.bool,
    numFiles: PropTypes.number,
    maxSize: PropTypes.number,
    mouseOver: PropTypes.bool,
    minDate: PropTypes.any,
    autoFocus: PropTypes.bool,
    classes: PropTypes.any,
    error: PropTypes.bool,
  };

  const { title, type, required, classNameContainer, inline, template, labelWhite } = props;
  return (
    <div
      className={`${inline ? props.classes.inline : props.classes.flex} ${props.classes.inputContainer} ${
        classNameContainer ? classNameContainer : ''
      }`}
    >
      {title && (
        <div className={`${inline ? props.classes.unflex : ''} ${props.classes.label}`}>
          <InputLabel style={{ width: '100%' }}>
            <span
              className={`${props.classes.label} ${title !== undefined ? null : props.classes.italicUndefined} ${
                labelWhite ? props.classes.labelNameWhite : props.classes.labelName
              }`}
            >{`${title !== undefined ? title : i18n.t('undefined')}${required ? ' *' : ''}`}</span>
            <span className={props.classes.labelNameTemplate}>{template ? template.tempName : ''}</span>
            {props.error ? <Warning classes={{ root: props.classes.warningIcon }} /> : null}
          </InputLabel>
        </div>
      )}
      {renderInput(type)}
    </div>
  );
};

export default withStyles(styles)(InputField);
