import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { styles } from 'src/utils/components/table/components/draggable-column-header/styles';
import { Column, ColumnOrderState, flexRender, Header, Table } from '@tanstack/react-table';
import { useDrag, useDrop } from 'react-dnd';
import { ColumnDragPosition } from '../enums/table.enums';
import { reorderColumn } from '../components/draggable-column-header/utils/draggable-column-header.utils';
import { CHECKBOX_HEADER_ID, MORE_OPTIONS_HEADER_ID } from '../constants/table.constants';
import makeStyles from '@mui/styles/makeStyles';

type DraggableColumnHeaderProps<TData> = {
  table: Table<TData>;
  header: Header<TData, unknown>;
  tableRef: MutableRefObject<HTMLDivElement>;
  setColumnOrder: (order: ColumnOrderState) => void;
  onColumnSizeChange?: (col: { newSize: number; name: string }) => void;
};

const useStyles = makeStyles(styles);

const TableAbstractColumns = <TData,>(props: DraggableColumnHeaderProps<TData>): JSX.Element => {
  const { table, header, setColumnOrder, tableRef, onColumnSizeChange } = props;
  const classes = useStyles();
  const { getState } = table;
  const { columnOrder } = getState();
  const { column } = header;

  const dragColumnRef = useRef<HTMLDivElement>();
  const dropColumnRef = useRef<HTMLTableCellElement>();
  const previewColumnRef = useRef<HTMLDivElement>();
  const isFirstRender = useRef(true);

  const [hoverPosition, setHoverPosition] = useState<ColumnDragPosition>(ColumnDragPosition.None);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (!header.column.getIsResizing()) {
      onColumnSizeChange?.({ newSize: header.column.getSize(), name: header.column.id });
    }
  }, [header.column.getIsResizing()]);

  const [{ isDragging }, connectDrag, connectPreview] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => column,
    type: 'column',
  });

  const [{ isOver }, connectDrop] = useDrop({
    accept: 'column',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    hover: (draggedColumn, monitor) => {
      if (!dragColumnRef.current) {
        return;
      }

      const dragIndex = draggedColumn.id;
      const hoverIndex = column.id;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = dragColumnRef.current?.getBoundingClientRect();
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverActualX = clientOffset.x - hoverBoundingRect.left;

      // // if dragging down, continue only when hover is smaller than middle X
      // if (dragIndex < hoverIndex && hoverActualX < hoverMiddleX) return;
      // // if dragging up, continue only when hover is bigger than middle X
      // if (dragIndex > hoverIndex && hoverActualX > hoverMiddleX) return;

      setHoverPosition(hoverActualX < hoverMiddleX ? ColumnDragPosition.Left : ColumnDragPosition.Right);
    },
    drop: (draggedColumn: Column<TData>) => {
      const newColumnOrder = reorderColumn(draggedColumn.id, column.id, columnOrder, hoverPosition);
      setHoverPosition(ColumnDragPosition.None);
      setColumnOrder(newColumnOrder);
    },
  });

  useEffect(() => {
    if (!dropColumnRef.current) return;
  }, [isOver]);

  if (props.header.id !== MORE_OPTIONS_HEADER_ID && props.header.id !== CHECKBOX_HEADER_ID) {
    connectDrag(dragColumnRef);
    connectDrop(dropColumnRef);
    connectPreview(previewColumnRef);
  }

  const settingsColumnStyles = header.id === '_more_' && {
    zIndex: 1,
    right: 0,
    borderLeft: '',
  };

  return (
    <th
      key={header.id}
      colSpan={header.colSpan}
      style={{
        ...settingsColumnStyles,
        position: header.id === '_more_' ? 'sticky' : 'relative',
        width: header.getSize(),
        opacity: isDragging ? 0.5 : 1,
      }}
      ref={dropColumnRef}
      className={classes.header}
    >
      <div ref={previewColumnRef}>
        {hoverPosition === ColumnDragPosition.Left && (
          <div
            style={{
              height: isOver ? tableRef?.current?.clientHeight : 'unset',
              position: 'absolute',
            }}
            className={classes.onOverLeft}
          />
        )}
        <div ref={dragColumnRef}>{flexRender(header.column.columnDef.header, header.getContext())}</div>
        <div
          onMouseDown={header.getResizeHandler()}
          onTouchStart={header.getResizeHandler()}
          className={
            isOver || !header.column.getCanResize()
              ? ''
              : `${classes.resizer} ${header.column.getIsResizing() ? classes.isResizing : ''}`
          }
          style={{
            transform: `translateX(${table.getState().columnSizingInfo.deltaOffset ?? 0}px)`,
            height: tableRef?.current?.clientHeight,
            position: 'absolute',
          }}
        />
        {hoverPosition === ColumnDragPosition.Right && (
          <div
            style={{
              height: isOver ? tableRef?.current?.clientHeight : 'unset',
              position: 'absolute',
            }}
            className={classes.onOverRight}
          />
        )}
      </div>
    </th>
  );
};

export default TableAbstractColumns;
