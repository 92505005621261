import AgoraRTC, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ILocalVideoTrack,
  IMicrophoneAudioTrack,
} from 'agora-rtc-sdk-ng';
import i18n from 'src/utils/translations/i18n';
import reduxStore from 'src/reducer-manager';
import {
  AgoraIONetworkQuality,
  AgoraIOStates,
  AgoraIOUsers,
  VolumeIndicator,
} from 'src/interfaces/remote-assistance/agoraIO';
import { Room } from 'src/interfaces/remote-assistance/room';
import { Session /*, VideoTrackType, VideoTrackType */ } from 'src/interfaces/remote-assistance/session';
import { User } from 'src/interfaces/remote-assistance/types';
import {
  setAgoraRtc,
  setAgoraUsers,
  setMicrophoneVolume,
  setUid,
  //setUserAudio,
} from 'src/modules/remote-assistance/agora/agora.redux';
import lodash from 'lodash';

// This needs to be checked
//import { logoutReducer, setLastRoomToken } from '../redux/authReducer';

//import { exitSession } from '../redux/meetingSidebarReducer';
import store from 'src/reducer-manager';
// import { Dispatch } from 'redux';
import { changeNetWorkQualityState, stopMeetingServices } from './agoraState';
//import { cameraVideoTrack, closeAndStopCameraVideoTrack } from './video-streaming/camera';

export let client: IAgoraRTCClient = null;

// Global variable to save RTC connection state
let AgoraRTCConnectionState: string = null;

// aux variable to control if reconnect message was shown
let reconnectMessageNeeded = false;

// Time to check the join session status in (ms)
const timeToCheckJoinSessionStatus = 15000;

//*****************************************************************************
export let audioTrack: IMicrophoneAudioTrack = null;
export let screenVideoTrack: ILocalVideoTrack = null;

AgoraRTC.enableLogUpload();
AgoraRTC.setLogLevel(process.env.NODE_ENV === 'production' ? 4 : 3);

export interface UserConfig {
  rtcToken: string;
  room: Room;
  session: Session;
  name: string;
  uid: string;
}

export const getDefaultUserConfig = (): UserConfig => {
  return {
    rtcToken: null,
    room: null,
    session: null,
    uid: '',
    name: null,
  };
};

export const newAgoraClient = (agoraClient: IAgoraRTCClient): void => {
  console.debug('Initializing Agora.io client');
  client = agoraClient;
};

export const handleAgoraIO = (stream: any, setStream: any): void => {
  if (client && client.connectionState !== 'DISCONNECTED') {
    void stopMeetingServices(stream, setStream);
  }
};

const subscribe = async (
  {
    user,
    mediaType,
  }: {
    user: IAgoraRTCRemoteUser;
    mediaType: 'audio' | 'video';
  } = {
    user: null,
    mediaType: 'audio',
  },
) => {
  // user - information of the user who posted something
  // mediaType - publication type (audio or video)
  const uid = user.uid;
  // make subscription in the publication made
  await client.subscribe(user, mediaType);

  const store = reduxStore?.getState();
  const remoteUsers = store?.agoraReducer?.usersAgora;
  const newRemoteUsers = lodash.cloneDeep(remoteUsers);

  if (mediaType === 'audio') {
    const audioTrack = user.audioTrack;

    newRemoteUsers[uid] = { ...newRemoteUsers[uid], audioTrack } as User;
    if (!store.agoraReducer.autoplayFailed) audioTrack.play();
  }

  if (mediaType === 'video') {
    const videoTrack = user.videoTrack;
    newRemoteUsers[uid] = { ...newRemoteUsers[uid], _videoTrack: videoTrack } as User;
  }

  reduxStore.dispatch(setAgoraUsers(newRemoteUsers as AgoraIOUsers));
};

// Check if user is still trying to join the meeting
const checkJoinChannelConnection = (roomId: string): void => {
  const userConfig = store.getState().loginReducer.userConfig;
  if (!userConfig.room || roomId != userConfig.room?.id) return;

  if (AgoraRTCConnectionState === AgoraIOStates.CONNECTING) {
    if (audioTrack) {
      audioTrack?.stop();
      audioTrack?.close();
      audioTrack = undefined;
    }
    if (client) {
      client.removeAllListeners();
      // Leave Channels
      void client.leave();
    }
    console.error(
      `User reached timeout to join session ${userConfig.room.code} (${userConfig.room.id}): unknown reason`,
    );
    return;
  }
};

export const handleUserPublished =
  () =>
  (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video'): void => {
    const id = user.uid;

    const store = reduxStore?.getState();
    const remoteUsers = store?.agoraReducer?.usersAgora;
    const newRemoteUsers = lodash.cloneDeep(remoteUsers);

    newRemoteUsers[id] = user as User;
    reduxStore.dispatch(setAgoraUsers(newRemoteUsers as AgoraIOUsers));

    void subscribe({ user, mediaType }).catch((err) => {
      console.error(err);
      // TODO handle error
    });
  };

export const handleUserUnPublished =
  () =>
  (user: IAgoraRTCRemoteUser): void => {
    const id = user.uid;

    const store = reduxStore?.getState();
    const remoteUsers = store?.agoraReducer?.usersAgora;
    const newRemoteUsers = lodash.cloneDeep(remoteUsers);

    newRemoteUsers[id] = user as User;

    reduxStore.dispatch(setAgoraUsers(newRemoteUsers as AgoraIOUsers));
  };

export const handleUserJoined =
  () =>
  (user: IAgoraRTCRemoteUser): void => {
    const id = user.uid;

    const store = reduxStore?.getState();
    const remoteUsers = store?.agoraReducer?.usersAgora;
    const newRemoteUsers = lodash.cloneDeep(remoteUsers);

    newRemoteUsers[id] = user as User;

    reduxStore.dispatch(setAgoraUsers(newRemoteUsers as AgoraIOUsers));
  };

export const handleUserLeft =
  () =>
  (user: IAgoraRTCRemoteUser): void => {
    const id = user.uid;

    const store = reduxStore?.getState();
    const remoteUsers = store?.agoraReducer?.usersAgora;
    const newRemoteUsers = { ...remoteUsers, [id]: user as User };

    delete newRemoteUsers[id];

    reduxStore.dispatch(setAgoraUsers(newRemoteUsers as AgoraIOUsers));
  };

export const handleConnectionStateChange =
  (currentRoom: string) =>
  (curState: string, revState: string): void => {
    const userConfig = store.getState().loginReducer.userConfig;
    if (currentRoom != userConfig.room.id) return;

    // Save Current state of Connection
    AgoraRTCConnectionState = curState;
    if (
      curState === AgoraIOStates.CONNECTING &&
      (revState === AgoraIOStates.DISCONNECTING || revState === AgoraIOStates.DISCONNECTED)
    ) {
      setTimeout(() => checkJoinChannelConnection(currentRoom), timeToCheckJoinSessionStatus);
      return;
    }

    if (curState === AgoraIOStates.RECONNECTING && revState === AgoraIOStates.CONNECTED) {
      reduxStore.dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('noInternetConnection'),
          severity: 'error',
          notHide: true,
        },
      });
      reconnectMessageNeeded = true;
      return;
    }
  };

export const handleNetworkQuality =
  () =>
  (state: AgoraIONetworkQuality): void => {
    if (state.downlinkNetworkQuality !== 6 && state.uplinkNetworkQuality !== 6 && reconnectMessageNeeded) {
      store.dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('successfullyReconnected'),
          severity: 'success',
        },
      });
      reconnectMessageNeeded = false;
      return;
    }

    const uid = store.getState().socketReducer.assistedUser?.user_details?.capabilities.rtc_uid;
    const assistedQuality = store.getState().socketReducer.assistedUser?.user_details?.current_state?.quality;
    const remoteUsers = store.getState().agoraReducer?.usersAgora;

    if (uid && remoteUsers[uid]?._videoTrack) changeNetWorkQualityState(uid, assistedQuality !== 0 && assistedQuality);
  };

export const handleVolumeIndicator =
  () =>
  async (event: Array<VolumeIndicator>): Promise<void> => {
    event.forEach((volume: VolumeIndicator) => {
      store.dispatch(setMicrophoneVolume({ id: volume.uid, microphoneVolume: volume.level }));
    });
  };

export const updateMeetingRedux = async (): Promise<void> => {
  const userConfig = store.getState().loginReducer.userConfig;

  // AgoraIO
  await Promise.all([store.dispatch(setAgoraRtc(true)), store.dispatch(setUid(userConfig.uid))]);
};

export const createScreenVideoTrack = async () => {
  screenVideoTrack = await AgoraRTC.createScreenVideoTrack({}, 'disable');
};

export const setAudioTrack = (newAudioTrack: IMicrophoneAudioTrack) => {
  audioTrack = newAudioTrack;
};

export const clearAudioTrack = () => {
  audioTrack = undefined;
};

export const clearScreenVideoTrack = () => {
  screenVideoTrack = undefined;
};

export const clearRemoteUsers = () => {
  reduxStore.dispatch(setAgoraUsers({} as AgoraIOUsers));
};
