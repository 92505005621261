import { useCallback, useRef } from 'react';

// See https://react.dev/learn/separating-events-from-effects for more info

/**
 * A custom hook that returns a stable event handler function.
 * This handler uses the latest version of the provided callback, avoiding issues with stale closures.
 * Especially useful to emulate useCallbacks without the need for dependency arrays
 * @template T - The type of the callback function.
 * @param {T} callback - The callback function to be called with the latest state or props.
 * @returns {T} - A stable event handler function that calls the latest version of the provided callback.
 */
export default function useEffectEvent<T extends (...args: any[]) => any>(callback: T): T {
  const callbackRef = useRef(null);

  callbackRef.current = callback;

  return useCallback(((...args) => callbackRef.current?.(...args)) as T, []);
}
