import { useEffect, useRef } from 'react';
import { CustomRange } from '../..';
import CustomValueRangeListItem from '../custom-value-range-list-item';

type CustomValueRangeListProps = {
  customRanges: CustomRange[];
  addClicked: boolean;
  setAddClicked: (val: boolean) => void;
  setCustomRanges: (ratings: CustomRange[]) => void;
  setErrorExists?: (error: boolean) => void;
};

const CustomValueRangeList = ({
  customRanges,
  addClicked,
  setAddClicked,
  setCustomRanges,
  setErrorExists,
}: CustomValueRangeListProps): JSX.Element => {
  const listRef = useRef<HTMLUListElement>();

  useEffect(() => {
    if (addClicked) {
      listRef?.current?.lastElementChild?.scrollIntoView();
      setAddClicked(false);
    }
  }, [customRanges?.length]);

  return (
    <ul style={{ padding: 0, margin: 0 }} ref={listRef}>
      {customRanges.map((item, index) => (
        <CustomValueRangeListItem
          customRange={item}
          customRanges={customRanges}
          index={index}
          key={index}
          setCustomRanges={setCustomRanges}
          setErrorExists={setErrorExists}
        />
      ))}
    </ul>
  );
};

export default CustomValueRangeList;
