import { styles } from './styles';
import { IconButton, TextField, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useCreateCommentMutation, useUpdateCommentMutation } from 'src/gql/graphql';
import useAtomicCallback from 'src/utils/hooks/useAtomicCallback';
import { Send } from '@mui/icons-material';
import useMentions, { ShowMentions } from '../../useMentions';
import DefaultButton from 'src/utils/components/default-button/default-button';
import makeStyles from '@mui/styles/makeStyles';
import { css } from '@emotion/css';
import { ApolloError } from '@apollo/client';
import { ToastMessage } from '../toast';
import { useSnackbar } from 'notistack';
import useLoadingCursor from 'src/utils/hooks/useLoadingCursor';
import { PostDoesNotExistModal } from './utils';
import { CommentType } from '../../feed.redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { stringifyUrl } from 'query-string';
import queryString from 'query-string';

const useStyles = makeStyles(styles);

interface IReplyToContext {
  editing?: string;
  setEditing: (x: string) => any;
}

export const EditingContext = createContext<IReplyToContext>(null);

export function EditingProvider({ children }) {
  const [editing, setEditing] = useState(null);

  const value = useMemo(
    () => ({
      editing,
      setEditing,
    }),
    [editing],
  );

  return <EditingContext.Provider value={value}>{children}</EditingContext.Provider>;
}

type CommentDraftProps = {
  postId: string;
  replyTo?: CommentType;
  originalComment?: Partial<CommentType>;
  close?: () => any;
};

export function CommentDraft(props: CommentDraftProps) {
  const { originalComment, replyTo, postId } = props;

  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [text, setText] = useState<string>('');
  const disabled = !text.trim();

  const { enqueueSnackbar } = useSnackbar();

  const {
    incompleteMention,
    mentions,
    onComplete,
    ref: draftRef,
  } = useMentions(text, setText, originalComment?.mentions as any);

  useEffect(() => {
    if (originalComment) {
      setText(originalComment.content);
    }
  }, [originalComment?._id]);

  const onError = (error: ApolloError) => {
    if (error.message.startsWith('Not found') || error.message.startsWith('Not authorized')) {
      PostDoesNotExistModal();
    } else if (error.networkError) {
      enqueueSnackbar(<ToastMessage severity={'warning'} message={t('internetConnectionLost')} />, {
        variant: 'warning',
      });
    } else {
      enqueueSnackbar(<ToastMessage severity={'warning'} message={t('commentNotPublishedTryAgain')} />, {
        variant: 'warning',
      });
    }
  };

  const [createPostComment, { loading: loadingCreate, error: errorCreate }] = useCreateCommentMutation({
    fetchPolicy: 'no-cache',
    onError,
  });

  const [updatePostComment, { loading: loadingUpdate, error: errorUpdate }] = useUpdateCommentMutation({
    fetchPolicy: 'no-cache',
    onError,
  });

  useLoadingCursor(loadingUpdate || loadingCreate);

  const sendComment = useAtomicCallback(async () => {
    if (originalComment?._id) {
      await updatePostComment({ variables: { id: originalComment._id, data: { content: text, mentions } } });
    } else {
      await createPostComment({
        variables: {
          data: {
            content: text,
            post: postId,
            parent: replyTo ? replyTo._id : null,
            mentions,
          },
        },
        onCompleted(data) {
          if (queryString.parse(location.search).commentId) {
            navigate(
              stringifyUrl({
                url: location.pathname,
                query: {
                  ...queryString.parse(location.search),
                  commentId: data.createComment._id,
                },
              }),
            );
          }
        },
      });
    }

    setText('');
    props.close?.();
  }, [originalComment?._id, text, replyTo, postId]);

  return (
    <>
      <div className={`${classes.commentContainerDraft} ${classes.marginBottom}`}>
        <TextField
          data-testid={
            replyTo
              ? 'comment-draft-replying-post-' + postId
              : `comment-draft-editing${originalComment?._id ? `-${originalComment.content}` : ''}`
          }
          InputProps={{
            /* disableUnderline: true, */ classes: {
              notchedOutline: css({
                borderColor: errorCreate || errorUpdate ? theme.palette.error.main : undefined,
              }),
            },
            className: classes.draftEdit,
          }}
          classes={{}}
          placeholder={t('writeComment')}
          inputRef={draftRef}
          size={'small'}
          autoFocus
          fullWidth
          value={text}
          onChange={(ev) => {
            setText(ev.target.value);
          }}
          onKeyUp={(ev) => {
            switch (ev.key) {
              case 'Escape':
                props.close?.();
                break;
              case 'Enter':
                sendComment();
                break;
            }
          }}
        />
        {(!!replyTo || !originalComment) && (
          <IconButton
            data-testid={'send-comment-' + text}
            disabled={disabled || loadingCreate || loadingUpdate}
            onClick={sendComment}
            className={classes.noPadding}
          >
            <Send
              fontSize={'large'}
              className={`${classes.sendIcon} ${disabled ? classes.sendIconDisabled : classes.colorTransition}`}
            />
          </IconButton>
        )}
        <ShowMentions
          postId={postId}
          onComplete={onComplete}
          inputRef={draftRef}
          incompleteMention={incompleteMention}
        />
      </div>
      {originalComment?._id && (
        <div className={classes.draftActionButtons}>
          <DefaultButton
            className={classes.smallBtn}
            data-testid={'cancel-coment-draft-edit'}
            variant={'discard'}
            onClick={() => props.close()}
          >
            {t('cancel')}
          </DefaultButton>
          <DefaultButton
            className={classes.smallBtn}
            loader={loadingCreate || loadingUpdate}
            data-testid={'save-coment-draft-edit'}
            variant={'default'}
            onClick={sendComment}
          >
            {t('save')}
          </DefaultButton>
        </div>
      )}
    </>
  );
}
