import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    listItem: {
      fontSize: theme.typography.fontSize,
    },
    icon: {
      fontSize: 20,
      color: theme.palette.text.primary,
    },
    subHeader: {
      textAlign: 'center',
      fontSize: theme.typography.fontSize,
      color: theme.palette.text.primary,
    },
  });
