import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    errorText: {
      fontSize: '12px',
      fontFamily: 'Open Sans',
      color: theme.palette.error.main,
      width: '90%',
    },
    numberInput: {
      '& input[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
    minMaxInput: {
      padding: '8px',
      height: '22px',
      maxWidth: '175px',
    },
    errorInput: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: `${theme.palette.error.main} !important`,
        },
        '&:hover fieldset': {
          borderColor: `${theme.palette.error.main} !important`,
        },
        '&.Mui-focused fieldset': {
          borderColor: `${theme.palette.error.main} !important`,
        },
      },
    },
  });
