import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';
import { MediaLibraryContextProvider } from './context/media-library.context';
import { mediaLibraryContextInitialValue } from './constants/media-library.constants';

const Models3DLibraryViewDefaultRefresh = () =>
  lazy(() => import('src/modules/media-library/media-library.view.default'));

const Models3DLibraryViewDefault = Models3DLibraryViewDefaultRefresh();

export const render = {
  view: {
    default: ({ ...props }) => {
      // if (Models3DLibraryViewDefault?._result?.type === 'error') {
      //   Models3DLibraryViewDefault = Models3DLibraryViewDefaultRefresh();
      // }
      return (
        <MediaLibraryContextProvider initialValue={mediaLibraryContextInitialValue}>
          <Suspense fallback={<LazyLoadingContent />}>
            <Models3DLibraryViewDefault {...props} />
          </Suspense>
        </MediaLibraryContextProvider>
      );
    },
  },
};

export default render;
