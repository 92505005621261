import { useEffect } from 'react';

export default function useLoadingCursor(loading: boolean) {
  useEffect(() => {
    if (loading) {
      document.body.style.cursor = 'wait !important';
    } else {
      document.body.style.cursor = '';
    }
  }, [loading]);
}
