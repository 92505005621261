import { Tooltip, useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import { styles, typography } from 'src/utils/components/default-button/styles/default-button.styles';
import useAtomicCallback from 'src/utils/hooks/useAtomicCallback';
import { DefaultButtonFont, DefaultButtonIcon, DefaultButtonType } from './types/default-button.types';
/* import { CSSProperties } from '@mui/styles'; */
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { DynamicIcon } from 'src/utils/funcs/icons/utils';
import { DefaultButtonSize } from './types/default-button.sizes';
import { formatLabel } from './utils/default-button.utils';

export interface ButtonProps {
  /* necessary prop for on-click action */
  onClick?: (e?: React.MouseEvent<HTMLDivElement>) => any;

  /* templates */
  variant?: DefaultButtonType;
  'data-testid': string;

  /* button type */
  icon?: DefaultButtonIcon;
  iconOnly?: boolean /* true when it should only display an icon without text */;

  font?: DefaultButtonFont;
  iconFont?: DefaultButtonSize;
  /* misc */
  loader?: boolean /* show if function takes time and the user isn't supposed to click multiple times */;

  noBorder?: boolean;
  label?: React.ReactNode /* TO DELETE */;
  edge?: boolean /* true if icon is an edge - important for border radius */;
  disabled?: boolean /* true if the input is disabled */;
  size?: DefaultButtonSize /* defines padding according to small, medium or large button. Buttons with icons should be medium size to maintain coherence  */;
  overrideSize?: string /* button size (width for button, width/height for icon) */;
  className?: string /* context-specific classes */;
  tooltip?: string /* tooltip for button/icon hover */;
  rounded?: boolean /* true if icon is a rounded - important for border radius */;
  shadow?: boolean;

  maxWidth?: number;
}

const useStyles = makeStyles(styles);

const DefaultButton = (props: React.PropsWithChildren<ButtonProps>) => {
  const {
    'data-testid': dataTestId,
    children,
    variant = 'default',
    icon = 'none',
    font = 'default',
    iconFont = 'small',
    iconOnly = false,
    noBorder = false,
    shadow,
    /* edge, */
    /* rounded, */
    label,
    size = 'large',
    /* overrideSize, */
    className,
    disabled,
    tooltip,
    loader,
    onClick,
  } = props;

  const classes = useStyles();
  const theme = useTheme();

  const clickFunction = useAtomicCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!disabled && !loader && typeof onClick === 'function') {
        return onClick(e);
      }
    },
    [disabled, loader, onClick],
  );

  const type = iconOnly ? classes.icon : classes.button;
  const optionalShadow = shadow ? classes.shadow : '';
  const previous = variant === 'previous';
  const next = variant === 'next';
  const transparent = variant === 'transparent';
  const fontClass = typography(theme)[`${font}`];

  /* const style = (() => {
    let style: CSSProperties;
    if (iconOnly) {
      style = {
        width: overrideSize || '41px',
      };
      if (edge) {
        style = { ...style, borderTopRightRadius: '0.25rem', borderBottomRightRadius: '0.25rem' };
      } else {
        style = { ...style, borderRadius: '4px' };
      }
      if (rounded) {
        style = { ...style, borderRadius: '50%', height: overrideSize || '35px' };
      }
      if (previous || next) {
        style.height = overrideSize || '35px';
      }
    }

    if (overrideSize) {
      style = { ...style, minWidth: overrideSize };
    }

    if (maxWidth) {
      style = { ...style, maxWidth: `${maxWidth}px` };
    }

    return style;
  })(); */

  const Spinner = () => {
    if (!loader) {
      return null;
    }
    return (
      <div className={classes.loadSpinnerContainer}>
        <CircularProgress size={24} classes={{ root: classes.loadSpinner }} />
      </div>
    );
  };

  const variantClass = loader || disabled ? classes.disabled : classes[`${variant}`];

  const noBorderClass = noBorder ? classes.noBorder : '';

  const variantSize = !iconOnly ? classes[`${size}`] : classes.paddingIconOnly;

  const classNameInput = className ? className : '';

  const containerClass = `${classes.common} ${type} ${variantClass} ${noBorderClass} ${classNameInput} ${optionalShadow} ${variantSize}`;

  const innerClass = (() => {
    if (loader || disabled)
      return ''; //classes.disabled;
    else {
      if (!transparent) return classes.enabledHover;
      else {
        if (previous || next) return classes.enabledCircleHover;
        else return classes.enabled;
      }
    }
  })();

  const iconClass = (() => {
    if (icon !== 'none') return classes.iconDiv;
    else return '';
  })();

  return (
    <Tooltip title={tooltip} arrow>
      <div data-testid={dataTestId} className={containerClass} onClick={clickFunction}>
        <div className={classNames(innerClass, fontClass, iconClass)}>
          {icon !== 'none' && !loader && <DynamicIcon fontSize={iconFont} name={icon} />}
          {!iconOnly && <>{label || children || formatLabel(variant)}</>}
        </div>
        <Spinner />
      </div>
    </Tooltip>
  );
};

export default DefaultButton;
