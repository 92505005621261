import gql from 'graphql-tag';
import { LABEL_VALUE } from './label';

export const PROFILE = gql`
  fragment profile on Profile {
    __typename
    _id
    name
    profilesCount
    elementsCount
    description
    deleted
    deletedAt
    deletedBy {
      name
    }
    totalItems: profilesCount
    parent {
      __typename
      _id
    }
    parentsTreeIds
    parentsTree {
      name
      _id
    }
    labelValues {
      ...labelValue
    }
    template {
      _id
      title
      frame {
        inputs {
          ...input
        }
      }
    }
    inputs {
      ...input
    }
    folder {
      _id
      name
    }
  }
  ${LABEL_VALUE}
`;

export const PROFILE_TO_TREE = gql`
  fragment profileToTree on Profile {
    __typename
    _id
    name
    description
    hasChildren: profilesCount
    profilesCount
    elementsCount
    parent {
      __typename
      _id
      name
    }
    parentsTreeIds
    parentsTree {
      _id
      name
    }
    folder {
      _id
      name
    }
  }
`;

export const PROFILE_TEMPLATE = gql`
  fragment profileTemplate on ProfileTemplate {
    __typename
    title
    _id
    protected
    folder {
      parentsTree {
        _id
        name
      }
      parent {
        _id
        name
      }
      protected
      name
      _id
    }
    frame {
      inputs {
        ...input
      }
    }
  }
`;

export const PROFILE_TEMPLATE_TO_TREE = gql`
  fragment profileTemplateToTree on ProfileTemplate {
    __typename
    title
    _id
    protected
    folder {
      _id
      name
      parentsTree {
        _id
        name
      }
    }
  }
`;

export const PROFILE_TEMPLATE_FOLDER = gql`
  fragment profileTemplateFolder on ProfileTemplateFolder {
    __typename
    name
    parent {
      _id
      name
    }
    parentsTreeCount
    profileTemplatesCount(where: { archived_ne: true })
    profileTemplateFoldersCount
    totalFolders: profileTemplateFoldersCount
    totalItems: profileTemplatesCount
    parentsTree {
      _id
      name
    }
    protected
    _id
  }
`;

export const PROFILE_FOLDER = gql`
  fragment profileFolder on ProfileFolder {
    __typename
    name
    parent {
      _id
      name
    }
    parentsTreeCount
    profilesCount
    hasChildren: profileFoldersCount
    profileFoldersCount
    totalFolders: profileFoldersCount
    totalItems: profilesCount
    parentsTree {
      _id
      name
    }
    protected
    _id
  }
`;
