import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { styles } from 'src/base/main-container/styles/main-container';
import { render as Routes } from 'src/base/routes';
import { NexusGenFieldTypes } from '../../../../server/src/types';
import DomainExpiredModal from './components/domain.expired.modal';
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap } from '@mui/styles/withStyles';

type MainContainerLoginProps = {
  currentSite?: NexusGenFieldTypes['Site'];
  forceUpdate?: any;
} & ConnectedProps<typeof connector>;

const useStyles = makeStyles(styles);
const MainContainerLogin: React.FC<MainContainerLoginProps> = (props): JSX.Element => {
  const classes: ClassNameMap<string> = useStyles();

  return (
    <div className={classes.div}>
      <div className={classes.routerWrapper}>
        {Routes ? (
          //@ts-ignore
          <Routes.view.default {...props} />
        ) : null}
      </div>
      <DomainExpiredModal />
    </div>
  );
};

const connector = connect(
  (state) => ({
    snackbarMessages: state.rootReducer.snackbarMessages,
    sidenavMinimized: state.rootReducer.sidenavMinimized,
  }),
  {},
);

export default connector(MainContainerLogin);
