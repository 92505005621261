import { getErrorObject } from 'src/utils/funcs';
import * as queries from 'src/modules/issue-execution/issue.execution.queries';
import { APOLLO_CLIENT } from 'config/apollo.config';
import { Dispatch } from 'redux';
import i18n from 'src/utils/translations/i18n';
import {
  IssueApprovalInput,
  IssueChangeDateExecutionInput,
  IssueInstance,
  IssueInstanceWhereUniqueInput,
  IssueRejectionInput,
  IssueTasksScore,
  IssueUpdateInput,
} from 'src/gql/graphql';

export const getIssueInstance = (issueId: string) => async (): Promise<IssueInstance> => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        filter: issueId,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ISSUE_INSTANCE,
    });

    return response.data.issue;
  } catch (error) {
    return error;
  }
};

export const getIssueInstanceScore = (issueId: string) => async (): Promise<IssueTasksScore> => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        filter: issueId,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ISSUE_SCORE,
    });

    return response.data.issue.allTaskScore;
  } catch (error) {
    return error;
  }
};

export const changeStateIssueInstance =
  (newState: { token?: string; action: string }, issueId: string, note?: string) =>
  async (dispatch: Dispatch): Promise<IssueInstance> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: newState.token
          ? {
              token: newState.token,
              id: issueId,
              action: newState.action,
              note: note ? note : null,
            }
          : {
              id: issueId,
              action: newState.action,
              note: note ? note : null,
            },
        fetchPolicy: 'no-cache',
        mutation: queries.M_STATE_MACHINE_CHANGE_STATE,
      });

      return response.data.changeIssueState;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const reassignIssueInstance =
  (data: { _id: string; accounts?: string[]; labelValues?: string[] }) =>
  async (dispatch: Dispatch): Promise<IssueInstance> => {
    try {
      const resp = await APOLLO_CLIENT.mutate({
        variables: {
          _id: data._id,
          accounts: data.accounts,
          labelValues: data.labelValues,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_REASSIGN_ISSUE_INSTANCE,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('issueReassignOccurredSuccessfully'),
          severity: 'success',
        },
      });
      return resp.data.reassignIssueInstance;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const acceptIssueInstance =
  (idIssueInstance: string) =>
  async (dispatch: Dispatch): Promise<IssueInstance> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          issueInstanceId: idIssueInstance,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_ACCEPT_ISSUE_INSTANCE,
      });

      return response.data.acceptIssue;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const cancelIssue =
  (id: string) =>
  async (dispatch: Dispatch): Promise<IssueInstance> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          where: { _id: id },
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_CANCEL_ISSUE,
      });

      return response.data.cancelIssue;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const approveIssue =
  (id: string, data: IssueApprovalInput, size: number = 1) =>
  async (dispatch: Dispatch): Promise<IssueInstance> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          where: { _id: id },
          data,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_APPROVE_ISSUE,
      });

      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: size + i18n.t('translation:approveIssueSuccess'),
          severity: 'success',
        },
      });

      return response.data.approveIssue;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const rejectIssue =
  (id: string, data: IssueRejectionInput, size: number = 1) =>
  async (dispatch: Dispatch): Promise<IssueInstance> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          where: { _id: id },
          data,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_REJECT_ISSUE,
      });

      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: size + i18n.t('translation:rejectIssueSuccess'),
          severity: 'success',
        },
      });

      return response.data.rejectIssue;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const changeIssueExecutionDate =
  (id: string, data: IssueChangeDateExecutionInput) =>
  async (dispatch: Dispatch): Promise<IssueInstance> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          where: { _id: id },
          data,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_CHANGE_EXECUTION_DATE_ISSUE,
      });

      return response.data.changeIssueExecutionDate;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const updateIssue =
  (where: IssueInstanceWhereUniqueInput, data: IssueUpdateInput, dispatchData?) =>
  async (dispatch): Promise<IssueInstance> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: { where, data },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_ISSUE_INSTANCE,
      });

      if (dispatchData) {
        dispatch(dispatchData);
      }

      return response.data.updateIssue;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const resetIssue =
  (id: string) =>
  async (dispatch: Dispatch): Promise<IssueInstance> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          where: { _id: id },
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_RESET_ISSUE,
      });

      return response.data.resetIssue;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const duplicateTask =
  (_id: string, groupId: string, taskId: string) =>
  async (dispatch: Dispatch): Promise<{ issue: IssueInstance; tasks: string[] }> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          _id: _id,
          groupId: groupId,
          taskId: taskId,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_DUPLICATE_TASK,
      });

      return response.data.duplicateTaskFromGroup;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const linkedWithThisInputCount = (_id: string, taskId: string) => async (): Promise<number> => {
  try {
    const response: { data: { linkedWithThisInputCount: number } } = await APOLLO_CLIENT.query({
      variables: {
        _id,
        taskId,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_LINK_WITH_THIS_INPUT_COUNT,
    });

    return response.data.linkedWithThisInputCount;
  } catch (error) {
    return error;
  }
};

export const findIssueInstance = (filter) => async (): Promise<IssueInstance> => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        filter: filter,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_FIND_ISSUE_INSTANCE,
    });

    return response.data.issueInstances[0];
  } catch (error) {
    return error;
  }
};

export const repeatTaskManyTimes =
  (_id: string, groupId: string, taskId: string[], timesToRepeat: number) =>
  async (dispatch: Dispatch): Promise<IssueInstance> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          _id: _id,
          groupId: groupId,
          taskId: taskId,
          timesToRepeat: timesToRepeat,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_REPEAT_TASK_MANY_TIMES,
      });

      return response.data.repeatTaskManyTimes;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getIssueLabelAssignees = (filter) => async (dispatch: Dispatch) => {
  try {
    const response: {
      data: {
        issues: any[];
      };
    } = await APOLLO_CLIENT.query({
      variables: {
        filter,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ISSUE_LABEL_ASSIGNEES,
    });

    return response.data.issues.map((iss) => ({
      ...iss,
      taskMappings: iss.taskGroups.flatMap((tg) => tg.tasks),
      taskGroups: undefined,
    }));
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const subscribeIssueUpdate = (_id: string) => {
  try {
    return APOLLO_CLIENT.subscribe({
      query: queries.S_ON_ISSUE_UPDATE,
      variables: { _id },
      fetchPolicy: 'no-cache',
    }).map((result) => {
      return result?.data?.issueInstanceUpdated;
    });
  } catch (error) {
    const obj = getErrorObject(error, '');
    console.error(obj);
    return error;
  }
};

export const dispatchSubscribeIssueUpdate = (_id: string) => (dispatch: Dispatch) => {
  try {
    return subscribeIssueUpdate(_id);
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};
