import { Divider, IconButton, MenuItem, Popover } from '@mui/material';
import { styles } from './styles';
import { ConnectedProps } from 'react-redux';
import {
  Account,
  CommentMentionType,
  CommentOrderByInput,
  SubscribeNewCommentsSubscription,
  useDeleteCommentMutation,
  useGetAdjacentCommentsCountQuery,
  useGetCommentsLazyQuery,
  useSubscribeCommentSubscription,
  useSubscribeNewCommentsSubscription,
} from 'src/gql/graphql';
import { closeSubComments, CommentType, updateCommentsState } from '../../feed.redux';
import { ReactNode, createContext, useContext, useEffect, useMemo, useState, Fragment } from 'react';
import { DeleteOutline, Edit, MoreVert } from '@mui/icons-material';
import { AccountAvatar } from 'src/utils/components/account-avatar';
import moment from 'moment';
import { timeDiffLowestCommonUnit, typedConnect, useTypedSelector } from 'src/utils/funcs';
import { useTranslation } from 'react-i18next';
import { CommentDraft, EditingContext } from './comment-draft';
import AccountPopoverDetails from './account-popover-details';
import useAtomicCallback from 'src/utils/hooks/useAtomicCallback';
import ReactLoading from 'react-loading';
import reactStringReplace from 'react-string-replace';
import DetailsPopover from 'src/utils/components/details-popover';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import { PostDoesNotExistModal } from './utils';
import Swal from 'sweetalert2';
import { maxBy, minBy } from 'lodash';
import useEffectEvent from 'src/utils/hooks/useEffectEvent';
import { OnDataOptions } from '@apollo/client';
import { createStyles } from '@mui/styles';

export const COMMENT_PAGINATION = 3;

const useStyles = makeStyles(styles);

export function ShowMoreComments(props: { newest: boolean } | { oldest: boolean }) {
  const { parentId, totalCommentCountNewest, totalCommentCountOldest, fetchMoreComments, loading } =
    useContext(CommentContext);

  const classes = useStyles();
  const { t } = useTranslation();

  const remainingCount =
    ('newest' in props && totalCommentCountNewest) || ('oldest' in props && totalCommentCountOldest) || 0;

  return (
    !!remainingCount && (
      <>
        {!loading ? (
          <span
            className={`${classes.blueColor} ${classes.marginBottom} ${classes.replyWidth}`}
            onClick={() => fetchMoreComments('newest' in props)}
          >
            {t(parentId ? 'showMoreReplies' : 'showMoreComments', {
              number: remainingCount,
            })}
          </span>
        ) : (
          <div className={classes.spinnerContainer}>
            <ReactLoading type={'spin'} color={'disabled'} height={50} width={50} />
          </div>
        )}
      </>
    )
  );
}

type CommentProviderProps = ConnectedProps<typeof commentContextConnector> & {
  postId: string;
  parentId?: string;
  filterId?: string;
  fetchInitial?: boolean;
  children: ReactNode;
};

interface CommentContext extends Pick<CommentProviderProps, 'postId' | 'parentId'> {
  fetchMoreComments: (newest?: boolean) => Promise<any>;
  hideSubComments: () => any;
  loading: boolean;
  totalCommentCountOldest: number;
  totalCommentCountNewest: number;
}

const CommentContext = createContext<CommentContext>(null);

const commentContextConnector = typedConnect(null, {
  closeSubComments,
  updateCommentsState,
});

export const CommentProvider = commentContextConnector(function (props: CommentProviderProps) {
  const { postId, parentId, filterId, children, fetchInitial } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [getPostComments, { loading }] = useGetCommentsLazyQuery({
    onCompleted(data) {
      if (!data.comments.length && filterId) {
        Swal.fire({
          title: t('commentDeleted'),
          text: t('commentDeletedByCreatorOrAdmin'),
        }).then(() => navigate('/feed'));
      }

      props.updateCommentsState(data.comments);
    },
    onError(error) {
      if (error.message.startsWith('Not authorized')) {
        PostDoesNotExistModal();
      }
    },
  });

  const { skipCommentsIds, skipCommentsDates } = useTypedSelector((state) => {
    const skipComments = state.feedReducer.comments.filter(
      (c) => c.postId === postId && ((!parentId && !c.parentId) || c.parentsTreeIds.includes(parentId)),
    );

    return {
      skipCommentsIds: skipComments.map((c) => c._id),
      skipCommentsDates: skipComments.map((c) => c.createdAt),
    };
  });

  const commonFilters = {
    post_eq: postId,
    _id_nin: skipCommentsIds,
    parentsTree_in: parentId ? [parentId] : undefined,
    parent_eq: !parentId ? null : undefined,
  };

  const { data: { beforeCount = 0, afterCount = 0 } = {}, refetch: refetchAdjacent } = useGetAdjacentCommentsCountQuery(
    {
      variables: {
        beforeWhere: {
          createdAt_lt: minBy(skipCommentsDates, (x) => moment.unix(x)),
          ...commonFilters,
        },
        afterWhere: {
          createdAt_gt: maxBy(skipCommentsDates, (x) => moment.unix(x)),
          ...commonFilters,
        },
      },
    },
  );

  const fetchMoreComments = useEffectEvent((newest?: boolean) =>
    getPostComments({
      variables: {
        where: {
          createdAt_lt: !newest ? minBy(skipCommentsDates, (x) => moment.unix(x)) : undefined,
          createdAt_gt: newest ? maxBy(skipCommentsDates, (x) => moment.unix(x)) : undefined,
          ...commonFilters,
        },
        skip: 0,
        limit: COMMENT_PAGINATION,
        orderBy: [newest ? CommentOrderByInput.createdAt_ASC : CommentOrderByInput.createdAt_DESC],
      },
    }),
  );

  const hideSubComments = () => {
    props.closeSubComments(parentId);
  };

  useEffect(() => {
    if (fetchInitial) {
      getPostComments({
        variables: {
          where: {
            post_eq: postId,
            _id_eq: filterId ?? undefined,
          },
          withRootComment: true,
          skip: 0,
          limit: COMMENT_PAGINATION,
          orderBy: [CommentOrderByInput.createdAt_DESC],
        },
      });
    }
  }, []);

  const onNewCommentData = useEffectEvent(({ data }: OnDataOptions<SubscribeNewCommentsSubscription>) => {
    if (data.data?.commentCreatedV2) {
      if (afterCount) {
        refetchAdjacent();
      } else {
        props.updateCommentsState([data.data.commentCreatedV2]);
      }
    }
  });

  useSubscribeNewCommentsSubscription({
    variables: {
      postIds: [postId],
    },
    skip: !!parentId,
    onData: onNewCommentData,
  });

  useSubscribeNewCommentsSubscription({
    variables: {
      parentIds: [parentId],
    },
    skip: !parentId,
    onData: onNewCommentData,
  });

  return (
    <CommentContext.Provider
      value={{
        postId,
        parentId,
        fetchMoreComments,
        hideSubComments,
        loading,
        totalCommentCountNewest: afterCount,
        totalCommentCountOldest: beforeCount,
      }}
    >
      {children}
    </CommentContext.Provider>
  );
});

const commentConnector = typedConnect(
  (state) => ({
    loggedUser: state.loginReducer.loggedUser as Account,
  }),
  {
    updateCommentsState,
  },
);

type CommentProps = {
  comment?: CommentType;
  children?: ReactNode[];
} & ConnectedProps<typeof commentConnector>;

const useSwalStyles = makeStyles((theme) =>
  createStyles({
    '@global': {
      '.swal2-styled.swal2-confirm': {
        backgroundColor: `${theme.palette.error.main} !important`,
        '&:hover': {
          backgroundColor: `${theme.palette.error.main} !important`,
        },
      },
    },
  }),
);

export default commentConnector(function Comment(props: CommentProps) {
  const { comment, children, loggedUser } = props;

  const { postId, hideSubComments } = useContext(CommentContext);
  const [isReplying, setIsReplying] = useState(false);

  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showMoreVert, setShowMoreVert] = useState(false);
  const { editing, setEditing } = useContext(EditingContext);
  const [openEditPopover, setOpenEditPopover] = useState<HTMLElement>(null);
  const [openAccountPopover, setOpenAccountPopover] = useState<string>(null);

  const [deleteOneComment] = useDeleteCommentMutation();

  useSwalStyles();

  useSubscribeCommentSubscription({
    variables: {
      id: comment?._id,
    },
    skip: !comment?._id,
    onData({ data }) {
      if (data.data?.commentUpdatedV2) {
        props.updateCommentsState([data.data.commentUpdatedV2]);
      }
    },
  });

  const isEdited = useMemo(
    () => (comment ? moment(comment.updatedAt).diff(moment(comment.createdAt), 'minutes') >= 1 : false),
    [comment],
  );

  const isBeingEdited = !comment || editing === comment._id;

  const postedDateDiff = useMemo(
    () => (comment ? timeDiffLowestCommonUnit(moment(), moment(comment.updatedAt)) : undefined),
    [comment],
  );

  const isSameAccount = comment?.createdBy._id === loggedUser._id;

  const user = useMemo(() => (comment?.createdBy ?? loggedUser) as Account, [loggedUser?._id, comment?._id]);

  const deleteComment = useAtomicCallback(
    (id: string) =>
      deleteOneComment({
        variables: { id },
        onError(error) {
          if (error.message.startsWith('Not found')) {
            PostDoesNotExistModal();
          }
        },
      }),
    [],
  );

  const formattedContent = useMemo(() => {
    if (!comment) {
      return '';
    }

    let ret: ReactNode[] | string = comment.content;

    for (const mention of comment.mentions) {
      // eslint-disable-next-line
      ret = reactStringReplace(ret, new RegExp(`(${mention.text})`, 'ig'), (a, index) => (
        <Fragment key={`${a}-${index}`}>
          {mention.type === 'issue' ? (
            <span
              data-testid={`mention-${mention.text}`}
              className={classes.mentionedLink}
              onClick={() => {
                mention.issue.stateMachineInstance.isRunning
                  ? navigate(
                      `/execution?name=${mention.text.slice(mention.text.indexOf('-') + 2)}&id=${mention.issueId}`,
                    )
                  : navigate(
                      `/history?name=${mention.text.slice(mention.text.indexOf('-') + 2)}&id=${mention.issueId}`,
                    );
              }}
            >
              {mention.text}
            </span>
          ) : mention.type === 'action' ? (
            <span
              data-testid={`mention-${mention.text}`}
              className={classes.mentionedLink}
              onClick={() => {
                navigate(`/action?name=${mention.text.slice(mention.text.indexOf('-') + 2)}&id=${mention.actionId}`);
              }}
            >
              {mention.text}
            </span>
          ) : (
            <span
              data-testid={`mention-${mention.text}`}
              onClick={() => setOpenAccountPopover(mention.accountId)}
              className={classes.mentionedLink}
            >
              {mention.text}
            </span>
          )}
        </Fragment>
      ));
    }

    return ret;
  }, [comment?.mentions, comment?.content]);

  return (
    <>
      <div className={classes.commentContainer}>
        <AccountAvatar account={user} />
        <div className={classes.commentGrid}>
          {!isBeingEdited ? (
            <>
              <div
                className={classes.commentBox}
                onMouseEnter={() => setShowMoreVert(true)}
                onMouseLeave={() => setShowMoreVert(false)}
              >
                {isSameAccount ? ( //Only show the icon for the popover if its the same account who made the comment
                  <IconButton
                    data-testid={`more-vert-${comment?.content}`}
                    style={{ visibility: showMoreVert ? 'visible' : 'hidden' }}
                    classes={{ root: `${classes.clickable} ${classes.commentMoreVert}` }}
                    onClick={(ev) => setOpenEditPopover(ev.target as HTMLElement)}
                  >
                    <MoreVert />
                  </IconButton>
                ) : null}
                <AccountPopoverDetails account={user} />
                <div className={classes.commentBody}>{formattedContent}</div>
              </div>
              <div className={classes.marginBottom}>
                <span className={classes.disabled}>
                  {postedDateDiff[0]} {t(postedDateDiff[1] + 's')}
                </span>
                {isEdited && <span className={classes.disabled}> {t('edited')}</span>}
                {'  '}
                <span
                  data-testid={'replyto-' + comment?.content}
                  className={classes.blueColor}
                  onClick={() => setIsReplying((isReplying) => !isReplying)}
                >
                  {t('reply')}
                </span>
              </div>
            </>
          ) : (
            <CommentDraft originalComment={comment} postId={postId} close={() => setEditing(null)} />
          )}
          {comment && (isReplying || children) ? (
            <div className={classes.subCommentContainer}>
              <div
                data-testid={'subcomments-' + comment?.content}
                className={classes.subCommentsBorder}
                onClick={() => hideSubComments()}
              />
              <div className={`${classes.subComments}`}>
                {isReplying && (
                  <CommentDraft
                    postId={postId}
                    replyTo={comment}
                    originalComment={{
                      content: `@${comment.createdBy.name} `,
                      mentions: [
                        {
                          type: CommentMentionType.account,
                          text: `@${comment.createdBy.name}`,
                          accountId: comment.createdBy._id,
                        },
                      ],
                    }}
                    close={() => setIsReplying(false)}
                  />
                )}

                {!!comment && !comment.parentId && <ShowMoreComments newest />}
                {children}
              </div>
            </div>
          ) : null}
          {!!comment && !comment.parentId && <ShowMoreComments oldest />}
        </div>
      </div>
      {openEditPopover ? (
        <Popover
          open={true}
          anchorEl={openEditPopover}
          onClick={() => setOpenEditPopover(null)}
          onClose={() => setOpenEditPopover(null)}
        >
          {/*<MenuItem
            data-testid={'copy-comment-link'}
            onClick={() =>
              navigator.clipboard.writeText(
                window.location.origin +
                  '/#' +
                  stringifyUrl({
                    url: '/feed',
                    query: {
                      id: comment.postId,
                      commentId: comment._id,
                    },
                  }),
              )
            }
          >
            <ContentCopy fontSize='small'>content_copy</>
            <span>{t('copyLink')}</span>
          </MenuItem>*/}
          {isSameAccount && (
            <>
              <MenuItem data-testid={'edit-comment-popover'} onClick={() => setEditing(comment._id)}>
                <Edit fontSize='small' />
                <span>{t('editComment')}</span>
              </MenuItem>
              <Divider />
              <MenuItem
                data-testid={'delete-comment-popover'}
                css={{ color: 'red' }}
                onClick={() =>
                  Swal.fire({
                    title: t('deleteComment'),
                    text: t('deleteTemplateSubTitle'),
                    reverseButtons: true,
                    showCancelButton: true,
                    confirmButtonText: t('delete'),
                    cancelButtonText: t('cancel'),
                  }).then((v) => {
                    if (v.isConfirmed) {
                      deleteComment(comment._id);
                    }
                  })
                }
              >
                <DeleteOutline fontSize={'medium'} />
                <span>{t('deleteComment')}</span>
              </MenuItem>
            </>
          )}
        </Popover>
      ) : null}
      {openAccountPopover ? (
        <DetailsPopover
          context={'account'}
          value={{
            _id: openAccountPopover,
          }}
          clear={() => setOpenAccountPopover(null)}
        />
      ) : null}
    </>
  );
});
