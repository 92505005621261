import { ApolloQueryResult } from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';
import { NexusGenInputs } from '@server/src/types';
import { APOLLO_CLIENT } from 'config/apollo.config';
import { cloneDeep, omit, orderBy, unionBy } from 'lodash';
import moment from 'moment';
import { Layout } from 'react-grid-layout';
import { Dispatch } from 'redux';
import {
  CreateDashboardDocument,
  CreateDashboardFolderDocument,
  CreateManyWidgetsDocument,
  CreateWidgetDocument,
  Dashboard,
  DashboardDraft,
  DashboardDraftInput,
  DashboardFolder,
  DashboardFolderCreateInput,
  DashboardFoldersDocument,
  DashboardFolderUpdateInput,
  DashboardFolderWhereInput,
  DashboardFolderWhereUniqueInput,
  DashboardsByFolderDocument,
  DeleteDashboardDocument,
  DeleteDashboardFolderDocument,
  DeleteWidgetDocument,
  Device,
  GetDashboardDraftDocument,
  GetDashboardsDocument,
  GetWidgetsDocument,
  SearchDashboardDocument,
  UpdateDashboardDocument,
  UpdateDashboardDraftDocument,
  UpdateDashboardFolderDocument,
  UpdateWidgetDocument,
  UpdateWidgetsLayoutDocument,
  UpsertDashboardDraftDocument,
  Widget,
  WidgetCreateInput,
  WidgetDocument,
  WidgetWhereInput,
} from 'src/gql/graphql';
import { State } from 'src/interfaces/reducers';
import { Tree, getErrorObject, isLeaf, setChildren } from 'src/utils/funcs';
import i18n from 'src/utils/translations/i18n';

export enum DashboardActionType {
  GET_DASHBOARD_FOLDERS = 'GET_DASHBOARD_FOLDERS',
  CREATE_FOLDER = 'CREATE_FOLDER',
  UPDATE_FOLDER = 'UPDATE_FOLDER',
  DELETE_FOLDER = 'DELETE_FOLDER',
  GET_DEFAULT_DASHBOARD = 'GET_DEFAULT_DASHBOARD',
  CREATE_DASHBOARD = 'CREATE_DASHBOARD',
  UPDATE_DASHBOARD = 'UPDATE_DASHBOARD',
  DELETE_DASHBOARD = 'DELETE_DASHBOARD',
  SEARCH_DASHBOARD = 'SEARCH_DASHBOARD',
  ERROR = 'ERROR',

  CREATE_WIDGET = 'CREATE_WIDGET',
  UPDATE_WIDGET = 'UPDATE_WIDGET',
  DELETE_WIDGET = 'DELETE_WIDGET',
  REFRESH_WIDGET = 'REFRESH_WIDGET',
  GET_WIDGETS = 'GET_WIDGETS',
  UPDATE_WIDGETS_LAYOUT = 'UPDATE_WIDGETS_LAYOUT',
  LOADING_WIDGETS = 'LOADING_WIDGETS',
  LOADING_DASHBOARD = 'LOADING_DASHBOARD',
  SNACKBAR_NEW_MESSAGE = 'SNACKBAR_NEW_MESSAGE',
  ENTER_DASHBOARD_FOLDER = 'ENTER_DASHBOARD_FOLDER',
  ENTER_DASHBOARD = 'ENTER_DASHBOARD',
  ENTER_DRAFT = 'ENTER_DASHBOARD_DRAFT',
  UPDATE_DRAFT = 'UPDATE_DASHBOARD_DRAFT',
  COMMIT_DRAFT = 'COMMIT_DASHBOARD_DRAFT',
  EXIT_DASHBOARD = 'EXIT_DASHBOARD',
  CREATE_MANY_WIDGETS = 'CREATE_MANY_WIDGETS',
}

export type DashboardAction =
  | {
      type:
        | DashboardActionType.CREATE_DASHBOARD
        | DashboardActionType.UPDATE_DASHBOARD
        | DashboardActionType.DELETE_DASHBOARD;
      payload: Dashboard;
    }
  | {
      type: DashboardActionType.SEARCH_DASHBOARD | DashboardActionType.GET_DASHBOARD_FOLDERS;
      payload: {
        folders: Tree[];
        dashboards: Dashboard[];
        root: boolean;
        isRoot: boolean;
      };
    }
  | {
      type: DashboardActionType.CREATE_FOLDER | DashboardActionType.UPDATE_FOLDER | DashboardActionType.DELETE_FOLDER;
      payload: DashboardFolder;
    }
  | {
      type: DashboardActionType.CREATE_WIDGET | DashboardActionType.UPDATE_WIDGET;
      payload: { widget: Widget };
    }
  | {
      type: DashboardActionType.CREATE_MANY_WIDGETS;
      payload: { widgets: Widget[] };
    }
  | {
      type: DashboardActionType.DELETE_WIDGET;
      payload: { id: string };
    }
  | {
      type: DashboardActionType.REFRESH_WIDGET;
      payload: { id: string; data: object; lastUpdated: Date };
    }
  | {
      type: DashboardActionType.GET_WIDGETS | DashboardActionType.UPDATE_WIDGETS_LAYOUT;
      payload: { widgets: Widget[] };
    }
  | { type: DashboardActionType.LOADING_WIDGETS; payload?: any }
  | { type: DashboardActionType.LOADING_DASHBOARD; payload?: any }
  | { type: DashboardActionType.SNACKBAR_NEW_MESSAGE; payload: any }
  | {
      type: DashboardActionType.ENTER_DRAFT;
      payload: DashboardDraft;
    }
  | {
      type: DashboardActionType.ENTER_DASHBOARD_FOLDER;
      payload: DashboardFolder;
    }
  | {
      type: DashboardActionType.ENTER_DASHBOARD;
      payload: Dashboard;
    }
  | {
      type: DashboardActionType.UPDATE_DRAFT;
      payload: {
        name: string;
        autoRefresh: boolean;
      };
    }
  | {
      type: DashboardActionType.EXIT_DASHBOARD;
      payload?: any;
    };

export type DispatchDashboard = Dispatch<DashboardAction>;

export interface DashboardTreeState {
  dashboards: Dashboard[];
  folders: Tree[];
  devices: Device[];
  searchResults: (Dashboard | DashboardFolder)[];
  defaultDashboard?: Dashboard;
  widgets: Widget[];
  loadingWidgets: boolean;
  loadingDashboard: boolean;
  currentDashboard?: Dashboard;
  currentDashboardDraft?: DashboardDraft;
  selectedInTree?: (Dashboard | DashboardFolder) & Partial<Tree>;
}

const initialState: DashboardTreeState = {
  dashboards: [],
  folders: [],
  devices: [],
  searchResults: [],
  widgets: [],
  loadingWidgets: false,
  loadingDashboard: false,
  currentDashboard: null,
  currentDashboardDraft: null,
};

export const addNewChildren = (tree, fatherNodeId, newNode) =>
  tree.map((node) => {
    if (node._id === fatherNodeId) {
      node.leaf = false;
      node.children.push({ ...newNode, children: [], leaf: true });
      node.children = orderBy(node.children, 'name', 'asc');
    } else if (node.children && node.children.length > 0) {
      node.children = addNewChildren(node.children, fatherNodeId, newNode);
    }
    return node;
  });

export const updateChildren = (tree, fatherNodeId, nodeUpdated) =>
  tree.map((node) => {
    if (node._id === fatherNodeId) {
      let childrens: any = [];
      node.children.map((ch) => {
        if (ch._id === nodeUpdated._id) {
          childrens.push({ ...ch, ...nodeUpdated });
          childrens = orderBy(childrens, 'name', 'asc');
        } else {
          if (nodeUpdated.default) {
            ch.default = false;
          }

          childrens.push(ch);
        }
      });
      node.children = orderBy(childrens, 'name', 'asc');
    } else if (node.children?.length > 0) {
      node.children = updateChildren(node.children, fatherNodeId, nodeUpdated);
    }
    return node;
  });

export const deleteChildren = (tree, fatherNodeId, nodeToDelete) =>
  tree.map((node) => {
    if (node._id === fatherNodeId) {
      node.children = node.children.filter((ch) => ch._id !== nodeToDelete._id);
      if (node.children?.length === 0) {
        node.leaf = true;
      }
    } else if (node.children?.length > 0) {
      node.children = deleteChildren(node.children, fatherNodeId, nodeToDelete);
    }
    return node;
  });

export default function (
  state: DashboardTreeState = initialState,
  { type, payload }: DashboardAction,
): DashboardTreeState {
  let dashboards: any = [];
  let folders: any = [];

  switch (type) {
    case DashboardActionType.GET_DASHBOARD_FOLDERS:
      folders = [];
      if (payload.folders.length > 0 && payload.folders[0].parent === null) {
        payload.folders.forEach((p) => {
          folders.push({
            ...p,
            leaf: isLeaf(p, 'dashboard'),
            children: [],
          });
        });
      } else if (payload.folders.length || payload.dashboards.length) {
        folders = setChildren(
          cloneDeep(state).folders,
          payload.folders.length && payload.folders[0].parent
            ? payload.folders[0].parent._id
            : payload.dashboards[0].folder._id,
          payload as any,
          'dashboard',
        );
      }
      return {
        ...state,
        folders: folders.length === 0 && !payload.root ? state.folders : orderBy(folders, 'name', 'asc'),
        dashboards: unionBy(payload.dashboards, state.dashboards),
      };
    case DashboardActionType.CREATE_FOLDER:
      folders = [];
      if (payload.parent !== null) {
        folders = addNewChildren(cloneDeep(state).folders, payload.parent._id, payload);
      } else {
        folders = JSON.parse(JSON.stringify(cloneDeep(state).folders));
        folders.push(Object.assign({ children: [], leaf: true }, { ...payload, dashboards: [] }));
      }
      return { ...state, folders: orderBy(folders, 'name', 'asc') };
    case DashboardActionType.UPDATE_FOLDER:
      folders = [];
      if (payload.parent !== null) folders = updateChildren(cloneDeep(state).folders, payload.parent._id, payload);
      else
        cloneDeep(state).folders.map((folder) => {
          if (folder._id === payload._id) {
            folders.push({
              ...folder,
              ...payload,
              children: folder.children,
            });
          } else {
            folders.push(folder);
          }
        });
      return { ...state, folders: orderBy(folders, 'name', 'asc') };
    case DashboardActionType.DELETE_FOLDER:
      folders = [];
      if (payload.parent !== null) {
        folders = deleteChildren(cloneDeep(state).folders, payload.parent._id, payload);
      } else {
        folders = cloneDeep(state).folders.filter((st) => st._id !== payload._id);
      }
      return {
        ...state,
        folders,
        searchResults: state.searchResults.filter((s) => s._id !== payload._id),
      };
    case DashboardActionType.CREATE_DASHBOARD:
      dashboards = [...state.dashboards, payload];
      folders = [];
      if (payload.folder !== null) {
        folders = addNewChildren(cloneDeep(state).folders, payload.folder._id, payload);
      }
      return { ...state, folders, dashboards };
    case DashboardActionType.UPDATE_DASHBOARD:
      dashboards = [];
      if (state.dashboards.some((dash) => dash._id === payload._id)) {
        dashboards = cloneDeep(state.dashboards).map((dashboard) =>
          dashboard._id === payload._id ? payload : dashboard,
        );
      } else {
        dashboards = [...cloneDeep(state.dashboards), payload];
      }

      if (payload.default) {
        dashboards = dashboards.map((d) => (d._id === payload._id ? d : { ...d, default: false }));
      }
      folders = [];
      if (payload.folder !== null) {
        folders = updateChildren(cloneDeep(state).folders, payload.folder._id, payload);
      }

      return {
        ...state,
        dashboards,
        folders,
        currentDashboard: payload._id === state.currentDashboard?._id ? payload : state.currentDashboard,
      };
    case DashboardActionType.DELETE_DASHBOARD: {
      const dashboards = state.dashboards.filter((d) => d._id !== payload._id);

      if (payload.folder) folders = deleteChildren(cloneDeep(state).folders, payload.folder._id, payload);
      return {
        ...state,
        dashboards,
        folders,
        searchResults: state.searchResults.filter((s) => s._id !== payload._id),
      };
    }
    case DashboardActionType.SEARCH_DASHBOARD:
      return {
        ...state,
        searchResults: [...payload.folders, ...payload.dashboards],
      };
    case DashboardActionType.LOADING_WIDGETS:
      return {
        ...state,
        loadingWidgets: true,
      };
    case DashboardActionType.LOADING_DASHBOARD:
      return {
        ...state,
        loadingDashboard: true,
      };
    case DashboardActionType.GET_WIDGETS:
      return {
        ...state,
        widgets: payload.widgets,
        loadingWidgets: false,
      };
    case DashboardActionType.UPDATE_WIDGETS_LAYOUT:
      return {
        ...state,
        widgets: payload.widgets.map((w) => ({ ...w, data: state.widgets.find((wd) => wd._id === w._id)?.data ?? [] })),
        loadingWidgets: false,
      };
    case DashboardActionType.CREATE_WIDGET:
      return {
        ...state,
        widgets: [...state.widgets, payload.widget],
      };
    case DashboardActionType.CREATE_MANY_WIDGETS:
      return {
        ...state,
        widgets: [...state.widgets, ...payload.widgets],
        loadingWidgets: false,
      };
    case DashboardActionType.UPDATE_WIDGET:
      return {
        ...state,
        widgets: state.widgets.map((w) => (w._id === payload.widget._id ? payload.widget : w)),
      };
    case DashboardActionType.DELETE_WIDGET:
      return {
        ...state,
        widgets: state.widgets.filter((w) => w._id !== payload.id),
      };
    case DashboardActionType.REFRESH_WIDGET:
      return {
        ...state,
        widgets: state.widgets.map((w) =>
          w._id === payload.id ? { ...w, data: payload.data, lastUpdated: payload.lastUpdated } : w,
        ),
      };
    case DashboardActionType.ENTER_DASHBOARD: {
      return {
        ...state,
        dashboards: unionBy([payload], state.dashboards),
        folders: updateChildren(cloneDeep(state).folders, payload.folder._id, payload),
        selectedInTree: payload,
        currentDashboard: payload,
        currentDashboardDraft: null,
        loadingDashboard: false,
      };
    }
    case DashboardActionType.ENTER_DASHBOARD_FOLDER: {
      return {
        ...state,
        selectedInTree: payload,
        currentDashboard: null,
        currentDashboardDraft: null,
        widgets: [],
      };
    }
    case DashboardActionType.ENTER_DRAFT: {
      return {
        ...state,
        currentDashboard: null,
        currentDashboardDraft: omit(payload, 'widgets'),
        widgets: payload.widgets,
        loadingDashboard: false,
      };
    }
    case DashboardActionType.UPDATE_DRAFT: {
      return {
        ...state,
        currentDashboardDraft: {
          ...state.currentDashboardDraft,
          name: payload.name,
          autoRefresh: payload.autoRefresh,
        },
      };
    }
    case DashboardActionType.EXIT_DASHBOARD: {
      return {
        ...state,
        selectedInTree: null,
        currentDashboard: null,
        currentDashboardDraft: null,
        widgets: [],
        loadingWidgets: false,
        loadingDashboard: false,
      };
    }
    default:
      return state;
  }
}

export const getFolders =
  (where, item?) =>
  async (dispatch: Dispatch): Promise<DashboardFolder[]> => {
    try {
      let response: ApolloQueryResult<any> | null = null;
      if (item?.node.dashboardFoldersCount || where.parent_exists !== undefined) {
        response = await APOLLO_CLIENT.query({
          variables: {
            where,
          },
          fetchPolicy: 'no-cache',
          query: DashboardFoldersDocument,
        });
      }

      let dashboards: ApolloQueryResult<any> | null = null;
      if (where.parent_eq && item.node.dashboardsCount) {
        dashboards = await APOLLO_CLIENT.query({
          variables: { _id: where.parent_eq },
          fetchPolicy: 'no-cache',
          query: GetDashboardsDocument,
        });
      }

      dispatch({
        type: DashboardActionType.GET_DASHBOARD_FOLDERS,
        payload: {
          root: where.parent_exists === false,
          folders: response ? response.data.dashboardFolders : [],
          dashboards: dashboards ? dashboards.data.dashboards : [],
          isRoot: !where.parent,
        },
      });

      return response?.data.dashboardFolders || [];
    } catch (e) {
      console.error('getFolders: ', e);
      return [];
    }
  };

export const getDefaultDashboard = (where: DashboardFolderWhereInput) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      query: DashboardFoldersDocument,
    });

    if (response.data.dashboardFolders.length > 0) {
      const dashboards = await APOLLO_CLIENT.query({
        variables: { folder: response.data.dashboardFolders[0]._id },
        fetchPolicy: 'no-cache',
        query: DashboardsByFolderDocument,
      });

      response.data.dashboardFolders[0].dashboards = dashboards.data.dashboards;
    }

    const defaultDashboard = response.data.dashboardFolders?.at(0)?.dashboards?.find((d) => d.default === true);

    if (defaultDashboard) {
      dispatch({
        type: DashboardActionType.ENTER_DASHBOARD,
        payload: defaultDashboard,
      });

      getWidgets({ dashboard_eq: defaultDashboard._id })(dispatch);
    }
  } catch (e) {
    console.error('getDefaultDashboard: ', e);

    return null;
  }
};

export const createFolder = (data: DashboardFolderCreateInput) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        data,
      },
      mutation: CreateDashboardFolderDocument,
    });

    dispatch({
      type: DashboardActionType.CREATE_FOLDER,
      payload: response.data.createDashboardFolder,
    });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const updateFolder =
  (data: DashboardFolderUpdateInput, where: DashboardFolderWhereUniqueInput) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          data,
          where,
        },
        mutation: UpdateDashboardFolderDocument,
      });

      dispatch({
        type: DashboardActionType.UPDATE_FOLDER,
        payload: response.data.updateDashboardFolder,
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const deleteFolder = (where: DashboardFolderWhereUniqueInput) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        where,
      },
      mutation: DeleteDashboardFolderDocument,
    });

    dispatch({
      type: DashboardActionType.DELETE_FOLDER,
      payload: response.data.deleteOneDashboardFolder,
    });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const createDashboard = (name: string, dashboardFolder: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: DashboardActionType.LOADING_DASHBOARD });
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        name,
        dashboardFolder,
        default: false,
      },
      mutation: CreateDashboardDocument,
    });
    const dashboard = response.data.createDashboard;
    dispatch({
      type: DashboardActionType.CREATE_DASHBOARD,
      payload: dashboard,
    });

    dispatch({
      type: DashboardActionType.ENTER_DASHBOARD,
      payload: dashboard,
    });

    return dashboard;
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const updateDashboard =
  (data: { id: string; autoRefresh?: boolean; default?: boolean; dashboardFolder?: string }) =>
  async (dispatch: Dispatch) => {
    try {
      const resp = await APOLLO_CLIENT.mutate({
        variables: {
          ...data,
        },
        mutation: UpdateDashboardDocument,
      });

      dispatch({
        type: DashboardActionType.UPDATE_DASHBOARD,
        payload: resp.data.updateDashboard,
      });

      return resp.data.updateDashboard;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const deleteDashboard = (dashboardId: string, exit?: boolean) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        id: dashboardId,
      },
      mutation: DeleteDashboardDocument,
    });
    dispatch({
      type: DashboardActionType.DELETE_DASHBOARD,
      payload: resp.data.deleteOneDashboard,
    });

    if (exit) {
      dispatch({
        type: DashboardActionType.EXIT_DASHBOARD,
      });
    }

    return resp.data.deleteOneDashboard;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const search = (searchString: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: { searchString },
      fetchPolicy: 'no-cache',
      mutation: SearchDashboardDocument,
    });
    dispatch({
      type: DashboardActionType.SEARCH_DASHBOARD,
      payload: {
        folders: response.data.dashboardFolders,
        dashboards: response.data.dashboards,
      },
    });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
  }
};

export const createWidget = (data: NexusGenInputs['WidgetUpdateInput']) => async (dispatch: DispatchDashboard) => {
  try {
    if (data?.dataset?.issuesCount?.filter?.date) {
      const dateInput = data.dataset.issuesCount.filter.date;

      dateInput.custom = dateInput.custom || {};

      if ((dateInput as any).startDate && (dateInput as any).endDate) {
        dateInput.custom.from = (dateInput as any).startDate;
        dateInput.custom.until = (dateInput as any).endDate;
        dateInput.type = 'custom';
        delete (dateInput as any).startDate;
        delete (dateInput as any).endDate;
      }

      data.dataset.issuesCount.filter.date = dateInput;
    }

    const response = await APOLLO_CLIENT.mutate({
      variables: {
        data,
      },
      mutation: CreateWidgetDocument,
    });

    dispatch({ type: DashboardActionType.CREATE_WIDGET, payload: { widget: response.data.createWidget } });

    return response.data.createWidget;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: DashboardActionType.SNACKBAR_NEW_MESSAGE,
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const updateWidgetsLayout = (l: Layout[]) => async (dispatch: DispatchDashboard, getState: () => State) => {
  try {
    const wdgts = l.map((widgetLayout) => {
      const widget = getState().dashboardReducer.widgets.find((w) => w.layout.i == parseInt(widgetLayout.i));

      return {
        layout: { ...widgetLayout, i: parseInt(widgetLayout.i), moved: undefined, static: undefined },
        where: { _id: widget._id },
      };
    });

    if (wdgts.length) {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          widgets: wdgts,
        },
        mutation: UpdateWidgetsLayoutDocument,
      });

      dispatch({
        type: DashboardActionType.UPDATE_WIDGETS_LAYOUT,
        payload: { widgets: response.data.updateWidgetsLayout },
      });
    }
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: DashboardActionType.SNACKBAR_NEW_MESSAGE,
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const updateWidget =
  (data: NexusGenInputs['WidgetUpdateInput'], id: string) => async (dispatch: DispatchDashboard) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          data,
          id,
        },
        mutation: UpdateWidgetDocument,
      });

      dispatch({ type: DashboardActionType.UPDATE_WIDGET, payload: { widget: response.data.updateWidget } });

      return response.data.updateWidget;
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: DashboardActionType.SNACKBAR_NEW_MESSAGE,
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getWidgetData =
  (id: string, forceReload: boolean = false) =>
  async (dispatch: DispatchDashboard) => {
    const { data } = await APOLLO_CLIENT.query({
      query: WidgetDocument,
      fetchPolicy: 'network-only',
      variables: {
        id,
        forceReload,
      },
    });

    dispatch({
      type: DashboardActionType.REFRESH_WIDGET,
      payload: { id, data: data.widget.data, lastUpdated: moment().toDate() },
    });

    return data.widget.data;
  };

export const cleanWidgets = () => (dispatch: DispatchDashboard) => {
  dispatch({
    type: DashboardActionType.GET_WIDGETS,
    payload: { widgets: [] },
  });
};

export const getWidgets = (where: WidgetWhereInput) => async (dispatch: DispatchDashboard) => {
  try {
    dispatch({ type: DashboardActionType.LOADING_WIDGETS });

    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      query: GetWidgetsDocument,
    });

    dispatch({
      type: DashboardActionType.GET_WIDGETS,
      payload: { widgets: response.data.widgets },
    });
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: DashboardActionType.SNACKBAR_NEW_MESSAGE,
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
  }
};

export const deleteWidget = (id: string) => async (dispatch: DispatchDashboard) => {
  try {
    await APOLLO_CLIENT.mutate({
      variables: {
        id,
      },
      mutation: DeleteWidgetDocument,
    });

    dispatch({ type: DashboardActionType.DELETE_WIDGET, payload: { id } });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: DashboardActionType.SNACKBAR_NEW_MESSAGE,
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getDashboardDraft = (id: string) => async (dispatch: DispatchDashboard) => {
  dispatch({ type: DashboardActionType.LOADING_DASHBOARD });
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        id,
      },
      mutation: GetDashboardDraftDocument,
    });

    dispatch({ type: DashboardActionType.ENTER_DRAFT, payload: response.data.getDashboardDraft });

    return response.data.getDashboardDraft;
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: DashboardActionType.SNACKBAR_NEW_MESSAGE,
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const upsertDashboardDraft = (id: string) => async (dispatch: DispatchDashboard) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        id,
      },
      mutation: UpsertDashboardDraftDocument,
    });

    dispatch({ type: DashboardActionType.ENTER_DASHBOARD, payload: response.data.upsertDashboardFromDraft });

    dispatch({
      type: DashboardActionType.SNACKBAR_NEW_MESSAGE,
      payload: {
        message: i18n.t('appliedChangesSuccess'),
        severity: 'success',
      },
    });

    return response.data.upsertDashboardFromDraft;
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: DashboardActionType.SNACKBAR_NEW_MESSAGE,
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const updateDashboardDraft = (id: string, data: DashboardDraftInput) => async (dispatch: DispatchDashboard) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        id,
        data,
      },
      mutation: UpdateDashboardDraftDocument,
    });

    dispatch({ type: DashboardActionType.UPDATE_DRAFT, payload: response.data.updateDashboardDraft });

    return response.data.updateDashboardDraft;
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: DashboardActionType.SNACKBAR_NEW_MESSAGE,
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const discardDashboardDraft = () => async (dispatch: DispatchDashboard) => {
  dispatch({ type: DashboardActionType.EXIT_DASHBOARD });
};

export const setSelectedInTree =
  <T extends Dashboard | DashboardFolder>(dashboard?: T) =>
  (dispatch: Dispatch) => {
    if (dashboard) {
      if (!dashboard.__typename.endsWith('Folder')) {
        dispatch({
          type: DashboardActionType.ENTER_DASHBOARD,
          payload: dashboard,
        });

        getWidgets({ dashboard_eq: dashboard._id })(dispatch);
      } else {
        dispatch({
          type: DashboardActionType.ENTER_DASHBOARD_FOLDER,
          payload: dashboard,
        });
      }
    } else {
      dispatch({
        type: DashboardActionType.EXIT_DASHBOARD,
      });
    }
  };

export const createManyWidgets =
  (data: WidgetCreateInput[]) =>
  async (dispatch: DispatchDashboard): Promise<Widget[] | { graphQLErrors: GraphQLErrors }> => {
    dispatch({ type: DashboardActionType.LOADING_WIDGETS });
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          data,
        },
        mutation: CreateManyWidgetsDocument,
      });

      dispatch({
        type: DashboardActionType.CREATE_MANY_WIDGETS,
        payload: { widgets: response.data.createManyWidgets },
      });
      return response.data.createManyWidgets;
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: DashboardActionType.SNACKBAR_NEW_MESSAGE,
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };
