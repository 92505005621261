import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    responsesItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: '4px',
      color: theme.palette.primary.main,
      padding: '6px 0',
      maxHeight: '40px',
      width: '100%',
      '&:hover': {
        cursor: 'pointer',
        background: theme.palette.primary.light,
      },
    },
    responsesChipContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: '4px',
    },
    responsesChip: {
      background: theme.palette.background.background1,
      fontSize: '10px',
      maxWidth: '96px',
    },
    responsesChipHovered: {
      background: theme.palette.background.background1,
      cursor: 'pointer',
      fontSize: '10px',
      maxWidth: '96px',
    },
    responsesEdit: {
      fontSize: '15px',
      fill: theme.palette.text.primary,
      color: theme.palette.text.primary,
    },
  });
