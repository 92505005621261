import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    '@global': {
      '.react-datepicker-popper': {
        zIndex: 10000,
      },
      '::placeholder': {
        color: `${theme.palette.grey.A200} !important`,
      },
    },
    header: {
      display: 'flex',
      flexGrow: 1,
      height: '40px',
      flexDirection: 'row',
    },
    datePicker: {
      padding: '0 10px 0 10px',
      width: '100% !important',
      border: `1px solid ${theme.palette.grey.light}`,
      outline: '1px',
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main} !important`,
      },
      '&:focus': {
        background: 'transparent !important',
        boxShadow: 'none !important',
        fontSize: theme.typography.fontSize,
        color: `${theme.palette.text.primary} !important`,
        transition: 'all 0.3s, height 1ms',
        borderRadius: '5px !important',
      },
    },
    datePickerError: {
      padding: '0 10px 0 10px',
      width: '100% !important',
      border: `1px solid ${theme.palette.error.main} !important`,
      '&:focus': {
        background: 'transparent !important',
        boxShadow: 'none !important',
        fontSize: theme.typography.fontSize,
        color: `${theme.palette.text.primary} !important`,
        transition: 'all 0.3s, height 1ms',
        borderRadius: '5px !important',
        border: `1px solid ${theme.palette.error.main} !important`,
      },
    },
    datePickerDisable: {
      padding: '0 10px 0 10px',
      width: '100% !important',
      border: `1px solid ${theme.palette.text.disabled} !important`,
      background: theme.palette.background.default,
    },
    height: {
      height: '40px',
    },
    inputFile: {
      border: '0 !important',
    },
    noBorder: {
      border: 'none !important',
    },
    fullWidth: {
      // width: '-webkit-fill-available',
      width: '100%',
    },
    badge: {
      backgroundColor: theme.palette.grey.main,
      fontWeight: 700,
      color: theme.palette.common.white,
      marginRight: '5px',
      borderRadius: '3px',
      position: 'relative',
      transform: 'none',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100px',
    },
    badgeRoot: {},
    inputGroupDisable: {
      boxShadow: 'none !important',
      transition: '0.3s',
      height: '40px',
      borderRadius: '5px !important',
    },
    inputGroup: {
      height: '40px',
      background: 'transparent !important',
      '&:hover': {
        // boxShadow: `0 0 5px ${theme.palette.grey.light} !important`,
        color: `${theme.palette.text.primary} !important`,
        border: `3px ${theme.palette.grey.light} !important`,
      },
    },
    inputDisabledWithBadge: {
      boxShadow: 'none !important',
      transition: '0.3s',
      height: '40px !important',
      borderRadius: '5px',
      color: `${theme.palette.text.secondary} !important`,
      fontSize: theme.typography.fontSize,
    },
    inputWithBadge: {
      boxShadow: 'none !important',
      color: theme.palette.text.primary,
      transition: 'all 0.3s, height 1ms',
      minHeight: '40px !important',
      borderRadius: '5px',
      fontSize: theme.typography.fontSize,
    },
    inputWithBadgeError: {
      background: 'transparent !important',
      boxShadow: 'none !important',
      color: theme.palette.text.primary,
      border: `1px solid ${theme.palette.error.main} !important`,
      transition: 'all 0.3s, height 1ms',
      minHeight: '40px !important',
      borderRadius: '5px',
      fontSize: theme.typography.fontSize,
    },
    noBorderColor: {
      borderColor: theme.palette.grey.light,
    },
    disabledBorderColor: {
      borderColor: `${theme.palette.text.disabled} !important`,
      boxShadow: 'unset !important',
    },
    borderError: {
      borderColor: `${theme.palette.error.main} !important`,
      boxShadow: 'unset !important',
    },
    inputCursorEnabled: {
      cursor: 'pointer',
      border: `1px solid ${theme.palette.primary.main}`,
    },
    inputCursorDisabled: {
      cursor: 'not-allowed',
    },
    inputAddon: {},
    adornEnd: {
      paddingRight: '0 !important',
    },
    taskTemplateInputColor: {
      '& input': {
        '&::-webkit-outer-spin-button': {
          opacity: 1,
        },
        '&::-webkit-inner-spin-button': {
          opacity: 1,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.grey.light}`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${theme.palette.primary.main}`,
        },
      },
    },
    inputAddonDisabled: {
      border: '1px rgba(255,255,255, 0.5) !important',
      color: 'rgba(255,255,255,0.4) !important',
      borderBottomRightRadius: '5px !important',
      borderTopRightRadius: '5px !important',
      borderLeft: '0 !important',
      display: 'inline !important',
      height: '40px !important',
      marginLeft: '0 !important',
    },
    inputDisabled: {
      boxShadow: 'none !important',
      transition: '0.3s',
      height: '40px',
      fontSize: theme.typography.fontSize,
      borderRadius: '5px !important',
      // color: `${theme.palette.text.secondary} !important`,
      color: `${theme.palette.text.disabled} !important`,
      pointerEvents: 'none',
    },
    inputOverride: {
      '-webkit-text-fill-color': `unset !important`,
    },
    input: {
      minHeight: '40px',
      background: 'transparent !important',
      boxShadow: 'none !important',
      fontSize: theme.typography.fontSize,
      color: `${theme.palette.text.primary} !important`,
      transition: 'all 0.3s, height 1ms',
      borderRadius: '5px !important',
      '& .MuiOutlinedInput-input': {
        padding: '8px',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${theme.palette.primary.main}`,
        },
      },
      '& ::placeholder': {
        color: `${theme.palette.text.secondary} !important`,
        opacity: 1,
      },
    },
    inputError: {
      border: `1px solid ${theme.palette.error.main} !important`,
    },
    buttonView: {
      height: '30px',
      width: '30px',
    },
    visibilityIcons: {
      fontSize: '16px',
      color: theme.palette.text.secondary,
    },
    numberUnit: {
      color: theme.palette.text.primary,
      fontFamily: 'Poppins',
      fontWeight: 700,
      marginRight: '8px',
    },
    imgDiv: {
      '& > img': {
        height: '24px',
        marginRight: '8px',
      },
    },
    textError: {
      color: theme.palette.error.main,
      margin: '8px 0px 0px 0px',
    },
    dateTimeWidth: {
      maxWidth: '300px',
      width: '300px',
    },
  });
