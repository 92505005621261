import { Action, State } from 'src/interfaces/reducers';
import * as queries from 'src/modules/alarm/alarm.queries';
import { getErrorObject } from 'src/utils/funcs';
import { APOLLO_CLIENT } from 'config/apollo.config';
import { Dispatch } from 'redux';

export const GET_ALARM_TASKS = 'GET_ALARM_TASKS';
export const GET_ALARM_HISTORY = 'GET_ALARM_HISTORY';
export const CREATE_ALARM_TASK = 'CREATE_ALARM_TASK';
export const DELETE_ALARM_TASK = 'DELETE_ALARM_TASK';
export const GET_ELEMENTS_WITH_TAGS = 'GET_ELEMENTS_WITH_TAGS';
export const GET_ELEMENT_SEARCH_ALARMS = 'GET_ELEMENT_SEARCH_ALARMS';
export const CHANGE_SEARCH_INPUT_VALUE_ALARMS = 'CHANGE_SEARCH_INPUT_VALUE_ALARMS';
export const GET_ELEMENT_SEARCH_TAGS = 'GET_ELEMENT_SEARCH_TAGS';
export const CHANGE_SEARCH_INPUT_VALUE_TAGS = 'CHANGE_SEARCH_INPUT_VALUE_TAGS';
export const ERROR = 'ERROR';

const initialState: State = {
  error: false,
  elements: [],
  alarms: [],
  history: [],
  elSearch: [],
  elOtherSearch: [],
  searchInputValue: '',
  searchInputValueTags: '',
};

export default (state: State = initialState, action: Action): State => {
  let tags: any = [];
  let al: any = [];
  switch (action.type) {
    case CHANGE_SEARCH_INPUT_VALUE_ALARMS:
      return { ...state, searchInputValue: action.payload.searchInputValue };
    case CHANGE_SEARCH_INPUT_VALUE_TAGS:
      return {
        ...state,
        searchInputValueTags: action.payload.searchInputValue,
      };
    case GET_ELEMENTS_WITH_TAGS:
      return { ...state, elements: action.payload.elements, error: false };
    case GET_ALARM_TASKS:
      return { ...state, alarms: action.payload.alarms, error: false };
    case CREATE_ALARM_TASK:
      al = [];
      state.alarms.map((alarm) => {
        if (alarm.id === action.payload.alarm.id) {
          al.push(action.payload.alarm);
        } else {
          al.push(alarm);
        }
      });
      return { ...state, alarms: al, error: false };
    case GET_ALARM_HISTORY:
      return { ...state, history: action.payload.alarms, error: false };
    case GET_ELEMENT_SEARCH_ALARMS:
      const alarms: any = [];
      tags = [];
      state.alarms.map((alarm) => {
        if (alarm.args.name.includes(state.searchInputValue)) {
          const el = state.elements.filter(
            (el) => el.tags.filter((tag) => tag.value.trim() === alarm.args.tagValue.trim()).length > 0,
          );
          alarms.push(Object.assign({ parent: el[0] }, alarm));
        }
      });
      state.elements.map((element) => {
        element.tags.map((tag) => {
          if (tag.name.includes(state.searchInputValue)) {
            tags.push(Object.assign({ parent: element }, tag));
          }
        });
      });
      return { ...state, elSearch: [...action.payload.elements, ...tags, ...alarms], error: false };
    case GET_ELEMENT_SEARCH_TAGS:
      tags = [];
      state.elements.map((element) => {
        element.tags.map((tag) => {
          if (tag.name.includes(state.searchInputValueTags)) {
            tags.push(Object.assign({ parent: element }, tag));
          }
        });
      });
      return {
        ...state,
        elOtherSearch: [...action.payload.elements, ...tags],
        error: false,
      };
    default:
      return state;
  }
};

export const getAlarmTasks = () => async (dispatch) => {
  // access the backend and get the access token
  try {
    const response = await APOLLO_CLIENT.query({
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ALARM_TASKS,
    });
    // update the local storage with the token using the correct action
    dispatch({
      type: GET_ALARM_TASKS,
      payload: { alarms: response.data.getAlarmTasks },
    });
    return response.data.getAlarmTasks;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    dispatch({
      type: ERROR,
      payload: error,
    });
  }
};

export const createOrUpdateAlarmTask =
  (
    taskId,
    taskStatus,
    measurement,
    tagKey,
    tagValue,
    name,
    triggerType,
    triggerWhen,
    triggerShift,
    triggerCondition,
    triggerValue,
    triggerRangeValues,
    triggerPeriod,
    triggerFunctionName,
    triggerFunctionPeriod,
  ) =>
  async (dispatch) => {
    // access the backend and get the access token
    try {
      const response = await APOLLO_CLIENT.mutate({
        mutation: queries.Q_CREATE_ALARM_TASK,
        variables: {
          taskId,
          taskStatus,
          measurement,
          tagKey,
          tagValue,
          name,
          triggerType,
          triggerWhen,
          triggerShift,
          triggerCondition,
          triggerValue: parseInt(triggerValue),
          triggerRangeValues: triggerRangeValues
            ? {
                lower: parseInt(triggerRangeValues.lower),
                upper: parseInt(triggerRangeValues.upper),
              }
            : undefined,
          triggerPeriod,
          triggerFunctionName,
          triggerFunctionPeriod,
        },
      });

      // update the local storage with the token using the correct action
      dispatch({
        type: CREATE_ALARM_TASK,
        payload: { alarm: response.data.createOrUpdateAlarmTask },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const deleteAlarmTask = (taskId) => async (dispatch) => {
  // access the backend and get the access token
  try {
    const response = await APOLLO_CLIENT.mutate({
      mutation: queries.Q_DELETE_ALARM_TASK,
      variables: {
        taskId,
      },
    });

    // update the local storage with the token using the correct action
    dispatch({
      type: DELETE_ALARM_TASK,
      payload: { alarm: response.data.deleteAlarmTask },
    });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
  }
};

export const getAlarmHistory = (offset, limit) => async (dispatch: Dispatch) => {
  // access the backend and get the access token
  try {
    const response = await APOLLO_CLIENT.query({
      query: queries.Q_GET_ALARM_HISTORY,
      variables: {
        offset,
        limit,
      },
      fetchPolicy: 'no-cache',
    });

    // update the local storage with the token using the correct action
    /* dispatch({
      type: GET_ALARM_HISTORY,
      payload: { alarms: response.data.getAlarmHistory},
    }); */
    return { alarms: response.data.getAlarmHistory, alarmsCount: response.data.getAlarmHistoryCount };
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const getElementsWithTags = () => async (dispatch: Dispatch) => {
  // access the backend and get the access token
  try {
    const response = await APOLLO_CLIENT.query({
      query: queries.Q_ELEMENTS_WITH_TAGS,
    });

    // update the local storage with the token using the correct action
    dispatch({
      type: GET_ELEMENTS_WITH_TAGS,
      payload: { elements: response.data.elements },
    });
    return response.data.elements;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
  }
};

export const getElementWithTag = (tagValue) => async (dispatch: Dispatch) => {
  // access the backend and get the access token
  try {
    const response = await APOLLO_CLIENT.query({
      query: queries.Q_ELEMENT_WITH_TAG,
      variables: {
        tagValue,
      },
    });

    return response.data.elements[0];
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
  }
};

export const elementSearch = (stringSearch) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: CHANGE_SEARCH_INPUT_VALUE_ALARMS,
      payload: { searchInputValue: stringSearch },
    });

    const response = await APOLLO_CLIENT.query({
      variables: {
        searchString: stringSearch,
      },
      fetchPolicy: 'no-cache',
      query: queries.M_ELEMENT_SEARCH,
    });
    // update the local storage with the token using the correct action
    dispatch({
      type: GET_ELEMENT_SEARCH_ALARMS,
      payload: { elements: response.data.elements },
    });

    return response.data.elements;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
  }
};

export const elementSearchTags = (stringSearch) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: CHANGE_SEARCH_INPUT_VALUE_TAGS,
      payload: { searchInputValue: stringSearch },
    });

    const response = await APOLLO_CLIENT.query({
      variables: {
        searchString: stringSearch,
      },
      fetchPolicy: 'no-cache',
      query: queries.M_ELEMENT_SEARCH,
    });
    // update the local storage with the token using the correct action
    dispatch({
      type: GET_ELEMENT_SEARCH_TAGS,
      payload: { elements: response.data.elements },
    });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
  }
};

export const siteElements = (id) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_SITE_ELEMENTS,
    });
    // update the local storage with the token using the correct action
    return response.data.site;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
  }
};

export const getTimeSeries = (filters, startTimeRange?, stopTimeRange?) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        filters,
        startTimeRange,
        stopTimeRange,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_TIME_SERIES,
    });
    // update the local storage with the token using the correct action
    return response.data.timeSeriesQueryBuilder;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
  }
};
