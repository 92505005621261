import moment from 'moment';
import { Account, Element, IssueCatalog, LabelValue, Site } from 'src/gql/graphql';
import i18n from 'src/utils/translations/i18n';
import {
  DateRangePresetType,
  DateRangePresetValue,
  getNameOfDateRangePreset,
} from '../custom-date-dropdown/utils/custom-date-dropdown.utils';

export interface IFiltersDateRange {
  type?: DateRangePresetType;
  current?: DateRangePresetValue;
  last?: DateRangePresetValue;
  from?: Date;
  to?: Date;
}

export const filterDateFormat = 'YYYY/MM/DD HH:mm';

export const siteElementFormatter = (siteEl: Array<Site | Element>) => siteEl.map((s) => s.name).join('; ');

export const issueFormatter = (siteEl: Array<IssueCatalog>) => siteEl.map((s) => s.name).join('; ');

export const labelAccountFormatter = (acc: Array<Account | LabelValue>) =>
  acc.map((a) => (a.__typename === 'LabelValue' ? a.value : a.name)).join('; ');

export const accountFormatter = (acc: Array<Account>) => acc.map((a) => a.name).join('; ');

export const labelValueFormatter = (lbl: Array<LabelValue>) => lbl.map((l) => l.value).join('; ');

export const defaultValueFormatter = (vals) =>
  vals
    .map((v) => {
      if (typeof v === 'object') {
        return i18n.t(
          v.name || v.value || v.title || v.address?.site?.name || v.address?.element?.name || v.address?.street,
        );
      }

      if (moment(v, filterDateFormat, true).isValid()) {
        return moment(v).format(filterDateFormat);
      }

      return i18n.t(v);
    })
    .join('; ');

export const dateValueFormatter = (val: {
  from?: Date;
  to?: Date;
  startDate?: Date;
  endDate?: Date;
  type?: DateRangePresetType;
  current?: DateRangePresetValue;
  last?: DateRangePresetValue;
}) => {
  if (!val?.from && !val?.to && !val?.startDate && !val?.endDate && !val?.type) {
    return '';
  }

  if (val?.type && (val?.current || val?.last)) return getNameOfDateRangePreset(val);

  const formatDate = (date?: Date) => (date ? moment(date).format('DD/MM/YYYY') : '');

  const fromDate = formatDate(val.from || val.startDate);
  const toDate = formatDate(val.to || val.endDate);
  const toText = val.to || val.endDate ? i18n.t('to') : '';

  return `${fromDate} ${toText} ${toDate}`;
};

export const possibleDateFilters = [
  'createdAt',
  'updatedAt',
  'dueDate',
  'startDate',
  'finishDate',
  'executionDate',
  'startDateTime',
  'finishDateTime',
  'creationDate',
  'lastUpdate',
];

/**
 * Function to format date filters in case they have absolute date and date is in a different format
 * @param filters
 * @returns
 */
export const formatDateFilters = (filters) => {
  possibleDateFilters.forEach((key) => {
    const filter = filters[key];
    if (filter && !filter.type) {
      const { startDate, endDate, from, to } = filter;
      filters[key] = startDate || endDate || from || to ? { from: startDate || from, to: endDate || to } : undefined;
    }
  });

  return filters;
};

export const getSpecificDateByDateFilter = (date, filterType) => {
  const isCurrent = date.type === 'current';

  if (filterType === 'from') {
    if (isCurrent) return moment().startOf(date[date.type]).toDate();
    else {
      switch (date[date.type]) {
        case 'day':
          return moment().subtract(1, 'day').startOf('day').toDate();
        case 'week':
          return moment().subtract(7, 'day').startOf('day').toDate();
        case 'month':
          return moment().subtract(1, 'month').startOf('month').toDate();
        case 'year':
          return moment().subtract(1, 'year').startOf('year').toDate();
      }
    }
  } else {
    if (isCurrent) return moment().endOf(date[date.type]).toDate();
    else {
      switch (date[date.type]) {
        case 'day':
          return moment().subtract(1, 'day').endOf('day').toDate();
        case 'week':
          return moment().endOf('day').toDate();
        case 'month':
          return moment().subtract(1, 'month').endOf('month').toDate();
        case 'year':
          return moment().subtract(1, 'year').endOf('year').toDate();
      }
    }
  }

  return moment().toDate();
};

export const getDateToFilterQuery = (dateFilter, filterType: 'to' | 'from') => {
  return dateFilter
    ? dateFilter[filterType]
      ? moment(dateFilter[filterType]).toDate()
      : dateFilter.type
        ? getSpecificDateByDateFilter(dateFilter, filterType)
        : undefined
    : undefined;
};
