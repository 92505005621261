import { PopperPlacementType } from '@mui/material';
import { PlacementType } from '@rc-component/tour/es/placements';
import { MainContainerState } from 'src/base/main-container/context/main.container.context';

/**
 * This will format incoming placements from the @rc-component/tour type and convert them into
 * PopperPlacementType this is used when making the rendered poppers for each sub step
 * @param placement - PlacementType placements
 * @param classes - class names
 * @param state - MainContainerState
 * @returns - necessary formated data converted into PopperPlacementType
 */
export const formatPlacement = (
  placement: PlacementType,
  classes: Record<string, string>,
  state: MainContainerState,
): { newPlacement: PopperPlacementType; correspondingClass: string; correspondingArrow: string } => {
  let newPlacement: PopperPlacementType;
  let correspondingClass: string;
  let correspondingArrow: string;
  switch (placement) {
    case 'bottom':
      newPlacement = 'bottom';
      correspondingClass = classes.subStepPopperArrowTop;
      correspondingArrow = classes.subStepArrowUp;
      break;
    case 'bottomLeft':
      newPlacement = 'bottom-start';
      correspondingClass = classes.subStepPopperArrowTopLeft;
      correspondingArrow = classes.subStepArrowUp;
      break;
    case 'bottomRight':
      newPlacement = 'bottom-end';
      correspondingClass = classes.subStepPopperArrowTopRight;
      correspondingArrow = classes.subStepArrowUp;
      break;
    case 'left':
      newPlacement = 'left';
      correspondingClass = classes.subStepPopperArrowRight;
      correspondingArrow = classes.subStepArrowRight;
      break;
    case 'leftTop':
      newPlacement = 'left-start';
      correspondingClass = classes.subStepPopperArrowRight;
      correspondingArrow = classes.subStepArrowRight;
      break;
    case 'right':
      newPlacement = 'right';
      correspondingClass = classes.subStepPopperArrowLeft;
      correspondingArrow = classes.subStepArrowLeft;
      break;
    case 'rightTop':
      newPlacement = 'right-start';
      correspondingClass = classes.subStepPopperArrowLeft;
      correspondingArrow = classes.subStepArrowLeft;
      break;
    case 'top':
      newPlacement = 'top';
      correspondingClass = classes.subStepPopperArrowBottom;
      correspondingArrow = classes.subStepArrowDown;
      break;
    case 'topLeft':
      newPlacement = 'top-start';
      correspondingClass =
        state.currentStep === 'createIssue'
          ? classes.subStepPopperArrowBottomLeftIssue
          : classes.subStepPopperArrowBottomLeft;
      correspondingArrow = classes.subStepArrowDown;
      break;
    case 'topRight':
      newPlacement = 'top-end';
      correspondingClass =
        state.currentStep === 'build'
          ? classes.subStepPopperArrowBottomRightBuild
          : classes.subStepPopperArrowBottomRight;
      correspondingArrow = classes.subStepArrowDown;
      break;
  }
  return { newPlacement, correspondingClass, correspondingArrow };
};
