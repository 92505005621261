import GlartekDark from '@assets/images/glartek_dark.png';
import GlartekLight from '@assets/images/GlarVision.png';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Card, FormGroup, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { ConnectedProps } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { isValidToken } from 'src/base/reset-password/reset-password.backend';
import { styles } from 'src/base/reset-password/styles/reset-password';
import { showSnackbar } from 'src/base/root/root.redux';
import { useResetPasswordMutation } from 'src/gql/graphql';
import DefaultBackground from 'src/utils/components/default-background';
import DefaultButton from 'src/utils/components/default-button/default-button';
import { typedConnect } from 'src/utils/funcs';
import { withBlankPage } from '../main-container/hocs/with-blank-page';

interface ResetPasswordProps extends ConnectedProps<typeof connector>, WithTranslation, WithStyles<typeof styles> {}

const ResetPassword = (props: ResetPasswordProps) => {
  const [, setValid] = useState<boolean>(false);
  const [seePass, setSeePass] = useState<boolean>(false);
  const [seePassConfirm, setSeePassConfirm] = useState<boolean>(false);
  const location = useLocation();
  const useQuery = () => new URLSearchParams(location.search);
  const query = useQuery();
  const { t }: { t: (key: string) => string } = useTranslation();
  const pwRef: MutableRefObject<HTMLInputElement | undefined> = useRef();
  const confirmRef: MutableRefObject<HTMLInputElement | undefined> = useRef();
  const navigate = useNavigate();

  const [resetPassword, { loading: saving }] = useResetPasswordMutation();

  useEffect(() => {
    isValidToken(query.get('token'))
      .then((isValid) => {
        //@ts-ignore
        if (!isValid || isValid === 'false') {
          navigate(props.defaultRoute || '/');
          void props.showSnackbar('error', t('errorInvalidToken'));
        }
        setValid(isValid);
      })
      .catch((e) => console.error('ResetPassword: ', e));
  }, [query.get('token')]);

  const get = (ref: MutableRefObject<HTMLInputElement | undefined>): string => {
    if (ref && ref.current) return ref.current.value;
    return '';
  };

  const validate = () => {
    const pw = get(pwRef);
    const confirm = get(confirmRef);
    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
    if (pw === '') return t('errorNewPasswordNotDefined');
    if (confirm === '') return t('errorConfirmPasswordNotDefined');
    if (pw !== confirm) return t('errorPasswordNotMatch');
    if (!pattern.test(pw)) return t('accountErrorStrongPassword');
    return null;
  };

  const changePassword = async () => {
    const error = validate();

    if (error) {
      props.showSnackbar('error', error);
      return;
    }

    // Using a try/catch block for error handling
    try {
      const response = await resetPassword({
        variables: {
          newPassword: get(pwRef),
          token: query.get('token'),
        },
      });

      // Check if the response is defined
      if (!response) {
        throw new Error(t('errorUpdatePassword'));
      }

      // Handle GraphQL errors if present
      if ('graphQLErrors' in response) {
        return;
      }

      // Handle general errors in the response
      if (response.errors?.[0]?.message || !response.data?.resetPassword) {
        const errorMessage = response.errors?.[0]?.message || t('errorUpdatePassword');
        props.showSnackbar('error', errorMessage);
      } else {
        // Success case
        props.showSnackbar('success', t('passwordChanged'));
        navigate('/');
      }
    } catch (err) {
      if (err.message.includes('Recovery was already used!')) {
        props.showSnackbar('error', t('passwordResetTokenAlreadyUsed'));
      } else {
        console.error('Error during password reset:', err);
        props.showSnackbar('error', t('errorUpdatePassword'));
      }
    }
  };
  const check = (t: { charCode: number }) => t.charCode === 13 && changePassword();

  return (
    <DefaultBackground>
      <div className={`hidden ${props.classes.headerBrand}`}>
        <span>
          <img
            src={
              props.themeSelected === 'dark'
                ? props.tenantInfo?.resources?.logo_dark?.url || GlartekDark
                : props.tenantInfo?.resources?.logo_light?.url || GlartekLight
            }
            height={56}
            width={'auto'}
          />
        </span>
      </div>
      <Box boxShadow={6}>
        <Card
          enzyme-attr='component-login-card'
          className={`card-body`}
          onKeyDown={check}
          classes={{ root: props.classes.loginWrapper }}
        >
          <FormGroup classes={{ root: props.classes.formGroup }}>
            <div className={props.classes.label}>{t('newPassword')}</div>
            <OutlinedInput
              disabled={saving}
              classes={{ root: props.classes.inputs }}
              inputRef={pwRef}
              className='form-control'
              type={seePass ? 'text' : 'password'}
              id={'inputNewPassword'}
              autoComplete='off'
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton onClick={() => setSeePass(!seePass)} edge='end' size='large'>
                    {seePass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormGroup>
          <FormGroup classes={{ root: props.classes.formGroup }}>
            <div className={props.classes.label}>{t('confirmNewPassword')}</div>
            <OutlinedInput
              disabled={saving}
              classes={{ root: props.classes.inputs }}
              inputRef={confirmRef}
              className='form-control'
              type={seePassConfirm ? 'text' : 'password'}
              id={'inputConfirmNewPassword'}
              autoComplete='off'
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton onClick={() => setSeePassConfirm(!seePassConfirm)} edge='end' size='large'>
                    {seePassConfirm ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormGroup>
          <Box display='flex' style={{ gap: '24px', justifyContent: 'center' }}>
            <DefaultButton
              data-testid={'buttonCancelResetPassword'}
              enzyme-attr='a-login'
              label={t('cancel')}
              variant='discard'
              onClick={() => {
                setValid(false);
                navigate('/');
              }}
            />
            <DefaultButton
              loader={saving}
              data-testid={'buttonResetPassword'}
              enzyme-attr='a-login'
              label={t('resetPassword')}
              variant='default'
              onClick={changePassword}
            />
          </Box>
        </Card>
      </Box>
    </DefaultBackground>
  );
};

const connector = typedConnect(
  (state) => ({
    defaultRoute: state.accountReducer.defaultRoute,
    themeSelected: state.loginReducer.theme,
    tenantInfo: state.authenticationReducer.tenantInfo,
  }),
  { showSnackbar },
);

export const render = {
  reset: {
    default: compose(withBlankPage, withTranslation('translation'), withStyles(styles), connector)(ResetPassword),
  },
};

export default render;
