import { getErrorObject } from 'src/utils/funcs';
import * as queries from 'src/modules/settings/settings.queries';
import { APOLLO_CLIENT } from 'config/apollo.config';
import { Dispatch } from 'redux';
import { FetchResult } from '@apollo/client';
import { Palette } from '@mui/material/styles';
import { Tenant, UploadFileInfo } from 'src/gql/graphql';
import axios from 'axios';

export default (): void => null;

export const updateTimeZone =
  (_id: string, timeZone: string) =>
  async (dispatch: Dispatch): Promise<string | { graphQLErrors: string }> => {
    try {
      const response: FetchResult<{ updateTenantTimeZone: string }> = await APOLLO_CLIENT.mutate({
        variables: {
          _id,
          timeZone,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_TIME_ZONE,
      });

      return response.data.updateTenantTimeZone;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error as { graphQLErrors: string };
    }
  };

export const updatePalette = async (
  _id: string,
  paletteLight: Palette,
  paletteDark: Palette,
): Promise<Tenant | { graphQLErrors: string }> => {
  try {
    const response: FetchResult<{ updateTenantPalette: Tenant }> = await APOLLO_CLIENT.mutate({
      variables: {
        _id,
        paletteLight,
        paletteDark,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_UPDATE_PALETTE,
    });

    return response.data.updateTenantPalette;
  } catch (error) {
    return error as { graphQLErrors: string };
  }
};

export const getUploadLogoLightFileRequest =
  (name: string, size: number) =>
  async (dispatch: Dispatch): Promise<UploadFileInfo> => {
    try {
      const response: FetchResult<{ getUploadLogoLightFileRequest: UploadFileInfo }> = await APOLLO_CLIENT.mutate({
        variables: {
          name,
          size,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_GET_UPLOAD_LOGO_LIGHT,
      });

      return response.data.getUploadLogoLightFileRequest;
    } catch (e) {
      const obj = getErrorObject(e, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return null;
    }
  };

export const getUploadLogoDarkFileRequest =
  (name: string, size: number) =>
  async (dispatch: Dispatch): Promise<UploadFileInfo> => {
    try {
      const response: FetchResult<{ getUploadLogoDarkFileRequest: UploadFileInfo }> = await APOLLO_CLIENT.mutate({
        variables: {
          name,
          size,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_GET_UPLOAD_LOGO_DARK,
      });

      return response.data.getUploadLogoDarkFileRequest;
    } catch (e) {
      const obj = getErrorObject(e, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return null;
    }
  };

export const addLogoFile = (data: File, fData: any) => async (): Promise<void> => {
  try {
    const formData = new FormData();
    for (const key in fData.formData) {
      formData.append(key, fData.formData[`${key}`]);
    }
    formData.append('File', data);
    await axios.post(fData.url, formData);
    return;
  } catch (error) {
    return error;
  }
};
