import { Theme } from '@mui/material/styles';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) => {
  return createStyles({
    dialog: {
      width: '400px',
    },
    title: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 500,
      alignSelf: 'center',
      marginTop: '16px',
    },
    footer: {
      alignSelf: 'center',
      padding: '24px',
    },
    subTitle: {
      color: theme.palette.text.secondary,
      fontSize: '16px',
      fontFamily: 'Open Sans',
      alignSelf: 'center',
      margin: '8px 24px 16px',
      textAlign: 'center',
    },
  });
};
