import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';

const ApprovalsRefresh = () => lazy(() => import('src/modules/approvals/approvals'));

let Approvals = ApprovalsRefresh();

export const render = {
  default: ({ ...props }) => {
    if (Approvals?._result?.type === 'error') {
      Approvals = ApprovalsRefresh();
    }

    return (
      <Suspense fallback={<LazyLoadingContent />}>
        <Approvals {...props} />
      </Suspense>
    );
  },
};

export default render;
