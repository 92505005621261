import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    closeIcon: {
      marginLeft: 'auto',
      marginRight: '10px',
      marginTop: '10px',
      cursor: 'pointer',
    },
    stepper: {
      backgroundColor: theme.palette.background.default,
      padding: 0,
    },
    tabShow: {
      height: 'calc(100% - 70px)',
      display: 'block',
    },
    tabHidden: {
      display: 'none',
    },
    titleContainer: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '20px',
      marginTop: '30px',
    },
    modalFieldTitle: {
      fontFamily: 'Open Sans',
      fontWeight: 600,
    },
    divModalGeneralFieldsTitle: {
      width: '370px',
    },
    divModalGeneralFields: {
      border: `1px solid ${theme.palette.text.secondary}`,
      borderRadius: '4px',
      height: 'calc(100% - 60px)',
      overflow: 'auto',
    },
    divAttr: {
      display: 'flex',
      paddingLeft: '40px',
      marginBottom: '15px',
      fontFamily: 'Open Sans',
    },
    checkboxSelected: {
      marginRight: '20px',
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
    checkboxBlank: {
      marginRight: '20px',
      cursor: 'pointer',
      color: theme.palette.text.secondary,
    },
    divBtns: {
      width: '100%',
      display: 'flex',
      justifyContent: 'right',
      '& > div:nth-child(2)': {
        marginRight: '0',
      },
    },
    divAllTasks: {
      width: '330px',
      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
      overflow: 'auto',
    },
    tasksTitle: {
      fontFamily: 'Open Sans',
      fontWeight: 600,
    },
    divTaskInputs: {
      boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.25)',
      width: 'calc(100% - 360px)',
      marginLeft: '30px',
    },
    taskInputsTitle: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      color: theme.palette.primary.main,
      marginTop: '15px',
      marginLeft: '25px',
    },
    divExecGroupHeader: {
      borderBottom: `1px solid ${theme.palette.text.disabled}`,
      backgroundColor: theme.palette.text.disabled,
      height: '30px',
      display: 'flex',
      alignItems: 'center',
    },
    spanExecGroupName: {
      fontFamily: 'Poppins',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    divExecGroupOnlyOne: {
      marginLeft: '25px',
      marginRight: '25px',
    },
    divExecGroup: {
      borderRadius: '3px',
      border: `1px solid ${theme.palette.text.disabled}`,
      marginBottom: '15px',
      marginLeft: '25px',
      marginRight: '25px',
    },
    taskIndexSpan: {
      color: theme.palette.text.disabled,
      fontSize: '12px',
      fontFamily: 'Poppins',
    },
    taskName: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
    },
    listItemTaskSelected: {
      marginLeft: '5px',
      marginRight: '5px',
      height: '32px',
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.main,
      color: `${theme.palette.common.white} !important`,
      borderRadius: '2px',
      fontFamily: 'Poppins',
      marginTop: '5px',
      cursor: 'pointer',
      paddingLeft: '5px',
      display: 'flex',
      alignItems: 'center',
    },
    listItemTask: {
      marginLeft: '5px',
      marginRight: '5px',
      height: '32px',
      border: `1px solid ${theme.palette.text.disabled}`,
      borderRadius: '2px',
      fontFamily: 'Poppins',
      marginTop: '5px',
      cursor: 'pointer',
      paddingLeft: '5px',
      display: 'flex',
      alignItems: 'center',
    },
    iconsTasksLeft: {
      fontSize: '19px',
      color: theme.palette.text.secondary,
    },
    numberCircle: {
      borderRadius: '50%',
      minWidth: '20px',
      height: '20px',
      /* padding: "8px", */
      background: theme.palette.success.main,
      color: theme.palette.common.white,
      textAlign: 'center',
      fontSize: '12px',
      fontFamily: 'Open Sans',
      fontWeight: 700,
      display: 'flex',
      marginRight: '10px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputHistory: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '16px',
    },
    allTasksContainer: {
      display: 'inline-flex',
      width: '100%',
      height: 'inherit',
      marginTop: '20px',
      paddingBottom: '20px',
    },
    allTasksChild: {
      display: 'inline-flex',
      marginTop: '10px',
      marginLeft: '15px',
      width: 'calc(100% - 30px)',
      height: '22px',
    },
    inputsContainer: {
      display: 'inline-flex',
      width: '100%',
      height: 'inherit',
      marginTop: '20px',
      paddingBottom: '20px',
    },
    modalContainer: {
      width: '1150px',
      height: '725px',
      paddingLeft: '100px',
      paddingRight: '30px',
      paddingBottom: '20px',
    },
  });
