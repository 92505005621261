import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';
const VariantComparison = lazy(() => import('./variant-comparison'));

export default function () {
  return (
    <Suspense fallback={<LazyLoadingContent />}>
      <VariantComparison />
    </Suspense>
  );
}
