import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    '@global': {
      '.MuiSelect-icon': {
        display: 'none',
      },
    },
    topBar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      gap: 16,
    },
    widgetHeaderBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    widgetHeader: {
      fontWeight: 'bold',
      fontSize: 20,
    },
    widgetDescription: {
      color: theme.palette.grey.main,
      fontSize: 14,
    },
    inputLabel: {
      color: theme.palette.text.primary,
      marginBottom: 8,
      width: '100%',
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
      '& .MuiFormLabel-asterisk': {
        color: theme.palette.error.main,
      },
    },
    bold: {
      fontWeight: 450,
    },
    widgetEditContainer: {
      margin: 40,
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      overflowY: 'auto',
      overflowX: 'hidden',
      height: '100%',
    },
    axisBackground: {
      backgroundColor: theme.palette.background.background1,
      padding: 8,
    },
    horizontalBox: {
      display: 'flex',
      width: '100%',
      gap: 32,
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    inputBox: {
      width: '100%',
      flex: 1,
    },
    hint: {
      color: theme.palette.grey.main,
    },
    noMargin: {
      marginBottom: '0 !important',
      height: '40px !important',
    },
    removeStroke: {
      borderLeft: 'unset',
    },
    selectWidgetInfo: {
      height: '40px',
      borderRadius: '5px',
      width: '100%',
    },
    borderDisabled: {
      border: `1px solid ${theme.palette.text.disabled} !important`,
    },
    selectInputInput: {
      height: '40px',
      borderRadius: '4px !important',
      position: 'relative',
      border: `1px solid ${theme.palette.grey.light}`,
      color: theme.palette.text.primary,
      fontSize: '14px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.background.default,
      padding: '8px',
      '&:not(.Mui-disabled):hover': {
        border: `1px solid ${theme.palette.primary.main} !important`,
      },
    },
    multipleSelect: {
      background: theme.palette.background.default,
      boxShadow: 'none !important',
      color: `${theme.palette.text.primary} !important`,
      transition: 'all 0.3s, height 1ms',
      height: '40px',
      width: '100%',
      alignItems: 'flex-start',
      display: 'flex',
      cursor: 'pointer',
      maxWidth: '100%',
      border: `1px solid ${theme.palette.grey.light}`,
      borderRadius: '5px',
      overflow: 'hidden',
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.text.primary,
      },
      '&:focus': {
        color: theme.palette.common.white,
        border: '1px solid',
      },
      padding: 8,
    },
    disabled: {
      cursor: 'unset !important',
    },
    adornment: {
      right: 8,
      top: 8,
      position: 'absolute',
    },
    showPercentage: {
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${theme.palette.grey.light}`,
      borderWidth: '1px 0 1px 0',
      padding: '16px 0',
    },
    halfWidth: {
      minWidth: '50%',
    },
    placeholder: {
      color: theme.palette.text.primary,
    },
    chip: {
      marginRight: 4,
      marginBottom: 4,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    decimalDigitsInfoIcon: {
      fontSize: '16px',
      marginLeft: '5px',
    },
    decimalDigitsInfoSpan: {
      display: 'flex',
      alignItems: 'center',
      width: '125px',
    },
    multipleSelectRowContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      columnGap: '16px',
    },
    multipleSelectBox: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      width: '100%',
    },
    multipleSelectBoxMaxWidth: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      maxWidth: '100%',
    },
    editWidgetButtons: {
      justifyContent: 'flex-end',
      display: 'flex',
      marginRight: 40,
      marginTop: 'auto',
      gap: 8,
    },
    responseByTimeLine: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: 16,
      width: '100%',
    },
    responseByTimeLineTimeRange: {
      width: '50%',
    },
    responseByTimeLineGroupBy: {
      width: '50%',
    },
    infoPopoverContainer: {
      borderRadius: '15px',
      padding: '2px 4px',
    },
    infoDivStyle: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    infoText: {
      fontSize: '12px',
      fontFamily: 'Open Sans',
    },
    infoCloseIcon: {
      fontSize: '14px',
      cursor: 'pointer',
    },
    decimalDigitsInput: {
      padding: '8px',
      height: '22px',
      background: theme.palette.background.default,
    },
    minMaxValueInput: {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.grey.light}`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
      },
      '&:has(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
    divContainer: {
      width: '100%',
    },
    relative: {
      marginRight: 8,
      position: 'relative',
    },
    expandIcon: {
      position: 'absolute',
      right: 0,
      top: 8,
    },
    expandIconActive: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    expandIconDisabled: {
      '&:hover': {
        cursor: 'default',
      },
    },
    popoverContainer: {
      maxHeight: '260px',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    listItem: {
      fontFamily: 'Open sans',
      display: 'flex',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
      height: '36px',
      paddingLeft: 0,
    },
    itemText: {
      margin: 0,
      '& >span': {
        marginLeft: 8,
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontFamily: 'Open sans',
        wordBreak: 'break-all',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        maxWidth: '100%',
      },
    },
    selectedValuesCounter: {
      color: '#0078FE',
      position: 'absolute',
      right: 8,
      top: 8,
    },
    placehoderSelectInformation: {
      color: theme.palette.text.secondary,
    },
  });
