import gql from 'graphql-tag';

export const ROLE_TO_TREE = gql`
  fragment roleToTree on Role {
    __typename
    name
    protected
    _id
  }
`;

export const ROLE = gql`
  fragment role on Role {
    __typename
    name
    protected
    description
    clearances {
      bundle {
        name
        groups {
          name
          _id
        }
        _id
      }
      clearance
      level
    }
    _id
    deleted
  }
`;

export const PERMISSION = gql`
  fragment permission on Permission {
    __typename
    parentType
    fieldName
    roleType
    role
    access
    _id
    deleted
  }
`;
