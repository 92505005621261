import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    customRangeItemContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '12px',
      marginBottom: '16px',
    },
    customRangeContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '8px',
    },
    minMaxInput: {
      padding: '8px',
      height: '22px',
      maxWidth: '175px',
    },
    errorInput: {
      borderColor: theme.palette.error.main,
      '&:hover': {
        borderColor: theme.palette.error.main,
      },
    },
    deleteCustomRange: {
      cursor: 'pointer',
      alignSelf: 'center',
    },
    deleteCustomRangeError: {
      cursor: 'pointer',
      alignSelf: 'flex-start',
      marginTop: '6px',
    },
    divBackgroundColor: {
      width: '36px',
      height: '36px',
      borderRadius: '5px',
      cursor: 'pointer',
    },
    colorPicker: {
      width: 'fit-content',
    },
    errorText: {
      fontSize: '12px',
      fontFamily: 'Open Sans',
      color: theme.palette.error.main,
    },
    numberInput: {
      '& input[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  });
