import { Dialog, DialogTitle, Typography, Theme } from '@mui/material';
import { Close } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap } from '@mui/styles/withStyles';

interface Size {
  width?: string;
  maxWidth?: string;
  minWidth?: string;
  height?: string;
}

interface ModalProps {
  children: any;
  zIndex?: number;
  size?: Size;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  classes?: any;
  subtitle?: string;
  withoutClose?: boolean;
}

const Modal = (props: ModalProps) => {
  const { children, zIndex, size, isOpen, onClose, title, subtitle, withoutClose } = props;
  const useStyles = makeStyles((theme: Theme) => ({
    children: {
      width: size ? size.width : 'auto',
      height: size ? size.height : 'auto',
      maxWidth: size?.maxWidth ? size.maxWidth : 'unset',
      minWidth: size?.minWidth ? size.minWidth : 'unset',
      minHeight: '215px',
      maxHeight: 'unset',
      position: 'relative',
      overflow: ' hidden',
      zIndex: zIndex,
      fontSize: 14,
    },
    header: {
      lineHeight: '40px',
      fontSize: '1.09375rem',
      fontWeight: 500,
      height: '70px',
      padding: '16px 24px',
      display: 'flex',
      // TODO: Ricardo
      // width: '100%',
      // display: 'flex',
      // justifyContent: 'center',
      // borderBottom: '1px solid ',
      '& .MuiTypography-root': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    },
    headerWithoutClose: {
      lineHeight: '40px',
      fontSize: '1.09375rem',
      fontWeight: 500,
      height: '70px',
      padding: '24px 24px 16px 24px',
      // TODO: Ricardo
      // width: '100%',
      // display: 'flex',
      // justifyContent: 'center',
      // borderBottom: '1px solid ',
      '& .MuiTypography-root': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      },
    },
    close: {
      float: 'right',
      height: '100%',
      cursor: 'pointer',
      marginLeft: 'auto',
      marginRight: '0px',
    },
    // modalArea: {
    //   margin: '24px 24px 16px 24px',
    //   display: 'flex',
    //   flexDirection: 'column',
    //   alignItems: 'center'
    // },
    modalBody: {
      borderRadius: '0 0 5px 5px',
      height: 'calc(100% - 70px)',
      // TODO: Ricardo
      // width: '100%',
      '& .MuiDialogContent-root': {
        height: '100%',
        padding: '8px 24px 16px',
      },
    },
    dialogHeader: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.15px',
      color: theme.palette.text.primary,
      textAlign: 'center',
    },
    dialogSubHeader: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '11px',
      lineHeight: '15px',
      color: theme.palette.text.primary,
      opacity: '0.42',
    },
  }));
  const classes: ClassNameMap<string> = useStyles();

  return (
    <>
      <Dialog
        open={isOpen}
        style={{ zIndex: 1000 }}
        /* onEscapeKeyDown={(): void => onClose()} */
        onClose={(): void => onClose()}
        fullWidth={false}
        classes={{ paper: classes.children }}
      >
        {/* <div className={classes.modalArea}> */}
        <DialogTitle classes={{ root: !withoutClose ? classes.header : classes.headerWithoutClose }}>
          <Typography className={classes.dialogHeader}>{title}</Typography>
          {subtitle ? <Typography className={classes.dialogSubHeader}>{subtitle}</Typography> : null}
          {!withoutClose ? (
            <Close
              id={'buttonCloseDialog'}
              data-testid={'buttonCloseDialog'}
              classes={{ root: classes.close }}
              onClick={(): void => onClose()}
              style={subtitle ? { position: 'relative', top: '-40px' } : null}
            />
          ) : undefined}
        </DialogTitle>
        <div className={classes.modalBody}>{Array.isArray(children) ? [...children] : children}</div>
        {/* </div> */}
      </Dialog>
    </>
  );
};

export default Modal;
