import { APOLLO_CLIENT } from 'config/apollo.config';
import * as mutations from '../rooms.queries';
import { CustomError } from 'src/interfaces/remote-assistance/custom-error';
import { getErrorObject } from 'src/utils/funcs';
import { Room } from 'src/interfaces/remote-assistance/room';
import { RoomUser } from 'src/interfaces/remote-assistance/types';

export interface InviteSMSResponse {
  number: string;
  status: string;
}

export interface InviteEmailResponse {
  email: string;
  status: string;
}

export interface InvitedUsersResponse {
  emails: string[];
  phone_numbers: string[];
}
// TODO: implement external data later
export const createNewRoom = async (): Promise<[Room | null, CustomError | null]> => {
  try {
    const response = await APOLLO_CLIENT.query({
      query: mutations.Q_CREATE_ROOM,
      fetchPolicy: 'no-cache',
    });

    const { createRoom } = response.data;
    return [createRoom as Room, null];
  } catch (error) {
    const errMsg = getErrorObject(error, '');
    return [null, { message: errMsg } as unknown as CustomError];
  }
};

export const getRoomUsers = async (room_id: string): Promise<[Array<RoomUser> | null, CustomError | null]> => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      mutation: mutations.M_GET_ROOM_USERS,
      fetchPolicy: 'no-cache',
      variables: {
        roomId: room_id,
      },
    });

    const { getRoomUsers } = response.data;
    return [getRoomUsers.users as [RoomUser], null];
  } catch (error) {
    const errMsg = getErrorObject(error, '');
    return [null, { message: errMsg } as unknown as CustomError];
  }
};

export const joinRoomByCode = async (code: string): Promise<[Room | null, CustomError | null]> => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      mutation: mutations.M_JOIN_ROOM_BY_CODE,
      fetchPolicy: 'no-cache',
      variables: {
        roomCode: code,
      },
    });

    const { joinRoomByCode } = response.data;
    return [joinRoomByCode as Room, null];
  } catch (error) {
    const errMsg = getErrorObject(error, '');
    return [null, { message: errMsg } as unknown as CustomError];
  }
};

export const joinRoomById = async (id: string): Promise<[Room | null, CustomError | null]> => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      mutation: mutations.M_JOIN_Room_BY_ID,
      fetchPolicy: 'no-cache',
      variables: {
        roomId: id,
      },
    });

    const { joinRoomById } = response.data;
    return [joinRoomById as Room, null];
  } catch (error) {
    const errMsg = getErrorObject(error, '');
    return [null, { message: errMsg } as unknown as CustomError];
  }
};

export const inviteUserBySMS = async (
  room_id: string,
  phone_number_list: [string],
): Promise<[Array<InviteSMSResponse> | null, CustomError | null]> => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      mutation: mutations.M_INVITE_TO_ROOM_BY_SMS,
      fetchPolicy: 'no-cache',
      variables: {
        room_id: room_id,
        phone_number_list: phone_number_list,
      },
    });
    const { inviteToRoomBySMS } = response.data;
    return [inviteToRoomBySMS as [InviteSMSResponse], null];
  } catch (error) {
    const errMsg = getErrorObject(error, '');
    return [null, { message: errMsg } as unknown as CustomError];
  }
};

export const inviteUserByEmail = async (
  room_id: string,
  email_list: [string],
): Promise<[Array<InviteEmailResponse> | null, CustomError | null]> => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      mutation: mutations.M_INVITE_TO_ROOM_BY_EMAIL,
      fetchPolicy: 'no-cache',
      variables: {
        room_id: room_id,
        email_list: email_list,
      },
    });
    const { inviteToRoomByEmail } = response.data;
    return [inviteToRoomByEmail as [InviteEmailResponse], null];
  } catch (error) {
    const errMsg = getErrorObject(error, '');
    return [null, { message: errMsg } as unknown as CustomError];
  }
};
