import { Card } from '@mui/material';
import { Equalizer, List, PieChart, ShowChart, Speed, TableChart, TextFields } from '@mui/icons-material';
import {
  Account,
  ElementTag,
  Input,
  IssueCatalog,
  LabelValue,
  WidgetDatasetRealtimeAggregation,
} from 'src/gql/graphql';
import { NexusGenEnums, NexusGenFieldTypes } from '@server/src/types';
import { CustomRange } from 'src/utils/components/custom-value-range-picker';
import { SortOrder, SortTypes } from 'src/utils/components/custom-sort-by/types';

export interface WidgetInfo {
  type?: string;
  _id?: string;
  name?: string;
  header: string;
  chartType: ChartTypeEnum;
  includeArchives?: boolean;
  showAsPercentage?: boolean;
  measurement?: MeasurementType;
  by?: IssueByEnum | ActionByEnum | InputByEnum;
  information?: InformationEnum;
  timeUnit?: NexusGenEnums['WidgetDatasetIssuesAverageTimeUnit'];
  timeSpan?: {
    startDate: any;
    endDate: any;
  };
  range?: {
    min: number;
    max: number;
  };
  axis?: {
    x: XAxis;
    y: YAxis;
  };
  filter?: {
    groupBy?: string;
    decimalDigits?: number;
    sites?: NexusGenFieldTypes['Site'][];
    elements?: NexusGenFieldTypes['Element'][];
    assignedAccounts?: Account[];
    labelValues?: LabelValue[];
    assignedLabelValues?: LabelValue[];
    catalogs?: IssueCatalog[];
    states?: FilterStateEnum[];
    statuses?: FilterStatusEnum[];
    tags?: ElementTag[];
    issueCatalog?: IssueCatalog;
    input?: Input;
    responseOptions?: string[];
    date?: any;
  };
  customFilters?: any;
  realtimeAggregation?: WidgetDatasetRealtimeAggregation;
  ranges?: CustomRange[];
  decimalDigits?: number;
  sort?: {
    type: SortTypes;
    order: SortOrder;
  };
}

interface IChartCardProps {
  Icon: JSX.Element;
  info: WidgetInfo;
}

export type MeasurementType =
  | 'count'
  | 'duration'
  | 'average'
  | 'planned'
  | 'last'
  | 'time'
  | 'lowest'
  | 'highest'
  | 'sum'
  | 'custom';
export type FilterStateEnum = 'wip' | 'notWip' | 'done' | 'canceled';
export type FilterStatusEnum = 'PENDING' | 'CANT_DO' | 'DOING' | 'SOLVED';
export type InformationEnum = 'issues' | 'actions' | 'realtime' | 'responses';
export type IssueByEnum = 'assignee' | 'assignedLabelValues' | 'labelValues' | 'element' | 'state' | 'catalog' | 'time';
export type ActionByEnum = 'assignee' | 'labelValues' | 'element' | 'status';
export type InputByEnum = 'response';
export type GroupByEnum = 'day' | 'week' | 'month' | 'year' | 'none';
export type ChartTypeEnum = 'bar' | 'gauge' | 'value' | 'pie' | 'table' | 'line';

export const axis = {
  issues: ['issuesCount', 'executionAverageTime', 'timeSpan'],
  assignees: ['actionsCount', 'issuesCount', 'issuesAverage', 'timeSpent'],
  timeSpanAbsolute: ['realtime'],
  timeSpanRelative: ['realtime'],
  sites: ['issuesCount'],
  responses: ['responsesCount'],
};

export type XAxis = keyof typeof axis;
export type YAxis =
  | 'issuesCount'
  | 'executionAverageTime'
  | 'timeSpan'
  | 'actionsCount'
  | 'issuesCount'
  | 'issuesAverage'
  | 'timeSpent'
  | 'realtime'
  | 'responsesCount';

export const byFilterMapping = {
  element: ['sites', 'elements'],
  catalog: ['catalogs'],
  assignee: ['assignedAccounts'],
  labelValues: ['labelValues'],
  assignedLabelValues: ['assignedLabelValues'],
  state: ['states'],
  status: ['statuses'],
}; /* satisfies {
  [P in IssueByEnum | ActionByEnum]?: (keyof WidgetInfo['filter'])[];
} */

export const timespanUnits = ['hour', 'day', 'week', 'month', 'year', 'none'];

export type TimespanUnit = (typeof timespanUnits)[number];

export type ChartPreset = ReturnType<typeof chartCardBuilder>;

const chartCardBuilder: (props: IChartCardProps) => React.FC<{
  classes: Record<string, any>;
  t: (word: string) => string;
  setEditInfo?: (data: WidgetInfo) => any;
}> =
  ({ info, Icon }) =>
  ({ classes, t, setEditInfo }) => (
    <Card
      data-testid={`chart-card-${info.header}`}
      classes={{ root: classes.chartCard }}
      onClick={() => setEditInfo?.(info)}
    >
      {Icon}
      <span className={classes.chartCardHeader}>{t(`chartCards.${info.header}.header`)}</span>
      <span className={classes.chartCardDescription}>{t(`chartCards.${info.header}.description`)}</span>
    </Card>
  );

export const PieChartIcon = <PieChart color={'primary'} fontSize={'large'} />;

export const BarChartIcon = <Equalizer htmlColor={'#2A9D8F'} fontSize={'large'} />;

export const GaugeChartIcon = <Speed htmlColor={'#06d6ad'} fontSize={'large'} />;

export const TableChartIcon = <TableChart htmlColor={'#7C18FB'} fontSize={'large'} />;

export const ListChartIcon = <List htmlColor={'#EF476F'} fontSize={'large'} />;

export const LineChartIcon = <ShowChart htmlColor={'#E76F51'} fontSize={'large'} />;

export const TextChartIcon = <TextFields htmlColor={'#0078FE'} fontSize={'large'} />;

export const IssuesByStateCard = chartCardBuilder({
  Icon: TextChartIcon,
  info: {
    chartType: 'value',
    measurement: 'count',
    header: 'issuesByState',
    information: 'issues',
    by: 'state',
  },
});

export const IssuesByMultipleStateCard = chartCardBuilder({
  Icon: PieChartIcon,
  info: {
    chartType: 'pie',
    measurement: 'count',
    header: 'issuesByMultipleStates',
    information: 'issues',
    by: 'state',
  },
});

export const IssuesByAssigneeEqualizerCard = chartCardBuilder({
  Icon: BarChartIcon,
  info: {
    chartType: 'bar',
    measurement: 'count',
    header: 'issuesByAssignee',
    information: 'issues',
    axis: {
      x: 'assignees',
      y: 'issuesCount',
    },
  },
});
export const MediumValueByResponseCard = chartCardBuilder({
  Icon: TextChartIcon,
  info: {
    chartType: 'value',
    measurement: 'average',
    header: 'responsesMediumValue',
    information: 'responses',
  },
});
export const ExecutionAverageTimeGaugeCard = chartCardBuilder({
  Icon: BarChartIcon,
  info: {
    chartType: 'bar',
    measurement: 'average',
    header: 'executionAverageTime',
    information: 'issues',
    axis: {
      x: 'issues',
      y: 'executionAverageTime',
    },
  },
});
export const IssuesByLabelCard = chartCardBuilder({
  Icon: TableChartIcon,
  info: {
    chartType: 'table',
    measurement: 'count',
    header: 'issuesByLabel',
    information: 'issues',
    by: 'labelValues',
  },
});

export const InstancesByTimeCard = chartCardBuilder({
  Icon: BarChartIcon,
  info: {
    chartType: 'bar',
    measurement: 'count',
    header: 'instancesByTime',
    information: 'issues',
    axis: {
      x: 'issues',
      y: 'timeSpan',
    },
  },
});

export const IssueInstancesComparisonCard = chartCardBuilder({
  Icon: BarChartIcon,
  info: {
    chartType: 'bar',
    header: 'issueInstancesComparison',
    measurement: 'count',
    information: 'issues',
    axis: {
      x: 'issues',
      y: 'issuesCount',
    },
  },
});

export const IssuesByAssigneeCard = chartCardBuilder({
  Icon: BarChartIcon,
  info: {
    chartType: 'bar',
    measurement: 'count',
    header: 'issuesByAssignee',
    information: 'issues',
    axis: {
      x: 'assignees',
      y: 'issuesCount',
    },
  },
});

export const ExecutionAverageTimeCard = chartCardBuilder({
  Icon: BarChartIcon,
  info: {
    chartType: 'bar',
    measurement: 'average',
    header: 'executionAverageTime',
    information: 'issues',
    axis: {
      x: 'issues',
      y: 'executionAverageTime',
    },
  },
});

export const IssuesFinishedTextCard = chartCardBuilder({
  Icon: TextChartIcon,
  info: {
    chartType: 'value',
    measurement: 'count',
    header: 'issuesFinished',
    by: 'state',
    information: 'issues',
    filter: {
      states: ['done'],
    },
  },
});

export const InstancesByIssueCard = chartCardBuilder({
  Icon: TextChartIcon,
  info: {
    chartType: 'value',
    measurement: 'count',
    header: 'instancesByIssue',
    information: 'issues',
    by: 'catalog',
  },
});

export const PlannedIssuesBySiteCard = chartCardBuilder({
  Icon: TextChartIcon,
  info: {
    chartType: 'value',
    measurement: 'planned',
    header: 'plannedIssuesBySite',
    information: 'issues',
  },
});

export const PlannedIssuesByLabelCard = chartCardBuilder({
  Icon: TextChartIcon,
  info: {
    chartType: 'value',
    measurement: 'planned',
    header: 'plannedIssuesByLabel',
    information: 'issues',
    by: 'labelValues',
  },
});

export const ValuesByTimeCard = chartCardBuilder({
  Icon: LineChartIcon,
  info: {
    chartType: 'line',
    header: 'responsesByTime',
    information: 'responses',
    by: 'time',
  },
});

export const ActionsByStatusCard = chartCardBuilder({
  Icon: TextChartIcon,
  info: {
    chartType: 'value',
    measurement: 'count',
    header: 'actionsByStatus',
    information: 'actions',
    by: 'status',
  },
});

export const ActionsByMultipleStatusesCard = chartCardBuilder({
  Icon: PieChartIcon,
  info: {
    chartType: 'pie',
    measurement: 'count',
    header: 'actionsByMultipleStatuses',
    information: 'actions',
    by: 'status',
  },
});

export const ActionsBySitesCard = chartCardBuilder({
  Icon: PieChartIcon,
  info: {
    chartType: 'pie',
    measurement: 'count',
    header: 'actionsBySites',
    information: 'actions',
    by: 'element',
  },
});

export const ActionsByAssigneeCard = chartCardBuilder({
  Icon: BarChartIcon,
  info: {
    chartType: 'bar',
    measurement: 'count',
    header: 'actionsByAssignee',
    information: 'actions',
    axis: {
      x: 'assignees',
      y: 'actionsCount',
    },
  },
});

export const TotalActionsTextCard = chartCardBuilder({
  Icon: TextChartIcon,
  info: {
    chartType: 'value',
    measurement: 'count',
    header: 'totalActions',
    information: 'actions',
    by: 'element',
  },
});

export const ActionsByLabelCard = chartCardBuilder({
  Icon: TableChartIcon,
  info: {
    chartType: 'table',
    measurement: 'count',
    header: 'actionsByLabel',
    information: 'actions',
    by: 'labelValues',
  },
});

/*export const TotalLabelsCard = chartCardBuilder({
  Icon: TextChartIcon,
  info: {
    chartType: 'value',
    measurement: 'count',
    header: 'totalLabelsSite',
  },
});*/

export const TimeSpentAssigneeCard = chartCardBuilder({
  Icon: BarChartIcon,
  info: {
    chartType: 'bar',
    header: 'timeSpentByAssignee',
    axis: {
      x: 'assignees',
      y: 'timeSpent',
    },
  },
});

export const IssuesBySitesCard = chartCardBuilder({
  Icon: BarChartIcon,
  info: {
    chartType: 'bar',
    measurement: 'count',
    header: 'issuesBySites',
    axis: {
      x: 'sites',
      y: 'issuesCount',
    },
  },
});

export const TagByTimeCard = chartCardBuilder({
  Icon: LineChartIcon,
  info: {
    chartType: 'line',
    header: 'tagByTime',
    axis: {
      x: 'timeSpanAbsolute',
      y: 'realtime',
    },
  },
});

//custom
export const TagLastValueCard = chartCardBuilder({
  Icon: TextChartIcon,
  info: {
    chartType: 'value',
    measurement: 'count',
    header: 'tagLastValue',
    information: 'realtime',
  },
});

export const TextCard = chartCardBuilder({
  Icon: TextChartIcon,
  info: {
    chartType: 'value',
    header: 'value',
  },
});

export const TableCard = chartCardBuilder({
  Icon: TableChartIcon,
  info: {
    chartType: 'table',
    header: 'table',
  },
});

export const BarChartCard = chartCardBuilder({
  Icon: BarChartIcon,
  info: {
    chartType: 'bar',
    header: 'bar',
  },
});

export const GaugeCard = chartCardBuilder({
  Icon: GaugeChartIcon,
  info: {
    chartType: 'gauge',
    header: 'gauge',
  },
});

export const PieCard = chartCardBuilder({
  Icon: PieChartIcon,
  info: {
    chartType: 'pie',
    header: 'pie',
  },
});

export const LineCard = chartCardBuilder({
  Icon: LineChartIcon,
  info: {
    chartType: 'line',
    header: 'line',
  },
});

export const LastValueByResponseCard = chartCardBuilder({
  Icon: TextChartIcon,
  info: {
    chartType: 'value',
    header: 'responsesLastValue',
    information: 'responses',
    measurement: 'last',
  },
});

export const TotalValueResponsesCard = chartCardBuilder({
  Icon: BarChartIcon,
  info: {
    chartType: 'bar',
    header: 'responsesTotalValues',
    information: 'responses',
    measurement: 'count',
    axis: {
      x: 'responses',
      y: 'responsesCount',
    },
  },
});

export const ResponsesByRatingCard = chartCardBuilder({
  Icon: GaugeChartIcon,
  info: {
    chartType: 'gauge',
    header: 'responsesByRating',
    information: 'responses',
    measurement: 'custom',
  },
});

export const TotalResponsesCard = chartCardBuilder({
  Icon: PieChartIcon,
  info: {
    chartType: 'pie',
    measurement: 'count',
    header: 'responsesTotal',
    information: 'responses',
    axis: {
      x: 'responses',
      y: 'responsesCount',
    },
  },
});
