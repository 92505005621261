import gql from 'graphql-tag';
import { INPUT } from 'src/utils/fragments/input';
import {
  PROFILE,
  PROFILE_TEMPLATE,
  PROFILE_TEMPLATE_FOLDER,
  PROFILE_TEMPLATE_TO_TREE,
  PROFILE_TO_TREE,
  PROFILE_FOLDER,
} from 'src/utils/fragments/profile';
import { SITE_TO_TREE } from 'src/utils/fragments/site';

export const Q_GET_PROFILES_ROOT = gql`
  query GetProfilesRoot {
    profiles(where: { parent_exists: false, folder_exists: false }, orderBy: name_ASC) {
      ...profileToTree
    }
  }
  ${PROFILE_TO_TREE}
`;

export const Q_GET_PROFILES = gql`
  query GetProfiles($filter: ProfileWhereInput) {
    profiles(where: $filter) {
      ...profile
    }
  }
  ${INPUT}
  ${PROFILE}
`;

export const Q_GET_PROFILE_WITH_DELETED = gql`
  query GetProfilesWithDeleted($filter: ProfileWhereUniqueInput!) {
    profileWithDeleted(where: $filter) {
      ...profile
    }
  }
  ${INPUT}
  ${PROFILE}
`;

export const Q_GET_PROFILES_SEARCH_PAGINATED = gql`
  query GetProfilesSearchPaginated($filter: ProfileWhereInput, $limit: Int, $skip: Int) {
    profiles(skip: $skip, limit: $limit, where: $filter) {
      __typename
      _id
      name
      description
      parentsTreeIds
    }
  }
`;

export const Q_GET_PROFILES_AND_FOLDERS = gql`
  query GetProfilesAndFolders(
    $skip: Int
    $limit: Int
    $templateWhere: ProfileWhereInput
    $templateFolderWhere: ProfileFolderWhereInput
    $orderByTemplate: [ProfileOrderByInput!]!
    $orderByTemplateFolder: [ProfileFolderOrderByInput!]!
    $tenant: ID
  ) {
    profilesAndFolders(
      skip: $skip
      limit: $limit
      templateWhere: $templateWhere
      templateFolderWhere: $templateFolderWhere
      templateOrderBy: $orderByTemplate
      templateFolderOrderBy: $orderByTemplateFolder
      tenant: $tenant
    ) {
      ... on Profile {
        ...profile
      }
      ... on ProfileFolder {
        ...profileFolder
      }
    }
  }
  ${INPUT}
  ${PROFILE}
  ${PROFILE_FOLDER}
`;

export const Q_GET_PROFILE_TEMPLATES_AND_FOLDERS = gql`
  query GetProfileTemplatesAndFolders(
    $skip: Int
    $limit: Int
    $templateWhere: ProfileTemplateWhereInput
    $templateFolderWhere: ProfileTemplateFolderWhereInput
    $orderByTemplate: [ProfileTemplateOrderByInput!]!
    $orderByTemplateFolder: [ProfileTemplateFolderOrderByInput!]!
    $tenant: ID
  ) {
    profileTemplatesAndFolders(
      skip: $skip
      limit: $limit
      templateWhere: $templateWhere
      templateFolderWhere: $templateFolderWhere
      templateOrderBy: $orderByTemplate
      templateFolderOrderBy: $orderByTemplateFolder
      tenant: $tenant
    ) {
      ... on ProfileTemplate {
        ...profileTemplate
      }
      ... on ProfileTemplateFolder {
        ...profileTemplateFolder
      }
    }
  }
  ${INPUT}
  ${PROFILE_TEMPLATE}
  ${PROFILE_TEMPLATE_FOLDER}
`;

export const M_DUPLICATE_PROFILE = gql`
  mutation DuplicateProfile($_id: ID!, $copyChildren: Boolean!) {
    duplicateProfile(_id: $_id, copyChildren: $copyChildren) {
      ...profile
    }
  }
  ${INPUT}
  ${PROFILE}
`;

export const M_DUPLICATE_PROFILE_TEMPLATE = gql`
  mutation DuplicateProfileTemplate($_id: ID!, $copyChildren: Boolean!) {
    duplicateProfileTemplate(_id: $_id, copyChildren: $copyChildren) {
      ...profileTemplate
    }
  }
  ${INPUT}
  ${PROFILE_TEMPLATE}
`;

export const Q_GET_PROFILES_BY_FATHER_ID = gql`
  query GetProfilesByFatherId($parentId: ID, $folderId: ID) {
    profiles(where: { parent_eq: $parentId, folder_eq: $folderId }, orderBy: name_ASC) {
      ...profileToTree
    }
  }
  ${PROFILE_TO_TREE}
`;

export const M_PROFILE_SEARCH = gql`
  query ProfileSearch($searchString: String) {
    profiles(where: { name_contains: $searchString }) {
      ...profileToTree
    }
  }
  ${PROFILE_TO_TREE}
`;

export const M_CREATE_PROFILE = gql`
  mutation CreateProfile(
    $name: String!
    $description: String
    $parentId: ID
    $templateId: ID!
    $inputs: [InputCreateInput!]
    $labelValues: [ID]
    $folder: ID
  ) {
    createProfile(
      data: {
        name: $name
        description: $description
        parent: $parentId
        template: $templateId
        inputs: $inputs
        labelValues: $labelValues
        folder: $folder
      }
    ) {
      ...profile
    }
  }
  ${INPUT}
  ${PROFILE}
`;

export const M_CREATE_PROFILE_ROOT = gql`
  mutation CreateProfileRoot(
    $name: String!
    $description: String
    $templateId: ID!
    $inputs: [InputCreateInput!]
    $labelValues: [ID]
    $folder: ID
  ) {
    createProfile(
      data: {
        name: $name
        description: $description
        template: $templateId
        inputs: $inputs
        labelValues: $labelValues
        folder: $folder
      }
    ) {
      ...profile
    }
  }
  ${INPUT}
  ${PROFILE}
`;

export const M_DELETE_PROFILE = gql`
  mutation DeleteProfile($id: ID!) {
    deleteOneProfile(where: { _id: $id }) {
      ...profileToTree
    }
  }
  ${PROFILE_TO_TREE}
`;

export const M_UPDATE_PROFILE = gql`
  mutation UpdateProfile(
    $name: String
    $description: String
    $parentId: ID
    $id: ID!
    $inputs: [InputUpdateInput!]
    $templateId: ID
    $labelValues: [ID]
    $folder: ID
  ) {
    updateProfile(
      data: {
        name: $name
        description: $description
        parent: $parentId
        inputs: $inputs
        template: $templateId
        labelValues: $labelValues
        folder: $folder
      }
      where: { _id: $id }
    ) {
      ...profile
    }
  }
  ${INPUT}
  ${PROFILE}
`;

export const M_UPDATE_PROFILE_ROOT = gql`
  mutation UpdateProfileRoot(
    $name: String
    $description: String
    $id: ID!
    $inputs: [InputUpdateInput!]
    $templateId: ID
    $labelValues: [ID]
    $folder: ID
    $parentId: ID
  ) {
    updateProfile(
      data: {
        name: $name
        description: $description
        inputs: $inputs
        template: $templateId
        labelValues: $labelValues
        folder: $folder
        parent: $parentId
      }
      where: { _id: $id }
    ) {
      ...profile
    }
  }
  ${INPUT}
  ${PROFILE}
`;

export const M_CREATE_PROFILE_TEMPLATE = gql`
  mutation CreateProfileTemplate($title: String!, $folder: ID!, $inputs: [InputCreateInput!]) {
    createProfileTemplate(data: { title: $title, folder: $folder, frame: { inputs: $inputs } }) {
      ...profileTemplate
    }
  }
  ${INPUT}
  ${PROFILE_TEMPLATE}
`;

export const M_DELETE_PROFILE_TEMPLATE = gql`
  mutation DeleteProfileTemplate($id: ID!) {
    deleteOneProfileTemplate(where: { _id: $id }) {
      ...profileTemplate
    }
  }
  ${INPUT}
  ${PROFILE_TEMPLATE}
`;

export const M_UPDATE_PROFILE_TEMPLATE = gql`
  mutation UpdateProfileTemplate($data: ProfileTemplateUpdateInput!, $where: ProfileTemplateWhereUniqueInput!) {
    updateProfileTemplate(data: $data, where: $where) {
      ...profileTemplate
    }
  }
  ${INPUT}
  ${PROFILE_TEMPLATE}
`;

export const Q_GET_PROFILE_TEMPLATE_FOLDERS = gql`
  query GetProfileTemplateFolders($where: ProfileTemplateFolderWhereInput) {
    profileTemplateFolders(where: $where, orderBy: name_ASC) {
      ...profileTemplateFolder
    }
  }
  ${PROFILE_TEMPLATE_FOLDER}
`;

export const M_CREATE_PROFILE_TEMPLATE_FOLDER = gql`
  mutation CreateProfileTemplateFolder($name: String!, $parent: ID) {
    createProfileTemplateFolder(data: { name: $name, parent: $parent }) {
      ...profileTemplateFolder
    }
  }
  ${PROFILE_TEMPLATE_FOLDER}
`;

export const M_DELETE_PROFILE_TEMPLATE_FOLDER = gql`
  mutation DeleteProfileTemplateFolder($where: ProfileTemplateFolderWhereUniqueInput!) {
    deleteOneProfileTemplateFolder(where: $where) {
      ...profileTemplateFolder
    }
  }
  ${PROFILE_TEMPLATE_FOLDER}
`;

export const M_UPDATE_PROFILE_TEMPLATE_FOLDER = gql`
  mutation UpdateProfileTemplateFolder($name: String, $parent: ID, $_id: ID!) {
    updateProfileTemplateFolder(data: { name: $name, parent: $parent }, where: { _id: $_id }) {
      ...profileTemplateFolder
    }
  }
  ${PROFILE_TEMPLATE_FOLDER}
`;

export const Q_SEARCH = gql`
  query SearchProfileTemplates($searchString: String) {
    profileTemplateFolders(where: { name_contains: $searchString }) {
      ...profileTemplateFolder
    }
    profileTemplates(where: { folder: { name_contains: $searchString } }) {
      ...profileTemplateToTree
    }
  }
  ${PROFILE_TEMPLATE_TO_TREE}
  ${PROFILE_TEMPLATE_FOLDER}
`;

export const Q_GET_SITES_ROOT = gql`
  query GetSitesroot($skip: Int, $limit: Int, $filter: SiteWhereInput!) {
    sitesV2(skip: $skip, limit: $limit, where: $filter) {
      ...siteToTree
    }
  }
  ${SITE_TO_TREE}
`;

export const Q_GET_PROFILE_TEMPLATE_TO_TREE = gql`
  query GetProfileTemplateTree($filter: ProfileTemplateWhereInput) {
    profileTemplates(where: $filter, orderBy: title_ASC) {
      ...profileTemplateToTree
    }
  }
  ${PROFILE_TEMPLATE_TO_TREE}
`;

export const Q_GET_PROFILE_TEMPLATE = gql`
  query GetProfileTemplate($filter: ProfileTemplateWhereInput) {
    profileTemplates(where: $filter) {
      ...profileTemplate
    }
  }
  ${INPUT}
  ${PROFILE_TEMPLATE}
`;

export const Q_GET_PROFILE_TO_TREE = gql`
  query GetProfileTree($filter: ProfileWhereInput) {
    profiles(where: $filter, orderBy: name_ASC) {
      ...profileToTree
    }
  }
  ${PROFILE_TO_TREE}
`;

export const Q_GET_PROFILE_FOLDERS = gql`
  query GetProfileFolders($where: ProfileFolderWhereInput) {
    profileFolders(where: $where, orderBy: name_ASC) {
      ...profileFolder
    }
  }
  ${PROFILE_FOLDER}
`;

export const Q_GET_PROFILE_FOLDER = gql`
  query GetProfileFolder($filter: ProfileFolderWhereInput) {
    profileFolders(where: $filter) {
      ...profileFolder
    }
  }
  ${PROFILE_FOLDER}
`;

export const M_CREATE_PROFILE_FOLDER = gql`
  mutation CreateProfileFolder($name: String!, $parent: ID) {
    createProfileFolder(data: { name: $name, parent: $parent }) {
      ...profileFolder
    }
  }
  ${PROFILE_FOLDER}
`;

export const M_UPDATE_PROFILE_FOLDER = gql`
  mutation UpdateProfileFolder($name: String, $id: ID!) {
    updateProfileFolder(data: { name: $name }, where: { _id: $id }) {
      ...profileFolder
    }
  }
  ${PROFILE_FOLDER}
`;

export const M_DELETE_PROFILE_FOLDER = gql`
  mutation DeleteProfileFolder($where: ProfileFolderWhereUniqueInput!) {
    deleteOneProfileFolder(where: $where) {
      ...profileFolder
    }
  }
  ${PROFILE_FOLDER}
`;

export const M_MOVE_MANY_PROFILES = gql`
  mutation MoveManyProfiles($_ids: [ID!]!, $parentId: ID, $folderId: ID) {
    moveManyProfiles(_ids: $_ids, parentId: $parentId, folderId: $folderId) {
      ...profile
    }
  }
  ${INPUT}
  ${PROFILE}
`;

export const M_MOVE_MANY_PROFILE_FOLDERS = gql`
  mutation MoveManyProfileFolders($_ids: [ID!]!, $folderId: ID) {
    moveManyProfileFolders(_ids: $_ids, parentId: $folderId) {
      ...profileFolder
    }
  }
  ${PROFILE_FOLDER}
`;

export const M_UPDATE_MANY_PROFILE_TEMPLATES = gql`
  mutation UpdateManyProfileTemplates($where: ProfileTemplateWhereInput!, $update: ProfileTemplateUpdateInput!) {
    updateManyProfileTemplates(where: $where, update: $update) {
      _id
      title
    }
  }
`;
