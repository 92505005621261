const fixOrderKey = (orderKey: string): string => {
  const replacements: [string, string][] = [
    ['parentSite', 'parent_site'],
    ['parentElement', 'parent_element'],
    ['rootSite', 'root_site'],
    ['rootElement', 'root_element'],
    // Add more replacements as needed
  ];

  let result = orderKey;
  replacements.forEach(([search, replacement]) => {
    result = result.replace(search, replacement);
  });

  return result;
};

export default fixOrderKey;
