import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';
import { IssueExecutionContextProvider, issueExecutionContextInitialValue } from './context/issue.execution.context';

const IssueExecutionRefresh = () => lazy(() => import('src/modules/issue-execution/issue.execution'));

let IssueExecution = IssueExecutionRefresh();

export const render = {
  default: ({ ...props }) => {
    if (IssueExecution?._result?.type === 'error') {
      IssueExecution = IssueExecutionRefresh();
    }

    return (
      <Suspense fallback={<LazyLoadingContent />}>
        <IssueExecutionContextProvider initialValue={issueExecutionContextInitialValue}>
          <IssueExecution {...props} />
        </IssueExecutionContextProvider>
      </Suspense>
    );
  },
};
export default render;
