import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { styles } from 'src/base/notification-dropdown/styles/notification-dropdown';
import makeStyles from '@mui/styles/makeStyles';
import { MenuItem, ListItemIcon, ListSubheader, List, Dialog, useTheme, Skeleton } from '@mui/material';
import {
  NotificationsNone,
  Assignment,
  Flag,
  Cancel,
  Close,
  Announcement,
  AccessTime,
  NextWeek,
  Room,
  CategoryOutlined,
  AlternateEmail,
} from '@mui/icons-material';
import CustomDropdown from 'src/utils/components/custom-dropdown/index';
import { useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import {
  getNotification,
  getNotifications,
  acknowledgeNotification,
  hasNotViewedNotification,
  viewAllNotifications,
  getConfiguration,
} from 'src/base/notification-dropdown/notification-dropdown.redux';
import moment from 'moment';
import { useState } from 'react';
import { cloneDeep } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import InputFieldGeneric from 'src/utils/components/input-field/input-generic';
import { getIssueInstanceToNotification } from 'src/modules/agenda/agenda.redux';
import translateNotification from './utils/translateNotification';
import { useSubscription } from '@apollo/client';
import { subscribeTopic } from 'src/modules/notification/notification.redux';
import { PushNotification, SubscribeNotificationsDocument } from 'src/gql/graphql';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useNavigate } from 'react-router-dom';

export interface Notification {
  _id: string;
  createdAt: string;
  acknowledged: boolean;
  title: string;
  body: string;
  data: {
    context: string;
    _id: string;
  };
  files: { _id: string }[];
}

type NotificationDropdownProps = ConnectedProps<typeof connector>;

const useStyles = makeStyles(styles);

const NotificationDropdown: React.FC<NotificationDropdownProps> = (props): JSX.Element => {
  const classes: ClassNameMap<string> = useStyles();
  const [oldNotifications, setOldNotifications] = useState<Notification[]>([]);
  const [newNotificationsIcon, setNewNotificationsIcon] = useState<boolean>(false);
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [notificationToModal, setNotificationToModal] = useState<PushNotification>(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  useSubscription(SubscribeNotificationsDocument, {
    onData({ data }) {
      if (data?.data?.pushNotifications && !popoverOpen) {
        setNewNotificationsIcon(true);
      }
    },
  });

  const setPushNotifications = (): void => {
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: props.firebaseConfigurations.apiKey,
        authDomain: props.firebaseConfigurations.authDomain,
        databaseURL: props.firebaseConfigurations.databaseURL,
        projectId: props.firebaseConfigurations.projectId,
        storageBucket: props.firebaseConfigurations.storageBucket,
        messagingSenderId: props.firebaseConfigurations.messagingSenderId,
        appId: props.firebaseConfigurations.appId,
        measurementId: props.firebaseConfigurations.measurementId,
      });
    } else {
      firebase.app(); // if already initialized, use that one
    }
    let messaging: firebase.messaging.Messaging;
    try {
      messaging = firebase.messaging();
    } catch (err) {
      console.info('Failed to initialize Firebase Messaging', err);
    }
    void navigator?.serviceWorker
      ?.register(
        `firebase-messaging-sw.js?apiKey=${props.firebaseConfigurations.apiKey}&authDomain=${props.firebaseConfigurations.authDomain}&databaseURL=${props.firebaseConfigurations.databaseURL}&projectId=${props.firebaseConfigurations.projectId}&storageBucket=${props.firebaseConfigurations.storageBucket}&messagingSenderId=${props.firebaseConfigurations.messagingSenderId}&appId=${props.firebaseConfigurations.appId}&measurementId=${props.firebaseConfigurations.measurementId}`,
      )
      .then((registration) => {
        void Notification.requestPermission().then((permission) => {
          if (permission === 'denied' || permission === 'default') {
            return;
          }
          messaging
            ?.getToken({
              vapidKey: props.firebaseConfigurations.vapidKey,
              serviceWorkerRegistration: registration,
            })
            .then((currentToken) => {
              if (currentToken) {
                props.subscribeTopic(currentToken);
              } else {
                // Show permission request.
                console.error('No registration token available. Request permission to generate one.');
              }
            })
            .catch((err) => {
              console.error('An error occurred while retrieving token. ', err);
            });
          messaging?.onMessage((payload: { data: { body: string } }) => {
            const notificationOptions = {
              body: payload.data.body,
              icon: '/images/assignment_grey_72x72.png',
            };
            setNewNotificationsIcon(true);
            void navigator?.serviceWorker?.getRegistrations()?.then((registration) => {
              if (registration.length) void registration[0].showNotification('GlarVision', notificationOptions);
            });
          });
        });
      });
  };

  useEffect(() => {
    if (props.loggedUser?._id) {
      void props.getNotifications(0);
      void props.hasNotViewedNotification().then((resp) => {
        if (resp) setNewNotificationsIcon(true);
      });
    }
    void props.getConfiguration();
  }, [props.loggedUser?._id]);

  useEffect(() => {
    if (props.firebaseConfigurations && props.loggedUser?._id) setPushNotifications();
  }, [JSON.stringify(props.firebaseConfigurations), props.loggedUser?._id]);

  useEffect(() => {
    if (
      !popoverOpen &&
      props.notifications.filter((n) => oldNotifications.filter((on) => on._id === n._id).length).length !==
        props.notifications.length &&
      ((props.notifications.length && oldNotifications.length) || props.notifications.length === 1)
    ) {
      setNewNotificationsIcon(true);
      setOldNotifications(cloneDeep(props.notifications));
    } else {
      setOldNotifications(cloneDeep(props.notifications));
    }
  }, [JSON.stringify(props.notifications)]);

  const setIcon = (type: string): JSX.Element => {
    switch (type) {
      case 'Issue finished':
        return <Flag classes={{ root: classes.iconSize }} />;
      case 'Issue canceled':
        return <Cancel classes={{ root: classes.iconSize }} />;
      case 'Issue Approval':
        return <AccessTime classes={{ root: classes.iconSize }} />;
      case 'Work package started':
        return <NextWeek classes={{ root: classes.iconSize }} />;
      case 'Site Input Approval':
      case 'Site Input Reviewed':
        return <Room classes={{ root: classes.iconSize }} />;
      case 'Element Input Approval':
      case 'Element Input Reviewed':
        return <CategoryOutlined classes={{ root: classes.iconSize }} />;
      case 'New Message':
        return <AlternateEmail classes={{ root: classes.iconSize }} />;
      default:
        return <Assignment classes={{ root: classes.iconSize }} />;
    }
  };

  const showNotification = (notification: PushNotification, key: number): JSX.Element => {
    return (
      <MenuItem
        id={`notification${key}`}
        key={notification._id}
        className={notification.acknowledged ? classes.menuTextSizeAcknowledge : classes.menuTextSize}
        onClick={(): void => {
          if (!notification.acknowledged) {
            void props.acknowledgeNotification(notification._id);
          }

          if (notification.data.context === 'Other') {
            props.getNotification({ _id_eq: notification._id }).then((resp) => {
              setNotificationToModal(resp);
            });
          } else if (notification.title === 'Issue finished' || notification.title === 'Issue canceled') {
            navigate(`/history?id=${notification.data._id}`);
          } else if (notification.title === 'Issue Approval') {
            navigate(`/approval?id=${notification.data._id}`);
          } else if (notification.data.context === 'Task') {
            void props
              .getIssueInstanceToNotification({
                taskGroups_some: { tasks_some: { _id_eq: notification.data._id } },
              })
              .then((resp) => {
                if (resp) navigate(`/execution?id=${resp._id}`);
              });
          } else if (notification.data.context === 'Action') {
            navigate(`/action?id=${notification.data._id}`);
          } else if (notification.data.context === 'WorkPackage') {
            navigate(`/work-packages?id=${notification.data._id}`);
          } else if (notification.data.context === 'Site') {
            if (notification.title === 'Site Input Approval') {
              navigate(`/sites?siteId=${notification.data._id}&approval=true`);
            } else if (notification.title === 'Site Input Reviewed') {
              navigate(`/sites?siteId=${notification.data._id}`);
            }
          } else if (notification.data.context === 'Element') {
            if (notification.title === 'Element Input Approval') {
              navigate(`/sites?elementId=${notification.data._id}&approval=true`);
            } else if (notification.title === 'Element Input Reviewed') {
              navigate(`/sites?elementId=${notification.data._id}`);
            }
          } else if (notification.data.context === 'ChatMessage') {
            switch (notification?.data?.messageFrom) {
              case 'ChatRoom':
                navigate(`/chat?id=${notification.data._id}`);
                break;
              default:
                navigate(`/execution?id=${notification.data._id}&chat=1`);
                break;
            }
          } else if (notification.data.context === 'Comment') {
            navigate(`/feed?id=${notification.data._id}&commentId=${notification.data.extraData}`);
          } else {
            navigate(`/execution?id=${notification.data._id}`);
          }
        }}
      >
        <ListItemIcon>
          {notification.data.context === 'Other' ? (
            <Announcement classes={{ root: classes.iconSize }} />
          ) : (
            setIcon(notification.title)
          )}
        </ListItemIcon>
        <div className={classes.divItemContent}>
          <div className={classes.divItemMsg}>
            {notification.data.context === 'Other' ? notification.title : translateNotification(notification.body, t)}
          </div>
          <div className={classes.timeDiv}>
            <div>{moment(notification.createdAt).fromNow()}</div>
          </div>
        </div>
      </MenuItem>
    );
  };

  return (
    <CustomDropdown
      id={'notificationsDropdown'}
      iconBtn
      button={
        <div className={classes.notificationIcon} id={'buttonOpenNotifications'}>
          <NotificationsNone />
          {newNotificationsIcon ? <div className={classes.divMainIcon} /> : null}
        </div>
      }
      handleClick={async (): Promise<void> => {
        setNewNotificationsIcon(false);
        await props.getNotifications(0);
        await props.viewAllNotifications();
        await props.hasNotViewedNotification().then((resp) => {
          if (resp) setNewNotificationsIcon(true);
        });
      }}
      changeOpen={(open: boolean): void => {
        setPopoverOpen(open);
      }}
    >
      <div
        id='scrollableDiv'
        style={{
          maxHeight: '500px',
          overflow: 'auto',
          display: 'flex',
          background: theme.palette.background.default,
        }}
      >
        <InfiniteScroll
          dataLength={props.notifications.length}
          next={(): Promise<void> => props.getNotifications(props.notifications.length)}
          hasMore={props.notifications.length < props.totalNotifications}
          loader={<Skeleton animation='wave' />}
          endMessage={<div />}
          scrollableTarget='scrollableDiv'
        >
          <>
            {props.notifications.length ? null : (
              <MenuItem className={classes.menuTextSize} style={{ justifyContent: 'center' }}>
                {t('noNotifications')}
              </MenuItem>
            )}
            {props.notifications.filter((n) => moment.duration(moment().diff(n.createdAt)).asHours() < 24).length ? (
              <List
                subheader={
                  <ListSubheader classes={{ root: classes.pastSubHeader }}>
                    <strong>{t('new')}</strong>
                  </ListSubheader>
                }
              >
                {props.notifications
                  .filter((n) => moment.duration(moment().diff(n.createdAt)).asHours() < 24)
                  .map((notification, key) => showNotification(notification, key))}
              </List>
            ) : null}
            {props.notifications.filter((n) => moment.duration(moment().diff(n.createdAt)).asHours() >= 24).length ? (
              <List
                subheader={
                  <ListSubheader classes={{ root: classes.pastSubHeader }}>
                    <strong>{t('past')}</strong>
                  </ListSubheader>
                }
              >
                {props.notifications
                  .filter((n) => moment.duration(moment().diff(n.createdAt)).asHours() >= 24)
                  .map((notification, key) => showNotification(notification, key))}
              </List>
            ) : null}
          </>
        </InfiniteScroll>
      </div>
      {notificationToModal ? (
        <Dialog open={!!notificationToModal} onClose={(): void => setNotificationToModal(null)} maxWidth={false}>
          <Close
            id={`buttonClose`}
            classes={{ root: classes.closeIcon }}
            onClick={(): void => setNotificationToModal(null)}
          />
          <div
            style={{
              width: '900px',
              height: '700px',
              paddingRight: '45px',
              paddingLeft: '45px',
            }}
          >
            <div className={classes.titleDialog}>{t('GroupNotification')}</div>
            <div>
              <div className={classes.fieldTitle}>{t('title')}</div>
              <div>
                <InputFieldGeneric
                  id='inputDescription'
                  data-testid='inputDescription'
                  editable={false}
                  disabled={true}
                  type={'string'}
                  onlyText
                  onlyInput
                  title={''}
                  values={['title' in notificationToModal ? notificationToModal.title : null]}
                  handleChange={(): void | null => null}
                />
              </div>
              <div className={classes.fieldTitle}>{t('description')}</div>
              <div>
                <InputFieldGeneric
                  editable={false}
                  disabled={true}
                  type={'string'}
                  onlyText
                  onlyInput
                  title={''}
                  values={['body' in notificationToModal ? notificationToModal.body : null]}
                  handleChange={(): void | null => null}
                />
              </div>
              <div className={classes.fieldTitle}>{t('files')}</div>
              <InputFieldGeneric
                editable={false}
                disabled={true}
                type={'file'}
                multiline
                onlyText
                onlyInput
                title={''}
                values={'files' in notificationToModal ? notificationToModal.files : null}
                handleChange={(): void | null => null}
              />
            </div>
          </div>
        </Dialog>
      ) : null}
    </CustomDropdown>
  );
};

const mapStateToProps = (state) => ({
  loggedUser: state.loginReducer.loggedUser,
  notifications: state.notificationdropdownReducer.notifications,
  totalNotifications: state.notificationdropdownReducer.totalNotifications,
  firebaseConfigurations: state.notificationdropdownReducer.firebaseConfigurations,
});

const mapDispatchToProps = {
  getNotifications,
  // subscriptionNotifications,
  acknowledgeNotification,
  hasNotViewedNotification,
  viewAllNotifications,
  getConfiguration,
  getNotification,
  getIssueInstanceToNotification,
  subscribeTopic,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(NotificationDropdown);
