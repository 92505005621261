import { useEffect, useMemo, useRef } from 'react';
import { Button, ClickAwayListener } from '@mui/material';
import styles from './styles';
import moment from 'moment';
import { useState } from 'react';
import DefaultButton from '../default-button';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'react-date-range';
import { pt, enUS } from 'date-fns/locale';
import { CalendarToday } from '@mui/icons-material';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { withDialogBoundary } from 'src/utils/other/componentErrorBoundary';
import useResize from 'src/utils/hooks/useResize';
import { createPortal } from 'react-dom';
import { DateRangePresetType, DateRangePresetValue } from '../custom-date-dropdown/utils/custom-date-dropdown.utils';
import { LoginReducerProps } from 'src/base/login/login.redux';
import { WithStyles, withStyles } from '@mui/styles';

export type DateRange = {
  type?: DateRangePresetType;
  current?: DateRangePresetValue;
  last?: DateRangePresetValue;
  startDate?: Date;
  endDate?: Date;
};

interface MapStateToProps {
  loginReducer: LoginReducerProps;
}
interface IDateRangePickerProps extends WithStyles<typeof styles>, ConnectedProps<typeof connector> {
  defaultDateRange: DateRange;
  minDate?: Date;
  maxDate?: Date;
  onApply: (range: DateRange) => void;
  'data-testid'?: string;
  disabled?: boolean;
  defaultLabel?: string;
  noIcon?: boolean;
}

const emptyRange: DateRange = {
  startDate: moment().toDate(),
  endDate: moment().toDate(),
};

const dateRangePickerElement = document.createElement('div');

dateRangePickerElement.id = 'date-range-picker-root';

document.body.appendChild(dateRangePickerElement);

const CustomDateRangePicker = (props: IDateRangePickerProps) => {
  const { classes, disabled } = props;

  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);
  const [range, setRange] = useState<DateRange>(emptyRange);
  const ref = useRef<HTMLButtonElement>(null);
  const datePickerRef = useRef<HTMLDivElement>(null);

  const btnLabel = useMemo<string>(() => {
    if (props.defaultLabel) return t(props.defaultLabel);

    if (!props.defaultDateRange || !props.defaultDateRange.startDate || !props.defaultDateRange.endDate) {
      return t('anyDate');
    }

    const diff = Math.round(
      moment(props.defaultDateRange.endDate).diff(props.defaultDateRange.startDate, 'days', true),
    );

    if (
      diff === 1 &&
      Math.round(moment(props.defaultDateRange.startDate).diff(moment().startOf('day'), 'days', true)) === 0
    ) {
      return t('today');
    }

    if (
      diff === 1 &&
      Math.round(
        moment(props.defaultDateRange.startDate).diff(moment().subtract(1, 'day').startOf('day'), 'days', true),
      ) === 0
    ) {
      return t('lastDay');
    }

    if (
      diff === 7 &&
      Math.round(moment(props.defaultDateRange.startDate).diff(moment().startOf('week'), 'days', true)) === 0
    ) {
      return t('thisWeek');
    }

    if (
      diff === 7 &&
      Math.round(
        moment(props.defaultDateRange.startDate).diff(moment().subtract(1, 'week').startOf('week'), 'days', true),
      ) === 0
    ) {
      return t('lastWeek');
    }

    if (
      Math.round(moment(props.defaultDateRange.startDate).diff(moment().startOf('month'), 'days', true)) === 0 &&
      diff === moment().daysInMonth()
    ) {
      return t('thisMonth');
    }

    if (
      Math.round(
        moment(props.defaultDateRange.startDate).diff(moment().subtract(1, 'month').startOf('month'), 'days', true),
      ) === 0 &&
      diff === moment().subtract(1, 'month').daysInMonth()
    ) {
      return t('lastMonth');
    }

    return t('custom');
  }, [props.defaultDateRange?.startDate, props.defaultDateRange?.endDate]);

  useEffect(() => {
    if (props.defaultDateRange === null) {
      setRange(emptyRange);
    } else if (props.defaultDateRange !== undefined) {
      setRange(props.defaultDateRange);
    }
  }, [props.defaultDateRange]);

  useResize(datePickerRef, ref, { x: 'left', y: 'bottom' }, !open);

  const minDate = useMemo(
    () => (props.minDate ? moment(props.minDate).startOf('day').toDate() : undefined),
    [props.minDate],
  );
  const maxDate = useMemo(
    () => (props.maxDate ? moment(props.maxDate).endOf('day').toDate() : undefined),
    [props.maxDate],
  );

  const rangeIsSet = !!range.startDate && !!range.endDate;

  return (
    <>
      <Button
        disabled={disabled}
        className={classes.btnDimensions}
        data-testid={props['data-testid']}
        ref={ref}
        color={'primary'}
        variant={/* btnLabel === t('anyDate') ? */ 'text' /*  : 'contained' */}
        startIcon={props.noIcon ? null : <CalendarToday />}
        onClick={() => setOpen(true)}
      >
        <span>{btnLabel}</span>
      </Button>
      {open &&
        createPortal(
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div className={classes.datePicker} ref={datePickerRef}>
              <DateRangePicker
                onChange={(ranges) => setRange(Object.values(ranges).at(0) as any)}
                moveRangeOnFirstSelection={false}
                minDate={minDate}
                maxDate={maxDate}
                key={props.loggedUser.preferences.locale}
                months={1}
                ranges={[range]}
                locale={props.loggedUser.preferences.locale === 'pt_PT' ? pt : enUS}
                direction='horizontal'
              />
              <div className={classes.spacing}>
                {rangeIsSet && (
                  <DefaultButton
                    id='buttonApply'
                    info
                    className={classes.btn}
                    onClick={() => {
                      props.onApply({
                        startDate: range === emptyRange ? null : moment(range.startDate).startOf('day').toDate(),
                        endDate: range === emptyRange ? null : moment(range.endDate).endOf('day').toDate(),
                      });
                      setOpen(false);
                    }}
                  >
                    {t('apply')}
                  </DefaultButton>
                )}
                <DefaultButton
                  id='buttonClear'
                  info
                  className={classes.btn}
                  onClick={() => {
                    props.onApply({ startDate: null, endDate: null });
                    setOpen(false);
                  }}
                >
                  {t('clear')}
                </DefaultButton>
              </div>
            </div>
          </ClickAwayListener>,
          dateRangePickerElement,
        )}
    </>
  );
};

const fallbackComponent = () => {
  const { t } = useTranslation();
  return (
    <Button color={'primary'} variant={'outlined'} startIcon={<CalendarToday />} disabled>
      <span>{t('anyDate')}</span>
    </Button>
  );
};

const connector = connect(
  (state: MapStateToProps) => ({ loggedUser: state.loginReducer.loggedUser }),
  () => ({}),
);

export default withStyles(styles)(connector(withDialogBoundary(CustomDateRangePicker, { fallbackComponent })));
