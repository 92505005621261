import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { Dialog, Stepper, Step, StepLabel, Tooltip, TextField, Checkbox, Skeleton } from '@mui/material';
import { withStyles } from '@mui/styles';
import { styles } from 'src/modules/report/components/modal-create-issue-report/styles';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Close,
  CheckBoxOutlineBlank,
  CheckBox,
  ArrowDropDown,
  ArrowRight,
  Search,
  ExpandMore,
  ExpandLess,
} from '@mui/icons-material';
import InputFieldGeneric from 'src/utils/components/input-field/input-generic';
import { getTaskTemplateGroups } from 'src/modules/issue-catalogs/issue.catalogs.redux';
import DefaultButton from 'src/utils/components/default-button';
import { orderBy } from 'lodash';
import GenericInputCard from 'src/utils/components/generic-input-card';
import { createIssueReport, updateIssueReport } from 'src/modules/report/report.redux';
import { formatExecInps } from 'src/utils/funcs';
import { showSnackbar } from 'src/base/root/root.redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import BetterFieldPopover from 'src/utils/components/better-field-popover';
import { inputIterator } from 'src/modules/issue-templates/utils/issue.template.utils';
import LabelInput from 'src/utils/components/input-field/input-label';

const attributes = [
  { name: 'issueId', id: 'glarID' },
  { name: 'description', id: 'description' },
  { name: 'assignedLabelValues', id: 'assignedLabelValues' },
  { name: 'assignedAccounts', id: 'assignedAccounts' },
  { name: 'element', id: 'element' },
  { name: 'labels', id: 'labels' },
  { name: 'site', id: 'site' },
  { name: 'Executed by', id: 'executedBy' },
  { name: 'state', id: 'stateMachineInstance' },
  { name: 'Created at', id: 'startDate' },
  { name: 'Frequency', id: 'frequency' },
  { name: 'Due date', id: 'endDate' },
  { name: 'Last action', id: 'lastAction' },
  { name: 'Wrong answer', id: 'wrongAnswer' },
  { name: 'Expected time', id: 'expectedTime' },
  { name: 'Execution time', id: 'executionTime' },
  { name: 'Triggered by', id: 'triggeredBy' },
  { name: 'Report', id: 'report' },
  { name: 'Parent Site', id: 'parentSite' },
  { name: 'Root Site', id: 'rootSite' },
  { name: 'Parent Element', id: 'parentElement' },
  { name: 'Root Element', id: 'rootElement' },
  { name: 'approvedBy', id: 'approvedBy' },
  { name: 'approvedAt', id: 'approvedAt' },
  { name: 'score', id: 'score' },
];

export const IssueTemplateReportContext = React.createContext(null);

const ModalCreateIssueReport: React.FC<any> = (props) => {
  const { classes, onClose, create, update, folder, data } = props;
  const { t } = useTranslation();
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [issue, setIssue] = useState<any>(null);
  const [selectedAttributes, setSelectedAttributes] = useState<any>([]);
  const [selectedInputs, setSelectedInputs] = useState<any>({});
  const [selectedConditionalInputs, setSelectedConditionalInputs] = useState<any>({});
  const [selectedTask, setSelectedTask] = useState<any>(null);
  const [taskGroups, setTaskGroups] = useState<any>([]);
  const [hiddenGroups, setHiddenGroups] = useState<string[]>([]);
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchActive, setSearchActive] = useState<boolean>(false);
  const [expandInputHistory, setExpandInputHistory] = useState<boolean>(true);
  const [firstTime, setFirstTime] = useState<boolean>(true);

  useEffect(() => {
    if (data) {
      setName(data.name);
      setIssue(data.issueCatalog);
      setSelectedAttributes(data.generalFields.map((f) => attributes.filter((attr) => attr.id === f)[0]));
    }
  }, []);

  const renderSelectAll = () => {
    return (
      <div className={classes.divAttr}>
        {selectedAttributes?.length === attributes?.length ? (
          <CheckBox
            classes={{ root: classes.checkboxSelected }}
            onClick={() => {
              setSelectedAttributes([]);
            }}
            data-testid='unselect-all-attr'
          />
        ) : (
          <CheckBoxOutlineBlank
            classes={{ root: classes.checkboxBlank }}
            onClick={() => {
              setSelectedAttributes([...attributes]);
            }}
            data-testid='select-all-attr'
          />
        )}
        <span>{t('selectAll')}</span>
      </div>
    );
  };

  const renderGeneralAttributes = (attr) => {
    return attr.map((att, i) => (
      <div key={i} className={classes.divAttr}>
        {selectedAttributes.filter((at) => at?.id === att.id).length ? (
          <CheckBox
            classes={{ root: classes.checkboxSelected }}
            onClick={() => {
              setSelectedAttributes(selectedAttributes.filter((at) => at.id !== att.id));
            }}
            data-testid={`unselect-attr-${att.id}`}
          />
        ) : (
          <CheckBoxOutlineBlank
            classes={{ root: classes.checkboxBlank }}
            onClick={() => {
              setSelectedAttributes([...selectedAttributes, att]);
            }}
            data-testid={`select-attr-${att.id}`}
          />
        )}
        <span>{t(att.name)}</span>
      </div>
    ));
  };

  const validateNext = () => {
    if (name && name.trim() && issue) return true;
    else return false;
  };

  useEffect(() => {
    setTaskGroups([]);
    setSelectedInputs({});
    setSelectedConditionalInputs({});
    setSelectedTask(null);
  }, [issue]);

  const countInputsOnTask = (inputs, listOfIds, listOfCondIds) => {
    let ids = [];
    let condIds = [];

    inputs.map((input) => {
      if (listOfIds.includes(input._id)) {
        ids = [...ids, input._id];
      }
      input[input.type].onResponse?.map((onResp) => {
        condIds = [...condIds, ...countConditionals(onResp.inputs, listOfCondIds)];
      });
    });

    return { ids, condIds };
  };

  const countConditionals = (inputs, listOfIds) => {
    let ids = [];
    inputs?.map((inp) => {
      if (listOfIds.includes(inp._id)) {
        ids = [...ids, inp._id];
      }
      inp[inp.type].onResponse?.map((onResp) => (ids = [...ids, ...countConditionals(onResp.inputs, listOfIds)]));
    });
    return ids;
  };

  const next = async () => {
    if (taskGroups.length === 0) {
      const resp = await props.getTaskTemplateGroups({ issueTemplate_eq: issue.template._id });
      setTaskGroups(resp);
      if (update) {
        let selInputs = {};
        let selCondInputs = {};
        resp.map((tg) => {
          tg.taskTemplates.map((taskTemp) => {
            const { ids, condIds } = countInputsOnTask(
              taskTemp.inputs,
              data.taskInputFields,
              data.taskConditionalInputFields,
            );
            if (ids.length) selInputs = { ...selInputs, [taskTemp._id]: ids };
            if (condIds.length) selCondInputs = { ...selCondInputs, [taskTemp._id]: condIds };
          });
        });
        setSelectedConditionalInputs(selCondInputs);
        setSelectedInputs(selInputs);
      }
    }
    setActiveTab(1);
  };

  /**
   * Get conditional inputs of every input of current task
   * @returns List of conditional inputs
   */
  const getConditionalInputsBySelectedTask = () => {
    if (!selectedTask?.inputs) return [];

    // It returns all inputs including top-level inputs
    const allInputs = Array.from(inputIterator(selectedTask.inputs));
    // Only want the conditional ones
    const conditionalInputs = allInputs.filter((inp) => !selectedTask.inputs.some((i) => i._id === inp._id));

    return conditionalInputs;
  };

  /**
   * Validates if all inputs (normal inputs, history inputs and conditional inputs) of current task are selected
   * @returns boolean
   */
  const areAllInputsSelectedBySelectedTask = () => {
    const selectedInputsCount =
      (selectedInputs[selectedTask._id]?.length || 0) + (selectedConditionalInputs[selectedTask._id]?.length || 0);

    const totalInputsCount =
      (selectedTask.inputs?.filter(
        (inp) =>
          inp.type !== 'instruction' && inp.type !== 'signature' && inp.type !== 'table' && inp.type !== 'multiple',
      )?.length || 0) +
      (selectedTask.inputHistory?.filter(
        (inp) =>
          inp.type !== 'instruction' && inp.type !== 'signature' && inp.type !== 'table' && inp.type !== 'multiple',
      )?.length || 0) +
      getConditionalInputsBySelectedTask().filter(
        (inp) => inp.type !== 'instruction' && inp.type !== 'signature' && inp.type !== 'table',
      )?.length;

    return selectedInputsCount === totalInputsCount;
  };

  /**
   * Select or unselect all inputs (normal inputs, history inputs and conditional inputs) of current task
   */
  const selectAllInputsOfSelectedTask = () => {
    // Unselect all inputs from selected task if all of them are selected
    if (areAllInputsSelectedBySelectedTask()) {
      const newSelectedInputs = { ...selectedInputs };
      delete newSelectedInputs[selectedTask._id];
      setSelectedInputs(newSelectedInputs);

      const newSelectedConditionalInputs = { ...selectedConditionalInputs };
      delete newSelectedConditionalInputs[selectedTask._id];
      setSelectedConditionalInputs(newSelectedConditionalInputs);
    } else {
      // Select all inputs
      setSelectedInputs({
        ...selectedInputs,
        [selectedTask._id]: [
          ...selectedTask.inputs
            .filter(
              (inp) =>
                inp.type !== 'instruction' &&
                inp.type !== 'signature' &&
                inp.type !== 'table' &&
                inp.type !== 'multiple',
            )
            .map((inp) => inp._id),
          ...selectedTask.inputHistory
            .filter(
              (inp) =>
                inp.type !== 'instruction' &&
                inp.type !== 'signature' &&
                inp.type !== 'table' &&
                inp.type !== 'multiple',
            )
            .map((inp) => inp._id),
        ],
      });
      setSelectedConditionalInputs({
        ...selectedConditionalInputs,
        [selectedTask._id]: getConditionalInputsBySelectedTask()
          .filter((inp) => inp.type !== 'instruction' && inp.type !== 'signature' && inp.type !== 'table')
          .map((ci) => ci._id),
      });
    }
  };

  const save = () => {
    setDisableButton(true);
    let inputs = [];
    let condInputs = [];

    Object.keys(selectedInputs).map((k) => {
      inputs = [...inputs, ...selectedInputs[k.toString()]];
    });

    Object.keys(selectedConditionalInputs).map((k) => {
      condInputs = [...condInputs, ...selectedConditionalInputs[k.toString()]];
    });

    if (!selectedAttributes?.length && !inputs?.length && !condInputs?.length) {
      props.showSnackbar('error', t('selectedFieldsIssueReport'));
      setDisableButton(false);
      return;
    }

    if (create) {
      props
        .createIssueReport({
          name,
          folder: folder._id,
          issueCatalog: issue._id,
          generalFields: selectedAttributes.map((attr) => attr.id),
          taskInputFields: inputs,
          taskConditionalInputFields: condInputs,
        })
        .then((resp) => {
          if (resp && resp.graphQLErrors) {
            return;
          }
          onClose(resp);
        });
    } else if (update) {
      props
        .updateIssueReport(
          { _id: data._id },
          {
            name,
            issueCatalog: issue._id,
            generalFields: selectedAttributes.map((attr) => attr?.id).filter((attr) => !!attr),
            taskInputFields: inputs,
            taskConditionalInputFields: condInputs,
          },
        )
        .then((resp) => {
          if (resp && resp.graphQLErrors) {
            return;
          }
          onClose(resp);
        });
    }
  };

  return (
    <IssueTemplateReportContext.Provider value={taskGroups}>
      <Dialog open={true} onClose={(): void => onClose()} maxWidth={false}>
        <Close
          id={`buttonCloseCreateReport`}
          data-testid={`buttonCloseCreateReport`}
          classes={{ root: classes.closeIcon }}
          onClick={(): void => onClose()}
        />
        <div data-testid={'create-edit-issue-report'} className={classes.modalContainer}>
          <Stepper classes={{ root: classes.stepper }} style={{ maxWidth: '400px' }} nonLinear activeStep={activeTab}>
            <Step style={{ cursor: 'pointer' }} onClick={() => setActiveTab(0)}>
              <StepLabel>{t('generalSettings')}</StepLabel>
            </Step>
            <Step
              style={{ cursor: 'pointer' }}
              onClick={async () => {
                if (validateNext()) {
                  next();
                }
              }}
            >
              <StepLabel>{t('Inputs')}</StepLabel>
            </Step>
          </Stepper>
          <div className={activeTab === 0 ? classes.tabShow : classes.tabHidden}>
            <div className={classes.titleContainer}>{t('generalSettings')}</div>
            <div className={classes.inputsContainer}>
              <div style={{ width: 'calc(100% - 370px)', paddingRight: '70px' }}>
                <LabelInput required={true}>{t('nameOfReport')}</LabelInput>
                <InputFieldGeneric
                  editable={true}
                  disabled={false}
                  type={'string'}
                  onlyInput
                  values={name ? [name] : []}
                  id={`report-name-field`}
                  handleChange={(e: string[]): void => setName(e[0])}
                  placeholder={t('Add Name')}
                />
                <div style={{ marginTop: '25px' }}>
                  <LabelInput required={true}>{t('issue')}</LabelInput>
                </div>
                <BetterFieldPopover
                  title={''}
                  context={['issueCatalogOpen', 'issueCatalogScheduled']}
                  data-testid={`inputIssueCatalog`}
                  disabled={false}
                  value={issue}
                  onChange={setIssue}
                  placeholder={t('selectWorkOrder')}
                />
              </div>
              <div className={classes.divModalGeneralFieldsTitle}>
                <div>
                  <span className={classes.modalFieldTitle}>{`${t('generalInputs')}`}</span>
                </div>
                <div id='scrollableDiv' className={classes.divModalGeneralFields} style={{ paddingTop: '20px' }}>
                  <>
                    <InfiniteScroll
                      dataLength={attributes.length}
                      next={(): void | null => null}
                      hasMore={true}
                      loader={<Skeleton animation='wave' />}
                      endMessage={<div />}
                      scrollableTarget='scrollableDiv'
                    >
                      {' '}
                      {renderSelectAll()}
                      <br></br>
                      {renderGeneralAttributes(attributes)}
                    </InfiniteScroll>
                  </>
                </div>
              </div>
            </div>
            <div className={classes.divBtns}>
              <DefaultButton discard onClick={() => onClose()} id={`discard-btn`}>
                {t('cancel')}
              </DefaultButton>
              <DefaultButton
                id={'btn-next'}
                disabled={!validateNext()}
                onClick={() => {
                  next();
                }}
              >
                {t('next')}
              </DefaultButton>
            </div>
          </div>
          <div className={activeTab === 1 ? classes.tabShow : classes.tabHidden}>
            <div className={classes.titleContainer}>{t('Inputs')}</div>
            <div className={classes.allTasksContainer}>
              <div className={classes.divAllTasks}>
                <div className={classes.allTasksChild}>
                  <div className={classes.tasksTitle}>{t('tasks')}</div>
                  {searchActive ? (
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                      <TextField
                        size={'small'}
                        variant='outlined'
                        InputProps={{ style: { height: '22px' } }}
                        data-testid={`search-field`}
                        style={{ marginRight: '10px', marginLeft: '15px' }}
                        onChange={(e) => {
                          setSearchWord(e.target.value);
                        }}
                      />
                      <Close
                        style={{ marginLeft: 'auto', marginRight: '5px' }}
                        classes={{ root: classes.iconsTasksLeft }}
                        data-testid={`close-search-field`}
                        onClick={() => {
                          setSearchActive(false);
                          setSearchWord('');
                        }}
                      />
                    </div>
                  ) : (
                    <Search
                      style={{ marginLeft: 'auto', marginRight: '5px' }}
                      classes={{ root: classes.iconsTasksLeft }}
                      data-testid={`open-search-field`}
                      onClick={() => {
                        setSelectedTask(null);
                        setSearchActive(true);
                      }}
                    />
                  )}
                </div>
                <div style={{ marginTop: '20px' }}>
                  {orderBy(taskGroups, 'order', 'asc').map((group) => (
                    <div
                      className={taskGroups.length > 1 ? classes.divExecGroup : classes.divExecGroupOnlyOne}
                      style={hiddenGroups.includes(group._id) ? {} : { paddingBottom: '5px' }}
                    >
                      {taskGroups.length > 1 ? (
                        <div
                          style={hiddenGroups.includes(group._id) ? { borderBottom: 'none' } : {}}
                          className={classes.divExecGroupHeader}
                        >
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                            data-testid={`show-or-hide-group-${group.name}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (hiddenGroups.includes(group._id))
                                setHiddenGroups((prev) => prev.filter((id) => id !== group._id));
                              else setHiddenGroups((prev) => [...prev, group._id]);
                            }}
                          >
                            {hiddenGroups.includes(group._id) ? <ArrowDropDown /> : <ArrowRight />}
                          </div>
                          <span className={classes.spanExecGroupName}>{group.name}</span>
                        </div>
                      ) : null}
                      {orderBy(
                        group.taskTemplates.filter(
                          (t) => !searchWord || t.name.toLowerCase().includes(searchWord.toLowerCase()),
                        ),
                        'order',
                        'asc',
                      ).map((task, index) => (
                        <div
                          key={task._id}
                          style={hiddenGroups.includes(group._id) ? { display: 'none' } : {}}
                          className={
                            selectedTask?._id === task._id ? classes.listItemTaskSelected : classes.listItemTask
                          }
                          data-testid={`select-task-${group.name}-${task.name}`}
                          onClick={() => {
                            setSelectedTask(
                              selectedTask && selectedTask._id === task._id
                                ? null
                                : {
                                    ...task,
                                    inputs: formatExecInps(task.inputs),
                                    inputHistory: formatExecInps(task.inputHistory),
                                  },
                            );
                          }}
                        >
                          <div className={classes.taskName}>
                            <Tooltip title={task.name}>
                              <span>
                                <span className={classes.taskIndexSpan}>{`${index + 1} - `}</span>
                                {task.name}
                              </span>
                            </Tooltip>
                          </div>
                          {selectedInputs[task._id]?.length || selectedConditionalInputs[task._id]?.length ? (
                            <div className={classes.numberCircle}>
                              {selectedConditionalInputs[task._id]?.length
                                ? selectedConditionalInputs[task._id]?.length + (selectedInputs[task._id]?.length || 0)
                                : selectedInputs[task._id]?.length || undefined}
                            </div>
                          ) : undefined}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              {selectedTask ? (
                <div className={classes.divTaskInputs}>
                  <div className={classes.taskInputsTitle}>{t('taskInputs')}</div>
                  <div
                    style={{ overflow: 'auto', height: 'calc(100% - 50px)', paddingRight: '20px', paddingLeft: '20px' }}
                  >
                    <div>
                      <Checkbox
                        id='checkbox-select-all'
                        data-testid='checkbox-select-all'
                        color={'primary'}
                        checked={areAllInputsSelectedBySelectedTask()}
                        onClick={() => selectAllInputsOfSelectedTask()}
                      />
                      {t('selectAll')}
                    </div>
                    {selectedTask.inputs.map((input) => {
                      return (
                        <div
                          key={input._id}
                          style={
                            input.type !== 'instruction' &&
                            input.type !== 'signature' &&
                            input.type !== 'table' &&
                            input.type !== 'multiple'
                              ? {}
                              : { opacity: 0.5, paddingLeft: '39px' }
                          }
                        >
                          <GenericInputCard
                            firstTime={firstTime}
                            setFirstTime={setFirstTime}
                            disable={true}
                            enableCheckbox={
                              input.type !== 'instruction' &&
                              input.type !== 'signature' &&
                              input.type !== 'table' &&
                              input.type !== 'multiple'
                            }
                            moveCard={() => null}
                            duplicate={() => null}
                            id={`genericInputCard${input.order}${selectedTask.order}`}
                            checkboxInputsSelect={
                              selectedInputs[selectedTask._id] ? selectedInputs[selectedTask._id] : []
                            }
                            setCheckboxInputsSelect={(e) => {
                              setSelectedInputs({ ...selectedInputs, [selectedTask._id]: e });
                            }}
                            delete={(): void => null}
                            instruction
                            input={input}
                            setInput={(): void => null}
                            checkboxInputsSelectConditionals={
                              selectedConditionalInputs[selectedTask._id]
                                ? selectedConditionalInputs[selectedTask._id]
                                : []
                            }
                            setCheckboxInputsSelectConditionals={(e) => {
                              setSelectedConditionalInputs({
                                ...selectedConditionalInputs,
                                [selectedTask._id]: e,
                              });
                            }}
                            conditionalNotRemovable={true}
                          />
                        </div>
                      );
                    })}
                    {selectedTask.inputHistory.length ? (
                      <>
                        <div
                          style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                          onClick={() => setExpandInputHistory(!expandInputHistory)}
                          data-testid={`expand-selected-task-input-history`}
                        >
                          <span className={classes.inputHistory}>{t('inputsHistory')}</span>
                          {expandInputHistory ? (
                            <ExpandLess style={{ fontSize: '30px' }} />
                          ) : (
                            <ExpandMore style={{ fontSize: '30px' }} />
                          )}
                        </div>
                        {expandInputHistory &&
                          selectedTask.inputHistory.map((input) => (
                            <div
                              key={input._id}
                              style={
                                input.type !== 'instruction' &&
                                input.type !== 'signature' &&
                                input.type !== 'table' &&
                                input.type !== 'multiple'
                                  ? {}
                                  : { opacity: 0.5, paddingLeft: '39px' }
                              }
                            >
                              <GenericInputCard
                                firstTime={firstTime}
                                setFirstTime={setFirstTime}
                                disable={true}
                                enableCheckbox={
                                  input.type !== 'instruction' &&
                                  input.type !== 'signature' &&
                                  input.type !== 'table' &&
                                  input.type !== 'multiple'
                                }
                                moveCard={() => null}
                                duplicate={() => null}
                                id={`genericInputCard${input.order}${selectedTask.order}`}
                                checkboxInputsSelect={
                                  selectedInputs[selectedTask._id] ? selectedInputs[selectedTask._id] : []
                                }
                                setCheckboxInputsSelect={(e) => {
                                  setSelectedInputs({ ...selectedInputs, [selectedTask._id]: e });
                                }}
                                delete={(): void => null}
                                instruction
                                input={input}
                                setInput={(): void => null}
                                conditionalNotRemovable={true}
                              />
                            </div>
                          ))}
                      </>
                    ) : undefined}
                  </div>
                </div>
              ) : undefined}
            </div>
            <div className={classes.divBtns}>
              <DefaultButton discard onClick={() => setActiveTab(0)} id={`back-btn`}>
                {t('back')}
              </DefaultButton>
              <DefaultButton id={'btn-create'} disabled={!validateNext() || disableButton} onClick={save}>
                {t('create')}
              </DefaultButton>
            </div>
          </div>
        </div>
      </Dialog>
    </IssueTemplateReportContext.Provider>
  );
};

const mapStateToProps = (): Record<string, never> => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getTaskTemplateGroups,
      createIssueReport,
      updateIssueReport,
      showSnackbar,
    },
    dispatch,
  );

export default compose<any>(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(ModalCreateIssueReport);
