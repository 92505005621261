import { MainContainerState } from 'src/base/main-container/context/main.container.context';
import { FinishStepModalProps } from '../components/finish.step.modal';
import { IssueCatalogDraftConflictHandler } from 'src/gql/graphql';

/**
 * used to handle the logic behind redoing the step the user
 * just finished (the progress will reset, along with the sub steps as well)
 * @param state
 * @param props
 * @param history
 * @param updateMainContainerState
 */
export const handleRedoStep = async (
  state: MainContainerState,
  props: FinishStepModalProps,
  navigate,
  updateMainContainerState: (newData: Partial<MainContainerState>) => void,
  t,
) => {
  let folders, templateDraft, issueInstance;
  switch (state.currentStep) {
    case 'build':
      await props.deleteOneIssueTemplateDraft(props.loggedUser?.onBoarding?.templateDraft._id);
      folders = await props.getIssueTemplateFolders({ name_eq: 'Drafts' }, 1, 0);
      templateDraft = await props.createIssueTemplate(folders[0]._id);
      await props.updateLoggedUser(
        {
          id: props.loggedUser._id,
          onBoarding: {
            ...props.loggedUser?.onBoarding,
            templateDraft: templateDraft._id,
            template: null,
            openIssueDraft: null,
            openIssue: null,
            issueInstance: null,
            progressTotal: 0,
            currentSubStep: 0,
          },
        },
        false,
      );
      navigate(`/templates/issues/issue?id=${templateDraft._id}&edit=true`);
      break;
    case 'createTasks':
      await props.deleteIssueDraft(props.loggedUser?.onBoarding?.openIssueDraft._id).then(async () => {
        const catalogFolders = await props.getCatalogFolders(1, 0, { name_contains: 'Drafts' });
        const stateMachines = await props.getStateMachines({ folder: { _id_ne: null } });
        const issueCatalogDraft = await props.createIssueCatalogDraft({
          name: 'New issue',
          folder: catalogFolders[0]._id,
          conflictHandler: IssueCatalogDraftConflictHandler.keepBoth,
          stateMachine: stateMachines.filter((st) => st.name === 'Start and finish manually')[0]._id,
          taskMappings: [],
          scheduler: [],
          assignedAccounts: [props.loggedUser?._id],
          site: props.loggedUser?.authorizedSites[0]._id,
          tasksFallbackStateMachine: stateMachines.filter((st) => t(st.name) === 'Start and finish manually')[0]._id,
        });
        props.updateLoggedUser(
          {
            id: props.loggedUser._id,
            onBoarding: {
              ...props.loggedUser?.onBoarding,
              step: 'createIssue',
              availableSteps: ['createTasks', 'execute', 'finish'],
              templateDraft: null,
              template: props.loggedUser?.onBoarding?.template._id,
              openIssueDraft: issueCatalogDraft._id,
              openIssue: null,
              issueInstance: null,
              progressTotal: Math.floor(100 / 3),
              currentSubStep: 0,
              maxSubStep: 3,
            },
          },
          false,
        );
        updateMainContainerState({ currentStep: 'createIssue' });
        navigate(`/open?type=draft&idIssue=${issueCatalogDraft._id}`);
      });
      break;
    case 'execute':
      await props.deleteIssue(props.loggedUser?.onBoarding?.issueInstance._id);
      issueInstance = await props.runIssues(
        [props.loggedUser?.onBoarding?.openIssue._id],
        null,
        null,
        null,
        null,
        true,
      );
      await props.updateLoggedUser(
        {
          id: props.loggedUser._id,
          onBoarding: {
            ...props.loggedUser?.onBoarding,
            step: 'execute',
            availableSteps: ['finish'],
            templateDraft: null,
            template: props.loggedUser?.onBoarding?.template._id,
            openIssueDraft: null,
            openIssue: props.loggedUser?.onBoarding?.openIssue._id,
            issueInstance: issueInstance[0]._id,
            progressTotal: Math.floor((100 / 3) * 2),
            currentSubStep: 0,
          },
        },
        false,
      );

      navigate(`/execution?name=${issueInstance[0].name}&id=${issueInstance[0]._id}`);
      break;
  }
  updateMainContainerState({ openEndStepModal: false, startSubSteps: false, open: true });
};
