import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    listItem: {
      padding: '8px 16px',
      fontFamily: 'Open Sans',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
      },
    },
    listItemDisabled: {
      padding: '8px 16px',
      fontFamily: 'Open Sans',
    },
    listItemNoHover: {
      fontFamily: 'Open Sans',
      paddingLeft: 0,
      paddingRight: 0,
    },
    itemText: {
      margin: 0,
      '& >span': {
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        lineHeight: '22px',
        wordBreak: 'break-all',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        maxWidth: '100%',
      },
    },
    rightIcon: {
      color: theme.palette.text.primary,
      marginLeft: 'auto',
    },

    disabledText: {
      color: theme.palette.text.secondary,
    },
    textFieldIcon: {
      color: theme.palette.primary.main,
      marginRight: '5px',
    },
    oneIcon: {
      color: 'rgba(247, 104, 0, 0.54)',
      marginRight: '5px',
    },
    checkboxOutIcon: {
      color: theme.palette.primary.main,
      marginRight: '5px',
    },
    radioOutIcon: {
      color: theme.palette.success.main,
      marginRight: '5px',
    },
    calendarIcon: {
      color: '#00AF5B',
      marginRight: '5px',
    },
    fileIcon: {
      color: theme.palette.error.main,
      marginRight: '5px',
    },
    elementIcon: {
      color: theme.palette.text.primary,
      marginRight: '5px',
    },
    tableIcon: {
      color: '#7C18FB',
      marginRight: '5px',
    },
    signatureIcon: {
      color: theme.palette.primary.main,
      marginRight: '5px',
    },
    qrCodeIcon: {
      color: '#FFD600',
      marginRight: '5px',
    },
    toolIcon: {
      color: theme.palette.error.main,
      marginRight: '5px',
    },
    inputIcon: {
      position: 'relative',
      top: '3px',
      '&.MuiSvgIcon-root': {
        fontSize: '20px',
      },
    },
  });
