import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    mainContainer: {
      width: '100%',
      overflow: 'auto',
    },
    popoverContainer: {
      width: '280px',
      maxHeight: '360px',
      // overflow: 'hidden',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    listItem: {
      padding: '8px 16px',
      fontFamily: 'Open Sans',
      cursor: 'pointer',
      fontSize: '14px',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    customListItem: {
      '& > button': {
        justifyContent: 'flex-start',
        padding: '8px 16px',
        fontFamily: 'Open Sans',
        width: '100%',
        color: theme.palette.text.primary,
        fontSize: '14px',
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
        },
      },
    },
    horizontal: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 8,
      marginTop: '16px',
      '& > div': {
        marginBottom: '0px',
      },
    },
    relative: {
      position: 'relative',
      marginRight: 8,
    },
    expandIcon: {
      position: 'absolute',
      right: 0,
      top: 8,
      cursor: 'pointer',
    },
    input: {
      background: theme.palette.background.default,
      boxShadow: 'none !important',
      color: `${theme.palette.text.primary} !important`,
      transition: 'all 0.3s, height 1ms',
      height: '40px',
      width: '100%',
      alignItems: 'flex-start',
      display: 'flex',
      cursor: 'pointer',
      maxWidth: '100%',
      padding: '8px',
      border: `1px solid ${theme.palette.grey.light}`,
      borderRadius: '5px',
      overflow: 'hidden',
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.text.primary,
      },
      '&:focus': {
        color: theme.palette.common.white,
        border: '1px solid',
      },
    },
  });
