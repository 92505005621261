import gql from 'graphql-tag';
import { LABEL_VALUE } from 'src/utils/fragments/label';
import { SKILL, SKILL_GROUP, SKILL_SUBJECT, SKILL_LEVEL, SKILL_GROUP_COMPLETE } from 'src/utils/fragments/skills';

export const Q_GET_SKILL_SUBJECTS = gql`
  query GetSkillSubjects(
    $where: SkillSubjectWhereInput
    $limit: Int
    $skip: Int
    $orderBy: [SkillSubjectOrderByInput]
  ) {
    skillSubjects(where: $where, orderBy: $orderBy, limit: $limit, skip: $skip) {
      ...skillSubject
    }
  }
  ${SKILL_SUBJECT}
`;

export const Q_GET_SKILL_SUBJECTS_COUNT = gql`
  query GetSkillSubjectsCount($where: SkillSubjectWhereInput) {
    skillSubjectsCount(where: $where)
  }
`;

export const Q_GET_SKILL_SUBJECT = gql`
  query GetSkillSubject($where: SkillSubjectWhereUniqueInput!) {
    skillSubject(where: $where) {
      ...skillSubject
    }
  }
  ${SKILL_SUBJECT}
`;

export const M_CREATE_SKILL_SUBJECT = gql`
  mutation CreateSkillSubject($data: SkillSubjectCreateInput!) {
    createSkillSubject(data: $data) {
      ...skillSubject
    }
  }
  ${SKILL_SUBJECT}
`;

export const M_UPDATE_SKILL_SUBJECT = gql`
  mutation UpdateSkillSubject($where: SkillSubjectWhereUniqueInput!, $data: SkillSubjectUpdateInput!) {
    updateSkillSubject(where: $where, data: $data) {
      ...skillSubject
    }
  }
  ${SKILL_SUBJECT}
`;

export const M_DELETE_SKILL_SUBJECT = gql`
  mutation DeleteSkillSubject($where: SkillSubjectWhereUniqueInput!) {
    deleteOneSkillSubject(where: $where) {
      ...skillSubject
    }
  }
  ${SKILL_SUBJECT}
`;

export const M_DELETE_SKILL_SUBJECTS = gql`
  mutation DeleteSkillSubjects($ids: [ID!]!) {
    removeSkillSubjects(skillSubjectIds: $ids) {
      ...skillSubject
    }
  }
  ${SKILL_SUBJECT}
`;

export const Q_GET_SKILL_GROUPS = gql`
  query GetSkillGroups($where: SkillGroupWhereInput, $limit: Int, $skip: Int, $orderBy: [SkillGroupOrderByInput]) {
    skillGroups(where: $where, orderBy: $orderBy, limit: $limit, skip: $skip) {
      ...skillGroup
    }
  }
  ${SKILL_GROUP}
`;

export const Q_GET_SKILL_GROUP = gql`
  query GetSkillGroup($where: SkillGroupWhereUniqueInput!) {
    skillGroup(where: $where) {
      ...skillGroupComplete
    }
  }
  ${SKILL_GROUP_COMPLETE}
`;

export const M_CREATE_SKILL_GROUP = gql`
  mutation CreateSkillGroup($data: SkillGroupCreateInput!) {
    createSkillGroup(data: $data) {
      ...skillGroup
    }
  }
  ${SKILL_GROUP}
`;

export const M_UPDATE_SKILL_GROUP = gql`
  mutation UpdateSkilGroup($where: SkillGroupWhereUniqueInput!, $data: SkillGroupUpdateInput!) {
    updateSkillGroup(where: $where, data: $data) {
      ...skillGroup
    }
  }
  ${SKILL_GROUP}
`;

export const M_DELETE_SKILL_GROUP = gql`
  mutation DeleteSkillGroup($where: SkillGroupWhereUniqueInput!) {
    deleteOneSkillGroup(where: $where) {
      ...skillGroup
    }
  }
  ${SKILL_GROUP}
`;

export const Q_GET_SKILLS = gql`
  query GetSkills($where: SkillWhereInput, $limit: Int, $skip: Int, $orderBy: [SkillOrderByInput]) {
    skills(where: $where, orderBy: $orderBy, limit: $limit, skip: $skip) {
      ...skill
    }
  }
  ${SKILL}
`;

export const Q_GET_SKILL = gql`
  query GetSkill($where: SkillWhereUniqueInput!) {
    skill(where: $where) {
      ...skill
    }
  }
  ${SKILL}
`;

export const M_CREATE_SKILL = gql`
  mutation CreateSkill($data: SkillCreateInput!) {
    createSkill(data: $data) {
      ...skill
    }
  }
  ${SKILL}
`;

export const M_UPDATE_SKILL = gql`
  mutation UpdateSkill($where: SkillWhereUniqueInput!, $data: SkillUpdateInput!) {
    updateSkill(where: $where, data: $data) {
      ...skill
    }
  }
  ${SKILL}
`;

export const M_DELETE_SKILL = gql`
  mutation DeleteSkill($where: SkillWhereUniqueInput!) {
    deleteOneSkill(where: $where) {
      ...skill
    }
  }
  ${SKILL}
`;

export const Q_GET_SKILL_LEVELS = gql`
  query GetSkillLevels($where: SkillLevelWhereInput, $limit: Int, $skip: Int, $orderBy: [SkillLevelOrderByInput]) {
    skillLevels(where: $where, limit: $limit, skip: $skip, orderBy: $orderBy) {
      ...skillLevel
    }
  }
  ${SKILL_LEVEL}
`;

export const Q_COUNT_SKILL_LEVELS = gql`
  query CountSkillLevels($where: SkillLevelWhereInput) {
    skillLevelsCount(where: $where)
  }
`;

export const M_CREATE_SKILL_LEVELS = gql`
  mutation CreateSkillLevels($subjectId: ID!, $accountIds: [ID!]!) {
    createSkillLevels(skillSubjectId: $subjectId, accountIds: $accountIds) {
      ...skillLevel
    }
  }
  ${SKILL_LEVEL}
`;

export const M_UPDATE_SKILL_LEVEL = gql`
  mutation UpdateSkillLevel($where: SkillLevelWhereUniqueInput!, $data: SkillLevelUpdateInput!) {
    updateSkillLevel(where: $where, data: $data) {
      ...skillLevel
    }
  }
  ${SKILL_LEVEL}
`;

export const Q_GET_SKILL_LEVEL_MEMBERS = gql`
  query GetSkillLevelMembers(
    $subjectId: ID!
    $nameContains: String
    $labelValuesIn: [String]
    $limit: Int
    $skip: Int
  ) {
    getSkillLevelMembers(
      skillSubjectId: $subjectId
      nameContains: $nameContains
      labelValuesIn: $labelValuesIn
      limit: $limit
      skip: $skip
    ) {
      _id
      username
      name
      photo
      email
      labelValues {
        ...labelValue
      }
    }
  }
  ${LABEL_VALUE}
`;

export const M_REMOVE_SKILL_LEVELS = gql`
  mutation RemoveSkillLevels($subjectId: ID!, $accountIds: [ID!]!) {
    removeSkillLevels(skillSubjectId: $subjectId, accountIds: $accountIds) {
      ...skillLevel
    }
  }
  ${SKILL_LEVEL}
`;

export const Q_GET_MATRIX_HEADER = gql`
  query GetMatrixHeader($where: SkillGroupWhereInput, $limit: Int, $skip: Int, $orderBy: [SkillGroupOrderByInput]) {
    skillGroups(where: $where, orderBy: $orderBy, limit: $limit, skip: $skip) {
      _id
      name
      skills {
        _id
        name
      }
    }
  }
`;

export const Q_GET_MATRIX_DATA = gql`
  query GetMatrixData($skillSubjectId: ID!, $where: SkillMatrixWhere) {
    getMatrixData(skillSubjectId: $skillSubjectId, where: $where) {
      headers {
        skillGroupId
        name
        subheaders {
          skillId
          name
        }
      }
      data {
        accountId
        username
        photo
        levels {
          ...skillLevel
        }
      }
    }
  }
  ${SKILL_LEVEL}
`;
