import { Delete } from '@mui/icons-material';
import { CircularProgress, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useUnDeletePostMutation } from 'src/gql/graphql';
import useAtomicCallback from 'src/utils/hooks/useAtomicCallback';
import { PostType, undoSoftDeletePostState } from '../../feed.redux';

export function HiddenPost({ post }: { post: PostType }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [undoSoftDeletePost, { loading }] = useUnDeletePostMutation();

  const undoDeletePostHandler = useAtomicCallback(
    () =>
      undoSoftDeletePost({
        variables: {
          id: post._id,
        },
        onCompleted(data) {
          if (data.unDeleteOnePost) {
            dispatch(undoSoftDeletePostState(post._id));
          }
        },
      }),
    [post._id],
  );

  return (
    <div
      id={`full-delete-post-div-${post.subject}`}
      data-testid={`full-delete-post-div-${post.subject}`}
      css={{
        borderRadius: 12,
        margin: 8,
        padding: 12,
        gap: 16,
        backgroundColor: theme.palette.background.background0,
        display: 'flex',
        flexFlow: 'row nowrap',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
        width: '681px',
        maxWidth: '80%',
      }}
    >
      <Delete color={'error'} />
      <span>{t('postDeleted')}</span>
      <span
        id={`hidden-post-${post.subject}`}
        data-testid={`hidden-post-${post.subject}`}
        onClick={undoDeletePostHandler}
        css={{
          marginLeft: 'auto',
          color: theme.palette.primary.dark,
          fontSize: 14,
          '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
          },
        }}
      >
        {loading ? <CircularProgress /> : t('undo')}
      </span>
    </div>
  );
}
