import { File } from 'src/gql/graphql';
import { styles } from './styles';
import DocumentIcon from '../DocumentIcon';
import { useTranslation } from 'react-i18next';
import { convertBytesToString } from 'src/utils/funcs';
import { CircularProgress, Paper, Typography, useTheme } from '@mui/material';
import { Delete, Download } from '@mui/icons-material';
import { ConnectedProps, connect } from 'react-redux';
import { downloadFile } from 'src/modules/file-storage/file-storage.redux';
import { getFileExtension } from '../../utils/show-file-util-functions';

interface IDocumentItemProps extends ConnectedProps<typeof connector> {
  file: File;
  showDownload?: boolean;
  delete?: (file: File) => void;
  setOpenCarousel?: (file: File) => void;
  uploadProgress?: number;
}

const DocumentItem = (props: IDocumentItemProps) => {
  const { file } = props;
  const classes = styles();
  const theme = useTheme();
  const { t } = useTranslation();

  const onClickDownloadFile = (file: File): void => {
    props.downloadFile(file.download?.url || file.name, file._id, file.download?.validUntil || undefined);
  };

  return (
    <Paper variant='outlined' className={classes.document}>
      <div
        className={classes.baseContainer}
        onClick={() => {
          props.setOpenCarousel?.(file);
        }}
      >
        <div className={classes.topContainer}>
          <DocumentIcon
            color={theme.palette.primary.main}
            width={16}
            height={20}
            extension={getFileExtension(file.name)}
          />
          <Typography className={classes.documentName}>{file.name}</Typography>
        </div>
        <div className={classes.bottomContainer}>
          <Typography
            className={classes.documentDescription}
          >{`${t(file.extension ?? (file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length) || file.name))} ${t('doc')} | ${convertBytesToString(file.size)}`}</Typography>
          {!!props.uploadProgress && <CircularProgress variant='determinate' value={props.uploadProgress} size={12} />}
        </div>
      </div>
      {props.delete && (
        <Delete
          className={classes.iconDelete}
          onClick={() => {
            props.delete(file);
          }}
        />
      )}
      {props.showDownload && (
        <Download onClick={() => onClickDownloadFile(file)} className={classes.iconDownload}></Download>
      )}
    </Paper>
  );
};

const connector = connect(null, {
  downloadFile,
});

export default connector(DocumentItem);
