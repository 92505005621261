import { Close } from '@mui/icons-material';
import { Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dateRangePresets } from '../custom-date-dropdown/utils/custom-date-dropdown.utils';
import { GlarFilterMap } from './glar-filter.context';
import { filterDateFormat } from './helper';
import styles from './styles';

const useStyles = makeStyles(styles);

interface GlarFilterToastBarsProps {
  filters: GlarFilterMap;
  setFilters: (filters: GlarFilterMap) => any;
}

export default function GlarFilterToastBars({ filters, setFilters }: GlarFilterToastBarsProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const divRef = useRef<HTMLDivElement | null>(null);

  const [visibleDivChildren, setVisibleDivChildren] = useState(0);
  const [extraFilters, setExtraFilters] = useState(0);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      checkVisibleChildren();
    });

    observer.observe(divRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const checkVisibleChildren = () => {
    if (divRef.current) {
      const children = Array.from(divRef.current.children);
      const parentRect = divRef.current.getBoundingClientRect();

      const visibleCount = children.filter((child) => {
        const rect = (child as HTMLElement).getBoundingClientRect();
        if (!parentRect) return false;

        return rect.top >= parentRect.top && rect.bottom <= parentRect.bottom;
      }).length;

      setVisibleDivChildren(visibleCount);
    }
  };

  useEffect(() => {
    checkVisibleChildren();
  }, [filters, extraFilters]);

  const content = useMemo(() => {
    return Object.entries(filters).map(([accessor, value], index) => {
      const chipsToFrom = [];
      const chipsDate = [];
      const valueToDisplay = Array.isArray(value) ? value[0] : value;
      const filterCounter = Array.isArray(value) ? value.length - 1 : 0;
      switch (true) {
        case !valueToDisplay:
          return [];
        case typeof valueToDisplay === 'string' || typeof valueToDisplay === 'number':
          return (
            <Chip
              size='small'
              key={index}
              data-testid={`filter-chip-${valueToDisplay}`}
              classes={{ root: classes.chip }}
              deleteIcon={<Close data-testid={`remove-filter-chip-${valueToDisplay}`} />}
              onDelete={() => {
                setFilters({
                  ...filters,
                  [accessor]: undefined,
                });
              }}
              label={`${t(accessor === 'glarID_contains' ? 'search' : accessor)}: ${
                moment(valueToDisplay, filterDateFormat, true).isValid()
                  ? moment(valueToDisplay).format(filterDateFormat)
                  : Array.isArray(value)
                    ? t(valueToDisplay as string) + (filterCounter > 0 ? ` +${filterCounter}` : '')
                    : valueToDisplay
              }`}
            />
          );

        case !!(valueToDisplay.from || valueToDisplay.to):
          if (valueToDisplay?.from) {
            chipsToFrom.push(
              <Chip
                data-testid={`filter-chip-from-${valueToDisplay.from}`}
                size={'small'}
                key={`${index}-from`}
                classes={{ root: classes.chip }}
                deleteIcon={<Close />}
                onDelete={() => {
                  setFilters({
                    ...filters,
                    [accessor]: valueToDisplay.to ? { ...valueToDisplay, from: undefined } : undefined,
                  });
                }}
                label={`${t(accessor)} ${t('from')}: ${moment(valueToDisplay.from).format(filterDateFormat)}`}
              />,
            );
          }
          if (valueToDisplay?.to) {
            chipsToFrom.push(
              <Chip
                data-testid={`filter-chip-to-${valueToDisplay.to}`}
                size={'small'}
                key={`${index}-to`}
                classes={{ root: classes.chip }}
                deleteIcon={<Close />}
                onDelete={() => {
                  setFilters({
                    ...filters,
                    [accessor]: valueToDisplay.from ? { ...valueToDisplay, to: undefined } : undefined,
                  });
                }}
                label={`${t(accessor)} ${t('to')}: ${moment(valueToDisplay.to).format(filterDateFormat)}`}
              />,
            );
          }

          return chipsToFrom;

        case !!(valueToDisplay.startDate || valueToDisplay.endDate):
          if (valueToDisplay?.startDate) {
            chipsDate.push(
              <Chip
                data-testid={`filter-chip-from-${valueToDisplay.startDate}`}
                size={'small'}
                key={`${index}-startDate`}
                classes={{ root: classes.chip }}
                deleteIcon={<Close />}
                onDelete={() => {
                  setFilters({
                    ...filters,
                    [accessor]: valueToDisplay.endDate ? { ...valueToDisplay, startDate: undefined } : undefined,
                  });
                }}
                label={`${t(accessor)} ${t('from')}: ${moment(valueToDisplay.startDate).format(filterDateFormat)}`}
              />,
            );
          }

          if (valueToDisplay?.endDate) {
            chipsDate.push(
              <Chip
                data-testid={`filter-chip-to-${valueToDisplay.endDate}`}
                size={'small'}
                key={`${index}-endDate`}
                classes={{ root: classes.chip }}
                deleteIcon={<Close />}
                onDelete={() => {
                  setFilters({
                    ...filters,
                    [accessor]: valueToDisplay.startDate ? { ...valueToDisplay, endDate: undefined } : undefined,
                  });
                }}
                label={`${t(accessor)} ${t('to')}: ${moment(valueToDisplay.endDate).format(filterDateFormat)}`}
              />,
            );
          }

          return chipsDate;
        case !!(valueToDisplay.type && valueToDisplay[valueToDisplay.type]):
          return (
            <Chip
              key={`${index}-${valueToDisplay.type}`}
              data-testid={`filter-chip-${valueToDisplay.type}`}
              size='small'
              classes={{ root: classes.chip }}
              deleteIcon={<Close />}
              onDelete={() => {
                setFilters({ ...filters, [accessor]: undefined });
              }}
              label={`${t(accessor)}: ${t(
                `${dateRangePresets.find((d) => d.type === valueToDisplay.type && d[d.type] === valueToDisplay[valueToDisplay.type])?.name}`,
              )}`}
            />
          );

        case Object.values(value).every((v) => v == null):
          return [];
        default: {
          const filterCounter = value.length - 1;
          const name =
            valueToDisplay.name ||
            valueToDisplay.value ||
            valueToDisplay.title ||
            valueToDisplay.address?.site?.name ||
            valueToDisplay.address?.element?.name ||
            valueToDisplay.address?.street;

          return (
            <Chip
              key={index}
              size='small'
              data-testid={`filter-chip-${name}`}
              classes={{ root: classes.chip }}
              deleteIcon={<Close data-testid={`remove-filter-chip-${name}`} />}
              onDelete={() => {
                setFilters({
                  ...filters,
                  [accessor]: undefined,
                });
              }}
              label={`${t(accessor)}: ${name} ${filterCounter > 0 ? '+' + filterCounter : ''}`}
            />
          );
        }
      }
    });
  }, [filters]);

  const displayContent = Array.from(content).flat();

  useEffect(() => {
    const hiddenCount = displayContent.length - visibleDivChildren;
    setExtraFilters(hiddenCount > 0 ? hiddenCount : 0);
  }, [visibleDivChildren, filters]);

  if (extraFilters > 0) {
    displayContent.splice(
      visibleDivChildren - 1,
      0,
      <Chip
        key={'extra-chip'}
        size={'small'}
        data-testid={`filter-chip-extra-filters`}
        classes={{ root: classes.chip }}
        label={`+${extraFilters}`}
      />,
    );
  }

  return (
    <div data-testid='main-filters-div' ref={divRef}>
      {displayContent && Object.keys(displayContent).length !== 0 && (
        <div data-testid='current-filters' className={classes.filterToastBar}>
          {displayContent}
        </div>
      )}
      <div data-testid='no-filters-detected'></div>
    </div>
  );
}
