import { ColumnOrderState } from '@tanstack/react-table';
import { ColumnDragPosition } from '../../../enums/table.enums';

export const reorderColumn = (
  draggedColumnId: string,
  targetColumnId: string,
  columnOrder: string[],
  hoverPosition: ColumnDragPosition,
): ColumnOrderState => {
  const indexTargetColumn = columnOrder.indexOf(targetColumnId);
  const indexDraggedColumn = columnOrder.indexOf(draggedColumnId);

  let newIndex = indexTargetColumn;
  if (hoverPosition === ColumnDragPosition.Left && indexDraggedColumn < indexTargetColumn) {
    newIndex -= 1;
  }

  if (hoverPosition === ColumnDragPosition.Right && indexDraggedColumn > indexTargetColumn) {
    newIndex += 1;
  }

  columnOrder.splice(newIndex, 0, columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0] as string);

  return [...columnOrder];
};
