import { MainContainerState } from 'src/base/main-container/context/main.container.context';
import { startTutorial } from './onBoarding.utils.startTutorial';

/**
 * Used to return to on boarding mode and return to the progress of the user if he had any,
 * if he had already finished it, it will be as if he was doing a new one
 * @param props
 * @param history
 * @param updateMainContainerState
 */
export const resumeTutorial = (
  props,
  navigate,
  updateMainContainerState: (newData: Partial<MainContainerState>) => void,
) => {
  switch (props.loggedUser?.onBoarding?.step) {
    case 'unInitialized':
      startTutorial(props, updateMainContainerState, navigate);
      break;
    case 'build':
      props.updateLoggedUser(
        {
          id: props.loggedUser._id,
          onBoarding: {
            ...props.loggedUser?.onBoarding,
            templateDraft: props.loggedUser?.onBoarding?.templateDraft._id,
            template: null,
            openIssueDraft: null,
            openIssue: null,
            issueInstance: null,
            doingOnboarding: true,
          },
        },
        false,
      );
      navigate(`/templates/issues/issue?type=issue&id=${props.loggedUser?.onBoarding?.templateDraft._id}&edit=true`);
      break;
    case 'createIssue':
    case 'createTasks':
      props.updateLoggedUser(
        {
          id: props.loggedUser._id,
          onBoarding: {
            ...props.loggedUser?.onBoarding,
            templateDraft: null,
            template: props.loggedUser?.onBoarding?.template._id,
            openIssueDraft: props.loggedUser?.onBoarding?.openIssueDraft._id,
            openIssue: null,
            issueInstance: null,
            doingOnboarding: true,
          },
        },
        false,
      );
      navigate(`/open?type=draft&idIssue=${props.loggedUser?.onBoarding?.openIssueDraft._id}&edit=true`);
      break;
    case 'execute':
      props.updateLoggedUser(
        {
          id: props.loggedUser._id,
          onBoarding: {
            ...props.loggedUser?.onBoarding,
            templateDraft: null,
            template: props.loggedUser?.onBoarding?.template._id,
            openIssueDraft: null,
            openIssue: props.loggedUser?.onBoarding?.openIssue._id,
            issueInstance: props.loggedUser?.onBoarding?.issueInstance._id,
            doingOnboarding: true,
          },
        },
        false,
      );
      navigate(
        `/execution?name=${props.loggedUser?.onBoarding?.issueInstance.name}&id=${props.loggedUser?.onBoarding?.issueInstance._id}`,
      );
      break;
  }
  updateMainContainerState({
    hideSteps: false,
    doingOnBoarding: true,
  });
};
