import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

export const styles = makeStyles((theme: Theme) =>
  createStyles({
    swiper: {
      padding: '0 4px 0 4px',
    },
    plusDiv: {
      position: 'absolute',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    plusText: {
      fontFamily: 'Poppins',
      color: theme.palette.background.default,
      fontSize: '22px',
      lineHeight: '32px',
      fontWeight: '500',
    },
    editIcon: {
      color: theme.palette.background.default,
      fontSize: '16px !important',
      top: '4px',
      left: '4px',
      position: 'absolute',
    },
    image: {
      width: '107px',
      height: '112px',
      objectFit: 'cover',
      borderRadius: '4px',
    },
    imageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      position: 'relative',
      width: 'fit-content',
      height: 'fit-content',
      flexDirection: 'column-reverse',
    },
    arIcon: {
      color: 'white',
      backgroundColor: 'transparent',
      position: 'absolute',
      fontSize: '35px',
      pointerEvents: 'none',
    },
    closeButton: {
      cursor: 'pointer',
      position: 'absolute',
      border: 'none',
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '50%',
      width: '16px',
      height: '16px',
      top: '-4px',
      right: '-4px',
      padding: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    close: { fontSize: '12px !important', color: theme.palette.background.default },
    imageLoading: { color: theme.palette.primary.main, position: 'absolute' },
    playArrow: {
      position: 'absolute',
      left: 'calc(50% - 20px)',
      color: 'white',
      fontSize: '40px',
      top: 'calc(50% - 20px)',
    },
    playDiv: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0.2)',
      borderRadius: '4px',
    },
  }),
);
