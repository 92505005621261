import { get, isEmpty, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ConnectedProps } from 'react-redux';
import { setPathsAndDefaultRoute } from 'src/modules/account/account.redux';
/* import { hasClearance } from 'src/utils/funcs'; */
import {
  Collapse,
  ListItemIcon,
  ListItem,
  ListItemText,
  List,
  Paper,
  SvgIconTypeMap,
  IconButton,
  Divider,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  ArchiveOutlined,
  ArrowBackIosNew,
  AssignmentLateOutlined,
  AssignmentOutlined,
  AssistantOutlined,
  CalendarToday,
  CategoryOutlined,
  Chat,
  CheckCircleOutline,
  DashboardOutlined,
  ExpandLess,
  ExpandMore,
  FeaturedPlayListOutlined,
  PlaceOutlined,
  SettingsOutlined,
  SupportAgentOutlined,
  ReportProblemOutlined,
  InsertDriveFileOutlined,
  MoreTime,
  RadioButtonUnchecked,
  ViewInAr,
  Groups,
  FeedbackOutlined,
  GroupWorkOutlined,
  FeedOutlined,
} from '@mui/icons-material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styles } from 'src/base/navbar/styles/navbar';
import { toggleSidenav } from 'src/base/root/root.redux';
import { markSeenActionIssue } from 'src/modules/account/account.redux';
import Sidenav from 'src/utils/components/side-bar';
import { typedConnect } from 'src/utils/funcs';
import useEffectEvent from 'src/utils/hooks/useEffectEvent';
import { getLoggedUser, updateFilter } from '../login/login.redux';
import NavbarBadge from './components/navbar.badge';
import React from 'react';

export const entries = {
  Feed: {
    icon: 'FeedOutlined',
    path: '/feed',
  },
  Dashboards: {
    icon: 'Dashboard',
    path: '/dashboards',
  },
  REPORTS: {
    icon: 'InsertDriveFileOutlined',
    children: {
      'Issue report': {
        path: '/issue-report',
      },
      'Site/Element report': {
        path: '/site-element-report',
      },
    },
  },
  Agenda: {
    icon: 'CalendarToday',
    path: '/issues',
  },
  ISSUES: {
    icon: 'Build',
    children: {
      // Planning: {
      //   order: 0,
      //   path: '/planning',
      // },
      Scheduled: {
        path: '/scheduled',
      },
      Open: {
        path: '/open',
      },
    },
  },
  TEMPLATES: {
    icon: 'AssignmentOutlined',
    children: {
      Issues: {
        path: '/templates/issues',
      },
      Sites: {
        path: '/templates/sites',
      },
      Elements: {
        path: '/templates/elements',
      },
      Profiles: {
        path: '/templates/profiles',
      },
    },
  },
  APPROVALS: {
    icon: 'CheckCircleOutline',
    path: '/approvals',
  },
  ACTIONS: {
    icon: 'FeedbackOutlined',
    path: '/actions',
  },
  ALERTS: {
    icon: 'ReportProblemOutlined',
    path: '/alerts',
  },
  'SITES/ELEMENTS': {
    icon: 'LocationOn',
    path: '/sites',
  },
  PROFILES: {
    icon: 'FeaturedVideo',
    path: '/profiles',
  },
  TIMESHEETS: {
    icon: 'MoreTime',
    path: '/timesheets',
  },
  SKILLS: {
    icon: 'RadioButtonUnchecked',
    path: '/skills',
  },
  INVENTORY: {
    icon: 'ArchiveOutlined',
    children: {
      Storages: {
        path: '/storages',
      },
      Materials: {
        path: '/materials',
      },
      Tools: {
        path: '/tools',
      },
    },
  },
  ADMINISTRATION: {
    icon: 'Settings',
    children: {
      Labels: {
        path: '/labels',
      },
      Authentication: {
        children: {
          LDAP: {
            path: '/ldap',
          },
          'SAML SSO': {
            path: '/samlsso',
          },
          'Accounts Manager': {
            path: '/accounts-manager',
          },
          Roles: {
            path: '/roles',
          },
        },
      },
      Devices: {
        path: '/devices',
      },
      Settings: {
        path: '/settings',
      },
    },
  },
  AR: {
    icon: 'ViewInAr',
    children: {
      'AR images': {
        path: '/ar-image',
      },
      'AR mapping': {
        path: '/ar-mapping',
      },
      'media-library': {
        path: '/media-library',
      },
    },
  },
  DIVIDER: {},
  CHAT: {
    icon: 'Chat',
    path: '/chat',
  },
  REMOTEASSIST: {
    icon: 'SupportAgentOutlined',
    path: '/remote-assistance',
  },
};

const adminEntries = {
  Dashboards: {
    icon: 'Dashboard',
    path: '/dashboards',
  },
  Tenants: {
    icon: 'Groups',
    path: '/tenants',
  },
};

function capitalize(s: string): string {
  return s[0].toUpperCase() + s.slice(1).toLowerCase();
}

export const icons: Record<string, OverridableComponent<SvgIconTypeMap>> = {
  FeedOutlined,
  InsertDriveFileOutlined,
  Settings: SettingsOutlined,
  Build: GroupWorkOutlined,
  Warning: AssignmentLateOutlined,
  LocationOn: PlaceOutlined,
  AccountTree: CategoryOutlined,
  FeaturedVideo: FeaturedPlayListOutlined,
  AssistantOutlined,
  SupportAgentOutlined,
  ReportProblemOutlined,
  Dashboard: DashboardOutlined,
  ArchiveOutlined,
  FeedbackOutlined,
  CheckCircleOutline,
  RadioButtonUnchecked,
  AssignmentOutlined,
  MoreTime,
  ViewInAr,
  Chat,
  Groups,
  CalendarToday,
};

type NavbarProps = ConnectedProps<typeof connector>;

interface NavItem {
  order: any;
  path: string;
  icon: string;
  name: string;
  children: NavItem[];
  type?: string;
}

const connector = typedConnect(
  (state) => ({
    loggedUser: state.loginReducer.loggedUser,
    clearances: state.loginReducer.clearances,
    sidenavMinimized: state.rootReducer.sidenavMinimized,
    currentSite: state.accountReducer.currentSite,
    tenantInfo: state.authenticationReducer.tenantInfo,
  }),
  {
    setPathsAndDefaultRoute,
    toggleSidenav,
    getLoggedUser,
    markSeenActionIssue,
    updateFilter,
  },
);

const useStyles = makeStyles(styles);

const Navbar: React.FC<NavbarProps> = (props) => {
  const {
    loggedUser,
    getLoggedUser,
    sidenavMinimized,
    currentSite,
    clearances,
    /* setPathsAndDefaultRoute, */
    toggleSidenav,
    tenantInfo,
  } = props;
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedMenu, setSelectedMenu] = useState<Record<string, boolean>>({});
  const { t } = useTranslation();
  let availablePaths: string[] = [];
  const showConfMenu: boolean = false;

  useEffect(() => {
    if (!loggedUser) getLoggedUser();
  }, []);

  const clickMenu = useEffectEvent((menuKey: string) => {
    const newState = {};
    newState[`${menuKey}`] = !selectedMenu[`${menuKey}`];
    Object.keys(selectedMenu)
      .filter((key) => !menuKey.includes(key))
      .forEach((key) => (newState[`${key}`] = key === menuKey && !selectedMenu[`${key}`]));
    setSelectedMenu({ ...selectedMenu, ...newState });
  });

  const fieldIcon = (name: string) => {
    const Icone = icons[`${name}`];
    return Icone ? <Icone /> : null;
  };

  const renderNavbar = (keyMap, context = '', level = 0) =>
    map(keyMap, (entry: NavItem, name: string) => {
      const isMenu = get(entry, 'children');
      const key = `${context}-${name}`;

      if (name === 'REMOTEASSIST' && !tenantInfo?.isRemoteAssistAllowed) return;
      if (name === 'DIVIDER') return <Divider key={name} sx={{ margin: '5px auto', width: '100%' }} />;

      if (keyMap[`${name}`].path && isEmpty(availablePaths.filter((path) => path === keyMap[`${name}`].path)))
        availablePaths = [...availablePaths, keyMap[`${name}`].path];

      if (isMenu && !isEmpty(get(entry, 'children')))
        return (
          <div key={key}>
            {' '}
            <ListItem id={key} button onClick={(): void => clickMenu(key)} color='lightGray'>
              <ListItemIcon classes={{ root: classes.item }}>
                {entry.icon !== 'dashboard' && fieldIcon(entry.icon)}
              </ListItemIcon>
              <ListItemText primary={t(capitalize(name))} />
              {name === 'Agenda' && !!props.loggedUser.issues && (
                <div style={{ marginRight: '6px' }}>
                  <NavbarBadge value={props.loggedUser.issues} />
                </div>
              )}

              {selectedMenu[`${key}`] === true ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={selectedMenu[`${key}`] === true} timeout='auto' unmountOnExit>
              <List component='div' disablePadding className={classes.nested}>
                {renderNavbar(entry.children, key, level + 1)}
              </List>
            </Collapse>
          </div>
        );

      return loggedUser.clientMixedRealityEnabled && name === 'AR images' ? (
        <ListItem style={{ display: 'none' }} key={key} />
      ) : (
        <ListItem
          button
          key={key}
          id={key}
          /* eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with */
          selected={location.pathname === entry.path}
          onClick={async () => {
            if (name === 'Agenda' || name === 'ACTIONS') {
              const context = name === 'Agenda' ? 'issue' : 'action';
              const allTabFilter = props.loggedUser?.filters.find((f) => f.name === 'All' && f.context === context);
              if (allTabFilter) {
                const updatedFilter = {
                  filterId: allTabFilter._id,
                  name: allTabFilter.name,
                  columns: allTabFilter.columns,
                  context: allTabFilter.context,
                  filters: [],
                  order: allTabFilter.order,
                };
                await props.updateFilter(updatedFilter);
              }
            }
            navigate(entry.path);
          }}
          classes={{ selected: classes.active }}
        >
          {/* eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with */}
          <ListItemIcon classes={location.pathname === entry.path ? { root: classes.active } : { root: classes.item }}>
            {context === '' && entry.icon !== 'dashboard' && fieldIcon(entry.icon)}
          </ListItemIcon>
          <ListItemText primary={name === 'AR images' || name === 'media-library' ? t(name) : t(capitalize(name))} />
          {name === 'ACTIONS' && !!props.loggedUser.actions && <NavbarBadge value={props.loggedUser.actions} />}

          {name === 'Feed' && props.loggedUser.feed > 0 && (
            <div className={classes.badge}>{props.loggedUser.feed >= 100 ? '99+' : props.loggedUser.feed}</div>
          )}

          {name === 'CHAT' && props.loggedUser.chat > 0 && (
            <div className={classes.badge}>{props.loggedUser.chat >= 100 ? '99+' : props.loggedUser.chat}</div>
          )}
        </ListItem>
      );
    });

  const actualEntries = useMemo(() => (loggedUser.isPlatformAdmin ? adminEntries : entries), [loggedUser]);

  const navbar = useMemo(
    () => renderNavbar(actualEntries),
    [actualEntries, loggedUser, selectedMenu, location, tenantInfo],
  );

  return sidenavMinimized && (currentSite || loggedUser.isPlatformAdmin) ? (
    <div key={JSON.stringify(location)}>
      <Sidenav
        entries={actualEntries}
        loggedUser={loggedUser}
        clearances={clearances}
        showConfMenu={showConfMenu}
        availablePaths={availablePaths}
      />
    </div>
  ) : !currentSite && !loggedUser.isPlatformAdmin ? null : (
    <Paper elevation={3} id='navbar' classes={{ root: classes.paper }}>
      <>
        <List component='nav' aria-labelledby='nested-list-subheader' className={`${classes.list} sidebar-nav`}>
          {navbar}
        </List>
        {/* {setPathsAndDefaultRoute(availablePaths)} */}
        <IconButton onClick={() => toggleSidenav(!sidenavMinimized)} className={classes.arrowButton} size='large'>
          <ArrowBackIosNew />
        </IconButton>
      </>
    </Paper>
  );
};

export default connector(Navbar);
