import { Action, State } from 'src/interfaces/reducers';
import { getErrorObject } from 'src/utils/funcs';
import * as queries from 'src/modules/alert/alert.queries';
import { APOLLO_CLIENT } from 'config/apollo.config';

const initialState: State = {};

export default (_state: State = initialState, action: Action): State => {
  switch (action.type) {
    default:
      return {};
  }
};

export const getAlerts = (where?: any, limit?: number, skip?: number, orderBy?: number) => async (dispatch: any) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
        limit,
        skip: skip !== undefined && skip < 0 ? 0 : skip,
        orderBy,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ALERTS,
    });

    return { alerts: response.data.alerts, alertsCount: response.data.alertsCount };
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getAlert = (where?: { _id_eq: string }) => async (dispatch: any) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        where,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ALERT,
    });
    return response.data.alerts[0];
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const closeAlert = (where, data) => async (dispatch: any) => {
  try {
    const resp = await APOLLO_CLIENT.mutate({
      variables: {
        where,
        data,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_CLOSE_ALERT,
    });
    return resp.data.closeAlert;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};
