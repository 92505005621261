import { makeStyles, withStyles } from '@mui/styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { styles } from './styles';
import { useMemo, useRef } from 'react';
import { FilterDataType } from '../..';
import { ExpandMore } from '@mui/icons-material';
import BetterFieldPopover from 'src/utils/components/better-field-popover';
import CustomDateDropdown from 'src/utils/components/custom-date-dropdown';
import StatusPopover from './components/status-popover';
import { DateRange } from 'src/utils/components/custom-date-range-picker';

type SelectFilterValueProps = {
  filterData: FilterDataType[];
  index: number;
  context: string;
  setFilterData: (val: FilterDataType[]) => void;
} & WithTranslation;

const useStyles = makeStyles(styles);
const SelectFilterValue: React.FC<SelectFilterValueProps> = (props): JSX.Element => {
  const { t, setFilterData, filterData, index, context } = props;
  const classes = useStyles();
  const anchorRef = useRef();
  //const [openPopover, setOpenPopover] = useState<boolean>(false);
  const filterType = useMemo(() => filterData[index].type, [filterData]);

  // const handleOpen = () => {
  //   setOpenPopover(true);
  // };

  const handleChangeValues = (vals: any, date: boolean) => {
    const updatedFilterData = [...filterData];
    const correctDate = {
      type: 'custom',
      custom: {
        from: vals?.startDate,
        until: vals?.endDate,
      },
    };

    updatedFilterData[index] = {
      ...updatedFilterData[index],
      values: date || vals?.length === 0 ? null : vals,
      dateValue: date ? (vals?.startDate ? correctDate : vals) : null,
    };
    setFilterData(updatedFilterData);
  };

  const displayValueOptions = () => {
    let correctValueComponent: JSX.Element;

    switch (filterType) {
      case 'executedBy':
      case 'assignedTo':
        correctValueComponent = (
          <BetterFieldPopover
            classes={{ root: classes.betterFieldPopover }}
            context={'assignee'}
            data-testid={'select-account-filter'}
            multiple
            placeholder={t('selectOption')}
            onChange={(accounts) => {
              handleChangeValues(accounts, false);
            }}
            value={filterData[index].values}
          />
        );
        break;
      case 'status':
      case 'workOrderStatus':
        correctValueComponent = (
          <StatusPopover filterData={filterData} setFilterData={setFilterData} context={context} index={index} />
        );
        break;
      case 'labels':
        correctValueComponent = (
          <BetterFieldPopover
            classes={{ root: classes.betterFieldPopover }}
            context={context === 'issues' ? 'labelIssue' : 'labelAction'}
            data-testid={'select-account-filter'}
            multiple
            placeholder={t('selectOption')}
            onChange={(labels) => {
              handleChangeValues(labels, false);
            }}
            value={filterData[index].values}
          />
        );
        break;
      case 'siteElement':
        correctValueComponent = (
          <BetterFieldPopover
            classes={{ root: classes.betterFieldPopover }}
            context={'siteElement'}
            data-testid={'select-account-filter'}
            multiple
            placeholder={t('selectOption')}
            onChange={(asset) => {
              handleChangeValues(asset, false);
            }}
            value={filterData[index].values}
          />
        );
        break;
      default:
        correctValueComponent = (
          <CustomDateDropdown
            customClass={classes.mainContainer}
            placeholder={t('selectOption')}
            timeSpan={filterData[index].dateValue}
            setTimeSpan={(val: DateRange) => handleChangeValues(val, true)}
            withFullCalendar={true}
          />
        );
        break;
    }

    return correctValueComponent;
  };

  return !filterType ? (
    <div className={classes.mainContainer} ref={anchorRef} key={'timespan-current-selected'}>
      <span className={classes.placeholder}>{t('selectOption')}</span>
      <ExpandMore />
    </div>
  ) : (
    displayValueOptions()
  );
};

export default withTranslation('translation')(withStyles(styles)(SelectFilterValue));
