import {
  Button,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Paper,
  Tooltip,
  Popover,
  Box,
  CircularProgress,
  Backdrop,
  TooltipProps,
  useTheme,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  Refresh,
  Edit,
  FilterList,
  Settings,
  Fullscreen as Full,
  UnfoldMore,
  VisibilityOff,
} from '@mui/icons-material';
import { Classes } from '@mui/styles/mergeClasses/mergeClasses';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import i18n from 'src/utils/translations/i18n';
import {
  getIssueTemplates,
  getTimeMap,
  getDraftTimeMap,
  getIssueTemplateToHeatmap,
  getTimeMapColumns,
  getHeatmapConfigurationDrafts,
  getHeatmapConfigurations,
  createHeatmapConfigurationDraft,
} from 'src/modules/issue-templates/issue.templates.redux';
import { styles } from 'src/utils/components/heat-map/styles';
import queryString from 'query-string';
import EditTable from 'src/utils/components/heat-map/edit-table';
import HeatMapColors from './heat-map-colors';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import {
  extractSelectedLabelValues,
  getLabelsNames,
  getStyledTableCellColorFilter,
} from 'src/utils/components/heat-map/utils';
import { isEqual } from 'lodash';
import { IssueTemplate, IssueTemplateDraft } from 'src/gql/graphql';
import HeatmapFilterPopover, { HeatmapFilters } from '../glar-filter-popover/preset-filters/heatmap-filter';

export interface HeatmapConfigurationDraft {
  _id?: string;
  taskTime: number;
  issueTemplate?: string;
  issueTemplateDraft: string;
  colorsRange: {
    min: number;
    max: number;
    color: string;
  }[];
}

export interface HeatmapConfiguration {
  _id?: string;
  taskTime: number;
  issueTemplate: IssueTemplate;
  colorsRange: {
    min: number;
    max: number;
    color: string;
  }[];
}

interface Props extends ConnectedProps<typeof connector> {
  classes: Classes;
  getTimeMap: any;
  getDraftTimeMap: any;
}
interface ColorObject {
  min: number;
  max: number;
  color: string;
}

const StyledTableCellHeader = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background.background1,
    color: theme.palette.text.primary,
    border: `0.5px solid ${theme.palette.text.secondary}`,
    height: '40px',
    padding: 0,
  },
  body: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'center',
    height: '40px',
  },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 14,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    border: `0.5px solid ${theme.palette.text.secondary}`,
    maxWidth: 150,
    padding: 0,
    height: '40px',
  },
}))(TableCell);

interface columns {
  columns: {
    groupId: string;
    time: number;
  }[];
}

const HeatMap = (props: Props): JSX.Element => {
  const { classes } = props;
  const location = useLocation();
  const templateID: string = queryString.parse(location.search)?.id as string;
  const isDraft = queryString.parse(location.search)?.isDraft;
  const [columns, setColumns] = useState<columns[]>([]);
  const [columnsFilter, setColumnsFilter] = useState<columns[]>([]);
  const [template, setTemplate] = useState<IssueTemplate | IssueTemplateDraft>(null);
  const [headerArray, setHeaderArray] = useState<any>([]);
  const [editTable, setEditTable] = useState<boolean>(false);
  const [heatMapFilter, setHeatMapFilter] = useState<boolean>(false);
  const [filters, setFilters] = useState<HeatmapFilters>({});
  const [colorModalOpen, setColorModalOpen] = useState<boolean>(false);
  const [colorFilters, setColorFilter] = useState<ColorObject[]>([]);
  const [taskTime, setTaskTime] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [heatmapConfigurationDraft, setHeatmapConfigurationDraft] = useState<HeatmapConfigurationDraft>(null); //
  const [refresh, setRefresh] = useState<boolean>(false);
  const TEN_MINUTES_MS = 600000;
  const [choosedGroups, setChoosedGroups] = useState([]);
  const [hiddenGroups, setHiddenGroups] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [choosedColumns, setChoosedColumns] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [anchorElColumn, setAnchorElColumn] = useState(null);
  const [headerNames, setHeaderNames] = useState([]);
  const [colsToEdit, setColsToEdit] = useState([]);
  const [loadingTimeMap, setLoadingTimeMap] = useState(true);
  const [loadingIssueTemplate, setLoadingIssueTemplate] = useState(true);
  const columnsRef = useRef([]);
  const tableContainerRef = useRef<HTMLDivElement>();
  const [tooltipPositions, setTooltipPositions] = useState<TooltipProps['placement'][]>([]);
  const theme = useTheme();
  const settingsRef = useRef<SVGSVGElement>();

  const handle = useFullScreenHandle();

  /**
   * @description Get heatmap configuration (if exists) and heatmap configuration draft (if exists) of this issue template and set heatmap configuration draft. NewHTConfigData used to create heatmap config draft in case it does not exist for this issue template draft
   * @param { IssueTemplateDraft } templateDraft => Current issue template draft
   */
  const getHeatmapConfigs = (templateDraft: IssueTemplateDraft) => {
    props.getHeatmapConfigurationDrafts(templateDraft._id).then((heatmapConfigDraft) => {
      props
        .getHeatmapConfigurations(templateDraft.updateTemplateId)
        .then(async (resp) => {
          const newHTConfigData = {
            issueTemplate: resp[0].issueTemplateId,
            issueTemplateDraft: templateDraft._id,
            taskTime: resp[0].taskTime,
            colorsRange: resp[0].colorsRange,
          };

          setHeatmapDraft(newHTConfigData, heatmapConfigDraft[0]);
        })
        .catch(async () => {
          // Else, create a new heatmap configuration draft with empty values
          const newHTConfigData = {
            issueTemplate: templateDraft.updateTemplateId,
            // heatmapConfig: data of heatmap; template._id: id of issue template draft
            issueTemplateDraft: templateDraft._id,
            taskTime: 0,
            colorsRange: [],
          };

          setHeatmapDraft(newHTConfigData, heatmapConfigDraft[0]);
        });
    });
  };

  /**
   * @description Set heatmap configuration draft, task time and colors range based on data previously saved on db when entering or reloading the page
   * @param { HeatmapConfigurationDraft } newHTConfigData => new heatmap config data in case heatmap draft doesnt exists
   * @param { HeatmapConfigDraft } heatmapConfigDraft => heatmap configuration draft in case it already exists (can be undefined)
   */
  const setHeatmapDraft = async (
    newHTConfigData: HeatmapConfigurationDraft,
    heatmapConfigDraft: HeatmapConfigurationDraft,
  ) => {
    if (heatmapConfigDraft) {
      setHeatmapConfigurationDraft(heatmapConfigDraft);
      setTaskTime(heatmapConfigDraft.taskTime);
      setColorFilter(heatmapConfigDraft.colorsRange);
    } else {
      props.createHeatmapConfigurationDraft(newHTConfigData).then((newHMConfigDraft) => {
        setHeatmapConfigurationDraft(newHMConfigDraft);
        setTaskTime(newHTConfigData.taskTime);
        setColorFilter(newHTConfigData.colorsRange);
      });
    }
  };

  const handleGetTimeMap = () => {
    if (!loadingTimeMap) setLoadingTimeMap(true);

    if (!loading) setLoading(true);
    if (isDraft === 'false') {
      props.getTimeMap(templateID).then((resp) => {
        setColumns(resp);
        setLoading(false);
        setLoadingTimeMap(false);
      });
    } else {
      props.getDraftTimeMap(templateID).then((resp) => {
        setColumns(resp);
        setLoading(false);
        setLoadingTimeMap(false);
      });
    }
  };

  const refreshTimeMap = () => {
    setRefresh(true);
    setLoading(true);

    if (isDraft) {
      props.getDraftTimeMap(templateID).then((response) => {
        setColumns(response);
        setRefresh(false);
        setLoading(false);
      });
    } else {
      props.getTimeMap(templateID).then((response) => {
        setColumns(response);
        setRefresh(false);
        setLoading(false);
      });
    }
  };

  const handleGetIssueTemplate = async (fromEditTable = false) => {
    if (!loadingIssueTemplate) setLoadingIssueTemplate(true);

    const resp = template
      ? template
      : isDraft === 'false'
        ? await props.getIssueTemplates({ _id_eq: templateID })
        : await props.getIssueTemplateToHeatmap({ _id: templateID });
    //@ts-ignore
    if (resp.graphQLErrors) return;

    setTemplate(Array.isArray(resp) ? resp[0] : resp);

    if (resp) getHeatmapConfigs(Array.isArray(resp) ? resp[0] : resp);

    const timeMapColumns = template
      ? await props.getTimeMapColumns(templateID, isDraft === 'true' ? true : false)
      : resp.timeMapColumns;

    const columnArray = [];
    if (timeMapColumns.length > 0) {
      timeMapColumns.map((column) => {
        const auxColumn = [];

        column.labelValuesIds.map((lvId) => {
          if (lvId) {
            const labelValue = column.labelValues.find((lv) => lv?._id === lvId);
            auxColumn.push({
              label: labelValue?.label,
              labelValue: {
                value: labelValue?.value,
                _id: labelValue?._id,
                backgroundColor: labelValue?.backgroundColor,
              },
            });
          } else {
            auxColumn.push({
              label: null,
              labelValue: null,
            });
          }
        });
        columnArray.push(auxColumn);
      });
    }

    setColsToEdit(columnArray);

    //This is one way to creat a object for the header of the table
    if (timeMapColumns.length) {
      const length = timeMapColumns[0]?.labelValuesIds.length;
      const array = [];
      let labelValuesAux = filters.labels || [];
      let columnsAux = timeMapColumns;
      if (filters.labels?.length === 0 || fromEditTable) {
        labelValuesAux = extractSelectedLabelValues(columnsAux);
        setFilters({ labels: labelValuesAux });
      } else {
        //check if columns have all rows
        columnsAux.map((column) => {
          column.labelValuesIds.map((id, key) => {
            if (!id) return;

            const check = labelValuesAux.find((label) => label?._id === id) ? true : false;
            !check
              ? ((columnsAux = columnsAux.filter((col) => columnsAux.indexOf(col) === key)),
                setColumnsFilter(columns.filter((col) => columns.indexOf(col) === key)))
              : null;
          });
        });
      }

      const headerNames = getLabelsNames(timeMapColumns);

      // const tempNames = [];
      for (let i = 0; i < length; i++) {
        const auxArray = [];

        columnsAux.map((column) => {
          auxArray.push(
            column?.labelValues.find((lv) => lv?._id === column.labelValuesIds[parseInt(i.toString())])?.value || '-',
          );
        });
        array.push(auxArray);
      }

      if (columnsAux.length === columns.length) {
        setColumnsFilter([]);
      }

      setHeaderNames(headerNames);
      setHeaderArray(array);
    }

    setLoadingIssueTemplate(false);
  };

  useEffect(() => {
    handleGetTimeMap();
    handleGetIssueTemplate();
  }, []);

  /**
   * @description Get Time Map data every 10 minutes
   */
  useEffect(() => {
    const interval = setInterval(() => {
      handleGetTimeMap();
    }, TEN_MINUTES_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  const handleCloseDialog = () => {
    setHeatMapFilter(false);
  };

  const parseColumn = (array, rowIdx) => {
    const columns = [];
    let tempIdxs = [];
    let alreadyHidden = [];
    for (let index = 0; index < array.length; index++) {
      if (!hiddenColumns.find((c) => c.idx === index)) tempIdxs.push(index);
      if (array[index] === array[index + 1]) {
        continue;
      } else {
        if (tempIdxs.length) {
          const hiddenBefore = hiddenColumns.filter(
            (c) => c.idx < index && c.rowIdx === rowIdx && !alreadyHidden.includes(c.idx),
          );
          columns.push({ value: array[index], idxs: tempIdxs, hiddenBefore: hiddenBefore, hiddenAfter: [] });
          alreadyHidden = alreadyHidden.concat(hiddenBefore.map((c) => c.idx));
        }
        tempIdxs = [];
      }
    }

    const afterHidden = hiddenColumns.filter((c) => c.rowIdx === rowIdx && !alreadyHidden.includes(c.idx));
    if (afterHidden.length) {
      if (columns[columns.length - 1]) {
        columns[columns.length - 1].hiddenAfter = afterHidden;
      }
    }

    return columns;
  };

  const parseHeaderArray = () => {
    return headerArray
      ? headerArray.map((elem, idx) => {
          return {
            idx: idx,
            value: headerNames[idx],
            columns: parseColumn(elem, idx),
          };
        })
      : [];
  };

  const addHeader = () => {
    const parsedHeaderArray = parseHeaderArray();

    return parsedHeaderArray.map((row, rowIdx) => (
      <TableRow key={rowIdx}>
        <StyledTableCellHeader key={rowIdx} className={classes.groupCell}>
          <Tooltip
            title={row.value}
            placement='top'
            arrow
            classes={{ tooltip: props.classes.tooltipCustom, arrow: props.classes.arrowCustom }}
          >
            <Typography className={classes.headerText}>{row.value}</Typography>
          </Tooltip>
          {columns.length === hiddenColumns.length && rowIdx === parsedHeaderArray.length - 1 ? (
            <div
              className={classes.unfoldIconAfterColumn}
              onClick={() => {
                setHiddenColumns([]);
              }}
            >
              <UnfoldMore style={{ transform: 'rotate(90deg)' }} />
            </div>
          ) : null}
        </StyledTableCellHeader>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyItems: 'left',
            alignItems: 'center',
            minHeight: '40px',
          }}
        >
          {row.columns.map((column, idx) => {
            return addColumnCell(column, idx, rowIdx);
          })}
        </div>
      </TableRow>
    ));
  };

  const addColumnCell = (column, columnIdx, rowIdx) => {
    const selectedColumns = choosedColumns.filter((cCol) => column.idxs.includes(cCol.idx));
    const allSelected = selectedColumns.length === column.idxs.length;

    return (
      <StyledTableCellHeader
        colSpan={column.idxs.length}
        style={{
          width: (120 * column.idxs.length).toString() + 'px',
          position: 'relative',
          background: allSelected ? theme.palette.primary.light : '',
          color: allSelected ? theme.palette.primary.main : '',
          border: allSelected ? `1px solid ${theme.palette.primary.main}` : '',
        }}
        onClick={(e) => {
          handleColumnClick(e, column, rowIdx);
        }}
        onContextMenu={(e) => {
          handleRightClickColumn(e, column);
        }}
        key={columnIdx}
        id={columnIdx}
      >
        <Tooltip
          title={column.value}
          placement={tooltipPositions[columnIdx] ? tooltipPositions[columnIdx] : 'top'}
          arrow
          classes={{ tooltip: props.classes.tooltipCustom, arrow: props.classes.arrowCustom }}
        >
          <Typography className={classes.headerText} ref={(el) => (columnsRef.current[columnIdx] = el)}>
            {column.value}
          </Typography>
        </Tooltip>
        {!allSelected && selectedColumns.length ? addPartialSelected(selectedColumns, column) : null}
        <Popover
          key={columnIdx}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          open={Boolean(anchorElColumn)}
          anchorEl={anchorElColumn}
          onClose={(e: any) => {
            e.stopPropagation();
            setAnchorElColumn(null);
          }}
        >
          <div className={classes.hideLinePopover} onClick={(e) => handleHideRow(e)}>
            <VisibilityOff />
            <span style={{ marginLeft: '8px' }}>{i18n.t('hideColumn')}</span>
          </div>
        </Popover>
        {column.hiddenBefore.length ? (
          <div
            className={classes.unfoldIconBeforeColumn}
            onClick={(e) => {
              e.stopPropagation();
              displayColumns(column.hiddenBefore);
            }}
          >
            <UnfoldMore style={{ transform: 'rotate(90deg)' }} />
          </div>
        ) : null}
        {column.hiddenAfter.length ? (
          <div
            className={classes.unfoldIconAfterColumn}
            onClick={(e) => {
              e.stopPropagation();
              displayColumns(column.hiddenAfter);
            }}
          >
            <UnfoldMore style={{ transform: 'rotate(90deg)' }} />
          </div>
        ) : null}
      </StyledTableCellHeader>
    );
  };

  const addPartialSelected = (selectedColumns, column) => {
    const selectedDivs = [];

    let tempDiv = {
      idx: selectedColumns[0].idx,
      width: 1,
      offset: 120 * column.idxs.indexOf(selectedColumns[0].idx),
    };
    for (let index = 1; index <= selectedColumns.length; index++) {
      if (selectedColumns[index] && selectedColumns[index].idx === selectedColumns[index - 1].idx + 1) {
        tempDiv.width++;
        continue;
      } else {
        selectedDivs.push(tempDiv);
      }
      if (index < selectedColumns.length) {
        tempDiv = {
          idx: selectedColumns[index].idx,
          width: 1,
          offset: 120 * column.idxs.indexOf(selectedColumns[0].idx),
        };
      }
    }

    return selectedDivs.map((col) => {
      return (
        <div
          style={{
            top: '-0.5px',
            left: `${col.offset - 0.5}px`,
            position: 'absolute',
            height: 'calc(100% + 1px)',
            background: theme.palette.primary.light,
            width: `${120 * col.width}px`,
            zIndex: 0,
            border: `0.5px solid ${theme.palette.primary.main}`,
          }}
        ></div>
      );
    });
  };

  const handleRowClick = (e, group) => {
    e.preventDefault();

    if (e.type === 'click') {
      if (choosedGroups.includes(group._id)) {
        setChoosedGroups(choosedGroups.filter((grp) => grp !== group._id));
      } else {
        setChoosedGroups([...choosedGroups, group._id]);
      }
    } else if (e.type === 'contextmenu') {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleHideColumn = (e) => {
    e.stopPropagation();
    setHiddenGroups([...hiddenGroups, ...choosedGroups]);
    setAnchorEl(null);
    setChoosedGroups([]);
  };

  const renderRowHeaders = (template) => {
    let hiddenCount = 0;
    let hiddenGroupsAssigned = [];
    let tempHiddenGroups = [];

    if (template?.taskTemplateGroups?.length === hiddenGroups.length) {
      return (
        <TableRow>
          <StyledTableCell className={classes.hiddenGroupCell}>
            <div
              className={classes.unfoldIconBefore}
              onClick={() => {
                setHiddenGroups([]);
              }}
            >
              <UnfoldMore />
            </div>
          </StyledTableCell>
        </TableRow>
      );
    }

    return template?.taskTemplateGroups
      ? (template.taskTemplateGroups as any[])
          .sort((x, y) => x.order - y.order)
          .map((group, idx) => {
            group.expandGroupAfter = [];
            if (hiddenGroups.includes(group._id)) hiddenCount += 1;

            if (hiddenGroups.includes(group._id)) {
              tempHiddenGroups.push(group._id);
            } else {
              group.expandGroupBefore = tempHiddenGroups;
              hiddenGroupsAssigned = [...hiddenGroupsAssigned, ...tempHiddenGroups];
              tempHiddenGroups = [];
            }

            if (idx === template.taskTemplateGroups.length - hiddenGroups.length - 1 + hiddenCount) {
              const unassignedHiddenGroups = hiddenGroups
                .filter((x) => !hiddenGroupsAssigned.includes(x))
                .concat(hiddenGroupsAssigned.filter((x) => !hiddenGroups.includes(x)));
              if (unassignedHiddenGroups.length) {
                group.expandGroupAfter = unassignedHiddenGroups;
              }
            }

            const render = !hiddenGroups.includes(group._id) ? (
              <TableRow key={idx}>
                <StyledTableCell
                  className={classes.groupCell}
                  style={{
                    marginTop: `calc(40px * ${idx - hiddenCount})`,
                    background: choosedGroups.includes(group._id) ? theme.palette.primary.light : '',
                    color: choosedGroups.includes(group._id) ? theme.palette.primary.main : '',
                    border: choosedGroups.includes(group._id) ? `1px solid ${theme.palette.primary.main}` : '',
                    pointerEvents: 'visible',
                  }}
                  onClick={(e) => handleRowClick(e, group)}
                  onContextMenu={(e) => handleRowClick(e, group)}
                >
                  <Tooltip
                    title={group.name}
                    placement='top'
                    arrow
                    classes={{ tooltip: props.classes.tooltipCustom, arrow: props.classes.arrowCustom }}
                  >
                    <Typography className={classes.headerText}>{group.name}</Typography>
                  </Tooltip>
                  <Popover
                    key={idx}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'center',
                    }}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={(e: any) => {
                      e.stopPropagation();
                      setAnchorEl(null);
                    }}
                  >
                    <div className={classes.hideLinePopover} onClick={handleHideColumn}>
                      <VisibilityOff />
                      <span style={{ marginLeft: '8px' }}>{i18n.t('hideLine')}</span>
                    </div>
                  </Popover>
                  {group?.expandGroupBefore?.length ? (
                    <div
                      className={classes.unfoldIconBefore}
                      onClick={(e) => {
                        e.stopPropagation();
                        setHiddenGroups(hiddenGroups.filter((grp) => !group.expandGroupBefore.includes(grp)));
                      }}
                    >
                      <UnfoldMore />
                    </div>
                  ) : null}
                  {group?.expandGroupAfter?.length ? (
                    <div
                      className={classes.unfoldIconAfter}
                      onClick={(e) => {
                        e.stopPropagation();
                        setHiddenGroups(hiddenGroups.filter((grp) => !group.expandGroupAfter.includes(grp)));
                      }}
                    >
                      <UnfoldMore />
                    </div>
                  ) : null}
                </StyledTableCell>
              </TableRow>
            ) : null;
            return render;
          })
      : null;
  };

  const handleColumnClick = (e, column, rowIdx) => {
    e.stopPropagation();
    e.preventDefault();

    const count = column.idxs.filter((c) => !choosedColumns.find((e) => e.idx === c)).length;

    if (!count) {
      setChoosedColumns(choosedColumns.filter((c) => !column.idxs.includes(c.idx)));
    } else {
      const hiddenColumns = column.idxs.map((idx) => ({ idx: idx, rowIdx: rowIdx, target: e.currentTarget }));
      setChoosedColumns([...choosedColumns.filter((c) => !column.idxs.includes(c.idx)), ...hiddenColumns]);
    }
  };

  const handleRightClickColumn = (e, column) => {
    e.preventDefault();
    e.stopPropagation();
    const choosed = choosedColumns.filter((c) => column.idxs.includes(c.idx));
    if (!choosed.length) return;
    setAnchorElColumn(choosed[0].target);
  };

  const handleHideRow = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setHiddenColumns([...hiddenColumns, ...choosedColumns]);
    setChoosedColumns([]);
    setAnchorElColumn(null);
  };

  const displayColumns = (columns) => {
    setHiddenColumns(hiddenColumns.filter((col) => !columns.find((c) => c.idx === col.idx)));
  };

  const handleClickHideButton = (e) => {
    handleHideRow(e);
    handleHideColumn(e);
  };
  const tableBodyHeight = template?.taskTemplateGroups
    ? { height: `calc(40px * (${template.taskTemplateGroups.length} - ${hiddenGroups.length}) )` }
    : {};
  const tableBodyWidth = loading && refresh ? { width: '100%' } : {};

  const handleScroll = () => {
    const newPositions = columnsRef.current.map((column) => {
      return getTooltipPosition(column);
    });

    if (!isEqual(tooltipPositions, newPositions)) {
      setTooltipPositions(newPositions);
    }
  };

  const getTooltipPosition = (column) => {
    const tableBox = tableContainerRef.current.getBoundingClientRect();
    const columnBox = column.getBoundingClientRect();

    // Calculate the center of the column
    const columnCenter = columnBox.left + columnBox.width / 2;

    // Check if the center of the column is within the table container's bounds
    if (columnCenter >= tableBox.left && columnCenter <= tableBox.right) {
      return 'top';
    }

    if (columnBox.right < tableBox.right) {
      return 'top-end';
    }

    if (columnBox.left > tableBox.left) {
      return 'top-start';
    }
    return 'top';
  };

  return editTable ? (
    <EditTable
      templateID={templateID}
      isDraft={isDraft}
      colsToEdit={colsToEdit}
      headerNames={headerNames}
      close={() => {
        setEditTable(false);
        handleGetTimeMap();
        handleGetIssueTemplate(true);
      }}
    />
  ) : (
    <div style={{ margin: '15px', position: 'relative', flexGrow: 1 }}>
      <Backdrop className={classes.backdrop} open={loadingIssueTemplate || loadingTimeMap}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          {choosedColumns.length || choosedGroups.length ? (
            <Button className={classes.editButton} onClick={(e) => handleClickHideButton(e)}>
              <VisibilityOff className={classes.topButton} />
              <span className={classes.editText} style={{ color: theme.palette.primary.main }}>
                {i18n.t('hide')}
              </span>
            </Button>
          ) : null}
        </div>
        <div className={classes.filterDiv}>
          <Tooltip title={i18n.t('refreshWidgets')} arrow>
            <div
              onClick={async () => {
                if (!loading) refreshTimeMap();
              }}
              className={classes.refreshWidgets}
            >
              <Refresh classes={{ root: classes.mainColor }} />
            </div>
          </Tooltip>
          <Tooltip title={i18n.t('fullScreen')} arrow>
            <div onClick={handle.enter} className={classes.fullScreen}>
              <Full classes={{ root: classes.mainColor }} />
            </div>
          </Tooltip>

          <Button className={classes.editButton} onClick={() => setEditTable(true)}>
            <Edit className={classes.topButton} />
            <span className={classes.editText} style={{ color: theme.palette.primary.main }}>
              {i18n.t('edit')}
            </span>
          </Button>
          <Button className={classes.editButton} onClick={() => setHeatMapFilter(true)}>
            <FilterList className={classes.topButton} />
            <span className={classes.editText} style={{ color: theme.palette.primary.main }}>
              {i18n.t('filter')}
            </span>
          </Button>

          <Settings
            style={{ fontSize: '30px', marginRight: '8px', cursor: 'pointer' }}
            onClick={() => setColorModalOpen(true)}
            ref={settingsRef}
          />
        </div>
      </div>

      {columns.length > 0 ? (
        <FullScreen handle={handle}>
          {handle.active ? (
            <div>
              <Tooltip title={i18n.t('fullScreen')} arrow>
                <div
                  onClick={handle.exit}
                  className={classes.fullScreen}
                  style={{ margin: '24px 24px 24px 0px', display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Full classes={{ root: classes.mainColor }} />
                </div>
              </Tooltip>
            </div>
          ) : null}
          {loading && refresh && (
            <div className={classes.circularRefresh}>
              <div className={classes.divLoadingAnimation}>
                <span className={classes.spanLoadingAnimation}>{i18n.t('pleaseWait')}</span>
                <span className={classes.spanLoadingAnimation}>{i18n.t('yourDataIsBeingUpdated')}</span>
                <Box position='relative' display='inline-flex' marginTop='56px'>
                  <CircularProgress style={{ width: '100px', height: '100px' }} />
                </Box>
              </div>
            </div>
          )}
          <div className={classes.scrollableTable} onScroll={handleScroll} ref={tableContainerRef}>
            <Table
              className={loading && refresh ? `${classes.loadingTable} ${classes.table}` : classes.table}
              style={{ maxWidth: '100%' }}
            >
              <TableHead>{addHeader()}</TableHead>
              <TableBody>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>{renderRowHeaders(template)}</div>

                  <div
                    id={'tbody'}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      ...tableBodyHeight,
                      ...tableBodyWidth,
                    }}
                  >
                    {template?.taskTemplateGroups
                      ? (template.taskTemplateGroups as any[])
                          .filter((g) => !hiddenGroups.includes(g._id))
                          .sort((x, y) => x.order - y.order)
                          .map((group, idx) => {
                            return (
                              <TableRow key={idx}>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyItems: 'left',
                                  }}
                                >
                                  {(columnsFilter.length > 0 ? columnsFilter : columns).map((column, cIdx) =>
                                    column.columns.map((val) => {
                                      const res =
                                        val.groupId === group._id && !hiddenColumns.find((c) => c.idx === cIdx) ? (
                                          <StyledTableCell
                                            key={cIdx}
                                            className={classes.w120}
                                            style={getStyledTableCellColorFilter(
                                              colorFilters,
                                              val,
                                              taskTime,
                                              choosedGroups,
                                              choosedColumns,
                                              group,
                                              cIdx,
                                              theme,
                                            )}
                                          >
                                            <Typography className={classes.tableText}>{val.time}</Typography>
                                          </StyledTableCell>
                                        ) : null;
                                      return res;
                                    }),
                                  )}
                                </div>
                              </TableRow>
                            );
                          })
                      : null}
                  </div>
                </div>
              </TableBody>
            </Table>
          </div>
        </FullScreen>
      ) : (
        <div style={{ display: 'flex' }}>
          <Table style={{ width: '10%' }}>
            <TableBody>
              {template?.taskTemplateGroups
                ?.sort((x, y) => x.order - y.order)
                .map((group, idx) => (
                  <TableRow key={idx}>
                    <StyledTableCell style={{ width: '90px' }}>
                      <Tooltip
                        title={group.name}
                        placement='top'
                        arrow
                        classes={{ tooltip: props.classes.tooltipCustom, arrow: props.classes.arrowCustom }}
                      >
                        <Typography className={classes.headerText}>{group.name}</Typography>
                      </Tooltip>
                    </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <div style={{ width: '100%' }}>
            <Paper variant='outlined' className={classes.paperInfo}>
              <Typography className={classes.paperText}>{i18n.t('noInfoEditTable')}</Typography>
            </Paper>
          </div>
        </div>
      )}
      {heatMapFilter ? (
        <HeatmapFilterPopover defaultFilters={filters} onSubmit={setFilters} onClose={handleCloseDialog} />
      ) : null}
      {colorModalOpen ? (
        <HeatMapColors
          openFilter={colorModalOpen}
          onClose={() => {
            setColorModalOpen(false);
          }}
          colorFilters={colorFilters}
          setColorFilters={(value: ColorObject[]) => setColorFilter(value)}
          taskTime={taskTime}
          setTaskTime={(time: number) => setTaskTime(time)}
          template={template}
          heatmapConfigurationDraft={heatmapConfigurationDraft}
          setHeatmapConfigurationDraft={(heatmapConfig: HeatmapConfigurationDraft) =>
            setHeatmapConfigurationDraft(heatmapConfig)
          }
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (): object => ({});

const mapDispatchToProps = {
  getTimeMap,
  getIssueTemplates,
  getDraftTimeMap,
  getIssueTemplateToHeatmap,
  getTimeMapColumns,
  getHeatmapConfigurations,
  getHeatmapConfigurationDrafts,
  createHeatmapConfigurationDraft,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default compose<any>(withTranslation('translation'), withStyles(styles), connector)(HeatMap);
