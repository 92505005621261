import {
  AttachFile,
  CalendarToday,
  Category,
  CheckBox,
  Construction,
  FilterCenterFocus,
  Gesture,
  Info,
  LocationOn,
  LooksOne,
  RadioButtonCheckedOutlined,
  TableChart,
  TextFields,
} from '@mui/icons-material';

const IconInput = (props: any): JSX.Element => {
  const { input, classes } = props;

  switch (input.type) {
    case 'string':
      return <TextFields classes={{ root: `${classes.textFieldIcon} ${classes.inputIcon}` }} />;
    case 'instruction':
      return <Info classes={{ root: `${classes.elementIcon} ${classes.inputIcon}` }} />;
    case 'number':
      return <LooksOne classes={{ root: `${classes.oneIcon} ${classes.inputIcon}` }} />;
    case 'multiple':
      return <CheckBox classes={{ root: `${classes.checkboxOutIcon} ${classes.inputIcon}` }} />;
    case 'selection':
      return <RadioButtonCheckedOutlined classes={{ root: `${classes.radioOutIcon} ${classes.inputIcon}` }} />;
    case 'datetime':
      return <CalendarToday classes={{ root: `${classes.calendarIcon} ${classes.inputIcon}` }} />;
    case 'file':
      return <AttachFile classes={{ root: `${classes.fileIcon} ${classes.inputIcon}` }} />;
    case 'element':
      return <Category classes={{ root: `${classes.elementIcon} ${classes.inputIcon}` }} />;
    case 'site':
      return <LocationOn classes={{ root: `${classes.elementIcon} ${classes.inputIcon}` }} />;
    case 'table':
      return <TableChart classes={{ root: `${classes.tableIcon} ${classes.inputIcon}` }} />;
    case 'signature':
      return <Gesture classes={{ root: `${classes.signatureIcon} ${classes.inputIcon}` }} />;
    case input['string']?.setValueWithQRCode:
      return <FilterCenterFocus classes={{ root: `${classes.qrCodeIcon} ${classes.inputIcon}` }} />;
    case 'qrCode':
      return <FilterCenterFocus classes={{ root: `${classes.qrCodeIcon} ${classes.inputIcon}` }} />;
    case 'tool':
      return <Construction id='genericInputCardTool' className={`${classes.toolIcon} ${classes.inputIcon}`} />;
    default:
      return null;
  }
};

export default IconInput;
