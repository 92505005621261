import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    disabledWithBadge: {
      display: 'flex',
      width: '100%',
    },
    displayTable: {
      height: 'auto',
      wordWrap: 'break-word',
      whiteSpace: 'nowrap',
      borderCollapse: 'separate',
    },
    label: {
      color: theme.palette.primary.main,
    },
    border: {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    spanLabel: {
      margin: '1px 2px 1px 0px',
      height: '18px',
      maxWidth: 'inherit',
      whiteSpace: 'nowrap',
    },
    spanLabelNotFirst: {
      margin: '1px 2px',
      height: '18px',
      maxWidth: 'inherit',
      whiteSpace: 'nowrap',
    },
    spanLabelSideMenu: {
      margin: '2px 0px',
      height: '18px',
      maxWidth: 'inherit',
      whiteSpace: 'nowrap',
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    avatar: {
      width: 'unset !important',
      display: 'block !important',
      borderRadius: '30px',
      padding: '1px 6px',
      maxWidth: '165px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: `${theme.palette.common.white} !important`,
    },
    avatarAccount: {
      backgroundColor: theme.palette.grey.medium,
    },
    avatarText: {
      color: theme.palette.common.white,
    },
    container: {
      display: 'flex',
    },
    labelsContainer: {
      width: '100%',
    },
    editIcon: {
      fontSize: '20px',
      color: theme.palette.warning.main,
      marginTop: '5px',
    },
    modalBody: {
      height: 'calc(100% - 40px)',
    },
    modalFooter: {
      float: 'right',
    },
    accountNameWithHover: {
      cursor: 'pointer',
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.primary.main,
        textDecorationLine: 'underline',
      },
    },
    accountNameActive: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
    pointer: {
      cursor: 'pointer',
    },
    chip: {
      borderRadius: '30px',
    },
    labelSmall: {
      padding: '0px 4px',
    },
    divAssigneesColumns: {
      display: 'flex',
      flexWrap: 'nowrap',
      gap: '2px',
    },
    divAssigneesColumnsSideMenu: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '4px',
    },
    sideMenu: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      gap: '4px',
    },
  });
