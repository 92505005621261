import { useSubscription } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChatMessageCreatedDocument, ChatRoomType } from 'src/gql/graphql';
import { getUnreadMessages } from 'src/modules/account/account.redux';
import { withDialogBoundary } from 'src/utils/other/componentErrorBoundary';

type ChatPushNotificationsProps = ConnectedProps<typeof connector>;

const ChatPushNotifications = ({ loggedUser, getUnreadMessages }: ChatPushNotificationsProps) => {
  const location = useLocation();
  const useQuery = () => new URLSearchParams(location.search);
  const query = useQuery();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if ('Notification' in window) {
      Notification.requestPermission();
    }
  }, []);

  const idToIgnore = useMemo(() => {
    const path = location.pathname;
    if (path === '/chat') {
      return query.get('id');
    }
    return undefined;
  }, [location]);

  useSubscription(ChatMessageCreatedDocument, {
    skip: !loggedUser?._id,
    onData: async ({ data }) => {
      const message = data?.data?.chatMessageCreated;
      if (
        message &&
        message?.chatRoom?._id !== idToIgnore &&
        message?.chatRoom?.type !== ChatRoomType.ISSUE &&
        message.infoMessage === null
      ) {
        getUnreadMessages();
        if (Notification?.permission === 'granted') {
          const notificatioBody = message.text ? message.text : message.files ? t('fileSent') : null;
          const notification = new Notification(message.createdBy.name, {
            body: notificatioBody,
            tag: `chat-notification-${message.chatRoom._id}`,
          });
          notification.onclick = (event) => {
            event.preventDefault();
            navigate(`/chat?id=${message.chatRoom._id}`);
          };
        }
      }
    },
  });

  return null;
};

const connector = connect(
  (state: any) => ({
    loggedUser: state.loginReducer.loggedUser,
  }),
  {
    getUnreadMessages,
  },
);

export default withDialogBoundary(connector(ChatPushNotifications));
