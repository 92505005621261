export default function EmptyPostCard() {
  const lightblue = '#E9F5FE';
  const darkblue = '#0078FE';

  return (
    <div style={{ position: 'relative', width: 300, height: 270 }}>
      <svg style={{ position: 'absolute', width: 250, height: 250 }}>
        <rect
          x={10}
          y={10}
          rx={15}
          ry={15}
          width={190}
          height={220}
          fill={'white'}
          stroke={'grey'}
          strokeOpacity={'50%'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
          strokeWidth={8}
        ></rect>
        <rect width={30} height={28} x={26} y={52} fill={lightblue}></rect>
        <rect width={30} height={28} x={26} y={100} fill={lightblue}></rect>
        <rect width={30} height={28} x={26} y={150} fill={lightblue}></rect>

        <rect width={84} height={7} x={69} y={55} fill={lightblue}></rect>
        <rect width={84} height={7} x={69} y={70} fill={lightblue}></rect>

        <rect width={84} height={7} x={69} y={105} fill={lightblue}></rect>
        <rect width={84} height={7} x={69} y={120} fill={lightblue}></rect>

        <rect width={84} height={7} x={69} y={155} fill={lightblue}></rect>
        <rect width={84} height={7} x={69} y={170} fill={lightblue}></rect>
      </svg>
      <svg style={{ position: 'absolute', width: 400, height: 200 }} transform='rotate(10)'>
        <rect
          x={150}
          y={45}
          rx={15}
          ry={15}
          width={187}
          height={130}
          fill={'white'}
          stroke={'grey'}
          strokeOpacity={'50%'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
          strokeWidth={8}
        ></rect>
        <rect width={33} height={31} x={170} y={65} fill={darkblue}></rect>

        <rect width={90} height={7} x={210} y={70} fill={lightblue}></rect>
        <rect width={95} height={7} x={210} y={85} fill={lightblue}></rect>

        <rect width={135} height={7} x={170} y={105} fill={lightblue}></rect>
        <rect width={135} height={7} x={170} y={120} fill={lightblue}></rect>
        <rect width={135} height={7} x={170} y={135} fill={lightblue}></rect>
        <rect width={135} height={7} x={170} y={150} fill={lightblue}></rect>
      </svg>
    </div>
  );
}
