import Switch from 'react-switch';
import { Checkbox } from '@mui/material';
import { styles } from 'src/utils/components/checkbox-field/styles';
import { useTheme } from '@mui/material';
import { useState, useEffect } from 'react';
import i18n from 'src/utils/translations/i18n';
import { withStyles } from '@mui/styles';
import { CheckBoxOutlineBlank } from '@mui/icons-material';

interface CheckboxFieldProps {
  handleChange?: (arg1?: any, arg2?: any) => void;
  value: any;
  disabled?: boolean;
  checkbox?: boolean; // if true, appear as checkbox. otherwise, appear as toggle
  inline?: boolean;
  mouseOver?: boolean;
  id?: string;
  classes?: any;
}

const CheckboxField = (props: CheckboxFieldProps) => {
  const { handleChange, value, disabled, checkbox, inline, mouseOver, id } = props;
  const [stt, setStt] = useState<boolean[]>(value);
  const theme = useTheme();

  useEffect(() => {
    setStt(value);
  }, [value]);

  const onChange = () => {
    if (disabled) return;
    setStt([!value[0]]);
    if (handleChange) handleChange([!value[0]]);
  };

  return (
    <>
      {!checkbox ? (
        <Switch
          checked={stt?.length ? stt[0] : false}
          disabled={disabled}
          className={`switch ${inline ? props.classes.inline : ''} ${props.classes.handles} ${
            mouseOver
              ? props.classes.inputCursorEnabled
              : disabled
                ? props.classes.disabledHandle
                : props.classes.disabled
          }`}
          onChange={(_val, e): void => {
            e.stopPropagation();
            onChange();
          }}
          uncheckedIcon={<div className={`${props.classes.handle} ${props.classes.off}`}>{i18n.t('OFF')}</div>}
          checkedIcon={<div className={`${props.classes.handle} ${props.classes.on}`}>{i18n.t('ON')}</div>}
          height={24}
          width={51}
          handleDiameter={18}
          offColor={theme.palette.mode === 'dark' ? '#f0f0f0' : '#404040'}
          offHandleColor={'#bababa'}
          onColor={theme.palette.mode === 'dark' ? '#f0f0f0' : '#404040'}
          onHandleColor={theme.palette.primary.main}
          id={'switch-' + id}
        />
      ) : (
        <span
          className={`cus-label ${`${inline ? props.classes.inline : ''} ${props.classes.checkbox}`} ${
            mouseOver
              ? props.classes.inputCursorEnabled
              : disabled
                ? props.classes.disabledHandle
                : props.classes.disabled
          }`}
          id={'switch-on-click-' + id}
          onClick={(e): void => {
            e.stopPropagation();
            disabled ? null : handleChange ? handleChange([!value[0]]) : null;
          }}
        >
          {!value.length || !value[0] ? (
            <span enzyme-attr={`checkbox-false`}>
              <CheckBoxOutlineBlank style={{ fontSize: '15px' }} />
            </span>
          ) : (
            <span enzyme-attr={`checkbox-true`}>
              <Checkbox style={{ fontSize: '15px' }} />
            </span>
          )}
        </span>
      )}
    </>
  );
};

export default withStyles(styles)(CheckboxField);
