import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      width: 500,
    },
    dialogTitle: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '26px',
      textAlign: 'center',
      color: theme.palette.text.primary,
    },
    dialogFooter: {
      padding: '16px',
    },
    readedNumber: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      color: theme.palette.text.secondary,
    },
    chipContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      marginTop: '10px',
      flexWrap: 'wrap',
    },
    checkIcon: {
      fontSize: '14px',
      color: theme.palette.success.main,
      marginLeft: '5px',
    },
    chipValue: {},
    chipValueScanned: {
      backgroundColor: `${theme.palette.primary.light}`,
    },
    chipTextContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    chipTextScanned: {
      color: theme.palette.primary.main,
    },
  });
