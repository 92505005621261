import { useSubscription } from '@apollo/client';
import { ReactNode, createContext, useContext, useState } from 'react';
import {
  ChatMessageCreatedDocument,
  ChatMessageUpdatedDocument,
  ChatRoomType,
  ChatRoomUpdatedDocument,
} from 'src/gql/graphql';
import { ChatState } from '../types/chat.context.type';

export const chatContextInitialValue: ChatState = {
  chatRooms: [],
  chatRoomsCount: 0,
  searchChatRoom: '',
  showChatRoomFiles: false,
  haveImportantMessages: false,
  havePinnedMessages: false,
  replyMessage: undefined,

  activeChatRoom: undefined,
  chatMessages: [],
  chatMessagesCount: 0,
  accountsColor: new Map(),
  listScrolled: false,
  showScrollMessage: false,

  openCreateModal: false,
  openEditChatRoomNameModal: false,
  openAddMoreParticipants: false,
  openLeaveDialog: false,
};

const ChatContext = createContext<ChatState>(chatContextInitialValue);

interface ChatContextProviderProps {
  children: ReactNode;
  initialValue: ChatState;
}

export const ChatContextProvider = ({ children, initialValue = chatContextInitialValue }: ChatContextProviderProps) => {
  const [chatState, setChatState] = useState<ChatState>(initialValue);

  const updateChatState = (newData: Partial<ChatState>) => {
    setChatState((prevState) => ({ ...prevState, ...newData }));
  };

  useSubscription(ChatRoomUpdatedDocument, {
    skip: !chatState?.chatRooms?.length,
    variables: { chatRoomIds: chatState?.chatRooms?.map((el) => el._id) },
    onData: ({ data }) => {
      const newChatRoom = data?.data?.chatRoomUpdated;
      if (newChatRoom) {
        updateChatState({
          chatRooms: chatState?.chatRooms?.map((cr) => (cr._id === newChatRoom._id ? { ...cr, ...newChatRoom } : cr)),
          activeChatRoom:
            newChatRoom._id === chatState?.activeChatRoom?._id
              ? { ...chatState.activeChatRoom, ...newChatRoom }
              : chatState?.activeChatRoom,
        });
      }
    },
  });

  useSubscription(ChatMessageCreatedDocument, {
    onData({ data }) {
      const newMessage = data?.data?.chatMessageCreated;
      const chatRoom = newMessage?.chatRoom;
      if (chatRoom?.type !== ChatRoomType.SINGLE_CHAT && chatRoom?.type !== ChatRoomType?.GROUP_CHAT) return;
      const isNewChatRoom = !chatState?.chatRooms?.some((el) => el._id === chatRoom._id);
      if (isNewChatRoom) {
        updateChatState({
          chatRooms: [chatRoom, ...chatState.chatRooms],
        });
      } else {
        updateChatState({
          chatMessages:
            chatState?.activeChatRoom?._id === chatRoom._id
              ? chatState.chatMessages.find((m) => m._id === newMessage._id)
                ? chatState.chatMessages.map((e) => (e._id === newMessage._id ? newMessage : e))
                : [newMessage, ...chatState.chatMessages]
              : chatState?.chatMessages,
          chatMessagesCount:
            chatState?.activeChatRoom?._id === chatRoom._id
              ? chatState.chatMessagesCount + 1
              : chatState?.chatMessagesCount,
          chatRooms: chatState.chatRooms.map((cr) =>
            cr._id === chatRoom._id && !newMessage?.infoMessage
              ? {
                  ...cr,
                  lastMessage: newMessage,
                  unreadMessages: cr._id !== chatState?.activeChatRoom?._id ? cr.unreadMessages + 1 : cr.unreadMessages,
                }
              : cr,
          ),
          showScrollMessage: chatState.listScrolled,
          activeChatRoom:
            chatState.activeChatRoom._id === chatRoom._id
              ? { ...chatRoom, unreadMessages: chatState.activeChatRoom.unreadMessages }
              : chatState.activeChatRoom,
        });
      }
    },
  });

  useSubscription(ChatMessageUpdatedDocument, {
    skip: !chatState?.activeChatRoom,
    variables: { chatRoomIds: [chatState?.activeChatRoom?._id] },
    onData({ data }) {
      const newMessage = data?.data?.chatMessageUpdated;
      updateChatState({
        chatMessages: chatState?.chatMessages?.map((m) => (m._id === newMessage._id ? newMessage : m)),
      });
    },
  });

  return (
    <ChatContext.Provider value={{ ...chatState, updateChatState, setChatState }}>{children}</ChatContext.Provider>
  );
};

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChatContext must be used within a ChatContext');
  }
  return context;
};
