import { styles } from 'src/utils/components/heat-map/styles';
import { Tooltip, useTheme } from '@mui/material';
import { withStyles } from '@mui/styles';
import { DragIndicatorOutlined, MoreVert, KeyboardArrowDown } from '@mui/icons-material';
import { useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useRef } from 'react';
import { Classes } from '@mui/styles/mergeClasses/mergeClasses';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import i18n from 'src/utils/translations/i18n';
import { Label } from 'src/gql/graphql';

export interface idxToDelete {
  type: string;
  idx: number;
}

interface Props {
  classes: Classes;
  label: Label;
  idx: number;
  setSelectedLabelGroupIdx(idx: number): void;
  setGroupPopover(group: HTMLElement): void;
  setDeletePopover(item: EventTarget): void;
  setIdxToDelete(item: idxToDelete): void;
  moveListItem(dragIndex: number, hoverIndex: number, type: string): void;
}

const GroupItem = (props: Props) => {
  const { classes, label, idx, moveListItem } = props;
  const dragGroupRef = useRef<HTMLDivElement>();
  const dropGroupRef = useRef<HTMLDivElement>();
  const blueTopDivRef = useRef<HTMLDivElement>();
  const blueBottomDivRef = useRef<HTMLDivElement>();
  const theme = useTheme();

  const [{ isDragging }, connectDrag, connectPreview] = useDrag({
    type: 'group',
    item: { idx },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, connectDrop] = useDrop({
    accept: 'group',
    collect: (monitor) => ({
      //@ts-ignore
      isOver: monitor.isOver(),
    }),
    hover: (draggedId: { idx }, monitor) => {
      if (!dragGroupRef.current) {
        return;
      }

      const dragIndex = draggedId.idx;

      const hoverIndex = idx;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = dragGroupRef.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverActualY = clientOffset.y - hoverBoundingRect.top;

      // if dragging down, continue only when hover is smaller than middle Y
      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
      // if dragging up, continue only when hover is bigger than middle Y
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

      if (dragIndex > hoverIndex) {
        blueTopDivRef.current.classList.add(classes.onOverLine);
      } else {
        blueBottomDivRef.current.classList.add(classes.onOverLine);
      }
    },
    drop: (draggedId: { idx }, monitor) => {
      if (!dragGroupRef.current) {
        return;
      }

      const dragIndex = draggedId.idx;
      const hoverIndex = idx;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = dragGroupRef.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverActualY = clientOffset.y - hoverBoundingRect.top;

      // if dragging down, continue only when hover is smaller than middle Y
      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
      // if dragging up, continue only when hover is bigger than middle Y
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

      moveListItem(dragIndex, hoverIndex, 'line');

      draggedId.idx = hoverIndex;
    },
  });

  useEffect(() => {
    if (!dropGroupRef.current) return;

    if (isDragging) dropGroupRef.current.classList.add(classes.onDragLine);
    else dropGroupRef.current.classList.remove(classes.onDragLine);
  }, [isDragging]);

  useEffect(() => {
    if (!dropGroupRef.current) return;

    if (!isOver) {
      blueTopDivRef.current.classList.remove(classes.onOverLine);
      blueBottomDivRef.current.classList.remove(classes.onOverLine);
    }
  }, [isOver]);

  const opacity = isDragging ? 0 : 1;

  connectDrag(dragGroupRef);
  connectDrop(dropGroupRef);
  connectPreview(dropGroupRef);

  const groupSelection = {
    marginRight: '0px',
    ...(idx === 0 || idx % 2 === 0
      ? { background: theme.palette.primary.light }
      : { background: theme.palette.background.background0 }),
  };

  return (
    <div
      className={classes.divLabelGroups}
      ref={dropGroupRef}
      style={isDragging ? { backgroundColor: theme.palette.common.white } : {}}
    >
      <div ref={dragGroupRef} className={classes.divDragIndicator} style={{ opacity }}>
        <DragIndicatorOutlined className={classes.dragIndicator} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', opacity }}>
        <div ref={blueTopDivRef} />

        <div key={idx} className={classes.addGroupDiv} style={groupSelection}>
          <Tooltip
            title={label?.name}
            arrow
            placement='top'
            classes={{
              tooltip: props.classes.tooltipCustom,
              arrow: props.classes.arrowCustom,
            }}
          >
            <span
              className={classes.addGroupDivText}
              style={
                label?.name !== i18n.t('selectGroup')
                  ? { color: `${theme.palette.text.primary}` }
                  : { color: `${theme.palette.text.secondary}` }
              }
            >
              {label?.name}
            </span>
          </Tooltip>
          <div
            id={`group-${idx}`}
            style={{ paddingRight: '16px' }}
            onClick={(e) => {
              props.setSelectedLabelGroupIdx(idx);
              props.setGroupPopover(e.currentTarget);
            }}
          >
            <KeyboardArrowDown className={classes.arrowDown}></KeyboardArrowDown>
          </div>
        </div>

        <div ref={blueBottomDivRef} />
      </div>
      <MoreVert
        fontSize='small'
        onClick={(e) => {
          props.setDeletePopover(e.target);
          props.setIdxToDelete({
            type: 'group',
            idx: idx,
          });
        }}
        style={{
          marginRight: '4px',
          marginLeft: '4px',
          opacity,
        }}
        className={classes.moreVertIcon}
      />
    </div>
  );
};

export default compose<any>(withTranslation('translation'), withStyles(styles))(GroupItem);
