import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import store from 'src/reducer-manager';
import { useTypedSelector } from 'src/utils/funcs';

type TenantState = {
  defaultTenant?: string | null;
};

const initialState = (): TenantState => ({
  defaultTenant: null,
});

const tenantSlice = createSlice({
  name: 'tenant',
  initialState: initialState(),
  reducers: {
    changeDefaultTenant(state, action: PayloadAction<string | null | undefined>) {
      state.defaultTenant = action.payload ?? undefined;
    },
  },
});

export const tenantReducer = tenantSlice.reducer;

export const { changeDefaultTenant } = tenantSlice.actions;

export const getDefaultTenant = () => store.getState().tenantReducer.defaultTenant;

export const useDefaultTenant = () => useTypedSelector((state) => state.tenantReducer.defaultTenant);
