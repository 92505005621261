import { useTheme } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { typedConnect } from 'src/utils/funcs';
import useAtomicCallback from 'src/utils/hooks/useAtomicCallback';

const connector = typedConnect((state) => ({
  isBeingImpersonated: !!state.loginReducer.impersonatedBy,
  username: state.loginReducer.loggedUser.username,
  tenantId: state.authenticationReducer.tenantInfo._id,
}));

export const ImpersonatedHeader = memo(
  connector((props: ConnectedProps<typeof connector>) => {
    const { isBeingImpersonated, username, tenantId } = props;
    const theme = useTheme();
    const { t } = useTranslation();

    const navigate = useNavigate();

    const stopImpersonating = useAtomicCallback(async () => {
      const oldAccessToken = localStorage.getItem('old_access_token');
      localStorage.clear();

      localStorage.setItem('access_token', oldAccessToken);

      navigate(`/tenants/${tenantId}`);
      window.location.reload();
    }, []);

    return isBeingImpersonated ? (
      <div
        css={{
          position: 'absolute',
          //backgroundColor: theme.palette.primary.light,
          background: `linear-gradient(90deg, transparent 5%,${theme.palette.primary.light} 40% 60%,transparent 95%)`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignSelf: 'center',
          userSelect: 'none',
          gap: 16,
          zIndex: 100,
          width: '100%',
          padding: '0 4px',
          borderRadius: '0 0 10px 10px',
          opacity: '40%',
          transition: 'opacity 0.2s',
          '&:hover': {
            opacity: '100%',
          },
        }}
      >
        <span
          css={{ color: theme.palette.text.primary, fontWeight: 'bold' }}
        >{`${t('currentlyImpersonating')} "${username}".`}</span>
        <span
          onClick={stopImpersonating}
          css={{
            color: theme.palette.primary.main,
            fontWeight: 'bolder',
            letterSpacing: '1px',
            '&:hover': {
              textDecoration: 'underline',
              cursor: 'pointer',
            },
          }}
        >
          {/*loading ? <CircularProgress size={15} /> :*/ t('stopImpersonating')}
        </span>
      </div>
    ) : null;
  }),
);
