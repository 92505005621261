import * as React from 'react';
import { styles } from './styles';
import { Chip, Avatar, Typography } from '@mui/material';
import { useState } from 'react';
import DetailsPopover from 'src/utils/components/details-popover';
import { Account as GQLAccount } from 'src/gql/graphql';
import makeStyles from '@mui/styles/makeStyles';

type Account = Pick<GQLAccount, '_id' | 'name' | 'photo'>;

export type ChipAccountProps = {
  /** Array of Accounts with required fields: _id, name and photo (optional) */
  accounts: Account[];
  sideMenu?: boolean;
};

const useStyles = makeStyles(styles);

const ChipAccount: React.FC<ChipAccountProps> = (props): JSX.Element => {
  const classes = useStyles();
  const { accounts, sideMenu } = props;
  const [popoverDetails, setPopoverDetails] = useState<any>(null);
  const [selectedValue, setSelectedValue] = useState<Account>(null);

  return (
    <div className={classes.container}>
      <div className={classes.labelsContainer} data-testid='chips-accounts'>
        <div className={sideMenu ? classes.sideMenu : classes.disabledWithBadge}>
          {accounts?.length
            ? accounts.map((account, i) => (
                <div key={i}>
                  <ChipBody
                    account={account}
                    popoverDetails={popoverDetails}
                    setPopoverDetails={setPopoverDetails}
                    selectedValue={selectedValue}
                    setSelectedValue={setSelectedValue}
                    index={i}
                    sideMenu={sideMenu}
                  />
                </div>
              ))
            : null}
        </div>
      </div>
      {popoverDetails && (
        <DetailsPopover
          context={'account'}
          anchor={popoverDetails}
          value={selectedValue}
          clear={() => {
            setPopoverDetails(null);
            setSelectedValue(null);
          }}
        />
      )}
    </div>
  );
};

type ChipBodyProps = {
  account: Account;
  selectedValue: Account;
  setSelectedValue: React.Dispatch<React.SetStateAction<Account>>;
  popoverDetails?: any;
  setPopoverDetails: React.Dispatch<React.SetStateAction<any>>;
  index: number;
  sideMenu: boolean;
};

const ChipBody: React.FC<ChipBodyProps> = (props) => {
  const classes = useStyles();
  const { account, selectedValue, popoverDetails, setPopoverDetails, setSelectedValue, index } = props;
  return (
    <span
      data-testid={`chip-account-[${account?._id}]`}
      className={index === 0 ? classes.spanLabel : classes.spanLabelNotFirst}
    >
      <Chip
        avatar={
          account?.photo ? (
            <Avatar
              classes={{ root: classes.avatarAccount }}
              style={{ margin: '0px 0px 0px 1px' }}
              src={`data:image/webp;base64,${account?.photo}`}
            />
          ) : (
            <Avatar classes={{ root: classes.avatarAccount }} style={{ margin: '0px 0px 0px 3px' }}>
              <Typography variant='caption' className={classes.avatarText}>
                {account?.name.charAt(0).toUpperCase()}
              </Typography>
            </Avatar>
          )
        }
        label={
          <span
            className={
              popoverDetails && selectedValue?._id === account?._id
                ? classes.accountNameActive
                : classes.accountNameWithHover
            }
            onClick={(e) => {
              e.stopPropagation();
              setSelectedValue(account);
              setPopoverDetails(e.target);
            }}
          >
            <Typography variant='caption'>{account?.name}</Typography>
          </span>
        }
        variant='outlined'
        size='small'
        classes={{ labelSmall: classes.labelSmall }}
      />
    </span>
  );
};

export default ChipAccount;
