import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    selected: {
      color: theme.palette.primary.main,
    },
    icon: {
      width: '112px',
      height: '112px',
      justifyContent: 'center',
      alignItems: 'center',
      '> span': {
        lineHeight: '112px',
        fontWeight: 'lighter',
        fontSize: 'xx-large',
      },
    },
    valueWrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      containerType: 'inline-size',
    },
    value: {
      color: theme.palette.primary.main,
      fontWeight: 500,
      fontSize: 'clamp(32px, 12cqw, 58px)',
      lineHeight: 'normal',
      textAlign: 'center',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      wordWrap: 'break-word',

      '@supports (-webkit-line-clamp: 2)': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'initial',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
      },
    },
    label: {
      color: theme.palette.text.disabled,
      textAlign: 'center',
      fontFamily: 'Poppins',
      fontSize: 'clamp(12px, 4cqw, 24px)',
      fontWeight: 500,
    },
  });
