import { NexusGenFieldTypes, NexusGenInputs } from '@server/src/types';
import { identity, uniqBy } from 'lodash';
import { createContext, Reducer, useReducer } from 'react';
import { Dispatch } from 'redux';
import {
  getAssignees,
  getIssueCatalogsFolders,
  getSitesAndElements,
  getWithFoldersGenerics,
  getRoles,
  getTools,
  getMaterials,
} from './queries';
import { prependFunction } from 'src/utils/funcs';
import { getLabels, labelValuesPaginated } from 'src/modules/label/label.redux';
import { IssueCatalogFolderWhereInput } from 'src/gql/graphql';

type WithTypename<T extends Record<string, any>> = T & { __typename?: string };

type WithTypenameFieldType<T extends keyof NexusGenFieldTypes> = WithTypename<NexusGenFieldTypes[T]>;

type WithFolder<T extends keyof NexusGenFieldTypes> = `${T}Folder` extends keyof NexusGenFieldTypes
  ? WithTypename<NexusGenFieldTypes[T] & NexusGenFieldTypes[`${T}Folder`]>
  : never;

type FieldPopoverLabel = NexusGenFieldTypes['Label'] &
  NexusGenFieldTypes['LabelValue'] & { __typename?: string; totalItems?: number };

export type FieldPopoverItem = {
  site: WithTypename<NexusGenFieldTypes['LeanSite']>;
  siteElement: WithTypename<NexusGenFieldTypes['LeanSite'] & NexusGenFieldTypes['LeanElement']>;
  element: WithTypename<NexusGenFieldTypes['LeanSite'] & NexusGenFieldTypes['LeanElement']>;
  tag: WithTypename<NexusGenFieldTypes['LeanElementTag']>;
  issueTemplate: WithTypenameFieldType<'IssueTemplate'>;
  siteTemplate: WithTypenameFieldType<'SiteTemplate'>;
  elementTemplate: WithTypenameFieldType<'ElementTemplate'>;
  profileTemplate: WithTypenameFieldType<'ProfileTemplate'>;
  accountTemplate: WithTypenameFieldType<'AccountTemplate'>;
  issueCatalog: WithTypenameFieldType<'IssueCatalog'>;
  issueCatalogOpen: WithTypenameFieldType<'IssueCatalog'>;
  issueCatalogScheduled: WithTypenameFieldType<'IssueCatalog'>;
  role: WithTypenameFieldType<'Role'>;
  unit: WithTypenameFieldType<'Unit'>;
  account: WithTypenameFieldType<'Account'>;
  assignee: WithTypenameFieldType<'Account'>;
  profile: WithTypenameFieldType<'Profile'>;
  label: WithTypename<NexusGenFieldTypes['LabelValue']>;
  labelTask: WithTypename<NexusGenFieldTypes['LabelValue']>;
  labelUnit: WithTypename<NexusGenFieldTypes['LabelValue']>;
  labelSite: WithTypename<NexusGenFieldTypes['LabelValue']>;
  labelIssue: WithTypename<NexusGenFieldTypes['LabelValue']>;
  labelElement: WithTypename<NexusGenFieldTypes['LabelValue']>;
  labelProfile: WithTypename<NexusGenFieldTypes['LabelValue']>;
  labelAccount: WithTypename<NexusGenFieldTypes['LabelValue']>;
  labelAction: WithTypename<NexusGenFieldTypes['LabelValue']>;
  labelTimesheet: WithTypename<NexusGenFieldTypes['LabelValue']>;
  labelMaterial: WithTypename<NexusGenFieldTypes['LabelValue']>;
  labelTool: WithTypename<NexusGenFieldTypes['LabelValue']>;
  issueCatalogOpenFolder: WithTypename<NexusGenFieldTypes['IssueCatalogFolder']>;
  issueCatalogScheduledFolder: WithTypename<NexusGenFieldTypes['IssueCatalogFolder']>;
  tool: WithTypename<NexusGenFieldTypes['Tool']>;
  material: WithTypename<NexusGenFieldTypes['Material']>;
};

export type FieldPopoverFolder = FieldPopoverItem & {
  tag: WithTypename<
    NexusGenFieldTypes['LeanSite'] & NexusGenFieldTypes['LeanElement'] & NexusGenFieldTypes['LeanElementTag']
  >;
  issueTemplate: WithFolder<'IssueTemplate'>;
  siteTemplate: WithFolder<'SiteTemplate'>;
  elementTemplate: WithFolder<'ElementTemplate'>;
  profileTemplate: WithFolder<'ProfileTemplate'>;
  accountTemplate: WithFolder<'AccountTemplate'>;
  issueCatalog: WithFolder<'IssueCatalog'>;
  issueCatalogOpen: WithFolder<'IssueCatalog'>;
  issueCatalogScheduled: WithFolder<'IssueCatalog'>;
  unit: WithFolder<'Unit'>;
  account: WithFolder<'Account'>;
  profile: WithFolder<'Profile'>;
  label: FieldPopoverLabel;
  labelTask: FieldPopoverLabel;
  labelUnit: FieldPopoverLabel;
  labelSite: FieldPopoverLabel;
  labelIssue: FieldPopoverLabel;
  labelElement: FieldPopoverLabel;
  labelProfile: FieldPopoverLabel;
  labelAccount: FieldPopoverLabel;
  labelAction: FieldPopoverLabel;
  labelTimesheet: FieldPopoverLabel;
  labelMaterial: FieldPopoverLabel;
  labelTool: FieldPopoverLabel;
  issueCatalogOpenFolder: WithTypename<NexusGenFieldTypes['IssueCatalogFolder']>;
  issueCatalogScheduledFolder: WithTypename<NexusGenFieldTypes['IssueCatalogFolder']>;
};

export type FieldPopoverContext = keyof FieldPopoverItem;

type FolderTypes<T extends string> = T extends keyof NexusGenFieldTypes
  ? `${T}Folder` extends keyof NexusGenFieldTypes
    ? T
    : never
  : never;

export type FieldPopoverContextTemplates = Uncapitalize<
  Extract<Capitalize<FieldPopoverContext>, FolderTypes<keyof NexusGenFieldTypes>>
>;

export enum FieldPopoverActionType {
  BACK = 'BACK',
  SEARCH = 'SEARCH',
  ENTER = 'ENTER',
  SCROLL = 'SCROLL',
  LOADING = 'LOADING',
  LOADINGMORE = 'LOADINGMORE',
}

export type FieldPopoverAction<
  T extends { _id: string; __typename?: string },
  F extends { _id: string; __typename?: string } = T,
> =
  | {
      type: FieldPopoverActionType.SCROLL | FieldPopoverActionType.BACK;
      payload: {
        items: T[];
      };
    }
  | {
      type: FieldPopoverActionType.ENTER;
      payload: {
        item: F;
        items: T[];
      };
    }
  | {
      type: FieldPopoverActionType.SEARCH;
      payload: {
        items: T[];
        search: string;
      };
    }
  | {
      type: FieldPopoverActionType.LOADING;
    }
  | {
      type: FieldPopoverActionType.LOADINGMORE;
    };

export interface FieldPopoverNavigationState<
  T extends { _id: string; __typename?: string },
  F extends { _id: string; __typename?: string } = T,
> {
  parentsTree: F[];
  search: string | null;
  items: T[];
  limit: number;
  hasMore: boolean;
  orderBy: string;
  loading: boolean;
  loadingMore: boolean;
  filterIds?: string[]; //This filter will only work for assignees at the moment
}

export type FieldPopoverReducer<C extends FieldPopoverContext> = Reducer<
  FieldPopoverNavigationState<FieldPopoverItem[C], FieldPopoverFolder[C]>,
  FieldPopoverAction<FieldPopoverItem[C], FieldPopoverFolder[C]>
>;

export type NavigationDispatcher<C extends FieldPopoverContext> = (
  dispatch: Dispatch<FieldPopoverAction<FieldPopoverItem[C], FieldPopoverFolder[C]>>,
  state: FieldPopoverNavigationState<FieldPopoverItem[C], FieldPopoverFolder[C]>,
) => {
  onBack: () => Promise<void>;
  onScroll: () => Promise<void>;
  onEnter: (item?: FieldPopoverFolder[C]) => Promise<void>;
  onSearch: (search: string) => Promise<void>;
};

const initialState: FieldPopoverNavigationState<any> = {
  parentsTree: [],
  search: null,
  items: [],
  limit: 20,
  hasMore: false,
  orderBy: null,
  loading: false,
  loadingMore: false,
  filterIds: [],
};

export const useFieldPopoverNavigation = <C extends FieldPopoverContext>(
  context: C | null,
  navigationFactoryMap: Partial<typeof NavigationFactoryMap> = NavigationFactoryMap,
  filterIds?: string[],
): {
  state: FieldPopoverNavigationState<FieldPopoverItem[C]>;
  actions: ReturnType<NavigationDispatcher<C>>;
} => {
  initialState.filterIds = filterIds;
  const [state, dispatch] = useReducer<FieldPopoverReducer<C>>(navigationReducer, initialState);

  const actions = (navigationFactoryMap[context] as NavigationDispatcher<C>)?.(
    dispatch as Dispatch<FieldPopoverAction<FieldPopoverItem[C]>>,
    state,
  ) ?? { onEnter: identity, onScroll: identity, onBack: identity, onSearch: identity };
  return {
    state: {
      ...state,
      filterIds: filterIds,
    },
    //typechecker bug
    actions: {
      ...actions,
      onEnter: prependFunction(() => dispatch({ type: FieldPopoverActionType.LOADING }), actions.onEnter),
      onScroll: prependFunction(() => dispatch({ type: FieldPopoverActionType.LOADINGMORE }), actions.onScroll),
      onBack: prependFunction(() => dispatch({ type: FieldPopoverActionType.LOADING }), actions.onBack),
      onSearch: prependFunction(() => dispatch({ type: FieldPopoverActionType.LOADING }), actions.onSearch),
    },
  };
};

const navigationReducer: FieldPopoverReducer<any> = (state, action) => {
  const { type } = action;
  const payload = action['payload'] ?? undefined;

  switch (type) {
    case FieldPopoverActionType.BACK:
      return {
        ...state,
        items: payload.items,
        parentsTree: state.parentsTree.slice(1),
        hasMore: payload.items.length >= state.limit,
        loading: false,
      };
    case FieldPopoverActionType.SEARCH:
      return {
        ...state,
        items: payload.items,
        hasMore: payload.items.length >= state.limit,
        search: payload.search,
        loading: false,
      };
    case FieldPopoverActionType.ENTER:
      return {
        ...state,
        items: payload.items,
        parentsTree: [...(payload.item ? [payload.item] : []), ...state.parentsTree],
        hasMore: payload.items.length >= state.limit,
        loading: false,
      };
    case FieldPopoverActionType.SCROLL:
      return {
        ...state,
        items: uniqBy([...state.items, ...payload.items], '_id'),
        hasMore: payload.items.length >= state.limit,
        loadingMore: false,
        loading: false,
      };
    case FieldPopoverActionType.LOADING:
      return {
        ...state,
        loading: true,
      };
    case FieldPopoverActionType.LOADINGMORE:
      return {
        ...state,
        loadingMore: true,
      };
  }
};

const siteNavigationDispatcher = identity<NavigationDispatcher<'site'>>((dispatch, state) => {
  const getSiteFilters = (item?: FieldPopoverItem['site'], search?: string): NexusGenInputs['SiteWhereInput'] => {
    const searchFilter = search
      ? {
          name_contains: search ?? '',
        }
      : {};

    const siteFilter = {
      parent_eq: item?._id ?? null,
      ...searchFilter,
    };

    return siteFilter;
  };

  const getSitesOnly = (filters: NexusGenInputs['SiteWhereInput'], skip: number, limit: number) =>
    getSitesAndElements(filters, undefined, skip, limit);

  return {
    async onBack() {
      const filters = getSiteFilters(state.parentsTree.at(1));
      const items = await getSitesOnly(filters, 0, state.limit);

      dispatch({
        type: FieldPopoverActionType.BACK,
        payload: {
          items,
        },
      });
    },
    async onEnter(item) {
      const filters = getSiteFilters(item);
      const items = await getSitesOnly(filters, 0, state.limit);

      dispatch({
        type: FieldPopoverActionType.ENTER,
        payload: {
          item,
          items,
        },
      });
    },
    async onScroll() {
      const filters = getSiteFilters(state.parentsTree[0]);

      const items = await getSitesOnly(filters, state.items.length, state.limit);

      dispatch({
        type: FieldPopoverActionType.SCROLL,
        payload: {
          items,
        },
      });
    },
    async onSearch(search) {
      const filters = getSiteFilters(state.parentsTree[0], search);

      const items = await getSitesOnly(filters, 0, state.limit);

      dispatch({
        type: FieldPopoverActionType.SEARCH,
        payload: {
          items,
          search,
        },
      });
    },
  };
});

const labelNavigationDispatcher = (type?: NexusGenFieldTypes['Label']['context']) =>
  identity<NavigationDispatcher<'label'>>((dispatch, state) => {
    const getLabelsOrLabelValues = async (
      item: FieldPopoverItem['label'] | undefined,
      search: string | undefined,
      skip: number,
      limit: number,
    ) => {
      if (!item) {
        return (
          await getLabels(
            {
              name_contains: search ?? undefined,
              context_eq: type as any,
            },
            skip,
            limit,
          )(dispatch)
        ).map((r) => ({ ...r, totalItems: r.labelValues.length }));
      } else {
        return (
          await labelValuesPaginated(skip / state.limit, state.limit, {
            value_contains: search ?? undefined,
            label: { _id_eq: item._id },
          })(dispatch)
        ).labelValues.map((r) => ({ ...r, totalItems: 0 }));
      }
    };

    return {
      async onBack() {
        state.parentsTree;
        const items = (await getLabelsOrLabelValues(
          state.parentsTree.at(1),
          undefined,
          0,
          state.limit,
        )) as FieldPopoverItem['label'][];

        dispatch({
          type: FieldPopoverActionType.BACK,
          payload: {
            items,
          },
        });
      },
      async onEnter(item) {
        const items = (await getLabelsOrLabelValues(item, undefined, 0, state.limit)) as FieldPopoverItem['label'][];

        dispatch({
          type: FieldPopoverActionType.ENTER,
          payload: {
            item,
            items,
          },
        });
      },
      async onScroll() {
        const items = (await getLabelsOrLabelValues(
          state.parentsTree.at(0),
          undefined,
          state.items.length,
          state.limit,
        )) as FieldPopoverItem['label'][];

        dispatch({
          type: FieldPopoverActionType.SCROLL,
          payload: {
            items,
          },
        });
      },
      async onSearch(search) {
        const items = (await getLabelsOrLabelValues(
          state.parentsTree.at(0),
          search,
          0,
          state.limit,
        )) as FieldPopoverItem['label'][];

        dispatch({
          type: FieldPopoverActionType.SEARCH,
          payload: {
            items,
            search,
          },
        });
      },
    };
  });

const assigneeNavigationDispatcher = identity<NavigationDispatcher<'assignee'>>((dispatch, state) => {
  return {
    async onBack() {},
    async onEnter() {
      const items = await getAssignees(
        { name_contains: state.search ?? undefined, _id_nin: state.filterIds ?? undefined },
        0,
        state.limit,
      );

      dispatch({
        type: FieldPopoverActionType.ENTER,
        payload: {
          item: null,
          items,
        },
      });
    },
    async onScroll() {
      const items = await getAssignees(
        { name_contains: state.search ?? undefined, _id_nin: state.filterIds ?? undefined },
        state.items.length,
        state.limit,
      );

      dispatch({
        type: FieldPopoverActionType.SCROLL,
        payload: {
          items,
        },
      });
    },
    async onSearch(search) {
      const items = await getAssignees(
        { name_contains: search, _id_nin: state.filterIds ?? undefined },
        0,
        state.limit,
      );

      dispatch({
        type: FieldPopoverActionType.SEARCH,
        payload: {
          items,
          search,
        },
      });
    },
  };
});

const roleNavigationDispatcher = (dispatch, state) => {
  return {
    async onBack() {},
    async onEnter() {
      const items = await getRoles({ name_contains: state.search ?? undefined }, 0, state.limit);

      dispatch({
        type: FieldPopoverActionType.ENTER,
        payload: {
          item: null,
          items,
        },
      });
    },
    async onScroll() {
      const items = await getRoles({ name_contains: state.search ?? undefined }, state.items.length, state.limit);

      dispatch({
        type: FieldPopoverActionType.SCROLL,
        payload: {
          items,
        },
      });
    },
    async onSearch(search) {
      const items = await getRoles({ name_contains: search }, 0, state.limit);

      dispatch({
        type: FieldPopoverActionType.SEARCH,
        payload: {
          items,
          search,
        },
      });
    },
  };
};

const toolNavigationDispatcher = (dispatch, state) => {
  return {
    async onBack() {},
    async onEnter() {
      const items = await getTools({ designation_contains: state.search ?? undefined }, 0, state.limit);

      dispatch({
        type: FieldPopoverActionType.ENTER,
        payload: {
          item: null,
          items,
        },
      });
    },
    async onScroll() {
      const items = await getTools(
        { designation_contains: state.search ?? undefined },
        state.items.length,
        state.limit,
      );

      dispatch({
        type: FieldPopoverActionType.SCROLL,
        payload: {
          items,
        },
      });
    },
    async onSearch(search) {
      const items = await getTools(
        {
          OR: [{ partNumber_contains: search }, { designation_contains: search }],
        },
        0,
        state.limit,
      );
      dispatch({
        type: FieldPopoverActionType.SEARCH,
        payload: {
          items,
          search,
        },
      });
    },
  };
};

const materialNavigationDispatcher = (dispatch, state) => {
  return {
    async onBack() {},
    async onEnter() {
      const items = await getMaterials({ name_contains: state.search ?? undefined }, 0, state.limit);

      dispatch({
        type: FieldPopoverActionType.ENTER,
        payload: {
          item: null,
          items,
        },
      });
    },
    async onScroll() {
      const items = await getMaterials({ name_contains: state.search ?? undefined }, state.items.length, state.limit);

      dispatch({
        type: FieldPopoverActionType.SCROLL,
        payload: {
          items,
        },
      });
    },
    async onSearch(search) {
      const items = await getMaterials(
        {
          OR: [{ name_contains: search }, { code_contains: search }],
        },
        0,
        state.limit,
      );
      dispatch({
        type: FieldPopoverActionType.SEARCH,
        payload: {
          items,
          search,
        },
      });
    },
  };
};

const siteElementNavigationDispatcher = (withTags?: boolean) =>
  identity<NavigationDispatcher<'siteElement'>>((dispatch, state) => {
    const getSiteElementFilters = (
      item?: FieldPopoverItem['siteElement'],
      search?: string,
    ): { siteFilter?: NexusGenInputs['SiteWhereInput']; elementFilter?: NexusGenInputs['ElementWhereInput'] } => {
      const isElement = !!item?.__typename.endsWith('Element');

      const commonFilter = search
        ? {
            name_contains: search ?? '',
          }
        : {};

      const siteFilter = !isElement
        ? {
            parent_eq: item?._id ?? null,
            ...commonFilter,
          }
        : undefined;

      const elementFilter = item
        ? isElement
          ? {
              parent_eq: item._id,
              ...commonFilter,
            }
          : {
              site_eq: item._id,
              parent_eq: null,
              ...commonFilter,
            }
        : undefined;

      return { siteFilter, elementFilter };
    };

    return {
      async onBack() {
        const filters = getSiteElementFilters(state.parentsTree.at(1));
        const items = await getSitesAndElements(filters.siteFilter, filters.elementFilter, 0, state.limit, withTags);

        dispatch({
          type: FieldPopoverActionType.BACK,
          payload: {
            items,
          },
        });
      },
      async onEnter(item) {
        const filters = getSiteElementFilters(item);

        const items = await getSitesAndElements(filters.siteFilter, filters.elementFilter, 0, state.limit, withTags);

        dispatch({
          type: FieldPopoverActionType.ENTER,
          payload: {
            item,
            items,
          },
        });
      },
      async onScroll() {
        const filters = getSiteElementFilters(state.parentsTree[0]);

        const items = await getSitesAndElements(
          filters.siteFilter,
          filters.elementFilter,
          state.items.length,
          state.limit,
          withTags,
        );

        dispatch({
          type: FieldPopoverActionType.SCROLL,
          payload: {
            items,
          },
        });
      },
      async onSearch(search) {
        const filters = getSiteElementFilters(state.parentsTree[0], search);

        const items = await getSitesAndElements(filters.siteFilter, filters.elementFilter, 0, state.limit, withTags);

        dispatch({
          type: FieldPopoverActionType.SEARCH,
          payload: {
            items,
            search,
          },
        });
      },
    };
  });

//autogenerate template dispatchers
const templateNavigationDispatcher = <T extends FieldPopoverContextTemplates>(context: T) =>
  identity<NavigationDispatcher<T>>((dispatch, state) => {
    const getTemplateFilters = (
      item?: FieldPopoverItem[T],
      search?: string,
    ): {
      templateFilter?: NexusGenInputs['IssueTemplateWhereInput'];
      templateFolderFilter?: NexusGenInputs['IssueTemplateFolderWhereInput'];
    } => {
      //profiles can have parent profiles
      const templateFilter = {
        ...(!item
          ? { folder: { _id_eq: null }, parent_eq: context === 'profile' ? null : undefined }
          : item.__typename.endsWith('Folder')
            ? { folder: { _id_eq: item._id }, parent_eq: context === 'profile' ? null : undefined }
            : { parent_eq: item._id }),
        title_contains: context.endsWith('Template') ? (search ?? undefined) : undefined,
        name_contains: !context.endsWith('Template') ? (search ?? undefined) : undefined,
        variant:
          context === 'issueTemplate'
            ? {
                position_eq: 1,
              }
            : undefined,
      };

      const templateFolderFilter = {
        parent_eq: item?._id ?? null,
        name_contains: search ?? undefined,
      };

      return { templateFilter, templateFolderFilter };
    };

    return {
      async onBack() {
        const { templateFilter, templateFolderFilter } = getTemplateFilters(state.parentsTree.at(1));
        const items = await getWithFoldersGenerics[context](templateFilter, templateFolderFilter, 0, state.limit);
        items[0];

        dispatch({
          type: FieldPopoverActionType.BACK,
          payload: {
            items,
          },
        });
      },
      onEnter: async function (item): Promise<void> {
        const { templateFilter, templateFolderFilter } = getTemplateFilters(item);

        const items = await getWithFoldersGenerics[context](templateFilter, templateFolderFilter, 0, state.limit);

        dispatch({
          type: FieldPopoverActionType.ENTER,
          payload: {
            item,
            items,
          },
        });
      },
      async onScroll() {
        const { templateFilter, templateFolderFilter } = getTemplateFilters(state.parentsTree[0], state.search);

        const items = await getWithFoldersGenerics[context](
          templateFilter,
          templateFolderFilter,
          state.items.length,
          state.limit,
        );

        dispatch({
          type: FieldPopoverActionType.SCROLL,
          payload: {
            items,
          },
        });
      },
      async onSearch(search) {
        const { templateFilter, templateFolderFilter } = getTemplateFilters(state.parentsTree[0], search);

        const items = await getWithFoldersGenerics[context](templateFilter, templateFolderFilter, 0, state.limit);

        dispatch({
          type: FieldPopoverActionType.SEARCH,
          payload: {
            items,
            search,
          },
        });
      },
    };
  });

const issueCatalogNavigationDispatcher = <T extends boolean | undefined>(scheduled: T) =>
  identity<
    NavigationDispatcher<
      T extends true ? 'issueCatalogScheduled' : T extends false ? 'issueCatalogOpen' : 'issueCatalog'
    >
  >((dispatch, state) => {
    const getCatalogFilters = (
      item?: FieldPopoverItem['issueCatalog'],
      search?: string,
    ): {
      catalogFilter?: NexusGenInputs['IssueCatalogWhereInput'];
      catalogFolderFilter?: NexusGenInputs['IssueCatalogFolderWhereInput'];
    } => {
      const catalogFilter = {
        folder: { _id_eq: item?._id ?? null },
        name_contains: search ?? undefined,
        scheduler_exists: scheduled,
      };

      const catalogFolderFilter = {
        parent_eq: item?._id ?? null,
        name_contains: search ?? undefined,
        context_eq: scheduled ? 'scheduled' : scheduled === false ? 'open' : undefined,
      };

      return { catalogFilter, catalogFolderFilter };
    };

    return {
      async onBack() {
        const { catalogFilter, catalogFolderFilter } = getCatalogFilters(state.parentsTree.at(1));
        const items = await getWithFoldersGenerics['issueCatalog'](catalogFilter, catalogFolderFilter, 0, state.limit);
        items[0];

        dispatch({
          type: FieldPopoverActionType.BACK,
          payload: {
            items,
          },
        });
      },
      onEnter: async function (item): Promise<void> {
        const { catalogFilter, catalogFolderFilter } = getCatalogFilters(item);

        const items = await getWithFoldersGenerics['issueCatalog'](catalogFilter, catalogFolderFilter, 0, state.limit);

        dispatch({
          type: FieldPopoverActionType.ENTER,
          payload: {
            item,
            items,
          },
        });
      },
      async onScroll() {
        const { catalogFilter, catalogFolderFilter } = getCatalogFilters(state.parentsTree[0], state.search);

        const items = await getWithFoldersGenerics['issueCatalog'](
          catalogFilter,
          catalogFolderFilter,
          state.items.length,
          state.limit,
        );

        dispatch({
          type: FieldPopoverActionType.SCROLL,
          payload: {
            items,
          },
        });
      },
      async onSearch(search) {
        const { catalogFilter, catalogFolderFilter } = getCatalogFilters(state.parentsTree[0], search);

        const items = await getWithFoldersGenerics['issueCatalog'](catalogFilter, catalogFolderFilter, 0, state.limit);

        dispatch({
          type: FieldPopoverActionType.SEARCH,
          payload: {
            items,
            search,
          },
        });
      },
    };
  });

const issueCatalogFolderNavigationDispatcher = (scheduled: boolean) => (dispatch, state) => {
  const getFilters = (
    item: FieldPopoverItem['issueCatalogScheduledFolder'],
    search?: string,
  ): IssueCatalogFolderWhereInput => {
    return {
      parent_eq: item?._id ?? null,
      name_contains: search ?? undefined,
      context_eq: scheduled ? 'scheduled' : scheduled === false ? 'open' : undefined,
    };
  };

  return {
    async onBack() {
      const filter = getFilters(state.parentsTree.at(1));
      const items = await getIssueCatalogsFolders(state.limit, 0, filter);
      items[0];

      dispatch({
        type: FieldPopoverActionType.BACK,
        payload: {
          items,
        },
      });
    },
    onEnter: async function (item) {
      const filter = getFilters(item);
      const items = await getIssueCatalogsFolders(state.limit, 0, filter);

      dispatch({
        type: FieldPopoverActionType.ENTER,
        payload: {
          item,
          items,
        },
      });
    },
    async onScroll() {
      const filter = getFilters(state.parentsTree[0], state.search);
      const items = await getIssueCatalogsFolders(state.limit, state.items.length, filter);
      dispatch({
        type: FieldPopoverActionType.SCROLL,
        payload: {
          items,
        },
      });
    },
    async onSearch(search) {
      const filter = getFilters(state.parentsTree.at(1), search);
      const items = await getIssueCatalogsFolders(state.limit, 0, filter);
      dispatch({
        type: FieldPopoverActionType.SEARCH,
        payload: {
          items,
          search,
        },
      });
    },
  };
};

const NavigationFactoryMap = {
  site: siteNavigationDispatcher,
  element: siteElementNavigationDispatcher(),
  siteElement: siteElementNavigationDispatcher(),
  tag: siteElementNavigationDispatcher(true),
  assignee: assigneeNavigationDispatcher,
  issueTemplate: templateNavigationDispatcher('issueTemplate'),
  siteTemplate: templateNavigationDispatcher('siteTemplate'),
  elementTemplate: templateNavigationDispatcher('elementTemplate'),
  accountTemplate: templateNavigationDispatcher('accountTemplate'),
  profileTemplate: templateNavigationDispatcher('profileTemplate'),
  profile: templateNavigationDispatcher('profile'),
  account: templateNavigationDispatcher('account'),
  role: roleNavigationDispatcher,
  unit: templateNavigationDispatcher('unit'),
  issueCatalogOpen: issueCatalogNavigationDispatcher(false),
  issueCatalogScheduled: issueCatalogNavigationDispatcher(true),
  issueCatalog: issueCatalogNavigationDispatcher(null),
  label: labelNavigationDispatcher(),
  labelAccount: labelNavigationDispatcher('Account'),
  labelAction: labelNavigationDispatcher('Action'),
  labelElement: labelNavigationDispatcher('Element'),
  labelIssue: labelNavigationDispatcher('Issue'),
  labelMaterial: labelNavigationDispatcher('Material'),
  labelProfile: labelNavigationDispatcher('Profile'),
  labelSite: labelNavigationDispatcher('Site'),
  labelTask: labelNavigationDispatcher('Task'),
  labelTimesheet: labelNavigationDispatcher('Timesheet'),
  labelTool: labelNavigationDispatcher('Tool'),
  labelUnit: labelNavigationDispatcher('Unit'),
  issueCatalogScheduledFolder: issueCatalogFolderNavigationDispatcher(true),
  issueCatalogOpenFolder: issueCatalogFolderNavigationDispatcher(false),
  tool: toolNavigationDispatcher,
  material: materialNavigationDispatcher,
};

export const BetterFieldPopoverNavigationContext =
  createContext<Partial<typeof NavigationFactoryMap>>(NavigationFactoryMap);
