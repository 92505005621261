import { ReactNode, createContext, useContext, useState } from 'react';
import { SubjectsState } from '../types/subject.types';

export const subjectsContextInitialValue: SubjectsState = {
  subjects: [],
  totalSubjects: 0,
  selectedSubjects: [],
  refetchRequired: false,

  searchActive: true,
  search: '',
  tableState: {
    limit: 25,
    orderBy: 'name',
    orderType: '_ASC',
  },

  // MODALS
  openCreateSubjectModal: false,
  openDeleteDialog: false,
};

const SubjectsContext = createContext<SubjectsState>(subjectsContextInitialValue);

interface SubjectsContextProviderProps {
  children: ReactNode;
  initialValue: SubjectsState;
}

export const SubjectsContextProvider = ({
  children,
  initialValue = subjectsContextInitialValue,
}: SubjectsContextProviderProps) => {
  const [subjectsState, setSubjectsState] = useState<SubjectsState>(initialValue);

  const updateSubjectsState = (newData: Partial<SubjectsState>) => {
    setSubjectsState((prevState) => ({ ...prevState, ...newData }));
  };

  return (
    <SubjectsContext.Provider value={{ ...subjectsState, updateSubjectsState, setSubjectsState }}>
      {children}
    </SubjectsContext.Provider>
  );
};

export const useSubjectsContext = () => {
  const context = useContext(SubjectsContext);
  if (!context) {
    throw new Error('useSubjectContext must be used within a SubjectContext');
  }
  return context;
};
