export enum SortTypes {
  default = 'default',
  value = 'value',
  valueCount = 'valueCount',
  alphabetical = 'alphabetical',
}

export enum SortOrder {
  default = 'default',
  asc = 'asc',
  desc = 'desc',
}

export type SortPreset = {
  name: string;
  type: SortTypes;
  order: SortOrder;
};
