import '@fontsource/poppins/500.css';
import { Theme } from '@mui/material/styles';

const theme: Theme = {
  palette: {
    primary: {
      main: '#397EBA',
      light: '#1E4363',
      dark: '#275882',
    },
    secondary: {
      main: '#FFFFFF',
      dark: '#E9F5FE',
    },
    background: {
      default: '#202023',
      background0: '#26262A',
      background1: '#2C2C30',
      background2: '#1B3C59',
      background3: '#232227',
    },
    grey: {
      main: '#D0D5DD',
      light: '#3F4651',
      medium: '#98A2B3',
    },
    text: {
      primary: '#FFFFFF',
      disabled: '#3F4651',
      secondary: '#D0D5DD',
    },
    success: {
      main: '#00C48C',
      dark: '#02AB7B',
    },
    alert: {
      main: '#FFD279',
      light: '#B88724',
      dark: '#FFC654',
    },
    error: {
      main: '#F44336',
      light: '#8A322D',
      medium: '#F9A09A',
      dark: '#E31B0C',
    },
    info: {
      main: '#54C1FB',
      light: '#1E4363',
    },
    other: {
      pink: '#EB43B2',
      purpleDark: '#7C18FB',
      purpleLight: '#B468FF',
      peach: '#FE8947',
    },
    label: {
      label1: '#FFFFFF',
      label2: '#97C3D1',
      label3: '#2A9D8F',
      label4: '#E9C46A',
      label5: '#F4A261',
      label6: '#E76F5',
      label7: '#EF476F',
      label8: '#FFD166',
      label9: '#06D6AD',
      label10: '#118AB2',
      label11: '#669BAD',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '55px',
          display: 'flex',
          flexDirection: 'row',
          boxShadow: 'none',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#26272F',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '35px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontSize: '0.875rem',
          fontFamily: '"Poppins", sans-serif;',
        },
        body2: {
          fontFamily: 'Open Sans',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '22px',
        },
        caption: {
          fontFamily: 'Open Sans',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '18px',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          minHeight: '0px !important',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          zIndex: 0,
          fontFamily: '"Poppins", sans-serif;',
          fontWeight: 500,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#1E4363',
          },
        },
      },
    },
  },
  typography: {
    subtitle1: {
      fontFamily: '"Open Sans", sans-serif;',
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '16px',
    },
    body1: {
      fontFamily: '"Open Sans", sans-serif;',
      fontWeight: 400,
      fontSize: '0.875rem',
      /* fontSize: '16px', */
      lineHeight: '24px',
    },
    h5: {
      fontFamily: '"Poppins", sans-serif;',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      textAlign: 'center',
    },
    h3: {
      fontFamily: '"Poppins", sans-serif;',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '28px',
      textAlign: 'center',
    },
    fontSize: 14,
    h4: {
      fontFamily: '"Poppins", sans-serif;',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '26px',
      textAlign: 'center',
    },
    button: {
      textTransform: 'none',
    },
  },
} as any;

export default theme;
