import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';

const LabelCreateDefaultRefresh = () => lazy(() => import('src/modules/label/label.create.default'));

let LabelCreateDefault = LabelCreateDefaultRefresh();

export const render = {
  create: {
    default: ({ ...props }) => {
      if (LabelCreateDefault?._result?.type === 'error') {
        LabelCreateDefault = LabelCreateDefaultRefresh();
      }
      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <LabelCreateDefault {...props} />
        </Suspense>
      );
    },
  },
};

export default render;
