import gql from 'graphql-tag';

export const M_GET_SESSION_BY_TOKEN = gql`
  mutation GetSessionByToken($token: String!) {
    getSessionByToken(token: $token) {
      ... on Session {
        id
        tenant_id
        is_guest
        token
        room_id
        create_at
        update_at
        expires_at
        last_activity_at
        user_id
        user_email
        device_id
        roles
        entity_roles
        approved
        is_oauth
        props
        details {
          name
          microphone
          video_track
          video_track_type
          capabilities
          current_state
          room_features
        }
        language
        user_contact_list
      }
    }
  }
`;
