import { Close, Edit, House, LocalLibrary, LocationOn, MoreVert, North, Search, South } from '@mui/icons-material';
import { ColumnDef } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TenantOrderByInput,
  TenantsInfoQuery,
  TenantWhereInput,
  useTenantsInfoCountQuery,
  useTenantsInfoLazyQuery,
  useUpdateTenantMutation,
} from 'src/gql/graphql';
import TableAbstract from 'src/utils/components/table/abstract/table-abstract';
import { useStyles } from './styles';
import { IconButton, InputBase, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import DefaultButton from 'src/utils/components/default-button/default-button';
import { CreateTenantModal, TenantModalMode } from './create-tenant-modal';
import useDebouncedCallback from 'src/utils/hooks/useDebouncedCallback';
import { withRefresh } from 'src/utils/components/refresh-provider';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DeleteModal } from 'src/utils/components/delete-modal';
import { css } from '@emotion/css';
import { useSnackbar } from 'notistack';
import { ToastMessage } from '../feed/components/toast';

const centerItems = css({
  display: 'flex',
  justifyContent: 'stretch',
  alignItems: 'center',
  gap: '8px !important',
});

export const TenantsDefault = withRefresh(function () {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();

  const [openSearch, setOpenSearch] = useState(false);
  const [filters, setFilters] = useState<{ search?: string }>({});
  const [openCreateTenantModal, setOpencreateTenantModal] = useState(false);
  const [openMoreVert, setOpenMoreVert] = useState<{ row: string; anchor: HTMLDivElement }>(null);
  const [openEditTenantModal, setOpenEditTenantModal] = useState<string>(null);
  const [tenants, setTenants] = useState<TenantsInfoQuery['tenants']>([]);
  const [orderBy, setOrderBy] = useState<TenantOrderByInput>(TenantOrderByInput.name_ASC);

  const [tableOrderKey, tableOrderType] = orderBy?.split('_') || [];

  const { enqueueSnackbar } = useSnackbar();

  const onError = (error) => {
    enqueueSnackbar(<ToastMessage severity='error' message={error.message} />, {
      variant: 'error',
    });
  };

  const setSearch = useDebouncedCallback(
    (val?: string) => setFilters((filters) => ({ ...filters, search: val })),
    200,
    [],
  );

  const [updateTenant] = useUpdateTenantMutation();

  const where = useMemo<TenantWhereInput>(
    () => ({
      name_contains: filters.search ? filters.search : undefined,
    }),
    [filters],
  );

  const [queryTenants, { loading }] = useTenantsInfoLazyQuery({
    onError(error) {
      throw new Error(error.message);
    },
  });

  const { data: { tenantsCount = 0 } = {} } = useTenantsInfoCountQuery({
    variables: {
      where,
    },
  });

  const moreVertTenant = useMemo(
    () => (openMoreVert ? tenants.find((t) => t._id === openMoreVert.row) : null),
    [openMoreVert, tenants],
  );

  const tableHeaderTitle = (name: string, orderKey: string) => {
    return (
      <div className={classes.divHeader}>
        <span
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          className={tableOrderKey === orderKey ? classes.columnSorting : undefined}
        >
          {t(name)}
        </span>
        {tableOrderType === 'ASC' && tableOrderKey === orderKey ? (
          <North
            classes={{
              root: `${classes.sortArrowIcon} ${tableOrderKey === orderKey ? classes.sortArrowIconVisible : classes.sortArrowIconHidden}`,
            }}
            onClick={(e) => {
              e.stopPropagation();
              setOrderBy((orderKey + '_DESC') as any);
            }}
          />
        ) : (
          <South
            classes={{
              root: `${classes.sortArrowIcon} ${tableOrderKey === orderKey ? classes.sortArrowIconVisible : classes.sortArrowIconHidden}`,
            }}
            onClick={(e) => {
              e.stopPropagation();
              setOrderBy((orderKey + '_ASC') as any);
            }}
          />
        )}
      </div>
    );
  };

  const columns: ColumnDef<TenantsInfoQuery['tenants'][0]>[] = useMemo(
    () => [
      {
        id: '_id',
        accessorKey: '_id',
        size: 50,
        minSize: 50,
        enableResizing: false,
        header: '',
        cell: (props) => (
          <IconButton onClick={() => navigate(`/tenants/${props.row.original._id}`)}>
            <LocationOn />
          </IconButton>
        ),
      },
      {
        id: 'name',
        accessorKey: 'name',
        header: () => tableHeaderTitle('name', 'name'),
        cell: (props) => props.row.original.name,
      },
      {
        id: 'domain-name',
        accessorKey: 'domain',
        header: () => tableHeaderTitle('domainName', 'domain'),
        cell: (props) => props.row.original.domain,
      },
      {
        id: 'created-at',
        accessorKey: 'createdAt',
        header: () => tableHeaderTitle('createdAt', 'createdAt'),
        cell: (props) => moment(props.row.original.createdAt).format(`DD/MM/YYYY [${t('at')}] hh:mm`),
      },
      {
        id: 'users-added',
        accessorKey: 'totalAccounts',
        header: () => t('usersAdded'),
        cell: (props) => <span>{props.row.original.totalAccounts}</span>,
      },
    ],
    [orderBy, classes],
  );

  return (
    <>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 12,
        }}
      >
        <Typography variant={'h3'} color={theme.palette.primary.dark}>
          {t('tenants')}
        </Typography>
        <DefaultButton
          data-testid={'create-tenant-button'}
          onClick={() => setOpencreateTenantModal(true)}
          variant={'default'}
        >
          {t('createTenant')}
        </DefaultButton>
      </div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: 12,
        }}
      >
        {openSearch ? (
          <>
            <InputBase
              autoFocus
              css={{
                border: `1px solid ${theme.palette.primary.main}`,
                borderRadius: '4px',
                padding: '0px 4px',
              }}
              key={filters.search}
              defaultValue={filters.search ?? ''}
              onChange={(e) => {
                const val = e.target.value;
                setSearch(val);
              }}
            />
            <IconButton
              onClick={() => {
                setOpenSearch(false);
                setFilters((filters) => ({ ...filters, search: undefined }));
              }}
            >
              <Close />
            </IconButton>
          </>
        ) : (
          <IconButton onClick={() => setOpenSearch(true)}>
            <Search />
          </IconButton>
        )}
      </div>
      <TableAbstract
        showLoading={loading}
        columns={columns}
        data-testid='tenants-table'
        dataSize={tenantsCount}
        onDataUpdated={setTenants}
        moreOptionsCell={(props) => (
          <IconButton onClick={(ev) => setOpenMoreVert({ row: props.row.original._id, anchor: ev.target as any })}>
            <MoreVert />
          </IconButton>
        )}
        loadData={async (skip, limit) => {
          {
            const response = await queryTenants({
              variables: {
                where,
                limit,
                skip,
                orderBy,
              },
            });

            return response.data.tenants;
          }
        }}
        refetchDependencies={[filters, orderBy]}
      />
      {openCreateTenantModal && (
        <CreateTenantModal onClose={() => setOpencreateTenantModal(false)} mode={TenantModalMode.Create} />
      )}
      {openEditTenantModal && (
        <CreateTenantModal
          onClose={() => setOpenEditTenantModal(null)}
          mode={TenantModalMode.Update}
          tenantId={openEditTenantModal}
        />
      )}
      {!!openMoreVert && (
        <Menu
          open={true}
          anchorEl={openMoreVert.anchor}
          transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          onClose={() => setOpenMoreVert(null)}
        >
          <MenuItem
            data-testid={'edit-tenant-modal'}
            className={centerItems}
            onClick={() => {
              setOpenEditTenantModal(openMoreVert.row);
              setOpenMoreVert(null);
            }}
          >
            <Edit htmlColor={theme.palette.alert.dark} />
            <span>{t('edit')}</span>
          </MenuItem>
          {!moreVertTenant.master && (
            <MenuItem
              data-testid={'make-tenant-master'}
              disabled={tenants.find((t) => t._id === openMoreVert.row).master}
              className={centerItems}
              onClick={() => {
                setOpenMoreVert(null);
                DeleteModal({
                  title: t('areYouSureMakeTenantMaster'),
                  cancelButtonText: t('cancel'),
                  confirmButtonText: t('confirm'),
                  success() {
                    updateTenant({
                      variables: {
                        id: openMoreVert.row,
                        data: {
                          master: true,
                        },
                      },
                      onError,
                    });
                  },
                });
              }}
            >
              <LocalLibrary htmlColor={theme.palette.label.label2} />
              <span>{t('makeTenantMaster')}</span>
            </MenuItem>
          )}
          {!moreVertTenant.default && (
            <MenuItem
              data-testid={'make-tenant-default'}
              disabled={tenants.find((t) => t._id === openMoreVert.row).default}
              className={centerItems}
              onClick={() => {
                setOpenMoreVert(null);
                DeleteModal({
                  title: t('areYouSureMakeTenantDefault'),
                  cancelButtonText: t('cancel'),
                  confirmButtonText: t('confirm'),
                  success() {
                    updateTenant({
                      variables: {
                        id: openMoreVert.row,
                        data: {
                          default: true,
                        },
                      },
                      onError,
                    });
                  },
                });
              }}
            >
              <House htmlColor={theme.palette.primary.dark} />
              <span>{t('makeTenantDefault')}</span>
            </MenuItem>
          )}
        </Menu>
      )}
    </>
  );
});
