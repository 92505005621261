import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    paperContainer: {
      height: '700px',
      width: '1150px',
      maxWidth: '80vw',
      padding: 16,
      overflow: 'hidden',
      background: theme.palette.background.default,
    },
    editChartPaperContainer: {
      width: 'fit-content',
      maxWidth: '80vw',
      padding: 16,
      overflow: 'hidden',
      background: theme.palette.background.default,
    },
    topContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    closeIcon: {
      fontSize: '24px',
      cursor: 'pointer',
    },
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: '20% 1fr',
      height: '100%',
      marginTop: 10,
    },
    dataTypes: {
      overflow: 'hidden',
      display: 'flex',
      gap: 8,
      flexFlow: 'column nowrap',
      borderRadius: 2,
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: theme.palette.background.background0,
      '& > span': {
        cursor: 'pointer',
        width: '100%',
        fontWeight: 500,
        fontSize: 16,
        padding: '8px',
        transition: 'background-color 0.1s ease,color 0.1s ease',
        '&:hover, &[data-selected=true]': {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.light,
        },
      },
    },
    selectionGridItem: {
      display: 'grid',
      overflowY: 'auto',
      margin: 30,
      padding: 10,
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: 'auto',
      alignContent: 'start',
      gridGap: '24px',
      marginTop: 0,
    },
    editChartItem: {
      display: 'grid',
      overflowY: 'auto',
      margin: 30,
      padding: 10,
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      alignContent: 'start',
      gridGap: '24px',
      marginTop: 0,
    },
    editChartItemTwoColumns: {
      gridTemplateColumns: '1fr 1fr',
    },
    chartCard: {
      height: 160,
      padding: 8,
      cursor: 'pointer',
      boxShadow:
        '0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 8,
      overflowY: 'hidden',
      background: theme.palette.background.default,
      '&:hover': {
        '& > span:first-of-type': {
          color: theme.palette.text.primary,
        },
        backgroundColor: theme.palette.primary.light,
        transform: 'scale(1.02)',
        transition: 'transform .2s',
      },
    },
    chartCardHeader: {
      fontWeight: 500,
      fontSize: 14,
      textAlign: 'center',
    },
    chartCardDescription: {
      color: theme.palette.text.secondary,
      textAlign: 'center',
    },
    editChartTitle: {
      fontSize: '16px',
      fontWeight: 500,
      fontFamily: 'Poppins',
      textAlign: 'center',
    },
    editChartText: {
      fontSize: '16px',
      fontWeight: 400,
      fontFamily: 'Open Sans',
      color: theme.palette.text.secondary,
      textAlign: 'center',
    },
  });
