import gql from 'graphql-tag';
import { ISSUE_CATALOG_DRAFT, ISSUE_INSTANCE, ISSUE_TEMPLATE } from './issue';
import { STATE_MACHINE_INSTANCE } from './stateMachine';
import { LOCATION, STORAGE, TOOL, TOOL_ITEM } from './inventory';
import {
  INPUT,
  INPUT_NUMBER,
  INPUT_STRING,
  INPUT_WITH_LOG,
  INPUT_WITH_RECURSIVE,
  ON_RESPONSE_COMMON_NUMBER,
  ON_RESPONSE_COMMON_STRING,
} from './input';
import { TASK_INSTANCE_TO_TABLE } from './task';
import { LABEL_VALUE } from './label';

export const ON_BOARDING = gql`
  fragment onBoarding on Account {
    onBoarding {
      step
      finished
      currentSubStep
      maxSubStep
      progressTotal
      availableSteps
      doingOnboarding
      templateDraft {
        _id
      }
      template {
        ...issueTemplate
        taskTemplateGroups {
          name
          order
          sequential
          _id
          markersToShow
          taskTemplates {
            name
            order
            onResponseId
            parentsTree
            estimatedDuration
            _id
            variant {
              variantId
              position
              name
            }
            isRepeatable
            assignedTools {
              tool {
                designation
                partNumber
                _id
              }
              quantity
            }
            assignedMaterials {
              material {
                code
                name
                _id
              }
              quantity
            }
            arConfiguration {
              markersType
              markersColor
              markersOnFinishingTask
              markersSize
              typeOfInput
              timerFinishTask
            }
            advancedLabelSelection {
              name
              filter {
                includeAllLabels
                conditions {
                  operator
                  labelValue {
                    __typename
                    value
                    _id
                    backgroundColor
                    description
                    label {
                      context
                      name
                      _id
                      labelValues {
                        ...labelValue
                      }
                    }
                  }
                }
              }
            }
            labelValues {
              ...labelValue
            }
            inputs {
              ...inputWithRecursive
            }
          }
        }
      }
      openIssueDraft {
        ...issueCatalogDraft
      }
      openIssue {
        _id
      }
      issueInstance {
        ...issueInstance
        startDate
        taskGroups {
          name
          _id
          sequential
          order
          tasks {
            ...taskInstanceToTable
            element {
              name
            }
            site {
              name
            }
            onResponseId
            parentsTree
            isActiveByOnResponse
            assignedAccounts {
              name
              email
              photo
              _id
              _id
              deleted
            }
            labelValues {
              ...labelValue
            }
            assignedLabelValues {
              ...labelValue
            }
            score {
              totalScore
              currentScore
            }
            assignedTools {
              tool {
                _id
                designation
              }
              reserveAtStart
              associatedWith {
                designation
                partNumber
                _id
              }
            }
          }
        }
        workPackageId
        workPackage {
          _id
          workPackageName
          totalIssuesDone
          issueCatalogs {
            _id
            name
          }
        }
        toolItems {
          _id
          available
          status
          description
          serialNumber
          code
          createdBy {
            name
            email
            photo
            _id
          }
          createdAt
          tool {
            _id
            designation
            partNumber
          }
          location {
            _id
            address {
              street
              site {
                name
              }
              element {
                name
              }
            }
            storage {
              _id
              name
              address {
                street
                site {
                  name
                }
                element {
                  name
                }
              }
            }
          }
          usedBy {
            name
            email
            photo
            _id
          }
          usedIn {
            _id
            glarID
            name
          }
        }
        toolAssociations {
          associatedToolItems {
            ...toolItem
          }
          tool {
            ...tool
          }
          toolItems {
            ...toolItem
          }
          associatedTools {
            ...tool
          }
        }
      }
    }
  }
  ${INPUT}
  ${INPUT_WITH_LOG}
  ${INPUT_WITH_RECURSIVE}
  ${STATE_MACHINE_INSTANCE}
  ${ISSUE_INSTANCE}
  ${TASK_INSTANCE_TO_TABLE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
  ${TOOL}
  ${TOOL_ITEM}
  ${LOCATION}
  ${STORAGE}
  ${ISSUE_CATALOG_DRAFT}
  ${ISSUE_TEMPLATE}
  ${LABEL_VALUE}
`;

export const ACCOUNT_TEMPLATE = gql`
  fragment accountTemplate on AccountTemplate {
    __typename
    title
    folder {
      parentsTree {
        _id
        name
      }
      parent {
        _id
      }
      protected
      name
      _id
    }
    _id
    frame {
      inputs {
        ...input
      }
    }
  }
`;

export const ACCOUNT_TEMPLATE_TO_TREE = gql`
  fragment accountTemplateToTree on AccountTemplate {
    __typename
    title
    folder {
      _id
      name
      parentsTree {
        _id
        name
      }
    }
    _id
  }
`;

export const TAB = gql`
  fragment tab on Tab {
    _id
    context
    name
    columns {
      name
      size
      order
    }
  }
`;

export const ACCOUNT = gql`
  fragment account on Account {
    __typename
    _id
    firstLogin
    username
    name
    photo
    email
    deleted
    deletedAt
    deletedBy {
      name
    }
    protected
    tenantId
    folder {
      _id
      name
      parentsTree {
        _id
        name
      }
    }
    labelValues {
      ...labelValue
    }
    preferences {
      defaultSite {
        __typename
        name
        coordinates
        _id
      }
      locale
      theme
      timeZone
    }
    template {
      ...accountTemplate
    }
    authorizedSites {
      __typename
      name
      _id
      coordinates
    }
    inputs {
      ...input
    }
    roles {
      __typename
      name
      _id
    }
    photo
    isActive
    lastLogin
  }
  ${LABEL_VALUE}
`;

export const ACCOUNT_TO_TREE = gql`
  fragment accountToTree on Account {
    __typename
    _id
    name
    protected
    template {
      _id
    }
    folder {
      _id
      name
      parentsTree {
        _id
        name
      }
    }
  }
`;

export const ACCOUNT_TEMPLATE_FOLDER = gql`
  fragment accountTemplateFolder on AccountTemplateFolder {
    __typename
    _id
    name
    protected
    accountTemplatesCount(where: { archived_ne: true })
    accountTemplateFoldersCount
    totalItems: accountTemplatesCount
    totalFolders: accountTemplateFoldersCount
    parentsTree {
      _id
      name
    }
    parent {
      _id
      name
    }
  }
`;

export const ACCOUNT_FOLDER = gql`
  fragment accountFolder on AccountFolder {
    __typename
    _id
    name
    protected
    accountsCount
    accountFoldersCount
    parentsTree {
      _id
      name
    }
    parent {
      _id
      name
    }
    totalItems: accountsCount
    totalFolders: accountFoldersCount
  }
`;

export const ACCOUNT_FILTERS = gql`
  fragment accountFilterFragment on AccountFilter {
    name
    columns
    filters {
      name
      state
      operator
      catalogs {
        __typename
        _id
        name
      }
      assignedAccounts {
        __typename
        _id
        name
      }
      executedBy {
        __typename
        _id
        name
        photo
      }
      sites {
        _id
        name
        __typename
        parentsTreeIds
      }
      elements {
        _id
        name
        parentsTreeIds
        site {
          _id
          parentsTreeIds
        }
        __typename
      }
      rootSites {
        _id
        name
        parentsTreeIds
        __typename
      }
      rootElements {
        _id
        name
        __typename
        parentsTreeIds
        site {
          _id
          parentsTreeIds
        }
      }
      parentSites {
        _id
        name
        parentsTreeIds
        __typename
      }
      parentElements {
        _id
        name
        __typename
        parentsTreeIds
        site {
          _id
          parentsTreeIds
        }
      }
      assignedLabelValues {
        ...labelValue
      }
      labelValues {
        ...labelValue
      }
      frequency
      expectedTime
      issues {
        _id
        name
      }
      status
      created_at {
        type
        current
        last
        from
        to
      }
      finishDate {
        type
        current
        last
        from
        to
      }
      dueDate {
        type
        current
        last
        from
        to
      }
      updated_at {
        type
        current
        last
        from
        to
      }
      lastUpdater {
        name
        _id
      }
      search
      approvedAccounts {
        __typename
        _id
        name
        photo
      }
    }
    order
    context
    _id
  }
  ${LABEL_VALUE}
`;

export const ACCOUNT_LOGIN = gql`
  fragment accountLogin on Account {
    __typename
    _id
    firstLogin
    ...onBoarding
    username
    name
    email
    isPlatformAdmin
    issues
    actions
    feed
    chat
    gps
    tenantId
    clientMixedRealityEnabled
    folder {
      _id
      name
      parentsTree {
        _id
        name
      }
    }
    labelValuesIds
    labelValues {
      ...labelValue
    }
    defaultTimeZone
    preferences {
      defaultSite {
        __typename
        parentsTreeIds
        accessToSites {
          __typename
          name
          _id
        }
        name
        _id
      }
      timeZone
      locale
      theme
    }
    template {
      ...accountTemplate
    }
    photo
    authorizedSites {
      __typename
      name
      _id
      coordinates
      parentsTreeIds
    }
    inputs {
      ...input
    }
    filters {
      ...accountFilterFragment
    }
    roles {
      name
      _id
      protected
      permissions {
        parentType
        fieldName
        roleType
        access
      }
    }
    myClearances {
      level
      clearance
      bundle {
        _id
        name
      }
    }
    lastLogin
    protected
    tutorialCompleted
    tabs {
      ...tab
    }
  }

  ${ACCOUNT_FILTERS}
  ${LABEL_VALUE}
  ${TAB}
`;

export const INVITE = gql`
  fragment invite on Invite {
    _id
    extra {
      email
      roles {
        _id
      }
      authorizedSites {
        _id
      }
    }
    type
    validUntil
    createdAt
    updatedAt
  }
`;
