import { Marker, Popup } from 'react-leaflet';
import * as Leaflet from 'leaflet';
import { setCoordinates, setMarkers, setSelectedNode, setSelectedToShow } from 'src/utils/funcs/store/actions';
import { MarkerData, MarkerProps, Reducer } from 'src/utils/funcs/marker/marker.types';
import MarkerBlue from '@assets/images/markerBlue.svg';
import MarkerGreen from '@assets/images/markerGreen.svg';

const lIconBlue = new Leaflet.Icon({
  iconUrl: MarkerBlue,
  iconSize: [80, 80],
  popupAnchor: [0, -30],
});

const lIconGreen = new Leaflet.Icon({
  iconUrl: MarkerGreen,
  iconSize: [80, 80],
  popupAnchor: [0, -30],
});

export const showSiteMarker = (marker: MarkerData) => {
  return (
    <Marker
      //@ts-ignore
      icon={marker.icon}
      enzyme-attr={`marker-${marker.key}`}
      key={marker.key}
      id={marker.key}
      position={[marker.coords.lat, marker.coords.lng]}
    >
      <Popup>
        <b>{marker.name}</b>
      </Popup>
      )
    </Marker>
  );
};

export const addMarker = (lat: number, lng: number, reducer: Reducer) => {
  const { dispatch, state } = reducer;
  const { createMode, updateMode, selectedNode } = state;
  const marker: MarkerProps = {
    _id: 'new',
    //@ts-ignore
    data: showSiteMarker({
      key: 'new',
      name: createMode ? 'New' : selectedNode.name,
      coords: { lat, lng },
      //@ts-ignore
      icon: lIconGreen,
    }),
  };

  setMarkers(dispatch, state.markers.filter((m: { _id: string }) => m._id !== 'new').concat(marker));
  setCoordinates(dispatch, {
    lat,
    lng,
    toggle: true,
  });

  if (!updateMode) {
    setSelectedToShow(dispatch, { ...selectedNode, coordinates: [lat, lng] });
    return;
  }
  setSelectedNode(dispatch, { ...selectedNode, coordinates: [lat, lng] });
};

export const showAllSiteMarkers = (allSites: any[], reducer: Reducer) => {
  const { dispatch, state } = reducer;
  const { createMode, updateMode, coordinates, showAll } = state;
  const selectedNode = createMode
    ? {
        _id: 'new',
        name: 'New',
        coordinates: [coordinates.lat, coordinates.lng],
      }
    : state.selectedNode;

  if (!selectedNode.coordinates) {
    return;
  }

  const sites = showAll ? allSites.filter((s) => s._id != selectedNode._id).concat(selectedNode) : [selectedNode];
  const markers: any[] = [];
  sites.forEach((s) => {
    const markerData: MarkerData = {
      key: s._id,
      name: s.name,
      coords: {
        lat: updateMode && s._i === selectedNode._id ? coordinates.lat : s.coordinates[0],
        lng: updateMode && s._i === selectedNode._id ? coordinates.lng : s.coordinates[1],
      },
      //@ts-ignore
      icon: selectedNode?._id === s._id ? lIconGreen : lIconBlue,
    };
    markers.push({
      _id: markerData.key,
      data: showSiteMarker(markerData),
    });
  });

  setMarkers(dispatch, markers);
  setCoordinates(dispatch, {
    lat: selectedNode.coordinates[0],
    lng: selectedNode.coordinates[1],
    toggle: true,
  });
};
