import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { styles } from 'src/base/on-boarding/components/styles';
import { useMainContainerContext } from 'src/base/main-container/context/main.container.context';
import { startTutorial } from '../utils/onBoarding.utils.startTutorial';
import { ConnectedProps } from 'react-redux';
import { updateLoggedUser } from 'src/base/login/login.redux';
import DefaultButton from 'src/utils/components/default-button/default-button';
import { Permission } from 'src/gql/graphql';
import { createIssueTemplate, getIssueTemplateFolders } from 'src/modules/issue-templates/issue.templates.redux';
import makeStyles from '@mui/styles/makeStyles';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { typedConnect } from 'src/utils/funcs';

type IntroductionModalProps = ConnectedProps<typeof connector>;

const useStyles = makeStyles(styles);
const IntroductionModal: FC<IntroductionModalProps> = (props): JSX.Element => {
  const { loggedUser } = props;
  const classes = useStyles();
  const state = useMainContainerContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { updateMainContainerState } = state;

  const canDoOnBoarding = useMemo(() => {
    if (!loggedUser?.roles?.length || loggedUser.isPlatformAdmin || props.isBeingImpersonated) {
      return false;
    }
    const permissions = loggedUser?.roles[0]?.permissions ?? [];
    if (!permissions.length) {
      return false;
    }
    return permissions.some(
      (permission: Permission) =>
        permission.fieldName === 'createIssueTemplate' ||
        (permission.parentType === 'Mutation' && permission.fieldName === '*'),
    );
  }, [JSON.stringify(loggedUser?.roles[0])]);

  return (
    <Dialog
      open={!!state.openModalIntroduction}
      classes={{
        root: classes.onboardingModal,
        paperWidthSm: classes.modalWidth,
      }}
      onClose={() => updateMainContainerState({ openModalIntroduction: false })}
    >
      <DialogTitle>
        <Typography className={classes.welcomeTitle}>{t('welcomeVision')}</Typography>
        <Typography className={classes.welcomeSubTitle}>{t('gladHaveOnboard')}</Typography>
      </DialogTitle>
      <DialogContent className={classes.videoContainer}>
        <video controls style={{ width: '100%' }}>
          <source
            src='https://s3.eu-west-1.amazonaws.com/cdn.glartek.com/production/onboarding_1.mp4'
            type='video/mp4'
          />
          Your browser does not support the video tag.
        </video>
      </DialogContent>
      <DialogActions classes={{ root: classes.skipStartContainer }}>
        <DefaultButton
          data-testid={'skip-tutorial-button'}
          variant={canDoOnBoarding ? 'outlined' : 'default'}
          className={classes.defaultBtnPadding}
          onClick={() => {
            updateMainContainerState({ openModalIntroduction: false });
            props.updateLoggedUser(
              {
                id: props.loggedUser._id,
                firstLogin: false,
              },
              false,
            );
          }}
          label={canDoOnBoarding ? t('skipTutorial') : t('getStarted')}
        />
        {canDoOnBoarding && (
          <DefaultButton
            data-testid={'start-tutorial-button'}
            className={classes.defaultBtnPadding}
            onClick={() => {
              startTutorial(props, updateMainContainerState, navigate);
              updateMainContainerState({ doingOnBoarding: true, openModalIntroduction: false });
            }}
            label={t('startTutorial')}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

const connector = typedConnect(
  (state) => ({
    loggedUser: state.loginReducer.loggedUser,
    isBeingImpersonated: !!state.loginReducer.impersonatedBy,
  }),
  {
    updateLoggedUser,
    getIssueTemplateFolders,
    createIssueTemplate,
  },
);

export default connector(IntroductionModal);
