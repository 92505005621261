import GlarFilterPopover, { GenericFilterPopoverProps } from '..';
import { LabelValueFilterItem } from '../preset-filter-items';
import { LabelValue } from 'src/gql/graphql';

export type HeatmapFilters = Partial<{
  labels: LabelValue[];
}>;

export default function HeatmapFilterPopover(props: GenericFilterPopoverProps<HeatmapFilters>) {
  return (
    <GlarFilterPopover {...props}>
      <LabelValueFilterItem accessor={'labels'} context={'labelTask'} />
    </GlarFilterPopover>
  );
}
