import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    body: {
      position: 'relative',
      textAlign: 'center',
      flex: 1,
      width: '100%',
      minHeight: '100%',
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      padding: '2% 0px',
      backgroundImage: 'url("images/error_background.svg")',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    logo: {
      width: '100%',
    },
    divBtn: {
      padding: '10px',
    },
    divTitle: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    divInfo: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      color: theme.palette.text.primary,
      textAlign: 'center',
      fontFamily: 'Poppins',
      fontSize: '40px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '41px',
    },
    content: {
      color: theme.palette.text.primary,
      textAlign: 'center',
      fontFamily: 'Poppins',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '28px',
    },
    clickableContent: {
      color: theme.palette.primary.main,
      fontFamily: 'Poppins',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '28px',
      textDecorationLine: 'underline',
      cursor: 'pointer',
    },
    contactUs: {
      color: theme.palette.text.primary,
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '22px' /* 137.5% */,
    },
    clickableContactUs: {
      color: theme.palette.primary.main,
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '22px',
      textDecorationLine: 'underline',
    },
  });
