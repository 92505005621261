import i18n from 'src/utils/translations/i18n';
import { DateRange } from '../../custom-date-range-picker';

export type DateRangePresetType = 'current' | 'last' | 'custom';

export type DateRangePresetValue = 'year' | 'month' | 'week' | 'day';

export const dateRangePresets = [
  {
    name: 'today',
    type: 'current',
    current: 'day',
  },
  {
    name: 'lastDay',
    type: 'last',
    last: 'day',
  },
  {
    name: 'lastSevenDays',
    type: 'last',
    last: 'week',
  },
  {
    name: 'thisMonth',
    type: 'current',
    current: 'month',
  },
  {
    name: 'lastMonth',
    type: 'last',
    last: 'month',
  },
  {
    name: 'thisYear',
    type: 'current',
    current: 'year',
  },
];

export const getNameOfDateRangePreset = (date: DateRange) => {
  if (!date?.type) return '';
  return i18n.t(`${dateRangePresets.find((d) => d.type === date.type && d[d.type] === date[date.type])?.name}`);
};
