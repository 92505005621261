import {
  useStaticWidgetLastTenantsCreatedQuery,
  useStaticWidgetLastUsersCreatedQuery,
  useStaticWidgetLastUsersLoggedQuery,
  useStaticWidgetTotalActionsQuery,
  useStaticWidgetTotalAssetsQuery,
  useStaticWidgetTotalProceduresQuery,
  useStaticWidgetTotalTenantsQuery,
  useStaticWidgetTotalUsersQuery,
  WidgetDatasetChart,
} from 'src/gql/graphql';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { shuffle } from 'lodash';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import { FullscreenExit, Fullscreen as Full, Refresh } from '@mui/icons-material';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useKey } from 'src/utils/hooks/useKey';
import { css } from '@emotion/css';
import WidgetWrapper from './widgets/widget-wrapper';

export const widgetPalette = ['#54C1FB', '#EF476F', '#FFD279', '#00C48C', '#2470B3', '#F44336', '#F4A261', '#2A9D8F'];

type StaticWidgetProps = {
  data: any[];
  chart: WidgetDatasetChart;
  name: string;
  loading?: boolean;
  showAsPercentage?: boolean;
  width?: number;
  height?: number;
};

type StaticWidgetPropsNoData = Omit<StaticWidgetProps, 'data' | 'loading' | 'chart'>;

function StaticWidget({
  data,
  chart,
  name,
  loading = false,
  showAsPercentage = false,
  width = 1,
  height = 1,
}: StaticWidgetProps) {
  const palette = useMemo(() => shuffle(widgetPalette), []);

  return (
    <div
      css={{
        width: 300 * width,
        height: 200 * height,
        position: 'relative',
      }}
    >
      <WidgetWrapper
        widget={{
          name,
          chart,
          data,
          palette,
        }}
        loading={loading}
        showAsPercentage={showAsPercentage}
        data-testid={`${name}-widget`}
      />
    </div>
  );
}

const TotalTenantsWidget: React.FC<StaticWidgetPropsNoData> = (props) => {
  const { data, loading } = useStaticWidgetTotalTenantsQuery();

  return (
    <StaticWidget
      chart={WidgetDatasetChart.value}
      data={[{ count: data?.staticWidgets.totalTenants }]}
      loading={loading}
      {...props}
    />
  );
};

const TotalUsersWidget: React.FC<StaticWidgetPropsNoData> = (props) => {
  const { data, loading } = useStaticWidgetTotalUsersQuery();

  return (
    <StaticWidget
      chart={WidgetDatasetChart.value}
      data={[{ count: data?.staticWidgets.totalUsers }]}
      loading={loading}
      {...props}
    />
  );
};

const TotalAssetsWidget: React.FC<StaticWidgetPropsNoData> = (props) => {
  const { data, loading } = useStaticWidgetTotalAssetsQuery();

  return (
    <StaticWidget
      chart={WidgetDatasetChart.value}
      data={[{ count: data?.staticWidgets.totalAssets }]}
      loading={loading}
      {...props}
    />
  );
};

const TotalActionsWidget: React.FC<StaticWidgetPropsNoData> = (props) => {
  const { data, loading } = useStaticWidgetTotalActionsQuery();

  return (
    <StaticWidget
      chart={WidgetDatasetChart.value}
      data={[{ count: data?.staticWidgets.totalActions }]}
      loading={loading}
      {...props}
    />
  );
};

const TotalConfiguredProceduresWidget: React.FC<StaticWidgetPropsNoData> = (props) => {
  const { data, loading } = useStaticWidgetTotalProceduresQuery({
    variables: {
      isClosed: false,
    },
  });

  const noResults = !data?.staticWidgets.totalOpenProcedures && !data?.staticWidgets.totalScheduledProcedures;

  return (
    <StaticWidget
      chart={WidgetDatasetChart.pie}
      showAsPercentage
      data={
        noResults
          ? []
          : [
              { count: data?.staticWidgets.totalOpenProcedures, name: 'onDemand' },
              { count: data?.staticWidgets.totalScheduledProcedures, name: 'scheduled' },
            ]
      }
      loading={loading}
      {...props}
    />
  );
};

const LastUsersLoggedWidget: React.FC<StaticWidgetPropsNoData> = (props) => {
  const { data, loading } = useStaticWidgetLastUsersLoggedQuery();

  return (
    <StaticWidget
      chart={WidgetDatasetChart.table}
      data={data?.staticWidgets.lastUsersLogged.map((a) => ({
        metadata: { users: a },
        value: a.lastLogin,
        name: 'users',
      }))}
      loading={loading}
      {...props}
    />
  );
};

const LastUsersCreatedWidget: React.FC<StaticWidgetPropsNoData> = (props) => {
  const { data, loading } = useStaticWidgetLastUsersCreatedQuery();

  return (
    <StaticWidget
      chart={WidgetDatasetChart.table}
      data={data?.staticWidgets.lastUsersCreated.map((u) => ({
        metadata: { users: u },
        value: u.createdAt,
        name: 'users',
      }))}
      loading={loading}
      {...props}
    />
  );
};

const ExecutedProceduresWidget: React.FC<StaticWidgetPropsNoData> = (props) => {
  const { data, loading } = useStaticWidgetTotalProceduresQuery({
    variables: { isClosed: true },
  });

  const noResults = !data?.staticWidgets.totalOpenProcedures && !data?.staticWidgets.totalScheduledProcedures;

  return (
    <StaticWidget
      chart={WidgetDatasetChart.pie}
      showAsPercentage
      data={
        noResults
          ? []
          : [
              { count: data?.staticWidgets.totalOpenProcedures, name: 'onDemand' },
              { count: data?.staticWidgets.totalScheduledProcedures, name: 'scheduled' },
            ]
      }
      loading={loading}
      {...props}
    />
  );
};

const LastTenantsCreatedWidget: React.FC<StaticWidgetPropsNoData> = (props) => {
  const { data, loading } = useStaticWidgetLastTenantsCreatedQuery();

  return (
    <StaticWidget
      chart={WidgetDatasetChart.table}
      data={data?.staticWidgets.lastTenantsCreated.map((t) => ({
        metadata: { tenants: t },
        value: t.createdAt,
        name: 'tenants',
      }))}
      loading={loading}
      {...props}
    />
  );
};

export default function StaticDashboard() {
  const { t } = useTranslation();
  const theme = useTheme();

  const handle = useFullScreenHandle();

  const [key, remount] = useKey();

  return (
    <>
      <FullScreen handle={handle} css={{ background: theme.palette.background.default }}>
        <div css={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
          {!handle.active && (
            <Tooltip arrow title={t('refresh')} placement={'top'}>
              <IconButton
                className={css({
                  '&:active:hover': {
                    '& svg': {
                      color: theme.palette.primary.main,
                    },
                  },
                })}
                onClick={remount}
              >
                <Refresh />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip arrow title={t('refresh')} placement={'top'}>
            <IconButton data-testid={'fullscreen-dashboard-btn'} onClick={handle.active ? handle.exit : handle.enter}>
              {handle.active ? <FullscreenExit /> : <Full />}
            </IconButton>
          </Tooltip>
        </div>

        <div
          key={key}
          css={{
            display: 'flex',
            flexFlow: 'row wrap',
            gap: 8,
            width: '100%',
            height: '100%',
            padding: 12,
            justifyContent: 'flex-start',
            alignContent: 'flex-start',
            alignItems: 'flex-start',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          <TotalTenantsWidget name={t('totalNumberTenants')} />
          <TotalUsersWidget name={t('totalNumberUsers')} />
          <TotalAssetsWidget name={t('totalNumberAssets')} />
          <TotalConfiguredProceduresWidget name={t('configuredProcedures')} />
          <TotalActionsWidget name={t('totalNumberActions')} />
          <LastUsersLoggedWidget name={t('last10UsersLoggedIn')} />
          <ExecutedProceduresWidget name={t('executedProcedures')} />
          <LastTenantsCreatedWidget name={t('last10TenantsCreated')} />
          <LastUsersCreatedWidget name={t('last10UsersCreated')} />
        </div>
      </FullScreen>
    </>
  );
}
