import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    header: {
      width: '100%',
      backgroundColor: theme.palette.background.background1,
      padding: '6px 16px',
      display: 'flex',
    },
    title: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '36px',
    },
    expandIcon: {
      display: 'flex',
      marginLeft: 'auto',
      alignItems: 'center',
    },
    content: {
      marginTop: '24px',
      marginBottom: '24px',
    },
    displayNone: {
      display: 'none',
    },
    section: {
      marginBottom: '16px',
    },
  });
