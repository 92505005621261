import { split } from 'lodash';
import { Errors, LineColumnErrors } from 'src/interfaces/import';
import * as Sentry from '@sentry/browser';

interface Error {
  code?: string;
  type?: string;
  operationName?: string;
  operation?: any;
  message: string;
  stack?: string;
}

export const errorMessagesToIgnore = [
  'NotUniqueError',
  'NotFoundError',
  'ValidationError',
  'NotAllowedError',
  'AuthenticationError',
];

export const processErrors = (handler: any): Error[] => {
  const { networkError, graphQLErrors } = handler;
  const errors: Error[] = [];
  if (networkError) {
    Sentry.captureException(networkError);
    const errorMessage = split(networkError.stack, ':');
    errors.push({
      code: 'NETWORK_ERROR',
      type: errorMessage?.[0].trim(),
      operationName: handler.operation.operationName,
      message: errorMessage?.[1].trim(),
      stack: networkError.stack,
    });
  }
  if (graphQLErrors) {
    //GraphQL Console errors disabled here, uncomment this to show all graphql errors.
    //console.error(graphQLErrors[0].message, { code: graphQLErrors[0]?.extensions?.code });
    if (
      graphQLErrors.length > 0 &&
      (graphQLErrors[0].message ===
        "Context creation failed: Authentication failed: 'Authorization Token Not Valid!'" ||
        graphQLErrors[0].message === 'Authorization Token Not Valid!')
    ) {
      window.localStorage.clear();
      window.location.reload();
    } else {
      graphQLErrors.forEach((e: { message: string }) =>
        errors.push({
          code: undefined,
          type: undefined,
          operationName: undefined,
          operation: undefined,
          message: e.message,
          stack: undefined,
        }),
      );
    }
  }
  return errors;
};

const setMissingErrorMessages = (data, errorsLinesIndexes, requiredFields, t) => {
  const errorMessages = [];
  for (let lineIndex = 0; lineIndex < errorsLinesIndexes.length; lineIndex++) {
    const line = data[errorsLinesIndexes[lineIndex]];

    let columnIndex = 0;

    for (const propertyName in line) {
      columnIndex++; // Increment the column number for each property

      for (const reqField of requiredFields) {
        if (propertyName === reqField) {
          if (!line[propertyName] || line[propertyName] === '-') {
            // Create the message in the desired format
            const message = `${t('line')} ${errorsLinesIndexes[lineIndex] + 1}, ${t('column')} ${columnIndex}`;

            // Output or use the message as needed
            errorMessages.push(message);
          }
        }
      }
    }
  }

  return errorMessages;
};

const setDuplicatedErrorMessages = (data, errorsLinesIndexes, duplicateFieldsToCheck, t) => {
  const errorMessages = [];
  const seenValues = new Set();
  for (let lineIndex = 0; lineIndex < errorsLinesIndexes.length; lineIndex++) {
    const line = data[errorsLinesIndexes[lineIndex]];

    let columnIndex = 0; // Initialize the column number to 0

    for (const propertyName in line) {
      columnIndex++; // Increment the column number for each property

      for (const fieldToCheck of duplicateFieldsToCheck) {
        const propertyValue = line[fieldToCheck];
        // Check if the property value is already in the set (i.e., it's a duplicate)
        if (seenValues.has(propertyValue) && propertyName === fieldToCheck) {
          const message = `${t('line')} ${errorsLinesIndexes[lineIndex] + 1}, ${t('column')} ${columnIndex}`;
          errorMessages.push(message);
        } else {
          // If it's not a duplicate, add it to the set
          seenValues.add(propertyValue);
        }
      }
    }
  }

  return errorMessages;
};

const setNegativeErrorMessages = (data, errorsLinesIndexes, t) => {
  const errorMessages = [];
  for (let lineIndex = 0; lineIndex < errorsLinesIndexes.length; lineIndex++) {
    const line = data[errorsLinesIndexes[lineIndex]];

    let columnIndex = 0; // Initialize the column number to 0

    for (const propertyName in line) {
      columnIndex++; // Increment the column number for each property
      if (!isNaN(line[propertyName]) && line[propertyName] < 0) {
        const message = `${t('line')} ${errorsLinesIndexes[lineIndex] + 1}, ${t('column')} ${columnIndex}`;
        errorMessages.push(message);
      }
    }
  }

  return errorMessages;
};

const setInvalidErrorMessages = (data, errorsLinesIndexes, validData, t) => {
  const errorMessages = [];
  for (let lineIndex = 0; lineIndex < errorsLinesIndexes.length; lineIndex++) {
    const line = data[errorsLinesIndexes[lineIndex]];

    let columnIndex = 0; // Initialize the column number to 0

    for (const propertyName in line) {
      columnIndex++; // Increment the column number for each property
      if (
        (propertyName === 'unit' || propertyName === 'location' || propertyName === 'storage') &&
        !validData.includes(line[propertyName])
      ) {
        const message = `${t('line')} ${errorsLinesIndexes[lineIndex] + 1}, ${t('column')} ${columnIndex}`;
        errorMessages.push(message);
      }
    }
  }

  return errorMessages;
};

export const lineCollumn = (
  objectArray: any[],
  t: (key: string) => string,
  missing = false,
  duplicate = false,
  negativeNumbers = false,
  invalidUnit = false,
  errors: Errors,
  duplicateFieldsToCheck?: string[],
  requiredFields?: string[],
  validUnits?: string[],
  validLocationData?: any,
  invalidLocation = false,
  invalidStorage = false,
  fileTooLarge = false,
): LineColumnErrors => {
  let errorMessages = {
    missingFields: {
      hint: t('requiredFieldsError'),
      linesColumns: [],
    },
    duplicateIds: {
      hint: t('duplicateIdError'),
      linesColumns: [],
    },
    negativeNumbers: {
      hint: t('negativeInputError'),
      linesColumns: [],
    },
    invalidUnits: {
      hint: t('invalidUnitError'),
      linesColumns: [],
    },
    invalidLocations: {
      hint: t('invalidLocationError'),
      linesColumns: [],
    },
    invalidStorages: {
      hint: t('invalidStorageError'),
      linesColumns: [],
    },
    fileTooLarge: {
      hint: t('fileTooLarge'),
      linesColumns: [],
    },
  };

  if (fileTooLarge) {
    errorMessages.fileTooLarge.linesColumns = [t('fileTooLargeLine')];
  }

  if (missing)
    errorMessages.missingFields.linesColumns = setMissingErrorMessages(
      objectArray,
      errors.missingFieldsIndexes,
      requiredFields,
      t,
    );

  if (duplicate)
    errorMessages.duplicateIds.linesColumns = setDuplicatedErrorMessages(
      objectArray,
      errors.duplicateIDsIndexes,
      duplicateFieldsToCheck,
      t,
    );

  if (negativeNumbers)
    errorMessages.negativeNumbers.linesColumns = setNegativeErrorMessages(objectArray, errors.negativeInputsIndexes, t);

  if (invalidUnit)
    errorMessages.invalidUnits.linesColumns = setInvalidErrorMessages(
      objectArray,
      errors.invalidUnitsIndexes,
      validUnits,
      t,
    );

  if (invalidLocation)
    errorMessages.invalidLocations.linesColumns = setInvalidErrorMessages(
      objectArray,
      errors.invalidLocationsIndexes,
      validLocationData.locations,
      t,
    );

  if (invalidStorage)
    errorMessages.invalidStorages.linesColumns = setInvalidErrorMessages(
      objectArray,
      errors.invalidStoragesIndexes,
      validLocationData.storages,
      t,
    );

  return errorMessages;
};
