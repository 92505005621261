import { Checkbox, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import { styles } from './styles';
import { ISelectListItem } from '../select-list-item';

type SelectResponseListItemProps = {
  item: ISelectListItem;
  onClick?: (item: ISelectListItem) => void;
} & WithStyles<typeof styles>;

const SelectResponseListItem = ({ classes, item, onClick }: SelectResponseListItemProps) => {
  const handleClick = (item: ISelectListItem) => {
    onClick?.(item);
  };

  return (
    <ListItem key={item?._id} classes={{ root: classes.listItem }}>
      <ListItemIcon>
        {
          <Checkbox
            data-testid={'better-dropdown-response' + item?._id}
            size={'small'}
            className={classes.checkboxIcon}
            checked={item?.isSelected}
            onClick={() => handleClick(item)}
          />
        }
      </ListItemIcon>
      <ListItemText>
        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          <Typography variant='body2'>
            <span>{item?.name}</span>
          </Typography>
        </div>
      </ListItemText>
    </ListItem>
  );
};

export default withStyles(styles)(SelectResponseListItem);
