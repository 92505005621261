import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const typography = (theme: Theme) => {
  return {
    default: {
      fontFamily: '"Poppins", sans-serif',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '1.25px',
    },
    h5: {
      ...theme.typography.h5,
    },
    h4: {
      ...theme.typography.h4,
    },
    h3: {
      ...theme.typography.h3,
    },
  };
};

export const styles = (theme: Theme) =>
  createStyles({
    common: {
      '-webkit-touch-callout': 'none',
      '-webkit-user-select': 'none',
      '-khtml-user-select': 'none',
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
      userSelect: 'none',
      position: 'relative',
    },
    button: {
      height: 'fit-content',
      fontFamily: '"Poppins", sans-serif',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '1.25px',
      borderRadius: '4px',
      border: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      minHeight: 30,
      '& > div': {
        height: '100%',
        width: '100%',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
      },
    },
    icon: {
      border: 'none !important',
      display: 'inline-block',
      cursor: 'pointer',
      '& > div': {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
      },
    },
    enabledHover: {
      transition: 'background 0.3s',
      hover: {
        background: theme.palette.common.black,
      },
      active: {
        background: theme.palette.common.black,
      },
    },
    enabled: {
      transition: 'background 0.3s',
      opacity: 1,
    },
    enabledCircleHover: {
      transition: 'background 0.3s',
      borderRadius: '4px',
      hover: {
        borderRadius: '4px',
        background: theme.palette.common.white,
      },
      active: {
        borderRadius: '4px',
        background: theme.palette.common.white,
      },
    },
    iconDiv: {
      display: 'flex',
      gap: '8px !important',
    },
    disabled: {
      cursor: 'not-allowed',
      background: theme.palette.text.disabled,
      color: theme.palette.text.secondary,
      border: `1px solid ${theme.palette.text.disabled}`,
    },
    success: {
      background: theme.palette.success.main,
      color: theme.palette.background.default,
      boxShadow:
        '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)',
      '&:hover': {
        background: `${theme.palette.success.dark} !important`,
        boxShadow:
          '0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)',
      },
    },
    pause: {
      background: theme.palette.other.peach,
      color: theme.palette.background.default,
      '&:hover': {
        background: `${theme.palette.other.peach} !important`,
      },
    },
    discard: {
      background: theme.palette.background.default,
      border: `1px solid ${theme.palette.primary.light}`,
      color: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.primary.light,
      },
    },
    transparent: {
      color: theme.palette.primary.main,
    },
    cancel: {
      background: theme.palette.grey.medium,
      color: theme.palette.background.default,
    },
    delete: {
      background: theme.palette.error.main,
      color: theme.palette.background.default,
      boxShadow:
        '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)',
      '&:hover': {
        background: theme.palette.error.dark,
        boxShadow:
          '0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)',
      },
    },
    'delete-alt': {
      border: `1px solid ${theme.palette.primary.light}`,
      color: theme.palette.error.main,
      '&:hover': {
        background: theme.palette.primary.light,
      },
    },
    'success-alt': {
      border: `1px solid ${theme.palette.primary.light}`,
      color: theme.palette.success.main,
      '&:hover': {
        background: theme.palette.primary.light,
      },
    },
    'load-more': {
      background: theme.palette.action.disabledBackground,
      color: theme.palette.text.primary,
    },
    noBorder: {
      border: 'none',
    },
    default: {
      background: theme.palette.primary.main,
      color: theme.palette.background.default,
      boxShadow:
        '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)',
      '&:hover': {
        color: theme.palette.background.default,
        background: `${theme.palette.primary.dark} !important`,
        boxShadow:
          '0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)',
      },
    },
    loadSpinnerContainer: { display: 'flex', width: '100%', height: '100%', position: 'absolute' },
    loadSpinner: {
      color: theme.palette.background.default,
    },
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    shadow: {
      boxShadow:
        '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)',
    },
    outlined: {
      background: theme.palette.background.default,
      border: `1px solid ${theme.palette.primary.light}`,
      color: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.primary.light,
      },
    },
    small: {
      padding: '4px 10px',
    },
    medium: {
      padding: '6px 16px',
    },
    large: {
      padding: '8px 22px',
    },
    paddingIconOnly: {
      borderRadius: '4px',
      padding: '4px 8px',
    },
  });
