import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import RTCPeer from 'src/utils/remote-assistance/RTCPeer';
import { changeMicrophoneDevice, changeSpeakerDevice } from 'src/utils/remote-assistance/agorartc/agoraState';

export interface CaptureMouse {
  capture?: boolean;
  events?: {
    click?: boolean;
    draw?: boolean;
  };
  rate?: number;
  srcEvent?: string;
}

interface RemotePeerDetails {
  width?: number;
  height?: number;
  arSupported?: boolean;
  platform?: string;
  currentView?: string;
}

export interface RtcStateProps {
  selectedCamera?: string;
  selectedMicrophone?: string;
  selectedSpeaker?: string;
  audioPaused?: boolean;
  videoPaused?: boolean;
  captureMouse?: CaptureMouse;
  dataChannelAction?: string;
  dataChannelData?: string;
  remotePeerDetails?: RemotePeerDetails;
  localVideo?: {
    width?: number;
    height?: number;
  };
  videoRatio?: number;
  rtcPeerConn?: RTCPeer;
}

const defaultState: RtcStateProps = {
  selectedCamera: '',
  selectedMicrophone: '',
  selectedSpeaker: '',
  audioPaused: false,
  videoPaused: false,
  captureMouse: {
    capture: false,
    events: {
      click: false,
      draw: false,
    },
    rate: 0,
    srcEvent: '',
  },
  dataChannelAction: '',
  dataChannelData: '',
  remotePeerDetails: {
    width: 0,
    height: 0,
    arSupported: false,
    platform: '',
    currentView: '',
  },
  localVideo: {
    width: 0,
    height: 0,
  },
  videoRatio: 1,
  rtcPeerConn: null,
};

const rtcSlice = createSlice({
  name: 'rtc',
  initialState: defaultState,
  reducers: {
    setSelectedCamera(state, action: PayloadAction<string>) {
      state.selectedCamera = action.payload;
    },
    setSelectedMicrophone(state, action: PayloadAction<string>) {
      changeMicrophoneDevice(action.payload);
      state.selectedMicrophone = action.payload;
    },
    setSelectedSpeaker(state, action: PayloadAction<string>) {
      state.selectedSpeaker = action.payload;
    },
    setAudioPaused(state, action: PayloadAction<boolean>) {
      state.audioPaused = action.payload;
    },
    setVideoPaused(state, action: PayloadAction<boolean>) {
      state.videoPaused = action.payload;
    },
    setCaptureMouse(state, action: PayloadAction<CaptureMouse>) {
      state.captureMouse = action.payload;
    },
    setDataChannel(state, action: PayloadAction<{ dataChannelAction: string; dataChannelData: string }>) {
      const { dataChannelAction, dataChannelData } = action.payload;
      if (state.rtcPeerConn) {
        state.rtcPeerConn.sendMessage(dataChannelAction, dataChannelData);
      }
      state.dataChannelAction = dataChannelAction;
      state.dataChannelData = dataChannelData;
    },
    setRemotePeerDetails(state, action: PayloadAction<RemotePeerDetails>) {
      state.remotePeerDetails = {
        ...state.remotePeerDetails,
        ...action.payload,
      };
    },
    setLocalVideo(state, action: PayloadAction<{ width: number; height: number }>) {
      state.localVideo = action.payload;
    },
    setVideoRatio(state, action: PayloadAction<number>) {
      state.videoRatio = action.payload;
    },
    setRTCPeerConn(state, action: PayloadAction<RTCPeer>) {
      state.rtcPeerConn = action.payload;
    },
  },
});

export const {
  setSelectedCamera,
  setSelectedMicrophone,
  setSelectedSpeaker,
  setAudioPaused,
  setVideoPaused,
  setCaptureMouse,
  setDataChannel,
  setRemotePeerDetails,
  setLocalVideo,
  setVideoRatio,
  setRTCPeerConn,
} = rtcSlice.actions;

export default rtcSlice.reducer;

export interface RtcDispatchProps {
  setSelectedCamera?: typeof setSelectedCamera;
  setSelectedMicrophone?: typeof setSelectedMicrophone;
  setSelectedSpeaker?: typeof setSelectedSpeaker;
  setAudioPaused?: typeof setAudioPaused;
  setVideoPaused?: typeof setVideoPaused;
  setCaptureMouse?: typeof setCaptureMouse;
  setDataChannel?: typeof setDataChannel;
  setRemotePeerDetails?: typeof setRemotePeerDetails;
  setLocalVideo?: typeof setLocalVideo;
  setVideoRatio?: typeof setVideoRatio;
  setRTCPeerConn?: typeof setRTCPeerConn;
}
