import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Webcam from 'react-webcam';
import { bindActionCreators, compose, Dispatch } from 'redux';
import DefaultButton from 'src/utils/components/default-button/default-button';
import { styles } from './style';
import { withStyles } from '@mui/styles';

interface CameraDialogProps {
  onClose: () => void;
  classes: Record<string, string>;
  addImage: (image: string) => Promise<void>;
}

const CameraDialog = (props: CameraDialogProps) => {
  const { classes } = props;

  const { t } = useTranslation();
  const webcamRef = useRef<Webcam>(null);

  const [hasCameraAccess, setHasCameraAccess] = useState(false);

  const checkCameraAccess = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      setHasCameraAccess(true);
    } catch (error) {
      setHasCameraAccess(false);
      console.error('Error accessing camera:', error);
    }
  };

  useEffect(() => {
    checkCameraAccess();
  }, []);

  const capture = useCallback(() => {
    if (!webcamRef.current) {
      return;
    }
    const imageSrc = webcamRef.current?.getScreenshot();
    props.addImage(imageSrc);
    props.onClose();
  }, [webcamRef]);

  const videoConstraints = {
    width: 650,
    height: 350,
    facingMode: 'user',
  };

  return (
    <Dialog open={true} onClose={props.onClose} maxWidth={false} classes={{ paper: classes.children }}>
      <DialogTitle style={{ display: 'flex', justifyContent: 'center' }}>
        <span className={classes.dialogTitleText}>{t('aimYourCamera')}</span>
      </DialogTitle>

      <div style={{ marginLeft: 25 }}>
        {hasCameraAccess ? (
          <Webcam
            height={350}
            width={650}
            screenshotFormat='image/png'
            audio={false}
            videoConstraints={videoConstraints}
            ref={webcamRef}
          />
        ) : (
          <div className={classes.grantCameraAccesContainer}>
            <span className={classes.grantCameraAccessText}>{t('grantCameraAccess')}</span>
          </div>
        )}
      </div>

      <DialogActions style={{ justifyContent: 'center' }}>
        <DefaultButton data-testid='buttonTakePicture' onClick={capture}>
          {t('takePicture')}
        </DefaultButton>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default compose<any>(
  withTranslation('translation'),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(CameraDialog);
