import { Theme } from '@mui/material/styles';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) => {
  return createStyles({
    tooltipContainer: {
      position: 'relative',
    },
    tooltipTextRight: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: 'fit-content',
      backgroundColor: theme.palette.text.secondary,
      color: theme.palette.common.white,
      textAlign: 'center',
      whiteSpace: 'nowrap',
      padding: '4px 5px',
      marginLeft: '8px',
      borderRadius: 6,
      position: 'absolute',
      zIndex: 1,
      top: '0%',
      bottom: '0%',
      left: '105%',
      '&:after': {
        content: '""',
        position: 'absolute',
        top: '50%' /* At the bottom of the tooltip */,
        right: '100%',
        marginTop: '-5px',
        borderWidth: '5px',
        borderStyle: 'solid',
        borderColor: `transparent ${theme.palette.text.secondary} transparent transparent`,
      },
    },
    tooltipTextLeft: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: 'fit-content',
      backgroundColor: theme.palette.text.secondary,
      color: theme.palette.common.white,
      textAlign: 'center',
      whiteSpace: 'nowrap',
      padding: '4px 5px',
      marginLeft: '8px',
      borderRadius: 6,
      position: 'absolute',
      zIndex: 1,
      top: '0%',
      bottom: '0%',
      right: '145%',
      '&:after': {
        content: '""',
        position: 'absolute',
        top: '50%' /* At the bottom of the tooltip */,
        left: '100%',
        marginTop: '-5px',
        borderWidth: '5px',
        borderStyle: 'solid',
        borderColor: `transparent transparent transparent ${theme.palette.text.secondary}`,
      },
    },
  });
};
