import { useEffect } from 'react';

export default function useResize<T extends HTMLElement, U extends HTMLElement>(
  ref: React.RefObject<T>,
  anchorRef: React.RefObject<U>,
  anchorPositions: {
    x: 'left' | 'right';
    y: 'top' | 'bottom';
  },
  skip: boolean,
): void {
  useEffect(() => {
    if (skip) {
      return () => null;
    }

    const observer = new ResizeObserver((entries) => {
      const { width: windowWidth, height: windowHeight } = entries[0].contentRect;

      const datePickerRect = ref.current.getBoundingClientRect();
      const anchorRect = anchorRef.current.getBoundingClientRect();

      const idealX = anchorRect[anchorPositions.x];
      const idealY = anchorRect[anchorPositions.y];

      //adjust Y
      const overlapHeight = idealY + datePickerRect.height - (windowHeight - 20);

      if (overlapHeight > 0) {
        ref.current.style.top = `${idealY - overlapHeight}px`;
      } else {
        ref.current.style.top = `${idealY}px`;
      }

      //adjust X
      const overlapWidth = idealX + datePickerRect.width - (windowWidth - 20);

      if (overlapWidth > 0) {
        ref.current.style.left = `${idealX - overlapWidth}px`;
      } else {
        ref.current.style.left = `${idealX}px`;
      }
    });

    observer.observe(document.getElementById('root'));

    return () => {
      observer.disconnect();
    };
  }, [skip]);
}
