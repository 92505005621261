import gql from 'graphql-tag';
import { MATERIAL, TOOL, STORAGE, LOCATION, MATERIAL_ITEM, TOOL_ITEM } from 'src/utils/fragments/inventory';

export const Q_GET_MATERIAL = gql`
  query GetMaterial($where: MaterialWhereUniqueInput!) {
    material(where: $where) {
      ...material
    }
  }
  ${MATERIAL}
`;

export const Q_GET_MATERIALS = gql`
  query GetMaterials($where: MaterialWhereInput, $limit: Int, $skip: Int, $orderBy: [MaterialOrderByInput]) {
    materials(where: $where, orderBy: $orderBy, limit: $limit, skip: $skip) {
      ...material
      totalStock
      stockPerStorage {
        stock
        name
      }
    }
  }
  ${MATERIAL}
`;

export const Q_GET_MATERIALS_SEARCH = gql`
  query GetMaterialsSearch($where: MaterialWhereInput, $limit: Int, $skip: Int, $orderBy: [MaterialOrderByInput]) {
    materialsSearch(where: $where, orderBy: $orderBy, limit: $limit, skip: $skip) {
      ...material
      totalStock
      stockPerStorage {
        stock
        name
      }
    }
  }
  ${MATERIAL}
`;

export const Q_GET_MATERIALS_COUNT = gql`
  query GetMaterialsCount($where: MaterialWhereInput) {
    materialsCount(where: $where)
  }
`;

export const M_CREATE_MATERIAL = gql`
  mutation CreateMaterial($data: MaterialCreateInput!) {
    createMaterial(data: $data) {
      ...material
    }
  }
  ${MATERIAL}
`;

export const M_DELETE_MATERIAL = gql`
  mutation DeleteMaterial($where: MaterialWhereUniqueInput!) {
    deleteOneMaterial(where: $where) {
      _id
    }
  }
`;

export const M_UPDATE_MATERIAL = gql`
  mutation UpdateMaterial($data: MaterialUpdateInput!, $where: MaterialWhereUniqueInput!) {
    updateMaterial(data: $data, where: $where) {
      _id
    }
  }
`;

export const Q_GET_TOOLS = gql`
  query GetTools($where: ToolWhereInput, $limit: Int, $skip: Int, $orderBy: [ToolOrderByInput]) {
    tools(where: $where, orderBy: $orderBy, limit: $limit, skip: $skip) {
      totalStock
      stockPerStorage {
        stock
        name
      }
      ...tool
    }
  }
  ${TOOL}
`;

export const Q_GET_TOOL_ITEMS = gql`
  query GetToolItems($where: ToolItemWhereInput, $limit: Int, $skip: Int, $orderBy: [ToolItemOrderByEnum]) {
    toolItems(where: $where, orderBy: $orderBy, limit: $limit, skip: $skip) {
      available
      ...toolItem
    }
    total: toolItemsCount(where: $where)
  }
  ${STORAGE}
  ${LOCATION}
  ${TOOL}
  ${TOOL_ITEM}
`;

export const Q_GET_TOOL_ITEM = gql`
  query GetToolItem($where: ToolItemWhereUniqueInput!) {
    toolItem(where: $where) {
      available
      usedBy {
        name
        email
        photo
        _id
      }
      usedIn {
        _id
        glarID
        name
      }
      ...toolItem
    }
  }
  ${STORAGE}
  ${LOCATION}
  ${TOOL}
  ${TOOL_ITEM}
`;

export const Q_GET_TOOL_ITEMS_LOCATIONS = gql`
  query GetToolItemsLocations($toolId: String!) {
    toolItemsLocations(toolId: $toolId) {
      location {
        ...location
      }
      toolItemCount
    }
  }
  ${LOCATION}
  ${STORAGE}
`;

export const Q_GET_TOOLS_SEARCH = gql`
  query GetToolsSearch($where: ToolWhereInput, $skip: Int, $limit: Int, $orderBy: [ToolOrderByInput]) {
    toolsSearch(where: $where, skip: $skip, limit: $limit, orderBy: $orderBy) {
      ...tool
      totalStock
      stockPerStorage {
        stock
        name
      }
    }
  }
  ${TOOL}
`;

export const Q_GET_TOOLS_COUNT = gql`
  query GetToolsCount($where: ToolWhereInput) {
    toolsCount(where: $where)
  }
`;

export const M_CREATE_TOOL = gql`
  mutation CreateTool($data: ToolCreateInput!) {
    createTool(data: $data) {
      ...tool
    }
  }
  ${TOOL}
`;

export const M_DELETE_TOOL = gql`
  mutation DeleteTool($where: ToolWhereUniqueInput!) {
    deleteOneTool(where: $where) {
      _id
    }
  }
`;

export const M_UPDATE_TOOL = gql`
  mutation UpdateTool($data: ToolUpdateInput!, $where: ToolWhereUniqueInput!) {
    updateTool(data: $data, where: $where) {
      ...tool
    }
  }
  ${TOOL}
`;

export const M_CREATE_STORAGE = gql`
  mutation CreateStorage($data: StorageCreateInput!) {
    createStorage(data: $data) {
      ...storage
    }
  }
  ${STORAGE}
`;

export const Q_GET_STORAGES = gql`
  query GetStorages($where: StorageWhereInput, $limit: Int, $skip: Int, $orderBy: [StorageOrderByInput]) {
    storages(where: $where, orderBy: $orderBy, limit: $limit, skip: $skip) {
      ...storage
    }
  }
  ${STORAGE}
`;

export const Q_GET_STORAGES_COUNT = gql`
  query GetStoragesCount($where: StorageWhereInput) {
    storagesCount(where: $where)
  }
`;

export const Q_GET_STORAGE = gql`
  query GetStorage($where: StorageWhereUniqueInput!) {
    storage(where: $where) {
      ...storage
    }
  }
  ${STORAGE}
`;

export const M_UPDATE_STORAGE = gql`
  mutation UpdateStorage($data: StorageUpdateInput!, $where: StorageWhereUniqueInput!) {
    updateStorage(data: $data, where: $where) {
      ...storage
    }
  }
  ${STORAGE}
`;

export const M_DELETE_STORAGE = gql`
  mutation DeleteStorage($where: StorageWhereUniqueInput!) {
    deleteOneStorage(where: $where) {
      _id
    }
  }
`;

export const M_CREATE_LOCATION = gql`
  mutation CreateLocation($data: LocationCreateInput!) {
    createLocation(data: $data) {
      ...location
    }
  }
  ${STORAGE}
  ${LOCATION}
`;

export const Q_GET_LOCATIONS = gql`
  query GetLocations($where: LocationWhereInput, $limit: Int, $skip: Int, $orderBy: [LocationOrderByInput]) {
    locations(where: $where, orderBy: $orderBy, limit: $limit, skip: $skip) {
      ...location
    }
  }
  ${STORAGE}
  ${LOCATION}
`;

export const Q_GET_LOCATIONS_COUNT = gql`
  query GetLocationsCount($where: LocationWhereInput) {
    locationsCount(where: $where)
  }
`;

export const M_DELETE_MANY_LOCATIONS = gql`
  mutation DeleteManyLocations($_ids: [ID]) {
    deleteManyLocations(_ids: $_ids) {
      ...location
    }
  }
  ${STORAGE}
  ${LOCATION}
`;

export const M_CREATE_MATERIAL_ITEM = gql`
  mutation CreateMaterialItem($data: MaterialItemCreateInput!) {
    createMaterialItem(data: $data) {
      ...materialItem
    }
  }
  ${STORAGE}
  ${LOCATION}
  ${MATERIAL}
  ${MATERIAL_ITEM}
`;

export const Q_GET_MATERIAL_ITEMS = gql`
  query GetMaterialItems(
    $where: MaterialItemWhereInput
    $limit: Int
    $skip: Int
    $orderBy: [MaterialItemOrderByInput]
  ) {
    materialItems(where: $where, orderBy: $orderBy, limit: $limit, skip: $skip) {
      ...materialItem
    }
  }
  ${STORAGE}
  ${LOCATION}
  ${MATERIAL}
  ${MATERIAL_ITEM}
`;

export const Q_GET_MATERIAL_ITEMS_COUNT = gql`
  query GetMaterialItemsCount($where: MaterialItemWhereInput) {
    materialItemsCount(where: $where)
  }
`;

export const M_CREATE_MATERIALS = gql`
  mutation CreateMaterials($data: String!) {
    createMaterials(data: $data) {
      total
      totalImported
    }
  }
`;

export const M_DELETE_MATERIAL_ITEM = gql`
  mutation DeleteMaterialItem($where: MaterialItemWhereUniqueInput!) {
    deleteOneMaterialItem(where: $where) {
      _id
    }
  }
`;

export const M_DELETE_MANY_MATERIAL_ITEMS = gql`
  mutation DeleteManyMaterialItems($_ids: [ID], $storage_id: ID, $material_ids: [ID]) {
    deleteManyMaterialItems(_ids: $_ids, storage_id: $storage_id, material_ids: $material_ids) {
      _id
    }
  }
`;

export const M_UPDATE_MATERIAL_ITEM = gql`
  mutation UpdateMaterialItem($data: MaterialItemUpdateInput!, $where: MaterialItemWhereUniqueInput!) {
    updateMaterialItem(data: $data, where: $where) {
      ...materialItem
    }
  }
  ${STORAGE}
  ${LOCATION}
  ${MATERIAL}
  ${MATERIAL_ITEM}
`;

export const Q_GET_MATERIALS_BY_STORAGE = gql`
  query GetMaterialsByStorage(
    $storage: ID
    $limit: Int
    $skip: Int
    $orderBy: [MaterialOrderByInput]
    $where: MaterialWhereInput
  ) {
    materialsByStorage(storage: $storage, limit: $limit, skip: $skip, orderBy: $orderBy, where: $where) {
      ...material
    }
    materialsByStorageCount(storage: $storage, where: $where)
  }
  ${MATERIAL}
`;

export const M_CREATE_TOOL_ITEM = gql`
  mutation CreateToolItem($data: ToolItemCreateInput!) {
    createToolItem(data: $data) {
      ...toolItem
    }
  }
  ${STORAGE}
  ${LOCATION}
  ${TOOL}
  ${TOOL_ITEM}
`;

gql`
  query GetToolItems($where: ToolItemWhereInput, $limit: Int, $skip: Int, $orderBy: [ToolItemOrderByEnum]) {
    toolItems(where: $where, orderBy: $orderBy, limit: $limit, skip: $skip) {
      available
      ...toolItem
    }
    total: toolItemsCount(where: $where)
  }

  query GetToolItem($where: ToolItemWhereUniqueInput!) {
    toolItem(where: $where) {
      available
      usedBy {
        name
        email
        photo
        _id
      }
      usedIn {
        _id
        glarID
        name
      }
      ...toolItem
    }
  }
`;

export const Q_GET_TOOL_ITEM_WITH_DELETED = gql`
  query GetToolItemWithDeleted($where: ToolItemWhereUniqueInput!) {
    toolItemWithDeleted(where: $where) {
      available
      usedBy {
        name
        email
        photo
        _id
      }
      usedIn {
        _id
        glarID
        name
      }
      ...toolItem
    }
  }
  ${STORAGE}
  ${LOCATION}
  ${TOOL}
  ${TOOL_ITEM}
`;

export const M_UPDATE_TOOL_ITEM = gql`
  mutation UpdateToolItem($data: ToolItemUpdateInput!, $where: ToolItemWhereUniqueInput!) {
    updateToolItem(data: $data, where: $where) {
      ...toolItem
    }
  }
  ${STORAGE}
  ${LOCATION}
  ${TOOL}
  ${TOOL_ITEM}
`;

export const M_DELETE_MANY_TOOL_ITEMS = gql`
  mutation DeleteManyToolItems($_ids: [ID], $storage_id: ID, $tool_ids: [ID]) {
    deleteManyToolItems(_ids: $_ids, storage_id: $storage_id, tool_ids: $tool_ids) {
      _id
    }
  }
`;

export const M_DELETE_TOOL_ITEM = gql`
  mutation DeleteToolItem($where: ToolItemWhereUniqueInput!) {
    deleteOneToolItem(where: $where) {
      _id
    }
  }
`;

export const Q_GET_TOOL = gql`
  query GetTool($where: ToolWhereUniqueInput!) {
    tool(where: $where) {
      ...tool
    }
  }
  ${TOOL}
`;

export const Q_GET_SUGGESTED_TOOL_ITEMS = gql`
  query GetSuggestedToolItems($tools: [ID!]!) {
    getSuggestedToolItems(tools: $tools) {
      ...toolItem
    }
  }
  ${STORAGE}
  ${LOCATION}
  ${TOOL}
  ${TOOL_ITEM}
`;

export const Q_GET_NFC_TAGS_LINKED_TO_TOOL_ITEM = gql`
  query GetNfcTagsLinkedToToolItem($where: NfcTagWhereInput!) {
    nfcTags(where: $where) {
      _id
      createdAt
      createdBy {
        _id
        name
        username
      }
      linkedTo {
        assetType
        assetId
      }
      uid
    }
  }
`;

export const M_UNLINK_NFC_TAG = gql`
  mutation UnlinkNFCTag($id: ID!, $data: NfcTagUpdateInput!) {
    updateNfcTag(where: { _id: $id }, data: $data) {
      _id
    }
  }
`;

export const M_CREATE_TOOL_ITEMS = gql`
  mutation CreateToolItems($data: String!, $toolId: ID!) {
    createToolItems(data: $data, toolId: $toolId) {
      total
      totalImported
    }
  }
`;

export const M_DELETE_TOOLITEMS_BATCH = gql`
  mutation deleteToolItemsBatch($storageId: ID!, $toolId: ID!) {
    deleteToolItemsBatch(storageId: $storageId, toolId: $toolId) {
      deletedCount
    }
  }
`;

export const S_SUBSCRIBE_XSLX_UPLOAD = gql`
  subscription SubscribeXLSXUpload {
    XLSXUploadProgress {
      progress
      status
    }
  }
`;
