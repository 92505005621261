import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    disabledWithBadge: {
      display: 'flex',
      gap: 4,
    },
    displayTable: {
      height: 'auto',
      wordWrap: 'break-word',
      whiteSpace: 'nowrap',
      borderCollapse: 'separate',
    },
    label: {
      color: theme.palette.primary.main,
    },
    labelBold: {
      color: theme.palette.primary.main,
      fontWeight: 'bolder',
    },
    labelInline: {
      color: theme.palette.primary.light,
      fontWeight: 'bolder',
      display: 'inline-block',
    },
    labelInlineNoBold: {
      color: theme.palette.primary.light,
      display: 'inline-block',
    },
    spanAccountName: {
      padding: '3px 5px 0 5px',
      borderRadius: '4px',
      maxWidth: '50%',
      display: 'inline-flex',
      verticalAlign: '-webkit-baseline-middle',
      margin: '-3px -1px 1px -1px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: '12px',
    },
    border: {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    spanLabel: {
      '& > *': {
        margin: theme.spacing(0.5),
      },
      fontSize: '9pt',
      borderRadius: '5px',
      maxWidth: 'inherit',
      whiteSpace: 'nowrap',
    },
    spanLabelRow: {
      fontSize: '9pt',
      height: 'fit-content',
      borderRadius: '5px',
      maxWidth: 'inherit',
      whiteSpace: 'nowrap',
      marginLeft: '2px',
      marginRight: '2px',
    },
    spanTable: {
      display: 'inherit',
      width: 'min-content',
      fontSize: '9pt',
      height: '18px',
      borderRadius: '5px',
      marginLeft: '5px',
      marginRight: '5px',
      marginTop: '7px',
      maxWidth: 'inherit',
      whiteSpace: 'nowrap',
      padding: '0 0 5px 0',
    },
    spanLabelDropdown: {
      fontSize: '9pt',
      padding: '2px 5px 1px 0',
      height: '18px',
      borderRadius: '5px',
      marginLeft: '10px',
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    spanLabelName: {
      padding: '1px 5px 3px 5px',
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
      maxWidth: '50%',
      display: 'inline-flex',
      verticalAlign: '-webkit-baseline-middle',
      margin: '-5px 0 0 -1px',
      color: theme.palette.common.white,
      fontSize: '12px',
    },
    spanLabelNameTable: {
      padding: '0 5px 0 5px',
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
      maxWidth: '150px',
      display: 'inline-flex',
      verticalAlign: '-webkit-baseline-middle',
      margin: '-8px -1px 3px -1px',
      color: theme.palette.common.white,
      fontSize: '12px',
    },
    spanAccountNameTooltip: {
      padding: '0 5px 1px 5px',
      borderRadius: '4px',
      maxWidth: '150px',
      display: 'inline-flex',
      verticalAlign: '-webkit-baseline-middle',
      margin: '-8px -1px 3px -1px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: '12px',
    },
    labelValue: {
      color: theme.palette.text.primary,
      padding: '0px 5px 6px 5px',
      maxWidth: '50%',
      display: 'inline-flex',
      verticalAlign: '-webkit-baseline-middle',
      fontSize: '12px',
    },
    labelValueTable: {
      color: theme.palette.common.white,
      padding: '5px',
      maxWidth: '50%',
      display: 'inline-flex',
      verticalAlign: '-webkit-baseline-middle',
      margin: '-10px 0 0 0',
      fontSize: '12px',
    },
    avatar: {
      width: 'unset !important',
      display: 'block !important',
      lineHeight: '18px !important',
      borderRadius: '17px',
      paddingLeft: '5px',
      paddingRight: '5px',
      maxWidth: '165px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: `${theme.palette.common.white} !important`,
    },
    container: {
      display: 'flex',
    },
    labelsContainer: {
      width: '100%',
    },
    editIcon: {
      fontSize: '20px',
      color: theme.palette.warning.main,
      marginTop: '5px',
    },
    modalBody: {
      height: 'calc(100% - 40px)',
    },
    modalFooter: {
      float: 'right',
    },
    accountNameWithHover: {
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
        textDecorationLine: 'underline',
      },
    },
    accountNameActive: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  });
