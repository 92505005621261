import gql from 'graphql-tag';
import { LABEL_VALUE } from './label';

export const SITE_TO_TREE = gql`
  fragment siteToTree on LeanSite {
    __typename
    _id
    name
    protected
    parentId
    parentsTreeIds
    coordinates
    hasChildren
    hasSites
    hasElements
    devicesCount
    totalItems: hasElements
    totalFolders: hasChildren
    templateIcon
  }
`;

export const SITE = gql`
  fragment site on Site {
    __typename
    _id
    name
    description
    devicesCount
    sitesCount
    elementsCount
    deleted
    deletedAt
    deletedBy {
      name
    }
    parent {
      __typename
      name
      _id
      coordinates
    }
    parentsTree {
      name
      _id
    }
    coordinates
    labelValues {
      ...labelValue
    }
    template {
      _id
      title
      icon
      frame {
        inputs {
          ...input
        }
      }
    }
    labelValues {
      ...labelValue
    }
    inputs {
      ...input
    }
    pendingInputs {
      ...input
    }
    pendingAt
    pendingBy {
      _id
      name
      username
      photo
    }
    needsApproval
    awaitingApproval
    responsibles {
      accounts {
        __typename
        username
        name
        email
        photo
        _id
      }
      labelValues {
        ...labelValue
      }
    }
    log {
      action
      field
      type
      status
      to {
        value
        labelValues {
          ...labelValue
        }
      }
      by {
        name
      }
      createdAt
    }
  }
  ${LABEL_VALUE}
`;

export const INPUT_APPROVAL = gql`
  fragment inputApproval on InputApproval {
    _id
    name
    updatedAt
    context
    originalInput {
      ...input
    }
    pendingInput {
      ...input
    }
  }
`;

export const SITE_TEMPLATE = gql`
  fragment siteTemplate on SiteTemplate {
    __typename
    title
    icon
    _id
    protected
    folder {
      parentsTree {
        _id
        name
      }
      parent {
        _id
        name
      }
      protected
      name
      _id
    }
    frame {
      inputs {
        ...input
      }
    }
  }
`;

export const SITE_TEMPLATE_TO_TREE = gql`
  fragment siteTemplateToTree on SiteTemplate {
    __typename
    title
    icon
    _id
    protected
    folder {
      _id
      name
      parentsTree {
        name
        _id
      }
    }
  }
`;

export const SITE_TEMPLATE_FOLDER = gql`
  fragment siteTemplateFolder on SiteTemplateFolder {
    __typename
    name
    parent {
      _id
      name
    }
    parentsTreeCount
    siteTemplatesCount(where: { archived_ne: true })
    siteTemplateFoldersCount
    totalItems: siteTemplatesCount(where: { archived_ne: true })
    totalFolders: siteTemplateFoldersCount
    parentsTree {
      _id
      name
    }
    protected
    _id
  }
`;
