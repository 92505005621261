import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface EventStateProps {
  messageSended?: boolean;
  anchorKey?: string;
}

const defaultState: EventStateProps = {
  messageSended: false,
  anchorKey: null,
};

const eventsSlice = createSlice({
  name: 'events',
  initialState: defaultState,
  reducers: {
    setMessageSended(state, action: PayloadAction<boolean>) {
      state.messageSended = action.payload;
    },
    setAnchorKey(state, action: PayloadAction<string>) {
      if (state.messageSended) {
        state.anchorKey = action.payload;
      }
    },
    clearEvents() {
      return defaultState;
    },
  },
});

export const { setMessageSended, setAnchorKey, clearEvents } = eventsSlice.actions;

export default eventsSlice.reducer;
