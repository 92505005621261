import { NexusGenEnums, NexusGenInputs } from '@server/src/types';
import { filter, map } from 'lodash';
import moment from 'moment';
import { Widget } from 'src/gql/graphql';
import { FieldPopoverItem } from 'src/utils/components/better-field-popover/better-list-by-level/popover-reducer';
import { DateRange } from 'src/utils/components/custom-date-range-picker';
import { SortOrder, SortTypes } from 'src/utils/components/custom-sort-by/types';
import { CustomRange } from 'src/utils/components/custom-value-range-picker';
import { ISelectListItem } from 'src/utils/components/execution-input-select/components/select-list-item';
import { FilterDataType } from 'src/utils/components/pie-chart-filter';
import i18n from 'src/utils/translations/i18n';
import {
  ActionByEnum,
  FilterStateEnum,
  FilterStatusEnum,
  InformationEnum,
  InputByEnum,
  IssueByEnum,
  MeasurementType,
  TimespanUnit,
  WidgetInfo,
  XAxis,
  YAxis,
} from './components/new-chart/components/chartCards';

export const calculateTotal = function (result: any[] | null): number {
  if (result?.length) {
    return result.reduce((prev, inp) => prev + (inp[inp.name] || inp.count || 0), 0);
  } else return 0;
};

export const convertToPercentage = (num: number, total: number, showAsPercentage: boolean): number =>
  showAsPercentage ? Math.round(100 * (num / total)) : num;

export const renderPercentage = (num: number, showAsPercentage: boolean): string =>
  showAsPercentage ? `${num}%` : num.toString();

export const renderValue = (num: number, total: number, showAsPercentage: boolean) =>
  renderPercentage(convertToPercentage(num, total, showAsPercentage), showAsPercentage);

export const percentageFormatter =
  (showAsPercentage: boolean, convert: boolean = false, total?: number) =>
  (num: any) =>
    convert ? renderValue(num, total, showAsPercentage) : renderPercentage(num, showAsPercentage);

export const formatTimeUnit = (date: string | Date, timeUnit: string | null, value?: string): string | null => {
  const parsedDate = moment(date, moment.ISO_8601, true).isValid() ? moment(date) : moment(new Date(date));

  switch (timeUnit) {
    case 'hour':
      return value ? moment().hour(parseInt(value)).minutes(0).format('HH:mm') : parsedDate.format('HH:mm');
    case 'day':
      return value ? moment().dayOfYear(parseInt(value)).format('DD MMMM') : parsedDate.format('dddd');
    case 'week':
      return value ? `${value} ${i18n.t('week')}` : parsedDate.isoWeek().toString();
    case 'month':
      return value
        ? moment()
            .month(parseInt(value) - 1)
            .format('MMMM')
        : parsedDate.format('MMMM');
    case 'year':
      return value ? moment().year(parseInt(value)).format('YYYY') : parsedDate.format('YYYY');
    default:
      return parsedDate.isValid() ? parsedDate.format('DD/MM/YYYY HH:mm') : 'Invalid date';
  }
};

export function editWidgetInfo(widget: Widget): WidgetInfo {
  const dataset = widget.dataset[widget.dataset.type];
  const type = widget.dataset.type;

  let realtimeInfo;
  let otherInfo;

  let xaxis: XAxis;
  let yaxis: YAxis;

  if (type === 'realtime') {
    realtimeInfo = {
      information: 'realtime',
      ranges: widget.dataset.realtime.ranges,
      timeSpan: {
        startDate: widget.dataset.realtime.startTimeRange,
        endDate: widget.dataset.realtime.stopTimeRange,
      },
      filter: {
        tags: widget.dataset.realtime.filters.flatMap((f) => f.tags),
      },
    };

    if (widget.chart === 'line') {
      xaxis =
        widget.dataset.realtime.stopTimeRange === 'now' || !widget.dataset.realtime.stopTimeRange
          ? 'timeSpanRelative'
          : 'timeSpanAbsolute';
      yaxis = 'realtime';
    }
  } else {
    const timeSpan: DateRange =
      dataset['by'] === 'time'
        ? {
            startDate: dataset['filter']['date']?.['custom']?.['from'],
            endDate: dataset['filter']['date']?.['custom']?.['until'],
          }
        : null;

    if (widget.chart === 'bar' || widget.chart === 'line') {
      if (widget.dataset.issuesCount) {
        if (widget.dataset.issuesCount.by === 'assignee') {
          xaxis = 'assignees';
          yaxis = 'issuesCount';
        } else if (widget.dataset.issuesCount.by === 'element') {
          xaxis = 'sites';
          yaxis = 'issuesCount';
        } else {
          xaxis = 'issues';
          if (widget.dataset.issuesCount?.by === 'time') {
            yaxis = 'timeSpan';
          } else {
            yaxis = 'issuesCount';
          }
        }
      }
      if (widget.dataset.actionsDuration || widget.dataset.issuesDuration) {
        xaxis = 'assignees';
        yaxis = 'timeSpent';
      }
      if (widget.dataset.issuesAverage) {
        if (widget.dataset.issuesAverage.by === 'assignee') {
          xaxis = 'assignees';
          yaxis = 'issuesAverage';
        } else {
          xaxis = 'issues';
          yaxis = 'executionAverageTime';
        }
      } else if (widget.dataset.actionsCount) {
        xaxis = 'assignees';
        yaxis = 'actionsCount';
      } else if (widget.dataset.responsesTotalValues || widget.dataset.responsesTotal) {
        xaxis = 'responses';
        yaxis = 'responsesCount';
      }
    }

    otherInfo = {
      by: dataset['by'],
      showAsPercentage: dataset['showAsPercentage'],
      includeArchives: dataset['includeArchives'],
      timeUnit: widget.dataset.issuesAverage?.timeUnit,
      timeSpan,
      range:
        dataset['min'] != null && dataset['max'] != null
          ? {
              min: dataset['min'],
              max: dataset['max'],
            }
          : undefined,
      type: widget.dataset.type,
      filter: dataset['filter']
        ? {
            catalogs: dataset['filter']['catalogs'],
            sites: dataset['filter']['sites'],
            elements: dataset['filter']['elements'],
            assignedAccounts: dataset['filter']['assignedAccounts'],
            labelValues: dataset['filter']['labelValues'],
            assignedLabelValues: dataset['filter']['assignedLabelValues'],
            states: dataset['filter']['states'],
            statuses: dataset['filter']['statuses'],
            issueCatalog: dataset['filter']['issueCatalog'],
            input: dataset['filter']['input'],
            date: dataset['filter']['date'],
            responseOptions: dataset['filter']['responseOptions'],
            decimalDigits: dataset['filter']['decimalDigits'],
            groupBy: dataset['filter']['groupBy'],
          }
        : undefined,
      customFilters:
        dataset['customFilters'] && dataset['customFilters'].length > 0 ? dataset['customFilters'] : undefined,
      ranges: dataset['ranges']?.length ? dataset['ranges'] : undefined,
      sort:
        dataset['sort'] != null && dataset['sort']['type'] != null
          ? {
              type: dataset['sort']['type'],
              order: dataset['sort']['order'],
            }
          : undefined,
      decimalDigits: dataset['decimalDigits'] ? dataset['decimalDigits'] : undefined,
    };
  }
  return {
    _id: widget._id,
    name: widget.name,
    header: widget.preset,
    axis: xaxis || yaxis ? { x: xaxis, y: yaxis } : null,
    chartType: widget.chart as any,
    information: type.startsWith('issue')
      ? 'issues'
      : type.startsWith('action')
        ? 'actions'
        : type.startsWith('responses')
          ? 'responses'
          : 'realtime',
    measurement:
      (dataset['measurement'] ?? (type.endsWith('Count') || type.endsWith('Total')))
        ? 'count'
        : type.endsWith('Planned')
          ? 'planned'
          : type.endsWith('Average') || type.endsWith('MediumValue')
            ? 'average'
            : type.endsWith('Duration')
              ? 'duration'
              : type.endsWith('LastValue')
                ? 'last'
                : null,
    ...(otherInfo ?? []),
    ...(realtimeInfo ?? []),
  };
}

export function duplicateWidgetDataset(widget: Widget) {
  const dataset = widget.dataset[widget.dataset.type];

  const newDataset =
    widget.dataset.type !== 'realtime'
      ? {
          filter: {
            catalogs: dataset['filter']['catalogs']?.map((f) => f._id),
            labelValues: dataset['filter']['labelValues']?.map((f) => f._id),
            assignedLabelValues: dataset['filter']['assignedLabelValues']?.map((f) => f._id),
            assignedAccounts: dataset['filter']['assignedAccounts']?.map((f) => f._id),
            sites: dataset['filter']['sites']?.map((f) => f._id),
            elements: dataset['filter']['elements']?.map((f) => f._id),
            statuses: dataset['filter']['statuses'],
            states: dataset['filter']['states'],
            date: dataset['filter']['date'],
            // For response widgets
            issueCatalog: dataset['filter']['issueCatalog']?._id,
            input: dataset['filter']['input'],
            unit: dataset['filter']['unit']?._id,
            responseOptions: dataset['filter']['responseOptions'],
            decimalDigits: dataset['filter']['decimalDigits'],
            groupBy: dataset['filter']['groupBy'],
          },
          customFilters:
            dataset['customFilters'] && dataset['customFilters'].length > 0 ? dataset['customFilters'] : undefined,
          measurement: dataset['measurement'] !== null ? dataset['measurement'] : undefined,
          ranges: dataset['ranges']?.length ? dataset['ranges'] : undefined,
          sort:
            dataset['sort'] != null && dataset['sort']['type'] != null
              ? {
                  type: dataset['sort']['type'],
                  order: dataset['sort']['order'],
                }
              : undefined,
          decimalDigits: dataset['decimalDigits'] ? dataset['decimalDigits'] : undefined,
        }
      : {
          filters: [
            {
              type: 'or',
              tags: map(widget.dataset.realtime.filters[0].tags, '_id'),
            },
          ],
        };

  return {
    ...dataset,
    ...newDataset,
  };
}

interface EditWidgetVariables {
  information: InformationEnum;
  measurement: MeasurementType;
  by: IssueByEnum | ActionByEnum | InputByEnum;
  chart: any;
  range: { min: number; max: number };
  includeArchives: boolean;
  showAsPercentage: boolean;
  timeSpan: DateRange;
  elements: FieldPopoverItem['siteElement'][];
  tags: FieldPopoverItem['tag'][];
  assignedAccounts: FieldPopoverItem['account'][];
  assignedLabelValues: FieldPopoverItem['label'][];
  labelValues: FieldPopoverItem['label'][];
  catalogs: FieldPopoverItem['issueCatalog'][];
  states: FilterStateEnum[];
  statuses: FilterStatusEnum[];
  isSingleValue: boolean;
  timeUnit: NexusGenEnums['WidgetDatasetIssuesAverageTimeUnit'];
  xAxis: XAxis;
  timeSpanCount: number;
  timeSpanUnit: TimespanUnit;
  issueCatalog: FieldPopoverItem['issueCatalog'];
  input: ISelectListItem;
  ranges?: CustomRange[];
  groupByResponses: 'hour' | 'day' | 'week' | 'month' | 'year';
  sort?: {
    order: SortOrder;
    type: SortTypes;
  };
  decimalDigits?: number;
  customFilters: FilterDataType[];
  responseOptions: ISelectListItem[];
}

export function buildWidgetDataset({
  information,
  measurement,
  by,
  chart,
  range,
  tags,
  elements,
  catalogs,
  assignedAccounts,
  assignedLabelValues,
  labelValues,
  includeArchives,
  showAsPercentage,
  timeSpan,
  states,
  statuses,
  timeUnit,
  xAxis,
  isSingleValue,
  timeSpanCount,
  timeSpanUnit,
  issueCatalog,
  input,
  ranges,
  groupByResponses,
  sort,
  decimalDigits,
  customFilters,
  responseOptions,
}: EditWidgetVariables): NexusGenInputs['WidgetDatasetUpdateInput'] {
  const newTarget = {
    min: range?.min,
    max: range?.max,
  };

  const filteredElements = map(
    filter(elements, (e) => e.__typename.endsWith('Element')),
    '_id',
  );
  const filteredSites = map(
    filter(elements, (e) => e.__typename.endsWith('Site')),
    '_id',
  );

  const responseOptionsNames = responseOptions?.filter((op) => op.isSelected)?.map((op) => op.name);

  let groupBy = null;

  if (by === 'time' && timeSpan?.endDate && timeSpan?.startDate) {
    const diffDays = moment(timeSpan?.endDate).diff(moment(timeSpan?.startDate), 'days');

    switch (true) {
      case diffDays > 365:
        groupBy = 'year';
        break;
      case diffDays > 28:
        groupBy = 'month';
        break;
      case diffDays > 7:
        groupBy = 'week';
        break;
      default:
        groupBy = 'day';
    }
  }

  switch (true) {
    case information === 'issues' && measurement === 'count':
      return {
        type: 'issuesCount',
        issuesCount: {
          by: by as any,
          groupBy,
          chart,
          showAsPercentage,
          includeArchives,
          ...newTarget,
          ranges,
          filter: {
            date: timeSpan,
            catalogs: map(catalogs, '_id'),
            sites: filteredSites,
            elements: filteredElements,
            states,
            labelValues: map(labelValues, '_id'),
            assignedAccounts: map(assignedAccounts, '_id'),
            assignedLabelValues: map(assignedLabelValues, '_id'),
          },
          customFilters: customFilters as any,
        },
      };
    case information === 'issues' && measurement === 'planned':
      return {
        type: 'issuesPlanned',
        issuesPlanned: {
          by: by as any,
          chart,
          showAsPercentage,
          includeArchives,
          ...newTarget,
          ranges,
          filter: {
            catalogs: map(catalogs, '_id'),
            sites: filteredSites,
            elements: filteredElements,
            states,
            labelValues: map(labelValues, '_id'),
            assignedAccounts: map(assignedAccounts, '_id'),
            assignedLabelValues: map(assignedLabelValues, '_id'),
          },
          customFilters: customFilters as any,
        },
      };
    case information === 'issues' && measurement === 'duration':
      return {
        type: 'issuesDuration',
        issuesDuration: {
          by: by as any,
          chart,
          showAsPercentage,
          includeArchives,
          ...newTarget,
          ranges,
          filter: {
            catalogs: map(catalogs, '_id'),
            sites: filteredSites,
            elements: filteredElements,
            states,
            labelValues: map(labelValues, '_id'),
            assignedAccounts: map(assignedAccounts, '_id'),
            assignedLabelValues: map(assignedLabelValues, '_id'),
          },
          customFilters: customFilters as any,
        },
      };
    case information === 'issues' && measurement === 'average':
      return {
        type: 'issuesAverage',
        issuesAverage: {
          by: by as any,
          chart,
          showAsPercentage,
          includeArchives,
          timeUnit,
          ...newTarget,
          ranges,
          filter: {
            catalogs: map(catalogs, '_id'),
            sites: filteredSites,
            elements: filteredElements,
            states,
            labelValues: map(labelValues, '_id'),
            assignedAccounts: map(assignedAccounts, '_id'),
            assignedLabelValues: map(assignedLabelValues, '_id'),
          },
          customFilters: customFilters as any,
        },
      };
    case information === 'actions' && measurement === 'count':
      return {
        type: 'actionsCount',
        actionsCount: {
          by: by as any,
          chart,
          showAsPercentage,
          ...newTarget,
          ranges,
          filter: {
            statuses,
            sites: filteredSites,
            elements: filteredElements,
            labelValues: map(labelValues, '_id'),
            assignedAccounts: map(assignedAccounts, '_id'),
            assignedLabelValues: map(assignedLabelValues, '_id'),
          },
          customFilters: customFilters as any,
        },
      };
    case information === 'actions' && measurement === 'duration':
      return {
        type: 'actionsDuration',
        actionsDuration: {
          by: by as any,
          chart,
          showAsPercentage,
          ...newTarget,
          ranges,
          filter: {
            statuses,
            sites: filteredSites,
            elements: filteredElements,
            labelValues: map(labelValues, '_id'),
            assignedAccounts: map(assignedAccounts, '_id'),
            assignedLabelValues: map(assignedLabelValues, '_id'),
          },
          customFilters: customFilters as any,
        },
      };
    case information === 'realtime':
      return {
        type: 'realtime',
        realtime: {
          ranges,
          chart,
          startTimeRange: isSingleValue
            ? null
            : xAxis === 'timeSpanRelative'
              ? `-${timeSpanCount}${timeSpanUnit.toLowerCase().at(0)}`
              : timeSpan?.startDate?.toISOString(),
          stopTimeRange: isSingleValue || xAxis === 'timeSpanRelative' ? null : timeSpan?.endDate?.toISOString(),
          filters: [
            {
              type: 'or',
              tags: map(tags, '_id'),
            },
          ],
        },
      };
    case information === 'responses' && measurement === 'last' && ranges.length === 0:
      return {
        type: 'responsesLastValue',
        responsesLastValue: {
          chart,
          ...newTarget,
          filter: {
            sites: filteredSites,
            elements: filteredElements,
            issueCatalog: issueCatalog._id,
            input: input._id,
            unit: input?.unit?._id,
          },
          customFilters: customFilters as any,
        },
      };
    case information === 'responses' && measurement === 'average' && ranges.length === 0:
      return {
        type: 'responsesAverageValue',
        responsesAverageValue: {
          chart,
          ...newTarget,
          filter: {
            sites: filteredSites,
            elements: filteredElements,
            issueCatalog: issueCatalog._id,
            input: input._id,
            groupBy: groupByResponses,
            decimalDigits: decimalDigits,
            date:
              timeSpan?.startDate && timeSpan?.endDate
                ? {
                    type: 'custom',
                    custom: {
                      from: timeSpan.startDate,
                      until: timeSpan.endDate,
                    },
                  }
                : timeSpan?.type
                  ? {
                      type: timeSpan?.type,
                      [timeSpan.type]: timeSpan[timeSpan?.type],
                    }
                  : undefined,
          },
          customFilters: customFilters as any,
        },
      };
    case information === 'responses' && measurement === 'lowest':
      return {
        type: 'responsesLowestValue',
        responsesLowestValue: {
          chart,
          ...newTarget,
          filter: {
            sites: filteredSites,
            elements: filteredElements,
            issueCatalog: issueCatalog._id,
            input: input._id,
            groupBy: groupByResponses,
            decimalDigits: decimalDigits,
            date:
              timeSpan?.startDate && timeSpan?.endDate
                ? {
                    type: 'custom',
                    custom: {
                      from: timeSpan.startDate,
                      until: timeSpan.endDate,
                    },
                  }
                : undefined,
          },
          customFilters: customFilters as any,
        },
      };
    case information === 'responses' && measurement === 'highest':
      return {
        type: 'responsesHighestValue',
        responsesHighestValue: {
          chart,
          ...newTarget,
          filter: {
            sites: filteredSites,
            elements: filteredElements,
            issueCatalog: issueCatalog._id,
            input: input._id,
            groupBy: groupByResponses,
            decimalDigits: decimalDigits,
            date:
              timeSpan?.startDate && timeSpan?.endDate
                ? {
                    type: 'custom',
                    custom: {
                      from: timeSpan.startDate,
                      until: timeSpan.endDate,
                    },
                  }
                : undefined,
          },
          customFilters: customFilters as any,
        },
      };
    case information === 'responses' && measurement === 'sum':
      return {
        type: 'responsesSum',
        responsesSum: {
          chart,
          ...newTarget,
          filter: {
            sites: filteredSites,
            elements: filteredElements,
            issueCatalog: issueCatalog._id,
            decimalDigits: decimalDigits,
            input: input._id,
            groupBy: groupByResponses,
            date:
              timeSpan?.startDate && timeSpan?.endDate
                ? {
                    type: 'custom',
                    custom: {
                      from: timeSpan.startDate,
                      until: timeSpan.endDate,
                    },
                  }
                : undefined,
          },
          customFilters: customFilters as any,
        },
      };
    case information === 'responses' && measurement === 'count':
      if (responseOptions) {
        return {
          type: 'responsesTotal',
          responsesTotal: {
            by: by as any,
            chart,
            showAsPercentage,
            ...newTarget,
            filter: {
              date:
                timeSpan?.startDate && timeSpan?.endDate
                  ? {
                      type: 'custom',
                      custom: {
                        from: timeSpan.startDate,
                        until: timeSpan.endDate,
                      },
                    }
                  : timeSpan?.type
                    ? {
                        type: timeSpan?.type,
                        [timeSpan.type]: timeSpan[timeSpan?.type],
                      }
                    : null,
              sites: filteredSites,
              elements: filteredElements,
              issueCatalog: issueCatalog._id,
              input: input._id,
              unit: input?.unit?._id,
              responseOptions: responseOptionsNames,
            },
            sort,
            customFilters: customFilters as any,
          },
        };
      } else {
        return {
          type: 'responsesTotalValues',
          responsesTotalValues: {
            by: by as any,
            chart,
            ...newTarget,
            filter: {
              date:
                timeSpan?.startDate && timeSpan?.endDate
                  ? {
                      type: 'custom',
                      custom: {
                        from: timeSpan.startDate,
                        until: timeSpan.endDate,
                      },
                    }
                  : timeSpan?.type
                    ? {
                        type: timeSpan?.type,
                        [timeSpan.type]: timeSpan[timeSpan?.type],
                      }
                    : null,
              sites: filteredSites,
              elements: filteredElements,
              issueCatalog: issueCatalog._id,
              input: input._id,
              unit: input?.unit?._id,
            },
            sort,
            decimalDigits,
          },
        };
      }
    case information === 'responses' && ranges.length > 0:
      return {
        type: 'responsesByRating',
        responsesByRating: {
          chart,
          filter: {
            sites: filteredSites,
            elements: filteredElements,
            issueCatalog: issueCatalog._id,
            input: input._id,
            unit: input?.unit?._id,
            date:
              timeSpan?.startDate && timeSpan?.endDate
                ? {
                    type: 'custom',
                    custom: {
                      from: timeSpan.startDate,
                      until: timeSpan.endDate,
                    },
                  }
                : timeSpan?.type
                  ? {
                      type: timeSpan?.type,
                      [timeSpan.type]: timeSpan[timeSpan?.type],
                    }
                  : undefined,
          },
          ranges: ranges.map((r) => (r.max ? r : { ...r, max: 100 })),
          measurement: measurement === 'last' ? 'last' : 'average',
          customFilters: customFilters as any,
        },
      };
    default:
      throw new Error('error building widget');
  }
}

export function fromNow(date: moment.Moment): string {
  const now = moment();

  if (now.diff(date, 'minutes') < 1) {
    return i18n.t('Updated') + ' ' + i18n.t('justNow');
  }

  if (now.diff(date, 'hours') < 1) {
    return i18n.t('updatedSince', {
      date: now.diff(date, 'minutes') + ' ' + i18n.t('minutes'),
    });
  }

  return i18n.t('Updated') + ' ' + date.fromNow();
}
