import { styles } from 'src/utils/components/heat-map/styles';
import { Tooltip, useTheme } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Classes } from '@mui/styles/mergeClasses/mergeClasses';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import i18n from 'src/utils/translations/i18n';
import { column } from './edit-table';
import { idxToDelete } from './group-item';
import { withStyles } from '@mui/styles';
import { Label } from 'src/gql/graphql';

interface Props {
  classes: Classes;
  column: column[];
  columnToEdit: column[][];
  label: Label;
  index: number;
  idx: number;
  labelValuesIdsByColumn: string[];
  setChangeColumn(column: column[][]): void;
  setIdxToDelete(item: idxToDelete): void;
  setLabelPopover(target: EventTarget): void;
  setGroupSelected(group: Label): void;
  setSelectedItemIndex(index: number): void;
}

const SelectionItem = (props: Props) => {
  const { classes, column, label, index, idx, columnToEdit, labelValuesIdsByColumn } = props;
  const theme = useTheme();

  const dropdownSelection = {
    marginRight: '8px',
    marginLeft: '8px',
    ...(index === 0 || index % 2 === 0
      ? { background: theme.palette.primary.light }
      : { background: theme.palette.background.background0 }),
  };

  return (
    <div className={classes.divLabelGroups} style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ width: '100%' }}>
        <div
          key={index}
          className={classes.addGroupDiv}
          onClick={(e) => {
            props.setSelectedItemIndex(index);
            props.setLabelPopover(e.currentTarget);
            props.setGroupSelected(label);
            props.setChangeColumn(columnToEdit);
            props.setIdxToDelete({
              type: 'group',
              idx: idx,
            });
          }}
          style={dropdownSelection}
        >
          <Tooltip
            title={
              labelValuesIdsByColumn && labelValuesIdsByColumn[index]
                ? column.find((c) => c.labelValue?._id === labelValuesIdsByColumn[index])?.labelValue?.value
                : i18n.t('selectOption')
            }
            arrow
            placement='top'
            classes={{ tooltip: props.classes.tooltipCustom, arrow: props.classes.arrowCustom }}
          >
            <span
              className={classes.addGroupDivText}
              style={
                labelValuesIdsByColumn && labelValuesIdsByColumn[index]
                  ? { color: `${theme.palette.text.primary}` }
                  : { color: `${theme.palette.text.secondary}` }
              }
              data-testid={`label-${index}-name`}
            >
              {labelValuesIdsByColumn && labelValuesIdsByColumn[index]
                ? column.find((c) => c.labelValue?._id === labelValuesIdsByColumn[index])?.labelValue?.value
                : i18n.t('selectOption')}
            </span>
          </Tooltip>
          <div data-testid={`label-${index}`} style={{ paddingRight: '16px' }}>
            <KeyboardArrowDown className={classes.arrowDown}></KeyboardArrowDown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose<any>(withTranslation('translation'), withStyles(styles))(SelectionItem);
