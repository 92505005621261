import { lazy, Suspense } from 'react';
import LazyLoadingContent from 'src/utils/components/lazy-loading-content';

const ArchiveCreateDefaultRefresh = () => lazy(() => import('src/modules/archive/archive.create.default'));
const SiteCreateDefaultRefresh = () => lazy(() => import('src/modules/archive/site.create.default'));
const ElementCreateDefaultRefresh = () => lazy(() => import('src/modules/archive/element.create.default'));
const ProfileCreateDefaultRefresh = () => lazy(() => import('src/modules/archive/profile.create.default'));
const AccountCreateDefaultRefresh = () => lazy(() => import('src/modules/archive/account.create.default'));

let ArchiveCreateDefault = ArchiveCreateDefaultRefresh();
let SiteCreateDefault = SiteCreateDefaultRefresh();
let ElementCreateDefault = ElementCreateDefaultRefresh();
let ProfileCreateDefault = ProfileCreateDefaultRefresh();
let AccountCreateDefault = AccountCreateDefaultRefresh();

export const render = {
  archive: {
    issue: ({ ...props }) => {
      if (ArchiveCreateDefault?._result?.type === 'error') {
        ArchiveCreateDefault = ArchiveCreateDefaultRefresh();
      }

      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <ArchiveCreateDefault {...props} />
        </Suspense>
      );
    },
    site: ({ ...props }) => {
      if (SiteCreateDefault?._result?.type === 'error') {
        SiteCreateDefault = SiteCreateDefaultRefresh();
      }

      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <SiteCreateDefault {...props} />
        </Suspense>
      );
    },
    element: ({ ...props }) => {
      if (ElementCreateDefault?._result?.type === 'error') {
        ElementCreateDefault = ElementCreateDefaultRefresh();
      }

      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <ElementCreateDefault {...props} />
        </Suspense>
      );
    },
    profile: ({ ...props }) => {
      if (ProfileCreateDefault?._result?.type === 'error') {
        ProfileCreateDefault = ProfileCreateDefaultRefresh();
      }

      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <ProfileCreateDefault {...props} />
        </Suspense>
      );
    },
    account: ({ ...props }) => {
      if (AccountCreateDefault?._result?.type === 'error') {
        AccountCreateDefault = AccountCreateDefaultRefresh();
      }

      return (
        <Suspense fallback={<LazyLoadingContent />}>
          <AccountCreateDefault {...props} />
        </Suspense>
      );
    },
  },
};

export default render;
