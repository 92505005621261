import * as React from 'react';
import Select, { Options, createFilter } from 'react-select';
import { useTheme } from '@mui/styles';
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Button,
  TextField,
  Tooltip,
  Popover,
  MenuItem,
} from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import { styles } from 'src/utils/components/select-box/styles';
import {
  DeleteOutlined,
  CallSplit,
  FeedbackOutlined,
  LiveHelpOutlined,
  RemoveCircleOutline,
  ReportProblemOutlined,
  Cancel,
  InsertDriveFileOutlined,
  HighlightOffOutlined,
  Add,
} from '@mui/icons-material';
import InputFieldGeneric from 'src/utils/components/input-field/input-generic';
import * as PropTypes from 'prop-types';
import { useEffect, useState, useCallback, useMemo } from 'react';
import i18n from 'src/utils/translations/i18n';
import store from 'src/reducer-manager';
import CheckboxComponent from 'src/utils/components/select-box/components/checkbox';
import RadioComponent from 'src/utils/components/select-box/components/radio';
import update from 'immutability-helper';
import { isArray, isEqual, once } from 'lodash';
import usePrevious from 'src/utils/hooks/usePrevious';
import { GenericInputCardContext } from '../generic-input-card';
import { editPreset } from 'src/modules/issue-templates/issue.templates.redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ConnectedProps } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useMainContainerContext } from 'src/base/main-container/context/main.container.context';
import {
  withIssueTemplateConnector,
  WithIssueTemplateConnectorProps,
} from 'src/modules/issue-templates/redux/issue.template.draft.redux';
import { useSequential } from 'src/utils/hooks/useSequential';
import useEffectEvent from 'src/utils/hooks/useEffectEvent';
import { typedConnect } from 'src/utils/funcs';

export const customStyles = (error: boolean) => {
  const theme = useTheme();
  return {
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      borderBottomRightRadius: '0 !important',
      borderTopRightRadius: '0 !important',
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      borderBottomLeftRadius: '0 !important',
      borderTopLeftRadius: '0 !important',
    }),
    control: (provided: any, state: { isDisabled: boolean; isFocused: boolean }) => {
      if (state.isDisabled) {
        return {
          ...provided,
          boxShadow: 'none !important',
          transition: '0.3s',
          border: `1px solid ${theme.palette.grey.light} !important`,
          borderRadius: '5px !important',
          color: `${theme.palette.text.disabled} !important`,
          cursor: 'not-allowed',
          height: '40px',
          minHeight: '30px',
          backgroundColor: `transparent !important`,
        };
      } else {
        return {
          ...provided,
          '&:hover': {
            border: state.isFocused
              ? `1px solid ${error ? theme.palette.error.main : theme.palette.primary.main} !important`
              : `1px solid ${error ? theme.palette.error.main : theme.palette.primary.main} !important`,
            color: theme.palette.text.primary + ' !important',
            background: `transparent !important`,
            boxShadow: `${theme.palette.grey.light} !important`,
            cursor: 'pointer',
            height: '40px',
            minHeight: '40px',
          },
          '&:active': {
            border: state.isFocused
              ? `1px solid ${error ? theme.palette.error.main : theme.palette.primary.main} !important`
              : `1px solid ${error ? theme.palette.error.main : theme.palette.primary.main} !important`,
            color: theme.palette.text.primary + ' !important',
            background: `transparent !important`,
            boxShadow: `${theme.palette.grey.light} !important`,
            cursor: 'pointer',
            height: '40px',
            minHeight: '40px',
          },
          border: state.isFocused
            ? `1px solid ${error ? theme.palette.error.main : theme.palette.primary.main} !important`
            : `1px solid ${error ? theme.palette.error.main : theme.palette.grey.light} !important`,
          background: `transparent !important`,
          boxShadow: 'none !important',
          height: '40px',
          minHeight: '40px',
          transition: 'all .3s',
        };
      }
    },
    container: (provided: any) => ({
      ...provided,
      width: '-webkit-fill-available',
      height: '40px',
      minHeight: '40px',
    }),
    option: (provided: any, state: { isFocused: boolean; isDisabled: boolean; isSelected: boolean }) => ({
      ...provided,
      '&:hover:not([:disabled])': {
        backgroundColor: theme.palette.primary.main,
        color: `${theme.palette.common.white} !important`,
        cursor: 'pointer',
      },
      '&:focus:not([:disabled])': {
        backgroundColor: `${theme.palette.primary.dark} !important`,
        color: `${theme.palette.common.white} !important`,
      },
      ':active': {
        ...styles[':active'],
        backgroundColor: state.isSelected ? theme.palette.primary.dark : undefined,
      },
      backgroundColor: state.isFocused ? theme.palette.primary.main : theme.palette.background.default,
      color: state.isFocused
        ? theme.palette.common.white
        : state.isDisabled
          ? theme.palette.text.disabled
          : theme.palette.text.primary,
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: theme.palette.background.default,
      zIndex: 1050,
    }),
    singleValue: (provided: any, state: { isDisabled: boolean }) => {
      const transition = 'opacity 300ms';

      return {
        ...provided,
        transition,
        color: state.isDisabled
          ? theme.palette.text.disabled + ' !important'
          : theme.palette.text.primary + ' !important',
      };
    },
    placeholder: (provided: any, state: { isDisabled: boolean }) => ({
      ...provided,
      color: state.isDisabled
        ? theme.palette.text.disabled + ' !important'
        : theme.palette.text.secondary + ' !important',
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    dropdownIndicator: (base: any, state: { isDisabled: boolean }) => ({
      ...base,
      color: state.isDisabled ? theme.palette.grey.light : theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.text.primary,
      },
    }),
  };
};

interface SelectBoxOption {
  id?: string;
  value?:
    | string
    | {
        value?: string;
        label?: string;
      };
  label?: string;
  _id?: string;
  order?: number;
  color?: string;
}

interface SelectBoxProps
  extends ConnectedProps<typeof connecter>,
    WithIssueTemplateConnectorProps,
    WithStyles<typeof styles>,
    WithTranslation {
  values: any;
  handleChange: (e: any) => void;
  multiple?: boolean;
  listValues: any[];
  disabled?: boolean;
  placeholder?: string;
  mouseOver?: boolean;
  disable?: boolean;
  id?: string;
  root?: boolean;
  formControlRoot?: any;
  removeItemSelected?: (e: any) => void;
  fullWidth?: any;
  extended?: boolean;
  labelSelectedStyle?: any;
  editableOptions?: boolean;
  options?: SelectBoxOption[];
  setOptions?: (type: string, options) => Promise<any>;
  error?: boolean;
  onFocus?: (e?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (e?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void;
  addCondition?: (opt: { id: string; label: string }) => void;
  validations?: any;
  refCreateAction?: any;
  refCreateAlert?: any;
  refNewAnswer?: any;
  refFailResponse?: any;
  refCancelIssue?: any;
  refFinishIssue?: any;
  setActionRequired?: (item: { label: string; _id: string; id: number }) => void;
  setAlert?: (item: any) => void;
  addFailResponse?: (item: { label: string; _id: string; id: number }) => void;
  setCancelIssue?: (item: { label: string; _id: string; id: number }) => void;
  refExecuteNewTask?: any;
  addNewTask?: (item: { label: string; _id: string; id: number }) => void;
  setFinishIssue?: (item: any) => void;
  required?: boolean;
  scoreInputs?: boolean;
  setScoreOptions?;
  scoreOptions?;
  isOptionDisabled?: (option: string, selectValue: Options<string>) => boolean;
  helperText?: JSX.Element;
  setOpts?: (options: string[]) => void;
}

const SelectBox = (props: SelectBoxProps): JSX.Element => {
  const {
    values,
    handleChange,
    multiple,
    listValues,
    disabled,
    placeholder,
    id,
    extended,
    editableOptions,
    options,
    error,
    onBlur,
    onClick,
    onFocus,
    addCondition,
    addFailResponse,
    addNewTask,
    required,
    scoreInputs,
    setScoreOptions,
    scoreOptions,
    isOptionDisabled,
    editPreset,
    issueTemplate,
    updateIssueTemplateDraftState,
  } = props;
  const [editOption, setEditOption] = useState(null);

  const setOptions = useEffectEvent(props.setOptions);

  const theme = useTheme();
  const location = useLocation();
  const { input, setRefConditional } = React.useContext(GenericInputCardContext) || {};
  const frame = issueTemplate?.frame;
  const inputIncludedInPresets = React.useMemo(
    () => location.pathname.includes('templates/issues') && input?.selection?.preset?.presetId,
    [JSON.stringify(input), JSON.stringify(issueTemplate?.frame?.presets)],
  );
  const [openConditionalPopover, setOpenConditionalPopover] = useState<any>(null);
  const mainContainerState = useMainContainerContext();
  const [showAllSelectionOptions, setShowAllSelectionOptions] = useState<boolean>(false);

  const { queueTask } = useSequential();

  useEffect(() => {
    setRefConditional && setRefConditional(!!openConditionalPopover);
  }, [openConditionalPopover]);

  const opts = React.useMemo(() => {
    return options?.length && scoreOptions?.length === 0 && options[0].id !== undefined
      ? options.map((opt, i) => ({
          _id: opt._id || `id-${i}`,
          ...opt,
          id: opt.id || i,
          order: i,
        }))
      : scoreOptions?.length
        ? scoreOptions.map((opt, i) => ({
            _id: `id-${i}`,
            order: i,
            id: i,
            label: opt.option,
            score: opt.score,
          }))
        : options?.length
          ? options.map((opt, i) => ({
              _id: opt._id || `id-${i}`,
              order: opt.order || i,
              id: opt.id || i,
              label: opt,
            }))
          : listValues;
  }, [options, scoreOptions, listValues]);

  const toggleShowAllSelectionOptions = () => {
    setShowAllSelectionOptions(true);
  };

  const visibleOpts = useMemo(() => {
    return editableOptions ? opts : showAllSelectionOptions ? opts : opts.slice(0, 5);
  }, [editableOptions, opts, showAllSelectionOptions]);

  const prevOpts = usePrevious(opts);

  const onKeyUpHandler = useCallback(
    async (item, event) => {
      event.preventDefault();
      event.stopPropagation();

      if (event.key === 'Enter') {
        setOptions('CHANGE', { ...item, label: event.target.value });
        if (item.id + 1 === opts.length) {
          setOptions('ADD', {
            order: opts.length,
            id: opts.length,
            label: `${i18n.t('option')} ${opts.length + 1}`,
            _id: `id-${opts.length}`,
          });
          setEditOption(opts.length);
        }
      }
    },
    [opts.length],
  );

  const onRemoveHandler = useCallback((item) => queueTask(() => setOptions('REMOVE', item)), []);

  const onAddHandler = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      queueTask(() =>
        setOptions('ADD', {
          order: opts.length,
          id: opts.length,
          label: `${i18n.t('option')} ${opts.length + 1}`,
          _id: `id-${opts.length}`,
        }),
      );
    },
    [opts.length],
  );

  const onBlurHandler = useCallback(
    async (e, item) => {
      e.preventDefault();
      e.stopPropagation();
      setEditOption(null);

      if (e.target.value.trim() === '') {
        e.target.value = `${i18n.t('option')} ${item.order + 1}`;
      }

      const tmpValue = e.target.value.trim();

      queueTask(() => setOptions('CHANGE', { ...item, label: tmpValue }));
      if (onBlur) onBlur(e);
    },
    [onBlur],
  );

  const inputsLabel = (
    item: { label: string; _id: string; id: number; score?: number; order?: number },
    key?,
  ): JSX.Element => {
    return editableOptions ? (
      <div
        className={
          inputIncludedInPresets
            ? `${props.classes.divLabel} ${props.classes.divLabelPresetHovered}`
            : props.classes.divLabel
        }
        key={item._id}
      >
        <TextField
          id={`option-${key ?? item.order ?? 'X'}`}
          data-testid={`option-${key ?? item.order ?? 'X'}`}
          key={item.label}
          defaultValue={item?.label}
          variant={'standard'}
          size={'small'}
          disabled={!!inputIncludedInPresets}
          multiline={true}
          fullWidth={true}
          InputProps={{
            classes: {
              input:
                editOption === key && !inputIncludedInPresets
                  ? props.classes.inputFieldEdit
                  : !inputIncludedInPresets && props.classes.inputField,
              disabled: props.classes.inputInPreset,
              underline: props.classes.inputUnderline,
            },
            disableUnderline: editOption !== key,
          }}
          onClick={() => setEditOption(key)}
          onKeyUp={(ev) => onKeyUpHandler(item, ev)}
          onBlur={(e) => {
            onBlurHandler(e, item);
          }}
          onFocus={(event): void => {
            if (onFocus) {
              onFocus(event);
            }
            event.target.select();
          }}
        />
        {options.length > 1 ? (
          <div
            id={'div-buttons-actions'}
            className={
              openConditionalPopover?.item && openConditionalPopover?.item.label === item.label
                ? props.classes.divBtnsActive
                : props.classes.divBtns
            }
          >
            {!inputIncludedInPresets && (
              <Button
                classes={{ root: props.classes.btnDelete }}
                onClick={() => onRemoveHandler(item)}
                id={`buttonDeleteOption${item.id}`}
              >
                <DeleteOutlined classes={{ root: props.classes.deleteIcon }} />
              </Button>
            )}
            {addCondition && !mainContainerState.doingOnBoarding && !multiple ? (
              <Tooltip title={i18n.t('conditional').toString()}>
                <Button
                  classes={{
                    root:
                      props.validations?.level === 2
                        ? props.classes.buttonConditionalDisabled
                        : props.classes.buttonConditional,
                  }}
                  variant={'text'}
                  onClick={(e): void => {
                    setOpenConditionalPopover({ target: e.target, item: item });
                  }}
                  data-testid={`buttonConditional${key !== undefined ? key : item.label}`}
                >
                  <CallSplit style={{ transform: 'rotate(90deg)' }} />
                </Button>
              </Tooltip>
            ) : null}
          </div>
        ) : null}
        {scoreInputs ? (
          <div style={{ marginLeft: 35 }}>
            <TextField
              key={item.score}
              placeholder='0'
              variant='outlined'
              type='number'
              className={props.classes.scoreDiv}
              defaultValue={item.score}
              id={item._id}
              onBlur={(e) => {
                setScoreOptions({ option: item.label, score: e.target.value, _id: item._id });
              }}
            />
          </div>
        ) : null}
      </div>
    ) : (
      <div className={disabled ? props.classes.optionsDisable : props.classes.optionsEnable}>{item.label}</div>
    );
  };

  inputsLabel.propTypes = {
    classes: PropTypes.object,
    values: PropTypes.object,
  };

  useEffect(() => {
    // @ts-ignore
    if (prevOpts && opts.length > prevOpts.length) {
      const inp = document.getElementById(opts[opts.length - 1]._id);
      if (inp) inp.focus();
    }
  }, [opts]);

  const moveOption = useCallback(
    once((src_ID: string, dest_order: number) => {
      const opt = opts.find((i) => i._id === src_ID);

      if (!opt) {
        return;
      }

      const newOpts = update(opts, {
        $splice: [
          [opt.order, 1],
          [dest_order, 0, opt],
        ],
      }).map((opti, i) => ({ ...opti, order: i }));

      props.setOpts(newOpts);
    }),
    [JSON.stringify(opts)],
  );

  const dropOption = async () => {
    await setOptions('MOVE', opts);
  };

  const renderScoreSum = (multiple: boolean) => {
    let sum = 0;

    if (multiple) {
      opts.map((opt) => (sum += opt.score));
    } else {
      sum = Math.max(...opts.map((opt) => parseInt(opt.score)));
    }

    return (
      <div
        style={{
          /* display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', */
          marginLeft: 'auto',
          /*  marginRight: multiple ? '75px' : '95px', */
          textAlignLast: 'center',
          width: multiple ? '295px' : '330px',
        }}
      >
        <div
          id={`total-score-value-${sum}`}
          data-testid={`total-score-value-${sum}`}
          className={props.classes.sumValueText}
        >
          {sum.toString() === 'NaN' ? '-' : sum}
        </div>
        <div id={`score-text`} data-testid={`score-text`} className={props.classes.sumValueDiv}>
          {i18n.t('maxScore')}
        </div>
      </div>
    );
  };

  const addConditionalToPreset = async () => {
    const currentPreset = frame?.presets.find((preset) => preset._id === input[input.type].preset.presetId);
    const updatedOptions = currentPreset.options.map((option) => {
      if (option.response === openConditionalPopover.item.label) {
        // Update the desired option here
        return {
          ...option,
          conditionals: option.conditionals.length ? [...option.conditionals, input?._id] : [input?._id],
        };
      } else {
        // If the option does not match, return it unchanged
        return option;
      }
    });
    const response = await editPreset(issueTemplate?._id, { options: updatedOptions }, currentPreset._id);
    updateIssueTemplateDraftState({
      frame: { ...issueTemplate?.frame, presets: response.frame?.presets },
    });
  };

  return (
    <div onClick={onClick}>
      {extended && multiple ? (
        <FormControl
          id={id}
          component='fieldset'
          classes={{ root: error ? props.classes.formControlError : props.classes.formControl }}
        >
          <FormGroup>
            {opts.map((item) => {
              return item.id !== undefined ? (
                <div key={item._id}>
                  <CheckboxComponent
                    item={item}
                    handleChange={handleChange}
                    values={values}
                    disabled={disabled !== undefined ? disabled : true}
                    editableOptions={editableOptions || false}
                    inputsLabel={inputsLabel}
                    classes={props.classes}
                    moveOption={moveOption}
                    helperText={props.helperText}
                    dropOption={dropOption}
                  />
                </div>
              ) : null;
            })}
            <div>
              {editableOptions ? (
                <div style={{ display: 'flex', paddingLeft: '40px', width: '100%' }}>
                  <FormControlLabel
                    id={`button-add-option`}
                    data-testid={`button-add-option`}
                    key={'add'}
                    onClick={onAddHandler}
                    classes={{
                      root: props.classes.addOpt,
                      label: props.classes.addOptLabel,
                    }}
                    control={<Add style={{ fontSize: '18px' }} />}
                    label={i18n.t('addOptions')}
                  />
                  {scoreInputs ? renderScoreSum(true) : null}
                </div>
              ) : null}
            </div>
          </FormGroup>
        </FormControl>
      ) : extended && !multiple ? (
        <FormControl
          component='fieldset'
          style={{ paddingLeft: inputIncludedInPresets && '14px' }}
          classes={{ root: error ? props.classes.formControl2Error : props.classes.formControl }}
        >
          {opts.length > 5 && !location.pathname.startsWith('/templates') && !editableOptions ? (
            <InputFieldGeneric
              disabled={disabled !== undefined ? disabled : true}
              id={`selectionInput`}
              data-testid={`selectionInput`}
              type={'select'}
              onlyInput
              values={values[0]}
              options={opts.map((opt) => opt.label)}
              handleChange={(e): void => {
                handleChange(e[0]);
              }}
              placeholder={i18n.t('selectOption')}
            />
          ) : (
            <RadioGroup
              value={values?.length ? opts.filter((opt) => opt.label === values[0])[0].id : null}
              id={id}
              onChange={(e): void => {
                handleChange(opts.filter((v) => v.id.toString() == e.target.value)[0].label);
              }}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
            >
              {visibleOpts.map((item, index) => (
                <div style={{ width: '100%' }} key={item._id}>
                  <RadioComponent
                    //@ts-ignore
                    item={item}
                    key={item._id}
                    disabled={disabled !== undefined ? disabled : true}
                    editableOptions={editableOptions || false}
                    inputIncludedInPresets={!!inputIncludedInPresets}
                    inputsLabel={inputsLabel}
                    classes={props.classes}
                    moveOption={moveOption}
                    required={required}
                    handleChange={handleChange}
                    values={values}
                    index={index}
                    helperText={props.helperText}
                    dropOption={dropOption}
                  />
                </div>
              ))}

              {opts.length > 5 && !showAllSelectionOptions && !editableOptions && (
                <div className={props.classes.divMoreSelectionOptions} onClick={toggleShowAllSelectionOptions}>
                  + {opts.length - 5}
                </div>
              )}
            </RadioGroup>
          )}
          {editableOptions && !inputIncludedInPresets ? (
            <div style={{ display: 'flex', paddingLeft: '40px', width: '100%' }}>
              <FormControlLabel
                id={`button-add-option`}
                data-testid={`button-add-option`}
                key={'add'}
                onClick={onAddHandler}
                classes={{
                  root: props.classes.addOpt,
                  label: props.classes.addOptLabel,
                }}
                control={<Add style={{ fontSize: '18px' }} />}
                label={i18n.t('addOptions')}
              />
              {scoreInputs ? renderScoreSum(false) : null}
            </div>
          ) : (
            <>{scoreInputs && editableOptions ? renderScoreSum(false) : null}</>
          )}
        </FormControl>
      ) : (
        <Select
          isOptionDisabled={isOptionDisabled}
          options={opts}
          styles={customStyles(error || false)}
          isMulti={multiple}
          placeholder={i18n.t(placeholder)}
          id={id}
          menuPortalTarget={document.body}
          filterOption={createFilter({ ignoreAccents: false, stringify: (option) => `${option.label}` })}
          onChange={(e): void => {
            handleChange(Array.isArray(e) ? e.map((v) => v.label) : [e.label]);
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          isDisabled={disabled}
          value={
            isArray(values)
              ? opts?.filter((opt) => values?.find((v) => isEqual(v, opt.label)))
              : values
                ? opts?.filter((opt) => [values].find((v) => isEqual(v, opt.label)))
                : []
          }
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      )}
      {openConditionalPopover ? (
        <Popover
          open={!!openConditionalPopover}
          key={openConditionalPopover?.item.label}
          // @ts-ignore
          anchorEl={openConditionalPopover?.target}
          onClose={(): void => {
            setOpenConditionalPopover(null);
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {!props.isMasterTenant ? (
            <MenuItem
              id='button-Create action'
              data-testid='button-Create action'
              ref={props.refCreateAction}
              onClick={async (e): Promise<void> => {
                e.stopPropagation();
                inputIncludedInPresets && (await addConditionalToPreset());
                if (props.setActionRequired) props.setActionRequired(openConditionalPopover?.item);
                setOpenConditionalPopover(null);
              }}
            >
              <FeedbackOutlined style={{ color: theme.palette.other.peach, marginRight: '5px' }} />
              {i18n.t('createAction')}
            </MenuItem>
          ) : null}
          <MenuItem
            id='button-Create alert'
            data-testid='button-Create alert'
            ref={props.refCreateAlert}
            onClick={async (e): Promise<void> => {
              e.stopPropagation();
              inputIncludedInPresets && (await addConditionalToPreset());
              if (props.setAlert) props.setAlert(openConditionalPopover?.item);
              setOpenConditionalPopover(null);
            }}
          >
            <ReportProblemOutlined style={{ color: theme.palette.error.main, marginRight: '5px' }} />
            {i18n.t('createAlert')}
          </MenuItem>
          <MenuItem
            id='button-Answer new question(s)'
            data-testid='button-Answer new question(s)'
            ref={props.refNewAnswer}
            onClick={async (e): Promise<void> => {
              e.stopPropagation();
              if (props.validations?.level === 2) {
                store.dispatch({
                  type: 'SNACKBAR_NEW_MESSAGE',
                  payload: {
                    message: i18n.t('limitConditionalInputsExceeded'),
                    severity: 'error',
                  },
                });
              } else {
                inputIncludedInPresets && (await addConditionalToPreset());
                if (addCondition) addCondition(openConditionalPopover?.item);
              }
              setOpenConditionalPopover(null);
            }}
          >
            <LiveHelpOutlined style={{ color: theme.palette.primary.main, marginRight: '5px' }} />
            {i18n.t('answerNewQuestion')}
          </MenuItem>
          <MenuItem
            id='button-Fail response'
            data-testid='button-Fail response'
            ref={props.refFailResponse}
            onClick={async (e): Promise<void> => {
              e.stopPropagation();
              if (props.validations?.level === 2) {
                store.dispatch({
                  type: 'SNACKBAR_NEW_MESSAGE',
                  payload: {
                    message: i18n.t('limitConditionalInputsExceeded'),
                    severity: 'error',
                  },
                });
              } else {
                if (addFailResponse) {
                  inputIncludedInPresets && (await addConditionalToPreset());
                  addFailResponse(openConditionalPopover?.item);
                }
              }
              setOpenConditionalPopover(null);
            }}
          >
            <RemoveCircleOutline style={{ color: theme.palette.error.main, marginRight: '5px' }} />
            {i18n.t('failResponse')}
          </MenuItem>
          <MenuItem
            id='button-Cancel issue'
            data-testid='button-Cancel issue'
            ref={props.refCancelIssue}
            onClick={async (e): Promise<void> => {
              e.stopPropagation();
              /* if (!openConditionalPopover?.item.color) { */
              inputIncludedInPresets && (await addConditionalToPreset());
              if (props.setCancelIssue) props.setCancelIssue(openConditionalPopover?.item);
              setOpenConditionalPopover(null);
            }}
          >
            <Cancel style={{ color: theme.palette.text.primary, marginRight: '5px' }} />
            {i18n.t('cancelIssue')}
          </MenuItem>
          <MenuItem
            id='button-Execute new task(s)'
            data-testid='button-Execute new task(s)'
            ref={props.refExecuteNewTask}
            onClick={async (e): Promise<void> => {
              e.stopPropagation();
              if (props.validations?.level === 2) {
                store.dispatch({
                  type: 'SNACKBAR_NEW_MESSAGE',
                  payload: {
                    message: i18n.t('limitConditionalInputsExceeded'),
                    severity: 'error',
                  },
                });
              } else {
                if (addNewTask) {
                  inputIncludedInPresets && (await addConditionalToPreset());
                  addNewTask(openConditionalPopover?.item);
                }
              }
              setOpenConditionalPopover(null);
            }}
          >
            <InsertDriveFileOutlined style={{ color: theme.palette.primary.main, marginRight: '5px' }} />
            {i18n.t('executeNewTask')}
          </MenuItem>
          <MenuItem
            id='button-Finish issue'
            data-testid='button-Finish issue'
            ref={props.refFinishIssue}
            onClick={async (e): Promise<void> => {
              e.stopPropagation();
              if (props.validations?.level === 2) {
                store.dispatch({
                  type: 'SNACKBAR_NEW_MESSAGE',
                  payload: {
                    message: i18n.t('limitConditionalInputsExceeded'),
                    severity: 'error',
                  },
                });
              } else {
                if (props.setFinishIssue) {
                  inputIncludedInPresets && (await addConditionalToPreset());
                  props.setFinishIssue(openConditionalPopover?.item);
                }
              }
              setOpenConditionalPopover(null);
            }}
          >
            <HighlightOffOutlined style={{ color: theme.palette.error.main, marginRight: '5px' }} />
            {i18n.t('finishIssue')}
          </MenuItem>
        </Popover>
      ) : null}
    </div>
  );
};

const connecter = typedConnect(
  (state) => ({
    isMasterTenant: state.authenticationReducer?.tenantInfo?.master,
  }),
  {
    editPreset,
  },
);

export default withTranslation('translation')(withStyles(styles)(connecter(withIssueTemplateConnector(SelectBox))));
