import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    trProps: {
      borderLeft: `0.4px solid ${theme.palette.text.disabled}`,
    },
    trPropsSelected: {
      borderLeft: `0.4px solid ${theme.palette.text.disabled}`,
      color: theme.palette.primary.main,
      background: theme.palette.primary.dark,
    },
    tdProps: {
      textAlign: 'left',
      alignItems: 'center',
      display: 'flex',
    },
    trPropsInputTool: {
      borderLeft: `0.4px solid ${theme.palette.text.disabled}`,
      '&:hover': {
        borderLeft: `0.4px solid ${theme.palette.text.disabled} !important`,
        color: theme.palette.primary.main,
        background: `${theme.palette.primary.dark} !important`,
        cursor: 'pointer',
      },
    },
    table: {
      border: 'none !important',
    },
    tableHeader: {
      '&>div': {
        width: '100%',
        border: 'none !important',
      },
      color: theme.palette.text.secondary,
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '16px',
      height: '34px',
      borderRight: `0.4px solid ${theme.palette.text.disabled} !important`,
      background: theme.palette.background.background1,
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      padding: '5px 8px !important',
      '&:last-child': {
        border: 'none !important',
      },
    },
    cellWithIcon: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.secondary,
    },
    tableText: {
      color: theme.palette.text.primary,
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '21px',
      letterSpacing: '0.1px',
    },
    mainContainer: {
      padding: '24px',
    },
    title: {
      color: theme.palette.text.primary,
      textAlign: 'center',
      fontFamily: 'Poppins',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '32px',
    },
    btnContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '16px',
      gap: '8px',
    },
    checkboxSelected: {
      color: theme.palette.primary.main,
    },
    selectedContainer: {
      marginTop: '8px',
      marginBottom: '8px',
      display: 'flex',
      justifyContent: 'flex-end',
      color: theme.palette.primary.main,
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '21px',
      letterSpacing: '0.1px',
    },
    divTable: {
      marginLeft: '-24px',
      marginRight: '-24px',
      '& > div': {
        height: 'calc(100vh - 272px)',
      },
      /* '&>div': {
        height: 'unset',
        maxHeight: '70vh',
      }, */
    },
    searchAndQrDiv: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      marginTop: '16px',
      gap: '8px',
    },
  });
