import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    inputs: {
      fontSize: theme.typography.fontSize,
    },
    iconSize: {
      fontSize: 20,
    },
    formGroup: {
      marginBottom: '24px',
    },
    loginWrapper: {
      padding: '16px 24px',
      fontSize: theme.typography.fontSize,
      width: '300px',
      [theme.breakpoints.up(500)]: {
        width: '500px',
      },
    },
    mutedText: {
      color: `${theme.palette.text.secondary} !important`,
    },
    headerBrand: {
      fontWeight: 500,
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      fontSize: '30px',
      marginBottom: '24px',
    },
    label: {
      fontSize: '16px',
    },
    divCard: {
      margin: '24px 24px 32px 24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    titleJoinCompany: {
      color: theme.palette.secondary.main,
      textAlign: 'center',
      fontFamily: 'Poppins',
      fontSize: '22px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '32px',
      marginBottom: '24px',
    },
    spanDefinePassword: {
      //@ts-ignore
      color: theme.palette.grey.main,
      textAlign: 'center',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '16px',
    },
    divInput: {
      marginBottom: '24px',
      '& > div': {
        marginBottom: '0px',
        '& > div:nth-child(1)': {
          color: theme.palette.secondary.main,
          fontFamily: 'Poppins',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '36px',
          paddingTop: '0px',
          marginBottom: '8px',
        },
        '& > div:nth-child(2)': {
          borderRadius: '4px',
          border: `1px solid ${theme.palette.grey.light}`,
          background: theme.palette.background.default,
        },
      },
    },
    spanNotIntended: {
      color: theme.palette.secondary.main,
      fontFamily: 'Open Sans',
      fontSize: '11px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '16px',
      letterSpacing: '0.4px',
    },
    divCancel: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '32px',
      marginBottom: '16px',
    },
    spanCancel: {
      color: theme.palette.primary.main,
      fontFamily: 'Open Sans',
      fontSize: '11px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '24px',
      letterSpacing: '0.1px',
      textDecorationLine: 'underline',
      cursor: 'pointer',
    },
  });
