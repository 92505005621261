import { useDropzone } from 'react-dropzone';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Grid, InputLabel } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import { Size } from 'src/utils/components/input-field/input-field';
import { styles } from 'src/utils/components/photo-field/styles';
import { useCallback } from 'react';
import { withStyles } from '@mui/styles';
import ProfilePhoto from '@assets/images/profilePhoto.png';

interface ChangePhotoProps extends WithTranslation {
  callback?: (photo: string[]) => void;
  value?: any;
  editable?: boolean;
  size?: Size;
  centered?: boolean;
  mouseOver?: boolean;
  classes?: any;
  id?: string;
}

const PhotoField = withTranslation('translation')((props: ChangePhotoProps) => {
  const convert = (file: string): void => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const newImage = new Image();
    newImage.src = file;
    canvas.width = 128;
    canvas.height = 128;
    newImage.onload = () => {
      ctx.drawImage(newImage, 0, 0, canvas.width, canvas.height);
      const convertedImage = canvas.toDataURL(`image/webp`, 0.9);
      props.callback([convertedImage]);
    };
  };

  const onDrop = useCallback((files) => {
    const fileToBase64 = async (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.toString());
        reader.onerror = (err) => reject(err);
        reader.readAsDataURL(file);
      });
    };
    fileToBase64(files[0])
      .then((res: string) => {
        convert(res);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
  });

  return (
    <form style={{ padding: '6px' }}>
      {props.editable ? (
        <Grid container className={props.classes.card}>
          <Grid item md={9} className={props.classes.container}>
            <div {...getRootProps()} className={`${props.classes.dropzone}`}>
              <input {...getInputProps()} id={props.id} />
              <div className={`${props.classes.row}`}>
                <Grid item className={props.classes.icon}>
                  <CloudUpload style={{ fontSize: '9em', color: 'white' }} />
                </Grid>
                <Grid item className={props.classes.text}>
                  {props.t('selectPhoto')}
                </Grid>
              </div>
            </div>
          </Grid>
          <Grid item md={3} className={props.classes.preview}>
            <InputLabel className={props.classes.previewLabel}>{props.t('preview')}</InputLabel>
            <div
              className={`${props.classes.imageCropper} ${props.classes.center}`}
              style={
                props.size
                  ? { width: props.size.width, height: props.size.height }
                  : { width: '100px', height: '100px' }
              }
            >
              <img className={props.classes.photo} src={props.value[0] || ProfilePhoto} alt={'Avatar'} />
            </div>
          </Grid>
        </Grid>
      ) : (
        <div
          className={`${props.classes.imageCropper} ${props.centered ? props.classes.center : props.classes.left}`}
          style={
            props.size ? { width: props.size.width, height: props.size.height } : { width: '100px', height: '100px' }
          }
        >
          <img className={props.classes.photo} src={props.value[0] || ProfilePhoto} alt={'Avatar'} />
        </div>
      )}
    </form>
  );
});

export default withStyles(styles)(PhotoField);
