import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    containerHeader: {
      display: 'inline-flex',
      padding: '0 !important',
      paddingLeft: '16px !important',
      alignItems: 'center',
    },
    tableTitle: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '56px',
      textAlign: 'center',
      color: theme.palette.text.primary,
      width: '100%',
      marginLeft: '-29px',
    },
  });
