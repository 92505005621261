import { Action, State } from 'src/interfaces/reducers';
import { getErrorObject } from 'src/utils/funcs';
import * as queries from 'src/modules/notification/notification.queries';
import { APOLLO_CLIENT } from 'config/apollo.config';
import { Dispatch } from 'redux';
import { GraphQLErrors } from '@apollo/client/errors';

const initialState: State = {};

export default (_state: State = initialState, action: Action): State => {
  switch (action.type) {
    default:
      return {};
  }
};

export const subscribeTopic =
  (token: string) =>
  async (dispatch: Dispatch): Promise<any | { graphQLErrors?: GraphQLErrors }> => {
    try {
      await APOLLO_CLIENT.mutate({
        variables: {
          token,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_SUBSCRIBE_TOPIC,
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };
