import { Chip, MenuItem, Popover, useTheme } from '@mui/material';
import { compose } from 'redux';
import { WithStyles, withStyles } from '@mui/styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { styles } from 'src/utils/components/generic-input-card/styles';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Ref, useContext, useEffect, useState } from 'react';
import { BlockCloseDropdownContext } from '..';

interface Input {
  subType: string;
  type: string;
  [x: string]: any;
  number: {
    validations: {
      min?: number;
      max?: number;
      inclusive?: boolean;
      name?: string;
      notBetween?: number[];
    }[];
  };
  multiple: {
    maxValues?: number;
    validations: {
      min?: number;
      max?: number;
      regex?: string;
      name?: string;
    }[];
  };
  string: {
    maxValues: number;
    validations: {
      min?: number;
      max?: number;
      regex?: string;
      name?: string;
    }[];
  };
}

interface MultiplePopoverProps extends WithTranslation, WithStyles<typeof styles> {
  anchor: {
    current: Element;
  };
  onClose: () => void;
  input: Input;
  setInput: (input: Partial<Input>) => void;
  selectRef: Ref<HTMLDivElement>;
  open: boolean;
  active: boolean;
  name: string;
  idx: number;
  optionsWithoutLinkWith: { option: string; value: string }[];
  options: { option: string; value: string }[];
  linkWith: boolean;
  validationIndex?: number;
}

const SelectValidationType = (props: MultiplePopoverProps): JSX.Element => {
  const {
    classes,
    input,
    active,
    selectRef,
    name,
    idx,
    linkWith,
    options,
    optionsWithoutLinkWith,
    validationIndex,
    setInput,
  } = props;
  const [oneSelectedPopover, setOneSelectedPopover] = useState<any>(null);
  const theme = useTheme();

  const handleChangeOptString = (newOpt: string) => {
    const updatedValidations = [...input[input.type].validations];

    updatedValidations[validationIndex] = {
      ...input[input.type].validations[validationIndex],
      name: newOpt === 'linkWith' ? newOpt : newOpt + ':',
    };

    if (input.type === 'string') {
      return setInput({
        ...input,
        [input.type]: { ...input[input.type], validations: updatedValidations },
      });
    }

    setInput({
      _id: input._id,
      required: newOpt === 'linkWith' ? true : input.required,
      [input.type]: { ...input[input.type], validations: updatedValidations },
      string: { ...input['string'], validations: updatedValidations },
    });
  };
  const setBlockClose = useContext(BlockCloseDropdownContext);

  useEffect(() => {
    setBlockClose(!!oneSelectedPopover);
  }, [oneSelectedPopover]);

  const handleChangeOpt = (event: { target: { value: unknown } }, idx: number): void => {
    setInput({
      _id: input._id,
      required: event.target.value === 'linkWith' ? true : input.required,
      [input.type]: {
        ...input[input.type],
        validations:
          idx === 0 && (input.type === 'qrCode' || input.type === 'tool')
            ? [
                {
                  name:
                    event.target.value === 'linkWith'
                      ? 'linkWith'
                      : `
          ${event.target.value}${input[input.type]?.validations[0].name.split(':').length > 1 ? ':' : ''}
          ${
            input[input.type]?.validations[0].name.split(':').length > 1
              ? input[input.type]?.validations[0].name.split(':')[1].trim()
              : ''
          }
          `.trim(),
                  ...(input.type === 'tool' && event.target.value === 'linkWith' ? { linkWithInputs: [] } : {}),
                },
              ]
            : input[input.type]?.validations.map((val, i) =>
                i === idx
                  ? {
                      name:
                        event.target.value === 'linkWith'
                          ? 'linkWith'
                          : event.target.value /* `${event.target.value}:${val.name.split(':')[1].trim()}` */,
                    }
                  : val,
              ),
      },
    });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
      <Chip
        style={{ display: idx > 0 ? 'flex' : 'none' }}
        label={props.t('OR')}
        size={'small'}
        classes={{ root: classes.orChip }}
      />
      <p className={classes.selectText}>{`${props.t('validation')}:`}</p>
      <div
        style={{
          minWidth: '80px',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          color: theme.palette.text.primary,
          borderBottom: active ? `1px solid` : ``,
          /* minHeight: '36px' */
        }}
        onClick={(e) => {
          if (active) setOneSelectedPopover(e.target);
        }}
      >
        {props.t(name?.split(':')[0])}
        {active ? <KeyboardArrowDown style={{ marginLeft: 'auto', marginRight: 0 }} /> : undefined}
      </div>
      {oneSelectedPopover ? (
        <Popover
          ref={selectRef}
          open={!!oneSelectedPopover}
          anchorEl={oneSelectedPopover}
          className={classes.selectOptions}
          onClose={() => setOneSelectedPopover(null)}
        >
          {(linkWith ||
          ((input.type === 'qrCode' || input.type === 'tool') && input[input.type].validations?.length === 1)
            ? options
            : optionsWithoutLinkWith
          )
            .filter((opt) => !(input.type !== 'tool' && opt.value === 'specificTool'))
            .map((opt, i) => (
              <MenuItem
                id={'menuItem' + `${props.t(opt.value)} `}
                data-testid={'menuItem' + `${props.t(opt.value)} `}
                onClick={() => {
                  ['string', 'qrCode'].includes(input.type)
                    ? handleChangeOptString(opt.value)
                    : handleChangeOpt({ target: { value: opt.value } }, idx);
                  setOneSelectedPopover(null);
                }}
                key={i}
              >
                {`${props.t(opt.value)} `}
              </MenuItem>
            ))}
        </Popover>
      ) : undefined}
    </div>
  );
};

export default compose<any>(withTranslation('translation'), withStyles(styles))(SelectValidationType);
