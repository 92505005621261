import { ReactNode, createContext, useCallback, useContext, useState } from 'react';
import { AccountState, TableState } from '../types/account.types';

export const tableContextInitialState: TableState = {
  data: [],
  dataSize: 0,
  columnsToShow: null,
  dataQueryState: {
    limit: 25,
    skip: 0,
    sorted: null,
    filtered: null,
    orderBy: 'name',
    orderType: '_ASC',
  },
  loading: false,
  optionsDropdownOpen: false,
  optionsDropdownRef: null,
  selectedAccounts: [],
  updatingData: false,
  filters: {},
  filtersOrder: null,
  filtersCount: 0,
  refetchRequired: false,
};

export const accountContextInitialValue: AccountState = {
  tableState: tableContextInitialState,
  openFilterPopover: false,
  searchActive: false,
  columnsToShow: null,
};

const AccountContext = createContext<AccountState>(accountContextInitialValue);

interface AccountContextProviderProps {
  children: ReactNode;
  initialValue: AccountState;
}

export const AccountContextProvider = ({
  children,
  initialValue = accountContextInitialValue,
}: AccountContextProviderProps) => {
  const [accountState, setAccountState] = useState<AccountState>(initialValue);

  const updateAccountState = useCallback(
    (newData: Partial<AccountState>) => {
      setAccountState((prevState) => ({ ...prevState, ...newData }));
    },
    [setAccountState],
  );

  const updateTableState = useCallback(
    (newData: Partial<TableState>) => {
      setAccountState((prevState) => ({
        ...prevState,
        tableState: { ...prevState.tableState, ...newData },
      }));
    },
    [setAccountState],
  );

  return (
    <AccountContext.Provider value={{ ...accountState, updateAccountState, updateTableState, setAccountState }}>
      {children}
    </AccountContext.Provider>
  );
};

export const useAccountContext = () => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error('useAccountContext must be used within a AccountContext');
  }
  return context;
};
