import { ReactNode, createContext, useCallback, useContext, useState } from 'react';
import { AgendaState, TableState } from '../types/agenda.types';
import { useSelector } from 'react-redux';
import { GlarState } from 'src/reducer-manager';

export const tableContextInitialState: TableState = {
  data: [],
  subscriptionData: null,
  openQrCode: false,
  token: null,
  expanded: {},
  taskInstances: [],
  elements: [],
  loadingPdf: null,
  pagination: {
    sorted: null,
    filtered: null,
  },
  todo: [],
  typeOrder: true,
  varToOrder: 'createdAt',
  subscriptions: [],
  dataToDownload: null,
};

export const agendaContextInitialValue: AgendaState = {
  dropdownOpen: false,
  splitButtonOpen: false,
  openModal: false,
  activeTab: 1,
  activeTabFilter: null,
  listView: true,
  activeMainTab: 1, //history.location.pathname === '/work-packages' ? 2 : 1,
  exportExcelModal: false,
  columnsToShow: null,
  searchActive: false,
  dateRange: {
    startDate: null,
    endDate: null,
  },
  calendarDateRange: {
    startDate: null,
    endDate: null,
  },
  filtersCount: 0,
  tabsCount: null,
  openPopoverFilter: false,
  anchorPopoverFilter: null,
  workPackageInstanceTitle: '',
  tableState: tableContextInitialState,
  loggedUser: null,
};

const AgendaContext = createContext<AgendaState>(agendaContextInitialValue);

interface AgendaContextProviderProps {
  children: ReactNode;
  initialValue: AgendaState;
}

export const AgendaContextProvider = ({
  children,
  initialValue = agendaContextInitialValue,
}: AgendaContextProviderProps) => {
  initialValue.loggedUser = useSelector((state: GlarState) => state.loginReducer.loggedUser);
  const [agendaState, setAgendaState] = useState<AgendaState>(initialValue);

  const updateAgendaState = useCallback((newData: Partial<AgendaState>) => {
    setAgendaState((prevState) => ({ ...prevState, ...newData }));
  }, []);

  const updateTableState = useCallback((newData: Partial<TableState>) => {
    setAgendaState((prevState) => ({
      ...prevState,
      tableState: { ...prevState.tableState, ...newData },
    }));
  }, []);

  return (
    <AgendaContext.Provider value={{ ...agendaState, updateAgendaState, updateTableState, setAgendaState }}>
      {children}
    </AgendaContext.Provider>
  );
};

export const useAgendaContext = () => {
  const context = useContext(AgendaContext);
  if (!context) {
    throw new Error('useAgendaContext must be used within a AgendaContext');
  }
  return context;
};
