import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    centerLoading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    body: {
      backgroundColor: theme.palette.background.background1,
      width: '100%',
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      alignItems: 'center',
      // minHeight: '100%',
      flexGrow: 1,
      justifyContent: 'flex-start',
    },
    underline: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    noResults: {
      fontWeight: 'bolder',
      fontSize: 16,
      color: theme.palette.grey.main,
    },
    emptyContainer: {
      width: '100%',
      height: '100%',
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    unauthorized: {
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 16,
    },
  });
