import { useCallback, useEffect, useRef } from 'react';

interface Props {
  onTriggered: (e: Event) => void;
  disableClick?: boolean;
  disableTouch?: boolean;
  disableKeys?: boolean;
  allowAnyKey?: boolean;
  triggerKeys?: string[];
  skip?: boolean;
}

type EventConfigItem = [boolean | undefined, string, (e: Event) => void];

/**
 * Hook used to detect clicks outside a component (or an escape key press). onTriggered function is triggered on `click`, `touch` or escape `keyup` event.
 *
 */
export default function useDetectClickOutside({
  onTriggered,
  disableClick,
  disableTouch,
  disableKeys,
  allowAnyKey,
  triggerKeys,
  skip,
}: Props) {
  const ref = useRef(null);

  const keyListener = useCallback(
    (e: KeyboardEvent) => {
      if (allowAnyKey) {
        onTriggered(e);
      } else if (triggerKeys) {
        if (triggerKeys.includes(e.key)) {
          onTriggered(e);
        }
      } else {
        if (e.key === 'Escape') {
          onTriggered(e);
        }
      }
    },
    [allowAnyKey, triggerKeys],
  );

  const clickOrTouchListener = useCallback((e: MouseEvent | TouchEvent) => {
    if (ref && ref.current) {
      if (!(ref.current! as any).contains(e.target)) {
        onTriggered?.(e);
      }
    }
  }, []);

  useEffect(() => {
    if (!skip) {
      const eventsConfig: EventConfigItem[] = [
        [disableClick, 'click', clickOrTouchListener],
        [disableTouch, 'touchstart', clickOrTouchListener],
        [disableKeys, 'keyup', keyListener],
      ];

      eventsConfig.map((eventConfigItem) => {
        const [isDisabled, eventName, listener] = eventConfigItem;

        if (!isDisabled) {
          document.addEventListener(eventName, listener);
        }
      });

      return () => {
        eventsConfig.map((eventConfigItem) => {
          const [isDisabled, eventName, listener] = eventConfigItem;

          if (!isDisabled) {
            document.removeEventListener(eventName, listener);
          }
        });
      };
    }

    return () => {};
  }, [disableClick, disableTouch, disableKeys, !!skip]);

  return ref;
}
