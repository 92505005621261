import { ApolloQueryResult } from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';
import { APOLLO_CLIENT } from 'config/apollo.config';
import moment from 'moment';
import { Dispatch } from 'redux';
import {
  Input,
  IssueInstance,
  IssueTemplateDraft,
  IssueTemplateDraftTaskTemplateGroupTaskTemplate,
  IssueTemplateDraftTaskTemplateGroupTaskTemplateLogUpdateInput,
  Job,
  MoveTaskTemplateDraftsDocument,
  Task,
  UpdateTaskTemplateLogsDocument,
} from 'src/gql/graphql';
import { Action, State } from 'src/interfaces/reducers';
import * as queries from 'src/modules/task/task.queries';
import { formatExecInps, getErrorObject } from 'src/utils/funcs';
import i18n from 'src/utils/translations/i18n';
import { NexusGenFieldTypes, NexusGenInputs } from '../../../../server/src/types';
import { addInputState } from '../issue-templates/redux/issue.template.draft.redux';

export enum TaskInstanceDispatchTypes {
  CHANGE_STATE_TASK_INSTANCE = 'CHANGE_STATE_TASK_INSTANCE',
  TASK_INSTANCE_SET_INPUT_VALUE = 'TASK_INSTANCE_SET_INPUT_VALUE',
  TASK_EXECUTION_UPDATE_INPUT_NOTE = 'TASK_EXECUTION_UPDATE_INPUT_NOTE',
}

export const ERROR = 'ERROR';

const initialState: State = {
  taskInstances: [],
  error: false,
};

export default (state: State = initialState, action: Action): State => {
  let taskInstances: any[] = [];
  switch (action.type) {
    case TaskInstanceDispatchTypes.CHANGE_STATE_TASK_INSTANCE:
      taskInstances = [];
      state.taskInstances.map((taskInstance: NexusGenFieldTypes['Task']) => {
        if (action.payload.taskInstanceId && taskInstance._id === action.payload.taskInstanceId) {
          taskInstance.stateMachineInstance = action.payload.stateMachine;
          taskInstances.push(taskInstance);
        } else {
          taskInstances.push(taskInstance);
        }
      });
      return { ...state, taskInstances, error: false };
    case TaskInstanceDispatchTypes.TASK_INSTANCE_SET_INPUT_VALUE:
      taskInstances = [];
      state.taskInstances.map((taskInstance: NexusGenFieldTypes['Task']) => {
        if (taskInstance._id === action.payload.taskInstance._id) {
          taskInstances.push(action.payload.taskInstance);
        } else {
          taskInstances.push(taskInstance);
        }
      });
      return { ...state, taskInstances, error: false };
    case TaskInstanceDispatchTypes.TASK_EXECUTION_UPDATE_INPUT_NOTE:
      taskInstances = [];
      state.taskInstances.map((taskInstance: NexusGenFieldTypes['Task']) => {
        if (taskInstance._id === action.payload.taskInstance._id) {
          taskInstances.push(action.payload.taskInstance);
        } else {
          taskInstances.push(taskInstance);
        }
      });
      return { ...state, taskInstances, error: false };
    case ERROR:
      return { ...state, error: true };
    default:
      return state;
  }
};

export const getTaskInstance =
  (issueInstanceId: string) =>
  async (dispatch: Dispatch): Promise<Task> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          id: issueInstanceId,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_TASK_INSTANCE,
      });

      return response.data.taskInstance;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const changeStateTaskInstance =
  (newState: { token: string; action: string }, taskInstanceId: string) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: newState.token
          ? {
              token: newState.token,
              id: taskInstanceId,
              action: newState.action,
            }
          : {
              id: taskInstanceId,
              action: newState.action,
            },
        fetchPolicy: 'no-cache',
        mutation: queries.M_STATE_MACHINE_CHANGE_STATE,
      });

      dispatch({
        type: TaskInstanceDispatchTypes.CHANGE_STATE_TASK_INSTANCE,
        payload: {
          stateMachine: response.data.changeTaskState,
          taskInstanceId,
        },
      });

      return response.data.changeTaskState;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const setInputValueTaskInstance =
  (
    valueData: { _id: string; data: NexusGenInputs['InputValueUpdateInput']; cancelMotive?: string },
    cancelMotive?: string,
    issueId?: string,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      if (valueData.data.datetime) {
        valueData.data.datetime = valueData.data.datetime.map((date) => moment(date).toISOString());
      }
      if (valueData.data.site && valueData.data.site.length && typeof valueData.data.site[0] !== 'string') {
        valueData.data.site = valueData.data.site.map((s) => s['_id']);
      }
      if (valueData.data.element && valueData.data.element.length && typeof valueData.data.element[0] !== 'string') {
        valueData.data.element = valueData.data.element.map((s) => s['_id']);
      }

      const response = await APOLLO_CLIENT.mutate({
        variables: {
          id: valueData._id,
          data: valueData.data,
          cancelMotive: valueData.cancelMotive ? valueData.cancelMotive : cancelMotive ? cancelMotive : null,
          issueId,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_TASK_INSTANCE_SET_INPUT_VALUE,
      });

      dispatch({
        type: TaskInstanceDispatchTypes.TASK_INSTANCE_SET_INPUT_VALUE,
        payload: { taskInstance: response.data.setTaskExecutionInputs },
      });

      return response.data.setTaskExecutionInputs;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      //input validations are being handled in frontend isValidInputs

      if (!obj.message.startsWith('validations')) {
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: obj.message,
            severity: 'error',
          },
        });
      }

      return error;
    }
  };

export const updateInputNote =
  (valueData: {
    _id: string;
    _inputId: string;
    onResponsePath: string[];
    data: NexusGenInputs['InputNoteUpdateInput'][];
  }) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          id: valueData._id,
          inputId: valueData._inputId,
          onResponsePath: valueData.onResponsePath,
          data: valueData.data,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_TASK_EXECUTION_UPDATE_INPUT_NOTE,
      });

      dispatch({
        type: TaskInstanceDispatchTypes.TASK_EXECUTION_UPDATE_INPUT_NOTE,
        payload: { taskInstance: response.data.setTaskExecutionInputNote },
      });
      return response.data.setTaskExecutionInputNote;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const reassignTaskInstances =
  (data: { _id: string; accounts: string[]; labelValues: string[] }[]) =>
  async (dispatch: Dispatch): Promise<void | string[]> => {
    try {
      const resp = await APOLLO_CLIENT.mutate({
        variables: {
          data,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_REASSIGN_TASK_INSTANCES,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('taskReassignSucess'),
          severity: 'success',
        },
      });
      return resp.data.reassignTasks;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const updateTaskInstances =
  (data: NexusGenInputs['TaskMultipleUpdateInput'], where: NexusGenInputs['IssueInstanceWhereUniqueInput']) =>
  async (dispatch: Dispatch): Promise<void | unknown> => {
    try {
      const resp = await APOLLO_CLIENT.mutate({
        variables: {
          data,
          where,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_TASK_INSTANCES,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('tasksUpdatedSuccessfully'),
          severity: 'success',
        },
      });
      return resp.data.updateIssueTasks.tasks;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const updateTaskDraft = (id, update) => async (): Promise<void> => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        id,
        update,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_UPDATE_TASK_DRAFT,
    });

    return response.data.updateTaskMappingDraft;
  } catch (error) {
    return error;
  }
};

export const updateManyTasksDraft =
  (issueCatalogDraft, update, taskMappingsToUpdate) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          issueCatalogDraft,
          update,
          taskMappingsToUpdate,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_MANY_TASKS_DRAFT,
      });

      return response.data.updateManyTaskMappingDrafts;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const deleteManyTaskTemplates =
  (id, ids) =>
  async (dispatch: Dispatch): Promise<Partial<IssueTemplateDraft>> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          ids,
          id,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_DELETE_MANY_TASK_TEMPLATES,
      });

      return response.data.deleteManyTaskTemplateDrafts;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const updateTaskTemplateDraftsVariantOrder =
  (issueId, groupId, data) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          issueId,
          groupId,
          data,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_TASK_TEMPLATE_DRAFTS_VARIANT_ORDER,
      });
      return response.data.updateTaskTemplateDraftsVariantOrder;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const createManyTaskTemplates =
  (issueId, groupId, data) =>
  async (dispatch: Dispatch): Promise<Partial<IssueTemplateDraft>> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          issueId,
          groupId,
          data,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_CREATE_MANY_TASK_TEMPLATES,
      });
      return response.data.createManyTaskTemplateDrafts;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const updateManyTaskTemplates =
  (issueId, taskTemplatesToUpdate, update) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          issueId,
          taskTemplatesToUpdate,
          update,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_MANY_TASK_TEMPLATES,
      });

      return response.data.updateManyTaskTemplateDrafts;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const updateMultipleTaskTemplates =
  (id, data, draftId) =>
  async (dispatch: Dispatch): Promise<Partial<IssueTemplateDraft> & { graphQLErrors: GraphQLErrors }> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          data,
          taskTemplateGroup: id,
          issueDraftId: draftId,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_MULTIPLE_TASK_TEMPLATES,
      });

      return response.data.updateMultipleTaskTemplateDrafts;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const updateTaskTemplate =
  (draftId, groupId, data, id) =>
  async (/* dispatch: Dispatch */): Promise<
    Partial<IssueTemplateDraftTaskTemplateGroupTaskTemplate> | { graphQLErrors: string }
  > => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          draftId,
          groupId,
          data,
          id,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_TASK_TEMPLATE,
      });

      return response.data.updateTaskTemplateDraft;
    } catch (error) {
      /* const obj = getErrorObject(error, "");
          dispatch({
            type: "SNACKBAR_NEW_MESSAGE",
            payload: {
              message: obj.message,
              severity: "error",
            },
          }); */

      return error;
    }
  };

export const updateTaskTemplateLogs =
  (
    draftId: string,
    groupId: string,
    logs: IssueTemplateDraftTaskTemplateGroupTaskTemplateLogUpdateInput[],
    id: string,
  ) =>
  async (): Promise<Partial<IssueTemplateDraftTaskTemplateGroupTaskTemplate>> => {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        draftId,
        groupId,
        data: {
          log: logs,
        },
        id,
      },
      fetchPolicy: 'no-cache',
      mutation: UpdateTaskTemplateLogsDocument,
    });

    return response.data.updateTaskTemplateDraft;
  };

export const updateTaskTemplateInput = (draftId, groupId, taskId, inputId, data) => async (): Promise<Input> => {
  const response = await APOLLO_CLIENT.mutate({
    variables: {
      draftId,
      groupId,
      taskId,
      inputId,
      data,
    },
    fetchPolicy: 'no-cache',
    mutation: queries.M_UPDATE_TASK_TEMPLATE_INPUT,
  });

  return response.data.updateInputTemplateDraft;
};

export const addTaskTemplateInput =
  (draftId: string, groupId: string, taskId: string, data: any, onResponseId?: string) =>
  async (dispatch: Dispatch) => {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        draftId,
        groupId,
        taskId,
        onResponseId,
        data,
      },
      fetchPolicy: 'no-cache',
      mutation: queries.M_ADD_TASK_TEMPLATE_INPUT,
    });

    dispatch(
      addInputState({
        onResponseId,
        inputToAdd: formatExecInps([response.data.addInputTemplateDraft], { keepOrder: true })[0],
      }),
    );

    return response.data.addInputTemplateDraft._id;
  };

export const taskTemplateDraft =
  (draftId, groupId, taskId) =>
  async (/* dispatch: Dispatch */): Promise<Partial<IssueTemplateDraftTaskTemplateGroupTaskTemplate>> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          draftId,
          groupId,
          taskId,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_TASK_TEMPLATE_DRAFT,
      });

      return response.data.taskTemplateDraft;
    } catch (error) {
      /* const obj = getErrorObject(error, "");
          dispatch({
            type: "SNACKBAR_NEW_MESSAGE",
            payload: {
              message: obj.message,
              severity: "error",
            },
          }); */

      return error;
    }
  };

export const taskTemplateDraftsTyped = async (
  draftId: string,
  taskIds: string[],
): Promise<Partial<IssueTemplateDraftTaskTemplateGroupTaskTemplate>[]> => {
  try {
    const response: ApolloQueryResult<Partial<IssueTemplateDraftTaskTemplateGroupTaskTemplate>[]> =
      await APOLLO_CLIENT.query({
        variables: {
          draftId,
          taskIds,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_TASK_TEMPLATE_DRAFTS,
      });

    // Ensure the response data and its shape is as expected
    if (response.data) {
      return response.data;
    } else {
      // Handle the case where the response is not as expected
      throw new Error('Unexpected response format');
    }
  } catch (error) {
    // Handle errors and reject the promise with the error
    throw error;
  }
};

//return should be NexusGenFieldTypes['IssueTemplateDraftTaskTemplateGroupTaskTemplate'][]
export const taskTemplateDrafts =
  (draftId, taskIds) =>
  async (/* dispatch: Dispatch */): Promise<Partial<IssueTemplateDraftTaskTemplateGroupTaskTemplate>[]> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          draftId,
          taskIds,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_TASK_TEMPLATE_DRAFTS,
      });
      return response.data.taskTemplateDrafts;
    } catch (error) {
      /* const obj = getErrorObject(error, "");
          dispatch({
            type: "SNACKBAR_NEW_MESSAGE",
            payload: {
              message: obj.message,
              severity: "error",
            },
          }); */

      return error;
    }
  };

export const moveTaskTemplateDrafts =
  (issueTemplateDraft, fromTaskTemplateGroup, toTaskTemplateGroup, taskTemplatesToMove, taskTemplateNewOrder?) =>
  async (dispatch: Dispatch): Promise<Partial<IssueTemplateDraft> & { graphQLErrors: GraphQLErrors }> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          issueTemplateDraft,
          fromTaskTemplateGroup,
          toTaskTemplateGroup,
          taskTemplatesToMove,
          taskTemplateNewOrder,
        },
        fetchPolicy: 'no-cache',
        mutation: MoveTaskTemplateDraftsDocument,
      });

      return response.data.moveTaskTemplateDrafts;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const startGroupTimer =
  (issueId, groupId) =>
  async (dispatch: Dispatch): Promise<IssueInstance> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          issueId,
          groupId,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_START_GROUP_TIMER,
      });

      return response.data.startGroupTimer;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const pauseGroupTimer =
  (issueId, groupId) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          issueId,
          groupId,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_PAUSE_GROUP_TIMER,
      });

      return response.data.pauseGroupTimer;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const resumeGroupTimer =
  (issueId: string, groupId: string, timer: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          issueId,
          groupId,
          timer,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_RESUME_GROUP_TIMER,
      });

      return response.data.resumeGroupTimer;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const groupTimerJobs = (issue: string) => async (): Promise<Job[]> => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        issue,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GROUP_TIMER_JOBS,
    });

    return response.data.groupTimerJobs;
  } catch (error) {
    return error;
  }
};

export const getTasksMaterialsOrTools = (id: string, material: boolean) => async () => {
  try {
    let responseMaterials: {
      data: { taskInstance: { assignedMaterials: NexusGenFieldTypes['TaskAssignedMaterial'][] } };
    };

    let responseTools: { data: { taskInstance: { assignedTools: NexusGenFieldTypes['TaskAssignedTool'][] } } };

    if (material) {
      responseMaterials = await APOLLO_CLIENT.query({
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_MATERIALS,
      });
    } else {
      responseTools = await APOLLO_CLIENT.query({
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_TOOLS,
      });
    }

    return responseMaterials?.data.taskInstance.assignedMaterials.length > 0
      ? responseMaterials?.data.taskInstance.assignedMaterials
      : responseTools.data.taskInstance.assignedTools.length > 0
        ? responseTools.data.taskInstance.assignedTools
        : [];
  } catch (error) {
    return error;
  }
};

export const retakeTask = (task: Task) => async (dispatch: Dispatch) => {
  try {
    const response: { data: { retakeTask: Task } } = await APOLLO_CLIENT.query({
      variables: {
        task: task._id,
      },
      fetchPolicy: 'no-cache',
      query: queries.M_RETAKE_TASK,
    });

    return response.data.retakeTask;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);

    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    throw error;
  }
};
