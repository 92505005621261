import LazyLoadingContent from 'src/utils/components/lazy-loading-content';
import FeedPage from './feed.page';
import FeedView from './components/view';
import FeedCreatePost from './components/create';
import { Suspense } from 'react';

export const render = {
  view: (props) => (
    <Suspense fallback={<LazyLoadingContent />}>
      <FeedPage {...props} type={'view'}>
        <FeedView />
      </FeedPage>
    </Suspense>
  ),
  create: (props) => (
    <Suspense fallback={<LazyLoadingContent />}>
      <FeedPage {...props} type={'create'}>
        <FeedCreatePost />
      </FeedPage>
    </Suspense>
  ),
};
