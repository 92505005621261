import { Close } from '@mui/icons-material';
import { Popover, TextField, Tooltip } from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { TwitterPicker } from 'react-color';
import { TwitterPickerStylesProps } from 'react-color/lib/components/twitter/Twitter';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Classes } from 'reactcss';
import CustomNumberInput from 'src/utils/components/custom-number-input';
import { CustomRange } from '../..';
import { styles } from './styles';

type CustomValueRangeListItemProps = {
  customRange: CustomRange;
  setCustomRanges: (ratings: CustomRange[]) => void;
  customRanges: CustomRange[];
  index: number;
  setErrorExists?: (error: boolean) => void;
} & WithTranslation &
  WithStyles<typeof styles>;

const CustomValueRangeListItem = ({
  t,
  customRange,
  customRanges,
  index,
  classes,
  setCustomRanges,
  setErrorExists,
}: CustomValueRangeListItemProps) => {
  const [colorPickerRef, setColorPickerRef] = useState<HTMLDivElement>(null);
  const twitterPickerStyling: Partial<Classes<TwitterPickerStylesProps>> | undefined = {
    default: {
      input: { display: 'none' },
      hash: { display: 'none' },
      body: { display: 'grid', gridTemplateColumns: 'repeat(6, 40px)' },
      card: { width: 'fit-content' },
    },
  };

  const handleDataChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string, dataToChange: string) => {
    let targetValue: string | number;

    if (typeof e === 'string') {
      targetValue = e;
    } else {
      targetValue = dataToChange === 'max' ? parseInt(e.target.value) : e.target.value;
    }
    const newRatings = customRanges.map((rating, i) =>
      index === i ? { ...customRange, [`${dataToChange}`]: targetValue } : rating,
    );

    setCustomRanges(newRatings);
  };

  const handleDeleteRange = () => {
    const newRatings = customRanges.filter((_, i) => i !== index);
    setCustomRanges(newRatings);
  };

  const targetLessThanPrevious = useMemo(() => {
    if (index === 0) {
      return false;
    } else {
      let valueToReturn = false;
      const val = customRanges[index]?.max;
      for (let ind = 0; ind < index; ind++) {
        const element = customRanges[ind];
        if (element?.max > val) valueToReturn = true;
      }
      return valueToReturn;
    }
  }, [customRanges]);

  useEffect(() => setErrorExists(targetLessThanPrevious), [targetLessThanPrevious]);

  const handleNumberChange = (direction: 'increase' | 'decrease') => {
    const changeValue = direction === 'increase' ? 1 : -1;

    const newRatings = customRanges.map((rating, i) => {
      if (index === i) {
        // Update the current index's max or min
        return { ...rating, ['max']: rating['max'] + changeValue };
      } else {
        // For all other cases, return the rating as it is
        return rating;
      }
    });

    setCustomRanges(newRatings);
  };

  return (
    <div className={classes.customRangeItemContainer}>
      <TextField
        style={{ width: '50%' }}
        value={customRange.name}
        variant={'standard'}
        onChange={(e) => handleDataChange(e, 'name')}
      />
      <div className={classes.customRangeContainer}>
        <CustomNumberInput
          id='input-custom-range'
          value={customRange.max}
          placeholder='0'
          variant='outlined'
          hasErrors={targetLessThanPrevious}
          style={{
            width: targetLessThanPrevious ? '90%' : '100%',
          }}
          errorMessage='targetLessThanPrevious'
          onChangeFunction={(e) => handleDataChange(e, 'max')}
          onClickToIncrease={() => handleNumberChange('increase')}
          onClickToDecrease={() => customRange.max > 0 && handleNumberChange('decrease')}
          minNumber={0}
          maxNumber={999999999}
        />

        <Tooltip arrow title={t('rangeColor')} placement={'top'}>
          <div
            className={classes.divBackgroundColor}
            style={{ backgroundColor: customRange.color, marginLeft: targetLessThanPrevious ? '-8px' : '16px' }}
            onClick={(e): void => {
              setColorPickerRef(e.currentTarget);
            }}
          />
        </Tooltip>
        <Popover
          open={!!colorPickerRef}
          // @ts-ignore
          anchorEl={colorPickerRef}
          onClose={(): void => {
            setColorPickerRef(null);
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className={classes.colorPicker}>
            <TwitterPicker
              onChange={(e: { hex: string }): void => {
                handleDataChange(e.hex, 'color');
                setColorPickerRef(null);
              }}
              width={'312px'}
              styles={twitterPickerStyling}
              colors={[
                '#F44336',
                '#00C48C',
                '#2470B3',
                '#E9C46A',
                '#F4A261',
                '#2A9D8F',
                '#E31B0C',
                '#F9A09A',
                '#98A2B3',
                '#073B4C',
                '#06D6AD',
              ]}
            />
          </div>
        </Popover>
        {customRanges.length > 1 && (
          <Close
            className={targetLessThanPrevious ? classes.deleteCustomRangeError : classes.deleteCustomRange}
            onClick={() => handleDeleteRange()}
          />
        )}
      </div>
    </div>
  );
};

export default withTranslation('translation')(withStyles(styles)(CustomValueRangeListItem));
