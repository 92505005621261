import BetterFieldPopover, { FieldPopoverOnChange, FieldPopoverProps } from '../../better-field-popover';
import { useQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import { Q_GET_UNIT } from 'src/modules/unit/unit.queries';
import { getErrorObject } from 'src/utils/funcs';

export default function UnitField(
  props: Omit<FieldPopoverProps<'unit'>, 'context' | 'resetOption' | 'multiple' | 'value'> & { value?: string },
) {
  const { data, error, loading } = useQuery(Q_GET_UNIT, {
    variables: {
      filter: {
        _id: props.value,
      },
    },
    fetchPolicy: 'cache-first',
    skip: !props.value,
  });

  if (error) {
    throw new Error(getErrorObject(error).message);
  }

  if (props.disabled) {
    return (
      <Typography data-testid={props['data-testid']} variant={'body1'} style={{ fontSize: 13, marginLeft: 5 }}>
        {!loading && data ? data.unit.name : undefined}
      </Typography>
    );
  }

  return (
    <BetterFieldPopover
      {...props}
      context={'unit'}
      multiple={false}
      value={loading || !data ? undefined : data.unit}
      //@ts-ignore
      onChange={props.onChange as FieldPopoverOnChange<false, 'unit'>}
    />
  );
}
